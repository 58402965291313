import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/Hmerologia_p9/image001.jpg";
import Image02 from "../assets/images/Hmerologia_p9/image002.jpg";
import Image03 from "../assets/images/Hmerologia_p9/image003.jpg";
import Image04 from "../assets/images/Hmerologia_p9/image004.jpg";
import Image05 from "../assets/images/Hmerologia_p9/image005.jpg";
import Image06 from "../assets/images/Hmerologia_p9/image006.jpg";
import Image07 from "../assets/images/Hmerologia_p9/image007.jpg";
import Image08 from "../assets/images/Hmerologia_p9/image008.jpg";
import Image09 from "../assets/images/Hmerologia_p9/image009.jpg";
import Image10 from "../assets/images/Hmerologia_p9/image010.jpg";
import Image11 from "../assets/images/Hmerologia_p9/image011.jpg";
import Image12 from "../assets/images/Hmerologia_p9/image012.jpg";
import Image13 from "../assets/images/Hmerologia_p9/image013.jpg";
import Image14 from "../assets/images/Hmerologia_p9/image014.jpg";
import Image15 from "../assets/images/Hmerologia_p9/image015.jpg";
import Image16 from "../assets/images/Hmerologia_p9/image016.jpg";
import Image17 from "../assets/images/Hmerologia_p9/image017.jpg";
import Image18 from "../assets/images/Hmerologia_p9/image018.jpg";
import Image19 from "../assets/images/Hmerologia_p9/image019.jpg";
import Image20 from "../assets/images/Hmerologia_p9/image020.jpg";

function P10_Hmerologia() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1
          props="Ημερολόγιο 2013 : Μικρασιατική εκστρατεία -
Πορεία Μεραρχίας Αρχιπελάγους"
        />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
      <Typography variant="h1" sx={theme.typography.typography1}>
          Πρόλογος
        </Typography>
        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

         <Typography variant="h3" sx={theme.typography.typography2}>
          Πριν από ενενήντα περίπου χρόνια έλαβε χώρα η μεγαλύτερη καταστροφή
          που γνώρισε ο Ελληνισμός. Το Γένος δεν έχασε τότε απλώς ένα τμήμα του,
          αλλά στην κυριολεξία,απώλεσε τον μισό εαυτό του. Σε όλες τις εποχές
          της ιστορίας μας, η Μ. Ασία αποτελούσε το ένα από τα δύο βάθρα του
          Ελληνισμού. Μετά το 1922, έχει μείνει μόνο το ένα, η κυρίως Ελλάδα.{" "}
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Με την παρούσα έκδοση ο Σύλλογος Μικρασιατών της Σκάλας Λουτρών Λέσβου
          φέρνει στην επικαιρότητα, εν μέρει, μέσω ενός αυθεντικού οδοιπορικού
          της Μεραρχίας Αρχιπελάγους, όσα συνέβησαν τότε, κατά τη διάρκεια της
          Μικρασιατικής εκστρατείας.
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Το χειρόγραφο αυτό οδοιπορικό (έχει διατηρηθεί η ορθογραφία του
          πρωτότυπου κειμένου με τις ελάχιστες δυνατές παρεμβάσεις) γραμμένο από
          τον Βρυσσαγώτη έφεδρο ανθυπολοχαγό της Μεραρχίας Αρχιπελάγους Ηλία Ν.
          Κώσση εκτίθεται στο «Μουσείο Προσφυγικής Μνήμης 1922» στη Σκάλα
          Λουτρών δοσμένο από τον Σύλλογο Εκπαιδευτικών Πρωτοβάθμιας Εκπαίδευσης
          Λέσβου «Μίλτος Κουντουράς».
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Μέσα από αυτό το οδοιπορικό, αποσπάσματα του οποίου έχουν μεταφερθεί
          στο παρόν ημερολόγιο, δίδεται στον αναγνώστη από πρώτο χέρι,
          περιληπτικά, όλη η πορεία της Μεραρχίας Αρχιπελάγους τόσο κατά την
          προώθησή της στο εσωτερικό της Μικράς Ασίας όσο και κατά την υποχώρηση
          (σημειώνουμε ότι οι ημερομηνίες που αναφέρονται είναι με το παλαιό
          ημερολόγιο).
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Οι καταδιώξεις, ο διωγμός, η απέλαση, η άτακτη φυγή υπό συνθήκες
          πολέμου, η απώλεια των προσώπων, των υλικών αγαθών, της προγονικής γης
          και της κοινωνικής υπόστασης, είναι το αποτέλεσμα αυτής της μεγάλης
          καταστροφής. Τις ευχαριστίες μας στον Σύλλογο Εκπαιδευτικών
          Πρωτοβάθμιας Εκπαίδευσης Λέσβου «Μίλτος Κουντουράς» για το βιβλίο που
          φιλοξενείται στο Μουσείο μας, στον ιατρό κ. Τιμολέοντα Κουτσουραδή,
          στον Δ/ντή του Ελληνικού N. Geographic κ. Ν. Μάργαρη και στον
          Μυτιληνιό κ. Θεόδωρο Πελεκάνο για το φωτογραφικό υλικό που μας
          παραχώρησαν με ιδιαίτερη προθυμία από τα αρχεία τους, καθώς και στον
          καθηγητή-φιλόλογο κ. Παναγιώτη Αλεξέλλη για την επιμέλεια των κειμένων
          του ημερολογίου αυτού. Χαμένες πατρίδες, τι θα απογίνετε σαν φύγουν
          από τη ζωή και οι τελευταίοι που δακρύζουν ακόμη για σας;
          <br />
          <br />
          <i>
            Το Διοικητικό Συμβούλιο του Συλλόγου Μικρασιατών
            <br />
            της Σκάλας Λουτρών Λέσβου
          </i>
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Ο Μικρασιατικός Ελληνισμός
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Ας απλωθή ο νους και η ψυχή μας εκεί κάτω στην χώρα της θυσίας και του
          πόνου, του ολέθρου και του σπαραγμού, την αιματοποτισμένη και
          κοκκαλοσπαρμένη, εκεί κάτω, εις την «μεγάλην Ελλάδα του μεσαίωνος»,
          όπως την ονόμαζε ο αείμνηστος Παπαρρηγόπουλος, και ας τη γνωρίσωμε
          τώρα, αφού δεν ηθελήσαμε να την γνωρίσωμε εις τις ημέρες του
          ανθίσματος της.
          <br />Η <b>«μεγάλη Ελλάς»</b> έγραφε ο Ιστορικός του Ελληνικού Έθνους,
          και συνεπλήρωσε ο Γάλλος υπερασπιστής των Ελληνικών δικαίων Ρενέ Πυώ —
          όταν ευρέθη μεταξύ των διανοουμένων Μικρασιατικών κύκλων την 16ην
          Φεβρουαρίου 1919— η «Χώρα των Ελλάδων». Και ήτο πράγματι η «Χώρα των
          Ελλάδων». <b>Η σκλάβα χώρα που δεν εγνώρισε τύραννο</b>. Γι’ αυτό και
          ο κατακτητής την έλεγε με μίσος «γιαούρισσα». Aν και ζούσε η
          Μικρασιατική Ελλάδα κάτω από το ακονισμένο σπαθί του Τούρκου, όμως η
          ψυχή της ήτανε ελεύθερη και ο παλμός της πάντοτε ένας και
          συγκινητικός. Η Ελλάδα. Τόσον σπαρταριστά Ελληνική ήτανε η Μ. Ασία,
          ώστε η ελεύθερη γωνία δεν είχε κανένα φόβο, κανένα τρόμο για την
          απώλεια του εθνισμού και της κυριαρχίας της απέναντι των Τούρκων.
          <br />
          Οι Γενικοί Πρόξενοι επήγαιναν εκεί κάτω στα «Μικρασιατικά θλιμμένα
          ακρογιάλια», για να επιδείξουν περισσότερο τον εαυτό τους παρά την
          στοργή και την λαχτάρα του επισήμου Κράτους… …Ό,τι λοιπόν ακμαίο,
          φωτεινό, επιβλητικό, εδημιουργήθη εις την Ιωνίαν εις το εμπόριο, τα
          γράμματα, τις τέχνες, τη βιομηχανία, τα επαγγέλματα, τη γεωργία, το
          θρήσκευμα, την εκπαίδευση, είναι αποτέλεσμα της ικανότητος και του
          αγνού πατριωτισμού των Μικρασιατών.
          <br />
          Δεν εζήτησαν τίποτε από την ελευθέρα γωνία, για να επιβληθούν ως
          άτομα, ως έμποροι,, ως επιστήμονες και βιομήχανοι. Εδημιούργησαν ένα
          υπέροχο πολιτισμό,, μίαν ανθούσα Ελλάδα, για να την προσφέρουν ως
          θαυμαστό αδάμαντα εις το «ελεύθερον Βασίλειον». Από την Ιωνία
          εξώρμησαν κατά καιρούς οι σοφοί του Γένους και κατέπληξαν τον κόσμο.
          Εκείθεν το φως, η αγάπη, η λατρεία, η επιστήμη, αι τέχναι. Η πίστις, η
          αγάπη προς το Ελληνικόν Γένος, ο αιωνόβιος πολιτισμός μας, ώθησε τον
          Πανταλέοντα Σεβαστόπουλον εις το να ίδρύση τω 1720 την Ευαγγελική
          Σχολή. Και μόνον η μνημόνευση του έτους αυτού αρκεί δια ν’ αποδείξη
          την Μικρασιατική υπεροχή εις τα γράμματα. Την ονόμασαν Ευαγγελικήν,
          διότι οι Τούρκοι επέτρεπον τότε μόνον την διδασκαλίαν του Ευαγγελίου.
          Οι ιδρυταί της, υποπτευόμενοι τις Τουρκικές καταδιώξεις, την έθεσαν
          υπό την προστασία της Αγγλίας, και η προστασία αύτη διετηρήθη μέχρι
          των ημερών της καταστροφής. <br />Η Ελληνική γλώσσα εν Σμύρνη τόσον,
          ήτο εν χρήσει εις τον προφορικό και γραπτό λόγο, ώστε εις την μεγάλη
          βιβλιοθήκη του πολυμαθούς δημοσιογράφου Γ. Υπερίδου, εκ των διευθυντών
          της ιστορικής «Αμαλθείας», υπήρχε ο κανονισμός του καθολικού
          νοσοκομείου «Άγιος Ιωάννης» συντεταγμένος την{" "}
          <b>4ην Δεκεμβρίου 1848</b> εις γλώσσαν Ελληνική με λατινικούς
          χαρακτήρες. Ο Τύπος, αν και ευρίσκετο διαρκώς υπό τα δεσμά των
          Σουλτανικών λογοκρισιών, εξεδίδετο από του 1830 και διεφώτιζε τον
          Ελληνικό κόσμο. Από του 1830 μέχρι του 1837 εξεδίδοντο διάφορα
          περιοδικά, από δε του 1838 εξεδίδετο η πρώτη Ελληνική εφημερίδα
          «Αμάλθεια», καθ’ ην στιγμήν εις το ελεύθερο Βασίλειο ημερησία
          εφημερίδα εξεδόθη ο «Αιών» και η «Ελπίς» μετά το 1877. Το πρώτον εν
          Σμύρνη Ελληνικό τυπογραφείο ιδρύθη υπό του Δαμιανού τω 1830, αμέσως δε
          κατόπιν ιδρύθησαν και άλλα τυπογραφεία, εις τα οποία εξετυπούντο
          περιοδικά και βιβλία. Έκαστος δύναται να εννοήση ότι η πνευματική
          εκπαίδευσις των Μικρασιατών ήτο ασυγκρίτως ανωτέρα από τα άλλα
          υπόδουλα μέρη, αφού το πρώτον σχολείον των ελειτούργησε κατά το 1720,
          δηλαδή εκατό και ένα έτος προ της επαναστάσεως του 1821.
          <br />
          <br />
          <i>
            Εισαγωγή από το βιβλίο του Μιχαήλ Ροδά <br />
            «Η Ελλάδα στη Μικρά Ασία – Μικρασιατική καταστροφή»
          </i>
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΙΑΝΟΥΑΡΙΟΣ – 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους(Μάρτιος - Απρίλιος
          1921).
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Η κλάσις μου (1913Α) επιστρατεύθη την 14ην Μαρτίου 1921. Την 14ην
          Μαρτίου 1921 ανεχωρήσαμεν εκ Βρισσάς μετά του Ιωάννη Ιατρέλλου και
          Ευαγγέλου Συγιώργη. Καθ όλην την ημέραν παραμέναμεν εις Πολυχνίτον
          ελείψει μέσου συγκοινωνίας μετά των Παπαθεοδώρου, Δημητρίου Καραμάνου
          και Ιωάννου Αποστολίδου. Κατά την 3ην πρωινήν ανεχωρήσαμεν με εν
          τουρκικόν αυτοκίνητον. Νυξ σεληνοφώτιστος. Την 12ην και 13ην Μαρτίου
          παρουσιασθέντες εις το Φρουραρχείον, παρεμένομεν εις Μυτιλήνην
          αναμένοντες ατμόπλοιον ίνα αναχωρήσωμεν εις Σμύρνην. Την 14ην
          επιβιβάσθημεν ατμοπλοίου και ανεχωρήσαμεν μέσον Δικελή διά Σμύρνην.
          Κατά την άφιξιν μας εις Δικελή οι επίστρατοι διασκέδαζον με την
          μουσικήν, κατενθουσιασμένοι δε επιβιβάσθησαν επί του ατμοπλοίου μας.
          <br />
          Την <b>15ην Μαρτίου</b> αφίχθημεν εις Σμύρνην παρουσιάσθημεν εις το
          Φρουραρχείον, το οποίον μας ετοποθέτησε εις τα έμπεδα της ΙΙ
          Μεραρχίας. Την 16ην Μαρτίου ανεχωρήσαμεν εκ Σμύρνης διά μέτωπον. Την
          17ην Μαρτίου διήλθομεν κόλπον Αδραμυτίου, ένθα παρεμείναμεν επί
          δίωρον. Την 18ην Μαρτίου εισπλέωμεν τα στενά των Δαρδανελίων και
          θάλασσαν Μαρμαρά. Προ της εισόδου των Δαρδανελλίων παρατηρούμεν
          πλείστα ατμόπλοια των συμμάχων βυθισμένα υπό τορπιλών. Κατά τον
          είσπλουν των στενών ένθεν και ένθεν τα φρούρια Σιδίλ Μπαχρ,
          Τσανάκ-Καλέ κλπ με τα πυροβόλα, κατεχόμενα πλέον, φρουρούμενα υπό των
          συμμάχων. Επίσης παρατηρούμεν πλείστα μνημεία των συμμάχων και το
          νεκροταφείον του Αγνώστου Στρατιώτου. <br />
          Την <b>19ην Μαρτίου</b> αφίχθημεν εις Μουδανιά και παρουσιάσθημεν εις
          το Φρουραρχείον. Εις Μουδανιά αναμέναμεν επι 2 ημέρας αποστολήν ίνα
          συνοδεύσωμεν εις το μέτωπον. Την 21ην Μαρτίου με το Λοχαγόν κ.
          Ταβουρτζίκαν και Ανθ/γόν κ. Φερεγγίδην συνοδεύσαμεν αποστολήν 500
          στρατιωτών δια Προύσσαν, αποχωρισθείς με τον Ιωάννη Ιατρέλλη και
          Ευάγγελον Συγιώργη. Καθ’ οδόν ούτοι διήλθον δι’ αμάξης κατευθυνόμενοι
          εις Προύσσαν. Μετά πορείαν 35 χιλιομέτρων εφθάσαμεν εις Προύσσαν το
          μεσονύκτιον υπό βροχήν. Ετοποθετήσαμε τους άνδρας εις οικήματα, ημείς
          δε μετά οκτώ ετέρων Αξιωματικών εμείναμε εις την οικίαν του
          οδοντιάτρου το λυκαυγές μας απεστάλη διαταγή αναχωρήσωμεν επειγόντως
          κατά την 6ην πρωινήν.
          <br />
          Την <b>22αν</b> Μαρτίου κατήλθωμεν εις τον σταθμόν Προύσσης προς
          αναχώρησην εις το μέτωπον. Εις τον σταθμόν αναζητήσας το κιβώτιον μου
          δεν το ανεύρον, ευτυχώς μετά πολύωρον αναζήτησιν το ανεύρον εις την
          Στρατιωτικήν Διοίκησιν Προύσσης. Κατά την πορείαν μας καθ’ οδόν,
          κατήρχοντο πολλοί πρόσφυγες, ελεεινοί, ρακένδυτοι καταφοβισμένοι κατά
          πολύ, με τας αποσκευάς των, νέοι, γέροντες, γυναίκες, νήπια, φεύγοντες
          τας ορδάς του Κεμάλ. Διαταγή Στρατηγού Βλαχοπούλου καταυλισθούμε παρά
          τη Κεστέλ. Μετά δίωρον ανάπαυσιν εχωρίσθη η φάλαγξ εις δύο ομάδας, η
          μία κατευθύνθη προς την Χ Μεραρχίαν παρά τω Ερμενλή Σέλους, ή δε
          δευτέρα προς την VII παρά τω Άκ Σου. Μετά πορείαν 40 χιλιομέτρων
          εφθάσαμε εις Ακ Σου και καταυλισθήκαμε εις αντίσκηνα υπό ραγδαίαν
          βροχήν. <br />
          Την <b>23ην Μαρτίου</b> παρουσιάσθημεν VII Μεραρχίαν η οποία με
          ετοποθέτησε εις το 4ον (22ον) Σύνταγμα Αρχιπελάγους. Παρουσιάσθην εις
          το Σύνταγμά μου και ετοποθετήθην εις το 3ον Τάγμα. Μας οδήγησαν οι
          σύνδεσμοι εις το Τάγμα εις Τίμπος. Την 24ην ετοποθετήθην 11ον λόχον.
          Διετάχθημεν επενέλθωμεν Μανδαλάκιο. <br />
          Την <b>25ην Μαρτίου</b> ετοποθετήθη λόχον κ. Βασιλικιώτης. Την 26ην
          Μαρτίου παρέλαβεν διοικητής λόχου ο κ. Χρυσοβέργης παρά του κ.
          Ιερωνυμά αναλαβών Υπασπιστής Μεραρχίας Πεζικού. Την 27η, 28η, 29η, 30η
          και 31η Μαρτίου οργανώναμεν τομέα μας. Την 1ην και 2αν Απριλίου
          παραμείναμεν Μανδαλάκιο. Την 4η και 5η Απριλίου γυμνάσια και βολή.
          Συνηντήθην με τον Θεοχάρην Ιατρέλλην. Την 10η Απριλίου αρχίσαμε
          οργάνωσιν τομέως οδεύσεις, χαρακώματα, συρματοπλέγματα. Την 11η
          Απριλίου των Βαϊων ο λόχος μας κατεγίνετο εις έργα. Την 18η Πάσχα.
          Ψήσιμο σούβλας, διασκέδασις λόχου. Την νύκτα ήρχισαν τα φυλάκια
          πυροβολούντα δια το Χριστός Ανέστη.
          <br />
          Την <b>19η μέχρι 27η Απριλίου</b> πρώτην γραμμήν. Την <b>28η</b>{" "}
          διετάχθημεν ενεργήσωμεν επιθετικήν αναγνώρησιν κατά του Καρά Κιό.
          Συνελάβαμε δύο αιχμαλώτους των γνωστών τραπέντων εις φυγήν. Την 29η
          έρευνα εις το χωριόν Αγιάσμα εγκαταλειφθέν υπό των Τούρκων.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΦΕΒΡΟΥΑΡΙΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Ιούνιος -
          Ιούλιος1921).
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>1ην Ιουνίου 1921</b> Λόχος μου διετάχθη αναλάβη έργα εις
          κέντρον αντιστάσεως «Μουσταφά» αντικαταστήσας 2ον λόχον. Από 2ην έως
          11ην Ιουνίου Λόχος επεδίδετο εις έργα κέντρου αντιστάσεως, χαρακώματα,
          συρματοπλέγματα κλπ. Την 12ην Ιουνίου αντικατεστάθημεν παρά της 3ης
          Μεραρχίας, ετέθημεν εις πορείαν και κατασκηνώσαμεν εις το 7ον
          χιλιόμετρον Βορείως Προύσσης παρά το δάσος, παραμονή ανάπαυσις μέχρι
          της 20ης. Την 24ην Ιουνίου αφίχθη εις Τσόγγαρα η 10η Μεραρχία και
          συνήντησα τον αδελφό μου, το Γιάγκο Γιατρέλλη, τον Ευαγ. Συγιώργη, το
          Σβ. Νικέλλη κλπ. μαζί με τον Σπύρο Γιανάκα. Την επομένη μας εγνώσθη
          ότι άρχισεν η επίθεσις.
          <br />
          Την <b>25ην Ιουνίου</b> η Μεραρχία μας διετάχθη προελάση 3ην πρωινήν,
          δια της οδού Τσόγγαρα Κιουταχείας υπό καύσωνα αφόρητον. Συνάντησις
          εχθρού παρά το Ακτσέ Κιό. Κατόπιν αψιμαχίας εχθρός ετράπη εις φυγήν.
          Απώλειαι ημετέρων 7 ίπποι νεκροί και 3 στρατιώται τραυματίαι. Εχθρός
          έσχεν πολλάς απωλείας. Οδός προς Ακτσέ Κιό ελικοειδής. Το Μπαξέ Κιό
          επυρπολήθη παρά των ημετέρων. Καταυλισμός εις Μπαξέ Κιό την 11ην μ.μ.
          μετά πορείαν 19 ωρών. Αποσκευαί εβράδυνον να έλθουν (ετραβήξαμε κρύο
          φοβερό). Την 26ην Ιουνίου Τάγμα μας πρωτοπορία. Λόχος μου δεξιά
          πλαγιοφυλακή. Πορεία από όρος σε όρος με δυσβάτους χαράδρας.
          Διετάχθημεν επιτεθώμεν εναντίον γεφύρας κατεχομένης παρά του εχθρού.
          Κατάληψις γεφύρας υποχώρησις εχθρού καταστρέψαντος ταύτην. Λόχος μου
          νήστης 48 ώρας, στρατιώται από την πολλή πείνα καθ’ οδόν έτρωγαν
          ρεπάνια. Τάγμα μας κατέλαβε τα πέραν της όχθης υψώματα.
          <br />
          Την <b>27ην Ιουνίου</b> κατά την πορείαν λόχος μου απεκόπη του
          Τάγματος, εστρέψαμεν πορείαν κατά μήκος του ποταμού ίνα διέλθωμεν,
          στρατιώται καταπονημένοι, απώθησης του εχθρού, κατάληψις Μπεζέ Κιό.
          Απεχωρίσθημεν από 10ην Μεραρχίαν κατευθυνθείσαν δια δημοσίας οδού προς
          Κιουτάχειαν. Σύνταγμα μας ανά τα όρη και χαράδρας κατόπιν 14 ωρών
          πορείαν αφίχθη εις Μπελέν Ουλούν. Την <b>28ην Ιουνίου</b> Λόχος μου
          μεμονωμένος πλαγιοφυλακή συνεδέθη με 2ον Τάγμα, μετά πορείαν 10 ωρών
          αφίχθη εις Αβδίν Κιό την 12η μ.μ. Την 29ην Ιουνίου Σύνταγμα εις κύριον
          Σώμα Μεραρχίας. Στρατιώται καθ’ οδόν άρχισαν να μεμψιμοιρούν. Κατά την
          πορείαν εκραγείσα χειροβομβίς εφόνευσε Εσσέν Κιό. Καταυλισμός. Την
          30ην Ιουνίου αναχώρησις 5η πρωινή σειρά Τάγματος 2α πορεία κοπιώδης
          δύσβατος, άφιξις εις Ταουστάν Κιό. Καταυλισμός.
          <br />
          Την <b>3ην Ιουλίου</b> συνάντησις του εχθρού, επίθεσις εναντίον των
          οργανωμένων θέσεών του περί την 8ην πρωινή, λόχος μου προσωρινώς εις
          την διάθεσιν του Συντάγματος. Την 10ην πρωινή εξόρμησις εναντίον
          χαρακωμάτων Σέιν Κρός, κατάληψις 1-2ας γραμμής, κυρίευσις πολλών
          πολυβόλων, πυρομαχικών. Εχθρός ετράπη εις φυγήν, εγκαταλείψας το
          συγκρότημα και πολλούς εχθρούς τραυματίας. Διμοιρία μου φυλάκια πρώτην
          γραμμήν. Την 4ην Ιουλίου καταδίωξις υποχωρούντος εχθρού καθ’ όλην την
          ημέραν άνευ συναντήσεως του. Καθ’ οδόν αιχμαλωτίσαμε πολλούς. Εχθρός
          εγκατέλειψε τραυματίας, αμάξας με πυρομαχικά και πολεμικόν υλικόν
          άπειρον. Διμοιρία μου πλαγιοφυλακή. Διετάχθην συλλάβω ομάδα
          υποχωρούντων στρατιωτών και αμαξών, πολλοί αυτόμολοι. Άφιξις εις
          Σειγκσούν Κιό, και Χαμάμ Κιό. Εις Τερεσί, 9ην μ.μ. καταυλισμός
          προφυλακαί. Λυκαυγές συναγερμός, εχθρός φεύγει πανικόβλητος
          αιχμαλωτίσαμε 120 Τσέτας.
          <br />
          Την <b>5ην Ιουλίου</b> αναχώρησις 9ην πρωινήν, εχθρός υποχωρεί
          φοβούμενος κύκλωσιν, προφυλακαί Τσερκέζ Καρτάζ. Αεροπλάνα μας ίπταντο
          άνωθεν στρατευμάτων μας, εβομβάρδισαν εχθρικάς οργανώσεις. Την{" "}
          <b>6ην και 7ην Ιουλίου</b> μας επληροφόρησαν ότι εχθρός υποχωρεί
          βορείως Εσκή Σεχήρ. Καταδίωξις Ιν. Ενού Αβκίν, Κοβαλίτουν Σεϊγκούν δια
          σιδηροδρομικής γραμμής, κατάληψις Εσκή Σεχήρ εχθρού τραπέντος εις
          φυγήν και οχυρουμένου εις υψώματα βορείως Εσκή Σεχήρ. Την 8ην Ιουλίου
          εχθρός ανασυνταχθείς βομβαρδίζει Εσκή Σεχήρ επιχειρήσας ανακατάληψίν
          του. Μεραρχία μας και δη 22ον Σύνταγμα αντέκρουσε Κεμαλικάς δυνάμεις
          τρέψας αυτάς εις άτακτον φυγήν εις τα όρη. Σύνταγμά μας διετάχθη
          εξακολουθήση καταδίωξιν του, κατελάβαμεν τας οχυράς θέσεις του, και
          εκυριεύσαμεν 3 πυροβόλα Σκόντα και 2 πολυβόλα Μαξίμ και άπειρον
          πολεμικόν υλικόν. Ο εχθρός εγκατέλειψε τα πάντα εις τας θέσεις
          εξορμήσεως του, ως και τους λέβητας ακόμη πλήρης συσσιτίου.
          Αναπτυχθείς κατά την νύκτα έκαμεν αντεπίθεσιν ίνα καταλάβη θέσεις του,
          διμοιρία μου εστάλη ενίσχυσις 3ου λόχου, απεκρούσθη η αντεπίθεσις του
          εκδιώχθη και από τας υπολοίπους γραμμάς του και ετράπη προς Άγκυραν.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΜΑΡΤΙΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Ιούλιος - Αύγουστος
          1921)
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>15ην και 16ην Ιουλίου 1921</b> παραμονή εις Χασάν Μπέη,
          διμοιρία μου φρουρά χωρίου, ανάπαυσις. Μεραρχία μας ονομάσθη Πτερωτή.
          Την 20ην Ιουλίου ονομαστική μου εορτή. Έσφαξα ένα αμνόν και παρέθεσα
          τραπέζι εις όλους τους φίλους μου Αξιωματικούς, διεσκεδάσαμε μέχρι
          πρωίας θαυμάσια. Το κρασί το αγόρασα 15, το ούζο 40 δραχμάς!!! Την
          21ην Ιουλίου μετακίνησις παρά τον σιδηρ. σταθμόν. Την 22ην Ιουλίου
          μετέβην εις Εσκή Σεχήρ. Συνήντησα Χίους συναδέλφους εορτάζοντας την
          Αγίαν Μαρκέλλαν. Διασκεδάσαμε θαυμάσια μέχρι της 2ας πρωινής,
          επληρώσαμε δρχ. 200 τα ποτά και 300 τα όργανα. Ένας Αρμένιος μας
          διηγείτο ότι τον εξισλάμισαν δια της βίας και τον ενύμφευσαν με
          μουσουλμάνιδα και μας εζητούσε πληροφορίας πώς μπορεί να την αφίση και
          να γίνη Χριστιανός. Ήτο δε και άριστος αθλητής. Κατά την διαμονήν μου
          εις Εσκή Σεχήρ συνήντησα τον Σωκράτη Τακτικό εις ένα χάνι και
          καφενείον, το οποίον διατηρούσε. <br />
          <b>Την 25ην Ιουλίου</b> διετάχθην με την διμοιρίαν μου να μεταβώ προς
          επίταξιν κτηνών, ανεχώρησα την 2αν μ.μ. σιδηροδρομικώς, άφιξις εις
          Μποζ Εγκούκ. Επιτάξαμε 64 ίππους. Ανεχώρησα 4ην π.μ. διέλευσις Ακ.
          Μπουνάρ, Κοβαλίτσα, άφιξις εις Ιν. Ενού, όπου συνήντησα Τουρκοκρήτας
          ομιλούντας την Ελληνικήν. Την 28ην Ιουλίου διετάχθη η προέλασις του
          Σαγγαρίου. Αναχώρησις 2αν μ.μ. άφιξις εις Καρά Σούγιουτζακ 12ην.
          Καύσων αφόρητος. Από του Εσκή Σεχήρ κατά την προέλασιν δένδρα δεν
          υπάρχουν. Την 29ην Ιουλίου αναχώρησις 6ην πρωϊνήν, διέλευσις Τσεχτέ
          Ογλού Τσιφλίκ, χωρίον με 20 οικογενείας Ελληνικάς, και μόνον
          γυναικόπαιδα διότι τους άρρενες τους έχουν στρατολογήσει οι Τούρκοι,
          που συνηντήσαμε μέχρι σήμερον. Υπάρχει δε και ναός του Αγίου Γεωργίου.
          Κάποιος στρατιώτης ελήστευσε μιαν Ελληνικήν οικογένειαν, μετά πολύωρον
          έρευναν ανακαλυφθείς εστάλη στρατοδικείον. Την 1ην Αυγούστου προέλασις
          Ουγκός Χαμάμ, Κισμέτ, Εσκή-Μπουρνού, Χαλήλ Μισαά. Διμοιρία μου εστάλη
          προς φρούρησιν του χωριού. Τμήματα του εχθρού ετράπησαν εις φυγήν. Εν
          τω χωρίω υπήρχεν κρήνη με αρχαίας ελληνικάς επιγραφάς. Ησθένησα από
          πυρετόν. <br />
          Την <b>2αν Αυγούστου</b> προέλασις Χαληλ Μπαά 4ην π.μ., άφιξις εις
          Χααγκίν 1ην μ.μ. άνευ συναντήσεως του εχθρού. Την 3ην Αυγούστου
          προέλασις Δουμπρέκ, Καρατζά-Καγιά, Κοτσ-Οβά, κάτοικοι μας υπεδέχθησαν
          θερμώς, διετάχθην με 12 άνδρας καταλάβω λόφους πέραν χωρίου. Την 6ην
          Αυγούστου Την 11ην Αυγούστου προέλασις όχθας Σαγγαρίου. Εχθρός
          υπεχώρησε έναντι όχθην. Διμοιρίαι επιθέσεως 4-1, μας έβαλλε το
          πυροβολικόν, υποχώρησις εις τόπον εξορμήσεως.
          <br />
          Την <b>12ην Αυγούστου</b> από της 9ης μ.μ. ήρχισεν σφοδρός
          βομβαρδισμός. Τάγμα μας παραμένει αριστεράν όχθην. Διαταγή άμα τη
          λήψει παρούσης, τεθώμεν εις πορείαν. Ετέθημεν εις πορείαν, καθ’ οδόν
          ύδωρ δεν υπάρχει, άφιξις εις Μεραρχίαν την 12ην μεσονύκτιον. Εκοιμήθην
          νήστις, βομβαρδισμός εξακολουθεί. Την 13ην Αυγούστου διέλευσις
          Σαγγαρίου. Τάγμα μας εφεδρεία του 25ου Συντάγματος. Εχθρός οργανωμένος
          ανθίσταται. Προχωρούμεν βήμα προς βήμα, νεκροί τήδε κακείσε
          πολυάριθμοι, ίπποι παραμορφωμένοι, τραυματίαι επίσης εγκαταλελειμμένοι
          εις το έλεος του Θεού. Τμήματά μας καθηλώθησαν. Εχθρικόν πυροβολικόν
          βάλλει, αενάως, απώλειαι εκατέρωθεν μέγιστοι, νεκροί παραμένουν
          άταφοι, δυσοσμία αφόρητος. Λόχος παραμένει νήστις επί 2 ημέρας,
          εχθρικόν πυροβολικόν εξακολουθεί βάλλει επί 48ωρον, αιχμάλωτοι
          τραυματίαι μας πληροφορούν ότι ο ίδιος ο Κεμάλ διοικεί αυτοπροσώπως
          την μάχην. Κατά την νύκτα εχθρός επιχειρεί επίθεσιν καταλάβη
          απολεσθείσας γραμμάς του. Αντεπίθεσίς του απεκρούσθη. Παρ’ ολίγον
          αιχμαλωτίσει ΙΙ Τάγμα, απώλειαι εχθρού μέγισται ηριθμήσαμεν περί τους
          200 νεκρούς τομέα μας. Παραμένομεν καθηλωμένοι. Εκ των άλλων Μεραρχιών
          δεν έχομεν εισέτι πληροφορίας. Μετετέθην 7ον λόχον εσπέρας παρέμεινα
          εις το Σύνταγμα. Απώλειαι Αξιωματικών Συντάγματος, περί τους 35 νεκροί
          και τραυματίαι.
          <br />
          <br />
          <b>Διαταγή Συντάγματος : </b>
          <br />
          <i>
            Επ’ουδενί λόγω να επιτρέπηται μετακίνησιν των ανδρών σας προς τα
            οπίσω. Εδώ ένθα ευρίσκωμαι υπάρχουν πολλαί ενισχύσεις, και συνεπώς
            πάση θυσία να μείνητε εις τας θέσεις σας. Ενταύθα ευρίσκεται και ο
            κ. Μέραρχος. Ενθαρρύνατε τους άνδρας σας και σας καθιστώ ατομικώς
            υπευθύνους. Εάν αντιληφθήτε επίθεσιν εχθρικήν να ενεργήσητε
            αντεπίθεσιν διά της λόγχης.
          </i>
          <br />
          <br />
          <i>
            Ταβολανή 2. 7 .21 Γ. Ψάρας. Συν/χης.
            <br />
            Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.
          </i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΑΠΡΙΛΙΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Αύγουστος 1921)
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>14ην Αυγούστου 1921</b> μετέβην εις το Τάγμα 7ον λόχον,
          συνήντησα τον Θεοχάρην, παραμένoμεν εις τας θέσεις εξορμήσεως. Εχθρός
          κατέχει απέναντι υψώματα, ανθίσταται. Τα πυροβόλα του εις την πρώτην
          γραμμή. Τάγμα διετάχθη οπισθοχωρήσει εις την πρoτέραν γραμμήν, καθότι
          ήτο εκτεθειμένον πολύ εις τα πυρά πυροβολικού. Κατά την οπισθοχώρησιν
          το πυροβολικόν του εχθρού ελύσσα, κάθε στρατιώτη και μία οβίδα εις την
          χαράδραν. Εγλύτωσα διά τρίτην φοράν τον θάνατον, μία οβίς βαρέoς
          έσκασε ένα μέτρο δίπλα μου, και με εσκέπασε όλον. Ο Θεοχάρης βλέπων με
          έτσι είπε, πάει ο Ηλίας. Αλλά άμα με είδε μετά παρέλευσιν λεπτών τα
          έχασε. Bρε Ηλία, μου λέγει, είσαι ζωντανός ή όνειρο βλέπω. Το Τάγμα
          καθ’όλην την ημέραν είχε πολλάς απωλείας. Μεραρχία αριθμεί περί τους
          1500 νεκρούς και τραυματίας.
          <br />
          Την <b>15ην Αυγούστου</b> κατά το λυκαυγές έπεσαν αρκετοί πυροβολισμοί
          ενομίσαμεν αντεπίθεσιν των Τούρκων. Μετά παρέλευσιν λεπτών ήρχισαν τα
          πυροβόλα μας πυρά ταχέα κατά την οχύρωσιν του εχθρού, καθώς και του
          εχθρού εναντίον μας. Ο 3ος Λόχος οπισθοχώρησε εις την έδραν του λόχου
          μας. Κατόπιν ενθαρρύνσεων μας ανακατέλαβεν θέσεις του. Εχθρός
          διεκδικεί σπιθαμήν προς σπιθαμήν το έδαφος. Μάχεται μέχρι ενός. Μάχη
          εξακολουθεί. Η χαράδρα, ένθα ευρίσκεται ο λόχος, βάλλεται αενάως παρά
          του εχθρού, προσπαθούντος να ανακαλύψει τα μεταγωγικά, εφεδρείας.
          Αεροπλάνα μας κατοπτεύουν εχθρικάς οργανώσεις. Εχθρικόν αεροπλάνον
          κατοπτεύει κινήσεις μας. Βαρύ πυροβολικόν του προσπαθεί να ανακαλύψει
          πυροβόλα μας. Πυροβολικόν με πυροβολικόν λυσσούν. Πέφτουν κορμιά
          απειράριθμα. Ώρα 3:45΄ γίνεται εξόρμησις προς κατάληψιν οχυρών θέσεων
          του εχθρού. Ένας Λόχος Ι τάγματος εξόρμησε οι λοιποί παραμένουν θέσεις
          των. Βομβαρδισμός εξακολουθεί. ΙΙ Τάγμα διετάχθη ενισχύση το Ι. Λόχος
          μου διετάχθη να φθάση εις το Τάγμα την ημέραν. Μας αντελήφθη το
          εχθρικόν πυροβολικόν, ελύσσαξε, μας τις έστελνε συστημένες εις το
          μέσον του λόχου. Ευτυχώς και ενύκτωσε ειδ’ άλλως θα ήτο ο τάφος όλων
          μας. Τα πυρά πυροβολικού εξακολουθούν. Λόχος μας διετάχθη και πάλιν
          μεταβή εις την θέσιν του, μετ’ ολίγον αφίχθη και το Τάγμα ως και το Ι
          κατέλαβεν προτεραίας του θέσεις. Το 5ον Σύνταγμα κατέλαβε οδοντωτούς
          λόφους. Το 6ον παραμένει εις τας θέσεις του, δεν μπορεί να σηκώση
          κεφάλι από τον βομβαρδισμόν.
          <br />
          Την <b>16ην Αυγούστου</b> το λυκαυγές μας αντικατέστησε το ΙΙΙ Τάγμα.
          Το Τάγμα μας διετάχθη μεταβή εφεδρεία Μεραρχίας, μας επήρε η ημέρα,
          εχθρός ευτυχώς δεν μας αντελήφθη, εφθάσαμε Μεραρχίαν ανεπαύθημεν,
          διότι δεν φθάνουν τα πυροβόλα του εχθρού. Περί την μεσημβρίαν ένας
          Δεκανέας έπιασε ένα λαγό με τα χέρια του, καλός οιωνός, καθαριστήκαμε,
          ένα αεροπλάνο μας είχε προσγειωθή υποστάν βλάβην. Παρεμείναμεν μέχρι
          τις 11:30 μ.μ. Την 17ην Αυγούστου μεσονύκτιον διαταγή εκκινήσωμεν προς
          κωνοειδή λόφον. Εβαδίζομεν όλην την νύκτα εφθάσαμε το πρωί. Ήρχισεν η
          επίθεσις, σειρά Ταγμάτων Ι-ΙΙΙ-ΙΙ καταλάβαμε διά της λόγχης
          Ι-ΙΙ-ΙΙΙ-ΙΙΙΙ σειράν οργανώσεων του εχθρού. Εχθρός ετράπη εις φυγήν,
          πυροβολικόν μας βάλλει εναντίον φεύγοντος εχθρού, απώλειαι αρκεταί
          εκατέρωθεν. Συνεδέθημεν με την ΙΙΙ Μεραρχίαν. Τάγμα μας διετάχθη
          καταλάβη αριστερόν ΙΙΙ Τάγματος. Νήστις όλην την ημέραν 20 ώρες επί
          ποδός. Ευτυχώς μας ήρθεν την νύκτα φαγητό. Την 18ην Αυγούστου διαταγή
          καταλάβωμε δεσπόζοντα άτινα κατέχονται υπό του εχθρού δυτικώς Πολατζή.{" "}
          <br />
          Ώρα 5μ.μ. Διμοιρία μου ανιχνευταί. Επί κεφαλής Διμοιρίας μου εγένετο
          εξόρμησις, μας άρχισε εχθρός με το πυροβολικόν μας έβαλλε τα τμήματά
          μας. Απώλειαι λόχου 2 νεκροί 7 τραυματίαι. Μάχη εξακολουθεί.
          Επετέθημεν και εναντίον των άλλων υψωμάτων, εκπορθήσαμεν τον εχθρόν,
          τραπέντα εις άτακτον φυγήν βορείως Πολατζή. Τη βοηθεία της Παναγιάς
          εγλύτωσα και αυτήν την μάχην. Το πυροβολικόν μας τους ερήμαξε Το
          Πολατζή καίεται. Τάγμα μας εσπέρας εφεδρεία. Εχθρός οχυρούται βορείως
          Πολατζή. Κάμπος γεμάτος νεκρούς. Την 22αν Αυγούστου 8:40΄ ήρχισε
          γενική επίθεσις. Εχθρός υποχωρεί. Παρουσιάσθησαν 3 αυτόμολοι
          αγγελιοφόροι Τουρκικής Μεραρχίας. Μας επληροφόρησαν ότι η 17η Τουρκική
          Μεραρχία διελύθη. ότι ο στρατός πολεμά με βίαν των αξιωματικών των,
          διότι όταν οπισθοχωρούν, τους σκοτώνουν. Εις πολλά τμήματα, φονεύοντες
          τους αξιωματικούς των, λιποτακτούν. Δύναμις Τάγματος 300 άνδρες. Η Χ
          Μεραρχία αντικατέστησε ΙΙΙΧ, παραμονή εις τα υψώματα, το κανόνι
          βροντά, προφυλακαί μάχης αιχμάλωτοι 1300 + 700, αξιωματικοί 70.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΜΑΙΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Αύγουστος 1921).
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>25ην Αυγούστου</b> επληροφορήθημεν ότι όπισθεν μας μια Τουρκική
          Μεραρχία εβάδιζε να κυκλώση 9ην Μεραρχίαν μας. Αεροπλάνον αντιληφθέν
          κινήσεις της ειδοποίησε τμήματά μας. Ένα Σύνταγμα εκρύβη εις μίαν
          χαράδραν, τα άλλα εξακολούθουν πορείαν των. Μόλις διήλθεν η Τουρκική
          Μεραρχία της επετέθησαν τα δύο Συντάγματα και το έτερον εκ των όπισθεν
          τους διέλυσαν δε κυριολεκτικώς. Προηγουμένως κατά την πορείαν της η
          Τουρκική Μεραρχία συναντήσασα καθ’ οδόν την εφοδιοπομπήν τους
          κατέσφαξαν, παρ’ όλίγον δε αιχμαλώτίσουν τον διάδοχον, τον Στρατηγόν
          Παπούλαν. Ευτυχώς απέφυγον τον κίνδυνον. Κεμάλ διαδίδει εις τα
          στρατεύματά του ότι όσους συλλαμβάνουμε αιχμαλώτους, τους σφάζουμε,
          και ούτω τους ενσπείρει τον πανικόν να μη λιποτακτούν. Συσσίτιον
          έρχεται κάθε 24ωρον. Οι στρατιώται από την πείνα βράζουν σιτάρι και
          τρώνε. Διετάχθην μεταβώ Σεϊντζίκ, επιτάξω πτυοσκαπάνας διά την
          κατασκευήν οχυρώσεων.
          <br />
          Την <b>27ην Αυγούστου</b> καταλάβαμε θέσεις εις τα χαρακώματα, είμεθα
          έτοιμοι, αναμέναμεν επίθεσιν από στιγμήν εις στιγμήν, αλλά ματαίως,
          δεν ετόλμησαν. Καθ’ όλην την νύκτα άϋπνος τυλιγμένος σε μια κουβέρτα.
          Μας έβαλλε το πυροβολικόν του εχθρού. Τραυματίαι 7ου Λόχου 10. Την
          28ην Αυγούστου δεξιά και αριστερά μας το κανόνι λυσσά. Τούρκοι έκαναν
          αντεπιθέσεις καταλάβουν θέσεις μας. Γενομένης εφόδου διά της λόγχης
          ετράπησαν εις φυγήν εγκαταλείποντας πολλούς νεκρούς και τραυματίες.
          Κατέλαβαν πετρώδη λόφον. Τον ανεκατέλαβαν οι δικοί μας. Την νύκτα το
          τουφέκι άναβε, άρχισαν να επιτίθενται τα τμήματά μας, με το αθάνατον
          Αέρα!!! Ξανά σαλπίζουν οι σάλπιγγες εμπρός διά της λόγχης!!
          Πανζουρλισμός. Δεξιά και αριστερά μας Τούρκοι έκαμαν αντεπίθεσιν που
          απεκρούσθη. Το 37ον Συν/μα υπεχώρησεν, Τούρκοι κατέλαβον Διδύμους
          λόφους. Περί την 1μ.μ ήρχισαν να κατέρχωνται εκ Πολατζή μεγάλαι
          δυνάμεις του εχθρού, επιχειρούντος να δώση το τελευταίο ατού του.
          Κρίνεται περί των όλων αγών, προς στιγμήν ολίγον να χάσω το ηθικόν
          μου, διά να είμαι ειλικρινής διότι αί δυνάμεις ευάλωτοι, το
          πυροβολικόν μας έβαλλε ανεπιτυχώς, εχθρός εκρύβη όπισθεν των υψωμάτων,
          και ήρχισαν να κατέρχωνται αριστερά μας, ίνα μας αποκόψουν, αλλά δεν
          το κατώρθωσαν. Ήρχισαν αμέσως να κινούνται αι φάλαγγες μας με
          αφάνταστον ηρωϊσμόν, καταλάβωμεν θέσεις εις τα χαρακώματα. Είμεθα
          έτοιμοι εις θέσιν αναμονής. Οι Τούρκοι επιχείρησαν καταλάβουν
          οργανικάς θέσεις μας, τους απεκρούσαμεν, κατόπιν τους επετέθημεν και
          τους εκδιώξαμεν από όλας τας γραμμάς μας, συνελάβαμεν πολλούς
          αιχμαλώτους, απώλειαι νεκροί και τραυματίαι εχθρού τεράστιαι.
          Πληροφορίαι παρά αιχμαλώτων ότι τους έχουν νήστεις και ανυποδήτους,
          τους κακοποιούν. Δύναμις Λόχου των 50-60 άνδρες, έναντι μας η 23η και
          1η Τουρ. Μεραρχίαι. Αεροπλάνα μας έρριψαν προκηρύξεις, και τους
          στρατιώτας οι οποίοι τας συνέλεξαν τους εκρέμασαν. <br />
          Την <b>29ην Αυγούστου</b>, περί το λυκαυγές επεχείρησαν καταλάβουν
          πάλιν Πετρώδη Λόφον, ήρχισαν τα πυροβόλα εκατέρωθεν να λυσσούν.
          Διετάχθη επίθεσις. Λόχος μας τους έβαλλε πυρά πλευρικά, τους ετσάκισε.
          Εχθρός ήρχισε αμέσως να υποχωρεί πανταχόθεν. Τα πυροβόλα λυσσούν
          βρήκαν το στόχο. Ετούτο να είχε ένα μάτι κανείς να παρακολουθή. Τους
          τσάκισε κυριολεκτικώς, τους εσκόρπισε μέσα στο κάμπο, συνελάβαμε περί
          τους 100 αιχμαλώτους. Νεκροί τραυματίαι εγέμισεν ο κάμπος. Λόχος μου
          κατέλαβε δεσπόζον σημείον του κάμπου. Εχθρός υπεχώρησε προς βορράν.
          Πληροφορίαι αιχμαλώτου ότι εμάχετο μαζί και η πρώτη Τουρκική Μεραρχία
          επιλέκτων. Αριστερά τμήματά μας ανεκατέλαβον Διδύμους λόφους. Δύο
          Τουρκικαί Μεραρχίαι διελύθησαν. Απώλειαι μας αρκεταί. Εγκαταστήσαμε
          προφυλακάς μάχης. Λόχος μας κατέχει επικίνδυνον σημείον, με παραμικράν
          επίθεσιν είμεθα όλοι μας χαμένοι. Ευτυχώς οι Τούρκοι δεν αντελήφθησαν
          την κατάστασιν. Οι Έλληνες εθριάμβευσαν. <br />
          Την <b>30ην Αυγούστου</b> διετάχθημεν να είμεθα έτοιμοι. Παρεδόθη ένας
          τραυματίας 40 ντούτης. Πληροφορίαι 57η Μεραρχία διελύθη. Ότι έχουν
          μεγάλας απώλειας, τους στρατολογούν διά της βίας. Είναι ρακένδυτοι και
          νήστεις. Εις Πετρώδη λόφον παρεδόθη ένας Λόχος, αφού πρότερον
          εφόνευσαν όλους τους Αξιωματικούς των. Το πυροβολικόν τους έβαλλε
          ανεπιτυχώς. Εχθρός επεχείρησε επίθεσιν όπως και την προτεραίαν
          επιτίθεται κατά φάλαγγας. Λόχος μας παρ’ ολίγον αιχμαλωτισθή, Τούρκοι
          περί τα 100 μέτρα. Μάχη δεξιά και αριστερά εξακολουθεί.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΙΟΥΝΙΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Σεπτέμβριος 1921)
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>31ην Αυγούστου 1921</b> κατά την 12ην διετάχθησαν όλα τα
          τμήματά μας να υποχωρήσουν πέραν της γεφύρας του Σαγγαρίου. Όλα τα
          τμήματά μας υπέχωρησαν λόχος μου έμεινε τελευταίος. Εχθρός, αν μας
          αντιλαμβάνετο ήθελε μας τσακίση. Περί την πρωϊαν είχομεν διέλθη τον
          ποταμόν οι τελευταίοι. Καταστρέψαμεν όλας τας γεφύρας. Πέραν του
          ποταμού συνηντήθημεν με τον Δημ. Συγιώργη. Πορεία από της 12
          μεσονυκτίου, άφιξις 2μ.μ. εις Δεμιρτζή. Έχομεν απογοητευθεί προς
          στιγμήν. Μετέβην εις την 10ην Μεραρχίαν, έμαθα για το Στρατή και ότι
          ετραυματίσθη ο Γιώργος Γιατρέλης. Περί την 11ην διετάχθη Τάγμα μας
          εγκαταστήσει προφυλακάς, επειδή ενεφανίσθησαν Τσέται. <br />
          Την <b>1ην Σεπτεμβρίου</b> παραμένουμεν προφυλακάς. Στις 9:00 διαταγή
          Τάγμα μας εκινήση οπισθοφυλακή όλων των τμημάτων. Συνήντησα τον Στράτη
          το Γιατρέλη Θ. και 2 Κουνήδες. Έμαθα τον θάνατον του Π. Πελέκου, Σ.
          Μπάσσου, Ι. Μπάσσου, Μ. Αναγνώστου, Δ. Καφαλούκα, Αϊβαλιώτη Παν,
          Γεωργακή και Καφαλούκα. Περί την 8ην καταυλισθήκαμε νοτίως Πουρσάκ
          Ποταμού, παρά τον Σιδηροδ. Σταθμόν. Κατά την πορείαν σκόνη πάρα πολλή.
          Καταστρέψαμε την Σιδηροδρομική γραμμή και όλας τας γεφύρας.
          <br />
          Την <b>5ην Σεπτεμβρίου</b> παραμονή εις Εσκή Σεχήρ, ήρχισαν αι
          ανατινάξεις των σιδηρ. γραμμών, ενόμιζε τις εκ των ανατινάξεων ότι τα
          πυροβόλα έβαλλον πυρά ομαδόν. Μετακίνησις διμοιρίας μου βορείως Καζ
          Νταγ. Οργάνωσις του τομέως. Εντεύθεν αρχίσαμε να βλέπουμε δένδρα.
          Μέχρι στιγμής ούτε ένα δένδρο συνηντήσαμε. Ανέλαβα προσωρινώς
          Διοικητής Λόχου. Οργάνωσις τομέως.
          <br />
          Την <b>6ην και 7ην Σεπτεμβρίου</b> ουδεμία διαταγή. Παρέλαβε τον Λόχον
          ο κ. Ζαμπετάκης. Διετάχθημεν είμεθα έτοιμοι δι’ εκίνησιν ώρα 6μ.μ.
          Αέρας, δυνατό κρύο, εβαδίζαμε όλην την νύκτα και μέχρι της 2μ.μ. της
          επομένης. Πως αντέχουν οι στρατιώται θαύμα, διήλθομεν διάφορα χωριά
          πυρπολούμενα, εγκατάστασις προφυλακών Ντερέ Κιό. Την 17ην Σεπτεμβρίου
          παραμονή Μουταλίπ, διετάχθη το ΙΙΙ Τάγμα συν 7ου Λόχου μετά
          πυροβολικού και μηχανικών μεταβή αναγνώρισιν Σαγγάριον προς
          εκκαθάρισιν του εδάφους και καταστροφήν των γεφυρών. Καιρός βροχερός,
          ήλθε και με συνήντησε ο Γιάννης Χιωτέλλης. Την 18ην Σεπτεμβρίου καθ’
          όλην την νύκτα χιονοθύελλα, προς στιγμήν εβελτιώθη. Μετά παρέλευσιν
          ωρών καιρός ο αυτός.
          <br />
          Την <b>22ην Σεπτεμβρίου</b> παραμονή Μουταλίπ. Επόπτης περιπολιών, το
          Φρουραρχείον εξέδιδε αδείας των χωρικών μεταβαινόντων εις μύλον δι’
          άλεσμα, μας επεριποιούντο θαυμάσια. Με τον Ταγματάρχην του ΙΙΙ
          Τάγματος ησχολήθημεν εις την καταγραφήν και επίταξιν των οικιών,
          προκειμένου να επισταθμεύση η Μεραρχία. Την 12ην ώραν ήλθε επείγουσα
          διαταγή της Μεραρχίας να ετοιμασθή το Σύνταγμά μας καθώς και ο λόχος
          μου προς καταδίωξιν του εχθρού υποχωρούντος. Κατόπιν λόχος μου
          διετάχθη παραμείνη ως φρουρά του χωρίου και των αρχείων του
          Συντάγματος. Το πυροβολικόν βάλλει τα υψώματα Μποζ Ταγ. Εχθρός
          απεκρούσθη υποχωρήσας ατάκτως. Αφίχθη η Μεραρχία την 5ην καθώς και το
          23ον το 37ον κατευλίσθη έξωθεν του χωρίου ως εφεδρεία του Σώματος. Το
          εσπέρας μας ήλθε μουσαφίρης ο Παππάς της Μεραρχίας, το εχαρακτήρισα
          καλόν οιωνόν. <br />
          Την <b>23ην Σεπτεμβρίου</b> παραμονή, εξακολουθεί χορήγησις αδειών.
          Την 3ην μ.μ. διετάχθη μία διμοιρία συνοδεύση τρόφιμα εις το Σύνταγμα,
          προτού αναχωρήση διαταγή Μεραρχίας εις Ουλαμός συνοδεύση Μοίραν
          Ορειβατικού Πυροβολικού. Μετέβη ο κ. Δαρδαμανέλλης, λόχος μας εζήτησε
          ενίσχυσιν Ουλαμού εκ του 37ου Συν/τος, αντικατέστησα τα φυλάκια, τον
          Ουλαμόν κρατήσας εις το Τζαμί δι’ανάγκας του χωρίου, καθότι διάφοροι
          στρατιώται διαφυγόντες των περιπολιών εξεβίαζον τους κατοίκους όπως
          προμηθευθώση διάφορα τρόφιμα. <br />
          Την <b>24ην Σεπτεμβρίου</b> παραμονή εις το δωμάτιον επόπτης
          περιπολικού, η καταδίωξις εξακολουθεί. Την 4ην μ.μ. διετάχθη 37ον
          Συν/μα αναχωρήση εις τας θέσεις ως κατείχε. Αντικατεστάθη Ουλαμός 37ου
          μια διμοιρία φυλάκια μία διμοιρία εν επιφυλακή. Επήλθεν η τάξις εντός
          του χωρίου. Οι κάτοικοι καθ’ εκάστην μας επρομήθευαν γάλα, αυγά,
          γιαούρτι κ.λ.π. Εμείναμε εις το δωμάτιον του παραρτήματος Τζαμιού.
          Εδιόρθωσα το κρεβάτι εκστρατείας και τη σκηνή μου Οι χωρικοί
          κατενθουσιασμένοι εκ της υπηρεσίας. <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΙΟΥΛΙΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Οκτώβριος 1921)
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>28ην Σεπτεμβρίου 1921</b> στις 9:30 αναχωρήσαμεν διά το
          Συνταγμα, μετά πορείαν 2.30 ωρών αφίχθημεν εις Καρατζά, καταυλισθήκαμε
          εις παλαιόν καταυλισμόν. Αφίχθη νέος Ταγματάρχης. Την{" "}
          <b>29ην Σεπτεμβρίου παραμονή</b>> εις Καρατζά. Εζήτησα άδειαν διά Εσκή
          Σεχήρ, δεν μου εδόθη. Έφυγα λάθρα επρομηθεύθην ιματισμόν. Στρατιά
          είχεν αναχωρήση εκ Εσκή Σεχήρ. Επελήφθην ενόρκων προανακρίσεων εις
          στρατιώτας 7ου Λόχου μηνυομένων επί λιποταξία. Την 30ην Σεπτεμβρίου
          ανέλαβε διοίκησιν Λόχου ο κ. Παπαδόπουλος, εξακολουθώ ανακρίσεις.
          Διετάχθημεν να κτίσωμεν υπόστεγα διά τους άνδρας, ησθένησα με
          επεσκέφθη ο γιατρός. <br />
          Την <b>1ην Οκτωβρίου</b> διετάχθημεν μετασταθμεύσωμεν 4 χιλ.
          ανατολικώς Συντάγματος. Επισταθμία εντός του χωρίου, οι άνδρες
          ετοποθετήθησαν εντός των οικιών ημείς παραμείναμεν εις το ύπαιθρον.
          Ψύχος δριμύτατον. Την 2αν Οκτωβρίου επισκευή οικημάτων. Μας εχορηγήθη
          ένας σταύλος, εστεγάσθησαν 3 διμοιρίαι.
          <br />
          Την <b>3ην Οκτωβρίου</b> επισκευή εξακολουθεί, επελήφθην ενόρκων
          προανακρίσεων, Διμοιρία μου μένει υπό αντίσκηνα. Την 4ην Οκτωβρίου
          επισκευή οικιών, έδωσα να μου διορθώσουν τις κυλόττες, είδα τον Παν.
          Γιατρέλλην. Το ένα οίκημα τελείωσε. Καιρός καλός. Την 5ην Οκτωβρίου
          ενέργεια ανακρίσεων, μετέβην πήρα τον μισθόν μου καιρός καλός. Την 7ην
          και 8ην Οκτωβρίου εμβολιασμός λόχου, απολύμανσις ανδρών. Την 10ην
          Οκτωβρίου μετέβην εις Καρατζά όπου παρέμεινε το Συν/μα μας, έκαμε
          διάλεξη ο κ. Ψαράς, επέστρεψα το εσπέρας.
          <br />
          Την <b>11ην έως την 18ην Οκτωβρίου</b> ήρχισαν γυμνάσια ανδρών,
          εγένετο διανομή ιματισμού, ανάπαυσις, καιρός βροχερός. Την 19ην
          Οκτωβρίου μας εκοινοποιήθη διαταγή αναχωρήσωμεν την 20ην ωραν διά
          Σεϊντή Γαζή. Χιονιάς. Την 20ην Οκτωβρίου πορεία 20 χιλιομ. κρύο
          φοβερό, άφιξις εις Τιβεράν. Εκοιμηθήκαμε εις οικήματα, ήλθε ο Δημ.
          Συγιώργης. Την 21ην Οκτωβρίου πορεία, άφιξις εις Σεϊντή Γαζή.
          Παραμένομεν επί μιαν ώραν εις τον κάμπον, έως ότου επιστρέψη η
          επισταθμία, ενεφανίσθη εχθρικόν αεροπλάνον κατά την ώραν της
          παρελάσεως, μας έριξεν δύο βόμβας, ευτυχώς ανεπιτυχώς. Εάν παραμέναμεν
          εις την πρώτην θέσιν μας θα παθαίναμεν πανωλεθρίαν. Μας ετοποθέτησαν
          εις ένα οίκημα Πυροβολαρχίαν 7ον και 5ον λόχον. Εκοιμηθήκαμε εις το
          μπουντρούμι των φυλακών Σεϊντή Γαζή. Την 22αν Οκτωβρίου μετεκινήσαμεν
          τον λόχον εις ένα σταύλον φρίκη, τας αποσκευάς μας τας μεταφέραμε εις
          ένα θάλαμον αρκετά καλόν. Βροχή. Την 23ην Οκτωβρίου Λόχος μου εις
          υπηρεσίαν περίπολοι κ.λ.π. Περιηγήθημεν το Μοναστήριον, εντός του
          οποίου υπάρχει ο τάφος του Σεϊντή Γαζή και της Ερηνούσας ως και πολλά
          αρχαία. Προ της εισόδου ίσταται εις λέων, επί δε της εισόδου υπάρχει η
          κάτωθι Επιγραφή: «Η Βουλή και ο Δήμος των Ναπολέων, πεδίων, Κλαυδιανών
          Νήγηρα Ήρωα Ενδοξότατον, των εις την Πατρίδα ευεργεσιών, αμοιβής
          ένεκα, ετίμησε της Πόλεως, αναστησάσης τον ανδριάντα ιδίων χρημάτων.»
          Την 24ην Οκτωβρίου μετεκινήθημεν πάλιν εις έτερον θάλαμον, κάτωθεν
          σταύλου. <br />
          Την <b>25ην Οκτωβρίου γυμνάσια</b>, ανεχώρησεν 37ον Σύνταγμα
          καταλάβαμεν στρατώνας, ετοποθετηθήκαμεν εις ένα δωμάτιον τέλειον ήθελε
          να το καταλάβη ο κ. Ταγματάρχης, κατόπιν μετεκινήθη εις έτερον. Την
          26ην Οκτωβρίου του Μεγαλομάρτυρος Δημητρίου, ανάπαυσις. Λόχος μας
          αντικατέστησε 7ον εις τα φυλάκια, υψώματα Σεϊντή Γαζή. Πολλά
          χαρακώματα και πτώματα Τούρκων παραμορφωμένα. Την 27ην Οκτωβρίου
          φυλάκια, μας ειδοποίησαν ότι θα κάμη το πυροβολικόν βολήν, καιρός
          βροχερός κρύο φοβερό. Την 28ην Οκτωβρίου ομοίως, μας επεσκέφθη
          εχθρικόν αεροπλάνον έβαλλε άνευ αποτελεσμάτων. Την 29ην Οκτωβρίου
          ερρίφθησαν πυροβολισμοί δεξιά μας, διετάχθην να αναφέρω σχετικώς.
          Ανέφερα ότι ερρίπτοντο παρά του 37ου Συντάγματος. Καιρός ο αυτός. Από
          το αντίσκηνο δεν βγήκα καθόλου. Την 30ην Οκτωβρίου περί την 1:30 με
          αντικατέστησε ο 6ος Λόχος, κατήλθον εις Σεϊντή Γαζή, εις την οικίαν
          του Χότζα. Την 31ην Οκτωβρίου γυμνάσια, διεδόθη ότι θα αναχωρήσωμεν
          εις Αφιόν Καρά Χισάρ, συνηντήθην με τον Μανώλη Π. Γδούτον.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΑΥΓΟΥΣΤΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Νοέμβριος -
          Δεκέμβριος 1921)
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>2αν Νοεμβρίου 1921</b> διετάχθημεν ετοιμασθώμεν δι’αναχώρησιν.
          Εκκίνησις στις 20:00, άφιξις εις Ακ Ιν στις 24:00. Την 3ην Νοεμβρίου
          εκκίνησις την 8ην π.μ., πορεία με βροχήν διερχόμεθα μιαν στενωπόν, επί
          5ωρον υποφέραμε τα πάνδεινα καταυλισθήκαμε στις 20:00. Την 4ην
          Νοεμβρίου εκκίνησις εκ της στενωπού την 9ην π.μ.. Mετα δίωρον πορείαν
          εξήλθομεν της στενωπού. Μακρόθεν είδαμε τον Σιδηροδρομικόν Σταθμόν.
          Άφιξις εις Τουμπρέη στις 16:00 πορεία πολύ ανυπόφορος, καταυλισμός εις
          Σιδηρ. Γραμμή. <br />
          Την <b>5ην Νοεμβρίου</b> πορεία, εκκίνησις στις 8:30. Τάγμα
          εμπροσθοφυλακή Φάλαγγος, Ουλαμός μου ανιχνευταί, εβάδιζον επι κεφαλής
          ανιχνευτών. Άφιξις στις 13:00 εις Τεπετζέ Τεπέ εγκατέστησα προφυλακάς.
          Ουλαμός μου φυλάκια. <br />
          Την <b>6ην Νοεμβρίου</b> παραμονή εις φυλάκια ουδεμία διαταγή ελήφθη.
          Την <b>7ην Νοεμβρίου</b> καθ’ όλην την ημέραν ομίχλη κρύο δριμύτατον,
          τα κάρβουνα δεν έλειψαν διόλου. Την <b>8ην Νοεμβρίου</b> των
          Ταξιαρχών, μετακίνησις καταυλισμού διότι ήτο ορατός εκ μέρους του
          εχθρού. Ήλθεν ο Γεωρ.Σταματινός από την Αθήνα. Την{" "}
          <b>9ην Νοεμβρίου</b> διετάχθημεν μετασταθμεύσωμεν. Πορεία 2ωρος,
          χιονόνερο, επισταθμία εις Σουσούζ Οσμανιέ. Την 10ην έως 12ην Νοεμβρίου
          παραμονή καιρός χιονώδης, μας εκοινοποιήθη διαταγή ν’αναχωρήσωμεν. Την{" "}
          <b>13ην Νοεμβρίου</b> εκκίνησις την 9ην π.μ..Διμοιρία μου έμεινε
          οπισθοφυλακή της Φάλαγγος, αφίχθημεν ειςΠερέν στις 15:00 μοι εδώρησαν
          ένα περιστέρι. Την <b>14ην Νοεμβρίου</b> πορεία διά της αμαξητής οδού
          πλαγίως σιδηροδρομικής γραμμής. Μπάλ ΜαΧμούτ Σινάν Πασσά παραμονή
          Μουσαφίρ Οτασί. Την <b>15ην Νοεμβρίου</b> διετάχθημεν μεταβώ εις
          Κιούρκα προς εφοδιασμόν αχύρου παρά των κατοίκων. Επέστρεψα Σύνταγμα
          μεσημβρίαν. <b>Την 16ην Νοεμβρίου</b> Τάγμα μετεκινήθη εις Κιούρκα.
          Ουλαμός μου φυλάκια. Την 17ην Νοεμβρίου φυλάκια, καιρός χιονώδης. Την
          19ην Νοεμβρίου έριξε χιόνι 60% του μέτρου, εξακολουθεί να χιονίζη. Την
          20ην έως 26ην Νοεμβρίου παγωνιά μεγάλη. Την 30ην Νοεμβρίου μετέβημεν
          όλοι οι Αξιωματικοί εις Σινάν Πασσά, είχαμε διάλεξιν. Την 1ην έως 6ην
          Δεκεμβρίου παραμονή εις Κιούρκα, του Αγ.Νικολάου.
          <br />
          Την <b>7ην Δεκεμβρίου</b> Λόχος διετάχθη 3ην μεσονυκτίου ενεργήση
          αναγνώρισιν εις Τσαϊ Χισάρ. Μετέβημεν 4, εστήσαμεν ενέδρα πέριξ του
          χωρίου μέχρι της 9ης πρωινής. Εφάγαμε μια δροσιά και ένα χιόνι, παρ’
          ολίγον να κοκκαλώσουμε. Την 9ην πρωινήν κατήλθομεν εν τω χωρίω και
          προέβημεν εις γενική έρευναν άνευ τινος αποτελέσματος. Επληροφορήθημεν
          ότι προ 4 ημερών αφίχθησαν 10 τσέται, εζήτησαν πληροφορίες και είτα
          ετράπησαν εις φυγήν εις άγνωστον διεύθυνσιν. Επήραμε μαζί μας 2 εκ των
          κατοίκων διότι μας έδιδον αντιφατικάς πληροφορίας. Επανήλθομεν εις
          Κιούρκα στις 11:00. Την 12ην Δεκεμβρίου το Σύνταγμά μας ανέλαβεν
          επιτήρηση τομέως Χασάν Μπέλ. Τάγμα μας δεξιάν τομέως, Λόχος μου εις το
          Λ.4. Χιόνι ½ του μέτρου. Ευτυχώς είχον κατασκευάσει παράγκα. Τομεύς
          σχεδόν οχυρωμένος μετά μιας σειράς συρματοπλεγμάτων. Ουλαμός μου εις
          την α΄ γραμμήν. Την 13ην Δεκεμβρίου έργα, καιρός καλύτερος. Κατά τας
          πρωϊνάς μόνον ώρας κρύο πάρα πολύ. Την 23ην Δεκεμβρίου χαρακώματα,
          συρματοπλέγματα. Την 24ην Δεκεμβρίου παραμονή των Χριστουγέννων, έξω
          βρέχει, χαλάζι, χιόνι έχει φθάσει ένα μέτρο. <br />
          Την <b>25ην Δεκεμβρίου</b> των Χριστουγέννων χιών εξακολουθεί. Ήπιαμε
          λίγο κονιάκ, κατόπιν επλάγιασα, δεν μου κάνει αίσθηση τίποτα. Αέρας
          εχάλασε τις παράγκες του 6ου. Την 26ην Δεκεμβρίου χιόνι ένα μέτρο,
          παγωνιά. Την 27ην Δεκεμβρίου χιόνι εξακολουθεί έχει φθάσει 1 ½ μέτρο.
          Την 28ην Δεκεμβρίου χιόνια μας έκλεισαν μέσα στ’ αμπρί. Επήρα τη
          διμοιρία μου να ανοίξω συγκοινωνίας, εμάργωσα επί μιαν ώραν, δεν
          μπορούσα να συνέλθω. Την 29ην Δεκεμβρίου η ημέρα ολίγον καλλιτέρα,
          είδαμε ολίγον ήλιο. Τας συγκοινωνίας τας άνοιξαν Τούρκοι, τους ίππους
          ηγγαρεύσαμεν από τα πέριξ χωρία. Την 30ην Δεκεμβρίου χιών, βροχή,
          αέρας. Παραμονή του νέου έτους καιρός ο αυτός.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΣΕΠΤΕΜΒΡΙΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Ιανουάριος -
          Απρίλιος 1922)
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>1ην Ιανουαρίου 1922</b> το νέον έτος. Δεν καταλάβαμε διόλου τας
          εορτάς. Kρύο, χιόνια, όλες τις ημέρες ήμουνα κλεισμένος στην καλύβα.{" "}
          <br />
          Την <b>6ην Ιανουαρίου</b> των Φώτων, επισκέφθην τους φαντάρους στα
          αμπρί των. Ήταν όλα ανεξαιρέτως εν αθλία καταστάσει, το νερό έτρεχε
          από κάτω τους βρύση, υποφέρουν πάρα πολύ. Την 8ην Ιανουαρίου
          ησχολήθημεν εις την διόρθωσιν χαρακωμάτων, ήλθεν αναγνώρισις του 23ου
          Πεζικού Συν/τος. Την <b>10ην Ιανουαρίου</b> περί τις 4:30 ηκούσαμεν
          πυροβολισμούς από τα φυλάκια, ο σκοπός αντελήφθη Τούρκους
          πλησιάζοντας, εσηκώθημεν αμέσως, καθ’ όλην την νύκτα εμείναμεν εν
          επιφυλακή. Την πρωϊαν επί κεφαλής περιπόλων εξήλθομεν των γραμμών μας
          προς αναγνώρισιν. Εις απόστασιν 500 μέτρων ανεύρομεν εν όπλον 6
          ξιφιλόγχας και δύο ζεύγη γάντια εγκαταλειφθέντα υπό των εις άτακτον
          φυγήν τραπέντων Τούρκων. Μας συνεχάρη ο κ. Ταγματάρχης. Από τα ίχνη
          των τσαρουχιών τους υπολογίσαμε έως 40. <br />
          Την <b>12ην Ιανουαρίου</b> αντικατεστάθημεν παρά του 10ου λόχου του
          23ου Συντάγματος. Το Σύνταγμα επιστάθμευσε εφεδρεία εις το χωρίον Ερέν
          Κιό. Την 2αν Φεβρουαρίου λόχος μου ανέλαβε προκάλυψιν Συντάγματος υπό
          σκηνάς. Κατά την 10ην έπιασε δυνατός αέρας, μου έπαιρνε το αντίσκηνο.
          Κατόπιν άρχισε βροχή, το αντίσκηνο το έπαιρνε ο αέρας, καθ’ όλην την
          νύκτα δεν έκλεισα μάτι. Οι κουβέρτες μου εβράχησαν όλες. Περί τα
          ξημερώματα δεν αντέχω πλέον από το κρύο κατέβηκα εις το χωριό, μου
          έφερεν τις κουβέρτες ο υπηρέτης μου, ανάψαμε φωτιά και έτσι συνήλθα
          ολίγον. Το βράδυ έμεινα στο χωριό. Την 6ην Φεβρουαρίου παρουσιάσθησαν
          δύο Τούρκοι αυτόμολοι, εις λοχίας και εις στρατιώτης, κατάστασις των
          ελεεινή. Ρακένδυτοι και λιμώττοντες. Τους οδήγησα εις το Τάγμα. Την
          9ην και 10ην Φεβρουαρίου γυμνάσια Συντάγματος. Την 11ην Φεβρουαρίου
          διετάχθημεν αντικαταστήσωμεν ΙΙΙ Τάγμα εις Αφιόν Καραχισάρ. Φυλάκια
          6ος λόχος, καιρός καλός. Την 11ην Μαρτίου συμπλήρωσις δωδεκαμήνου από
          της αναχωρήσεώς μου εκ του χωρίου εις τον Στρατόν. Την 12ην Μαρτίου
          διά του ασυρμάτου ελάβομεν είδησιν ότι Υπουργοί των τριών Μεγ.
          Δυνάμεων συνήλθον εις συνδιάσκεψιν Ανατολικού προτείναντες κατά πρώτον
          τρίμηνον ανακωχήν. Διετάχθην με Ουλαμόν μου προβώ εις αναγνώρισιν
          πέραν γραμμών μας. Περί ώραν 6ην εξήλθον γραμμών μας περί τα επτά 7
          χιλιόμετρα, ουδέν ανεκαλύφθη περί προκειμένου αιφνιδιασμού εκ μέρους
          του εχθρού. Την 17ην Μαρτίου έγινε μνημόσυνον υπέρ των εν πολέμοις
          πεσόντων. <br />
          Την <b>25ην Μαρτίου</b> ετελέσθη δοξολογία εις Σινίρ Κιό επί τη Εθνική
          εορτή της Παλιγγενεσίας. Την 27ην Μαρτίου των Βαϊων. Ανεχωρήσαμε εκ
          Καγιά Τασή στις 9:30 διετάχθην μείνω οπισθοφυλακή της όλης φάλαγγος,
          διέλευσις Σινίρ Κιό, Ακ. Εϋρεν, Εγισύς Χαμάμ, καταυλισμός παρά την
          Σιδηροδρομικήν γραμμήν στις 4 μ.μ. Την 31ην Μαρτίου λόγω του πυκνού
          συνωστισμού, διετάχθημεν αραιώσωμεν ένα ουλαμόν εις αντίσκηνα.
          Εψάλησαν τα 12 Ευαγγέλια εν υπαίθρω. Παρέστη ο κ. Μέραρχος και πάντες
          οι Αξιωματικοί. Την 1ην Απριλίου λόχος μας της ημέρας. Όρισα 5
          φυλάκια, 1 διμοιρία αντιαεροπορική, 1 διμοιρία Στρατονομική φυλακή.
          Ορίσθην παρά του Συντάγματος επιτροπή παραδόσεως αμνών εις τους
          Λόχους. Στις 7:30 μ.μ. συνεκεντρώθησαν τα τμήματα έξωθεν του Τζαμιού,
          εψάλη ο επιτάφιος θρήνος. Παρέστη ο κ. Μέραρχος και πάντες οι
          αξιωματικοί. Την 9ην μ.μ. εγένετο η περιφορά του επιταφίου εν τω
          χωρίω. Την 2αν Απριλίου 2ος λόχος της ημέρας, στις 10:00 μετέβημεν δια
          την ζύγισιν των αμνών. Διακόσμησις του χώρου δια την εορτήν του Πάσχα.
          Εις το μέσον του χωριού εστήθη εξέδρα δια την Ανάστασιν. Επείγουσα
          διαταγή αυξήσωμεν τας περιπόλους προς πρόληψιν δυστυχημάτων εκ των
          πυροβολισμών απαγορευθέντων αυστηρώς. Στις 19:30 ήρχισεν η λειτουργία
          της Αναστάσεως. Λόχος μου διέθεσε περιπόλους προς τήρησιν της τάξεως.
          Ορίσθην επόπτης περιπολιών. Μετά την Ανάστασιν εφάγαμεν την
          μαγειρίτσαν. Την <b>3ην Απριλίου</b>, το Άγιον Πάσχα. Χιονίζει.
          Προετοιμασία της σούβλας, εφάγαμε μέσα στο Τζαμί. Διανομή εις τους
          φαντάρους αυγά, κρασί, κρέας, ήρχισε το φαγοπότι, κατόπιν ο χορός. Μας
          επεσκέφθη ο κ. Μέραρχος, ο κ. Συνταγματάρχης και πολλοί Αξιωματικοί,
          τους εδεξιώθημεν και τους ηυχήθημεν καλόν Πάσχα. <br />
          Την <b>8ην Απριλίου</b> της Ζωοδόχου Πηγής. Γυμνάσια Συντάγματος από
          τον Συν/χην κ. Γ. Ψάραν. Μετά το πέρας των γυμνασίων εξεφώνησε λόγον
          εξάρας την δράσιν του Συντάγματος. Την 9ην Απριλίου καθαριότης Λόχου.
          Ήλθε ο Γ. Φεκλός, συνηντήθημεν με τον Θεοχάρην. Παρέμεινα το εσπέρας
          εις του Θεοχάρη, περάσαμε έξοχα.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΟΚΤΩΒΡΙΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Απρίλιος - Ιούλιος
          1922)
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>10ην Απριλίου 1922</b> κατά το λυκαυγές ενεφανίσθη εχθρικόν
          αεροπλάνον. Την 11ην Απριλίου Λόχος μου της ημέρας, εγένετο
          συγκέντρωσις του Συν/τος και διαπόμπευσις των λιποτακτών Λοχίου Κόη
          μετά 2 στρατιωτών. Επί της ράχεως των ανηρτήθη πινακίς έχουσα ούτω
          “Άτιμος. Προδότης”. Την 12ην Απριλίου αφίχθη ο κ. Δαρδαμανέλλης.
          Επήραμε μισθό. Εξόφλησις λογαριασμών. Δια το δέμα έδωσα δρχ 28. Την
          13ην Απριλίου παρέλαβε Λόχος της ημέρας o 6ος. Αφίχθη θίασος,
          παρέστησε την Σκλάβα. Την 14ην Απριλίου έφυγε ο κ. Βασιλειάδης διά τα
          έμπεδα Οδεμησίου. Mε εδώρισεν ένα καθρέπτην. Την 15ην Απριλίου αφίχθη
          ΙΙΙ Τάγμα εξ Αφιόν Καραχισάρ. Επήγα εις το θέατρον, παράστηναν την
          Εσμέ. <br />
          Την <b>23ην Απριλίου</b> του Μεγαλομάρτυρος Γεωργίου αργία. Έγινε
          δοξολογία, εκάναμε επισκέψεις εις τους εορτάζοντας, επεράσαμε έξοχα.
          Την 24ην Απριλίου διετάχθην παραλάβω διμοιρίαν μου μεταβώ εις Τσιά
          Κουγιού ανεύρω 16 ζώα άτινα εδραπέτευσαν εκ της αγέλης. Μεταβάς εις
          Τσιά Κουγιού ανεύρον τούτα και επέστρεψα εις Σύνταγμα μεσημβρίαν. Την
          25ην Απριλίου αφίχθη ο Θεοδ. Κωλέττης εις το Σύνταγμα, προσπάθησα να
          τον τοποθετήσω εις τον Λόχον μου, το επέτυχον. Την 2αν Μαΐου γυμνάσια,
          ετιμωρήθη ο κ. Ιωανίδης διότι είχε ανηρτημένην την εικόνα του κ. Ελ.
          Βενιζέλου. Την 15ην Μαΐου περί την 11ην εσπέρας επληροφορήθημεν ότι
          πολλοί οπλίται θέλουν εγκαταλείψουν τας τάξεις των. Διετάχθημεν
          λάβωμεν τα κατάλληλα μέτρα. Καθ’ όλην την νύκτα εμείναμεν άϋπνοι.
          Λιποταξία απεσοβήθη. <br />
          Την <b>17ην Μαΐου</b> επί τη επετείω της μάχης του Σκρά
          συνεκεντρώθημεν εις το 23ον Σύνταγμα όπου ετελέσθη δοξολογία και
          μνημόσυνον υπέρ των πεσόντων. Παρίσταντο πάντες οι Αξ/κοί της
          Μεραρχίας και ο Στρατηγός, εκφωνήσας λόγον, συνεχάρη τους μετασχόντας
          εις την μάχην ως και λοιπούς. Μετέβημεν εις το δωμάτιον του κ.
          Διάμεση. Την 18ην Μαΐου συγκέντρωσις Συντάγματος διάφοροι ασκήσεις υπό
          του κ. Διοικητού του Συν/τος μέχρι τις 12:30 μ.μ. Την 21ην Μαΐου του
          Αγίου Κωνσταντίνου και Ελένης έγινε δοξολογία. Μετέβην σε διαφόρους
          φίλους και διασκεδάσαμε. Την 25ην Μαΐου γυμνάσια. Μετά το πέρας των
          γυμνασίων ανεγνώσθη αποχαιρετιστήριος διαταγή του παραιτηθέντος
          Στρατηγού κ. Παπούλα, αναλαβόντος την αρχηγίαν της Στρατιάς ο κ.
          Χατζηανέστης. Την 30ην Μαΐου ήλθε προς συνάντησιν μου ο Παναγιώτης
          Παπαγιάννης, τον εκράτησα εις το δωμάτιον μου, επήρα τρεις φιάλας
          ούζο, επεράσαμε έξοχα, ανεχώρησε την επαύριον. Την 31ην Μαΐου αφίχθη ο
          αρχηγός της Στρατιάς για να επιθεωρήση την Μεραρχίαν. Μετά την
          επιθεώρησιν εγένετο παρέλασις, έμεινε κατενθουσιασμένος. Λόχος μας εις
          τα φυλάκια. Επόπτης φυλακίων. Την 24ην Ιουνίου έτος συμπληρούται από
          της εκκινήσεως της Μεραρχίας από Τσόγγαρα διά τας επιχειρήσεις
          Σαγγαρίου. Επεστράφη η άδειά μου, μη εγκριθείσα παρά του Συντάγματος.
          Την 8ην Ιουλίου επί τη επετείω της μάχης Δορυλαίου διέταξεν η Μεραρχία
          να παύσουν αι ασκήσεις. Εγένετο Δοξολογία, μνημόσυνον υπέρ των
          πεσόντων, ανάπτυξις της εορτής επί τη επιτυχία των Ελληνικών όπλων.{" "}
          <br />
          Την <b>10ην Ιουλίου</b> εγένετο μνημόσυνον υπέρ των ηρωικώς πεσόντων
          αδελφών μας εις τα Μικρασιατικά πεδία των μαχών, εξεφωνήθησαν πάντα τα
          ονόματα των αξιωματικών και οπλιτών. Επικήδειον εξεφώνησε ο κ.
          Διοικητής της Μεραρχίας. Την 12ην Ιουλίου περί ώραν 5ην ενεφανίσθη
          εχθρικόν αεροπλάνον επλήγη παρά των ημετέρων και περί ώραν 11ην
          επληροφορήθημεν ότι κατερρίφθη εις Χαμάμ, φονευθέντων των αεροπόρων.
          Την 20ην Ιουλίου του προφήτου Ηλιού. Εορτάσαμεν με τους φίλους μου
          κατόπιν μετέβημεν εις το δωμάτιον του κ. Δαρδαμανέλλη, εφάγαμε ένα
          αρνί μετά του σχετικού ούζου και μπίρας κ.λ.π. Την <b>21ην Ιουλίου</b>{" "}
          εγένετο πανήγυρις επί τη καταλήψει της Μ. Ασίας. Εις την πλατείαν του
          χωριού είχον συγκεντρωθή πάντες οι Οθωμανοί των πέριξ χωριών όπως
          εκδηλώσουν την υποταγήν των. Επαιάνιζε η μουσική του Σώματος διάφορα
          άσματα.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΝΟΕΜΒΡΙΟΣ - 22ον Σύνταγμα, Μεραρχία Αρχιπελάγους (Αύγουστος 1922)
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          Την <b>1ην έως την 10ην Αυγούστου 1922</b> ασκήσεις. Την 11ην
          Αυγούστου επέτειος της διαβάσεως του Σαγγαρίου εγένετο εξομολόγησις
          των ανδρών. Ι Τάγμα προφυλακάς. <br />
          Την <b>12ην Αυγούστου</b> διετάχθημεν να είμεθα έτοιμοι προς εκκίνησιν
          καθότι εξεδηλώθη εχθρική επίθεσις. Περί την 8ην πρωινήν εγένετο η
          εκκίνησις του Συντάγματος εις Μπάλ – Μαχμούτ. Την 2ανμ.μ. εγένετο
          μεγάλη στάσις εις Κιοπρουλού. Εκκινήσαμεν την 5ηνμ.μ., αφίχθημεν την
          11ην εις Μπογιαλή. Εχθρός έχει επιτεθεί εις το κενόν Συνίρ Κιό. <br />
          Την <b>13ην Αυγούστου</b> επίθεσις εχθρού εγενικεύθη εις Αφιόν
          Καραχισάρ καθ’ όλους τους τομείς. Εχθρός μάχεται απεγνωσμένως, τα
          Συντάγματά μας ανθίστανται ηρωικώς, απώλειαι μέγισται, 49ον και 4ον
          Σύνταγμα αριστερά οπισθοχώρησαν. Σύνταγμά μας διελύθη. Δύο Τάγματα εις
          Κιλίτους Αρογάν, Τάγμα μας εις κενόν Καγιά Τασί Τιλκί – Κηρί – Μπέλ.
          Καθ’ όλην την ημέραν είμεθα εφεδρεία, επέταξαν τα περιστέρια, την
          εσπέραν της 13ης Αυγούστου διετάχθημεν καταλάβωμεν επίκαιρα σημεία εις
          το προκεχωρημένον Καγιά Τυσί προς Σινήρ Κιό, εστήσαμεν προφυλακάς
          μάχης. <br />
          Την <b>14ην Αυγούστου</b>, επίθεσις εχθρού δεξιά και αριστερά κατά
          φάλαγγας, εκατέρωθεν επιθέσεις και αντεπιθέσεις με τεράστιας απωλείας,
          περί την 8ην οι φαντάροι πολεμούσαν σαν λέοντες, αλλά και εχθρός
          μάχεται απεγνωσμένως, επιχειρών να διακόψη από τα άλλα τμήματα και να
          καταλάβη την σιδηροδρομικήν γραμμήν, την οποίαν αφ’ εσπέρας ιππείς,
          φονεύσαντες τους σκοπούς των φυλακίων, απέκοψαν την συγκοινωνίαν με
          Αφιόν Καρά Χισάρ. Το πυροβολικόν και τα πολυβόλα του εχθρού βάλλουν
          διαρκώς με μεγάλας επιτυχίας. Τα στρατεύματά μας εις το δεξιόν έναντι
          των φαλάγγων του εχθρού υποχωρούν και αντεπιτίθενται, εντέλει
          αναγκάζωνται να υποχωρήσουν. Εις τον τομέα μας εχθρική επίθεσις άρχισε
          κατά φάλαγγας, λόχος μου μάχεται απεγνωσμένως, μας έχουν πλησιάσει
          περί τα 50 μέτρα, ετοιμαζόμεθα με εφ’ όπλου λόγχην, και τους βάλαμε με
          χειροβομβίδας και οπλοβομβίδας, απώλειαι εκατέρωθεν τεράστιαι. Μας
          έχουν κυκλώσει δεξιά και αριστερά, τμήματά μας οπισθοχώρησαν, παρ’
          ολίγον μας αιχμαλωτίσουν. Περί την 12ην τραυματίζομαι εις την κεφαλήν
          μου, μένω αναίσθητος. Τέσσερις στρατιώται του λόχου μου με μεταφέρουν,
          δεν προλάβαμε να κατέλθωμε στη χαράδρα Σινίρ Κιό, ήρχισε οπισθοχωρή
          και το Τάγμα μας την 12:15! Παρ’ ολίγον με εγκαταλείψουν στρατιώται
          μου και συλληφθώ αιχμάλωτος. Απώλεσα την χλαίνην μου. Κατά την
          διέλευσιν από Σινίρ Κιό, μας επυροβολούσαν οι κάτοικοι. Με ίππευσε στο
          άλογο του ο λοχαγός Πυροβολαρχίας κ. Β. Πετρόπουλος. Περί τις 4:30
          φθάσαμε εις τον σιδηρ. σταθμόν Μπαλ Μαχμούτ. Εις Μπαλ Μαχμούτ έχει
          εγκαταλειφθεί όλον το υλικόν, φύρδην μίγδην τρόφιμα, άρβυλα, ιματισμός
          κ.λ.π. λόγω της καταστροφής της Σιδηρ. γραμμής Σμύρνης Αφιόν.
          Τραυματίαι απειράριθμοι εγκαταλελειμμένοι εις το έλεος του Θεού. Τα
          τμήματά μας ήρχισαν να οπισθοχωρούν με κατεύθυνσιν εις Τουλού Μπουνάρ.
          Εις Κουτσού Κιό εγένετο στάσις. Παραμονή μέχρι πρωίας.
          <br />
          Την <b>15ην Αυγούστου</b> εγένετο οπισθοχώρησις προς Τουλού Μπουνάρ.
          Το Τάγμα μας οπισθοφυλακή. Εβάδιζα με τα μεταγωγικά Σώματος. Ελαφρά
          τμήματα του εχθρού ιδίως άτακτα σώματα (Τσέται) απασχολούν τα τμήματά
          μας. Περί το εσπέρας εφθάσαμε εις Τουλού Μπουνάρ. Μετ’ άλλων
          τραυματιών επεβιβάσθημεν σιδηροδρομικώς εις Ουσάκ, μας διεκόμισαν εις
          το ΣΤ΄ Στρατιωτικόν Νοσοκομείον. <br />
          Την <b>16ην Αυγούστου</b>, την μεσημβρίαν ετοιμασθήκαμε διακομισθούμε
          εις Σμύρνην. Επιβιβάσθημεν την 8ην μ.μ. διέλευσις, Οτουράκ Μπανάζ,
          Ελβανλάρ. Περί την 2 π.μ. παρά των φυλακίων μας ερρίπτοντο
          πυροβολισμοί δια να μας ειδοποιήσουν ότι υπάρχει κίνδυνος. Ευτυχώς,
          αντιληφθείς ο μηχανικός εσταμάτησε το τραίνον και απεσοβήθη το
          δυστύχημα, είχον βάλει βόμβας εις την σιδηροδρ. γραμμήν διά να μας
          πετάξουν εις τον αέρα. Παραμείναμε μέχρι πρωϊας έως ότου διορθωθή η
          γραμμή. Εξακολουθούμε πορείαν 6 π.μ. Διήλθαμε Φιλαδέλφεια, κάτοικοι
          συναθροισμένοι εις τον σταθμόν Φιλαδέλφειας, Ντερέ Κιό, Αχμετλή,
          Κασαμπά, Μαγνησία , Μαινεμένη, κλαίοντες μας υπεδέχοντο και μας
          διένειμαν διάφορα, κονιάκ, τσιγάρα γλυκά κ.λ.π. Περί την 7ην μ.μ.
          αφίχθημεν εις Σμύρνην Μπασμαχανέ. Δι’ αυτοκινήτου μας διεκόμισαν εις
          το Β΄ Στρατιωτικόν Νοσοκομείον, άνωθεν των φυλακών, εις ο υπηρετούσαν
          κατά καλήν μου τύχην ο Δημ. Νικέλλης και ο Κομνηνός Κουνής. Μοι
          εγένετο αμέσως αλλαγή του τραύματός μου παρά του ιατρού του
          Νοσοκομείου κ. Ορφανίδου. Ο γιατρός κατά την αλλαγήν με εφόβισε, διότι
          πραγματικώς το τραύμα μου άρχισε να όζη και να πυορροή, διέταξε δε
          αμέσως να μου γίνη χειρουργική επέμβασις προς πρόληψιν δυσαρέστων
          συνεπειών.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>

        <br />
        <br />
        <br />
        <br />
        <br />

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΔΕΚΕΜΒΡΙΟΣ - Αντίγραφον φύλλου Νοσηλείας
        </Typography>
         <Typography variant="h3" sx={theme.typography.typography2}>
          «Απόσπασμα»
          <br />
          Β΄ Στρατιωτικόν Νοσοκομείον
          <br />
          Φύλλον Νοσηλείας
          <br />
          Ονομ/μον Κώσσης Ηλίας Νικολάου
          <br />
          Βαθμός Ανθυπολοχαγός
          <br />
          Σώμα ή Όπλον. 22ον Σύνταγμα Πεζικού 5ος Λόχος.
          <br />
          Τόπος Τραυματισμού. Μάχη Τιλκί-Κηρί-Μπελ
          <br />
          Καταγωγή – Βρισσά – Λέσβου.
          <br />
          Κλάσις Επιστρατεύσεως 1913α
          <br />
          Διάγνωσις
          <br />
          Φέρει τραύματα τυφλά της αριστεράς μετωποβρεγματικής χώρας. Τα
          τραύματα διήκουσι ιδίως της μετωπιαίας χώρας, πιθανώτατα φέρει κάταγμα
          της έξω αυτού πλακός. Δια της πιέσεως των μαλακών μορίων, πύον αρκετόν
          εξέρχεται. Ο άρρωστος βαδίζει, η δε γενική του κατάστασις παρουσιάζει
          συμπτώματα τινα λειτουργικής διαταραχής. Επέμβασις διά γενικής δια
          χλωροφορμίου αναισθησίας, γίνεται τομή κατά την μετωπιαίαν χώραν, όπου
          ευρίσκεται τεμάχιον υφάσματος και θραύσμα οβίδος. Το δε οστούν απεκόπη
          όλον το κατεστραμμένον, και είτα μερική ραφή. Κατόπιν διανοίγομεν και
          το έτερον τραύμα, όπου ευρίσκομεν και ενταύθα το οστούν γύρωθεν του
          περιοστέου, και εις το σημείον τούτο ρωγμή του οστού και ραφή.
          <br />
          Σμύρνη τη 17 – 8 – 1922
          <br />
          Ο Χειρούργος
          <br />
          Α. Ορφανίδης.
          <br />
          <br />
          <br />
          Από 18ην έως 22αν Αυγούστου η κατάστασίς μου άρχισε να βελτιούται.
          <br />
          Την 23ην Αυγούστου μας συνέλεξαν τα φύλλα Νοσηλείας και μας επιβίβασαν
          του ατμοπλοίου «Αμφιτρίτη» διά να μας μεταφέρουν εις Θεσσαλονίκην.
          <br />
          Την 24ην Αυγούστου αφίχθημεν εις Θεσσαλονίκην, πλήθος κόσμου μας
          υπεδέχθη εις το Τελωνείον, η δε κ. Στρατηγού Σωτήλη μετά επιτροπής
          κυριών μας διένειμαν διάφορα και μας ετοποθέτησαν προσωρινώς εις το Β΄
          Στρατιωτικόν Νοσοκομείον Θεσ/κης. Την 25ην Αυγούστου μας διεκόμισαν
          εις το νεοσύστατον Νοσοκομείον αξιωματικών, πρώην Βουλγαρικόν
          Προξενείον.
          <br />
          Από 25ην έως 31ην Αυγούστου εις Νοσοκομείον η κατάστασις μου
          εβελτιούτο σημαντικώς.
          <br />
          Την 1ην Σεπτεμβρίου εξήλθον του Νοσοκομείου λαβών 40ήμερον αναρρωτικήν
          άδειαν από την επιτροπήν απαλλαγών. Ανεχώρησα την 3ην Σεπτεμβρίου εις
          Μυτιλήνην.
          <br />
          Την 5ην Σεπτεμβρίου άφιξις εις Μυτιλήνην.
          <br />
          Την 6ην Σεπτεμβρίου άφιξις εις Βρισσάν.
          <br />
          <br />
          <br />
          Ηλίας Ν.Κώσσης Εφ. Ανθυπολοχαγός Πεζικού, 5ος λόχος. Έτος 1922
          <br />
          <br />
          Τον Οκτώβριο του 1922 απελύθην ευρισκόμενος εν αναρρωτική αδεία.
          <br />
          <br />
          <i>Από το ημερολόγιο του Έφεδρου Ανθ/γού Ηλία Ν. Κώσση.</i>
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
      <Typography variant="h1" sx={theme.typography.typography1}>
          Φωτογραφικό Υλικό
        </Typography>

        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image03}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image04}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image05}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image06}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image07}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image08}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image09}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image10}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image11}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image12}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image13}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image14}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image15}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image16}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image18}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image19}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image20}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default P10_Hmerologia;
