import React from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import ScrollToTopButton from '../components/ScrollToTopButton';
import Header1 from '../components/Header1';
import HomeIcon from '@mui/icons-material/Home'
import { commonBoxStyles, headerBoxStyles } from '../components/CommonStyles'

import Image01 from '../assets/images/drastiriotites2009/kimage001.jpg'
import Image02 from '../assets/images/drastiriotites2009/kimage002.jpg'
import Image03 from '../assets/images/drastiriotites2009/kimage003.jpg'
import Image04 from '../assets/images/drastiriotites2009/kimage004.jpg'
import Image05 from '../assets/images/drastiriotites2009/kimage005.jpg'
import Image06 from '../assets/images/drastiriotites2009/kimage006.jpg'
import Image07 from '../assets/images/drastiriotites2009/kimage007.jpg'
import Image08 from '../assets/images/drastiriotites2009/kimage008.jpg'
import Image09 from '../assets/images/drastiriotites2009/kimage009.jpg'
import Image10 from '../assets/images/drastiriotites2009/kimage010.jpg'
import Image11 from '../assets/images/drastiriotites2009/kimage011.jpg'
import Image12 from '../assets/images/drastiriotites2009/kimage012.jpg'
import Image13 from '../assets/images/drastiriotites2009/kimage013.jpg'
import Image14 from '../assets/images/drastiriotites2009/kimage014.jpg'
import Image15 from '../assets/images/drastiriotites2009/kimage015.jpg'
import Image16 from '../assets/images/drastiriotites2009/kimage016.jpg'
import Image17 from '../assets/images/drastiriotites2009/kimage017.jpg'
import Image18 from '../assets/images/drastiriotites2009/kimage018.jpg'
import Image19 from '../assets/images/drastiriotites2009/kimage019.jpg'
import Image20 from '../assets/images/drastiriotites2009/kimage020.jpg'
import Image21 from '../assets/images/drastiriotites2009/kimage021.jpg'
import Image22 from '../assets/images/drastiriotites2009/kimage022.jpg'
import Image23 from '../assets/images/drastiriotites2009/kimage023.jpg'
import Image24 from '../assets/images/drastiriotites2009/kimage024.jpg'
// import Image25 from '../assets/images/drastiriotites2009/kimage0025.jpg'
// import Image26 from '../assets/images/drastiriotites2009/kimage0026.jpg'
// import Image27 from '../assets/images/drastiriotites2009/kimage0027.jpg'
// import Image28 from '../assets/images/drastiriotites2009/kimage0028.jpg'
import Image29 from '../assets/images/drastiriotites2009/kimage029.jpg'
import Image30 from '../assets/images/drastiriotites2009/kimage030.jpg'


function D6_drastiriotites_2009() {

    const theme = useTheme();


    return (

        <>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ ...headerBoxStyles }}>
                <Header1 props="Οι δραστηριότητές μας το 2009" />
            </Box>


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Η Χοροεσπερίδα μας την παραμονή της Πρωτοχρονιάς στο ξενοδοχείο ΖΑΙΡΑ</Typography>
                <img src={Image01} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Όπως κάθε χρόνο έτσι και φέτος πραγματοποιήθηκε με μεγάλη επιτυχία η χοροεσπερίδα του Συλλόγου μας την παραμονή της Πρωτοχρονιάς, στις 10 το βράδυ στο
                    ξενοδοχείο ΖΑΪΡΑ. Με την αλλαγή του χρόνου αμέσως μετά τις 12.00 κόπηκε η βασιλόπιτα του Συλλόγου μας, με τις ευλογίες του ιερέα του χωριού μας του Παπά Παναγιώτη.
                    Τυχερή της βραδιάς με κλήρωση ήταν το μέλος του Δ.Σ. του Συλλόγου μας η κ. Κατερίνα Γρηγορέλλη που με τον αριθμό 127 κέρδισε το φλουρί της πίτας, ένα χρυσό
                    Κωσταντινάτο. Στην συνέχεια έγινε δεύτερη κλήρωση ένα  ανδρικό ρολόι  προσφορά από το χρυσοχοείο του κ. Γιώργου Λαουτάρη το οποίο κέρδισε η κ. Χρυσούλα Αράπη με
                    τον αριθμό 168. Στην εκδήλωση παραβρέθηκε και μας τίμησε με την παρουσία του, ο τ. Διοικητής Πυροσβεστικής Υπηρεσίας και νυν δημοτικός σύμβουλος κ. Κώστας Τατάκης,
                    πρόεδροι τοπικών Συλλόγων καθώς και  φίλοι μας από τη Μυτιλήνη και τα χωριά της Γέρας. Τέλος μήνυμα με ευχές μας έστειλε και διαβάσαμε ο Νομάρχης Λέσβου κ.
                    Παύλος Βογιατζής. </Typography>


                <Typography variant="h3" sx={theme.typography.typography2}>Κατά την διάρκεια της εκδήλωσης διενεργήθηκε λαχειοφόρος αγορά με πολλά και πλούσια δώρα , όλα προσφορές των καταστημάτων και των επιχειρήσεων
                    της Μυτιλήνης και του χωριού μας. Δώρα στα παιδιά, αλλά και σε μεγάλους, μοίρασε ο Αι Βασίλης ο γνωστός μας Γιάννης Μολυβιάτης τον οποίο και ευχαριστούμε
                    θερμά για την διαχρονική προσφορά του στις εκδηλώσεις του Συλλόγου μας. Δώρα σε όσους παραβρέθηκαν πρόσφερε και ο Σύλλογός μας, από ένα βιβλίο με τίτλο
                    «Γνωριμία με την Καινή Διαθήκη» του καθηγητή κ. Μπαΐρακτάρη μια έκδοση του 1958 την οποία ανατυπώσαμε πρόσφατα, για να είναι ευλογημένος ο νέος χρόνος..
                    Η εξυπηρέτηση του ξενοδοχείου ήταν πολύ καλή, το φαγητό εξαιρετικό  και η ορχήστρα με τους Νίκο Χατζηράλλη, Γιώργο Ασλάνη, Μιχάλη και Μενέλαο Κυριάκογλου
                    κράτησε αμείωτο το κέφι και το γλέντι μέχρι τις πρωινές ώρες. Και του χρόνου με υγεία.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Υπό βροχή ο Αγιασμός των υδάτων στη Σκάλα Λουτρών.</Typography>
                <img src={Image02} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Εξαίρεση αποτέλεσε η φετινή εορτή των Θεοφανίων στη Σκάλα Λουτρών αφού για πρώτη φορά μετά από πολλά χρόνια ο Αγιασμός των υδάτων έγινε υπό βροχή.
                    Η εξαίρεση όμως αυτή δεν απετέλεσε εμπόδιο στην συμμετοχή των κατοίκων του τοπικού διαμερίσματος Λουτρών, των μελών του Συλλόγου μας  και του Τοπικού Συμβουλίου
                    Λουτρών, οι οποίοι προσήλθαν στη Σκάλα του Αγίου Γεωργίου μαζί με τον Ιερέα τον Παπα Παναγιώτη για να γίνει  η ρίψη του Τιμίου Σταυρού στη θάλασσα.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Τον Σταυρό ανέσυρε ο Άγγελος Μήνικνας από τα Λουτρά ο οποίος και παρέλαβε το δώρο του, ένα χρυσό Σταυρό με καδένα προσφορά του Πολιτιστικού κέντρου
                    Λουτρών. Παρόντες ο Πρόεδρος και τα μέλη του Τοπικού Συμβουλίου Λουτρών καθώς και το Δ.Σ. και πολλά μέλη του Συλλόγου μας. Και του χρόνου με υγεία.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Υπό βροχή ο Αγιασμός των υδάτων και στη Χαραμίδα.</Typography>
                <img src={Image03} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Μετά τον Αγιασμό των υδάτων που έγινε στη Σκάλα Λουτρών ο ιερέας του χωριού μας και όσοι άλλοι παρευρίσκονταν, ξεκίνησαν για να αγιάσουν τα ύδατα στην περιοχή
                    της Χαραμίδας. Αυτό είναι ένα νέο σχετικά έθιμο που ξεκίνησε πριν από μερικά χρόνια με πρωτοβουλία του Εξωραϊστικού Συλλόγου Χαραμίδας, και χρόνο με το χρόνο
                    καθιερώνεται όλο και περισσότερο. Εκεί λοιπόν κάτω από την γνωστή ταβέρνα του κ. Παναγιώτη Βουτσαρδέλλη με τις ομπρέλες ανοικτές περίμεναν τα μέλη του τοπικού
                    Συλλόγου  και οι κάτοικοι της περιοχής για να γίνει και εδώ ο καθιερωμένος πλέον Αγιασμός των υδάτων και στην συνέχεια το κόψιμο της Βασιλόπιτας.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Τον Σταυρό ανέσυρε από την Θάλασσα ο κ Σωτήρης Μαμουνής γνωστό μέλος τόσο του Συλλόγου μας όσο και του Συλλόγου της Χαραμίδας, ο μόνος που
                    βουτάει κάθε χρόνο στην περιοχή.. Επακολούθησε το κόψιμο της πίτας και το σχετικό κέρασμα σε όλους τους παρευρισκόμενους με  πρωτεργάτες τα μέλη του Συλλόγου
                    και την κ. Άσπα Αβαγιανού. </Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Δενδροφύτευση στο νησάκι του Αγίου Ισιδώρου στον κόλπο Γέρας.</Typography>
                <img src={Image04} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας "ΤΟ ΔΕΛΦΙΝΙ", από κοινού με τον  <b>Φυσιολατρικό Όμιλο Μυτιλήνης ΥΔΑΤΙΝΟΣ</b>, συμμετείχε την Κυριακή 27/1/2009 και ώρα 09:30 σε
                    δενδροφύτευση πάνω στο γραφικό νησάκι του Αγίου Ισιδώρου στον κόλπο Γέρας λίγο ποιο πέρα από την Κουντουρουδιά. Εκεί μας μετέφερε αφιλοκερδώς με την βάρκα του
                    ο Στρατής Καραντώνης ο οποίος συμμετείχε και στην δενδροφύτευση.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Προγράμματα επιμόρφωσης ενηλίκων.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Δύο προγράμματα υλοποιήθηκαν φέτος από την Γενική Γραμματεία δια βίου μάθησης του υπουργείου εθνικής παιδείας και θρησκευμάτων στον χώρο της βιβλιοθήκης του
                    Συλλόγου μας στη Σκάλα Λουτρών. Μέσω αυτών των προγραμμάτων επιδιώχθηκε η ευαισθητοποίηση των πολιτών σε θέματα προστασίας του Περιβάλλοντος γενικότερα, αλλά και
                    ειδικότερα με αναφορές στην περιοχή μας. Το πρώτο (από 14-3-09 έως 8-4-09) είχε τίτλο «Το φυσικό Περιβάλλον και η προστασία του» και ο στόχος ήταν, η παροχή γνώσεων
                    σε θέματα πληροφόρησης και ευαισθητοποίησης για το περιβάλλον, η εξοικείωση με βασικές έννοιες και αρχές καθώς και η ανάπτυξη περιβαλλοντικής συνείδησης για την
                    πρόληψη και την αντιμετώπιση περιβαλλοντικών προβλημάτων.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Το δεύτερο (από 10-4-09 έως 20-5-09) είχε τίτλο «Περιβάλλον και Οικολογία» και ο στόχος ήταν η παροχή γνώσεων σε θέματα του φυσικού περιβάλλοντος και της
                    διαχείρισης του, η εκτίμηση των περιβαλλοντικών επιπτώσεων από έργα και δραστηριότητες, η ενημέρωση για την επεξεργασία υγρών και στερεών αποβλήτων, ο έλεγχος
                    εκπομπής αερίων ρύπων κ.τ.λ.  Επιπλέον εκτός από τα ανωτέρω έγιναν αναφορές και αποκτήθηκαν γνώσεις για τις διάφορες μορφές ακτινοβολίας και την επίδρασή τους
                    στον άνθρωπο, στην υγεία και την ποιότητα της καθημερινής του ζωής καθώς και μέτρα για την πρόληψη, την αντιμετώπιση και την μείωση των αρνητικών συνεπειών τους.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Τα προγράμματα παρακολούθησαν 15 άτομα, 7 από τη Σκάλα Λουτρών και 8 από τα Λουτρά. Καθηγητής ήταν ο κ. Φαρφαράς Αθανάσιος.
                    Η εκπαίδευση αποτελεί αέναη διαδικασία καλλιέργειας και προσωπικής ανάπτυξης ενώ ταυτόχρονα συμβάλλει στην κοινωνική συνοχή. Είναι γεγονός ότι σήμερα η παιδεία
                    αποτελεί το μοναδικό σταθερό σημείο σε μια μεταβαλλόμενη πραγματικότητα και την μοναδική αξιόπιστη επένδυση για το μέλλον του τόπου μας και του πλανήτη γενικότερα.
                    Αποκτώντας επιπλέον γνώσεις είναι αλήθεια ότι συμβάλλουμε στην ευημερία και την πρόοδο της πατρίδας μας.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Πάσχα  στη Σκάλα Λουτρών</Typography>
                <img src={Image05} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκαν και φέτος για τέταρτη συνεχή χρονιά οι θρησκευτικές τελετουργίες για τον εορτασμό των αγίων ημερών του Πάσχα στη Σκάλα Λουτρών. Αυτές
                    ξεκίνησαν με την τέλεση της θείας λειτουργίας το πρωί της Κυριακής των Βαΐων και του Νυμφίου το απόγευμα της ίδιας μέρας. Στην συνέχεια το απόγευμα της Μ.
                    Τετάρτης έγινε προηγιασμένη θεία λειτουργία και ευχέλαιο. Την Μ. Πέμπτη εψάλησαν τα 12 Ευαγγέλια και την Μ. Παρασκευή έγινε η περιφορά του επιτάφιου και η
                    αποκαθήλωση στην συνέχεια του εσταυρωμένου με την συμμετοχή όλων των κατοίκων και ιδίως της νεολαίας του οικισμού μας.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Τέλος το βράδυ του Μεγάλου Σαββάτου, μέσα σε μια πολύ κατανυκτική ατμόσφαιρα υπό το φως των κεριών  ετελέσθη η αναστάσιμη Θεία Λειτουργία στο
                    γραφικό εκκλησάκι του Αγίου Παντελεήμονα αφού με πρωτοβουλία του Σεβασμιωτάτου Μητροπολίτου Μυτιλήνης και ύστερα από δικό μας αίτημα εστάλη ιερέας γι αυτό το
                    σκοπό. Η συμμετοχή των κατοίκων ήταν καθολική και ιδίως των ηλικιωμένων οι οποίοι προσήλθαν με μεγάλη χαρά. Και του χρόνου να είμαστε γεροί να αναστήσουμε ξανά
                    με ιερέα στον οικισμό μας.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Οι προτάσεις του Συλλόγου μας στην Π.Β.Α. σχετικά με την αναθεώρηση των Κ.Α.Ζ.</Typography>
                <img src={Image06} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}><br /> <b>Θέμα:</b>  Παρατηρήσεις στην Μελέτη αναθεώρησης των Κ.Α.Ζ. νήσου Λέσβου.<br /><br />
                    Σχετικά με την διαβούλευση που ανακοινώθηκε για την πρόταση αναθεώρησης των Καταφυγίων άγριας ζωής στη Νήσο Λέσβο έχουμε να παρατηρήσουμε τα παρακάτω:<br /><br />

                    <b>1ον. Καταργήσεις Κ.Α.Ζ.</b><br />
                    Διαφωνούμε με την κατάργηση των Υφιστάμενων Κ.Α.Ζ. «Δίβολο Ακόθι» δήμου Μυτιλήνης,  «Κόκκινα Λιχρίνια Αγουρόσυκο» και  «Προφήτης Ηλίας» δήμου Πολυχνίτου,  «Βαθειά
                    Κοιλάδα Κελέμια» και «Μαλιώντας Ταβάρι Χρούσος» δήμου Ερεσού Αντίσσης  και «Λογαράς» δήμου Πλωμαρίου διότι αυτά θεωρούνται  και είναι στην πραγματικότητα μικρές οάσεις
                    προστασίας για την άγρια πανίδα του νησιού με αποτέλεσμα να υπάρχει κατά κανόνα εύκολο καταφύγιο για τα άγρια ζώα και πτηνά του νησιού μας.
                    Ειδικότερα,<br />
                    με την πρόταση κατάργησης του Κ.Α.Ζ. «Δίβολο – Ακόθι» ολόκληρη η χερσόνησος της Αμαλής από την Χαραμίδα μέχρι την περιοχή της Καράβας παραμένει τελείως απροστάτευτη.
                    Στο ανατολικό αυτό τμήμα του νησιού με τους περισσότερους κυνηγούς όταν δεν θα υπάρχει Κ.Α.Ζ. είναι βέβαιο ότι όλα τα είδη που υπάρχουν και που μέχρι σήμερα
                    προστατεύονται, θα εξολοθρευτούν τελείως. <br />
                    Ακόμη, επειδή με τις αλλαγές που επέφερε ο νόμος 2637/98 τα Κ.Α.Ζ. δεν επικεντρώνονται πλέον μόνο στην προστασία του θηραματικού πλούτου αλλά και σε όλα τα
                    είδη της άγριας πανίδας και της αυτοφυούς χλωρίδας δηλαδή στην διατήρηση του οικοσυστήματος γενικότερα, σας πληροφορούμε ότι εκτός από τα είδη που διαβιούν εδώ
                    και που αναφέρονται στην απόφαση ίδρυσης του καταφυγίου υπ. αρ.151713/2056/9-5-84 πρέπει να ληφθεί υπ όψιν ότι μέσα στην περιοχή του υπάρχοντος καταφυγίου πολύ
                    σημαντική είναι και η παρουσία της χλωρίδας, ενδεικτικά δε αναφέρουμε ότι, στην δυτική πλευρά του όρους Δίβολο έναντι της βραχονησίδας Άγιος Ισίδωρος του κόλπου
                    Γέρας υπάρχει σημαντικός βιότοπος από άγριες Ορχιδέες (12 είδη από αυτές αναφέρονται αναλυτικά στο βιβλίο του κ. Ιωάννη Καρατζά με τίτλο «Ορχιδέες και αγριολούλουδα
                    της Λέσβου»).<br />
                    Επίσης σας πληροφορούμε ότι υπάρχει ένα μικρό έλος στην περιοχή Ακόθι το οποίο θα παύση να προστατεύετε εφ όσον καταργηθεί το καταφύγιο. Αυτό το μικρό έλος είναι
                    τόπος επώασης και αναπαραγωγής ορισμένων ειδών ψαριών (όπως π.χ. κέφαλοι).  <br />
                    Για όλα τα προαναφερθέντα αλλά και για άλλα στα οποία επιφυλασσόμαστε να συγκεντρώσουμε στοιχεία και να σας τα προσκομίσουμε προσεχώς η πρότασή μας είναι να
                    διατηρηθεί το υπάρχον καταφύγιο και να διευρυνθεί βόρεια έτσι ώστε μέσα σ αυτό να περιληφθεί εκτός από το όρος Δίβολο και οι λόφοι μέχρι το όρος Προφήτης Ηλίας.<br /><br />
                    Για τα άλλα καταφύγια που προτείνετε να καταργηθούν δεν έχουμε στοιχεία να σας παραθέσουμε, προτείνουμε όμως να ζητηθεί η γνώμη του Τμήματος Περιβάλλοντος του
                    Πανεπιστημίου Αιγαίου επειδή σαν επιστημονικός φορέας είναι βέβαιο ότι θα έχει άποψη για την χρησιμότητα ύπαρξης η μη αυτών των καταφυγίων και
                    μάλιστα τεκμηριωμένη.<br /><br />

                    <b>2ον . Επανεξετάσεις και τροποποιήσεις Κ.Α.Ζ.</b><br />
                    Το Κ.Α.Ζ. «Πλάτη» του δήμου Ευεργέτουλα μειώνεται από τα 8.100 στρέμματα που ήταν στα 1.380 και αυτό όταν όλοι γνωρίζουμε πόσο σημαντικός βιότοπος είναι ο
                    υγρότοπος αυτός. Η μετατόπιση του κέντρου βάρους του καταφυγίου στον πυρήνα του υγροβιότοπου θεωρούμε ότι είναι σωστή αλλά είναι μέγα λάθος ο δραστικός περιορισμός
                    της έκτασης αυτής στα 1.380 στρέμματα. Μια γνωμοδότηση εδώ του Τμήματος Περιβάλλοντος του Πανεπιστημίου Αιγαίου θα ήταν αρκετά χρήσιμη αφού κατά το παρελθόν
                    έχει ασχοληθεί συστηματικά με αυτό το θέμα και έχει εκπονήσει αξιόλογες μελέτες.<br /><br />


                    <b>3ον . Ίδρυση καταφυγίου άγριας ζωής.</b>
                    Θεωρούμε θετική την απόφαση ίδρυσης Κ.Α.Ζ. στους υγροτόπους «αλυκής Καλλονής-Νουτζάρια-Καντρί-Μέσα» των δήμων Καλλονής και Αγίας Παρασκευής αν και αυτό θα έπρεπε να
                    είχε γίνει αρκετά χρόνια πριν. Όμως η σημαντικότητα αυτού του υγροτόπου είναι τόσο μεγάλη που θα έπρεπε τα όριά του να είναι περισσότερο διευρυμένα. Άλλωστε
                    υπάρχει και ένας επιπλέον λόγος γι αυτό πέρα από την προστασία των ειδών της άγριας πανίδας και χλωρίδας και αυτός είναι ο μεγάλος αριθμός των τουριστών που
                    έρχονται κάθε χρόνο και παρακολουθούν με τα κιάλια και με φωτογραφικές μηχανές τα πουλιά. Δεν είναι επομένως νοητό εκεί που είναι π.χ. τα κιόσκια παρακολούθησης
                    των άγριων πτηνών ακριβώς δίπλα να επιτρέπετε το κυνήγι. Τα αρνητικά σχόλια που κάνουν οι τουρίστες στις πατρίδες τους είναι πολλά και αποβαίνουν εις βάρος
                    των τουριστικών επαγγελματιών του τόπου μας αλλά και του εθνικού συμφέροντος της χώρας μας γενικότερα.<br />
                    Με αυτά τα λίγα πιστεύουμε ότι συμβάλουμε στο μέτρο των δυνατοτήτων και των εμπειρικών γνώσεων που έχουμε στο να βελτιωθεί η πρόταση της αναθεώρησης των Κ.Α.Ζ.
                    προς όφελος όλων και κυρίως της πατρίδας μας.</Typography>

            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εθελοντικός  καθαρισμός παραλιών.</Typography>
                <img src={Image07} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Την Κυριακή 17 Μαΐου 2009 πραγματοποιήθηκε όπως κάθε χρόνο με την βοήθεια των μελών και κυρίως της νεολαίας του οικισμού μας η καθιερωμένη εξόρμηση εθελοντικού
                    καθαρισμού παραλιών. Φέτος  επιλέξαμε για καθαρισμό την παραλία στην περιοχή «Καμάρι¨» δίπλα από τον όρμο  της Σκάλας Λουτρών. Το μήνυμα που δίδουμε κάθε χρόνο
                    με αυτή την ενέργειά μας είναι ότι είμαστε παρόντες στην ανάγκη προστασίας των ακτών διότι αυτές αποτελούν σημαντικό χώρο αναψυχής των πολιτών, πόλο έλξης
                    επισκεπτών και πηγή οικονομικής ανάπτυξης του τόπου μας. Ο καθαρισμός και η προστασία των παραλιών σε συνδυασμό με το ότι αυτές βρίσκονται μέσα στην περιοχή
                    προστασίας του Κόλπου Γέρας ο οποίος έχει υπαχθεί στο δίκτυο NATURA 2000 συμβάλουν στην οικολογική ισορροπία της περιοχής και στην προστασία των απειλούμενων
                    ζώων και φυτών που υπάρχουν και διαβιούν εδώ. </Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Όπως κάθε χρόνο  λοιπόν  έτσι και φέτος δώσαμε το μήνυμα της ανάγκης προστασίας των ακτών μας, για μας, για τα παιδιά μας, για τις επόμενες γενιές,
                    για τα ζώα, τα φυτά και την οικολογική ισορροπία. Ζητάμε παραλίες ελεύθερες για όλους, καθαρές με τη φροντίδα της Πολιτείας, των Δήμων και των πολιτών. </Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Πρωτομαγιά στη Νομαρχία Λέσβου.</Typography>
                <img src={Image08} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Όπως κάθε χρόνο έτσι και φέτος προσφέραμε ένα πανέμορφο στεφάνι από τριαντάφυλλα και αγριολούλουδα στην Νομαρχία Λέσβου το οποίο έφτιαξαν οι
                    κυρίες του Δ.Σ. του Συλλόγου μας. Κατά την επίσκεψη στην Νομαρχία Λέσβου τα μέλη του  Δ.Σ.  ευχήθηκαν στον Νομάρχη κ. Παύλο Βογιατζή καλό μήνα, καλό καλοκαίρι και
                    του χρόνου με αγάπη και υγεία. </Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Μια σημαντική πρωτοβουλία.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Επειδή στη Σκάλα Λουτρών δεν υπάρχει εκκλησία με μέγεθος που να καλύπτει τις ανάγκες των κατοίκων του οικισμού, ξεκίνησε η προσπάθεια
                    ανεύρεσης χώρου και συλλογής χρημάτων για να κτισθεί ενοριακή εκκλησία που να μπορεί να εξυπηρετεί και να χωράει όλους τους πιστούς. Για το σκοπό αυτό ο
                    Σύλλογός μας ανέλαβε την υποχρέωση να κάνει επιστολή στο Ίδρυμα Π. Μάνδρα με σκοπό την παραχώρηση έκτασης περίπου 150 τ.μ. και τρία μέλη του Συλλόγου μας,
                    οι κυρίες Τουρλή Ευθαλία, Κάσσου Κλεονίκη και Κρητικού Ελένη άνοιξαν κοινό λογαριασμό για να καταθέτουν όσοι επιθυμούν χρήματα για αυτό τον Ιερό σκοπό. Ήδη
                    υπάρχει η πρώτη κατάθεση με ποσό 6000 ευρώ. Όλοι πρέπει να βοηθήσουμε σ αυτή την κοινή προσπάθεια.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>5η Ιουνίου Παγκόσμια ημέρα για το περιβάλλον</Typography>
                <img src={Image09} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Η 5η Ιουνίου ως γνωστόν έχει καθιερωθεί από τον Ο.Η.Ε. ως <b>Παγκόσμια Ημέρα για το Περιβάλλον</b>. Με την ευκαιρία λοιπόν αυτή ο Σύλλογός μας διοργανώνει
                    κάθε χρόνο διαγωνισμό ανάμεσα στους μαθητές του Δημοτικού Σχολείου Λουτρών ξεχωριστά για κάθε τάξη με την συνεργασία των δασκάλων.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Έτσι λοιπόν και φέτος οι Α! , Β! , 	Γ! και Δ! τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα <b>"Ζωγράφισε την γειτονιά σου όπως θα σου άρεσε να
                    ήταν"</b> οι δε Ε! και ΣΤ! τάξεις στην έκθεση με θέμα <b>" Τι είναι ανακύκλωση και τι προσφέρει αυτή στην προστασία του περιβάλλοντος "</b>.<br /><br /><br /><br /></Typography>
            </Box>


            <ScrollToTopButton />



            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Επιστολή προς τον Σεβασμιώτατο Μητροπολίτη Μυτιλήνης Ιάκωβο.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Σεβασμιώτατε,   δια της παρούσης επιστολής αναγκαζώμεθα να επανέλθουμε δια πολοστήν φοράν και να επαναδιατυπώσουμε το πάγιο αίτημά μας στο θέμα ορισμού ιερέως ως
                    μονίμου εφημέριου στον οικισμό Σκάλα Λουτρών Μυτιλήνης.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Υπενθυμίζουμε ότι μέχρι στιγμής έχουμε κάνει ότι μας έχετε υποδείξει και σας έχουμε αποστείλει τον αριθμό των οικογενειών που διαβιούν μόνιμα εδώ με τις
                    υπογραφές τους καθώς και δύο έγγραφα υπομνήματα με ημερομηνίες 22-2-2006 και 1-3-2008 . Επίσης γι αυτό το θέμα σας έχουμε επισκεφθεί τόσο εσάς όσο και τον πατέρα
                    κ. Γρηγόριο Δομούζη πάρα πολλές φορές.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Τελειώνοντας την αναφορά μας αυτή για το χρονίζον πρόβλημα του οικισμού μας ευελπιστούμε ότι θα ενδιαφερθείτε για την όσο το δυνατόν συντομότερη επίλυσή του και
                    σας ευχόμεθα από καρδιάς καλή Ανάσταση.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Μαζί με τις ευχές του Δ.Σ. του συλλόγου μας σας διαβιβάζουμε και τις ευχές όλων των κατοίκων της Σκάλας Λουτρών και σας παρακαλούμε να ενδιαφερθείτε
                    προσωπικά για την αποστολή ιερέως την Μεγάλη εβδομάδα και εάν υπάρχουν δυσκολίες γι αυτό τουλάχιστον να μας στείλετε έναν ιερέα για το βράδυ του Μεγάλου Σαββάτου,
                    κάτι που το τηρείτε ανελλιπώς τα πέντε τελευταία χρόνια.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τοποθέτηση πινακίδας οικισμού στη στάση του λεωφορείου.</Typography>
                <img src={Image10} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Μία παραδοσιακή ζωγραφισμένη πινακίδα με την απεικόνιση του οικισμού της Σκάλας Λουτρών τοποθετήσαμε μέσα στη στάση του λεωφορείου έναντι της
                    ταβέρνας του κ. Ιωάννη Βαρτή, έτσι ώστε να μπορούν να κατατοπίζονται καλλίτερα όσοι ξένοι επισκέπτονται τον οικισμό μας.<br /></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Αρχαιρεσίες στον Σύλλογό μας.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε την Κυριακή 5 Απριλίου 2009  και ώρα 6 το απόγευμα η ετήσια τακτική γενική συνέλευση των μελών του Συλλόγου μας στην αίθουσα του Μουσείου
                    Προσφυγικής Μνήμης 1922 στην Σκάλα Λουτρών, κατά την διάρκεια της οποίας έγινε η  έκθεση και ο έλεγχος της δράσης του Δ.Σ., ο Οικονομικός απολογισμός και η έκθεση
                    της εξελεγκτικής επιτροπής καθώς και η ενημέρωση επί τρεχόντων θεμάτων. Στην συνέχεια αφού ακούστηκαν οι προτάσεις των μελών, πραγματοποιήθηκε η εκλογή εφορευτικής
                    επιτροπής η οποία ήταν αρμόδια για τις αρχαιρεσίες του Συλλόγου που έγιναν την επόμενη Κυριακή 12-4-2009 στον ίδιο χώρο και από ώρα 17.00 έως 20.00.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Επί συνόλου 307 εγγεγραμμένων μελών ψήφισαν 166. Από αυτές εκλέχτηκε το νέο Δ.Σ. του Συλλόγου για την επόμενη διετία που είναι το παρακάτω.
                    <b>Πρόεδρος:</b> Παπαχρυσός Δημήτριος , <b>Αντιπρόεδρος:</b> Σταματέλλη –Σπανού Ρένα, <b>Γενικός Γραμματέας:</b> Βαλαχής Παναγιώτης, Ταμίας: Σταματέλλη Αγγελική,
                    <b>Μέλη:</b> Τουρλής Μιχάλης, Τζανέτος Εμανουήλ, Γρηγορέλλη Κατερίνα, Α Μέλη: Λημναίου Ευαγγελία, Ευαγγέλου Βικτωρίτσα, Παλκάνικου-Δασκάλου Τζένη, Δαφιώτη Νάσια.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}><b><u>Υπεύθυνοι τμημάτων του Συλλόγου:</u></b><br /><br />
                    <b>Πολιτιστικά:</b> Δαφιώτη Νάσια, Ευαγγέλου Βικτωρίτσα, Λημναίου Βαγγελίτσα, Σταματέλλη Αγγελική.<br />
                    <b>Θέματα περιβάλλοντος:</b> Τζανέτος Εμμανουήλ , Τουρλής Μιχάλης, Παλκάνικου-Δασκάλου Τζένη, Παπαχρυσός Δημήτρης.<br />
                    <b>Βιβλιοθήκη:</b> Παπαχρυσός Δημήτρης, Καραμάνης Στράτης.<br />
                    <b>Μουσείο Προσφυγικής Μνήμης 1922:</b> Βαλαχής Σταύρος, Τουρλή Ευθαλία, Καραμάνης Στράτης.<br />
                    <b>Μουσικά τμήματα:</b> Γρηγορέλλη Κατερίνα,  Σταματέλλη –Σπανού Ρένα, Ευαγγέλου Βικτωρίτσα.<br /></Typography>

                <Typography variant="h3" sx={theme.typography.typography2}></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τοποθέτηση πινακίδων σήμανσης για το «Μουσείο Προσφυγικής Μνήμης 1922»</Typography>
                <img src={Image11} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Κατασκευάστηκαν και τοποθετήθηκαν 4 πινακίδες σήμανσης για το «Μουσείο Προσφυγικής Μνήμης 1922» αρχής γενομένης από την διασταύρωση του
                    δρόμου προς τα Λουτρά. Έτσι διευκολύνονται όσοι επισκέπτονται το Μουσείο μας και δεν γνωρίζουν καλά την περιοχή.<br /></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Το Δελφίνι σε ευρωπαϊκό πρόγραμμα.</Typography>
                <img src={Image12} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκαν με επιτυχία οι εργασίες της 5ης συνάντησης για το Ευρωπαϊκό σχέδιο <b>«Η Ευρώπη χρειάζεται ενεργούς πολίτες»</b> στο πλαίσιο του προγράμματος
                    Grundtvig – Εκπαίδευση Ενηλίκων, στο οποίο συμμετέχει ο σύλλογός μας μαζί με τις χώρες Πολωνία, Ιταλία, Πορτογαλία, Ισπανία, Τουρκία και Εσθονία.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Οι συναντήσεις έγιναν στο ξενοδοχείο ΖΑΙΡΑ της Σκάλας Λουτρών, με την συμμετοχή νέων, αλλά και ατόμων άνω των 50 ετών κατά την διάρκεια των οποίων έγινε
                    παρουσίαση στοιχείων του Ελληνικού πολιτισμού, μεταφορά εμπειριών από τους ηλικιωμένους στους νεότερους καθώς και παρουσίαση των δράσεων του Συλλόγου μας.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Όσοι συμμετείχαν είχαν την ευκαιρία να μάθουν για άλλες κουλτούρες, να συζητήσουν, αλλά και να διασκεδάσουν μαζί μας ανοίγοντας έτσι διαύλους επικοινωνίας
                    με ανθρώπους άλλων πολιτισμών.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Κατά την διάρκεια της παραμονής τους από 1 έως 7 Ιουλίου 2009 οι φιλοξενούμενοι επισκέφθηκαν, το <b>«Μουσείο Προσφυγικής μνήμης 1922»</b>  και την
                    Παναγιά την Αψηλή της Σκάλας Λουτρών, τον Μόλυβο, την Πέτρα, τον Ασώματο και τους Αγίους Αναργύρους. Επίσης περιηγήθηκαν την αγορά και την παλιά πόλη της Μυτιλήνης,
                    συμμετείχαν σε εκδρομή με ιστιοπλοϊκό σκάφος και παρακολούθησαν την συναυλία των Scorpions στο κάστρο της Μυτιλήνης, φεύγοντας με τις καλλίτερες εντυπώσεις από
                    το νησί μας</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Νέα εκθέματα στο Μουσείο Προσφυγικής Μνήμης 1922.</Typography>
                <img src={Image13} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Συνεχίστηκε και εφέτος με ταχύτερους ρυθμούς η προσφορά αξιόλογων αντικειμένων από Μικρασιάτες πρόσφυγες στο «Μουσείο Προσφυγικής Μνήμης 1922». Ήδη έχουν
                    προστεθεί αρκετές νέες βιτρίνες με πολύ αξιόλογα εκθέματα τα οποία ομολογουμένως θα εντυπωσιάσουν τους επισκέπτες μας.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ενδεικτικά αναφέρουμε την συλλογή με ενδυμασίες της αστικής Σμύρνης που μας χάρισε ο κ. Αλέξανδρος Χατζηδήμος καθώς και μία ταμπακέρα κατασκευασμένη σε
                    μοναστήρι της Σμύρνης (τέλη του 19ου αιώνα) η οποία ανήκε στον οικονομικό παράγοντα της Σμύρνης Στυλιανό Σεφέρη (Σεφεριάδης) πατέρα του νομπελίστα ποιητή
                    Γιώργου Σεφέρη (Νόμπελ λογοτεχνίας 1963). Μας την χάρησε ο κ. Τουτουντζίδης Θεόδωρος και την αφιερώνει στη μνήμη της καλής του φίλης Ντόρας Τσάτσου, ανιψιάς του
                    ποιητή και κόρης του πρώην Προέδρου της Δημοκρατίας Κωνσταντίνου Τσάτσου, που του την χάρισε το 1970. Η παραχώρηση στο μουσείο μας έγινε με τη συγκατάθεση του
                    Γιώργου Συμεωνίδη, γιου της Ντόρας Τσάτσου και βαφτισιμιού του Γιώργου Σεφέρη</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Λειτουργία στο εκκλησάκι του Αγίου Παντελεήμονα</Typography>
                <img src={Image14} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Την Δευτέρα 27 Ιουλίου 2009 εορτάσθηκε με λαμπρότητα και πλήθος πιστών από τη Σκάλα Λουτρών,  τα Λουτρά και τη Μυτιλήνη η εορτή του Αγίου
                    Παντελεήμονα στο ομώνυμο εκκλησάκι του οικισμού μας στη Σκάλα Λουτρών.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Κάθε χρόνο όλο και περισσότεροι πιστοί προσέρχονται για να προσευχηθούν και να θαυμάσουν
                    το γραφικό αυτό εκκλησάκι του οικισμού μας το οποίο ως γνωστό έχει ανακαινισθεί πλήρως με την βοήθεια των κατοίκων και του Συλλόγου μας ο οποίος εξακολουθεί
                    να το φροντίζει και να το συντηρεί  όπου είναι απαραίτητο. Αυτή τη φορά η λειτουργία έγινε με την παρουσία δύο ιερέων του πατέρα Παναγιώτη εφημέριου Αγίου
                    Γεωργίου Λουτρών και του πατέρα Κομνηνού εφημέριου Αγίων Θεοδώρων Μεραρχίας. Μετά την λειτουργία έγινε για πρώτη φορά εφέτος η λιτανεία της ιεράς εικόνος με
                    την συμμετοχή όλων των πιστών.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Θερινή χοροεσπερίδα και βράβευση αριστούχων μαθητών.</Typography>
                <img src={Image15} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε με επιτυχία η χοροεσπερίδα του Συλλόγου μας το Σάββατο 8-8-2009  στο κτήμα <b>«Ελιά και Θάλασσα»</b> του ξενοδοχείου ΖΑΙΡΑ στη Σκάλα Λουτρών,
                    κατά την διάρκεια της ποίας πραγματοποιήθηκε κrαι η βράβευση των αριστούχων μαθητών του χωριού μας.<br /><br />

                    Βραβεύτηκαν οι παρακάτω:<br /><br />
                    <b>Κοτοπούλη Μαρία</b> από την    Α!    γυμνασίου<br />
                    <b>Κούτλα Άννα</b> από την    Α!    γυμνασίου<br />
                    <b>Καρακατσάνη Ειρήνη</b> από την    Α!    γυμνασίου<br />
                    <b>Μωυσιάδου Βασιλική</b> από την    Α!    γυμνασίου<br />
                    <b>Τασιοπούλου Παναγιώτα</b> από την    Α!    γυμνασίου<br />
                    <b>Ευαγγέλου Βασιλική</b> από την    Β!    γυμνασίου<br />
                    <b>Πατέστου Χρυσοβαλλάντη</b> από την    Β!    γυμνασίου<br />
                    <b>Ταλιαντζή Κατερίνα</b> από την    Β!    γυμνασίου<br />
                    <b>Σφακιανός Κωνσταντίνος</b> από την    Γ!    γυμνασίου<br />
                    <b>Κρητικού Αφροδίτη</b> από την     Β!     λυκείου <br />
                    <b>Παπαχρυσού Παναγιώτα</b> από την     Β!     λυκείου <br /></Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Επίσης εδόθησαν έπαινοι σε έξη παιδιά του Δημοτικού Σχολείου Λουτρών [ένα από κάθε τάξη] γιατί πρώτευσαν σε διαγωνισμό με θέμα <b>"το περιβάλλον και την
                    ανακύκλωση"</b> ο οποίος είχε πραγματοποιηθεί σε συνεργασία με τον Σύλλογό μας την 5η Ιουνίου παγκόσμια ημέρα για το περιβάλλον. Οι μαθητές που βραβεύτηκαν είναι
                    οι παρακάτω.<br /><br />

                    1.	Από την  Α! τάξη ο <b>Κέντσης Μαρίνος </b><br />
                    2.	Από την  Β! τάξη η <b>Μωυσιάδου Αλεξάνδρα </b><br />
                    3.	Από την  Γ! τάξη ο <b>Αχλάδας Παναγιώτης </b><br />
                    4.	Από την  Δ! τάξη ο <b>Βερβέρης Αντώνης </b><br />
                    5.	Από την  Ε! τάξη η <b>Δράκου Χαρίκλεια </b><br />
                    6.	Από την  ΣΤ! τάξη ο <b>Μεταξάς Ερμογένης </b><br /> </Typography>


                <Typography variant="h3" sx={theme.typography.typography2}>Τέλος έπαινος δόθηκε και στην μαθήτρια της ΣΤ! Δημοτικού <b>Δωροθέα Ζαραδούκα</b> η οποία διακρίθηκε σε παλεσβιακό επίπεδο για την έκθεσή της με θέμα:
                    <b>"Γράφω μια επιστολή στον πρόεδρο του Ελληνικού Κοινοβουλίου και προτείνω μέτρα τα οποία θα βελτιώσουν την ποιότητα της Δημοκρατίας".</b></Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Κατά την διάρκεια της εκδήλωσης έγινε και η κλήρωση των δώρων της λαχειοφόρου αγοράς του Συλλόγου μας και κέρδισαν οι παρακάτω αριθμοί.<br /><br />
                    1ος  λαχνός  το νούμερο  <b>213</b>  κερδίζει  το ποδήλατο. <br />
                    2ος  λαχνός  το νούμερο  <b>535</b>  κερδίζει το  DVD.<br />
                    3ος  λαχνός  το νούμερο   <b>733</b>  κερδίζει το ραδιόφωνο<br />
                    4ος  λαχνός  το νούμερο  <b>925</b>  κερδίζει τον ανεμιστήρα.<br />
                    Επίσης οι αριθμοί <b>764</b> και  <b>622</b> κέρδισαν από ένα εισιτήριο για την συναυλία του Δημ. Μητροπάνου  προσφορά του  προέδρου της ΟΛΣΑ κ. Χριστόδουλου
                    Τσακυρέλλη.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Θεία Λειτουργία στην Παναγιά την Αψηλή.</Typography>
                <img src={Image16} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Όπως κάθε χρόνο έτσι και φέτος την Κυριακή 23 Αυγούστου εορτή της Κοιμήσεως της Θεοτόκου (εννιάμερα) ετελέσθη Θεία Λειτουργία στο εκκλησάκι της Παναγίας
                    της Αψηλής στην κορυφή του λόφου του Συνοικισμού της Σκάλας Λουτρών με την παρουσία πολλών κατοίκων της Σκάλας Λουτρών, των Λουτρών αλλά και κατοίκων από τα χωριά
                    της Γέρας οι οποίοι έρχονται με τη βάρκα κάθε χρόνο αυτήν την ημέρα στην Παναγιά την Αψηλή.<br /><br /><br /><br /><br /><br /><br /></Typography>

                <img src={Image17} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Το Σάββατο παραμονή της εορτής ετελέσθη πανηγυρικός εσπερινός στις 8 το βράδυ στο παρεκκλήσι της Παναγίας στον Συνοικισμό  και επακολούθησε για πρώτη φορά
                    λιτάνευση της εικόνος εντός του χωριού, η οποία κατέληξε στην αυλή του «Μουσείου Προσφυγικής Μνήμης 1922» όπου και έγινε αρτοκλασία και δέηση υπέρ αναπαύσεως των
                    ψυχών των Μικρασιατών αδελφών μας.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Μετά την αρτοκλασία ο ιερέας ευλόγησε τα φαγητά που είχαν φροντίσει να μαγειρέψουν από νωρίς οι νοικοκυρές του χωριού (Ρεβύθια με κρέας). Τον
                    πανηγυρικό της ημέρας για την Παναγία, μας διάβασε το  μέλος του Συλλόγου μας η δικηγόρος Ειρήνη Σταματέλλη. Τέλος ο κόσμος αφού ασπάσθηκε την εικόνα της
                    Παναγίας μοιράστηκε το φαγητό μαζί με κρασί Λήμνου, προσφορά του συγχωριανού μας Δημήτρη Ζαραδούκα. Και του χρόνου με υγεία.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Αγορά οπτικοακουστικού υλικού και βιτρινών στο Μουσείο.</Typography>
                <img src={Image18} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Από τον περασμένο Μάιο το Δ.Σ. του Συλλόγου μας αποφάσισε και εγκατέστησε στον χώρο του «Μουσείου Προσφυγικής Μνήμης 1922» οπτικοακουστικό υλικό για τις
                    ανάγκες της πληρέστερης ενημέρωσης κυρίως  των μαθητών των σχολείων που μας επισκέπτονται. Αυτό αποτελείτε από μία τηλεόραση LCD 40 ιντσών επίτοιχη, έναν
                    ηλεκτρονικό υπολογιστή και μία οθόνη αφής. Μέσα σ αυτό το υλικό έχουν αποθηκευθεί εκπαιδευτικά προγράμματα κυρίως ιστορικά, σχετικά με την Μικρά Ασία, καθώς
                    και διάφορα παραδοσιακά τραγούδια, χοροί, στολές κ.τ.λ. Με αυτή την εγκατάσταση πιστεύουμε ότι έχουμε κάνει ένα ακόμη βήμα εκσυγχρονισμού στο «Μουσείο
                    Προσφυγικής Μνήμης 1922».</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Επίσης 8 νέες βιτρίνες προστέθηκαν στο χώρο του Μουσείου και τοποθετήθηκαν σ αυτές νέα, πολύ αξιόλογα κειμήλια των Μικρασιατών προσφύγων του 1922.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Ομάδα «Μουσείο Προσφυγικής Μνήμης 1922» και στο Facebook.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Όσοι από τα μέλη του Συλλόγου μας έχετε κάνει προσωπική σελίδα στο facebook μπορείτε να γίνετε μέλη της <b>ομάδας «Μουσείο Προσφυγικής Μνήμης 1922»</b>
                    και να απολαύσετε από όπου και αν βρίσκεστε πολλές από τις φωτογραφίες των αντικειμένων που υπάρχουν στο Μουσείο μας καθώς και να το διαδώσετε στους φίλους σας.
                    Αξίζει τον κόπο.</Typography>
            </Box>


            <ScrollToTopButton />



            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εκδηλώσεις μνήμης.</Typography>
                <img src={Image19} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Την Κυριακή 20 Σεπτεμβρίου 2009 πραγματοποιήθηκαν στη Μυτιλήνη οι εκδηλώσεις μνήμης για την γενοκτονία των Ελλήνων της Μικράς Ασίας στο πλαίσιο της 87ης επετείου
                    από τη Μικρασιατική καταστροφή. Κατά την διάρκεια των εκδηλώσεων ετελέσθη δοξολογία και αρτοκλασία στον ιερό ναό του Αγίου Νικολάου στην Επάνω Σκάλα και τον
                    πανηγυρικό της ημέρας εκφώνησε η κ. Μαρία Καραλή, μέλος του Δ.Σ. και έφορος εκδηλώσεων του Εξωραϊστικού – Εκπολιτιστικού Συλλόγου «Η Επάνω Σκάλα». Μετά την
                    δοξολογία επακολούθησε επιμνημόσυνη δέηση και κατάθεση στεφάνων στον χώρο του αγάλματος της Μικρασιάτισσας Μάνας με την συμμετοχή της Στρατιωτικής Μουσικής της
                    98 ΑΔΤΕ. Το Δ.Σ. του Συλλόγου μας παραβρέθηκε τόσο στην τέλεση της δοξολογίας στον Ιερό Ναό του Αγίου Νικολάου στην επάνω Σκάλα όσο και στην συνέχεια με κατάθεση
                    στεφάνου στο άγαλμα της Μικρασιάτισας μάνας μαζί με τους υπόλοιπους Συλλόγους και τις αρχές του τόπου.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Όπως είναι γνωστό η 14η Σεπτεμβρίου καθιερώθηκε με το νόμο  2645/1998, ως ημέρα μνήμης της γενοκτονίας των Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος.
                    Η Μικρασιατική καταστροφή δεν είναι ένα απλό ιστορικό γεγονός. Αντιπροσωπεύει μια πραγματική τομή για την Ελληνική Ιστορία. Γιατί πέρα από τα πραγματικά γεγονότα,
                    επέφερε παράλληλα και μια ανανέωση στις δομές της Ελληνικής Κοινωνίας και στον πολιτισμό της. </Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Η Μικρασιατική καταστροφή είναι γεγονός ότι καταγράφεται στις ποιο θλιβερές σελίδες του Ελληνικού Έθνους, στιγματίστηκε από την απώλεια ανθρώπων
                    και πατρίδων, δημιούργησε πρόσφυγες και σκόρπισε δυστυχία. Αυτοί οι πρόσφυγες πληγωμένοι από τη βαρβαρότητα και τη φρίκη ήρθαν στην Ελλάδα αγωνίστηκαν για την
                    επιβίωσή τους και συνέβαλαν με τους αγώνες τους στην ανανέωση και στην πρόοδο της Ελληνικής κοινωνίας.</Typography>
            </Box>


            <ScrollToTopButton />

            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εορτή Αγίου Νικολάου</Typography>
                <img src={Image29} height="225" style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Με μεγάλη λαμπρότητα και με την συμμετοχή όλων των κατοίκων του χωριού μας, πραγματοποιήθηκαν οι εκδηλώσεις για την εορτή του Αγίου Νικολάου, προστάτη των ναυτικών
                    και των αλιέων, που διοργανώσαμε μαζί με τους κατοίκους αναβιώνοντας για δεύτερη χρονιά το παλιό τοπικό έθιμο.</Typography>
                    <img src={Image29} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Με την παρουσία, του εφημέριου της ενορίας Αγίου Γεωργίου Λουτρών, παπά Παναγιώτη και  στρατιωτικού αγήματος από την μονάδα καταδρομέων του Περάματος ετελέσθη η
                    Θεία Λειτουργία και στη συνέχεια έγινε η λιτάνευση της εικόνας του Αγίου Νικολάου με κατεύθυνση το λιμανάκι της Σκάλας Λουτρών. Μετά την ευλογία των άρτων ο ιερέας
                    και το στρατιωτικό άγημα επιβιβάσθηκαν στη βάρκα του κ. Ευστράτιου Καραντώνη από την Κουντουρουδιά  και κρατώντας την εικόνα του Αγίου την περιέφεραν, ρίχνοντας το
                    στεφάνι που την πλαισίωνε στην θάλασσα και ευχόμενοι ήρεμα ταξίδια για τους ναυτικούς και τους αλιείς μας.</Typography>


                <Typography variant="h3" sx={theme.typography.typography2}>Μετά το πέρας των θρησκευτικών εκδηλώσεων τα μέλη του Συλλόγου κατέληξαν στην ταβέρνα του Οδυσσέα Παπαμακαρίου όπου γευμάτισαν και γλέντησαν συνοδεία
                    παραδοσιακής ορχήστρας με νησιώτικα τραγούδια μέχρι αργά το βράδυ.</Typography>
                
                <img src={Image30} height="225" style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Πολλές επισκέψεις στο «Μουσείο Προσφυγικής Μνήμης 1922».</Typography>
                <img src={Image20} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Κάθε χρόνο και καλλίτερα αφού, οι επισκέπτες που έρχονται στο Μουσείο μας, είναι όλο και περισσότεροι και είναι αλήθεια πως ξεπέρασαν κάθε προσδοκία μας. Όμως οι
                    δυσκολίες που αντιμετωπίζουμε σχετικά με τις ώρες λειτουργίας του είναι ανυπέρβλητες αφού παρά τις προσπάθειες που κάναμε δεν κατέστη δυνατόν να μας δοθεί όπως και
                    πέρυσι, ένας υπάλληλος με το πρόγραμμα STAGE, για να είναι ανοικτό το Μουσείο και τις πρωινές ώρες. Έτσι αυτή τη στιγμή εκτός από τις απογευματινές ώρες που σχεδόν
                    πάντοτε υπάρχει κάποιο μέλος του Συλλόγου και το κρατά ανοικτό, θα πρέπει για διαφορετικές ώρες, οι ενδιαφερόμενοι να έρχονται σε επικοινωνία με τα τηλέφωνα
                    22510.91170 κ. Ευθαλία Τουρλή και 69746.40419 κ. Δημήτρη Παπαχρυσό για να εξυπηρετούνται.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Κατά την διάρκεια του τρέχοντος έτους εκτός από τους επισκέπτες και τους ξένους τουρίστες, πολλές ήταν οι μορφωτικές επισκέψεις διαφόρων σχολείων και φορέων που
                    μας επισκέφθηκαν.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ενδεικτικά αναφέρουμε το Δημοτικό σχολείο και το νηπιαγωγείο Λουτρών, το Δημοτικό σχολείο Ταξιαρχών, το Δημοτικό σχολείο Αγιάσου, το Δημοτικό σχολείο Πλωμαρίου,
                    το Δημοτικό σχολείο Παμφίλων, αρκετά από τα Δημοτικά σχολεία της Μυτιλήνης,  καθώς και πολλοί φοιτητές από το Πανεπιστήμιο Αιγαίου και κυρίως από το τμήμα
                    Πολιτισμικής Επικοινωνίας και Τεχνολογίας.
                    Και όλα αυτά χωρίς να έχει υπάρξει σχετική διαφημιστική προβολή.
                    Οι κολακευτικές αναφορές στο βιβλίο των επισκεπτών είναι πολλές και μας δίνουν το κουράγιο να συνεχίσουμε την προσπάθειά μας αυτή.
                    Ενδεικτικά αναφέρουμε μερικές από αυτές.<br /><br />
                    <b>19-10-2009.</b> Ένα Μουσείο είναι ο καλλίτερος τρόπος να μάθεις την ιστορία του τόπου σου. Σας ευχαριστούμε πολύ, τα αγόρια της ΣΤ! τάξης του 10ου δημοτικού
                    σχολείου Μυτιλήνης.<br /><br />
                    <b>5-10-2009.</b> Η συγκίνηση της προσφυγιάς είναι τόσο μεγάλη μέσα σ αυτό το χώρο, που με έφερε 100 χρόνια πίσω και μπορώ να σας διαβεβαιώσω με ειλικρίνεια ότι μέσα
                    εδώ αισθάνομαι όλες τις ψυχές των σφαγιασθέντων αδελφών μας στα ματωμένα χώματα. Δεν μπορώ να εκφράσω τα αισθήματα μου, δεν μπορώ να βρω το μέτρο, γιατί
                    έζησα και ζω την προσφυγιά της Ίμβρου μας. Δεν υπάρχουν και δεν φτάνουν οι λέξεις να δηλώσω τα αισθήματα της προσφυγιάς.<br />
                    Από την Ίμβρο Γιώργος Ζουμπουλάκης.<br /><br />
                    <b>6-9-2009.</b> Είναι θετικό όταν στοιχεία του παρελθόντος παρουσιάζονται χωρίς εθνικιστική αντιπαράθεση. Έτσι αναδεικνύουν την ανωτερότητα του αγγέλματος του Χριστού.
                    Πράξεις 10:34.35
                    Σάκης και Ισιδώρα.<br /><br />
                    <b>5-9 2009.</b> Το Μουσείο σας είναι θαυμάσιο. Συγκινήθηκα βαθύτατα από τη δουλειά, το μεράκι και το συναίσθημα αυτών που έστησαν αυτή την έκθεση. Μπράβο που
                    καταθέσατε την ιστορία των οικογενειών σας και της ζωής σας. Ένα κομμάτι από την Ελληνική ιστορία.
                    Αγλαΐα Αρχοντίδου.<br /><br />
                    <b>20-8-2009.</b> Με μεγάλη συγκίνηση καταθέτω τα συγχαρητήρια και τα μπράβο που αξίζουν σε όλους σας, για την ιδέα και την προσπάθεια να κρατήσετε τις μνήμες ζωντανές.
                    Χίλια ευχαριστώ.
                    Καίτη Μεσσηνέζη - Πλατσή.<br /><br />
                    <b>20-8-2009.</b> Η σύζυγός μου και εγώ εντυπωσιαστήκαμε από το Μουσείο Προσφυγικής Μνήμης 1922 του Συλλόγου Μικρασιατών Σκάλας Λουτρών Λέσβου «ΤΟ ΔΕΛΦΙΝΙ». Συγχαίρουμε
                    όλους τους δημιουργούς αυτού του μουσείου, με όλη μας την καρδιά.
                    Απόστολος Γρημάνης, Δρ χημικός Τμ. πυρηνικής χημείας Δημόκριτου.<br /><br />
                    <b>20-8-2009.</b> Έμπλεος συγκίνησης επισκέφθηκα στις 20-8-2009 το Μουσείο Μικρασιατικού Ελληνισμού και ενωτίστηκα ακμάζουσες μνήμες από τον ξεριζωμό των Ελλήνων της
                    Μικρασιατικής Ιωνίας. Συγχαίρω όλους τους φορείς της δημιουργίας αυτού του Μουσείου που με αφοσίωση, αυταπάρνηση και επιμέλεια μέλισσας έστησαν στη Σκάλα Λουτρών ένα
                    μνημείο ιστορικής και προσφυγικής μνήμης. Θερμά συγχαρητήρια.
                    Καθηγητής Δημήτρης Νικορέτζος, φυσικός, (συγγραφέας και λογοτέχνης).<br /><br />
                    <b>2-6-2009.</b> Αυτό που αντικρίσαμε όταν μπήκαμε στο Μουσείο Μικρασιατικού πολιτισμού δεν περιγράφετε με λίγα λόγια. Χρειάζονται πολλά και πάλι δεν μπορούμε να
                    γράψουμε αυτά που νιώσαμε. Συγκίνηση, θαυμασμός μ έκπληξη, νοσταλγία, λύπη για τους πρόσφυγες. Νιώσαμε την παρουσία τους δίπλα μας. Μάθαμε τον πολιτισμό τους,
                    γνωρίσαμε την κουλτούρα τους. Συγχαρητήρια για τους ανθρώπους που διατήρησαν αυτό τον υπέροχο πολιτισμό.
                    Οι μαθητές της Δ! τάξης του 3ου δημοτικού σχολείου Πλωμαρίου.<br /><br />
                    <b>13-5-2009.</b> Λαός χωρίς παρελθόν δεν έχει μέλλον. Οι μνήμες κρατάνε ζωντανές τις ρίζες. Θερμά συγχαρητήρια για την προσπάθειά σας. Σας ευχαριστούμε για πράγματα
                    και γεγονότα που μας μάθατε και μας θυμίσατε. Ευχόμαστε να έχετε πάντα τη δύναμη και την όρεξη για να μην ξεχνάμε.
                    Οι μαθητές του δημοτικού σχολείου Αγιάσου. <br /><br />
                    <b>13-5-2009.</b> Χαίρομαι διότι, στον τόπο αυτό που έχω εγκατασταθεί προερχόμενη από την Αλεξανδρούπολη, βρήκα ένα Μουσείο μνήμης αντίστοιχο με της πατρίδας μου που
                    είναι το Εθνολογικό Μουσείο Θράκης. Αυτά τα μουσεία αγγίζουν τις ψυχές όλων αυτών που η καταγωγή τους είναι από τα απέναντι παράλια και αξίζει ένα μεγάλο μπράβο και
                    ένα ευχαριστώ στους ανθρώπους που εμπνεύστηκαν την δημιουργία τους και την έκαναν πράξη.
                    Ελένη Κουφού - Διακορώνα, εκπαιδευτικός, υπεύθυνη πολιτιστικών θεμάτων Α!βάθμιας εκπαίδευσης.<br /><br />
                    <b>25-1-2009.</b> Στην τέταρτη και όπως υποψιάζομαι και στην εκατοστή τέταρτη φορά που επισκέπτομαι το χώρο αυτό, συγκινούμε το ίδιο πολύ… Συγχαρητήρια θερμά από
                    τα βάθη της ψυχής μου.
                    Μαριάνθη Λάμπρου - Ιωαννίδου.<br /><br />
                    <b>11-12-2008.</b> Κάθε βιτρίνα και γωνιά του Μουσείου είναι και μια μνήμη η και αχνός πόνος για ότι υπήρχε στη Μικρασία και χάθηκε. Είναι κι ένας αναλογισμός για
                    την αντοχή και δημιουργικότητα των προσφύγων που ξαναβρήκαν τον βηματισμό τους στη φιλόξενη Λέσβο.
                    Π. Παρασκευαίδης, Φιλόλογος.<br /><br />
                    <b>6-12-2008.</b> Συγκλονισμένος στάθηκα μπροστά στη ζωντανή ιστορία των αδελφών Μικρασιατών, στο καταπληκτικό Μουσείο της Σκάλας Λουτρών που η αγάπη και η
                    φιλοπατρία των κατοίκων έστησε ένα μνημόσυνο αιώνιο. Συγχαρητήρια στους πρωτεργάτες και σε όσους συνέβαλαν στην διατήρηση της λαμπρής ιστορίας που θα πρέπει να
                    παραδοθεί στις επόμενες γενιές. Αιωνία η μνήμη των μαρτύρων αδελφών. Υποκλίνομαι μπροστά τους.
                    Πατήρ Κομνηνός.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εκδήλωση του μουσικού τμήματος του Συλλόγου μας.</Typography>
                <img src={Image21} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Στον αύλειο χώρο του «Μουσείου Προσφυγικής Μνήμης 1922» της Σκάλας Λουτρών, το βράδυ της Κυριακής 6 Σεπτεμβρίου 2009, παρουσίασαν τη δουλειά της
                    χρονιάς που πέρασε τα μουσικά τμήματα του Συλλόγου μας. Στην εκδήλωση έπαιξαν και τραγούδησαν διάφορα Ελληνικά και ξένα τραγούδια οι μαθητές και μαθήτριες,
                    Αφροδίτη Κρητικού,  Παναγιώτα Παπαχρυσού, Βατζάκα Μαριλένα, Βατζάκα Χριστίνα, Εγγλέζου Μελπομένη, Καλιοντζής Θεόδωρος, Κουκώνης Ιωάννης, Μεταξάς Γεώργιος και
                    Μεταξάς Ερμογένης, με τον δάσκαλο του τμήματος κιθάρας τον κ. Γρηγόρη Μολυβιάτη.<br /></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>

                <Typography variant="h1" sx={theme.typography.typography1}>Το ημερολόγιό μας για το 2010.</Typography>
                <img src={Image22} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Με τίτλο <b>«Λογοτεχνικό ταξίδι στη Λέσβο του χθες»</b> κυκλοφόρησε το ημερολόγιο του Συλλόγου μας για το έτος 2010. Το αφιερώσαμε στους δημιουργούς της
                    «Αιολικής άνοιξης» σ’ αυτή την μεγάλη πολιτιστική μας περιουσία. Επιλέξαμε 12 Λέσβιους λογοτέχνες με έντονη προσφορά στην πνευματική και κοινωνική ζωή της Λέσβου
                    και της Ελλάδας τιμώντας όμως στο πρόσωπό τους όλους τους Λέσβιους δημιουργούς του παρελθόντος αλλά και του παρόντος.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Πρόκειται για επιτραπέζιο ημερολόγιο, το έβδομο στη σειρά από αυτά που εκδίδει ο Σύλλογός μας τα τελευταία χρόνια, είναι συλλεκτικό κομμάτι και είναι πλαισιωμένο
                    με φωτογραφίες της Μυτιλήνης των αρχών του περασμένου αιώνα.
                    Θεωρούμε υποχρέωσή μας να ευχαριστήσουμε την Νομαρχιακή Αυτοδιοίκηση Λέσβου και ιδιαιτέρως τον Νομάρχη Λέσβου κ. Παύλο Βογιατζή για την βοήθειά τους στην έκδοση του
                    ημερολογίου αυτού καθώς και όλους τους λοιπούς χορηγούς  που κάθε χρόνο συμβάλουν οικονομικά στην έκδοση των ημερολογίων μας.<br /><br />
                    ΕΙΣΑΓΩΓΗ<br />
                    <b>Η Λέσβος είναι πάντα ωραία. «Είναι ένα κομμάτι από την ουσία του ουρανού και της θάλασσας που κρυστάλλωσε κι έγινε γης. Η Θεότητα της Μεσογείου έκανε να φυτρώνουν
                        απάνω της ροδοδάφνες κι ελιές, για να δίνουν, με τα φύλλα τους, μια γλυκύτερη νότα στο αιώνιο τραγούδι των νερών και του αγέρα».</b> Τούτα τα λόγια του αξέχαστου
                    θυμόσοφου και εκλεκτού συγγραφέα Ασημάκη Πανσέληνου δεν είναι καθόλου μακριά απ’ την πραγματικότητα.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}> Είναι, αυτή η θεϊκή επίδραση που από τότε ενέπνευσε και θα συνεχίσει να εμπνέει το πνεύμα των ανθρώπων που έχουν τη τύχη να γεννιούνται πάνω σ’ αυτό το ευλογημένο
                    νησί. Ένα απίθανο είδος ανθρώπων με λογής επαγγέλματα, και ταυτόχρονα λογοτέχνες, απλοί και μαστόροι στο γράψιμο, σπουδαγμένοι κι ασπούδαστοι, σημαντικές μορφές που
                    έδωσαν μια καλλιτεχνική ζωτικότητα σ’ ολόκληρο το νησί που την ονόμασαν <b>«Λεσβιακή Άνοιξη».</b> Και « ...<b>δεν μπορούσες να ανήκεις στη Λεσβιακή Άνοιξη χωρίς να είσαι
                        Μυτιληνιός, μηδέ να είσαι Μυτιληνιός χωρίς να είσαι σπουδαίος </b>» έλεγε ακόμα ο Ασημάκης Πανσέληνος.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Μια ατελείωτη Άνοιξη που ανθοφόρησε τότε και συνεχίζει «εν διαρκή καρποφορία». Κίνητρο δυνατό, αναμφίβολα, το Γυμνάσιο της Μυτιλήνης με καθηγητές άξιους και με
                    υψηλή προσωπικότητα που προσπαθούσαν να συμβάλλουν στην ανάταση της ψυχής, να δώσουν περιεχόμενο στη ζωή της νεολαίας, να δημιουργήσουν ένα πνεύμα ανεξαρτησίας και
                    να τεθούν τα θεμέλια σ’ ένα κίνημα πνευματικής αναγέννησης.	Αποκορύφωμα, σίγουρα, η πνευματική κίνηση των χρόνων μετά την Μικρασιατική καταστροφή, γύρω στο 1925
                    όταν εμφανίζεται η κατά τα άλλα παλαβοπαραία γνωστή σαν η <b>«Ορδή των Βασιβουζούκων».</b> Τι τίτλος κι αυτός! Έδρα τους τα ουζάδικα της Μυτιλήνης. Τι άνθρωποι κι
                    αυτοί που μπροστά τους όλη η Ελλάδα γονάτισε με σεβασμό. «Με μια αιολική πνευματική λεβεντιά, που πάει αντάμα με την υπεροψία της ράτσας και το πάθος για την τέχνη».
                    Αναμφίβολα όλοι κατέχουν, μια ζηλευτή θέση, στην πορεία της νεοελληνικής λογοτεχνίας.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Τις ευχαριστίες μας στον Μυτιληνιό κ. Θεόδωρο Πελεκάνο για το φωτογραφικό υλικό που μας παραχώρησε με ιδιαίτερη προθυμία από τα αρχεία του, καθώς και
                    στον καθηγητή-φιλόλογο κ. Παναγιώτη Σκορδά για την επιλογή και την επιμέλεια των κειμένων του ημερολογίου αυτού. Τις ιδιαίτερες ευχαριστίες μας για άλλη μια φορά
                    προς το νομαρχιακό συμβούλιο Λέσβου και προσωπικά στο νομάρχη κ. Παύλο Βογιατζή, πιστούς συνοδοιπόρους επτά  χρόνια τώρα στις εκδόσεις αυτών των συλλεκτικών
                    ημερολογίων του Συλλόγου μας.<br /><br />

                    Το Διοικητικό Συμβούλιο του Συλλόγου Μικρασιατών της Σκάλας Λουτρών Λέσβου.</Typography>

            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Λοιπές δραστηριότητες.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}><br />
                    <b>1.</b> Συντηρήθηκε το κτίριο του Μουσείου με βάψιμο των τοίχων και των κάγκελων της αυλής, επίσης έγινε επισκευή της υγρομόνωσης της ταράτσας.<br />
                    <b>2.</b> Έγινε επισκευή της μπασκέτας του γηπέδου μας με αντικατάσταση του πλεξιγκλάς και της βάσης, με έξοδα που κάλυψε ο ΝΑΟΛ ύστερα από αίτημά μας..<br />
                    <b>3.</b> Αγοράσθηκε ένα DVD Writer για την βιβλιοθήκη μας.<br />
                    <b>4.</b> Εστάλη στον τύπο επιστολή στήριξης του πρύτανη του πανεπιστημίου Αιγαίου κ. Ανδρέα Τρούμπη.<br />
                    <img src={Image23} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                    <br/>
                    <b>5.</b> Εστάλη επιστολή στην ΕΚΟ με την οποία ζητάμε την αποψίλωση των κενών πλέον δεξαμενών καυσίμων που υπάρχουν εδώ και ρυπαίνουν τόσο οπτικά το τοπίο της περιοχής
                    μας, όσο και ουσιαστικά το περιβάλλον, από την οξείδωση λόγω εγκατάλειψης της συντήρησης σ αυτές.<br />
                    <b>6.</b> Πραγματοποιήθηκε εκδήλωση κοπής πίτας για τα μέλη του Συλλόγου μας στις 8-2-2009 στο ξενοδοχείο ΖΑΙΡΑ με κλήρωση δώρων και κέρασμα αναψυκτικών και καφέ στα
                    μέλη μας.<br />
                    <b>7.</b> Έγινε επανεκτύπωση ενός φυλλαδίου του 1958 με τίτλο «Γνωριμία με την Καινή Διαθήκη»το οποίο μοιράστηκε δωρεάν κατά την διάρκεια της χοροεσπερίδας του Συλλόγου
                    μας στις 31-12-2008.<br />
                    <b>8.</b> Επανεκδώσαμε το βιβλίο «Χρυσόστομος ο Σμύρνης», του Κώστα Πολίτη, το οποίο αποτελεί πιστή ανατύπωση της εκδόσεως του 1934 της εφημερίδος «ΠΑΤΡΙΣ».
                    Το πρωτότυπο βιβλίο βρισκόταν στην κατοχή του ιατρού κ. Αλέξανδρου Χατζηδήμου από τον οποίο και το δανειστήκαμε για να το επανεκδώσουμε. Ο Σύλλογός μας θέλοντας
                    να μεταφέρει στις νέες γενιές το μέγιστο ηθικό του παράδειγμα επανεκτύπωσε αυτό το βιβλίο στην ιερή του μνήμη. Το βιβλίο διατίθεται αποκλειστικά από το Σύλλογό μας
                    στην τιμή των 15 ευρώ. Στα σχολεία που θα μας το ζητήσουν για τη βιβλιοθήκη τους το διαθέτουμε δωρεάν.<br /></Typography>
                <Typography variant="h3" sx={theme.typography.typography2}></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα Αρμονίου.</Typography>
                <img src={Image21} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Λειτούργησε μέχρι τον Ιούνιο του 2007 οπότε και σταμάτησε λόγω έλλειψης μαθητών. Το τμήμα θα επαναλειτουργήσει μόλις εκδηλωθεί σχετικό ενδιαφέρον.
                    Όσοι λοιπόν ενδιαφέρονται για τα παιδιά τους μπορούν να το δηλώσουν στο τηλ. 22510 91231 κ. Παπαχρυσό Δημ. η στο 22510 91302 κ. Γρηγορέλλη Κατερίνα. Η μηνιαία
                    συνδρομή είναι 20 ευρώ και για να λειτουργήσει ένα τμήμα πρέπει να υπάρξει ενδιαφέρον από τρεις τουλάχιστον μαθητές.<br /></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα κιθάρας.</Typography>
                <img src={Image24} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Λειτουργούν κάθε Σάββατο από τις 2 το μεσημέρι μέχρι τις 5 το απόγευμα και όσοι ενδιαφέρονται για τα παιδιά τους μπορούν να το δηλώσουν στα
                    ανωτέρω προαναφερθέντα τηλέφωνα . Η μηνιαία συνδρομή είναι και εδώ 20 ευρώ και δάσκαλος είναι ο κ. Μολυβιάτης Γρηγόρης ο οποίος επί πολλά χρόνια έχει προσφέρει
                    εξαιρετικό έργο στα παιδιά του Συλλόγου μας.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα παραδοσιακών χορών και τραγουδιών.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>O Σύλλογός μας προτίθεται να επαναλειτουργήσει τμήματα παραδοσιακών χορών και τραγουδιών από τη Μ. Ασία γι αυτό όσοι ενδιαφέρονται μικροί και μεγάλοι
                    μπορούν να έλθουν σε συνεννόηση με την κ. Ευθαλία Τουρλή στο τηλ. 22510 91170 για να το δηλώσουν. </Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Νέα τμήματα</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας προτίθεται να ιδρύσει νέα τμήματα παραδοσιακών μουσικών οργάνων εφ όσον εκδηλωθεί σχετικό ενδιαφέρον από τα μέλη μας. Διευκρινίζουμε
                    ότι για να λειτουργήσει ένα νέο τμήμα θα πρέπει να ενδιαφερθούν κατ ελάχιστο τρία άτομα.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Δωρεά βιβλίων στο Σύλλογό μας.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ευχαριστούμε ιδιαιτέρως τον κ. Βασίλη Βόμβα με την σύζυγό του Άντα για τα πολλά και εξαιρετικού ενδιαφέροντος βιβλία που μας χάρισαν και που υπερβαίνουν τα 500.
                    Για την τακτοποίησή τους ο Σύλλογός μας έχει παραγγείλει έξτρα έπιπλο βιβλιοθήκης για να τοποθετηθούν. Επίσης ευχαριστούμε θερμά  τους  κ.κ. Κλήμη Αριστείδη ,
                    Αγιακάτσικα Παναγιώτη, Χατζηδήμο Αλέξανδρο, Πρίμο Γεώργιο, Μεσηνέζη Καίτη, Θωμαίδη Πλαστήρα, Σταματέλλη Αγγελική, Ταμβάκη Ανδρέα, Πολυχρονιάδη Θανάση, Σαμαρίδη Μαρία,
                    Γονιδέλλη Γεώργιο, Γκαγκάνη Δέσποινα, Παπαχρυσού Αγγελική, Περγαμηνού Μαρία, Σκορδά Παναγιώτη, Λενέτα Στράνη, Ψαλτόπουλο Μιχάλη, τη Νομαρχία Λέσβου, το Σύλλογο
                    Μεσοτοπιτών, το WWF, και τα τυπογραφεία Δημητρίου Δούκα και Γεωργίου Παπαχρυσού, για τα βιβλία που προσέφεραν στη βιβλιοθήκη του Συλλόγου μας.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}> Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα χρειάζονται να μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας η οποία ήδη στεγάζεται στο καινούργιο
                    κτίριο που κατασκευάσαμε στη Σκάλα Λουτρών και που αυτή τη στιγμή τα βιβλία που διαθέτει ξεπερνούν τις 5.000.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση είναι
                    δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα προβλήματα που αποτελούν  τη ζωή του σύγχρονου ανθρώπου και τοποθετεί τις βάσεις για τη συνολική πρόοδο του
                    πολιτισμού, συντελεί δε, στη διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και φανατισμούς.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Ευχαριστήρια.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ευχαριστούμε θερμά τους παρακάτω.<br /><br />
                    *Το Νομάρχη Λέσβου κ. Παύλο Βογιατζή και το Νομαρχιακό Συμβούλιο Λέσβου για τη χορηγία τους στην έκδοση του ημερολογίου μας έτους 2010.<br />
                    *Τον ΟΤΕ Μυτιλήνης, την ΕΠΟΜ, το ξενοδοχείο ΖΑΙΡΑ, τον κ. Δεμερτζή Δημήτρη, την καφετέρια Κήπος  και τους λοιπούς χορηγούς του ημερολογίου μας.<br />
                    *Τα καταστήματα της Μυτιλήνης και όσους προσφέρουν δώρα στις εκδηλώσεις μας.<br />
                    * Τον κ. Σαράτση Νίκο που επιδιορθώνει χωρίς αμοιβή τα βιβλία της βιβλιοθήκης μας.<br />
                    *Την κ. Πλατσή - Μεσσηνέζη Καίτη για την λιθογραφία με έργο της, που δώρισε στη βιβλιοθήκη του Συλλόγου μας.<br />
                    *Τον κ. Ψαρρόπουλο Σταύρο που ενέκρινε την δωρεάν επισκευή της μπασκέτας του γηπέδου μας από τον ΝΑΟΛ.<br />
                    * Την κ. Γιώτα Μπρούνη για την βασιλόπιτα που μας έφτιαξε δωρεάν στην εκδήλωση της 8-2-2009 στο ΖΑΙΡΑ.<br />
                    *Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες του Συλλόγου καθώς και όσους προσφέρουν εθελοντικώς την εργασία τους.<br />
                    *Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο μας.<br />
                    *Ιδιαιτέρως ευχαριστούμε το συγχωριανό μας Δημήτρη Γρηγορέλλη που από ιδρύσεως του Συλλόγου μας , μας έχει παραχωρήσει αφιλοκερδώς το πατρικό του σπίτι στη Σκάλα
                    Λουτρών για την στέγαση  δραστηριοτήτων του Συλλόγου μας.<br />
                </Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Οικονομικές ενισχύσεις.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.<br /><br />
                    Σταυρινού  Έλλη                       50 ευρώ.<br />
                    Μηναίδης Βασίλειος                100 ευρώ.<br />
                    Σεβαστός Δημήτριος                 100 ευρώ<br />
                    Δεμερτζής Ιγνάτιος                      20 ευρώ<br />
                    Κατσαλή Γεωργία                     50 ευρώ.<br />
                    Κατανάκης Μιχάλης                  50 ευρώ<br />
                    Ζαραδούκας Χρήστος                50 ευρώ<br />
                    Περγαμηνού Γαρουφαλιά          50 ευρώ<br />
                    Πελεκάνος Θεόδωρος (50+20)  70 ευρώ.<br />
                    Κλήμης Αριστείδης  (100+50)  150 ευρώ. <br />
                    Βουκλαρής Παναγιώτης            135 ευρώ<br />
                    Λευκαδίτης Τάκης                       50 ευρώ<br />
                    Αναγνωστόπουλος Νικόλαος     200 ευρώ<br />
                    Παπαδάκης Ιωάννης                  100 ευρώ.<br />
                    Δεσλής Φώτης                            150 ευρώ<br />
                    Τσουμάκης Στράτος                   100 ευρώ<br />
                    Μολυβιάτη Ρούλα                        10 ευρώ<br />
                    Κινικλή Τούλα                           20 ευρώ.<br />
                    Ανώνυμος                                  50 ευρώ.<br />
                    Γκαγκάνη Δέσποινα                   50 ευρώ<br />
                    Θωμαίδης Πλαστήρας                50 ευρώ<br />
                    Ανώνυμος                                     5 ευρώ.<br />
                    Ζουμπουλάκης Γεώργιος            20 ευρώ.<br />
                    Metin Yilmaz                               10 ευρώ.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός  για αναβάθμιση
                    του οικισμού μας , έτσι ώστε να μπορέσουμε να ανταποκριθούμε στο βαρύ φορτίο των υποχρεώσεων που έχουμε αναλάβει και να ολοκληρώσουμε τους στόχους μας σε σύντομο
                    χρονικό διάστημα. </Typography>

            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Νέα μέλη του Συλλόγου μας.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Με μεγάλη χαρά καλωσορίζουμε στην οικογένεια του Συλλόγου μας τα νέα μέλη του, που είναι οι παρακάτω:<br />
                    Σεβαστός Δημήτριος, Αγλαία Αρχοντίδου, Γκαγκάνη Δέσποινα.</Typography>
            </Box>


            <ScrollToTopButton />


        </>
    )
}

export default D6_drastiriotites_2009