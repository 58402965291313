import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/30SinedrioPandoiko.jpg";

function F17_oikologika_17() {


    const theme = useTheme();



  return (
    <>

    
<IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="30o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>

        
		
	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
    <Typography variant="h3" sx={theme.typography.typography2}>
    	Πραγματοποιήθηκε στο Βόλο, το Σάββατο 17 Οκτωβρίου 2020, το 30ο  Συνέδριο του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ).
 Θα έπρεπε το 30ο  Συνέδριο του ΠΑΝΔΟΙΚΟ να είναι πανηγυρικό. Όμως οι  συνθήκες που υπάρχουν, λόγω του κορωνοϊού, δεν επέτρεψαν να πραγματοποιηθεί θεματικό Συνέδριο με την ΚΕΔΕ. <br/>
  Έτσι στα πλαίσια αυτού του Συνεδρίου πραγματοποιήθηκε μόνο η Γενική Συνέλευση του ΠΑΝΔΟΙΚΟ για απολογισμούς και εκλογή νέων οργάνων, όπως τα προηγούμενα χρόνια.  <br/>
  Έλαβαν μέρος σύνεδροι από το Αγρίνιο, τη Μάκρη, το Κιάτο, τη Ρόδο, το Βόλο, τη Βέροια, την Ακράτα, την Αθήνα, την Πάτρα, τη Χαλκιδική, τη Λέσβο, τη Σκιάθο, το Φάληρο, την Καστοριά, τη Χαλκίδα, τη Δράμα, την Κορινθία, το Λουτράκι, το Μουζάκι, και τη Βεγορίτιδα.
  Οι σύνεδροι ενέκριναν ομόφωνα τον Απολογισμό Δράσης της Επταμελούς Γραμματείας του ΠΑΝΔΟΙΚΟ, τον Οικονομικό Απολογισμό και τα ψηφίσματα που εκδόθηκαν.<br/>
  Στις αρχαιρεσίες που ακολούθησαν εκλέχτηκαν στην Επταμελή Γραμματεία του ΠΑΝΔΟΙΚΟ οι εξής:<br/>
<b>Ν. Ασλάνογλου</b> (Βέροια), <b>Κ. Βολιώτης</b> (Βόλος), <b>Θαν Θεοδωρόπουλος</b> (Πάτρα),  <b>Γ. Καλλιαμπέτσος</b> (Αθήνα), <b>Δ. Μίχαλος</b> (Ρόδος), <b>Γ. Παλαμάρης</b> (Κορινθία) και <b>Μαίρη Χουλιέρη</b> (Χαλκίδα)


	</Typography>


      </Box>

      <ScrollToTopButton />
    
    
    
    
    
    </>
  )
}

export default F17_oikologika_17