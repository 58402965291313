import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/28_Oikologika_Pandoiko.jpg";

function F15_oikologika_15() {

    const theme = useTheme();
    
  return (
    <>

<IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="28o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>



    
      <Box sx={{ ...commonBoxStyles }}>
	
      <Typography variant="h1" sx={theme.typography.typography1}>9-11 Νοεμβρίου 2018, Κατερίνη</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>Θέμα: «Κλιματική Αλλαγή. Αστικό Περιβάλλον»</Typography>
		
	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
    <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε στην Κατερίνη από 9-11 Νοεμβρίου 2018 το 28ο Συνέδριο του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ) σε συνδιοργάνωση με την ΕΝΠΕ, την ΚΕΔΕ και το Δήμο Κατερίνης. Το θέμα του Συνεδρίου ήταν:<br/><br/>
«Κλιματική Αλλαγή. Αστικό Περιβάλλον» και πραγματοποιήθηκε στον Καπνικό Σταθμό Κατερίνης.<br/>
 Το Συνέδριο αυτό ήταν το 14ο που συνδιοργανώθηκε με την ΚΕΔΕ.<br/>
Από την Δευτέρα 5 Νοεμβρίου 2018 μέχρι την Παρασκευή 9 Νοεμβρίου 2018 έγιναν προσυνεδριακές εκδηλώσεις με σχολεία της Κατερίνης για το ζήτημα της κλιματικής αλλαγής 
   Ειδικοί επιστήμονες και εκπρόσωποι φορέων ανέλυσαν όλες τις πτυχές του προβλήματος της κλιματικής αλλαγής.  Ιδιαίτερη αναφορά έγινε για την περίπτωση των πόλεων οι οποίες υφίστανται το κύριο βάρος της κατάστασης αυτής.
   Πρόσφατα ακραία φυσικά φαινόμενα στην Ελλάδα, την Ευρώπη, αλλά και σε ολόκληρο τον κόσμο, δείχνουν ότι τα περιθώρια ανάσχεσης των επιπτώσεων της κλιματικής αλλαγής έχουν στενέψει δραματικά.<br/>
Το Συνέδριο παρακολούθησαν πολλοί Δήμαρχοι, Αντιδήμαρχοι, Δημοτικοί Σύμβουλοι, στελέχη της Τοπικής Αυτοδιοίκησης και των ΦΟΔΣΑ, εκπρόσωποι φορέων και οικολογικών οργανώσεων, στελέχη εταιρειών και απλοί πολίτες.<br/>
   Με τη λήξη του Συνεδρίου ψηφίστηκε ομόφωνα η Απόφαση του Συνεδρίου η οποία αναφέρεται στη διαχρονικότητα της κλιματικής αλλαγής, η οποία επιταχύνεται πλέον από ανθρωπογενείς παράγοντες, στα ακραία καιρικά φαινόμενα που είναι συχνότερα και εντονότερα, τις προβλέψεις για την αύξηση της θερμοκρασίας της Γης τα επόμενα χρόνια, την ανάγκη να προχωρήσει η κυκλική οικονομία, μετά την ολοκληρωμένη ανακύκλωση και στην ενίσχυση των ΑΠΕ. Αναφέρθηκε επίσης ότι η αστικοποίηση σε συνδυασμό με την κλιματική αλλαγή δημιουργεί το φαινόμενο της αστικής θερμικής νησίδας που πρέπει κατάλληλα να αντιμετωπιστεί, Τονίστηκε η ανάγκη ενημέρωσης των μαθητών και γενικότερα των νέων και η υιοθέτηση κατάλληλων εργαλείων αντιμετώπισης της κλιματικής αλλαγής την επόμενη προγραμματική περίοδο 2021-2027.
 Τέλος επισημάνθηκε η ανάγκη ανάληψης πρωτοβουλιών σε όλα τα επίπεδα εξουσίας προκειμένου να προλάβουμε τις καταστροφές.


</Typography>
	<br/><br/>

	
    <Typography variant="h1" sx={theme.typography.typography1}>Απόφαση 28ου Πανελλήνιου Συνεδρίου ΠΑΝΔΟΙΚΟ</Typography>
   
<Typography variant="h3" sx={theme.typography.typography2}>  
Το 28ο Συνέδριο του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ) που πραγματοποιήθηκε στην Κατερίνη 9-11 Νοεμβρίου 2018, σε συνδιοργάνωση με την ΕΝΠΕ την ΚΕΔΕ και το Δήμο Κατερίνης διαπιστώνει τα εξής: <br/>
   Το κλίμα της Γης κατά τη διάρκεια των 4,6 δισεκατομμυρίων ετών της ηλικίας της βρίσκεται σε συνεχή αλλαγή, που ελέγχεται από αστρονομικούς παράγοντες. 
   Στην πιο πρόσφατη σχετικά ιστορία της, η ζωή στον πλανήτη επηρεάζεται από τις συνέπειες όχι μόνο της κλιματικής μεταβλητότητας, που οφείλεται σε φυσικά αίτια, αλλά και της επιδεινούμενης Κλιματικής Αλλαγής από ανθρωπογενείς δραστηριότητες, λόγω της αλόγιστης πολιτικής για εκμετάλλευση όλων των φυσικών πόρων προς οικονομική ανάπτυξη των κοινωνιών, του υπερπληθυσμού, αλλά και της συσσώρευσης πλούτου στα χέρια λίγων.<br/>
   Ακραία καιρικά φαινόμενα όπως πλημμύρες, τυφώνες, καύσωνες, το λιώσιμο μεγάλου ποσοστού των αιώνιων πάγων μέσα σε μερικές δεκαετίες και η αύξηση της μέσης θερμοκρασίας της Γης, είναι αδιαμφισβήτητες, δυστυχώς, αποδείξεις για μία αλλαγή που είναι ήδη πραγματικότητα.<br/>
   Σύμφωνα με το πόρισμα της Διακυβερνητικής Επιτροπής για την Κλιματική Αλλαγή, η μέση θερμοκρασία της επιφάνειας του πλανήτη αναμένεται να αυξηθεί από 1,4 έως 5,8 βαθμούς Κελσίου έως το 2100, σε σχέση με τα τέλη του 19ου αιώνα, αν δεν αλλάξει το μοντέλο που ακολουθείται μέχρι σήμερα. Ήδη έχει καταγραφεί αύξηση της μέσης θερμοκρασίας πάνω από 0,6 βαθμούς Κελσίου.<br/>
   Έχει γίνει, πλέον, ευρέως αποδεκτή η απόρριψη του γραμμικού μοντέλου ανάπτυξης και η σταδιακή υιοθέτηση μοντέλων κυκλικής οικονομίας, με τη μεγαλύτερη δυνατή επαναχρησιμοποίηση και ανακύκλωση των υλικών και την ελαχιστοποίηση της καύσης, πάντα με ασφαλείς συνθήκες για τους πολίτες. Επίσης, προτείνεται η προώθηση των ΑΠΕ, με την απαραίτητη προϋπόθεση της ορθής και προγραμματισμένης χωροταξικής κατανομής τους, ενώ πρέπει να υπάρξει περιορισμός της έρευνας για νέα και χρήσης των υπαρχόντων ορυκτών καυσίμων.</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Είναι πλέον επιτακτική η ανάγκη για οικολογικές πολιτικές, φιλικές για το περιβάλλον λύσεις – δράσεις και σωστή εφαρμογή αυτών. 
   Ιδιαίτερα στον αστικό και περιαστικό χώρο οι συνθήκες διαβίωσης επιβαρύνονται συνεχώς. Η μεγάλη αστικοποίηση, πέρα από τα οφέλη, έχει επιφέρει και σημαντικά προβλήματα, όπως για παράδειγμα τη διαχείριση των απορριμμάτων, την αέρια ρύπανση και την έλλειψη χώρων πρασίνου. Μία από τις επιπτώσεις της αστικοποίησης, σε συνδυασμό και με την κλιματική αλλαγή, είναι το φαινόμενο της αστικής θερμικής νησίδας. Για την αντιμετώπιση της ανθρώπινης θερμικής δυσφορίας προτείνεται τόσο η καταγραφή των βιοκλιματικών συνθηκών μέσα στην πόλη (με χρήση νέων τεχνολογιών όπως π.χ. drones και δικτύων μετρητικών σταθμών) όσο και η βελτίωση αυτών μέσα από:
Βιοκλιματική αξιολόγηση των προτεινόμενων και σχεδιαζόμενων έργων και δράσεων στον αστικό χώρο.<br/>
Χρήση ψυχρών υλικών, δενδροφυτεύση, βιοκλιματικός αστικός και πολεοδομικός σχεδιασμός.<br/>
Χρήση ανανεώσιμων πηγών ενέργειας μέσα στην πόλη.<br/>
Σχεδιασμός και επιλογή λύσεων με σκοπό τον μετριασμό των επιπτώσεων της κλιματικής αλλαγής, αλλά και τη βελτίωση της ποιότητας ζωής των πολιτών.<br/>
   Θεωρούμε απαραίτητη την ενίσχυση έργων και δράσεων βιοκλιματικών παρεμβάσεων στον δημόσιο αστικό χώρο καθώς και την παροχή κινήτρων για αντίστοιχες δράσεις στον ιδιωτικό.<br/>
   Η οικολογική συνείδηση δεν ξεκινά δια μαγείας από την ενήλικη ζωή παρά καλλιεργείται και κτίζεται από την παιδική ηλικία, με δράσεις που χαρακτηρίζονται από συνέπεια και σοβαρότητα και αποτελούν αναπόσπαστο κομμάτι της εκπαιδευτικής διαδικασίας.<br/>
   Για την Ελλάδα ζητείται στην επόμενη προγραμματική περίοδο 2021-2027 να υπάρξει σημαντικό αποτύπωμα αντιμετώπισης της κλιματικής αλλαγής.<br/>
   Τα αρμόδια Υπουργεία, οι Περιφέρειες, οι Δήμοι, φορείς κλπ., μπορούν και πρέπει να εκπονήσουν προγράμματα αντιμετώπισης της κλιματικής αλλαγής που να είναι άμεσα εφαρμόσιμα και όχι θεωρητικά και σε αδράνεια. Όμως, και οι απλοί πολίτες πρέπει να συμβάλλουν στην αντιμετώπιση της κλιματικής αλλαγής.<br/>
   Το 28ο Συνέδριο του ΠΑΝΔΟΙΚΟ πιστεύει τέλος ότι ο χρόνος για την αποφυγή των συνεπειών της κλιματικής αλλαγής στον πλανήτη λιγοστεύει δραματικά και οι όποιες προσπάθειες πρέπει να αρχίσουν άμεσα.<br/><br/>
Για το Συνέδριο<br/><br/>

Ο πρόεδρος της Ο.Ε.		-		Ο Συντονιστής<br/><br/>
Σάββας Χιονίδης		   -        Κώστας Βολιώτης<br/>


</Typography>

</Box>

<ScrollToTopButton />
    
    </>
  )
}

export default F15_oikologika_15;