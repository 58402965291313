import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image02 from "../assets/images/drastiriotites2018/image002.jpg";
import Image04 from "../assets/images/drastiriotites2018/image004.jpg";
import Image06 from "../assets/images/drastiriotites2018/image006.jpg";
import Image08 from "../assets/images/drastiriotites2018/image008.jpg";
import Image10 from "../assets/images/drastiriotites2018/image010.jpg";
import Image12 from "../assets/images/drastiriotites2018/image012.jpg";
import Image14 from "../assets/images/drastiriotites2018/image014.jpg";
import Image16 from "../assets/images/drastiriotites2018/image016.jpg";
import Image18 from "../assets/images/drastiriotites2018/image018.jpg";
import Image20 from "../assets/images/drastiriotites2018/image020.jpg";
import Image22 from "../assets/images/drastiriotites2018/image022.jpg";
import Image24 from "../assets/images/drastiriotites2018/image024.jpg";
import Image26 from "../assets/images/drastiriotites2018/image026.jpg";
import Image28 from "../assets/images/drastiriotites2018/image028.jpg";
import Image30 from "../assets/images/drastiriotites2018/image030.jpg";
import Image32 from "../assets/images/drastiriotites2018/image032.jpg";
import Image34 from "../assets/images/drastiriotites2018/image034.jpg";
import Image36 from "../assets/images/drastiriotites2018/image036.jpg";
import Image38 from "../assets/images/drastiriotites2018/image038.gif";
import Image40 from "../assets/images/drastiriotites2018/image040.jpg";
import Image42 from "../assets/images/drastiriotites2018/image042.jpg";
import Image44 from "../assets/images/drastiriotites2018/image044.jpg";
import Image46 from "../assets/images/drastiriotites2018/image046.jpg";
import Image48 from "../assets/images/drastiriotites2018/image048.jpg";
import Image50 from "../assets/images/drastiriotites2018/image050.jpg";
import Image52 from "../assets/images/drastiriotites2018/image052.jpg";
import Image54 from "../assets/images/drastiriotites2018/image054.jpg";
import Image56 from "../assets/images/drastiriotites2018/image056.jpg";
import Image58 from "../assets/images/drastiriotites2018/image058.jpg";
import Image60 from "../assets/images/drastiriotites2018/image060.jpg";
import Image62 from "../assets/images/drastiriotites2018/image062.jpg";
import Image64 from "../assets/images/drastiriotites2018/image064.jpg";
import Image66 from "../assets/images/drastiriotites2018/image066.jpg";
import Image68 from "../assets/images/drastiriotites2018/image068.jpg";
import Image70 from "../assets/images/drastiriotites2018/image070.jpg";
import Image72 from "../assets/images/drastiriotites2018/image072.jpg";
import Image74 from "../assets/images/drastiriotites2018/image074.jpg";
import Image76 from "../assets/images/drastiriotites2018/image076.jpg";
import Image78 from "../assets/images/drastiriotites2018/image078.jpg";

function D15_drastiriotites_2018() {

    const theme = useTheme();


  return (
    <>

<IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Οι δραστηριότητές μας το 2018" />
      </Box>


	
<Box sx={{ ...commonBoxStyles }}>  
    
	<Typography variant="h1" sx={theme.typography.typography1}>Κοπή της Βασιλόπιτας του Συλλόγου μας</Typography>	
	<img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image04}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />    
    <Typography variant="h3" sx={theme.typography.typography2}>
    	
   	Στην βιβλιοθήκη του Συλλόγου, την Κυριακή 7 Ιανουαρίου 2018 στις 6 το απόγευμα το Δ.Σ. του Συλλόγου Μικρασιατών της Σκάλας Λουτρών «ΤΟ ΔΕΛΦΙΝΙ», έκοψε την βασιλόπιτά του.<br/>
   Παρόντες ο ιερέας της ενορίας μας πάτερ Παναγιώτης ο οποίος την ευλόγησε, ο ψάλτης και μέλος του Συλλόγου Σωτήρης Μαμουνής, ο πρόεδρος του Τοπικού μας Συμβουλίου Βασίλης Μαμώλης, ο πρόεδρος του Αετού Λουτρών Νίκος Σπανός, ο πρόεδρος του Συλλόγου Αλιέων Ιωάννης Μολυβιάτης και πολλά μέλη του Συλλόγου.
   Το φλουρί,  ήταν προσφορά από το χρυσοχοείο  «Βέτσικα» και  μετά από κλήρωση το κέρδισε η κ. Μαρία Βέντου με τον αριθμό 43. Δεύτερος τυχερός μετά το φλουρί ήταν η κ. Δαφιώτη Νάσια η οποία κέρδισε ένα γυναικείο ρολόι, προσφορά από το κατάστημα «Ρολογάδικο» του κ. Ιωάννη Μολυβιάτη. Στους παρόντες προσφέρθηκαν καφές, αναψυκτικά, και ποτά με διάφορα εδέσματα.<br/>
   Στο τέλος της εκδήλωσης έγινε η κλήρωση των δώρων της λαχειοφόρου αγοράς και κέρδισαν οι παρακάτω αριθμοί:<br/>
   Ο αριθμός 962 κέρδισε την τηλεόραση,  ο αριθμός 857 κέρδισε το TABLET ο αριθμός 786 κέρδισε το ηλεκτρικό υπόστρωμα, ο αριθμός 730 κέρδισε την καφετιέρα και τέλος ο αριθμός 627 κέρδισε το αερόθερμο.


	<br/><br/>
	Και του χρόνου με υγεία.
</Typography>

	
	</Box>

    <ScrollToTopButton />
	
	


<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Ο Αγιασμός των Υδάτων στη Σκάλα Λουτρών και στην Χαραμίδα.</Typography>    
	
	<img src={Image06}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image08}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    

	

    <Typography variant="h3" sx={theme.typography.typography2}> 

    Με την παρουσία του αντιδημάρχου Λέσβου, κ. Παναγιώτη Τσουπή, του προέδρου Ταξιαρχών κ. Μακρή Μιχάλη, του κ. Δημήτρη Μπουρνού εκ μέρους του Συλλόγου αιμοδοτών Μυτιλήνης, του προέδρου της τοπικής μας κοινότητας κ. Μαμώλη Βασίλη, του προέδρου του Αετού Λουτρών κ. Σπανού Νικολάου και του Δ.Σ. του Συλλόγου Μικρασιατών της Σκάλας Λουτρών «ΤΟ ΔΕΛΦΙΝΙ», πραγματοποιήθηκε ο Αγιασμός των υδάτων στην προβλήτα του Αγίου Γεωργίου στη Σκάλα Λουτρών και εν συνεχεία  στη Χαραμίδα. 
   Ο εξαιρετικός καιρός με άπνοια, ηλιοφάνεια αλλά και με υψηλές για την εποχή θερμοκρασίες, βοήθησε έτσι ώστε η συμμετοχή των κατοίκων, αλλά και των κολυμβητών που έπεσαν στην θάλασσα να είναι αρκετά μεγαλύτερη από άλλες χρονιές. <br/>
   Μετά το  πέρας της Θείας Λειτουργίας στον Ιερό Ναό του Αγίου Γεωργίου Λουτρών, όλο το εκκλησίασμα ξεκίνησε για τον αγιασμό των υδάτων στην Σκάλα Λουτρών όπου διαβάστηκε το ευαγγέλιο πάνω στην εξέδρα που γι αυτό το σκοπό είχε στηθεί και μόλις ο ιερέας ο πάτερ Παναγιώτης είπε το «εν Ιορδάνη βαπτιζομένου σου Κύριε…»  έξη νεαροί, αλλά και θαρραλέοι κολυμβητές βούτηξαν στα κρύα νερά του κόλπου Γέρας.     <br/>
   Αυτός που έπιασε πρώτος τον Σταυρό τον οποίο και ανέσυρε στην επιφάνεια, ήταν ο Άγγελος Μίνικνας από τα Λουτρά, ο οποίος έλαβε και το δώρο του από τον αντιδήμαρχο και τον πρόεδρο των Λουτρών, το οποίο ήταν μία εικόνα του Αγίου Νικολάου. Αμέσως μετά το Τοπικό Συμβούλιο Λουτρών έκοψε την καθιερωμένη βασιλόπιτα προσφέροντας συγχρόνως και από ένα ποτό στους παρευρισκόμενους. Το φλουρί εδώ έπεσε στο κομμάτι του Συλλόγου αιμοδοτών Λουτρών και το δώρο που ήταν μια εικόνα του Χριστού παρέλαβε η κ. Σαμαρτζή Μαρία.</Typography>
    <Typography variant="h3" sx={theme.typography.typography2}>Μετά τον Αγιασμό των υδάτων που έγινε στη Σκάλα Λουτρών ο ιερέας και οι περισσότεροι από τους παρευρισκόμενους, ξεκίνησαν για να παρευρεθούν στον Αγιασμό των υδάτων στην Χαραμίδα όπου περίμεναν πολλοί κάτοικοι της περιοχής, καθώς και αρκετοί Μυτιληνιοί.<br/>
     Η καθιερωμένη τελετή πραγματοποιήθηκε και εδώ χωρίς προβλήματα και τον Σταυρό ανέσυρε από τη θάλασσα ο κ. Σωτήρης Μαμουνής. Αμέσως μετά ακολούθησε το κόψιμο της βασιλόπιτας των κατοίκων της περιοχής και το σχετικό κέρασμα σε όλους τους παρευρισκόμενους.<br/>
     Παρόντες και εδώ τόσο τα μέλη του Τοπικού Συμβουλίου Λουτρών  όσο και του Συλλόγου Μικρασιατών της Σκάλας Λουτρών. <br/><br/>
 Και του χρόνου με υγεία.

	</Typography>


    
	</Box>
	
	
	<ScrollToTopButton />












<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Πάσχα  στη Σκάλα Λουτρών</Typography>    
	
	<img src={Image10}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image12}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    
	
    <Typography variant="h3" sx={theme.typography.typography2}>    Με μεγάλη συμμετοχή και με κατάνυξη πραγματοποιήθηκαν και φέτος οι ιερές ακολουθίες της Μεγάλης εβδομάδας στο εκκλησάκι του Αγίου Παντελεήμονα της Σκάλας Λουτρών.<br/>
   Ο Σύλλογός μας, σε συνεργασία με τον ιερέα της ενορίας μας και με το γραφείο της Ιεράς Μητροπόλεως Μυτιλήνης μερίμνησαν, έτσι ώστε να καλυφθούν οι ανάγκες των κατοίκων με την παρουσία ιερέως ειδικά για αυτές τις Άγιες Ημέρες.</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Φέτος ήταν η δέκατη τρίτη συνεχόμενη χρονιά που πραγματοποιήθηκαν οι θρησκευτικές εκδηλώσεις των αγίων ημερών του Πάσχα στη Σκάλα Λουτρών.
   Το πρωί της Κυριακής των Βαΐων, ετελέσθη η θεία λειτουργία από τον πρωτοσύγκελο της Ιεράς  Μητροπόλεως Μυτιλήνης π. Ιάκωβο με μεγάλη συμμετοχή πιστών και το απόγευμα της ίδιας ημέρας η ακολουθία του Νυμφίου. Εν συνεχεία την  Μ. Τετάρτη το απόγευμα ετελέσθη το Μυστήριο του Ευχελαίου και την Μ. Πέμπτη η ακολουθία των παθών με τα 12 Ευαγγέλια από τον ιερέα της ενορίας μας.<br/>
   Το βράδυ της Μ. Πέμπτης πραγματοποιήθηκε ο στολισμός του επιταφίου από τις γυναίκες και  τα νέα κορίτσια του οικισμού μας, που αφιέρωσαν με μεγάλη χαρά πολλές ώρες, για να είναι ο στολισμός του επιταφίου ο καλύτερος. Γνωστά τα ονόματά τους σε όλους μας (Ευθαλία,  Δέσποινα, Ανθούλα, Βικτωρίτσα, Γραμματική, Άννα, Ελένη, Ειρήνη, Βασιλεία, Κατερίνα, Βασούλα, Κλεονίκη, Βούλα, και τόσες άλλες…).  Βάσει του εθίμου, κατά την διάρκεια του στολισμού, εψάλη το τραγούδι της Παναγίας και άλλα εγκώμια από όλες τις κυρίες. Κατά την διάρκεια του στολισμού του επιταφίου προσφέρθηκε πρόσφορο, βουτηγμένο σε κόκκινο κρασί και ο στολισμός του επιταφίου στον Άγιο Παντελεήμονα, έληξε γύρω στις 3 το πρωί. </Typography>
   <Typography variant="h3" sx={theme.typography.typography2}>Την Μ. Παρασκευή στις 5 το απόγευμα έγινε η αποκαθήλωση του εσταυρωμένου και η περιφορά του επιταφίου σε όλο τον οικισμό από τον ιερέα της ενορίας μας π. Παναγιώτη, με θαυμάσιο καιρό και στην ίδια πάντα διαδρομή δίπλα στο πράσινο της  φύσης και στο γαλάζιο της θάλασσας. Την τελετή της περιφοράς συμπλήρωνε η χορωδία από ομάδα γυναικών μελών του Συλλόγου μας.<br/>
   Τέλος το βράδυ του Μεγάλου Σαββάτου, μέσα σε μια κατανυκτική ατμόσφαιρα υπό το φως των κεριών  ετελέσθη η αναστάσιμη Θεία Λειτουργία από τον πρωτοσύγκελο της Ιεράς  Μητροπόλεως Μυτιλήνης π. Ιάκωβο.<br/>
   Ο σύλλογός μας τηρώντας το έθιμο, στο τέλος της Θείας λειτουργίας μοίρασε σε όλους τους παρευρισκόμενους κόκκινα αυγά  και τσουρέκια. Εμείς από την πλευρά μας ευχαριστούμε θερμά τον εφημέριο της ενορίας μας π. Παναγιώτη, τον πρωτοσύγκελο της Ιεράς Μητροπόλεως Μυτιλήνης π. Ιάκωβο, καθώς και τον Σεβασμιότατο  Μητροπολίτη Μυτιλήνης, που μερίμνησαν και ικανοποίησαν το αίτημά μας  για αποστολή ιερέα στον οικισμό μας τις ημέρες του Πάσχα. Η συμμετοχή των κατοίκων ήταν καθολική και ιδίως των ηλικιωμένων οι οποίοι προσήλθαν με μεγάλη χαρά. Και του χρόνου να είμαστε γεροί να ξαναγιορτάσουμε όλοι μαζί αυτές τις Άγιες ημέρες.
</Typography>

	<br/><br/>
	Και του χρόνου με υγεία.


    
	</Box>
	
	
	<ScrollToTopButton />













<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>3η Περιβαλλοντική δράση στην καμένη Αμαλή.</Typography>    
	
	<img src={Image14}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image16}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    
	<Typography variant="h3" sx={theme.typography.typography2}> 
    
Με την συγκέντρωση αρκετών εθελοντών και παρά τις δυσμενείς καιρικές συνθήκες, πραγματοποιήθηκε την Κυριακή 4 Φεβρουαρίου 2018 στις 10 το πρωί, για 3η συνεχή χρονιά, περιβαλλοντική δράση στην καμένη Αμαλή.<br/>
    Ποιο συγκεκριμένα με τις οδηγίες υπαλλήλου της Δασικής Υπηρεσίας έγινε σπορά (15.000) σπόρων πεύκου, με στόχο την αναδάσωση μέρους του καμένου δάσους από την καταστροφική πυρκαγιά του 2015 στην περιοχή της Χαραμίδας.<br/><br/>
   Διοργανωτές ήταν, η Δημοτική Κοινότητα Λουτρών, ο Σύλλογος Μικρασιατών Σκάλας Λουτρών «Το Δελφίνι», ο Πολιτιστικός Σύλλογος Λουτρών «Το Λιοστάσι», και ο Σύλλογος Γονέων και Κηδεμόνων Δημοτικού Σχολείου και Νηπιαγωγείου Λουτρών.<br/>
   Κατά την διάρκεια της σποράς έγινε και έλεγχος των αποτελεσμάτων στις περιοχές όπου είχε πραγματοποιηθεί τα προηγούμενα δύο χρόνια αντίστοιχη σπορά και διαπιστώθηκε ότι σε αρκετά σημεία είχαν κάνει την εμφάνισή τους νεαρά πευκάκια ύψους περίπου 15 εκατοστών.<br/>
   Ευχαριστούμε τους συμμετέχοντες που με την δράση τους απέδειξαν για μια ακόμη φορά την ευαισθησία τους για το περιβάλλον και ότι, αγαπάνε το δάσος και νοιάζονται γι αυτό. <br/>
   Παραθέτουμε φωτογραφίες από την δράση και μερικές με πευκάκια που έχουν φυτρώσει από τις προηγούμενες σπορές στο δάσος της Αμαλής.<br/><br/>

Κοιμήσου, πεύκο αγαπημένο,<br/>
μες στης νύχτας τη σιγαλιά.<br/>
Στην καταπράσινη αγκαλιά σου<br/>
χιλιάδες κούρνιασαν πουλιά.<br/>
Μικρή φωλίτσα παραδείσου,<br/>
πεύκο πανέμορφο κοιμήσου...<br/><br/>
	
	</Typography>


    
	</Box>
	
	
	<ScrollToTopButton />






<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Lets do it Greece</Typography>    
	<h2>Περιβαλλοντική δράση στη Σκάλα Λουτρών</h2> 
	
	<img src={Image18}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image20}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    
	
    <Typography variant="h3" sx={theme.typography.typography2}> 
Ο Σύλλογός μας «ΤΟ ΔΕΛΦΙΝΙ» συμμετείχε στην Πανελλαδική δράση καθαρισμού ακτών και κοινόχρηστων χώρων «<b>Lets do it Greece</b>» που πραγματοποιήθηκε την Κυριακή 2 Απριλίου 2017.</Typography>
 <Typography variant="h3" sx={theme.typography.typography2}>Η συνάντηση για το ξεκίνημα της δράσης έγινε στο γήπεδο μπάσκετ της Σκάλας Λουτρών στις 09.30 το πρωί και καθαρίστηκαν όλοι οι κοινόχρηστοι χώροι και οι ακτές της Σκάλας Λουτρών. </Typography>
   <Typography variant="h3" sx={theme.typography.typography2}>Το μήνυμα που δίδουμε κάθε χρόνο με αυτές τις εξορμήσεις καθαριότητας, είναι ότι είμαστε παρόντες στην προσπάθεια που πρέπει να γίνεται από όλους για την προστασία του περιβάλλοντος, των ακτών και των δημόσιων χώρων, που αποτελούν σημαντικό χώρο αναψυχής των πολιτών, πόλο έλξης επισκεπτών και πηγή οικονομικής ανάπτυξης του τόπου μας. </Typography>
   <Typography variant="h3" sx={theme.typography.typography2}>Ευχαριστούμε το Δήμο Μυτιλήνης και το Τοπικό Συμβούλιο Λουτρών, που μεριμνούν και μας διευκολύνουν σε θέματα καθαριότητας του οικισμού μας
	
	</Typography>


    
</Box>
	
	
	<ScrollToTopButton />





<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Διαγωνισμός ζωγραφικής και έκθεσης</Typography>    
	<img src={Image22}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image24}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    
	
    <Typography variant="h3" sx={theme.typography.typography2}>
      Πραγματοποιήθηκε στις 5 Ιουνίου 2018 από τον Σύλλογό μας «ΤΟ ΔΕΛΦΙΝΙ» με επιτυχία, <u>ο ετήσιος διαγωνισμός ζωγραφικής και έκθεσης</u> για το περιβάλλον, με τους μαθητές και τις μαθήτριες του δημοτικού σχολείου Λουτρών.<br/>
     Οι τέσσερις πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα: «<b>Ζωγραφίζω τους φίλους μου τα Ζώα!</b>» και οι δύο μεγαλύτερες τάξεις διαγωνίσθηκαν στην έκθεση με θέμα: «Τα αδέσποτα Ζώα και οι ευθύνες μας γι αυτά!»<br/>
       Τα παιδιά που διακρίθηκαν βραβεύτηκαν σε ειδική εκδήλωση που πραγματοποιήθηκε το Σάββατο 4 Αυγούστου 2018 στις 9.30 το βράδυ, στο κτήμα «Ελιά και θάλασσα» του ξενοδοχείου ΖΑΙΡΑ στη Σκάλα Λουτρών.<br/>
Διακρίθηκαν:<br/><br/>
Από την <b>Α! Τάξη</b> που έχει δύο τμήματα ο Παπουτσής Ταξιάρχης,<br/>
 η Λύρα Ουρανία, η Μπλέτσα Παναγιώτα και η Τατάκη Αλεξάνδρα.<br/>
Από την <b>Β! Τάξη</b>   η Γκέντση Μαριτίνα και η Κλάβα Σοφία.<br/>
Από την <b>Γ! Τάξη</b>   η Βαιοπούλου Ελένη Όλγα και η Κλάβα Μαρία.<br/>
Από την <b>Δ! Τάξη</b>   η Βασιλέλλη Μαρία και η Γρημάνη Μαρία.<br/>
Από την <b>Ε! Τάξη</b>   ο Κέντσης Στράτος και η  Τριανταφύλλου Θεανώ.<br/>
Από την <b>ΣΤ! Τάξη</b> η Κέντση Μαρία και η Τρουλινού Κατερίνα.<br/><br/>

   Το Δ.Σ. του συλλόγου μας ευχαριστεί την διευθύντρια και τους δασκάλους του Δημοτικού Σχολείου Λουτρών για την ουσιαστική βοήθεια και την συνεργασία που είχαμε κατά την διενέργεια του διαγωνισμού.

	</Typography>


    
</Box>
	
	
	<ScrollToTopButton />







<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>7ος Κολυμβητικός διάπλους Μικρασιατικής Μνήμης</Typography>    
	<img src={Image26}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image28}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    
	
    <Typography variant="h3" sx={theme.typography.typography2}>
    	Πραγματοποιήθηκε με επιτυχία και ρεκόρ συμμετοχών κολυμβητών και κολυμβητριών  ο 7ος Διάπλους του κόλπου Γέρας από την Κουντουρουδιά έως το Πέραμα την Κυριακή 29-7-2018. Η έκπληξη του φετινού διάπλου ήταν η παρουσία δελφινιών ανάμεσα στην Κουντουρουδιά και στο Πέραμα στις 09:30 το πρωί, δηλαδή λίγο πριν την έναρξη της εκδήλωσης.<br/>
    Οι 88 κολυμβητές και κολυμβήτριες που συμμετείχαν διήνυσαν την απόσταση των 1.100 μέτρων που χωρίζει την Κουντουρουδιά από το Πέραμα χωρίς ιδιαίτερα προβλήματα αφού ο καιρός ήταν εξαιρετικός και η θάλασσα χωρίς κυματισμούς.</Typography>
    <Typography variant="h3" sx={theme.typography.typography2}>
    Οι ηλικίες των κολυμβητών που συμμετείχαν ήταν από 10 έως 74 ετών και ο χρόνος που έκαναν κυμάνθηκε από 12 περίπου λεπτά ο πρώτος μέχρι 60 λεπτά ο τελευταίος.
     Στην ασφαλή διεξαγωγή του κολυμβητικού διάπλου συνέβαλαν αποφασιστικά, το πλωτό σκάφος του Λιμενικού Σώματος που επέβλεπε συνεχώς τους κολυμβητές, η παρουσία  του ιατρού κ. Μάκη Αξιώτη που παρευρίσκετο για να προσφέρει τις πρώτες βοήθειες αν χρειαζόταν, καθώς και η γυμνάστρια ναυαγοσώστρια κ. Μαρία Καραμολέγκου.
      Τις ευχαριστίες μας οφείλουμε σε όλους τους προαναφερθέντες, καθώς επίσης και στους κ.κ. Ευστράτιο Καραντώνη και Στέλιο Χιώτη, οι οποίοι διέθεσαν τις βάρκες τους για τη δωρεάν μεταφορά των αθλητών και των συνοδών τους από την Κουντουρουδιά στο Πέραμα και αντιστρόφως.</Typography>
      <Typography variant="h3" sx={theme.typography.typography2}>
      Τέλος, σε όλους τους συμμετέχοντες δόθηκαν αναμνηστικά διπλώματα και μπλουζάκια για τη συμμετοχή τους τα οποία ήταν προσφορά του Ν.Π.Δ.Δ. Πολιτισμού, Αθλητισμού και Τουρισμού του Δήμου Λέσβου.<br/><br/>
Και του χρόνου με υγεία.<br/><br/>

Διοργανωτές της εκδήλωσης ήταν: <br/>
Ο «Παγγεραγωτικός» Πολιτιστικός Σύλλογος Γέρας,<br/>
Ο Σύλλογος Μικρασιατών Σκάλας Λουτρών «ΤΟ ΔΕΛΦΙΝΙ»  και<br/>
Ο «Παγγεραγωτικός» Σύλλογος Αθηνών<br/>


	</Typography>


    
</Box>
	
	
	<ScrollToTopButton />










<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Σαν παραμύθι, εκδήλωση αφιερωμένη στη μνήμη των προσφύγων της Μικρασιατική Καταστροφής</Typography>    

	<img src={Image30}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image32}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
    <br/><br/>
    <Typography variant="h3" sx={theme.typography.typography2}>
Συνεργασία του Πειραματικού ΓΕ.Λ Μυτιλήνης του πανεπιστημίου Αιγαίου και του Συλλόγου Μικρασιατών Σκάλας Λουτρών «Το Δελφίνι».<br/><br/>
</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Αφιερωμένη στη μνήμη των προσφύγων της Μικρασιατική Καταστροφής ήταν η βραδιά της Κυριακής 8ης Ιουλίου 2018. Στον αύλιο χώρο του Μουσείου Προσφυγικής Μνήμης του Συλλόγου Μικρασιατών Σκάλας Λουτρών «Το Δελφίνι», μαθητές και μαθήτριες του Πειραματικού ΓΕ.Λ. Μυτιλήνης του πανεπιστημίου Αιγαίου, αφηγήθηκαν τις ιστορίες των προπαππούδων και των προγιαγιάδων τους, οι οποίοι ήταν Μικρασιάτες πρόσφυγες. Παράλληλα προβλήθηκαν σπάνια κειμήλια και φωτογραφίες που εκείνοι έφεραν, όταν το 1922 άφησαν τις πατρογονικές τους εστίες, καταφτάνοντας στη νέα πατρίδα τους, τη Λέσβο.
   Η εκδήλωση έγινε στο πλαίσιο του πολιτιστικού προγράμματος σχολικών δραστηριοτήτων, που εδώ και μια πενταετία υλοποιεί ομάδα καθηγητών του Πειραματικού ΓΕΛ. Μυτιλήνης, με τίτλο: «Σαν παραμύθι… Η μετάδοση της προσφυγικής εμπειρίας στην τρίτη και τέταρτη γενιά. Μαθητές του Πειραματικού ΓΕ.Λ. Μυτιλήνης απόγονοι Μικρασιατών προσφύγων».   </Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Στην ομιλία που προηγήθηκε η κ. Μαρία Ζερβού, φιλόλογος καθηγήτρια, με γλαφυρότητα ανέλυσε την αξία που έχει η μετάδοση της προσφυγικής εμπειρίας στις επόμενες γενιές. Αξίζει να σημειωθεί πως η εκδήλωση πλαισιώθηκε με την έκθεση φωτογραφιών του Felix Sartiaux από τον πρώτο διωγμό του 1914 στη Φώκαια της Μικράς Ασίας, καθώς και με τραγούδια από την παραδοσιακή ορχήστρα της Σχολής Βυζαντινής Μουσικής της Ιεράς Μητροπόλεως Μυτιλήνης «Άγιος Θεόδωρος ο Βυζάντιος». Στον χαιρετισμό του ο Πρόεδρος του πάντα δραστήριου Συλλόγου Μικρασιατών Σκάλας Λουτρών Μυτιλήνης «Το Δελφίνι» κ. Δημήτριος Παπαχρυσός, πέραν της ανακοίνωσης του προγράμματος των εκδηλώσεων που για το εφετινό καλοκαίρι έγιναν στον αύλιο χώρο του Μουσείου Προσφυγικής Μνήμης, τόνισε τη σημασία που έχουν για τη νέα γενιά εκδηλώσεις όπως αυτή του Πειραματικού ΓΕ.Λ. Μυτιλήνης. 	</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Στην εκδήλωση παρέστησαν και χαιρέτησαν: ο εκπρόσωπος του Σεβασμιοτάτου Μυτιλήνης κ. Ιακώβου Γ΄ αιδεσιμότατος Παν. Χατζηβασιλείου, η Αντιπεριφερειάρχης Βορείου Αιγαίου κ. Καλλιόπη Πρωτογεράκη – Γόμου, η Πρόεδρος του ΕΠ.Ε.Σ. του Πειραματικού ΓΕ.Λ. Μυτιλήνης του Πανεπιστημίου Αιγαίου κ. Αικατερίνη Κλωνάρη, αναπληρώτρια καθηγήτρια του τμήματος Γεωγραφίας του Πανεπιστημίου Αιγαίου, η Διευθύντρια του Πειραματικού ΓΕ.Λ. Μυτιλήνης κ. Γεωργία Σκαλοχωρίτου και η υπεύθυνη του τμήματος Σχολικών Δραστηριοτήτων Δευτεροβάθμιας Εκπαίδευσης Ν. Λέσβου κ. Λάουρα Λοϊζίδου. Παράλληλα αναγνώσθηκε χαιρετισμός του Δημάρχου Μυτιλήνης κ. Σπύρου Γαληνού, ο οποίος λόγω ανειλημμένων υποχρεώσεων δεν μπόρεσε να παραστεί.</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Σημαντικές ήταν οι αφηγήσεις των ιστοριών των Μικρασιατών προγόνων τους από τους επτά συμμετέχοντες μαθητές: Στρατή Κιτζού, Ελένης Καλδέλη, Δημήτρη Κουρούτσαλτη, Ταξιαρχούλας Καλαμάτα, Γιάννη Πασπάτη, Καλλιόπης Χαδούλη και Κατερίνας Χατζηλία, οι οποίοι με τον τρόπο που ανέγνωσαν τα κείμενά τους συγκίνησαν τους παραβρισκόμενους. Στις αφηγήσεις των παιδιών αυτών προστέθηκε στο τέλος και μια αφήγηση από το αρχείο του Συλλόγου Μικρασιατών της Σκάλας Λουτρών «Το Δελφίνι» με τίτλο «Η διαδρομή ενός Μικρασιάτη», που ήταν παλαιότερη εργασία 16χρονου μαθητή του Πειραματικού Λυκείου</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Το συντονισμό της όλης εκδήλωσης είχαν οι δύο Καθηγητές του Πειραματικού ΓΕ.Λ. Μυτιλήνης: κ. Αθανάσιος Ι. Καλαμάτας, Θεολόγος και κ. Αναστάσιος Τρούλος, Κοινωνιολόγος. Σαν παραμύθι… πράγματι ήταν οι αφηγήσεις που ακούστηκαν.<br/>
   Στο τέλος της εκδήλωσης, στους προσκεκλημένους και σε όσους άλλους παραβρέθηκαν, προσφέρθηκε ένας μικρός μπουφές με εδέσματα και αναψυκτικά. 
   </Typography>




</Box>
	
	
	<ScrollToTopButton />








<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Βραδιά παραμυθιού</Typography>    
	
	<img src={Image34}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image36}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />    

<Typography variant="h3" sx={theme.typography.typography2}>
Στην Σκάλα Λουτρών, εκεί στην οδό Παραμυθιών, το βράδυ της 18ης Ιουλίου2018, στην καρδιά του καλοκαιριού, περάσαμε μια πολύ ωραία βραδιά με την αγαπημένη μας θεατρολόγο και αντιπρόεδρο του Συλλόγου μας κ. Νάσια Δαφιώτη στο ρόλο της αφήγησης των παραμυθιών και τον εξαίρετο δάσκαλο μουσικής του Συλλόγου μας τον κ.  Γρηγόρη στο τραγούδι με την κιθάρα του!!! </Typography>
<Typography variant="h3" sx={theme.typography.typography2}>
  Μια καταπληκτική βραδιά για τους μικρούς μας φίλους που συμμετείχαν με ενθουσιασμό στα δρώμενα, αλλά και τους γονείς των παιδιών που απόλαυσαν τα υπέροχα τραγούδια που συνόδευσαν τα παραμύθια, στην εξαιρετική από κάθε άποψη καλοκαιρινή βραδιά…<br/>
  Μια βραδιά ενταγμένη στο πρόγραμμα του Λεσβιακού καλοκαιριού που χάρισε πολλά, πολλά χαμόγελα σε όλα τα παιδιά, μικρά και μεγάλα!!!<br/><br/>
Και του χρόνου με υγεία!!!
  

	
</Typography>


    
</Box>
	
	
	<ScrollToTopButton />






<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Η Γ.Γ. Αιγαίου και Νησιωτικής Πολιτικής ενισχύει τον Σύλλογο μας..</Typography>    
	
	<img src={Image38}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
   

	<Typography variant="h3" sx={theme.typography.typography2}>
	Η Γενική Γραμματεία Αιγαίου και Νησιωτικής Πολιτικής του Υπουργείου Ναυτιλίας και Νησιωτικής Πολιτικής επιχορήγησε μετά από σχετικό αίτημα τον Σύλλογό μας για τις πολιτιστικές του δραστηριότητες του 2018 και συγκεκριμένα:<br/>
*Για την διοργάνωση χοροεσπερίδας και τελετής βράβευσης αριστούχων μαθητών της περιοχής μας αλλά και των παιδιών που διακρίθηκαν στο διαγωνισμό ζωγραφικής και έκθεσης για το περιβάλλον, που πραγματοποιήθηκε στις 4 Αυγούστου 2018 στο κτήμα «Ελιά και Θάλασσα» του ξενοδοχείου ΖΑΙΡΑ.<br/>
*Για τις διήμερες εκδηλώσεις τις αφιερωμένες στην Μικρά Ασία, που πραγματοποιήθηκαν στις 22 και 23 Αυγούστου 2018 και
*Για την έκδοση του περιοδικού «ΤΑ ΝΕΑ ΜΑΣ», 1000 αντιτύπων.<br/>
Ο Σύλλογος μας ευχαριστεί θερμά τον Υφυπουργό Ναυτιλίας κ. Νεκτάριο Σαντορινιό και τον Γ.Γ. Αιγαίου και Νησιωτικής Πολιτικής κ. Ιωάννη Γιαννέλλη για την θετική ανταπόκριση τους στο αίτημα μας.<br/>  
	

	</Typography>
	

    
	</Box>
	
	
	<ScrollToTopButton />







<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Διεθνής ημέρα Μουσείων</Typography>    
	
	<img src={Image40}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image42}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    
	<Typography variant="h3" sx={theme.typography.typography2}>
Το Διεθνές Συμβούλιο Μουσείων (ICOM), επιθυμώντας να αναδείξει το ρόλο των Μουσείων στη σύγχρονη κοινωνία, έχει ορίσει από το<b>1977</b> την <b>18η Μαΐου</b> ως <b>Διεθνή Ημέρα Μουσείων.</b><br/>
Έτσι και φέτος τιμώντας αυτή την επέτειο, το «<b>Μουσείο Προσφυγικής Μνήμης 1922</b>» στη Σκάλα Λουτρών, παρέμεινε ανοικτό για τους επισκέπτες από τις <b>09.00</b> έως τις <b>12.00</b> και από τις <b>18.00</b> έως τις <b>20.00</b>.<br/></Typography>
<Typography variant="h3" sx={theme.typography.typography2}>
Το μήνυμα αυτής της επετείου είναι «να γίνουν τα Μουσεία φορείς πολιτισμικών ανταλλαγών, με σκοπό την ανάπτυξη της μόρφωσης, της αμοιβαίας κατανόησης, της συνεργασίας και της ειρήνης ανάμεσα στους λαούς».<br/>
Επίσης το «<b>Μουσείο Προσφυγικής Μνήμης 1922</b>» καθ όλη την θερινή περίοδο ήταν ανοικτό τις ημέρες Δευτέρα και Πέμπτη και από ώρα 18.00 έως 20.00.             <br/><br/>
Υπενθυμίζουμε ότι κατά την χειμερινή περίοδο το «Μουσείο Προσφυγικής Μνήμης 1922» δέχεται επισκέψεις, μόνον κατόπιν τηλεφωνικής συνεννοήσεως στα τηλέφωνα:<br/><br/>
69746.40419 κ. Δημήτρη Παπαχρυσό και<br/>
22510.91277 κ. Ευθαλία Τουρλή.

	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />






<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Προσφυγική οικία ιστορικής μνήμης</Typography>    
	
	<img src={Image44}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<br/>
	<Typography variant="h3" sx={theme.typography.typography2}>

  
   Στα πλαίσια των δραστηριοτήτων του Συλλόγου μας, με αίτησή μας προς το Υπουργείο Πολιτισμού, επιδιώξαμε και επιτύχαμε στο παρελθόν τον χαρακτηρισμό ως μνημείου (ΦΕΚ 138/24-11-2006) της λιθόκτιστης προσφυγικής οικίας στη Σκάλα Λουτρών ιδιοκτησίας Αναστασίας Φραγκουλάκη, η οποία φιλοξενείτο επί 25 περίπου έτη στο Ίδρυμα Θεομήτωρ Αγιάσου, και όπως κατά το παρελθόν μας πληροφόρησαν δεν είχε δικαιοπρακτική ικανότητα καθώς ήταν υπερήλικη και με προβλήματα υγείας.</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>
   Η οικία αυτή είναι μία από τις 25 πανομοιότυπες προσφυγικές οικίες του Προσφυγικού Συνοικισμού της Σκάλας Λουτρών και το ενδιαφέρον μας για να διασωθεί είναι μεγάλο πριν αυτή καταρρεύσει ολότελα, διότι, αποτελεί ιδιαίτερα σημαντικό από ιστορικής απόψεως μνημείο που συνδέεται με τα γεγονότα που επακολούθησαν τη Μικρασιατική καταστροφή και με την προσπάθεια να ενταχθεί στις τοπικές κοινωνίες το μεγάλο κύμα προσφύγων του
1922.</Typography>
<Typography variant="h3" sx={theme.typography.typography2}>
   Η οικία είναι η μοναδική από τον αρχικό πυρήνα των κατοικιών που διασώζεται χωρίς παρεμβάσεις, προσθήκες η μετατροπές έως σήμερα και αποτελεί σημείο αναφοράς και ιστορικής μνήμης των κατοίκων του χωριού μας και του νησιού μας γενικότερα.
</Typography>
<Typography variant="h3" sx={theme.typography.typography2}>
   Πρόθεσή μας ήταν αυτή η οικία η οποία βρίσκεται απέναντι από το «Μουσείο Προσφυγικής Μνήμης 1922» να περιέλθει με κάποιο τρόπο για χρήση στον Σύλλογό μας ούτως ώστε να επισκευαστεί και να εκτεθούν μέσα σ αυτήν όλα τα αντικείμενα που χρησίμευαν για την επιβίωσή τους οι πρόσφυγες από την Μικρά Ασία και να αποτελέσει έτσι ένα ενιαίο σύνολο με το Μουσείο.</Typography>
  

   	<Typography variant="h3" sx={theme.typography.typography2}>
   Για τον λόγο αυτό ο Σύλλογός μας έστειλε επιστολή στον Δήμαρχο Μυτιλήνης από τις 27 Μαΐου του 2015 με όλα ανεξαιρέτως τα δικαιολογητικά που μας ζητήθηκαν με σκοπό να προχωρήσει στην αγορά η σε περίπτωση διαφορετική στην απαλλοτρίωση της προς επίτευξη των προαναφερθέντων.
   Δυστυχώς μέχρι σήμερα (τέλος Νοεμβρίου) και παρά τις επανειλημμένες επισκέψεις μας τόσο προς τον Δήμαρχο όσο και στους εκάστοτε αρμόδιους αντιδημάρχους η πρόταση μας δεν έχει έρθει προς συζήτηση ούτε καν στο Δημοτικό Συμβούλιο για να παρθεί η σχετική απόφαση με αποτέλεσμα πριν λίγους μήνες να καταρρεύσει όλη η σκεπή της οικίας και να υπάρχει ο κίνδυνος τώρα και της κατάρρευσης της τειχοποιίας της.
</Typography>
<Typography variant="h3" sx={theme.typography.typography2}>
   Εκφράζουμε την έντονη δυσαρέσκεια μας και την λύπη μας για την απραξία του Δήμου μας σε ένα τόσο σημαντικό από ιστορικής απόψεως θέμα, και ευελπιστούμε πως έστω και καθυστερημένα θα προχωρήσει τάχιστα στις ενέργειες που απαιτούνται για την υλοποίηση των σχετικών διαδικασιών. Δεν πρέπει μάλιστα να  αγνοείται ότι, ο πληθυσμός της Μυτιλήνης σε απογραφή του 1927 αποτελείτο κατά 45% από Μικρασιάτες Πρόσφυγες.
</Typography>

	

    
	</Box>
	
	
	<ScrollToTopButton />










<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Εργασίες συντήρησης στο Μουσείο</Typography>    
	
	<img src={Image46}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image48}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />    

	<Typography variant="h3" sx={theme.typography.typography2}>
	Με πρωτοβουλία του Δ.Σ. του Συλλόγου μας και με την αγορά των κατάλληλων υλικών πραγματοποιήθηκαν εκτεταμένες εργασίες συντήρησης στην εσωτερική, και στην εξωτερική τοιχοποιία του «Μουσείου Προσφυγικής Μνήμης 1922», λόγω της εκτεταμένης υγρασίας που είχε εμφανιστεί εδώ και αρκετό διάστημα.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>
  Επίσης πραγματοποιήθηκαν εργασίες υγρομόνωσης στην ταράτσα του Μουσείου με επάλειψη ασταριού και ελαστομερούς υλικού, το οποίο μας προμήθευσε ο Δήμος Μυτιλήνης ύστερα από αίτημά μας προς αυτόν και προς το Τοπικό μας Συμβούλιο τους οποίους και ευχαριστούμε.
	

	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />







<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Καλλωπισμός του αύλιου χώρου Μουσείου Προσφυγικής Μνήμης 1922</Typography>    
	<img src={Image50}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    
	

	<Typography variant="h3" sx={theme.typography.typography2}>
Την Κυριακή 13 Μαΐου 2018 πραγματοποιήθηκε περιβαλλοντική δράση καθαρισμού και καλλωπισμού του αύλιου χώρου του «Μουσείου Προσφυγικής Μνήμης 1922» με θέμα «Καλλωπίζω τον αύλιο χώρο του Μουσείου, φυτεύοντας λουλούδια». Για τον σκοπό αυτό αγοράσθηκαν και φυτεύτηκαν 38 γλάστρες με λουλούδια και θάμνους στα υφιστάμενα παρτέρια με την συμμετοχή των μελών του Συλλόγου μας και των μικρών μας φίλων, οι οποίοι έμαθαν με μεγάλη ευκολία τον τρόπο που φυτεύουμε τα λουλούδια και τους θάμνους. 
	</Typography>



    
	</Box>
	
	
	<ScrollToTopButton />




<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Τελετή βράβευσης αριστούχων μαθητών</Typography>    
	
	<img src={Image52}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image54}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    	

	<Typography variant="h3" sx={theme.typography.typography2}>
Με επιτυχία πραγματοποιήθηκε η τελετή βράβευσης των αριστούχων μαθητών του Συλλόγου Μικρασιατών της Σκάλας Λουτρών «<b>ΤΟ ΔΕΛΦΙΝΙ»</b> το Σάββατο <b>4</b> Αυγούστου <b>2018</b> στο κτήμα «Ελιά και θάλασσα» του ξενοδοχείου ΖΑΙΡΑ. Με την παρουσία του πρ. της Δημοτικής Κοινότητας Λουτρών κ. Β. Μαμώλη, της διευθύντριας του Δημοτικού Σχολείου Λουτρών κ. Εριφύλης Χιωτέλλη, του πρ. του Αετού Λουτρών κ. Ν. Σπανού, του αντιπροέδρου του Παγγεραγωτικού Συλλόγου Αθήνας κ. Δημ. Ευσταθίου, και την συμμετοχή των μελών και των φίλων του Συλλόγου περάσαμε μια υπέροχη βραδιά δίπλα στη θάλασσα, με θέα τον κόλπο  και τα απέναντι χωριά της Γέρας. </Typography>
<Typography variant="h3" sx={theme.typography.typography2}>
    Κατά την διάρκεια της εκδήλωσης έγινε μια σύντομη ενημέρωση από τον πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό, σχετική με τις τρέχουσες δραστηριότητες και εν συνεχεία πραγματοποιήθηκε η καθιερωμένη βράβευση των αριστούχων μαθητών του Γυμνασίου και του Λυκείου της δημοτικής κοινότητας Λουτρών.</Typography>
    <Typography variant="h3" sx={theme.typography.typography2}>
   Μήνυμα μας έστειλε ο Γ.Γ. Αιγαίου και Νησιωτικής Πολιτικής κ. Ι. Γιαννέλλης, με το οποίο μας ενημερώνει ότι λόγω ανειλημμένων υποχρεώσεων δεν θα παρευρίσκετο στην0 εκδήλωση μας και ότι σε τέτοιες εκδηλώσεις θα είναι πάντα αρωγός. Τον ευχαριστούμε.</Typography>

 <Typography variant="h3" sx={theme.typography.typography2}>  
Βραβεύτηκαν οι παρακάτω: <br/><br/>
Από  την   <b>Α!</b>    γυμνασίου       ο Στρίγκος Μάριος  και   <br/>     
                                                  ο Αλεξίου Γεώργιος<br/><br/>                                              

Από την    <b>Β!</b>    γυμνασίου      η Ευαγγέλου Χριστίνα<br/>
                                                 η Μύνικνα Ειρήνη<br/>
                                                 ο Πλιακάτος Παναγιώτης<br/> 
                                                 ο Τριανταφύλλου Παναγιώτης και<br/>
                                                 η Στυψιανού Μυρσίνη<br/><br/>

Από την     <b>Γ!</b>    γυμνασίου      η  Αλεξίου Μαρία<br/>
                                                  η Μαμώλη Ραφαηλία Μαρίνα<br/>
                                                  ο Κωστής Παρασκευάς και<br/>
                                                  ο Λέλεκας Κωνσταντίνος<br/><br/>
 
    Από την    <b>Α!</b>    λυκείου        η Γκύζα Κωνσταντίνα <br/>
                                                  η Σφακιανού Γαρυφαλιά και<br/>
                                                  η Ζούρου Χριστίνα<br/>    <br/>

Από την     <b>Γ!</b>    λυκείου           ο  Ντινιακός Μαρίνος <br/>
                                                  ο Σαράκης Ακίνδυνος<br/>
                                                  η Λικολλάρι Έρικα και<br/>
                                                  η Ευαγγελέλλη Μαρία<br/><br/>
                                              </Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Επίσης δόθηκαν έπαινοι σε παιδιά του Δημοτικού Σχολείου Λουτρών που διακρίθηκαν στο διαγωνισμό ζωγραφικής και έκθεσης με θέμα το περιβάλλον ο οποίος είχε πραγματοποιηθεί στις 5 Ιουνίου.<br/>
<u>Οι μαθητές και οι μαθήτριες που διακρίθηκαν είναι οι παρακάτω:</u><br/><br/>
Από την <b>Α! Τάξη</b> που έχει δύο τμήματα ο Παπουτσής Ταξιάρχης,<br/>
 η Λύρα Ουρανία, η Μπλέτσα Παναγιώτα και η Τατάκη Αλεξάνδρα.<br/>
Από την <b>Β! Τάξη</b>   η Γκέντση Μαριτίνα και η Κλάβα Σοφία.<br/>
Από την <b>Γ! Τάξη</b>   η Βαιοπούλου Ελένη Όλγα και η Κλάβα Μαρία.<br/>
Από την <b>Δ! Τάξη</b>   η Βασιλέλλη Μαρία και η Γρημάνη Μαρία.<br/>
Από την <b>Ε! Τάξη</b>   ο Κέντσης Στράτος και η  Τριανταφύλλου Θεανώ.  <br/>                     .
Από την <b>ΣΤ! Τάξη</b> η Κέντση Μαρία και η Τρουλινού Κατερίνα.    <br/><br/></Typography>

<Typography variant="h3" sx={theme.typography.typography2}>

   Εκτός από τον έπαινο και το περιοδικό του Συλλόγου στην τσάντα που δόθηκε στα παιδιά περιλαμβανόταν και ένα πρωτότυπο δώρο το οποίο το ονομάσαμε «<b>Το κλειδί της γνώσης</b>». Αυτό ήταν ένα USB STICK 16 Gigabyte σε σχήμα κλειδιού που  περιελάμβανε <b>12</b> βιβλία σχετικά με το περιβάλλον καθώς και ορισμένα αρχεία από τις δραστηριότητες του Συλλόγου. Επίσης στην τσάντα περιλαμβανόταν και ένα σημειωματάριο με στυλό και αριθμομηχανή.<br/>
   Στα αριστούχα παιδιά του Γυμνασίου και του Λυκείου εκτός των προαναφερθέντων δώρων, δόθηκε επιπλέον ένα βιβλίο με αναφορά στα σχολεία της Σμύρνης, καθώς και ένα ρολόι χειρός νέας τεχνολογίας <b>smart watc</b> της <b>Huawei</b>. <br/>
 Εδώ να αναφέρουμε ότι η Γ.Γ. Αιγαίου και Νησιωτικής Πολιτικής συμμετείχε στα έξοδα βράβευσης των μαθητών και ευχαριστούμε ιδιαιτέρως τον Γ.Γ. κ. Ιωάννη Γιαννέλλη που ανταποκρίθηκε στο αίτημα του Δ.Σ. του Συλλόγου μας. Επίσης το γεύμα των αριστούχων παιδιών ήταν προσφορά της επιχείρησης «Catering Ελαίτης» εκφράζοντας έτσι τις ευχαριστίες μας γι αυτό.<br/><br/>
</Typography>

<Typography variant="h3" sx={theme.typography.typography2}>
   Τέλος κατά την διάρκεια της εκδήλωσης πραγματοποιήθηκε η κλήρωση των δώρων της λαχειοφόρου αγοράς του Συλλόγου  και κέρδισαν οι παρακάτω αριθμοί.<br/>
<b>1ος</b> λαχνός το νούμερο <b>305</b> κερδίζει μια τηλεόραση LED 32΄΄<br/>
<b>2ος</b> λαχνός το νούμερο <b>146</b> κερδίζει ένα πλυστικό πιεστικό μηχάνημα EINHELL<br/>
<b>3ος</b> λαχνός το νούμερο  <b>264</b> κερδίζει ένα έγχρωμο πολυμηχάνημα ΗΡ <br/>
<b>4ος</b> λαχνός το νούμερο   <b>83</b>  κερδίζει μία ψηφιακή κορνίζα 7΄΄ και<br/>
<b>5ος</b> λαχνός το νούμερο   <b>568</b> κερδίζει έναν ανεμιστήρα επιτραπέζιο<br/>

	</Typography>

	

    
	</Box>
	
	
	<ScrollToTopButton />











<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>20-8-2018 Συναυλία με την ορχήστρα Βυζαντινής Μουσικής της Μητροπόλεως Μυτιλήνης</Typography>    
	
	<img src={Image56}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image58}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    

	<Typography variant="h3" sx={theme.typography.typography2}>
    Το βράδυ της Δευτέρας 20 Αυγούστου 2018 πραγματοποιήθηκε με επιτυχία στο θερινό αμφιθέατρο της Σκάλας Λουτρών η συναυλία με την ορχήστρα Βυζαντινής Μουσικής της Μητροπόλεως Μυτιλήνης «Άγιος Θεόδωρος ο Βυζάντιος» και τίτλο «Τραγούδια της ξενιτιάς και της Προσφυγιάς». <br/>
  Η Σχολή Βυζαντινής Μουσικής της Μητροπόλεως Μυτιλήνης με την επωνυμία «Ο Άγιος Θεόδωρος ο Βυζάντιος» ιδρύθηκε από τον Μητροπολίτη Μυτιλήνης το 2015 και είναι ο πρόεδρο της.</Typography>
  <Typography variant="h3" sx={theme.typography.typography2}>
  Διευθυντής της Σχολής είναι ο πατήρ Ευστράτιος Γιουσμάς ο οποίος έχει ως συνεργάτες του έμπειρους καθηγητές μουσικής του νησιού μας.<br/>
  Σκοπός της Σχολής είναι η διάδοση και η σωστή εκμάθηση της Ελληνικής Μουσικής γι' αυτό και η Σχολή είναι αναγνωρισμένη από το Υπουργείο Πολιτισμού δίνοντας την δυνατότητα να παρέχει πτυχία και διπλώματα αναγνωρισμένα από το Κράτος και αυτή τη στιγμή έχει 60 σπουδαστές όλων των ηλικιών. <br/>
  Η χορωδία της Σχολής συμμετέχει σε συναυλίες, εκδηλώσεις και λατρευτικές συνάξεις εντός και εκτός νησιού, πρόσφατα δε έδωσε συναυλία στην Κωνσταντινούπολη όπου καλέστηκε από τον Οικουμενικό Πατριάρχη κ. Βαρθολομαίο για να συμμετάσχει στα εγκαίνια ναϋδρίου στο Νιχώρι Κωνσταντινουπόλεως.<br/>
  Παράλληλα με την χορωδία λειτουργεί και ορχήστρα παραδοσιακής μουσικής όπου μέλη της βρίσκονται σήμερα εδώ στη Σκάλα Λουτρών.<br/>
  Η ορχήστρα της Σχολής έχει δώσει αρκετές συναυλίες εντός και εκτός νησιού και στους επόμενους μήνες θα βρεθεί στην Αυστραλία και συγκεκριμένα στο Σίδνεϋ για να δώσει μια συναυλία στην Ελληνική Παροικία. 
  </Typography>
  <Typography variant="h3" sx={theme.typography.typography2}>
  Τα μαθήματα της Σχολής ξεκινούν τον Σεπτέμβριο και ολοκληρώνονται τον Ιούνιο με τις προαγωγικές ή πτυχιακές εξετάσεις. <br/>
  Από καρδιάς ευχαριστούμε τον πατέρα Ευστράτιο Γιουσμά που αποδέχτηκε το αίτημα του Συλλόγου μας και παρουσίασε μαζί με την ορχήστρα του, εδώ στο θερινό αμφιθέατρο της Σκάλας Λουτρών, τα «Τραγούδια της ξενιτιάς και της Προσφυγιάς».<br/>
   Το Θεατράκι μας ήταν κατάμεστο από τα μέλη και τους φίλους του Συλλόγου μας και η αλήθεια είναι πως περάσαμε μια καταπληκτική βραδιά που ενθουσίασε όλους τους παρευρισκόμενους και θα μας μείνει αξέχαστη για πολύ καιρό. Να διευκρινίσουμε πως ενώ το κανονικό πρόγραμμα ήταν διάρκειας περίπου 90 λεπτών αυτό τελικά προσέγγισε τις τρεις ώρες. Την εκδήλωση τίμησαν με την παρουσία τους ο αντιπεριφερειάρχης κ. Βαλσαμίδης, ο πρόεδρος του τοπικού μας διαμερίσματος κ. Μαμώλης, ο πρόεδρος του Αετού Λουτρών κ. Σπανός και πολλοί άλλοι.</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>
   Μετά το πέρας της συναυλίας, ο Σύλλογος μας πρόσφερε σε ταβέρνα της Σκάλας Λουτρών ένα ουζάκι σε όλα τα μέλη της ορχήστρας. Από καρδιάς τους ευχαριστούμε.<br/><br/>
 * Η εκδήλωση ήταν ενταγμένη στα πλαίσια του Λεσβιακού καλοκαιριού και υποστηρίχθηκε από το ΝΠΔΔ Πολιτισμού, Αθλητισμού και Τουρισμού του Δήμου Λέσβου, καθώς και από την Γ.Γ. Αιγαίου και Νησιωτικής Πολιτικής. 

  


	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />


    



<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Αφιέρωμα στους προσκόπους της Μικρασιατικής Γης</Typography>    
	
	<img src={Image60}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image62}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    

	<Typography variant="h3" sx={theme.typography.typography2}>
  Πραγματοποιήθηκαν με μεγάλη επιτυχία οι εκδηλώσεις (20-22 και 23 Αυγούστου) του Συλλόγου μας «Το Δελφίνι» οι οποίες ήταν αφιερωμένες στις αλησμόνητες πατρίδες της Ανατολής. Η εκδήλωση της Τετάρτης 22 Αυγούστου ξεκίνησε στις 19.30 με την τέλεση πανηγυρικού Εσπερινού στο εκκλησάκι της Παναγίας και την περιφορά της εικόνας από τις κυρίες του περιφερειακού τμήματος του Ερυθρού Σταυρού, μέχρι την αυλή του «Μουσείου Προσφυγικής Μνήμης 1922».
   Ακολούθησε αρτοκλασία από τον ιερέα της ενορίας μας και επιμνημόσυνη δέηση υπέρ αναπαύσεως των ψυχών των Μικρασιατών προσφύγων που εγκαταστάθηκαν στη Σκάλα Λουτρών και μνημονεύτηκαν τα ονόματά τους.</Typography>
   <Typography variant="h3" sx={theme.typography.typography2}>
   Στην συνέχεια ακολούθησε το αφιέρωμα για τον προσκοπισμό στην Μικρά Ασία με την διαφορά ότι σοβαροί προσωπικοί λόγοι δεν επέτρεψαν στον δημοσιογράφο και Περιφερειακό Έφορο Προσκόπων Λέσβου κ. Στρατή Μπαλάσκα να είναι κοντά μας, γι αυτό η παρουσίαση για το συγκεκριμένο θέμα που είχε προετοιμαστεί από τον κ. Μπαλάσκα έγινε από την αντιπρόεδρο του Συλλόγου μας την κ. Νάσια Δαφιώτη.

   <h4>Αφιέρωμα στους προσκόπους της Μικρασιατικής Γης.</h4></Typography>

<i><Typography variant="h3" sx={theme.typography.typography2}>
   «Αγαπητοί συμπατριώτες Μικρασιάτες στη Σκάλα Λουτρών. Η πρώτη επίσημη εμφάνιση των Ελλήνων Προσκόπων, που ιδρύθηκαν από τον Αθανάσιο Λευκαδίτη στην Αθήνα το 1910, έγινε στη γιορτή των «Ανθεστηρίων». Σταδιακά το μοναδικό αυτό κίνημα νέων αναπτύχθηκε ενώ η απελευθέρωση της Μακεδονίας, της Ηπείρου και των νησιών δίνει τη δυνατότητα για την ανάπτυξη προσκοπικών ομάδων σε όλη την επικράτεια βοηθώντας στην ενσωμάτωση των νέων αυτών περιοχών στην Ελλάδα.
   Μετά την υπογραφή της Συνθήκης του Μούδρου στις 31 Οκτωβρίου του 1918, ξεκινά το χρονικό της ίδρυσης των ελληνικών προσκοπικών ομάδων της Μικρασίας. Από τη Σμύρνη, την πρωτεύουσα της Ιωνίας όπου είχε εγκατασταθεί ο Ευάγγελος Ιωαννίδης, παλαιός πρόσκοπος και Αρχηγός ομάδος από την Αλεξάνδρεια της Αιγύπτου μαζί με τον Γεώργιο Παπαδημητρίου πήραν την πρωτοβουλία να ιδρύσουν ελληνικές προσκοπικές ομάδες. Στην προσπάθειά τους αυτή βρήκαν συμπαράσταση από τον Δημήτριο Δάλλα που ήταν από τους πρωτοστάτες της εκεί αθλητικής κίνησης του Πανιωνίου Γυμναστικού Συλλόγου κατά τη διάρκεια εκείνης της περιόδου. Συνάντησαν, επίσης, τον Δημήτριο Αγγελομάτη και τον ποιητή Αλέκο Φωτιάδη που είχε παρακολουθήσει τον προσκοπισμό στην Αγγλία.</Typography>
   <Typography variant="h3" sx={theme.typography.typography2}>
   Τον Φεβρουάριο του 1919 έφτασε στη Σμύρνη ο τότε γενικός έφορος του Προσκοπισμού Κωνσταντίνος Μελάς. Πρόκειται για τον αδελφό του Παύλου Μελά, απελευθερωτή της Λέσβου, πρώτο πολιτικό και στρατιωτικό διοικητή του νησιού και ιδρυτή στις 21 Ιανουαρίου του 1913 των Προσκόπων της Λέσβου.
   Στη Σμύρνη μετά την άφιξη του Μελά ο οποίος εν τω μεταξύ κατ’ εντολή του Ελευθερίου Βενιζέλου έχει αποστρατευθεί και ασχολείται αποκλειστικά με την Προσκοπική κίνηση, μέσω επαφών με τοπικούς παράγοντες, σχηματίσθηκε περιφερειακή επιτροπή και διορίσθηκαν ντόπιοι Μικρασιάτες Έφοροι. Ιδρύθηκαν έτσι πολλές ομάδες. Εκατοντάδες νέοι της Σμύρνης και περιχώρων γίνονται Πρόσκοποι και με μεγάλο ενθουσιασμό βοηθούσαν σε όλες τις εθνικές, θρησκευτικές, αθλητικές και φιλανθρωπικές εκδηλώσεις.</Typography>
   

   Με την άφιξη του ελληνικού στρατού στην Ιωνία, ο ρόλος αυτών των παιδιών αναβαθμίζεται. 65 ομάδες προσκόπων λειτουργούν στη Σμύρνη και σε κωμοπόλεις και εργάζονται ως αγγελιαφόροι, διερμηνείς, γραφείς, οδηγοί και 
   τραυματιοφορείς αποσπώντας τον θαυμασμό όλων. Πρόσφεραν ακόμη τις υπηρεσίες τους στο Φρουραρχείο, το Λιμεναρχείο, την Επιμελητεία, το Γραφείο Τύπου, στο Αρχηγείο Στρατού και, γενικά, όπου χρειαζόταν βοηθητική υπηρεσία. 
   Στην Ευαγγελική Σχολή είχε κατασκηνώσει για ένα μήνα ομάδα μεγαλύτερων προσκόπων της οποίας τα μέλη συνόδευαν τις στρατιωτικές περιπόλους ως οδηγοί, μέρα νύχτα, στις διάφορες συνοικίες της πόλης.
   Υποστηριζόμενοι από τον μαρτυρικό μετέπειτα μητροπολίτη Σμύρνης Χρυσόστομο, τον Απρίλιο του 1920, εκδίδουν δικό τους περιοδικό, τον «Πρόσκοπο της Ιωνίας» και έχουν δικό τους ύμνο που μελοποίησε ο συνθέτης Δημήτρης Μιλανάκης 
   σε στίχους Μιχάλη Αργυρόπουλου. Παντού οι Μικρασιατικές προσκοπικές ομάδες λειτούργησαν, πραγματικό καμάρι του τόπου τους.</i>


<h4>Η υπόθεση Αϊδίνι</h4> 

	<i>
    
    <Typography variant="h3" sx={theme.typography.typography2}>
   Λίγες μέρες μετά την είσοδο του Ελληνικού Στρατού στη Σμύρνη, έπειτα από πληροφορίες ότι στο Αϊδίνιο συγκεντρώθηκε μεγάλος αριθμός Τούρκων, στάλθηκε ισχυρή στρατιωτική δύναμη με επικεφαλής τον αντισυνταγματάρχη Σχοινά για να αντιμετωπίσει την κατάσταση. Η τουρκική επίθεση αιφνιδίασε το στρατό, που αποχώρησε από το Αϊδίνιο αφήνοντας τους προσκόπους να υπερασπιστούν την πόλη και να φροντίσουν τα γυναικόπαιδα, τους ανήμπορους και τους τραυματίες.<br/>
   Το πέρασμα των Τούρκων ατάκτων από το Αϊδίνι κράτησε τρεις μέρες και δεν άφησε τίποτα όρθιο. Πυρπόλησαν σπίτια, λεηλάτησαν περιουσίες, ρήμαξαν τα πάντα. Η μανία τους ξέσπασε με ξεχωριστό πάθος στους ένστολους προσκόπους που φρόντιζαν τους κατοίκους. Όσους δεν σκότωσαν επιτόπου, τους οδήγησαν, μαζί με τον Έφορο Νίκο Αυγερίδη, έπειτα από μια δραματική νύχτα στο μπουντρούμι του Διοικητηρίου, στις όχθες τους Εύδωνα ποταμού.<br/>
Εκεί, συνολικά 31 άτομα, εκτελέσθηκαν μέχρις ενός. Για το ομαδικό αυτό έγκλημα αντέδρασαν δυναμικά οι Ελληνικές Αρχές, ο ελληνικός και ο παγκόσμιος Τύπος, καθώς και πολλές προσωπικότητες.</Typography></i>




<h4>Άλλες υποθέσεις</h4>
   <i><Typography variant="h3" sx={theme.typography.typography2}>Στα Σώκια, κωμόπολη του νομού Σμύρνης που, κατά την περίοδο 1919-22, βρισκόταν υπό ιταλική διοίκηση, εξαφανίστηκαν άλλοι 78 πρόσκοποι που μαζί με προκρίτους της περιοχής είχαν φυλακίσει αναίτια οι Τούρκοι επί μήνες.<br/>
   Τον Απρίλιο του 1920, όταν πλησίαζε ο ελληνικός στρατός, Τούρκοι άτακτοι στρατιώτες έσυραν στα βουνά τους προσκόπους όπου εξαφανίστηκαν.
   Μαρτυρίες υπάρχουν για την τύχη των προσκόπων του χωριού Κάτω Παναγιά (σημερινό Γενήκιοϊ) της χερσονήσου της Ερυθραίας. Τον Αύγουστο του 1922 μαζί με άλλους 800 συγχωριανούς τους βρήκαν τον θάνατο οι πρόσκοποι Κώστας Θεοφανίδης (γιοςτου δασκάλου της τάξης Ιωαν. Θεοφανίδη) κι οι συμμαθητές του Κυριάκος Μίχαλος, Αθαν. Καμπάνης και Δημ. Οικονομίδης.<br/>
   Είμαστε εδώ, σήμερα 99 χρόνια μετά, μια ζεστή καλοκαιρινή μέρα σαν και εκείνη την 17η Ιουνίου 1919, γιατί αγαπάμε την Ειρήνη. Πολεμάμε για την Ειρήνη. Αλλά ταυτόχρονα και ξέρουμε. Πως η παρέα μας, η παρέα των προσκόπων που θέλουμε να είμαστε πάντα έτοιμοι και πάντα να αριστεύουμε ερχόμαστε από μακριά. Έχουμε ιστορία, μια μακριά και σημαντική ιστορία προσφοράς στην πατρίδα. Αγαπάμε την πατρίδα μας, γιατί πολεμήσαμε για αυτήν, κάποια παιδιά σαν τα παιδιά της σημερινής συνάντησης, σαν εμάς που παλιότερα υπήρξαμε παιδιά και παραμένουμε πρόσκοποι πολέμησαν γι αυτήν την πατρίδα. Για τούτο την πατρίδα μας τη θέλουμε αναπτυγμένη, ικανή να μας προσφέρει τα πάντα όπως τα πάντα δώσαμε σαν πρόσκοποι για αυτήν όταν χρειάστηκε.<br/>
Για να συνεχίσουν να υπάρχουν Πρόσκοποι.<br/>
Είπαμε. Ερχόμαστε από μακριά και ο δρόμος μας είναι μακρύς. Πάμε μακριά…<br/><br/>
Αγαπητοί φίλοι.<br/>
   Σχεδόν 100 χρόνια μετά από την ίδρυση των προσκόπων στη Μικρασιατική γη ήρθαμε εδώ στη φιλόξενη Εστία των Μκρασιατών της Σκάλας Λουτρών για να διαδηλώσουμε πως θυμόμαστε τα παιδιά του Αϊδινίου. Θυμόμαστε τα παιδιά που χάθηκαν στα Σώκια αλλά και στη Σμύρνη, και στο Αίβαλί και στην Πέργαμο, στην Κάτω Παναγιά και στον Τσεσμέ της Ερυθραίας. Όπου στήθηκαν προσκοπικές ομάδες ή όπου δεν έφθασε ο Προσκοπισμός στη Μικρασιατική γη. Θυμόμαστε όλα τα παιδιά που χάθηκαν το 1922 κι όσα ήρθαν εδώ στη Λέσβο και στην υπόλοιπη Ελλάδα κουβαλώντας τη μοναδική αξία του Μικρασιάτη πρόσκοπου.<br/>
   Όσα παιδιά πρόσφυγες και Ελλαδίτες βοήθησαν για το ξαναρίζωμα δυο εκατομμυρίων Ελλήνων προσφύγων, όσα παιδιά βοήθησαν το συνάνθρωπο τους σε δύσκολες εποχές για την πατρίδα αλλά και όπου το καθήκον τους κάλεσε από τότε και μέχρι σήμερα.<br/>
   Έχει σημασία που στο μπράτσο μας οι Πρόσκοποι της Λέσβου, στο θυρεό της περιφέρειας μαζί με το όνομα του νησιού μας Λέσβος έχουμε το κιονόκρανο της Αιολίδας, της αιολικής γης. Κουβαλάμε στο μπράτσο μας καντηλάκι άσβεστο την ιστορική μνήμη για τις γειτονιές του Ομήρου. Έσεται γαρ ημάς νόστιμον ήμαρ. Να ναι καλό το ταξίδι της επιστροφής. Να ζήσουμε την ειρηνική παλιννόστηση στις προγονικές εστίες. Να ζήσουμε την Ανάσταση…»</Typography></i>



   <Typography variant="h3" sx={theme.typography.typography2}>Η όλη παρουσίαση συνοδεύτηκε με την προβολή επιλεγμένων φωτογραφιών σχετικών με το θέμα που αναπτύχθηκε και αμέσως μετά μοιράστηκε το παραδοσιακό φαγητό «<b>Ο καβουρμάς</b>» μαγειρεμένο από τις νοικοκυρές του οικισμού μας μαζί με ένα ποτήρι λευκό κρασί, συντροφιά με τραγούδια και μουσικές της Μικράς Ασίας από την παραδοσιακή ορχήστρα «<b>Τα φώτα απέναντι</b>».
Κατά την διάρκεια της προαναφερθείσας εκδήλωσης, ο πρόεδρος του Συλλόγου μας κ. Δημήτρης Παπαχρυσός, με απόφαση του Δ.Σ. του Συλλόγου μας βράβευσε τον τελευταίο δάσκαλο του πρώην Δημοτικού Σχολείου Σκάλας Λουτρών κ. Γεώργιο Ψαρρό και του απένειμε μία αναμνηστική πλακέτα με την επιγραφή:

<i>«Ο Σύλλογος Μικρασιατών Σκάλας Λουτρών «Το Δελφίνι» στον συνταξιούχο δάσκαλο κ. Γεώργιο Ψαρρό -ΤΙΜΗΣ ΕΝΕΚΕΝ- για την πολύτιμη προσφορά του στους μαθητές του πρώην Δημοτικού Σχολείου Σκάλας Λουτρών κατά την χρονική περίοδο 1978 έως 2002.- Αύγουστος 2018».</i>
</Typography>
<Typography variant="h3" sx={theme.typography.typography2}>
Η τελετή βράβευσης του κ. Γεωργίου Ψαρρού έκλεισε με μία αναφορά στο έργο του από την πρώην μαθήτρια του δ. Ειρήνη Σταματέλλη.
Την επομένη το πρωί 23-8-2017 τελέσθηκε η καθιερωμένη Θεία Λειτουργία με αρτοκλασία στην Παναγιά την Αψηλή με την παρουσία πολλών κατοίκων από την Σκάλα Λουτρών, τα Λουτρά και το Πέραμα της Γέρας.<br/><br/>
 Και του χρόνου με υγεία. 
  

	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />













<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Ημέρα μνήμης της γενοκτονίας των Ελλήνων της Μικράς Ασίας</Typography>    
	
	<img src={Image64}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
   
	<Typography variant="h3" sx={theme.typography.typography2}>
       
Σύμφωνα με τις διατάξεις του Προεδρικού Διατάγματος 304/2001, η 14η Σεπτεμβρίου ορίζεται ως ημέρα Εθνικής μνήμης της γενοκτονίας των Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος. Η επέτειος μνήμης έχει σκοπό να τονίσει ιδιαίτερα και να υπενθυμίσει τα γεγονότα της δραματικής περιόδου της γενοκτονίας και του ξεριζωμού των Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος, που είχαν ως αποτέλεσμα να μετατρέψουν τους Έλληνες της Μικράς Ασίας σε έναν λαό προσφύγων της διασποράς. 
- Το πρόγραμμα εκδηλώσεων αυτής της επετείου μνήμης για αυτή την χρονιά πραγματοποιήθηκε την Κυριακή 17 Σεπτεμβρίου και περιελάμβανε:<br/><br/>
- Τέλεση δοξολογίας και αρτοκλασίας στον Ιερό Ναό Αγίου Νικολάου στην Επάνω Σκάλα.<br/>
-Τον πανηγυρικό της ημέρας εκφώνησε η κα Καίτη Στρατάκη, Διευθύντρια Α΄θμιας Εκπαίδευσης Λέσβου.<br/>
- Επιμνημόσυνη δέηση και κατάθεση στεφάνων στο χώρο του αγάλματος της Μικρασιάτισσας Μάνας (Επάνω Σκάλα), με τη συμμετοχή της Στρατιωτικής Μουσικής της 98 ΑΔΤΕ, από τον εκπρόσωπο της Κυβέρνησης, την Περιφερειάρχη Βορείου Αιγαίου, το Στρατιωτικό Διοικητή, το Δήμαρχο Λέσβου, τους εκπροσώπους Πολιτικών Κομμάτων, αντιπροσωπείες των Απόστρατων Αξιωματικών, εκπροσώπους των Εφεδροπολεμιστικών Οργανώσεων, εκπροσώπους νομικών προσώπων και συλλόγων.<br/>
Το Δ.Σ. του Συλλόγου μας, όπως κάθε χρόνο, παρευρέθη στις προαναφερθείσες εκδηλώσεις μνήμης, με κατάθεση στεφάνου.



	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />








<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Εκδήλωση μνήμης στο Γαλλικό Νεκροταφείο Λουτρών 100 χρόνια από την λήξη του Α! παγκοσμίου πολέμου</Typography>    
	
	<img src={Image66}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image68}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />  


	<Typography variant="h3" sx={theme.typography.typography2}>
       
  Πραγματοποιήθηκαν με επιτυχία οι εκδηλώσεις μνήμης για τα 100 χρόνια από την λήξη του Α! παγκοσμίου πολέμου στο Γαλλικό Νεκροταφείο Λουτρών οι οποίες περιελάμβαναν:<br/><br/>
*Τελετή ιστορικής μνήμης 1918-2018 στο Γαλλικό νεκροταφείο Λουτρών με κατάθεση στεφάνων από τις αρχές του τόπου, τις πρεσβείες και τον Σύλλογο μας, *Αποκαλυπτήρια τελετή της στήλης μνήμης από τον εκπρόσωπο της Γαλλικής Πρεσβείας.<br/>
*Επίσκεψη στο «Μουσείο Προσφυγικής Μνήμης 1922» της Σκάλας Λουτρών όπου υπήρχε έκθεση φωτογραφιών του Γάλλου αρχαιολόγου Φελίξ Σαρτιώ από την Φώκαια της Μικράς Ασίας και προσφορά αναψυκτικών στους επισκέπτες.<br/>
*Ημερίδα στον χώρο του νέου αρχαιολογικού Μουσείου Μυτιλήνης με θέμα: «Λήξη του πρώτου παγκοσμίου πολέμου – ο απόηχος 100 χρόνια μετά» με ομιλητές τους κ.κ. Αναστάσιο Αναστασιάδη, Βλάση Βλασίδη, Παναγιώτη Γρηγ0ορίου, Στέλιο Μάντζαρη, Ευστράτιο Αναγνώστου και Ευστράτιο Χαραλάμπους.<br/><br/>
  Οι ανωτέρω εκδηλώσεις πραγματοποιήθηκαν με πρωτοβουλία της επίτιμης προξένου Γαλλίας για Λέσβο και Λήμνο κ. Σοφίας Μπαρουτάκη – Τσιριγώτη.<br/>
	</Typography>


    
	</Box>
	
	
	<ScrollToTopButton />









<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Εκδήλωση μουσικού τμήματος.</Typography>    
	
	<img src={Image70}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image72}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    

	<Typography variant="h3" sx={theme.typography.typography2}>
    Με επιτυχία πραγματοποιήθηκε την Κυριακή 18-11-2018 το απόγευμα, στην αίθουσα πολιτισμού Λουτρών, η ετήσια εκδήλωση του μουσικού τμήματος του Συλλόγου Μικρασιατών της Σκάλας Λουτρών «ΤΟ ΔΕΛΦΙΝΙ».<br/>
Την εκδήλωση, παρακολούθησαν με μεγάλο ενδιαφέρον οι γονείς των παιδιών, ο πρόεδρος του τοπικού μας Συμβουλίου και πολλά μέλη του Συλλόγου οι οποίοι είχαν τη δυνατότητα να παρατηρήσουν από κοντά την πρόοδο των παιδιών τους στη μουσική και να τα καμαρώσουν γι αυτό.</Typography>

<Typography variant="h3" sx={theme.typography.typography2}>
Στο μουσικό τμήμα του Συλλόγου συμμετέχουν αυτή τη στιγμή 16 παιδιά ηλικίας από 6 έως 24 ετών, δάσκαλος είναι ο κ. Γρηγόρης Μολυβιάτης και τα μαθήματα για όσους ενδεχομένως ενδιαφέρονται γίνονται στην βιβλιοθήκη του Συλλόγου στη Σκάλα Λουτρών κάθε Σάββατο μετά το μεσημέρι.<br/><br/>
Στην εκδήλωση έπαιξαν κιθάρα και τραγούδησαν (κατά σειρά παρουσίασης) οι παρακάτω μαθητές και μαθήτριες:<br/>
Τάσος Φραντζέσκος, Πολύδωρος Τρουλινός, Γιάννης Δασκάλου, Άρης Μουσελίμης, Φίλιππος Καραμάνης, Γιώργος Καραμάνης, Φίλιππος Δασκάλου, Μιχάλης Τρουλινός, Πέρσα Γκαγκάνη, Δίας Μουσελίμης, Αλέξανδρος Μουσελίμης, Δέσποινα Καραβατάκη, Ντορέτα Λιαδέλλη, Νικολέτα Τριβέλλα, Απόστολος Κομνηνός και Παναγιώτα Παπαχρυσού
Μετά το πέρας της εκδήλωσης προσφέρθηκαν αναψυκτικά και γλυκίσματα στους μαθητές και σε όλους τους παρευρισκόμενους.<br/>
Την επιμέλεια της παρουσίασης της εκδήλωσης είχε η αντιπρόεδρος του Συλλόγου κ. Νάσια Δαφιώτη.

    
    


	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />








<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Εορτή του Αγίου Νικολάου στη Σκάλα Λουτρών</Typography>    
	
	<img src={Image74}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image76}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    

	<Typography variant="h3" sx={theme.typography.typography2}>
       
Πραγματοποιήθηκαν και εφέτος με λαμπρότητα και με καλό καιρό, οι εορταστικές εκδηλώσεις για την εορτή του Αγίου Νικολάου, στην Σκάλα Λουτρών, με την συμμετοχή των μελών του Συλλόγου ΔΕΛΦΙΝΙ, του προέδρου της Δημοτικής μας κοινότητας, και πολλών πιστών από την Σκάλα Λουτρών, τα Λουτρά, την Κουντουρουδιά και τη Μυτιλήνη.
    Συγκεκριμένα, ετελέσθη στο παρεκκλήσιο του Αγίου Νικολάου της Σκάλας Λουτρών, η Θεία Λειτουργία και στη συνέχεια έγινε η λιτάνευση της εικόνας του Αγίου Νικολάου, φερμένη το 1922 από τον Τσεσμέ της Μ. Ασίας παρουσία στρατιωτικού αγήματος από την μονάδα καταδρομέων του Περάματος, με κατεύθυνση την νέα προβλήτα του λιμανιού της Σκάλας Λουτρών.</Typography>
    <Typography variant="h3" sx={theme.typography.typography2}>
   Μετά την ευλογία των άρτων ο ιερέας και το στρατιωτικό άγημα επιβιβάσθηκαν στη βάρκα του κ. Ευστράτιου Καραντώνη από την Κουντουρουδιά  και κρατώντας την εικόνα του Αγίου την περιέφεραν, ρίχνοντας το στεφάνι που την πλαισίωνε στην θάλασσα και ευχόμενοι ήρεμα ταξίδια για τους ναυτικούς και τους αλιείς μας.
   Μετά το πέρας των θρησκευτικών εκδηλώσεων οι παριστάμενοι προσκλήθηκαν στην Βιβλιοθήκη του Συλλόγου όπου τους προσφέρθηκαν διάφορα εδέσματα, αναψυκτικά και καφές, όλα φτιαγμένα από τις νοικοκυρές του οικισμού μας. <br/><br/>
Και του χρόνου με υγεία.




	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />







<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Μουσικό τμήμα.</Typography>    
	
	<img src={Image78}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
   

	<Typography variant="h3" sx={theme.typography.typography2}>
       
Λειτουργεί στην Βιβλιοθήκη του Συλλόγου κάθε Σάββατο, από τις 1 το μεσημέρι μέχρι τις 8 το βράδυ και αυτή την περίοδο φοιτούν 16 παιδιά σχολικής ηλικίας τα οποία μαθαίνουν κιθάρα. Τα μαθήματα γίνονται στην Βιβλιοθήκη του Συλλόγου και εκτός από πρακτικά είναι και θεωρητικά έτσι ώστε τα παιδιά να αποκτούν γερές βάσεις και να συνεχίσουν μελλοντικά τις σπουδές τους στην μουσική εφ όσον το επιθυμήσουν. Η μηνιαία συνδρομή είναι 20 ευρώ και όσοι ενδιαφέρονται για τα παιδιά τους μπορούν να έρθουν σε επικοινωνία με τον πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό στο τηλ. 69746.40419 για περισσότερες πληροφορίες. Δάσκαλος είναι ο κ. Μολυβιάτης Γρηγόρης ο οποίος επί πολλά χρόνια έχει προσφέρει εξαιρετικό έργο.

 <br/>


	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />








<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Νέα τμήματα.</Typography>    
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>
       
Ο Σύλλογός μας μπορεί να ιδρύσει νέα τμήματα παραδοσιακών μουσικών οργάνων όπως π.χ. βιολί, λαούτο κτλ εφ όσον εκδηλωθεί σχετικό ενδιαφέρον από τα μέλη μας. Διευκρινίζουμε ότι για να λειτουργήσει ένα νέο τμήμα θα πρέπει να ενδιαφερθούν κατ ελάχιστο τρία άτομα.
 




	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />

    







<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Τμήματα παραδοσιακών τραγουδιών και χορών.</Typography>    
	
	

	<Typography variant="h3" sx={theme.typography.typography2}>
       
Αυτά τα τμήματα λειτουργούν περιστασιακά και πλαισιώνουν διάφορες εκδηλώσεις του Συλλόγου μας που έχουν σχέση με τις αλησμόνητες πατρίδες της Μ. Ασίας. Τα μαθήματα είναι δωρεάν και όσοι ενδιαφέρονται μικροί και μεγάλοι μπορούν να έλθουν σε συνεννόηση με την κ. Ευθαλία Τουρλή στο τηλ. 22510 91277.
 




	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />







<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Τμήμα βιβλιοθήκης.</Typography>    
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>
       
Με 7.500 περίπου τίτλους βιβλίων είναι εμπλουτισμένη η βιβλιοθήκη του Συλλόγου μας τα οποία είναι διαθέσιμα  για να δανειστούν στα μέλη μας, αλλά και σε όσους άλλους ενδιαφέρονται. Όλα τα βιβλία είναι καταχωρημένα σε ηλεκτρονικό υπολογιστή για την εύκολη διαχείριση τους.<br/>
Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα προβλήματα που αποτελούν  τη ζωή του σύγχρονου ανθρώπου και τοποθετεί τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και φανατισμούς.





	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />






<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Δωρεά βιβλίων στο Σύλλογό μας.</Typography>    
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>
       
   Ευχαριστούμε θερμά  τους  κ.κ. Αλεξέλλη Παναγιώτη, Κρητικό Γεώργιο, Βαλαχή Σταυρίτσα, Σάλτα Μαρία, Χατζηδήμο Αλέξανδρο, Σταματέλλη Αγγελική, Κεφαλίδη Δημήτρη, Πελεκάνο Θεόδωρο, Παπαχρυσό Γεώργιο και το ΚΕ.ΜΙ.ΠΟ. για τα βιβλία που πρόσφεραν στην βιβλιοθήκη του Συλλόγου μας.
 






	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />





<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Ευχαριστήρια.</Typography>    
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>
       
  Ευχαριστούμε θερμά τους παρακάτω.<br/>
*Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες συντήρησης των κτιρίων του Συλλόγου, τον Άρη Κερεντζή ο οποίος φροντίζει τον κήπο του Μουσείου, καθώς και όσους προσφέρουν εθελοντικώς την εργασία τους.<br/>
*Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο μας.

 





	</Typography>

    
	</Box>
	
	
	<ScrollToTopButton />







<Box sx={{ ...commonBoxStyles }}> 
	<Typography variant="h1" sx={theme.typography.typography1}>Οικονομικές ενισχύσεις.</Typography>    
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>
       
  Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.<br/><br/>

Μηναίδου Μαρία                        100 ευρώ.<br/>
Κατανάκης Μιχάλης                   30 ευρώ.<br/>
Παπαχρυσός Ιωάννης                  50 ευρώ.<br/>
Κεφαλίδης Δημήτρης                  20 ευρώ.<br/>
Χατζηδήμος Αλέξανδρος            50 ευρώ.<br/>
Michele Ban                                 20 ευρώ.<br/>
Σύλλογος Περίπολος Αττικής      50 ευρώ.<br/>
Γονιδέλλης Κώστας Ν.                10 ευρώ.<br/>
Κλειδαρά Μαρία                          20 ευρώ.<br/>
Πλέλλης Κώστας                         20 ευρώ.<br/>
Λύρας Νικόλαος                          20 ευρώ.<br/>
Ανώνυμοι                                     95 ευρώ.<br/>
Μολυβιάτης Γρηγόρης  (20+20)  40 ευρώ,  στη μνήμη της μητέρας του Ελπίδας.<br/>
Μολυβιάτης Γρηγόρης                 30 ευρώ,  στη μνήμη του παππού του Πάνου.<br/>
Δεσπότου Έλλη                           150 ευρώ, στη μνήμη της μητέρας της Ευαγγελίας.<br/>
Λευκαδίτης Τάκης                        25 ευρώ,  στη μνήμη της μητέρας του Μερακλίνας.<br/>
Μανδαμά Ελπίδα                          20 ευρώ, στη μνήμη της αδελφής της Φρόσως.<br/>
Μπουγά Παπαστεφάνου Βούλα   32 ευρώ, στη μνήμη των γονέων της.<br/>
Πασπατή Αργυρώ                         …ευρώ, στη μνήμη του συζύγου της Αργύρη και του υιού της Μιχάλη.<br/>
Καλαθά-Αϊβαλιώτου Χρυσούλα 100 ευρώ, στη μνήμη των γονέων της Αντωνίου και Αντωνίας.<br/><br/>

Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός  για αναβάθμιση του οικισμού μας , έτσι ώστε να μπορέσουμε να ανταποκριθούμε στο βαρύ φορτίο των υποχρεώσεων που έχουμε αναλάβει.
 

Για καταθέσεις συνδρομών και ενισχύσεων στο Σύλλογο Τράπεζα Πειραιώς Αριθμός Λογαριασμού 6355-010482-750  IBAN  GR94 0171 3550 0063 5501 0482 750     

	</Typography>

    
	</Box>
	
    <ScrollToTopButton />



    </>
  )
}

export default D15_drastiriotites_2018