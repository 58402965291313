import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g8/1922_24_01.jpg";
import Image02 from "../assets/images/mikrasiatika_g8/1922_24_02.jpg";
import Image03 from "../assets/images/mikrasiatika_g8/1922_24_03.jpg";
import Image04 from "../assets/images/mikrasiatika_g8/1922_24_04.jpg";
import Image05 from "../assets/images/mikrasiatika_g8/1922_24_05.jpg";
import Image06 from "../assets/images/mikrasiatika_g8/1922_24_06.jpg";
// import Image07 from "../assets/images/mikrasiatika_g8/1922_24_07.jpg";
import Image08 from "../assets/images/mikrasiatika_g8/1922_24_08.jpg";
import Image09 from "../assets/images/mikrasiatika_g8/1922_24_09.jpg";
import Image10 from "../assets/images/mikrasiatika_g8/1922_24_10.jpg";
import Image11 from "../assets/images/mikrasiatika_g8/1922_24_11.jpg";
import Image12 from "../assets/images/mikrasiatika_g8/1922_24_12.jpg";
import Image13 from "../assets/images/mikrasiatika_g8/1922_24_13.jpg";
import Image14 from "../assets/images/mikrasiatika_g8/image030.jpg";

function G8_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="1922 - 1924 Η μεγαλύτερη μετακίνηση πληθυσμών στην ιστορία" />
      </Box>


      
      <Box sx={{ ...commonBoxStyles }}>
	
	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Με το διωγμό του 1922, άρχισε αυτό που δικαίως μπορεί να θεωρηθεί το πιο μακρύ,το πιο θεματικό οδοιπορικό στην ιστορία. Πρόκειται για την αναγκαστική μετακίνηση 
	δύο εκατομμυρίων χριστιανών και μωαμεθανών και στις δύο ακτές του Αιγαίου.<br/>
	Η ανάκαμψη της Τουρκίας μετά την ήττα στον Α΄ Παγκόσμιο Πόλεμο και η επακόλουθη νίκη της επί των Ελλήνων στη Μικρά Ασία ήταν οι αιτίες αυτής της μεγάλης 
	μετακίνησης, που τελικά πήρε τη μορφή ενός ΄΄διακανονισμού ανταλλαγής΄΄ φυλετικών μειονοτήτων –σύμφωνα με ειδικούς όρους –που τελούσε υπό την υψηλή επίβλεψη της 
	Κοινωνίας των Εθνών. Ωστόσο, τα πρώτα επεισόδια του δράματος της ανταλλαγής έμελλε να παιχτούν με την υπόκρουση των κανονιών, το κροτάλισμα των πολυβόλων και με 
	φόντο τις φλόγες από το ολοκαύτωμα της Σμύρνης.</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Τα πρώτα θύματα</Typography>	
	<Typography variant="h3" sx={theme.typography.typography2}>Οι Έλληνες του εσωτερικού της Μικράς Ασίας ήταν τα πρώτα θύματα της ανταλλαγής. Μακριές ουρές από ανθρώπινα ράκη σε ένα ταξίδι οκτακοσίων περίπου 
	χιλιομέτρων από τα μετόπισθεν των Τούρκων εθνικιστών ως τα παράλια. Παρόλο που οι περισσότεροι από αυτούς ζούσαν σε περιοχές οι οποίες  απείχαν πολλές 
	εβδομάδες δρόμο από τη ζώνη των πολεμικών επιχειρήσεων, είναι ευνόητο ότι οι Τούρκοι εθνικιστές δεν χαίρονταν καθόλου νιώθοντας στα νώτα τους συγγενείς 
	των Ελλήνων εχθρών τους.  Έτσι, λοιπόν, το διάστημα 1920 – 1921 το κύμα των προσφύγων προχωρούσε αδιάκοπα ανάμεσα στα χιονισμένα βουνά και τις κατακαμένες 
	έρημες πεδιάδες της Μικράς Ασίας, αφήνοντας πίσω τους αναρίθμητους νεκρούς. <br/>
	Για περισσότερο από δύο χιλιάδες χρόνια στα βάθη της Μικράς Ασίας ανατράφηκαν πολλοί απόγονοι των ανήσυχων εκείνων πνευμάτων που είχαν ακολουθήσει το Μέγα 
	Αλέξανδρο στις εκστρατείες του στην Ασία. Χίλια χρόνια νωρίτερα οι ακτές της είχαν εξελληνισθεί από τις ελληνικές φυλές του πρώτου ελληνικού αποικισμού 
	-1.100-800 π.Χ.- δημιουργώντας το θαύμα της Ιωνίας. <br/>
	Διωγμένοι τώρα από τοις προαιώνιες κοιτίδες τους, συνωθούνταν μισόγυμνοι στις αγορές των παραλίων, σαν ναυαγοί που καλούσαν απεγνωσμένα οποιοδήποτε περαστικό 
	πλεούμενο για να τους σώσει από τα διογκούμενα κύματα της προσφυγιάς. <br/>
	Έτσι, οι πρώτοι 100.000 πρόσφυγες του μεγαλύτερου μεταναστευτικού κύματος της ανθρώπινης ιστορίας, πέρασαν σιγά σιγά στην Ελλάδα.</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Κοσμοσυρροή προσφύγων επί ένα εξάμηνο</Typography>
	<img src={Image03}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Με την τελική επίθεση των Τούρκων στις 26 Αυγούστου 1922  (13 Αυγούστου με το παλιό ημερολόγιο) οι γραμμές του ελληνικού στρατού κατέρρευσαν σε ένα μέτωπο 240 
	χιλιομέτρων.<br/>
	Επακολούθησε κατακλυσμός προσφύγων επί έξι μήνες, λες και είχε μεταδοθεί ο πανικός τηλεπαθητικά, κατά μεγάλα κύματα που σάρωναν την Ανατολία, τη Σμύρνη, τη Θράκη 
	και την Κωνσταντινούπολη. Οι Έλληνες που ζούσαν στις αχανείς αυτές περιοχές αφυπνίστηκαν ξαφνικά, ανακαλύπτοντας  ότι βρίσκονταν στο χείλος μιας επικίνδυνης 
	δίνης. <br/>
	Πρόσφυγες που ξεκινούσαν από μέρη που βρίσκονται  μέχρι και 240  χιλιόμετρα στο εσωτερικό,  κατευθύνονταν κοπαδιαστά προς τα παράλια και τη Σμύρνη. <br/>
	Στην αρχή έφταναν με τα τακτικά δρομολόγια των τρένων ή με τις άμαξες. Κουβαλούσαν μπόγους με κουβέρτες και χράμια, λίγα σκεύη που είχαν πάρει από τα σπίτια 
	τους, ίσως και μερικά ζώα. Καθώς η μακρινή πραγματικότητα του πολέμου πλησίαζε, η φυγή του κόσμου ακολουθούσε ξέφρενο ρυθμό: δέκα, μετά είκοσι, κατόπιν τριάντα 
	χιλιάδες πρόσφυγες την ημέρα. Οι λίγες αποσκευές που όλο και λιγόστευαν, προμήνυαν το τρελό φευγιό που θα ακολουθούσε  μπροστά στη φωτιά και στη σφαγή. 
	Το χάραμα της 7ης Σεπτεμβρίου (νέο ημερ.) βρήκε ολότελα  απογυμνωμένα πλήθη ανθρώπων που παρέπαιαν, γυναίκες που θρηνούσαν την πρώτη τους οικογενειακή τραγωδία,
	 μανάδες χωρίς φαΐ για τα μωρά τους, οι οποίες είχαν αναγκαστεί να εγκαταλείψουν τα μεγαλύτερα παιδιά τους σε χωριά στο δρόμο.</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Η πύρινη λαίλαπα στην Σμύρνη</Typography>
	
	<img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η μεγάλη προκυμαία της Σμύρνης είχε γεμίσει πλέον ασφυκτικά από 150.000 περίπου εξόριστους, που κατασκήνωναν και κοιμούνταν εκεί απλώνοντας χράμια και κουβέρτες 
	για να προφυλαχτούν από το λιοπύρι, που η κάψα του και μόνο προμήνυε την επέλαση της φωτιάς.<br/>
	Λίγες μέρες μετά τη θριαμβευτική είσοδο των Τούρκων στη Σμύρνη, το πλήθος που είχε καταλάβει την προκυμαία είδε φλόγες να ξεπηδούν από την αρμενική συνοικία με 
	τα ξύλινα σπίτια. Η φωτιά που θέριευε με τον άνεμο, περνούσε από μπαλκόνι σε μπαλκόνι κι εξαπλωνόταν στους δρόμους και τα σοκάκια. Πολύ γρήγορα η ξέφρενη επέλασή 
	της κάλυψε τη διάμετρο των δύο χιλιομέτρων της πόλης, συνεχίζοντας προς την προκυμαία, και η μεγάλη μάζα των προσφύγων στριμώχνονταν ανάμεσα  στο τεράστιο πύρινο 
	μέτωπο και την απεραντοσύνη της θάλασσας. <br/>
	Τώρα  νέα πλήθη έρχονταν να προστεθούν  σωρηδόν  στα προηγούμενα, φυγάδες  κάτοικοι  της Σμύρνης, φορτωμένοι με μωρά και στρώματα που πρόλαβαν να μαζέψουν 
	την τελευταία στιγμή. Η πόλη είχε γίνει ένα γιγάντιο πυρωμένο καμίνι. Ο άνεμος παρέσυρε τις φλόγες στην προκυμαία και η κάψα ήταν τόσο τρομακτική, που οι 
	άνθρωποι μούσκευαν τις κουβέρτες τους στη θάλασσα για να τυλιχτούν μ’ αυτές και να  προφυλαχτούν. Άνθρωποι που βρίσκονταν στις μπροστινές γραμμές σπρώχνονταν 
	βίαια από εκείνους που τους άγγιζαν οι φλόγες στις πίσω ή παρασύρονταν προς την άκρη του μόλου, πέφτοντας τελικά στη θάλασσα. <br/>
	Μετά από τρεις μέρες, τα δύο τρίτα της Σμύρνης είχαν μεταμορφωθεί σε ένα σωρό από κάρβουνα που ακόμα σιγόκαιγαν. 
	Ψηλά στις πλαγιές του όρους Πάγου υψώνονταν άθικτοι οι μιναρέδες του τούρκικου μαχαλά, σαν σύμβολο νίκης. 
	Για μια ακόμα εβδομάδα γύρω στις 200.000 πρόσφυγες συνέχιζαν να ζουν, να πεθαίνουν και να γεννούν μωρά πάνω σ’ εκείνη την αξέχαστη προκυμαία.
	Ο βυθός της θάλασσας ήταν γεμάτος πνιγμένους. Συμμορίες ιερόσυλων ψάρευαν με αγκίστρια δεμένα σε τηλεγραφικό σύρμα τα πτώματα από το νερό, αναζητώντας 
	δαχτυλίδια και αδειάζοντας τις τσέπες τους. 
	Την παραμονή της εκκένωσης πάνω από τα κεφάλια των προσφύγων της Σμύρνης, ένα αεροπλάνο πετούσε προκηρύξεις κάνοντας κύκλους στον αέρα. Φέιγ βολάν ενημέρωναν 
	συνεχώς το άστεγο και ακυβέρνητο πλήθος ότι όσοι δεν κατάφερναν να φύγουν μέσα σε μία βδομάδα θα εκτοπίζονταν στο εσωτερικό της Μικράς Ασίας. Αυτό είχε ως 
	αποτέλεσμα το επόμενο πρωί 350.000 άνθρωποι, μέσα στον πανικό της φυγής, να περάσουν απ’ την μπάρα της προκυμαίας και ύστερα από ένα σωρό άλλες παρόμοιες μπάρες 
	μέχρι να φτάσουν στην αποβάθρα του σιδηροδρόμου. Στο λιμάνι ήταν αγκυροβολημένα τα αναμενόμενα από καιρό πλοία- ελληνικά με αμερικανική σημαία.
	Την πρώτη μέρα εκατοντάδες πρόσφυγες συνθλίβονταν μέχρι θανάτου στην προκυμαία ή σπρώχνονταν μέχρι πνιγμού στην αποβάθρα, ώσπου οκτώ πλοία, υπό τη συνοδεία των
	αμερικανικών αντιτορπιλικών, αναχώρησαν παίρνοντας μαζί τους 43.000 ψυχές. Για όσους έμεναν πίσω υπήρχαν μόνο έξι ευκαιρίες- μία την ημέρα- και ύστερα η απόγνωση 
	της εκτόπισης στο εσωτερικό της Μικράς Ασίας. 
	Με την έρευνα των Τούρκων από σπίτι σε σπίτι άλλοι 100.000 πρόσφυγες, που ήταν κρυμμένοι σε υπόγεια, ήρθαν να προστεθούν στο πλήθος στης προκυμαίας, που ολοένα 
	λιγόστευε. Το γεγονός αυτό καθιστούσε απαραίτητη την παράταση της προθεσμίας των έξι ημερών.</Typography>
	
	
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Η φυγή χιλιάδων κατοίκων της Ανατολικής Θράκης</Typography>
	
	<img src={Image04}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Μέχρι τις 8 Οκτωβρίου η εκκένωση της Σμύρνης είχε ολοκληρωθεί. Χωρίς να υπολογίσουμε ένα μεγάλο αριθμό εκτοπισθέντων εφήβων και αντρών, μέσα σ’ ένα δεκαπενθήμερο 
	300.000 άνθρωποι είχαν φύγει από το λιμάνι της. Οι ανθρώπινες απώλειες στην καταστροφή της Σμύρνης, σύμφωνα με τις πρώτες εκτιμήσεις, έφτασαν τους 10.000 νεκρούς, 
	ενώ οι υλικές ζημιές τα 300.000.000 δολάρια Η.Π.Α. Με αυτό τον τρόπο το δεύτερο αυτό κύμα της μεγάλης προσφυγιάς πέρασε στην Ελλάδα. <br/>
	Στις 23 Σεπτεμβρίου, με διπλωματική νότα των Συμμάχων η Ανατολική Θράκη, που είχε προσαρτηθεί στην Ελλάδα με την Συνθήκη των Σεβρών, δόθηκε πάλι πίσω στους Τούρκους. 
	Τα ανακοινωθέντα έδιναν διορία ενός μήνα για την υποχώρηση των ελληνικών στρατευμάτων και την είσοδο της τουρκικής χωροφυλακής στην περιοχή, προκειμένου να αποφευχθεί 
	ο πανικός. Ο πανικός, όμως, ήταν ήδη στην ημερήσια διάταξη, όπως και στην πόλη της Σμύρνης. Μία βδομάδα μετά την εκκένωση της προκυμαίας οι Έλληνες της Θράκης είδαν 
	τα ελληνικά στρατεύματα να διαλύουν τα στρατόπεδά τους και να κατευθύνονται προς δυσμάς.
	"Θα γυρίσετε πίσω;" ρωτούσαν ανήσυχοι οι αγρότες.
	"Όχι" ήταν η απάντηση. </Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Μέσα σε μια ώρα ολόκληρα χωριά είχαν ερημώσει. Οικιακά είδη και σάκοι με δημητριακά ρίχνονταν στις καρότσες, τα βόδια ζεύονταν και σε λίγο η μικρή κοινότητα 
	ξεκινούσε το ταξίδι της προς τα δυτικά.<br/>
	Στη θέα του στρατού που αποχωρούσε περνώντας από χωριό σε χωριό, οι γυναίκες που άλεθαν στους χερόμυλους και οι άντρες που αλώνιζαν έστεκαν έκπληκτοι. 
	Η στιγμιαία αντίδραση ήταν η ίδια: όλοι παρατούσαν τα εργαλεία τους χάμω, στριμώχνονταν όπως μπορούσαν στις βιαστικά φορτωμένες καρότσες και ξεκινούσαν 
	προς τα δυτικά. <br/>
	Πολλοί τοπικοί παράγοντες έτρεχαν ξοπίσω τους φωνάζοντας: "Γυρίστε πίσω! Μαζέψτε τη σοδειά σας! Έχετε ακόμα ένα μήνα καιρό".
	Η απάντηση ήταν πάντα η ίδια, μονότονη και μοιραία: "Όχι. Όλα χάθηκαν. Πρέπει να φύγουμε στην Παλιά Ελλάδα".
	Σε όλη την πεδιάδα το μήνυμα εξαπλωνόταν ταχύτατα. Το ταξίδι ξεκινούσε. Ατελείωτα καραβάνια άρχισαν να σχηματίζονται παντού. Η ομαδική αυτή αποδημία των 
	χριστιανών προς την Ελλάδα προχωρούσε αργόσυρτα, πληθαίνοντας ολοένα και πιο πολύ. <br/>
	Στη Ραιδεστό 28.000 πρόσφυγες κατέβαιναν τα μονοπάτια των γκρεμών και μέσα απ’ τους απότομους βράχους προσπαθούσαν να φτάσουν στην ανεμοδαρμένη παραλία. 
	Εκεί για μέρες θα περίμεναν να επιβιβαστούν στα πλοία διάσωσης, μέχρι να εξαντληθούν τελείως από την πείνα, ενώ η σοδειά τους μούχλιαζε εκεί όπου την είχαν 
	αφήσει, στην έρημη ενδοχώρα.<br/>
	Από την Ανδριανούπολη τις έξι πρώτες μέρες πέρασαν ακόμα 60.000 άνθρωποι. Τόσο γρήγορα ο πανικός έδινε τη θέση του στη φυγή, ώστε όσοι είχαν ξεκινήσει από 
	τα περίχωρα της Κωνσταντινούπολης έβλεπαν μόνο εγκαταλειμμένα χωριά και άλλα καραβάνια προσφύγων.</Typography>
	
			
	<Typography variant="h1" sx={theme.typography.typography1}>Η φυγή των Ελλήνων από την Κωνσταντινούπολη</Typography>
	
	<img src={Image05}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Άλλη μια ομάδα 25.000 προσφύγων είχε συγκεντρωθεί στην είσοδο της Κωνσταντινούπολης, κατά μήκος των στενών των Δαρδανελίων, ενόσω ο στρατός των Τούρκων 
	εθνικιστών προχωρούσε κυκλικά, βόρεια από την κατακτημένη Σμύρνη. <br/>
	Τελικά ο σουλτάνος Μωάμεθ ο ΣΤ΄ εγκατέλειψε την Κωνσταντινούπολη και οι εθνικιστικές, τούρκικες δυνάμεις μπήκαν στην πόλη. Διέσχισαν τη γέφυρα του Γαλατά, βαδίζοντας 
	πάνω στα στρωμένα με λουλούδια καλντερίμια, ανέβηκαν στο λόφο του Γαλατά αφού πέρασαν μια θάλασσα από κόκκινα φέσια. Σειρήνες στρίγκλιζαν στην Ευρώπη και στην Ασία, 
	μεταφέροντας παντού την είδηση ότι οι Τούρκοι γύρισαν πίσω στην Κωνσταντινούπολη.<br/>
	Αμέσως μετά τα γεγονότα αυτά, τοιχοκολλήθηκε διάταγμα σε όλες τις περιοχές της Μικράς Ασίας. Ανακοίνωνε επίσημα ότι παρεχόταν η ΄΄άδεια΄΄ σ’ όλους τους αλλόθρησκους να 
	εγκαταλείψουν την χώρα μέχρι και την 30η Νοεμβρίου. Πολύ σύντομα όλοι οι εναπομείναντες χριστιανοί στο εσωτερικό της Μικράς Ασίας έφυγαν μαζικά προς τις ακτές της Μαύρης 
	Θάλασσας. Σχεδόν ταυτόχρονα 40.000 γυναίκες, παιδιά και γέροι -οι άντρες κρατήθηκαν ως αιχμάλωτοι πολέμου- κατέκλυσαν το λιμάνι της Σαμψούντας. Μέσα σε τρεις βδομάδες 
	άλλοι 250.000 πρόσφυγες πορεύονταν στους χιονισμένους δρόμους που οδηγούσαν στην Τραπεζούντα, στη Σινώπη και στην Ινέπολη.<br/>
	Η ΄΄άδεια΄΄ ερμηνεύτηκε ως ΄΄απέλαση΄΄. Από διάφορα σημεία της ενδοχώρας, μέχρι και σε 800 χιλιόμετρα απόσταση από τα παράλια, χωρικοί και κάτοικοι των πόλεων μαζεύονταν 
	και ξεκινούσαν να διασχίσουν την παγωμένη πεδιάδα.<br/>
	Από τη μια ερημωμένες από πλοία προκυμαίες, όπως στο λιμάνι της Σμύρνης, από τη άλλη λιμάνια της Μαύρης Θάλασσας πλημμυρισμένα από ανθρώπους που κοιμόνταν στα πεζοδρόμια, 
	εξαθλιωμένοι, καταρρακωμένοι και τσακισμένοι από τις αρρώστιες. <br/>
	Τον Ιανουάριο του 1923 ογδόντα χιλιάδες πρόσφυγες, το ένα δέκατο δηλαδή του τέταρτου αλλά πιο ορμητικού κύματος της μεγάλης αυτής προσφυγιάς, έφτασαν στην Ελλάδα. Η χώρα 
	υπέφερε από την ατελείωτη εισροή προσφύγων, που στο τέλος έγινε τόσο δυσβάσταχτη, ώστε η Αθήνα αναγκάστηκε να κλείσει επισήμως τις πύλες της, διαμαρτυρόμενη για τις 
	συνεχιζόμενες ΄΄απελάσεις΄΄.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Εναλλακτική λύση ήταν, όπως φάνηκε, οι αχρηστεμένοι στρατώνες και οι στάβλοι γύρω από την Κωνσταντινούπολη: Μια μεγάλη ΄΄χωματερή΄΄ για να στοιβαχτεί το πλήθος 
	των 100.000 Ελλήνων της Ανατολίας, όπου η θνησιμότητα ξεπερνούσε τις 300 ψυχές τη μέρα.  <br/>
	Τι συνέβαινε στο μεταξύ πίσω από τα επισήμως κλειστά σύνορα της Ελλάδας; Επρόκειτο για μια μικρή χώρα, με όχι σπουδαίο φυσικό πλούτο, που είχε μόλις βγει από μια 
	δεκαετία επιστρατεύσεων και πολέμων, με αποκορύφωμα την πρόσφατη ήττα, μόνο και μόνο για να ξαναβυθιστεί σ’ ένα ακόμα τεράστιο πρόβλημα: το προσφυγικό. Χωρίς να 
	αναφερθούμε στα τελικά αποτελέσματα της ανταλλαγής, η Ελλάδα υποδέχτηκε 1.250.000 πρόσφυγες σε διάστημα ενός μόνο έτους από την καταστροφή της Σμύρνης. Αυτή η κατά
	25% αύξηση του πληθυσμού της σήμαινε ότι σε κάθε τέσσερις κατοίκους αναλογούσε τώρα ένας άπορος, άστεγος και δυστυχισμένος, συνήθως, πρόσφυγας. <br/>
	Τώρα πια η Αθήνα και η Θεσσαλονίκη περιβάλλονταν από κάμπους γεμάτους αντίσκηνα. Θύμιζαν κλασικές γραβούρες πολιορκημένων πόλεων. Πολιορκητής, όμως, 
	εδώ ήταν η πείνα. <br/>
	 Καθημερινά μέσα απ’ αυτές τις φτωχογειτονιές κάτω από την Ακρόπολη με τις σκηνές από καραβόπανο ξεπρόβαλλαν γυναίκες για να πουλήσουν τα μικρής αξίας κοσμήματα 
	 τους, τα χράμια και τις ραπτομηχανές τους, μέχρις ότου όλα όσα είχαν περισώσει απ’ τη Μικρά Ασία να γίνουν μερικές χούφτες χονδρόκοκκο στάρι. Ώσπου να γραφτούν 
	 κι αυτές στους  καταλόγους των 800.000 ανέργων της χώρας.  <br/>
	</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Η συνθήκη της Λωζάνης εξορίζει 2.000.000 ανθρώπους</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Στις <b>30 Ιανουαρίου του 1923</b> υπογράφηκε η <b>συνθήκη που αφορούσε την ανταλλαγή ελληνικών και τουρκικών πληθυσμών</b>. Χάρη στην ελληνική επιμονή, η Τουρκία 
	αναγκάστηκε τελικά να δεχτεί πίσω 450.000 μουσουλμάνους που κατοικούσαν στην ελληνική επικράτεια, προκειμένου να ελευθερωθεί χώρος στην Ελλάδα για τη φιλοξενία 
	1.000.000 και πλέον προσφύγων.Η Ελλάδα δέχτηκε την ανταλλαγή ως οικονομική αναγκαιότητα, η Τουρκία επίσης, αν και κάτω από πολύ διαφορετικό πρίσμα. </Typography>	
	<Typography variant="h3" sx={theme.typography.typography2}><b>Η Συνθήκη της Λοζάνης</b> αποτελεί ένα  διεθνές ορόσημο. Προηγήθηκαν άλλες, που καθόριζαν ανταλλαγές πληθυσμών μεταξύ Τούρκων και Βουλγάρων, το 1913 και μεταξύ 
	Βούλγαρων και Ελλήνων  το 1919. Διαφέρει, ωστόσο, από εκείνες ως προς το ότι αυτή ήταν <b>αναγκαστική</b> και υλοποιήθηκε υπό την αιγίδα της Κοινωνίας των Εθνών. <br/>
	Δεν είναι υπερβολή να πούμε ότι η ιστορία δεν έχει παρουσιάσει προηγούμενο τόσο ασυνήθιστης συνθήκης. Ποτέ άλλοτε μέσα στην μακραίωνη ιστορία των μαζικών 
	μετακινήσεων πληθυσμών δεν είδαμε 2.000.000 ανθρώπους να ξεριζώνονται και να  εγκαθίστανται  αλλού με  μια μονοκοντυλιά. Ακόμα και αν θεωρούνταν εθελούσια 
	μετοικεσία και όχι αναγκαστική ανταλλαγή, πάλι η πληθυσμιακή αυτή μετακίνηση δεν θα είχε προηγούμενο μέσα στη 
	μεταναστευτική ιστορία. Θα προσθέταμε μάλιστα πως ποτέ στην ιστορία δεν υπήρξε συνθήκη τόσο δύσκολη στην εκτέλεση της. Για να ελαττωθούν κάπως αυτές οι 
	δυσκολίες, οι όροι της ανταλλαγής βασίστηκαν σε θρησκευτικά και όχι σε φυλετικά κριτήρια. 
	Ο μηχανισμός που δημιουργήθηκε για να ασχοληθεί μ’ αυτούς τους <b>2.000.000 ανθρώπους</b> είχε δύο σκέλη: το ένα  ήταν η Μεικτή   Επιτροπή με  έδρα  την  
	Κωνσταντινούπολη και έχοντας να διαχειριστούν τις υποθέσεις 450.000 μωαμεθανών και 150.000 Ελλήνων που βρίσκονταν ακόμα στην Ανατολία.
	Το άλλος σκέλος, η Επιτροπή Αποκαταστάσεως Προσφύγων, με εκπροσώπους στη Θεσσαλονίκη και στην Αθήνα, είχε αναλάβει να βοηθήσει στην αποκατάσταση όλων των 
	Ελλήνων προσφύγων, συμπεριλαμβανομένων των 150.000 που ακόμα αναμένονταν.</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Η μεγαλύτερη πληθυσμιακή μετακίνηση της ιστορίας κόστισε 300.000 ζωές</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ενώ τα τελευταία ίχνη της κλασικής Ιωνίας εξαφανίστηκαν από την Ασία, η μητέρα πατρίδα, υποδεχόμενη πίσω τους απογόνους των πρώτων αποικιστών, μετά από 3.000 
	χρόνια, απέκτησε ένα εύρωστο παραγωγικό δυναμικό που αναζωογόνησε τη γεωργία και, επιπλέον, δέχτηκε μια σπουδαία εισροή βιομηχανικών εργατών, που έφεραν μαζί τους 
	τις τέχνες της μεταξουργίας, της ταπητουργίας και της κεραμικής. <br/>
	Όσο για το ανθρώπινο κόστος που είχε αυτό το μεγάλο μεταναστευτικό κύμα, με συντηρητικούς υπολογισμούς φτάνει τις 300.000 ζωές, που χάθηκαν από επιδημίες και 
	κακουχίες, ενώ οι υλικές απώλειες ξεπέρασαν τα 100.000.000 δολάρια Η.Π.Α.<br/>
	Το χειμώνα του 1924 τα παλιρροϊκά κύματα των προσφύγων, που κράτησαν δύο χρόνια, κόπασαν. Τώρα μπορούσες να περπατήσεις στις ακτές της Εγγύς Ανατολής σαν
	ύστερα από μεγάλη καταιγίδα και να παρατηρήσεις τις αλλαγές που είχαν επέλθει. </Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Υπήρχε  και η  Κωνσταντινούπολη  θλιβερά απογυμνωμένη και σε μαρασμό. Το λιμάνι της είχε σχεδόν ερημώσει. Η ελληνική συνοικία συρρικνώνονταν με γοργό ρυθμό. Οι 
	ξένες πρεσβείες ήταν έτοιμες να μετακομίσουν στην Άγκυρα και το "σουλτανάτο" και  "χαλιφάτο" της πόλης είχαν καταργηθεί με την ανακήρυξη της Δημοκρατίας.<br/>
	Τέλος, υπήρχε και η Αθήνα, σχεδόν ρακένδυτη εξαιτίας της μεγάλης οικονομικής της δυσπραγίας, αποψιλωμένη σχεδόν σαν ύστερα από επιδρομή ακρίδων. Τα προάστιά της 
	γεμάτα από τις 60.000 πρόσφυγες, στεγασμένους σε συνοικισμούς. Ο Παρθενώνας επόπτευε από ψηλά  όλα τα επακόλουθα του δράματος του 1922-1924.<br/>
	Δράμα τόσο παράξενο και τόσο συγκινητικό, που μόνο οι αρχαίοι ποιητές της Ελλάδας θα μπορούσαν να το ζωντανέψουν στη σκηνή: ο Σοφοκλής ίσως, εκφράζοντας έτσι 
	το αίνιγμα της ανθρώπινης δυστυχίας σε μια τραγωδία με τίτλο "Οι Ανταλλαγέντες ".</Typography>
	</Box>
	
	<ScrollToTopButton />
	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Η προσφυγική Ελλάδα μέσα από αριθμούς</Typography>
	<img src={Image14}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>1832 - 1947 Η Ελλάδα μέσα από τις συνθήκες</Typography>
	<img src={Image08}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image09}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image10}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image11}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image12} height="310"   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image13}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	
	</Box>
	
	
	<ScrollToTopButton />


    </>
  );
}

export default G8_mikrasiatika;
