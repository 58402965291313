import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/27_image_01.jpg";

function F13_oikologika_13() {

    const theme = useTheme();


  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="27o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>


      
      <Box sx={{ ...commonBoxStyles }}>
	
      <Typography variant="h1" sx={theme.typography.typography1}>17-19 Νοεμβρίου 2016, Ηγουμενίτσα</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>Θέμα: «Προβλήματα Κλειστών Θαλάσιων Κόλπων»</Typography>
		
	<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
    <Typography variant="h3" sx={theme.typography.typography2}>Με μεγάλη επιτυχία πραγματοποιήθηκε στην Ηγουμενίτσα 17-19 Νοεμβρίου 2017 το 27ο Συνέδριο του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ). Συνδιοργανωτές ήταν η ΚΕΔΕ, η Περιφέρεια Ηπείρου, η ΠΕΔ Ηπείρου και ο Δήμος Ηγουμενίτσας. Το θέμα του Συνεδρίου (Προβλήματα Κλειστών Θαλάσσιων Κόλπων) ήταν πολύ σημαντικό, μετά μάλιστα τα γνωστά προβλήματα στο Σαρωνικό.<br/>
Ειδικοί εισηγητές ανέλυσαν όλες τις παραμέτρους των προβλημάτων που σχετίζονται με τους κλειστούς κόλπους και γενικότερα το θαλάσσιο περιβάλλον. Κοινή ήταν η αντίληψη για το υψηλό επίπεδο των εισηγήσεων και το διάλογο που ακολουθούσε. Το Συνέδριο κατέληξε σε συγκεκριμένη Απόφαση η οποία θα διαβιβαστεί προς κάθε αρμόδιο για τη λήψη μέτρων προστασίας των κλειστών κόλπων. Παραβρέθηκαν: ο Γ.Γ. του Υπουργείου Ναυτιλίας κ. Χρ. Λαμπρίδης, Βουλευτές, Δήμαρχοι και στελέχη της Τοπικής Αυτοδιοίκησης και Φορέων. Χαιρετισμό μέσω skype έκανε ο Πρόεδρος της ΚΕΔΕ  κ. Γ. Πατούλης.<br/>
Το δεύτερο μέρος του συνεδρίου αποτελούσε το Εσωτερικό Συνέδριο του ΠΑΝΔΟΙΚΟ, με την έγκριση απολογισμών και την εκλογή νέας διοίκησης.<br/>
Στη νέα Επταμελή Γραμματεία του ΠΑΝΔΟΙΚΟ εκλέχτηκαν οι εξής:<br/>
Νίκος Ασλάνογλου (Βέροια), Κώστας Βολιώτης (Βόλος) , Θανάσης Θεοδωρόπουλος (Αχαΐα),  Γιώργος Καλλιαμπέτσος (Αθήνα), Δημήτρης Μίχαλος (Ρόδος), Γιώργος Παλαμάρης (Κορινθία),  Μαίρη Χουλιέρη (Χαλκίδα) 
</Typography>
	<br/><br/>

	
    <Typography variant="h3" sx={theme.typography.typography2}>Απόφαση 27ου Πανελλήνιου Συνεδρίου ΠΑΝΔΟΙΚΟ</Typography>
   
    <Typography variant="h3" sx={theme.typography.typography2}>
Από τις εισηγήσεις που παρουσιάστηκαν, οι σύνεδροι διαπιστώνουν ότι:<br/>
*Οι κλειστοί κόλποι έχουν συγκεκριμένες ιδιαιτερότητες που πρέπει να ληφθούν επειγόντως υπόψη.<br/>
*Υπάρχει περιορισμένη ανανέωση νερών.<br/>
*Τα οικοσυστήματα τους είναι πολύ ευαίσθητα.<br/>
* Οι ανθρωπογενείς δραστηριότητες πχ βιομηχανία, α0ποχετεύσεις, τουρισμός, αλιεία, γεωργία κτλ. εντείνουν την ρύπανσή τους.<br/>
*Απαιτείται η σωστή σχεδίαση και εγκατάσταση των βιολογικών καθαρισμών και η αναβάθμιση, συντήρηση και έλεγχος λειτουργίας των υπαρχουσών. Στους κλειστούς κόλπους πρέπει όλοι οι οικισμοί ανεξάρτητα από το πληθυσμιακό τους μέγεθος να αποκτήσουν βιολογικούς καθαρισμούς.<br/>
*Η εποχικότητα, η κλιματική αλλαγή και οι αλληλεπιδράσεις του τουρισμού με άλλες δραστηριότητες, αποτελούν σημαντικά ζητήματα για τη βιώσιμη ανάπτυξη κάθε κλειστού κόλπου.<br/>
*Ο θαλάσσιος χωροταξικός σχεδιασμός τους, πρέπει να λάβει υπόψη του τον κίνδυνο από μεταφορές καυσίμων, βλέπε π.χ. πρόσφατο ατύχημα στο Σαρωνικό. Οι ιδιαιτερότητες της ρύπανσης που προκλήθηκε από το ναυάγιο του «ΑΓΙΑ ΖΩΝΗ ΙΙ», οι εργασίες απορρύπανσης των ακτών και η οικονομικές επιπτώσεις που υπήρξαν, πρέπει να προβληματίσει τους αρμόδιους και να αποτελέσει το σήμα κινδύνου για τη λήψη όλων των μέτρων που απαιτούνται για να μην επαναληφθεί τέτοια καταστροφή.<br/>
*Είναι απαραίτητη η αυστηρή εφαρμογή των υπαρχουσών εθνικών, ευρωπαϊκών και διεθνών κανονιστικών διατάξεων για τη βιωσιμότητα των κλειστών κόλπων.<br/>
*Η νομοθεσία (Ελληνική και Διεθνής) που αφορά τις θαλάσσιες ρυπάνσεις είναι επαρκής, αρκεί να εφαρμόζεται, ιδιαίτερα για την αποφυγή της ρύπανσης στους κλειστούς θαλάσσιους κόλπους.<br/>
*Απαιτείται η συμπλήρωση και αναθεώρηση του εθνικού θεσμικού πλαισίου για τις προστατευόμενες περιοχές NATURA 2000 και RAMSAR, το οποίο συνδέεται με την ελλειμματική προστασία των κλειστών θαλάσσιων κόλπων. Βλέπε π.χ. Παγασητικός κόλπος.<br/>
*Οι Φορείς Διαχείρισης των προστατευόμενων περιοχών είναι απαραίτητοι για την προστασία τους και πρέπει να κατοχυρωθούν και να αποκτήσουν ελεγκτικές και προανακριτικές αρμοδιότητες. <br/>
*Η διατάραξη της βιολογικής αλυσίδας στον Κορινθιακό κόλπο και κυρίως των ψαριών λόγω της υπεραλίευσης, έχει συντελέσει στην κατάληψη του θώκου τους από τις τσούχτρες. Εκφράζονται μάλιστα φόβοι ότι το ίδιο μπορεί να συμβεί και σε άλλους κλειστούς κόλπους.<br/>
*Οι ιχθυοκαλλιέργειες αποτελούν μια συμπληρωματική της αλιείας δραστηριότητα, με σημειακή ρύπανση, η οποία όμως πρέπει να ελέγχεται ως προς την εφαρμογή της περιβαλλοντικής νομοθεσίας με την επιβολή κυρώσεων σε περιπτώσεις παραβίασης των όρων παραχώρησης χρήσης, αλλά και των περιβαλλοντικών όρων που αδειοδοτήθηκαν. Να είναι δε αναρτημένοι οι όροι και οι άδειες λειτουργίας σε ειδικό διαδικτυακό τόπο.<br/>
*Είναι σημαντικό να υπάρξουν προγράμματα προστασίας των ιχθυοπληθυσμών στις Ελληνικές θάλασσες, με συνεχείς ελέγχους και αυστηρή εφαρμογή της νομοθεσίας.<br/>
*Να απαγορευτεί η αλιεία από γρι-γρι σε απόσταση μικρότερη από 1,5 μίλι από την ακτή, ενώ το σήμα που εκπέμπουν να επαναλαμβάνεται ανά 20 λεπτά.<br/>
*Τέλος με γνώμονα την σύγχρονη αντίληψη του πράσινου λιμένα είναι αναγκαίο να βελτιωθεί το υφιστάμενο νομικό πλαίσιο σχεδιασμού, κατασκευής, αναβάθμισης και λειτουργίας λιμένων και μαρινών.<br/>

</Typography>

</Box>

<ScrollToTopButton />



    </>
  );
}

export default F13_oikologika_13;
