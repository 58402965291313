import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image02 from "../assets/images/drastiriotites2021/image002.jpg";
import Image04 from "../assets/images/drastiriotites2021/image004.jpg";
import Image06 from "../assets/images/drastiriotites2021/image006.jpg";
import Image08 from "../assets/images/drastiriotites2021/image008.jpg";
import Image10 from "../assets/images/drastiriotites2021/image010.jpg";
import Image12 from "../assets/images/drastiriotites2021/image012.jpg";
import Image14 from "../assets/images/drastiriotites2021/image014.jpg";
import Image16 from "../assets/images/drastiriotites2021/image016.jpg";
import Image18 from "../assets/images/drastiriotites2021/image018.jpg";
import Image20 from "../assets/images/drastiriotites2021/image020.jpg";
import Image22 from "../assets/images/drastiriotites2021/image022.jpg";
import Image24 from "../assets/images/drastiriotites2021/image024.jpg";
import Image26 from "../assets/images/drastiriotites2021/image026.jpg";
import Image28 from "../assets/images/drastiriotites2021/image028.jpg";
import Image30 from "../assets/images/drastiriotites2021/image030.jpg";
import Image32 from "../assets/images/drastiriotites2021/image032.jpg";
import Image34 from "../assets/images/drastiriotites2021/image034.jpg";
import Image36 from "../assets/images/drastiriotites2021/image036.jpg";
import Image38 from "../assets/images/drastiriotites2021/image038.jpg";
import Image40 from "../assets/images/drastiriotites2021/image040.jpg";
import Image42 from "../assets/images/drastiriotites2021/image042.jpg";
import Image44 from "../assets/images/drastiriotites2021/image044.jpg";
import Image46 from "../assets/images/drastiriotites2021/image046.jpg";
import Image48 from "../assets/images/drastiriotites2021/image048.jpg";
import Image50 from "../assets/images/drastiriotites2021/image050.jpg";
import Image52 from "../assets/images/drastiriotites2021/image052.jpg";
import Image54 from "../assets/images/drastiriotites2021/image054.jpg";
import Image56 from "../assets/images/drastiriotites2021/image056.jpg";
import Image58 from "../assets/images/drastiriotites2021/image058.jpg";
import Image60 from "../assets/images/drastiriotites2021/image060.jpg";
import Image62 from "../assets/images/drastiriotites2021/image062.jpg";

function D18_drastiriotites_2021() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Οι δραστηριότητές μας το 2021" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          200 χρόνια μετά την επανάσταση
        </Typography>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Γιορτάζουμε, Συμμετέχουμε Οραματιζόμαστε
        </Typography>
        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Σύλλογος μας, συμμετέχοντας στον εορτασμό των 200 χρόνων (1821-2021)
          από την απελευθέρωση της πατρίδας μας από τον Οθωμανικό ζυγό αποφάσισε
          ότι:
          <br />
          Στις 21 εκάστου μηνός, για όλο το έτος 2021 και από ώρα 09:30 έως
          12:30 στο <b>«Μουσείο Προσφυγικής Μνήμης 1922»</b> θα υπάρχει ελεύθερη
          είσοδος και ξενάγηση για τους επισκέπτες. Υπενθυμίζουμε ότι, στο
          Μουσείο εκτίθενται πλέον των 500 κειμηλίων από την Μικρά Ασία και επί
          πλέον στο χώρο υπάρχει: <br />
          <br />
          1- Ένας επίτοιχος χάρτης των πόλεων του Μικρασιατικού Ελληνισμού που
          περιλαμβάνει, περίπου 2500 πόλεις και χωριά της Μ. Ασίας, στα οποία
          υπήρχε σημαντικός Ελληνικός πληθυσμός μέχρι το 1922.
          <br />
          2- Ένας δεύτερος επίτοιχος χάρτης των παραλίων της Μ. Ασίας, με τις
          πόλεις που δημιούργησαν οι Αιολείς, οι Ίωνες και οι Δωριείς πριν 3000
          χρόνια.
          <br />
          3- Μία μακέτα της πόλεως της Σμύρνης, όπως αυτή ήταν πριν την
          καταστροφή της, το 1922.
          <br />
          <br />
          Τις υπόλοιπες ημέρες και ώρες, το{" "}
          <b>«Μουσείο Προσφυγικής Μνήμης 1922»</b> εξακολουθούσε να είναι
          διαθέσιμο για τους επισκέπτες, μόνο κατόπιν τηλεφωνικού ραντεβού, στο
          τηλ. 69746.40419.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αρχαιρεσίες στο Σύλλογο μας
        </Typography>
        <img
          src={Image04}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Μετά τις αρχαιρεσίες που πραγματοποιήθηκαν την Κυριακή 25 Απριλίου
          2021 στον χώρο του Μουσείου της Σκάλας Λουτρών και στις οποίες ψήφισαν
          163 από τα 227 μέλη του Συλλόγου, το νέο Δ.Σ. που προέκυψε από τις
          εκλογές συνήλθε σε σώμα ως κάτωθι:
          <br />
          <br />
          <b>Πρόεδρος:</b> Παπαχρυσός Δημήτρης
          <br />
          <b>Αντιπρόεδρος:</b> Δαφιώτη Αθανασία
          <br />
          <b>Γ. Γραμματέας:</b> Βέντου Θεοδώρα
          <br />
          <b>Ταμίας:</b> Σταματέλλη Ρένα
          <br />
          <b>Μέλη:</b> Γρηγορέλλη Κατερίνα, Παπαμακαρίου Ντόρα, Χιωτέλης
          Χαράλαμπος
          <br />
          <b>Αναπληρωματικά μέλη</b>: Κούκλου Μαρία, Ευαγγέλου Βικτωρίτσα,
          Τουρλής Μιχάλης, Καραντώνης Ευστράτιος.
          <br />
          <br />
          <u>Για την Ε. Επιτροπή εκλέχτηκαν οι:</u>
          <br />
          Βαλαχής Παναγιώτης
          <br />
          Δασκάλου Μαρία
          <br />
          Σπανός Νικόλαος
          <br />
          <br />
          <u>Η εφορευτική Επιτροπή:</u> <br />
          Αποστόλου Ανθίππη, Παλκάνικου Τζένη, Σταματέλλη Γραμματική.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ανάσταση στη Σκάλα Λουτρών
        </Typography>
        <img
          src={Image06}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image08}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ανάσταση στη Σκάλα Λουτρών στο εκκλησάκι της Παναγίας για πρώτη φορά.{" "}
          <br />
          Συγκινητικές στιγμές από έναν καταξιωμένο ιερέα που εδώ και πολλά
          χρόνια υπηρετεί στους κόλπους της εκκλησίας μας, τον πάτερ Κομνηνός
          Βράνη, ο οποίος βρέθηκε κοντά μας και μας έκανε να νιώσουμε με τα
          γλυκά του λόγια το μήνυμα της Ανάστασης του Θεανθρώπου. Η ακολουθία
          της Ανάστασης πραγματοποιήθηκε λόγω των μέτρων για τον κορωνοϊό
          ενωρίτερα στις 19:30 του Μεγάλου Σαββάτου και σ αυτήν συμμετείχαν
          ευλαβικά όλα τα μέλη του Συλλόγου και όλοι οι κάτοικοι της Σκάλας
          Λουτρών. Ο Σύλλογος μας φρόντισε όπως κάθε χρόνο να μοιραστούν
          τσουρέκια, κόκκινα αυγά και αναστάσιμα κεριά σε όλους τους
          παρευρεθέντες. Οφείλουμε ένα μεγάλο ευχαριστώ στον ιερέα και στον
          ψάλτη που τον συνόδευσε, καθώς και στον δικό μας ψάλτη, το μέλος του
          Συλλόγου μας κ. Σωτήρη Μαμουνή, που μας εξυπηρετεί πάντοτε με το να
          μην απουσιάζει από καμία θρησκευτική εκδήλωση μας. Θέλοντας να
          εκφράσουμε τις ευχαριστίες του Συλλόγου, στο τέλος της Αναστάσιμης
          ακολουθίας προσφέραμε από ένα λεύκωμα 30 ετών του Συλλόγου τόσο στον
          Ιερέα όσο και στους ψάλτες που συμμετείχαν.
          <br />
          <br />
          Επίσης το ίδιο βράδυ μετά την Ανάσταση όλοι μαζί μεταβήκαμε στην
          προβλήτα του νέου λιμανιού της Σκάλας Λουτρών και ανάψαμε αναδυόμενα
          φαναράκια τα οποία ήταν προσφορά του μέλους του Συλλόγου μας Μανώλη
          Τζανέτου τον οποίο και ευχαριστούμε. Ευχή όλων μας αυτό το έθιμο να
          διατηρηθεί και η προσπάθεια να συνεχιστεί απ όλους μας!
          <br />
          <br />
          Και του χρόνου με υγεία!
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διεθνής ημέρα Μουσείων
        </Typography>
        <img
          src={Image10}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Τρίτη 18 Μαΐου 2021, που εορτάζεται η Διεθνής ημέρα Μουσείων, το
          «Μουσείο Προσφυγικής Μνήμης 1922» στη Σκάλα Λουτρών, ήταν ανοικτό για
          τους επισκέπτες από τις 09.30 έως τις 12.30.
          <br />
          Το Διεθνές Συμβούλιο Μουσείων (ICOM), επιθυμώντας να αναδείξει το ρόλο
          των Μουσείων στη σύγχρονη κοινωνία, έχει ορίσει από το 1977 την 18η
          Μαΐου ως Διεθνή Ημέρα Μουσείων. Το μήνυμα αυτής της επετείου είναι «να
          γίνουν τα Μουσεία φορείς πολιτισμικών ανταλλαγών, με σκοπό την
          ανάπτυξη της μόρφωσης, της αμοιβαίας κατανόησης, της συνεργασίας και
          της ειρήνης ανάμεσα στους λαούς».
          <br />
          *Υπενθυμίζουμε επίσης ότι, το «Μουσείο Προσφυγικής Μνήμης 1922» ήταν
          ανοικτό την 21η εκάστου μηνός για όλο το έτος 2021 που εορτάζονταν τα
          200 χρόνια από την Ελληνική επανάσταση του 1821 (από τις 09.30 έως τις
          12.30), τις δε υπόλοιπες ημέρες και ώρες δεχόταν επισκέψεις, μόνον
          κατόπιν ραντεβού στο τηλέφωνο: 69746.40419.
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Περιβαλλοντικές δράσεις στα Λουτρά και τη Σκάλα Λουτρών
        </Typography>
        <img
          src={Image12}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image14}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Παρασκευή 28 Μαΐου, οι μαθητές και οι μαθήτριες του Δημοτικού
          σχολείου Λουτρών σε συνεργασία με τους δασκάλους του σχολείου, την
          Κοινότητα Λουτρών και τους Συλλόγους Μικρασιατών Σκάλας Λουτρών «Το
          Δελφίνι» και «Λιοστάσι» Λουτρών, καθάρισαν (τηρώντας όλα τα
          υγειονομικά μέτρα) ανά τάξη συγκεκριμένους κοινόχρηστους χώρους των
          Λουτρών και της Σκάλας Λουτρών συλλέγοντας παράλληλα, υλικά κατάλληλα
          για ανακύκλωση και φυτεύοντας σε ένδειξη ανάμνησης της περιβαλλοντικής
          δράσης, λουλούδια στην πλατεία Λουτρών και μία ελιά στον χώρο μπάσκετ
          και αθλοπαιδιών της Σκάλας Λουτρών. <br />
          <br />
          Στη συνέχεια, το Σάββατο 29 Μαΐου στον αύλιο χώρο του «Μουσείου
          Προσφυγικής Μνήμης 1922» στη Σκάλα Λουτρών μας παρουσίασαν διάφορα
          δρώμενα όπως, «Μια ιστορία αειφορίας», «Ένας ανθρώπινος πλανήτης,
          «Τραγούδια με θέμα το Περιβάλλον», «Όλοι μαζί μπορούμε» κ.α. ενώ τα
          παιδιά της Α! και Β! τάξης ασχολήθηκαν, σε διαφορετικό χώρο, με
          διάφορες κατασκευές από ανακυκλώσιμα υλικά. <br />
          <br />
          Μετά το πέρας των εκδηλώσεων των μαθητών του Δημοτικού Σχολείου, ο
          Σύλλογος Μικρασιατών Σκάλας Λουτρών «Το Δελφίνι», παρουσίασε ένα πολύ
          ενδιαφέρον βιντεάκι του Ινστιτούτου Κητολογικών Ερευνών με θέμα τα
          σοβαρά προβλήματα που δημιουργούν τα πλαστικά στις θάλασσες μας.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Βραδιά παραμυθιού και μουσικής στη Σκάλα Λουτρών
        </Typography>
        <img
          src={Image16}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image18}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στην Σκάλα Λουτρών, εκεί στην οδό παραμυθιών, το βράδυ της Κυριακής 11
          Ιουλίου, περάσαμε πολύ ωραία με την αγαπημένη μας θεατρολόγο-ηθοποιό
          Νάσια Δαφιώτη στο ρόλο της αφήγησης των παραμυθιών και τον εξαίρετο
          δάσκαλο μουσικής τον Γρηγόρη Μολυβιάτη στο τραγούδι με την κιθάρα του.
          <br />
          <br />
          Οι μικροί μας φίλοι συμμετείχαν με ενθουσιασμό στα δρώμενα, αλλά και
          οι γονείς των παιδιών που παραβρέθηκαν απόλαυσαν τα υπέροχα τραγούδια
          που συνόδευσαν τα παραμύθια. Ήταν μια εξαιρετική από κάθε άποψη
          βραδιά, η οποία εκτός από διασκέδαση πρόσφερε και γνώση στα παιδιά και
          τους μεγάλους, με την θεματολογία των παραμυθιών να είναι σχετική με
          την αγάπη, την ευγένεια, την αλληλεγγύη, αλλά και την προστασία του
          περιβάλλοντος!
          <br />
          <br />Ο Σύλλογος Μικρασιατών Σκάλας Λουτρών "Το Δελφίνι" ευχαριστεί
          από καρδιάς την Νάσια και τον Γρηγόρη για την υπέροχη βραδιά που μας
          χάρισαν και μάλιστα αφιλοκερδώς! Και του χρόνου με υγεία…
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Επίσκεψη του Ροταριανού Ομίλου Μυτιλήνης στο «Μουσείο Προσφυγικής
          Μνήμης 1922»
        </Typography>
        <img
          src={Image20}
          width="600"
          height="225"
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Τετάρτη 2 Ιουνίου 2021, είχαμε την χαρά να υποδεχθούμε και να
          ξεναγήσουμε στο «Μουσείο Προσφυγικής Μνήμης 1922», τα μέλη του
          Ροταριανού Ομίλου Μυτιλήνης, οι οποίοι έδειξαν μεγάλο ενδιαφέρον για
          τα κειμήλια που φιλοξενούμε στο Μουσείο. Ιδιαίτερα εντυπωσιάστηκαν από
          τους δύο επίτοιχους χάρτες και από την μακέτα της Σμύρνης, όπως αυτή
          ήταν πριν την καταστροφή της. <br />
          <br />
          Κατά την διάρκεια της επίσκεψης η κ. Ελένη Χατζηδήμου- Γογγολίδου με
          καταγωγή από τα Μοσχονήσια δώρισε στο Μουσείο ένα εσώρουχο της γιαγιάς
          της Ελένης, καθώς και ένα αντίγραφο φωτογραφίας που απεικονίζει τον
          έρανο που πραγματοποιήθηκε στην πλατεία Μοσχονησίων, υπέρ των
          επαναπατρισθέντων προσφύγων το 1919, πρόσφυγες που είχαν απομακρυνθεί
          κατά την διάρκεια του πρώτου διωγμού το 1914. Τους ευχαριστούμε τόσο
          για την ενδιαφέρουσα επίσκεψη που μας έδωσε χαρά, όσο και για τα
          κειμήλια που δώρισαν στο Μουσείο.
          <br />
          <br />
          Μετά την ξενάγηση στο Μουσείο μετέβησαν όλοι μαζί για δείπνο σε
          παραλιακή ταβέρνα της Σκάλας Λουτρών.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          «10ος Κολυμβητικός διάπλους Μικρασιατικής Μνήμης»
        </Typography>
        <img
          src={Image22}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image24}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε με επιτυχία και ικανοποιητική συμμετοχή την Κυριακή 1
          Αυγούστου 2021, ο 10ος κολυμβητικός διάπλους του Κόλπου Γέρας από την
          Κουντουρουδιά έως το Πέραμα. Οι 51 κολυμβητές και κολυμβήτριες που
          συμμετείχαν διήνυσαν την απόσταση των <b>1.000</b> περίπου μέτρων που
          χωρίζει την Κουντουρουδιά από το Πέραμα χωρίς ιδιαίτερα προβλήματα
          αφού ο καιρός ήταν εξαιρετικός και η θάλασσα χωρίς κυματισμούς.
          <br />
          <br />
          Οι ηλικίες των κολυμβητών που συμμετείχαν ήταν από 14 έως 82 ετών και
          ο χρόνος που έκαναν κυμάνθηκε από 18 περίπου λεπτά ο πρώτος μέχρι 55
          λεπτά ο τελευταίος. Στην ασφαλή διεξαγωγή του κολυμβητικού διάπλου
          συνέβαλαν αποφασιστικά, το πλωτό σκάφος του Λιμενικού Σώματος που
          επέβλεπε συνεχώς τους κολυμβητές, καθώς και η παρουσία του ιατρού κ.{" "}
          <b>Μάκη Αξιώτη</b> που παρευρίσκετο για να προσφέρει τις πρώτες
          βοήθειες αν χρειαζόταν.
          <br />
          <br />
          Τις ευχαριστίες μας οφείλουμε σε όλους τους προαναφερθέντες, καθώς
          επίσης και στους κ.κ. <b>Ευστράτιο Καραντώνη</b> και{" "}
          <b>Στέλιο Χιώτη</b>, οι οποίοι διέθεσαν τις βάρκες τους για τη δωρεάν
          μεταφορά των αθλητών και των συνοδών τους από την Κουντουρουδιά στο
          Πέραμα και αντιστρόφως.
          <br />
          <br />
          Τέλος, σε όλους τους συμμετέχοντες δόθηκαν αναμνηστικά διπλώματα και
          μπλουζάκια για τη συμμετοχή τους τα οποία ήταν προσφορά της
          αντιδημαρχίας Πολιτισμού, Αθλητισμού και Τουρισμού του Δήμου
          Μυτιλήνης.
          <br />
          <br />
          Και του χρόνου με υγεία.
          <br />
          <br />
          <u>Διοργανωτές της εκδήλωσης ήταν:</u>
          <br />
          <br />
          Ο «Παγγεραγωτικός» Πολιτιστικός Σύλλογος Γέρας,
          <br />
          Ο Σύλλογος Μικρασιατών Σκάλας Λουτρών «ΤΟ ΔΕΛΦΙΝΙ»
          <br />
          Ο «Παγγεραγωτικός» Σύλλογος Αθηνών και η <br />
          Α.Π.Σ. «Αναγέννηση Ποσειδώνα» Περάματος.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Βράβευση αριστούχων μαθητών Δημοτικής Κοινότητας Λουτρών
        </Typography>
        <img
          src={Image26}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image28}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στον αύλιο χώρο του «Μουσείου Προσφυγικής Μνήμης 1922» και σε
          συνδιοργάνωση με την Π.Β.Α. πραγματοποιήθηκε, την Δευτέρα{" "}
          <b>9-8-2021</b> το απόγευμα, με επιτυχία και αθρόα συμμετοχή, η
          εκδήλωση για την βράβευση των αριστούχων μαθητών της Δημοτικής
          Κοινότητας Λουτρών από τον Σύλλογο Μικρασιατών της Σκάλας Λουτρών{" "}
          <b>«ΤΟ ΔΕΛΦΙΝΙ»</b>, παρουσία της αντιπεριφερειάρχου Πολιτισμού και
          Οικονομικών κ. Αναστασίας Αντωνέλλη, του προέδρου της Κοινότητας μας,
          της διευθύντριας και των δασκάλων του Δημοτικού Σχολείου Λουτρών.{" "}
          <br /> <br />
          Στην αρχή της εκδήλωσης έγινε μια σύντομη ενημέρωση από τον πρόεδρο κ.
          Δημήτρη Παπαχρυσό, σχετική με τις τρέχουσες δραστηριότητες του
          Συλλόγου και αμέσως μετά ένα σύντομο αφιέρωμα στα{" "}
          <b>«Σχολεία της Σμύρνης»</b> από το μέλος του Συλλόγου την κ. Μαρία
          Δασκάλου. <br />
          Οι βραβεύσεις άρχισαν με τα παιδιά του Δημοτικού σχολείου που
          διακρίθηκαν στην ζωγραφική και στην έκθεση με θέμα το περιβάλλον.
          <br />
          <br />
          Οι <b>4</b> πρώτες τάξεις είχαν διαγωνιστεί στην ζωγραφική με θέμα:{" "}
          <br />
          <b>
            «Ζωγράφισε την παραλία των ονείρων σου»
            <br />
          </b>
          και οι 2 μεγαλύτερες τάξεις στην έκθεση με θέμα:
          <br />
          <b>«Τι θα άλλαζες στο περιβάλλον του χωριού σου»</b> <br />
          <br />
          <br />
          Βραβεύτηκαν:
          <br />
          Από την <b>Α! Τάξη</b> η Λιαδέλλη Μάρα και η Μανιάτη Μαρίνα. <br />
          Από την <b>Β! Τάξη</b> η Γκέντση Ελεονώρα και η Κλάβα Ευγλωτία-Μαρία.
          <br />
          Από την <b>Γ! Τάξη </b> η Ντάκα Αντζελική και ο Τατάκης Ιορδάνης.
          <br />
          Από την <b>Δ1! Τάξη</b> η Ζαμτράκη Πελαγία και η Τσολάκη Ελίνα. <br />
          Από την <b>Δ2! Τάξη</b> η Ζέκα Αρμέλα και η Λύρα Ουρανία.
          <br />
          Από την <b>Ε! Τάξη</b> η Γκέντση Μαριτίνα και η Κλάβα Σοφία.
          <br />
          Από την <b>ΣΤ! Τάξη</b> ο Τατάκης Γιώργος και ο Αγιασώτης
          Ανδρέας-Ταξιάρχης.
          <br />
          Από την <b>ΣΤ!Τάξη</b> υποδοχής ο Σαίντ Σαμάρι και η Γουσόν Σαμάρι.
          <br />
          <br />
          Ακολούθησε η βράβευση των αριστούχων παιδιών του Γυμνασίου και του
          Λυκείου.
          <br />
          <br />
          Βραβεύτηκαν:
          <br />
          <br />
          Από την <b>Α! γυμνασίου</b> <br /> ο Στρίγκος Χρήστος
          <br />
          η Βασιλέλλη Μαρία
          <br />
          η Ζούρου Ευαγγελία και
          <br />
          η Κέντση Ταξιαρχούλα και
          <br />
          η Λιαδέλλη Δωροθέα
          <br />
          <br />
          Από την <b>Β! γυμνασίου</b> <br /> η Μύνικνα Μαρία και <br />
          η Τριανταφύλλου Θεανώ <br />
          <br />
          Από την <b>Γ! γυμνασίου</b> <br /> η Τρουλινού Αικατερίνη <br />
          η Γκίζα Ευστρατία – Γεωργία
          <br />
          η Μολυβιάτου Νίκη και
          <br />
          η Κέντζη Μαρία <br />
          <br />
          Από την <b> Α! λυκείου </b> <br /> η Γεωργιτζίκη Παρασκευή <br />
          ο Λέλεκας Φίλιππος
          <br />
          η Παπαμακαρίου Ελένη
          <br />
          ο Στρίγκος Μάριος <br />
          ο Αρμενάκας Νίκος και
          <br />
          ο Αλεξίου Γιώργος
          <br /> <br />
          Από την <b> Β! λυκείου</b> <br /> η Μύνικνα Ειρήνη
          <br />
          η Στυψιανού Μυρσίνη
          <br />
          η Ευαγγέλου Χριστίνα
          <br />
          ο Αρμενάκας Παναγιώτης <br />
          η Παπαμακαρίου Δούκισσα και
          <br />
          ο Κουκώνης Ραφαήλ-Βασίλειος
          <br /> <br />
          Από την <b> Γ! λυκείου </b> <br /> ο Λέλεκας Κωνσταντίνος και
          <br />
          η Αλεξίου Μαρία <br />
          <br /> <br />
          Η βράβευση των διακριθέντων παιδιών του Δημοτικού Σχολείου Λουτρών
          έγινε από τους δασκάλους και την διευθύντρια του σχολείου κ. Ερ.
          Χιωτέλλη και των αριστούχων Γυμνασίου και Λυκείου από την
          αντιπεριφερειάρχη κ. Αναστασία Αντωνέλλη, από τον πρόεδρο της
          Κοινότητας Λουτρών κ. Β. Μαμώλη, από την επίτιμη πρόξενο της Γαλλίας
          για Λέσβο και Λήμνο και συνεργάτιδα του Γαλλικού Ινστιτούτου Ελλάδας
          κ. Σ. Τσιριγώτη και από τον Λουτραγώτη συγγραφέα κ. Απόστολο
          Γονιδέλλη.
          <br /> <br />
          Στην εκδήλωση εκτός από την συμβολή της Π.Β.Α. συμμετείχαν ο ΟΔΕΓ
          (Οργανισμός Διάδοσης Ελληνικής Γλώσσας) που πρόσφερε μέσω των μελών
          του ισάριθμα βιβλία και μπάλες για όλα τα παιδιά του Δημοτικού
          Σχολείου Λουτρών, η κ. Σοφία Τσιριγώτη που πρόσφερε 3 υποτροφίες για
          παιδιά της ΣΤ΄ τάξης που θα έχουν δηλώσει ως δεύτερη γλώσσα τα Γαλλικά
          και ο κ. Απόστολος Γονιδέλλης με ένα χρηματικό ποσό των 300 ευρώ που
          μοιράστηκαν τα παιδιά της Γ΄ Λυκείου με την υψηλότερη βαθμολογία που
          ήταν η Αλεξίου Μαρία και ο Κωνσταντίνος Λέλεκας. Η εκδήλωση έκλεισε με
          δυο τραγούδια που μας είπαν η αντιπρόεδρος του Συλλόγου, κ. Νάσια
          Δαφιώτη, η οποία είχε και τον συντονισμό της εκδήλωσης, μαζί με τον
          εξαίρετο δάσκαλο μουσικής κ. Γρηγόρη Μολυβιάτη.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αφιέρωμα στα 200 χρόνια από την Ελληνική επανάσταση του 1821
        </Typography>
        <img
          src={Image30}
          width="300"
          height="425"
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Δευτέρα, 16 Αυγούστου 2021 στον αύλιο χώρο του «Μουσείου
          Προσφυγικής Μνήμης 1922» με την συνεργασία του Συλλόγου
          «Culture4All/Πολιτισμός για όλους» και την Αντιπεριφέρεια Πολιτισμού
          Βορείου Αιγαίου, πραγματοποιήθηκε εκδήλωση – αφιέρωμα στα 200 χρόνια
          από την Ελληνική επανάσταση με τίτλο:
          <br />
          <b>
            «Ο Μικρασιατικός Ελληνισμός τις παραμονές του απελευθερωτικού αγώνα»
          </b>
          .<br />
          <br />
          Ομιλητές στην εκδήλωση ήταν: <br />
          1- Ο κ. Αναγνώστου Στρατής, διδάκτωρ ιστορικός και πρόεδρος της
          Εταιρείας Λεσβιακών Μελετών, με θέμα{" "}
          <b>«Η Λέσβος την περίοδο της επανάστασης του 1821»</b>
          <br />
          2- Ο κ. Αργυρός Λεωνίδας, διδάκτωρ ιστορικός και επίτιμος επιμελητής
          του Μουσείου της πόλεως των Αθηνών, με θέμα:{" "}
          <b>
            «Το άλλο ταξίδι του λόρδου Βύρωνα: από την Σμύρνη στην
            Κωνσταντινούπολη»
          </b>
          <br />
          3- Ο κ. Μιχαηλάρης Παναγιώτης, διδάκτωρ ιστορικός και επίτιμος
          καθηγητής του Εθνικού Ιδρύματος Ερευνών, με θέμα:{" "}
          <b>«Η συμβολή των Κυδωνιών στην Επανάσταση του 1821»</b> και
          <br />
          4- Ο κ. Παπαχρυσός Δημήτρης, πρόεδρος του Συλλόγου Μικρασιατών της
          Σκάλας Λουτρών «Το Δελφίνι», με θέμα:
          <b>
            «Η συμβολή των Μικρασιατών στον Εθνικό αγώνα του 1821 και το όραμα
            του Αδαμαντίου Κοραή»
          </b>
          <br />
          <br />
          Την εκδήλωση συντόνισε η κ. Αγλαΐα Αρχοντίδου, αρχαιολόγος και επίτιμη
          Διευθύντρια του Υπουργείου Πολιτισμού και του Μουσείου της Πόλεως των
          Αθηνών.
          <br />
          <br />
          <br />
          <br />
          <br />
        </Typography>
     
        <Typography variant="h1" sx={theme.typography.typography1}>Παρακάτω δημοσιεύουμε την ομιλία με θέμα:</Typography>
   
        <Typography variant="h1" sx={theme.typography.typography1}>
            Η συμβολή των Μικρασιατών στον Εθνικό αγώνα του 1821 και το όραμα
            του Αδαμάντιο Κοραή.
          </Typography>
<Typography variant="h3" sx={theme.typography.typography2}>
          <img
            src={Image32}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          />
          <img
            src={Image34}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          />
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Με την ευκαιρία του εορτασμού της παλιγγενεσίας του Ελληνικού Έθνους,
          θα μπορούσαμε να αναφερθούμε εκτενώς σε πάμπολλες σχετικές μελέτες
          καθώς η σχετική βιβλιογραφία είναι άφθονη και οι μελετητές έχουν
          αναλύσει λεπτομερώς όχι μόνο τις ηρωικές στιγμές και τις δόξες των
          αγωνιστών, αλλά και τις πολιτικές διαπλοκές τις δίκες και τις
          δολοφονίες αυτών. <br />
          <br />
          Χαρά και περηφάνια λοιπόν για όσα επιτύχαμε έχοντας ομόνοια ,αλλά και
          λύπη για όσα συνέβησαν όταν δεν κατορθώναμε να ξεπεράσουμε την εθνική
          μας κατάρα, που είναι η διχόνοια. Ιστορικά για το συγκεκριμένο θέμα
          υπήρχε ένα μεγάλο κενό για το οποίο με την ευκαιρία του εορτασμού των
          200 ετών από την επανάσταση του 1821 μας δόθηκε η ευκαιρία να ψάξουμε
          στις διάφορες πηγές, ώστε να αναδειχθούν κομμάτια της ιστορίας που
          λείπουν και που αυτά έχουν σχέση με την συμβολή των Μικρασιατών στον
          απελευθερωτικό αγώνα του έθνους μας. Ξεκινώντας από τα προεπαναστατικά
          χρόνια, θα αναφερθώ εν συντομία στην πνευματική συμβολή του Σμυρνιού
          Αδαμάντιου Κοραή και στα όσα με προτροπή του πραγματοποιήθηκαν πριν
          την επανάσταση για να μπουν οι βάσεις με την κατάλληλη διαπαιδαγώγηση
          των Σμυρνιών και κατ επέκταση των Ελλήνων της Μικράς Ασίας. <br />
          <br />
          Αφορμή για την αναφορά μου στον Αδαμάντιο Κοραή, στάθηκε η κυκλοφορία
          τον περασμένο χρόνο, από το Κ.Μ.Σ. ενός βιβλίου με τίτλο{" "}
          <b>«Η Σμύρνη των βιβλίων»</b>, το οποίο αναφέρεται σε συγγραφείς,
          εκδότες, μεταφραστές και τυπογράφους της περιόδου 1764-1922. Ένα
          βιβλίο στο οποίο σημαντική θέση, αν όχι την κυριότερη, κατέχει η
          Σμυρναϊκή βιβλιογραφία του ιατρού Αθανάσιου Χατζηδήμου, πατέρα του
          μέλους του Συλλόγου μας κ. Αλέξανδρου Χατζηδήμου. <br />
          <br />
          Μέσα από αυτό το βιβλίο και αφού επικεντρώθηκα στην περίοδο λίγο πριν
          την Ελληνική επανάσταση του 1821, έχω αντλήσει τις περισσότερες
          πληροφορίες για το όραμα του Αδαμαντίου Κοραή και την κυοφορούμενη
          τότε αλλαγή, δηλαδή την επανάσταση του 1821. <br />
          <br />
          Στην προεπαναστατική περίοδο στη Σμύρνη, η απουσία τυπογραφικής
          δραστηριότητας δεν δηλώνει απαραίτητα και την αδιαφορία των Σμυρναίων
          για την μόρφωσή τους. Σε επιστολή του ο Αδαμάντιος Κοραής από το
          Παρίσι που βρισκόταν τότε, εκφράζει την ανησυχία του για την
          βραδυπορία των Σμυρναίων στην παιδεία έναντι των γειτόνων τους που
          κατοικούν στην Χίο και στις Κυδωνίες. Ωστόσο τη δεύτερη δεκαετία πριν
          από τον αγώνα του 1821, συντελείται στη Σμύρνη η πνευματική εκείνη
          ζύμωση, η οποία αποτυπώνει τις ιδέες που θα βλαστήσουν πολλές
          δεκαετίες αργότερα, στην πόλη της Ιωνίας. <br />
          <br />
          Οι φιλομαθείς Σμυρναίοι αυτή την περίοδο ενημερώνονται από διάφορες
          «αγγελίες» και καταφεύγουν στο σύστημα των συνδρομητών για να
          εγγραφούν σε βιβλία που πρόκειται να τυπωθούν στα μεγάλα ευρωπαϊκά
          κέντρα. Οι «αγγελίες» απευθύνονται σε ελληνόγλωσσους και ελληνομαθείς
          πληθυσμούς και σκοπό είχαν τη δημοσιοποίηση των εκδοτικών προθέσεων
          των συγγραφέων προς άγραν οικονομικής ενίσχυσης για τα προεπαναστατικά
          έντυπα. Οι πολλές παραγγελίες για βιβλία που θα έρχονταν απ έξω,
          δείχνουν, σε μικροκλίμακα και την αγωνιστική δίψα μιας μερίδας των
          κατοίκων για την οικειοποίηση νέων ιδεών που μεταλαμπαδεύονται στη
          Σμύρνη από την ξένη τυπογραφία. Η άφιξη π.χ. της Γαλλικής
          εγκυκλοπαίδειας στη Σμύρνη το 1816, του εμβληματικού αυτού έργου του
          διαφωτισμού, παρέχει πλείστα όσα πνευματικά ερεθίσματα τόσο στις
          Ελληνικές όσο και στις ξενόγλωσσες ομάδες του πληθυσμού. <br />
          <br />
          Αυτή την προεπαναστατική περίοδο, η μορφή του Αδαμάντιου Κοραή κατέχει
          πρωτεύουσα θέση. Εγκατεστημένος στη Γαλλική πρωτεύουσα, δίχως να χάνει
          ποτέ επαφή με τα ζητήματα της γενέτειρας, σχεδιάζει την τακτική και τη
          στρατηγική που οφείλουν να ακολουθήσουν οι Γραικοί για να αποτρέψουν
          τον «ολέθριο ύπνο της αμαθείας». <br />
          <br />
          Το 1807, θα αποτανθεί «προς τους Έλληνες». Εισηγείται στον Πατριάρχη
          και στη Σύνοδο των Αρχιερέων τη νεόκοπη έννοια ενός Μουσείου με
          πρόσημο αρχαιοελληνικό αλλά και εθνικό. Το αποκαλεί Ελληνικό Μουσείο,
          προσδίδοντας στον παλαιό όρο Μουσείο τη νέα σημασία του. Συμβουλεύει
          δε «Να οριστεί εντός του Μουσείου τόπος για να δημιουργηθεί μία
          εξαίρετος βιβλιοθήκη, καθώς και ένα νέο εκπαιδευτήριο, το οποίο τελικά
          αρχίζει τη βραχύβια λειτουργία του το 1809 με την ονομασία Φιλολογικό
          Γυμνάσιο. <br />
          <br />
          Το 1810 ο Αδαμάντιος Κοραής, απευθυνόμενος «προς τους Έλληνες και
          εξαιρέτως προς τους Σμυρναίους», τους συμβουλεύει συστήνοντας σ αυτούς
          συγκεκριμένες λύσεις για τον επιδιωκόμενο σκοπό, καταγράφοντας
          βήμα-βήμα τις τακτικές κινήσεις που απαιτούνται για να κατακτηθεί ο
          στρατηγικός στόχος: και αυτοί είναι, η παιδεία, οι θεσμοί, ο
          πολιτισμός οι βιβλιοθήκες τα μουσεία και ιδίως η ελεύθερη πρόσβαση σ
          αυτά. <br />
          <br />
          Παρά ταύτα, δεν εφησυχάζει: δεν αρκεί από μόνη της η απόκτηση μιας
          πλούσιας βιβλιοθήκης για να ξεπεραστεί η αμάθεια και οι σπουδαστές να
          ωφεληθούν από τα βιβλία. Στο σημείο αυτό, ο πνευματικός ταγός,
          επιχειρεί την ιστορική υπέρβαση. Θα προσδώσει στην παιδεία διάσταση
          εθνική. Πριν λοιπόν από την ίδρυση του Ελληνικού κράτους, εκείνος
          αντιλαμβάνεται την παιδεία με πρόσημο εθνικό, Ελληνικό και όχι ως κάτι
          γεωγραφικά πεπερασμένο ή περιχαρακωμένο. Δίχως να αρνείται το τοπικό,
          κινείται επέκεινα του τοπικού. Συλλαμβάνει το ζήτημα της παιδείας σε
          μια ευρεία προοπτική, θεωρώντας την πόλη της Σμύρνης τμήμα ενός
          ενιαίου, υπερκείμενου οργανισμού: Οι κάτοικοί της θα είναι τέκνα μιας
          μητρός, της Ελλάδος, θα ανήκουν σε ένα έθνος και όπως λέει:
          <br />
          <br />
          <b>
            «Ομόνοιαν εννοώ τοιαύτην. Τα τρία Γυμνάσια της Σμύρνης, της Χίου και
            των Κυδωνιών, να λογίζονται εν δένδρον καρποφόρον διηρημένον εις
            τρεις κλάδους. Εις ποταμός σοφίας, σχισμένος εις τρεις διώρυγας, οι
            τρεις διδάσκαλοι, τρεις αδελφοί. Οι επίτροποι των τριών Γυμνασίων,
            της Χίου, της Σμύρνης και των Κυδωνιών, να μη νομίζονται μήτε
            Κυδωνιάται, μήτε Σμυρναίοι, μήτε Χίοι, αλλ Έλληνες, μιας μητρός, της
            Ελλάδος τέκνα, κοινωνούντες προς αλλήλους ότι συμφέρει εις την
            κοινήν του Έθνους αναγέννησιν».
          </b>
          <br />
          <br />
          Ο Αδαμάντιος Κοραής που ήταν το πρώτο από τα οκτώ παιδιά της
          οικογένειάς του διδάχθηκε τα πρώτα του γράμματα στην Ευαγγελική Σχολή
          της Σμύρνης, όπου σημείωσε μεγάλη πρόοδο στην εκμάθηση της αρχαίας
          ελληνικής γλώσσας και γραμματείας. Στην εφηβεία του άρχισε να μαθαίνει
          γαλλικά και ιταλικά και στην συνέχεια την εβραϊκή γλώσσα από κάποιον
          Εβραίο και τα λατινικά από τον τότε Ολλανδό πάστορα του ολλανδικού
          προξενείου της Σμύρνης.
          <br />
          <br />
          Πατέρας του ήταν ο Ιωάννης Κοραής, επιτυχημένος έμπορος μεταξιού που
          στην ελληνική κοινότητα της Σμύρνης εκλεγόταν συχνά δημογέροντας και
          επίτροπος των ιδρυμάτων της κοινότητας και του Μετοχίου του Παναγίου
          Τάφου στη Σμύρνη. <br />
          <br />
          Μητέρα του ήταν η Θωμαΐδα Ρυσίου από μορφωμένη οικογένεια κι από τις
          ελάχιστες γυναίκες της εποχής που ήξεραν ανάγνωση και γραφή με
          καταγωγή από το Ρύσιο της Μικράς Ασίας. Στις οικογένειες και των δύο
          γονέων του υπήρχαν πάρα πολλοί λόγιοι. <br />
          <br />
          Ο Αδαμάντιος Κοραής που γεννήθηκε στη Σμύρνη στις 27 Απριλίου του 1748
          και πέθανε τελικά στο Παρίσι σε ηλικία 84 χρονών το 1833, αποτέλεσε
          τον λαμπρότερο εκπρόσωπο του νεοελληνικού διαφωτισμού και αποδείχθηκε
          ο κυριότερος «εγκέφαλος» σχετικά με την διάδοση των ευρωπαϊκών
          αντιλήψεων στην τουρκοκρατούμενη τότε Ελλάδα, βάζοντας με αυτό τον
          τρόπο και εκείνος το λιθαράκι του για την πνευματική προετοιμασία των
          Ελλήνων στο ξέσπασμα της Ελληνικής επανάστασης του 1821.
          <br />
          Αφού κλείσουμε τώρα το κεφάλαιο του Αδαμάντιου Κοραή που αφορούσε την
          προεπαναστατική περίοδο, θα παρατηρήσουμε ότι σήμερα, όλες οι αναφορές
          για την ελληνική επανάσταση του 1821 αναφέρονται στον κυρίως κορμό της
          Ελλάδας, δηλαδή την Πελοπόννησο, τη Στερεά Ελλάδα και ορισμένα νησιά
          γενικότερα. <br />
          Εκτενής είναι η αναφορά σε Πελοποννήσιους, Ρουμελιώτες, νησιώτες και
          άλλους στο πλαίσιο των πολιτικών και πολεμικών γεγονότων που
          διαδραματίστηκαν στις προαναφερθείσες περιοχές. Είναι παντελής,
          ωστόσο, -πλην ελάχιστων περιπτώσεων- η έλλειψη αναφορών στη συμβολή
          των Ελλήνων της Μικράς Ασίας, οι οποίοι έδωσαν με σθένος και παλικαριά
          ό,τι μπορούσαν για την ελευθερία της Ελλάδας και θυσιάστηκαν στο βωμό
          των γεγονότων, δίνοντας ακόμη και τη ζωή τους για να ζήσουν οι
          απόγονοί τους ελεύθεροι.
          <br />
          Επιδιώκοντας να αναδείξουμε αυτά τα κομμάτια της ιστορίας που λείπουν
          η που δεν είναι ευρέως γνωστά στο κοινό, διαπιστώσαμε ότι η επανάσταση
          του 1821 ήταν ένα γεγονός που δεν σήμανε συναγερμό μόνο στους Έλληνες
          της κυρίως Ελλάδας, αλλά και σε αυτούς που συγκροτούν το Μείζονα
          Ελληνισμό. <br />
          Οι πόλεις της Μικράς Ασίας λοιπόν ήταν και αυτές που από πολύ νωρίς
          έγιναν αποδέκτες των μηνυμάτων για οργάνωση, ακολουθώντας τον ξεσηκωμό
          του Γένους. Η Φιλική Εταιρεία π.χ. από πολύ νωρίς εξαπλώθηκε γρήγορα,
          τόσο στην Κωνσταντινούπολη, όσο και στη Σμύρνη, καθώς και στις
          Κυδωνίες, στην χερσόνησο της Ερυθραίας, στην Καππαδοκία και τον Πόντο.
          Ας σημειώσουμε εδώ, φέρνοντας ένα παράδειγμα ότι, στη Σμύρνη, είχε
          συσταθεί η «Φιλόμουσος και Φιλάνθρωπος Γραικική Εμπορική Εταιρία», τα
          κέρδη της οποίας διετίθεντο υπέρ της ελευθερίας της Πατρίδος, για
          «αγορά και προώθηση πολεμοφοδίων στην Ελλάδα» κατ’ εντολή του
          Αλέξανδρου Υψηλάντη, παραμονές μάλιστα της Ελληνικής Επανάστασης του
          1821, ένα καράβι φορτωμένο μπαρούτι και μολύβι από την Σμύρνη φτάνει
          στο λιμανάκι του Αλμυρού, στη Βέργα, και από εκεί με άκρα μυστικότητα
          το φορτίο θα μεταφερθεί στην μονή Μαρδακίου για να διανεμηθεί στους
          επαναστάτες.
          <br />
          Υπάρχουν αναφορές που δηλώνουν ότι σ αυτό το κάλεσμα της πατρίδας
          προσέτρεξαν από τους πρώτους και οι απλοί Έλληνες της Μικρασίας που
          αγωνίσθηκαν μαζί με τους αδελφούς τους του Μοριά, της Ρούμελης και των
          νησιών. Εντάχθηκαν αμέσως στους στρατιωτικούς σχηματισμούς οπλαρχηγών,
          όπως ο Νικηταράς, ο Μακρυγιάννης, ο Κολοκοτρώνης, ο Δημ. Υψηλάντης
          κ.α. και αργότερα συγκρότησαν ιδιαίτερο στρατιωτικό σχηματισμό, την
          Φάλαγγα των Ιώνων.
          <br />
          <br />
          Αμέσως μετά τον ξεσηκωμό του Γένους και λόγω αυτής της συμμετοχής των
          Μικρασιατών σ αυτόν, ακολούθησαν εκδικητικά γεγονότα με σφαγές στις
          πόλεις της Μικράς Ασίας όπου κατοικούσαν Έλληνες. Σε χιλιάδες
          ανέρχονται τα θύματα των σφαγών, ενώ το Αϊβαλί με πληθυσμό 35.000
          κατοίκους πυρπολήθηκε και καταστράφηκε ολοσχερώς από τους Τούρκους,
          όπως λεπτομερώς μας αφηγήθηκε ο προλαλήσας καθηγητής κ. Μιχαηλάρης.{" "}
          <br />
          Ποιος γνωρίζει π.χ. σήμερα, ότι, εκτός από την θυσία των γυναικών στο
          Ζάλογγο και τη Νάουσα , 17 γυναίκες στην Καισάρεια της Καππαδοκίας
          έπεσαν στον ποταμό Σάρρο, για να μην πέσουν στα χέρια των Τούρκων την
          επόμενη της επανάστασης? <br />
          Ποιος γνωρίζει ακόμη για την αρχόντισσα των Κυδωνιών την Πανωραία
          Αϊβαλιώτη που όταν καταστράφηκε ολοσχερώς το Αϊβαλί και έχασε και τα 5
          παιδιά της ήρθε ζητιάνα στο Ναύπλιο και έμεινε με το όνομα
          Ψωροκώσταινα που την αδικεί και η οποία αφιέρωσε τη ζωή της στην
          υπηρεσία της πατρίδος, βοηθώντας τα ορφανά που άφησαν πίσω τους οι
          επιδρομές του Ιμπραήμ? Οι περισσότεροι Μικρασιάτες αγωνιστές του ’21
          κατάγονται από τις Κυδωνίες, τη Σμύρνη, τα Βουρλά και γενικότερα τη
          δυτική Μικρά Ασία, πολλοί όμως ήταν και οι καταγόμενοι από την
          Καππαδοκία και τον Πόντο. Απέκρυπταν τα πραγματικά τους ονόματα, από
          φόβο μήπως ξεσπάσουν αντίποινα κατά των συγγενών τους στη Μικρά Ασία,
          και φρόντιζαν να δηλώνουν ως επώνυμο τον τόπο προέλευσής τους.
          <br />
          Έτσι, έχουμε επίθετα δηλωτικά της πατρίδας τους, όπως π.χ. Σμυρνιός, ή
          Σμυρνιώτης ή Σμυρναίος, Αϊβαλιώτης ή Κυδωνιάτης, Μοσχονησιώτης,
          Βουρλιώτης, Καισαρέας, Τραπεζούντιος, και εκατοντάδες άλλα που δήλωναν
          ότι είναι Μικρασιατικής καταγωγής.
          <br />
          Οι Αϊβαλιώτες ήταν οι πρώτοι που έφτασαν στην κυρίως Ελλάδα.
          Προσχώρησαν αμέσως στις άτακτες στρατιωτικές ομάδες διάφορων
          οπλαρχηγών και καπεταναίων ή συγκρότησαν αυτοτελείς στρατιωτικούς
          σχηματισμούς, αποτελούμενους μόνο από Αϊβαλιώτες, όπως του Χατζή
          Αποστόλη, του Δημ. Καπανδάρου, που, όντας επικεφαλής ενός σώματος
          τριακοσίων Κυδωνιέων, αγωνίστηκε κατά του Δράμαλη, του Κωνσταντίνου
          Αϊβαλιώτη κ.ά. Κυδωνιάτης ήταν επίσης και ο Δημήτριος Μοσχονησιώτης,
          που πρώτος αυτός εκπόρθησε το κάστρο του Παλαμηδίου στις 30 Νοεμβρίου
          του 1822.
          <br />
          Ανάμεσα στους Αϊβαλιώτες ξεχωρίζουν οι ηρωικοί πέντε αδελφοί Πίσσα,
          ένας από τους οποίους, ο Ευστράτιος, στα «Απομνημονεύματά» του
          υπολογίζει τους πεσόντες στις διάφορες μάχες Κυδωνιείς σε
          πέντε-χιλιάδες. <br />
          Μετά τις Κυδωνίες έρχεται η προσφορά της Σμύρνης. Σε αρκετές
          εκατοντάδες, ίσως και σε χιλιάδες, πρέπει να ανέρχονται οι Σμυρναίοι
          αγωνιστές. Μόνο με τα πατριδωνυμικά επίθετα Σμυρναίος, Σμυρνιός,
          Σμυρνιώτης, Σμυρνιωτάκης κ.λπ., ευρέθησαν 184 αγωνιστές, χωρίς να
          υπολογιστούν όσοι άλλοι δήλωσαν το κανονικό τους επίθετο και παράλληλα
          δήλωσαν ως τόπο καταγωγής τους τη Σμύρνη. Και βεβαίως στους Σμυρνιούς
          θα πρέπει να προστεθούν οι Μπουρνοβαλήδες, οι Βουρλιώτες, οι
          Τσεσμελήδες-κ.α. Σμυρνιός ήταν ο Γιαννακός Καρόγλου, ο επικεφαλής της
          Ιωνικής Φάλαγγας, που συγκροτήθηκε αποκλειστικά από Μικρασιάτες και
          αποτέλεσε ένα ανεξάρτητο επαναστατικό σώμα που πολέμησε κατά του
          Ιμπραήμ στην Πελοπόννησο, με τον Καραϊσκάκη στη μάχη της Αράχοβας, και
          αλλού.
          <br />
          Από την Καππαδοκία ήταν ο Χατζησάββας Καισαρέας, Έλληνας που
          εγκατέλειψε το τάγμα των γενίτσαρων, όπου τον είχε κατατάξει το
          παιδομάζωμα, και κατέφυγε στην Ελλάδα για να καταταγεί στο σώμα του
          Πλαπούτα και να ανδραγαθήσει.
          <br />
          Οι Πόντιοι της Τραπεζούντας αντιπροσωπεύονται από ένα σημαντικό αριθμό
          αγωνιστών, που δήλωναν Τραπεζανλήδες, Γκιουμουσχανετζήδες (από την
          Αργυρούπολη) ή γενικώς Καραντενιζλήδες (Μαυροθαλασσίτες).
          <br />
          Τέλος, στους Μικρασιάτες οφείλεται η επιτυχία της πρώτης προσπάθειας
          για δημιουργία ακομμάτιστου εθνικού στρατιωτικού σχηματισμού, που
          έπειτα, βέβαια, από πολλές και κοπιώδεις προσπάθειες κατέληξε στη
          δημιουργία τακτικού στρατού. Η δημιουργία τακτικού Ελληνικού στρατού
          στην επαναστατημένη Ελλάδα δεν θα ήταν τότε δυνατή χωρίς τη συμμετοχή
          των Ελλήνων της Μικράς Ασίας.
          <br />
          Όλους αυτούς πρέπει να τους θυμόμαστε και κάποτε να τους τιμήσουμε
          ξεχωριστά.
          <br />
          Σας ευχαριστώ
          <br />
          <br />
          Πηγές:
          <br />
          1-Η Σμύρνη των Βιβλίων έκδοση Κ.Μ.Σ. Αθήνα 2020.
          <br />
          2-Αδαμάντιος Κοραής Μορφ. Ίδρ. Εθν. Τραπέζης 1986.
          <br />
          3-Ένωση Βουρλιωτών Μ. Ασίας άρθρο στο facebook.
          <br />
          4-Βιβλίο Τάκη Α. Σαλκιτζόγλου έκδοση του ΙΜΕ με θέμα:
          <br />
          «Η Μικρά Ασία στην επανάσταση του 1821 και Η συμβολή των Μικρασιατών
          στον εθνικό αγώνα». <br />
          5-Απομνημονεύματα 1821, στρατηγού Ευστρ. Πίσσα. <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διήμερες εκδηλώσεις 22 και 23 Αυγούστου 2021
        </Typography>
        <img
          src={Image36}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image38}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στον αύλιο χώρο του «Μουσείου Προσφυγικής Μνήμης 1922»
          πραγματοποιήθηκε την Κυριακή 22 Αυγούστου εκδήλωση με δύο θέματα: Το
          πρώτο ήταν ένα σύντομο αφιέρωμα στον προσφυγικό συνοικισμό της Σκάλας
          Λουτρών και το δεύτερο ήταν η παρουσίαση του Λευκώματος των 30 ετών
          του Συλλόγου με τίτλο, «Το Δελφίνι εν τοις έργοις αυτού και οι δράσεις
          του Συλλόγου μέσα από τον Λεσβιακό τύπο». <br />
          Προηγήθηκε η τέλεση πανηγυρικού εσπερινού στο εκκλησάκι της Παναγίας
          από τον ιερέα π. Χρήστο Καλπάκα με επιμνημόσυνη δέηση υπέρ της
          αναπαύσεως των ψυχών των Μικρασιατών προσφύγων της Σκάλας Λουτρών και
          εν συνεχεία όλοι μαζί μεταβήκαμε στον αύλιο χώρο του «Μουσείου
          Προσφυγικής Μνήμης 1922» όπου παρακολουθήσαμε ένα αφιέρωμα στον
          προσφυγικό συνοικισμό της Σκάλας Λουτρών με την προβολή ενός μικρής
          διάρκειας βίντεο με αφηγήσεις προσφύγων πρώτης γενιάς, που συγκίνησε
          βαθύτατα το κοινό. Ακολούθησε η παρουσίαση του λευκώματος που εξέδωσε
          ο Σύλλογος μας με την ευκαιρία της συμπλήρωσης 30 ετών από την ίδρυση
          του το 1990 για το οποίο μίλησαν η συγγραφέας κ. Ειρήνη Βεκρή, η κ.
          Αγλαΐα Αρχοντίδου, αρχαιολόγος και επίτιμη Διευθύντρια του Υπουργείου
          Πολιτισμού και του Μουσείου της Πόλεως των Αθηνών και ο πρόεδρος του
          Συλλόγου μας κ. Δημήτρης Παπαχρυσός.
          <br />
          Ακολούθησαν μικρασιάτικα τραγούδια από την παραδοσιακή ορχήστρα που
          την αποτελούσαν οι: Λέλεκας Έλπις στο τουμπερλέκι, Λέλεκας Μάριος στο
          τζουρά, Κουτσκουδή Μυρσίνη στο σαντούρι και ο Κονσολάκης Χρήστος στην
          κιθάρα. Συμμετοχή στο τραγούδι είχε και η αντιπρόεδρος του Συλλόγου
          μας κ. Νάσια Δαφιώτη η οποία είχε και την ευθύνη του καλωσορίσματος
          και της ροής της εκδήλωσης.
          <br />
          Η βραδιά έκλεισε με το κέρασμα στο κοινό, ενός ποτηριού λευκού κρασιού
          που συνοδευόταν με εξαιρετικούς γκιουσλεμέδες προσφορά από το μέλος
          του Συλλόγου μας κ. Μανώλη Τζανέτο τον οποίο και ευχαριστούμε.
          <br />
          Την επομένη 23 Αυγούστου το πρωί στις 8:00 πραγματοποιήθηκε η
          καθιερωμένη πλέον λειτουργία στην Παναγιά την Αψηλή με την συμμετοχή
          των μελών του Συλλόγου και των κατοίκων της Σκάλας Λουτρών, των
          Λουτρών και του Περάματος Γέρας.
          <br />
          <br />
          Ακολουθεί η παρουσίαση του Λευκώματος από την Συγγραφέα κ. Ειρήνη
          Βεκρή.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Το Δελφίνι εν τοις έργοις αυτού και οι δράσεις του Συλλόγου μέσα από
          τον Λεσβιακό τύπο
        </Typography>
        <img
          src={Image40}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image42}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το Λεύκωμα που εκδόθηκε από τον Σύλλογο Μικρασιατών Σκάλας Λουτρών,
          «Το Δελφίνι» για να γιορτάσει τα 30 χρόνια από την ίδρυσή του, είναι
          ένα μεγάλο και βαρύ βιβλίο. Και σε ύλη, στα 3 κιλά το υπολογίζω, αλλά
          κυρίως σε δράσεις και επιτεύγματα. Γεμάτο από δεκάδες αγώνων και
          προσπαθειών, που οι περισσότερες στέφθηκαν από επιτυχία και
          αναφέρονται, κυρίως, μέσα από τα αποκόμματα του τοπικού τύπου, που
          κάποιος συμμάζευε και φύλαγε προσεκτικά όλα αυτά τα χρόνια. <br />
          Έχει ένα πανέμορφο εξώφυλλο της Παναγιάς της Αψηλής ή
          Προσφυγοπαναγιάς, όπου αμέσως, από την πρώτη σελίδα, αναφέρεται το
          ιστορικό της εκκλησούλας, μαρτυρία της Γεωργίας Τατάκη Μοσχονησιώτη.
          Και μετά από τους χαιρετισμούς των αρχόντων του τόπου, βρίσκουμε το
          καταστατικό του συλλόγου, που έχει ημερομηνία την 11η Νοεμβρίου του
          1990 και το Λεύκωμα τελειώνει με την επίσκεψη της Προέδρου της
          Δημοκρατίας στις 20 Αυγούστου 2020. <br />
          Το διάβασα όλο το βιβλίο. Και επειδή θέλησα να το σχολιάσω με μια
          συγκεκριμένη τάξη το περιεχόμενό του, ξεχώρισα τις δραστηριότητες σε
          βαρύτητα, σύμφωνα με τη δικιά μου ζυγαριά, σε τρεις βασικούς πυλώνες.
          Βρήκα δεκατέσσερις άλλες, εξαιρετικά σημαντικές και επιτυχημένες.
          Αμέσως, μ’ αυτούς τους αριθμούς, με το 3 και το 14, ήρθε στο μυαλό μου
          το γνωστό της γεωμετρίας Π, το 3,14. <br />
          Αλλά και οι τρεις βασικοί πυλώνες, που μου προέκυψαν από σύμπτωση
          αρχίζουν από Π, και είναι:
          <br />
          Περιβάλλον, Παιδί και Προσφυγική Μνήμη.
          <br />
          Θα ξεκινήσω από το <b>Παιδί.</b> <br />
          Ξεχώρισα καθαρά τη διαρκή φροντίδα από το «Δελφίνι» για το παιδί. Στο
          πρώτο κιόλας συμβούλιο αποφασίστηκε ο καλλωπισμός και το βάψιμο της
          παιδικής χαράς, τοποθέτηση μπασκετών στη συνέχεια και δεν παραλείπει
          το Δελφίνι να βραβεύει, σε όλα τα τριάντα χρόνια του, τις άριστες
          σχολικές επιδόσεις. Με επιμονή και εμμονή. Με διαγωνισμούς ζωγραφικής,
          αθλητικούς αγώνες, μαθήματα, γιορτές με παραμύθια και μασκαρέματα.
          Άριστα πράττει. Τα παιδιά κοντά στο σύλλογο. Έτσι οι σύλλογοι δεν
          γερνούν, γεγονός που προβληματίζει σήμερα τους περισσότερους
          πολιτιστικούς συλλόγους. <br />
          Μ’ αυτόν τον τρόπο, με το παιδί και τον νέο κοντά στο Σύλλογο, η
          πολιτιστική κληρονομιά, από τη Μικρασιάτικη <b>Προσφυγική Μνήμη</b>,
          κι έρχομαι τώρα στο δεύτερο βασικό πυλώνα, έχει συνέχεια και διαδοχή.
          Κάθε υλικό ή άυλο που κουβαλάει τις ιστορικές μνήμες και τις αξίες
          τους, είναι γνωστό στο παιδί που μεγαλώνει. Γίνεται φυσικά, με την
          προώθηση από το σύλλογο στα μέλη του και στον ευρύτερο κοινωνικό
          περίγυρο των πολιτιστικών αξιών της παράδοσης από τις μικρασιάτικες
          πατρίδες. Βοηθά ακόμα η παράλληλη ενσωμάτωση αυτών των αξιών στις νέες
          συνθήκες και αξίες που διαμορφώνει η σύγχρονη πραγματικότητα. Το
          παιδί, ο νέος, μεγαλώνει σε ανάλογο περιβάλλον και σιγά σιγά γίνεται
          πρωταρχική η ανάγκη του να διαφυλάξει, να προστατεύσει, να διατηρήσει,
          να διαδώσει και να αξιοποιήσει τις παραδόσεις, τα ήθη και τα έθιμα που
          κουβαλά αφού τα έχει βιώσει.
          <br />Ο τρίτος βασικός πυλώνας που ξεχώρισα στις δραστηριότητες του
          Δελφινιού, είναι βέβαια η φροντίδα για το <b>Περιβάλλον</b>.<br />
          Μεγαλύτερος σε διάρκεια από τον δεκαετή Τρωικό Πόλεμο, ο αγώνας για
          την απομάκρυνση των αποθηκών των πετρελαιοειδών. Ο καθαρισμός της
          θάλασσας από τα βυθισμένα καΐκια, οι δενδροφυτεύσεις και οι συνεχείς
          εξορμήσεις καθαριότητας. Πάρα πολλά έχουν γίνει για το περιβάλλον.
          Μπράβο.
          <br />
          Μετά τους τρεις πυλώνες, θα πω σχεδόν επιγραμματικά τις δεκατέσσερις
          σημαντικές και επιτυχημένες δράσεις που ξεχώρισα. <br />
          1-Μουσείο
          <br />
          2-Βιβλιοθήκη
          <br />
          3-Αμφιθέατρο. Τα τρία πρώτα είναι σημαντικότατα δημιουργήματα του
          συλλόγου, στα οποία η κ. Αρχοντίδου, ως ειδική θα αναφερθεί. Είδαμε
          ήδη και στο βίντεο σκηνές από τα εγκαίνια και άλλες σχετικές
          εκδηλώσεις.
          <br />
          4-Το Περιοδικό «Τα Νέα μας», πάντα καλογραμμένο με ενδιαφέρουσα και
          πρωτότυπη ύλη, κυκλοφόρησε το 26ο φύλλο του. Και θέλω να ομολογήσω πως
          αυτό το περιοδικό ήταν η αιτία να γίνω μέλος του Συλλόγου, έχοντας
          βέβαια το βασικό προσόν, να είμαι Μικρασιάτικης καταγωγής.
          <br />
          5-Τα επιτραπέζια σαν βιβλία, θεματικά Ημερολόγια. Αφιερωμένα σε
          Σμύρνη, Αϊβαλί, Πέργαμο, Τένεδο, το Θεόφιλο, το Μουσείο, τους
          υδροβιότοπους της Λέσβου, τα αποδημητικά πουλιά. Εξαιρετικά.
          <br />
          6-Εκδόσεις ανατυπώσεις σπάνιων βιβλίων, όπως ο Χρυσόστομος Σμύρνης, η
          Ελλάδα στη Μικρά Ασία του Μιχαήλ Ροδά και άλλες. <br />
          7-Κοινωνική Αλληλεγγύη, συμπαράσταση σε κάθε κρίση στο νησί ή αλλού.
          <br />
          8-Κοινωνικές εκδηλώσεις: Πίττες, χοροεσπερίδες, καρναβάλια…
          <br />
          9-Αναβίωση εθίμων: Έθιμα του γάμου, στολισμός της νύφης, γιορτή του
          Αγ. Νικολάου.
          <br />
          10-Μαθήματα για παιδιά – θεατρικό, χορευτικό και μουσικό τμήμα. <br />
          11-Μαθήματα για ενήλικες, κοπτική, ραπτική…
          <br />
          12-Εκδρομές - Προσκυνήματα
          <br />
          13-Αθλητισμός
          <br />
          14-Δικτύωση, με άλλους φορείς όπως το Κέντρο Σπουδής και Ανάδειξης του
          Μικρασιατικού Πολιτισμού, τα περιβαλλοντικά δίκτυα και τα διαδικτυακά.{" "}
          <br />
          Νομίζω πως κάλυψα, σχεδόν όλες τις δραστηριότητες του συλλόγου.
          Υπάρχουν όμως άλλα δύο σημαντικά Π, που θέλω να αναφέρω. Είναι ο
          Πρόεδρος Παπαχρυσός. Σίγουρα αποτελεί τον 4ο πυλώνα και είναι πάντα
          δημιουργικός και ακούραστος.
          <br />
          Τελειώνοντας, θέλω να σας συγχαρώ για το Λεύκωμα που παρουσιάζουμε
          απόψε και κυρίως για τον όμορφο Σύλλογό σας. Να τον προσέχετε και να
          τον καμαρώνετε!
          <br />
          <br />
          Ειρήνη Π. Βεκρή
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ομιλία για το Λεύκωμα του προέδρου του Συλλόγου
        </Typography>
        <img
          src={Image44}
          width="500"
          height="560"
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Καλησπέρα σας και από εμένα, Όπως γνωρίζετε οι περισσότεροι, ο
          Σύλλογος μας πέρυσι συμπλήρωσε αισίως 30 χρόνια λειτουργίας από το
          1990 που ιδρύθηκε μέχρι και το 2020, αφήνοντας ένα σημαντικό αποτύπωμα
          των δράσεων του στη Σκάλα Λουτρών όλα αυτά τα χρόνια.
          <br />
          Αυτό το αποτύπωμα θελήσαμε να μεταφέρουμε μέσα στο λεύκωμα που
          εκδώσαμε, έτσι ώστε, να δώσουμε την δυνατότητα να θυμηθούν από αυτό οι
          παλαιότεροι, αλλά και να διδαχθούν οι νεώτεροι που θα πρέπει κάποια
          στιγμή να παραλάβουν την σκυτάλη από εμάς.
          <br />
          Εγώ θα αναφερθώ εν συντομία σε ορισμένα στατιστικά στοιχεία του
          λευκώματος και εν συνεχεία θα μιλήσουν γι αυτό η συγγραφέας κ. Ειρήνη
          Βεκρή και η αρχαιολόγος, επίτιμη Διευθύντρια του Υπουργείου Πολιτισμού
          και του Μουσείου της πόλεως των Αθηνών κ. Αγλαΐα Αρχοντίδου, τις
          οποίες ευχαριστώ θερμά εκ μέρους του Δ.Σ. του Συλλόγου για την
          συμμετοχή τους σ αυτή την παρουσίαση.
          <br />
          Το λεύκωμα αυτό το οποίο μπορείτε να ξεφυλλίσετε στο τραπέζι που
          εκτίθεται, αποτελείται από 340 έγχρωμες σελίδες, είναι μεγάλου
          μεγέθους 34χ24 cm και είναι σκληρόδετο, όπως ένας τόμος εγκυκλοπαίδεια
          θα έλεγα εγώ.
          <br />
          Αυτό περιέχει τις δράσεις του Συλλόγου χρόνο με το χρόνο, ξεκινώντας
          από το 1990 και φθάνοντας μέχρι και το τέλος του 2020. <br />
          Για κάθε χρόνο αφιερώνονται περίπου 6 με 7 σελίδες από τα αρχεία του
          Συλλόγου και άλλες τόσες από διάφορα δημοσιεύματα όλου του Λεσβιακού
          τύπου που αφορούν τις δράσεις μας. Συνολικά το λεύκωμα περιέχει 657
          φωτογραφίες από τις διάφορες δράσεις και εκδηλώσεις του Συλλόγου όπως
          π.χ. από περιβαλλοντικές δράσεις, από χοροεσπερίδες, από αφιερώματα
          στις αλησμόνητες πατρίδες, από εκδρομές, από τις κατά καιρούς εκδόσεις
          μας, από τις βραβεύσεις των αριστούχων παιδιών και πολλά άλλα. <br />
          Επίσης μέσα στο λεύκωμα αυτό υπάρχουν 581 δημοσιεύματα του Λεσβιακού
          τύπου που αφορούν τον Σύλλογο από τις εφημερίδες ΔΗΜΟΚΡΑΤΗΣ,
          ΔΗΜΟΚΡΑΤΗΣ ΜΥΤΙΛΗΝΗΣ, ΑΙΟΛΙΚΑ ΝΕΑ, ΕΜΠΡΟΣ, ΝΕΟ ΕΜΠΡΟΣ, ΠΟΛΙΤΙΚΑ, ΝΕΑ
          ΠΟΡΕΙΑ, ΜΥΤΙΛΗΝΗ, κ.α., καθώς και δημοσιεύματα του Αθηναϊκού τύπου από
          τις εφημερίδες, ΤΑ ΝΕΑ, ΕΛΕΥΘΕΡΟΤΥΠΙΑ, ΚΑΘΗΜΕΡΙΝΗ, ΕΛΕΥΘΕΡΟ ΤΥΠΟ κ.α.
          <br />
          Κλείνοντας θέλω να ευχαριστήσω όλους όσους βοήθησαν στο να γίνει
          εφικτή η εκτύπωση του λευκώματος και ιδιαιτέρως θα αναφερθώ σε δύο
          μέλη μας, την κ. Δεσπότου Έλλη η οποία συμμετείχε με το ποσό των 2000
          ευρώ στη μνήμη του πατέρα της από το Αϊβαλί και τον κ. Μανώλη Τζανέτο
          που και αυτός συμμετείχε με το ίδιο ποσό στη μνήμη του πατέρα του
          Παναγιώτη. Από καρδιάς τους ευχαριστούμε. <br />
          Το Λεύκωμα προσφέρετε σήμερα για όσους θέλουν να το αγοράσουν στην
          προνομιακή τιμή των 20 ευρώ, που είναι η τιμή του κόστους της
          εκτύπωσης.
          <br />
          Όμως πριν κλείσω την σύντομη αυτή παρουσίαση, θα ήθελα να κάνω μια
          αναφορά στα ονόματα όλων αυτών που συμμετείχαν στα εκάστοτε Δ.Σ. του
          Συλλόγου.
          <br />
          Στον Σύλλογο αρχαιρεσίες έγιναν 16 φορές (που σημαίνει μία ανά διετία
          όπως προβλέπετε στο καταστατικό) και σε αυτές εκλέχτηκαν οι παρακάτω
          κατ αλφαβητική σειρά μαζί με τα χρόνια που ο καθένας υπηρετούσε:
          <br />
          <br />
          Βαγιάνης Γεώργιος 2 χρόνια <br />
          Βαλαχής Παναγιώτης 16 χρόνια
          <br />
          Βέντου Δώρα 12 χρόνια
          <br />
          Γρηγορέλλης Ευάγγελος 2 χρόνια
          <br />
          Γρηγορέλλη Κατερίνα 22 χρόνια
          <br />
          Δασκάλου Ευστρατία 2 χρόνια
          <br />
          Δασκάλου Μαρία 8 χρόνια
          <br />
          Δαφιώτη Νάσια 8 χρόνια
          <br />
          Ευαγγέλου Βικτωρίτσα 12 χρόνια
          <br />
          Καράμπελας Ιωάννης 2 χρόνιαv
          <br />
          Κρητικός Βασίλης 2 χρόνια
          <br />
          Κρητικός Παναγιώτης 8 χρόνια
          <br />
          Λημναίου Βαγγελίτσα 4 χρόνια
          <br />
          Μαμώλης Βασίλης του Μαρ. 2 χρόνια
          <br />
          Μεταξά Φιλίτσα 2 χρόνια
          <br />
          Παλάσης Βασίλης 2 χρόνια
          <br />
          Παλάση Ταξούλα 2 χρόνια
          <br />
          Παλκάνικου Τζένη 6 χρόνια
          <br />
          Παπαμακαρίου Γεωργία 4 χρόνια
          <br />
          Παπαμακαρίου Ντόρα 4 χρόνια
          <br />
          Παπαχρυσός Δημήτρης 32 χρόνια
          <br />
          Σταματέλλη Αγγελική 8 χρόνια
          <br />
          Σταματέλλη Ειρήνη 6 χρόνια
          <br />
          Σταματέλλη Μαρία 6 χρόνια
          <br />
          Σταματέλλη Ρένα 14 χρόνια
          <br />
          Τζανέτος Μανώλης 6 χρόνια
          <br />
          Τσομάκη Μαρία 4 χρόνια
          <br />
          Τουρλή Ευθαλία 12 χρόνια
          <br />
          Τουρλής Μιχάλης 8 χρόνια και
          <br />
          Χιωτέλλης Χαράλαμπος 6 χρόνια
          <br />
          <br />
          Επίσης στο πρώτο προσωρινό Δ.Σ. του Συλλόγου το οποίο λειτούργησε για
          λίγο διάστημα ώσπου να γίνουν οι πρώτες αρχαιρεσίες συμμετείχαν οι:
          <br />
          Καράμπελας Ιωάννης <br />
          Κερεντζής Νικόλαος
          <br />
          Κοντούλης Θεοφάνης
          <br />
          Κρητικός Παναγιώτης
          <br />
          Παπαμακαρίου Γεωργία
          <br />
          Παπαχρυσός Δημήτρης και <br />
          Τσεσμελή Μαρία.
          <br />
          <br />
          Όλους αυτούς που προανέφερα θέλω να τους ευχαριστήσω για την συμβολή
          τους σε όλα όσα κάναμε, άλλος περισσότερο και άλλος λιγότερο, ο
          καθένας στο μέτρο των δυνατοτήτων του, όλοι έχουν βάλει το λιθαράκι
          τους.
          <br />
          Ακόμη θέλω να ευχαριστήσω και όλους τους αφανείς ήρωες οι οποίοι
          βοήθησαν χωρίς να είναι στο Δ.Σ. και ένας από αυτούς ο σημαντικότερος
          ίσως είναι ο δάσκαλος ο Σταύρος Βαλαχής που χωρίς αυτόν το Μουσείο που
          φτιάξαμε δεν θα είχε την αξία που έχει σήμερα.
          <br />
          <br />
          Σας ευχαριστώ
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Για την έκδοση του Λευκώματος 30 ετών του Συλλόγου πρόσφεραν
        </Typography>
        <img
          src={Image46}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Η κ. Έλλη Δεσπότου 2000 ευρώ στην μνήμη του πατέρα της Γεωργίου
          Δεσπότη ο οποίος γεννήθηκε στο Δικελί της Μικράς Ασίας όπου τελείωσε
          και το δημοτικό σχολείο. Στην συνέχεια μεταφερθήκανε οικογενειακώς στο
          Αϊβαλί όπου εκεί τελείωσε το σχολαρχείο. Ήταν το τέταρτο παιδί μιας
          οικογένειας με εφτά παιδιά (πέντε αγόρια και δύο κορίτσια).
          <br />
          Μετά την Μικρασιατική καταστροφή μικρό παιδί ακόμα, πέρασε με την
          οικογένεια του στην Λέσβο και συγκεκριμένα στο Πλωμάρι όπου τελείωσε
          το γυμνάσιο. Μετά από μερικά χρόνια γνώρισε στην Μυτιλήνη την
          Ευαγγελία Βαγάραλη την οποία παντρεύτηκε. Αργότερα έφυγαν μαζί από την
          Λέσβο και πήγαν στην Αθήνα. Πολύ σύντομα αποδέχτηκε πρόσκληση της
          αδελφής του –η οποία ήταν παντρεμένη με έναν Τσέχο- και πήγε μαζί με
          τη γυναίκα του στην Τσεχοσλοβακία (σημερινή Τσεχία), στην πόλη Ντέτσιν
          όπου ασχολήθηκε επαγγελματικά με το σχέδιο.
          <br />
          Μετά την λήξη του 2ου παγκοσμίου πολέμου έφυγαν με την πρώτη τους κόρη
          Αναστασία η οποία είχε γεννηθεί στην Τσεχία και επέστρεψαν στην Ελλάδα
          και συγκεκριμένα στην Αθήνα. Στην Αθήνα απέκτησε και τις άλλες δύο
          κόρες του την Νεκταρία και την Έλλη.
          <br />
          Ένα πολύ σημαντικό γεγονός της ζωής του ήταν η γνωριμία του στο Αϊβαλί
          με τον Φώτη Κόντογλου τον οποίο ξανασυνάντησε στη Μυτιλήνη καθώς
          επίσης και στην Αθήνα ακόμη και μετά την επιστροφή του από την Τσεχία.
          <br />
          Από εκεί ψηλά που βρίσκεται θα νιώθει πολύ όμορφα για την βοήθεια που
          πρόσφερε η κόρη του Έλλη ώστε να εκδοθεί αυτό το λεύκωμα—μια πολύ
          σημαντική προσπάθεια του Συλλόγου Μικρασιατών Σκάλας Λουτρών για την
          δημιουργία ενός τόσο σπουδαίου έργου. <br />
          Το Δ.Σ. του Συλλόγου, ευχαριστεί την κ. Έλλη Δεσπότου για την προσφορά
          της στην έκδοση του Λευκώματος. <br />
        </Typography>

        <img
          src={Image48}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image50}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο κ. Μανώλης Τζανέτος 2000 ευρώ στην μνήμη του πατέρα του Παναγιώτη
          Τζανέτου, ο οποίος κατά το παρελθόν οραματίστηκε την αναστήλωση ενός
          παλαιού και επιβλητικού ελαιοτριβείου χτισμένο το 1909 στη Σκάλα
          Λουτρών, μετατρέποντας το σε πολυχώρο φιλοξενίας και πολιτισμού το
          1986 με την ονομασία «Ζαϊρα».
          <br />
          Μέσα στον καταπράσινο ελαιώνα, γύρω από το ξενοδοχείο «Ζαϊρα», που
          τελειώνει εκεί όπου αρχίζει η θάλασσα, η ιστορία ενώνεται με την φύση,
          η παράδοση με την πολυτέλεια και το παρελθόν με το μέλλον όταν, το
          2008 η δεύτερη γενιά πρωτοπορεί με τη δημιουργία του κτήματος «Ελιά
          και Θάλασσα», ενσωματώνοντας ολιστικά τις εγκαταστάσεις του στον
          περιβάλλοντα χώρο δίνοντας έμφαση στο φυσικό τοπίο, την ελιά, τη
          θάλασσα, την πέτρα και έκτοτε γράφει την δική του ιστορία φιλοξενώντας
          τις ξεχωριστές στιγμές που μοιράζονται οικογένειες, φίλοι και
          συνεργάτες.
          <br />
          Σήμερα αυτός ο χώρος αποτελεί σημείο αναφοράς για κοινωνικές
          εκδηλώσεις, συναντήσεις, πάρτυ, συναυλίες και συνέδρια που
          φιλοξενούνται στο εντυπωσιακό κτήμα «Ελιά και Θάλασσα», στην
          κινηματογραφική αυλή του ξενοδοχείου Ζαΐρα και στις αίθουσες του
          ελαιοτριβείου. <br />
          Το Δ.Σ. του Συλλόγου, ευχαριστεί τον κ. Μανώλη Τζανέτο για την
          προσφορά του στην έκδοση του Λευκώματος, ο οποίος υπήρξε κατά το
          παρελθόν και μέλος του Δ.Σ. <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ημέρα μνήμης της γενοκτονίας των Ελλήνων της Μικράς Ασίας
        </Typography>
        <img src={Image52}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }} alt="Δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκαν και φέτος, μέσα σε κλίμα συγκίνησης, οι εκδηλώσεις
          τιμής και μνήμης για την γενοκτονία των Ελλήνων της Μικράς Ασίας.
          Σύμφωνα με τις διατάξεις του Προεδρικού Διατάγματος 304/2001, η 14η
          Σεπτεμβρίου ορίζεται ως ημέρα Εθνικής μνήμης της γενοκτονίας των
          Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος. Η επέτειος μνήμης
          έχει σκοπό να τονίσει ιδιαίτερα και να υπενθυμίσει τα γεγονότα της
          δραματικής περιόδου της γενοκτονίας και του ξεριζωμού των Ελλήνων της
          Μικράς Ασίας από το Τουρκικό κράτος, που είχαν ως αποτέλεσμα να
          μετατρέψουν τους Έλληνες της Μικράς Ασίας σε έναν λαό προσφύγων της
          διασποράς.
          <br />
          - Το πρόγραμμα εκδηλώσεων αυτής της επετείου μνήμης πραγματοποιήθηκε
          την Κυριακή 19 Σεπτεμβρίου 2021 και περιελάμβανε:
          <br />
          * Τέλεση δοξολογίας και αρτοκλασίας στον Ιερό Ναό Αγίου Νικολάου στην
          Επάνω Σκάλα.
          <br />
          *Τον πανηγυρικό της ημέρας εκφώνησε, η φιλόλογος κ. Αναγνώστου.
          <br />
          *Επιμνημόσυνη δέηση, με τη συμμετοχή της Στρατιωτικής μουσικής της 98
          ΑΔΤΕ και κατάθεση στεφάνων στο χώρο του αγάλματος της Μικρασιάτισσας
          Μάνας, από εκπροσώπους της Κυβέρνησης, της Περιφέρειας Βορείου
          Αιγαίου, του Δήμου Λέσβου, του Στρατιωτικού Διοικητή, τους εκπροσώπους
          Πολιτικών Κομμάτων, αντιπροσωπείες των Απόστρατων Αξιωματικών,
          εκπροσώπους των Εφεδροπολεμιστικών Οργανώσεων, εκπροσώπους νομικών
          προσώπων και Συλλόγων.
          <br />
          Το Δ.Σ. του Συλλόγου μας, όπως κάθε χρόνο, παρευρέθη στις
          προαναφερθείσες εκδηλώσεις μνήμης, με κατάθεση στεφάνου από τον
          πρόεδρο κ. Δ. Παπαχρυσό.
        </Typography>
      </Box>


      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εκδήλωση μνήμης και στη Σκάλα Λουτρών
        </Typography>
        <img
          src={Image54}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Για πρώτη φορά φέτος, εκδηλώσεις μνήμης πραγματοποιήθηκαν και στη
          Σκάλα Λουτρών στο μνημείο «Μικρά Ασία πατρίδα της ψυχής μας» την Τρίτη
          14 Σεπτεμβρίου, με την συμμετοχή του Δημοτικού σχολείου Λουτρών, της
          κοινότητας Λουτρών, του Συλλόγου μας και πολλών κατοίκων. Η εκδήλωση
          περιελάμβανε ομιλίες σχετικές με την γενοκτονία των Ελλήνων της Μικράς
          Ασίας καθώς και καταθέσεις στεφάνων.
          <br />
          Για το όνειρο που έσβησε, την κόλαση, τη φρίκη, τη σφαγή ανδρών,
          γυναικών και βρεφών, την εξόντωση, το ολοκαύτωμα που ακολούθησε, έχουν
          μιλήσει οι μάρτυρες, -κυριολεκτικά και μεταφορικά μάρτυρες- που το
          έζησαν, έχουν καταθέσει την αλήθεια τους για τους ανθρώπους τους που
          είδαν να ατιμάζονται και να μετατρέπονται σε κουρέλια μπροστά στα
          μάτια τους. Οι περιγραφές τους είναι ανατριχιαστικές, γεννούν
          αποτροπιασμό και κάνουν το νου να σαλεύει, γιατί ενσαρκώνουν τους
          στίχους του Σολωμού :<br />
          <br />
          Παντού φόβος και τρομάρα, και φωνές και στεναγμοί
          <br />
          Παντού κλάψα, παντού αντάρα και παντού ξεψυχισμοί...
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αντικατάσταση Πινακίδων σήμανσης
        </Typography>
        <img
          src={Image56}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Σε αντικατάσταση των πινακίδων που οδηγούν προς το «Μουσείο
          Προσφυγικής Μνήμης 1922» , προς την Βιβλιοθήκη του Συλλόγου «Πέτρος
          Μάνδρας» και προς την «Παναγιά την Αψηλή», προχώρησε ο Σύλλογος μας
          λόγω φθοράς των υφισταμένων που είχαν τοποθετηθεί προ δεκαπενταετίας.
          Επίσης νέες πινακίδες τοποθετήθηκαν στην βρύση του Συλλόγου για να μην
          πλένουν αυτοκίνητα και βάρκες μπροστά στην είσοδο της παιδικής χαράς
          όπως και στον πίνακα ελέγχου της ΔΕΗ που βρίσκετε μέσα στην παιδική
          χαρά και εγκυμονούσε κινδύνους τόσο στα παιδιά όσο και στους
          επισκέπτες.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Μουσικό τμήμα
        </Typography>
        <img
          src={Image58}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Μετά από την διακοπή λόγω COVID-19 και κορωνοϊού επιτρέποντος, θα
          επαναλειτουργήσει το μουσικό τμήμα από το νέο έτος. Τα μαθήματα θα
          γίνονται στην Βιβλιοθήκη του Συλλόγου κάθε Σάββατο, από τις 12 το
          μεσημέρι μέχρι τις 5 το απόγευμα. Για περισσότερες πληροφορίες
          μπορείτε να έρθετε σε επικοινωνία με τον πρόεδρο του Συλλόγου κ.
          Δημήτρη Παπαχρυσό στο τηλ. 69746.40419. Δάσκαλος είναι ο κ. Μολυβιάτης
          Γρηγόρης ο οποίος επί πολλά χρόνια έχει προσφέρει εξαιρετικό έργο.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Νέα τμήματα 
          </Typography>
          <Typography variant="h3" sx={theme.typography.typography2}> 
          Ο Σύλλογός μας μπορεί να ιδρύσει νέα τμήματα παραδοσιακών
          μουσικών οργάνων όπως π.χ. βιολί, λαούτο κτλ εφ όσον εκδηλωθεί σχετικό
          ενδιαφέρον από τα μέλη μας. Διευκρινίζουμε ότι για να λειτουργήσει ένα
          νέο τμήμα θα πρέπει να ενδιαφερθούν κατ ελάχιστο τρία άτομα.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τμήματα παραδοσιακών τραγουδιών και χορών
        </Typography>
        <img
          src={Image60}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

    
        <Typography variant="h3" sx={theme.typography.typography2}>
          Από το νέο έτος και κορωνοϊού επιτρέποντος, θα ξεκινήσουν να
          λειτουργούν τμήματα παραδοσιακών χορών με δασκάλα την κ. Φλώρα
          Αντωνοπούλου και όσοι ενδιαφέρονται μικροί και μεγάλοι μπορούν να
          έλθουν σε συνεννόηση με το Δ.Σ. του Συλλόγου η με τον κ. Δημήτρη
          Παπαχρυσό στο τηλ. 69746.40419.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τμήμα βιβλιοθήκης
        </Typography>
        <img
          src={Image62}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

       
        <Typography variant="h3" sx={theme.typography.typography2}>
          Με πλέον των 8.500 περίπου τίτλους βιβλίων είναι εμπλουτισμένη η
          βιβλιοθήκη του Συλλόγου μας τα οποία είναι διαθέσιμα για να δανειστούν
          στα μέλη μας, αλλά και σε όσους άλλους ενδιαφέρονται. Όλα τα βιβλία
          είναι καταχωρημένα σε ηλεκτρονικό υπολογιστή για την εύκολη διαχείριση
          τους.
          <br />
          Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει
          τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση
          είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα
          προβλήματα που αποτελούν τη ζωή του σύγχρονου ανθρώπου και τοποθετεί
          τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη
          διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και
          φανατισμούς.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Δωρεά βιβλίων στο Σύλλογό μας
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους κ.κ. Μέλλο Σταύρο, Πελεκάνο Θεόδωρο, Θωμαίδου
          Μαρία, Παπαναστασίου Μιχάλη, Τσομάκη Μαρία, και Ροδάκη Δημήτρη για τα
          βιβλία που πρόσφεραν στην βιβλιοθήκη του Συλλόγου μας. <br />
          Επίσης ευχαριστούμε τον κ. Χασλαρίδη Δημήτρη, ο οποίος εκτός από τα
          πολλά και αξιόλογα βιβλία που μας έστειλε από την Αθήνα μας έδωσε και
          ορισμένα κειμήλια του πατέρα του με σκοπό να εκτεθούν ορισμένα από
          αυτά στην υπό απαλλοτρίωση από το Δήμο Μυτιλήνης «Προσφυγική Οικία».
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ευχαριστήρια
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους παρακάτω:
          <br />
          *Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο
          μας.
          <br />
          *Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες
          συντήρησης των κτιρίων του Συλλόγου, καθώς και όσους προσφέρουν
          εθελοντικώς την εργασία τους.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Οικονομικές ενισχύσεις
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά:
          <br />
          <br />
          Σταύρος και Βίκυ Κρητικού 601 ευρώ (1000 δολ. Αυστραλίας)
          <br />
          Γρημάνη Μυρσίνη 100 ευρώ
          <br />
          Χατζηδήμος Αλέξανδρος 100+50 ευρώ
          <br />
          Κινικλή Τούλα 50 ευρώ
          <br />
          Δεσπότου Έλλη 150 ευρώ, στη μνήμη της μητέρας της Ευαγγελίας.
          <br />
          Π. Κλήμη, Ο. Καμινάρη 100 ευρώ, στη μνήμη Αριστείδη και Μερόπης Κλήμη
          <br />
          Ευσταθίου Δημήτρης 20 ευρώ
          <br />
          Ιωάννα Τζανουδάκη 50+50 ευρώ
          <br />
          Καλαθάς Γρηγόρης, Αϊβαλιώτη Χρυσούλα 100 ευρώ
          <br />
          Παυλάκος Γεώργιος 50 ευρώ
          <br />
          Μιχαρικόπουλος Παναγιώτης 50 ευρώ
          <br />
          Φωτεινός Γεώργιος 10 ευρώ
          <br />
          Αμαρτωλού Ακατερίνη 10 ευρώ
          <br />
          Νταλαμάγκας Π. 10 ευρώ
          <br />
          Ανναλένα Καράμπελα 10 ευρώ
          <br />
          Άγνωστος 7 ευρώ
          <br />
          Jones Robert 20 ευρώ <br />
          Grup NIBAK 15 ευρώ
          <br />
          MIDEN QOEN 10 ευρώ
          <br />
          Παπαναστασίου Μιχάλης 50 ευρώ στη μνήμη Μαρίκας Βοστάνη το γένος Γ.
          Γεωργαλά από το Αϊβαλί.
          <br />
          Κωνσταντινίδου Ευδοξία 20 ευρώ
          <br />
          Παπαχρυσός Ιωάννης 50 ευρώv
          <br />
          Ανώνυμος 10 ευρώ
          <br />
          Ανώνυμος 5 ευρώ
          <br />
          <br />
          Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του
          να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός για
          αναβάθμιση του οικισμού μας , έτσι ώστε να μπορέσουμε να
          ανταποκριθούμε στο βαρύ φορτίο των υποχρεώσεων που έχουμε αναλάβει.
          <br />
          Για καταθέσεις συνδρομών και ενισχύσεων στο Σύλλογο Τράπεζα Πειραιώς
          Αριθμός Λογαριασμού 6355-010482-750 IBAN GR94 0171 3550 0063 5501 0482
          750 <br />
          <br />
          Ευχαριστούμε επίσης την Π.Β.Α. και ιδιαίτερα την αντιπεριφερειάρχη κ.
          Αντωνέλλη για την επιχορήγηση ορισμένων εκδηλώσεων του Συλλόγου μας με
          το ποσό των 2500 ευρώ.
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default D18_drastiriotites_2021;
