import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";


import Image01 from '../assets/images/oikologika/18sinedriopandoiko_01.jpg'
import Image02 from '../assets/images/oikologika/17sinedriopandoiko_02.jpg'

function F4_oikologika_04() {

    const theme = useTheme();


  return (
    <>

     
<IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="18ο συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
      <Typography variant="h1" sx={theme.typography.typography1}>&nbsp;</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>ΒΕΡΟΙΑ 3-5 Νοεμβρίου 2006.</Typography>
	  <Typography variant="h1" sx={theme.typography.typography1}>ΘΕΜΑ:  «Κοινωνική Περιβαλλοντική Ευθύνη και Απασχόληση»</Typography>	
		<br/><br/><br/>
      <Typography variant="h1" sx={theme.typography.typography1}>Απόφαση συνεδρίου</Typography>
		
		<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

		<Typography variant="h3" sx={theme.typography.typography2}>Το 18ο Πανελλήνιο Συνέδριο του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ) διοργανώθηκε στη Βέροια (3-5/11/2006) σε συνεργασία με την ΚΕΔΚΕ, τη 
		ΓΣΕΕ και το Δήμο Βέροιας. Το θέμα του Συνεδρίου ήταν η «Κοινωνική Περιβαλλοντική Ευθύνη και Απασχόληση» στο πλαίσιο της Εταιρικής Κοινωνικής Ευθύνης (ΕΚΕ). 
		Η ΕΚΕ είναι εθελοντική ανάληψη ευθύνης από τις επιχειρήσεις για την κοινωνία και το περιβάλλον.<br/>
		Είναι ένα νέο πλαίσιο μέσα στο οποίο όλα τα ενδιαφερόμενα μέρη έχουν ένα σημαντικό ρόλο να παίξουν, με απώτερο στόχο τη δέσμευση για δημιουργία  συμπράξεων 
		αμοιβαίου οφέλους.<br/>
		Ειδικότερα στο τομέα του φυσικού περιβάλλοντος και της προστασίας του, οι συμμετέχοντες στο 18ο Συνέδριο του ΠΑΝΔΟΙΚΟ συμφώνησαν στην αναγκαιότητα διάδοσης 
		ορθών πρακτικών ΕΚΕ. Γι’ αυτό εκφράστηκε η ελπίδα οι ιδέες και οι προτάσεις που προέκυψαν να αποτελέσουν βάση για περαιτέρω διάλογο και διαβούλευση όπως:
		<br/><br/>
		<b>1.</b>    Ανάληψη εκ μέρους των επιχειρήσεων δράσεων για τη προστασία του εργασιακού (υγεία και ασφάλεια) και ευρύτερου περιβάλλοντος.<br/><br/>
		<b>2.</b>    Δημιουργία κοινά αποδεκτών εργαλείων μέτρησης και αξιολόγησης περιβαλλοντικής συμπεριφοράς.<br/><br/>
		<b>3.</b>    Διαβούλευση με τους ενδιαφερομένους, την κοινωνία και τις δημόσιες αρχές, για την ανάληψη πρωτοβουλιών για την προστασία του περιβάλλοντος.<br/><br/>
		<b>4.</b>    Δημιουργία εκπαιδευτικού και ενημερωτικού υλικού για την ΕΚΕ και το περιβάλλον.<br/><br/>
		<b>5.</b>    Παροχή κινήτρων προς όλα τα ενδιαφερόμενα μέρη για βελτίωση των περιβαλλοντικών και των κοινωνικών επιδόσεών τους.<br/><br/>
		<b>6.</b>    Ανάπτυξη πρωτοβουλιών συντονισμού για εφαρμογή και διάδοση κοινών δράσεων ΕΚΕ.<br/><br/>
		<b>7.</b>    Δημιουργία Ακαδημίας Υπευθυνότητας.<br/><br/>
		<b>8.</b>    Στήριξη δράσεων ΕΚΕ μέσα από τις προμήθειες του δημόσιου τομέα με κριτήρια κοινωνικής και περιβαλλοντικής επίδοσης και με ανάπτυξη ολοκληρωμένης δημόσιας 
		πολιτικής.<br/><br/>
		<b>9.</b>    Δημιουργία θέσεων απασχόλησης στο τομέα του περιβάλλοντος με την υποστήριξη των δράσεων ΕΚΕ και ανάπτυξη της πράσινης επιχειρηματικότητας όπως στους τομείς 
		της ανακύκλωσης, των ανανεώσιμων πηγών ενέργειας, του οικοτουρισμού κοκ.<br/><br/>
		<b>10.</b>    Η αυτοδιοίκηση ως βασικός κοινωνικός εταίρος μπορεί να αναλάβει πρωτοβουλίες και να συντονίσει δράσεις και προγράμματα που αποσκοπούν στην προστασία του 
		περιβάλλοντος και τη δημιουργία πράσινων θέσεων απασχόλησης σε τοπικό, περιφερειακό και διακρατικό  επίπεδο και σε τομείς όπως η διαχείριση απορριμμάτων, οι 
		αστικές αναπλάσεις, οι ανανεώσιμες πηγές ενέργειας, ο εναλλακτικός τουρισμός, η κοινωνική επιχειρηματικότητα κ.α.</Typography>

		<Typography variant="h3" sx={theme.typography.typography2}>Ο διάλογος γύρω από αυτά τα ζητήματα και ο ρόλος των ΜΚΟ δεν τελειώνει με το παρόν Συνέδριο αλλά ανοίγει στους εταίρους και συμμετέχοντες 
		και ευρύτερα στην κοινωνία.</Typography>

		</Box>
	
		<ScrollToTopButton />
    
    
    
    </>
  )
}

export default F4_oikologika_04