export const headerBoxStyles = {
    marginBottom: '3em',
    borderRadius: 8,
    backgroundColor: '#eeeeee',
    padding:'15px'
    

};


export const commonBoxStyles = {
    backgroundColor: '#eeeeee',
    borderRadius: 8,
    padding: '15px',
    textAlign: 'center',
};