import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g20/image002.jpg";
import Image02 from "../assets/images/mikrasiatika_g20/image004.jpg";
import Image03 from "../assets/images/mikrasiatika_g20/image006.jpg";

function G20_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Η αρχαιολογική υπηρεσία στη Μικρά Ασία" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Δρ. Μαρίας Σάλτα Αρχαιολόγου
        </Typography>
        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Μια ιδιαίτερη περίπτωση για την γενικότερη Ιστορία της Αρχαιολογικής
          Υπηρεσίας συνιστά ή εποχή του πολέμου του 1919-1922, όταν στα πλαίσια
          τής ίδρυσης Ελληνικής Διοίκησης με έδρα τη Σμύρνη, εγκαθίστανται στη
          Μικρά Ασία και Έλληνες αρχαιολόγοι με σκοπό την φροντίδα των
          εγκαταλελειμμένων λόγω των εχθροπραξιών αρχαιολογικών χώρων, την
          περισυλλογή, συντήρηση και φύλαξη των αρχαιοτήτων καθώς και την
          διενέργεια περιορισμένου αριθμού ανασκαφών.
          <br />
          Επικεφαλής του «Τμήματος Αρχαιοτήτων» τής Ελληνικής Διοίκησης Σμύρνης
          τοποθετείται έως τον Νοέμβριο του 1920 ο τότε έφορος αρχαιοτήτων
          Γεώργιος Οικονόμου, ενώ από τον Ιανουάριο του 1921 έως και τον
          Σεπτέμβριο του 1922 αποσπάσθηκε στη θέση αυτή ο Κωνσταντίνος
          Κουρουνιώτης, με βοηθό τόν επιμελητή του Εθνικού Αρχαιολογικού
          Μουσείου Νικόλαο Λάσκαρι. Συνεργάτης του Κουρουνιώτη στή Μικρά Ασία
          υπήρξε και ο αρχαιολόγος Στρ. Παρασκευαϊδης.
          <br />
          Πληροφορίες για τη στελέχωση και το έργο του νέου αυτού τμήματος τής
          Αρχαιολογικής Υπηρεσίας, προσφέρει ο έβδομος τόμος του περιοδικού
          «Αρχαιολογικόν Δελτίον», πού εκδόθηκε το 1924 από το αρμόδιο
          «Υπουργείον των Εκκλησιαστικών και τής Δημοσίας Εκπαιδεύσεως», με τόν
          τίτλο «Ελληνικαί ανασκαφαί εν Μικρά Ασία».
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στο ειδικευμένο προσωπικό τής Αρχαιολογικής Υπηρεσίας στή Μ. Ασία
          περιλαμβάνονται, εκτός από τους αρχαιολόγους, ο φωτογράφος Α.
          Πετρίτσης και ο Αρμένιος αρχιτέκτονας Μιθριδάτης Καρακότσης πού
          δραστηριοποιήθηκε κυρίως στην ’Έφεσο, «αποσπασθείς εις την
          αρχαιολογικήν υπηρεσίαν Μικράς Ασίας από του τμήματος Δημοσίων έργων
          τής Ελληνικής Διοικήσεως».
          <br />
          Στον ίδιο τόμο ειδική μνεία γίνεται σε διορισθέντες φύλακες
          αρχαιοτήτων και τούς επιβλέποντες τους, όπως στην Έφεσο ο δάσκαλος τής
          κοινότητας Άγγελος Αλιβιζάτος και ο «αρχαιόφιλος Περγαμηνός» Αρτέμ.
          Κιοσκλής στην Πέργαμο.
          <br />
          Στήν ύλη του περιοδικού περιλαμβάνονται άρθρα του Κουρουνιώτη πού
          αφορούν ανασκαφές του στή Νύσα επί Μαιάνδρω και στή γειτονική αρχαία
          πόλη Μάσταυρα, του Γ. Σωτηρίου με θέμα τήν ανασκαφή του Βυζαντινού
          Ναού Ιωάννου του Θεολόγου στην "Έφεσο και του Γ. Οικονόμου με θέμα τα
          αξιώματα των Ναοποιών και των Εσσηνών, με αφορμή επιγραφή από τήν
          Έφεσο.
          <br />
          Στο Παράρτημα τού ίδιου τόμου υπάρχει έκθεση του Κ. Κουρουνιώτη πού
          άφορά «Το έργον τής Ελληνικής Αρχαιολογικής Υπηρεσίας εν Μικρά Ασία».
          Σε αυτό γίνεται λόγος για τήν συντήρηση των αρχαιοτήτων και τα
          προβλήματα επιμέρους αρχαιολογικών χώρων (’Έφεσο, Πέργαμο, Σάρδεις,
          Πριήνη), τήν αρχαιολογική συλλογή τής Ευαγγελικής σχολής στή Σμύρνη,
          καθώς και τήν περισυλλογή αρχαίων.
          <br />
          Ιδιαίτερα όσον άφορά στή συντήρηση αρχαιολογικών χώρων και μουσείων ο
          Κουρουνιώτης γράφει: «Κατά τήν διάρκειαν του μεγάλου πολέμου οι
          Τούρκοι εγκατέλιπον αφύλακτα εις τήν τύχην αυτών όχι μόνον τα
          μεμονωμένως υπάρχοντα εις διαφόρους πόλεις αρχαία ερείπια, άλλά και
          τας συστηματικώς υπό ξένων αρχαιολόγων άνεσκαμμένας αρχαίας πόλεις,
          αίτινες μέχρι τότε έφυλάσσοντο οπωσούν καλώς χάριν εις τας φροντίδας
          των ξένων. Ένεκα τής παραμελήσεως ταύτης και ή φύσις και οι άνθρωποι
          είχον επιφέρει σπουδαίας βλάβας εις τα μνημεία και τούς αρχαιολογικούς
          χώρους». Και συνεχίζει: «Εν Εφέσω αι εν τη αγορά υπό των Αυστριακών
          αποτελεσθεϊσαι αρχαιολογικαί συλλογαί κατεστράφησαν και ελεηλατήθησαν
          ... προς τούτοις είχον αφαιρεθή εκ τού χώρου των ανασκαφών κίονες και
          άλλα σπουδαία αρχιτεκτονικά μάρμαρα και είχον μεταφερθή εις Σμύρνην
          κατά διαταγήν του διοικητού ταύτης, όπως χρησιμεύσωσιν εις τήν
          οικοδομήν δημοσίου κτιρίου».
        </Typography>

        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Είναι χρήσιμο να αναφέρουμε εδώ, ότι οι ανασκαφές στό Αρτεμίσιο τής
          Εφέσου ήταν υπό αυστριακή εποπτεία, στην Πέργαμο και Πριήνη υπό
          γερμανική.
          <br />
          Ειδική μνεία γίνεται σε ανασκαφές ξένων αρχαιολογικών ιδρυμάτων, οι
          οποίες έγιναν «αδεία τής Ελληνικής Διοικήσεως εν Κολοφώνι υπό των
          Αμερικανών τής εν Αθήναις αρχαιολογικής Σχολής, εν Νοτίω υπό τής εν
          Αθήναις Γαλλικής αρχαιολογικής Σχολής, εις τας Σάρδεις υπό τής
          Αμερικανικής Εταιρείας προς ανασκαφήν των Σάρδεων». Στίς ξένες αυτές
          αποστολές «παρεσχέθη υπό των πολιτικών και στρατιωτικών αρχών πάσα
          υποστήριξις, διευκολύνθη δε. παντοιοτρόπως ή εργασία τούτων υπό τής
          αρχαιολογικής υπηρεσίας».
          <br />
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το κλείσιμο του Γερμανικού Αρχαιολογικού Ινστιτούτου τής Αθήνας στα
          τέλη τού έτους 1916, ως συνέπεια τού Α' Παγκοσμίου πολέμου και τής
          γαλλικής κατοχής, είχε ως αποτέλεσμα να μεταφέρει ή γερμανική
          κυβέρνηση τήν έδρα τού Ινστιτούτου στή Σμύρνη, όπου εγκαταστάθηκε το
          1917/18 και ο Διευθυντής του G.Karo. Τα επόμενα χρόνια οι Γερμανοί
          αρχαιολόγοι ασχολήθηκαν ιδιαίτερα με τήν περιοχή τής Καρίας και τήν
          Αλικαρνασσό.
          <br />
          Το 1916 διέκοψε τις εργασίες του και το Αυστριακό Αρχαιολογικό
          Ινστιτούτο τής Αθήνας, πού είχε ιδρυθεί το 1897/8. Στόν ίδιο φορέα
          υπαγόταν το αυστριακό αρχαιολογικό παράρτημα τής Σμύρνης, πού έκλεισε
          το 1920 με ενέργειες τού O. Walter. Στό Ινστιτούτο τής Αθήνας ανήκε
          και το βραχύβιο αυστριακό αρχαιολογικό παράρτημα τής
          Κωνσταντινούπολης.
          <br />
          Στό υπ’ αριθ. 44/8 Μαρτίου 1923 ανυπόγραφο έγγραφο του Μουσείου προς
          το Υπουργείο Παιδείας, ο Κουρουνιώτης αναφέρεται στό έργο του ως
          διευθυντού τής Αρχαιολογικής Υπηρεσίας στή Σμύρνη, κατά τα δύο
          τελευταία χρόνια τής παραμονής του στρατού και μετά από διαταγή τής
          Ελληνικής Διοίκησης. Στίς δραστηριότητες του περιλαμβάνεται ή
          συγκέντρωση στή Σμύρνη και Μουδανιά, με συνδρομή τού στρατού,
          αρχαιοτήτων από όλη τη Μικρά Ασία και ιδιαίτερα τη Φρυγία (ιδιόρρυθμα
          φρυγικά επιτύμβια ανάγλυφα και σπουδαίες επιγραφές). Κατόπιν αναφέρει
          τούς τόπους αποθήκευσης. Επίσης ανακοινώνει ότι μετέφερε από τις
          Σάρδεις στή Σμύρνη περίφημες και μοναδικές λυδικές επιγραφές και άλλα
          αρχαία από τήν περιοχή τής αρχαίας Νύσας και αξιολογότατη ζωγραφισμένη
          σαρκοφάγο από τις Κλαζομενές. Τήν τύχη αυτών των αρχαιοτήτων αναφέρει
          ότι αγνοεί, γνωρίζει μόνο ότι οι συνοικίες όπου αυτά φυλάσσονταν δεν
          εκάησαν. Κατόπιν αναφέρεται στό Μουσείο τής Ευαγγελικής Σχολής τής
          Σμύρνης, το όποιο κάηκε, άλλά προσθέτει ότι τουλάχιστον τα μαρμάρινα
          και πήλινα εκθέματα του ισογείου ίσως δεν κατεστράφησαν ολοσχερώς.
          <br />
          Έκκληση για τήν έκδοση των αρχαιοτήτων του Μουσείου τής Ευαγγελικής
          Σχολής στή σειρά «Einzelaufnahmen antiker Skulpturen», με βάση τις προ
          τής καταστροφής καταγραφές και φωτογραφίσεις διαφόρων μελετητών,
          απηύθυνε το ίδιο έτος ο αρχαιολόγος G. Lippold.
          <br />
          Στη διάρκεια της υπηρεσίας του ο Κουρουνιώτης εφρόντισε επίσης για την
          συντήρηση των Ελληνικών αρχαιοτήτων με εκτεταμένες εργασίες στην
          Πέργαμο, Έφεσο, Νύσα, Κλαζομενές και άλλες πόλεις. Με έκδηλη ανησυχία
          παρατηρεί ότι «Ο εγερθείς ανθελληνικός και εν γένει αντιξενικός
          φανατισμός παρά τοις Τούρκοις είναι πιθανόν, ότι θα εκσπάση και επί
          των μνημείων των πόλεων τούτων, ένεκα της στοργής ακριβώς την οποίαν
          έδειξαμεν ημείς υπέρ αυτών». Τέλος προτείνει στον Υπουργό, στή Σύνοδο
          του Αρχαιολογικού τμήματος των Ακαδημιών στις Βρυξέλες να γίνουν
          σχετικές ενέργειες για τη διάσωση των αρχαιοτήτων με υπέρ αυτών
          παρέμβαση τής Ένωσης. Τα ευρήματα των ελληνικών ανασκαφών παρέμειναν
          στή Μ. Ασία, πλην ελαχίστων εξαιρέσεων, όπως το επωνομαζόμενο «μικρό
          προσφυγάκι» από το Γεροντικόν Νύσης, σήμερα στό Εθνικό Αρχαιολογικό
          Μουσείο, άριθ. εύρ. 3485 Συλλογής Γλυπτών.{" "}
        </Typography>
        <img
          src={Image03}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Με τήν καταστροφή τού Αύγούστου τού 1922 ή ανασκαφή στό Γεροντικόν
          Νύσης διακόπτεται, ο δε ανασκαφέας Κουρουνιώτης σημειώνει: «...Αλλ'
          ήδη είμαι υποχρεωμένος να επικαλεσθώ τήν συνδρομήν των ξένων
          συναδέλφων, όσοι είνε εις θέσιν να παρέμβωσι παρά τη Τουρκική
          κυβερνήσει, ίνα αύτη φροντίση προς ανατροπήν τής επαπειλουμένης
          καταστροφής τόσον του μνημείου τούτου όσον και των λοιπών
          Μικρασιατικών αρχαιοτήτων».
          <br />
          Μοναδική είναι ή περίπτωση τής μαρμάρινης κεφαλής του Εθνικού Μουσείου
          με αριθ. εύρ. 3616, ή όποία δημοσιεύτηκε πρόσφατα από την Ε.
          Ραυτοπούλου. Στό ευρετήριο τού Μουσείου αναφέρεται ως τόπος προέλευσης
          ή Σμύρνη, με τήν προσθήκη ότι δωρήθηκε το Ι926 από τόν άγγλο
          στρατιωτικό ακόλουθο Bauer.
          <br />
          ΓΙιό κατατοπιστικό είναι το υπ’ άριθ. 1043/28.8.1926 έγγραφο του
          Εθνικού Αρχαιολογικού Μουσείου προς το Υπουργείο, στό όποιο ο
          Διευθυντής II. Καστριώτης αναφέρει, ότι ο στρατιωτικός ακόλουθος της
          ενταύθα αγγλικής πρεσβείας κ. L. Bauer εδώρησε στό Εθνικό Μουσείο
          «κεφαλή γυναικεία μαρμάρινη ρωμαϊκών χρόνων αντίτυπον έργου
          ελληνιστικών χρόνων, παριστάνουσα δε πιθανώς Μούσα τινά, από το εν
          Σμύρνη Μουσεϊον τής Ευαγγελικής Σχολής», προσθέτοντας μάλιστα ότι το
          έργο φέρει ζωηρά ίχνη φωτιάς από τήν καταστροφή τής πόλης στις 27
          Αύγούστου Ι922. Τήν ίδια μέρα, με το υπ’ άριθ. 1042 έγγραφο, ο ΓΙ.
          Καστριώτης ευχαριστεί τόν L. Bauer για τήν παράδοση στό Εθνικό Μουσείο
          τής μαρμάρινης κεφαλής.
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default G20_mikrasiatika;
