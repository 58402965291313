import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g19/image002.jpg";
import Image02 from "../assets/images/mikrasiatika_g19/image004.jpg";
import Image03 from "../assets/images/mikrasiatika_g19/image006.jpg";
import Image04 from "../assets/images/mikrasiatika_g19/image008.jpg";
import Image05 from "../assets/images/mikrasiatika_g19/image010.jpg";

function G19_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Οι ηθοποιοί στον πόλεμο (του 1922)" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αντί προλόγου
        </Typography>

        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Η Ελλάς διεξήγαγε αγώνας μακρούς. Αγώνας πόλεων και αγώνας μετώπων. Η
          ιστορία των αγώνων αυτών είναι ένα ολόκληρο έπος και οι αγωνιστές των
          ήρωες μυθικοί. Σελίδες ολόκληρες και στήλες χρυσές έχουν αφιερωθεί για
          τους ήρωας αυτούς. Ανάμεσα όμως εις τους ήρωας αυτούς άπειροι, οι
          αφανείς, που ενώ προσέφεραν την ζωήν, το αίμα και την ψυχήν των,
          έμειναν άγνωστοι και λησμονημένοι, οι ηθοποιοί, οι οποίοι με την
          δημιουργίαν του θεάτρου του μετώπου, ιδίως κατά τους τελευταίους
          πολέμους, όπου από πολέμους εφόδων και υποχωρήσεων μετεβλήθησαν εις
          πολέμους χαρακωμάτων, εις τα οποία η ακινησία, και η αναμονή,
          κατέστησαν ανάγκη επιτακτική ως μέσον ψυχαγωγίας, το θέατρο.
          <br />Η συμβολή των ηθοποιών εις την διατήρησιν των εθνικών ιδανικών
          ήτο ανάλογος με την επίδρασιν του παππά και του δασκάλου. Ο λογοτέχνης
          Μιχαήλ Ροδάς που έδωσε διάλεξη στον Παρνασσό με το θέμα «οι ηθοποιοί
          στο μέτωπο» συνέστησε όπως το κράτος απονείμει τον πολεμικόν σταυρόν
          εις το σωματείον των ηθοποιών ως εκπρόσωπον των ηθοποιών που τόσο
          ηγωνίσθησαν και με το επάγγελμα και με τον ηρωισμόν των για την
          πατρίδα.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο σαλπιγκτής του Α! Συντάγματος σκαπανέων Αθηνών εσήμανε
          προσκλητήριον…
          <br />
          Ο Συνταγματάρχης μας κ. Δημαράς μας αποχαιρετά και μας εύχεται τη νίκη
          της Μικρασιατικής εκστρατείας.
          <br />
          Το μουράγιο στον Πειραιά είναι γεμάτο από κόσμο που ήρθε να χαδέψει με
          το βλέμμα για στερνή ίσως φορά γιους, αδέρφια, αρραβωνιαστικούς,
          συζύγους, στρατιώτες, όλους. Ανεβασμένος στο πλοίο ξεχωρίζω και εγώ
          κάπου εκεί τη γριά μάνα μου και τις αδερφές μου. Σε λίγο μανδήλια
          πολλά αποχαιρετούν ένα ομοιόχρωμο σύνολο, το στρατιωτικό.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Μικρασιατικό μέτωπο
        </Typography>

        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Όσο και αν η κυριαρχούσα σκέψη εκείνες τις στιγμές θάπρεπε νάναι η
          ιερότης του πολέμου οι βωμοί, εστίες,(γιατί εις τον πόλεμον της
          Μικρασίας κάτι παρόμοιο είχαμε να επιτελέσουμε), οι παρθένες αδερφές,
          η ζωή των μικρών η των γερόντων, για να συνοδεύεται ο αποχαιρετισμός
          και από καμάρι στο καινούργιο βλαστάρι της γενιάς μας, δεν μπορεί
          κανείς να αρνηθεί στον κόσμο αυτό που στερείται το στήριγμα τις
          ελπίδες και τις χαρές του σπιτιού, να συγκινηθεί, να κλάψει για το
          τσάκισμα του κορμιού η για τον πιθανό χαμό μιας φρέσκιας ψυχούλας
          πολυαγαπημένης. Εξ άλλου οι στιγμές αυτές ανήκουν πάντα στις μανούλες
          και τις αδερφάδες που το κλάμα και η συντριβή τους ζωντανεύουν τη
          φλόγα της Ελληνικής εστίας, της Ελληνικής οικογένειας. Πόσο αγαπημένες
          είναι τότε αυτές οι υπαρξούλες που εγκαταλείπονται έτσι κάπου εκεί, σε
          κάποιο μουράγιο, σε κάποιο σταθμό. Τραβάμε προς το άγνωστο… άραγε θα
          ξαναγυρίσουμε? Θα ξαναδούμε τις γλυκές μορφές των δικών μας?...
          <br />
          Το πλοίο αφήνει σιγά – σιγά το λιμάνι του Πειραιώς και ανοίγεται στο
          πέλαγος. Ο κόσμος, οι δικοί μας που ήταν στην αποβάθρα, φαίνονται σαν
          ένας μαύρος όγκος, προσδίδοντας γύρω του μια πένθιμη όψη. Σιγά – σιγά
          χάνεται κι αυτός. Πλέομε προς την Σμύρνη. Καθισμένος στο κατάστρωμα
          άθελα κοιτώ το ρολόι μου. Δείχνει δέκα η ώρα που αρχίζουν τα θέατρα,
          είπα μέσα μου. Ξάφνου μια φωνή μου αναστατώνει τις νοσταλγικές σκέψεις
          μου. «Ρε συνάδελφε θεατρίνε, σε θέλει ο επιλοχίας», ήταν η φωνή ενός
          στρατιώτου. Τρέχω και ο επιλοχίας με διατάσει να τους παραστήσω κάτι.
          Σαν τι? κύριε επιλοχία του λέω. Ξέρω γώ μου απαντά. Εσύ είσαι
          θεατρίνος και να μας κάνεις ένα θέατρο. Άλλο δεν ήθελα. Άρχισα να τους
          απαγγέλλω το προφητικό ποίημα του αειμνήστου Λάμπρου «Τρεις εποχές».
          Οι φαντάροι ενθουσιάστηκαν, επακολούθησε τραγούδι ομαδικό και χορός.
          Μια καινούργια ζωή αρχίζει. Είμαστε αδελφωμένοι σαν μια οικογένεια.
          <br />
          Αφού φτάσαμε στη Σμύρνη, αποσπάσθηκα στην υπηρεσία της Χ.Α.Ν. Σμύρνης.
          Εκεί βρήκα διευθυντή τον Αμερικανό Χέντερσον. Με χρησιμοποίησαν για
          την δημιουργία ενός στρατιωτικού θιάσου που θα έπαιζε στο σπίτι του
          στρατιώτου Σμύρνης.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στρατιώται ηθοποιοί ήσαν στην Σμύρνη το 1920 ο Γ. Γληνός, ο Άγγελος
          Μαυρόπουλος, Ν. Περάκης, Δ. Κιούσης, Μάνος Φιλιππίδης, Ι. Λαγογιάννης
          και ο ερασιτέχνης μηχανικός Αργύρης Λεοντόπουλος που χρησιμοποιήθηκε
          για την κατασκευή της σκηνής.
          <br />
          Με την συμμετοχή της Δδος Γαϊτανάκη και άλλων κυριών που βρίσκονταν
          στη Σμύρνη απετελέσαμε τον πρώτο στρατιωτικό θίασο υπό την διεύθυνση
          μου και αρχίσαμε τις παραστάσεις. Έναρξη κάναμε με το «υπηρετείν» του
          Λαβεντάν και με διάφορα νούμερα από επιθεωρήσεις. Η μαντολινάτα του
          «καφέ» Φώτη έπαιζε στα διαλείμματα και η μουσική της στρατιάς Μικράς
          Ασίας συμπλήρωνε το πρόγραμμα.
          <br />
          Την παράσταση ετίμησε ο αρχηγός της στρατιάς Παππούλας και πολλοί
          ανώτεροι αξιωματικοί. Δώσαμε σειρά παραστάσεων σκορπώντας έτσι τη χαρά
          και τον ενθουσιασμό στους στρατιώτας συναδέλφους μας.
          <br />
          Ύστερα από ένα μήνα έδωσαν εις τους στρατιώτας ηθοποιούς φύλλα πορείας
          για διαφόρους μονάδας που βρισκόντουσαν στην πρώτη γραμμή του μετώπου.
          Μου εδόθη φύλλον πορείας για την 11ην Μεραρχία που βρισκότανε στη
          Νικομήδεια.
          <br />
        </Typography>
        <div
          class={Image03}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Νικομήδεια!!! Τρομερός κανονιοβολισμός. Γινόταν η μάχη για την
          κατάληψη της Σαπάντζας. Πρώτη φορά άκουγα τον τρομερό ήχο των
          κανονιών… είδα να φέρνουν έναν τραυματισμένο αξιωματικό που λεγόταν
          Σινιόρης. Τρομερή η εντύπωσις αυτή. Σε λίγο έφεραν κι άλλους. Ω! άθελά
          του ο στρατιώτης γίνεται σκληρός όταν βλέπει τα αδέρφια του χτυπημένα
          από εχθρικά βόλια, γίνεται θηρίο, ζητά να εκδικηθεί… ένα τέτοιο
          συναίσθημα με κατέλαβε βλέποντας τους τραυματισμένους.
          <br />
          Σε λίγο η πόλις Σαπάντζα είχε καταληφθεί απ την μεραρχία μας.
          Πανηγυρίσαμε γι αυτό… η ημέρα αυτή θα μου μείνει αλησμόνητη. <br />
          Το Φρουραρχείο Νικομήδειας μ έστειλε στη διλοχία μου που ήταν στο
          Ντέρετζε. Δύο ώρες έξω από την Νικομήδεια. Διοικητής της διλοχίας ήταν
          ο υπέροχος στρατιώτης ταγματάρχης Γιαννακόπουλος ο σημερινός Διοικητής
          του 1ου Συντάγματος τηλεγραφητών. Λοχαγό είχα τον κ. Καλογερά. Την
          ημέρα μας την περνούσαμε σκάβοντας. Φτιάχναμε αμπριά και χαρακώματα
          στην Αρετσού δύο ώρες έξω από τον Ντέρετζε. Πλησίον της
          Κωνσταντινουπόλεως. Την εργασία μας μας διέκοπταν διάφορες επιδρομές
          τσετών όπου παίρνοντας τα όπλα αρχίζαμε τη μάχη. Το βράδυ γυρίζοντας
          στον καταυλισμό κατάκοπος και εξηντλημένος σε μια πρόχειρη σκηνούλα
          που είχε για σκηνικά αντίσκηνα και κουβέρτες και φωτισμό από τρία
          μικρά κεράκια με μονόπρακτα με απαγγελίες και διάφορα τραγούδια, έκανα
          να ξεχνάμε τις ταλαιπωρίες μας.
          <br />
          Ένα πρωί με κάλεσε ο στρατηγός κ. Κλαδάς στη Νικομήδεια και με διέταξε
          να διοργανώσω μια παράσταση στο κινηματοθέατρο της πόλεως την οποίαν
          θα παρηκολούθη και ο ίδιος καθώς και οι αξιωματικοί του Ναυτικού.
          Επρόκειτο νάρθη ο Αβέρωφ με άλλα δύο πολεμικά. Δεν ήξερα τι να κάνω…
          <br />
          Δεν είχα καθόλου τα μέσα, αλλά ούτε ηθοποιούς για να παρουσιάσω μια
          καλή παράσταση.
          <br />
          Τότε ένας λοχίας ονόματι Ηλιάδης με πληροφόρησε ότι εις την Μεραρχίαν
          μας ευρισκόταν ένας πολύ καλός τενόρος. Τρέχω και τον βρίσκω, του λέω
          πως έχω διαταγή να τον πάρω, για να τραγουδήσει δύο τρία καλά
          κομμάτια. Αυτός όμως έγινε έξω φρενών (είμαι στρατιώτης και όχι
          θεατρίνος) μου λέει. Παρά τας διαμαρτυρίας τον ανέφερα στο λοχαγό του.
          Αμέσως ο λοχαγός του τον διέταξε, αυτός τότε ήρθε μαζί μου, διότι δεν
          μπορούσε να κάνει κι αλλιώς.
          <br />
          Κατήρτισα το πρόγραμμα. Αυτό περιείχε μίαν μονόπρακτη κωμωδία, του
          Λάσκαρη, απαγγελία το ποίημα «Ματρόζον», από την επιθεώρησιν
          «Παναθήναια» το εγώ ειμ εγώ ευζωνάκι γοργό, και τρία άλλα τραγούδια
          του Σαμάρα. Κατά δε τα διαλείμματα, η μπάντα του Αβέρωφ. Οι δοκιμές
          άρχισαν. Ο τενόρος είχε πολύ γλυκιά φωνή, και ήμουν βέβαιος πως θα μ
          έβγαζε ασπροπρόσωπο και πως θα ενθουσίαζε το ακροατήριο.
          <br />
          Το βράδυ της παραστάσεως ήρθε. Το θέατρο ήταν γεμάτο. Ο Στρατηγός, ο
          Ναύαρχος, οι Αξιωματικοί είχαν πάρει τα ς θέσεις τους. Η παράστασις
          πήγαινε καλά. Αλλά όταν ήρθε και η σειρά του τενόρου να τραγουδήσει,
          καταστρέφετο σχεδόν το παν. Αυτός είχε καταληφθεί από μίαν τέτοιαν
          νευρικήν κρίσιν, και κατακίτρινος μου λέει ότι κατάπιε την φωνή του
          και ότι του ήταν αδύνατο να βγει στη σκηνή. <br />
          Προσπαθώ να τον ενθαρρύνω, χωρίς αποτέλεσμα. Βλέποντας ότι όλα πάνε
          χαμένα, του δίνω μια σπρωξιά και από τα παρασκήνια χωρίς να το
          καταλάβει βρέθηκε στη σκηνή. Μόλις τον είδαν οι στρατιώτες, ήρχισαν να
          τον χειροκροτούν, τότε ο τενόρος πήρε θάρρος και ήρχισε να τραγουδεί.
          Τραγούδησε όμως τόσο ωραία που χειροκροτήθηκε ενθουσιωδώς. Ο τενόρος
          εκείνος δεν ήταν άλλος παρά ο Ορέστης Μακρής, ο οποίος ακολουθήσας τας
          συστάσεις μου, επεδόθη στο θέατρο, δημιουργήσας μεγάλας επιτυχίας. Η
          Μεραρχία διατάσσεται να εγκαταλείψει την Νικομήδεια. Φύγαμε με δυνατή
          βροχή, φορτωμένοι με πλήρη τον εξοπλισμό μας, επί πλέον δε με πτύα και
          σκαπάνες. Η βροχή μας έχει διπλασιάσει το βάρος. Τέλος φθάσαμε νύχτα
          στο Μπαξεντζίκ, και στρατοπεδεύσαμε. Όταν ξημέρωσε, αρχίσαμε αμέσως να
          φτιάχνουμε τη μικρή και πρόχειρη σκηνούλα μας. Ο Βασιλάκης όμως μας
          έλεγε «άδικα βρε παιδιά την φτιάχνουμε, έχω μια προαίσθηση πως δε θα
          μείνουμε πολύ καιρό εδώ». και προτού προφθάσει να τελειώσει η
          κατασκευή της, μια επισκεπτική οβίδα μας τάραξε την ησυχίαν. Η μάχη
          ήρχισε! Μάχη τρομακτική, που κράτησε όλην την ημέρα. Η διλοχία μας
          είχε λάβει μέρος. Τέλος ο εχθρός ηναγκάσθη να υποχωρήσει. Μόλις ο
          σαλπιγκτής εσήμανε έφοδο, με την λόγχην, με αλαλαγμούς και αέρα, ο
          εχθρός υπεχώρησε.
          <br />
          Το βράδυ οι ηθοποιοί ξεχνώντας όλοι την φοβερή κούραση και τον
          εκνευρισμό της ημέρας, αρχίσαμε να ετοιμάζουμε πάλι για να
          παραστήσουμε. Βρεθήκαμε όλοι. Έλειπε μόνον, ο Ορέστης Μακρής. Άρχισα
          να ρωτώ δω και κει μήπως τον είδαν. Γύρισα ψάχνοντας όλον τον
          καταυλισμό μήπως τον βρω, οπότε κάποιος συνάδελφος με πληροφορεί ότι
          τον είδε νεκρό. Η λύπη μου ήτο μεγάλη. Όλη την νύχτα δεν κοιμήθηκα.
          Και όπως γίνεται πάντοτε όταν χάσουμε ένα φίλο μας!
          <br />
          Αρχίσαμε να του πλέκουμε επιθανάτια εγκώμια, να μιλάμε για τα
          προτερήματα του, την καλή του καρδιά και τα τόσα άλλα χαρίσματα του. Ο
          θάνατος είχε διαλύσει κάθε δυσαρέσκεια. Και αυτό το απέδειξε κάποιος
          συνάδελφος, ο οποίος δεν τον συμπαθούσε επειδή ήτο και αυτός τενόρος.
          Μα να τώρα όμως συμφωνούσε μαζί μας. Η παράστασις ανεβλήθη, διότι
          δήλωσα ασθένεια λόγω των ταλαιπωριών της ημέρας.
        </Typography>
        <div
          class={Image04}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το πρωί αφού ξύπνησα, έκαμα μια βόλτα στα πρόχειρα χειρουργεία του
          καταυλισμού. Ξάφνου διακρίνω έναν που έμοιαζε του Μακρή. Πλησιάζω και
          βεβαιώνομαι ότι δεν ήταν άλλος από τον Ορέστη μας. Δόξα τω Θεώ φωνάζω
          – Ορέστη μου ζείς? Και άρχισα να ψάχνω μην του λείπει κανένα χέρι η
          κανένα πόδι. – Βρε Μήτσο μου λέει είμαι ζωντανός, χτύπα ξύλο μου
          κάνει, χτυπώντας το κεφάλι μου. Βρε Ορέστη, του λέγω εμείς σε είχαμε
          για πεθαμένο και πάνω στη συζήτηση πέφτουν τα μάτια μου άθελα στον
          διπλανό, ο οποίος κοιμόταν παραμιλώντας από τον πυρετό. Τούχαν κόψει
          το ένα πόδι… Τότε ξαναγύρισα στον Ορέστη, ο οποίος με βεβαίωνε, ότι
          δεν είχε τίποτε άλλο, παρά ένα επιπόλαιο τραύμα, από θραύσμα οβίδος
          και ότι δεν πονούσε καθόλου. Ακόμη μου πρόσθεσε ότι μπορούσε να
          περπατά θαυμάσια αλλά έκαμε τον τραυματία, μήπως τσιμπήσει καμιά
          άδεια. Του ευχήθηκα καλή επιτυχία στο κόλπο του και τον άφησα.
          <br />
          Έμαθα σε λίγο ότι είχε διακομισθεί, αλλά σε δέκα ημέρες μας τον
          ξαναστέλνουν. Το κόλπο του δεν πέτυχε.
          <br />
          Νέα διαταγή της Μεραρχίας να προχωρήσουμε. Αρχίσαμε να βαδίζουμε μέρα
          και νύχτα. Η ανάπαυσις ήτο μικρά. Δεν μπορούσαμε να βγάλουμε από πάνω
          μας ούτε τις μπαλάσκες ούτε τα άρβυλα για να αναπαυθούν λίγο τα
          κουρασμένα από την πορεία πόδια μας. Βαδίζαμε παραθαλασσίως , από
          Μπαξεντζίκ μέχρι Γιάλοβας, έχοντας δύο πολεμικά και ένα πλωτό
          νοσοκομείο. Όταν κανείς ετραυματίζετο από τους τσέτας, οι οποίοι μας
          κτυπούσαν αιφνιδιαστικά μετεφέρετο αμέσως εις το πλωτό νοσοκομείο.
          Εκεί που βάδιζα μου σώθηκε το νερό. Και θέλαμε 5 ώρες να φτάσουμε στο
          μέρος όπου – κατά τον χάρτη, όπως μας έλεγε ο Ανθυπολοχαγός
          Παναγιωτάκης υπήρχε πηγάδι με νερό. Ζήτησα τότε από τους συναδέλφους
          μου. Κανείς δεν μούδωσε. Οι σαρδέλες που μας δίνανε και η ζέστη του
          Ιουλίου, μας είχαν κάμει να ζητάμε νερό, ως μοναδικό τονωτικό για να
          συνεχίσουμε την εξαντλητική πορεία μας. Τα πόδια μου είχαν παραλύσει,
          και μου ήλθε μία ελαφρά σκοτοδίνη. Δεν ήξευρα τι θα γινόμουν, αν ένας
          συνάδελφος μου, που ποτέ δεν θα ξεχάσω το όνομα του, (λεγόταν Τατίνης)
          από την Ζάκυνθο δεν μου έδινε λίγο νερό να πιω. Τότε κάπως συνήλθα και
          έκαμα κουράγιο για να συνεχίσω την πορεία μου. Φθάσαμε στην Γιάλοβα.
          Εκεί ο εχθρός φεύγοντας είχε βάλει φωτιά προφανώς για να μη βρούμε
          τρόφιμα. Τα πολεμικά μας αφήκαν. Τώρα βαδίζαμε μόνοι, μέσα στην
          τρομερή ζέστη. Τα κρούσματα της ηλιάσεως κατελάμβανον ως επί το
          πλείστον τους στρατιώτες που ήσαν από χωριά. Ο αρχηγός του μεραρχιακού
          πεζικού Συνταγματάρχης Καϊμπαλής διέταξε, ν αφήσουμε από μίαν κουβέρτα
          για να ελαφρωθεί λίγο το βάρος. Βαδίζοντας στα στενά του Γκεϊβαί
          είμεθα ευτυχείς, διότι είχαμε τον Γάλο ποταμό και άφθονα κεράσια.
          Κόβαμε ολόκληρα κλαδιά από τις κερασιές και βαδίζοντας τρώγαμε. Το
          θέαμα ήταν υπέροχο! Το νερό και τα φρούτα μας είχαν τονώσει. <br />
          Μετά δεκαπενθήμερο πορεία, φθάσαμε στο Ταμπλάρ και εκεί
          στρατοπεδεύσαμε. Ο στρατηγός Κλαδάς διέταξε να βάλουν οι λόχοι τα
          καζάνια να κάνουν βραστό κρέας, από τα γελάδια, που είχαμε μαζέψει από
          τον δρόμο. Οι φωτιές άναψαν, οι αγκαρίες κουβάλησαν νερό, άλλοι πάλι
          έσφαξαν τα γελάδια, άλλοι έπλεναν τα ψωριάρικα ρούχα και οι ηθοποιοί
          ετοίμαζαν την σκηνούλα τους. Ήταν η πιο ευτυχισμένη ημέρα της ζωής
          μας. Έπειτα από τόση εξαντλητική πορεία, βγάζαμε τα άρβυλα και
          πλυθήκαμε! Το συσσίτιο ετοιμάσθηκε και ο στρατηγός με τους
          αξιωματικούς, παρακολουθούσαν την διανομή. Δώστε μεγάλη μερίδα έλεγε α
          στρατηγός στους καλλιτέχνας να δυναμώσουν για να παίξουν με όρεξη.
          Άλλο που δεν ήθελα. Είχα πάρει αρκετό θάρρος και αφού καταβρόχθισα δύο
          τεράστιες μερίδες που μούδωσαν μαζί με μισή κουραμάνα, ξαναμπήκα στη
          γραμμή και ξαναπήρα συσσίτιο.{" "}
        </Typography>
        <img
          src={Image05}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το βράδυ, εδόθη η παράστασις. Επαίξαμε το έργο «μία κλίνη για τρεις»
          και διάφορα τραγούδια επιθεωρήσεων, και επικολούθησε χορός ομαδικός.
          Αλλά αυτό όμως ήτανε μόνο για μια νύχτα. Γιατί το πρωί αντί
          εγερτηρίου, οβίδες εχθρικού πυροβολικού μας ετρόμαξαν. Ερίχνοντο μέσα
          στον καταυλισμό μας. Φαίνεται ότι ο εχθρός μας είχε επισημάνει. Οι
          άνδρες της διλοχίας του μηχανικού που έφυγαν για να φτιάξουν την
          γέφυρα που θα περνούσαμε για να βγούμε από τα στενά του Γκεϊβαί,
          έπαθαν μεγάλη πανωλεθρία. Ο στρατηγός διέταξε τότε πάση θυσία να
          περάσει το πυροβολικό από την βαλλόμενη γέφυρα και να απαντήσει τον
          εχθρό. Ο εχθρός ηναγκάσθηκε να υποχωρήσει. Είχαμε μεγάλας απωλείας.
          Μία οβίδα που έσκασε τότε δίπλα μου, με πλήγωσε στο δεξιό πόδι. Την
          ίδια ημέρα έμαθα πως σκοτώθηκε κι ο αγαπημένος μου αδελφός, Στυλιανός,
          εις την μάχη του Άκ Μπουνάρ, εις το 34ον Σύνταγμα πεζικού υπό τον
          Συνταγματάρχην Βαλέταν.
          <br />
          Ύστερα απ αυτό και κατόπιν νοσηλείας στο νοσοκομείον Προύσσης,
          μεταφέρθηκα εις την 2αν σειράν εφεδρείας ως έχον αδελφόν φονευθέντα.
          Το τραύμα μου επουλώθει, και ήλθα στην Αθήνα όπου υπό την προστασίαν
          της πριγκηπίσης Ελένης του Νικολάου, έκαμα την εμφάνισιν μου στο
          Δημοτικό θέατρο Αθηνών με το έργο (ο γέρο Λεμπονάρ) τη συμμετοχή του
          μεγάλου καλλιτέχνου Ευτυχίου Βονασέρα. Έπειτα αμέσως επήγα στο θίασο
          της μεγάλης μου δασκάλας Μαρίκας Κοτοπούλη…
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default G19_mikrasiatika;
