import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/drastiriotites2020/image001.jpg";
import Image03 from "../assets/images/drastiriotites2020/image003.jpg";
import Image05 from "../assets/images/drastiriotites2020/image005.jpg";
import Image07 from "../assets/images/drastiriotites2020/image007.jpg";
import Image09 from "../assets/images/drastiriotites2020/image009.jpg";
import Image11 from "../assets/images/drastiriotites2020/image011.jpg";
import Image13 from "../assets/images/drastiriotites2020/image013.jpg";
import Image15 from "../assets/images/drastiriotites2020/image015.jpg";
import Image17 from "../assets/images/drastiriotites2020/image017.jpg";
import Image19 from "../assets/images/drastiriotites2020/image019.jpg";
import Image21 from "../assets/images/drastiriotites2020/image021.jpg";
import Image23 from "../assets/images/drastiriotites2020/image023.jpg";
import Image25 from "../assets/images/drastiriotites2020/image025.jpg";
import Image27 from "../assets/images/drastiriotites2020/image027.jpg";
import Image29 from "../assets/images/drastiriotites2020/image029.jpg";
import Image31 from "../assets/images/drastiriotites2020/image031.jpg";
import Image33 from "../assets/images/drastiriotites2020/image033.jpg";
import Image35 from "../assets/images/drastiriotites2020/image035.jpg";
import Image37 from "../assets/images/drastiriotites2020/image037.jpg";
import Image39 from "../assets/images/drastiriotites2020/image039.jpg";
import Image41 from "../assets/images/drastiriotites2020/image041.jpg";
import Image43 from "../assets/images/drastiriotites2020/image043.jpg";
import Image45 from "../assets/images/drastiriotites2020/image045.jpg";
import Image47 from "../assets/images/drastiriotites2020/image047.jpg";
import Image49 from "../assets/images/drastiriotites2020/image049.jpg";
import Image51 from "../assets/images/drastiriotites2020/image051.jpg";
import Image53 from "../assets/images/drastiriotites2020/image053.jpg";
import Image55 from "../assets/images/drastiriotites2020/image055.jpg";
import Image57 from "../assets/images/drastiriotites2020/image057.jpg";


function D17_drastiriotites_2020() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Οι δραστηριότητές μας το 2020" />
      </Box>


      

	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Κοπή της Βασιλόπιτας του Συλλόγου μας</Typography>	
	<img src={Image01} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    <img src={Image03} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />       
    <Typography variant="h3" sx={theme.typography.typography2}> 
	
	Στην αίθουσα της Βιβλιοθήκης του, έκοψε ο Σύλλογος μας «Το Δελφίνι» την Βασιλόπιτά του, παρουσία του Δημάρχου Μυτιλήνης κ. Κύτελη Ευστρατίου, του Αντιδημάρχου Πολιτισμού κ. Τσακύρη Παναγιώτη, του Προέδρου του Δημοτικού Συμβουλίου κ. Χατζηγιάννη Δημοσθένη, του προέδρου της Δημοτικής Κοινότητας Λουτρών κ. Μαμώλη Βασίλη, του προέδρου του Αετού Λουτρών κ. Σπανού Νικολάου, του τ. προέδρου του Συλλόγου Αλιέων Μυτιλήνης κ. Μολυβιάτη Ιωάννη και των κατοίκων της Σκάλας Λουτρών που γέμισαν ασφυκτικά την αίθουσα της Βιβλιοθήκης.<br/>
   Ο πρόεδρος του Συλλόγου κ. Δημήτρης Παπαχρυσός αφού καλωσόρισε όλους τους παρευρισκόμενους και τους ευχαρίστησε για την παρουσία τους, που τιμάει τον Σύλλογο,  απευθυνόμενος στον Δήμαρχο του ευχήθηκε καλή επιτυχία στο δύσκολο έργο της νέας Δημοτικής αρχής και αναφέρθηκε στην πρόσφατη συνάντηση που είχε το Δ.Σ. του Συλλόγου μαζί του, όπου του αναφέρθηκαν λεπτομερώς και γραπτώς τα θέματα που μας απασχολούν. Στην συνέχεια πήρε τον λόγο ο Δήμαρχος Μυτιλήνης ο οποίος αναφέρθηκε κυρίως στο θέμα της προσφυγικής οικίας που απασχολεί τον Σύλλογο εδώ και μια πενταετία υποσχόμενος ότι αυτό το θέμα είναι στις προτεραιότητες του Δήμου καθώς και ότι θα είναι αρωγός στις προσπάθειες που καταβάλει ο Σύλλογος τις σχετικές με τις πολύπλευρες δραστηριότητες του.<br/>
   Τον λόγο πήραν στην συνέχεια ο αντιδήμαρχος πολιτισμού και ο πρόεδρος του Δημοτικού Συμβουλίου οι οποίοι αναφέρθηκαν με τα καλύτερα λόγια για τις δράσεις του Συλλόγου και ότι θα είναι αρωγοί στις προσπάθειές που καταβάλλουμε. Τέλος τον λόγο πήρε ο πρόεδρος της Δημοτικής μας Κοινότητας ο οποίος καλωσόρισε και αυτός με την σειρά του, τους εκπροσώπους της νέας Δημοτικής αρχής.<br/>
   Αφού τελείωσαν οι προσφωνήσεις ο ιερέας της ενορίας μας πατήρ Παναγιώτης Χατζηβασιλείου ευλόγησε την Βασιλόπιτα και στην συνέχεια ο πρόεδρος με το Δ,Σ, του Συλλόγου την έκοψαν και την μοίρασαν στους παρευρισκόμενους. Το φλουρί που ήταν προσφορά από το χρυσοχοείο του κ. Βέτσικα μετά από κλήρωση το κέρδισε ο κ. Ευστράτιος Καραντώνης. Ακολούθησε η κλήρωση ενός ρολογιού προσφορά από το κατάστημα «Ρολογάδικο» που κέρδισε ο οδηγός του Δημάρχου, ενός βιβλίου με Μικρασιάτικες συνταγές που μας έστειλε ο συγγραφέας Παρασκευάς Συριανόγλου από τον Σύλλογο Μικρασιατών Ρεθύμνου Κρήτης που κέρδισε ο κ. Νίκος Σπανός και τέλος ενός βιβλίου του Κ. Πολίτη που κέρδισε ο κ. Χιωτέλλης Χαράλαμπος.<br/>
   Ως ένδειξη εκτίμησης και αναμνηστικό για την επίσκεψη τους στην βιβλιοθήκη μας προσφέρθηκε στους Δημοτικούς μας άρχοντες από ένα βιβλίο για τον «Χρυσόστομο Σμύρνης» έκδοσης του Συλλόγου που δεν κυκλοφορεί στο εμπόριο. 
	</Typography>
	</Box>
	
	
	 <ScrollToTopButton />





	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Θεοφάνια στη Σκάλα Λουτρών 2020</Typography>	
	<img src={Image05} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
	<img src={Image07} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
	  Παρά το τσουχτερό κρύο, πραγματοποιήθηκε ο καθιερωμένος «Αγιασμός των Υδάτων» την ημέρα των Θεοφανίων στην προβλήτα του Αγίου Γεωργίου στην Σκάλα Λουτρών. Αρκετοί πιστοί παρακολούθησαν την τελετή και την ρίψη του Τίμιου Σταυρού που τέλεσε ο π. Παναγιώτης Χατζηβασιλείου.<br/>
  Φέτος ο μοναδικός και τολμηρός νέος που ανέσυρε τον Σταυρό ήταν ο Άκης Κολέας. Στον τολμηρό και θαρραλέο νέο, δόθηκε από την Κοινότητα Λουτρών μία χειροποίητη εικόνα της Παναγίας. Στην εκδήλωση παρευρέθηκαν εκ μέρους του Δήμου, ο Αντιπρόεδρος του Δημοτικού Λιμενικού Ταμείου Λέσβου κ. Κώστας Παπαδόπουλος, ο Περιφερειακός Σύμβουλος κ. Χριστόφας Παναγιώτης, ο πρώην Αντιδήμαρχος Μυτιλήνης και μέλος του Συλλόγου Αιμοδοτών Μυτιλήνης κ. Δημήτρης Μπουρνούς, όλα τα μέλη του Τοπικού Συμβουλίου της Κοινότητας Λουτρών, ο Πρόεδρος και τα μέλη του Συλλόγου "Λιοστάσι", ο Πρόεδρος και τα μέλη του Συλλόγου "Δελφίνι", καθώς και ο Πρόεδρος και τα μέλη της Διοίκησης του Αετού Λουτρών.<br/>
  Εδώ να σημειωθεί, ότι, λόγω της κακοκαιρίας δεν πραγματοποιήθηκε η κοπή της πίτας της Κοινότητας Λουτρών, ούτε ο Αγιασμός των υδάτων στην Χαραμίδα.<br/>
Και του χρόνου με υγεία.</Typography>

	
	
	</Box>
	
	
	 <ScrollToTopButton />





	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ομορφαίνουμε τον τόπο μας </Typography>	
	<img src={Image09}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
         
	<img src={Image11} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Ομορφύναμε τον τόπο μας με μια μικρής κλίμακας δενδροφύτευση, η οποία πραγματοποιήθηκε από τον Σύλλογο μας «Το Δελφίνι», στις 13 Φεβρουαρίου 2020, στην παραλία «Πύργος Κουντουρουδιάς», με αλμυρίκια και προστατευτικά τελάρα.!!!<br/>  Αυτή είναι μια μικρή αλλά όμορφη παραλία της περιοχής μας την οποία επισκέπτονται αρκετοί από τους κατοίκους της Σκάλας Λουτρών για να κάνουν το μπάνιο τους κάθε καλοκαίρι. Αυτή την παραλία θα προσπαθήσουμε να την αξιοποιήσουμε και με άλλες παρεμβάσεις που θα πραγματοποιήσει τα επόμενα χρόνια ο Σύλλογος.
	</Typography>
	

	
		</Box>
	 <ScrollToTopButton />





	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Εργασίες συντήρησης στο Μουσείο και στο γήπεδο μπάσκετ</Typography>	
	<img src={Image13} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    <img src={Image15} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
   	Με πρωτοβουλία του Δ.Σ. του Συλλόγου μας και με την αγορά των κατάλληλων υλικών πραγματοποιήθηκαν εργασίες βαψίματος στην τοιχοποιία της εξωτερικής περίφραξης του «Μουσείου Προσφυγικής Μνήμης 1922», λόγω της εκτεταμένης υγρασίας που είχε εμφανιστεί εδώ και αρκετό διάστημα.
   	<br/>
  	Επίσης αγοράστηκαν τα υλικά και βάφτηκε το δάπεδο του γηπέδου μπάσκετ του Συλλόγου επειδή αυτό είχε να συντηρηθεί αρκετά χρόνια με αποτέλεσμα οι διαγραμμίσεις του γηπέδου να είναι δυσδιάκριτες.
    </Typography>	
	
	</Box>
	
	
	 <ScrollToTopButton />





	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Διαγωνισμός ζωγραφικής και έκθεσης</Typography>	
	<img src={Image17} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    <img src={Image19} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Πραγματοποιήθηκε στις 5 Ιουνίου από τον Σύλλογο μας με επιτυχία, ο ετήσιος διαγωνισμός ζωγραφικής και έκθεσης με τους μαθητές και τις μαθήτριες του δημοτικού σχολείου Λουτρών.<br/>
  	Οι τέσσερις πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα:<br/>
 	<b>«Ζωγράφισε τι ονειρευόσουν την περίοδο του «Μένουμε σπίτι»</b><br/>                                  
	και οι δύο μεγαλύτερες τάξεις στην έκθεση με θέμα:<br/>
	<b>«Τι με δίδαξε το «Μένουμε σπίτι»</b><br/>
  	Τα παιδιά που διακρίθηκαν βραβεύτηκαν σε ειδική εκδήλωση που πραγματοποιήθηκε την Κυριακή 21 Αυγούστου 2020 στις 19.00 το βράδυ στον αύλιο χώρο του «Μουσείου Προσφυγικής Μνήμης 1922».<br/>
	Διακρίθηκαν:<br/><br/>
	Από την Α! Τάξη   οι, Βάρκα-Λύρα Ζαχαρούλα και Γκέντση Ελεονώρα. <br/>
	Από την Β! Τάξη   οι, Βουτσαρδέλλη Μαρία και Σταυράκης Παναγιώτης.<br/>
	Από την Γ1! Τάξη  οι, Ζαμτράκη Πελαγία και Παπουτσής Ταξιάρχης.<br/>
	Από την Γ2! Τάξη  οι, Λύρα Ουρανία και Τατάκη Αλεξάνδρα. <br/>
	Από την Δ! Τάξη   οι, Κόντρα Κλαιντι και Δήμου Γεώργιος.<br/>
	Από την Ε! Τάξη   οι,  Βαϊοπούλου Έλενα και Καραντώνη Ολυμπία.<br/>
	Από την ΣΤ! Τάξη οι, Ζούρου Ευαγγελία και Λιαδέλλη Ντορέτα. <br/><br/>
   Το Δ.Σ. του Συλλόγου μας ευχαριστεί την διευθύντρια και τους δασκάλους του Δημοτικού Σχολείου Λουτρών για την ουσιαστική βοήθεια και την συνεργασία που είχαμε κατά την διενέργεια του διαγωνισμού.<br/>

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />




	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>9ος Κολυμβητικός διάπλους Μικρασιατικής Μνήμης</Typography>	
	<img src={Image21} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
	<img src={Image23} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
	 <b> Την Κυριακή 26 Ιουλίου 2020</b>, στις 10 το πρωί, επρόκειτο να πραγματοποιηθεί ο <b>«9ος Κολυμβητικός Διάπλους»</b> του Κόλπου Γέρας, με αφετηρία την Κουντουρουδιά και τερματισμό το Πέραμα, όμως η Οργανωτική Επιτροπή στις 23 Ιουλίου εξέδωσε την παρακάτω ανακοίνωση:<br/><br/>
  « Η Οργανωτική Επιτροπή για τον <b>«9ο Κολυμβητικό Διάπλου Μικρασιατικής Μνήμης του Κόλπου Γέρας»</b>, ανακοινώνει με λύπη την ακύρωση αυτής της Πολιτιστικής Δραστηριότητας, λόγω των προβλημάτων που προέκυψαν σχετικά με τη διαδικασία αδειοδότησης από τα Λιμεναρχεία Μυτιλήνης και Περάματος.
Εδώ και 15 μέρες, γίνεται μία προσπάθεια, (από τα μέλη των συμμετεχόντων Συλλόγων), συγκέντρωσης όλων των απαραίτητων εγγράφων, ώστε να εκδοθεί η σχετική άδεια. Μία προσπάθεια, που γινόταν με περίσσεια χαρά τα προηγούμενα 8 χρόνια και ο Διάπλους διεξαγόταν επιτυχώς, καθώς συμμετείχαν πάνω από 60 κολυμβητές όλων των ηλικιών.<br/>
Την Πέμπτη 23-7-2020 και ενώ είχαμε λάβει τη σχετική άδεια από το Κεντρικό Λιμεναρχείο Μυτιλήνης, μας ανακοινώθηκε, ότι θα έπρεπε να έχουμε επί πλέον «άδεια διεξαγωγής αθλητικής συνάντησης» από τον ΕΟΤ - γεγονός που θα καθιστούσε τον - εν λόγω- διάπλου, (αγώνα), πράγμα παράλογο, καθώς είχαμε αναφέρει εγγράφως ότι δεν είναι αγώνας και επί πλέον είχαμε λάβει σχετικό έγγραφο από τον αντιπεριφερειάρχη αθλητισμού της Π.Β.Α. το οποίο διαβεβαίωνε ότι η εκδήλωση δεν έχει χαρακτήρα αγώνα και δεν απαιτείται αδειοδότηση από την Περιφέρεια! Έτσι καλούμαστε τελευταία στιγμή να βγάλουμε επί πλέον μια πληθώρα εγγράφων, προς κατάθεση στο Λιμεναρχείο, μαζί με ένα παράβολο του Δημοσίου της τάξεως των 150€… και σε περίπτωση κάποιας παράβασης, το πρόστιμο στους διοργανωτές μπορεί να κυμανθεί από 500€ έως και 2.000€.<br/>
Λόγω αυτών των απαράδεκτων απαιτήσεων που μάλιστα δεν έχουν σχέση με την ασφάλεια διεξαγωγής του διάπλου, για την οποία είχαμε ήδη μεριμνήσει ώστε να υλοποιηθούν όλες οι σχετικές απαιτήσεις και υποδείξεις του Λιμεναρχείου, οι Πολιτιστικοί Σύλλογοι:<br/>
«Παγγεραγωτικός» Αθηνών και Γέρας,<br/>
«Το Δελφίνι» Σκάλας Λουτρών και<br/>
Α.Π.Σ. Αναγέννηση Ποσειδώνα Περάματος,<br/>
αποσύρουμε την απόφαση υλοποίησης αυτής της Πολιτιστικής δραστηριότητας και καταγγέλλουμε τους υπεύθυνους για την τεράστια γραφειοκρατική μέγγενη και την αδυναμία ανάληψης ευθυνών, από μέρους τους.<br/>
  Κρίμα και πάλι κρίμα για την ανικανότητα στήριξης του πολιτισμού και των εθελοντικών φορέων που συνήθως χωρίς καμιά χρηματοδότηση προσπαθούν να δώσουν λίγη χαρά στους συμπολίτες μας χωρίς να βρίσκουν την αρμόζουσα κατανόηση και διευκόλυνση στις προσπάθειες που καταβάλουν.
* Επειδή είχαμε ήδη ενημερώσει τα ΜΜΕ για την διεξαγωγή του διάπλου η Οργανωτική επιτροπή το πρωί της Κυριακής θα βρίσκετε στην Κουντουρουδιά για να αναγγείλει σε όσους δεν πρόλαβαν να ενημερωθούν ότι ο διάπλους δεν θα διεξαχθεί με ευθύνη των Συλλόγων και αν κάποιοι αποφασίσουν να κολυμπήσουν (σημειώνουμε ότι η κολύμβηση δεν απαγορεύετε στον κόλπο Γέρας) το κάνουν με δική τους αποκλειστικά ευθύνη.»<br/>
Τελικά ο Διάπλους πραγματοποιήθηκε με την συμμετοχή 16 κολυμβητών οι οποίοι συμμετείχαν με δική τους αποκλειστικά ευθύνη.

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />





	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Βράβευση αριστούχων μαθητών Δημοτικής Κοινότητας Λουτρών</Typography>	
	<img src={Image25} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
	<img src={Image27} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
	    Στον αύλιο χώρο του «Μουσείου Προσφυγικής Μνήμης 1922», σε συνδιοργάνωση με την Π.Β.Α. πραγματοποιήθηκε, την Παρασκευή <b>21-8-2020</b> το απόγευμα, με επιτυχία και αθρόα συμμετοχή, η εκδήλωση για την βράβευση των αριστούχων μαθητών της Δημοτικής Κοινότητας Λουτρών, παρουσία της αντιπεριφερειάρχου Πολιτισμού και Οικονομικών κ. Αναστασίας Αντωνέλλη, καθώς και της διευθύντριας και των δασκάλων του Δημοτικού Σχολείου Λουτρών.<br/>
   Στην αρχή της εκδήλωσης έγινε μια σύντομη ενημέρωση από τον πρόεδρο κ. Δημήτρη Παπαχρυσό, σχετική με τις τρέχουσες δραστηριότητες του Συλλόγου και εν συνεχεία οι βραβεύσεις άρχισαν με τα παιδιά του  Δημοτικού σχολείου που διακρίθηκαν στην ζωγραφική και στην έκθεση, μια δραστηριότητα που επαναλαμβάνεται κάθε χρόνο με την συνεργασία του Συλλόγου και των δασκάλων του Δημοτικού Σχολείου Λουτρών στις 5 Ιουνίου, που είναι η παγκόσμια ημέρα  για το περιβάλλον. <br/>
Οι 4 πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα: <br/>
<b>«Ζωγράφισε τι ονειρευόσουν την περίοδο του «Μένουμε σπίτι» </b>
και οι 2 μεγαλύτερες τάξεις διαγωνίσθηκαν στην έκθεση με θέμα:<br/>
<b>«Τι με δίδαξε το «Μένουμε σπίτι»</b><br/>
Βραβεύτηκαν:<br/><br/>
Από την <b>Α! Τάξη</b>    η Βάρκα-Λύρα Ζαχαρούλα και η Γκέντση Ελεονώρα.<br/>
Από την <b>Β! Τάξη</b>    η Βουτσαρδέλλη Μαρία και ο Σταυράκης Παναγιώτης.<br/>
Από την <b>Γ1! Τάξη</b>  η Ζαμτράκη Πελαγία και ο Παπουτσής Ταξιάρχης.<br/>
Από την <b>Γ2! Τάξη</b>  η Λύρα Ουρανία και η Τατάκη Αλεξάνδρα.<br/>
Από την <b>Δ! Τάξη</b>    ο Κόντρα Κλάιντι και ο Δήμου Γεώργιος.<br/>
Από την <b>Ε! Τάξη</b>    η  Βαϊοπούλου Έλενα και η Καραντώνη Ολυμπία.<br/>
Από την <b>ΣΤ! Τάξη</b>  η Ζούρου Ευαγγελία και η Λιαδέλλη Ντορέτα.<br/><br/>

Ακολούθησε η βράβευση των αριστούχων παιδιών του Γυμνασίου και του Λυκείου<br/>

<u>Βραβεύτηκαν:</u><br/><br/>

Από  την   <b>Α!</b>    γυμνασίου    <br/>
η <b>Τριανταφύλλου Θεανώ</b> και<br/>
η <b>Μύνικνα Μαρία</b><br/><br/>

Από την    <b>Β!</b>    γυμνασίου     <br/>
η<b> Τρουλινού Αικατερίνη</b>  <br/>
η<b> Γκίζα Ευστρατία – Γεωργία</b> και<br/>
η<b> Κέντζη Μαρία</b>               <br/>   <br/>                                       

Από την     <b>Γ!</b>    γυμνασίου     <br/>
ο <b>Στρίγκος Μάριος</b>  <br/>
η <b>Γεωργιτζίκη Παρασκευή</b>  και<br/>
ο <b>Αλεξίου Γιώργος</b>      <br/>       <br/>                  

Από την     <b>Α!</b>  λυκείου             <br/>
η<b> Στυψιανού Μυρσίνη</b> <br/>
η <b>Μύνικνα Ειρήνη</b>      <br/>                                       
η <b>Ευαγγέλου Χριστίνα</b> και<br/>
ο <b>Αρμενάκας Παναγιώτης</b><br/><br/>

Από την     <b>Β!</b>  λυκείου        <br/>     
ο<b> Λέλεκας Κωνσταντίνος</b><br/>
η<b> Αλεξίου Μαρία</b>  και <br/>
ο<b> Κωστής Παρασκευάς</b><br/><br/>

Από την <b>Γ!</b>      λυκείου        <br/>   
η<b> Σφακιανού Γαρυφαλιά</b><br/>
η <b>Δημητρούλη Αγγελική</b><br/>
η <b>Ζούρου Χριστίνα</b><br/>
η <b>Γκίζα Κωνσταντίνα</b> <br/>
η <b>Γεωργιτζίκη Γαρυφαλιά</b> και <br/>
ο <b>Κέντσης Μαρίνος</b><br/><br/><br/>


   Η βράβευση των διακριθέντων παιδιών του Δημοτικού Σχολείου Λουτρών  έγινε από την διευθύντρια του σχολείου κ. Εριφύλη Χιωτέλλη και των αριστούχων Γυμνασίου και Λυκείου από την αντιπεριφερειάρχη κ. Αναστασία Αντωνέλλη.  <br/>
   Σε όλα τα παιδιά, εκτός από τον έπαινο και το περιοδικό του Συλλόγου στην τσάντα που τους δόθηκε περιλαμβάνονταν και άλλα χρήσιμα δώρα μεταξύ των οποίων ένα USB stick που περιείχε  σε ψηφιακή μορφή τα παρακάτω:<br/><br/>
12 βιβλία σχετικά με το περιβάλλον <br/>
5 βιντεάκια σχετικά με τις επιπτώσεις των πλαστικών στη θάλασσα<br/>
1 βιβλίο σχετικό με την εγκατάσταση των προσφύγων του 1922 στη Λέσβο<br/>
1 βιβλίο σχετικό με τις εφευρέσεις των αρχαίων Ελλήνων<br/>
Την Συνθήκη της Λωζάννης<br/>
Το περιοδικό μας με το αφιέρωμα στον Α! διωγμό του 1914<br/>
1 βιβλίο σχετικό με το πράσινο και τους ελεύθερους χώρους στην πόλη <br/>
1 βιβλίο σχετικό με τις πρώτες βοήθειες για το καλοκαίρι και τέλος <br/>
1 βιντεάκι σχετικό με τα εγκαίνια του Μουσείου μας το 2006<br/><br/>

  Επίσης μαζί με τα προαναφερθέντα όλα τα παιδιά πήραν και από ένα φορητό BLUETOOTH SPEAKER το οποίο θα τους κρατά συντροφιά και θα τους διασκεδάζει τις ελεύθερες ώρες τους.<br/>
   Εδώ θα πρέπει να ευχαριστήσουμε την Π.Β.Α. και την αντιπεριφερειάρχη  κ. Αναστασία Αντωνέλλη που συνέβαλαν στην αγορά των δώρων των παιδιών που βραβεύσαμε.<br/>
   Η εκδήλωση συνεχίστηκε με την προβολή ενός βίντεο σχετικού με την ρύπανση των θαλασσών από τα πλαστικά,  το οποίο μας έστειλε το Πανελλήνιο Δίκτυο Οικολογικών Οργανώσεων μέλος του οποίου είναι και ο Σύλλογος μας.


	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />




	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Επίσκεψη της Προέδρου της Ελληνικής Δημοκρατίας στο «Μουσείο Προσφυγικής Μνήμης 1922»</Typography>	

	<img src={Image29} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    <img src={Image31} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    <img src={Image33} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    <img src={Image35} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Το "Μουσείο Προσφυγικής Μνήμης 1922" στη Σκάλα Λουτρών, επισκέφθηκε η Πρόεδρος της Ελληνικής Δημοκρατίας κ. Κατερίνα Σακελαροπούλου, την Πέμπτη 20 Αυγούστου 2020 στα πλαίσια της 6ήμερης επίσκεψης της στη Λέσβο. <br/>
  Την υποδέχτηκαν στο Μουσείο ο πρόεδρος του Συλλόγου κ. Δημήτρης Παπαχρυσός και τα μέλη του Διοικητικού Συμβουλίου μαζί με τον πρόεδρο της Κοινότητας Λουτρών κ. Βασίλη Μαμώλη.<br/>
  Κατά την υποδοχή της προέδρου της Ελληνικής Δημοκρατίας ο κ. Δημήτρης Παπαχρυσός την καλωσόρισε λέγοντας.<br/><br/>
 Αξιότιμη κ. πρόεδρε της Ελληνικής Δημοκρατίας, <br/>
η σημερινή επίσκεψη σας στην ακριτική Σκάλα Λουτρών και ιδιαίτερα η παρουσία σας σ αυτόν εδώ τον χώρο του «Μουσείου Προσφυγικής Μνήμης 1922»   μας γεμίζει χαρά και αποτελεί ύψιστη τιμή τόσο για το χωριό μας όσο και για τον Σύλλογο μας αφού σ αυτόν εδώ τον χώρο φυλάσσονται με μεγάλο σεβασμό  τα κειμήλια που έφεραν μαζί τους οι πρόγονοι μας διωγμένοι και κατατρεγμένοι ύστερα από μια τρισχιλιόχρονη παρουσία τους στα αγιασμένα χώματα της Μικράς Ασίας. <br/>
* Έχουν περάσει 98 χρόνια από τότε που η Σκάλα Λουτρών, η Μυτιλήνη αλλά και όλη η Ελλάδα υποδέχτηκαν τους Μικρασιάτες πρόσφυγες με τα λιγοστά υπάρχοντά τους. Ορισμένοι από αυτούς, εγκαταστάθηκαν εδώ και συνέχισαν να αναδεικνύουν ειρηνικά μέχρι τις μέρες μας, τον Μικρασιατικό πολιτισμό, την αρχοντιά, και την αξιοσύνη.<br/>
  * Καθήκον όλων μας είναι να τιμούμε τους προγόνους μας, γι αυτό ο Σύλλογός μας εκτός των άλλων εκδηλώσεων και δραστηριοτήτων του, ίδρυσε  και λειτουργεί σ αυτόν τον χώρο εδώ και 14 χρόνια το «Μουσείο Προσφυγικής Μνήμης 1922».<br/>
*  Εδώ συγκεντρώσαμε, ό,τι είχαν μπορέσει να φέρουν μαζί τους απ’ τα καρσινά παράλια οι πρόσφυγες πρώτης γενιάς το 1922 και το αναδείξαμε με τον καλλίτερο τρόπο, έτσι ώστε, να θυμόμαστε τις ρίζες μας και να μη τις λησμονήσουμε ποτέ.<br/>
 *  Ο χώρος που στεγάζεται αυτό το Μουσείο, ήταν το πρώην Δημοτικό σχολείο του οικισμού μας και όταν το 2002 αυτό συγχωνεύτηκε με το αντίστοιχο σχολείο των Λουτρών, ζητήσαμε από την τότε Νομαρχία Λέσβου να μας παραχωρήσει την χρήση του, όπως και έγινε, και έκτοτε μια ομάδα ανθρώπων, μελών του Συλλόγου, με πρωτεργάτη τον δάσκαλο κ. Σταύρο Βαλαχή και χρηματοδότηση του Συλλόγου κατορθώσαμε μέσα σε 3,5 χρόνια με σκληρή εθελοντική εργασία, να αναμορφώσουμε τον χώρο, τόσο εξωτερικά όσο και εσωτερικά, ώστε αυτός να πάρει την τελική του μορφή όπως θα τον δείτε σε λίγο.<br/>
* Τα εγκαίνια του Μουσείου, έγιναν, στις 12 Αυγούστου του 2006 από την Α.Θ.Π. τον Οικουμενικό Πατριάρχη Κωνσταντινουπόλεως κ. Βαρθολομαίο, με την παρουσία πολλών επισήμων και των λιγοστών πλέον προσφύγων πρώτης γενιάς, που στην τελευταία περίοδο της ζωής τους ένιωσαν αυτή την χαρά και την ικανοποίηση, διότι, μπόρεσαν να ξαναφέρουν στις μνήμες τους έστω και μέσα από αυτόν εδώ τον χώρο, τις αλησμόνητες πατρίδες μας.<br/>
  * Στους μελλοντικούς στόχους μας τοποθετείται και  η επέκταση του εν λόγω μουσείου στον παρακείμενο χώρο που  διαθέτουμε έτσι ώστε να συγκροτηθεί και να εκτεθεί καλύτερα η συλλογή των κειμηλίων, που διαρκώς αυξάνει με κειμήλια που αποστέλλονται απ’ όλη την Ελλάδα. <br/>
Ακόμη μια επιπλέον επιδίωξή μας είναι η απαλλοτρίωση και στη συνέχεια η αποκατάσταση μιας από τις 25 προσφυγικές κατοικίες που έχει διατηρηθεί και που με  πρωτοβουλία μας χαρακτηρίστηκε διατηρητέα από το υπουργείο πολιτισμού, με το υπ αρ. 138/24-11-2006  ΦΕΚ, λόγω της ιστορικής αλλά και αρχιτεκτονικής της αξίας. Το σπίτι αυτό όπως καταλαβαίνετε, περιέκλυσε μέσα του τις μνήμες μιας από τις τραγικότερες ιστορικές περιόδους του τόπου μας και αυτή τη στιγμή η διαδικασία απαλλοτρίωσης από τον Δήμο Μυτιλήνης είναι σε εξέλιξη.<br/>
 * Καλώς ανταμωθήκαμε λοιπόν και ήρθε η ώρα να  σας συστήσω τα υπόλοιπα μέλη του Δ.Σ. που είναι:<br/><br/>
η Αντιπρόεδρος    κ. Δαφιώτη Νάσια<br/>
η Γ. Γραμματέας   κ. Βέντου Δώρα<br/>
η Ταμίας                κ. Σταματέλλη Ρένα και<br/>
τα Μέλη                κ. Γρηγορέλλη Κατερίνα<br/>
                              κ. Παπαμακαρίου Ντόρα και <br/>
                              κ. Χιωτέλης Χαράλαμπος<br/>
Παίρνοντας τον λόγο φανερά συγκινημένη, η Πρόεδρος της Δημοκρατίας κ. Κατερίνα Σακελαροπούλου είπε:<br/>
  «Όλη η Ελλάδα έχει μνήμες από την προσφυγιά του 1922 αλλά νομίζω αυτός ο τόπος τις διατηρεί πάρα πολύ έντονες, γι' αυτό δεν είναι τυχαίο ότι βρέθηκε αυτός εδώ ο χώρος να γίνει αυτό το μουσείο της προσφυγιάς και της μικρασιατικής καταστροφής», ενθυμούμενη τη δική της μάχη στη Δικαιοσύνη για να σωθούν τα προσφυγικά της Λεωφόρου Αλεξάνδρας. «Οι προηγούμενοι από εμάς έζησαν αυτή την περίοδο. Για εμάς τους νεότερους σημαντική είναι η μνήμη και είναι σημαντικό για τους ακόμη πιο νέους να διατηρούνται οι μνήμες αυτές».<br/>
  Η κυρία Σακελαροπούλου αναφέρθηκε και στην αντίδραση της τοπικής κοινωνίας το 1922. «Υπήρξαν, είπε, δυσκολίες και όμως τελικά αφομοιώθηκαν, έγιναν αποδεκτοί οι άνθρωποι, έστησαν εδώ τα σπίτια τους, απέκτησαν βιός, έκαναν οικογένειες κι έτσι δημιουργήθηκε ο πλούτος της πολιτιστικής παράδοσης, της παραγωγικής αυτής παράδοσης της Λέσβου. Γιατί αυτό νομίζω ότι είναι το νόημα όλων αυτών, ότι όλοι οι άνθρωποι έχουν καλά στοιχεία να δώσουν και μπορεί κανείς να αντλεί τα καλύτερα από όλους για να κάνει κάτι καλύτερο για το μέλλον. Αυτά ίσχυαν τότε και τα είδαμε στην πράξη να γίνονται».<br/>
  Η Πρόεδρος της Δημοκρατίας αναφέρθηκε και «στην ένταση που δημιουργείται και ζείτε κι εσείς στον τόπο εδώ» με αφορμή το σημερινό προσφυγικό και μεταναστευτικό ζήτημα. «Δεν έχουν όλα τα φαινόμενα την ίδια μορφή και γι' αυτό δεν παράγουν την ίδια ένταση ίσως. Αλλά εγώ πάντα προσπαθώ να σκέφτομαι τα θετικά ότι πρέπει να αποβλέπουμε πάντα στη θετική πλευρά των πραγμάτων και να τα διαχειριζόμαστε. Στο μέτρο που ξεφεύγει η κατάσταση πρέπει να τυγχάνει μιας διαχείρισης για να μπορεί πάνω από όλα να διατηρηθεί η κοινωνική συνοχή», κατέληξε.<br/>
  Αμέσως μετά την ομιλία της η πρόεδρος εισήλθε στον χώρο του Μουσείου όπου ξεναγήθηκε από τον κ. πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό και την αντιπρόεδρο κ. Νάσια Δαφιώτη και έγραψε τις εντυπώσεις της στο βιβλίο επισκεπτών. Κατά την έξοδό της από την αίθουσα του Μουσείου, προσφέρθηκε στην πρόεδρο και την συνοδεία της ένα κέρασμα και της δόθηκαν τα παρακάτω δώρα σε ανάμνηση της επίσκεψης της στη Σκάλα Λουτρών.
  1 βιβλίο – λεύκωμα με τίτλο -«ΦΩΚΑΙΑ 1913-1920» Η μαρτυρία του felix Sartiaux- με αναφορά στον Α! διωγμό του 1914 καθώς και στα τραγικά γεγονότα που τότε συνέβησαν στην Παλαιά Φώκαια της Μ. Ασίας.<br/>
  1 βιβλίο με τίτλο «Η ΕΛΛΑΣ ΣΤΗ ΜΙΚΡΑ ΑΣΙΑ – ΜΙΚΡΑΣΙΑΤΙΚΉ ΚΑΤΑΣΤΡΟΦΗ» του Μιχαήλ Ροδά ο οποίος ήταν διευθυντής του γραφείου τύπου της Ύπατης Αρμοστείας της Σμύρνης και στο οποίο αναφέρονται όλα τα έγγραφα που διακινήθηκαν μέσω της Αρμοστείας την κρίσιμη περίοδο 1919-1922.
  1 βιβλίο του Κ. Πολίτη με τίτλο «Χρυσόστομος ο Σμύρνης» το οποίο είχε δημοσιευθεί στην εφημερίδα ΠΑΤΡΙΣ το 1936, το οποίο δεν κυκλοφορεί στο εμπόριο και τέλος <br/>
  1 συλλεκτικό ημερολόγιο του 2008 που είχε εκδώσει ο Σύλλογος μας με τίτλο «Μουσείο Προσφυγικής Μνήμης 1922» και το οποίο περιέχει πολλές φωτογραφίες από τα εκθέματα του Μουσείου. <br/>
	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />


	


	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}></Typography>	
	<img src={Image37} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
  	  
  
    <Typography variant="h3" sx={theme.typography.typography2}> 
	  Καθώς πλησιάζει η συμπλήρωση 100 χρόνων από την Μικρασιατική καταστροφή, το Κέντρο Μικρασιατικών Σπουδών κυκλοφόρησε σε ψηφιακό δίσκο την «Σμυρναϊκή βιβλιογραφία 1764-1922. Ο ψηφιακός δίσκος  περιλαμβάνεται  μέσα στο βιβλίο της κ. Ιωάννας Πετροπούλου, με τίτλο «Η Σμύρνη των βιβλίων, συγγραφείς, μεταφραστές, εκδότες, τυπογράφοι 1764-1922» Αθήνα 2020.<br/>
  Αρχικός κορμός της έκδοσης αυτής αποτέλεσε η πολύτιμη καταγραφή του ιατρού και συλλέκτη κ. Αθανασίου Χατζηδήμου (1910-1967) ο οποίος γεννήθηκε στο Αϊδίνι της Μικράς Ασίας, ενηλικιώθηκε στη Μυτιλήνη και σπούδασε στο Παρίσι… <br/>
  Αυτό το χειρόγραφο πόνημα του κ. Αθανασίου Χατζηδήμου περιελάμβανε καταγραφή 940 βιβλίων που είχαν εκδοθεί στη Σμύρνη μέχρι το 1922.
  Το βιβλίο μαζί με τον ψηφιακό δίσκο  αποτελεί σίγουρα ένα σπουδαίο εργαλείο ιστορικής γνώσης και ο Σύλλογος μας ευχαριστεί θερμά το Κέντρο Μικρασιατικών Σπουδών που είχε την καλοσύνη να μας το αποστείλει μετά από σχετικό αίτημα μας.

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />




	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Μνήμες Μικρασίας</Typography>	
	<img src={Image39} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
	<img src={Image41} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Με την παρουσία του Περιφερειάρχη κ. Κώστα Μουτζούρη, του προέδρου του Περιφερειακού Συμβουλίου κ. Κωνσταντίνου Αρώνη, του προέδρου της Κοινότητας Λουτρών κ. Βασίλη Μαμώλη, του καθηγητή κ. Μιχάλη Ανδρεαδέλλη και της συγγραφέως κ. Μιράντας Σοφιανού πραγματοποιήθηκαν οι εκδηλώσεις με τίτλο «Μνήμες Μικρασίας» το Σάββατο 22 Αυγούστου 2020 στην αυλή του «Μουσείου Προσφυγικής Μνήμης 1922» στη Σκάλα Λουτρών.<br/>
   Στην αρχή της εκδήλωσης η αντιπρόεδρος του Συλλόγου κ. Νάσια Δαφιώτη αφού καλωσόρισε εκ μέρους του Δ.Σ. το κοινό, έδωσε το λόγο στον Περιφερειάρχη κ. Κώστα Μουτζούρη ο οποίος συνεχάρη τον Σύλλογο για τις δραστηριότητες του και μίλησε για τις ομορφιές της Σκάλας Λουτρών οι οποίες αξίζει, όπως είπε, να έχουν μια καλύτερη προοπτική. <br/>
   Στη συνέχεια τον λόγο πήρε ο πρόεδρος του Συλλόγου κ. Δημήτρης Παπαχρυσός ο οποίος αφού ευχαρίστησε τον περιφερειάρχη για την στήριξη που παρέχετε από την Π.Β.Α., και τον πρόεδρο του Περιφερειακού Συμβουλίου, αναφέρθηκε στο θέμα της εκδήλωσης που ήταν ένα Αφιέρωμα - Μαρτυρία στον Παντελή Σαλιάρη – Θωμαίδη από τον Γκιουλμπαξέ της Ερυθραίας της Μικράς Ασίας και συνοδευόταν από πολλές φωτογραφίες και κειμήλια της οικογένειας Θωμαίδη τα οποία έχουν δωριθεί και φιλοξενούνται στο Μουσείο Προσφυγικής Μνήμης 1922 στη Σκάλα Λουτρών. Εδώ να σημειώσουμε ότι αυτό το αφιέρωμα επρόκειτο να παρουσιασθεί στις 20 Μαρτίου 2020 στο Δημοτικό Θέατρο Μυτιλήνης, σε μια από κοινού  εκδήλωση με το Πειραματικό Λύκειο Μυτιλήνης, το 2ο Γυμνάσιο Νέας Ιωνίας, το 2ο Λύκειο Χαλανδρίου και το 1ο Λύκειο Βριλησσίων, η οποία όμως ματαιώθηκε λόγω των απαγορεύσεων που υπήρχαν τότε για τους κλειστούς χώρους ένεκα κορωνοϊού.  <br/>
   Ακολούθησε ένα 12λεπτο βιντεάκι με τίτλο «Σαν Παραμύθι» που ήταν εργασία του 2ου Γυμνασίου Νέας Ιωνίας και που επρόκειτο και αυτό να προβληθεί στις 20 Μαρτίου στο Δημοτικό Θέατρο Μυτιλήνης. <br/>
  Σ αυτό παρακολουθήσαμε την μαρτυρία της κ. Ευλαμπίας Λάλα από τα Βουρλά της Μ. Ασίας και αμέσως μετά η εκδήλωση έκλεισε με το κέρασμα ενός παραδοσιακού γλυκού «Σαραγλί», φτιαγμένο από τον Γυναικείο Συνεταιρισμό Μεσοτόπου σε αντικατάσταση του παραδοσιακού καβουρμά που λόγω των μέτρων για τον κορωνοϊό δεν φτιάξαμε φέτος. <br/>
  Τέλος πραγματοποιήθηκε η κλήρωση της λαχειοφόρου αγοράς του Συλλόγου και ο αριθμός 500 κέρδισε την τηλεόραση των 40 ιντσών, ενώ κατά την διάρκεια της εκδήλωσης υπήρχε στον χώρο, έκθεση φωτογραφιών του Γάλου αρχαιολόγου  Felix Sartiaux σχετική με τον Α! διωγμό από τα παράλια της Μ. Ασίας.
   Οι εκδηλώσεις Μνήμες Μικρασίας, ολοκληρώθηκαν την Δευτέρα το πρωί 23 Αυγούστου 2020 στην Παναγιά την Αψηλή όπου τελέσθηκε η καθιερωμένη Θεία Λειτουργία με την παρουσία πολλών κατοίκων, από την Σκάλα Λουτρών, τα Λουτρά και το Πέραμα της Γέρας. 

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />





	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Το τραγούδι της φτερωτής</Typography>	
	<img src={Image43} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
   
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Μας εστάλη από τον εκπαιδευτικό, συγγραφέα και φίλο του  Συλλόγου μας κ. Φώτη Βασίλογλου το νέο του πόνημα με τίτλο «Το τραγούδι της φτερωτής στην κοιλάδα με τους ανεμόμυλους». <br/>
  Ο Φώτης Βασίλογλου έχει καταγωγή από τον Μεσότοπο, είναι υποδιευθυντής στο 3ο Δημοτικό σχολείο Αργυρούπολης και ενεργό μέλος του Συλλόγου Μεσοτοπιτών Λέσβου « Η Αναγέννηση», μάλιστα διετέλεσε πρόεδρος του Συλλόγου για αρκετά χρόνια και είχαμε συνεργαστεί μαζί του όταν τους φιλοξενήσαμε το 2017 στο θερινό αμφιθέατρο της Σκάλας Λουτρών σε μια εκδήλωση που είχαμε από κοινού διοργανώσει. Ο  Κ. Φώτης Βασίλογλου έχει τιμηθεί για το συγγραφικό του έργο, από την Ακαδημία Αθηνών και από το Υπουργείο Παιδείας.<br/>
Κάποια από τα βιβλία του είναι τα παρακάτω:<br/><br/>
«Ένα καλάθι ιστορίες-η τέχνη της καλαθοπλαστικής στον Μεσότοπο Λέσβου» το 2010, « Ως τρέχ του νιρό… Τα νερά και οι ιστορίες τους στον Μεσότοπο Λέσβου» το 2013 κ.α. <br/>
  Ευχόμαστε από καρδιάς καλή επιτυχία στην νέα αυτή έκδοση του φίλου μας κ. Φώτη Βασίλογλου και να είναι πάντα γερός και ενεργός για να απολαμβάνουμε και να διδασκόμαστε από τα βιβλία του.<br/>

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />




	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Παρουσίαση βιβλίου</Typography>	
	<img src={Image45} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
	<img src={Image47} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Την Κυριακή 23 Αυγούστου 2020 το απόγευμα, στην αυλή του «Μουσείου Προσφυγικής Μνήμης 1922» στη Σκάλα Λουτρών παρουσιάστηκε το βιβλίο της κ. Μιράντας Σοφιανού «Οι συνταγές της Λόπης – Η Οδύσσεια της Πηνελόπης από την Πέργαμο της Μικράς Ασίας, μέσα από το τετράδιο της».<br/>
  Για το βιβλίο, μίλησαν οι εκπαιδευτικοί κ. Βάσω Αλατζά και κ. Κλεονίκη Βουγιουκλή – Χρόνη, η αρχαιολόγος, επίτιμη Διευθύντρια του Υπουργείου Πολιτισμού κ. Αγλαΐα Αρχοντίδου – Αργύρη και η συγγραφέας κ. Μιράντα Σοφιανού η οποία αναφέρθηκε στην ιστορία της οικογένειας της και ιδιαίτερα της Λόπης καθώς και στην ιστορία του βιβλίου.<br/>
   Επίσης, για το βιβλίο και για τη σχέση του  Συλλόγου Μικρασιατών Σκάλας Λουτρών «Το Δελφίνι» με τη συγγραφέα μίλησε ο πρόεδρος του Συλλόγου κ. Δημήτρης Παπαχρυσός, ο οποίος έκανε και μια σύντομη αναφορά για τον χώρο του «Μουσείου Προσφυγικής Μνήμης 1922» καθώς και για τον προσφυγικό Συνοικισμό της Σκάλας Λουτρών, ενώ ποιήματα της συγγραφέως διάβασε η αντιπρόεδρος κ. Νάσια Δαφιώτη.
   Τον συντονισμό της εκδήλωσης είχε ο δημοσιογράφος κ. Στρατής Μπαλάσκας, ο οποίος μας μίλησε με αρκετές λεπτομέρειες για την Πέργαμο, πατρίδα της συγγραφέως αλλά και δική του.<br/>
  Η συγγραφέας κ. Μιράντα Σοφιανού με δική της επιθυμία χάρισε όλα τα έσοδα από την πώληση των βιβλίων στον Σύλλογο, με σκοπό να χρησιμοποιηθούν για την συντήρηση του «Μουσείου Προσφυγικής Μνήμης 1922».<br/>
   Η εκδήλωση έκλεισε με μια μικρή Συναυλία κλασικής μουσικής από τους νέους Σολίστ του Μουσικού Σωματείου Gina Bachauer, που είχαν την τύχη να απολαύσουν, όσοι βρέθηκαν στη Σκάλα Λουτρών το βράδυ της Κυριακής 23 Αυγούστου 2020.<br/>
  * Η παρουσίαση του βιβλίου οργανώθηκε, από τον Σύλλογο μας σε συνεργασία με τον Πολιτιστικό Οργανισμό «Culture4All / Πολιτισμός για Όλους».
  <img src={Image49} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
        <br/><br/> <br/><br/>
  <h2>Λίγα λόγια για το βιβλίο</h2>	
  </Typography>
  <Typography variant="h3" sx={theme.typography.typography2}> 
Η Λόπη, θεία της Μιράντας Σοφιανού, ήρθε σε νεαρή ηλικία στη Μυτιλήνη, χάνοντας κατά τη Μικρασιατική καταστροφή τις δυο της αδερφές και τη μητέρα της. Με τη στήριξη του θείου της Κωστή Φίλιογλου καθώς και του νονού της, του διάσημου Γερμανού αρχαιολόγου Wilhelm Dörpfeld, είχε πάντα στο προσκεφάλι της τα επτά τελευταία γράμματα από τις αδερφές της, σταλμένα τον Αύγουστο του 1922 από την Πέργαμο στη Μυτιλήνη.
Η νεαρή δασκάλα, βυθισμένη πάντα στη μεγάλη απώλεια, πέρασε όλη τη ζωή της περιμένοντας ένα καλό μαντάτο, ελπίζοντας πως οι αγαπημένες της ζουν. Περίμενε μαγειρεύοντας και μαγειρεύοντας, νοστιμίζοντας τις ζωές των άλλων.<br/>
Ο πρόλογος του βιβλίου δίνει ανάγλυφα –τόσο με το κείμενο όσο και με τις φωτογραφίες των ντοκουμέντων που συγκέντρωσε και φύλαξε με αγάπη η συγγραφέας– την ιστορία της Μικρασιατικής ευμάρειας και του πλούτου, και κατόπιν της καταστροφής, μέσα από τη ζωή αυτού του κοριτσιού.
Ακολουθεί ο χείμαρρος των συνταγών, που και μόνο με τον καλλιγραφικό χαρακτήρα της Λόπης φανερώνουν τη λεπτότητα και το υψηλό επίπεδο του πολιτισμού των ανθρώπων της Περγάμου.<br/>
Στις 240 έγχρωμες σελίδες του, το βιβλίο περιλαμβάνει 203 συνταγές γλυκισμάτων και φαγητών καθώς και 60 φωτογραφίες αντικειμένων του τραπεζιού της εποχής εκείνης, αντικειμένων που γίνονται πρωταγωνιστές της έκδοσης. Σημαντικά στοιχεία υψηλής αισθητικής στο τραπέζι, το ιερό σημείο που συγκέντρωνε την οικογένεια μετατρέποντας το καθημερινό γεύμα σε τελετουργία.<br/>

  </Typography>
	
	
	</Box>
	
	
	 <ScrollToTopButton />





	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ημέρα μνήμης της γενοκτονίας των Ελλήνων της Μικράς Ασίας</Typography>	
	<img src={Image51} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
   
    <Typography variant="h3" sx={theme.typography.typography2}> 
	   Πραγματοποιήθηκαν και φέτος, μέσα σε κλίμα συγκίνησης, οι εκδηλώσεις τιμής και μνήμης για την γενοκτονία των Ελλήνων της Μικράς Ασίας.
   Σύμφωνα με τις διατάξεις του Προεδρικού Διατάγματος 304/2001, η 14η Σεπτεμβρίου ορίζεται ως ημέρα Εθνικής μνήμης της γενοκτονίας των Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος. Η επέτειος μνήμης έχει σκοπό να τονίσει ιδιαίτερα και να υπενθυμίσει τα γεγονότα της δραματικής περιόδου της γενοκτονίας και του ξεριζωμού των Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος, που είχαν ως αποτέλεσμα να μετατρέψουν τους Έλληνες της Μικράς Ασίας σε έναν λαό προσφύγων της διασποράς.<br/>
 Για το όνειρο που έσβησε, την κόλαση, τη φρίκη, τη σφαγή ανδρών, γυναικών και βρεφών, την εξόντωση, το ολοκαύτωμα που ακολούθησε, έχουν μιλήσει οι μάρτυρες, -κυριολεκτικά και μεταφορικά μάρτυρες- που το έζησαν, έχουν καταθέσει την αλήθεια τους για τους ανθρώπους τους που είδαν να ατιμάζονται και να μετατρέπονται σε κουρέλια μπροστά στα μάτια τους. Οι περιγραφές τους είναι ανατριχιαστικές, γεννούν αποτροπιασμό και κάνουν το νου να σαλεύει, γιατί ενσαρκώνουν τους στίχους του Σολωμού :<br/><br/>

<b>Παντού φόβος και τρομάρα, και φωνές και στεναγμοί<br/>
Παντού κλάψα, παντού αντάρα και παντού ξεψυχισμοί...</b><br/><br/>

 - Το πρόγραμμα εκδηλώσεων αυτής της επετείου μνήμης πραγματοποιήθηκε την Κυριακή 20 Σεπτεμβρίου 2020 και περιελάμβανε:<br/>
* Τέλεση δοξολογίας και αρτοκλασίας στον Ιερό Ναό Αγίου Νικολάου στην Επάνω Σκάλα.<br/>
*Τον πανηγυρικό της ημέρας εκφώνησε, ο Θεολόγος κ. Ν. Ματθαίου Συντονιστής Εκπαιδευτικού Έργου ΠΕ-01 Θεολόγων του 1ου ΠΕΚΕΣ Μυτιλήνης.* *Επιμνημόσυνη δέηση, με τη συμμετοχή της Στρατιωτικής μουσικής της 98 ΑΔΤΕ και κατάθεση στεφάνων στο χώρο του αγάλματος της Μικρασιάτισσας Μάνας, από εκπροσώπους της Κυβέρνησης, της Περιφέρειας Βορείου Αιγαίου, του Δήμου Λέσβου, του Στρατιωτικού Διοικητή, τους εκπροσώπους Πολιτικών Κομμάτων, αντιπροσωπείες των Απόστρατων Αξιωματικών, εκπροσώπους των Εφεδροπολεμιστικών Οργανώσεων, εκπροσώπους νομικών προσώπων και Συλλόγων.   <br/>            
  Το Δ.Σ. του Συλλόγου μας, όπως κάθε χρόνο, παρευρέθη στις προαναφερθείσες εκδηλώσεις μνήμης, με κατάθεση στεφάνου από τον πρόεδρο κ. Δ. Παπαχρυσό. 

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />




	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Μουσικό τμήμα</Typography>	
	<img src={Image53} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
    
    <Typography variant="h3" sx={theme.typography.typography2}> 
    Λειτουργεί στην Βιβλιοθήκη του Συλλόγου κάθε Σάββατο, από τις 12 το μεσημέρι μέχρι τις 5 το απόγευμα. Τα μαθήματα γίνονται στην Βιβλιοθήκη του Συλλόγου και εκτός από πρακτικά είναι και θεωρητικά έτσι ώστε τα παιδιά να αποκτούν γερές βάσεις και να συνεχίσουν μελλοντικά τις σπουδές τους στην μουσική εφ όσον το επιθυμήσουν. Η μηνιαία συνδρομή είναι 20 ευρώ και όσοι ενδιαφέρονται για τα παιδιά τους μπορούν να έρθουν σε επικοινωνία με τον πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό στο τηλ. 69746.40419 για περισσότερες πληροφορίες. Δάσκαλος είναι ο κ. Μολυβιάτης Γρηγόρης ο οποίος επί πολλά χρόνια έχει προσφέρει εξαιρετικό έργο. Υπενθυμίζουμε ότι τα μαθήματα έχουν προσωρινά διακοπεί λόγω κορωνοϊού και θα επανέλθουν αμέσως μετά την λήξη της καραντίνας.	
    </Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />





	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Νέα τμήματα</Typography>
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Ο Σύλλογός μας μπορεί να ιδρύσει νέα τμήματα παραδοσιακών μουσικών οργάνων όπως π.χ. βιολί, λαούτο κτλ εφ όσον εκδηλωθεί σχετικό ενδιαφέρον από τα μέλη μας. Διευκρινίζουμε ότι για να λειτουργήσει ένα νέο τμήμα θα πρέπει να ενδιαφερθούν κατ ελάχιστο τρία άτομα.
	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />









	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Τμήματα παραδοσιακών τραγουδιών και χορών</Typography>	
	<img src={Image55} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
    
  
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Πολύ σύντομα θα ξεκινήσουν να λειτουργούν τμήματα παραδοσιακών χορών με δασκάλα την κ. Φλώρα Αντωνοπούλου και όσοι ενδιαφέρονται μικροί και μεγάλοι μπορούν να έλθουν σε συνεννόηση με το Δ.Σ. του Συλλόγου η με τον κ. Δημήτρη Παπαχρυσό στο τηλ. 69746.40419.

  
	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />





	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Τμήμα βιβλιοθήκης</Typography>	
	
    
  
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Με 7.500 περίπου τίτλους βιβλίων είναι εμπλουτισμένη η βιβλιοθήκη του Συλλόγου μας τα οποία είναι διαθέσιμα  για να δανειστούν στα μέλη μας, αλλά και σε όσους άλλους ενδιαφέρονται. Όλα τα βιβλία είναι καταχωρημένα σε ηλεκτρονικό υπολογιστή για την εύκολη διαχείριση τους.<br/>
  Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα προβλήματα που αποτελούν  τη ζωή του σύγχρονου ανθρώπου και τοποθετεί τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και φανατισμούς.

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />






	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Δωρεά βιβλίων στο Σύλλογό μας</Typography>	
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Ευχαριστούμε θερμά  τους  κ.κ. Δεληγιάννη Νικόλαο, Μουσταφά Καντέρ, Συριανόγλου Παρασκευά, Αντωνόπουλο Κωνσταντίνο, Κυπριώτου Κοραλία, Πελεκάνο Θεόδωρο και Χατζηδήμο Αλέξανδρο για τα βιβλία που πρόσφεραν στην βιβλιοθήκη του Συλλόγου μας.
	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />






	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ευχαριστήρια</Typography>
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Ευχαριστούμε θερμά τους παρακάτω:<br/>
	*Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο μας.<br/>
	*Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες συντήρησης των κτιρίων του Συλλόγου, καθώς και όσους προσφέρουν εθελοντικώς την εργασία τους.

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />






	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Οικονομικές ενισχύσεις</Typography>	
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.<br/><br/>

Γρημάνη Μυρσίνη                  100 ευρώ<br/>
Κατανάκης Μιχάλης               50 ευρώ<br/>
Χατζηδήμος Αλέξανδρος        50 ευρώ<br/>
Κεφαλίδηε Δημήτρης              30 ευρώ<br/>
Μούχαλου Ελισάβετ                20 ευρώ<br/>
Wolf Bawic                                5 ευρώ<br/>
Μαμώλης Κώστας                   10 ευρώ<br/>
Φωτεινός Γεώργιος                  10 ευρώ<br/>
Δεσπότου Έλλη                        150 ευρώ, στη μνήμη της μητέρας της Ευαγγελίας.<br/>
Κλήμη …                                 50 ευρώ, στη μνήμη Αριστείδη και Μερόπης Κλήμη<br/>
Λευκαδίτης Τάκης                   25 ευρώ,  στη μνήμη της μητέρας του Μερακλίνας.<br/><br/>

Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός  για αναβάθμιση του οικισμού μας , έτσι ώστε να μπορέσουμε να ανταποκριθούμε στο βαρύ φορτίο των υποχρεώσεων που έχουμε αναλάβει.

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />




	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Για καταθέσεις συνδρομών και ενισχύσεων</Typography>
    <Typography variant="h3" sx={theme.typography.typography2}> 
	Για καταθέσεις συνδρομών και ενισχύσεων στο Σύλλογο Τράπεζα Πειραιώς Αριθμός Λογαριασμού <u>6355-010482-750</u>  <u>IBAN:  GR94 0171 3550 0063 5501 0482 750     	</u>

	</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />




	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Επετειακή έκδοση του Συλλόγου</Typography>
	<img src={Image57} style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />   
	
    <Typography variant="h3" sx={theme.typography.typography2}> 
	<u>Λεύκωμα για την συμπλήρωση 30 ετών από την ίδρυση του Συλλόγου.</u><br/>
Στόχος μας, να φρεσκάρουν τη μνήμη τους οι ηλικιωμένοι και να διδαχθούν οι νεώτερες γενιές από αυτό, για να μπορέσουν να συνεχίσουν αυτό το σπουδαίο έργο που τους αφήνουμε παρακαταθήκη και ας μην ξεχνάμε πως, η γνώση του παρελθόντος αποτελεί σοφία για το μέλλον...<br/>
Το λεύκωμα αποτελείται από 340 έγχρωμες σελίδες μεγάλου μεγέθους διαστάσεων 33χ23 cm  και έχει σκληρόδετο εξώφυλλο όπως ο τόμος μιας εγκυκλοπαίδειας, θα διατεθεί δε στα μέλη του Συλλόγου σε τιμή κόστους για να μη λείψει από κανένα σπίτι.

</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />




    </>
  );
}

export default D17_drastiriotites_2020;
