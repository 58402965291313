import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image02 from "../assets/images/drastiriotites2017/image002.jpg";
import Image04 from "../assets/images/drastiriotites2017/image004.jpg";
import Image06 from "../assets/images/drastiriotites2017/image006.jpg";
import Image08 from "../assets/images/drastiriotites2017/image008.jpg";
import Image10 from "../assets/images/drastiriotites2017/image010.jpg";
import Image12 from "../assets/images/drastiriotites2017/image012.jpg";
import Image14 from "../assets/images/drastiriotites2017/image014.jpg";
import Image16 from "../assets/images/drastiriotites2017/image016.jpg";
import Image18 from "../assets/images/drastiriotites2017/image018.jpg";
import Image20 from "../assets/images/drastiriotites2017/image020.jpg";
import Image22 from "../assets/images/drastiriotites2017/image022.jpg";
import Image24 from "../assets/images/drastiriotites2017/image024.jpg";
import Image26 from "../assets/images/drastiriotites2017/image026.jpg";
import Image28 from "../assets/images/drastiriotites2017/image028.jpg";
import Image30 from "../assets/images/drastiriotites2017/image030.jpg";
import Image32 from "../assets/images/drastiriotites2017/image032.jpg";
import Image34 from "../assets/images/drastiriotites2017/image034.jpg";
import Image36 from "../assets/images/drastiriotites2017/image036.jpg";
import Image38 from "../assets/images/drastiriotites2017/image038.gif";
import Image40 from "../assets/images/drastiriotites2017/image040.jpg";
import Image42 from "../assets/images/drastiriotites2017/image042.jpg";
import Image44 from "../assets/images/drastiriotites2017/image044.jpg";
import Image46 from "../assets/images/drastiriotites2017/image046.jpg";
import Image48 from "../assets/images/drastiriotites2017/image048.jpg";
import Image50 from "../assets/images/drastiriotites2017/image050.jpg";
import Image52 from "../assets/images/drastiriotites2017/image052.jpg";
import Image54 from "../assets/images/drastiriotites2017/image054.jpg";
import Image56 from "../assets/images/drastiriotites2017/image056.jpg";
import Image58 from "../assets/images/drastiriotites2017/image058.jpg";
import Image60 from "../assets/images/drastiriotites2017/image060.jpg";
import Image62 from "../assets/images/drastiriotites2017/image062.jpg";
import Image64 from "../assets/images/drastiriotites2017/image064.jpg";
import Image66 from "../assets/images/drastiriotites2017/image066.jpg";
import Image68 from "../assets/images/drastiriotites2017/image067.gif";
import Image70 from "../assets/images/drastiriotites2017/image069.jpg";
import Image71 from "../assets/images/drastiriotites2017/image071.jpg";
import Image73 from "../assets/images/drastiriotites2017/image073.jpg";
import Image75 from "../assets/images/drastiriotites2017/image075.jpg";

function D14_drastiriotites_2017() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Οι δραστηριότητές μας το 2017" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Κοπή της Βασιλόπιτας του Συλλόγου μας
        </Typography>
        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image04}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στην βιβλιοθήκη του Συλλόγου με τσουχτερό κρύο, την Κυριακή 8
          Ιανουαρίου 2017 στις 4 το απόγευμα το Δ.Σ. του Συλλόγου μας έκοψε την
          βασιλόπιτά του.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Παρόντες ο ιερέας της ενορίας μας πάτερ Παναγιώτης ο οποίος την
          ευλόγησε, ο ψάλτης και μέλος του Συλλόγου Σωτήρης Μαμουνής, ο πρόεδρος
          του Τοπικού μας Συμβουλίου Βασίλης Μαμώλης, ο πρόεδρος του Συλλόγου
          της παλιάς αγοράς Ιωάννης Μολυβιάτης, ο πρόεδρος του Αετού Λουτρών
          Νίκος Σπανός και αρκετά μέλη του Συλλόγου.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το φλουρί, για τρίτη συνεχόμενη χρονιά, ήταν προσφορά από το
          χρυσοχοείο «Βέτσικα» και μετά από κλήρωση το κέρδισε η κ. Μίνα
          Μαμουνή. Δεύτερος τυχερός μετά το φλουρί ήταν ο Ευστράτιος
          Χατζηβασιλείου ο οποίος κέρδισε ένα επιτραπέζιο ρολόι, προσφορά από το
          κατάστημα «Ρολογάδικο» του κ. Ιωάννη Μολυβιάτη. Στους παρόντες
          προσφέρθηκαν καφές, αναψυκτικά, και ποτά με διάφορα εδέσματα.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στο τέλος της εκδήλωσης έγινε η κλήρωση των δώρων της λαχειοφόρου
          αγοράς και κέρδισαν οι παρακάτω αριθμοί:
          <br />Ο αριθμός <b>410</b> κέρδισε την τηλεόραση, ο αριθμός <b>174</b>{" "}
          κέρδισε το TABLET ο αριθμός <b>758</b> κέρδισε το ηλεκτρικό υπόστρωμα,
          ο αριθμός <b>892</b> κέρδισε το αερόθερμο και τέλος ο αριθμός{" "}
          <b>754</b> κέρδισε το κομπρεσέρ αέρος αυτοκινήτου..
          <br />
          Και του χρόνου με υγεία.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Πάσχα στη Σκάλα Λουτρών
        </Typography>
        <img
          src={Image06}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image08}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Με κατάνυξη πραγματοποιήθηκαν και φέτος οι ιερές ακολουθίες της
          Μεγάλης εβδομάδας στο εκκλησάκι του Αγίου Παντελεήμονα της Σκάλας
          Λουτρών. Ο Σύλλογός μας μερίμνησε και εφέτος σε συνεργασία με τον
          ιερέα της ενορίας μας και με το γραφείο της Ιεράς Μητροπόλεως
          Μυτιλήνης να καλυφθούν οι ανάγκες των κατοίκων με την παρουσία ιερέως
          ειδικά για αυτές τις Άγιες Ημέρες.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Φέτος ήταν η δωδέκατη συνεχής χρονιά που πραγματοποιήθηκαν οι
          θρησκευτικές εκδηλώσεις των αγίων ημερών του Πάσχα στη Σκάλα Λουτρών.
          Το πρωί της Κυριακής των Βαΐων, ετελέσθη η θεία λειτουργία από τον
          πρωτοσύγκελο της Ιεράς Μητροπόλεως Μυτιλήνης π. Ιάκωβο με μεγάλη
          συμμετοχή πιστών και το απόγευμα της ίδιας ημέρας η ακολουθία του
          Νυμφίου. Εν συνεχεία την Μ. Τετάρτη το απόγευμα ετελέσθη το Μυστήριο
          του Ευχελαίου και την Μ. Πέμπτη η ακολουθία των παθών με τα 12
          Ευαγγέλια από τον ιερέα της ενορίας μας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το βράδυ της Μ. Πέμπτης πραγματοποιήθηκε ο στολισμός του επιταφίου από
          τις γυναίκες και τα νέα κορίτσια του οικισμού μας, που αφιέρωσαν με
          μεγάλη χαρά πολλές ώρες, για να είναι ο στολισμός του επιταφίου ο
          καλύτερος. Γνωστά τα ονόματά τους σε όλους μας (Ευθαλία, Γραμματική,
          Δέσποινα, Ανθούλα, Άννα, Ελένη, Ειρήνη, Βασιλεία, Βικτωρίτσα,
          Κατερίνα, Κλεονίκη, Βασούλα, Βούλα, και τόσες άλλες…). Βάσει του
          εθίμου, κατά την διάρκεια του στολισμού, εψάλη το τραγούδι της
          Παναγίας και άλλα εγκώμια από όλες τις κυρίες. Ενδιάμεσα του
          στολίσματος του επιταφίου ο Δημήτρης μοίρασε πρόσφορο, βουτηγμένο σε
          κόκκινο κρασί και έτσι ο στολισμός του επιταφίου στον Άγιο
          Παντελεήμονα, έληξε γύρω στις 3 τα ξημερώματα.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Μ. Παρασκευή στις 5 το απόγευμα έγινε η αποκαθήλωση του
          εσταυρωμένου και η περιφορά του επιταφίου σε όλο τον οικισμό από τον
          ιερέα της ενορίας μας π. Παναγιώτη, με θαυμάσιο καιρό και σε μια
          διαδρομή υπέροχη που δένει αρμονικά το πράσινο των αγρών με το γαλάζιο
          της θάλασσας. Την τελετή της περιφοράς συμπλήρωνε η χορωδία από ομάδα
          γυναικών μελών του Συλλόγου μας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τέλος το βράδυ του Μεγάλου Σαββάτου, μέσα σε μια κατανυκτική
          ατμόσφαιρα υπό το φως των κεριών ετελέσθη η αναστάσιμη Θεία Λειτουργία
          από τον πρωτοσύγκελο της Ιεράς Μητροπόλεως Μυτιλήνης π. Ιάκωβο.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο σύλλογός μας τηρώντας το έθιμο, στο τέλος της Θείας λειτουργίας
          μοίρασε σε όλους τους παρευρισκόμενους κόκκινα αυγά και τσουρέκια.
          Εμείς από την πλευρά μας ευχαριστούμε θερμά τον εφημέριο της ενορίας
          μας π. Παναγιώτη, τον πρωτοσύγκελο της Ιεράς Μητροπόλεως Μυτιλήνης π.
          Ιάκωβο, καθώς και τον Σεβασμιότατο Μητροπολίτη Μυτιλήνης, που
          μερίμνησαν και ικανοποίησαν το αίτημά μας για αποστολή ιερέα στον
          οικισμό μας τις ημέρες του Πάσχα. Η συμμετοχή των κατοίκων ήταν
          καθολική και ιδίως των ηλικιωμένων οι οποίοι προσήλθαν με μεγάλη χαρά.
          Και του χρόνου να είμαστε γεροί να ξαναγιορτάσουμε όλοι μαζί αυτές τις
          Άγιες ημέρες.
        </Typography>
        <br />
        <br />
        Και του χρόνου με υγεία.
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          2η Περιβαλλοντική δράση στην καμένη Αμαλή.
        </Typography>

        <img
          src={Image10}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image12}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με τις οδηγίες υπαλλήλου της Δασικής Υπηρεσίας πραγματοποιήθηκε την
          Κυριακή 5 Μαρτίου 2017 στις 10 το πρωί, για 2η συνεχή χρονιά,
          περιβαλλοντική δράση στην καμένη Αμαλή, κατά την διάρκεια της οποίας
          έγινε σπορά σπόρων πεύκου, με στόχο την αναδάσωση μέρους του καμένου
          δάσους από την προπέρσινη καταστροφική πυρκαγιά στην περιοχή της
          Χαραμίδας.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Διοργανωτές ήταν, η Δημοτική Κοινότητα Λουτρών, ο Σύλλογος Μικρασιατών
          Σκάλας Λουτρών «Το Δελφίνι», ο Πολιτιστικός Σύλλογος Λουτρών «Το
          Λιοστάσι», το Δημοτικό Σχολείο Λουτρών, ο Αετός Λουτρών καθώς και ο
          Σύλλογος Γονέων και Κηδεμόνων Δημοτικού Σχολείου και Νηπιαγωγείου
          Λουτρών.
          <br />
          Οι συμμετέχοντες στη δράση απέδειξαν για μια ακόμη φορά την ευαισθησία
          τους για το περιβάλλον και ότι, αγαπάνε το δάσος και νοιάζονται γι
          αυτό.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Παραθέτουμε φωτογραφίες με πευκάκια που έχουν φυτρώσει από την περσινή
          σπορά στο δάσος της Αμαλής. Κοιμήσου, πεύκο αγαπημένο,
          <br />
          μες στης νύχτας τη σιγαλιά.
          <br />
          Στην καταπράσινη αγκαλιά σου
          <br />
          χιλιάδες κούρνιασαν πουλιά.
          <br />
          Μικρή φωλίτσα παραδείσου,
          <br />
          πεύκο πανέμορφο κοιμήσου...
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Επιστολή του Συλλόγου μας προς την Δημοτική Κοινότητα Λουτρών
        </Typography>

        <img
          src={Image14}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image16}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την παρακάτω επιστολή έστειλε στις 20 Μαρτίου 2017 ο Σύλλογός μας προς
          την Δημοτική κοινότητα Λουτρών σχετική με τα προβλήματα που
          αντιμετωπίζουμε.
          <br />
          <br />
          Κύριε Πρόεδρε <br />
          Με την παρούσα επιστολή θέλουμε να σας ενημερώσουμε για κάποια
          επείγοντα προβλήματα που υπάρχουν στην Σκάλα Λουτρών και θα πρέπει να
          μεριμνήσετε για την σύντομη επίλυσή τους.
          <br />
          <br />
          1. Παρόλο που στο παρελθόν έχουμε επισημάνει το πρόβλημα με τα
          εγκαταλελειμμένα καραβάκια στο Λιμεναρχείο Μυτιλήνης, το πρόβλημα
          εξακολουθεί να υφίσταται και θα πρέπει να επιλυθεί άμεσα εν όψει
          μάλιστα της νέας τουριστικής σεζόν. Θα πρέπει επίσης να επισημανθεί
          στο Λιμεναρχείο ότι η μεταφορά τους στην Σκάλα Λουτρών είναι παράνομη
          δεδομένου ότι η περιοχή είναι ενταγμένη στο δίκτυο NATURA μέσα στο
          οποίο δεν επιτρέπονται διαδικασίες διάλυσης πλοίων και θα αναγκασθούμε
          εάν δεν υπάρξει συμμόρφωση να προβούμε σε άλλου είδους διαδικασίες. 2.
          Οι ευκάλυπτοι που υπάρχουν στους κοινόχρηστους χώρους της Σκάλας
          Λουτρών έχουν ξεφύγει πάρα πολύ σε ύψος και θεωρούμε ότι θα πρέπει να
          κλαδευτούν και κυρίως να περιοριστεί δραστικά το ύψος τους για να
          αποφύγουμε ενδεχόμενη πτώση τους από δυνατούς αέρηδες, πράγμα που έχει
          συμβεί κατά το παρελθόν ευτυχώς όμως χωρίς ιδιαίτερες ζημιές.
          <br />
          <br />
          3. Θέλουμε να σας υπενθυμίσουμε ότι η διαδικασία απαλλοτρίωσης της
          χαρακτηρισμένης από το Υπ. Πολιτισμού Προσφυγικής οικίας, καθυστερεί
          να περάσει από το Δημοτικό Συμβούλιο, παρ όλο ότι εμείς έχουμε
          προσκομίσει στον Δήμο ότι δικαιολογητικά μας έχουν ζητηθεί.
          <br />
          <br />
          4. Τέλος θέλουμε να σας ενημερώσουμε ότι επειδή εδώ και αρκετό καιρό,
          το αστικό λεωφορείο στρίβει περίπου 30 μέτρα πριν από την στάση που ο
          Σύλλογός μας είχε προ ετών κατασκευάσει, αυτή πλέον δεν εξυπηρετεί και
          θα πρέπει να μεριμνήσετε σε συνεννόηση με το ΚΤΕΛ, για την τοποθέτηση
          μιας νέας στο νέο σημείο τερματισμού.
          <br />
          <br />
          Σας ευχαριστούμε και περιμένουμε την σύντομή επίλυση των
          προαναφερθέντων προβλημάτων που σας εκθέσαμε.
          <br />
          <br />
          ΥΓ. Σε σχέση με τα προαναφερθέντα αναφέρουμε ότι τα περισσότερα
          προβλήματα έχουν ήδη επιλυθεί και έχουμε την υπόσχεση ότι πολύ σύντομα
          θα επιλυθούν όλες οι εκκρεμότητες που υπάρχουν.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Περιβαλλοντική δράση στη Σκάλα Λουτρών
        </Typography>

        <img
          src={Image18}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image20}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Σύλλογός μας «ΤΟ ΔΕΛΦΙΝΙ» συμμετείχε στην Πανελλαδική δράση
          καθαρισμού ακτών και κοινόχρηστων χώρων «<b>Lets do it Greece</b>» που
          πραγματοποιήθηκε την Κυριακή 2 Απριλίου 2017.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η συνάντηση για το ξεκίνημα της δράσης έγινε στο γήπεδο μπάσκετ της
          Σκάλας Λουτρών στις 09.30 το πρωί και καθαρίστηκαν όλοι οι
          κοινόχρηστοι χώροι και οι ακτές της Σκάλας Λουτρών.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το μήνυμα που δίδουμε κάθε χρόνο με αυτές τις εξορμήσεις καθαριότητας,
          είναι ότι είμαστε παρόντες στην προσπάθεια που πρέπει να γίνεται από
          όλους για την προστασία του περιβάλλοντος, των ακτών και των δημόσιων
          χώρων, που αποτελούν σημαντικό χώρο αναψυχής των πολιτών, πόλο έλξης
          επισκεπτών και πηγή οικονομικής ανάπτυξης του τόπου μας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε το Δήμο Μυτιλήνης και το Τοπικό Συμβούλιο Λουτρών, που
          μεριμνούν και μας διευκολύνουν σε θέματα καθαριότητας του οικισμού μας
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αρχαιρεσίες στον Σύλλογό μας.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε την Πέμπτη 20 Απριλίου 2017 και ώρα 6 το απόγευμα η
          συνεδρίαση του νέου Δ.Σ. του Συλλόγου Μικρασιατών της Σκάλας Λουτρών
          «ΤΟ ΔΕΛΦΙΝΙ» που προέκυψε από τις αρχαιρεσίες της 9-4-2017 και
          καθόρισε τις αρμοδιότητές του ως κάτωθι:
          <br />
          <br />
          <b>Πρόεδρος:</b> Παπαχρυσός Δημήτρης.
          <br />
          <b>Aντιπρόεδρος:</b> Δαφιώτη Νάσια. <br />
          <b>Γ. Γραμματέας:</b> Βέντου Δώρα.
          <br />
          <b>Ταμίας:</b> Σταματέλλη Ρένα.
          <br />
          <b>Μέλη:</b> Λιόφλου Βικτωρίτσα, Γρηγορέλλη Κατερίνα και Χιωτέλης
          Χαράλαμπος.
          <br />
          <b>Αν. Μέλος:</b> Καραντώνης Ευστράτιος.
          <br />
          <br />
          <b>Υπεύθυνοι τμημάτων του Συλλόγου:</b>
          <br />
          <br />
          <b>Στο Μουσείο Προσφυγικής Μνήμης 1922:</b> Τουρλή Ευθαλία, Παπαχρυσός
          Δημήτρης και Βέντου Δώρα.
          <br />
          <b>Στην βιβλιοθήκη του Συλλόγου:</b> Χιωτέλης Χαράλαμπος, Παπαχρυσός
          Δημήτρης και Σταματέλλη Ρένα.
          <br />
          <b>Στο τμήμα Περιβάλλοντος :</b> Παπαχρυσός Δημήτρης, Καραντώνης
          Ευστράτιος και Γρηγορέλλη Κατερίνα.
          <br />
          <b>Στο τμήμα Πολιτιστικών εκδηλώσεων:</b> Δαφιώτη Νάσια, Τουρλή
          Ευθαλία, Λιόφλου Βικτωρίτσα, και Καραντώνης Ευστράτιος.
          <br />
          <b>Στο Μουσικό τμήμα:</b> Δαφιώτη Νάσια και Σταματέλλη Ρένα.
          <br />
          <b>Υπεύθυνοι δημοσίων σχέσεων: </b>Δαφιώτη Νάσια και Παπαχρυσός
          Δημήτρης.
          <br />
          <br />
          <b>Τέλος</b> για τις εισπράξεις συνδρομών καθορίστηκαν ως βοηθοί του
          ταμία οι Παπαχρυσός Δημήτρης, Καραντώνης Ευστράτιος και Γρηγορέλλη
          Κατερίνα.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Καλλωπισμός του αύλιου χώρου Μουσείου Προσφυγικής Μνήμης 1922
        </Typography>

        <img
          src={Image22}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Κυριακή 21 Μαΐου 2017 πραγματοποιήθηκε περιβαλλοντική δράση
          καθαρισμού και καλλωπισμού του αύλιου χώρου του «Μουσείου Προσφυγικής
          Μνήμης 1922» με θέμα «Καλλωπίζω τον αύλιο χώρο του Μουσείου,
          φυτεύοντας λουλούδια». Για τον σκοπό αυτό αγοράσθηκαν και φυτεύτηκαν
          35 γλάστρες με λουλούδια και θάμνους στα υφιστάμενα παρτέρια με την
          συμμετοχή των μελών του Συλλόγου μας και όλων των μικρών μας φίλων, οι
          οποίοι έμαθαν με μεγάλη ευκολία τον τρόπο που φυτεύουμε τόσο τα
          λουλούδια όσο και τους θάμνους.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εργασίες συντήρησης στην Βιβλιοθήκη
        </Typography>

        <img
          src={Image24}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Με πρωτοβουλία του Δ.Σ. του Συλλόγου μας και με την αγορά των
          κατάλληλων υλικών, συντηρήθηκε εξωτερικά το κτίριο της Βιβλιοθήκης του
          Συλλόγου. Συγκεκριμένα βάφτηκαν όλα τα παράθυρα, οι πόρτες και οι
          εξωτερικές τοιχοποιίες του κτιρίου, με προσφορά εθελοντικής εργασίας
          από τα μέλη μας.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διαγωνισμός ζωγραφικής και έκθεσης.
        </Typography>
        <img
          src={Image26}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image28}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε στις 5 Ιουνίου 2016 από τον Σύλλογό μας «ΤΟ ΔΕΛΦΙΝΙ»
          με επιτυχία, <u>ο ετήσιος διαγωνισμός ζωγραφικής και έκθεσης</u> για
          το περιβάλλον, με τους μαθητές και τις μαθήτριες του δημοτικού
          σχολείου Λουτρών.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Οι τέσσερις πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα:{" "}
          <b>«Μια μέρα στη Θάλασσαυ»</b> και οι δύο μεγαλύτερες τάξεις
          διαγωνίσθηκαν στην έκθεση με θέμα:{" "}
          <b>
            «Πως μπορώ να συμβάλω στην προστασία του φυσικού περιβάλλοντος του
            χωριού μου».
          </b>
          .
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Τα παιδιά που διακρίθηκαν βραβεύτηκαν σε ειδική εκδήλωση που
          πραγματοποιήθηκε το Σάββατο 5 Αυγούστου 2017 στις 9.30 το βράδυ, στο
          κτήμα «Ελιά και θάλασσα» του ξενοδοχείου ΖΑΙΡΑ στη Σκάλα Λουτρών..
        </Typography>
        <br />
        <br />
        Διακρίθηκαν:
        <br />
        Από την <b>Α! Τάξη</b> οι Γκέντση Μαριτίνα και η Κλάβα Σοφία.. <br />
        Από την <b>Β! Τάξη</b> οι Δασκάλου Φίλιππος και ο Σαματζής Βασίλης..
        <br />
        Από την <b>Γ! Τάξη</b> οι Λιαδέλλη Ντορέττα και η Γκαγκάνη Πέρσα. .{" "}
        <br />
        Από την <b>Δ! Τάξη</b> οι Γκίζα Γεωργία και Μολυβιάτου Νίκη.
        <br />
        Από την <b>Ε! Τάξη</b> Τρουλλινού Κατερίνα και η Κέντση Μαρία.
        <br />
        Από την <b>ΣΤ! Τάξη</b> Γεωργιτζίκη Βιβή και ο Αλεξίου Γιώργος.
        <br />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το Δ.Σ. του συλλόγου μας ευχαριστεί την διευθύντρια και τους δασκάλους
          του Δημοτικού Σχολείου Λουτρών για την ουσιαστική βοήθεια και την
          συνεργασία που είχαμε κατά την διενέργεια του διαγωνισμού.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          6ος Κολυμβητικός διάπλους Μικρασιατικής Μνήμης
        </Typography>

        <img
          src={Image30}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image32}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε με επιτυχία και ικανοποιητική συμμετοχή κολυμβητών
          και κολυμβητριών ο 6ος Διάπλους του κόλπου Γέρας από την Κουντουρουδιά
          έως το Πέραμα την Κυριακή 23-7-2017.
          <br />
          Οι 59 κολυμβητές και κολυμβήτριες που συμμετείχαν διήνυσαν την
          απόσταση των 1.100 μέτρων που χωρίζει την Κουντουρουδιά από το Πέραμα
          χωρίς ιδιαίτερα προβλήματα αφού ο καιρός ήταν καλός και οι άνεμοι
          βόρειοι και μικρής εντάσεως.
          <br />
          Οι ηλικίες των κολυμβητών που συμμετείχαν ήταν από 6 έως 73 ετών και ο
          χρόνος που έκαναν κυμάνθηκε από 10 περίπου λεπτά ο πρώτος μέχρι 65
          λεπτά ο τελευταίος. Στην ασφαλή διεξαγωγή του κολυμβητικού διάπλου
          συνέβαλε αποφασιστικά, το πλωτό σκάφος του Λιμενικού Σώματος, το οποίο
          επέβλεπε συνεχώς τους κολυμβητές, καθώς και η παρουσία του ιατρού κ.
          Μάκη Αξιώτη που παρευρίσκετο για να προσφέρει τις πρώτες βοήθειες όπου
          χρειαζόταν.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Τις ευχαριστίες μας οφείλουμε σε όλους τους προαναφερθέντες, καθώς
          επίσης και στους κ.κ. Ευστράτιο Καραντώνη και Στέλιο Χιώτη, οι οποίοι
          διέθεσαν τις βάρκες τους για τη δωρεάν μεταφορά των αθλητών και των
          συνοδών τους από την Κουντουρουδιά στο Πέραμα και αντιστρόφως. Τέλος,
          σε όλους τους συμμετέχοντες δόθηκαν αναμνηστικά διπλώματα και
          καπελάκια για τη συμμετοχή τους. Και του χρόνου με υγεία.
          <br />
          <br />
          Διοργανωτές της εκδήλωσης ήταν:
          <br /> <br />
          Ο «Παγγεραγωτικός» Πολιτιστικός Σύλλογος Γέρας,
          <br />
          Ο Σύλλογος Μικρασιατών Σκάλας Λουτρών «ΤΟ ΔΕΛΦΙΝΙ», <br />
          Ο «Παγγεραγωτικός» Σύλλογος Αθηνών και
          <br />
          Ο Αθλητικός Μορφωτικός Σύλλογος Περάματος «Ποσειδώνας»
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Θερινή χοροεσπερίδα και βράβευση των αριστούχων μαθητών.
        </Typography>
        <img
          src={Image34}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image36}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Με επιτυχία πραγματοποιήθηκε η χοροεσπερίδα του Συλλόγου μας «ΤΟ
          ΔΕΛΦΙΝΙ» το Σάββατο 5 Αυγούστου 2017 στο χώρο «Ελιά και θάλασσα» του
          ξενοδοχείου «ΖΑΙΡΑ». Με την παρουσία του πρ. της δημοτικής μας
          κοινότητας κ. Β. Μαμώλη, του πρ. του Αετού Λουτρών κ. Ν. Σπανού, του
          πρ. του Παγγεραγωτικού Αθήνας κ. Στρ. Χατζηχρήστου, του πρ. των
          ερασιτεχνών αλιέων Μυτιλήνης κ. Ι. Μολυβιάτη και την συμμετοχή των
          μελών και των φίλων του Συλλόγου περάσαμε μια υπέροχη βραδιά δίπλα στη
          θάλασσα, με θέα τον κόλπο και τα απέναντι χωριά της Γέρας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Κατά την διάρκεια της εκδήλωσης έγινε μια σύντομη ενημέρωση από τον
          πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό, σχετική με τις τρέχουσες
          δραστηριότητες και εν συνεχεία πραγματοποιήθηκε η καθιερωμένη βράβευση
          των αριστούχων μαθητών του Γυμνασίου και του Λυκείου της δημοτικής
          κοινότητας Λουτρών.
          <br />
          <br />
        </Typography>
        <u>Βραβεύτηκαν οι παρακάτω: </u>
        <br />
        <br />
        Από την Α! γυμνασίου <br /> η Ευαγγέλου Χριστίνα <br />
        η Κοζπή Σταυρούλα <br />
        η Μύνικνα Ειρήνη και
        <br />
        ο Πλιακάτος Παναγιώτης
        <br />
        <br />
        Από την Β! γυμνασίου <br /> η Αλεξίου Μαρία
        <br />
        η Δράκου Δήμητρα
        <br />
        ο Κωστής Παρασκευάς και <br />
        ο Λέλεκας Κωνσταντίνος <br /> <br />
        Από την Γ! γυμνασίου <br /> η Γεωργιτζίκη Γαρυφαλιά
        <br />
        η Ζούρου Χριστίνα
        <br />
        ο Καλιοντζής Θεόδωρος
        <br />
        η Κοζπή Ταξιαρχούλα <br />
        η Παπαμακαρίου Μιχαηλία–Θεοδώρα και
        <br />
        η Σφακιανού Γαρυφαλιά <br />
        <br />
        Από την Α! λυκείου <br /> η Αχλάδα Μαρία και
        <br />
        ο Καραντώνης Μιχαήλ
        <br />
        <br />
        Από την Β! λυκείου <br /> ο Μοχεμπί Χαντί
        <br />
        <br />
        Από την Γ! λυκείου <br /> ο Ταλιαντζής Ιωάννης και
        <br />
        η Μύνηκνα Μαρία
        <br />
        <br />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Επίσης δόθηκαν έπαινοι σε παιδιά του Δημοτικού Σχολείου Λουτρών γιατί
          διακρίθηκαν σε διαγωνισμό ζωγραφικής και έκθεσης με θέμα το περιβάλλον
          ο οποίος είχε πραγματοποιηθεί στις 5 του περασμένου Ιουνίου.
          <br />
          <br />
          <u>Οι μαθητές που διακρίθηκαν είναι οι παρακάτω.</u>
          <br />
          <br />
          Από την Α! Τάξη η Γκέντση Μαριτίνα και η Κλάβα Σοφία. <br />
          Από την Β! Τάξη ο Δασκάλου Φίλιππος και ο Σαματζής Βασίλης.
          <br />
          Από την Γ! Τάξη η Λιαδέλλη Ντορέττα και η Γκαγκάνη Πέρσα <br />
          Από την Δ! Τάξη η Ντήνια Ειρήνη και η Τριανταφύλλου Θεανώ.
          <br />
          Από την Ε! Τάξη η Τρουλλινού Κατερίνα και η Κέντση Μαρία.
          <br />
          Από την ΣΤ! Τάξη η Γεωργιτζίκη Βιβή και ο Αλεξίου Γιώργος. <br />
          <Typography variant="h3" sx={theme.typography.typography2}>
            Σε όλα τα παιδιά, εκτός από τον έπαινο και το περιοδικό του Συλλόγου
            δόθηκε για πρώτη φορά και ένα πρωτότυπο δώρο το οποίο το ονομάσαμε
            «Το κλειδί της γνώσης». Αυτό ήταν ένα USB STICK 16 Gigabyte σε σχήμα
            κλειδιού που περιελάμβανε 12 βιβλία σχετικά με το περιβάλλον καθώς
            και ορισμένα βίντεο από τις εκδηλώσεις του Συλλόγου. Εδώ να
            αναφέρουμε ότι το γεύμα των αριστούχων παιδιών ήταν προσφορά της
            επιχείρησης «Catering Ελαίτης» εκφράζοντας έτσι τις ευχαριστίες μας
            γι αυτό.
          </Typography>
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Μέλος της Ο.Π.Σ.Ε. ο Σύλλογος μας
        </Typography>

        <img
          src={Image38}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image40}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Η ισχύς εν τη ενώσει!!!
          <br />
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Από τον περασμένο Φεβρουάριο ο Σύλλογος μας μετά από σχετική αίτηση
          του, είναι μέλος της Ομοσπονδίας Προσφυγικών Σωματείων Ελλάδος.
          Σύμφωνα με το καταστατικό κύριος σκοπός της Ομοσπονδίας Προσφυγικών
          Σωματείων Ελλάδος είναι:
          <br />
          Η διατήρηση της μνήμης των πέραν του Αιγαίου και αλλαχού αλησμόνητων
          και αλύτρωτων πατρίδων της καθ ημάς Ανατολής και η προβολή και
          διεκδίκηση σε Διεθνές επίπεδο των ιστορικών και απαράγραπτων
          δικαιωμάτων όλων των θυμάτων των διωγμών του Ελληνισμού της Ανατολής
          κατά τα έτη 1914, 1922, 1924 και εξής και των απογόνων των όσον αφορά
          την προγονική των κληρονομιά.
          <br />
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ενταύθα ρητώς ορίζεται ότι ιδεολογικό και φιλοσοφικό θεμέλιο της
          Ο.Π.Σ.Ε. αποτελούν:
          <br />
          Α) Ο Ελληνισμός ως ιδέα πολυδιάστατη, πολυσήμαντη και οικουμενική και
          <br />
          Β) Η Ορθοδοξία ως θρησκευτική πίστη των πατέρων μας.
          <br />
          Σκοπός επίσης της Ο.Π.Σ.Ε. είναι η κοινωνική, οικονομική, και
          πολιτισμική προαγωγή των Σωματείων – μελών της και των φυσικών
          προσώπων μελών αυτών. <br />
          <br />
          Προς τούτο επιδιώκει:
          <br />
          Α) Την διάσωση και διατήρηση των ηθών, εθίμων και παραδόσεων του
          Ελληνισμού της Ανατολής, ως και παντός στοιχείου προσδιορίζοντος την
          Εθνική μας ταυτότητα.
          <br />
          Β) Την περισυλλογή, μελέτη, προβολή και αξιοποίηση του εθνολογικού,
          ιστορικού και λαογραφικού υλικού, καθώς και των κάθε είδους κειμηλίων
          και καλλιτεχνικών δημιουργημάτων του Ελληνισμού της Μικράς Ασίας, της
          Κωνσταντινουπόλεως, του Ευξείνου Πόντου, της Θράκης, της Ανατολικής
          Ρωμυλίας και γενικώς της καθ ημάς Ανατολής.
          <br />
          Γ) Τη συμβολή στη διάσωση, συνέχιση και προβολή της πολιτισμικής
          κληρονομιάς του Ελληνισμού της Ανατολής, ως και την ουσιαστική
          συμπαράσταση στο έργο παντός εθνογραφικού και λαογραφικού μουσείου που
          αφορά τον Ελληνισμό της καθ ημάς Ανατολής και τέλος η συμπαράσταση στο
          έργο παντός ατομικού η συλλογικού οργάνου δημοσίου, δημοτικού η
          ιδιωτικού με συναφείς σκοπούς και επιδιώξεις, ως π.χ. του κέντρου
          Μικρασιατικών Σπουδών.
          <br />
          Δ) Την ουσιαστική συμβολή στα Εθνικά θέματα του Ελληνισμού είτε του
          ελεύθερου σήμερα Ελλαδικού χώρου, είτε του Ελληνισμού της διασποράς,
          όπου γης.
          <br />
          Ε) Την αντιμετώπιση των κάθε φύσεως Προσφυγικών θεμάτων είτε εκκρεμούν
          σήμερα είτε προκύψουν στο μέλλον.
          <br />
          ΣΤ) Τη συμμετοχή στη διαχείριση της περιουσίας των εν Ελλάδι
          ανταλλαξίμων κτημάτων και ευαγών ιδρυμάτων Προσφύγων.
          <br />
          Ζ) Την ενίσχυση και ανάπτυξη των δεσμών και της συνεργασίας μεταξύ των
          Προσφυγικών Οργανώσεων οι οποίες διέπονται από τους ίδιους η
          παρεμφερείς προς την Ο.Π.Σ.Ε. σκοπούς, είτε εντός του Ελλαδικού χώρου
          είτε εις την αλλοδαπή. <br />
          <br />
          <u>Η σημερινή σύνθεση του Δ.Σ. της Ο.Π.Σ.Ε. είναι η παρακάτω:</u>
          <br />
          <br />
          Πρόεδρος: Λουκάς Χριστοδούλου (Ενωση Σπάρτης Μικράς Ασίας)
          <br />
          Αντιπρόεδρος: Φώτης Καραλής (Ενωση Βουρλιωτών Μικράς Ασίας)
          <br />
          Γενικός Γραμματέας: Δημήτρης Παντέλας (Ενωση Κάτω Παναγιάς Μικράς
          Ασίας)
          <br />
          Ταμίας: Κώστας Αναγνωστάκης (Μικρασιατικός Σύλλογος Νέου Ψυχικού)
          <br />
          Μέλη: Αντώνης Οραήλογλου (Αδελφότητα Προκοπιέων Μακεδονίας- Θράκης),
          Μαριάννα Μαστροσταμάτη (Σύλλογος Αλατσατιανών Αθήνας), Βαγγέλης
          Δαλκιρανίδης (Σύλλογος Μικρασιατών Πτολεμαίδας), Αποστόλης Δαγδελένης
          (Ενωση Μικρασιατών Θήβας), Γιάννης Τριανταφυλλίδης (Σωματείο Νέα
          Σινασός), Μαρία Δραγάτση (Σύλλογος Σμυρναίων Μικρασιατών Ελευθερίου
          Κορδελιού Θεσσαλονίκης), Γιάννης Παπαδάτος (Εστία Νέας Σμύρνης).{" "}
          <br />
          <br />
          <b>Επιστολή για οικονομική ενίσχυση από 10-3-2017</b>
          <br />
          <br />
          <b>Προς:</b> Γ. Γραμματέα Αιγαίου και Νησιωτικής Πολιτικής. κ. Ιωάννη
          Γιαννέλλη.
          <br />
          <b>Υπ όψιν:</b> Υπουργού Εμπορικής Ναυτιλίας, Αιγαίου και Νησιωτικής
          Πολιτικής.
          <br />
          <br />
          Κύριε Γ. Γραμματέα,
          <br />
          Αναλαμβάνουμε το θάρρος να επικοινωνήσουμε μαζί σας για να σας
          παρακαλέσουμε να ανταποκριθείτε στο αίτημά μας για οικονομική ενίσχυση
          των δραστηριοτήτων του Συλλόγου μας.
          <br />
          Όπως ασφαλώς θα γνωρίζετε ήδη, αποτελούμε ένα δραστήριο πολιτιστικό
          και οικολογικό σύλλογο με την επωνυμία « Το Δελφίνι », έτος ιδρύσεως
          το 1990 και έδρα μας τον προσφυγικό συνοικισμό της Σκάλας Λουτρών του
          Δήμου Μυτιλήνης. Η ιδιαιτερότητα του χωριού αυτού, είναι ότι
          δημιουργήθηκε το 1922, από είκοσι πέντε προσφυγικές οικογένειες που
          έλκουν την καταγωγή τους από τα παράλια της Μ. Ασίας.
          <br />
          Τα μέλη μας είναι 315 και οι δραστηριότητές μας είναι ευρέως γνωστές
          στην κοινωνία της Μυτιλήνης τόσο στον οικολογικό τομέα όσο και στα
          πολιτιστικά. Μερικές από αυτές είναι η βράβευση αριστούχων μαθητών του
          τοπικού διαμερίσματος Λουτρών, η έκδοση συλλεκτικών ημερολογίων για
          τις αλησμόνητες πατρίδες μας της Μ. Ασίας, η έκδοση περιοδικού με τα
          νέα του Συλλόγου, η προστασία του Περιβάλλοντος με δενδροφυτεύσεις και
          καθαριότητα παραλιών, η τοποθέτηση πινακίδων οδοσήμανσης για τα
          αξιοθέατα της περιοχής μας, η διατήρηση μουσικών και χορευτικών
          τμημάτων, η συντήρηση χώρου αθλοπαιδιών και γηπέδου μπάσκετ για την
          νεολαία του οικισμού μας, η συντήρηση κτιρίου που στεγάζει την
          βιβλιοθήκη μας η οποία διαθέτει περίπου 7.500 βιβλία όλων των
          κατηγοριών και κυρίως η συντήρηση του «Μουσείου Προσφυγικής Μνήμης
          1922» που έχουμε ιδρύσει από το 2006 και που καθημερινά συγκεντρώνει
          πολλούς επισκέπτες και πολλούς μαθητές από όλα τα σχολεία της Λέσβου.
          Ειδικά για το Μουσείο (το οποίο φιλοξενεί περισσότερα από 500 κειμήλια
          προσφύγων) θεωρούμε πως παντί σθένει, οφείλουμε να το διατηρήσουμε σε
          άριστη κατάσταση αφού αυτό αποτελεί τη μοναδική απτή μαρτυρία της
          ιστορίας του οικισμού μας.
          <br />
          Όμως οι οικονομικές μας δυνατότητες όπως αντιλαμβάνεσθε είναι
          περιορισμένες σε σχέση με τις υποχρεώσεις που έχουμε αναλάβει γι αυτό
          απευθυνόμαστε σε σάς μήπως υπάρξει η δυνατότητα επιχορήγησης του
          Συλλόγου μας έτσι ώστε να μπορέσουμε να ανταποκριθούμε κυρίως, στις
          επείγουσες συντηρήσεις των κτιρίων του Μουσείου και της Βιβλιοθήκης
          που λόγω της υγρασίας έχουν υποστεί σημαντικές φθορές.
          <br />
          <br />
          Κύριε Γ. Γραμματέα,
          <br />
          Θέλουμε να πιστεύουμε πως με τα προαναφερθέντα, δικαιολογήσατε την
          απόφασή μας να σας ενοχλήσουμε και σας προσκαλούμε να επισκεφθείτε το
          Σύλλογό μας όποτε το επιτρέψουν οι υποχρεώσεις σας.
          <br />
          Από καρδιάς σας ευχαριστούμε εκ των προτέρων για το οποιοδήποτε
          ενδιαφέρον σας.
          <br />
          Με εκτίμηση για το Δ.Σ.
          <br />
          <br />
          Ο Πρόεδρος Η Γ. Γραμματέας
          <br />
          Δημ. Παπαχρυσός Μαρία Δασκάλου
          <br />
          <br />
          <br />
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          ΥΓ. Δυστυχώς μέχρι σήμερα (Δεκέμβριος 2017) δεν υπήρξε καμία
          ανταπόκριση στο αίτημα μας με δεδομένο μάλιστα ότι, το «Μουσείο
          Προσφυγικής Μνήμης 1922» δεν έχει απολύτως καμία στήριξη από κανένα
          κρατικό η ιδιωτικό φορέα και εξακολουθεί να υπάρχει βασιζόμενο σε
          εθελοντικές προσφορές των μελών του Συλλόγου.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διεθνής ημέρα Μουσείων
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το Διεθνές Συμβούλιο Μουσείων (ICOM), επιθυμώντας να αναδείξει το ρόλο
          των Μουσείων στη σύγχρονη κοινωνία, έχει ορίσει από το <b>1977</b> την{" "}
          <b>18η Μαΐου</b> ως <b>Διεθνή Ημέρα Μουσείων.</b>
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Έτσι και φέτος τιμώντας αυτή την επέτειο, το «
          <b>Μουσείο Προσφυγικής Μνήμης 1922</b>» στη Σκάλα Λουτρών, παρέμεινε
          ανοικτό για τους επισκέπτες από τις 09.00 έως τις 12.00 και από τις
          18.00 έως τις 20.00. Το μήνυμα αυτής της επετείου είναι «να γίνουν τα
          Μουσεία φορείς πολιτισμικών ανταλλαγών, με σκοπό την ανάπτυξη της
          μόρφωσης, της αμοιβαίας κατανόησης, της συνεργασίας και της ειρήνης
          ανάμεσα στους λαούς».
          <br />
          Επίσης το «<b>Μουσείο Προσφυγικής Μνήμης 1922</b>» καθ όλη την θερινή
          περίοδο ήταν ανοικτό τις ημέρες Δευτέρα, Τετάρτη και Παρασκευή και από
          ώρα 18.00 έως 20.00. <br />
          Υπενθυμίζουμε ότι κατά την χειμερινή περίοδο το «Μουσείο Προσφυγικής
          Μνήμης 1922» θα δέχεται επισκέψεις, μόνον κατόπιν τηλεφωνικής
          συνεννοήσεως στα τηλέφωνα:
          <br />
          <br />
          69746.40419 κ. Δημήτρη Παπαχρυσό και
          <br />
          22510.91277 κ. Ευθαλία Τουρλή.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τραγουδώντας και χορεύοντας με τους Μεσοτοπίτες του Συλλόγου «Η
          Αναγέννηση»
        </Typography>
        <h4>
          (Χορευτικό - Χορωδία - Σαντούρια - Γλεντιστάδες) Εκδήλωση 19-8-2017
        </h4>
        Πραγματοποιήθηκε με εξαιρετική επιτυχία το βράδυ της 19ης Αυγούστου στη
        Σκάλα Λουτρών, το αντάμωμα του Συλλόγου Μικρασιατών Σκάλας Λουτρών «
        <b>Το Δελφίνι</b>» και της «<b>Αναγέννησης</b>» του Συλλόγου των
        απανταχού Μεσοτοπιτών Λέσβου.
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Η εκδήλωση την οποία παρακολούθησε πλήθος κόσμου και ανάμεσά τους και
          οι πρόεδροι των δύο χωριών κ.κ. Βασίλης Μαμώλης (Λουτρών) και Γαβριήλ
          Πιπίνης (Μεσοτόπου) ξεκίνησε με χαιρετισμούς των δύο προέδρων κ.
          Παπαχρυσού Δημητρίου και κ. Βασίλογλου Φώτη καθώς και με ανταλλαγή
          δώρων. Και οι δύο αναφέρθηκαν στους κοινούς δεσμούς με την Μικρά Ασία
          και τη Σμύρνη και στην ανάγκη διατήρησης της ιστορικής μνήμης και της
          πολιτιστικής παράδοσης. Ακολούθησε ένα 25λεπτο ντοκιμαντέρ του
          «Δελφινιού» με αφηγήσεις προσφύγων – κατοίκων της Σκάλας Λουτρών
          πρώτης γενιάς, καθώς και με αποσπάσματα από τις προγενέστερες
          εκδηλώσεις του Συλλόγου. Ακολούθησε το μουσικοχορευτικό πρόγραμμα των
          καλεσμένων Μεσοτοπιτών, το οποίο ξεκίνησε με «ρίμα» του Θύμιου
          Βασίλογλου – έμμετρο λόγο αφιερωμένο στους δύο Συλλόγους και στην
          ανάγκη πολιτιστικής συνεργασίας. Κλείνοντας ακούστηκε το τραγούδι
          «Πατρίδες αλησμόνητες» του ιδίου – συρτός - που χόρεψαν δύο ζευγάρια
          βρακάδων Μεσοτοπιτών. Στην συνέχεια ο πρόεδρος της «Αναγέννησης»
          Βασίλογλου Φώτης με προβολή διαφανειών έκανε μια αναφορά στην λαϊκή
          παράδοση του Μεσοτόπου με έμφαση στο γλέντι και τους παραδοσιακούς
          χορούς.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ακολούθησε η «Σαντουροπαρέα» -ομάδα της Αναγέννησης- αποτελούμενη από
          νεαρά παιδιά που μαθαίνουν παραδοσιακά όργανα, τα οποία έπαιξαν και
          τραγούδησαν σκοπούς της Λέσβου και της Μικράς Ασίας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Στην συνέχεια το χορευτικό της Αναγέννησης χόρεψε σκοπούς της Λέσβου
          συρτό-μπάλο, καρσιλαμά απτάλικο, Αϊβαλιώτικο ζεϊμπέκικο, Αϊσέ,
          καλαματιανό και Μαζωμένο. Η χορωδία της Αναγέννησης συνέχισε με
          τραγούδια Μικράς Ασίας και Λέσβου για να κλείσουν οι «Γλεντιστάδες»
          που με τα τουμπελέκια τους τραγούδησαν και χόρεψαν με ερωτικά δίστιχα,
          Ντούρου ντούρου, στ απάνω μάναμ το χωριό, δωδεκάχρονο κορίτσι,
          Φεργαδούλα και της Πορτοκαλιά τα φύλλα. Την Φεργαδούλα, τον αργό
          κοντραμπατζίδικο χαβά,, αφιέρωσαν στους πρώτους ψαράδες πρόσφυγες που
          κυνηγημένοι από τις Φώκαιες και τον Τσεσμέ, ήρθαν και βρήκαν «λιμάνι»
          εδώ, στη Σκάλα Λουτρών, όπου ρίζωσαν και πρόκοψαν.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Την παρουσίαση των Μεσοτοπιτών ολοκλήρωσε πάλι με «ρίμα» ένας άλλος
          Μεσοτοπίτης Ριμαδόρος, ο Δημήτρης Νοικοκύρης – Σαμαράς ευχαριστώντας
          για την πρόσκληση και επαινώντας τη δράση του ΄Δελφινιού» και
          πρωτίστως το υπέροχο Μουσείο Προσφυγικής Μνήμης 1922, που στεγάζει
          κειμήλια και μνήμες από τις αλησμόνητες πατρίδες. Αξίζει να αναφερθεί
          ότι πριν την έναρξη της εκδήλωσης όλη η αποστολή των Μεσοτοπιτών – 50
          άτομα - επισκέφθηκαν το Μουσείο το οποίο στεγάζετε στο παλιό Δημοτικό
          Σχολείο και ενημερώθηκαν από κοντά γι αυτό το έργο – κατάθεση ψυχής.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Όλη η αποστολή έφυγε συγκινημένη από την μεστή και εμπνευσμένη
          ξενάγηση που της έγινε, ενώ στο καλωσόρισμα προηγήθηκε κέρασμα με
          μεζέδες και γλυκά, που είχαν ετοιμάσει κυρίες – μέλη του Συλλόγου του
          Δελφινιού.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}></Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μετά το πέρας της εκδήλωσης προσφέρθηκε γεύμα με πλούσιους μεζέδες και
          τοπικό ούζο, προσφορά της ποτοποιίας «ΣΜΥΡΝΙΩ» και ακολούθησαν
          τραγούδια, κλείνοντας με τον καλλίτερο τρόπο τούτο το πολιτιστικό
          αντάμωμα.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στις αποσκευές τους οι Μεσοτοπίτες εκτός από την χαρά του ανταμώματος,
          την επιτυχία της εκδήλωσης, την συγκίνηση από την ξενάγηση στο Μουσείο
          και τις άριστες εντυπώσεις από την φιλοξενία των ανθρώπων του
          «Δελφινιού» πήραν και από ένα περιοδικό, καθώς και πλούσιο υλικό από
          την δράση του Συλλόγου σε ηλεκτρονική μορφή. Είθε το εν λόγω αντάμωμα,
          να δώσει την αφορμή ώστε παρόμοιες πολιτιστικές ανταλλαγές ανάμεσα σε
          τοπικούς Συλλόγους, να πληθύνουν στο μέλλον με την βοήθεια και του
          Δήμου Λέσβου.
          <br />
          <br />
          <br />
          <b>
            Το καλωσόρισμα από τον πρόεδρο του Συλλόγου μας κ. Δημήτρη Παπαχρυσό
          </b>
          <br />
          <br />
          <b>
            <u>Κύριε πρόεδρε</u>
          </b>{" "}
          και αγαπητοί φιλοξενούμενοι του Συλλόγου Μεσοτοπιτών «
          <b>Η Αναγέννηση</b>», καλώς μας ήρθατε στη Σκάλα Λουτρών
          φιλοξενούμενοι από τον Σύλλογό μας, τον Σύλλογο Μικρασιατών της Σκάλας
          Λουτρών «Το Δελφίνι».
          <br />
          Οι κοινοί δεσμοί που μας συνδέουν περιγράφονται αξιοθαύμαστα στο
          βιβλίο του προέδρου σας του κ. Φώτη Βασίλογλου ως εξής:
          <br />
          <br />
          «Ο ξενιτεμός – κατάρα και ευλογία μαζί – έφερε τους Μεσοτοπίτες
          τεχνίτες και οικοδόμους κυρίως, πρώτα στη Σμύρνη από τα μέσα του 19ου
          αιώνα. <br />
          Στις αρχές του 20ου αιώνα βρίσκουμε στη Σμύρνη και στα περίχωρα
          οικογένειες Μεσοτοπιτών άριστα αποκατεστημένες.
          <br />
          Οι περισσότεροι όμως δούλευαν εποχικά. Έφευγαν απ το χωριό μετά το
          Πάσχα και γύριζαν το χειμώνα κοντά στα Χριστούγεννα. ξεχειμώνιαζαν
          κοντά στις οικογένειές τους και την άνοιξη ξανάφευγαν.
          <br />
          Η Σμύρνη ήταν τότε η μητρόπολη της περιοχής, σταυροδρόμι εμπορικών και
          πολιτισμικών ανταλλαγών. Τα Μεσοτοπίτικα καΐκια κι οι «βαρκαλάδες» που
          πηγαινοέρχονταν ταχτικά, δεν κουβαλούσαν μόνο μαστόρους και
          επισκέπτες, αλλά έφερναν και λογιών – λογιών αγαθά, ιδέες, τέχνη και
          πολιτισμό.
          <br />
          Όλα αυτά χάθηκαν και έσβησαν τόσο τραγικά μες τα αποκαΐδια της Σμύρνης
          τον Σεπτέμβρη του 1922…»
          <br />
          <br />
          Αυτή η ιδιαίτερη σχέση του Μεσοτόπου με τη Σμύρνη και τη Μικρά Ασία
          γέννησε στις ψυχές των ανθρώπων μια κληρονομιά που τη ζεις και τη
          νιώθεις ακόμα και σήμερα στα λόγια, στα φερσίματα, στα ήθη και τα
          έθιμα, στα τραγούδια και στους χορούς.
          <br />
          Αυτά λοιπόν τα κοινά μας βιώματα και οι μνήμες είναι ο συνδετικός μας
          κρίκος, σαν απόγονοι Μικρασιατών προσφύγων και μας κάνουν διπλά
          ευτυχισμένους που έχουμε σήμερα την δυνατότητα να σας φιλοξενήσουμε,
          να τραγουδήσουμε και να χορέψουμε μαζί σας, κάτι σαν φόρο τιμής στους
          προγόνους όλων μας που πρόκοψαν και δημιούργησαν μαζί στα Άγια χώματα
          της Ανατολής… <br />
          <br />
          Καλώς ανταμωθήκαμε…
          <br />
          <br />
          <br />
          <b>Η ρίμα – χαιρετισμός από τον Θύμιο Βασίλογλου</b>
          <br />
          <br />
          Μια καλησπέρα να σας πω, θέλου απού καρδιάς
          <br />
          να χαιρετίσου τ’ αντάμωμα μιας όμορφης βραδιάς
          <br />
          <br />
          Ήρθαμι απ’ του Μισότοπου που φ’σά δρουσ’νός αγέρας
          <br />
          στα μουρφουστόλιστα Λουτρά, σντου ‘λιώνα μέσα τ’ς Γέρας
          <br />
          <br />
          Γκ(ι) παράδουσ’ να τιμήσουμι, να δώσουμι μια «πάσα»
          <br />
          μαζί μι σας να πάρουμι πολιτισμού ανάσα!
          <br />
          <br />
          Μεις γκ(ι) Αναγέννησ’, έχουμι, τση σεις μι του Δελφίν(ι)
          <br />
          καλώς ανταμουθήκαμι, μ’ αγάπ’ τσ’ αδιλφουσύν(ι)!
          <br />
          <br />
          Η Σύλλουγός μας γκη μαγιά ξέρ’ να γκ(ι) αυγατίζ(ι)
          <br />
          μι γκη Συλλουγικότητα να παραδειγματίζ(ι).
          <br />
          <br />
          Σγκ(ι) Αθήνα μένουν τα πηδιά αλλά ζωής ελπίδα
          <br />
          είν’ του μ’κρό μας χουριουδέλ(ι) η πιο γλυκιά πατρίδα!
          <br />
          <br />
          Η νοσταλγία μια φουκιά, που λειών(ι) τα ξερολίθια
          <br />
          χελιδουνέλια σγκη φουλιά η πιο τρανή αλήθεια
          <br />
          <br />
          Φεύγουν τα χρόνια μας, περνούν οι μήνις οι βδομάδις
          <br />
          τση μεις χηρόμαστ’ οι μεγάλ(ι) τα νιάτα τ’ς ανιτσνάδις!
          <br />
          <br />
          Οι «Γλεντιστάδις» λεβεντιά, ούλα τα φκιάν’ μπιλούργια!
          <br />
          Γκη Χουρουδία ακ’λουθούν γλυκόλαλα σαντούρια!
          <br />
          <br />
          Μες σντουν ανιμοστρόβιλου, που μας τσνυγά σα τέρας
          <br />
          τςι ρίζις μας πουτίζουμι, να μη μας πάρ’ γι
          <Typography variant="h3" sx={theme.typography.typography2}>
            ’ αγέρας!
            <br />
            <br />
            Τραγούδια, έθιμα, χοροί, αιώνων ιστορία,
            <br />
            που φκιαν’ τ’ς καρδιές τση ξιπιτούν για γκη Μικρά Ασία
            <br />
            <br />
            Σμύρνη, ωσαννά στα χερουβείμ, αγγελικό τροπάριου,
            <br />
            που φτάσαν οι παππούδις μας ίσαμι ντου Σαγγάριου!
            <br />
            <br />
            Η Σμύρν(ι), πριν γκη καταστροφή, ήταν τ’ νησιού η αυλή μας
            <br />
            στα ζάρια γκ(ι) παίξαν οι μεγάλ(ι) τση χάθ’τσι η βουλή μας
            <br />
            <br />
            Οι πρόσφυγες τα χρόνια φτα μι ντου ξεριζωμό
            <br />
            φέραν απάνω στου νησί τέχν(ι) τση πουλιτισμό!
            <br />
            <br />
            Τραγουδιστές τση μουσικοί, μεγάλ(ι) παιχνιδιατόρ’
            <br />
            σντου κόσμου ήταν ξακουστοί τση οι Σμυρνιοί μαστόρ’!
            <br />
            <br />
            Σαν πόσα να ‘χαν να μας πουν γι’ αθρώπ’ καρσί που ζήσαν,
            <br />
            που φύγαν μες σντου χαλασμό, το βιός τουν που αφήσαν…
            <br />
            <br />
            Ήβγαλι ρίζες η γενιά πας στου νησί μες στα Λουτρά, κόσμημα του
            Μουσείου,
            <br />
            χουρίς ψιγάδ’ φτο σας τιμά, ομολογεί τση μαρτυρά, τςι προσφυγιάς
            ντου βίου!
            <br />
            <br />
            Πουτές μη ντου ξιχάσιτι γενιές Λουτρογινν(ι)μένις
            <br />
            των πρόγονών σας οι ψυχές είνη αναπαμένις!
            <br />
            <br />
            Πατρίδες αλησμόνητες κι αν είσαστε χαμένις
            <br />
            αξέχαστες θα μείνετε και πάντα αγαπημένις!
            <br />
            <br />
            Ένα τραγ’δέλ(ι) που έγραψα σντου χτύπου τςη καρδιάς,
            <br />
            ας το χορέψουν τα παιδιά σγκη μνήμη τςη προυσφυγιάς!
            <br />
            <br />
            <br />
            <br />
            <b>Πατρίδες αλησμόνητες (τραγούδι)</b>
            <br />
            <br />
            Με χαιρετάς, με χαιρετάς με την καρδιά
            <br />
            κι ο νους μου πάει, ο νους μου πάει στην προσφυγιά
            <br />
            όποιος γνωρί, όποιος γνωρίζει το γιατί
            <br />
            έχει μερά, έχει μεράκια στη ψυχή
            <br />
            <br />
            Πατρίδες αλησμόνητες εικόνα μου Αγία
            <br />
            να βγαίνει ο ήλιος το πρωί απ’ τη Μικρά Ασία
            <br />
            αγγέλων φτερουγίσματα στο φύσημα του γραίου
            <br />
            νησιά του Ανατολικού και Βόρειου Αιγαίου
            <br />
            <br />
            Στο κόσμο τον, στο κόσμο τον σημερινό
            <br />
            πατρίδα έ, πατρίδα έχω και πονώ
            <br />
            απ τη δική, απ τη δική της απονιά
            <br />
            όπου κοιτά, όπου κοιτάξω με πονά
            <br />
            <br />
            Στης μοίρας τα, στης μοίρας τα τσακίσματα
            <br />
            έχει ο καιρός, έχει ο καιρός γυρίσματα
            <br />
            κοιτάζω την, κοιτάζω την Ανατολή
            <br />
            και μου θαμπώ και μου θαμπώνει το γυαλί
            <br />
            <br />
            Αγγέλων φτερουγίσματα στο φύσημα του γραίου
            <br />
            νησιά του Ανατολικού και Βόρειου Αιγαίου
            <br />
            στης θάλασσας τα κύματα αγάπης όρκο κάνω
            <br />
            <br />
            Στης θάλασσας τα κύματα αγάπης όρκο κάνω
            <br />
            να σ αγαπώ πατρίδα μου μέχρι που να πεθάνω
            <br />
            αγγέλων φτερουγίσματα στο φύσημα του γραίου
            <br />
            νησιά του Ανατολικού και Βόρειου Αιγαίου
            <br />
            <br />
            <br />
            Στίχοι: Θύμιος Βασίλογλου
            <br />
            Μουσική: Νίκος Τσιριγώτης
            <br />
            <br />
            <br />
            <b>
              Η ρίμα του κ. Δημήτρη Νοικοκύρη- Σαμαρά με την οποία έκλεισε η
              εκδήλωση.
            </b>
            <br />
            <b>Για το όμορφο χωριό σας</b>
            <br />
            <br />
            Γεια και χαρά σε όλους σας
            <br />
            γεια σας και πάλι γεια σας
            <br />
            είμαι διπλά χαρούμενος
            <br />
            που βρίσκομαι κοντά σας.
            <br />
            <br />
            Η μια χαρά μου είν’ αυτή,
            <br />
            που βρίσκομαι μαζί σας
            <br />
            κι δεύτερη που έγραψα
            <br />
            για την καταγωγή σας.
            <br />
            <br />
            Σήμερα ανταμώσαμε
            <br />
            ένα κρασί να πιούμε
            <br />
            και μέσα στην παράδοση
            <br />
            πολλά να θυμηθούμε.
            <br />
            <br />
            Πήρα το θάρρος κι έγραψα
            <br />
            με λογισμό κι ευθύνη
            <br />
            για το ωραίο σας χωριό
            <br />
            πάνω στη Μυτιλήνη.
            <br />
            <br />
            Που έφερε πολιτισμό
            <br />
            στης Σμύρνης το χαμό
            <br />
            μετά απ’ το ολοκαύτωμα
            <br />
            και τον ξεριζωμό.
            <br />
            <br />
            Εκατό χρονών μου είπανε
            <br />
            πως είναι η ζωή του
            <br />
            κ ήταν Σμυρνιοί και Φωκιανοί
            <br />
            οι πρώτοι κάτοικοί του.
            <br />
            <br />
            Οι πρώτοι πού ‘ρθανε εδώ
            <br />
            ήταν απελπισμένοι
            <br />
            τριάντα οικογένειες
            <br />
            μα σαν γροθιά δεμένοι.
            <br />
            <br />
            Αφήσανε τα σπίτια τους
            <br />
            και την περιουσία
            <br />
            δεχτήκανε κατατρεγμό
            <br />
            και την λεηλασία.
            <br />
            <br />
            Πέσανε μες στη θάλασσα
            <br />
            με αδειανά τα χέρια
            <br />
            γιατί τους κυνηγούσανε
            <br />
            ντουφέκια και μαχαίρια.
            <br />
            <br />
            Τα κύματα ήταν θεριά
            <br />
            βουβά και αφρισμένα
            <br />
            και γέμισε η θάλασσα
            <br />
            με πτώματα και αίμα.
            <br />
            <br />
            Όσοι γλιτώσαν ήρθανε
            <br />
            σ’ αυτόν εδώ τον τόπο
            <br />
            και όρθιοι σταθήκανε
            <br />
            με ιδρώτα και με κόπο.
            <br />
            <br />
            Από τη Φώκια φύγανε
            <br />
            ξεριζωμένοι ήδη
            <br />
            σαν ήρθανε δεν είχανε
            <br />
            ούτ’ ένα κεραμίδι.
            <br />
            <br />
            Μα όλοι είχανε ψυχή
            <br />
            σφίξανε την καρδιά τους
            <br />
            να ξαναχτίσουν τη ζωή
            <br />
            τα σπίτια τα δικά τους.
            <br />
            <br />
            Νέα πατρίδα κάνανε
            <br />
            εδώ να κατοικήσουν
            <br />
            και πήραν την απόφαση
            <br />
            απ’ την αρχή ν’ αρχίσουν.
            <br />
            <br />
            ‘Κατό πενήντα άνθρωποι,
            <br />
            το λέω κι απορώ,
            <br />
            την πέτρα την εστίβανε
            <br />
            και βγάζανε νερό.
            <br />
            <br />
            Τριάντα οικογένειες
            <br />
            όλοι Μικρασιάτες
            <br />
            δάσκαλοι επιστήμονες
            <br />
            θαλασσινοί κι εργάτες.
            <br />
            <br />
            Ήτανε χρόνια δύσκολα
            <br />
            τα χρόνια τα δικά τους
            <br />
            και τρέχανε μερονυχτίς
            <br />
            να ζήσουν τα παιδιά τους.
            <br />
            <br />
            Στα χρόνια της καταστροφής
            <br />
            και του ξεριζωμού
            <br />
            ο ένας κράταγε σφιχτά
            <br />
            το χέρι τ’ αλλουνού.
            <br />
            <br />
            Τον τόπο τους αγάπησαν
            <br />
            τον κάνανε δικό τους
            <br />
            δεν χάσαν το κουράγιο τους
            <br />
            ούτε το ηθικό τους.
            <br />
            <br />
            Μες στο λιοπύρι, στη βροχή
            <br />
            και μες στη καταιγίδα
            <br />
            δούλευαν για να χτίσουνε
            <br />
            τη νέα τους πατρίδα.
            <br />
            <br />
            Χτίσαν σχολειό και εκκλησιά
            <br />
            γι’ αυτούς και τα παιδιά τους
            <br />
            κι αρχίσαν να στηρίζονται
            <br />
            στα πόδια τα δικά τους.
            <br />
            <br />
            Φτιάξανε και υπέροχο
            <br />
            κι απάγκιο λιμάνι
            <br />
            ούτε βοριάς ούτε νοτιάς
            <br />
            αγέρας δεν το πιάνει.
            <br />
            <br />
            Και όλα αυτά που βλέπουμε
            <br />
            σ αυτό το μορφοχώρι
            <br />
            τα χτίσανε με έρωτα
            <br />
            οι Φωκιανοί μαστόροι.
            <br />
            <br />
            Η τέχνη τους είναι γνωστή
            <br />
            βαθιά μες στους αιώνες
            <br />
            σφίγγουνε τα γκουτζγκούνια τους
            <br />
            και φτιάχνουν Παρθενώνες.
            <br />
            <br />
            Μες στο παλιό το κτίριο,
            <br />
            δημοτικό σχολείο
            <br />
            εδώ και χρόνια λειτουργεί
            <br />
            το ξακουστό Μουσείο.
            <br />
            <br />
            Με πλούσια εκθέματα
            <br />
            απ την Μικρά Ασία
            <br />
            και όλοι μας θαυμάζουμε
            <br />
            της Σμύρνης τη μαγεία.
            <br />
            <br />
            Έχ(ι) έναν χάρτη σπάνιο
            <br />
            παλιό κιτρινισμένο
            <br />
            μα το μυαλό μου έμεινε
            <br />
            πάνω του κολλημένο.
            <br />
            <br />
            Που στέκεται αγέρωχα
            <br />
            και δίνει το παρόν
            <br />
            έχει δυόμισι χιλιάδες
            <br />
            τα ονόματα χωριών!
            <br />
            <br />
            Παραδίπλα μια μακέτα
            <br />
            φέρνει μνήμη ζωντανή
            <br />
            είναι η Σμύρνη, η Σμύρνη μας,
            <br />
            όπως ήταν πριν καεί…
            <br />
            <br />
            Μα πρωτεργάτης ήτανε
            <br />
            ο Σταύρος Βαλαχής.
            <br />
            Είναι μπροστάρης δυνατός
            <br />
            ετούτης της αρχής.
            <br />
            <br />
            Που έχει ανοιχτό μυαλό
            <br />
            έριξε την ιδέα
            <br />
            κ εμείς απολαμβάνουμε
            <br />
            της Σμύρνης τα ωραία.
            <br />
            <br />
            Έχουνε όλα τα καλά
            <br />
            εδώ μες το χωριό τους
            <br />
            κι αναβιώνουν έθιμα
            <br />
            μέσα στο Σύλλογό τους.
            <br />
            <br />
            Με την πλούσιά του δράση
            <br />
            είναι πια πολύ γνωστός
            <br />
            με χορούς και εκδηλώσεις
            <br />
            έχει γίνει ξακουστός.
            <br />
            <br />
            «Δελφίνι» ονομάζεται,
            <br />
            πανέμορφο δελφίνι
            <br />
            και είναι αξιόλογος
            <br />
            πάνω στην Μυτιλήνη.
            <br />
            <br />
            Τον Παπαχρυσό Δημήτρη
            <br />
            έχουν πάντα στο πλευρό τους,
            <br />
            όλοι τους τον αγαπάνε
            <br />
            και τον έχουν πρόεδρό τους.
            <br />
            <br />
            Στις μέρες του καλοκαιριού
            <br />
            σ’ αυτές τις παραλίες
            <br />
            εδώ γεννιούνται έρωτες
            <br />
            και γράφονται ιστορίες.
            <br />
            <br />
            Μικροί μεγάλοι χαίρονται
            <br />
            και γίνονται παιδιά
            <br />
            σε τούτες τις ακρογιαλιές
            <br />
            και στην Κουντουρουδιά.
            <br />
            <br />
            Κάνουνε τα μπανάκια τους
            <br />
            να γαληνέψει ο νους
            <br />
            και πίνουν τα ουζάκια τους
            <br />
            με πέντε αχινούς.
            <br />
            <br />
            Τριγύρω ο κόσμος άλλαξε
            <br />
            κατά διαβόλου πάμε
            <br />
            ξεχάσαμε τις ρίζες μας
            <br />
            και από πού κρατάμε.
            <br />
            <br />
            Πολλά είδαν τα μάτια μου
            <br />
            κι άλλα πολλά θα δούνε
            <br />
            κι απ’ τα παλιά τα έθιμα
            <br />
            πολλά θα ξεχαστούνε.
            <br />
            <br />
            Όμως εδώ, Σκάλα Λουτρών
            <br />
            η αρχοντιά θα μείνει!
            <br />
            Εδώ μυρίζει ανθρωπιά,
            <br />
            εδώ μυρίζει Σμύρνη.
            <br />
            <br />
            Πολλά θα ήθελα να πω
            <br />
            όμως θα κάνω πάσο,
            <br />
            γιατί με πιάνουν κλάματα
            <br />
            και πώς να τα διαβάσω.
            <br />
            <br />
            Μ’ αυτό που θέλω να σας πω
            <br />
            βάλτε το στην ψυχή σας
            <br />
            είν’ η καρδιά μου ζωντανή
            <br />
            και είν’ εδώ μαζί σας.
            <br />
            <br />
            Γεια και χαρά σας φίλοι μου
            <br />
            σε όλους και σε όλες
            <br />
            και να ‘ναι οι καρδούλες σας
            <br />
            ζεστές και μυροβόλες!
            <br />
            <br />
            <br />
            Γεια σας
            <br />
            Δημήτρης Νοικοκύρης- Σαμαράς 19-8-2017
            <br />
            <br />
            ΥΓ. Τις προσφωνήσεις στην ομαλή ροή της εκδήλωσης είχε αναλάβει η
            αντιπρόεδρος του Συλλόγου κ. Νάσια Δαφιώτη και την φιλοξενία στο
            Μουσείο η κ. Ευθαλία Τουρλή. Ο Σύλλογός μας ευχαριστεί τον πρόεδρο
            και τα μέλη του τοπικού μας συμβουλίου για την βοήθειά τους σε ότι
            ζητήσαμε, τον κ. Αστυρακάκη για τα επί πλέον μικρόφωνα που
            χρειαστήκαμε και μας δάνεισε, την ποτοποιία ΣΜΥΡΝΙΩ για τα ούζα που
            πρόσφερε, καθώς και όλα τα μέλη του που με οποιονδήποτε τρόπο
            βοήθησαν στο να πραγματοποιηθεί με επιτυχία αυτή η εκδήλωση.
            <br />
            Ιδιαιτέρως ευχαριστούμε τον π. πρόεδρο της ΟΛΣΑ κ. Νεκτάριο Βακάλη
            που μεσολάβησε, μεταφέροντας την πρόταση μας στους Μεσοτοπίτες, για
            να υλοποιηθεί αυτή η επιτυχημένη συνεργασία των δύο Συλλόγων.
            <br />
            <br />
            Και του χρόνου με υγεία.
          </Typography>
          <img
            src={Image42}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          />
          <img
            src={Image44}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          />
          <img
            src={Image46}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          />
          <img
            src={Image48}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          />
          <img
            src={Image50}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          />
          <img
            src={Image52}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διήμερες εκδηλώσεις του Συλλόγου, 22 και 23 Αυγούστου 2017 αφιερωμένες
          στις αλησμόνητες πατρίδες της Ανατολής
        </Typography>

        <img
          src={Image54}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image56}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκαν με μεγάλη επιτυχία οι εκδηλώσεις (22 και 23
          Αυγούστου) του Συλλόγου μας «Το Δελφίνι» οι οποίες ήταν αφιερωμένες
          στις αλησμόνητες πατρίδες της Ανατολής.
          <br />
          Η εκδήλωση της Τρίτης 22 Αυγούστου ξεκίνησε στις 19.30 με την τέλεση
          πανηγυρικού Εσπερινού στο εκκλησάκι της Παναγίας και την περιφορά της
          εικόνας μέχρι την αυλή του «Μουσείου Προσφυγικής Μνήμης 1922» <br />
          Ακολούθησε αρτοκλασία από τον ιερέα της ενορίας μας και επιμνημόσυνη
          δέηση υπέρ αναπαύσεως των ψυχών των Μικρασιατών προσφύγων που
          εγκαταστάθηκαν στη Σκάλα Λουτρών και μνημονεύτηκαν τα ονόματά τους.
          <br />
          Στην συνέχεια η κ. Νάσια Δαφιώτη αφού καλωσόρισε τους
          παρευρισκόμενους, μας διάβασε ένα ποίημα του Αλέξανδρου Παπαδιαμάντη
          με παραφρασμένο ελαφρώς τον τίτλο του με αφιέρωση στην Παναγία την
          «Αψηλή» και αμέσως μετά, τον λόγο πήρε η δ. Ειρήνη Σταματέλλη
          διαβάζοντας μας ένα αφιέρωμα σχετικό με τις αλησμόνητες πατρίδες της
          Ανατολής.
          <br />
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Επίσημος προσκεκλημένος στις εκδηλώσεις μας ήταν ο ιατρός κ. Μάκης
          Αξιώτης με ομιλία του που είχε τίτλο «Παναγιά η Αψηλή, η ιστορία ενός
          λόφου» η οποία ήταν πλαισιωμένη με φωτογραφίες του λόφου της Παναγίας
          και μας εξήγησε με ντοκουμέντα ότι αυτός ο λόφος με τον πύργο του στην
          κορυφή πως έχει ιστορία 5000 και πλέον ετών.
          <br />
          Ακολούθησε η προβολή ντοκιμαντέρ με θέμα τον πρώτο διωγμό του 1914 από
          την Φώκαια της Μικράς Ασίας και τέλος μοιράστηκε το παραδοσιακό φαγητό
          «Ο καβουρμάς» φτιαγμένο από τις νοικοκυρές του οικισμού μας μαζί με
          ένα ποτήρι λευκό κρασί.
          <br />
          Την επομένη το πρωί 23-8-2017 τελέσθηκε η καθιερωμένη Θεία Λειτουργία
          με αρτοκλασία στην Παναγιά την Αψηλή με την παρουσία πολλών κατοίκων
          από την Σκάλα Λουτρών, τα Λουτρά και το Πέραμα της Γέρας.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Στην Παναγιά την Αψηλή
        </Typography>

        <img
          src={Image58}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image60}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Μέσα στις τόσες Παναγίες που δεσπόζουν στα ποιήματα και τα διηγήματα
          των Ελλήνων πεζογράφων και ποιητών, μέσα στις τόσες εκκλησίες και
          ξωκλήσια που είναι σπαρμένα από άκρη σε άκρη ανά την Ελλάδα, βρίσκεται
          και η δική μας Παναγιά, η «Παναγιά η αψηλή» η Προσφυγοπαναγιά μας όπως
          την αποκαλούμε τα τελευταία χρόνια, μητέρα και σκέπη του προσφυγικού
          συνοικισμού μας, ελπίδα, καταφύγιο και σωτηρία στα βάσανα και τις
          ανάγκες όλων μας. Δημοσιεύουμε παρακάτω το ποίημα του Παπαδιαμάντη,
          που μας διάβασε η αντιπρόεδρος του Συλλόγου κ. Νάσια Δαφιώτη
          παραφράζοντας λίγο τον τίτλο. <br />
          <br />
          <b>Στην Παναγίτσα την Αψηλή</b>
          <br />
          <br />
          Χαίρετ ο Ιωακείμ κ η Άννα
          <br />
          που γέννησαν χαριτωμένη κόρη
          <br />
          την Παναγίτσα την Αψηλή!
          <br />
          <br />
          Χαίρετ όλη η έρημη ακρογιαλιά
          <br />
          κι ο βράχος κι ο γκρεμός αντίκρυ του πελάγους
          <br />
          που τον χτυπούν άγρια τα κύματα,
          <br />
          χαίρεται απ την εκκλησίτσα
          <br />
          που μοσχοβολά πάνω στη ράχη.
          <br />
          <br />
          Χαίρεται το άγριο δέντρο, που γέρνει,
          <br />
          το μισό απάνω στο βράχο,
          <br />
          το μισό στον γκρεμό,
          <br />
          χαίρετ ο βοσκός που φυσά τον αυλό του,
          <br />
          χαίρετ η γίδα του που τρέχει στα βράχια,
          <br />
          χαίρεται το ερίφιο που πηδά χαρμόσυνα.
          <br />
          <br />
          Κ η πλάση όλη αναγαλλιάζει
          <br />
          και το φθινόπωρο ξανανιώνει η γης,
          <br />
          σα σεμνή κόρη που περίμενε χρόνια
          <br />
          τον αρραβωνιαστικό της απ τα ξένα
          <br />
          και τέλος τον απόλαψε πριν είναι πολύ αργά
          <br />
          και σαν την στείρα γραία που γέννησε Θεόπαιδο
          <br />
          κι ευφράνθη στα γεράματα της!
          <br />
          <br />
          Δός μου κ εμένα άνεση κι ελπίδα, Προσφυγοπαναγιά μου,
          <br />
          πριν απέλθω και πλέον δεν θα υπάρχω.
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ημέρα μνήμης της γενοκτονίας των Ελλήνων της Μικράς Ασίας
        </Typography>

        <img
          src={Image62}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Σύμφωνα με τις διατάξεις του Προεδρικού Διατάγματος 304/2001, η 14η
          Σεπτεμβρίου ορίζεται ως ημέρα Εθνικής μνήμης της γενοκτονίας των
          Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος. Η επέτειος μνήμης
          έχει σκοπό να τονίσει ιδιαίτερα και να υπενθυμίσει τα γεγονότα της
          δραματικής περιόδου της γενοκτονίας και του ξεριζωμού των Ελλήνων της
          Μικράς Ασίας από το Τουρκικό κράτος, που είχαν ως αποτέλεσμα να
          μετατρέψουν τους Έλληνες της Μικράς Ασίας σε έναν λαό προσφύγων της
          διασποράς. - Το πρόγραμμα εκδηλώσεων αυτής της επετείου μνήμης για
          αυτή την χρονιά πραγματοποιήθηκε την Κυριακή 17 Σεπτεμβρίου και
          περιελάμβανε:
          <br />
          <br />
          - Τέλεση δοξολογίας και αρτοκλασίας στον Ιερό Ναό Αγίου Νικολάου στην
          Επάνω Σκάλα.
          <br />
          -Τον πανηγυρικό της ημέρας εκφώνησε η κα Καίτη Στρατάκη, Διευθύντρια
          Α΄θμιας Εκπαίδευσης Λέσβου.
          <br />
          - Επιμνημόσυνη δέηση και κατάθεση στεφάνων στο χώρο του αγάλματος της
          Μικρασιάτισσας Μάνας (Επάνω Σκάλα), με τη συμμετοχή της Στρατιωτικής
          Μουσικής της 98 ΑΔΤΕ, από τον εκπρόσωπο της Κυβέρνησης, την
          Περιφερειάρχη Βορείου Αιγαίου, το Στρατιωτικό Διοικητή, το Δήμαρχο
          Λέσβου, τους εκπροσώπους Πολιτικών Κομμάτων, αντιπροσωπείες των
          Απόστρατων Αξιωματικών, εκπροσώπους των Εφεδροπολεμιστικών Οργανώσεων,
          εκπροσώπους νομικών προσώπων και συλλόγων.
          <br />
          Το Δ.Σ. του Συλλόγου μας, όπως κάθε χρόνο, παρευρέθη στις
          προαναφερθείσες εκδηλώσεις μνήμης, με κατάθεση στεφάνου.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εκδήλωση μουσικού τμήματος.
        </Typography>

        <img
          src={Image64}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image66}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με επιτυχία πραγματοποιήθηκε την Κυριακή <b>15-10-2017</b> το
          απόγευμα, στην αίθουσα πολιτισμού Λουτρών, η ετήσια εκδήλωση του
          μουσικού τμήματος του Συλλόγου μας «<b>ΤΟ ΔΕΛΦΙΝΙ</b>». Την εκδήλωση,
          που ήταν και η τελευταία του καλοκαιριού, παρακολούθησαν με μεγάλο
          ενδιαφέρον οι γονείς των παιδιών και πολλά μέλη του Συλλόγου οι οποίοι
          είχαν τη δυνατότητα να παρατηρήσουν από κοντά την πρόοδο των παιδιών
          τους στη μουσική και να τα καμαρώσουν γι αυτό. Στο μουσικό τμήμα του
          Συλλόγου συμμετέχουν αυτή τη στιγμή <b>16</b> παιδιά ηλικίας από{" "}
          <b>6</b> έως <b>17</b> ετών και τα μαθήματα για όσους ενδεχομένως
          ενδιαφέρονται γίνονται στην βιβλιοθήκη του Συλλόγου στη Σκάλα Λουτρών
          κάθε Σάββατο.
          <br />
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Στην εκδήλωση έπαιξαν κιθάρα και τραγούδησαν οι παρακάτω μαθητές και
          μαθήτριες:
          <br />
          Πέρσα Γκαγκάνη, Μιχάλης Τρουλινός, Φίλιππος Δασκάλου, Αίας και
          Αλέξανδρος Μουσελίμης, Ντορέττα Λιαδέλλη, Βασίλης Τζωρτζάτος, Δέσποινα
          Καραβατάκη, Μαρίνος Ντινιακός, Ήλια Πανταζοπούλου, Νικολέτα Τριβέλλα,
          Παναγιώτα Παπαχρυσού και Απόστολος Κομνηνός.
          <br />
          Την επιμέλεια της παρουσίασης της εκδήλωσης είχε η αντιπρόεδρος του
          Συλλόγου κ. Νάσια Δαφιώτη.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          8ο Συμπόσιο ΚΕ.ΜΙ.ΠΟ.
        </Typography>

        <img
          src={Image68}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image70}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ένα σημαντικό τριήμερο συμπόσιο του ΚΕ.ΜΙ.ΠΟ. του Δήμου Νέας Ιωνίας,
          ολοκληρώθηκε με επιτυχία. Ασφυκτικά γεμάτη η αίθουσα του Συνεδριακού
          Κέντρου και τις τρεις ημέρες 24,25 και 26-11-2017 με ενδιαφέρουσες
          εισηγήσεις.
          <br />
          Συγκινητική η παρουσία των Μικρασιατικών Συλλόγων από την Αττική και
          από όλη την Ελλάδα, μεταξύ των οποίων και ο Σύλλογος μας «Το Δελφίνι».
          Ο ακούραστος ο πρόεδρος του ΚΕ.ΜΙ.ΠΟ. και της ΟΠΣΕ κ. Λουκάς
          Χριστοδούλου μαζί με τους εθελοντές βοηθούς του, οργάνωσαν με επιτυχία
          τα πάντα μέχρι την τελευταία λεπτομέρεια. Σημαντική στιγμή στην έναρξη
          η βράβευση του κ. Γιάννη Χαραλάμπους εισηγητή του νόμου για την
          καθιέρωση της 14ης Σεπτεμβρίου και ιδρυτή του ΚΕ.ΜΙ.ΠΟ.
          <br />
          Το Κέντρο Σπουδής και Ανάδειξης Μικρασιατικού Πολιτισμού ( ΚΕ.ΜΙ.ΠΟ.
          ), είναι Νομικό Πρόσωπο Δημοσίου Δικαίου ( Ν.Π.Δ.Δ. ) του Δήμου Νέας
          Ιωνίας Αττικής και ιδρύθηκε το 2003. Σκοπός του ΚΕ.ΜΙ.ΠΟ. είναι, η
          περισυλλογή, διάσωση, διατήρηση και μετάδοση των στοιχείων της
          Ιστορίας και του Πολιτισμού των Ελλήνων της καθ’ ημάς Ανατολής.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το ΚΕ.ΜΙ.ΠΟ. έχει οργανώσει στους χώρους του Συνεδριακού Κέντρου του
          Δήμου Ν. Ιωνίας έως τώρα οκτώ (8) Συμπόσια ( 2004, 2005, 2007, 2009,
          2011, 2012, 2015 και 2017).
          <br />
          • Το 1ο Συμπόσιο είχε ως θέμα : « Ο Ελληνισμός της Μικράς Ασίας από
          την Αρχαιότητα έως τη Μεγάλη Έξοδο »<br />
          • Το 2ο Συμπόσιο είχε ως θέμα : « Παιδεία και Εκπαίδευση στις
          Αλησμόνητες Πατρίδες της Ανατολής »<br />
          • Το 3ο Συμπόσιο είχε ως θέμα : « Τρεις χιλιετίες Μικρασιατικού
          Πολιτισμού : Επιστήμες – Γράμματα – Τέχνες »<br />
          • Το 4ο Συμπόσιο είχε ως θέμα : «Πολιτικοί, κοινωνικοί, οικονομικοί
          θεσμοί των Ελλήνων της Μ. Ασίας, κατά τους νεότερους χρόνους».
          <br />
          • Το 5ο Συμπόσιο είχε ως θέμα: « Η Συμβολή των προσφύγων στην
          Πολιτική, Πολιτιστική & Οικονομική ανάπτυξη της Ελλάδας»
          <br />
          • Το 6ο Συμπόσιο είχε ως θέμα: «Μικρασιατικός Ελληνισμός: Παρελθόν,
          Παρόν & Μέλλον»
          <br />
          • Το 7ο Συμπόσιο είχε ως θέμα: «Η θέση και ο ρόλος της Μικρασιάτισσας
          και <br />
          • Το 8ο Συμπόσιο είχε ως θέμα: «Λίγο πριν την 100/ετία : Πως και γιατί
          οι Έλληνες υπέστησαν τη μεγαλύτερη Καταστροφή της Ιστορίας τους», το
          οποίο πραγματοποιήθηκε στις 24, 25 και 26 Νοεμβρίου 2017 και ο
          Σύλλογός μας εκπροσωπήθηκε σ αυτό από την αντιπρόεδρό του κ. Νάσια
          Δαφιώτη, η οποία μας δήλωσε ότι:
          <br />
          <br />
          «Ευγνωμονούσα και ωφελημένη βγήκα από την παρακολούθηση του τριημέρου
          συμποσίου και μεταφέρω στον σύλλογο μας συναισθήματα, γνώσεις και
          εικόνες! Αυτά που δεν διδασκόμαστε στα σχολικά μας βιβλία, καμιάς
          εκπαιδευτικής βαθμίδας! Άκουσα πολλά και ενδιαφέροντα, που με έκαναν
          να ανασαίνω βαριά, αφού μου επιβεβαίωσαν ότι, οι πρόγονοι μας
          Μικρασιάτες ήταν θύματα μιας προαποφασισμένης τραγωδίας! Στο γύρισμα
          μιας παγκόσμιας σελίδας, που έμελλε να γυρίσει χωρίς να υπολογίσει τις
          ψυχές των Μικρασιατών! Ο πληθυσμός μας δεν χώραγε πια στην Μικρά Ασία.
          Δεν βόλευε η εκεί παραμονή μας τα μεγάλα συμφέροντα! Τα οποία πάντα
          και παντού ορίζουν τις τύχες των λαών».
          <br />
          *Συγχαρητήρια στο Δήμο Νέας Ιωνίας για την ουσιαστική συμβολή του στην
          διατήρηση της μνήμης, της Ιστορίας και του Πολιτισμού των Ελλήνων της
          καθ’ ημάς Ανατολής.
          <br />
          Ελπίζουμε αυτός να γίνει παράδειγμα προς μίμηση και για τον δικό μας
          Δήμο Μυτιλήνης του οποίου οι δημότες κατά συντριπτική πλειοψηφία έχουν
          Μικρασιατική καταγωγή.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εορτή του Αγίου Νικολάου στη Σκάλα Λουτρών
        </Typography>

        <img
          src={Image71}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image73}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκαν και εφέτος με λαμπρότητα και με καλό καιρό, οι
          εορταστικές εκδηλώσεις για την εορτή του Αγίου Νικολάου, στην Σκάλα
          Λουτρών, με την συμμετοχή των μελών του Συλλόγου ΔΕΛΦΙΝΙ, του προέδρου
          της Δημοτικής μας κοινότητας, και πολλών πιστών από την Σκάλα Λουτρών,
          τα Λουτρά, την Κουντουρουδιά και τη Μυτιλήνη.
          <br />
          Συγκεκριμένα, ετελέσθη στο παρεκκλήσιο του Αγίου Νικολάου της Σκάλας
          Λουτρών, η Θεία Λειτουργία και στη συνέχεια έγινε η λιτάνευση της
          εικόνας του Αγίου Νικολάου, φερμένη το 1922 από τον Τσεσμέ της Μ.
          Ασίας παρουσία στρατιωτικού αγήματος από την μονάδα καταδρομέων του
          Περάματος, με κατεύθυνση την νέα προβλήτα του λιμανιού της Σκάλας
          Λουτρών.
          <br />
          Μετά την ευλογία των άρτων ο ιερέας και το στρατιωτικό άγημα
          επιβιβάσθηκαν στη βάρκα του κ. Ευστράτιου Καραντώνη από την
          Κουντουρουδιά και κρατώντας την εικόνα του Αγίου την περιέφεραν,
          ρίχνοντας το στεφάνι που την πλαισίωνε στην θάλασσα και ευχόμενοι
          ήρεμα ταξίδια για τους ναυτικούς και τους αλιείς μας.
          <br />
          Μετά το πέρας των θρησκευτικών εκδηλώσεων οι παριστάμενοι προσκλήθηκαν
          στην Βιβλιοθήκη του Συλλόγου όπου τους προσφέρθηκαν διάφορα εδέσματα,
          αναψυκτικά και καφές, όλα φτιαγμένα από τις νοικοκυρές του οικισμού
          μας. <br />
          Και του χρόνου με υγεία.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Μουσικό τμήμα.
        </Typography>

        <img
          src={Image75}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Λειτουργεί στην Βιβλιοθήκη του Συλλόγου κάθε Σάββατο, από τις 1 το
          μεσημέρι μέχρι τις 8 το βράδυ και αυτή την περίοδο φοιτούν 19 παιδιά
          σχολικής ηλικίας τα οποία μαθαίνουν κιθάρα. Τα μαθήματα γίνονται στην
          Βιβλιοθήκη του Συλλόγου και εκτός από πρακτικά είναι και θεωρητικά
          έτσι ώστε τα παιδιά να αποκτούν γερές βάσεις και να συνεχίσουν
          μελλοντικά τις σπουδές τους στην μουσική εφ όσον το επιθυμήσουν. Η
          μηνιαία συνδρομή είναι 20 ευρώ και όσοι ενδιαφέρονται για τα παιδιά
          τους μπορούν να έρθουν σε επικοινωνία με τον πρόεδρο του Συλλόγου κ.
          Δημήτρη Παπαχρυσό στο τηλ. 69746.40419 για περισσότερες πληροφορίες.
          Δάσκαλος είναι ο κ. Μολυβιάτης Γρηγόρης ο οποίος επί πολλά χρόνια έχει
          προσφέρει εξαιρετικό έργο.
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Νέα τμήματα.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Σύλλογός μας μπορεί να ιδρύσει νέα τμήματα παραδοσιακών μουσικών
          οργάνων όπως π.χ. βιολί, λαούτο κτλ εφ όσον εκδηλωθεί σχετικό
          ενδιαφέρον από τα μέλη μας. Διευκρινίζουμε ότι για να λειτουργήσει ένα
          νέο τμήμα θα πρέπει να ενδιαφερθούν κατ ελάχιστο τρία άτομα.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τμήματα παραδοσιακών τραγουδιών και χορών.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Αυτά τα τμήματα λειτουργούν περιστασιακά και πλαισιώνουν διάφορες
          εκδηλώσεις του Συλλόγου μας που έχουν σχέση με τις αλησμόνητες
          πατρίδες της Μ. Ασίας. Τα μαθήματα είναι δωρεάν και όσοι ενδιαφέρονται
          μικροί και μεγάλοι μπορούν να έλθουν σε συνεννόηση με την κ. Ευθαλία
          Τουρλή στο τηλ. 22510 91277.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τμήμα βιβλιοθήκης.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με 7.500 περίπου τίτλους βιβλίων είναι εμπλουτισμένη η βιβλιοθήκη του
          Συλλόγου μας τα οποία είναι διαθέσιμα για να δανειστούν στα μέλη μας,
          αλλά και σε όσους άλλους ενδιαφέρονται. Όλα τα βιβλία είναι
          καταχωρημένα σε ηλεκτρονικό υπολογιστή για την εύκολη διαχείριση τους.
          <br />
          Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει
          τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση
          είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα
          προβλήματα που αποτελούν τη ζωή του σύγχρονου ανθρώπου και τοποθετεί
          τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη
          διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και
          φανατισμούς.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Δωρεά βιβλίων στο Σύλλογό μας.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά την Γ. Γραμματεία του Υπουργείου Αιγαίου και
          νησιωτικής πολιτικής, για τα βιβλία που πρόσφερε μετά από αίτημα μας
          στην βιβλιοθήκη του Συλλόγου μας. <br />
          Βιβλία για την βιβλιοθήκη μας πρόσφεραν επίσης ο κ. Εγγλέζος Θεοφάνης
          και ο κ. Χατζηδήμος Αλέξανδρος, η κ.Χρυσούλα Χατζηγιαννιού και ο κ.
          Νίκος Δεληγιάννης τους οποίους επίσης ευχαριστούμε,
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ευχαριστήρια.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους παρακάτω.
          <br />
          *Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες
          συντήρησης των κτιρίων του Συλλόγου, τον Άρη Κερεντζή ο οποίος
          φροντίζει τον κήπο του Μουσείου, καθώς και όσους προσφέρουν
          εθελοντικώς την εργασία τους.
          <br />
          *Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο
          μας.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Οικονομικές ενισχύσεις.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.
          <br />
          <br />
          Δεσπότου Έλλη 150 ευρώ
          <br />
          Κρητικός Σταύρος 100 ευρώ
          <br />
          Τζανουδάκη Ιωάννα 50 ευρώ
          <br />
          Κεφαλίδης Δημήτρης 20 ευρώ
          <br />
          Σαμιώτου Μερόπη 20 ευρώ
          <br />
          Tenk Alave (France) 20 ευρώ
          <br />
          Ανώνυμοι 104 ευρώ
          <br />
          <br />
          <b>Κρητικού Φρόσω</b> 50 ευρώ στη μνήμη του πατέρα της Ευστρατίου.
          <br />
          <b>Περγαμηνού Γαρυφαλιά</b> 20 ευρώ στη μνήμη του συζύγου της
          Ευστρατίου.
          <br />
          <b>Περγαμηνού - Σφακιανού Χρυσούλα</b> 30 ευρώ στη μνήμη της μητέρας
          της Γαρυφαλιάς.
          <br />
          <br />
          Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του
          να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός για
          αναβάθμιση του οικισμού μας , έτσι ώστε να μπορέσουμε να
          ανταποκριθούμε στο βαρύ φορτίο των υποχρεώσεων που έχουμε αναλάβει.
          <br />
          Για καταθέσεις συνδρομών και ενισχύσεων στο Σύλλογο Τράπεζα Πειραιώς
          Αριθμός Λογαριασμού <u>6355-010482-750</u>{" "}
          <b>
            <u>IBAN GR94 0171 3550 0063 5501 0482 750</u>
          </b>
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default D14_drastiriotites_2017;
