import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g13/psosfigiki_katoikia.jpg";

function G13_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Προσφυγική κατοικία στη Σκάλα Λουτρών" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Δημοσιεύθηκε από τις 9-8-06 στην εφημερίδα και πέρασε από το Κ.Α.Σ.{" "}
          <b>(Κεντρικό Αρχαιολογικό Συμβούλιο)</b> με θετική γνωμοδότηση η
          πρόταση του Συλλόγου μας για <b>χαρακτηρισμό ως διατηρητέας</b> η διασωζόμενη προσφυγική κατοικία
          που υφίσταται ακόμη χωρίς αλλοίωση των δομικών της χαρακτηριστικών στη
          Σκάλα Λουτρών.{" "}
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ως γνωστόν ο Σύλλογός μας καταβάλει προσπάθεια για να διασωθεί και να
          ανακαινισθεί μια από τις 33 συνολικά προσφυγικές κατοικίες που
          κτίστηκαν το 1933 για να στεγάσουν τους Μικρασιάτες πρόσφυγες που
          είχαν έρθει και εγκαταστάθηκαν εδώ κατά την διάρκεια του διωγμού του
          1922. Στην εν λόγω κατοικία την οποία εάν κάποτε περιέλθει στην κατοχή
          μας θα την ανακαινίσουμε και θα τοποθετήσουμε σ αυτήν ένα μεγάλο μέρος
          από αυθεντικό υλικό που έχουμε συλλέξει από τους ίδιους τους πρόσφυγες
          έτσι ώστε να διατηρηθούν αναλλοίωτες οι μνήμες και να αποτελέσει αυτή
          η προσφυγική κατοικία ένα ενιαίο σύνολο με το απέναντι κτίριο του
          πρώην Δημοτικού σχολείου της Σκάλας Λουτρών το οποίο ως γνωστόν έχει
          μετατραπεί σε Μουσείο Προσφυγικής Μνήμης 1922 από το Σύλλογό μας. Εν
          αναμονή λοιπόν της δημοσίευσης αυτής της απόφασης στην εφημερίδα της
          κυβερνήσεως και ένα μεγάλο ευχαριστώ από όλους μας στην βουλευτή κ.
          Χριστιάνα Καλογήρου η οποία ενδιαφέρθηκε προσωπικά για να
          ξεμπλοκαριστεί αυτή η υπόθεση από το Υπουργείο Πολιτισμού.
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default G13_mikrasiatika;
