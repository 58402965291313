import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g16/01_Aggeliki_Matheou.jpg";
import Image02 from "../assets/images/mikrasiatika_g16/02_Aggeliki_Matheou.jpg";

function G18_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Εγκατάσταση των προσφύγων του '22 στη Λέσβο" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Προσφυγικό ζήτημα
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Αύγουστος 1922. Μια ημερομηνία που προκαλεί και κεντρίζει την προσοχή
          όλων όπου κι όπως κι αν αναφέρεται. Σε κάθε γωνιά της Ελλάδας όποιος
          Έλληνας κι αν ρωτηθεί, θα’χει κάτι να πει για την ιστορική αυτή χρονιά
          που αποτελεί κοινή επέτειο για δύο διαφορετικούς λαούς μεταφρασμένη
          όμως σε χαρμόσυνα εμβατήρια για τον ένα (τούρκοι), τραγωδία δε για τον
          άλλο (Έλληνες).
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Σε κάθε γωνιά της Ελλάδας όποιος Έλληνας κι αν ρωτηθεί, θα’χει κάτι να
          πει για την ιστορική αυτή χρονιά που αποτελεί κοινή επέτειο για δύο
          διαφορετικούς λαούς μεταφρασμένη όμως σε χαρμόσυνα εμβατήρια για τον
          ένα (τούρκοι), τραγωδία δε για τον άλλο (Έλληνες).
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Χωρίς άλλο πρόκειται για τη Μικρασιατική καταστροφή και το διωγμό των
          Ελλήνων από τη Μ. άσία, γεγονός που στιγμάτισε αποφασιστικά όχι μόνο
          τη ζωή των προσφύγων αλλά και των μετέπειτα γενιών τους παίζοντας
          συγχρόνως καθοριστικής σημασία ρόλο, στο πάλκο του θεάτρου, της
          πολιτικής, οικονομικής, και κοινωνικοπολιτιστικής σκηνής της Ελλάδας,
          και θέτοντας παράλληλα σοβαρή υποψηφιότητα για τον τίτλο των πιο
          συνταρακτικών γεγονότων του 20ου αιώνα, που συγκλόνισαν την Ελλάδα.
          θλιβεροί πρωταγωνιστές, αυτής της τραγωδίας, οι ασπρομάλληδες γέροι
          και γριές – όσοι ζουν ακόμα – θύματα του ανελέητου κυνηγητού της
          μοίρας τους, των βάρβαρων, αφηνιασμένων ορδών του κεμάλ, της βρώμικης
          και απρόσωπης πολιτικής των Μ. Δυνάμεων ή του παλαιοκομματισμού κατά
          άλλους. Έτσι, οι εξηγήσεις, και οι δικαιολογίες, οι ανακρίσεις και οι
          αποδόσεις ευθυνών με απώτερο σκοπό την «κατόπιν εορτής» απόδοση
          δικαιοσύνης και την παραδειγματική τιμωρία των υπαιτίων της
          καταστροφής, δεν μπορούσαν, παρά να δημιουργήσουν ένα κλίμα φανατισμού
          και σύγχυσης. οι πρόσφυγες λοιπόν, που για πολλά χρόνια την
          συναντούσες σε κάθε εφημερίδα, σε κάθε περιοδικό, σε κάθε χείλι.
          Επηρεασμένοι, φοβερά από αυτή τους την περιπέτεια, φθάνουν στην
          Ελλάδα, γιατί πιστεύουν ότι είναι ο μόνος δρόμος που τους έχει
          απομείνει. άποφασισμένοι, να παλέψουν, να επιζήσουν, να
          ξαναδημιουργηθούν, να ξαναμεγαλουργήσουν, αρχίζουν να τρέχουν στον
          μαραθώνιο που σήμανε ο αφέτης της 31 άυγούστου 1922…
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα τα προβλήματα που έχουν ν’ αντιμετωπίσουν είναι πολύ περισσότερα
          απ’ όσα φαντάζονται. Η παγερή πολιτική του αντιβενιζελισμού πέφτει
          ανελέητα πάνω στις ήδη κουρασμένες τους πλάτες κυνηγώντας τους όπου κι
          αν πάνε. ςε πολλούς οπαδούς του παλαιοκομματισμού, η αδιαφορία τους
          μετατρέπεται σε απέχθεια και πολλές φορές σε μίσος.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η παγερή πολιτική του αντιβενιζελισμού πέφτει ανελέητα πάνω στις ήδη
          κουρασμένες τους πλάτες κυνηγώντας τους όπου κι αν πάνε. ςε πολλούς
          οπαδούς του παλαιοκομματισμού, η αδιαφορία τους μετατρέπεται σε
          απέχθεια και πολλές φορές σε μίσος.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Αντιβενιζελικοί για να υποστηρίξουν την πολιτική του κόμματός τους
          δημοσιεύουν σε «φιλικές» τους εφημερίδες δηλητηριώδη σχόλια κατά των
          προσφύγων χαρακτηρίζοντάς τους με «επίθετα» (παρατσούκλια) δικιάς τους
          επινοήσεως – όπως «τουρκόσπορους» και «Γιαουρτοβαφτισμένους» - ….
          αλήθεια τι αληθινά ευρήματα.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Πάρα πολλά ακούστηκαν και πράχτηκαν εναντίον των προσφύγων που
          ξεκινούσαν από τις φανερές διαφορές που είχαν σε σχέση με τους
          ντόπιους. τις διαφορές γνώσεων, φερσίματος, ονομάτων και επιθέτων,
          φυλετικού χάσματος, της διαφοροποιημένης γλώσσας, και άλλων. Γιατί
          όπως και να το κάνουμε τα πάμπολλα προτερήματα των ανθρώπων αυτών,
          όπως η μεγάλη τους εργατικότητα, η καλόβουλή τους ευγένεια, η πλατιά
          μόρφωσή τους, και η δίχως σύνορα προοδευτικότητά τους, είναι μερικά
          από τα χαρακτηριστικά τους γνωρίσματα που επιβλητικά δέσποζαν και
          κυριαρχούσαν, πράγμα που δεν μπορούσαν να χωνέψουν οι ζηλόφθονες και
          οι συμφεροντολόγοι.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα δίκαια θ’ αναρωτηθεί κάποιος με τα γραφόμενά μου: «άραγε τόσο
          βρώμικα σκεφτόταν ο Ελληνικός λαός σε βάρος των αδελφών του
          προσφύγων;». άντίθετα οι παραπάνω σκέψεις δεν ήταν τίποτα άλλο, παρά
          σκέψεις μειονοτήτων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Γιατί παντού και πάντα οι αντιδημοκράτες, και οι αισχροί
          συμφεροντολόγοι, αποτελούν μειονότητες, και οι βρώμικες συνειδήσεις
          τους αποτελούν την κακοχαραγμένη πλευρά του νομίσματος που λέγεται
          λαός.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Όσο για την άλλη πλευρά του νομίσματος αυτού, αποτέλεσε και αποτελεί
          πάντα το στήριγμα και τη δόξα του κάθε έθνους. Ήταν λοιπόν δυνατόν να
          αθετήσει το αιώνιό της αυτό συμβόλαιο και να μη βοηθήσει τους
          πρόσφυγες στις δύσκολες αυτές ώρες; άδύνατο… και το ότι ήταν αδύνατον
          το αποδεικνύει ο ηράκλειος άθλος του Ελληνικού λαού που στάθηκε πλάι
          στο πλευρό των προσφύγων προσφέροντάς τους κάθε βοήθεια, υλική και
          ηθική.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα και οι άνθρωποι αυτοί τους το ανταπέδωσαν αφού σε κάθε μέρος της
          Ελλάδας που πήγαν να κατοικήσουν, άρχισε ν’ ανατέλλει ο ήλιος της
          αναγέννησης και της δημοκρατίας. Για την «ιδιαίτερή μου» πατρίδα την
          Μυτιλήνη όπου ήδη κυριαρχούσαν οι ιδέες και η πολιτική του
          βενιζελισμού, άρχισαν να δημιουργούνται οι προϋποθέσεις για ένα
          αρμονικό συνταίριασμα ανάμεσα στους φιλελεύθερους και δημοκρατικούς
          πρόσφυγες ομογενείς και στους ντόπιους Μυτιληνιούς.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα ας δούμε κάπως πιο εκτενέστερα την πτυχή αυτή της ιστορίας μας μέσα
          από τη ζωή ενός συγκεκριμένου τόπου. του νησιού μου. της λέσβου, και
          ειδικότερα της πρωτεύουσας Μυτιλήνης. βρισκόμαστε λοιπόν στα τέλη
          άυγούστου 1922 και αρχές ςεπτεμβρίου του ίδιου χρόνου.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          τα πρώτα θύματα της καταστροφικής μανίας των τούρκων, άρχισαν να
          καταφθάνουν. Πρώτα ο στρατός, κατόπιν, οι πρόσφυγες – γυναικόπαιδα,
          γέροι και γριές – θλιβερό εμπόρευμα των ατμόπλοιων που τους κουβαλούν,
          κατακλύζουν τα νησιά λέσβος, Χίος, ςάμος, που λόγω της θέσεώς τους
          είναι τα πρώτα που δέχονται την πρώτη μπόρα.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Oι σκηνές που διαδραματίζονται στην προκυμαία της Μυτιλήνης κατά την
          αποβίβαση των προσφύγων, είναι απερίγραπτες. και είναι τόσο έντονα
          βαμμένες με τα πιο χαρακτηριστικά χρώματα της δυστυχίας, που μπροστά
          τους ακόμα και η πιο σκληρή καρδιά και συνείδηση λυγίζει. Μια τεράστια
          αντίθεση κεντρίζει την προσοχή του καθένα που βλέπει, από τη μια μεριά
          ανθρώπους μέχρι χθες ευκατάστατους και τώρα κατεστραμμένους, και από
          την άλλη τους εμβρόντητους από τούτο το αναπάντεχο, Μυτιληνιούς.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Aρχίζει η επίταξη σπιτιών και Δημοσίων κτιρίων, όπως το Γυμνάσιο και
          τα δημοτικά σχολεία της πόλης για να καλυφθούν οι πρωταρχικές ανάγκες
          των διωγμένων. Μα ο λαός της Μυτιλήνης, ο απλός και πάντα πολέμιος της
          δυστυχίας λαός, ανοίγει τα σπίτια του, και βάζει μέσα τους πρόσφυγες,
          αφιλοκερδώς, για να μοιραστεί την οικογενειακή ζεστασιά και την
          ευτυχία του, μ’ αυτούς που τόσο τώρα την έχουν ανάγκη. Είναι πράγματι,
          μια αγνή και υπέροχη χειρονομία, δεινό όμως ράπισμα, στους αναίσθητους
          συμφεροντολόγους και συγχρόνως ένα εύγλωττο μάθημα σ’ όλους τους
          Έλληνες.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Όμως οι πρόσφυγες είναι πάρα πολλοί. άρχίζει η διασπορά τους σ’ όλο το
          μήκος και το πλάτος του νησιού. Aπό την πρωτεύουσα Μυτιλήνη μέχρι την
          ακριτική Μήθυμνα, και από το ςίγρι μέχρι το Πλωμάρι, ο αριθμός τους
          έχει ξεπεράσει τις 30.0001, ενώ παράλληλα τα ατμόπλοια συνεχίζουν να
          μεταφέρουν τις χιλιάδες ακόμα συγκεντρωμένων στην ςμύρνη καθώς και στα
          πέριξ παράλια, που περιμένουν με αγωνία τα πλοία της σωτηρίας τους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Όμως παρά την φρικιαστική κατάσταση των προσφύγων που καταφεύγουν, στο
          νησί μου, τα μέτρα και οι φροντίδες για την ανακούφισή τους αργούν και
          αργούν πολύ. η «σάλπιγγα» τοπική εφημερίδα της εποχής εκείνης
          σχολιάζοντας την κατάσταση που επικρατούσε γράφει σ’ ένα άρθρο της:
          «οι ατυχείς ούτοι, σωθέντες από την μάχαιραν του κεμάλ κινδυνεύουν ν’
          αποθάνουν από την αναλγησίαν της κυβερνήσεως του Ελευθέρου
          Ελληνισμού».
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          H μόνη αρχή που συγκινείται είναι ο στρατηγός Μπάκος2 (Διοικητής του
          νησιού) ο οποίος υπ’ ευθύνη του διατάζει να διανεμηθούν στους
          πρόσφυγες 5.000 μερίδες ψωμιού από αυτές του στρατού. η χειρονομία του
          όμως αυτή όσο ευγενής κι αν είναι, δεν αποτελεί,. παρά σταγόνα στον
          ωκεανό, στην κάλυψη των αναγκών των προσφύγων. άλλά πολύ σύντομα, και
          με την κήρυξη της στρατιωτικής επανάστασης του Πλαστήρα, οι προοπτικές
          ανακουφίσεως των προσφύγων αυξάνονται.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Aρχίζει η κάπως συστηματικότερη διανομή του ψωμιού και των βοηθημάτων
          στους πρόσφυγες. Μα είναι τόση η πληθώρα τους, και τόσο άμεση η ανάγκη
          περίθαλψής τους, που απαιτείται η όσο το δυνατόν γρηγορότερη αραίωσή
          τους, ή η κατά τόπους καταγωγής κατανομή τους. Γιατί μέσα σ’ όλα τα
          δεινά έρχονται να προστεθούν με τη σειρά τους οι απειλητικές
          εμφανίσεις οστρακιάς, τυφοειδούς πυρετού και άλλων νόσων, που απειλούν
          με τη μορφή επιδημίας ολόκληρη την πόλη.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Oι συνεχείς εκκλήσεις των προσφύγων μέσω των εφημερίδων, προς τα
          αρμόδια όργανα του δήμου, για τη λήψη μέτρων απολύμανσης και
          καθαρισμού των προσφυγικών καταλυμάτων, εντείνονται ολοένα και
          περισσότερο. Όμως παρά τον μεγάλο θόρυβο των αρχών περί «καθαριότητος
          και επιτροπής υγείας» οι αρμόδιοι δεν κατόρθωσαν να καθαρίσουν και ν’
          απολυμάνουν τις εστίες ακαθαρσιών παρά μόνο μια φορά. Γι’ αυτό και τα
          φθινοπωρινά πρωτοβρόχια τους απάλλαξαν για λίγες μέρες από τα
          καθήκοντά τους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Aπό την άλλη μεριά όμως, γίνεται εύκολα αντιληπτή η κατάσταση των
          καταφυγόντων στο ύπαιθρο προσφύγων, που ξυπνούν κάθε πρωί «κατάβρεχτοι
          μέχρις οστέων». Μα η «πενία που τέχνας κατεργάζεται» αναγκάζει πολλούς
          από τους δυστυχείς πρόσφυγες να καταφύγουν μέσα στην απελπισία τους
          στη λύση της κλοπής. Έτσι παρουσιάζονται πολλά κρούσματα ληστειών και
          διαρρήξεων καταστημάτων, με πληγέντα προϊόντα, παπούτσια, σεντόνια,
          τρόφιμα, ρούχα κτλ.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          H αναταραχή που δημιουργείται είναι μεγάλη και η χωροφυλακή βρίσκεται
          συνέχεια σ’ επιφυλακή. οι δυνάμεις της όμως είναι πολύ λίγες για ν’
          αντιμετωπίσουν παρόμοια κρούσματα, τα οποία εξαπλώνονται σ’ ολόκληρο
          το νησί. ςυγχρόνως εντείνονται οι εκκλήσεις προς τους Μικρασιάτες
          πρόσφυγες, αξιωματικούς της χωροφυλακής και χωροφύλακες να
          διασκορπιστούν σε διάφορα μέρη του νησιού και να προσφέρουν,
          προσωρινά, την τόσο αναγκαία συνδρομή τους, για την ασφάλεια της
          υπαίθρου χώρας. Παράλληλα έρχονται και από την άθήνα 40 χωροφύλακες3
          για να ενισχύσουν το έργο τής εδώ αστυνομικής δύναμης. Παρ’ όλα αυτά
          τα παράνομα κρούσματα συνεχίζονται ακόμα (ιδιαίτερα στο ύπαιθρο) και
          αρχίζουν να εξασθενίζουν μόνον όταν αρχίζει να εξασθενίζει η μιζέρια
          των κατατρεγμένων προσφύγων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Έτσι έρχεται ο καιρός που η περίθαλψή τους αρχίζει κάπως να
          συστηματοποιείται. η καθημερινή διανομή ψωμιού και δυο συσσιτίων (το
          ένα πίσω από το Δικαστικό μέγαρο και το άλλο στο συνοικισμό της επάνω
          σκάλας) που παρέχουν συνολικά 3.200 μερίδες στους πρόσφυγες, είναι δυο
          αρκετά αποτελεσματικά μέτρα. άν μάλιστα λάβουμε υπ’ όψιν και την
          σύντομη λειτουργία τρίτου συσσιτίου (μέσα στον Δημαρχιακό κήποι) οι
          παρεχόμενες μερίδες συσσιτίων ανέρχονται στις 6.200 ημερησίως. οι
          προκηρύξεις εράνων είναι ακόμη ένα άλλο αποτελεσματικό μέτρο για την
          ανακούφιση των προσφύγων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Kαι οι εκκλήσεις στις τοπικές εφημερίδες συνεχίζονται… «άς ανοίξωσι τα
          βαλάντιά των και ας δώσωσι όσο το δυνατόν περισσότερα τιμώντες και
          εαυτούς και τον τόπο των. άς δείξωσι όπως και πάντοτε ότι εξακολουθούν
          να διαπνέονται υπό των ευγενών αισθημάτων φιλανθρωπίας αλληλεγγύης και
          φιλευσπλαχνίας» γράφει χαρακτηριστικά η «ςάλπιγγα».
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Oι έρανοι είχαν μεγάλο αντίκτυπο στην καλυτέρευση των συνθηκών
          διαβίωσης των προσφύγων. κάθε λέσβος προσπάθησε και μ’ αυτό τον τρόπο
          να βοηθήσει όσο μπορούσε τους πληγέντες πρόσφυγες. Ήταν πολλοί αυτοί
          που έμειναν ασυγκίνητοι από τις συνεχείς εκκλήσεις για προσφορές υπέρ
          των διωγμένων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Kαι μάταια προσπαθούσαν μέσα από τις κατευρές και οξείς στήλες των
          εφημερίδων και περιοδικών οι διάφοροι συντάκτες και αρθρογράφοι να
          τους λυγίσουν. Πολλά λόγια και «συμπάθεια» για τους πρόσφυγες μα τα
          έργα και οι ενέργειες γι’ αυτούς μετρημένα. Όμως δεν ήταν μόνο αυτοί
          οι εχθροί των προσφύγων: Εχθροί τους ήταν και οι ασυνείδητοι
          μαυραγορίτες που προσπαθούσαν να επωφεληθούν, αναζητώντας τις
          «κατάλληλες» περιστάσεις για να αισχροκερδίσουν.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Εχθροί τους ήταν επίσης και οι ορισμένοι καταστηματάρχες, που λόγω
          επικείμενης έλλειψης τροφίμων και αναμενόμενης ανόδου της τιμής τους,
          έκλειναν τα μαγαζιά τους, αφήνοντας τον κοσμάκη να πεθάνει από την
          πείνα, περιμένοντας εκείνοι τις «κατάλληλες συνθήκες» για να
          ξανανοίξουν, ενώ από την άλλη μεριά οι εντιμοφανείς σαράφηδες
          εκμεταλλευόταν με τον πιο αχρείο τρόπο τους πρόσφυγες, οι οποίοι
          έχοντας φέρει από τη Μικρά άσία μερικές τουρκικές λίρες – όσες
          πρόλαβαν μέσα στην αναμπουμπούλα – ήταν αναγκασμένοι να τις
          εξαργυρώσουν με τις πιο εξευτελιστικές τιμές, αφού τους το υπενθύμιζε
          συνέχεια, η πείνα και η ανέχεια.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Προβλήματα… άτέλειωτα προβλήματα είχαν ν’ αντιμετωπίσουν οι δύστυχοι.
          Μόλις ψευτόλυναν το ένα να’σου και έβγαινε στο φως ένα μεγαλύτερο. κι
          έχοντας νωπές ακόμα τις ευχάριστες αναμνήσεις από το παρελθόν στη Μ.
          άσία κοντά σε αγαπημένα τους πρόσωπα, μη μπορώντας να
          συνειδητοποιήσουν την τόσο απότομα «ψεύτικη» αλλαγή στη ζωή τους
          ρωτούσαν όποιον γνωστό έβλεπαν, για την τύχη των δικών τους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Εξυπηρετώντας τον ίδιο σκοπό οι σελίδες των τοπικών εφημερίδων,
          γεμάτες εκείνη την εποχή με αγγελίες αναζητήσεων, είχαν κατορθώσει να
          αγκαλιάσουν πολλές «κρυφές ελπίδες» των προσφύγων, που για πολλούς
          ασπρομάλληδες γέροντες και γριούλες ήταν το μόνο αντίβαρο που τους
          κρατούσε στη ζωή. και ήταν πραγματική ανάσταση γι’ αυτούς που
          ειδοποιούνταν για την ανεύρεση των δικών τους, αλλ’ ακόμα μεγαλύτερη
          ευτυχία, γι’ αυτούς που τυχαία περπατώντας στην αγορά και
          αναθεματίζοντας τη μοίρα τους, έβλεπαν ξαφνικά ένα «χαμένο τους
          πρόσωπο». τότε ξεχνούσαν τα βάσανά τους και με περισσότερο κουράγιο
          και αποφασιστικότητα, μοιράζοντας εξίσου φαγητό και δυστυχία,
          πορεύονταν τον άγαρμπο ανήφορο της ζωής τους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Tέτοιες σκηνές δεν έλειπαν από το πρόσωπο της Μυτιλήνης, αντίθετα,
          ήταν φαινόμενα πολύ συχνά τις παράξενες αυτές μέρες. Μα και οι μέρες
          αυτές κυλούν και οι τραχιές – πρώτες ώρες της δυστυχίας αρχίζουν ν’
          απαλύνονται. καιρός είναι πια να βρει η ζωή του τόπου τον συνηθισμένο
          της ρυθμό, έχοντας όμως τούτη τη φορά στον πολύπλοκο μηχανισμό της, τα
          πολύτιμα για την λειτουργία της «γρανάζια», που λέγονται πρόσφυγες.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          καιρός και για τα προσφυγόπουλα να παν στο σχολείο. Γιατί αν είναι
          επιζήμιος ο υποσιτισμός γι’ αυτά, άλλο τόσο είναι επιζήμια και η αποχή
          τους από τα γράμματα, και το σχολείο. ςτην αρχή βέβαια οι δυσκολίες
          φοίτησης γι’ αυτά ήταν μεγάλες αφού για την εγγραφή τους
          παρουσιάζονται χίλιες δυο δυσχέρειες – δυσκολίες – εξετάσεις και
          αμέτρητες διατυπώσεις. το παρακάτω απόσπασμα ενός σχετικού άρθρου της
          τοπικής εφημερίδας «ςάλπιγγα» είναι χαρακτηριστικό για την κατάσταση
          που επικρατούσε στο παρθεναγωγείο της Μυτιλήνης:
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Πώς είναι δυνατόν τα ατυχή αυτά κοράσια να υποστώσι εξετάσεις αφού
          ούτε βιβλία κατόρθωσαν να διασώσωσιν ούτε την απαιτούμενην πνευματικήν
          ηρεμίαν ανέκτησαν, εισέτι μεθ’ όσα υπέστησαν. Φρονούμεν ότι ο αρμόδιος
          επιθεωρητής έχει καθήκον να υποδείξει εις το υπουργείον Παιδείας την
          ανάγκην της άνευ εξετάσεων και άλλων διατυπώσεων, εγγραφής εις τας
          αντιστοιχούσας τάξεις των εν Μικρασίας προσφύγων μαθητών και
          μαθητριών. το υπουργείο είναι αδύνατον να μην εγκρίνει την δικαίαν και
          λογικήν ταύτην υπόδειξην».
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα το άλλο μεγάλο και βασικό γρανάζι μιας κοινωνίας, αυτό που
          αποτελούν οι ιερείς – φορείς κάθε θρησκείας – έπρεπε να πάρει κι αυτό
          τη θέση του στην κοινωνία της λέσβου. Με διάφορες ενέργειες ο τοπικός
          ιερατικός σύνδεσμός Μυτιλήνης, αφού κατέγραφε τους ευρισκόμενους
          ιερείς πρόσφυγες της Μ. άσίας, έδινε τα ονόματά τους στην ιερά ςύνοδο,
          που κάλυπτε έτσι τις κενές ενοριακές θέσεις με τοποθέτηση προσφύγων
          ιερέων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Έτσι, ενώ μ’ αυτό τον τρόπο άρχισαν να τακτοποιούνται και οι πρόσφυγες
          ιερείς, κατάλληλα μέτρα άρχισαν να παίρνονται για την προστασία,
          στέγαση, και περίθαλψη των πιο κατατρεγμένων από όλα τα προσφυγόπουλα.
          τα μικρά ορφανά που ήρθαν κι αυτά κυνηγημένα από τη Μ. άσία για ν’
          αναζητήσουν την «τύχη» τους. η μετατροπή του μεγάρου «ριζά-Πασά»4 στην
          κράτηγο σε προσφυγικό ορφανοτροφείο έδωσε μια ενθαρρυντική ανάσα στους
          λιλιπούτειους αυτούς κατατρεγμένους, θλιβερό φόντο στον πίνακα της
          προσφυγιάς.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Εν τω μεταξύ στο χάραγμα των νέων δρόμων που έχουν ανοιχτεί στις
          σχέσεις Ελλάδος – άμερικής, πλέουν και τα καθημερινά ερχόμενα στη
          Μυτιλήνη πλοία που μεταφέρουν άφθονα είδη και τρόφιμα, μια πολύτιμη
          βοήθεια, και συγχρόνως μια τονωτική ένεση χαράς και ελπίδας για τους
          διωγμένους Έλληνες Μικρασιάτες. ςύμφωνα με πληροφορίες της εποχής, οι
          εφημερίδες έγραφαν, ότι το σύνολο των υπέρ των προσφύγων άμερικανικών
          εισφορών θα υπέρβαινε το δισεκατομμύριο.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα τη στιγμή που άρχισε να επικρατεί μια κάποια ηρεμία και όλα
          έδειχναν ότι το κάθε τι άρχισε να παίρνει το δρόμο του, μια
          πολυσυζητημένη απόφαση τάραξε ξανά τα ηρεμούντα νερά της ζωής της
          πόλης. Πρόκειται για την απόφαση, περί αραίωσης των προσφύγων. η
          ασφυκτική συγκέντρωσή τους στην πόλη, και ο κατακλυσμός του Δημοτικού
          κήπου απ’ αυτούς, προκαλούσε κινδύνους που απειλούσαν τη δημόσια
          υγεία. Έτσι διατάχτηκε η αραίωσή τους και θα στέλνονταν σε μέρη που θα
          όριζε το αρμόδιο υπουργείο. το γεγονός προκάλεσε πλήθος διαμαρτυριών
          και αρνήσεων. ο «Ελεύθερος λόγος» μια άλλη τοπική εφημερίδα της
          εποχής, γράφει: «… ούτω το απόγευμα της τετάρτης εσχηματίσθη ζώνη περί
          τον κήπον, εκλείσθησαν άπασες οι θύρες του, και υποχρεώθησαν οι εν
          αυτώ πρόσφυγες ν’ αναχωρήσουν αμέσως, επιβιβαζόμενοι, των επί τούτω
          αναμενόντων ατμοπλοίων. Ήτο σκληρόν όντως το θέαμα, και εσπάραζον την
          καρδίαν του ανθρώπου οι ολοφυρμοί και οι θρήνοι των γυναικοπαίδων τα
          οποία παρά τας κακουχίας της εν υπαίθρου ζωής ενόμιζον ότι απηλάγησαν
          τέλος των δεινών, των ταξιδείων και της διαρκούς μετατοπίσεώς των… Μα
          η αραίωσις επιβάλλετο».
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Πάει κι αυτό… Ένα ένα τα γεγονότα που έχουν άμεση σχέση με τους
          Μικρασιάτες πρόσφυγες και έχουν διαδραματίσει κάποιο προσωπικό ρόλο
          στα πράγματα του τόπου, περνούν ταχύτατα μπρος απ’ τα μάτια του κινητή
          της ιστορίας ο οποίος στρέφει αμείλικτα το βαρούλκο του ιστορικού
          χρόνου φθάνοντας έτσι αργά – αργά μέχρι τις μέρες μας. Μα στο μακρύ
          και ανώμαλο αυτό δρόμο συναντάμε ακόμα ένα πολυσυζητημένο θέμα:
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Tην περιβόητη «αγροτική εγκατάσταση των προσφύγων». η ανταλλαγή των
          πληθυσμών, είχε σαν συνέπεια την εκκένωση των τουρκικών κτημάτων σε
          κάθε τόπο που υπήρχε τουρκική ιδιοκτησία. ςτη λέσβο ένα πλήθος από
          στρέμματα έμεινε χωρίς ιδιοκτήτες. και το πρόβλημα που απασχόλησε τις
          αρχές ήταν το πώς και με τι κριτήριο θα διανέμοντο όλες αυτές οι
          εκτάσεις στους πρόσφυγες. ςύμφωνα με το σχετικό νομοθετικό διάταγμα
          περί «άγροτικής εγκατάστασης των προσφύγων»5 προβλεπόταν τα εξής:
          «Παραχώρηση γεωργικού κλήρου στους δικαιούμενους. Παραχώρηση στέγης.
          Παραχώρηση δανείων πολυετών και μονοετών στους μη δικαιούμενους
          γεωργικού κλήρου»
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Σύμφωνα με τις κρίσεις της ίδιας εφημερίδας, το παραπάνω διάταγμα έχει
          τον εξής αντίκτυπο στη λέσβο. «Δεν υπάρχει αμφιβολία ότι δια του
          διατάγματος αυτού, μέγα μέρος του κόσμου καθίσταται παραγωγό αμέσως σε
          μέρη όπου υπάρχουν γυμνές καλλιεργημένες εκτάσεις ή και ακαλλιέργητες.
          άν όμως εξεταστεί η ποιότητα του εδάφους της λέσβου στην οποίαν μόνο
          μια πεδιάδα ευρίσκεται, το δε υπόλοιπο μέρος είναι ελαιόφυτο θα
          δημιουργηθεί το ερώτημα, κατά πόσον είναι δυνατή η εφαρμογή του
          διατάγματος τούτου στην νήσον μας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα ακόμα και κατά την διανομή του ελαιόφυτου μέρους της λέσβου, πώς ο
          πρόσφυγας θα καταστεί αμέσως παραγωγός και θα συγκομίσει την παραγωγή,
          αφού η εσοδεία είναι αβέβαιη συνήθως από τα ελαιόδεντρα, στη λέσβο;»
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα τα προβλήματα δεν σταματούν εκεί, και στα μέρη όπου τα συμφέροντα
          συγκρούονται από την εγκατάσταση των προσφύγων, είτε γιατί οι ντόπιοι
          έχουν βεβαιωμένη ανεπάρκεια γαιών, είτε για άλλους λόγους, οι διαφορές
          θα λύνονται από τώρα και στο εξής από επιτροπές που θα καταρτίζονται
          γι’ αυτό το λόγο, αποτελούμενες από ίσον αριθμό ντόπιων και προσφύγων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Όμως αυτοί που νοιάζονται περισσότερο για την ανταλλαγή των πληθυσμών
          και περιουσιών είναι οι άλλοτε «εν Μικρασία τσιφλικιούχοι πρόσφυγες».
          και αυτό γιατί ελπίζουν ότι με την ανταλλαγή θα πάρουν άλλα τσιφλίκια
          εδώ στην Ελλάδα, αντί των χαμένων. και σύμφωνα με κριτική της
          εφημερίδας «καμπάνα»: «άυτοί μεν καλά κάνουν και γυρεύουν τσιφλίκια. η
          κυβέρνηση όμως με κανένα τρόπο δεν πρέπει να δώσει τα τσιφλίκια των
          αποχωρούντων μπέηδων σ’ αυτούς. Γιατί ο σκοπός του εποικισμού των
          προσφύγων δεν είναι ν’ αποδώσει το δίκαιον στους πρόσφυγες, αλλά να
          τους δώσει τα μέσα να ζήσουν και να προκόψουν». Μα παρ’ όλα αυτά,
          αντίθετα με την παραπάνω διατυπωθείσα ορθή αντίληψη, άρχισαν να
          δημιουργούνται προνομιούχες τάξεις προσφύγων. και σύμφωνα με
          πληροφορίες της ίδιας εφημερίδας6 το πρώτο «κρούσμα» ένας πλούσιος
          πρόσφυγας από το άϊδίνι ονόματι θ. Γεωργιάδης κατάφερε να του δοθούν
          20.000 ελαιόδενδρα τουρκικά, από τα οποία θα μπορούσαν να ζήσουν 500
          οικογένειες προσφύγων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Αδικίες… Μα αν δεν υπήρχαν οι αδικίες και τα σφάλματα, τότε δεν θα
          ήταν ανάγκη να υπάρχουν νόμοι και δικαστήρια, αφού ότι έπραττε ο
          καθένας θα ήταν σωστό και χρήσιμο. Μερικές φορές πάλι, η παρουσία μιας
          αδικίας είναι ωφέλιμη γιατί κάνει πιο εμφανή την αντίθεση στην τραγική
          εικόνα μιας κατάστασης, και αφυπνίζει τους γύρω.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μια άλλη ενδιαφέρουσα ανακοίνωση, «πρόσκληση προς τους εργοστασιάρχες»
          αφορά την παραχώρηση ελαιοτριβείων σε πρώην εργοστασιάρχες πρόσφυγες,
          οι οποίοι σύμφωνα με την ίδια ανακοίνωση προσκαλούνται να προσέλθουν
          σε σύσκεψη για την κατάρτιση ομίλων, έχοντας μαζί τους τα
          πιστοποιητικά ότι είναι πράγματι πρόσφυγες και ότι είχαν εργοστάσιο
          στην τουρκία.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Και ενώ η παρέλαση των ιστορικών γεγονότων πλησιάζει προς το τέλος του
          χρονικού διαστήματος που εξετάζουμε, βρισκόμενοι στα μέσα του
          καλοκαιριού του 1923 και έχοντας αφήσει μόλις λίγο πίσω μας την
          απόφαση περί διακοπής της άμερικανικής βοήθειας προς τους πρόσφυγες
          αρχίζουμε να διακρίνουμε ξεκάθαρα μπροστά μας τα προεόρτια ενός πολύ
          μεγάλου πανηγυριού για τον πολιτικό μα και για ολόκληρο τον κόσμο «τις
          περιβόητες εκλογές του 1923».
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Οι ηχηρές και επίδοξες δηλώσεις, των επί του πολιτικού βάθρου
          ισταμένων προσώπων της εποχής, σε συνδυασμό με τις ανάλογες
          επιδοκιμασίες ή αποδοκιμασίες του λαού των συλλαλητηρίων, φτιάχνουν
          ένα μεθυστικό και συγχρόνως φανατικό κλίμα, με βασικούς πάντα φορείς
          ειδήσεων τις εφημερίδες και προμηνύουν για τον καθένα ένα ευχάριστο
          και ικανοποιητικό για την πολιτική παράταξή του αποτέλεσμα. οι
          πρόσφυγες μέσα σ’ αυτήν την πανδαισία της πολιτικής έξαρσης του τόπου,
          τείνουν να γίνουν αντικείμενο συζήτησης, διαφωνίας μα και διαμάχης.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Κάθε παράταξη, βενιζελικοί και άντιβενιζελικοί, φιλοδοξεί να τους έχει
          με το μέρος της. Όμως, από τη στιγμή που ο άντιβενιζελισμός βλέπει ότι
          αρχίζει να χάνει το παιχνίδι – αφού η τωρινή θέση των προσφύγων, είναι
          τόσο θλιβερή, συνέπεια της δικιάς τους πολιτικής – αλλάζει τακτική
          απέναντί τους, υποστηρίζοντας ότι δεν πρέπει να ψηφίσουν στις εκλογές
          του Δεκεμβρίου. άυτό δε, το στηρίζουν, όπως ισχυρίζονται, στο ότι οι
          πρόσφυγες λόγω της πρόσφατης εγκατάστασής τους στην Ελλάδα δεν
          γνωρίζουν καλά την πολιτική κατάσταση του τόπου, το συμφέρον του και
          το καλό του. Άρα δεν είναι σε θέση να ψηφίσουν, παίρνοντας θέση υπέρ
          του ενός ή του άλλου κόμματος.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Από την άλλη μεριά, οι βενιζελικοί υποστηρίζουν τη συμμετοχή των
          προσφύγων στις εκλογές, γιατί πιστεύουν ότι κάτι τέτοιο θα είναι
          σύμφωνο με το καλό του τόπου. ςυγχρόνως με τον φιλικό προς αυτούς τύπο
          εκφράζουν την αντίθεσή τους απέναντι στην άποψη των άντιβενιζελικών,
          προβάλλοντας επιχειρήματα και καταδικάζοντάς τον σε αποτυχία, πριν
          ακόμα διεξαχθεί ο εκλογικός αγώνας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Οι πρόσφυγες βρισκόμενοι στο κέντρο της διαμάχης, πιστεύουν ακράδαντα,
          ότι πρέπει να συνεισφέρουν την ψήφο τους σ’ αυτές τις εκλογές από τις
          οποίες κρίνεται το μέλλον του τόπου, και φυσικά και το δικό τους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η ταύτιση αυτή των απόψεών τους με το βενιζελικό κόμμα, καθώς και η
          μεγάλη εμπάθειά τους προς τους αντιβενιζελικούς – τους οποίους θεωρούν
          αίτιους της συμφοράς τους – τους οδηγεί στην απόφαση να κατέβουν στις
          εκλογές του Δεκεμβρίου στο πλευρό των βενιζελικών (Φιλελευθέρων).
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα ο τελικός λήπτης κάθε απόφασης, είναι η κυβέρνηση και στην
          προκειμένη περίπτωση η επαναστατική κυβέρνηση του Πλαστήρα, με σχετικό
          διάταγμα7 αποφασίζει την συμμετοχή των προσφύγων στις εκλογές
          καθιστώντας τους συγχρόνως – τους προερχόμενους από Μ. άσία, άνατολική
          θράκη, κωνσταντινούπολη, καύκασο και Δωδεκάνησο – Έλληνες πολίτες με
          την εγγραφή τους στα δημοτολόγια ή στα μητρώα των κοινοτήτων της
          διαμονής τους. Έτσι: κατά την μεν διεξαγωγή των εκλογών οι πρόσφυγες
          θα ψηφίζουν μαζί με τους υπόλοιπους δημότες υποψηφίους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Όπου δε, λόγω του μεγάλου αριθμού τους, ή του απεμακρυσμένου των
          συνοικισμών τους υπάρχει ανάγκη θα ιδρύονται, εκεί, εκλογικά τμήματα.
          οι προετοιμασίες συνεχίζονται, οι καυτοί λόγοι εκφωνούνται τώρα πιο
          έντονα από πριν, ο πυρετός της προεκλογικής διαμάχης ανεβαίνει, τα
          συλλαλητήρια έχουν γίνει ένα κομμάτι από την καθημερινότητα του τόπου,
          και οι εκλογές καθώς πλησιάζουν, αργά, αργά αλλά σταθερά, αποτελούν το
          κύριο θέμα κάθε κύκλου και παρέας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα, μια και μιλήσαμε για συλλαλητήρια και συγκεντρώσεις, ας
          αναφερθούμε στα μεγάλα τελευταία συλλαλητήρια που έγιναν στη Μυτιλήνη,
          και συγκέντρωναν πάνω από τα 2/3 του πληθυσμού της σε κάθε τους
          διεξαγωγή.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          κατά τη διάρκεια του τελευταίου αυτού χρόνου, είχαν προηγηθεί και άλλα
          συλλαλητήρια, όπως αυτά για την υποστήριξη της επανάστασης, και την
          επιδοκιμασία της καταδικαστικής της απόφασης κατά των ενόχων της Μ.
          καταστροφής, αλλά και αργότερα αυτά στα οποία κυριαρχούσαν τα
          συνθήματα αποδοκιμασίας και στιγματισμού του στασιαστικού κινήματος
          του Μεταξά σαν προδοτικό και εθνοκτόνο.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Εν όψει όμως τώρα των εκλογών, και της εμφανίσεως του καυτού ζητήματος
          που αφορά τη μορφή του πολιτεύματος μετά τις εκλογές – βασιλευομένη ή
          αβασίλευτη δημοκρατία – η ένταση και η διχογνωμία πάνω σ’ αυτό είναι
          τόσο έντονη ώστε προκαλεί την διεξαγωγή νέων συλλαλητηρίων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στη Μυτιλήνη μέσα σε καταρρακτώδη βροχή, ο λεσβιακός και ο προσφυγικός
          κόσμος «αποδεικνύουνε εν παμψηφία πραγματική» ότι έχουν συναίσθηση
          τόσο των δικαιωμάτων τους όσο και των καθηκόντων τους. άπαιτούν δε,
          την μεταβολή του πολιτεύματος «εις Δημοκρατικόν και η μεταβολή αυτή να
          γίνει αμέσως δι’ αμέσου δημοψηφίσματος». άυτά περιελάμβανε το εκδοθέν
          ψήφισμα8 κατά τη διεξαγωγή αυτού του συλλαλητηρίου.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Κι ενώ οι αντιβενιζελικοί απογοητευμένοι και πεπεισμένοι για την
          μέλλουσα συντριβή τους, προσπαθούν με νωθρές και σπασμωδικές κινήσεις
          να εμποδίσουν τη συμμετοχή των προσφύγων στις εκλογές, - λέγοντας ότι
          «δεν ωρίμασαν ακόμη πολιτικώς διά να έχουν εκλογικό δικαίωμα, και δεν
          γιγνώσκουν ικανώς τα εν Ελλάδι πρόσωπα και πράγματα» – από την άλλη
          μεριά, μέσα στην παράταξη των Φιλελευθέρων (βενιζελικών)
          δημιουργούνται δυο εκλογικοί συνδυασμοί: Διασπασμένοι λοιπόν οι
          βενιζελικοί σε Φιλελεύθερους Δημοκρατικούς και σε Φιλελεύθερους
          συντηρητικούς.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Έτσι παραγκωνίζοντας τους κακόμοιρους πλέον άντιβενιζελικούς οι οποίοι
          βρίσκονται σε δίλημμα για το αν θα κατέβουν στις Δεκεμβριανές εκλογές,
          αρχίζουν να μάχονται μεταξύ τους περιορίζοντας το μέτωπο του εκλογικού
          αγώνα και τη στροφή όλων των βλεμμάτων, στην μεταξύ τους αναμέτρηση.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Οι πρόσφυγες οπαδοί πάντα του βενιζελισμού, μπροστά στη νέα αυτή τροπή
          που παίρνουν τα γεγονότα της πολιτικής σκηνής, διαμοιράζονται στα δυο
          αυτά βενιζελικά στρατόπεδα και συνασπισμένοι στους δυο παρακάτω
          λεσβιακούς συνδυασμούς, περιμένουν με αγωνία και αποφασιστικότητα το
          σύνθημα της 16ης Δεκεμβρίου 1923. ςταθμός για την πολυτάραχη ζωή τους
          και για την άλλο τόσο τραχιά και πολυσέλιδη ιστορία τους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Tο ξημέρωμα της Δευτέρας 17 Δεκεμβρίου 1923 με τη διαλογή των ψήφων,
          και την σύγχρονη συντριπτική ήττα των άντιβενιζελικών – κάτι που
          βέβαια αναμενόταν – βρίσκει τους Φιλελεύθερους (βενιζελικούς) σε μια
          από τις πιο μεγάλες μέρες της ιστορίας τους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μα κοντά τους τώρα – κρατώντας το έπαθλο της νίκης – όπως και πριν
          κατά τον προεκλογικό αγώνα οι πολυβασανισμένοι πρόσφυγες. Γι’ αυτούς η
          μεγάλη αυτή νίκη είχε βαθύτερο νόημα από μια απλή εκλογική νίκη, και
          τους πανηγυρισμούς της. Ήταν η επισφράγιση των δημοκρατικών ιδεολογιών
          τους, και η γλυκιά ανταμοιβή των διωγμών τους από τα «ζεστά» σπίτια
          τους. Ήταν ένα μάθημα εκδίκησης και τιμωρίας στους «στυγνούς εραστές
          των εξουσιών και υπαίτιους της συμφοράς τους». Ήταν τέλος ένα
          ασφαλιστικό συμβόλαιο για τη μέλλουσα ζωή τους και γι’ αυτήν την τόσο
          πολύτιμη των παιδιών τους στα οποία ονειρεύονταν να εξασφαλίσουν την
          ανύπαρκτη γι’ αυτούς «επιτυχία της ζωής», την ευτυχία.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Παρ’ όλα αυτά ο χρόνος που δεν σταμάτησε ποτέ να γυρνά, δεν σταμάτησε
          ούτε τότε. οι ρακένδυτοι αυτοί «ευγενείς αλήτες» παρ’ όλες τις βαριές
          επικρίσεις και κατηγορίες των αντιβενιζελικών, οι οποίοι τους θεώρησαν
          υπαίτιους για την παταγώδη εκλογική αποτυχία τους, ζώντας επί πολλά
          χρόνια μετά την τραγική τους έξοδο, μέσα σ’ ένα διαρκή χειμώνα,
          περίμεναν με αγωνία το χαρμόσυνο άγγελμα της άνοιξης. Ποιος ξέρει
          πόσοι από αυτούς πρόλαβαν να το γευθούν; Πόσοι απ’ αυτούς πρόλαβαν να
          εξασφαλίσουν για τα παιδιά τους την ευτυχία αυτή την τόσο πολυδάπανη
          και περιζήτητη γι’ αυτούς χίμαιρα;
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Πόσοι; Πόσοι; Μα όσοι και να’ναι αυτοί, δεν μπορούν ούτε καν να
          μετριάσουν τα βάσανα και τον πόνο των υπόλοιπων αθώων προσφύγων που
          ποτέ τους δεν γεύτηκαν τη χαρά της επιτυχημένης ζωής. Δυστυχώς…
          θέλοντας ή όχι, μερικές ακόμα εκατοντάδες «ανθρώπων» προστέθηκαν στη
          στρατιά των παραγκωνισμένων, θύματα της αλόγιστης ανθρώπινης
          συμβίωσης…
        </Typography>
        <br />
        <br />
        <Typography variant="h3" sx={theme.typography.typography2}> Γρηγόρης Μολυβιάτης</Typography>
        <br />
        <br />
       
      </Box>

      <ScrollToTopButton />

      
    </>
  );
}

export default G18_mikrasiatika;
