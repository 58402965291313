import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles'

import ResponsiveDrawer from './components/ResponsiveDrawer';

import D107_Karsi from './screens/D107_Karsi';


const theme = createTheme({

  typography: {
    typography1: {
      color: 'blue',
      textAlign: 'center',

    },

    typography2: {
      color: 'black',
      textAlign: 'left'
    },

    typography3: {
      color: 'orange',
    },

    fontFamily: 'Roboto',

    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginTop: '1em',
      marginBottom: '1em'
    },

    h3: {
      fontSize: '1.3rem',
      // fontWeight: 600, 
      marginTop: '1em'
    },

  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>

      <Router>
        <ResponsiveDrawer />        
      </Router>    

    </ThemeProvider>

  //   <ThemeProvider theme={theme}>
  //   <Router>
  //     <Routes>
  //       <Route path="/karsi" element={<D107_Karsi />} />
  //       {/* Add more routes here if needed */}
  //       <Route path="*" element={<ResponsiveDrawer />} />
  //     </Routes>
  //   </Router>    
  // </ThemeProvider>
  );
}

export default App;