import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/drastiriotites2014/image1.jpg";
import Image02 from "../assets/images/drastiriotites2014/image2.jpg";
import Image03 from "../assets/images/drastiriotites2014/image3.jpg";
import Image04 from "../assets/images/drastiriotites2014/image4.jpg";
import Image05 from "../assets/images/drastiriotites2014/image5.jpg";
import Image06 from "../assets/images/drastiriotites2014/image6.jpg";
import Image07 from "../assets/images/drastiriotites2014/image7.jpg";
import Image08 from "../assets/images/drastiriotites2014/image8.jpg";
import Image09 from "../assets/images/drastiriotites2014/image9.jpg";
import Image10 from "../assets/images/drastiriotites2014/image10.jpg";
import Image11 from "../assets/images/drastiriotites2014/image11.jpg";
import Image12 from "../assets/images/drastiriotites2014/image12.jpg";
import Image13 from "../assets/images/drastiriotites2014/image13.jpg";
import Image14 from "../assets/images/drastiriotites2014/image14.jpg";
import Image15 from "../assets/images/drastiriotites2014/image15.jpg";
import Image16 from "../assets/images/drastiriotites2014/image16.jpg";
import Image17 from "../assets/images/drastiriotites2014/image17.jpg";
import Image18 from "../assets/images/drastiriotites2014/image18.jpg";
import Image19 from "../assets/images/drastiriotites2014/image19.jpg";
import Image20 from "../assets/images/drastiriotites2014/image20.jpg";
import Image21 from "../assets/images/drastiriotites2014/image21.jpg";
import Image22 from "../assets/images/drastiriotites2014/image22.jpg";
import Image23 from "../assets/images/drastiriotites2014/image23.jpg";
import Image24 from "../assets/images/drastiriotites2014/image24.jpg";
import Image25 from "../assets/images/drastiriotites2014/image25.jpg";
import Image26 from "../assets/images/drastiriotites2014/image26.jpg";
import Image27 from "../assets/images/drastiriotites2014/image27.jpg";
// import Image28 from "../assets/images/drastiriotites2014/image28.jpg";

function D11_drastiriotites_2014() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Οι δραστηριότητές μας το 2014" />
      </Box>
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Κοπή της Βασιλόπιτας του Συλλόγου μας
        </Typography>
        <img src={Image01} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image02} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε με επιτυχία η χοροεσπερίδα του Συλλόγου μας, την
          παραμονή της Πρωτοχρονιάς, στις 10 το βράδυ στο ξενοδοχείο «
          <b>SILVER BAY</b>». Η αίθουσα του ξενοδοχείου ήταν κατάμεστη από τα
          μέλη και τους φίλους του Συλλόγου που συμμετείχαν για μια ακόμη χρονιά
          στην καθιερωμένη εκδήλωση και έδειξαν ότι, παρά την οικονομική κρίση,
          υποστηρίζουν τις εκδηλώσεις μας και μας δίνουν κουράγιο για να
          συνεχίσουμε το έργο που επιτελούμε ανελλιπώς από το <b>1990</b>.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχάριστη εξαίρεση στην φετινή εκδήλωση, η συμμετοχή ενός γκρουπ
          τουριστών από τα απέναντι Μικρασιατικά παράλια οι οποίοι είχαν έλθει
          στο νησί μας και διέμεναν στο ξενοδοχείο για να γιορτάσουν την
          πρωτοχρονιά. Το κέφι ήταν παντού διάχυτο και η ορχήστρα του
          ξενοδοχείου μας κράτησε συντροφιά μέχρι τα ξημερώματα της
          Πρωτοχρονιάς.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Με την αλλαγή του χρόνου κόπηκε η βασιλόπιτα του Συλλόγου η οποία
          μοιράστηκε σε όλα τα τραπέζια. Τυχερός της βραδιάς με κλήρωση ήταν ο
          κ. <b>Γεώργιος Λεοντής</b> που με τον αριθμό <b>136</b> κέρδισε το
          φλουρί της πίτας, ένα ασημένιο χειροποίητο Κωσταντινάτο, προσφορά του
          χρυσοχοείου <b>Βέτσικα</b>, καθώς και ένα γυναικείο ρολόι προσφορά από
          το ρολογάδικο του κ. <b>Ιωάννη Μολυβιάτη</b>.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Κατά την διάρκεια της εκδήλωσης διενεργήθηκε λαχειοφόρος αγορά και
          τυχερός αναδείχθηκε ο κ. <b>Ευστράτιος Δελάγας</b> ο οποίος με τον
          αριθμό <b>549</b> κέρδισε μία τηλεόραση σύγχρονης τεχνολογίας{" "}
          <b>LED 40</b> ιντσών. Και του χρόνου με υγεία.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ο Αγιασμός των υδάτων στη Σκάλα Λουτρών και στη Χαραμίδα, τον
          περασμένο Ιανουάριο.
        </Typography>
        <img src={Image03} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image04} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με την παρουσία τόσο του Δημάρχου Λέσβου, κ.{" "}
          <b>Βουνάτσου Δημητρίου</b> όσο και του αντιδημάρχου καθαριότητας κ.{" "}
          <b>Κονιαρέλλη Ηλία</b>, πραγματοποιήθηκε ο Αγιασμός των υδάτων τόσο
          στη Σκάλα Λουτρών όσο και στη Χαραμίδα.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Η συμμετοχή των κατοίκων ήταν μεγάλη αφού μετά το πέρας της Θείας
          Λειτουργίας στον Ιερό Ναό του Αγίου Γεωργίου Λουτρών, ο ιερέας μαζί με
          το εκκλησίασμα ξεκίνησαν για τον αγιασμό των υδάτων στην προβλήτα της
          Σκάλας Λουτρών, ένα έθιμο το οποίο επαναλαμβάνετε αδιαλείπτως κάθε
          χρόνο.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Αφού διάβασε ο ιερέας της ενορίας μας το ευαγγέλιο πάνω στην εξέδρα
          που γι αυτό το σκοπό είχε στηθεί, μόλις είπε το «εν Ιορδάνη
          βαπτιζομένου σου Κύριε…» τέσσερις θαρραλέοι κολυμβητές βούτηξαν στα
          παγωμένα νερά του κόλπου Γέρας και αυτός που έπιασε πρώτος τον Σταυρό
          τον οποίο και ανέσυρε στην επιφάνεια, ήταν ο <b>Άγγελος Μίνικνας</b>{" "}
          από τα Λουτρά. Αμέσως μετά το πολιτιστικό κέντρο Λουτρών έκοψε την
          καθιερωμένη βασιλόπιτα προσφέροντας συγχρόνως και από ένα ποτό στους
          παρευρισκόμενους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μετά τον Αγιασμό των υδάτων που έγινε στη Σκάλα Λουτρών ο ιερέας και
          οι περισσότεροι από τους παρευρισκόμενους, ξεκίνησαν για να
          παρευρεθούν στον Αγιασμό των υδάτων στην περιοχή της Χαραμίδας όπου
          περίμεναν πολλοί κάτοικοι της περιοχής, καθώς και αρκετοί Μυτιληνιοί.
          Η καθιερωμένη τελετή και εδώ πραγματοποιήθηκε χωρίς ιδιαίτερα
          προβλήματα και τον Σταυρό ανέσυραν από τη θάλασσα από κοινού οι{" "}
          <b>Μαμουνής Σωτήρης</b> και <b>Μιχάλης Μακρής</b>.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Αμέσως μετά ακολούθησε και εδώ το κόψιμο της βασιλόπιτας των κατοίκων
          της περιοχής και το σχετικό κέρασμα σε όλους τους παρευρισκόμενους.
          Παρόντες και στις δύο τελετές τα μέλη του Τοπικού Συμβουλίου Λουτρών
          καθώς και το Δ.Σ. με τα μέλη του Συλλόγου Μικρασιατών της Σκάλας
          Λουτρών. <br />
          Και του χρόνου με υγεία.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αποκριάτικη παιδική εορτή
        </Typography>
        <img src={Image05} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image06} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Κυριακή 23 Φεβρουαρίου, για δεύτερη χρονιά, πραγματοποιήθηκε στο
          ξενοδοχείο Κουντουρουδιά, αποκριάτικη παιδική εορτή, με τη συμμετοχή
          όλων των μικρών μας φίλων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τα παιδιά, αλλά και αρκετοί από τους γονείς, μεταμφιεσμένοι ανάλογα με
          τα γούστα τους ξεφάντωσαν για αρκετές ώρες στον κατάλληλα διακοσμημένο
          χώρο του ξενοδοχείου. Οι μητέρες είχαν φέρει μαζί τους διάφορα
          γλυκίσματα όπως κέικ, τούρτες, και όλων των ειδών τις λιχουδιές. Όλοι
          περάσαμε πραγματικά υπέροχα και πρόθεσή μας είναι αυτή την γιορτούλα
          να την καθιερώσουμε και να την επαναλαμβάνουμε κάθε χρόνο αυτή την
          εποχή. Η μεταμφίεση ορισμένων ενηλίκων σε κλόουν, ενθουσίασε τα παιδιά
          και το ξεφάντωμα ήταν αναπόφευκτο.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Σύλλογός μας ευχαρίστησε όλες τους γονείς για την συμμετοχή τους και
          ειδικότερα τις μητέρες των μικρών παιδιών της παιδικής ομάδας Play
          group για την εξαιρετική βραδιά που μας πρόσφεραν. Τέλος θα ήταν
          παράλειψη αν δεν ευχαριστούσαμε την διεύθυνση του ξενοδοχείου
          ΚΟΥΝΤΟΥΡΟΥΔΙΑ που μας διέθεσε αφιλοκερδώς την αίθουσα για να
          πραγματοποιηθεί η εκδήλωσή μας.
          <br /> Και του χρόνου με υγεία.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Πάσχα στη Σκάλα Λουτρών
        </Typography>
        <img src={Image07} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Για ένατη συνεχή χρονιά πραγματοποιήθηκαν οι θρησκευτικές εκδηλώσεις
          των αγίων ημερών του Πάσχα στο εκκλησάκι του Αγίου Παντελεήμονα στη
          Σκάλα Λουτρών. Το πρωί της Κυριακής των Βαΐων, ετελέσθη η θεία
          λειτουργία με μεγάλη συμμετοχή πιστών και το απόγευμα της ίδιας μέρας
          η ακολουθία του Νυμφίου. Εν συνεχεία την Μ. Τετάρτη το απόγευμα
          ετελέσθη το Μυστήριο του Ευχελαίου και την Μ. Πέμπτη η ακολουθία των
          παθών με τα 12 Ευαγγέλια.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Μ. Παρασκευή έγινε η αποκαθήλωση του εσταυρωμένου και η περιφορά
          του επιταφίου σε όλο τον οικισμό, σε μια διαδρομή που δένει αρμονικά
          το πράσινο των αγρών με το μπλε της θάλασσας, υπό το άπλετο αιολικό
          φως, για να μας θυμίζει την απέριττη ομορφιά της τέχνης που βρίσκεται
          πάντα κοντά στο απλό. Την τελετή της περιφοράς συμπλήρωνε η χορωδία
          από ομάδα γυναικών μελών του Συλλόγου μας. Η συμμετοχή όλων των
          κατοίκων και της νεολαίας του οικισμού μας ήταν πλήρης..
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τέλος το βράδυ του Μεγάλου Σαββάτου, μέσα σε μια κατανυκτική
          ατμόσφαιρα υπό το φως των κεριών ετελέσθη η αναστάσιμη Θεία Λειτουργία
          από τον πρωτοσύγκελο της Ιεράς Μητροπόλεως Μυτιλήνης. Ο σύλλογός μας
          τηρώντας το έθιμο, στο τέλος της Θείας λειτουργίας μοίρασε σε όλους
          τους παρευρισκόμενους κόκκινα αυγά και τσουρέκια. Εμείς από την πλευρά
          μας ευχαριστούμε θερμά τον εφημέριο της ενορίας μας, τον πρωτοσύγκελο
          της Ιεράς Μητροπόλεως και τον Σεβασμιότατο Μητροπολίτη Μυτιλήνης, που
          μερίμνησαν και ικανοποίησαν το αίτημά μας για αποστολή ιερέα. Η
          συμμετοχή των κατοίκων ήταν καθολική και ιδίως των ηλικιωμένων οι
          οποίοι προσήλθαν με μεγάλη χαρά.
          <br />
          Και του χρόνου να είμαστε γεροί να ξαναγιορτάσουμε όλοι μαζί αυτές τις
          Άγιες ημέρες.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ο στολισμός του επιταφίου
        </Typography>
        <img src={Image08} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Όλες οι γυναίκες του οικισμού μας καθώς και όλα τα νέα κορίτσια κάθε
          χρόνο αφιερώνουν με μεγάλη χαρά, πολλές ώρες, για να είναι ο στολισμός
          του επιταφίου ότι καλλίτερο. Η Ευθαλίτσα, η Γραμματούλα, η
          Δεσποινούλα, η Ανθούλα, η Άννα, η Ελένη, η Ειρήνη, η Βασιλεία, η
          Βικτωρίτσα, η Κατερίνα, η Βούλα, και τόσες άλλες βοήθησαν η κάθε μια
          με τον τρόπο και τις ιδέες τους στον υπέροχο αυτόν στολισμό.{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τα λουλούδια, που στόλισαν τον επιτάφιο, ήταν μαργαρίτες και
          χρυσάνθεμα, μπλεγμένα με αγγελική και πλαισιωμένα από γυψόφυλλο. Γύρω
          γύρω, ήταν τοποθετημένα φρέσκα τριαντάφυλλα κομμένα απ' τις αυλές των
          σπιτιών τα οποία μοσχομύρισαν το μικρό εκκλησάκι του Αγίου
          Παντελεήμονα. Βάσει του εθίμου, κατά την διάρκεια του στολισμού, εψάλη
          το τραγούδι της Παναγίας και άλλα εγκώμια από όλες τις κυρίες.
          Ενδιάμεσα του στολίσματος του επιταφίου ο Δημήτρης μοίρασε πρόσφορο,
          βουτηγμένο σε κόκκινο κρασί και έτσι ο στολισμός του επιταφίου στον
          Άγιο Παντελεήμονα, έληξε γύρω στις 2 τα ξημερώματα.
          <br /> Και του χρόνου με υγεία.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εξορμήσεις καθαριότητας
        </Typography>
        <img src={Image09} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image10} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με την ευκαιρία της <b>5ης Ιουνίου</b>, ημέρας αφιερωμένης στην
          προστασία του περιβάλλοντος, ο Σύλλογός μας πραγματοποίησε δύο
          εξορμήσεις καθαριότητας. Στην πρώτη που έγινε στα μέσα Μαΐου
          καθαρίστηκαν από σκουπίδια και αγριόχορτα οι κοινόχρηστοι χώροι της
          Σκάλας Λουτρών, η παιδική χαρά, ο χώρος αθλοπαιδιών, το γήπεδο
          μπάσκετ, το θερινό αμφιθέατρο, το λιμανάκι και ο αύλιος χώρος του
          «Μουσείου Προσφυγικής Μνήμης 1922».{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στην δεύτερη εξόρμηση που έγινε την <b>Κυριακή 8 Ιουνίου</b>{" "}
          καθαρίστηκαν οι παραλίες και η παραλιακή οδός από την Σκάλα Λουτρών
          έως την Κουντουρουδιά. Το μήνυμα που δίδουμε κάθε χρόνο με αυτές τις
          εξορμήσεις καθαριότητας, είναι ότι είμαστε παρόντες στην προσπάθεια
          που πρέπει να γίνεται από όλους για την προστασία του περιβάλλοντος,
          των ακτών και των δημόσιων χώρων που εν γένει που αποτελούν σημαντικό
          χώρο αναψυχής των πολιτών, πόλο έλξης επισκεπτών και πηγή οικονομικής
          ανάπτυξης του τόπου μας. <br />
          Ζητάμε από το Δήμο Μυτιλήνης και από το νέο Τοπικό Συμβούλιο Λουτρών,
          την αυτονόητη φροντίδα από την υποχρέωση που έχουν σε θέματα
          καθαριότητας του οικισμού μας.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Στήριξη του Συλλόγου μας, στον Σύλλογο «Φίλοι Περιβάλλοντος Ιερισσού»
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Αγαπητοί μας φίλοι, παρακολουθούμε ανελλιπώς τον αγώνα σας για την
          αποτροπή της καταστροφής του περιβάλλοντος της Χαλκιδικής από τα
          μεταλλεία εξόρυξης χρυσού και σας συγχαίρουμε για τον αγώνα σας και
          για την πρωτοβουλία σας να διοργανώσετε επιστημονική διημερίδα για την
          ενημέρωση των κατοίκων και των φορέων που αντιτίθενται σε αυτού του
          είδους τις δραστηριότητες.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Έχουμε λάβει την πρόσκληση και λυπούμαστε που λόγοι πέραν της θελήσεώς
          μας δεν μας επιτρέπουν να είμαστε παρόντες.
          <br />
          Ευχόμαστε καλή επιτυχία στην διημερίδα, και σας γνωρίζουμε ότι θα
          είμαστε δίπλα σας και θα στηρίζουμε τον αγώνα σας μέχρι τελικής
          δικαιώσεως.
          <br />
          Παρακαλώ μεταφέρετε τους χαιρετισμούς μας στους παρευρισκόμενους, από
          τον Σύλλογο «ΤΟ ΔΕΛΦΙΝΙ» της ακριτικής Σκάλας Λουτρών Λέσβου.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Στήριξη του Συλλόγου μας, στο Επιμελητήριο Περιβάλλοντος για τις
          ακτές.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Σας ενημερώνουμε ότι στην συνάντηση της 15-5-2014 στο Επιμελητήριο
          Περιβάλλοντος και Βιωσιμότητας όπου θα συζητηθεί το πολύ σοβαρό
          πρόβλημα που προκύπτει από το υπό κατάθεση νομοσχέδιο το σχετικό με
          την <b>αλλαγή του καθεστώτος προστασίας των ακτών της χώρας μας</b>, ο
          Σύλλογός μας είναι αλληλέγγυος στον αγώνα και προσυπογράφει τις όποιες
          αποφάσεις ληφθούν.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ελπίζουμε να είμαστε παρόντες σε μια από τις προσεχείς συναντήσεις στο
          Επιμελητήριο του οποίου είμαστε μέλη από ιδρύσεώς του.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αγορά air Contition στο Ιατρείο της Σκάλας Λουτρών.
        </Typography>
        <img src={Image11} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με απόφαση του Δ.Σ. Του Συλλόγου μας αγοράσθηκε ένα air condition για
          τις ανάγκες του <b>Αγροτικού Ιατρείου της Σκάλας Λουτρών</b>, ένα
          ιατρείο το οποίο λειτουργεί εξ ολοκλήρου με χρηματοδότηση του Συλλόγου
          μας.{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Υπενθυμίζουμε ότι πριν ενάμιση έτος όταν καθιερώθηκε η ηλεκτρονική
          συνταγογράφιση, ο Σύλλογός μας ήταν αυτός που κάλυψε πάλι την δαπάνη
          αγοράς του αντίστοιχου ηλεκτρονικού εξοπλισμού, για να μπορούν να
          εξυπηρετούνται οι κάτοικοι του οικισμού μας.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Επισκευή στο εκκλησάκι της Παναγιάς της Αψηλής
        </Typography>
        <img src={Image12} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με την αξιέπαινη πρωτοβουλία της κ. <b>Αγγέλας Ντίνια</b> και με την
          χρηματοδότηση που συγκεντρώθηκε από τους κατοίκους της Σκάλας Λουτρών,
          επισκευάσθηκε η σκεπή και έγινε ο ελαιοχρωματισμός τόσο εσωτερικά όσο
          και εξωτερικά στο εκκλησάκι της Παναγίας της Αψηλής.
          <br />Ο Σύλλογός μας συνέβαλε στις παραπάνω εργασίες με το ποσό των
          300 ευρώ.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Επισκευές στο κτίριο του Μουσείου Προσφυγικής Μνήμης 1922
        </Typography>
        <img src={Image13} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στην προσπάθειά του να συντηρεί όσο το δυνατόν καλλίτερα το κτίριο του
          Μουσείου, ο Σύλλογός μας προέβη σε εργασίες ελαιοχρωματισμού τόσο
          στους εσωτερικούς όσο και στους εξωτερικούς χώρους του Μουσείου.{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Επίσης προέβη και σε εργασίες συντήρησης της υγρομόνωσης της ταράτσας.
          Ακόμη λόγω του ότι αυτή την χρονιά είχαμε να εκθέσουμε σημαντικά
          κειμήλια που μας είχαν δωριθεί, απεφασίσθη και αγοράστηκαν 5
          καινούργιες βιτρίνες οι οποίες ήδη κοσμούν με τα εκθέματά τους τον
          προαναφερθέντα χώρο.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διαγωνισμός ζωγραφικής και έκθεσης
        </Typography>
        <img src={Image14} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image15} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε και φέτος με επιτυχία, ο ετήσιος διαγωνισμός
          ζωγραφικής και έκθεσης με τους μαθητές και τις μαθήτριες του δημοτικού
          σχολείου Λουτρών.
          <br />Η εκδήλωση πραγματοποιήθηκε με πρωτοβουλία του Συλλόγου μας και
          με την ευκαιρία της 5ης Ιουνίου 2014, ημέρας αφιερωμένης ως γνωστόν
          στην προστασία του Περιβάλλοντος.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Οι τέσσερις πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα:{" "}
          <b>«Τι βλέπω έξω από το παράθυρό μου»</b> και οι δύο μεγαλύτερες
          τάξεις διαγωνίσθηκαν στην έκθεση με θέμα:{" "}
          <b>«Τι πιστεύετε ότι θα γίνει αν λιώσουν οι πάγοι στους πόλους; »</b>.
          <br />
          <br />
          Η επιλογή της καλλίτερης ζωγραφικής και της καλλίτερης έκθεσης από
          κάθε τάξη έγινε από τους δασκάλους του σχολείου και τα παιδιά δούλεψαν
          με ενθουσιασμό και φαντασία τόσο στην ζωγραφική όσο και στην έκθεση.{" "}
          <br />
          Τα παιδιά που διακρίθηκαν βραβεύθηκαν σε ειδική εκδήλωση που
          πραγματοποιήθηκε στο ξενοδοχείο «MYTILANA» το Σάββατο 2 Αυγούστου 2014
          στις 9.30 το βράδυ.
          <br />
          <br />
          Οι μαθητές που διακρίθηκαν είναι οι παρακάτω.
          <br />
          Από την Α! Τάξη οι <b>Κέντσης Στράτος</b> και <b>Μερούκο Φεσιάνα</b>.
          <br />
          Από την Β! Τάξη οι <b>Τρουλλινού Κατερίνα</b> και <b>Κέντση Μαρία</b>.
          <br />
          Από την Γ! Τάξη οι <b>Αλεξίου Γιώργος</b> και <b>Αχλάδας Γιώργος</b>.
          <br />
          Από την Δ! Τάξη οι <b>Ευαγγέλου Χριστίνα</b> και{" "}
          <b>Κοζπή Σταυρούλα</b>.<br />
          Από την Ε! Τάξη οι <b>Λέλεκας Κωνσταντίνος</b> και{" "}
          <b>Δράκου Δήμητρα</b>.<br />
          Από την ΣΤ! Τάξη οι <b>Σφακιανού Γαρυφαλιά</b> και{" "}
          <b>Κοζπή Ταξιαρχούλα</b>.<br />
          <br />
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          «3ος Κολυμβητικός διάπλους Μικρασιατικής Μνήμης» (Κουντουρουδιά –
          Πέραμα)
        </Typography>
        <img src={Image16} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image17} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με αθρόα συμμετοχή κολυμβητών διεξήχθη ο 3ος Διάπλους Μικρασιατικής
          Μνήμης την Κυριακή 27-7-2014.
          <br />
          Οι 70 περίπου κολυμβητές που συμμετείχαν διήνυσαν την απόσταση των{" "}
          <b>1.100 μέτρων</b> που χωρίζει την Κουντουρουδιά από το Πέραμα.
          <br />
          Όλοι τους τερμάτισαν, χωρίς προβλήματα και ο χρόνος που έκαναν οι
          κολυμβητές παρόλο που ο διάπλους ήταν συμβολικός και όχι διαγωνιστικός
          κυμάνθηκε από 12 περίπου λεπτά ο πρώτος μέχρι 44 λεπτά ο τελευταίος.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Μετά την άφιξη των κολυμβητών στο Πέραμα ξεκίνησε η τέλεση του
          Μικρασιατικού εθίμου το <b>«Κοντάρι»</b>, το οποίο επαναλαμβάνετε κάθε
          χρόνο στις 27 Ιουλίου, ημέρα της γιορτής του Αγίου Παντελεήμονος,
          πολιούχου Περάματος. Στην ασφαλή διεξαγωγή του κολυμβητικού διάπλου
          συνέβαλαν, αποφασιστικά, τα δύο πλωτά σκάφη του Λιμενικού Σώματος τα
          οποία και επέβλεπαν συνεχώς τους κολυμβητές.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Τις ευχαριστίες μας οφείλουμε εκτός από το Λιμενικό Σώμα, στους
          ιατρούς κ.κ. Ευστράτιο Γιαννίκο και Κώστα Χατζηκωνσταντίνο που
          παρευρίσκονταν για παν ενδεχόμενο, καθώς επίσης και στον κ. Ευστράτιο
          Καραντώνη, ο οποίος διέθεσε τη βάρκα του για τη δωρεάν μεταφορά των
          αθλητών και των συνοδών τους από την Κουντουρουδιά στο Πέραμα και
          αντιστρόφως.
          <br />
          Τέλος, σε όλους τους συμμετέχοντες δόθηκαν αναμνηστικά διπλώματα για
          τη συμμετοχή τους και μοιράστηκαν αναμνηστικά καπελάκια.
          <br />
          <br />
          Διοργανωτές της εκδήλωσης ήταν: <br />Ο{" "}
          <b>«Παγγεραγωτικός» Πολιτιστικός Σύλλογος Γέρας</b>,<br />Ο{" "}
          <b>Σύλλογος Μικρασιατών Σκάλας Λουτρών «ΤΟ ΔΕΛΦΙΝΙ»</b>,<br />Ο{" "}
          <b>«Παγγεραγωτικός» Σύλλογος Μυτιλήνης</b> και
          <br />Η <b>Τοπική Κοινότητα Περάματος</b>.<br />
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Θερινή χοροεσπερίδα και βράβευση των αριστούχων μαθητών.
        </Typography>
        <img src={Image18} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image19} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με επιτυχία και αθρόα συμμετοχή πραγματοποιήθηκε η χοροεσπερίδα του
          Συλλόγου Μικρασιατών της Σκάλας Λουτρών <b>«ΤΟ ΔΕΛΦΙΝΙ»</b> το Σάββατο
          2 Αυγούστου 2014 στο χώρο του ξενοδοχείου <b>«MYTILANA VILLAGE»</b>.
          Με την συμμετοχή των μελών και των φίλων του Συλλόγου περάσαμε μια
          υπέροχη βραδιά δίπλα στη θάλασσα, με θέα τον κόλπο και τα απέναντι
          χωριά της Γέρας. Κατά την διάρκεια της εκδήλωσης έγινε μια σύντομη
          ενημέρωση από τον πρόεδρο κ. Δημήτρη Παπαχρυσό, σχετική με τις
          τρέχουσες δραστηριότητες του Συλλόγου και εν συνεχεία πραγματοποιήθηκε
          η καθιερωμένη βράβευση των αριστούχων μαθητών.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Βραβεύτηκαν οι παρακάτω:
          <b>Αχλάδα Μαρία</b> του Ευστρατίου από την Α! γυμνασίου
          <br />
          <b>Αχλάδας Παναγιώτης</b> του Ευστρατίου από την Β! γυμνασίου
          <br />
          <b>Ντινιακός Μαρίνος</b> του Στέλιου από την Β! γυμνασίου
          <br />
          <b>Νικολάρη Έρικα</b> του Γεωργίου από την Β! γυμνασίου
          <br />
          <b>Λίγκος Χαράλαμπος</b> του Αργύρη από την Γ! γυμνασίου
          <br />
          <b>Ταλιαντζής Ιωάννης</b> του Σταύρου από την Γ! γυμνασίου
          <br />
          <b>Ευαγγέλου Μαρία</b> του Ασημάκη από την Γ! γυμνασίου
          <br />
          <b>Δράκου Χαρίκλεια</b> του Δημητρίου από την Α! λυκείου
          <br />
          <b>Βερβέρη Μαίρη</b> του Μιχάλη από την Α! λυκείου
          <br />
          <b>Τασιοπούλου Παναγιώτα</b> του Γεωργίου από την Γ! λυκείου
          <br />
          <b>Παπαχρυσός Απόστολος</b> του Ιωάννη από την Γ! λυκείου
          <br />
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Επίσης δόθηκαν έπαινοι σε παιδιά του Δημοτικού Σχολείου Λουτρών γιατί
          διακρίθηκαν σε διαγωνισμό ζωγραφικής και έκθεσης με θέμα το περιβάλλον
          ο οποίος είχε πραγματοποιηθεί στις 5 του περασμένου Ιουνίου. Οι
          μαθητές που διακρίθηκαν είναι οι παρακάτω. Από την Α! Τάξη οι{" "}
          <b>Κέντσης Στράτος</b> και <b>Μερούκο Φεσιάνα</b>.<br />
          Από την Β! Τάξη οι <b>Τρουλλινού Κατερίνα</b> και <b>Κέντση Μαρία</b>.
          <br />
          Από την Γ! Τάξη οι <b>Αλεξίου Γιώργος</b> και <b>Αχλάδας Γιώργος</b>.
          <br />
          Από την Δ! Τάξη οι <b>Ευαγγέλου Χριστίνα</b> και{" "}
          <b>Κοζπή Σταυρούλα</b>.<br />
          Από την Ε! Τάξη οι <b>Λέλεκας Κωνσταντίνος</b> και{" "}
          <b>Δράκου Δήμητρα</b>.<br />
          Από την ΣΤ! Τάξη οι <b>Σφακιανού Γαρυφαλιά</b> και{" "}
          <b>Κοζπή Ταξιαρχούλα</b>.<br />
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Κατά την διάρκεια της εκδήλωσης πραγματοποιήθηκε η κλήρωση των δώρων
          της λαχειοφόρου αγοράς του Συλλόγου και κέρδισαν οι παρακάτω αριθμοί.
          <br />
          <br />
          1ος λαχνός το νούμερο 513 κερδίζει το TABLET P/C
          <br />
          2ος λαχνός το νούμερο 462 κερδίζει το ποδήλατο.
          <br />
          3ος λαχνός το νούμερο 537 κερδίζει το χειροποίητο κόσμημα.
          <br />
          4ος λαχνός το νούμερο 230 κερδίζει τον ανεμιστήρα.
          <br />
          5ος λαχνός το νούμερο 579 κερδίζει το ραδιόφωνο.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διήμερες εκδηλώσεις του Συλλόγου, 22 και 23 Αυγούστου 2014
        </Typography>
        <img src={Image20} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image21} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκαν με μεγάλη επιτυχία οι διήμερες εκδηλώσεις του
          Συλλόγου μας, την Παρασκευή 22 και το Σάββατο 23 Αυγούστου αφιερωμένες
          στην συμπλήρωση 100 ετών από τον πρώτο ξεριζωμό των Ελλήνων το 1914
          στα παράλια της Μικράς Ασίας. Αυτές περιελάμβαναν:
          <br />
          Την Παρασκευή 22-8-2014 στις 19.30, τον Εσπερινό και την περιφορά της
          εικόνας της Παναγίας μέχρι τον αύλιο χώρο του «Μουσείου Προσφυγικής
          Μνήμης 1922», την ομιλία με τίτλο,{" "}
          <b>
            “Μικρά Ασία. Τόπος και τρόπος μνήμης. Πτυχές του Α΄ Διωγμού (1914)”
          </b>
          , από τον Δρ. Θεολογίας και καθηγητή κ. Αθανάσιο Καλαμάτα, την
          παρουσίαση στον χώρο του Μουσείου, της μακέτας της πόλεως της Σμύρνης,
          όπως αυτή ήταν πριν την καταστροφή του <b>1922</b>, την έκθεση
          φωτογραφίας από το αρχείο του Felix Sartiaux αφιερωμένη στον πρώτο
          διωγμό στις Π. Φώκαιες της Μικράς Ασίας, καθώς και την δωρεάν διανομή
          του περιοδικού μας που ήταν αφιερωμένο στην συμπλήρωση <b>100</b> ετών
          από τον πρώτο διωγμό.{" "}
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στην συνέχεια το χορωδιακό τμήμα των γυναικών του Συλλόγου μας που
          συστήθηκε με ευθύνη της κ. Ευθαλίας Τουρλή, συνοδεία της παραδοσιακής
          ορχήστρας της «Παράλου», μας παρουσίασε τραγούδια και χορούς από τα
          παράλια της Μικράς Ασίας.
          <br />
          Στο τέλος της εκδήλωσης έγινε η διανομή του Μικρασιάτικου Καβουρμά ο
          οποίος ήταν προσφορά των γυναικών του Μικρασιάτικου οικισμού μας,
          καθώς και κρασιού, προσφορά του Συλλόγου μας.
          <br />
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την επόμενη ημέρα 23-8-2014 στις 08.00 το πρωί που ήταν τα 9μερα της
          Παναγίας ετελέσθη θεία λειτουργία με αρτοκλασία και επιμνημόσυνη δέηση
          υπέρ αναπαύσεως των ψυχών των Μικρασιατών προσφύγων στην Παναγιά την
          Αψηλή, με την παρουσία πολλών κατοίκων από την Σκάλα Λουτρών, τα
          Λουτρά και το Πέραμα της Γέρας.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Η μακέτα της πόλεως της Σμύρνης πριν το 1922
        </Typography>
        <img src={Image22} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ένα σπουδαίο, ανεκτίμητης αξίας έργο του δασκάλου και καλλιτέχνη κ.
          Σταύρου Βαλαχή, φιλοξενείται από τον περασμένο Αύγουστο στην αίθουσα
          του «Μουσείου Προσφυγικής Μνήμης 1922». Ένα έργο διαστάσεων περίπου 2
          επί 2 μέτρων στο οποίο απεικονίζεται η Σμύρνη με τους δρόμους της, τα
          υπέροχα κτίριά της και τα μνημεία της όπως αυτή ήταν πριν την
          καταστροφή της το 1922. Παραπλεύρως υπάρχουν διάφορες πληροφορίες
          σχετικές με αυτήν, τις συνοικίες της και τα μνημεία της.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Σημαντικό είναι και το υπόμνημα που υπάρχει και που δίνει την
          δυνατότητα στον καθένα που ενδιαφέρετε να αναζητήσει και να βρει ένα
          από τα 136 σημαντικά κτίρια και μνημεία της.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εκδηλώσεις μνήμης
        </Typography>
        <img src={Image23} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Κυριακή <b>14 Σεπτεμβρίου 2014</b> πραγματοποιήθηκαν στην πόλη της
          Μυτιλήνης οι εκδηλώσεις μνήμης για την γενοκτονία των Ελλήνων της
          Μικράς Ασίας στο πλαίσιο της 92ης επετείου από τη Μικρασιατική
          καταστροφή. Παρόντες στην εκδήλωση, ο αντιπεριφερειάρχης κ. Στρατής
          Κύτελης, ο δήμαρχος Λέσβου κ. Σπύρος Γαληνός, εκπρόσωποι του Στρατού,
          του Λιμενικού, της Αστυνομίας καθώς και των εκπροσώπων πολλών Συλλόγων
          κυρίως Μικρασιατών.
          <br />
          Μετά την δοξολογία στον Ιερό Ναό του Αγίου Νικολάου στην Επάνω Σκάλα,
          επακολούθησε επιμνημόσυνη δέηση και κατάθεση στεφάνων στον χώρο του
          αγάλματος της Μικρασιάτισσας Μάνας με την συμμετοχή της Στρατιωτικής
          Μουσικής της 98 ΑΔΤΕ.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η Μικρασιατική καταστροφή καταγράφεται στις ποιο θλιβερές σελίδες του
          Ελληνικού Έθνους, στιγματίστηκε από την απώλεια ανθρώπων και πατρίδων,
          δημιούργησε πρόσφυγες και σκόρπισε δυστυχία. Αυτοί οι πρόσφυγες
          πληγωμένοι από τη βαρβαρότητα και τη φρίκη ήρθαν στην Ελλάδα
          αγωνίστηκαν για την επιβίωσή τους και συνέβαλαν με τους αγώνες τους
          στην ανανέωση και στην πρόοδο της Ελληνικής κοινωνίας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το Δ.Σ. του Συλλόγου μας παραβρέθηκε σύσσωμο τόσο στην τέλεση της
          δοξολογίας στον Ιερό Ναό του Αγίου Νικολάου όσο και στην συνέχεια με
          κατάθεση στεφάνου στο άγαλμα της Μικρασιάτισσας μάνας μαζί με τους
          υπόλοιπους Συλλόγους και τις αρχές του τόπου.{" "}
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Το άγαλμα της Μικρασιάτισσας μάνας
        </Typography>
        <img src={Image24} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στις <b>14 Οκτωβρίου 1984</b> έγιναν τα αποκαλυπτήρια της γλυπτής
          σύνθεσης που τότε παράγγειλε ο Εξωραϊστικός και Εκπολιτιστικός
          Σύλλογος Μυτιλήνης «Η Επάνω Σκάλα». Με έξοδά του παράγγειλε στη γνωστή
          γλύπτρια κα Κατερίνα Χαλεπά – Κατσάνου, εγγονή του Γιαννούλη Χαλεπά,
          να φτιάξει από χαλκό, τη «Μικρασιάτισσα Μάνα». Μόνη δέσμευση ήταν η
          Μάννα να κοιτά, προς την Ανατολή, εκεί που είχε αφήσει, στη χαμένη
          πατρίδα, άταφους νεκρούς, τους άντρες και τα αγόρια πάνω από δεκαπέντε
          χρόνων, που τάχαν σφάξει οι Τούρκοι. Και να χει τρία παιδιά, το ένα
          μωρό της αγκαλιάς, τα άλλα, μικρά, γαντζωμένα στη φούστα της.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η παραγγελία δόθηκε τέλος Φεβρουαρίου και γεννήθηκε η νέα γυναίκα με
          συννεφιασμένο πρόσωπο, με μια δύναμη και μια αποφασιστικότητα – θα
          ζήσει, αυτή και τα τρία βλαστάρια της, θα χτίσει, θα ξαναγαπήσει τη
          νέα πατρίδα, αλλά με το βλέμμα, και την καρδιά, για πάντα στην
          Ανατολή. Η τελειωμένη σύνθεση – με την κα Χαλεπά να δουλεύει και 18
          ώρες την ημέρα, για να προλάβει – πήγε στο χυτήριο τον περασμένο
          Σεπτέμβρη, όπου χύθηκε σε χαλκό. Τον Ιούλιο του 1984 στήθηκε στη θέση
          του και την περασμένη εβδομάδα, σε ατμόσφαιρα απερίγραπτης συγκίνησης,
          έγιναν τα αποκαλυπτήριά του, στην Επάνω Σκάλα Μυτιλήνης, εκεί όπου,
          τον Σεπτέμβρη του 1922, είχαν ξεμπαρκάρει, πρόσφυγες, οι δικές τους
          μάνες με τα παιδιά – αυτά τα παιδιά που μεγάλωσαν, έκαναν στη νέα
          πατρίδα οικογένεια και που τώρα τιμούν τη «Μικρασιάτισσα Μάννα»,
          σύμβολο δύναμης, επιβίωσης, το μεγαλείο της φυλής και της γυναίκας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Αυτά έγραφε στο φύλο της 25 Οκτωβρίου 1984 η έγκριτη εφημερίδα «Η
          Καθημερινή» στη στήλη του «Τήλεφου» παρουσιάζοντας το άγαλμα της
          «Μικρασιάτισσας Μάνας».
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εκδήλωση μουσικού τμήματος
        </Typography>
        <img src={Image25} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image26} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με επιτυχία πραγματοποιήθηκε το Σάββατο <b>4-10-2014</b> το απόγευμα
          στην αυλή του Μουσείου Προσφυγικής Μνήμης, η ετήσια εκδήλωση του
          μουσικού τμήματος του Συλλόγου μας «ΤΟ ΔΕΛΦΙΝΙ».
          <br />
          Την εκδήλωση παρακολούθησαν με μεγάλο ενδιαφέρον οι γονείς των παιδιών
          και πολλά μέλη του Συλλόγου οι οποίοι είχαν τη δυνατότητα να
          παρατηρήσουν από κοντά την πρόοδο των παιδιών τους στη μουσική και να
          τα καμαρώσουν γι αυτό.
          <br />
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στο μουσικό τμήμα του Συλλόγου συμμετέχουν αυτή τη στιγμή 22 παιδιά
          ηλικίας από 6 έως 15 ετών και τα μαθήματα για όσους ενδεχομένως
          ενδιαφέρονται γίνονται στην αίθουσα του Συλλόγου στη Σκάλα Λουτρών
          κάθε Σάββατο.
          <br />
          Στην εκδήλωση έπαιξαν κιθάρα και τραγούδησαν οι παρακάτω μαθητές και
          μαθήτριες.
          <br />
          <br />
          Αλεξίου Γιώργος, Αλεξίου Μαρία, Αραμπατζής Μίλτος, Αραμπατζή
          Παναγιώτα, Βουκαλέλλης Νίκος, Γκύζα Γεωργία, Γκύζα Κωνσταντίνα, Δράκου
          Δήμητρα, Κανέλος Άγγελος, Κέντζης Μαρίνος, Κουκώνης Ιωάννης, Κουκώνης
          Ραφαήλ, Λέλεκας Κωνσταντίνος, Λέλεκας Φίλιππος, Λύρας Δημήτρης,
          Ντηνιακός Μαρίνος, Παλαμουτσή Αναστασία και Σπανός Χαράλαμπος.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εορτή Αγίου Νικολάου
        </Typography>
        <img src={Image26} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <img src={Image27} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Με βροχερό καιρό αλλά με την συμμετοχή των μελών του Συλλόγου και
          πολλών πιστών από την Σκάλα Λουτρών, τα Λουτρά, την Κουντουρουδιά και
          τη Μυτιλήνη, πραγματοποιήθηκαν με λαμπρότητα, οι εκδηλώσεις για την
          εορτή του Αγίου Νικολάου, προστάτη των ναυτικών και των αλιέων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Συγκεκριμένα, με την παρουσία του εφημέριου Αγίου Γεωργίου Λουτρών,
          των ιεροψαλτών Γεωργίου Ψύρρα και Σωτήρη Μαμουνή και στρατιωτικού
          αγήματος από την μονάδα καταδρομέων του Περάματος, ετελέσθη στο
          παρεκκλήσιο του Αγίου Νικολάου της Σκάλας Λουτρών, η Θεία Λειτουργία
          και στη συνέχεια έγινε η λιτάνευση της εικόνας (του Αγίου Νικολάου,
          φερμένη το 1922 από τον Τσεσμέ της Μ. Ασίας), με κατεύθυνση την νέα
          προβλήτα του λιμανιού της Σκάλας Λουτρών. Μετά την ευλογία των άρτων ο
          ιερέας και το στρατιωτικό άγημα επιβιβάσθηκαν στη βάρκα του κ.
          Ευστράτιου Καραντώνη από την Κουντουρουδιά και κρατώντας την εικόνα
          του Αγίου την περιέφεραν, ρίχνοντας το στεφάνι που την πλαισίωνε στην
          θάλασσα και ευχόμενοι ήρεμα ταξίδια για τους ναυτικούς και τους αλιείς
          μας.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Δωρεά βιβλίων στο Σύλλογό μας
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά το <b>ίδρυμα Παπαδημητρίου</b> και ιδιαιτέρως τον
          πρόεδρό του κ. <b>Νικόλαο Παπαδημητρίου</b> για τα πολλά (34 κιβώτια)
          και ενδιαφέροντα βιβλία που πρόσφεραν στην βιβλιοθήκη του Συλλόγου
          μας. Επίσης βιβλία μας πρόσφεραν η Ένωση Σμυρναίων, το Αναγνωστήριο
          Αγιάσσου, οι κ.κ. Μαντός Δημήτριος, Παυλάκης Μάριος, Θωμαίδης
          Ελευθέριος, Βαλαχής Σταύρος και πολλοί άλλοι.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τους ευχαριστούμε θερμά όλους και ανακοινώνουμε ότι όλα αυτά είναι ήδη
          διαθέσιμα για δανεισμό από το κτίριο της βιβλιοθήκης του Συλλόγου μας
          στη Σκάλα Λουτρών, η οποία αυτή την στιγμή διαθέτει πλέον των 6.500
          τίτλων βιβλίων διαφόρων κατηγοριών τα οποία είναι καταχωρημένα σε
          ειδικό πρόγραμμα ηλεκτρονικού υπολογιστή για την εύκολη αναζήτησή
          τους.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ευχαριστήρια
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους παρακάτω.
          <br />
          <br />
          *Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες
          συντήρησης των κτιρίων του Συλλόγου καθώς και όσους προσφέρουν
          εθελοντικώς την εργασία τους.
          <br />
          *Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο
          μας.
          <br />
          *Ιδιαιτέρως ευχαριστούμε το συγχωριανό μας Δημήτρη Γρηγορέλλη που από
          ιδρύσεως του Συλλόγου, μας έχει παραχωρήσει αφιλοκερδώς το πατρικό του
          σπίτι στη Σκάλα Λουτρών για την στέγαση δραστηριοτήτων του Συλλόγου
          μας.
          <br />
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Μουσικό τμήμα
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Λειτουργεί κάθε{" "}
          <b>Σάββατο από τις 1 το μεσημέρι μέχρι τις 8 το βράδυ</b> και αυτή την
          περίοδο φοιτούν 22 παιδιά σχολικής ηλικίας τα οποία μαθαίνουν κιθάρα.
          Τα μαθήματα εκτός από πρακτικά είναι και θεωρητικά έτσι ώστε τα παιδιά
          να αποκτούν γερές βάσεις και να συνεχίσουν μελλοντικά τις σπουδές τους
          στην μουσική εφ όσον το επιθυμήσουν. Η μηνιαία συνδρομή είναι 20 ευρώ
          και όσοι ενδιαφέρονται για τα παιδιά τους μπορούν να έρθουν σε
          επικοινωνία με τον πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό στο τηλ.
          69746.40419 για περισσότερες πληροφορίες. Δάσκαλος είναι ο κ.{" "}
          <b>Μολυβιάτης Γρηγόρης</b> ο οποίος επί πολλά χρόνια έχει προσφέρει
          εξαιρετικό έργο.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Νέα τμήματα
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Σύλλογός μας μπορεί να ιδρύσει νέα τμήματα παραδοσιακών μουσικών
          οργάνων όπως π.χ. βιολί, λαούτο κτλ εφ όσον εκδηλωθεί σχετικό
          ενδιαφέρον από τα μέλη μας. Διευκρινίζουμε ότι για να λειτουργήσει ένα
          νέο τμήμα θα πρέπει να ενδιαφερθούν κατ ελάχιστο τρία άτομα.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τμήματα παραδοσιακών τραγουδιών και χορών
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Αυτά τα τμήματα λειτουργούν περιστασιακά και πλαισιώνουν διάφορες
          εκδηλώσεις του Συλλόγου μας που έχουν σχέση με τις αλησμόνητες
          πατρίδες της Μ. Ασίας. Τα μαθήματα είναι δωρεάν και όσοι ενδιαφέρονται
          μικροί και μεγάλοι μπορούν να έλθουν σε συνεννόηση με την κ.{" "}
          <b>Ευθαλία Τουρλή</b> στο τηλ. 22510 91277.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τμήμα βιβλιοθήκης
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με γοργό ρυθμό εμπλουτίζετε η βιβλιοθήκη του Συλλόγου μας και υπάρχει
          αρκετό ενδιαφέρον από τα μέλη μας που την επισκέπτονται για να
          δανειστούν διάφορα βιβλία. Κατά το τρέχον έτος προστέθηκαν πολλά νέα
          βιβλία, όλα δωρεές των μελών και των φίλων του Συλλόγου μας. Όλα τα
          βιβλία είναι καταχωρημένα σε ηλεκτρονικό υπολογιστή για την εύκολη
          διαχείριση τους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει
          τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση
          είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα
          προβλήματα που αποτελούν τη ζωή του σύγχρονου ανθρώπου και τοποθετεί
          τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη
          διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και
          φανατισμούς.
        </Typography>
      </Box>
      <ScrollToTopButton />
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Οικονομικές ενισχύσεις
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.
          <br />
          <b>Δεσπότου Έλλη</b> 250 ευρώ
          <br />
          <b>Κουτσοδόντης Κωνσταντίνος</b> 100 ευρώ
          <br />
          <b>Γρημάνη Γεωργία</b> 50 ευρώ
          <br />
          <b>Λεωνιδοπούλου Μυρσίνη</b> 100 ευρώ
          <br />
          <b>Παπαναστασίου Μιχάλης</b> 50 ευρώ
          <br />
          <b>Κεφαλίδης Δημήτρης</b> 20 ευρώ
          <br />
          <b>Πετράκη Μαρίνα</b> 30 ευρώ
          <br />
          <b>Αγγελάκη Αθηνά</b> 10 ευρώ
          <br />
          <b>Γιαλελή Κορίνα</b> 10 ευρώ
          <br />
          <b>Ανώνυμος</b> 20 ευρώ
          <br />
          <b>Halford Haskell</b> 20
          <br />
          <b>Sbren Merald</b> 12
          <br />
          <b>David Duntaornc</b> 5<br />
          <b>Dartinot Fra</b> 6<br />
          <b>Steen Bakker</b> 10
          <br />
          <br />
          <b>Κινικλή Τούλα</b> 20 ευρώ στη μνήμη του πατέρα της Γεωργίου.
          <br />
          <b>Μολυβιάτης Γρηγόρης</b> 50 ευρώ στη μνήμη του πατέρα του Δημητρίου.
          <br />
          <b>Περγαμηνού Γαρουφαλιά</b> 20 ευρώ στη μνήμη του συζύγου της
          Ευστρατίου.
          <br />
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του
          να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός για
          αναβάθμιση του οικισμού μας , έτσι ώστε να μπορέσουμε να
          ανταποκριθούμε στο βαρύ φορτίο των υποχρεώσεων που έχουμε αναλάβει.
          <br />
          <br />
          Για καταθέσεις συνδρομών και ενισχύσεων στο Σύλλογο{" "}
          <b>
            <u>
              Τράπεζα Πειραιώς Αριθμός Λογαριασμού 6355-010482-750 IBAN GR94
              0171 3550 0063 5501 0482 750
            </u>
          </b>
        </Typography>
      </Box>
      <ScrollToTopButton /> 
    </>
  );
}

export default D11_drastiriotites_2014;
