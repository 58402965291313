import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/29SinedrioPandoiko.jpg";

function F16_oikologika_16() {

    const theme = useTheme();


  return (
    <>
    
    
<IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="29o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
	
      <Typography variant="h1" sx={theme.typography.typography1}>9-11 Νοεμβρίου 2018, Κατερίνη</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>Θέμα: «Κλιματική Αλλαγή. Αστικό Περιβάλλον»</Typography>
		
	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
    <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε στη Ναύπακτο από 22 έως 24 Νοεμβρίου 2019 το 29ο Συνέδριο του Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων στο οποίο ο Σύλλογός μας εκπροσωπήθηκε από την αντιπρόεδρο κ. Νάσια Δαφιώτη.<br/>
Το πρώτο μέρος του Συνεδρίου είχε ως θέμα το Θαλάσσιο Χωροταξικό και τη Διαχείριση των Υγρών Αποβλήτων με αποδέκτη τη θάλασσα.
Ειδικοί επιστήμονες, αναφέρθηκαν στην Ευρωπαϊκή και Ελληνική νομοθεσία που διέπει τη θαλάσσια χωροταξία, τις προκλήσεις από τη μη εφαρμογή της και τα προβλήματα που δημιουργούνται εξ αυτού.<br/>
Η συζήτηση στα πλαίσια του Συνεδρίου ήταν πλούσια και ζωηρή, με τους επιστήμονες να δίνουν απαντήσεις σε ερωτήματα που προέκυψαν.
Στο δεύτερο μέρος του 29ου Συνεδρίου και μετά τους απολογισμούς της προηγούμενης διοίκησης του ΠΑΝΔΟΙΚΟ, πραγματοποιήθηκαν αρχαιρεσίες για την εκλογή νέας Επταμελούς Γραμματείας και εκλέχτηκαν οι εξής:<br/>
Νίκος Ασλάνογλου (Βέροια), Κώστας Βολιώτης (Βόλος), Θανάσης Θεοδωρόπουλος
(Πάτρα) Γιώργος Καλλιαμπέτσος (Αθήνα), Δημήτρης Μίχαλος (Ρόδος), Γιώργος Παλαμάρης (Κορινθία) και Μαίρη Χουλιέρη (Χαλκίδα).
</Typography>
	<br/><br/>


	
    <Typography variant="h1" sx={theme.typography.typography1}>Απόφαση 29ου Πανελλήνιου Συνεδρίου ΠΑΝΔΟΙΚΟ</Typography>
   
    <Typography variant="h3" sx={theme.typography.typography2}>  
Το 29ο  Συνέδριο του Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ) που διοργανώθηκε στη Ναύπακτο από τις 22 έως τις 24 Νοεμβρίου 2019, με τη συνδιοργάνωση της ΚΕΔΕ, της Περιφέρειας Στερεάς Ελλάδας, του ΣΠΟΑΚ «ΑΡΙΩΝΑ» και του Δήμου Ναυπακτίας, είχε ως κεντρικό θέμα:
«Θαλάσσιο Χωροταξικό. Επεξεργασία Υγρών Αποβλήτων. Θαλάσσιοι Αποδέκτες».<br/><br/>
Στο Συνέδριο παρουσιάστηκαν επιστημονικές εισηγήσεις, κατατέθηκαν απόψεις φορέων της επιστημονικής κοινότητας, φορέων της αυτοδιοίκησης και του οικολογικού κινήματος, ενώ παράλληλα αναπτύχθηκε πλούσιος διάλογος, μέσα από τον οποίο προέκυψαν σημαντικά συμπεράσματα και αποφάσεις που επικεντρώνονται στα εξής.<br/><br/>
*Ο θαλάσσιος χωροταξικός σχεδιασμός (ΘΧΣ) του οποίου το πλαίσιο θεσπίζεται με την οδηγία 2014/89/ΕΕ εντάσσεται στην ολοκληρωμένη θαλάσσια πολιτική της ΕΕ, που έχει ως στόχο να προαγάγει τη συντονισμένη και συνεπή λήψη αποφάσεων, με σκοπό τη βιώσιμη ανάπτυξη και την κοινωνική συνοχή των κρατών-μελών, ιδίως σε οτιδήποτε αφορά τις παράκτιες και νησιωτικές περιοχές.<br/><br/>
*Ο ΘΧΣ οργανώνει τις ανθρώπινες δραστηριότητες στις θαλάσσιες και παράκτιες περιοχές για να επιτευχθεί η σύνθεση οικολογικών, περιβαλλοντικών, οικονομικών, κοινωνικών και πολιτιστικών παραμέτρων με στόχο την προώθηση της αειφορίας. Στο πλαίσιο αυτό επιδιώκεται η αρμονική συνύπαρξη όλων των σχετικών δραστηριοτήτων και χρήσεων όπως είναι οι μεταφορές, η ναυτιλία, ο ενεργειακός<br/><br/>
τομέας, η εξόρυξη πρώτων υλών, ορυκτών και αδρανών υλικών, η αλιεία, η υδατοκαλλιέργεια και ο τουρισμός, καθώς και η διατήρηση, προστασία και βελτίωση του φυσικού και πολιτιστικού περιβάλλοντος. Οι ακτές έχουν ιδιαίτερη σημασία από την πλευρά των φυσικών οικοσυστημάτων και πόρων για την ανάπτυξη ανθρώπινων δραστηριοτήτων λόγω των ιδιαίτερων χαρακτηριστικών και πλεονεκτημάτων τους. Η
Ευρωπαϊκή οδηγία ενσωματώθηκε στην ελληνική νομοθεσία μετά από τέσσερα χρόνια με τον νόμο 4546/2018 (ΦΕΚ 101/Α/12-6-2018). Παραμένει όμως ανοικτό το ζήτημα της αλληλεπίδρασης των περιφερειακών και των ειδικών χωροταξικών, σε σχέση με την εφαρμογή του Θαλάσσιου Χωροταξικού Σχεδιασμού.
*Για το βαθμό ρύπανσης του θαλάσσιου χώρου διαπιστώνεται ότι σε ένα δείγμα νερού ή υγρών αποβλήτων μπορούν να πραγματοποιηθούν 4 είδη αναλύσεων: Φυσικοχημικές αναλύσεις, Χημικές αναλύσεις, Μικροβιολογικές αναλύσεις και Οικοτοξικολογικές αναλύσεις. Η διαχείριση και επεξεργασία των αστικών λυμάτων γίνεται με την Οδηγία 91/271 της ΕΕ και έχει ενσωματωθεί στο περιβαλλοντικό δίκαιο της χώρας μας. Προβλέπει την κατασκευή και λειτουργία αποχετευτικών
δικτύων και εγκαταστάσεων επεξεργασίας υγρών αποβλήτων σε πόλεις και οικισμούς, ανάλογα με τον πληθυσμό τους και τον τελικό αποδέκτη. Έμφαση
δίνεται σε θέματα που σχετίζονται με την επαναχρησιμοποίηση των εκροών, τη διαχείριση της ιλύος, αλλά και την ενεργειακή αξιοποίησή της. Ενδείκνυται η ανάπτυξη ενός ειδικού λογισμικού πρόβλεψης και έγκαιρης ειδοποίησης για κινδύνους ρύπανσης του εδάφους με βαρέα μέταλλα, βέλτιστης δόσης θρεπτικών συστατικών και σωστής εφαρμογής λιπασμάτων, ανάλογα με την καλλιέργεια.<br/><br/>
*Η παρουσία των μικροπλαστικών απορριμμάτων (διαμέτρου 5mm) στους βιολογικούς καθαρισμούς και τη θάλασσα αποτελεί μια σύγχρονη σχετικά ανακάλυψη, με απροσδιόριστους ακόμα τους κινδύνους για τα έμβια όντα. Οι Εγκαταστάσεις Επεξεργασίας Λυμάτων (ΕΕΛ) απομακρύνουν μεν ένα μεγάλο ποσοστό μικροπλαστικών σωματιδίων, όμως επιτρέπουν τη διαφυγή μεγάλων
ποσοτήτων μικροπλαστικών στους υδάτινους αποδέκτες. Απαραίτητη επομένως είναι
η αποτροπή της απελευθέρωσης των μικροπλαστικών στα λύματα.<br/><br/>
* Η εφαρμογή φυσικών συστημάτων για την επεξεργασία των υγρών αποβλήτων είναι κατάλληλη για μικρά χωριά και οικισμούς. Απαραίτητος είναι ο σωστός σχεδιασμός τους, οι κατάλληλες κλιματολογικές συνθήκες και η διαθεσιμότητα φθηνής γης. Σχετικά χρησιμοποιούνται διάφορα φυτά που είτε έχουν το ριζικό τους σύστημα στο έδαφος-πυθμένα του υγροβιότοπου ή επιπλέουν στηριζόμενα σε ένα δίκτυο υφασμένων ινών.<br/><br/>
*Τελικά διαπιστώνεται ότι οι σημερινές, θεσμικού χαρακτήρα ελλείψεις, η κάλυψη του συνόλου της χώρας με υποδομές διαχείρισης και η ανάπτυξη μηχανισμών ελέγχου και συμμόρφωσης, απαιτεί συνεχή διάλογο, διαρκή και σταθερή συνεργασία όλων των συναρμόδιων φορέων και ευρεία κοινωνική αποδοχή.


</Typography>
</Box>		

<ScrollToTopButton />


    </>
  )
}

export default F16_oikologika_16