import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";


import Image01 from '../assets/images/oikologika/17sinedriopandoiko_01.jpg'
import Image02 from '../assets/images/oikologika/17sinedriopandoiko_02.jpg'

function F3_oikologika_03() {

    const theme = useTheme();

  return (
    <>

    
<IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="17ο συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>
    
    
      <Box sx={{ ...commonBoxStyles }}>
      <Typography variant="h1" sx={theme.typography.typography1}>&nbsp;</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>ΜΥΤΙΛΗΝΗ 7, 8 και 9 Οκτωβρίου 2005 </Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>ΘΕΜΑ: Ανανεώσιμες Πηγές Ενέργειας.</Typography>
      
		
		<Typography variant="h3" sx={theme.typography.typography2}>Με μεγάλη επιτυχία πραγματοποιήθηκε από 7 έως 9 Οκτωβρίου 2005 στη Μυτιλήνη το 17ο Συνέδριο του <a href="http://www.pandoiko.gr">Πανελληνίου Δικτύου Οικολογικών 
		Οργανώσεων (ΠΑΝΔΟΙΚΟ)</a>, με συνδιοργανωτές την ΚΕΔΚΕ και τη Νομαρχιακή Αυτοδιοίκηση Λέσβου και οργανωτή τοπικά το σύλλογό μας το ΔΕΛΦΙΝΙ. </Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Στην κατάμεστη αίθουσα του Δημοτικού Θεάτρου Μυτιλήνης διακεκριμένοι ομιλητές ανέπτυξαν πολύ ενδιαφέρουσες εισηγήσεις πάνω στο θέμα του Συνεδρίου, 
		που ήταν οι: <b>«Ανανεώσιμες Πηγές Ενέργειας».</b> Παραβρέθηκαν και απηύθυναν χαιρετισμό στο Συνέδριο ο Υπουργός Αιγαίου κ. Αρ.  Παυλίδης, ο Υφυπουργός Ανάπτυξης
		κ. Γ. Σαλαγκούδης, ο Γ. Γ. Περιφέρειας Βορ. Αιγαίου κ. Σέργιος Τσίφτης, οι βουλευτές Λέσβου κ. Ιωαν. Γιαννέλης και Χριστιάνα Καλογήρου, ο Πρόεδρος της ΚΕΔΚΕ
		κ. Πάρις Κουκουλόπουλος, ο Νομάρχης Λέσβου κ. Παύλος Βογιατζής, ο Πρόεδρος της ΤΕΔΚ Λέσβου κ. Στέλιος Παυλής, ο Δήμαρχος Μυτιλήνης κ. Αριστ. Χατζηκομνηνός,
		ο Πρόεδρος του Επιμελητηρίου Λέσβου κ. Θρασ. Καλογρίδης και ο Πρόεδρος του ΤΕΕ Λέσβου κ. Δημ. Μάντζαρης.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Κατά γενική ομολογία εξαιρετική ήταν η οργάνωση του Συνεδρίου. Η καλή συνεργασία μεταξύ της επταμελούς Γραμματείας του ΠΑΝΔΟΙΚΟ και του τοπικού μας
		<b>συλλόγου «ΤΟ ΔΕΛΦΙΝΙ»</b> είχε σαν αποτέλεσμα να πραγματοποιηθεί ένα Συνέδριο από τα καλλίτερα που έχει οργανώσει ποτέ το Δίκτυο.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Το θεματικό μέρος του Συνεδρίου ήταν υψηλού επιπέδου με πολύ καλές εισηγήσεις και έντονο προβληματισμό. Οι εισηγητές προέρχονταν τόσο από την κεντρική
		πολιτική εξουσία όσο και από τον χώρο της Τοπικής αυτοδιοίκησης αλλά και από εκπροσώπους μη κυβερνητικών οργανώσεων και από ειδικούς επιστήμονες.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Πολύ σημαντική ήταν η συμμετοχή της τοπικής κοινωνίας στις εργασίες του Συνεδρίου με εισηγήσεις από φορείς του νησιού αλλά και με την παρακολούθησή του
		από τους πολίτες της τοπικής μας κοινωνίας. Σ αυτό βοήθησαν πολύ και οι προσυνεδριακές εκδηλώσεις που πραγματοποιήθηκαν στους περισσότερους Δήμους του
		νησιού μας και περιελάμβαναν προβολή ειδικού ντοκιμαντέρ για τις ΑΠΕ το οποίο μας είχε προμηθεύσει το ΚΑΠΕ (Κέντρο Ανανεώσιμων Πηγών Ενέργειας). 
		Στις εκδηλώσεις αυτές σημαντική ήταν και η παρουσία μαθητών των Γυμνασίων και των Λυκείων του κάθε Δήμου αφού υπήρχε προσυνενόηση με τους καθηγητές για την
		συμμετοχή και την εν γένει ευαισθητοποίηση των παιδιών.</Typography>
		
		<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<Typography variant="h3" sx={theme.typography.typography2}>Στο περιθώριο του Συνεδρίου σημαντική ήταν και η εκδήλωση αφιέρωμα στον Νίκο Ξυλούρη που έγινε το Σάββατο το βράδυ στο Δημοτικό θέατρο της πόλης μας. 
		Την επιμέλεια του οπτικοακουστικού αφιερώματος που το παρακολούθησε πλήθος κόσμου, επιμελήθηκε ο παραγωγός [και μέλος της επταμελούς Γραμματείας του 
		<a href="http://www.pandoiko.gr">ΠΑΝΔΟΙΚΟ</a>
		ο Δημήτρης Μίχαλος από την Ρόδο. Εξαιρετική επιτυχία είχε και το γεύμα γνωριμίας που παρατέθηκε στο ξενοδοχείο ΖΑΙΡΑ στη Σκάλα Λουτρών αφού το γλέντι που 
		επακολούθησε μας κράτησε εκεί μέχρι τις πρωινές ώρες. Η έκπληξη της βραδιάς ήταν το νέο μέλος της επταμελούς Γραμματείας ο Κίμων ο Καράτζος ο οποίος μας 
		γοήτευσε με τις ικανότητές του στο τραγούδι και την κιθάρα και ρωτώντας μάθαμε ότι στο παρελθόν συμμετείχε και ήταν από τα ιδρυτικά μέλη του πολύ γνωστού 
		συγκροτήματος των OLYMPIANS.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Στο πρόγραμμα του Συνεδρίου περιλαμβανόταν και η ξενάγηση των Συνέδρων σε διάφορα αξιόλογα μέρη του νησιού μας. Έτσι οι σύνεδροι είχαν την ευκαιρία 
		να επισκεφθούν με πούλμαν το αρχαιολογικό μουσείο της πόλης μας ,τα μουσεία Θεοφίλου και Τεριάντ τον Άγιο Ραφαήλ και τον Ταξιάρχη Μανταμάδου, την Ιερά 
		Μονή Λειμώνος, την Αγιάσσο, το απολιθωμένο δάσος της Λέσβου και το μουσείο φυσικής ιστορίας στο Σίγρι, </Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Σε σχετικά καλή ατμόσφαιρα κύλησαν και οι εργασίες που αφορούσαν το εσωτερικό συνέδριο του <a href="http://www.pandoiko.gr">ΠΑΝΔΟΙΚΟ</a>
		με τους απολογισμούς να εγκρίνονται ομόφωνα και 
		η ψηφοφορία να αναδεικνύει την νέα Επταμελή Γραμματεία, την οποία αποτελούν οι εξής:Ζήσης Γιάννης από την Λαμία , Φαναριώτης Δημήτρης από την Κέρκυρα, 
		Ασλάνογλου Νίκος από την Βέροια, Βολιώτης Κώστας από τον Βόλο, Καράτζος Κίμων από την Λάρισα, Μίχαλος Δημήτρης από την Ρόδο  και Παπαχρυσός Δημήτρης 
		από την Μυτιλήνη.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η απόφαση που πάρθηκε σχετικά με το θέμα και τον τόπο του επόμενου Συνεδρίου αυτό θα πραγματοποιηθεί στην Βέροια με θέμα "Κοινωνική περιβαλλοντική 
		ευθύνη και απασχόληση"</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Με τη λήξη των εργασιών του Συνεδρίου εκδόθηκε ομόφωνα η επισυναπτόμενη απόφαση για τις Ανανεώσιμες Πηγές Ενέργειας.</Typography>
		
		<h4>ΑΠΟΦΑΣΗ ΤΟΥ 17ου ΣΥΝΕΔΡΙΟΥ ΤΟΥ ΠΑΝΔΟΙΚΟ ΓΙΑ ΤΙΣ ΑΝΑΝΕΩΣΙΜΕΣ ΠΗΓΕΣ ΕΝΕΡΓΕΙΑΣ</h4>
		<img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<Typography variant="h3" sx={theme.typography.typography2}><b>Ένα από τα μεγαλύτερα προβλήματα που  αντιμετωπίζει ο σύγχρονος κόσμος, είναι ασφαλώς οι σοβαρές  επιπτώσεις σε πλανητικό επίπεδο από το υφιστάμενο 
		καθεστώς διαχείρισης της ενέργειας.</b></Typography>
		<Typography variant="h3" sx={theme.typography.typography2}> Η συνεχώς αυξανόμενη κατανάλωση, με τα  ορυκτά  καύσιμα να κατέχουν την μερίδα του λέοντος, σε ότι αφορά  την παραγωγή της ενέργειας,  έχει οδηγήσει 
		σε δραματική αύξηση τις εκπομπές του άνθρακα με αλυσιδωτές και ορατές πλέον επιδράσεις στο φαινόμενο  του θερμοκηπίου και τις κλιματικές αλλαγές.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Από την άλλη μεριά, η σημερινή  οικονομία του πετρελαίου με τα γιγάντια μονοπώλια να ελέγχουν  την  παγκόσμια παραγωγή, προκαλεί εξαρτήσεις, εντάσεις
		και πολέμους, καθιστώντας αδύναμες τις τοπικές κοινωνίες να διαχειριστούν και να σχεδιάσουν ένα βιώσιμο μέλλον.<br/>
		Στον αντίποδα αυτής της  αδιέξοδης πορείας,   και σε συνδυασμό με την εξάντληση των απαραίτητων φυσικών πόρων στο εγγύς μέλλον, βρίσκονται οι πολιτικές 
		που στοχεύουν, τόσο στην εξοικονόμηση ενέργειας, όσο και στη βαθμιαία  αντικατάσταση των συμβατικών πηγών από τις Ανανεώσιμες Πηγές Ενέργειας ( ΑΠΕ),  
		πολιτικές δηλαδή που  προωθούν την βιώσιμη ανάπτυξη.<br/>
		Και ενώ στις υπόλοιπες ευρωπαϊκές χώρες γίνονται ήδη σοβαρά   βήματα στην κατεύθυνση αυτή,  η Ελλάδα καθυστερεί σημαντικά, τόσο στον τομέα της  
		εξοικονόμησης  ενέργειας όσο και στον τομέα της ανάπτυξης των ΑΠΕ.<br/>
		Το κύριο χαρακτηριστικό του ελληνικού ενεργειακού συστήματος είναι η εξαιρετικά μεγάλη ενεργειακή ένταση, δηλ. η κατανάλωση ενέργειας ανά μονάδα προϊόντος.
		Το γεγονός αυτό, επιβαρύνει σημαντικά την ανταγωνιστικότητα της οικονομίας και το περιβάλλον.<br/>
		Σε μια χώρα με πλούσιο αιολικό δυναμικό, υψηλή ηλιοφάνεια, πολλά διαθέσιμα  γεωθερμικά πεδία και σημαντικούς υδάτινους πόρους, είναι απαράδεκτο να 
		κατέχουμε μια από  τις τελευταίες θέσεις σε επίπεδο Ευρωπαϊκής Ένωσης σε ότι αφορά την  αξιοποίησή τους. <br/>
		Ενδεικτικά  αναφέρουμε ότι, το ποσοστό σήμερα  της συμμετοχής των ΑΠΕ στην ηλεκτροπαραγωγή της χώρας είναι γύρω στο 10% (μαζί με τα υδροηλεκτρικά), όταν 
		η αντίστοιχη οδηγία της  Ε.Ε. θέτει ως στόχο το 20% μέχρι το 2010.<br/>
		Οι ευθύνες για αυτή την απαράδεκτη κατάσταση είναι διαχρονικές και ανήκουν κυρίως στην Πολιτεία.<br/>
		<u>Η ανυπαρξία μιας ολοκληρωμένης ενεργειακής στρατηγικής που θα δίνει έμφαση στις πολιτικές εξοικνόμησης ενέργειας και ανάπτυξης των ΑΠΕ και 
		η απίστευτη γραφειοκρατία σε ότι αφορά την αδειοδότησή τους σε συνδυασμό με την ελλιπή ενημέρωση της κοινής γνώμης,  είναι οι κύριες αιτίες για τη 
		σημερινή εικόνα, που παρά τις ηχηρές διακηρύξεις και ευχολόγια παραμένει αρνητική και απογοητευτική.</u><br/>
		<Typography variant="h3" sx={theme.typography.typography2}><b>Το 17ο συνέδριο του ΠΑΝΔΟΙΚΟ που έγινε σε συνδιοργάνωση με την ΚΕΔΚΕ , και τη Ν.Α. Λέσβου, εκτιμά ότι ΑΠΑΙΤΕΙΤΑΙ ΕΔΩ ΚΑΙ ΤΩΡΑ ΑΛΛΑΓΗ  ΠΛΕΥΣΗΣ 
		στην κατεύθυνση μιας βιώσιμης στρατηγικής στον τομέα  παραγωγής και κατανάλωσης της ενέργειας.</b><br/>
		Σε ότι αφορά  <b>την εξοικονόμηση ενέργειας,</b> απαιτείται η εφαρμογή  συνδυασμένων πολιτικών  ώστε να εξορθολογιστεί  η οικιακή κατανάλωση. Η εξοικονόμηση 
		ενέργειας και η μείωση της κατανάλωσης στον κτιριακό, βιομηχανικό και τον τομέα των μεταφορών, πρέπει να αποτελούν επίσης την πρώτη προτεραιότητα του όποιου 
		ενεργειακού σχεδιασμού. Χρειάζεται  ενίσχυση των δημόσιων πολιτικών για τη μεγιστοποίηση της διείσδυσης των ΑΠΕ στο ενεργειακό ισοζύγιο. Οι πολιτικές αυτές 
		πρέπει να λαμβάνουν υπόψη τις αναπτυξιακές προτεραιότητες και ανάγκες κάθε περιφέρειας, διασφαλίζοντας συγχρόνως τα περιβαλλοντικά συγκριτικά πλεονεκτήματά της.
		Οι χωροταξικές επιλογές θα πρέπει να εξασφαλίζουν την συμμετοχή και την συναίνεση της τοπικής αυτοδιοίκησης. </Typography><br/>
		<b>Ειδικότερα απαιτούνται:</b>
		<ul>
			<li>ισχυρά κίνητρα για την ενσωμάτωση των τεχνολογιών ΑΠΕ και εξοικονόμηση ενέργειας με έμφαση στην οικιακή κατανάλωση και τα δημόσια κτίρια. </li>
			<li>ορθολογικοποίηση και απλοποίηση των αδειοδοτικών διαδικασιών  για τις ΑΠΕ, με κριτήριο την υψηλή  προτεραιότητα τους για την επίτευξη των Εθνικών και
			Κοινοτικών στόχων </li>
			<li>η υλοποίηση των αναγκαίων έργων υποδομής από την ΔΕΗ  -πάντοτε με περιβαλλοντικούς όρους- για την απορρόφηση της ηλεκτρικής ενέργειας που θα παράγεται 
			από τα νέα έργα ΑΠΕ</li>
			<li>άμεση θεσμοθέτηση ενός Ειδικού Πλαισίου Χωροταξικού Συνδυασμού με σαφή κριτήρια για την χωροθέτηση των ΑΠΕ και περιβαλλοντική προστασία. </li>
			<li>ισχυρά θεσμικά  κίνητρα και νομοθετικά διασφαλισμένο ανταποδοτικό όφελος για  τους Δήμους που προωθούν έργα ΑΠΕ στην περιοχή τους</li>
			<li>ενίσχυση της πρωτογενούς παραγωγής ενέργειας από βιοκαύσιμα και ένταξη της στο νέο θεσμικό καθεστώς.</li>
			<li>είναι απολύτως  αναγκαίο μαζί με το νέο θεσμικό πλαίσιο να εκπονηθεί  πρόγραμμα μέτρων εφαρμογής του και μηχανισμό συνεχούς παρακολούθησης και 
			αξιολόγησής του.</li>		
		</ul></Typography>
		
		<Typography variant="h3" sx={theme.typography.typography2}>Είναι απαραίτητη επίσης η διαμόρφωση μιας στρατηγικής για την μεγιστοποίηση της διείσδυσης των ΑΠΕ στα νησιά που θα συμπεριλαμβάνει θέματα ηλεκτρικής 
		διασύνδεσης  μεταξύ τους και με το ηπειρωτικό δίκτυο, καθώς και θέματα αποθήκευσης ενέργειας. </Typography>
		<Typography variant="h3" sx={theme.typography.typography2}><b>Απολύτως αναγκαία  για την επίτευξη των παραπάνω στόχων είναι η ενεργοποίηση και συμπαράταξη των τοπικών κοινωνιών με την ενημέρωση και 
		ευαισθητοποίηση τους μέσα από συγκεκριμένες δράσεις και προγράμματα.</b>
		Οι συμμετέχοντες στο συνέδριο δεσμεύονται να συνεχίσουν τις προσπάθειές τους στο σοβαρό αυτό ζήτημα.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Πρέπει να γίνει σε όλους μας αντιληπτό, ότι μια εναλλακτική πορεία στο τεράστιο ζήτημα της ενέργειας <b>ΜΑΣ ΑΦΟΡΑ ΟΛΟΥΣ</b> και αποτελεί  μονόδρομο για
		την βιώσιμη ανάπτυξη του τόπου μας και την κοινωνία των πολιτών που οραματιζόμαστε. </Typography>

		</Box>
		
		<ScrollToTopButton />
		
	
    
    
    </>
  )
}

export default F3_oikologika_03