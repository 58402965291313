import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g14/exodos.jpg";

function G14_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="H Μεγάλη Έξοδος" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>

      
	<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
    <Typography variant="h3" sx={theme.typography.typography2}>Το 1922, τα τραγικά γεγονότα της Μικρασιατικής καταστροφής που σφραγίστηκαν με το αίμα χιλιάδων αθώων, αποτέλεσαν την πιο βαθιά τομή της νεοελληνικής 
	ιστορίας και καθόρισαν αποφασιστικά την πορεία του ελληνικού έθνους. Η τρισχιλιόχρονη ελληνική παρουσία στα χώματα της Μικρασίας διακόπτεται βίαια και η προσφυγική
	πλημμυρίδα που ακολουθεί προς την ελληνική επικράτεια, με καταλυτικό και πρωταγωνιστικό τρόπο επηρεάζει  το μετασχηματισμό και τη δημιουργία του σύγχρονου ελληνικού
	κράτους. Η Ελλάδα εξέρχεται από μια μακρά περίοδο πολεμικών αναμετρήσεων, όπου είδε τα σύνορα και τον πληθυσμό της  να υπερδιπλασιάζονται, αλλά που οδυνηρά βίωσε 
	την αναδίπλωση και περιορισμό του Ελληνισμού στα στενά πλέον όρια της γνωστής ελληνικής επικράτειας. Οι νέες συνθήκες που διαμορφώνονται σηματοδοτούν την απαρχή 
	μιας εκσυγχρονιστικής και ανορθωτικής περιόδου, στη διαμόρφωση της οποίας πρωταγωνιστεί ο προσφυγικός κόσμος, που καταφέρνει να ξεπεράσει τις ανείπωτες δυσκολίες 
	των πρώτων χρόνων και να αναδειχτεί σε παράγοντα προόδου. Η ευεργετική για την Ελλάδα παρουσία των Μικρασιατών προσφύγων, αμβλύνει σταδιακά τις συνέπειες της
	καταστροφής του πιο δυναμικού και ακμαίου τμήματος του Ελληνισμού. Μικρασιάτες, Πόντιοι, Κωνσταντινουπολίτες και Θράκες, δεν περιορίσθηκαν απλώς σε μια οικονομική
	ή πολιτιστική εισφορά στο εθνικό σύνολο, αλλά στάθηκαν συνδημιουργοί στη διαμόρφωση του Νέου Ελληνισμού.    
	Ο εκριζωμός των ελληνικών πληθυσμών από τη μικρασιατική πατρίδα θα λέγαμε πως έλαβε χώρα σε δύο κύριες φάσεις. Το κύμα της αποτρόπαιης βίας που σαρώνει τα δυτικά 
	παράλια αμέσως μετά την αποχώρηση του ελληνικού στρατού το 1922, διαδέχεται ο σπαραγμός του οργανωμένου ξεριζωμού των κατοίκων της Κεντρικής Μικράς Ασίας και του
	Πόντου το 1923-25, που είχε επιβληθεί με τη σύμβαση της ανταλλαγής πληθυσμών. Οι ομογενείς της Ανατολικής Θράκης σε μεγάλο ποσοστό κατορθώνουν να φθάσουν όπως-όπως 
	στον Έβρο και να βρουν καταφύγιο σε ελληνικό έδαφος..</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Η ΄΄Σύμβασις περί Ανταλλαγής Ελληνικών και Τουρκικών Πληθυσμών΄΄ (με εξαίρεση τους Μουσουλμάνους της Δυτικής Θράκης και τους εγκατεστημένους μέχρι το 1918 Έλληνες 
	της Κωνσταντινούπολης) που υπογράφηκε στη Λοζάνη (3-7-1923), νομιμοποίησε το ΄΄Μεγάλο ξεριζωμό΄΄ συντρίβοντας τα όνειρα των προσφύγων για παλινόστηση. Ενδεχομένως, 
	όπως έχει γραφεί,  να ήταν η μόνη ρεαλιστική και συμφέρουσα για την Ελλάδα λύση, καθώς αφορούσε 355.000 Μουσουλμάνους της Ελλάδας και 190.000 Έλληνες της Κεντρικής 
	Μικράς Ασίας (Καππαδοκία) και του Πόντου. Ο κύριος όγκος του προσφυγικού κόσμου, γύρω στο 1.000.000 είχε ήδη φτάσει στην Ελλάδα από το ΄22 και η επιστροφή του φάνταζε
	ανέφικτη.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Για τον αριθμό των προσφύγων που κατέφτασαν στον Ελλαδικό χώρο δεν υπάρχουν ακριβή στοιχεία εκτός από εκείνα της απογραφής του 1928, που καταγράφει 1.221.849 πρόσφυγες
	(περιλαμβάνονται 117.633 προερχόμενοι από Βουλγαρία, Καύκασο και αλλού) και τους διακρίνει σε 673.025 αστούς και 578.824 αγρότες. Ένα εντυπωσιακό ποσοστό 66% 
	αποτελείται από γυναίκες και παιδιά κάτω των δέκα ετών (38% και 28% αντίστοιχα), καθώς μεγάλο μέρος του άρρενος πληθυσμού εξοντώθηκε ή αιχμαλωτίστηκε. <br/>	
	Η Λέσβος έτυχε ένας από τους τόπους με τη μεγαλύτερη υποδοχή προσφύγων. Η εγκατάσταση αυτή μάλιστα, προσέγγισε το 45% του συνολικού πληθυσμού. Μια μικρή ομάδα
	από αυτούς, εγκαταστάθηκε στην περιοχή των Λουτρών. Η εγκατάσταση αυτή έγινε μέσα στο χωριό Λουτρά, αλλά και σε παλιές εγκαταλειμμένες εμπορικές αποθήκες και
	αγροικίες (ντάμια) στη Σκάλα Λουτρών, μια ακατοίκητη περιοχή με υποτυπώδες λιμάνι και δύο ελαιοτριβεία. Η υφιστάμενη εγκατάσταση διατηρήθηκε έτσι μέχρι το 1930.
	Στο διάστημα αυτό, οικοδομήθηκαν από το κράτος σε μια ανοιχτή έκταση με την επωνυμία ΄΄Βατσίνα΄΄, 25 σπίτια των δύο δωματίων και παραχωρήθηκαν σε 25 μικρασιατικές 
	οικογένειες το 1931. Με τον τρόπο αυτό στην περιοχή δημιουργείται ο οικισμός της Σκάλας Λουτρών, ένας καθαρά προσφυγικός οικισμός που στο λόγο των κατοίκων του 
	φέρει ακόμα την ονομασία ΄΄ο συνοικισμός΄΄.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Τόποι καταγωγής των προσφύγων της ευρύτερης περιοχής των Λουτρών ήταν ο Μαρμαράς Αϊδινίου, ο Τσεσμές (Κρήνη), η Αγία Παρασκευή Κρήνης, τα Αλάτσατα και το Εγγλεζονήσι 
	της Ερυθραίας, το Σεβδίκιοϊ της Σμύρνης, οι Φώκαιες, το Τσανταρλί, το Δικελί, το Ατζανός, το Κεμέρι, οι Σααντζήδες της περιοχής Περγάμου και το Αϊβαλί. Το 60% των
	προσφύγων της Σκάλας Λουτρών προέρχονται από τις Φώκαιες, το 19% από την περιοχή του Τσεσμέ, το 9% από το Εγγλεζονήσι και το υπόλοιπο από τις άλλες περιοχές. 
	Συνυπολογίζοντας και τα άτομα που δεν είχαν μικρασιατική καταγωγή μαζί με τα γεννημένα παιδιά δεύτερης γενιάς , το 1931 ο πρώτος οικισμός της Σκάλας Λουτρών θα 
	πρέπει να κατοικήθηκε από 160 περίπου κατοίκους. Σήμερα απαριθμεί γύρω στους 300 μόνιμους , διαθέτει κοινό τοπικό συμβούλιο με το χωριό Λουτρά και ανήκει διοικητικά
    στο Δήμο Μυτιλήνης. Το 80%   του σημερινού  πληθυσμού της Σκάλας είναι μικρασιατικής καταγωγής και από το 2006 έχει έρθει στη ζωή πλέον η πέμπτη γενιά προσφύγων.</Typography>
	</Box>

    <ScrollToTopButton />


    </>
  );
}

export default G14_mikrasiatika;
