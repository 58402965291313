import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";


import Image01 from "../assets/images/mikrasiatika_g3/photo01.jpg";
import Image02 from "../assets/images/mikrasiatika_g3/photo02.jpg";
import Image03 from "../assets/images/mikrasiatika_g3/photo03.jpg";
import Image04 from "../assets/images/mikrasiatika_g3/photo04.jpg";
import Image05 from "../assets/images/mikrasiatika_g3/photo05.jpg";
import Image06 from "../assets/images/mikrasiatika_g3/photo06.jpg";
import Image07 from "../assets/images/mikrasiatika_g3/photo07.jpg";
import Image08 from "../assets/images/mikrasiatika_g3/photo08.jpg";
import Image09 from "../assets/images/mikrasiatika_g3/photo09.jpg";
import Image10 from "../assets/images/mikrasiatika_g3/photo10.jpg";
import Image11 from "../assets/images/mikrasiatika_g3/photo11.jpg";
import Image12 from "../assets/images/mikrasiatika_g3/photo12.jpg";
import Image13 from "../assets/images/mikrasiatika_g3/photo13.jpg";
import Image14 from "../assets/images/mikrasiatika_g3/photo14.jpg";
import Image15 from "../assets/images/mikrasiatika_g3/photo15.jpg";
import Image16 from "../assets/images/mikrasiatika_g3/photo16.jpg";
import Image17 from "../assets/images/mikrasiatika_g3/photo17.jpg";
import Image18 from "../assets/images/mikrasiatika_g3/photo18.jpg";
import Image19 from "../assets/images/mikrasiatika_g3/photo19.jpg";
import Image20 from "../assets/images/mikrasiatika_g3/photo20.jpg";
import Image21 from "../assets/images/mikrasiatika_g3/photo21.jpg";
import Image22 from "../assets/images/mikrasiatika_g3/photo22.jpg";
import Image23 from "../assets/images/mikrasiatika_g3/photo23.jpg";



function G3_mikrasiatika() {

    const theme = useTheme();


  return (
    <>

     
<IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ο Γεωγραφικός χώρος της Μικράς Ασίας" />
      </Box>


      
	<Box sx={{ ...commonBoxStyles }}>
	 
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>O όρος  Μικρά Ασία ορίζεται, αναλόγως  της χρονικής  περιόδου, με διαφορετικό τρόπο. Αρχικά τον συναντάμε στο έργο του γεωγράφου <strong>Κλαύδιου Πτολεμαίου</strong>, 
	τον 2ο αι. μ. Χ., οπότε περιλαμβάνει μόνο τα δυτικά και νότια παράλια της Τουρκίας, με εξαίρεση τη νοτιοανατολική της προέκταση προς την αρχαία Συρία. Σε 
	ορισμένες περιπτώσεις χρησιμοποιείται ως συνώνυμος με τον όρο Ανατολία, που όμως είναι ευρύτερος, καθώς περιλαμβάνει και τη Συρία και τη Μεσοποταμία.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο <strong>γεωγραφικός χώρος</strong> της Μικράς Ασίας ορίζεται προς τα δυτικά από το Αιγαίο πέλαγος, προς τα βόρεια από την Προποντίδα και τον Εύξεινο Πόντο, προς τα νότια 
	από τη Μεσόγειο, ενώ στα ανατολικά εκτείνεται ως τον Τίγρη και τον Ευφράτη ή ακόμη ως τα όρη της Αρμενίας. Η δυτική Μικρά Ασία, περιορίζεται στα δυτικά παράλια 
	της Μικράς Ασίας, εν πολλοίς την περιοχή όπου εντοπίζεται κυρίως η δράση των Ελλήνων εποίκων. Σε γενικές γραμμές τα δυτικά παράλια της Μικράς Ασίας παρουσιάζουν 
	κοινή εδαφική μορφολογία: μικρές, στενές πεδιάδες που εναλλάσσονται με χαμηλά όρη και διατρέχονται από μικρού ή μεσαίου μεγέθους ποταμούς, τοπίο το οποίο έχει 
	πολλά κοινά με τη μητροπολιτική Ελλάδα. Η ζώνη αυτή επιμερίζεται σε μικρότερες γεωγραφικές περιοχές, οι οποίες απηχούν περισσότερο ιστορικούς παρά γεωγραφικούς 
	διαχωρισμούς. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ξεκινώντας από βορρά προς νότο, συναντά κανείς την Τρωάδα, την Αιολίδα, την Ιωνία, την Καρία και τη Λυκία. Στο εσωτερικό της παράλιας ζώνης συναντά κανείς 
	τη  Φρυγία, τη Λυδία και τη Μυσία, οι οποίες πριν από την Ελληνιστική   περίοδο   κατοικούνταν κυρίως από μη ελληνικούς πληθυσμούς, αλλά αργότερα αποτέλεσαν 
	σημαντικά κέντρα του Ελληνισμού.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η έκταση της <strong>Τρωάδας</strong> αποτελούσε πρόβλημα ήδη από την εποχή του Στράβωνα, καθώς η περιοχή που ο Όμηρος αποδίδει στον έλεγχο των Τρώων ήταν πολύ 
	μεγαλύτερη από αυτή που κατά τα Ιστορικά χρόνια αποκαλούνταν Τρωάς και εκτεινόταν από την Προποντίδα ως τον κόλπο του Αδραμυτίου, απέναντι από τη Μυτιλήνη. Η μεγαλύτερη 
	πόλη της κατά την Ιστορική περίοδο ήταν η Αλεξάνδρεια Τρωάς, που μαζί με το Ίλιον, διατηρούσαν ακέρατη τη μνήμη του ένδοξου παρελθόντος της ομηρικής Τροίας.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η <strong>Αιολίδα</strong> καταλαμβάνει τη ζώνη μεταξύ του κόλπου του Αδραμυττίου  και της Σμύρνης. Σε  αυτή την περιοχή βρίσκονται και οι πιο ηπειρωτικές ελληνικές
	πόλεις της Μικράς Ασίας, όπως η Μαγνησία του Σιπύλου και η Λάρισα στον Έρμο. Η Αιολίδα ήταν γεωγραφική οντότητα, χωρίς ποτέ να αποτελέσει διοικητικό κέντρο. </Typography>	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η <strong>Ιωνία</strong> είναι η περιοχή της κεντρικής παράλιας Μικράς Ασίας και εκτείνεται γεωγραφικά από την περιοχή απέναντι από τη Χίο έως και τη χερσόνησο 
	της Αλικαρνασσού. Αποτέλεσε την καρδιά του μικρασιατικού Ελληνισμού από την πρώιμη 1η χιλιετία π.Χ. ως τα Ύστερα  Ρωμαϊκά χρόνια, οπότε και ήταν η βάση της 
	Ρωμαϊκής Επαρχίας της Ασίας. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η χερσόνησος της <strong>Αλικαρνασσού</strong> ήταν το επίκεντρο της Καρίας, αν και οι Κάρες φαίνεται πως έλεγχαν πριν από τον ελληνικό αποικισμό και το 
	μεγαλύτερο κομμάτι της Ιωνίας. Η Καρία δεν αποτέλεσε ποτέ ξεχωριστό πολιτικό οργανισμό, αν και το α΄ μισό του 4ου αι. κατόρθωσε, υπό τη δυναστεία των Εκατομνιδών, 
	να παίξει σημαντικό ρόλο, κηδεμονεύοντας και ένα τμήμα των ελληνικών πόλεων της Μικράς Ασίας. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η <strong>Λυκία</strong> είναι η νοτιότερη περιοχή των δυτικών παραλίων, φτάνει ως την είσοδο του κόλπου της Αττάλειας και συνορεύει με την Πισιδία και την 
	Παμφυλία.  Η γεωγραφική έκταση της Λυκίας δεν ήταν επακριβώς καθορισμένη και κατά καιρούς περιοχές με λυκιακό πληθυσμό βρέθηκαν υπό τον έλεγχο των Καρίων, των 
	Ροδίων και των Ρωμαίων, πριν να αποτελέσει τελικώς αυτόνομη επαρχία της ρωμαϊκής αυτοκρατορίας.</Typography>		
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η <strong>Φρυγία</strong> ήταν το απομεινάρι του μεγάλου φρυγικού κράτους του 8ου αι. π.Χ. Οι Φρύγες ζούσαν σε μια ευρεία γεωγραφική περιοχή, η οποία ήδη 
	κατά τη διάρκεια της περσικής αυτοκρατορίας  χωριζόταν σε ελλησποντιακή και μεγάλη ηπειρωτική Φρυγία</Typography>	

	<Typography variant="h3" sx={theme.typography.typography2}>Η <strong>Μυσία</strong>, πατρίδα των Μυσών, είχε εξίσου ασαφή γεωγραφικά όρια και ακόμη και σήμερα, οι ιστορικοί διαφωνούν για το τι επακριβώς 
	περιλάμβανε. Το κέντρο της ήταν η Τευθρανία, εκεί όπου αργότερα ιδρύθηκε η Πέργαμος.</Typography>		
		
	</Box>
	
	
	 <ScrollToTopButton />		
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Προϊστορική Περίοδος  : Μινωϊτες και Μυκηναίοι Θαλασσοπόροι</Typography>
	
	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Κατά τον 18ο αι. π. Χ., οι Μινωίτες  θαλασσοπόροι  κατάφεραν  να  επεκτείνουν την κυριαρχία τους, εκτός από τις Κυκλάδες και από   ορισμένα στρατηγικά  
	σημεία της μητροπολιτικής Ελλάδας και στα μικρασιατικά παράλια. Οι κυριότερες αποικίες που δημιούργησαν  εκεί, με βάση τα πρόσφατα αρχαιολογικά ευρήματα, είναι η 
	Μίλητος και η παρακείμενη Ιασός της Καρίας.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η μυκηναϊκή παρουσία στη Μικρά Ασία αποτελεί ουσιαστικά την πρώτη ελληνική παρουσία στον ασιατικό χώρο και εστιάζεται κυρίως σε περιοχές όπου προηγουμένως 
	είχε αναπτυχθεί το υπερπόντιο εμπόριο των Μινωιτών. Το σημαντικότερο κέντρο της δραστηριότητας τους είναι η Μίλητος, όπου Μινωίτες και Μυκηναίοι συνυπάρχουν για 
	ένα διάστημα. Κατά τον 15ο αιώνα π.Χ. όμως, και έως το 1200 π.Χ. περίπου, η πόλη φαίνεται ότι ελέγχεται απευθείας από τους Αχαιούς.</Typography>
	
	<img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Γύρω στα 1200-1180 π. Χ. μια σειρά βίαιων καταστροφών στην ανατολική Μεσόγειο  σηματοδοτεί το τέλος της Εποχής του Χαλκού και την ανάδειξη νέων 
	δυνάμεων: τα μυκηναϊκά ανάκτορα καταστρέφονται, το βασίλειο των Χετταίων καταρρέει, στη Συρία και την Παλαιστίνη αναδύονται νέες δυνάμεις (Φιλισταίοι, Εβραίοι, 
	Φοίνικες), ενώ  η  Αίγυπτος παρακμάζει. Η ελληνική παρουσία στη Μικρά Ασία διακόπτεται απότομα, αλλά μόνο προσωρινά. 
	<br/><br/><br/><br/></Typography>
 
	</Box>
	
	
	 <ScrollToTopButton />	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ελληνικός Αποικισμός της Μικράς Ασίας</Typography>
	
	<img src={Image03} height="154"   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>H παράδοση συνδέει τον αποικισμό της Μικράς Ασίας με την Κάθοδο των Δωριέων, γύρω στο 1.100 π.Χ. Η σύγχρονη έρευνα ωστόσο θεωρεί πιθανότερη τη σταδιακή 
	μετακίνηση πληθυσμών από τον ελλαδικό χώρο προς τη Μικρά Ασία, η οποία και διήρκεσε τρεις περίπου αιώνες. Τρεις πληθυσμιακές ομάδες εμπλέκονται κυρίως στον 
	αποικισμό της Μικράς Ασίας. </Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Στα βόρεια παράλια εγκαταστάθηκαν οι <strong>Αιολείς</strong>. Ξεκίνησαν τη μετακίνησή τους τέσσερις γενιές πριν από τους Ίωνες, με αρχηγό τον Ορέστη, ο 
	οποίος όμως πέθανε  
	στην Αρκαδία. Οι απόγονοι του, ακολουθώντας ένα αργό δρομολόγιο και αφού προηγουμένως εγκαταστάθηκαν στη Φωκίδα και τη Θράκη, έφτασαν τελικά στη Μικρά Ασία 
	και τα νησιά του Αιγαίου την ίδια περίπου περίοδο με τους Ίωνες (περίπου το 1050). Εγκαταστάθηκαν στη Λέσβο, στην Τένεδο, στην Τρωάδα και στην περιοχή που 
	ονομάστηκε Αιολίδα, όπου ίδρυσαν την Πιτάνη, τη Μύρινα, τις Αιγές, την Κύμη και τη Σμύρνη, η οποία όμως αργότερα πέρασε στον έλεγχο των Ιώνων. Στην ενδοχώρα 
	ίδρυσαν τη Μαγνησία του Σιπύλου και τη Λάρισα  στον Έρμο, ενώ σε Αιολείς αποδίδεται επίσης η ίδρυση της Μαγνησίας του Μαιάνδρου, που γεωγραφικά ανήκει στην Ιωνία</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο χρονικογράφος <strong>Ερατοσθένης</strong> τοποθετεί την μετανάστευση των Ιώνων, 140 χρόνια μετά την πτώση της Τροίας, δηλαδή το 1044 π.Χ. Σήμερα είναι γνωστό ότι 
	αυτό το αποικιακό ρεύμα κράτησε πάνω από δύο αιώνες. Οι περισσότεροι Ίωνες θεωρούσαν ότι κατάγονταν από τους Αθηναίους, αλλά ο ρόλος της Αθήνας ήταν μάλλον 
	αυτός ενός ενδιάμεσου μεταξύ των πληθυσμών των Ιώνων που έφευγαν από την Πελοπόννησο (Κορινθία, Αργολίδα, Μεσσηνία) και τον τελικό προορισμό. Οι Ίωνες 
	ίδρυσαν τη Δωδεκάπολη,   χαλαρή   συνομοσπονδία με θρησκευτικό χαρακτήρα και έδρα το Ιερό του Ελικώνειου Ποσειδώνα στο ακρωτήριο της Μυκάλης. Οι πόλεις 
	αυτές ήταν (από βορρά προς νότο): η Φώκαια, που γεωγραφικά εντάσσεται στην Αιολίδα, οι Κλαζομενές, οι Ερυθρές, η Χίος, η Τέως, η Λέβεδος, η Κολοφώνα, η 
	Έφεσος, η Σάμος, η Πριήνη, ο Μυούς και η Μίλητος.</Typography>		
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το τελευταίο μεταναστευτικό ρεύμα ανήκει σε Δωριείς, προερχόμενους από την Πελοπόννησο και την  Κρήτη, οι οποίοι κατέλαβαν την περιοχή που 
	εκτείνεται μεταξύ του όρους  Λάτμος  και  του κόλπου  του Μαρμαρά, μαζί με τα παρακείμενα νησιά. Ίδρυσαν την Εξάπολη, με έδρα το Ιερό  του Τριοπίου Απόλλωνα, 
	στη χερσόνησο της Κνίδου. Οι δωρικές πόλεις ήταν η Κνίδος και η Αλικαρνασσός στα μικρασιατικά παράλια και οι νησιωτικές πόλεις Λίνδος, Κάμειρος, Ιαλυσός στη 
	Ρόδο, καθώς και η Κως. Σύντομα, η Αλικαρνασσός εκδιώχθηκε από την Εξάπολη. </Typography>	
	</Box>
	
	
	 <ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Η εξάπλωση των Ελλήνων στην Μικρά Ασία</Typography>
	
	<img src={Image04}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>O Β΄ αποικισμός, το φαινόμενο της εξάπλωσης του Ελληνισμού στο σύνολο σχεδόν του μεσογειακού χώρου, από το 800 ως το 500 π. Χ. περίπου, δεν βρήκε 
	αμέτοχη την ελληνική Μικρά Ασία. Οι μεγάλες ελληνικές πόλεις της περιοχής συμμετείχαν στο αποικιακό ρεύμα προς τη Δύση, τη βόρεια Αφρική, τη Θράκη και 
	τη Μαύρη Θάλασσα, είτε σε συνεργασία είτε διαγκωνιζόμενες μεταξύ τους για την πρωτοκαθεδρία. Προνομιακό χώρο δράσης για τους Έλληνες της Μικράς Ασίας και 
	ιδιαίτερα για τους Ίωνες, αποτέλεσε η δυτική Μεσόγειος και ο Εύξεινος Πόντος με την Προποντίδα, κάτι που μαρτυρά τον κατά βάση εμπορικό χαρακτήρα των αποικιών.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Πρωτοπόρος και σημαντικότερη αποικιακή δύναμη ήταν η <strong>Μίλητος</strong>. Θεωρείται ότι οι πρώτες αποικίες της ήταν η Σινώπη, η Τραπεζούντα στη Μαύρη Θάλασσα στα 
	μέσα του 8ου αι. π.Χ.) και η Κύζικος (756 π.Χ.). Αργότερα ίδρυσε μόνη ή σε συνεργασία με άλλες πόλεις της Μικράς Ασίας και του Αιγαίου το Πάριον (με την Πάρο 
	και τις Ερυθρές, το 709 π.Χ.), την Προκόνησο (690 π.Χ.), την Άβυδο (676 π.Χ.), την Προυσία, την Κίο (627 π.Χ.) και την Καρδία (μαζί με τις Κλαζομενές, στα 
	τέλη του 7ουαι. π.Χ.)  στη  Θράκη  και  στην  Προποντίδα αντίστοιχα, καθώς και μια σειρά  πόλεων στη Μαύρη Θάλασσα (Ίστρος το 657 π.Χ., Όλβια-Βορυσθένη το 
	647 π.Χ., Απολλωνία το 609 π.Χ., Παντικάπαιον το 600π.Χ., Οδησσός το 585 π.Χ., Αμισός το 564 π.Χ. μαζί με τη Φώκαια, Ηράκλεια Ποντική το 554 π.Χ. και το 
	Μυρμήκειον, την Τυριτάκη, τους Κήπους, την Τόμιδα, μεταξύ 550 π.Χ.). Η Μίλητος ηγήθηκε επίσης της εγκαθίδρυσης της μοναδικής ελληνικής αποικίας στην Αίγυπτο, 
	της Ναυκράτιδος, σε συνεργασία με τη Χίο, τη Ρόδο, τη Σάμο και την Αίγινα (620-610 π.Χ. π	ερίπου). Τα πλούσια ευρήματα κεραμικής από τη Μίλητο, που η 
	αρχαιολογική σκαπάνη ανέδειξε σε πληθώρα πόλεων της βόρειας Τουρκίας, της Βουλγαρίας, της Ρουμανίας, της Γεωργίας, της Ρωσίας και της Ουκρανίας αποτελούν 
	σήμερα τεκμήρια της πυρετώδους δραστηριότητας των Μιλήσιων ναυτικών.</Typography>	
	
	<img src={Image05}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Σε σχέση με τις υπόλοιπες μικρασιατικές μητροπόλεις, αξιοσημείωτες είναι οι αποικίες της Φώκαιας (Λάμψακος, το 654 π.Χ.) και της Τέως (Άβδηρα, 
	το 545 π.Χ. και Φαναγορία, το 545 π.Χ. περίπου).Οι Σάμιοι και οι Φωκαείς ήταν οι πρώτοι Ίωνες που αποτόλμησαν μακρινά  ταξίδια  στη  Δύση.  Ενώ οι πρώτοι είχαν 
	καθαρά εμπορικούς στόχους,  οι Φωκαείς απέβλεπαν σε πιο σταθερές βάσεις στην εξάπλωση τους προς τη δυτική Μεσόγειο. Η ίδρυση της Μασσαλίας το 600 π.Χ. στα νότια 
	παράλια της Γαλλίας και του Εμπορίου στη βορειοανατολική ακτή της Ιβηρικής χερσονήσου, έθεσαν τα γεωγραφικά όρια της εξάπλωσης αυτής. Οι δύο αυτές  πόλεις 
	αναδείχθηκαν με τη σειρά τους σε μητροπόλεις αποικιών όπως το Ημεροσκόπιο και η Μαινάκη στην Ιβηρική, η Αλαλία στην Κορσική (που όμως εγκαταλείφθηκε λόγω του 
	ανταγωνισμού των Καρχηδόνιων και των Ετρούσκων ) και η Ελέα στη δυτική Κάτω Ιταλία. Οι υπόλοιποι Έλληνες της Μικράς Ασίας ωστόσο δεν επέδειξαν την ίδια 
	αποικιακή δραστηριότητα. 
	Οι Κνίδιοι ίδρυσαν την αποικία της Μέλαινας Κέρκυρας σ’ ένα νησί των Δαλματικών ακτών και  μαζί με τους Ρόδιους, τη Λιπάρα στην ομώνυμη νήσο βορειοδυτικά της 
	Σικελίας, στην Τυρρηνική θάλασσα. Οι θέσεις αυτές ενισχύθηκαν μετά την περσική κατάληψη της Μικράς Ασίας, οπότε πολλοί Ίωνες εγκατέλειψαν την πατρίδα τους και 
	εγκαταστάθηκαν στις αποικίες, αλλά και σε άλλες θέσεις τη Δύσης, ελληνικές και μη, με αποτέλεσμα να διαδοθεί στις τέχνες της κυρίως Ελλάδας και της Ιταλίας ένα 
	ρεύμα "πανιωνισμού", που κυριάρχησε κατά το τελευταίο τρίτο του 6ου π.Χ.</Typography>
	
	</Box>
	
	
	 <ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Η Αρχιτεκτονική της Μικράς Ασίας</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ο Ιωνικός ρυθμός</Typography>
	<img src={Image06}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>O <strong>Ιωνικός ρυθμός</strong> διαμορφώθηκε    κατά τον 7ο αι. π.Χ., σε περιοχές κατοικημένες από Ίωνες, κυρίως στη Μικρά Ασία και τα νησιά του ανατολικού Αιγαίου.
	Κύριο χαρακτηριστικό του είναι ο ιωνικός κίονας, που  αποτελείται από τρία μέρη: το κιονόκρανο, τον κορμό και τη βάση. Ο κίονας στηρίζει το θριγκό, που αποτελείται 
	από το επιστύλιο, τη ζωφόρο και το γείσο. Τον 6ο αι. π.Χ. πολλές πόλεις της Μικράς Ασίας, ακολουθώντας τα ανατολικά πρότυπα, δημιούργησαν δική τους αρχιτεκτονική 
	έκφραση, στηριζόμενη πάντα στο διακοσμητικό και μορφολογικό πλούτο του ιωνικού ρυθμού. Τότε υψώθηκαν οι τεράστιοι ιωνικοί δίπτεροι ναοί. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Οι ιωνικοί ναοί, που ανεγέρθησαν κατά τους Αρχαϊκούς χρόνους στην ανατολική Ιωνία, ξεπερνούσαν κατά πολύ σε μέγεθος τους δωρικούς ναούς της ηπειρωτικής 
	Ελλάδας, αν και ο αρχιτεκτονικός σχεδιασμός τους δεν διέφερε σημαντικά. Το Αρτεμίσιο της Εφέσου (μέσα 6ου αι. π.Χ.) και το Διδυμαίο της Μιλήτου (540-525 π.Χ.) 
	ανήκουν στους σημαντικότερους και μεγαλοπρεπέστερους ναούς της αρχαίας ελληνικής αρχιτεκτονικής. Η πρόσοψη των αρχαϊκών ναών της Ιωνίας   έχει  συνήθως 8 κίονες, 
	με ύψος που μπορεί να φτάνει ως και τα 19 μ., στην κάτοψη επικρατεί ο τύπος  του μεγαλοπρεπούς δίπτερου, με δύο περιμετρικές κιονοστοιχίες γύρω από τον κυρίως ναό. </Typography>
	
	<img src={Image07}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Ο <strong>Ιωνικός ρυθμός</strong> συνέχισε να γνωρίζει μεγάλη άνθηση στη Μικρά Ασία κατά τον 4ο αι. π.Χ. και κατά την ελληνιστική περίοδο, όταν οι ελληνικές πόλεις
	είχαν την ευχέρεια να ανεγείρουν μεγαλόπρεπα οικοδομήματα που ανταγωνίζονταν του ναούς του παρελθόντος. Οι σημαντικότεροι ιωνικοί ναοί, είναι το Αρτεμίσιο στην 
	Έφεσο, ο Ναός του Απόλλωνα στα Δίδυμα, ο Ναός της Αθηνάς στην Πριήνη, ο Ναός της Άρτεμης Λευκοφρυήνης στη Μαγνησία του Μαιάνδρου και ο Ναός της Άρτεμης στις Σάρδεις.
	<br/><br/><br/><br/><br/><br/></Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ο Αιολικός ρυθμός</Typography>
	<img src={Image08}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>To <strong>αιολικού ρυθμού</strong> κιονόκρανο, ονομάζεται και πρωτοϊωνικό. Φέρει δύο έλικες, μεταξύ  των οποίων αναπτύσσεται ένα ανθέμιο και στη βάση του έχει 
	συνήθως ανάγλυφη διακόσμηση με πέταλα άνθους. Προέρχεται από την αρχιτεκτονική της Ανατολής (Ισραήλ, Ασσυρία) και εξελίχθηκε κυρίως  στο χώρο της Μικρά Ασίας και της 
	Λέσβου. Αναπτύχθηκε στα τέλη περίπου του 7ου αι. π.Χ. στη Σμύρνη, ενώ κατά τη διάρκεια του 6ου αι. π.Χ. συναντάται από την Καρία έως την Τρωάδα. Οι
	σημαντικότεροι  ναοί αιολικού ρυθμού είναι ένας αρχαϊκός ναός στην αιολική Λάρισα, με αξιόλογες πήλινες κεραμώσεις και ο αρχαϊκός ναός στη Νεανδρία της Τρωάδας.</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ο Δωρικός ρυθμός</Typography>
	<img src={Image09}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image10}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Ο <strong>δωρικός ρυθμός</strong> δε, γνώρισε ιδιαίτερη ανάπτυξη στη Μικρά Ασία. Μοναδικός ναός δωρικού ρυθμού κατά την αρχαϊκή περίοδο είναι ο Ναός της Αθηνάς 
	στην Άσσο (540-530 π.Χ.) που όμως έχει και ιωνικά στοιχεία. Κατά την ελληνιστική περίοδο, ο δωρικός ρυθμός εμφανίζεται σποραδικά στη ναοδομία της Μικράς Ασίας, 
	καθώς και σε κοσμικά οικήματα (πρόπυλα, στοές, γυμνάσια).
	<br/><br/><br/><br/><br/><br/></Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Κλασική εποχή</Typography>
	<img src={Image11}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η αρχιτεκτονική του 5ου αι. π.Χ. χαρακτηρίζεται ειδικότερα από την αρμονία των αναλογιών. Οι αρχιτεκτονικές μορφές και οι τρόποι δόμησης βελτιώθηκαν, ενώ τα 
	επιμέρους στοιχεία των αρχιτεκτονικών ρυθμών, του δωρικού και του ιωνικού, απέκτησαν τέλεια ισορροπία. Από τον 5ον αι. π.Χ. υπάρχουν ενδείξεις ότι οι Έλληνες 
	αρχιτέκτονες άρχισαν να ασχολούνται με τα προβλήματα του εσωτερικού χώρου. Επικράτησε η χρήση του μαρμάρου, με αποτέλεσμα τα αρχιτεκτονικά σύνολα να αποκτήσουν 
	περισσότερη επιβλητικότητα. Η ναοδομία συνέχισε να μονοπωλεί το ενδιαφέρον των αρχιτεκτόνων, με τη διαφορά  ότι  και  κατά  τα  Κλασικά χρόνια  οι   
	ελληνικοί ναοί επιβάλλονταν όχι με το μέγεθος αλλά με την ποιότητα του σχεδιασμού και της κατασκευής τους.</Typography> 
	<Typography variant="h3" sx={theme.typography.typography2}>Στην περιοχή της Μικράς Ασίας η αρχιτεκτονική αισθητική αναβίωσε τον 4ο αι. π.Χ. Η ιωνική αρχιτεκτονική εξελίχθηκε, ενώ οι μορφές της μαρτυρούσαν αττικές επιδράσεις, 
	διατηρώντας όμως ταυτόχρονα την τοπική παράδοση της Ανατολής. Μοναδικά παραμένουν στην ιστορία της αρχιτεκτονικής τα ταφικά μνημεία της Ύστερης Κλασικής περιόδου, 
	το Μνημείο των Νηρηίδων στην Ξάνθο της Λυκίας και το Μαυσωλείο της Αλικαρνασσού στην Καρία. Την εποχή αυτή ανοικοδομήθηκε ο Ναός της Άρτεμης στην Έφεσο και 
	χτίστηκε ο Ναός της Αθηνάς Πολιάδας στην  Πριήνη. Γενικότερα η αρχιτεκτονική του 4ου  αι. π.Χ. αποτέλεσε το συνδετικό κρίκο της κλασικής με την ελληνιστική τέχνη, 
	η οποία ακολούθησε.</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ελληνιστική εποχή</Typography>
	<img src={Image12}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η αρχιτεκτονική κατά τα ελληνιστικά χρόνια έγινε μέσο προβολής της δύναμης και του πλούτου των ηγεμόνων της περιόδου. Απέκτησε έτσι διαφορετική 
	αισθητική από την αρχιτεκτονική των προηγούμενων περιόδων, καθώς στράφηκε προς τη διακόσμηση, την πολλαπλότητα και την  εκλέπτυνση. Το  κέντρο  βάρους του  
	ελληνικού   πολιτισμού  μετατέθηκε στη Μικρά Ασία και την Ανατολή, με αποτέλεσμα την εξαιρετική ανάπτυξη και άνθηση της οικοδομικής τέχνης στην περιοχή αυτή. 
	Τα βασικά χαρακτηριστικά της ελληνιστικής αρχιτεκτονικής είναι οι μνημειακές διαστάσεις, η έντονη διακόσμηση, η χωροταξική διευθέτηση των κτηρίων, η 
	βελτίωση της λειτουργικότητας και η εκμετάλλευση του εσωτερικού χώρου των οικοδομημάτων. Τα οικοδομήματα δωρικού ρυθμού ήταν ελάχιστα, ενώ αντίθετα ο 
	ιωνικός και ο κορινθιακός ρυθμός γνώρισαν μεγάλη ακμή, λόγω του έντονα διακοσμητικού χαρακτήρα τους.</Typography>
	
	<img src={Image13}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Σύνηθες φαινόμενο ήταν επίσης και η ανάμειξη μορφών και στοιχείων διαφόρων ρυθμών στο ίδιο οικοδόμημα. Η ναοδομία συνέχισε να κατέχει βασική θέση στην 
	αρχιτεκτονική της περιοχής. Παλαιότερα ιερά ανοικοδομήθηκαν,   ενώ   ανεγέρθηκαν νέοι μνημειακοί ναοί, όπως ο Ναός της Άρτεμης στις Σάρδεις, ο Ναός του 
	Απόλλωνα στα Δίδυμα, ο Ναός του Απόλλωνος Σμινθέως στη Χρύση της Τρωάδας. Ταυτόχρονα, μοναδικά δημιουργήματα της ελληνιστικής τέχνης αποτελούν οι βωμοί της 
	Περγάμου, της Πριήνης και της Μαγνησίας του Μαιάνδρου.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Παράλληλα, κυριάρχησε η κοσμική αρχιτεκτονική. Οικοδομήθηκαν συγκροτήματα και κτήρια που πρόβαλαν το μεγαλείο των βασιλέων και εξυπηρετούσαν ποικίλες 
	ανάγκες του δημόσιου και ιδιωτικού  βίου, όπως αγορές, βουλευτήρια, πρυτανεία, στάδια, θέατρα, γυμνάσια, παλαίστρες, ανάκτορα και περίστυλες  οικίες, όλα 
	σε μεγάλες διαστάσεις και με πολυτελή διάκοσμο. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Σημαντική άνθηση γνώρισε ο οικοδομικός  τύπος της στοάς. Η στοά ήταν κατεξοχήν ελληνικό κτήριο, το οποίο στα ελληνιστικά χρόνια απέκτησε 
	μεγαλύτερες διαστάσεις και  συχνά μάλιστα ήταν διώροφο. Προοριζόταν  για τη στέγαση διαφόρων συγκροτημάτων, όπως αγορές, γυμνάσια, ιερά κ. ά. Ο σχεδιασμός των 
	αγορών των μικρασιατικών πόλεων επηρεάστηκε επίσης από το ανανεωτικό πνεύμα της εποχής. Οικοδομήθηκαν περίστυλες  αγορές κανονικού σχήματος, στις οποίες τα 
	επιμέρους οικοδομήματα διευθετούνταν γύρω από έναν ανοιχτό υπαίθριο χώρο που οριζόταν από στοές. Τυπικά παραδείγματα των ελληνιστικών αγορών αποτελούν οι 
	γορές της Μαγνησίας του Μαιάνδρου και της Άσσου. Ως καινοτομία της ελληνιστικής εποχής πρέπει να χαρακτηριστεί και η ανάπτυξη των μνημειακών πρόπυλων, με 
	πλούσια αρχιτεκτονική διακόσμηση. Πρόκειται για έναν τύπο που ενέπνευσε   τις  λαμπρές   προσόψεις των οικοδομημάτων κατά τα ρωμαϊκά χρόνια. Εξαιρετικά 
	παραδείγματα ελληνιστικών προπύλων αποτελούν το Πρόπυλο της Αθηνάς στην Πέργαμο και η Πύλη της Νότιας Αγοράς της Μιλήτου. </Typography>	
	
	</Box>
	
	
	 <ScrollToTopButton />
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ο Όμηρος και η Επική ποίηση</Typography>
	
	<img src={Image14}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η ελληνική και εν πολλοίς η  ευρωπαϊκή λογοτεχνία, γεννιέται στη γη της Ιωνίας κατά τον 8ο αι. π.Χ. Η παράδοση διέσωσε το όνομα του Ομήρου, του τυφλού 
	αοιδού, στον οποίο αποδίδονται τα δύο μεγάλα έπη του Τρωικού κύκλου, η Ιλιάδα και η Οδύσσεια. Το ζήτημα της ταυτότητας του Ομήρου και της πατρότητας των δύο 
	έργων απασχολεί την  έρευνα ήδη από την αρχαιότητα. Επτά πόλεις έριζαν για τη καταγωγή του Ομήρου, η μεικτή γλώσσα (ιωνική με ισχυρά αιολικά στοιχεία) του 
	οποίου μαρτυρά μια παράδοση που επιχωριάζει στη μικρασιατική ακτή. Από πολύ νωρίς, τα δύο έπη αναγνωρίστηκαν ως έργα ανυπέρβλητης λογοτεχνικής αξίας και 
	αποτέλεσαν βασικά διδακτικά συγγράμματα καθ’ όλη τη διάρκεια της αρχαιότητας. Στον Όμηρο αποδιδόταν, εσφαλμένα όπως έχει αποδειχθεί, μια σειρά ύμνων που 
	χρονολογούνται από τον 7ο  έως τον 5ο  αι. π.Χ. και αναφέρονται στους θεούς του Ολύμπου. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Κατά τον 7ο αι. π.Χ., Ίωνες επικοί ποιητές προσπάθησαν να συμπληρώσουν τον Τρωικό κύκλο, με έπη που παρέθεταν την ιστορία της Τροίας, από τους γάμους 
	του Πελία και της Θέτιδας έως το θάνατο του Οδυσσέα από τον  γιο του τον Τηλέγονο. Τα έπη αυτά είναι τα Κύπρια έπη (με 11 βιβλία) του Στασινού και του Ηγησία 
	ή Ηγησίνου, η Αιθιοπίδα (με 5 βιβλία) και η Ιλίου Πέρσις (με 2 βιβλία) του Αρκτίνου από τη Μίλητο, η Μικρά Ιλιάδα (με 4 βιβλία) του Λέσχη και η Τηλεγονεία του 
	Ευγάμμωνα από την Κυρήνη.</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Λυρική Ποίηση</Typography>
	
	<img src={Image15}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Στην ιαμβική ποίηση ξεχώρισε ο Ιππώνακτας από την Έφεσο (7ος αι. π.Χ.). Ο ίαμβος είναι σκωπτικό ποίημα που έχει τις ρίζες του στις τελετουργικές 
	βωμολοχίες των εορτών του Διόνυσου και της Δήμητρας. Η ελεγεία ξεκίνησε με τον Καλλίνο από την Έφεσο (β΄ τέταρτο του 7ου αι. π.Χ.). Το είδος αυτό θεωρείται 
	ότι προέρχεται από τον τελετουργικό θρήνο, αλλά κατά την αρχαϊκή περίοδο η θεματολογία του ήταν ποικίλη. Ο Καλλίνος ανήκε στην αριστοκρατία και οι πολιτικές 
	ελεγείες του καλούσαν τους συμπατριώτες  του  στη ύστατη θυσία για την απόκρουση των βάρβαρων επιδρομέων. </Typography>
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο Μίμνερμος από την Κολοφώνα (600 π.Χ. περίπου) έγραψε ελεγείες σε πιο προσωπικό τόνο που στηρίζονταν στο θέμα της αντίθεσης μεταξύ γηρατειών και νιότης, 
	ενώ δεν έλειπαν από το έργο του και μυθολογικά  θέματα.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Ο Ανακρέων από την Τέω (570-485 π.Χ. περίπου)  είναι ο τελευταίος μεγάλος εκπρόσωπος της ελληνικής λυρικής ποίησης. Υπήρξε κατεξοχήν ποιητής του έρωτα 
	και του συμποσίου και αποτέλεσε σύμβολο της τρυφηλής, ανέμελης και ειρηνικής ιωνικής αριστοκρατίας. Λέγεται ότι τα ποιήματά του ξεκινούσαν πάντοτε με μια 
	επίκληση στην Αφροδίτη. Συνόδευε ο ίδιος τις συνθέσεις του με τη μουσική του βαρβίτου (είδος λύρας). Του οποίου θεωρείται ο εφευρέτης της. </Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Ο Αλκμάνας ήταν ένας άλλος λυρικός ποιητής, που διακρίθηκε στη χορική ποίηση, σύμφωνα με την παράδοση καταγόταν από τη Λυδία και οι 
	περισσότεροι ερευνητές σήμερα θεωρούν ότι ήταν Ίωνας.</Typography>
	</Box>
	
	
	 <ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Η Ιωνική φιλοσοφία και η γέννηση της επιστήμης</Typography>
	
	<img src={Image16}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}><strong>Μίλητος</strong> του 6ου αι. π.Χ. είναι ο τόπος όπου γεννήθηκε η φιλοσοφία. Ο Θαλής, ο Αναξαγόρας και ο Αναξιμένης ήταν οι πρώτοι που "απελευθερώθηκαν" 
	από  το μύθο του έπους και ασχολήθηκαν με το πρόβλημα της ουσίας της Φύσης. Το βήμα αυτό είχε τεράστιες συνέπειες για τη μετέπειτα πορεία της ελληνικής και της 
	ευρωπαϊκής σκέψης.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο <strong>Θαλής ο Μιλήσιος</strong> (632-546 π.Χ.) είναι ο αρχαιότερος από τους φιλοσόφους. Ήταν αριστοκρατικής καταγωγής και αυτοδίδακτος. Ταξίδεψε αρκετά, 
	περνώντας μεγάλο διάστημα της ζωής του στην Αίγυπτο, όπου απέκτησε αρκετές από τις γνώσεις τις οποίες αργότερα εισήγαγε στην Ιωνία. Υπολόγισε το ύψος των πυραμίδων, 
	μετρώντας το μήκος της σκιάς τους, διαίρεσε το έτος σε 365 ημέρες, ενώ προέβλεψε την έκλειψη του ηλίου το 585 π.Χ. Δεν σώθηκαν γραπτά κείμενά του. Πίστευε 
	ότι συστατικό υλικό του κόσμου είναι το νερό, ότι η  ψυχή είναι αθάνατη, ενώ υποστήριζε ότι ακόμη και τα αντικείμενα έχουν ψυχή. Ήταν πολίτης με έντονο 
	ενδιαφέρον για τα κοινά και συμμετείχε στις συζητήσεις των Ιώνων σχετικά με την κοινή δράση κατά των Περσών.<br/>
	Ο <strong>Αναξίμανδρος</strong> ήταν  μαθητής του Θαλή. Γεννήθηκε στη Μίλητο περίπου το 610 π.Χ. Έγραψε  το έργο "Περί φύσεως" (550 π.Χ. περίπου), στο οποίο 
	παρουσίαζε την κοσμοθεωρία του και το οποίο περιείχε και τους πρώτους χάρτες και τις πρώτες περιγραφές του ουρανού και της οικουμένης.  Πιστεύεται επίσης ότι 
	εισήγαγε από τη Βαβυλώνα στη Μίλητο τη χρήση του ηλιακού ρολογιού. Συνέλαβε την έννοια της αδιαμόρφωτης μάζας ως αρχής του σύμπαντος και δίδαξε πως αρχή όλων είναι 
	το "άπειρον", από το οποίο γέννιουνται τα πάντα και στο οποίο τελικά επιστρέφουν. Θεωρούσε ότι η μάζα του ΄΄άπειρου΄΄ ήταν αθάνατη, εισάγοντας έτσι την έννοια 
	της αφθαρσίας της ύλης.</Typography>	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο <strong>Ξενοφάνης</strong> (565-470 π.Χ. περίπου) ήταν ένας ανεξάρτητος και τολμηρός στοχαστής, ο οποίος εγκατέλειψε την πατρίδα του την Κολοφώνα το 540 π.Χ. 
	περίπου και εγκαταστάθηκε στην Ελέα της Κάτω Ιταλίας, αποικία των Φωκαέων.<br/>
	Ο Ξενοφάνης συνέθεσε σκωπτικά ποιήματα τα οποία απήγγειλε ο ίδιος. Πρέσβευε, ενάντια στον ανθρωποκεντρικό πολυθεϊσμό των σύγχρονων του, ότι υπάρχει ένας 
	μόνο θεός. Λέγεται ότι, μελετώντας απολιθώματα, είχε ανιχνεύσει την ύπαρξη μιας περιόδου κατακλυσμού της γης.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο <strong>Αναξιμένης</strong> ήταν μαθητής του Αναξίμανδρου. Ο θάνατος του τοποθετείται μεταξύ 528 και 525 π.Χ Θεώρησε ως αρχική ύλη τον αέρα, ο οποίος είναι 
	άπειρος. Ο αέρας στηρίζει το γήινο δίσκο, ενώ ένα τμήμα του αέρα είναι και η ψυχή του ανθρώπου.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο <strong>Ηράκλειτος</strong> (535-475 π.Χ.) γεννήθηκε στην Έφεσο. Καταγόταν από βασιλική γενιά. Αλλά λέγεται ότι παραχώρησε το αξίωμα στον αδελφό του. Ήρθε 
	σε αντίθεση με τους συμπολίτες του και αποσύρθηκε, ακολουθώντας μοναχικό τρόπο ζωής. Σύμφωνα με την κοσμοθεωρία του, βασικό δομικό υλικό του κόσμου ήταν το ζωντανό πυρ, 
	το οποίο εξασφαλίζει τη μεταβλητότητα, την πολλαπλότητα, αλλά και την αιώνια σταθερότητα του κόσμου. Τα πάντα εναλλάσσονται σε σταθερές αναλογίες με το πυρ 
	και εκείνο με τα πάντα, χωρίς να αναλίσκεται ποτέ. Επιπλέον, ο Ηράκλειτος θεωρούσε ότι υπάρχουν ζεύγη αντιθέσεων, μεταξύ των οποίων διεξάγεται αέναος πόλεμος. 
	Ο κόσμος είναι ένα σύστημα αντιμαχόμενων δυνάμεων, αλλά τα αντίθετα αποτελούν μια ενότητα. Αποτελούν δηλαδή τις δύο όψεις του ίδιου νομίσματος. Μια από τις 
	πιο γνωστές θέσεις του Ηράκλειτου είναι ότι ο κόσμος αποτελεί ένα ρεύμα που κινείται αδιάκοπα. Όμως, ενώ τα πάντα μεταβάλλονται, ο κόσμος στο σύνολό του 
	παραμένει σταθερός και αυτό συμβαίνει επειδή η ροή έχει ένα σταθερό μέτρο. Η διδασκα	λία του Ηράκλειτου άσκησε μεγάλη επίδραση στην αρχαία αλλά και στη 
	σύγχρονη φιλοσοφία. Το στρυφνό και δύσκολο ύφος του, αλλά και οι παράδοξες φαινομενικά απόψεις του, προσέδωσαν στον Ηράκλειτο το προσωνύμιο "ο σκοτεινός".</Typography>
	
	<img src={Image17}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο  <strong>Αναξογόρας</strong>  (500-428 π.Χ.) είναι ο τελευταίος φυσικός φιλόσοφος της περιόδου εκείνης. Γεννήθηκε στις Κλαζομενές, αλλά την κύρια φράση 
	του την εντοπίζουμε στα 30 περίπου χρόνια και έζησε και δίδαξε στην Αθήνα. Ανάμεσα στους μαθητές του συγκαταλέγονταν ο Περικλής και Ευριπίδης. Σύμφωνα με τον 
	Αναξαγόρα η ύλη απαρτίζεται από μόρια. Σε κάθε φυσικό σώμα υπάρχουν κάθε είδους μόρια. Το είδος των ομοειδών μορίων που πλειοψηφούν και επικρατούν δίνουν στο σώμα τα 
	χαρακτηριστικά του. Τα πάντα, δηλαδή υπάρχουν στον κόσμο σαν ένα τέλειο μείγμα, στο οποίο έρχεται να βάλει τάξη ο Νους, το μοναδικό ον που δεν είναι 
	αναμειγμένο με τα υπόλοιπα και επομένως μπορεί  να τα ελέγχει και να τα βάζει σε τάξη.</Typography>	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο <strong>Εκαταίος</strong> ο Μιλήσιος ο πρώτος γεωγράφος, ήταν μαθητής του Αναξίμανδρου. Συνέταξε ένα χάρτη της γης με τετραμερή διάταξη (η Μεσόγειος και 
	η Μαύρη Θάλασσα παρουσιάζονταν ως γραμμή Ανατολής – Δύσης, ο Δούναβης και ο Νείλος ως γραμμή Βορρά-Νότου). Ταξίδεψε πολύ και συνέγραψε μια περιγραφή της γης σε 
	δύο βιβλία, υπό τη μορφή ενός περίπλου από τις ακτές της Μεσογείου και της Μαύρης Θάλασσας ως το Γιβραλτάρ, καθώς επίσης έργα γενεαλογίας, τα πρώτα έργα 
	ιστοριογραφίας στην ελληνική γλώσσα.</Typography>	
	
	<img src={Image18}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Πατέρας της ιστορίας βέβαια, θεωρείται ο <strong>Ηρόδοτος</strong>, ο οποίος γεννήθηκε στην Αλικαρνασσό το 485 π.Χ. περίπου και ήταν γόνος αριστοκρατικής 
	οικογένειας. 
	Ο πατέρας του ονομαζόταν Λύξης και η μητέρα του Δρυώ. Το 468 π.Χ. αναγκάστηκε να εγκαταλείψει την πόλη, διωκόμενος από τον τύραννο Λυγδάμη. Θεωρείται ότι 
	ταξίδεψε πολύ, φτάνοντας ως την Αίγυπτο και την Κυρήνη στη βόρεια Αφρική, την Τύρο στη Φοινίκη, στην ενδοχώρα της Ασίας και τη Μαύρη Θάλασσα, στις ελληνικές 
	πόλεις τις Ελλάδας και στου Θούριους της Κάτω Ιταλίας, όπου τελικά εγκαταστάθηκε και πέρασε τα τελευταία χρόνια της ζωής του. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το έργο του έχει τίτλο <strong>"Ηροδότου Ιστορίαι"</strong> και διαιρέθηκε από τους φιλολόγους της Αλεξανδρινής Σχολής σε εννέα βιβλία, καθένα από τα οποία 
	πήρε το όνομα μιας μούσας. Ο ίδιος χώριζε την ιστορία του σε λόγους: ο Λυδικός λόγος, ο Αιγυπτιακός λόγος, ο Περσικός λόγος, κ.λ.π. Το υλικό του το άντλησε 
	από προγενέστερες πηγές, κυρίως από τον Εκαταίο τον Μιλήσιο, έδινε όμως  μεγάλη έμφαση στην αυτοψία και αυτήκοη μαρτυρία. Η αφήγησή του διανθίζεται με 
	πλούσιο εθνογραφικό υλικό, το οποίο συνέλλεξε κατά τη διάρκεια των ταξιδιών του. Για το λόγο αυτό θεωρείται και πατέρας της εθνογραφίας. Το έργο του άσκησε 
	μεγάλη επίδραση στην αρχαιότητα, αν και συχνά κατηγορήθηκε για την αντι-ιστορική του μέθοδο.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο <strong>Έφορος</strong> είναι ο σημαντικότερος, μετά τον Ηρόδοτο, ιστορικός της Μικράς Ασίας. Καταγόταν από την αιολική Κύμη και γεννήθηκε στο α΄ τέταρτο 
	του 4ου αι. π.Χ. Ήταν μαθητής του Ισοκράτη και το κυριότερο έργο του , τα τριάντα βιβλία των Ιστορικών, γράφτηκε μετά το 350 π.Χ., η διήγησή του άρχιζε με 
	την κατάκτηση της Πελοποννήσου από τους Δωριείς και τελείωνε με την αρχή της βασιλείας του Φιλίππου Β΄. Έγραψε δηλαδή την πρώτη "παγκόσμια ιστορία". 
	Θεωρείται συγγραφέας, ο οποίος, χωρίς ιδιαίτερη ιστορική κατανόηση, διασκεύαζε όλες τις πηγές του και τις παραποιούσε άκριτα όπου παρουσίαζαν αντικρουόμενες 
	εκδοχές. Ο Διόδωρος ο Σικελιώτης στην Ιστορία του βασίζει πολλά από τα γεγονότα που αναφέρει στο βιβλίο Ιστορίες του Εφόρου.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Άλλοι ιστορικοί από το χώρο της Μικράς Ασίας ήταν ο <strong>Ξάνθος</strong> εξελληνισμένος Λυδός, ο οποίος συνέγραψε την πρώτη εθνική ιστορία 
	με τίτλο Λυδιακά, ο Χάρων της Λαμψάκου που συνέγραψε την ιστορία των Περσικών Πολέμων και ένα χρονικό της πόλης του, ο Κτησίας από την Κνίδο που έγραψε τα Περσικά 
	και ο Δίων ο Κολοφώνιος που συνέχισε το έργο του Κτησία, για να περιλάβει τη δεκαετία 350-340π.Χ. </Typography>
	</Box>
	
	
	 <ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Η Ιωνική τέχνη</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Η Ιωνική Αγγειογραφία</Typography>
	
	<img src={Image19}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Η <strong>Ιωνική αγγειογραφία</strong> συνέβαλε σημαντικά στη δημιουργία και τον εμπλουτισμό του λεγόμενου ανατολίζοντος Κεραμικού ρυθμού, ο οποίος διαδέχτηκε το 
	Γεωμετρικό και κυριάρχησε στην κυρίως Ελλάδα κατά τον 7ο αι. π.Χ. 
	Ο σημαντικότερος Ιωνικός Κεραμικός ρυθμός είναι ο <strong>Ρυθμός των Αιγάγρων</strong>. Κυριαρχεί στην αγγειογραφία της Μικράς Ασίας από τα μέσα του 7ου έως τα 
	μέσα του 6ου αι. π.Χ. Βασικό στοιχείο της πανίδας του ρυθμού είναι ο αίγαγρος, που απαντάται μεμονωμένος ή σε ζωφόρους. Εικονίζονται επίσης χήνες, ελάφια, 
	ταύροι, πάνθηρες, σκύλοι, λιοντάρια, σφίγγες, γρύπες, λαγοί, αγριόχοιροι, κριοί και αλεπούδες. Συμπληρωματικό ρόλο παίζει και το χελιδόνι. Δημοφιλέστερο σχήμα 
	είναι η οινοχόη με τριφυλλόσχημο στόμιο. Πατρίδα του Ρυθμού των Αιγάγρων ήταν η Μίλητος, ενώ στην ύστερη φάση του ρυθμού, τα κυριότερα εργαστήρια  τοποθετούνται 
	στην περιοχή των Κλαζομενών. Απομιμήσεις βρίσκουμε όμως σε πολλές περιοχές της Μικράς Ασίας και του νησιώτικου ελλαδικού χώρου.</Typography>
	
	<img src={Image20}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Επίγονος του Ρυθμού των Αιγάγρων είναι ο <strong>Ρυθμός των Φικελλούρων</strong>. Παραγόταν στη Μίλητο από το 560 έως το 494 π.Χ. και γνώρισε ευρεία διάδοση 
	στην Καρία, τη Ρόδο, τη Σάμο, τη Μαύρη Θάλασσα και τη Ναύκρατι της Αιγύπτου. Χαρακτηρίζεται από πλούσιο γεωμετρικό και φυτικό διάκοσμο και ευρεία χρήση 
	θεμάτων από τον κόσμο των ζώων : λιοντάρια, πάνθηρες, ταύροι, σκύλοι, αγριόχοιροι, λαγοί, αίγαγροι, ελάφια, σφίγγες, γρύπες, αλλά και σειρές πτηνών, κυρίως 
	πέρδικες και ερωδιοί. Νεωτερισμοί που αποδίδονται στο ρυθμό είναι η χρήση του μισοφέγγαρου και της έλικας στη διακόσμηση, καθώς και η χρήση του ελεύθερου 
	πεδίου. Τα μυθολογικά θέματα είναι ελάχιστα, ενώ συνηθέστερες είναι οι απεικονίσεις σατύρων και κενταύρων. Κυριότερα σχήματα είναι ο αμφορέας, ο αμφορίσκος 
	και η τριφυλλόσχημη οινοχόη</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Στο β’ μισό του 6ου αι. π.Χ. δεσπόζει στη βόρεια Ιωνία ο <strong>Κλαζομενικός Μελανόμορφος ρυθμός</strong>. Τα ευρήματα προέρχονται από τις ίδιες τις Κλαζομενές, 
	στη γειτονική Σμύρνη, τις πόλεις της  Μαύρης Θάλασσας και θέσεις στην Αίγυπτο, κυρίως από το Οχυρό της Δάφνης στο Δέλτα του Νείλου, καθώς και από τη 
	Ναύκρατι. Τα πιο δημοφιλή σχήματα του ρυθμού είναι ο αμφορέας, η υδρία, η πυξίδα, ο κρατήρας, ο ασκός και ο δίνος. Ιδιαίτερα αγαπητός είναι ο κόσμος των πτηνών 
	και των πτηνόμορφων τεράτων. Από τα θέματα με ανθρώπινες μορφές ξεχωρίζουν σκηνές μάχης, κυνηγιού, κωμαστών, ομάδες γυναικών που χορεύουν πιασμένες από τον 
	καρπό και πομπές γυναικών τυλιγμένων στα ιμάτιά τους. Μυθολογικά θέματα συναντάμε συχνά σε αγγεία μεγάλου μεγέθους, όπως υδρίες, αμφορείς και κρατήρες.</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Κλασική Περίοδος</Typography>
	
	<img src={Image21}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο ύστερος 6ος και 5ος αι. π.Χ. αποτέλεσαν περιόδους παρακμής για τη Μικρά Ασία. Καθοριστικοί παράγοντες θεωρούνται η γενικότερη παρακμή του εμπορίου των 
	μικρασιατικών πόλεων μετά την περσική κατάληψη και η συνακόλουθη έξοδος των καλλιτεχνών προς τη Μαύρη Θάλασσα, την Ελλάδα και την Ιταλία, η καταστροφή της 
	Μιλήτου το 494 π.Χ., μετά την ιωνική επανάσταση, αλλά και ο σφιχτός οικονoμικός εναγκαλισμός που επιβάλλει η "υπό την Αθήνα" συμμαχία κατά τη διάρκεια του 
	5ου αι. π.Χ. Κατά την περίοδο αυτή, η περσική παρουσία στη Μικρά Ασία επηρεάζει σε μεγάλο βαθμό και τις τέχνες. Τα σημαντικότερα κέντρα έντονης παρουσίας του 
	περσικού στοιχείου στη περιοχή των παραλίων ήταν το Δασκύλειον στη περιοχή του Ελλήσποντου, όπου άνθησε σχολή παραγωγής γλυπτών έργων που συνδυάζουν ελληνικά 
	και περσικά στοιχεία και κυρίως η Ξάνθος,. Εκεί, κατά τη διάρκεια του 5ου αι. π.Χ., οικοδομήθηκαν περίφημα ταφικά μνημεία, με γλυπτό διάκοσμο που συνδυάζει 
	τις ελληνικές καλλιτεχνικές παραδόσεις με την τυπική ανατολική θεματολογία (κυνήγι εξωτικών ζώων, σκηνές της αυλής των τοπικών ηγεμόνων και σκηνές 
	πολιορκίας πόλεων και μαχών). Η ταφική τέχνη κυριαρχούσε ως έκφραση της δύναμης των ηγεμόνων: εκτός από την Ξάνθο αξιόλογα μνημεία βρίσκουμε στην περιοχή 
	της Κνίδου (απ’ όπου προέρχεται λέοντας που επέστεφε ταφικό μνημείο Πέρση αξιωματούχου), όπως είναι ο ταφικός θάλαμος στο Kizibel της Λυκίας, της Ύστερης 
	Αρχαϊκής περιόδου, με τοιχογραφίες που παρουσιάζουν το νεκρό ως ήρωα συμποσιαστή και πολεμιστή, καθώς και το Ηρώο στην Trusa της Λυκίας, που χρονολογείται 
	στα 390-380 π.Χ. και διακοσμείται με ανάγλυφα που απεικονίζουν πολιορκία πόλης, μάχη δίπλα σε πλοία, την αρπαγή των Λευκιππιδών από τους Διόσκουρους, τον 
	Οδυσσέα να σκοτώνει τους Μνηστήρες, τους Επτά επί Θήβας, Αμαζονομαχία και Κενταυρομαχία.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Κατά τη διάρκεια του 4ου αι. π.Χ. παρουσιάστηκαν κάποια σημάδια ανάκαμψης, αν και το κέντρο βάρους παρέμεινε η αυλική τέχνη των μη Ελλήνων ηγεμόνων, με 
	σημαντικότερο μνημείο το Μαυσωλείο της Αλικαρνασσού και το γλυπτό του  διάκοσμο, ο οποίος πάντως αποδίδεται σε καλλιτέχνες που είχαν έρθει από την Ελλάδα. 
	Στην περίοδο αυτή και κυρίως στο τελευταίο τρίτο του 4ου αι. π.Χ. μετά δηλαδή την απελευθέρωση της Μικράς Ασίας από τον Αλέξανδρο, ξεκίνησε η αναγέννηση 
	της Ιωνίας. Η επανίδρυση της Πριήνης και ανέγερση του νέου Αρτεμίσιου στην Έφεσο είναι ενδείξεις νέας άνθησης των τεχνών. Αξιοσημείωτα έργα της περιόδου 
	αποτελούν το λατρευτικό άγαλμα της ένθρονης Δήμητρας από το Ναό της Κνίδου, που σήμερα βρίσκεται στο Βρετανικό Μουσείο (340 π.Χ.), καθώς και ο γλυπτός 
	διάκοσμος του Αρτεμίσιου, με την ανάγλυφη διακόσμηση του κατώτερου σπονδύλου των κιόνων της πρόσοψης, κατ’ αναλογία με τον αρχαϊκό ναό.</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ελληνιστική Περίοδος</Typography>
	
	<img src={Image22}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Η πραγματική άνθηση της μικρασιατικής τέχνης σηματοδοτήθηκε από την εγκαθίδρυση των <strong>Ελληνιστικών βασιλείων</strong>. Τα μεγάλα οικοδομικά προγράμματα 
	των βασιλέων της Ελληνιστικής  περιόδου οδήγησαν σε νέα ανάπτυξη της γλυπτικής. Σημαντικά έργα είναι η μετόπη από το Ναό της Αθηνάς στο Ίλιον, που χρονολογείται 
	στον πρώιμο 3ο αι. π.Χ. και παρουσιάζει τον θεό Ήλιο στο άρμα του ,τα ανάγλυφα από το Μαυσωλείο στο Belevi, η ζωφόρος από το Ναό του Διόνυσου στην Τέω και 
	τα ανάγλυφα από το Ναό της Εκάτης στα Λάγινα.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Κατά τον 2ο αι. π.Χ. άνθησε η περίφημη Σχολή της Περγάμου, που παρήγαγε αριστουργηματικές μεγαλόπρεπες συνθέσεις μαχών με στόχο να δοξαστεί το μεγαλείο των 
	Ατταλιδών. Εκτός από τα αρχιτεκτονικά γλυπτά και τα μπαρόκ συμπλέγματα, η Σχολή της Περγάμου έχει να επιδείξει πληθώρα αγαλμάτων από τα ιερά της πόλης, 
	μεταξύ των οποίων ξεχωρίζει το άγαλμα της Αθηνάς Παρθένου από τη Βιβλιοθήκη, το κολοσσιαίο άγαλμα του Δία από το Ιερό της Ήρας και το άγαλμα του Ερμαφρόδιτου.</Typography>
	
	<img src={Image23}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Χαρακτηριστικό στοιχείο κάθε ελληνικής πόλης ήταν το θέατρο. Στις πόλεις της Μικράς Ασίας οικοδομήθηκαν κολοσσιαία θέατρα που πολλά από αυτά μετασκευάστηκαν 
	την ρωμαϊκή εποχή και  αποτέλεσαν έναν ιδιαίτερο αρχιτεκτονικό τύπο, το  μικρασιατικό/ρωμαϊκό.  Τα ελληνιστικά θέατρα της Μιλήτου και της Εφέσου, μετασκευασμένα 
	περίπου τον 1ο αι. μ.Χ. σε θέατρα μικρασιατικού/ρωμαϊκού τύπου, είναι σήμερα από τα ωραιότερα θέατρα της Μικράς Ασίας. Το θέατρο της Εφέσου ένα από τα μεγαλύτερα 
	της αρχαιότητας, είχε χωρητικότητα 25.000 θεατές. Παραπάνω το θέατρο της Μιλήτου.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Στις υπόλοιπες περιοχές της Μικράς Ασίας, ξεχωρίζουν έργα από την Πριήνη, την Έφεσο και τη Μίλητο: ολόγλυφες μορφές κυρίως, με έντονη 
	μετωπικότητα και πλούσιες πτυχώσεις κατά την Πρώιμη Ελληνιστική περίοδο (3ος - 2ος αι. π.Χ.) και με τονισμένη τη διάσταση του βάθους κατά την Ύστερη (1ος αι. π.Χ.).
 	</Typography>	
	
	</Box>

    <ScrollToTopButton />
    
    </>
  )
}

export default G3_mikrasiatika