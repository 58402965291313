import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography,List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";


import Image01 from "../assets/images/Hmerologia_p2/Aibali_01.jpg";
import Image02 from "../assets/images/Hmerologia_p2/Aibali_02.jpg";
import Image03 from "../assets/images/Hmerologia_p2/Aibali_03.jpg";
import Image04 from "../assets/images/Hmerologia_p2/Aibali_04.jpg";
import Image05 from "../assets/images/Hmerologia_p2/Aibali_05.jpg";
import Image06 from "../assets/images/Hmerologia_p2/Aibali_06.jpg";
import Image07 from "../assets/images/Hmerologia_p2/Aibali_07.jpg";
import Image08 from "../assets/images/Hmerologia_p2/Aibali_08.jpg";
import Image09 from "../assets/images/Hmerologia_p2/Aibali_09.jpg";
import Image10 from "../assets/images/Hmerologia_p2/Aibali_10.jpg";
import Image11 from "../assets/images/Hmerologia_p2/Aibali_11.jpg";
import Image12 from "../assets/images/Hmerologia_p2/Aibali_12.jpg";
import Image13 from "../assets/images/Hmerologia_p2/Aibali_13.jpg";
import Image14 from "../assets/images/Hmerologia_p2/Aibali_14.jpg";
import Image15 from "../assets/images/Hmerologia_p2/Aibali_15.jpg";
import Image16 from "../assets/images/Hmerologia_p2/Aibali_16.jpg";
import Image17 from "../assets/images/Hmerologia_p2/Aibali_17.jpg";
import Image18 from "../assets/images/Hmerologia_p2/Aibali_18.jpg";
import Image19 from "../assets/images/Hmerologia_p2/Aibali_19.jpg";

function P2_Hmerologia() {

    const theme = useTheme();


  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ημερολόγιο 2005 : Αϊβαλί" />
      </Box>


        
      <Box sx={{ ...commonBoxStyles }}>
      <Typography variant="h1" sx={theme.typography.typography1}>Το Αϊβαλί η Πατρίδα μου</Typography>

	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<Typography variant="h3" sx={theme.typography.typography2}>Aπό δω που κάθουμαι βλέπω βουνά, βλέπω πολιτείες, κάστρα κάμπους, π’ άλλα ζαμάνια ματοχωθήκανε από μοβόρους  παλικαράδες και τώρα  τους αυλακώνουνε τα 
	ξυλάλετρα. Αντίκρυ απ’ το παραθύρι που κάθουμαι φαίνουνται μέσα στο θολό πέλαγο τα βουνά της Τουρκιάς. Σε κείνα τα μέρη γεννήθηκα  κ’ εγώ, κι αν ήτανε κανένας 
	παρών τώρα που κοιτάζω κατά κει, θαν έβλεπε  πως τα μάτια μου είναι δακρυσμένα…..</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Η πολιτεία που λέγω, αναγνώστη πικραμένε, δεν είναι καμιά από κείνες πόχουνε παλιά δόξα κι όνομα ξακουσμένο. Μηδέ Ρώμη είναι, μηδέ Αθήνα, μηδέ Τρωάδα, 
	μηδέ καμιά απ’ τις άλλες ξακουσμένες πολιτείες….</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}> Αν λάχει να περάσεις με καράβι απ’ το μπουγάζι της Μυτιλήνης, θα δεις κατά κει που βγαίνει ο ήλιος κάτι χαμηλά βουνά απάνου στη στεριά της Ανατολής. Κατά 
	τον βοριά στέκει το Καζ Νταγ, το μεγάλο βουνό που το λέει Ίδη ο Όμηρος και πως στην κορφή τουτουνού του βουνού καθόντανε τάχα οι Δώδεκα Θεοί και σεργιανίζανε 
	τον πόλεμο της Τρωάδας. Αν σιμώσεις περσότερο στη στεριά, θέλεις απορέσει πως δε φαίνεται πουθενά η πολιτεία γύρου. Τότε μπαίνεις μέσα σ’ ένα στενό μπάσιμο, το 
	Ταλιάνι λεγόμενο και, σαν τραβήξεις παραμέσα, θα δεις ανέλπιστα ν’ απλώνει μπροστά σου ένας μεγάλος κόρφος ίδιος λίμνη, που δεν τον υπόπτευες πίσ’ απ’ τα βουνά. 
	Μέσα κει θα δεις και την πολιτεία που σου λέγω, σα να’ναι φωλιασμένη, κρυμμένη από κάθε μάτι.</Typography>

	<img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<Typography variant="h3" sx={theme.typography.typography2}> Ποιος ξέρει βάσιμα πότε τη χτίσανε! Λένε πως τη χτίσανε κατά το 1600. Και πως κείνοι που τη χτίσανε, πήγανε και τρυπώσανε μέσα σε κείνο το θαλάμι, για να 
	ξεφύγουνε απ’ τους κουρσάρους, που κάνανε θρήνος όξου στο πέλαγο. Ως τα 1770 αυτοί οι άνθρωποι, όπως ούλοι οι Ρωμιοί της Τουρκιάς, ζούσανε κρυφά απ’ τον Θεό. 
	Τότες φανερώθηκε ένας παπάς τετραπέρατος και με τόση πιτυχία έβγαλε πέρα το ό,τι καταπιάστηκε, που φαίνεται πως ήταν σταλμένος απ’ τον Θεό. Τον λέγανε παπά-Γιάννη 
	Οικονόμο….<br/>
	Παραπάνω είπα πως το Αϊβαλί ξακούστηκε ύστερ’ από το 1770.<br/>
	Κείνον τον χρόνο πιάστηκε σε μεγάλη ναυμαχία ο Τούρκος με τον Ρούσο μέσ’ στα νερά του Τσεσμέ…<br/>
	Σαν σκόρπισε κακή-κακώς η τούρκικη αρμάδα, ένας απ’ τους ναυάρχους, ο Χασάν-πασάς, που του ’δωσε αργότερα ο σουλτάνος την επωνυμία Γαζής, δηλαδή Νικητής, 
	γλύτωσε απ’ το Χάρο παρά τρίχα. Τράβηξε τότες να πάει στην Πόλη από στεριά, επειδή η θάλασσα ήτανε πιασμένη απ’ τον οχτρό, κ’ έλαχε να  κονέψει ένα βράδυ 
	στην πολιτεία του Οικονόμου σε κακό χάλι. Κι ο παπάς τον συμμάζεψε σπίτι του και τον ζωογόνησε, τόσο που, μισεύοντας ο Τούρκος πήρε όρκο πως δε θα ξέχανε 
	ποτές την καλοσύνη που ΄χε δει απ’ τον Οικονόμο.
	Έλα-έλα, σαν περάσανε ένα-δυό χρόνια, μαθεύτηκε πως  ο Χασάν-πασάς ο Γαζής θέριεψε κ’ έγινε παντοδύναμος συμβουλάτορας του σουλτάνου και πως κείνος ήτανε το 
	δεσμείν και λύειν στην Πόλη. Σαν έφταξε στ’ αυτί του Οικονόμου η τέτοια είδηση, σηκώθηκε δίχως να χασομερήσει και πήγε στην Πόλη….
</Typography>

<img src={Image03}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	<Typography variant="h3" sx={theme.typography.typography2}> Και πηγαίνοντας στο παλάτι, τον υποδέχτηκε ο Χασάν-πασάς με μεγάλη εγκαρδιότητα και τον ρώτηξε τι χάρη ήθελε απ’ αυτόν. Τότες ο Οικονόμος ξετύλιξε
	το χαρτί, για να διαβάσει ο βεζίρης το τι αγαπούσε. Και κείνος, σαν άνθρωπος που κρατά το τάξιμο που βγαίνει από το στόμα του, έκανε ριτζά στο σουλτάνο να μη φύγει 
	μ’ αδειανά τα χέρια ο παπάς, ξιστορώντας του το πώς τον είχε συμμαζέψει γυμνόν και ξετραχηλισμένον στο σπίτι του. Και, στ’ αλήθεια, η Πόρτα έβγαλε φετβά που 
	διαλάβαινε τα προνόμια που δινόντανε στην πολιτεία του Οικονόμου:	</Typography>

    <Typography variant="h3" sx={theme.typography.typography2}>
	<List>
		<ListItem>Όσοι Τούρκοι έχουνε σπίτι μέσα στην πολιτεία, να φύγουνε παρευθύς μαζί με τις φαμελιές τους στα τουρκοχώρια που ’ναι ολοτρόγυρα. Και στο εξής κανένας 
		Οθωμανός δεν έχει την άδεια ν’ ανοίξει σπίτι και τζάκι μέσα στη χώρα. Και Τούρκος καβαλάρης να μη ζυγώνει στο έμπα της πολιτείας, παρά όσο ακούγεται πετεινός.
		Κι αν κανένας επίσημος του στρατού τύχει να ’μπει καβάλα μέσα στη χώρα, να βγάζει τα πέταλα του χαϊβανιού του.</ListItem>
		<ListItem>Η πολιτεία λογαριάζεται στο εξής ανεξάρτητη από τον ντερέμπεη που θα διορίζεται στα πέριξ</ListItem>
		<ListItem>Η κυβέρνηση, η δικαιοσύνη και τα κουμέρκια δίνουνται στους ντόπιους χριστιανούς, οι οποίοι έχουνε χρέος να πληρώνουνε σαρανταοχτώ χιλιάδες γρόσια κάθε χρόνο.</ListItem>
		<ListItem>Καϊμακάμης, αγάς για βοεβόντας θα στέλνεται απ’ την Πόλη, μα θα διορίζεται κείνος που θέλουνε διαλέξει οι χριστιανοί, κι απ’ τους χριστιανούς θα πληρώνεται 
		και πάλε απ’ τους χριστιανούς θαν αλλάζεται, όποτε κρίνεται πρέπον. </ListItem>
		<ListItem>Καδής θα στέλνεται απ’ την Πόλη ή απ’ αλλού, μα θε να’ ναι και τούτος μισθωτός των χριστιανών.</ListItem>
		<ListItem>Φρούραρχος δεν επιτρέπεται να καθήσει μέσα στη χώρα, μηδέ να περάσει από μέσα.</ListItem>
		<ListItem>Η πολιτεία στο εξής δεν θα δίνει δέκατο όπως πριν, αμή ο κάθε νοικοκύρης έχει χρέος να πληρώνει δυο παράδες για κάθε λιόδεντρο……</ListItem>
		<ListItem>Κ’ η χώρα έφταξε με τον καιρό σε μεγάλη ακμή και σε πολύ πληθυσμό, γιατί οι κατατρεγμένοι χριστιανοί από τα νησιά κι απ’ τη Ρούμελη κι απ’ το Μοριά βρίσκανε 
		κει καταφύγιο κ’ ησυχία….</ListItem>	
	</List>
    </Typography>

	<Typography variant="h3" sx={theme.typography.typography2}> Από  το βιβλίο του Φώτη Κόντογλου "Το Αϊβαλί η Πατρίδα μου".</Typography>
	</Box>
	


    <ScrollToTopButton />


	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Φωτογραφικό Υλικό</Typography>
    <img src={Image04}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image05}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image06}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image07}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image08}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image09}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image10}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image11}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image12}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image13}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image14}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image15}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image16}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image17}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image18}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image19}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		
    </Box>

    <ScrollToTopButton />



    </>
  );
}

export default P2_Hmerologia;
