import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g6/image001.jpg";
import Image02 from "../assets/images/mikrasiatika_g6/image002.jpg";
import Image03 from "../assets/images/mikrasiatika_g6/image003.jpg";
import Image04 from "../assets/images/mikrasiatika_g6/image004.jpg";
import Image05 from "../assets/images/mikrasiatika_g6/image005.jpg";
import Image06 from "../assets/images/mikrasiatika_g6/image006.jpg";
import Image07 from "../assets/images/mikrasiatika_g6/image007.jpg";
import Image08 from "../assets/images/mikrasiatika_g6/image008.jpg";
import Image09 from "../assets/images/mikrasiatika_g6/image009.jpg";
import Image10 from "../assets/images/mikrasiatika_g6/image010.jpg";
import Image11 from "../assets/images/mikrasiatika_g6/image011.jpg";
import Image12 from "../assets/images/mikrasiatika_g6/image012.jpg";


function G6_mikrasiatika() {

    const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Τα σχολεία της Σμύρνης - Νικολάου Παπαχρυσού" />
      </Box>

      
	<Box sx={{ ...commonBoxStyles }}>
	
	
	<Typography sx={{color:'red', fontSize:'18px', fontWeight:'bold'}}>
		Εν τη Ιωνία παρήχθη το αξιοθαυμαστότερον των πνευματικών προϊόντων πάντων των αιώνων, το έπος, όπερ ευθύς εξ’ αρχής ήνθησεν εν όλω αυτού τω ποιητικώ αρώματι,
		κτίσμα εσαεί σύμπαντος του πεπολιτισμένου κόσμου και ούτινος το απαράμιλλον μεγαλείον, εις τους μετέπειτα αιώνας, μέχρι σήμερον, παραμένει αμείωτον.
	</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Τα είδη των Μικρασιάτικων σχολείων</Typography>
	
	
	<img src={Image12}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η εκπαίδευση των Μικρασιατών ξεκίνησε από τους νάρθηκες των εκκλησιών και των μοναστηριών, γαλουχήθηκε στα χέρια απλοϊκών αλλά ακαταπόνητων ιερωμένων κι 
	αυτοδίδακτων λαϊκών, ανδρώθηκε στους περιβόλους των ενοριακών ναών, όπου στεγαζόταν τα σχολεία, κι έγινε θεσμός, που αναζωπύρωσε το φρόνημα και τόνωσε την εθνική 
	συνείδηση των σκλάβων με τη δραστηριότητα των κατά τόπους μητροπολιτών, του Οικουμενικού Πατριαρχείου, των κοινοτικών αρχών και της ιδιωτικής πρωτοβουλίας.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Βάση της εκπαίδευσης ήταν το <b>κοινοτικό σχολείο,</b> που αποτελούσε εξέλιξη των πρώτων σχολείων, των γνωστών παιδαγωγείων ή γραμματοδιδασκαλείων, όπου δίδασκάν 
	αυτοσχέδιοι συνήθως δάσκαλοι, οι γραμματιστές. Τα γραμματοδιδασκαλεία διατηρήθηκαν σ’ απομονωμένες περιοχές μέχρι το 1922! Παράλληλα με το κοινοτικό υπάρχει και 
	το ιδιωτικό που γνώρισε ιδιαίτερη άνθηση στη Σμύρνη. Τα κοινοτικά σχολεία διακρίνονται στα απλά κοινοτικά σχολεία και τις Κεντρικές σχολές. Η Κεντρική όμως σχολή 
	είναι συνήθως σχολείο της δευτεροβάθμιας εκπαίδευσης</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το κοινοτικό σχολείο της πρωτοβάθμιας εκπαίδευσης παρουσιάζει τις μορφές: <b>Αρρεναγωγείο, Παρθεναγωγείο, Νηπιαγωγείο</b>. Στη Μ. Ασία, μόνον όταν η κοινότητα ήταν 
	πολύ μικρή ή τα οικονομικά της περιορισμένα, υπήρχαν μικτά δημοτικά σχολεία. Προϋπήρξαν βέβαια τα αρρεναγωγεία, που ήταν εξέλιξη των γραμματοδιδασκαλείων, κι 
	ακολούθησαν τα παρθεναγωγεία αργά αλλά σταθερά, για να γενικευτούν σ’ όλες τις κοινότητες της Μ. Ασίας.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Σχεδόν παράλληλα με την καθιέρωση του παρθεναγωγείου δημιουργείται και ο θεσμός του νηπιαγωγείου. Τα έτη φοίτησης στα σχολεία αυτά δεν ήταν προκαθορισμένα, αλλά 
	ποίκιλαν ανάλογα με τα οικονομικά της κοινότητας και την πολιτιστική στάθμη των κατοίκων.
    Αρχικά στα κοινά δημοτικά σχολεία εισάγεται η <b>αλληλοδιδακτική μέθοδος</b> από τον Φιλιππουπολίτη Γεώργιο Κλεόβουτο το 1819, που γενικεύεται βαθμηδόν σ’ όλη τη Μ. Ασία 
	για περισσότερο από 50 χρόνια. Τα αλληλοδιδακτικά σχολεία επιβλήθηκαν για λόγους οικονομικούς, αφού στις δύο πρώτες τάξεις δίδασκαν οι μεγαλύτεροι μαθητές, αλλά και 
	γιατί ανταποκρινόταν στις παιδαγωγικές αντιλήψεις της εποχής.</Typography>
	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Παράλληλα με την αλληλοδιδακτική μέθοδο χρησιμοποιήθηκε κι η <b>συνδιδακτική μέθοδος</b>, που αποσκοπούσε στη δημιουργία μικτών σχολείων, αλλά απ’ ότι γνωρίζουμε η 
	μέθοδος αυτή χρησιμοποιήθηκε κυρίως στα σχολεία της Σμύρνης.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Οι απόφοιτοι των αλληλοδιδακτικών σχολείων μπορούσαν να συνεχίσουν τη φοίτησή τους στα <b>ελληνικά σχολεία</b>, που αντιστοιχούσαν με τα παλαιότερα δικά μας σχολαρχεία.
	Κάθε ελληνική κοινότητα ανάλογα με τα οικονομικά της και τον αριθμό των μαθητών της είχε κοντά στο δημοτικό της σχολείο (αλληλοδιδακτικό ή όχι) ολόκληρο 
	ελληνικό σχολείο (τριετές) ή μία ή δύο τάξεις του προσαρτημένες στο δημοτικό. Ωστόσο οι δάσκαλοι των ελληνικών σχολείων είχαν ανώτερη μόρφωση από τους αλληλοδιδάκτες,
	 που συνήθως μόλις είχαν τελειώσει τη σειρά των ελληνικών μαθημάτων.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Τα αλληλοδιδακτικά σχολεία καταργούμενα σιγά – σιγά αντικαθίσταντο από τις αστικές σχολές, που συνήθως ήταν επτατάξιες. Τα επτατάξια αυτά δημοτικά σχολεία 
	αντιστοιχούσαν με τα εξατάξια της ελεύθερης Ελλάδας κι έλαβαν την αρχή τους από το παράδειγμα της Κωνσταντινούπολης, όπου για πρώτη φορά εφαρμόστηκαν, σύμφωνα 
	με τα ευρωπαϊκά πρότυπα.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Οι αστικές σχολές της Μ. Ασίας δεν είναι αυτό, που ονομάζουμε εμείς δημοτικό σχολείο, αλλά ένας αυτοτελής παιδευτικός κύκλος μαθημάτων, που αποσκοπεί στην 
	παροχή ωφέλιμων γνώσεων, την κατάρτιση γραμματισμένων ανθρώπων των κοινοτήτων για τη διοίκησή τους (δημογερόντων, εφόρων, επιτρόπων, γραμματέων Μητροπόλεων) και 
	τη δημιουργία ικανών προσώπων για το μικρεμπόριο, για την καλύτερη καλλιέργεια των κτημάτων και την αποδοτικότερη εξάσκηση των λοιπών πρακτικών επαγγελμάτων.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Οι αστικές σχολές ήταν κυρίως αρρεναγωγεία και μόνο, όταν δεν υπήρχε στην κοινότητα παρθεναγωγείο φοιτούσαν και κορίτσια σ’ αυτές. Κανόνας στην Μικρασιατική 
	εκπαίδευση ήταν ο χωρισμός της εκπαίδευσης των αγοριών από τα κορίτσια. Έτσι παράλληλα με τα δημοτικά σχολεία ή αλληλοδιδακτικά ή αστικές σχολές ή αρρεναγωγεία 
	υπήρχαν και τα <b>παρθεναγωγεία,</b> που σαν θεσμός εμφανίζεται μετά την ίδρυση των αρρεναγωγείων, στεγάζονται σε δευτερότερα κτίρια, η φοίτηση σ’ αυτά είναι μικρότερη 
	κατά 1-3 χρόνια και σε οικονομική κρίση της κοινότητας συγχωνεύονται με τα αρρεναγωγεία ή συνυπάρχουν με άλλο τύπο σχολείων της Μ. Ασίας, τα νηπιαγωγεία, σαν 
	νηπιοπαρθεναγωγεία.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Σκοπός των παρθεναγωγείων ήταν η εκμάθηση της ελληνικής γλώσσας από τα κορίτσια για την πληρέστερη ένταξή τους στην εθνική κοινότητα του μικρασιατικού ελληνισμού,
	η ανάγκη της γνωριμίας ενός γλωσσικού οργάνου για την αλληλογραφία με τους μακριά από την πατρίδα ξενιτεμένους συνήθως συζύγους τους, η δυνατότητα να παρακολουθήσουν
	και να βοηθούν τη μόρφωση και την αγωγή των παιδιών τους αργότερα, η μετάδοση γνώσεων υγιεινής, καθαριότητας, μαγειρικής, ραπτικής, κοπτικής, κεντημάτων κλπ. Ήδη
	πριν από το 1856 ο θεσμός των παρθεναγωγείων ήταν γνωστός σ’ όλη τη Μ. Ασία.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Μαζί με τα αρρεναγωγεία και τα παρθεναγωγεία γενικευμένος ήταν στο μικρασιατικό χώρο ο θεσμός των <b>νηπιαγωγείων</b>. Τα νηπιαγωγεία στη Μ. Ασία είχαν ιδιαίτερο σκοπό,
	διάφορο από των δικών μας νηπιαγωγείων. Τα νήπια συμπληρώνοντας το τέταρτο έτος τους μέχρι το έκτο ή από το πέμπτο, όπου αυτά ήταν πολλά και η νηπιαγωγός μία, 
	φοιτούσαν στο νηπιαγωγείο, για να ακούσουν και να μαθαίνουν τα ελληνικά, που είχαν χάσει οι τουρκόφωνοι γονείς τους.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Πριν να φύγουμε από την πρωτοβάθμια εκπαίδευση πρέπει να αναφέρουμε και την ύπαρξη <b>πρότυπων δημοτικών σχολείων.</b></Typography>
	<img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Στις έδρες των μητροπόλεων, στις μεγάλες πόλεις, σε φημισμένα μοναστήρια ή στο κέντρο μεγάλων αγροτικών συνοικισμών είχαν ιδρυθεί <b>Κεντρικές σχολές ή 
	κοινοβιακά σχολεία</b> αρρένων ή θηλέων με οικοτροφεία για τα παιδιά, που έρχονταν από μακριά. Οι Κεντρικές σχολές είχαν ολόκληρο ελληνικό σχολείο και τάξεις ή και
	πλήρες γυμνάσιο.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Μετά τον κύκλο των μαθημάτων των ελληνικών σχολείων ή των ολοκληρωμένων αστικών σχολών υπήρχε το <b>Γυμνάσιο</b>, όπου δίδασκαν πτυχιούχοι του πανεπιστημίου Αθηνών ή 
	ευρωπαϊκών πανεπιστημίων ή απόφοιτοι ιερατικών σχολών.<br/>
    Προτού να ιδρυθεί ένα γυμνάσιο δημιουργούνταν τάξεις γυμνασιακές στα ελληνικά σχολεία ή στις αστικές σχολές. Όταν συμπλήρωναν τις τρεις πρώτες γυμνασιακές τάξεις, 
	σχηματιζόταν το <b>ημιγυμνάσιο</b>.
    Έπειτα από το γυμνάσιο υπήρχαν στη Μ. Ασία Ιερατικές Σχολές, που ιδρύθηκαν για να μορφώνουν ιερείς και δασκάλους για τις ελληνικές κοινότητες όλης της Ανατολής.
    Κοντά στα ημερήσια σχολεία δημιουργήθηκαν σε πολλά μέρη της Μ. Ασίας και <b>νυχτερινές σχολές</b> της πρωτοβάθμιας εκπαίδευσης.
    Σχετικά με την επαγγελματική αποκατάσταση των νέων είχαν ιδρυθεί και λειτουργούσαν στη Μ. Ασία πλήθος από <b>επαγγελματικά σχολεία.</b>
    Πέρα από τις διάφορες μορφές και βαθμίδες του κοινοτικού σχολείου που αναφέραμε, δεν έλειψε από το χώρο της μικρασιατικής εκπαίδευσης κι η ιδιωτική πρωτοβουλία.
    Τελειώνοντας την αναφορά αυτή για τα είδη των Μικρασιατικών σχολείων δεν πρέπει να παραλείψουμε και τα <b>Ορφανοτροφεία,</b> που είχαν ιδρυθεί σε πόλεις, επαρχιακά 
	κέντρα και μοναστήρια της Μ. Ασίας. Μέσα στα ορφανοτροφεία αυτά λειτουργούσαν δημοτικά σχολεία για την παροχή στοιχειωδών γνώσεων στα ορφανά.</Typography>
	</Box>
	
	 <ScrollToTopButton />
	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ευαγγελική Σχολή</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ιστορία</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Σχολή μέσης εκπαίδευσης που λειτουργούσε στη Σμύρνη από το 1717. Αρχικά έφερε διάφορες ονομασίες, όπως «Μεγάλο Σχολείον», «Ελληνικό Σχολείον», «Ελληνομουσείον»
	κλπ. Χάρη στην οικονομική ενίσχυση των ομογενών, η σχολή αναπτύχθηκε αλματωδώς, ενώ με τη μεσολάβηση της αγγλικής κυβέρνησης αναγνωρίστηκε επίσημα από τις τούρκικες
	αρχές. Από το 1808 ονομάστηκε «Ευαγγελική σχολή». Στις αρχές του 19ου αιώνα οι προοδευτικοί κύκλοι της Σμύρνης, αντιδρώντας στην μονομερώς κλασσική μόρφωση που 
	παρείχε στους μαθητές της, ίδρυσαν το «Φιλολογικόν Γυμνάσιον», στο οποίο διδάσκονταν και οι θετικές επιστήμες και επικράτησαν νεωτεριστικές τάσεις. Η παρέμβαση όμως
	του Οικουμενικού Πατριαρχείου υπέρ της Ευαγγελικής σχολής οδήγησε στη διάλυση του Φιλολογικού γυμνασίου. Με την έναρξη της Ελληνικής επανάστασης η Ευαγγελική σχολή 
	έκλεισε, αλλά από το 1824 άρχισε να λειτουργεί ξανά με ανανεωμένο πρόγραμμα, στο οποίο περιλαμβανόταν πλέον και οι θετικές επιστήμες. Μέσα από διαρκείς κλυδωνισμούς, 
	οφειλόμενους σε διαμάχες μεταξύ του προσωπικού, της θρησκευτικής ηγεσίας και των εφόρων, η σχολή κατόρθωσε να ανανεωθεί, να αυξήσει την ακτινοβολία της και να 
	προσλάβει τον χαρακτήρα ανώτερου εκπαιδευτηρίου. Έτσι η Ευαγγελική σχολή που από τα μέσα του 19ου αιώνα διέθετε πλήρες γυμνάσιο, δύο ελληνικά σχολεία και πέντε 
	δημοτικά, αναγνωρίστηκε από το 1862 από την Ελληνική κυβέρνηση και οι απόφοιτοί της απέκτησαν το δικαίωμα να εγγράφονται χωρίς εξετάσεις στο Πανεπιστήμιο Αθηνών. 
	Σταθμό στην ιστορία της Ευαγγελικής σχολής αποτέλεσε το αναλυτικό πρόγραμμα του 1875, που εισήγαγε την νέα ελληνική γλώσσα στις πέντε πρώτες τάξεις του δημοτικού 
	και καθιέρωσε στο γυμνάσιο την αντιπαραβολή των αρχαίων τύπων με τύπους της καθομιλουμένης. Το 1892 ιδρύθηκε ανεξάρτητο εμπορικό τμήμα που λειτούργησε ως το 1922 
	και εκπαίδευσε πολυάριθμους εμπόρους και τραπεζικούς υπάλληλους.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η Ευαγγελική σχολή περιλάμβανε βιβλιοθήκη με 50.000 τόμους και 180 σπάνια χειρόγραφα, καθώς και αρχαιολογικό μουσείο με πολύτιμα αντικείμενα και 15.000 νομίσματα.
	Τη διαχείριση της περιουσίας και την φροντίδα για τον διορισμό καθηγητών καθώς και τη συντήρηση του διδακτηρίου της σχολής είχε δωδεκαμελής εφορία, που την 
	αποτελούσαν επίλεκτα μέλη της σμυρναϊκής κοινωνίας. Η Ευαγγελική σχολή έπαψε να λειτουργεί με την είσοδο των Τούρκων στη Σμύρνη, το 1922. Τότε καταστράφηκαν και 
	τα περισσότερα κτίρια της σχολής.</Typography>
	
	<img src={Image03}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h1" sx={theme.typography.typography1}>Κτίριο</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η Ευαγγελική Σχολή βρισκόταν ανάμεσα στους ναούς της Αγίας Φωτεινής και του Αγίου Γεωργίου και παρείχε την απαιτούμενη ασφάλεια. Η Ευαγγελική σχολή με τις δύο 
	σιδερένιες θύρες που φύλαγαν προβλεπτικά απ’ την κακία ώρα του γιουρουσιού, με τους φρουριακούς θόλους της, ήταν το ασφαλέστερο κτίριο της Σμύρνης.
    Το κτίριο της Σχολής πολλές φορές υπέστη βλάβες και ζημιές αλλά πάντοτε το ξανάφτιαχναν ή το επιδιόρθωναν. Κατά την πυρκαγιά του 1842 καταστράφηκε το μεγαλύτερο
	μέρος της Ευαγγελικής Σχολής, διασώθηκαν μόνο το μαγαζί, η βιβλιοθήκη και το αλληλοδιδακτικό σχολείο της.
    Ο Μαρτζέλλας στον χώρο της αποτεφρωθείσας πτέρυγας και της κατεδαφισθείσης απ’ αυτόν πτέρυγας καθώς και της συνεχόμενης ιδιοκτήτου οικίας και οικοπέδου ανήγειρε 
	νέο ευρύχωρο οικοδόμημα για το οποίο δαπάνησε 700.000 περίπου γρόσια. Το νέο οικοδόμημα λειτούργησε το καλοκαίρι του 1882, η δε Εφορεία με το ακόλουθο από 4 Μαΐου 
	1882 ψήφισμα της ανακήρυξε τον Ιω. Μαρτζελλα μέγα ευεργέτη της Ευαγγελικής Σχολής.</Typography>
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Έως τη Μικρασιατική Καταστροφή το κτίριο της Ευαγγελικής Σχολής εκτός από ελάχιστες βελτιώσεις, παρέμεινε αμετάβλητο. Πάνω από την είσοδο της αίθουσας των 
	τελετών υπήρχε με μεγάλα γράμματα η ρήσης του Σολομώντα «Αρχή σοφίας φόβος Κυρίου». Στον ανατολικό τοίχο της αίθουσας έκαιγε ακοίμητο καντήλι, από την ίδρυση της 
	Σχολής, μπροστά σε εικόνισμα του Χριστού που βρισκόταν σε κοιλότητα του τοίχου. Η βιβλιοθήκη της Ευαγγελικής Σχολής ήταν μεγάλη και έφτανε έως την οδό του Αγίου 
	Γεωργίου. Η Ευαγγελική Σχολή διατηρούσε επίσης και τέλειο γυμναστήριο.
    Με τη συνεχή αύξηση του ελληνικού πληθυσμού της Σμύρνης και συνεπώς της μαθητευόμενης νεολαίας, το κτίριο της Ευαγγελικής Σχολής δεν επαρκεί πλέον. Έτσι αποφασίζεται 
	η ανέγερση νέου μεγαλύτερου και επιβλητικότερου κτιρίου, αντάξιου προς την μακραιώνια ιστορία της Σχολής.
    Στις 21 Δεκεμβρίου 1909 γίνεται συγκινητική και επιβλητική τελετή καταθέσεως του θεμέλιου λίθου της Ευαγγελικής Σχολής σε οικόπεδο πρώην ιδιοκτησίας Κιουπετζόγλου, 
	στη συνοικία Αγίας Αικατερίνης, πλησίον της Λυπημένης Παναγιάς.
    Η ανοικοδόμηση του κτιρίου καθ’ όλα συγχρονισμένου και επιβλητικού, με μεγάλο περίβολο, τέλειωσε το καλοκαίρι του 1922 και επρόκειτο να λειτουργήσει αυτό το έτος. 
	Μοίρα όμως κακή… Κάτω από τους θόλους του νέου μεγάρου της Ευαγγελικής Σχολής, που ανεγέρθηκε με τόσες θυσίες, με τόσους μόχθους, με τόσες ελπίδες και όνειρα, δεν 
	επέπρωτο να αντηχήσει  η ελληνική φωνή…</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>ΕΞΑΡΤΗΜΑΤΑ ΤΗΣ ΕΥΑΓΓΕΛΙΚΗΣ ΣΧΟΛΗΣ</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η Ευαγγελική Σχολή, εκτός από το κύριο μεγάλο κτίριό της που βρισκόταν παράπλευρα στον μητροπολιτικό μέγαρο, είχε τα ακόλουθα παραρτήματα ή εξαρτήματα, όπως 
	τα ονόμαζαν: α) του Αγίου Ιωάννου του Θεολόγου στον Επάνω Μαχαλά, β) την Κιουπετζόγλειο Σχολή Αγίας Αικατερίνης στη συνοικία Μπογιατζίδικα, γ) την Αλληλοδιδακτική 
	Σχολή Σχοινάδικων (Αγίου Ιωάννου Προδρόμου), δ) τη Σχολή Φασούλα στη συνοικία Φασούλα και ε) το Σοφείον στην οδό Ρόδων. 
    Η Ευαγγελική Σχολή διατηρούσε παλαιότερα (1836 – 1843) και νηπιακό καθώς και αλληλοδιδακτικό σχολείο (1847 – 1847).</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>ΚΑΝΟΝΙΣΜΟΙ ΕΥΑΓΓΕΛΙΚΗΣ ΣΧΟΛΗΣ</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο κανονισμός, «διοργανισμός» όπως τον αποκάλεσαν, συντάχθηκε μόνο στις 17 Σεπτεμβρίου του 1838, από τον τότε «αρχιδιδάσκαλο» της Σχολής Νεοκλή Παπάζογλου. 
	Σ’ αυτήν αναγράφονται μεταξύ των άλλων τα ακόλουθα:</Typography>
	
	<img src={Image04}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>«Η Ευαγγελική Σχολή έχει Εφόρους επτά, διδάσκαλους έξι, ο ένας των οποίων είναι και διευθυντής, και μαθητών αριθμό απροσδιόριστο. Γνωρίζει δύο κύρια καθήκοντα 
	και χρέη εσωτερικά: τη διδασκαλία των φιλολογικών και επιστημονικών γνώσεων και την ηθική μόρφωση της νεολαίας δια της ακριβούς τηρήσεως των πατρικών δογμάτων. 
	Θέλει και διδάσκοντας και διδασκόμενους άμεμπτους, τους μεν διδάσκοντας να φέρονται προς τους μαθητάς ως προς τέλειους άνδρας, με σεμνότητα και με ιλαρότητα, 
	χωρίς να σκυθρωπάζωσι τυραννικώς ή να προσοικειόνωνται μικροπρεπώς, τους δε διδασκόμενους όλους ισότιμους ως υιούς μίας και της αυτής οικογενείας, κοσμίους, 
	εύτακτους, ευσχήμονας, ευγενικούς και επιμελείς». </Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>ΚΑΘΗΓΗΤΕΣ ΚΑΙ ΔΙΔΑΣΚΑΛΟΙ ΤΗΣ ΕΥΑΓΓΕΛΙΚΗΣ ΣΧΟΛΗΣ</Typography>
	<img src={Image05}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Θεμέλια της εκπαιδεύσεως είναι αναμφισβήτητα οι καλοί διδάσκαλοι. Είναι αυτοί που εργάζονται ώρες ολόκληρες για να μεταλαμπαδεύσουν το φως της μαθήσεως. Αυτοί 
	που αγωνίζονται αέναα να διαπλάσουν το πνεύμα, να διαμορφώσουν τους χαρακτήρες, να δημιουργήσουν χρηστούς πολίτες και μαζί με τα γράμματα, να εμπνεύσουν και να 
	μεταδώσουν ότι το ευγενικό και ωραίο.
    Οι διδάσκαλοι στη Σμύρνη εργάζονταν μ’ ένθερμο ζήλο, μ’ επιμονή και με πίστη, με ειλικρινή αφοσίωση όχι μόνο για να δώσουν ζωή στις άψυχες του χαρτιού διδασκαλίες, 
	αλλά και να φυτεύσουν στις ψυχές των Ελληνοπαίδων τα άνθη της παιδείας, τα άνθη που ανέδιναν το άρωμα της χριστιανικής πίστης και των ιδανικών του Γένους.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Οι διδάσκαλοι της Σμύρνης ήταν ταπεινοί και αφανείς εργάτες φυτωρίων της ελληνικής ζωής στο σκληρό έδαφος της δουλείας. Ήταν οι αγνοί ιεροφάντες και άοκνοι 
	μυσταγωγοί, που λειτουργούσαν στα ελληνικά σχολεία – τους ναούς της Γνώσεως και του Εθνισμού.
    Οι διδάσκαλοι της Ευαγγελικής Σχολής, που παρήλασαν κατά τους δύο αιώνες της ζωής της, υπήρξαν όλοι αφοσιωμένοι λειτουργοί της παιδείας και άξιοι της μεγάλης και 
	υψηλής αποστολής τους.
    Πρώτοι διδάσκαλοι της Ευαγγελικής Σχολής ήταν ο Αδαμάντιος Ρύσιος, ο Ιερόθεος Δενδρινός, ο Χρύσανθος Καραβίας. Μαζί με το Χρύσανθο δίδαξε και ο Αγάπιος και 
	αργότερα ο Γαβριήλ από τα Βρύουλλα.
    Στις παραδόσεις της Ευαγγελικής Σχολής επικρατούσε απόλυτη σιωπή και απόλυτη προσήλωση των μαθητών προς τον διδάσκοντα καθηγητή. Συχνά κατά τη διάρκεια των 
	παραδόσεων προσέρχονταν και ο Μητροπολίτης και έφοροι της Σχολής για να παρακολουθήσουν την διδασκαλία και την πρόοδο των μαθητών και για να τους εμπνεύσουν το 
	αίσθημα της αυστηρής επαγρύπνησης.
    Η εξέταση των μαθητών γινόταν με κλήρο. Ο καθηγητής κλήρωνε τρεις μαθητές από τον κατάλογο και εξέταζε καθένα τους επί 8 – 10 λεπτά. Μετά την εξέταση της πρώτης 
	τριάδας ακολουθούσε δεύτερη. Το τελευταίο δεκαήμερο του μήνα καλούνταν προς εξέταση οι μη κληρωθέντες υπόλοιποι μαθητές.
    Οι ώρες διδασκαλίας ήταν έξη έως επτά κάθε μέρα (8 – 12 π.μ. και 2 – 4 ή 5 μ.μ.). Το Σάββατο περιοριζόταν σε πέντε ώρες (8π.μ. – 1μ.μ.). Το διάλειμμα, μεταξύ των 
	μαθημάτων, ήταν οκτάλεπτο και γινόταν μέσα στην Ευαγγελική Σχολή. Απαγορευόταν αυστηρά η έξοδος από το κτίριο.</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>ΜΑΘΗΤΕΣ ΚΑΙ ΑΠΟΦΟΙΤΟΙ ΤΗΣ ΕΥΑΓΓΕΛΙΚΗΣ ΣΧΟΛΗΣ</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Κατά τους δύο πρώτους αιώνες που λειτούργησε η Ευαγγελική Σχολή, χιλιάδες νέοι εκπαιδεύθηκαν. Οι μαθητές που εισέρχονταν σ’ αυτή αντιλαμβάνονταν πως 
	βρισκόταν σε τόπο ιερό, σε αληθινό τέμενος της Παιδείας, όπου σφυρηλατούνταν χαρακτήρες, πνεύμα, πίστη προς τη θρησκεία και τα εθνικά ιδεώδη. Γι’ αυτό οι 
	απόφοιτοι εξέρχονταν άρτια μορφωμένοι και με υψηλό θρησκευτικό και εθνικό φρόνημα. Μαθητολόγια άρχισαν να τηρούνται από το 1862. Προηγουμένως οι διευθυντές της 
	Σχολής έκαναν καταγραφές των μαθητών, αλλά κανένα σχετικό έγγραφο δεν διασώθηκε.</Typography>	
	
	</Box>
	
	 <ScrollToTopButton />	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Το κεντρικόν Παρθεναγωγείον</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ιστορία</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Η παιδεία των Ελληνίδων, γενικά σ’ ολόκληρη την Ελλάδα, κατά τα χρόνια της δουλείας, ήταν σχεδόν ανύπαρκτη. Στη Σμύρνη ιδίως που η σκλαβιά ήταν αισθητότερη, 
	η μόρφωση των Ελληνίδων ήταν δικαιολογημένα ελλιπέστατη. 
    Ως τις αρχές του 19ου αιώνα, στη Σμύρνη η παρουσία γραμματισμένης γυναίκας θεωρούνταν κάτι το σπάνιο. Γιατί δεν ήταν μόνο οι κίνδυνοι που διέτρεχαν τα κορίτσια 
	κατά την έξοδό τους, αλλά και τα κρατούντα τότε ήθη και έθιμα. Επικρατούσε δηλαδή η αντίληψη, πως τα γράμματα «εξέτρεπον» τη γυναίκα, όχι μόνο από τον κύριο προορισμό 
	της, τις οικιακές ασχολίες, αλλά διευκόλυναν και την αισθηματική αλληλογραφία. Έτσι ως τα 1830 «κορασιακά» σχολεία ή «θηλεοκομεία», όπως αποκαλούσαν παλιότερα τα 
	παρθεναγωγεία, δεν υπήρχαν στην Σμύρνη.
    Πρώτο ελληνικό σχολείο για κορίτσια βρίσκουμε να λειτουργεί στα 1834. Στα 1835 με πρωτοβουλία του ιεροκήρυκα Βενεδίκτου Κωνσταντινίδη και τη χρηματική βοήθεια του 
	τότε μητροπολίτη Σμύρνης Χρύσανθου και του επισκόπου Ανθίμου, ιδρύεται το σχολείο που ο ιδρυτής του το ονομάζει «Εκπαιδευτικόν Κατάστημα των κορασίων». Ανώτερη 
	παιδεία δεν υφίσταται την εποχή αυτή.
    Στα 1840 ο ναός της Αγίας Φωτεινής αναλαμβάνει με δαπάνη του, να ιδρύσει κατάλληλο σχολείο θηλέων. Για το σκοπό αυτό ανεγείρει εντός του αυλόγυρου λιθόκτιστο 
	κτίριο μ’ ευρύχωρη αίθουσα για το αλληλοδιδακτικό και δύο δωμάτια «διά τάς τέχνας».</Typography>
	<img src={Image06}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η λειτουργία του σχολείου που τιτλοφορείται «Σχολείον της Αγίας Φωτεινής», αρχίζει από το Σεπτέμβριο του 1841. Στα 1850 εκτός από το σχολείο της Αγίας Φωτεινής, 
	που αριθμεί 300 μαθήτριες ιδρύεται και άλλο δημόσιο σχολείο του Αγίου Ιωάννου του Θεολόγου στον Επάνω Μαχαλά με 110 μαθήτριες. Επίσης εξακολουθεί να λειτουργεί το 
	σχολείο εντός του περιβόλου του Γραικικού νοσοκομείου με 200 μαθήτριες. Εν τω μεταξύ ιδρύονται και ιδιωτικά παρθεναγωγεία.
    Στα 1857 δίδονται τα πρώτα πτυχία στις μαθήτριες του προπαιδευτικού. Οι πρώτες πτυχιούχες ήταν: Ιουδήθ Βλέσσα, Καλλιόπη Γιαλούση, Ανθίπη Λαμπρινούδη, Αμαλία 
	Σκαραμαγκά και Ευθαλία Στάνου. Το ίδιο έτος καλείται να αναλάβει τη διεύθυνση του Παρθεναγωγείου της Αγίας Φωτεινής η διακεκριμένη παιδαγωγός και ποιήτρια Σαπφώ 
	Λεοντιάς.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Επί Λεοντιάδος συντάσσεται ο πρώτος κανονισμός του Παρθεναγωγείου της Αγίας Φωτεινής. «Σκοπός του Παρθεναγωγείου είναι η κατωτέρα και η όσον οίον τε ανωτέρα 
	εκπαίδευσις των κορασίων. Η εν τω παρθεναγωγείο διδασκαλία από της προβιβάσεως της μαθήτριας εκ του αλληλοδιδακτικού εις το ελληνικόν, διαρκεί έξη έτη και 
	περιλαμβάνει την διδασκαλία της ελληνικής γλώσσας, τα θρησκευτικά μαθήματα, την ηθική, τα μαθηματικά, την γενική ιστορία, την γεωγραφείαν, την καλλιγραφείαν, 
	ελληνικήν και γαλλικήν, και τα χειροτεχνήματα. Αι μαθήτριαι οφείλουσι να 	έρχονται εις την Σχολήν εκτενισμέναι επιμελώς μεν, αλλά κοσμίως, καθάριοι τας χείρας 
	και το πρόσωπον, ενδεδυμέναι ωσαύτως καθαρίως και μετά της προσηκούσης εις τα κοράσια σεμνοπρεπείας και απλότητος. Οφείλουσιν έτι εισερχόμεναι εις την Σχολήν να 
	προσαγορεύωσιν ευπρεπώς, κατά τον ορισμένον τρόπον, τους διδασκάλους και τας συμμαθήτριάς των. Ομοίως οφείλουσι να περιπατώσι καθ’ οδόν ευσχημόνως και κοσμίως, 
	χωρίς να βλέπωσιν εδώ και εκεί περιέργως ή να γελώσι θορυβωδώς και ασέμνως, ή να ομιλώσιν αλλήλας, εάν συναντήσωσιν, ή αν συναντήσωσιν άλλα κοράσια, ή να φωνάζωσι 
	και να φέρονται αφρόνως και απρεπώς. Επίσης εις ουδεμίαν μαθήτρια συγχωρείται να λέγει εν τη Σχολή ό,τι είδεν ή ήκουσεν, ή έπραξεν εν τη οικία αυτής, ή αλλαχού, ή 
	να αναφέρει τα ονόματα του μεν και του Δε, είτε συγγενούς, είτε ξένου. Είναι: νουθεσία, επίπληξης, «κακοσημεία», που κατεχωρείτο στο καθημερινό βιβλίο, αντιγραφή 
	«άπαξ, ή δις ή τρις κλπ κατά την βαρύτητα του σφάλματος, δωρισμένου τεμαχίου βιβλίου». Εάν οι μαθήτριαι «εμμένουν εις την αφιλοτιμίαν, επιβολή τιμωρίας δια στάσεως 
	εν καιρώ των παραδόσεων», νηστεία και συγκράτησης για ορισμένον χρόνον. Τα ονόματα των τιμωρημένων αναγράφονταν τρεις φορές σε πίνακα με μαύρο πλαίσιο, που 
	αποκαλούνταν «πίνακας αισχύνης». Εάν και οι τιμωρίες αυτές δεν συνέτιζαν τις παρεκτρεπόμενες, τότε η Εφορεία ελάμβανε «δραστηριώτερα μέτρα». Στις διδασκάλισσες 
	απαγορευόταν αυστηρότατα «η βάναυσος χρήσης ραπίσματος ή ξυλισμού ή ύβρεων ή λόγων προσβλητικών καθ’ οποίας δήποτε μαθήτριας, οίον δήποτε σφάλμα ποιήσασης».</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Αυτά είναι τα βασικότερα στοιχεία του κανονισμού που συνέταξε η Σαπφώ Λεοντιάς, της οποίας η συγγραφική εργασία είναι αξιόλογη και αξιομνημόνευτη.
    Τον Αύγουστο του 1877 η Σαπφώ Λεοντιάς καλείται στην Κων/πολη και αναλαμβάνει την διεύθυνση του εκεί παρθεναγωγείου «Παλλάς». Παρά την στέρηση των πολύτιμων 
	υπηρεσιών της, το παρθεναγωγείο της Αγίας Φωτεινής συνεχίζει τη δημιουργική μορφωτική του πορεία με καθηγητή τον ιστορικό Παύλο Καρολίδη.
    Στα 1880 τη διεύθυνση του Παρθεναγωγείου αναλαμβάνει η Ζαφειρώ Σπετσιώτου. Επί Σπετσιώτου συντάσσεται ο δεύτερος κανονισμός. Τον Ιούνιο του 1883 πεθαίνει η 
	Σπετσιώτου. Ο θάνατός της προκάλεσε βαθύτατη λύπη στους Σμυρναίους και η κηδεία της έγινε επιβλητική.
    Μετά τον θάνατο της Σπετσιώτου τη διεύθυνση αναλαμβάνει επί τριετία η Σαπφώ Χριστοφόρου Λαιλίου, το γένος Αθανάσιου Τζαννή, που είχε σπουδάσει με δαπάνη της 
	κοινότητας Μυτιλήνης στην Αθήνα.
    Στα 1886 καλείται και αναλαμβάνει και πάλι τη διεύθυνση του παρθεναγωγείου η Σαπφώ Λεοντιάς και το διευθύνει έως τον Ιούλιο του 1891, οπότε «η σεβαστή ιέρεια 
	του τεμένους των Μουσών» αποχωρεί οριστικά, για να ιδιωτεύσει στην Κων/πολη.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Μετά την αποχώρηση της Λεοντιάδος ανέλαβε την διεύθυνση  του Κεντρικού παρθεναγωγείου η Καρολίνα Σμόλενιτζ, «γυνή μορφώσεως εγκυκλοπαιδικής όχι συνήθους, 
	διαπρέπουσα ιδίως εις τα μαθηματικά και την γαλλικήν φιλολογία. Στα 1894 πέθανε ξαφνικά η Σμόλενιτζ και το επόμενο έτος, τα δύο κοινοτικά σώματα της Σμύρνης, 
	υπό την προεδρία του Μητροπολίτη Σμύρνης Βασίλειου, εκλέγουν ως διευθύντρια του Παρθεναγωγείου την μέχρι τότε υποδιευθύντρια του «Κιουπετζογλείου» Σμύρνης Ουρανία 
	Δούκα. Το κεντρικό Παρθεναγωγείο το διεύθυνε επί τριακονταετία περίπου μέχρι της Μικρασιατικής Καταστροφής. Υπήρξε εκλεκτή παιδαγωγός και η φήμη της πέρασε τα
	όρια της Σμύρνης. Στα 1904 της δόθηκε από το Εκπαιδευτικό Συνέδριο των Αθηνών τιμητικό δίπλωμα και ο Πατριάρχης Ιεροσολύμων της απένειμε το Χρυσούν Σταυρόν του
	Παναγίου Τάφου. Η Δούκα πέθανε στην Αθήνα το Δεκέμβριο του 1941. Ο Δήμος Νέας Σμύρνης, τιμώντας τη μνήμη της έδωσε σ’ ένα δρόμο το όνομά της.</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>ΣΤΕΓΑΣΗ</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το Παρθεναγωγείο αρχικά στεγάστηκε σε οίκημα που είχε ανεγείρει στον περίβολό του ο ναός της Αγίας Φωτεινής, ο οποίος και το συντηρούσε. Με τη συνεχή όμως 
	αύξηση των μαθητριών το οίκημα αυτό κρίνετε ανεπαρκές και οι Σμυρναίοι επιδιώκουν τη μεταφορά του σε κτίριο ευρύχωρο και κατάλληλο. Στην κίνηση αυτή πρωτοστατεί η 
	διευθύντριά του Σαπφώ Λεοντιάς. Με εράνους και δωρεές επιτυγχάνεται τελικά η αγορά ευρύχωρου οικήματος στην οδό Ρόδων 46 όπου μεταφέρεται το παρθεναγωγείο της Αγίας 
	Φωτεινής, στις 22 Σεπτεμβρίου 1878.
    Μετά μιας οκταετίας το κτίριο της οδού Ρόδων κρίνεται και αυτό ανεπαρκές. Επιζητείται η ανέγερση μεγάλου οικοδομήματος στο κέντρο της πόλης όπου θα στέγαζε 
	οριστικά το Παρθεναγωγείο.
    Με το συγκεντρωθέν από τους εράνους ποσό αγοράζεται στην οδό Μεϊμάρογλου μεγάλο γήπεδο, όπου ανεγείρεται ισόγειο κτίριο, σε δύο πτέρυγες με περιστύλιο.
    Και το κτίριο όμως αυτό, μετά εικοσαετίας, δεν επαρκεί να στεγάσει το δημοτικό σχολείο, το γυμνάσιο και το διδασκαλείο. Αποφασίζεται στα 1908 ν’ ανεγερθεί νέο 
	μεγάλο κτίριο, πάνω στο ίδιο γήπεδο, αντάξιο προς τον Ελληνισμό της Σμύρνης</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>ΔΟΜΗ</Typography>
	<img src={Image07}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Το Κεντρικό Παρθεναγωγείο, έως τα 1898, ήταν εξατάξιο δημοτικό και τετρατάξιο γυμνασιακό. Από το 1898 προστέθηκε και πέμπτη γυμνασιακή τάξη, όπου διδασκόταν 
	παιδαγωγική. Με πλήρη οργάνωση το Παρθεναγωγείο είχε: α) νηπιαγωγείο, β) πρότυπο εξατάξιο δημοτικό, γ) αστική διτάξια σχολή, πλήρες τριτάξιο διδασκαλίο. Για όσες 
	μαθήτριες θα επιθυμούσαν να ακολουθήσουν πανεπιστημιακές σπουδές, το Παρθεναγωγείο είχε πλήρες τετρατάξιο γυμνάσιο. Είχε επίσης τριτάξιο εμπορικό τμήμα και τέλος 
	διτάξιο διδασκαλίο νηπιαγωγών. Είχε ακόμη και ειδικό τμήμα χειροτεχνίας. Στο διδασκαλείο, στο κλασσικό γυμνάσιο και στο εμπορικό τμήμα φοιτούσαν όσες μαθήτριες 
	είχαν αποφοιτήσει από την αστική σχολή. Από το διδασκαλείο έβγαιναν άριστα καταρτισμένες δασκάλες που αμέσως αναλάμβαναν υπηρεσία σε σχολεία του Εσωτερικού. Στο 
	διδασκαλίο διδασκόταν και το μάθημα της γυμναστικής, που έδινε ειδικό πτυχίο γυμναστικής.
     Με την από 22 Σεπτεμβρίου 1900 πράξη του Υπουργού Εκκλησιαστικών και Δημοσίας Εκπαίδευσης, το Κεντρικό Παρθεναγωγείο αναγνωρίστηκε ισότιμο και ισόβαθμο με το 
	 Αρσάκειο Αθηνών.</Typography>
	 
	 <Typography variant="h1" sx={theme.typography.typography1}>ΠΑΡΑΡΤΗΜΑΤΑ</Typography>
	 
	 <Typography variant="h3" sx={theme.typography.typography2}>Το Κεντρικό Παρθεναγωγείο είχε επτά παραρτήματα που λειτουργούσαν, τα περισσότερα, παράπλευρα σε ναούς διαφόρων συνοικιών της Σμύρνης. Τα παραρτήματα του 
	 Παρθεναγωγείου ήταν: α) της Αγίας Αικατερίνης, β) της Μεταμορφώσεως, γ) του Αγίου Τρύφωνος, δ) την Κιουπετζόγλειο Σχολή, ε) την Κωλέττειο Σχολή, στ) την Ακασόγλειο 
	 Σχολή και ζ) του Αγίου Πνεύματος.</Typography>
	 
	 <Typography variant="h1" sx={theme.typography.typography1}>ΑΠΟΦΟΙΤΟΙ</Typography>
	 
	 <Typography variant="h3" sx={theme.typography.typography2}>Από το Κεντρικό Παρθεναγωγείο αποφοίτησαν άρτια καταρτισμένες Ελληνίδες, που πολλές απ’ αυτές διωρίσθηκαν ως δασκάλες και διέπρεψαν στη λογοτεχνία. Μεταξύ 
	 των τελευταίων αναφέρουμε τις ποιήτριες Όλγα και Φιλή Βατίδου, Σίτσα Καραϊσκάκη, Ζωή Αναστασιάδη – Λάσκαρη, Άννα Βιλιάδου – Κιούση κ.ά. Επίσης αναφέρουμε τις 
	 καθηγήτριες Όλγα Κακριδή – Κομνηνού και Ευδοκία Ραΐση.
     Στο Κεντρικό Παρθεναγωγείο και στα παραρτήματά του φοιτούσαν κάθε χρόνο 3000 περίπου μαθήτριες. Η πρώτη απόφοιτος του Πανεπιστημίου Αθηνών, στα 1899, ήταν η 
	 Μικρασιάτης Ι. Βλαστού.</Typography>
	 
	 <Typography variant="h1" sx={theme.typography.typography1}>ΕΠΙΛΟΓΟΣ</Typography>
	 
	 <Typography variant="h3" sx={theme.typography.typography2}>Το Κεντρικό Παρθεναγωγείο μαζί με την Ευαγγελική Σχολή, υπήρξαν όπως έγραψε ο λόγιος Φ. Βουτσινάς «οι δύο γαλακτώδεις πνευματικοί μαστοί, των οποίων το ζ
	 ωογόνον γάλα έθετε η Σμύρνη κατ’ έτος εις την διάθεσην της μάθησης της νεολαίας». 
     Το κτίριο της Κεντρικού Παρθεναγωγείου σώθηκε κατά την Μικρασιατική Καταστροφή και χρησιμοποιήτε από τους Τούρκους ως σχολείο αρρένων, με την ονομασία «Λύκειο 
	 Ατατούρκ». Η μεγάλη του αίθουσα «Σα ναός αρχαίος με το απλό ιωνικό περιστύλιο, στέκεται ακόμα ανέπαφη. Αδειανή της εκκλησούλας η κώχη. Θαρρείς πως κάτι 
	 προσμένει…».</Typography>
	</Box>
	
	
	  <ScrollToTopButton />	
	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Το Ομήρειον Παρθεναγωγείον</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Από τα μέσα του περασμένου αιώνα Γάλλοι και Γερμανοί ίδρυσαν στη Σμύρνη άρτια οργανωμένα Παρθεναγωγεία που προσείλκυαν πολλές Ελληνίδες μαθήτριες. Αιτία 
	της προτιμήσεως αυτής ήταν η ελλιπής διδασκαλία των ξένων γλωσσών και των τεχνικών μαθημάτων στο Κεντρικό Παρθεναγωγείο και η έλλειψη οικοτροφείου, που εμπόδιζε 
	τη φοίτηση των Ελληνίδων του Εσωτερικού της Σμύρνης. Αυτές ακριβώς τις ελλείψεις και τα μειονεκτήματα των ελληνικών Παρθεναγωγείων επωφελήθηκαν στη Σμύρνη τα ξένα 
	που εκτός των ξένων γλωσσών, δίδασκαν μουσική, ζωγραφική και χορό – εφόδια απαραίτητα μιας συγχρονισμένης κοσμοπολίτικης ζωής. Η συρροή των Ελληνίδων στα ξένα 
	σχολεία ήταν τόση, ώστε κατά τα έτη 1875-1880 να φτάσει τα 80% του συνόλου των μαθητριών, με καταβολή 8.000 χρυσών λιρών ετησίως για τα δίδακτρα.</Typography>
	
	<img src={Image08}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Ο σκοπός όμως των ξένων παρθεναγωγείων ήταν κυρίως προσηλυτιστικός. Τα ξένα εκπαιδευτήρια είχαν μετατραπεί σε κέντρα θρησκευτικής και πολιτικής προπαγάνδας και 
	εργάζονταν προς όφελος της Γαλλίας ή της Γερμανίας, με απώτερο σκοπό την πνευματική και ψυχική κατάκτηση των αλλογενών και ετερόδοξων κατοίκων της Σμύρνης. Τα 
	κυριότερα μαθήματα, που ιδιαίτερα διδάσκονταν σ’ αυτά, ήταν η ιστορία και η γεωγραφία. Με έξαρση τονιζόταν το μεγαλείο της Γαλλίας ή της Γερμανίας καθώς και το 
	«αληθές και αλάθητον» του θρησκευτικού δόγματος των Καθολικών ή Διαμαρτυρομένων. Αντίθετα με πολλή περιφρόνηση γινόταν λόγος για την Ορθοδοξία και την Ελλάδα, κι 
	επαναλαμβανόταν μέχρι κόρου η γνωστή θεωρία του Φαλλμεράϋερ πως η φυλή των αρχαίων Ελλήνων εντελώς χάθηκε και πως ούτε σταγόνα γνησίου και αμιγούς ελληνικού αίματος 
	δεν ρέει στις φλέβες των σύγχρονων Ελλήνων.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Πολλοί Σμυρναίοι Ορθόδοξοι που αντιλήφθηκαν τους προπαγανδιστικούς σκοπούς των ξένων παρθεναγωγείων, άρχισαν να αντιδρούν. Ο ελληνικός τύπος της Σμύρνης άρχισε 
	δριμύτατη σταυροφορία εναντίον των «επιβουλευόμενων την ιεράν ημών πίστιν και τα πάτρια» και επισήμανε τους κινδύνους που διέτρεχε ο Ελληνισμός της Ιωνίας.
    Προσπάθειες έγιναν τότε από τους Έλληνες της Σμύρνης να ιδρυθούν ελληνικά παρθεναγωγεία εφάμιλλα στην εμφάνιση και τη λειτουργία των ξένων εκπαιδευτηρίων. Όλες όμως 
	οι προσπάθειες ναυαγούσαν για την έλλειψη οικονομικών πόρων και τη δυσκολία στην απόκτηση άδειας για τη λειτουργία από το Τουρκικό Υπουργείο Παιδείας, κατόπιν 
	αντιδραστικών εισηγήσεων από τις γαλλικές και γερμανικές προξενικές αρχές της Σμύρνης. Στα 1879 καταβάλλεται άλλη προσπάθεια: να συγχρονιστεί το Κεντρικό 
	Παρθεναγωγείο και να αποκτήσει οικοτροφείο. Αλλά και η προσπάθεια αυτή αποτυγχάνει και η προπαγάνδα των ξένων εκπαιδευτηρίων συνεχίζεται και εντείνεται.</Typography>
	
	<img src={Image09}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Παράλληλα συστήθηκε με την πρωτοβουλία του Παύλου Αθηνογένη «Φιλεκπαιδευτική Εταιρία», με σκοπό την σύσταση ελληνικού παρθεναγωγείου με οικοτροφείο. Η 
	Φιλεκπαιδευτική Εταιρία αναγνωρίζεται από το Υπουργείο εξωτερικών της Ελλάδας και επιτυγχάνει την έκδοση φιρμανιού λειτουργίας του νέου ελληνικού παρθεναγωγείου. 
	Για την πραγμάτωση αυτού του σκοπού η «Φιλεκπαιδευτική Εταιρία» εκδίδει μετοχές και σ’ ελάχιστο χρόνο συγκεντρώνεται το ποσό των 20.000 χρυσών λυρών. Αγοράζει τότε 
	πέντε οικήματα στην οδό Μανίφικου στις Χουρμαδιές. Τα οικήματα αυτά διασκευάζονται και εξοπλίζονται με τον πλέον σύγχρονο σχολικό υλικό. Τα εγκαίνια του νέου 
	«Παρθεναγωγείου μετ’ Οικοτροφείου» έγιναν τον Σεπτέμβριο του 1881.
    Κατά τον κανονισμό του παρθεναγωγείου η Σχολή απέβλεπε «εις την ψυχικήν διάπλασιν και την προς το αγαθόν αγωγήν των μαθητριών». Το Παρθεναγωγείο είχε τρία τμήματα: 
	α) νηπιαγωγείο, β) σχολείο, γ) γυμνάσιο. Γινόταν σ’ αυτό δεκτές «πλην των ορθοδόξων, και μαθήτριαι ανήκουσαι εις παν έτερον ανεγνωρισμένον θρήσκευμα, μη ούσαι αι 
	τελευταίαι υπόχρεοι να ακολουθώσι τα εις τας ορθοδόξους διδασκόμενα μαθήματα».
    Η Εφορεία του Παρθεναγωγείου μετακάλεσε διακεκριμένους καθηγητές και διδασκάλους από την Αγγλία, Γαλλία και Γερμανία για να διδάξουν τις ξένες γλώσσες και να 
	εφαρμόσουν σύγχρονες εκπαιδευτικές μεθόδους. Τέσσερα έτη από την ίδρυση του, το «Ελληνικό Παρθεναγωγείο μετ’ Οικοτροφείου» αναγνωρίζεται από το Υπουργείο Δημόσιας 
	Εκπαίδευσης της Ελλάδας ισότιμο και ισόβαθμο με το Αρσάκειο Αθηνών.
    Το παρθεναγωγείο περιλάμβανε δημοτική και γυμνασιακή εκπαίδευση καθώς και διδασκαλείο, που οι απόφοιτες του διοριζόταν δασκάλες σε σχολεία του Εσωτερικού της Σμύρνης. 
	Τον Οκτώβριο του 1886, ύστερα από εισήγηση του Παύλου Αθηνογένη προς την εφορεία του Παρθεναγωγείου, μετονομάζεται σε «Ομήρειον», προς τιμή του «αθάνατου Σμυρναίου 
	ραψωδού».</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το Νοέμβριο του 1886 πυρκαγιά εξαφάνισε ολόκληρο το Ομήρειο. Ευθύς αμέσως στο γήπεδό του και σε παραπλεύρως αγορασμένο γήπεδο συνολικής εκτάσεως 5.100 τ.π., 
	αναγείρεται νέο σχολικό κτίριο σε σχέδιο που φιλοτέχνησε ο Σμυρναίος αρχιτέκτων Ξενοφών Λάτρης. Το νέο Ομήρειο αποτελεί την «τελευταία λέξη» της αρχιτεκτονικής της 
	εποχής εκείνης. Είναι καλλιμάρμαρο με ευρύχωρες και ηλιόλουστες αίθουσες παραδόσεων, με μεγάλη αίθουσα τελετών, όπου υπάρχουν οι προτομές του Ομήρου, Σωκράτη, Πλάτωνα 
	και Ρήγα Φεραίου. Στην αίθουσα συνεδριάσεων είναι αναρτημένες οι προσωπογραφίες των ιδρυτών του και οι προτομές  των Παύλου Αθηνογένους και Διονυσίου Μαρκόπουλου. Έχει 
	επίσης μεγάλους και υγιεινούς κοιτώνες οικοτροφείου, πλούσια βιβλιοθήκη με 10.000 περίπου τόμους, υπόστεγο για γυμναστική και ανθόκηπο με μαρμαρόγλυφο πίδακα.
    Μετά την εγκατάσταση του Ομηρείου στη νέα του οικοδομή καμία σχεδόν Ελληνίδα μαθήτρια της Σμύρνης δεν φοιτά στα ξένα εκπαιδευτήρια. Αντίθετα πολλές Αρμενίδες και 
	Ισραηλίτιδες σπεύδουν να εγγραφούν και να μορφωθούν στο Ομήρειο, με τη βεβαιότητα πως δεν θα γίνουν θύματα προσηλυτισμού, όπως συνέβαινε στα ξένα της Σμύρνης 
	εκπαιδευτήρια.
    Το Ομήρειον σε έμψυχο και σε άψυχο υλικό είναι εφάμιλλο προς τα καλύτερα και τελειότερα Παρθεναγωγεία της Ευρώπης. Περιλαμβάνει τώρα πεντατάξιο γυμνάσιο και 
	διδασκαλείο. Επίσης δεχόταν δωρεάν μαθήτριες που ξεχώριζαν για την επιμέλειά τους, και από άλλα παρθεναγωγεία. Στο Ομήρειο γίνονται δεκτές οικότροφες, ημιοικότροφες κ
	αι εξωτερικές μαθήτριες που πλήρωναν δίδακτρα.
    Το Ομήρειον ήταν ίδρυμα εθνικό και όχι ιδιωτικό. Το διοικούσε δεκαμελές ισόβιο συμβούλιο, που αρχικά είχε αποτελεστεί από τους ιδρυτές του και αργότερα από τους 
	διαδόχους που οι ίδιοι υποδείκνυαν. Το συμβούλιο λογοδοτούσε κάθε χρόνο στο μητροπολίτη Σμύρνης και στο γενικό πρόξενο της Ελλάδος.
    Διευθύντρια του Ομηρείου διετέλεσε, από τη σύστασή του έως τη Μικρασιατική Καταστροφή, η Ελένη Λουΐζου. Επί σαράντα και πλέον έτι υπήρξε πραγματική ιέρεια της 
	παιδείας στο «εργαστήριο αυτό της μορφώσεως». Πέθανε στην Αθήνα στα 1941.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο ετήσιος προϋπολογισμός του ανερχόταν σε 10.000 χρυσές λίρες και καλυπτόταν από τα δίδακτρα και τα τροφεία. Κατά την Μικρασιατική Καταστροφή η αξία του ακινήτου 
	του και των εγκαταστάσεών του περνούσε τις 50.000 χρυσές λίρες.
    Με την απελευθέρωση της Σμύρνης από τον ελληνικό στρατό επιτάχθει το κτίριο του Ομηρείου και εγκαταστάθηκαν για μικρό χρονικό διάστημα Έλληνες στρατιώτες.
    Το Ομήρειον το «εγκαλλώπισμα του οφθαλμού της Μικρασίας», μαζί με την ελληνική Σμύρνη μεταβλήθηκε τον Αύγουστο του 1922 σε στάχτη. 
    Το Ομήρειον και το Κεντρικό Παρθεναγωγείο υπήρξαν τα δύο περίλαμπρα σμυρναίικα εκπαιδευτήρια, οι δύο ακτινοβόλοι αστέρες , που οδηγούσαν προς την πνευματική φάτνη 
	τις κόρες της Ιωνίας.
    Αν όμως το Ομήρειο της Σμύρνης καταστράφηκε κι’ αυτό από τις φλόγες του Αυγούστου του 1922, η ψυχή του ζει κάτω από τον Ιερό Βράχο. Οι απόφοιτές του σύστησαν στα 
	1950 «σύλλογο αποφοίτων Ομηρειάδων», που εργάζεται μέχρι σήμερα πατριωτικά, πνευματικά και αγαθόεργα.</Typography>
	</Box>
	
	 <ScrollToTopButton />	
	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ενοριακές Σχολές</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Παράλληλα προς τα «εξαρτήματα» (δημοτικά σχολεία των ανώτερων σμυρναϊκών εκπαιδευτηρίων), Ευαγγελικής σχολής και Κεντρικού Παρθεναγωγείου, λειτουργούσαν σε 
	κάθε ενορία της Σμύρνης κοινοτικά κατώτερα σχολεία αρρένων και θηλέων ή και μεικτά. Όλα σχεδόν τα ενοριακά σχολεία στεγαζόταν στους περιβόλους ή παράπλευρα των ναών.
	Την διοίκηση των σχολείων κάθε ενορίας είχε κατά τον κανονισμό της Κοινότητος Σμύρνης, εφορεία από μέλη της Εφοροεπιτροπής ναών και σχολείων.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Τα ενοριακά σχολεία εντός της πόλεως της Σμύρνης ήταν, κατά τον χρόνο της Μικρασιατικής καταστροφής τα ακόλουθα:</Typography>
	
	<ul>
		<li>Αγίας Αικατερίνης</li>
		<li>Αγίας Άννης</li>
		<li>Αγίου Βουκόλου</li>
		<li>Αγίου Γεωργίου</li>
		<li>Αγίου Δημητρίου</li>
		<li>Δαραγατσίου</li>
		<li>Ευαγγελίστριας</li>
		<li>Αγίου Ιωάννου Θεολόγου</li>
		<li>Αγίου Ιωάννου (Λιγαριάς)</li>
		<li>Αγίου Κωνσταντίνου</li>
		<li>Αγίας Κυριακής</li>
		<li>Αγίας Μαρκέλλας</li>
		<li>Μεταμορφώσεως (Μορτάκια)</li>
		<li>Νεκροταφείου</li>
		<li>Αγίου Νικολάου (Τσαϊρλί Μπαξέ)</li>
		<li>Τιμίου Προδρόμου</li>
		<li>Αγίου Τρύφωνος</li>	
	</ul>
	<Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
	</Box>
	
	
	 <ScrollToTopButton />	
	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ορφανοτροφείο – Λαϊκό κέντρο – Ευσέβεια</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Σχολεία στη Σμύρνη διατηρούσαν επίσης το Ορφανοτροφείο, το «Λαϊκό Κέντρο» και η «Ευσέβεια».</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ορφανοτροφείο Σμύρνης </Typography>
	<Typography variant="h3" sx={theme.typography.typography2}> Η ίδρυση του Ορφανοτροφείου στα 1870 οφείλεται στη φιλάνθρωπη ψυχή των Σμυρναίων, που ένοιωσαν την ανάγκη να έρθουν αρωγοί στα μικρά απόκληρα της τύχης. 
	Ιδρύουν στα 1867 αδελφότητα που γρήγορα έφτασε τα 600 μέλη, προβαίνουν σε συλλογή εράνων, συγκεντρώνουν σεβαστό χρηματικό ποσό, νοικιάζουν μεγάλο οίκημα κατά τη 
	θέση λιμανάκι, το εξοπλίζουν, προσλαμβάνουν κατάλληλο προσωπικό και τον Φεβρουάριο του 1870 θέτουν σε λειτουργία το Ορφανοτροφείο.       
    Το Νοέμβριο του 1886 πυρκαγιά κατέστρεψε το προς τον δρόμο τμήμα του Ορφανοτροφείου. Με εράνους ξαναχτίστηκε το τμήμα που καταστράφηκε. 
    Σ’ όλα τα ορφανά παρεχόταν εκπαίδευση ως την Πέμπτη δημοτική τάξη, ενώ ταυτόχρονα μάθαιναν διάφορες τέχνες, ραπτική, επιπλοποιεία, υποδηματοποιεία, υφαντική, 
	ξυλουργική, πλεκτική κ.α. Στα 1893 δημιουργήθηκε ορχήστρα πνευστών οργάνων με μουσικούς αποκλειστικά τα ορφανά. Τα μεγαλύτερα ορφανά ασκούνταν ως τεχνίτες σ’ εργοστάσια
	της Σμύρνης, με την άγρυπνη πάντα παρακολούθηση από το ίδρυμα. Το Ελληνικό Ορφανοτροφείο υπαγόταν στο Οικουμενικό Πατριαρχείο και με σιγίλιο του Πατριάρχη 
	χαρακτηρίστηκε ως σταυροπηγιακό.  
    Το Ορφανοτροφείο διοικούσε Εφορεία από 9 μέλη, που εκλεγόταν κάθε τριετία. Εκτός από την εφορεία υπήρχαν και κοσμήτριες που επέβλεπαν την γενική λειτουργία του 
	ιδρύματος, παρακολουθούσαν το ήθος των ορφανών και επιμελούνταν την οργάνωση διάφορων υπέρ του Ορφανοτροφείου ευεργετικών εορτών. Στα 52 έτη της λειτουργίας του το 
	Ορφανοτροφείο ευτύχησε να έχει εξαιρετικές διευθύντριες.
    Μέσα στο ίδρυμα του Ορφανοτροφείου χιλιάδες ορφανά βρήκαν τη στοργή, την αγάπη, τη συμπόνια. Βρήκαν ένα χάδι λίγη χαρά και μόρφωση και βγήκαν αργότερα οπλισμένοι 
	για τη βιοπάλη. Η εθνική και ανθρωπιστική αποστολή του ιδρύματος υπήρξε εξαιρετικά μεγάλη. Ανακόπηκε όμως και αυτή, όπως και των άλλων ελληνικών φιλανθρωπικών 
	καταστημάτων της Σμύρνης, από τον ανιστόρητο ξεριζωμό του 1922.</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>Λαϊκό κέντρο</Typography>
	<img src={Image10}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Στα 1890 με την πρωτοβουλία των Δημήτρη Ιωακειμίδη, Αλέξανδρο Καμπάδη, Στέλιο Κολάρου, Σάββα Παπαγρηγοριάδη και Ανδρέα Παπαδοπούλου ιδρύεται το «Λαϊκό Κέντρο», 
	που λειτούργησε έως τη Μικρασιατική Καταστροφή. Σκοπός του ήταν η μόρφωση των λαϊκών τάξεων με ειδικά μαθήματα και διαλέξεις σε νυκτερινές σχολές που ίδρυσαν στις 
	συνοικίες Φασούλα, Μορτάκια, Άη – Βούκλα κ.ά. Στα 1911 με δωρεά του Β. Βασιλειάδη και άλλων Σμυρναίων, το Λαϊκό Κέντρο απέκτησε ιδιόκτητο μέγαρο στο Φασούλα. Σ’ αυτό
	υπήρχε οικοτροφείο για τους άπορους μαθητές. Επίσης λειτουργούσε συσσίτιο όχι μόνο για φτωχούς σπουδαστές αλλά και για άπορους. Το Λαϊκό Κέντρο χορηγούσε κάθε 
	χρόνο «βραβείο αρετής» σε μαθητές και διατηρούσε μαντολινάτα.</Typography>	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ευσέβεια</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Στα 1893 το θρησκευτικό σωματείο «Άγιος Πολύκαρπος», που ιδρύθηκε το προηγούμενο έτος, μετατρέπεται σε αδελφότητα με τίτλο η «Ευσέβεια», που 
	λειτούργησε έως τη Μικρασιατική Καταστροφή. Μεταξύ των άλλων επιτευγμάτων της ήταν η ίδρυση κατηχητικών σχολείων στις περισσότερες σμυρναϊκές συνοικίες και η 
	συντήρηση του δημοτικού σχολείου στη λαϊκή συνοικία Μορτάκια</Typography>	
	
	</Box>
	
	
	 <ScrollToTopButton />	
	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ιδιωτικά Εκπαιδευτήρια</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Εκτός από την κοινοτική εκπαίδευση στη Σμύρνη που όπως είδαμε, ήταν άρτια οργανωμένη με τα μεγάλα ανώτερα εκπαιδευτήριά της τα εξαρτήματά τους και τα ενοριακά 
	σχολεία,  και η ιδιωτική εκπαίδευση με τα εξίσου άρτια λειτουργούντα σχολεία, πρόσφερε πολλά στη μόρφωση της Ελληνικής νεολαίας της Ιωνίας. Η ιδιωτική εκπαιδευτική 
	κίνηση στη Σμύρνη, από τις αρχές σχεδόν του περασμένου αιώνα, είναι αξιόλογη και αξιοσημείωτη. Οι ιδρυτές των ιδιωτικών ελληνικών σχολείων, κατέβαλλαν κάθε προσπάθεια 
	και κατόρθωναν, παρ’ όλα τα εμπόδια και τις αντιδράσεις από τη δουλεία, να δίνουν στη μαθητική νεολαία όχι μόνο ζηλευτή μόρφωση αλλά και εθνικό παλμό και πίστη στα 
	ιδανικά της θρησκείας και του γένους.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Παραθέτουμε κατά χρονολογική σειρά, τα ιδιωτικά σχολεία, που ιδρύθηκαν κατά καιρούς στη Σμύρνη και που πολλά απ’ αυτά σημείωσαν βίο δεκαετηρίδων:</Typography>
	
	<ul>
		<li>Ιδιωτικό σχολείο Βρούερ (1831)</li>
		<li>Ιδιωτικό σχολείο Κωνσταντίνου Ροδέ (1841)</li>
		<li>Ιδιωτική σχολή Παναγιώτη Μαμμακίδη (1841)</li>
		<li>Ιδιωτικό σχολείο Μιχαήλ Λαδοπούλου(1848)</li>
		<li>Μεικτή σχολή για μικρά παιδιά Γ. Δ. Λαμπίση (1851)</li>
		<li>«Ελληνικό Εκπαιδευτήριο Σμύρνης» του Γ. Κουϊμζέλη (1851)</li>
		<li>Παρθεναγωγείο Σαπφώ Τζανή και Ιωσήφ Μάγνη (1852)</li>
		<li>«Ελληνικό Σχολείο» Νικολάου Αρώνη (1852)</li>
		<li>Ιδιωτικό σχολείο Θωμά Τιμαγένη (1854)</li>
		<li>«Ελληνικό Παιδαγωγείο» Αντωνίου Ισηγόνη, Φ. Φιλιππούτση και Κ. Κουρνιαχτή (1854)</li>
		<li>Αλληλοδιδακτική σχολή θηλέων Ειρήνης Σοφιανοπούλου (1856)</li>
		<li>Παρθεναγωγείο των Ευπατρίδων μετ’ οικοτροφείου» (1857)</li>
		<li>Ιδιωτική σχολή Α. Αραβαντινού (1857)</li>
		<li>«Ελληνικό Παρθεναγωγείο μετ’ οικοτροφείου» Μεθοδίου Αρώνη (1858)</li>
		<li>«Ελληνικό Παρθεναγωγείο» Χαρ. Αναστασιάδη (1858)</li>
		<li>«Σμυρναϊκό Ελληνικό Εκπαιδευτήριο μετ’ οικοτροφείου» Ν. Κ. Ρενιέρη (1860)</li>
		<li>«Ελληνικό Λύκειο» αδελφών Γ. και Π. Γρηγοριάδη</li>
		<li>Παρθεναγωγείο, νηπιαγωγείο, πλήρες δημοτικό σχολείο και μία γυμνασιακή τάξη Φωτεινής Κοκκινάκη (1861)</li>
		<li>«Ο Πυθαγόρας» Κυριάκου και Φωτεινής Νικολαΐδου (1863)</li>
		<li>«Νέο Σχολείο Ιωαννίδη» (1866)</li>
		<li>«Ελληνικό Σχολείο αρρένων» Νικολάου Αρώνη</li>
		<li>Παρθεναγωγείο αδελφών Κυδωνοπούλου</li>
		<li>«Προκαταρκτική Σχολή» Μαριγώς Παράσχου (1871)</li>
		<li>«Σχολή Θηλέων» Α. Ζαφαρειάδου (1871)</li>
		<li>«Φιλολογικό και Εμπορικό Εκπαιδευτήριο» Λ. Ζινιού (1871)</li>
		<li>Παρθεναγωγείο Αμαλίας και Κλεοπάτρας Σκαραμαγκά (1872)</li>
		<li>Παρθεναγωγείο Χρυσάνθης Παπαδάκη (1873)</li>
		<li>Νηπιαγωγείο Αικατερίνης Ευθυμίου (1875)</li>
		<li>«Ελληνικό Παιδευτήριο» Διονυσίου Χαρικλή (1876)</li>
		<li>Ιδιωτική Σχολή Ιωάννας Βορονζέρη (1877)</li>
		<li>«Εμπορικό Λύκειο» Π. Καπλανίδη (1878)</li>
		<li>«Ελληνικό Παρθεναγωγείο μετ’ οικοτροφείου Κάδμος» Ελένης Χριστοπούλου (1880)</li>
		<li>«Ελληνικό Παρθεναγωγείο και Νηπιαγωγείο» Πηνελόπης Μασαούτη (1881)</li>
		<li>«Νέο Παρθεναγωγείο» Άννας Γερασιμίδου και Μαρίας Παπαδογιάννη (1881)</li>
		<li>Παρθεναγωγείο Ελένης Καλλιγά (1882)</li>
		<li>Παρθεναγωγείο Ιωνία» Ευθαλίας Καμποπούλου (1884)</li>
		<li>Παρθεναγωγείο με νηπιαγωγείο και οικοτροφείο Ελένης Ξάνθου (1884)</li>
		<li>Μεικτή δημοτική σχολή αδελφών Ζωγράφου (1884)</li>
		<li>Παρθεναγωγείο Γεωργίου Δαλδάκη (1885)</li>
		<li>«Κοραής» αδελφών Χριστιανίδου (1885)</li>
		<li>«Ελληνική Σχολή» Α. Ισηγόνης και Β. Καμινάρη (1885)</li>
		<li>«Ειδική Εμπορική Σχολή» Β. Σκόρδη καιν Γ. Βαρβούτη (1886)</li>
		<li>«Ελληνικό Εκπαιδευτήριο μετ’ οικοτροφείου» Λ. Μ. Καλημέρη (1886)</li>
		<li>«Εμπορικό Λύκειο Ερμής» Αριστοτέλη Χαροκόπου (1886)</li>
		<li>«Δραγάτσιο Παρθεναγωγείο» αδελφών Μαρίας και Φράντση Δραγάτση (1886)</li>
		<li>«Παλλάδιο Οικοτροφείο» αδελφών Πασχάλη (1886)</li>
		<li>«Πρακτική Ελληνική Σχολή» Ευάγγελου Χαραλαμπίδη (1887)</li>
		<li>«Λύκειο Μαλλιαρού» (1887)</li>
		<li>Νηπιαγωγείο Αδελφών Αναστασίας Βιργινίας και Μαριάνθης Λιναρδοπούλου (1893)</li>
		<li>Ιδιωτικό σχολείο Κλημέντου και Ελισάβετ Μακρίδου (1897)</li>
		<li>Πρακτικό Λύκειο των Α. Βιτάλη και Χρυσάνθης Βιτάλης (1897)</li>
		<li>«Αγαθάγγελος» Αναστασίας και Πηνελόπης Κοροβίλη (1897)</li>
		<li>«Ελικών» Νικομάχης Καλλιγέρης (1900)</li>
		<li>Παρθεναγωγείο με οικοτροφείο Ιωάννη Διαμαντοπούλου (1900)</li>
		<li>Εκπαιδευτήριο Αθανάσιου Αναστασιάδη και Σταυριάνθης Αναστασιάδου (1904)</li>
		<li>«Ελληνικό Εκπαιδευτήριο» Θ. Νεστορίδη (1905)</li>
		<li>«Ελληνογερμανικό Λύκειο» Κυριάκου Γιαννίκη</li>
		<li>Ιδιωτική Σχολή αδελφών Φαρδούλη (1907)</li>
		<li>Αστική Σχολή αδελφών Κωνσταντινίδη (1907)</li>
		<li>«Εθνικό Λύκειο» Θεόδωρου Κότσιου (1913)</li>
		<li>«Ιωνική Σχολή» Αναστάσιου Αναστασιάδη (1920)</li>
		<li>Ιδιωτική Σχολή Αναστάσιου Ευαγγελινίδη (1920)</li>	
	</ul>
	<Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
	</Box>
	
	
	 <ScrollToTopButton />	
	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Σπιτικά Σχολεία</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Παράλληλα προς τα κοινοτικά, ενοριακά και ιδιωτικά σχολεία λειτουργούσαν σε κάθε ελληνική γειτονιά της Σμύρνης ένα ή δύο σπιτικά σχολεία. Τα ίδρυαν Ελληνίδες 
	που με μικρό αντάλλαγμα, εργάζονταν με ζήλο και αφοσίωση να διδάξουν τα πρώτα γράμματα στα μικρά Ελληνόπουλα. Στα σχολεία αυτά παραδίδονταν μαθήματα έως και τη 
	δεύτερη τάξη του δημοτικού.
    Στη Σμύρνη κάθε συνοικία κάθε γειτονιά, είχε και το σπιτικό σχολείο της. Απ’ τις αστικές συνοικίες του Αγίου Δημητρίου και της Αγίας Αικατερίνης ως τις λαϊκές του 
	Άη Τρύφωνα και των Μορτακιών, ο περπατητής απ’ τα ψελλίσματα του αλφαβήτου θα διαπίστωνε χωρίς άλλη ειδοποίηση την ύπαρξή τους. Άλφααα, βήτααα, γάμααα… ήταν το 
	τραγούδι, το πρώτο ψέλλισμα των μικρών τροφίμων.
    Ιδρυτές ή μάλλον ιδρύτριες, των σπιτικών σχολείων,  ήταν, συνήθως, όψιμες κοπέλες που ατύχησαν στον έρωτα, που δεν πρόφτασαν να οσφρανθούν τους ονειρευτούς καρπούς 
	του υμεναίου. Έτσι όλο το απόθεμα της στοργής, του ανικανοποίητου μητρικού φίλτρου, που μέσα τους σπαρταρούσε, έπρεπε να διοχετευτεί κάπου. Και αυτό το κάπου τους 
	ωθούσε στην ίδρυση των σπιτικών σχολείων.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
	</Box>
	
	
	 <ScrollToTopButton />	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ελληνικά Σχολεία Προαστίων</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Δεν υπερτερούσε μόνο στην κυρίως πόλη της Σμύρνης η εκπαίδευση, αλλά και στα προάστια η υπεροχή του ήταν καταφανής. Στον Κουκλουτζά οι 5000 κάτοικοι του 
	ήταν όλοι Έλληνες, στο Σεβδίκιοϊ και οι 9000 κάτοικοί του ήταν επίσης Έλληνες, εκτός από τρεις Τούρκικες οικογένειες. Στο Βουρτζά με 12000 κάτοικους όλοι 
	σχεδόν ήταν Έλληνες, και στο Χατζηλάρι επί 500 κατοίκων οι 400 ήταν Έλληνες. Η ίδια αναλογία επικρατούσε και στα περισσότερα προάστια της Σμύρνης. Έτσι οι 
	ομογενείς των σμυρναϊκών προαστίων ιδρύουν για τη μόρφωση των παιδιών τους σχολεία και φιλεκπαιδευτικές αδελφότητες με σκοπό την συντήρηση των εκπαιδευτηρίων 
	τους, που λειτουργούσαν μέχρι την Μικρασιατική Καταστροφή. Ελληνικά σχολεία των Σμυρναϊκών προαστίων ήταν:</Typography>
	<ul>
		<li>Άγιοι Ανάργυροι (Λευκαία Πηγή)</li>
		<li>Αγία Κυριακή</li>
		<li>Αγία Τριάς</li>
		<li>Αλάμπεη</li>
		<li>Βαϊρακλή</li>
		<li>Βουτζάς</li>
		<li>Γκιόζ – Τεπέ (Ενόπη)</li>
		<li>Καραντίνα (Καλλιθέα)</li>
		<li>Καρατάσι (Μελαντία)</li>
		<li>Κοκαργιάλι (Μυρακτή)</li>
		<li>Κορδελιό (Περαία)</li>
		<li>Κουκλουτζάς</li>
		<li>Μερσινλή</li>
		<li>Μπουρνάμπασι</li>
		<li>Μπουρνόβας</li>
		<li>Ναρλίκιοϊ</li>
		<li>Παπά Σκάλα</li>
		<li>Προφήτης Ηλίας</li>
		<li>Σαλαχανέ (Σφακτήρια)</li>
		<li>Σεβδίκιοϊ</li>
		<li>Τομάζο</li>
		<li>Χατζηλάρι</li>	
	</ul>
	<Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
	</Box>
	
	
	 <ScrollToTopButton />	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ξένα Σχολεία</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Εκτός από τα ελληνικά σχολεία στη Σμύρνη, που υπερτερούσαν σημαντικότατα σε αριθμό και σε δύναμη διδασκάλων και μαθητών, λειτουργούσαν και πολλά ξένα 
	εκπαιδευτήρια που σε μερικά αγγλικά και αμερικάνικα κυρίως φοιτούσαν πολλοί Έλληνες μαθητές. Τα ξένα στη Σμύρνη σχολεία ήταν:</Typography>
	<ul>
		<li>Αγγλικά</li>
		<li>Αμερικάνικα</li>
		<li>Αρμένικα</li>
		<li>Γαλλικά</li>
		<li>Γερμανικά</li>
		<li>Ισραηλιτικά</li>
		<li>Ιταλικά</li>
		<li>Τούρκικα</li>	
	</ul>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η υπεροχή των Ελληνικών σχολείων στη Σμύρνη από όλα τα άλλα είναι καταφανής. Το 1894 η επαρχία της Σμύρνης είχε 235 σχολεία με 25.498 μαθητές και 
	μαθήτριες. Απ’ αυτούς οι 13.332 σπούδαζαν σε ελληνικά σχολεία, 6286 σε τούρκικα, 1185 σε αρμένικα, 710 σε διαμαρτυρόμενων, 2401 σε καθολικών και 1548 σε ισραηλιτικά.
    Την εποχή αυτή σπούδαζαν σε ελληνικά σχολεία στη Σμύρνη 8944 μαθητές, 4931 αγόρια και 4014 κορίτσια. Σε όλα τα άλλα σχολεία σπούδαζαν 7889 μαθητές, 5889 αγόρια και 
	2000 κορίτσια. Και ειδικότερα: σε τούρκικα, 2716 αγόρια και100 κορίτσια, σε αρμένικα 350 αγόρια και 80 κορίτσια, σε ισραηλιτικά 1228 αγόρια και 314 κορίτσια, 
	σε διαμαρτυρομένων 425 αγόρια και 285 κορίτσια, σε καθολικά 1178 αγόρια και 1213 κορίτσια.</Typography>
	</Box>
		
	 <ScrollToTopButton />	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Αρμοστειακό Διδασκαλείο και Γυμνάστο</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>ΑΡΜΟΣΤΕΙΑΚΟ ΔΙΔΑΣΚΑΛΕΙΟ</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Από τις πρώτες φροντίδες της Ελληνικής κυβέρνησης μετά την απελευθέρωση της Σμύρνης ήταν η ίδρυση ενός τριτάξιου διδασκαλείου αρρένων. Σκοπός του η εκπαίδευση 
	εντοπίων δημοδιδασκάλων που θα χρησιμοποιούνταν στα κοινοτικά σχολεία των πόλεων του εσωτερικού της Μικράς Ασίας. Η ίδρυση  διδασκαλείου κρίθηκε απαραίτητη γιατί 
	το διδασκαλείο της Ευαγγελικής σχολής είχε πάψει να λειτουργεί από τα πρώτα χρόνια του Ευρωπαϊκού πολέμου. Το διδασκαλείο στεγάστηκε στο οίκημα όπου βρισκόταν το 
	ενοριακό παρθεναγωγείο στον Άγιο Τρύφωνα και συντηρούνταν αποκλειστικά από την Ύπατη Αρμοστεία Σμύρνης. Το διδασκαλείο άρχισε να λειτουργεί τον Οκτώβριο του 1919 
	και σύντομα απέκτησε αξιόλογη σχολική βιβλιοθήκη, γεωγραφικούς χάρτες και πλούσιο διδακτικό υλικό.</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>ΑΡΜΟΣΤΕΙΑΚΟ ΓΥΜΝΑΣΙΟ</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Την άνοιξη του 1920 ιδρύθηκε από την Ύπατη Αρμοστεία Σμύρνης κλασσικό γυμνάσιο. Κατά τις απόψεις της αρμοστείας η Ευαγγελική Σχολή δεν ήταν σε θέση με τους 
	900 μαθητές της 650 του κλασικού τμήματος και 250 του εμπορικού να δεχτεί και άλλους μαθητές. Για τον λόγο αυτόν ίδρυσε το «Αρμοστειακό Γυμνάσιο», που στεγάστηκε 
	στη συνοικία της Ευαγγελίστριας.</Typography>
	</Box>
		
	 <ScrollToTopButton />	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Το πανεπιστήμιο της Σμύρνης</Typography>
	
	<img src={Image11}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Από τις πρώτες μέριμνες του πρωθυπουργού της Ελλάδας Ελευθέριου Βενιζέλου μετά την απελευθέρωση της Σμύρνης ήταν η ίδρυση Πανεπιστημίου στην Ιωνική πρωτεύουσα 
	με σκοπό την άμεση εξυπηρέτηση και θεραπεία  των κυριότερων αναγκών της Μικρασιατικής Ελλάδας. Η ίδρυση ανώτατου εκπαιδευτηρίου στη Σμύρνη απέβλεπε ειδικότερα 
	στην προαγωγή της γεωργίας και της κτηνοτροφίας, την τεχνική ανάπτυξη της χώρας, την καταπολέμηση των λοιμωδών νόσων, τη μόρφωση διοικητικού και άλλου προσωπικού 
	και τέλος τη διδασκαλεία των ανατολικών γλωσσών. Έτσι οι σχολές που ιδρύθηκαν ήταν οι εξής:</Typography>
	
	<ul>
		<li>Σχολή Γεωπονική και φυσικών επιστημών</li>
		<li>Σχολή ανατολικών γλωσσών</li>
		<li>Σχολή δημοσίων υπαλλήλων</li>
		<li>Εμπορική σχολή</li>
		<li>Σχολή χωροσταθμών και εργοδηγών</li>
		<li>Ανώτερο μουσουλμανικό ιεροδιδασκαλείο</li>
		<li>Ινστιτούτο υγιεινής</li>
		<li>Ίδρυση δημόσιας Βιβλιοθήκης</li>	
	</ul>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ως Πανεπιστήμιο Σμύρνης χρησιμοποιήθηκε μεγάλο και ευρύχωρο κτίριο στο λόφο Μπαχρή Μπαμπά. Το κτίριο ήταν μισοτελειωμένο όταν απελευθερώθηκε η Σμύρνη. Ευθύς 
	η Ύπατη Αρμοστεία ασχολήθηκε με τη διασκευή και αποπεράτωση του κτιρίου που περιελάμβανε 70 ευρύχωρες και ηλιόλουστες αίθουσες, αμφιθέατρο 320 θέσεων και μεγάλο
	περίβολο. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Για την οργάνωση του σμυρναϊκού Πανεπιστημίου ο Ύπατος Αρμοστής της Ελλάδος Αριστείδης Στεργιάδης, με εισήγηση του Ελευθερίου Βενιζέλου, μετακάλεσε τον Απρίλιο 
	του 1920 από το Βερολίνο, τον καθηγητή του εκεί Πανεπιστημίου Κωνσταντίνο Καραθεοδωρή. Ο Καραθεοδωρής γεννήθηκε το Σεπτέμβριο του 1873 στο Βερολίνο. Αρχικά σπούδασε 
	μηχανική στη στρατιωτική σχολή των Βρυξελλών και αργότερα παρακολούθησε μαθήματα φιλοσοφίας και μαθηματικών στα πανεπιστήμια Βερολίνου και Γοτίγγης. Στο τελευταίο 
	αναγορεύεται, στα 1904, διδάκτωρ και αργότερα εκλέγεται καθηγητής του. Επίσης ο Καραθεοδωρής διετέλεσε καθηγητής των μαθηματικών στα Πολυτεχνία του Ανοβέρου και 
	του Μπρεσλάου. Η συγγραφική του εργασία είναι περισπούδαστη και θεωρείται ο Καραθεοδωρής από τους διασημότερους μαθηματικούς της εποχής του, με κύρος διεθνές. 
	Εκλέχθηκε μέλος των ακαδημιών Αθηνών, Βερολίνου, Γοτίγγης, Μονάχου, Μπολώνιας, Ρώμης κ.α. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η σύμβαση μεταξύ Στεργιάδη και Καραθεοδωρή υπογράφθηκε στις 27 Οκτωβρίου 1920. Με αυτή τη αναθέτεται στον Καραθεοδωρή η καθηγεσία στο Πανεπιστήμιο 
	της Σμύρνης με αντιμισθία 4.000 δραχμών το μήνα για πέντε χρόνια. Ο διορισμός του επικυρώθηκε με πράξη της ύπατης Αρμοστείας.  
    Σχετικά με την ίδρυση και λειτουργία του Πανεπιστημίου Σμύρνης αναφέρω το ακόλουθο γεγονός. Ο βαθύπλουτος Αθηναίος στο Παρίσι Σταύρος Παλαντζής ανέλαβε, την 
	υποχρέωση να δωρίσει στο Ελληνικό Δημόσιο για την ανοικοδόμηση του Σμυρναϊκού Πανεπιστημίου 2.000.000 φράγκα σε τέσσερις ίσες εξαμηνιαίες δόσεις. Δυστυχώς όμως ο 
	Παλαντζής δεν φάνηκε συνεπής στις υποχρεώσεις του. Ισχυρίστηκε ότι η Βελγική κυβέρνηση είχε θέσει υπό μεσεγγύηση την μισή περιουσία του που βρισκόταν στο Βέλγιο. 
	Τον Αύγουστο του 1922 επακολούθησε η Μικρασιατική καταστροφή και κάθε υποχρέωση του Παλαντζή για το Πανεπιστήμιο Σμύρνης ατόνησε.
	Στο κτίριο του Πανεπιστημίου λειτουργεί τώρα τούρκικο λύκειο θηλέων.</Typography>
	
	</Box>
		
	 <ScrollToTopButton />	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Επίλογος</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Έτσι η Σμύρνη το «έαρ της Ελλάδος» κατά την αρχαιότητα, η «αιώνια όπως η Ρώμη» κατά τον Prokesch Von Osten, η κορωνίδα της Ιωνίας, υπήρξε με τα’ αναρίθμητα 
	εκπαιδευτήριά της ο πάμφωτος πνευματικός φάρος, που φώτιζε επί χρόνους μακρούς, όχι μόνο την ανατολή, αλλά και πολλά τμήματα της λοιπής Ελλάδας.
    Στα σχολεία της Σμύρνης, τους ναούς αυτούς της γνώσεως, μυριάδες ψυχές καταυγάστηκαν από το φως της μαθήσεως και μυριάδες καρδιές θερμάνθηκαν από τη φλόγα της 
	μορφώσεως.
    Κάτω από τους θόλους των Ελληνικών σχολείων της Ιωνίας αντηχούσε κάθε μέρα η λειτουργία της αγίας παιδείας. Για να διαπλάσει τους χαρακτήρες, να σφυρηλατήσει τη 
	χριστιανική ευσέβεια, να φλογίσει το εθνικό φρόνημα.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Η παιδεία – το θείο δώρο κατά τον Πλάτωνα – που αγκάλιαζε την πνευματική και ψυχική υπόσταση των ελληνοπαίδων της Ιωνίας, δεν ήταν απλώς εφόδιο σταδιοδρομίας. 
	Ήταν για τους Σμυρναίους βίωμα και θρησκεία και πάθος. Και όταν άφηναν τα μαθητικά εδώλια, μαζί με την ψυχική εφορεία που ένοιωθαν, είχαν και την πνευματική εκείνη
	συγκρότηση, που τους καθιστούσε πανέτοιμους στον αγώνα της ζωής. Με την καλλιέργεια του πνεύματος, με την χριστιανοσύνη στην ψυχή, με την Ελλάδα στην καρδιά – τους 
	τρεις αυτούς χαλύβδινους θώρακες – οι Σμυρναίοι Ελληνόπαιδες ξεκινούσαν για την πραγματοποίηση ενός φωτεινού μέλλοντος και τη δημιουργία μιας μεγάλης Ελλάδος… </Typography>
	
	</Box>

    <ScrollToTopButton />
    
    </>
  );
}

export default G6_mikrasiatika;
