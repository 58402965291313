import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image11 from "../assets/images/Hmerologia_p5/mouseio11.jpg";
import Image12 from "../assets/images/Hmerologia_p5/mouseio12.jpg";
import Image13 from "../assets/images/Hmerologia_p5/mouseio13.jpg";
import Image14 from "../assets/images/Hmerologia_p5/mouseio14.jpg";
import Image15 from "../assets/images/Hmerologia_p5/mouseio15.jpg";
import Image16 from "../assets/images/Hmerologia_p5/mouseio16.jpg";
import Image17 from "../assets/images/Hmerologia_p5/mouseio17.jpg";
import Image18 from "../assets/images/Hmerologia_p5/mouseio18.jpg";
import Image19 from "../assets/images/Hmerologia_p5/mouseio19.jpg";
import Image20 from "../assets/images/Hmerologia_p5/mouseio20.jpg";
import Image21 from "../assets/images/Hmerologia_p5/mouseio21.jpg";
import Image22 from "../assets/images/Hmerologia_p5/mouseio22.jpg";
import Image23 from "../assets/images/Hmerologia_p5/mouseio23.jpg";
import Image24 from "../assets/images/Hmerologia_p5/mouseio24.jpg";
import Image25 from "../assets/images/Hmerologia_p5/mouseio25.jpg";
import Image26 from "../assets/images/Hmerologia_p5/mouseio26.jpg";
import Image27 from "../assets/images/Hmerologia_p5/mouseio27.jpg";
import Image28 from "../assets/images/Hmerologia_p5/mouseio28.jpg";
import Image29 from "../assets/images/Hmerologia_p5/mouseio29.jpg";
import Image30 from "../assets/images/Hmerologia_p5/mouseio30.jpg";
import Image31 from "../assets/images/Hmerologia_p5/mouseio31.jpg";
import Image32 from "../assets/images/Hmerologia_p5/mouseio32.jpg";
import Image33 from "../assets/images/Hmerologia_p5/mouseio33.jpg";
import Image34 from "../assets/images/Hmerologia_p5/mouseio34.jpg";
import Image35 from "../assets/images/Hmerologia_p5/mouseio35.jpg";
import Image36 from "../assets/images/Hmerologia_p5/mouseio36.jpg";
import Image37 from "../assets/images/Hmerologia_p5/mouseio37.jpg";
import Image38 from "../assets/images/Hmerologia_p5/mouseio38.jpg";


function P5_Hmerologia() {

    const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        <HomeIcon />
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ημερολόγιο 2008 : Μουσείο Προσφυγικής μνήμης" />
      </Box>

      
      <Box sx={{ ...commonBoxStyles }}>
	
	<img src={Image11}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<Typography variant="h3" sx={theme.typography.typography2}>Με τίτλο  <b>"Μουσείο Προσφυγικής Μνήμης 1922"</b> κυκλοφόρησε το ημερολόγιο του Συλλόγου μας για το έτος 2008.
	Πρόκειται για επιτραπέζιο ημερολόγιο, βιβλίο το οποίο είναι συλλεκτικό κομμάτι και περιλαμβάνει ιστορικά στοιχεία και πάρα πολλές φωτογραφίες από τα κειμήλια των 
	Ελλήνων προσφύγων της Μ. Ασίας τα οποία φιλοξενούνται στο Μουσείο Προσφυγικής Μνήμης στη Σκάλα Λουτρών. Το ημερολόγιο είναι δίγλωσσο (Ελληνικά – Αγγλικά) και 
	τυπώθηκε στην Αθήνα.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Θεωρούμε υποχρέωσή μας να ευχαριστήσουμε την Νομαρχιακή Αυτοδιοίκηση Λέσβου και ιδιαιτέρως τον Νομάρχη Λέσβου κ. Παύλο Βογιατζή για την βοήθειά τους στην έκδοση του 
	ημερολογίου αυτού καθώς και όλους τους λοιπούς χορηγούς  που κάθε χρόνο συμβάλουν οικονομικά στην έκδοση των ημερολογίων μας.</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>"Δε ζει χωρίς πατρίδες η ανθρώπινη ψυχή" </Typography>
	<img src={Image12}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
    <Typography variant="h3" sx={theme.typography.typography2}>Ο παλαμικός τούτος στίχος, ασίγαστος συνοδός της ατελεύτητης όδεψης κάθε ανήσυχης ψυχής. Βασανιστικός στο άκουσμα του για όσους χάνουν τον πολυτιμότερό τους 
		προσδιορισμό, την Πατρίδα τους και εντέλει την ουσία της ταυτότητάς τους. ΄΄Χαρά και μύρο της ζωής τους ΄΄ σε όσους  έκλεισαν τα όσια και ιερά των προγόνων τους 
		στην ψυχή τους και από ΄΄χαμένα΄΄ τα μετέβαλαν σε ΄΄αλησμόνητα΄΄. Γιατί πραγματικά κάτι χάνεται μόνο αν  λησμονηθεί. Και οι δικές μας Πατρίδες στα σεπτά χώματα 
		της Μικρασίας, αλησμόνητες μείναν μέσα μας.</Typography>

    <Typography variant="h3" sx={theme.typography.typography2}>Σε τούτο το μικρό προσφυγικό χωριό, τη Σκάλα Λουτρών, με τον πιο βαθύ σεβασμό σκύψαμε πάνω στις τραγικές θύμησες των προσφύγων της πρώτης γενιάς και ό,τι είχαν 
	μπορέσει να φέρουν απ’ τα καρσινά παράλια μέσα απ’ την αντάρα της αποτρόπαιης βίας, το συγκεντρώσαμε σε ένα καλαίσθητο χώρο για να θυμόμαστε και να μη ξεχάσουμε ποτέ. 
	Μια ιδέα ενός μικρού ιστορικού, λαογραφικού μουσείου, σεμνή υπογραφή των προσφύγων και των απογόνων τους, σε έναν κόσμο που οι καινούριες θεωρίες του ΄΄συνωστίζονται΄΄ 
	στις προκυμαίες της λήθης. Μα ταυτόχρονα ο παντοτινός προσδιορισμός της μικρασιατικής ταυτότητας του οικισμού μας, οι παλιοί περήφανα για να θυμούνται, οι τωρινοί να 
	κατανοούν και οι καινούριοι να ρωτάνε και να μαθαίνουν. </Typography>

	<img src={Image13}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<Typography variant="h3" sx={theme.typography.typography2}><b>"Μουσείο Προσφυγικής Μνήμης 1922"</b>, το ονομάσαμε. 12 Αυγούστου 2006 το εγκαινιάσαμε. Η μεγαλύτερη τιμή που  έπρεπε, μας αποδόθηκε στα εγκαίνια του από την 
	παρουσία της Α.Π.Θ. Οικουμενικού Πατριάρχη Κωνσταντινουπόλεως κ.κ. Βαρθολομαίο. Μέσα στην καταλαμπή της μέρας εκείνης, ξεχωριστή θέση τα δάκρυα των ελάχιστων πλέον 
	προσφύγων πρώτης γενιάς, που στην τελευταία περίοδο της ζωής τους ένιωσαν τη  δικαίωση τούτη. Να τους ξαναδοθεί δηλαδή ένα μέρος της χαμένης  ταυτότητά τους.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Ένα μεγάλο ευχαριστώ σε όσους προσέφεραν και προσφέρουν τα ανεκτίμητα κειμήλιά τους, τους τελευταίους ΄΄θησαυρούς΄΄ της Μικράς Ασίας, προορισμένους πλέον να θυμίζουν
	στους παλιότερους και να διδάσκουν τους νεότερους. Ένα επιπλέον ευχαριστώ σε όσους προσφέρουν βιβλία σχετικά με τη Μικρά Ασία στην εξειδικευμένη βιβλιοθήκη του μουσείου
	και ιδιαίτερα στο Κέντρο Μικρασιατικών Σπουδών για τις πολυτιμότατες προσφορές του. Τους υποσχόμαστε πως όλα αυτά θα τα διαφυλάξουμε και θα τα συντηρήσουμε με τον 
	καλύτερο τρόπο.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}><b>Το Διοικητικό Συμβούλιο του Συλλόγου Μικρασιατών της Σκάλας Λουτρών Λέσβου.</b></Typography>
	</Box>
	
	
	<ScrollToTopButton />	
	
	<Box sx={{ ...commonBoxStyles }}>
    <Typography variant="h1" sx={theme.typography.typography1}>Φωτογραφικό Υλικό</Typography>
				
        <img src={Image14}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image15}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image16}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image17}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image18}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image19}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image20}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image21}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image22}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image23}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image24}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image25}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image26}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image27}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image28}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image29}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image30}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image31}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image32}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image33}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image34}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image35}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image36}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image37}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image38}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		
	</Box>

    <ScrollToTopButton />


    </>
  );
}

export default P5_Hmerologia;
