import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/spoudaiotera_erga_mas/dentrilia2.jpg";
import Image02 from "../assets/images/spoudaiotera_erga_mas/petrini_brisi.jpg";
import Image03 from "../assets/images/spoudaiotera_erga_mas/stasi.jpg";
import Image04 from "../assets/images/spoudaiotera_erga_mas/pinakides1.jpg";
import Image05 from "../assets/images/spoudaiotera_erga_mas/pedikixara1.jpg";
import Image06 from "../assets/images/spoudaiotera_erga_mas/athlopedia1.jpg";
import Image07 from "../assets/images/spoudaiotera_erga_mas/athlopedia2.jpg";
import Image08 from "../assets/images/spoudaiotera_erga_mas/organagum1.jpg";
import Image09 from "../assets/images/spoudaiotera_erga_mas/organagum2.jpg";
import Image10 from "../assets/images/spoudaiotera_erga_mas/athlopedia3.jpg";

import Image11 from "../assets/images/spoudaiotera_erga_mas/ag_panteleimonas2.jpg";
import Image12 from "../assets/images/spoudaiotera_erga_mas/ag_panteleimonas3.jpg";

import Image13 from "../assets/images/spoudaiotera_erga_mas/library.jpg";
import Image14 from "../assets/images/spoudaiotera_erga_mas/library4.jpg";
import Image15 from "../assets/images/spoudaiotera_erga_mas/mouseio.jpg";

function D101_spoudaioteraErgamas() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Σπουδαιότερα έργα μας." />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Φύτευμα 1500 δενδρυλίων{" "}
        </Typography>
        <img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Φύτευμα 1500 δενδρυλίων στην ευρύτερη περιοχή του οικισμού μας και
          φροντίδα αυτών με τοποθέτηση προστατευτικών πλαισίων, ποτίσματα
          κλαδέματα κτλ την περίοδο 1990 έως το 1994.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Κατασκευή πέτρινης βρύσης{" "}
        </Typography>
        <img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Κατασκευή πέτρινης βρύσης στην είσοδο της παιδικής χαράς του οικισμού
          μας το έτος 1991.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Κατασκευή στεγάστρου
        </Typography>
        <img src={Image03}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Κατασκευή στεγάστρου στη στάση του λεωφορείου για την αναμονή των
          επιβατών το έτος 1992.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τοποθέτηση πινακίδων
        </Typography>
        <img src={Image04}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τοποθέτηση πινακίδων σήμανσης στην παράκαμψη του δρόμου προς τον
          οικισμό μας το έτος 1993.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τοποθέτηση οργάνων στη παιδική χαρά
        </Typography>
        <img src={Image05}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τοποθέτηση οργάνων στην παιδική χαρά το 1994.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Δημιουργία χώρου αθλοπαιδιών και κατασκευή γηπέδου μπάσκετ
        </Typography>
        <img src={Image06}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image07}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Δημιουργία χώρου αθλοπαιδιών σε μία έκταση 3.500 m2 την οποία μας
          παρεχώρησε το Ίδρυμα Πέτρου Μάνδρα με συμβολικό ενοίκιο ύστερα από
          αίτησή μας και την οποία τη δενδροφυτεύσαμε τη περιφράξαμε και
          κατασκευάσαμε μέσα σ αυτό το χώρο <b>γήπεδο μπάσκετ / βόλευ </b>
          Ολυμπιακών διαστάσεων το 1994.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τοποθέτηση οργάνων Γυμναστικής
        </Typography>
        <img src={Image08}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image09}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τοποθέτηση οργάνων γυμναστικής στο χώρο αθλοπαιδιών το 1994.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          {" "}
          Κατασκευή θερινού αμφιθεάτρου.{" "}
        </Typography>
        <img src={Image10}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μέσα στον ίδιο χώρο των 3.500 m2 κατασκευάσαμε θερινό αμφιθέατρο με 3
          πέτρινες κερκίδες χωρητικότητας 180 περίπου ατόμων και πίστας 150 m2
          για τις ανάγκες των εκδηλώσεων των χορευτικών , θεατρικών και μουσικών
          τμημάτων του Συλλόγου μας τον οποίο και εγκαινιάσαμε το έτος 1996.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αναπαλαίωση εκκλησίας Αγίου Παντελεήμονος
        </Typography>
        <img src={Image11}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image12}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το 2001 με πρωτοβουλία του Συλλόγου μας , την συνεργασία του ιερέα του
          χωριού μας , και την οικονομική συνδρομή τόσο των μελών του Συλλόγου
          μας όσο και άλλων ευσεβών χριστιανών ξεκινήσαμε και αναπαλαιώσαμε το
          ερειπωμένο και μισογκρεμισμένο εκκλησάκι του Αγίου Παντελεήμονος το
          οποίο ευρίσκεται στην είσοδο του οικισμού μας.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Βιβλιοθήκη
        </Typography>
        <img src={Image13}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image14}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η βιβλιοθήκη του Συλλόγου μας, που η κατασκευή της ξεκίνησε το έτος
          2000 και τα εγκαίνιά της έγιναν στις 6 Ιουλίου 2008, αριθμεί σήμερα
          πάνω από 7500 βιβλία, ταξινομημένα σε ηλεκτρονικό υπολογιστή τα οποία
          μπορούν να αναζητηθούν ποικιλοτρόπως με μεγάλη ευκολία. Αυτή η
          βιβλιοθήκη εξυπηρετεί τις ανάγκες του Οικισμού μας αλλά και των
          τουριστών το καλοκαίρι αφού πολλά από τα βιβλία μας είναι και
          ξενόγλωσσα.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Μουσείο Προσφυγικής μνήμης
        </Typography>
        <img src={Image15}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το <b>«Μουσείο Προσφυγικής μνήμης»</b> 1922 ένα έργο του Συλλόγου που
          εγκαινίασε στις 12 Αυγούστου του 2006 η Α.Θ.Π. ο Οικουμενικός
          Πατριάρχης Βαρθολομαίος. Αυτό στεγάζετε στο πρώην Δημοτικό σχολείο της
          Σκάλας Λουτρών το οποίο μας παραχωρήθηκε από την Νομαρχία Λέσβου το
          έτος 2003 όταν τότε έκλεισε και οι λίγοι μαθητές του μεταφέρθηκαν στο
          πλησιέστερο Δημοτικό σχολείο Λουτρών. Το κτίριο αυτό αφού
          ανακαινίσθηκε πλήρως με προσωπική κυρίως εργασία των μελών του
          Συλλόγου μας στέγασε τα κειμήλια των προσφύγων κατοίκων του Οικισμού
          μας.
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default D101_spoudaioteraErgamas;
