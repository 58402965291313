import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/17sinedriopandoiko_01.jpg";
import Image02 from "../assets/images/oikologika/17sinedriopandoiko_02.jpg";
import Image03 from "../assets/images/oikologika/mamidakis3.jpg";

function F2_oikologika_02() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Περίληψη του προβλήματος των εγκαταστάσεων καυσίμων στον Κόλπο Γέρας" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          O υπέρ δεκαετής αγώνας του συλλόγου μας
        </Typography>

        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η εταιρία ΜΑΜΙΔΑΚΗΣ (νυν ΕΚΟ-ΕΛΔΑ) διατηρούσε{" "}
          <b>εγκαταστάσεις αποθήκευσης και διακίνησης υγρών καυσίμων</b> μέσα
          στην οικιστική ζώνη της Σκάλας Λουτρών στον Κόλπο της Γέρας της Νήσου
          Λέσβου από τα μέσα περίπου της δεκαετίας του 60 με άδεια που
          ανανεωνόταν περιοδικά.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μετά την έναρξη ισχύος της Κ.Υ.Α. 69269 η εταιρία ζήτησε στις 6-7-92
          από το ΥΠΕΧΩΔΕ την έγκριση περιβαλλοντικών όρων για ανανέωση της
          άδειας της που έληγε το 1994.
          <br />
          Η υποβληθείσα στα πλαίσια της διαδικασίας αυτής Μ.Π.Ε. απορρίφθηκε μεν
          αρχικά από το Νομαρχιακό Συμβούλιο Λέσβου κατά τις συνεδριάσεις του
          στις 6-7-93 και στις 20-5-94 , τελικά όμως εγκρίθηκε κατά πλειοψηφία
          με την υπ αρ. 14/21-2-96 απόφαση του Νομαρχιακού Συμβουλίου Λέσβου.
          <br />
          Κατόπιν αυτού εκδόθηκε η υπ αρ. 30931/20-5-96 κοινή απόφαση του Γ.Γ.
          του ΥΠΕΧΩΔΕ και του Γ.Γ. του Υπουργείου Ανάπτυξης με την οποία
          εγκρίθηκαν οι περιβαλλοντικοί όροι.
          <br />
          Η τελευταία αυτή απόφαση της Διοίκησης προσβλήθηκε νόμιμα και
          εμπρόθεσμα από τον Σύλλογο « ΔΕΛΦΙΝΙ » και την τότε Κοινότητα Λουτρών
          Λέσβου ενώπιον του Σ.τ.Ε. και το Ανώτατο Ακυρωτικό Δικαστήριο με την
          υπ αρ. 4633/97 απόφαση του Ε! Τμήματος ακύρωσε την ως άνω απόφαση ως
          μη νόμιμη , λόγω της αντίθεσής της, προς την αρχή της μη υποβάθμισης
          ζωτικού στοιχείου του θαλασσίου και παρακτίου κεφαλαίου της χώρας ,
          όπως είναι ο Κόλπος της Γέρας.
          <br />
          Ειδικότερα το <b>ανώτατο Διοικητικό Δικαστήριο της Χώρας</b> έκρινε
          στο αιτιολογικό της απόφασής του ότι εφ όσον ο κόλπος της Γέρας
          αποτελεί{" "}
          <b>
            ευαίσθητο οικοσύστημα, χερσαίο και θαλάσσιο με μεγάλη οικολογική
            αξία
          </b>
          , συνδυάζει δε επιπροσθέτως και την αξία του υψηλού αισθητικού κάλους
          , είναι σύμφωνα με τα ανωτέρω δεκτικός μόνον ηπίας διαχειρίσεως , με
          την οποία είναι προδήλως ασύμβατη η εγκατάσταση και λειτουργία
          δεξαμενών αποθήκευσης και διακίνησης υγρών καυσίμων , οι οποίες
          συνεπάγονται πρόδηλο επιβάρυνση και κίνδυνο για το περιβάλλον.
        </Typography>
        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την απόφαση αυτή του Σ.τ.Ε. κοινοποιήσαμε με δικαστικό κλητήρα στην
          εταιρία ΜΑΜΙΔΑΚΗΣ , στο ΥΠΕΧΩΔΕ , στην Υπουργό Ανάπτυξης και στην
          Νομαρχιακή Αυτοδιοίκηση Λέσβου για να πράξουν υποτίθεται τα δέοντα.
          <br />
          Μεσολαβεί αρκετό χρονικό διάστημα και στις 10-7-98 κοινοποιούμε
          εξώδικο στον τότε Νομάρχη Λέσβου κ. Μαθιέλλη στο οποίο επισημαίνουμε
          τις ποινικές ευθύνες που έχει από την μη εφαρμογή της απόφασης του
          Σ.τ.Ε.
          <br />
          Τον Μάρτιο του 1999 επισκεπτόμαστε τον νέο τότε Νομάρχη Λέσβου κ.
          Βουνάτσο για να του υπενθυμίσουμε την εκκρεμότητα που υπάρχει με το
          θέμα. Ο Νομάρχης υπόσχεται ότι το αργότερο εντός του Απριλίου 1999 θα
          συζητήσει το θέμα στο Νομαρχιακό Συμβούλιο ώστε να βρεθεί εναλλακτικός
          τρόπος διακίνησης των καυσίμων χωρίς όμως τελικά να το πράξει παρά τις
          επανειλημμένες ενοχλήσεις μας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τον Μάιο του 1999 ο πρόεδρος του Τοπικού Συμβουλίου Λουτρών
          καταγγέλλει στην Αστυνομία ότι οι εγκαταστάσεις ΜΑΜΙΔΑΚΗ λειτουργούν
          χωρίς άδεια, η Αστυνομία μεταβαίνει , επιβεβαιώνει το γεγονός και
          υποβάλει μήνυση στην εταιρεία.
          <br />
          Στις 5-6-99 ημέρα του περιβάλλοντος γίνεται σύσκεψη όλων των φορέων
          του Κόλπου Γέρας με την παρουσία των βουλευτών του Νομού , των
          Δημάρχων Γέρας , Ευεργέτουλα και Μυτιλήνης , του Νομάρχη Λέσβου και
          λοιπών φορέων στην οποία εκτός των άλλων συζητείται και το θέμα της
          εφαρμογής της απόφασης του Σ.τ.Ε. και μάλιστα προτείνονται και
          διάφοροι τρόποι διακίνησης των καυσίμων όπως ακριβώς συμβαίνει σε άλλα
          νησιά που στερούνται τέτοιων εγκαταστάσεων.
          <br />
          Τον Αύγουστο του 1999 σαν ύστατη ενέργειά μας παραδίδουμε επιστολή
          στον Υπουργό Αιγαίου με την οποία ζητάμε την μεσολάβησή του για την
          επίλυση του θέματος χωρίς ωστόσο να υπάρξει καμία απάντηση.{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τον Σεπτέμβριο του 1999 παραδίδετε επιστολή – καταγγελία στο
          Λιμεναρχείο Περάματος που έχει την ευθύνη της περιοχής στην οποία
          επισημαίνονται οι ευθύνες που έχει επειδή επιτρέπει την προσέγγιση των
          δεξαμενόπλοιων και την φορτοεκφόρτωση καυσίμων σε μια εταιρεία που
          στερείται αδείας. Απάντηση καμία.
          <br />
          Τον Οκτώβριο του 1999 καταγγέλλεται από τον Σύλλογο ΔΕΛΦΙΝΙ στην
          Εισαγγελία Μυτιλήνης ότι εντός των εγκαταστάσεων έχει γίνει παράνομη
          ταφή Τοξικών και Επικίνδυνων Αποβλήτων. Επεμβαίνει ο Εισαγγελέας ,
          γίνεται εκταφή στο χώρο που υπεδείχθη και επιβεβαιώνονται τα
          καταγγελλόμενα.
          <br />
          Το κλιμάκιο ελέγχου και προστασίας του περιβάλλοντος της Νομαρχιακής
          Αυτοδιοίκησης Λέσβου επιβάλει πρόστιμο 7.000.000 δρχ. στην εταιρεία
          και την υποχρεώνει εντός δύο μηνών να υποβάλει σχέδιο απορρύπανσης της
          περιοχής.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τον ίδιο μήνα οι ψαράδες της Σκάλας Λουτρών πιάνουν έναν άρρωστο
          πελεκάνο ο οποίος με πρωτοβουλία του Συλλόγου ΔΕΛΦΙΝΙ στέλνεται στο
          Κέντρο Περίθαλψης Αγρίων Ζώων και Πουλιών στην Αίγινα.
          <br />
          Το πουλί μετά 10 περίπου ημέρες αποβιώνει με την διάγνωση ότι έπασχε
          από Μολυβδίαση , στοιχείο τοξικό και χαρακτηριστικό των ρύπων από
          εγκαταστάσεις καυσίμων.
          <br />
          Στις 7-11-99 η Επιτροπή Περιβάλλοντος του Δήμου Μυτιλήνης αφού
          εξετάζει πολύπλευρα το θέμα ζητά από όλους τους αρμόδιους να
          εφαρμόσουν την απόφαση του Σ.τ.Ε. και εισηγείται στο Δημοτικό
          Συμβούλιο Μυτιλήνης να πράξει το ίδιο.
          <br />
          Οι Βουλευτές Στ. Σκοπελίτης του Κ.Κ.Ε. και Στ. Αλφιέρη του ΣΥΝ
          καταθέτουν σχετικές επερωτήσεις στην Βουλή για το θέμα.
          <br />
          Ο Ευρωβουλευτής κ. Παπαγιαννάκης πράττει το ίδιο στην Ευρωβουλή.
          <br />
          Το Δημοτικό Συμβούλιο Μυτιλήνης αποφασίζει ομόφωνα και ζητά από τον
          Νομάρχη Λέσβου την άμεση εφαρμογή της απόφασης του Σ.τ.Ε. και το ίδιο
          πράττει με επιστολή του ο Δήμαρχος Μυτιλήνης κ. Γιακαλής.
          <br />
          Στις 17-1-2000 κάνουμε αναφορά στον «ΣΥΝΗΓΟΡΟ ΤΟΥ ΠΟΛΙΤΗ» και η
          ανεξάρτητη αυτή Διοικητική Αρχή στέλνει μια επιστολή καταπέλτης στον
          Νομάρχη Λέσβου κ. Βουνάτσο και τον καλεί να απαντήσει εντός 20 ημερών.
          <br />
          Στις 24-4-2000 ο «ΣΥΝΗΓΟΡΟΣ ΤΟΥ ΠΟΛΙΤΗ» επανέρχεται με νέα επιστολή
          του προς τον Νομάρχη Λέσβου ζητώντας του εξηγήσεις για την καθυστέρηση
          λήψης σχετικών αποφάσεων επί του θέματος.
        </Typography>

        <img
          src={Image03}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τον Απρίλιο του 2000 έρχεται προς συζήτηση στο Νομαρχιακό Συμβούλιο
          Λέσβου νέα Μελέτη Περιβαλλοντικών Επιπτώσεων της εταιρείας ΜΑΜΙΔΑΚΗΣ
          κατά προκλητική παράβαση της απόφασης του Σ.τ.Ε.
          <br />
          Το Νομαρχιακό Συμβούλιο Λέσβου με την υπ αρ. 21/2000 απόφασή του της
          5-4-2000 την απορρίπτει ομόφωνα{" "}
          <b>
            {" "}
            επειδή υφίσταται δεδικασμένον προκύπτον από την αριθ. 4633/97
            απόφαση του Ε! Τμήματος του Σ.τ.Ε. και ζητά από το αρμόδιο Υπουργείο
            ΠΕ.ΧΩ.ΔΕ. να μην χορηγήσει έγκριση περιβαλλοντικών όρων στην σχετική
            αίτηση της παραπάνω εταιρείας
          </b>
          .<br />
          Τον Ιούνιο του 2000 και μετά από σχετικό αίτημα του Συλλόγου ΔΕΛΦΙΝΙ
          το Επιμελητήριο Περιβάλλοντος και Βιωσίμου Αναπτύξεως εκδίδει
          γνωμοδότηση και θεωρεί το θέμα της μη συμμορφώσεως της Διοικήσεως προς
          τις αποφάσεις του Σ.τ.Ε. ως μείζων κώλυμα για την εμπέδωση Κράτους
          Δικαίου στη Χώρα μας.
          <br />
          Παρά ταύτα το ΥΠΕΧΩΔΕ με την υπ αρ. 46315/7-6-2000 απόφασή του
          εγκρίνει την νέα Μ.Π.Ε. της προαναφερθείσης εταιρείας παρά του ότι
          υφίσταται δεδικασμένο προκύπτον από την 4633/97 απόφαση του Σ.τ.Ε.{" "}
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Σύλλογος της Σκ. Λουτρών Λέσβου <b>ΤΟ ΔΕΛΦΙΝΙ</b> και ο Δήμος
          Μυτιλήνης προσφεύγουν εκ νέου στο Ανώτατο Ακυρωτικό Δικαστήριο της
          χώρας μας το Σ.τ.Ε. για να καταγγείλουν την τελεσθείσα παρανομία.
          <br />
          Στις 5-8-2000 το θερινό τμήμα του Ε! Τμήματος του Σ.τ.Ε. εκδίδει
          προσωρινή διαταγή αναστολής της προαναφερθείσης έγκρισης και στις
          4-4-2001 εκδικάζεται η αίτηση ακυρώσεως στο Ε! τμήμα το οποίο και
          εξέδωσε την 4141/2001 απόφαση με την οποία ακυρώνεται η 46315/7-6-2000
          απόφαση των Υπουργείων Περιβάλλοντος και Ανάπτυξης ως παράνομη λόγω
          του δεδικασμένου που υπάρχει με την 4633/97 απόφαση του ιδίου
          τμήματος.
          <br />
          Ο Συνήγορος του Πολίτη εν τω μεταξύ επανέρχεται με νέες παρεμβάσεις
          του στις 1-6-2001 , στις 31-7-2001, στις28-9-2001, στις 31-12-2001
          ,στις 28-1-2002 ,στις 17-4-2002 , στις 1-7-2002 και στις 15-11-2002
          επισημαίνοντας την ύπαρξη ευθυνών όλων των εμπλεκομένων μερών χωρίς
          όμως θετικό αποτέλεσμα.
          <br />
          Εν τω μεταξύ ο Σύλλογός μας προσφεύγει εκ νέου με αίτησή του στο
          Σ.τ.Ε. στην επιτροπή του άρθρου 72 παρ. 3 του π.δ. 18/1989 για να
          διαπιστωθεί η μη συμμόρφωσις της Διοικήσεως με τις αποφάσεις του
          Ανωτάτου Διοικητικού Δικαστηρίου της χώρας μας , καθώς και με αναφορά
          του για το θέμα στην επιτροπή αναφορών του Ευρωπαϊκού Κοινοβουλίου.
          <br />
          Στις 16-10-2002 η Επιτροπή Ευρωπαϊκών Κοινοτήτων παρεμβαίνει με
          αιτιολογημένη γνώμη της και εγκαλεί τη χώρα μας δίδοντας προθεσμία δύο
          μηνών για να κλείσει τις εγκαταστάσεις διαφορετικά δηλώνει ότι θα την
          παραπέμψει στο Ευρωπαϊκό Δικαστήριο.
          <br />
          Δύο ακριβώς μήνες μετά στις16-12-2002 με την 1303/5-12-02 απόφασή της
          , η Νομαρχία Λέσβου αποφασίζει την διακοπή της λειτουργίας{" "}
          <b>"σφράγιση"</b> των εγκαταστάσεων δίδοντας τέλος σ ένα σήριαλ που
          ταλαιπώρησε τον Σύλλογό μας ,τα μέλη του και τους κατοίκους για
          περισσότερο από 10 έτη.
          <br />
          Στις 7-4-2003 συνεδρίασε στο Σ.τ.Ε. η επιτροπή του άρθρου 72 για να
          εξετάσει την από 28-6-2002 αίτησή μας η οποία αφού εξέτασε το θέμα
          εξέδωσε την 12/2003 απόφαση η οποία καταλήγει λέγοντας{" "}
          <b>
            {" "}
            Οι ανωτέρω ενέργειες δεν συνιστούν πραγμάτωση των υλικών ενεργειών
            που είναι απαραίτητες για την συμμόρφωση προς την ακυρωτική απόφαση
            του Σ.τ.Ε. Συνεπώς η Διοίκηση παρέλειψε την εκ του Συντάγματος και
            του νόμου υποχρέωσή της προς συμμόρφωση στην προμνημονευθείσα
            4141/2001 απόφαση του Σ.τ.Ε. , υπέχουσα για τον λόγο αυτό τις κατά
            νόμων ευθύνες.{" "}
          </b>
          <br />
          Μετά το κλείσιμο των εγκαταστάσεων ο Συνήγορος του Πολίτη επανέρχεται
          με νέες παρεμβάσεις του (στις 13-2-2003 και στις 3-7-2003) για το
          πρόβλημα της απορρύπανσης της περιοχής από τα τοξικά απόβλητα , την
          τήρηση της νομιμότητας και την προστασία του περιβάλλοντος και της
          δημόσιας υγείας.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Εμείς πάντως παρά τα τελευταία θετικά αποτελέσματα δεν εφησυχάζουμε,
          αγρυπνούμε και παρακολουθούμε από κοντά τις εξελίξεις, έτσι ώστε σε
          σύντομο διάστημα να επιτευχθεί και ο στόχος απορρύπανσης της περιοχής
          μας από τα τοξικά απόβλητα της εταιρείας.
          <br />
          Τώρα, σίγουρα μπορούμε να ελπίζουμε σ ένα καλύτερο μέλλον.
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default F2_oikologika_02;
