import React from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import ScrollToTopButton from '../components/ScrollToTopButton';
import Header1 from '../components/Header1';
import HomeIcon from '@mui/icons-material/Home'
import { commonBoxStyles, headerBoxStyles } from '../components/CommonStyles'

import Image01 from '../assets/images/drastiriotites2007/kopsimo_pitas_2007.jpg'
import Image02 from '../assets/images/drastiriotites2007/theophania.jpg'
import Image03 from '../assets/images/drastiriotites2007/theophania2.jpg'
import Image04 from '../assets/images/drastiriotites2007/bibliothiki.jpg'
import Image05 from '../assets/images/drastiriotites2007/xaramida_burned.jpg'
import Image06 from '../assets/images/drastiriotites2007/ekdiloseis_mnimis.jpg'
import Image07 from '../assets/images/drastiriotites2007/ag_ermogenis.jpg'
import Image08 from '../assets/images/drastiriotites2007/epitafios.jpg'
import Image09 from '../assets/images/drastiriotites2007/protomagia.jpg'
import Image10 from '../assets/images/drastiriotites2007/nomarxis.jpg'
import Image11 from '../assets/images/drastiriotites2007/tsirko.jpg'
import Image12 from '../assets/images/drastiriotites2007/pelekanos.jpg'
import Image13 from '../assets/images/drastiriotites2007/ag_panteleimonas.jpg'
import Image14 from '../assets/images/drastiriotites2007/fortosi_kausimon.jpg'
import Image15 from '../assets/images/drastiriotites2007/apsili.jpg'
import Image16 from '../assets/images/drastiriotites2007/mouseio2.jpg'
import Image17 from '../assets/images/drastiriotites2007/kithara.jpg'


function D4_drastiriotites_2007() {

  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Οι δραστηριότητές μας το 2007" />
      </Box>


      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Χοροεσπερίδα και κόψιμο Βασιλόπιτας στο ξενοδοχείο ΖΑΙΡΑ.</Typography>

        <img src={Image01} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />


        <Typography variant="h3" sx={theme.typography.typography2}>Την παραμονή της Πρωτοχρονιάς, στο ξενοδοχείο ΖΑΙΡΑ πραγματοποιήθηκε όπως κάθε χρόνο με μεγάλη επιτυχία η χοροεσπερίδα του Συλλόγου μας κατά τη διάρκεια
          της οποίας κόπηκε και η βασιλόπιτα, με τις ευλογίες του ιερέα του χωριού μας. Τυχερή της βραδιάς ήταν η κ. Καρέτα Ειρήνη  η οποία με τον τυχερό αριθμό 117 κέρδισε το
          φλουρί της πίτας , ένα χρυσό Κωσταντινάτο. Στην συνέχεια έγινε δεύτερη κλήρωση ένα  DVD προσφορά από το κατάστημα του κ. Γιώργου Καλιάλη το οποίο κέρδισε ο κ.
          Ψωμάς Παναγιώτης με το νούμερο 299 και τέλος ένα κιβώτιο κρασιά Λήμνου προσφορά του χωριανού μας Δημ. Ζαραδούκα το οποίο κέρδισε η κ. Βατή Μαριάνθη με τον αριθμό 300.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Στην εκδήλωση παραβρέθηκαν και μας τίμησαν με την παρουσία τους, ο αντινομάρχης Λέσβου κ. Σπύρος Γαληνός, πρόεδροι τοπικών Συλλόγων καθώς και  φίλοι μας από τη Μυτιλήνη
          και τα χωριά της Γέρας. Τέλος μήνυμα μας έστειλε και διαβάσαμε ο Νομάρχης Λέσβου κ. Παύλος Βογιατζής. Κατά την διάρκεια της εκδήλωσης διενεργήθηκε λαχειοφόρος αγορά με
          πολλά και πλούσια δώρα , όλα προσφορές των καταστημάτων και των επιχειρήσεων της Μυτιλήνης και του χωριού μας. Δώρα στα παιδιά μοίρασε ο Αι Βασίλης ο γνωστός μας
          Γιάννης Μολυβιάτης τον οποίο και ευχαριστούμε θερμά για την διαχρονική προσφορά του στις εκδηλώσεις του Συλλόγου μας. Η εξυπηρέτηση του ξενοδοχείου ήταν πολύ καλή και
          η ορχήστρα της Παράλου κράτησε αμείωτο το κέφι και το γλέντι μέχρι τις πρωινές ώρες.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Εορτή των Θεοφανίων στη Σκάλα Λουτρών</Typography>
        <img src={Image02} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Όπως το θέλει το έθιμο την ημέρα των Φώτων με την συμμετοχή των μελών του Συλλόγου μας , του Τοπικού Συμβουλίου Λουτρών, και πολλών κατοίκων του χωριού
          μας
          έγινε στην μυρτιδοστολισμένη εξέδρα που είχε τοποθετηθεί στην προβλήτα του Αγίου Γεωργίου στη Σκάλα Λουτρών ο Αγιασμός των υδάτων και η ρίψης του Σταυρού στη θάλασσα
          με την συμμετοχή αρκετών κολυμβητών  οι οποίοι αψήφησαν το κρύο και βούτηξαν κατά πως επιτάσσει το έθιμο. Φέτος, τυχερός νεαρός που ανέσυρε το σταυρό από τη θάλασσα
          ήταν ο Γιώργος Τακός που παρέλαβε και το δώρο του, ένα χρυσό σταυρό με καδένα προσφορά του Πολιτιστικού κέντρου Λουτρών. Του ευχόμαστε ολόψυχα να είναι γερός και
          πάντα να συμμετέχει σ αυτό το έθιμο.</Typography>
      </Box>

      <ScrollToTopButton />


      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Εορτή των Θεοφανίων στη Χαραμίδα</Typography>
        <img src={Image03} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}> Ένα έθιμο, που ξεκίνησε πριν από πέντε χρόνια με πρωτοβουλία του Εξωραϊστικού Συλλόγου Χαραμίδας, σίγουρα φέτος αναβαθμίστηκε με την παρουσία τόσο
          πολλών
          ανθρώπων. Αμέσως λοιπόν μετά τον αγιασμό των υδάτων που έγινε στη Σκάλα Λουτρών  ο ιερέας του χωριού μας και αρκετοί από τους παρευρισκόμενους πήγαν στη Χαραμίδα
          (κάτω από την γνωστή ταβέρνα του κ. Παναγιώτη Βουτσαρδέλλη) όπου περίμεναν τα μέλη του τοπικού Συλλόγου  και οι κάτοικοι της περιοχής για να γίνει και εκεί ο
          καθιερωμένος πλέον Αγιασμός των υδάτων και στην συνέχεια το κόψιμο της Βασιλόπιτας. Τον σταυρό ανέσυρε από την Θάλασσα ο κ Σωτήρης Μαμουνής γνωστό μέλος τόσο
          του Συλλόγου μας όσο και του Συλλόγου της Χαραμίδας. Επακολούθησε το κόψιμο της πίτας και το σχετικό κέρασμα σε όλους τους παρευρισκόμενους με  πρωτεργάτες τα μέλη
          του Συλλόγου και την κ. Άσπα Αβαγιανού.</Typography>
      </Box>

      <ScrollToTopButton />


      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Ολυμπιακός Σκάλας Λουτρών.</Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>Όπως σχεδόν κάθε χρόνο έτσι και φέτος ο Σύλλογός μας συνέδραμε οικονομικά την ομάδα της Σκάλας Λουτρών τον Ολυμπιακό με το ποσό των 500 Ευρώ για αγορά
          αναγκαίου αθλητικού υλικού. Στην ομάδα που εφέτος διαπρέπει στο πρωτάθλημα της Α! τοπικής κατηγορίας ευρισκόμενη στις πρώτες θέσεις του βαθμολογικού πίνακα,
          ευχόμαστε ολόψυχα καλή πορεία και στην συνέχεια του πρωταθλήματος με πολλές επιτυχίες.</Typography>
      </Box>

      <ScrollToTopButton />


      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Έργα και αγορά εξοπλισμού στην βιβλιοθήκη του Συλλόγου μας.</Typography>
        <img src={Image04} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Με απόφαση του Δ.Σ. του Συλλόγου μας απεφασίσθη η αγορά δύο επί πλέον επίπλων βιβλιοθήκης, κουρτινών, ενός δερμάτινου καναπέ, δύο μικρών τραπεζιών και
          ενός
          ψυγείου για τις ανάγκες της βιβλιοθήκης του Συλλόγου μας. Έτσι σχεδόν έχουν ολοκληρωθεί οι εργασίες μαζί με την προμήθεια του εξοπλισμού και το μόνο που καθυστερεί
          για να πραγματοποιηθούν τα εγκαίνιά της είναι η ταξινόμηση και καταγραφή εκ νέου των βιβλίων που διαθέτουμε και που αυτή τη στιγμή υπερβαίνουν τους 3000 τίτλους.
        </Typography>
      </Box>


      <ScrollToTopButton />


      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Εθελοντική δενδροφύτευση στην πυρόπληκτη Χαραμίδα.</Typography>
        <img src={Image05} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Στα πλαίσια των δραστηριοτήτων ανάπτυξης και ανασύστασης του Δημόσιου και καμένου πλέον από πρόπερσι δάσους της Αμαλής η Διεύθυνση δασών Λέσβου σε
          συνεργασία με
          πολλές εθελοντικές οργανώσεις μεταξύ των οποίων και ο Σύλλογός μας το ΔΕΛΦΙΝΙ προέβη σε επιλεγμένες αναδασώσεις. Σκοπός των αναδασώσεων αυτών είναι η συμβολή στην
          επανίδρυση του δάσους στις καμένες δημόσιες εκτάσεις, η περιβαλλοντική ευαισθητοποίηση των πολιτών, η ανάπτυξη του φιλοδασικού πνεύματος, καθώς και η εκπαίδευση των
          εθελοντών στην τεχνική των φυτεύσεων. Οι ημέρες εθελοντικής αναδάσωσης πραγματοποιήθηκαν στις 21και 28 Ιανουαρίου, καθώς και στις 21 Μαρτίου με αφορμή την παγκόσμια
          ημέρα δασοπονίας</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}> Οι δενδροφυτεύσεις έγιναν στη θέση Χαβιαρόπετρα στο 15ο χλμ Μυτιλήνης Χαραμίδας.
          Με την έναρξη του φθινοπώρου αυτές επαναλήφθηκαν ήδη και έτσι την Κυριακή 4-11-07 πραγματοποιήθηκαν νέες δενδροφυτεύσεις στην παραλία νησέλια Χαραμίδας. Οι
          εθελοντικές οργανώσεις που πήραν μέρος είναι οι παρακάτω:<br />
          Ο Πολιτιστικός Σύλλογος Χαραμίδας, το Πολιτιστικό Κέντρο Λουτρών, ο Σύλλογος Σκάλας Λουτρών Το Δελφίνι, ο φυσιολατρικός όμιλος Υδάτινος, το Σωματείο εθελοντών
          πυροσβεστών, το σώμα Ελληνικού Οδηγισμού  ο Υδάλιος Δάμος, ο Φιλοζωικός Σύλλογος Μυτιλήνης και άλλοι.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}> Εκδηλώσεις μνήμης</Typography>
        <img src={Image06} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Την Κυριακή 23 Σεπτεμβρίου 2007 πραγματοποιήθηκαν στη Μυτιλήνη οι εκδηλώσεις μνήμης για την γενοκτονία των Ελλήνων της Μικράς Ασίας στο πλαίσιο της
          85ης επετείου
          από τη Μικρασιατική καταστροφή. Όπως είναι γνωστό η 14η Σεπτεμβρίου καθιερώθηκε με το νόμο  2645/1998, ως ημέρα μνήμης της γενοκτονίας των Ελλήνων της Μικράς Ασίας
          από το Τουρκικό κράτος. Έτσι την Κυριακή 23 Σεπτεμβρίου το Δ.Σ. του Συλλόγου μας παρευρέθη στην τέλεση της δοξολογίας στον Ιερό Ναό του Αγίου Νικολάου στην επάνω
          Σκάλα και στην συνέχεια κατέθεσε στεφάνι στο άγαλμα της Μικρασιάτισας μάνας μαζί με τους υπόλοιπους Συλλόγους και τις αρχές του τόπου.
          Για το νόημα της ημέρας μίλησε ο κ. Αργύρης Κεραμιτζής, καθηγητής του τεχνικού Λυκείου Καλλονής.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Φιλιώ Χαϊδεμένου, η μνήμη της Σμύρνης</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Η Φιλιώ Χαϊδεμένου, 107 ετών, «έφυγε» τον περασμένο Ιούνιο από κοντά μας τυλιγμένη στο όνειρο του γυρισμού στα Βουρλά της Σμύρνης, ικανοποιημένη ότι
          το Μουσείο
          που φέρει το όνομά της στη Νέα Φιλαδέλφεια λειτουργεί και κρατά τη μνήμη ζωντανή. Εβδομήντα έξι χρονών βγήκε στη σύνταξη, δεν μπόρεσε ούτε μια μέρα να μείνει στο
          σπίτι, όπως λέει στο βιβλίο της «Τρεις αιώνες, μια ζωή». Πήρε την τσάντα της και βγήκε να διαφεντέψει τη μνήμη της Μικρασιατικής καταστροφής που την έζησε στα
          Βουρλά όπου και γεννήθηκε. Στα Βουρλά ρίζωσε και εκεί έμεινε η Φιλιώ κι ας έφυγε με τη μάνα της μόνο με το καράβι της προσφυγιάς. Η οικογένεια έμεινε πίσω και
          ξεκληρίστηκε. Τον πατέρα της τον σκότωσαν, τον αδερφό της τον κρέμασαν. Η Φιλιώ άρχισε από την αρχή, από το προαύλιο του  Αγίου Νικολάου στον Πειραιά να υφαίνει
          στον προσωπικό αργαλειό της το «δεν ξεχνώ».</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Εθελοντικός  καθαρισμός παραλιών</Typography>
        <img src={Image07} width="300" height="200" style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}> Όπως κάθε χρόνο έτσι και φέτος με την βοήθεια κυρίως της νεολαίας του οικισμού μας έγινε η καθιερωμένη εξόρμηση εθελοντικού καθαρισμού της παραλίας
          του όρμου
          της Σκάλας Λουτρών με τα γνωστά περιβαλλοντικά προβλήματα. Το μήνυμα που δίδουμε με αυτή την ενέργειά μας είναι ότι είμαστε παρόντες στην ανάγκη προστασίας των ακτών
          μας διότι αυτές αποτελούν σημαντικό χώρο αναψυχής των πολιτών, πόλο έλξης επισκεπτών και πηγή οικονομικής ανάπτυξης του τόπου μας. Ο καθαρισμός και η προστασία των
          παραλιών σε συνδυασμό με το ότι αυτές βρίσκονται μέσα στην περιοχή προστασίας του Κόλπου της Γέρας (NATURA 2000) συμβάλουν στην οικολογική ισορροπία της περιοχής
          και στην προστασία των απειλούμενων ζώων και φυτών που υπάρχουν και διαβιούν εδώ. Τα νέα είναι ευχάριστα και αισιόδοξα αφού από τον περασμένο Ιούνιο ξεκίνησε
          επιτέλους το πολύ σπουδαίο έργο του βιολογικού καθαρισμού των λυμάτων του τοπικού διαμερίσματος Λουτρών το οποίο μάλιστα προβλέπετε να έχει ολοκληρωθεί μέχρι τις
          31-12-2008. Όπως κάθε χρόνο  λοιπόν  έτσι και φέτος δώσαμε το μήνυμα της ανάγκης προστασίας των ακτών μας, για μας, για τα παιδιά μας, για τις επόμενες γενιές, για
          τα ζώα, τα φυτά και την οικολογική ισορροπία. Ζητάμε παραλίες ελεύθερες για όλους, καθαρές με τη φροντίδα της Πολιτείας, των Δήμων και των πολιτών. </Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Πάσχα  στη Σκάλα Λουτρών</Typography>
        <img src={Image08} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Οι θρησκευτικές τελετουργίες για τον εορτασμό των αγίων ημερών του Πάσχα στη Σκάλα Λουτρών ξεκίνησαν με την τέλεση της θείας λειτουργίας το πρωί της
          Κυριακής
          των Βαΐων και του Νυμφίου το απόγευμα της ίδιας ημέρας. Στην συνέχεια το απόγευμα της Μ. Τετάρτης έγινε προηγιασμένη θεία λειτουργία και ευχέλαιο. Την Μ. Πέμπτη
          εψάλησαν τα 12 Ευαγγέλια και την Μ. Παρασκευή έγινε η περιφορά του επιτάφιου και η αποκαθήλωσις στην συνέχεια (κάτι που έγινε για πρώτη φορά στη Σκάλα Λουτρών) με
          την συμμετοχή όλων των κατοίκων.<br />
          Τέλος το βράδυ του Μεγάλου Σαββάτου, μέσα σε μια πολύ κατανυκτική ατμόσφαιρα υπό το φως των κεριών  ετελέσθη η αναστάσιμη Λειτουργία στο εκκλησάκι του Αγίου
          Παντελεήμονα αφού με πρωτοβουλία του Σεβασμιωτάτου Μητροπολίτου Μυτιλήνης και ύστερα από δικό μας αίτημα εστάλη ιερέας γι αυτό το σκοπό. Η συμμετοχή των κατοίκων
          ήταν καθολική και ιδίως των ηλικιωμένων οι οποίοι προσήλθαν με μεγάλη χαρά. Για πρώτη φορά συμμετείχαν και αρκετοί συμπολίτες μας από την Μυτιλήνη, καθώς και
          ορισμένοι τουρίστες από το παραπλεύρως ξενοδοχείο ΖΑΙΡΑ. Ο Σύλλογός μας στο τέλος πρόσφερε κόκκινα αυγά , μαγειρίτσα και τσουρέκια σε όλους τους παρευρισκόμενους τα
          οποία ήταν προσφορά του ξενοδοχείου ΖΑΙΡΑ γι αυτό και ευχαριστούμε πολύ τον ιδιοκτήτη Εμ. Τζανέτο. Και του χρόνου να είμαστε γεροί να αναστήσουμε ξανά με ιερέα στον
          οικισμό μας, όσο για το ξενοδοχείο του ευχόμαστε ολόψυχα καλές δουλειές.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Πρωτομαγιά και Νομαρχία Λέσβου</Typography>
        <img src={Image09} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Καθιερώθηκε πλέον κάθε πρωτομαγιά ο Σύλλογός μας να προσφέρει ένα πανέμορφο στεφάνι από τριαντάφυλλα και αγριολούλουδα στην Νομαρχία Λέσβου
          το οποίο φτιάχνουν οι κυρίες του Δ.Σ. του Συλλόγου μας. Όπως κάθε χρόνο λοιπόν έτσι και φέτος τα μέλη του  Δ.Σ.  επισκεφθήκαμε την Νομαρχία Λέσβου και το
          παραδώσαμε στο γραφείο του Νομάρχη.Και του χρόνου με αγάπη και υγεία.<br /><br /><br /><br /><br /></Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Επίσκεψη Νομάρχη στη Στέγη Μικρασιατικού Πολιτισμού</Typography>
        <img src={Image10} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Τη Στέγη Μικρασιατικού Πολιτισμού καθώς και στα γραφεία του Συλλόγου μας επισκέφθηκε ο Νομάρχης Λέσβου κ. Παύλος Βογιατζής συνοδευόμενος από το Γενικό
          Διευθυντή
          της Νομαρχίας κ. Γιώργο Σπανέλλη και συζήτησε με τον πρόεδρο και το Δ.Σ. τα θέματα που αφορούν δράσεις του Συλλόγου. Ο Νομάρχης με το επιτελείο του περιηγήθηκε
          στους χώρους του Μουσείου και είδε από κοντά τα νέα εκθέματα, η αύξηση των οποίων αποδεικνύει το ενδιαφέρον και την απήχηση που έχει στον προσφυγικό πληθυσμό του
          νησιού η συγκεκριμένη προσπάθεια<br />
          Στη συζήτηση που επακολούθησε εξετάσθηκε η δυνατότητα κατασκευής νέας πτέρυγας στο Μουσείο η οποία θα φιλοξενήσει τα νέα κειμήλια και θα λειτουργήσει συγχρόνως
          και ως αίθουσα παρουσιάσεων και διαλέξεων. Ως γνωστόν η συνεργασία μας με τη Νομαρχία είναι άριστη αφού εκτός των άλλων, για πέμπτη συνεχή χρονιά εκδίδουμε με την
          υποστήριξή της τα ημερολόγια για τις αλησμόνητες πατρίδες. Στην συνέχεια ο Νομάρχης επισκέφθηκε το Μουσείο και τη βιβλιοθήκη μας και μας συνεχάρη για την συνεχή και
          ουσιαστική δραστηριότητα που επιδεικνύουμε όλα αυτά τα χρόνια.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Απόσυρση του βιβλίου ιστορίας της ΣΤ! Δημοτικού. Η μόνη έντιμη λύση.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Άξιος ο κ. Ευριπίδης Στυλιανίδης, ο νέος υπουργός παιδείας που είπε «όχι» στο ανιστόρητο βιβλίο ιστορίας της ΣΤ! δημοτικού. «Πατρίδα μας ζεις, όσο σε
          θυμόμαστε,
          και όσο σε τιμούμε» εμείς βροντοφωνάζουμε σαν απόγονοι αυτών που έζησαν τον «συνωστισμό» στις παραλίες της Σμύρνης το 1922. Βέβαια πέρσι το βιβλίο διδάχθηκε τα όσα
          θέλησαν να περάσουν ως «ιστορικά γεγονότα» οι σπουδαγμένοι συγγραφείς του, πιστεύοντας ότι στη σύγχρονη μορφή της παγκοσμιοποίησης οι σφαγές, οι γενοκτονίες, οι
          «συνωστισμοί» Ελλήνων που διώκονται από τη φωτιά από τα σπίτια τους, χωρίς επιστροφή, με καράβια της προσφυγιάς, δεν έχουν θέση. Αλλά ήταν τέτοια η οργή και ο
          πρωτοφανής ξεσηκωμός, οι διαμαρτυρίες της κοινής γνώμης, για την βεβήλωση της νωπής ιστορικής μνήμης της Μικρασιατικής καταστροφής ώστε να «φρενάρει» προεκλογικά η
          εκτύπωση του βιβλίου. </Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Προγράμματα επιμόρφωσης</Typography>
        <h4>Τμήμα Συμβουλευτικής Γονέων στη Σκάλα Λουτρών.</h4>

        <Typography variant="h3" sx={theme.typography.typography2}>Το <b>"Τμήμα Συμβουλευτικής"</b> που λειτουργεί στη Σκάλα Λουτρών, υπάγεται στις Σχολές Γονέων του Ινστιτούτου διαρκούς εκπαίδευσης ενηλίκων του
          Υπουργείου Εθνικής Παιδείας και Θρησκευμάτων και πραγματοποιείται μετά από πρόσκληση του τοπικού Συλλόγου Μικρασιατών «ΤΟ ΔΕΛΦΙΝΙ». Έχει διάρκεια 40 ωρών με 13
          συναντήσεις στον αριθμό και είναι πρόγραμμα σε εξέλιξη. Συμμετέχουν γονείς, «εν δυνάμει γονείς» και γιαγιάδες. Η συμμετοχή είναι ενεργητική.<br />
          Ο σκοπός λειτουργίας του προγράμματος είναι:<br />
          <ul>
            <li>Nα προσφέρει συμβουλευτικές υπηρεσίες στους γονείς, δίνοντας τα απαραίτητα εφόδια να μάθουν να κατανοούν τη συμπεριφορά (θετική και αρνητική) των παιδιών
              τους, τις συγκινήσεις τους και να χρησιμοποιούν την ενθάρρυνση ως μέσον σεβασμού και αγάπης προς αυτά.</li>
            <li>Να εφοδιάσει τους γονείς με βασικές γνώσεις, δεξιότητες χειρισμού και επίλυσης προβλημάτων, για τις ανάγκες των παιδιών τους, σε κάθε ηλικιακό στάδιο και
              ανάλογα με τη σύνθεση της οικογένειας.</li>
            <li>Να ενημερώσει για τους κανόνες που διέπουν την εποικοδομητική επικοινωνία, ως μέσον καλύτερης διαπροσωπικής σχέσης με τα παιδιά και τους
              συντρόφους τους.</li>
            <li>Να τονίσει τη σπουδαιότητα συνεργασίας σχολείου – οικογένειας για την ανάπτυξη της αυτοπεποίθησης και των ικανοτήτων των παιδιών τους.</li>
            <li>Να αποφορτίσει συναισθηματικά και να ενδυναμώσει τους γονείς στο ρόλο τους.</li>
          </ul></Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>Tα θέματα που μέχρι τώρα έχουν συζητηθεί αφορούν αποκλειστικά και μόνο τα μέλη της ομάδας. Η ολοκλήρωση του προγράμματος θα πραγματοποιηθεί
          στα μέσα Δεκεμβρίου 2007, με την τελική αξιολόγηση εκ μέρους των συμμετεχόντων στους στόχους που είχαν τεθεί με την έναρξη λειτουργίας του.
          Νοιώθω αυξημένο το αίσθημα της ευθύνης, ως εκπαιδεύτρια, όταν βρίσκομαι στο χώρο της ομάδας αυτής, διότι η φιλοξενία, ο σεβασμός και η αξιοπρέπεια που διέπουν
          τα μέλη της παρακινούν διαρκώς το ενδιαφέρον και την προσοχή μου. <br />
          Με εκτίμηση<br />
          Μαρία Αντωνέλλη.<br />
          Μυτιλήνη 11-11-2007</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Επιστολή προς το Δήμαρχο και το Δημοτικό Συμβούλιο Μυτιλήνης με θέμα: </Typography>
        <h4>την Χωροθέτηση μονάδας βιολογικού καθαρισμού λυμάτων Νοτίου Τμήματος Μυτιλήνης.</h4>

        <Typography variant="h3" sx={theme.typography.typography2}>Κύριε Δήμαρχε και κύριοι Δημοτικοί Σύμβουλοι με την επιστολή αυτή θέλουμε να βοηθήσουμε στην σωστή και χωρίς μελλοντικές περιπέτειες επίλυση του
          σπουδαίου έργου
          του βιολογικού καθαρισμού των Νοτίων προαστίων της πόλης μας.<br />
          Την αφορμή για την επιστολή μας αυτή την έδωσε η πρόσφατη ανακοίνωση μέσω του τύπου της πρόθεσης της Δημοτικής αρχής για την μελέτη-χωροθέτηση νέας μονάδας
          βιολογικού καθαρισμού λυμάτων στην θέση Κουτσουμπάρα (Άη Γιώργη Κρατήγου) για την οποία πρέπει να γνωρίζεται τα παρακάτω.
          Το 1993 είχε προταθεί η ίδια περιοχή για την κατασκευή της μονάδος Βιολογικού καθαρισμού που όμως ύστερα από καταγγελίες προς την Π.Β. Αιγαίου, την Νομαρχία Λέσβου,
          το ΥΠΕΧΩΔΕ και την Ευρωπαϊκή Ένωση [που χρηματοδοτούσε το έργο] είχε αιτιολογημένα απορριφθεί η χωροθέτηση στην εν λόγω περιοχή και έτσι η κατασκευή της μονάδος του
          Βιολογικού έγινε στην σημερινή της θέση στον Καρά Τεπέ Μυτιλήνης.
          Τις καταγγελίες αυτές είχαν κάνει τότε οι:<br />
          κοινότητες Λουτρών και Αγίας Μαρίνας,<br />
          ο Πολιτιστικός Εξωραϊστικός Σύλλογος Βαρειάς Ακρωτηρίου «Ο ΘΕΟΦΙΛΟΣ», <br />
          o Πολιτιστικός Οικολογικός Σύλλογος Κρατήγου Αγριλιάς, <br />
          o Πολιτιστικός Εξωραϊστικός Σύλλογος Νεαπόλεως,<br />
          ο Σύλλογός μας «ΤΟ ΔΕΛΦΙΝΙ», <br />
          η Ένωση Ξενοδόχων Λέσβου και άλλοι φορείς.<br />
          <span className="fl">Ο</span>φείλουμε εδώ να σημειώσουμε πως σύμφωνα με σχετικό έγγραφο του ΥΠΕΧΩΔΕ η υπάρχουσα μονάδα βιολογικού καθαρισμού της Μυτιλήνης είναι δυναμικότητος 54.000
          κατοίκων και στην τελική της μορφή 76.000 κατοίκων.
          Συμπερασματικά πιστεύουμε πως θα είναι μέγα λάθος η προσπάθεια χωροθέτησης νέας μονάδας στον Άη Γιώργη Κρατήγου διότι, πέραν των όσων τότε είχαμε επικαλεστεί
          όλοι μας, για να απορριφθεί αυτή η θέση και που όλα ανεξαιρέτως ισχύουν και σήμερα., στο σημείο αυτό λειτουργεί και μεγάλη μονάδα ιχθυοκαλλιέργειας  που
          οπωσδήποτε είναι ένας επιπλέον σοβαρός λόγος για την απόρριψη εκ νέου της προτεινόμενης θέσης.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Όχι στα τσίρκο με ζώα.</Typography>
        <img src={Image11} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Με αφορμή την παγκόσμια ημέρα αφιερωμένη στα δικαιώματα των ζώων, ο Σύλλογός μας από κοινού με τον φιλοζωικό Σύλλογο Λέσβου και τον Υδάτινο στείλαμε
          επιστολή
          στην τοπική ένωση Δήμων και Κοινοτήτων ΤΕΔΚ ,ζητώντας να αποφασίσει, σαν συλλογικό όργανο, στο μέλλον να μην παραχωρείται άδεια εγκατάστασης σε τσίρκο με ζώα,
          σε κανένα Δήμο της Λέσβου.<br /><br /><br /><br /><br /><br /><br /><br /></Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>5η Ιουνίου Παγκόσμια ημέρα για το περιβάλλον</Typography>
        <img src={Image12} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Με την ευκαιρία της εορτής της Παγκόσμιας ημέρας για το περιβάλλον ο Σύλλογός μας διοργάνωσε διαγωνισμό ανάμεσα στους μαθητές του Δημοτικού Σχολείου
          Λουτρών
          ξεχωριστά για κάθε τάξη με την συνεργασία των δασκάλων. Οι  Α! , Β! , Γ! και Δ! τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα <b>"Ζωγράφισε μια παραλία της περιοχής
            μας που σου αρέσει"</b>  οι δε  Ε! και ΣΤ! τάξεις στην έκθεση με θέμα <b>"Πώς να προστατέψουμε τις παραλίες της περιοχής μας και τι προβλήματα μπορεί να προκύψουν
              γι αυτές"</b><br /><br /><br /><br /></Typography>
      </Box>
      <ScrollToTopButton />


      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Λειτουργία στο εκκλησάκι του Αγίου Παντελεήμονα</Typography>
        <img src={Image13} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Όπως κάθε χρόνο έτσι και φέτος γιορτάσθηκε με λαμπρότητα και πλήθος πιστών από τα Λουτρά τη Σκάλα Λουτρών και τη Μυτιλήνη η εορτή του Αγίου Παντελεήμονα
          στο
          ομώνυμο εκκλησάκι στη Σκάλα Λουτρών στις 27 Ιουλίου 2007. Είναι πλέον γνωστό πως κάθε χρόνο όλο και περισσότεροι πιστοί προσέρχονται για να προσευχηθούν και να
          θαυμάσουν το γραφικό αυτό εκκλησάκι του οικισμού μας το οποίο ως γνωστό έχει ανακαινισθεί πλήρως με την βοήθεια των κατοίκων και του Συλλόγου μας. Επίσης θέλουμε
          να εκφράσουμε τις ευχαριστίες μας στον ιερέα του Ιερού Ναού Ταξιαρχών Μανταμάδου κ. Ευστράτιο Δήσσο για την δωρεά προς το εκκλησάκι δέκα ξυλόγλυπτων καθισμάτων. Στο
          ίδιο εκκλησάκι επίσης  αγοράσθηκε και τοποθετήθηκε καινούργια καμπάνα.</Typography>
      </Box>
      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Βράβευση αριστούχων</Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>Φέτος για πρώτη φορά στα χρονικά του Συλλόγου μας δεν πραγματοποιήθηκε η καλοκαιρινή μας χοροεσπερίδα λόγω του αιφνίδιου θανάτου μιας νέας κοπέλας από τον οικισμό
          μας συμμετέχοντας έτσι και εμείς με τον τρόπο μας στο πένθος της οικογένειας της εκλιπούσης. Αντί της χοροεσπερίδας πραγματοποιήθηκε μέσα στον αύλειο χώρο του
          Μουσείου στις 12-8-07 μία σεμνή εκδήλωση για την βράβευση των αριστούχων μαθητών του χωριού μας. </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Βραβεύτηκαν οι παρακάτω μαθητές και μαθήτριες:</Typography>
        <ul>
          <li><b>Σφακιανός Κωσταντίνος</b> από την Α! Γυμνασίου.</li>
          <li><b>Κρητικού Αφροδίτη</b> από την Γ! Γυμνασίου.</li>
          <li><b>Παπαχρυσού Παναγιώτα</b> από την Γ! Γυμνασίου.</li>
          <li><b>Κούβαρης Ευστράτιος</b> από την Γ! Λυκείου.</li>
          <li><b>Παπουτσής Παναγιώτης</b> από την Γ! Λυκείου</li>
          <li><b>Τριανταφύλλου Χριστίνα</b> από την Γ! Λυκείου.</li>
        </ul>
        <Typography variant="h3" sx={theme.typography.typography2}>Επίσης εδόθησαν έπαινοι σε έξη παιδιά του Δημοτικού Σχολείου Λουτρών [ένα από κάθε τάξη] γιατί πρώτευσαν σε διαγωνισμό ο οποίος είχε πραγματοποιηθεί σε
          συνεργασία με τον Σύλλογό μας την 5 Ιουνίου παγκόσμια ημέρα για το περιβάλλον. Οι μαθητές που βραβεύτηκαν είναι οι παρακάτω.</Typography>
        <ul>
          <li>Από την  Α!  τάξη ο <b>Σαράκης Ακίνδυνος.</b></li>
          <li>Από την  Β!  τάξη η <b>Αραμπατζής Μιλτιάδης</b></li>
          <li>Από την  Γ!  τάξη ο <b>Γιουρέλης Παναγιώτης.</b></li>
          <li>Από την  Δ!  τάξη η <b>Μηλιώνη Αιμιλία.</b></li>
          <li>Από την  Ε!  τάξη η <b>Βατζάκα Μαριλένα.</b></li>
          <li>Από την  ΣΤ! τάξη η <b>Ταλιαντζή Κατερίνα.</b></li>
        </ul>
        Σε όλα τα παιδιά εκτός από τον έπαινο εδόθησαν και διάφορα δώρα προσφορά του Συλλόγου μας.
        <Typography variant="h3" sx={theme.typography.typography2}>Στο τέλος της εκδήλωσης έγινε προβολή ενός ιστορικού ντοκιμαντέρ για τη Μικρασιατική εκστρατεία του συμπατριώτη μας κ. Στέλιου Σαμαρά τον οποίο και
          ευχαριστούμε θερμώς.</Typography>

        <b>ΛΑΧΕΙΟΦΟΡΟΣ ΑΓΟΡΑ</b>
        <Typography variant="h3" sx={theme.typography.typography2}>Kατά την διάρκεια της παραπάνω εκδήλωσης έγινε και η κλήρωση των δώρων της λαχειοφόρου αγοράς του Συλλόγου μας και κέρδισαν οι παρακάτω αριθμοί.
          <ul>
            <li>1ος  λαχνός  το νούμερο   <b>437</b>  κέρδισε ένα ποδήλατο </li>
            <li>2ος  λαχνός  το νούμερο   <b>251</b>  κέρδισε ένα  DVD </li>
            <li>3ος  λαχνός  το νούμερο   <b>173</b>  κέρδισε ένα ραδιόφωνο</li>
            <li>4ος  λαχνός  το νούμερο   <b>518</b> κέρδισε ένα ανεμιστήρα</li>
          </ul>
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Σύσκεψη για την εκφόρτωση καυσίμων στο Πέραμα </Typography>
        <img src={Image14} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Στις 16 Οκτωβρίου 2007 συμμετείχε ο Σύλλογός μας σε κοινή σύσκεψη με τους Συλλόγους του Κόλπου Γέρας όπου και απεφασίσθη η παρακάτω επιστολή να σταλεί στον
          Δήμαρχο και το Δημοτικό Συμβούλιο Γέρας. Κύριε Δήμαρχε με την υπ αρ. 162/2007 απόφαση του Δημοτικού Συμβουλίου Γέρας εγκρίθηκε υπό κάποιους όρους η μελλοντική
          (από τις 18-12-2007) διακίνηση υγρών καυσίμων από το λιμάνι  Περάματος του κόλπου Γέρας.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Επειδή για το θέμα αυτό υπάρχει σχετική απόφαση του Συμβουλίου της Επικρατείας την οποία πιθανώς να μην γνωρίζετε λεπτομερώς και η οποία απαγορεύει την
          εγκατάσταση και την διακίνηση υγρών καυσίμων στον Κόλπο της Γέρας σας επισυνάπτουμε την απόφαση αυτή υπ. Αρ. 4633/1997 και σας παρακαλούμε να συζητήσετε εκ νέου
          το θέμα της διακινήσεως υγρών καυσίμων από το λιμάνι του Περάματος έτσι ώστε να αποφευχθούν μελλοντικές προστριβές και αποδόσεις ευθυνών όπου υπάρξουν.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Συγκεκριμένα το Σ.τ.Ε. αφού είδε τα σχετικά έγγραφα της προσφυγής που είχε γίνει το 1997 εσκέφθη κατά νόμο και αποφάσισε εκτός των άλλων στην παράγραφο 7 τα εξής:</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}><b>«Επειδή, υπό τα ανωτέρω δεδομένα, ήτοι εφ όσον ο κόλπος της Γέρας αποτελεί ευαίσθητον οικοσύστημα, χερσαίον και θαλάσσιον, με μεγάλην οικολογικήν αξίαν συνδυάζει
          δε επιπροσθέτως και την αξίαν του υψηλού αισθητικού κάλλους, είναι ούτος, κατά τα προεκτεθέντα, δεκτικός μόνον ηπίας διαχειρίσεως, προς την οποίαν είναι προδήλως
          ασύμβατος η εγκατάστασις και λειτουργία δεξαμενών αποθηκεύσεως <u>και διακινήσεως υγρών καυσίμων</u>, οι οποίοι καθ εαυτό συνεπάγονται πρόδηλον επιβάρυνσιν και κίνδυνον
          δια το περιβάλλον…»</b></Typography>
        <Typography variant="h3" sx={theme.typography.typography2}> Κατόπιν τούτου σας παρακαλούμε να μας γνωρίσετε τις προθέσεις σας επί του θέματος έτσι ώστε να γνωρίζουμε και εμείς εάν θα προβούμε σε περαιτέρω ενέργειες ώστε
          να αποτρέψουμε την διακίνηση καυσίμων από το λιμάνι Περάματος στον κόλπο της Γέρας.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Η ανωτέρω επιστολή κοινοποιήθηκε στους παρακάτω εμπλεκόμενους φορείς,  Λιμενικό Ταμείο Λέσβου, Περιφέρεια Βορείου Αιγαίου, Νομαρχία Λέσβου, Λιμεναρχείο Μυτιλήνης
          και Περάματος, Ομοσπονδία Λεσβιακών Συλλόγων Αθήνας [Ο.Λ.Σ.Α.], Παγγεραγωτικός Σύλλογος Αθήνα, Εταιρεία πετρελαιοειδών ΕΛΙΝΟΙΛ και Μ.Μ.Ε.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Την επιστολή εκτός από το Σύλλογό μας υπέγραψαν και οι παρακάτω Σύλλογοι.  «Παγγεραγωτικός» Πολιτιστικός  Σύλλογος Γέρας, Πολιτιστικός Σύλλογος Παλαιοκήπου,
          Αθλητικός Μορφωτικός Σύλλογος «Ποσειδων», Πολιτιστικός Σύλλογος Μεσαγρού «Ειρήνη», Πολιτιστικός Σύλλογος Κεραμείων «Άγιος Γεώργιος», Πολιτιστικός Σύλλογος
          Ιπείου «Άγιος Προκόπιος», Πολιτιστικός Σύλλογος Συκούντας «Άγιος Κωνσταντίνος».</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Τοποθέτηση συναγερμού στο Μουσείο Προσφυγικής Μνήμης 1922</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Με απόφαση του Διοικητικού Συμβουλίου του Συλλόγου μας αγοράσθηκε και τοποθετήθηκε συναγερμός πολλαπλών σημείων στο Μουσείο Προσφυγικής Μνήμης 1922
          για να εξασφαλίσουμε την σωστή διαφύλαξη των πολύτιμων προσφυγικών κειμηλίων που μας εμπιστεύθηκαν τόσο οι κάτοικοι του Οικισμού μας όσο και οι λοιποί δωρητές. Έτσι
          λοιπόν θα πρέπει να γνωρίζουν όλοι όσοι μας εμπιστεύθηκαν τα κειμήλια αυτά ότι, εμείς από την πλευρά μας λαμβάνουμε όλα τα μέτρα τόσο για την σωστή προβολή όσο
          και για την διαφύλαξή τους.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Αγορά μεγαφωνικής εγκατάστασης</Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>Με απόφαση του Διοικητικού Συμβουλίου του Συλλόγου μας αγοράσθηκε και τοποθετήθηκε μεγαφωνική εγκατάσταση στο Μουσείο Προσφυγικής Μνήμης 1922 με την οποία
          έχουμε πλέον την δυνατότητα να κάνουμε ανακοινώσεις με τις οποίες θα ενημερώνουμε όλα τα μέλη και τους κατοίκους του Οικισμού μας. Με αυτή μας την προσπάθεια
          λύθηκε ένα ακόμα πρόβλημα που υπήρχε όσον αφορά την σωστή και άμεση ενημέρωση των μελών μας.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Λειτουργία στην Προσφυγοπαναγιά την Αψηλή.</Typography>
        <img src={Image15} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Με την συμμετοχή των μελών του Συλλόγου μας και πλήθος κόσμου που ήρθε από τα Λουτρά τη Μυτιλήνη και τα χωριά της Γέρας (όπου ως γνωστό υπάρχουν αρκετοί
          πρόσφυγες ιδίως στο Πέραμα), ετελέσθη η καθιερωμένη λειτουργία με αρτοκλασία και επιμνημόσυνη δέηση υπέρ αναπαύσεως των ψυχών των προσφύγων κατοίκων του οικισμού
          μας που έχει καθιερωθεί να εορτάζετε στις  22 Αυγούστου μαζί με τα εννιάμερα της Παναγίας στην προσφυγοπαναγιά την Αψηλή. Ο καιρός ήταν θαυμάσιος και αποζημίωσε με
          το παραπάνω όσους έκαναν τον κόπο και ανέβηκαν μέχρι την κορυφή του λόφου. Στο τέλος της Θείας Λειτουργίας και αφού ετελέσθη η επιμνημόσυνη δέηση τα μέλη του
          Συλλόγου μας πρόσφεραν καφέ , γλυκό και διάφορα εδέσματα στους προσκυνητές.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Μορφωτικές επισκέψεις στο Μουσείο μας.</Typography>
        <img src={Image16} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Οι επισκέπτες που ήρθαν εδώ μέχρι σήμερα υπερβαίνουν τους 3000 συμπεριλαμβανομένων και  αρκετών σχολείων  από το νησί μας και την υπόλοιπη Ελλάδα.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Ενδεικτικά αναφέρουμε το Εφέσιο Γυμνάσιο Ν. Σμύρνης, τα εκπαιδευτήρια Πλάτωνος, το Πειραματικό Γυμνάσιο Μυτιλήνης το 10ο Δημ. Σχολείο Μυτιλήνης , το Δημ. Σχολείο
          Λουτρών κ.α. Αξιομνημόνευτη  η επίσκεψη του Ιδρύματος Μείζονος Ελληνισμού καθώς και του Ιδρύματος ανταλλαγέντων της Λωζάνης από την Κωνσταντινούπολη.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Πολύ εντυπωσιακή η παρουσίαση, εδώ σ αυτό το χώρο, από τον κ. Χάρη Γιακουμή του φωτογραφικού αρχείου του Γάλου φωτογράφου Φελίξ Σαρτιό που αφορούσε τον πρώτο
          διωγμό του 1914 των Ελλήνων από τις Φώκαιες της Μ.Ασίας μια παρουσίαση με ξεχωριστή σημασία για μας αφού το 60% περίπου των κατοίκων αυτού του οικισμού είναι Φωκιανοί.
          Και όλα αυτά χωρίς να έχει υπάρξει πουθενά σχετική διαφημιστική προβολή. Οι κολακευτικές αναφορές στο βιβλίο των επισκεπτών είναι πολλές και μας δίνουν το κουράγιο
          να συνεχίσουμε την προσπάθειά μας αυτή. Ας αναφέρουμε τι λένε μερικές από αυτές.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Κάποτε πριν 36 χρόνια όταν έφευγα από την Κωνσταντινούπολη πίστευα ότι έχασα τα πάντα. Σήμερα είχα την τύχη και την τιμή να βρω αυτά που έχασα. Ευχαριστώ αυτούς
          τους μικρούς «Γαλάτες» που έφτιαξαν τον υπέροχο μικρόκοσμό μου. Ο Θεός πάνω να τους έχει καλά”.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Λένε πως όταν κλείνει ένα σχολείο ανοίγει μια φυλακή. Σ αυτή την περίπτωση ένα σχολείο έκλεισε και άνοιξε ένα Μουσείο, χάρις στις προσπάθειες κάποιων. Θερμά
          συγχαρητήρια σε όλους.” </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Καταθέτω το σεβασμό μου για το υπέροχο έργο που επιτελέσθηκε. Ένα έργο που ενώνει τις γενιές με τα ενθυμήματα που αφήνουν οι μεγαλύτεροι ως παρακαταθήκη στους
          νεότερους. Αυτό όμως που έγινε στη Σκάλα Λουτρών εκτός όλων των άλλων αποτελεί τη συνέχεια μιας φοβερής και θλιβερής ιστορίας που οι άνθρωποι που την έζησαν τη
          μετέτρεψαν σε δημιουργία. Σε όλους αυτούς αξίζει απεριόριστος σεβασμός και στους δημιουργούς του Μουσείου θερμά συγχαρητήρια. Εύχομαι να συνεχίσετε το υπέροχο  και
          μοναδικό σας έργο”. </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Ένα μεγάλο ευχαριστώ για σας που ποιήσατε αυτό το οίκημα, που κρατά άσβεστη τη μνήμη των προσφύγων μας. Ένοιωσα την ψυχή μου να ταξιδεύει στης Σμύρνης τα Άγια
          χώματα, στη γη των προγόνων μου και μέθυσα απ τις θύμισες της γιαγιάς μου της Κατέρης που με τις ιστορίες της μεγάλωσα. Εύγε.” </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Εδώ γεννήθηκα στη Σκάλα Λουτρών, εδώ μεγάλωσα μαζί με τους φίλους μου μικρασιάτες, εδώ θα μένω όσο ζω. Ζηλεύω που δεν είμαι μικρασιάτης, τους θαυμάζω, τους αγαπώ.
          Αξίζουν συγχαρητήρια, είναι όλοι τους αξιόλογοι άνθρωποι και θα πρέπει να χαίρονται που τους ζηλεύουν.” </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Εμείς τώρα πλέον τι παραπάνω να πούμε, παρά να κλείσουμε με ένα τετράστιχο που λέει.<br />
          Μικρασία, του κόσμου καμάρι εσύ,<br />
          Μικρασία, χαμένη πατρίδα!<br />
          Για ιδές τα παιδιά σου μακριά σου,<br />
          Πέρ απ τη ζεστή την αγκαλιά σου	</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Επιστολή του Συλλόγου μας προς το Δημοτικό Συμβούλιο και την Επιτροπή ονοματοθεσιών του Δήμου Μυτιλήνης για την σήμανση οδών της Σκάλας Λουτρών.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Κύριε Δήμαρχε, Με την παρούσα επιστολή σας γνωρίζουμε τα παρακάτω.</Typography>

        <ul>
          <li>Προ ενός έτους ο Σύλλογός μας δημιούργησε στη Σκάλα Λουτρών ένα μουσείο ιστορικό- λαογραφικό με την επωνυμία «Μουσείο Προσφυγικής Μνήμης
            1922» το οποίο μάλιστα εγκαινιάσθηκε από τον Οικουμενικό Πατριάρχη κ. Βαρθολομαίο στις 12 Αυγούστου του 2006.</li>
          <li>Πρόσφατα με πρωτοβουλία των νέων του οικισμού μας τοποθετήθηκαν γύρω από το μουσείο 6 συνολικά πινακίδες οδών με τις  ονομασίες [οδός Μ. Ασίας,
            οδός Κρήνης, οδός Εγγλεζονησίων, οδός Τσανταρλί, οδός Δικελί και οδός Ατζανός]. Η τοποθέτησή τους είχε τελείως συμβολικό χαρακτήρα, αφ ενός λόγω της καταγωγής
            των παππούδων και των γιαγιάδων τους από τα απέναντι Μικρασιατικά παράλια και αφ εταίρου για να πλαισιώσουν τον χώρο του υπάρχοντος  Μουσείου. Ας σημειωθεί ότι,
            η Σκάλα Λουτρών είναι ένα χωριό με πληθυσμό που στην συντριπτική του πλειοψηφία αποτελείται από Μικρασιάτες πρόσφυγες που εγκαταστάθηκαν εδώ μετά τον διωγμό
            του 1922.</li>
          <li>Επειδή σε αυτή την συμβολική σήμανση των οδών γύρω από το Μουσείο αντέδρασε το Τοπικό Συμβούλιο Λουτρών και προτείνει την αποκαθήλωσή τους, σας παρακαλούμε
            να μας γνωρίσετε εάν πρέπει να γίνουν κάποιες ενέργειες από την πλευρά του Συλλόγου μας που ενδιαφέρεται για το θέμα αυτό, έτσι ώστε αυτές οι πινακίδες να
            παραμείνουν ως έχουν πλαισιώνοντας όπως προαναφέραμε το Μουσείο Προσφυγικής Μνήμης 1922. Σ αυτό το αίτημά μας έχουμε την ομόφωνη απόφαση του Δ.Σ. του
            Συλλόγου μας καθώς και των 316 μελών μας. Συμπληρωματικά σας γνωρίζουμε πως στην Σκάλα Λουτρών δεν υπάρχουν καθόλου σημάνσεις οδών ούτε και έχει κάποιο άλλο
            νόημα να τοποθετηθούν πινακίδες (πλην του συμβολικού χαρακτήρα) δεδομένου ότι υπάρχουν ελάχιστα σπίτια και οι λίγοι κάτοικοι που υπάρχουν εδώ γνωρίζονται όλοι
            μεταξύ τους.</li>
        </ul>
        <Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Η Σκάλα Λουτρών τόπος πολιτιστικού και εκπαιδευτικού τουρισμού</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Για δεύτερη χρονιά φέτος επισκέφτηκαν τη Σκάλα Λουτρών οι σπουδαστές ελληνικής γλώσσας που παίρνουν μέρος στο πρόγραμμα ελληνικής γλώσσας και πολιτισμού που
          οργανώνει η Εταιρεία Πολιτιστικής Ανάπτυξης Λέσβου Αιολίς. Οι επισκέπτες φιλοξενήθηκαν στον ανακαινισμένο χώρο του ξενοδοχείου Ζαΐρα και έμαθαν και πάλι από τον
          πρόεδρο του Συλλόγου κ. Παπαχρυσό τα σχετικά με τις δραστηριότητες του συλλόγου και των κατοίκων του χωριού. </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Το σημαντικό είναι όχι μόνο ότι έρχονται άνθρωποι από όλο τον κόσμο, αλλά ότι ήρθαν και πολλοί για δεύτερη φορά. Τι συμπεράσματα μπορούμε να βγάλουμε από αυτό;</Typography>

        <ul>
          <li>Ότι η Λέσβος και ειδικότερα η Σκάλα Λουτρών είναι ένας προορισμός εκπαιδευτικού και πολιτιστικού τουρισμού ή ότι, τουλάχιστον, θα μπορούσε να γίνει σταθερά
            τέτοιος τόπος, ο οποίος να γίνεται πόλος έλξης επισκεπτών με ειδικό ενδιαφέρον για τον πολιτισμό της σύγχρονης Ελλάδας. </li>
          <li>Ότι χρειάζεται να προσέξουμε περισσότερο τις υποδομές του χωριού αλλά και ολόκληρου του νησιού. Η νέα καντίνα και η βιβλιοθήκη της Σκάλας Λουτρών είναι θετικά
            βήματα . Η παραλία όμως χρειάζεται καθαρισμό όπως και πολλά σημεία του χωριού. Οι πινακίδες για τον προσανατολισμό του επισκέπτη είναι απαραίτητες, πινακίδες που
            να μιλούν και για το χωριό, τον ιδιαίτερο χαρακτήρα και την ιστορία του.</li>
          <li>Το Μουσείο Μικρασιατικής Μνήμης ήδη αποτελεί έναν επισκέψιμο χώρο που έλκει και εντυπωσιάζει τους επισκέπτες, έλληνες και ξένους. Η επίσκεψη γίνεται πιο
            εντυπωσιακή μάλιστα, με τη συμβολή των ατόμων που ασχολούνται εθελοντικά με την υποδοχή των επισκεπτών. Συγχαρητήρια αξίζουν στον πρόεδρο του Συλλόγου κ. Δ. Παπαχρυσό
            αλλά και στον δάσκαλο και καλλιτέχνη κ. Σ. Βαλαχή, για την υποστήριξη, με ελάχιστα μέσα, μιας τέτοιας σημαντικής προσπάθειας, η οποία άρχισε κιόλας να αποδίδει
            καρπούς.</li>
          <li>Η Βιβλιοθήκη του χωριού μπορεί να γίνει ζωντανός τόπος συνάντησης των κατοίκων με τους επισκέπτες, ώστε να μαθαίνει κανείς από την επαφή με άτομα άλλων
            πολιτισμών και άλλης κουλτούρας.</li>
          <li>Καλό θα ήταν να σχεδιαστούν και να υλοποιούνται περιπατητικές διαδρομές γύρω από τη Σκάλα Λουτρών και στην εκκλησία της Παναγίας στο λόφο, με οδηγό κάποιον
            που γνωρίζει καλά τα μονοπάτια αλλά και την ιστορία και τους θρύλους του τόπου.</li>
          <li>Χρειάζεται να αναβιώσουμε ορισμένα  έθιμα που είναι ακόμη ζωντανά στο χωριό και στο νησί, όπως αυτό του Κλήδονα, όχι μόνο για τους επισκέπτες, αλλά κυρίως
            για τους κατοίκους και τους ειδικά τους νέους.</li>
          <li>Είναι καλό να προσπαθήσουμε να αποκτήσει το χωριό σταθερή πρόσβαση το καλοκαίρι στις κοντινές παραλίες του Αγ. Ερμογένη και της Χαραμίδας, μιας και αυτό θα
            γίνει αφορμή να επισκέπτονται και τη Σκάλα Λουτρών άτομα που θα ήθελαν μετά το μπάνιο τους να επισκεφτούν το χωριό και το Μουσείο του.</li>
        </ul>

        <img src="drastiriotites2007/peripatos.jpg" width="300" height="200" style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Η Αιολίς ήδη έχει εκπονήσει ορισμένους <b>Οδηγούς για τους Επισκέπτες </b>της Λέσβου, με πληροφορίες για τα μνημεία, τα μουσεία, τους ναούς, τα μοναστήρια, τους
          αρχαιολογικούς  χώρους, τους πολιτιστικούς συλλόγους και τα πανηγύρια της Λέσβου. </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Επίσης έχει λάβει την πρωτοβουλία να συστήσει ένα <b>Δίκτυο Πολιτιστικού Τουρισμού</b>, στο πλαίσιο του Ε. Π. «Απασχόληση και Επαγγελματική Κατάρτιση» της Ειδικής
          Υπηρεσίας Εφαρμογής Συγχρηματοδοτούμενων ενεργειών του Υπ. Απασχόλησης, για την ενίσχυση της απασχόλησης με την ενεργό συμμετοχή των ΜΚΟ και την  συγχρηματοδότηση
          του Ειδικού Κοινωνικού Ταμείου. Το Δίκτυο αυτό θα συνεχίσει τη λειτουργία του και μετά το τέλος του προγράμματος, μιας και θεωρείται από όλους αυτούς που συμμετέχουν
          ως μία σημαντική πρωτοβουλία για την προσέλκυση στη Λέσβο επισκεπτών που ενδιαφέρονται για πολιτιστικό τουρισμό. </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}><b>Η Αιολίς καλεί όλους τους κατοίκους</b> της Σκάλας Λουτρών να συμμετάσχουν ενεργά στις δραστηριότητές της, με το να παρουσιάζουν τη δουλειά, το έργο τους  και τη
          ζωή τους σε ανθρώπους που, όπως θα δουν οι ίδιοι, έχουν ήδη γνώσεις για τη σύγχρονη Ελλάδα και τον πολιτισμό της, να κάνουν δωρεάν πρακτική εξάσκηση στην ξένη
          γλώσσα που μαθαίνουν, συνομιλώντας με τους ξενόγλωσσους με τη μέθοδο Tandem και την καθοδήγηση των καθηγητών της Εταιρείας. Αντίστοιχα, είναι ο καθένας ευπρόσδεκτος
          να παρακολουθεί τις παρουσιάσεις που κάνουν οι σπουδαστές κάθε δύο εβδομάδες το καλοκαίρι (το πρόγραμμα του 2008 θα γίνει το Πάσχα και από τον Ιούνιο έως και το
          Σεπτέμβριο) όπου μιλούν για τις πόλεις τους και τα έθιμα των χωρών τους, να λαμβάνει δωρεάν μέρος στα μαθήματα μαγειρικής και στις άλλες δραστηριότητες της Αιολίς,
          οι οποίες θα ανακοινώνονται έγκαιρα.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Το σημαντικό κριτήριο της επιτυχίας ενός τέτοιου προγράμματος δεν είναι ο αριθμός των επισκεπτών που προσελκύονται, αλλά η ικανοποίησή τους από τη συμμετοχή
          τους στη ζωή και τα δρώμενα του τόπου που επισκέπτονται, και, βέβαια, η απόφασή τους να επιστρέψουν εδώ.</Typography><br />
        <b>Ιφιγένεια Γεωργιάδου</b><br />
        <b>Εταιρεία Πολιτιστικής Ανάπτυξης Λέσβου «Αιολίς»</b>
        <Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
      </Box>

      <ScrollToTopButton />




      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Αλιευτικός Τουρισμός</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε στους Πύργους Μεσαγρού Γέρας η συνάντηση για τον αλιευτικό τουρισμό στην οποία συμμετείχε και ο πρόεδρος του Συλλόγου μας ως εκπρόσωπος
          του Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων. Ο κύριος στόχος του προγράμματος είναι η ανάπτυξη αυτής της μορφής τουρισμού στις περιοχές της Ανατολικής
          Μεσογείου, σε μια προσπάθεια να μειωθούν τα οξυμένα προβλήματα που αντιμετωπίζουν οι ψαράδες. Το κοινοτικό πρόγραμμα που χρηματοδοτεί την ανάπτυξη του αλιευτικού
          τουρισμού, περιλαμβάνει δράσεις για την ανάλυση της υφιστάμενης κατάστασης, τη δημιουργία διαχειριστικού πλαισίου ανάπτυξης αλιευτικού τουρισμού, την ευαισθητοποίηση
          της τοπικής κοινωνίας και των φορέων που εμπλέκονται άμεσα σε αυτές, καθώς και δράσεις ενημέρωσης και προώθησης. Επίσης οι στόχοι του προγράμματος εξυπηρετούν άμεσα
          τις πολιτικές της Ευρωπαϊκής Ένωσης για την ανάπτυξη του τουρισμού και τη διαφοροποίηση του τουριστικού προϊόντος. Μέσα από αυτό το έργο η Περιφέρεια Βορείου Αιγαίου
          επιδιώκει να θέσει τις βάσεις ώστε οι ψαράδες των νησιών μας να ωφεληθούν με τη δημιουργία εναλλακτικού εισοδήματος, τη συγκράτηση του πληθυσμού στις περιοχές του
          και κυρίως της νεολαίας, την ενημέρωση για περιβαλλοντικά θέματα, την προστασία του περιβάλλοντος, την ανάδειξη της τοπικής πολιτιστικής παράδοσης, την βελτίωση
          της ποιότητας ζωής, την ενημέρωση και εκπαίδευση στις νέες τάσεις για τον τουρισμό, την συνεργασία με κατοίκους άλλων περιοχών – χωρών.
          Ο Σύλλογός μας θα παρακολουθεί το πρόγραμμα και θα ενημερώνει τους ενδιαφερομένους.</Typography>
      </Box>

      <ScrollToTopButton />




      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Αρχαιρεσίες στον Σύλλογό μας.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκαν με μεγάλη επιτυχία στις 25 Μαρτίου 2007 οι αρχαιρεσίες στον Σύλλογό μας αφού επί 305 εγγεγραμμένων μελών ψήφισαν 251 μέλη μας. Κατόπιν
          τούτου η νέα σύνθεση του Δ.Σ. είναι η παρακάτω:</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Πρόεδρος ο κ. Δημήτρης Παπαχρυσός, Αντιπρόεδρος η κ. Ευθαλία Τουρλή, Γενικός Γραμματέας ο κ. Παναγιώτης Βαλαχής, Ταμίας η δ. Αγγελική Σταματέλλη, Μέλη οι
          κ. Κατερίνα Γρηγορέλλη,  Βικτωρίτσα Ευαγγέλου, Εμμανουήλ Τζανέτος, Αναπληρωματικά μέλη οι κ. Νάσια Δαφιώτη, Ευαγγελία Λημναίου και ο κ. Παναγιώτης Κατσακούλης.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Επίσης ορίστηκαν νέοι υπεύθυνοι τμημάτων ως παρακάτω.
          <ul>
            <li>Στον Πολιτιστικό τομέα η κ. Νάσια Δαφιώτη με την Βικτωρίτσα Ευαγγέλου, την Ευαγγελία Λημναίου και την Κατερίνα Γρηγορέλλη.</li>
            <li>Στα θέματα περιβάλλοντος ο κ. Εμμανουήλ Τζανέτος με την Αγγελική Σταματέλλη και τον Δημήτρη Παπαχρυσό.</li>
            <li>Υπεύθυνοι για το Μουσείο Προσφυγικής Μνήμης 1922 ορίστηκαν ο κ. Σταύρος Βαλαχής με την κ. Ευθαλία Τουρλή και τέλος υπεύθυνοι στα μουσικά τμήματα ορίστηκαν οι
              κ. Κατερίνα Γρηγορέλλη μαζί με την Αγγελική Σταματέλλη και την Βικτωρίτσα Ευαγγέλου.</li>
          </ul></Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
      </Box>
      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα Αρμονίου</Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>Λειτούργησε μέχρι τον παρελθόντα Ιούνιο οπότε και σταμάτησε λόγω έλειψης μαθητών. Το τμήμα θα επαναλειτουργήσει μόλις εκδηλωθεί σχετικό ενδιαφέρον.
          Όσοι λοιπόν ενδιαφέρονται για τα παιδιά τους μπορούν να το δηλώσουν στο τηλ. 22510 91231 κ. Παπαχρυσό Δημ. η στο 22510 91302 κ. Γρηγορέλλη Κατερίνα.
          Η μηνιαία συνδρομή είναι 15 ευρώ.<br /><br /><br /></Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα Κιθάρας</Typography>
        <img src={Image17} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
        <Typography variant="h3" sx={theme.typography.typography2}>Λειτουργούν κάθε Σάββατο από τις 2 το μεσημέρι μέχρι τις 5 το απόγευμα και όσοι ενδιαφέρονται για τα παιδιά τους μπορούν να το δηλώσουν
          στα ανωτέρω προαναφερθέντα τηλέφωνα . Η μηνιαία συνδρομή είναι και εδώ 15 ευρώ και δάσκαλος είναι ο κ. Μολυβιάτης Γρηγόρης ο οποίος επί πολλά χρόνια έχει
          προσφέρει εξαιρετικό έργο στα παιδιά του Συλλόγου μας.<br /><br /><br /><br /><br /><br /></Typography>
      </Box>

      <ScrollToTopButton />





      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα Παραδοσιακών Χορών και Τραγουδιών</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>O Σύλλογός μας προτίθεται να επαναλειτουργήσει τμήματα παραδοσιακών χορών και τραγουδιών από τη Μ. Ασία γι αυτό όσοι ενδιαφέρονται μικροί και μεγάλοι
          μπορούν να έλθουν σε συνεννόηση με την κ. Ευθαλία Τουρλή στο τηλ. 22510 91170 για να το δηλώσουν.</Typography>
      </Box>

      <ScrollToTopButton />




      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Νέα Τμήματα</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας προτίθεται να ιδρύσει νέα τμήματα παραδοσιακών μουσικών οργάνων εφ όσον εκδηλωθεί σχετικό ενδιαφέρον από τα μέλη μας. Διευκρινίζουμε ότι
          για να λειτουργήσει ένα νέο τμήμα θα πρέπει να ενδιαφερθούν κατ ελάχιστο τρία άτομα.</Typography>
      </Box>

      <ScrollToTopButton />




      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Δωρεά βιβλίων στο Σύλλογό μας. </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Ευχαριστούμε θερμά  τους  κ.κ. Δέσποινα Λημναίου-Καρέκου, Μέλπω Αλμπάνη, Γεώργιο Πρίμο, Αριστείδη Κλήμη, Ιφιγένεια Γεωργιάδου, Βικτωρίτσα Ευαγγέλου,
          Αριστείδη Κουτζαμάνη, Θεόδωρο Πελεκάνο, Παντελή Τζανέτο, Γεώργιο Ντίνια, Νικόλαο Κατσαλή, Μαρία Καπαρού, Γονιδέλλη Ακίνδυνου, Αλέξανδρο Χατζηδήμο, τη
          Νομαρχία Λέσβου, το Υπουργείο Αιγαίου, και το τυπογραφείο Δημητρίου Δούκα για τα βιβλία που προσέφεραν στη βιβλιοθήκη του Συλλόγου μας.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα χρειάζονται να μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας η οποία ήδη στεγάζεται
          στο καινούργιο κτίριο που κατασκευάσαμε στη Σκάλα Λουτρών.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Το βιβλίο είναι ένα παράθυρο ανοιχτό, γιατί προσανατολίζει τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση είναι δύναμη. Η
          μόρφωση ευαισθητοποιεί το άτομο απέναντι στα προβλήματα που αποτελούν  τη ζωή του σύγχρονου ανθρώπου και τοποθετεί τις βάσεις για τη συνολική πρόοδο του
          πολιτισμού, συντελεί δε, στη διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και φανατισμούς.</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Ευχαριστήριο</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Ευχαριστούμε θερμά τους παρακάτω.<br />
          <ol style={{ paddingLeft: '20px' }}>
            <li style={{ marginBottom: '8px' }}>Τον τ.Υπουργό Αιγαίου κ. Αριστ. Παυλίδη για την οικονομική ενίσχυση 5.000 ευρώ υπέρ των δραστηριοτήτων του Συλλόγου μας.</li>
            <li style={{ marginBottom: '8px' }}>Το Νομάρχη Λέσβου κ. Παύλο Βογιατζή και το Νομαρχιακό Συμβούλιο Λέσβου για τη χορηγία τους στην έκδοση του ημερολογίου μας 2008.</li>
            <li style={{ marginBottom: '8px' }}>Τον ΟΤΕ Μυτιλήνης, την ΕΠΟΜ, τον κ. Δεμερτζή Δημήτρη, το ούζο ΣΜΥΡΝΙΩ και τους λοιπούς χορηγούς του ημερολογίου μας.</li>
            <li style={{ marginBottom: '8px' }}>Τα καταστήματα της Μυτιλήνης και όσους προσφέρουν δώρα στις εκδηλώσεις μας.</li>
            <li style={{ marginBottom: '8px' }}>Τον Σύλλογο «Υδάτινος» που ανταποκρίθηκε θετικά στο από 11-6-2007 αίτημά μας για τοποθέτηση κάδων ανακύκλωσης αλουμινίου.</li>
            <li style={{ marginBottom: '8px' }}>Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες του Συλλόγου καθώς και όσους προσφέρουν εθελοντικώς την εργασία τους.</li>
            <li style={{ marginBottom: '8px' }}>Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο μας.</li>
            <li style={{ marginBottom: '8px' }}>Ιδιαιτέρως ευχαριστούμε το συγχωριανό μας Δημήτρη Γρηγορέλλη που από ιδρύσεως του Συλλόγου μας , μας έχει παραχωρήσει αφιλοκερδώς το πατρικό του σπίτι στη Σκάλα Λουτρών για την στέγαση  δραστηριοτήτων του Συλλόγου μας.</li>
          </ol>
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
      </Box>

      <ScrollToTopButton />



      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Οικονομικές ενισχύσεις</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.<br /><br />
          <ul style={{ padding: '0 0 0 20px' }}>
            <li style={{ marginBottom: '8px' }}>Πελεκάνος Παναγιώτης - 50 ευρώ</li>
            <li style={{ marginBottom: '8px' }}>Λευκαδίτης Παναγιώτης - 30 ευρώ</li>
            <li style={{ marginBottom: '8px' }}>Ρετζέπης Ιωάννης - 50 ευρώ</li>
            <li style={{ marginBottom: '8px' }}>Παπαχρυσός Ιωάννης - 50 ευρώ</li>
            <li style={{ marginBottom: '8px' }}>Κατανάκης Μιχάλης - 50 ευρώ</li>
            <li style={{ marginBottom: '8px' }}>Κατσαλή Γεωργία - 50 ευρώ</li>
            <li style={{ marginBottom: '8px' }}>Κλήμης Αριστείδης - 100 ευρώ</li>
            <li style={{ marginBottom: '8px' }}>Δουκέλη Τούλα - 50 δολάρια</li>
          </ul>
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός  για αναβάθμιση του
          οικισμού μας, έτσι ώστε να μπορέσουμε να ανταποκριθούμε στις υποχρεώσεις που έχουμε αναλάβει και να ολοκληρώσουμε τους στόχους μας σε σύντομο χρονικό διάστημα.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>&nbsp;</Typography>
      </Box>

      <ScrollToTopButton />


      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>Διαγωνισμός ζωγραφικής και έκθεσης - 2007</Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε με επιτυχία ο διαγωνισμός ζωγραφικής και έκθεσης με τους μαθητές και τις μαθήτριες του Δημοτικού Σχολείου Λουτρών στις 5 Ιουνίου 2007 ,
          παγκόσμιας ημέρας εορτής για την προστασία του Περιβάλλοντος με την πολύ καλή  συνεργασία τόσο του διευθυντή του σχολείου κ. Γ. Μαλίδη όσο και όλων των υπολοίπων
          δασκάλων.</Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>Ο διαγωνισμός για τις τέσσερις  πρώτες τάξεις ήταν στην ζωγραφική με θέμα <b>"Ζωγράφισε μια παραλία της περιοχής μας που σου αρέσει"</b> και για τις δύο μεγαλύτερες
          τάξεις ήταν στην έκθεση με θέμα <b>"Πώς να προστατέψουμε τις παραλίες της περιοχής μας και τι προβλήματα μπορεί να προκύψουν γι αυτές"</b>. Η επιλογή της καλλίτερης
          ζωγραφικής και της καλλίτερης έκθεσης από κάθε τάξη έγινε από επιτροπή δασκάλων του σχολείου και όπως θα δείτε παρακάτω τα παιδιά μας δούλεψαν με μεράκι και φαντασία
          τόσο στην ζωγραφική όσο και στην έκθεση. Μάλιστα απέδειξαν ότι γνωρίζουν πολύ καλά το θέμα και ότι αγαπούν και ενδιαφέρονται ζωηρώς για το περιβάλλον της περιοχής μας
          γενικώς. Άλλωστε είναι βέβαιο ότι το μέλλον τους ανήκει και δείχνουν ότι αύριο θα γίνουν ευαισθητοποιημένοι πολίτες.</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Είναι γεγονός ότι όλες οι ζωγραφιές και όλες οι εκθέσεις των παιδιών ήταν εξαιρετικές εδώ όμως λόγω χώρου θα δημοσιεύσουμε τις πρώτες από κάθε τάξη. Να σημειώσουμε
          ότι τόσο οι ζωγραφιές όσο και οι εκθέσεις έγιναν στις αίθουσες των τάξεων του σχολείου και όχι στα σπίτια των παιδιών όπου θα μπορούσε να είχαν και την βοήθεια των
          γονιών τους και αυτό αναδεικνύει ακόμη περισσότερο αυτή τους την προσπάθεια. </Typography>
        <ul>Τα αποτελέσματα.
          <li>Α! Τάξη διαγωνισμός στην ζωγραφική. Το πρώτο βραβείο δόθηκε στον μαθητή Σαράκη Ακίνδυνο.</li>
          <li>Β! Τάξη διαγωνισμός στην ζωγραφική. Το πρώτο βραβείο δόθηκε στον μαθητή Αραμπατζή Μιλτιάδη.</li>
          <li>Γ! Τάξη διαγωνισμός στην ζωγραφική. Το πρώτο βραβείο δόθηκε στον μαθητή Γιουρέλη Παναγιώτη.</li>
          <li>Δ! Τάξη διαγωνισμός στην ζωγραφική. Το πρώτο βραβείο δόθηκε στην μαθήτρια Μηλιώνη Αιμιλία. </li>
          <li>Ε! Τάξη διαγωνισμός στην έκθεση. Το πρώτο βραβείο δόθηκε στην μαθήτρια Βατζάκα Μαριλένα.</li>
          <li>ΣΤ! Τάξη διαγωνισμός στην έκθεση. Το πρώτο βραβείο δόθηκε στην μαθήτρια Ταλιαντζή Κατερίνα</li>
        </ul>

        <h2>Παρακάτω δημοσιεύουμε τα βραβευμένα έργα.</h2>

        <h4>ΣΤ! Τάξη , Α! βραβείο Ταλιαντζή Κατερίνα</h4>
        <h4>Η Έκθεση:</h4>
        <Typography variant="h3" sx={theme.typography.typography2}>Η χώρα μας έχει πολλές παραλίες για κολύμπι. Δυστυχώς όμως είναι πνιγμένες από τα σκουπίδια. Έτσι οι άνθρωποι δεν μπορούν να απολαύσουν το μπάνιο τους. Γι αυτό
          πρέπει να ληφθούν κάποια μέτρα. Πρώτα πρώτα εμείς οι ίδιοι θα πρέπει να φροντίζουμε τις παραλίες και να μην πετάμε σκουπίδια, όπως χαρτάκια, μπουκάλια, τενεκεδάκια,
          νάιλον σακούλες κ.τ.λ. Πρέπει να τοποθετηθούν περισσότεροι σκουπιδοτενεκέδες και πινακίδες που να μας υπενθυμίζουν να κρατάμε την παραλία καθαρή και όχι τους
          κάδους απορριμμάτων. Ακόμη πρέπει να γίνει βιολογικός καθαρισμός για να μην πετάμε απόβλητα στη θάλασσα. Ακόμη παλιότερα κοντά στην περιοχή μας υπήρχαν εγκαταστάσεις
          πετρελαίου όπου μεταφερόταν πετρέλαιο με καράβια από τη θάλασσα. Πολλές φορές όμως τα καράβια έχυναν το πετρέλαιο στη θάλασσα με αποτέλεσμα να δημιουργείται μια
          μεγάλη εστία μόλυνσης. Έπρεπε να απομακρυνθεί κι έτσι έγινε. Όμως η μόλυνση είχε κάνει μεγάλη ζημιά στο περιβάλλον. Και το καρνάγιο της περιοχής όμως πρέπει να
          απομακρυνθεί διότι από τα καράβια πέφτουν σκουριές, λάδια από τις μηχανές, απόβλητα. Έτσι δημιουργείται ένας θαλάσσιος τάφος για τα ψάρια και τα πουλιά από τα
          μολυσμένα νερά. Όλο αυτό πρέπει να σταματήσει διότι η θάλασσα θα μολυνθεί σε τέτοιο βαθμό που δεν θα μπορούν να ζήσουν μέσα οι ζωντανοί οργανισμοί. Αποτέλεσμα θα
          είναι η εξαφάνιση της θαλάσσιας χλωρίδας και πανίδας της περιοχής. Θα πρέπει όλοι να αποκτήσουμε οικολογική συνείδηση και να φροντίσουμε τις παραλίες και τη θάλασσα
          του τόπου μας.</Typography>


        <h4>Ε! Τάξη , Α! βραβείο Βατζάκα Μαριλένα </h4>
        <h4>Η Έκθεση.</h4>
        <Typography variant="h3" sx={theme.typography.typography2}>Η παραλία είναι μια ωραία απασχόληση για τα παιδιά και τους μεγάλους. Σε μια παραλία μπορούμε να βρούμε κοχύλια και πολλά είδη ψαριών που αρέσει σ εμάς τα παιδιά
          να τα βλέπουμε, να τα φωτογραφίζουμε και να τα εξερευνούμε. Επίσης οι ποιο πολλοί άνθρωποι πηγαίνουν στις παραλίες για να δροσιστούν από την πολλή ζέστη που κάνει.
          Στις παραλίες τα παιδιά παίζουν με την άμμο και φτιάχνουν πύργους, σκάβουν λακκούβες με νερό και μπαίνουν μέσα. Αυτό είναι ωραίο παιχνίδι. Τα προβλήματα που υπάρχουν
          σε μια παραλία είναι που πετάνε σκουπίδια, τοξικά και χημικά αντικείμενα μέσα, αλλά και έξω από τη θάλασσα. Μερικοί άνθρωποι ρίχνουν μέσα αντικείμενα όπως λάδια,
          αντικείμενα με οξύ, χρωματικές ουσίες και καταστρέφουν τις παραλίες αλλά και τα λιμάνια. Πολλών ειδών ψάρια που θέλουμε να βλέπουμε πεθαίνουν. Μ αυτά μπορεί ακόμα και
          ένα παιδί να πάθει πρόβλημα γι αυτό κάντε ΑΝΑΚΥΚΛΩΣΗ! στις παραλίες και φωνάξτε ειδικούς για την θάλασσα ώστε να μαζέψουν τα τοξικά πράγματα που την μολύνουν, αλλά
          και σκοτώνουν πουλιά όπως τους γλάρους. Γι αυτό τα παιδιά όλων των σχολείων είναι σωστό να κάνουμε ανακύκλωση στα σχολεία αλλά και στις παραλίες γιατί άμα δεν το
          κάνουμε οι παραλίες δεν θα είναι ασφαλείς και θα καταστραφούν. Ακόμα τα παιδιά μόνα τους και με την οικογένειά τους να βοηθάνε αυτή την κατάσταση στις παραλίες αλλιώς
          δεν θα κάνουν μπάνιο γιατί όλα θα είναι μολυσμένα.</Typography>
      </Box>

      <ScrollToTopButton />


    </>
  )
}

export default D4_drastiriotites_2007