import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/25_image_01.jpg";
import Image02 from "../assets/images/oikologika/25_image_02.jpg";

function F11_oikologika_11() {

  const theme = useTheme();


  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="25o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>

      
      <Box sx={{ ...commonBoxStyles }}>
	
      <Typography variant="h1" sx={theme.typography.typography1}>7-8 Νοεμβρίου 2015, Βόλος.</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>Θέμα: «Οικονομική κρίση και περιβάλλον»</Typography>
		
	<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	
    <Typography variant="h3" sx={theme.typography.typography2}>Ολοκληρώθηκε την Κυριακή 8 Νοεμβρίου 2015, στο Βόλο, το 25ο Συνέδριο του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων, σε συνδιοργάνωση με την ΚΕΔΕ. Το θέμα του Συνεδρίου ήταν «Οικονομική Κρίση και Περιβάλλον». Το Συνέδριο παρακολούθησαν μέλη του Διοικητικού Συμβουλίου της ΚΕΔΕ, Δήμαρχοι και Δημοτικοί Σύμβουλοι, στελέχη της Τοπικής Αυτοδιοίκησης, φορείς, οικολογικές οργανώσεις και πολλοί πολίτες</Typography>
    


    <Typography variant="h1" sx={theme.typography.typography1}>25_image_01Απόφαση 25ου Πανελλήνιου Συνεδρίου ΠΑΝΔΟΙΚΟ</Typography>
   
   
   
  <Typography variant="h3" sx={theme.typography.typography2}>Οι σύνεδροι του 25ου Συνεδρίου του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ), εκπρόσωποι φορέων της Τοπικής Αυτοδιοίκησης κα των οικολογικών οργανώσεων της χώρας αφού άκουσαν τις εμπεριστατωμένες εισηγήσεις και συμμετείχαν στον διάλογο που αναπτύχθηκε στα πλαίσια του 25ου Συνεδρίου, το οποίο ανανέωσε και συνέχισε τη συνεργασία Τοπικής Αυτοδιοίκησης και οικολογικών οργανώσεων, συνεκτιμούν και συναποφασίζουν τα ακόλουθα:</Typography>
  
<Typography variant="h3" sx={theme.typography.typography2}>Η χώρα μας βιώνει τα τελευταία χρόνια μία πρωτοφανή οικονομική κρίση, μία κρίση που έχει αλλάξει άρδην λειτουργίες, συμπεριφορές, καταναλωτικά πρότυπα, συνήθειες και πρακτικές τόσο σε ατομικό όσο και σε συλλογικό επίπεδο, στην ιδιωτική και τη δημόσια ζωή της χώρας.</Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Μία κρίση που αφήνει καθημερινά να βαθαίνει το αποτύπωμά μας στο οικονομικό και στο κοινωνικό πεδίο, αλλά και στο ίδιο το περιβάλλον άλλοτε θετικά και άλλοτε αρνητικά.
Οι οικονομολόγοι και γενικά όσοι ασκούσαν οικονομική πολιτική μετά το 19ο αιώνα  δεν ενδιαφέρθηκαν ουσιαστικά για τις επιπτώσεις της ανάπτυξης στο φυσικό και κοινωνικό περιβάλλον, παρά μετά την κρίση του 1973-74. Αυτό που κατανοήθηκε μετά το 1973 είναι ότι τα όρια της ανάπτυξης τα θέτει ο ρυθμός ανανέωσης των φυσικών πόρων και έτσι καθ1
ιερώθηκε ο όρος «βιώσιμη ανάπτυξη» ως «η βελτίωση της ποιότητας της ζωής μέσα στα πλαίσια της φέρουσας ικανότητας των υποστηρικτικών οικοσυστημάτων».</Typography>
<img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

<Typography variant="h3" sx={theme.typography.typography2}>Η αειφόρος ανάπτυξη, ο έμπρακτος σεβασμός στο περιβάλλον (φυσικό, πολιτιστικό, ιστορικό, ανθρωπογενές) και η τήρηση των νόμων από κάθε πολίτη αλλά παράλληλα και από τη συντεταγμένη πολιτεία φαίνεται να είναι ένας αργός, επίπονος αλλά ασφαλής τρόπος διεξόδου από την οικονομική κρίση τώρα αλλά και πιθανόν μελλοντικά.<br/>
Υπάρχουν τρεις διαφορετικές σχολές σκέψης όσο αφορά στη χρήση των «κοινών πόρων»: Η πρώτη προτείνει ένα ολοένα και  μεγαλύτερο δημόσιο έλεγχο επί των πόρων, η δεύτερη προτείνει μια αποτελεσματικότερη κατανομή ιδιοκτησιακών δικαιωμάτων, ώστε η αγορά να επιβαρύνει οικονομικά όσους επιβαρύνουν περισσότερο το περιβάλλον, και τέλος μια τρίτη άποψη, της λεγόμενης «κοινοτικοποίησης», που μεταφέρει την ευθύνη της διαχείρισης στις κοινότητες που ζουν και εργάζονται στην γειτονιά του κοινόχρηστου πόρου. Σε κάθε περίπτωση, η έννοια της βιωσιμότητας πρέπει να γίνεται αντιληπτή με όλες της τις διαστάσεις, την οικονομική, την κοινωνική και την περιβαλλοντική ώστε να είναι αποτελεσματική και εφικτή. 
Η νέα περιβαλλοντική οικονομία που έχει εδώ και καιρό αρχίσει να διαμορφώνεται στην Ευρώπη και την Αμερική μπορεί, υπό προϋποθέσεις, να αποτελέσει μία διέξοδο από την κρίση. Στην Ελλάδα ο θεσμός των Μελετών Περιβαλλοντικών Επιπτώσεων, μοιάζει να έχει γίνει προκειμένου να προστεθεί ένα ακόμα γραφειοκρατικό γρανάζι στην αδειοδότηση νέων δραστηριοτήτων. Η αειφόρος ανάπτυξη, ο έμπρακτος σεβασμός στο περιβάλλον (φυσικό, πολιτιστικό, ιστορικό, ανθρωπογενές) και η τήρηση των νόμων από κάθε πολίτη, αλλά παράλληλα και από τη συντεταγμένη πολιτεία φαίνεται να είναι ένας αργός, επίπονος αλλά ασφαλής τρόπος διεξόδου από την οικονομική κρίση τώρα, αλλά και πιθανόν μελλοντικά.<br/>
Η οικονομική κρίση το πρώτο που θυσιάζει είναι το περιβάλλον. Τα ζητήματα του περιβάλλοντος στην Ελλάδα είναι πολύ ευρύτερα από αυτά που αφορούν στους Φορείς Διαχείρισης. Η διατήρηση των προστατευόμενων περιοχών είναι πολύ σοβαρό θέμα. Από τις 450 προστατευόμενες περιοχές, μόνο οι 28 έχουν Φορείς Διαχείρισης. Δηλαδή το 70% από αυτές είναι χωρίς Φορ1είς Διαχείρισης. </Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Το νέο Εθνικό Σχέδιο Διαχείρισης Αποβλήτων (ΕΣΔΑ), όσον αφορά τα Αστικά Στερεά Απόβλητα (ΑΣΑ), θεωρητικά βρίσκεται στη σωστή κατεύθυνση, στην πράξη όμως για να εφαρμοσθεί θα υπάρξουν σημαντικά προβλήματα. Όμως οι προβλεπόμενες δράσεις, εκτός από χρήματα, απαιτούν και προσωπικό που σήμερα οι Δήμοι δεν το διαθέτουν, αλλά ούτε και τους επιτρέπεται να προσλάβουν. Απαιτείται συνεχής προσπάθεια ενημέρωσης και ευαισθητοποίησης του δημότη, στην οποία οι οικολογικές οργανώσεις μπορούν και πρέπει να συμβάλουν.<br/>
Η εναλλακτική διαχείριση των συσκευασιών και λοιπών προϊόντων μπορεί να συμβάλει επίσης θετικά στο ΕΣΔΑ, αλλά και στην αντιμετώπιση της οικονομικής κρίσης. Οι συνεργαζόμενες με τα Συστήματα Εναλλακτικής Διαχείρισης υποδομές ανακύκλωσης υπολογίζεται ότι συνεισφέρουν στην δημιουργία περισσότερων από 4.000 θέσεων εργασίας στην Ελληνική Επικράτεια, μειώνοντας ταυτόχρονα την κατανάλωση πόρων και την πρόκληση περιβαλλοντικής ζημίας. Οι στόχοι ανακύκλωσης που τίθενται από την Ευρωπαϊκή Νομοθεσία και το ΕΣΔΑ απαιτούν σωστό σχεδιασμό εκ μέρους των Ο.Τ.Α., στενή συνεργασία και δράσεις μεταξύ πολιτών, ΟΤΑ και λοιπών οικονομικών παραγόντων.</Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Οι οικολογικές οργανώσεις φρονούσαν, πριν την κρίση, αλλά και σήμερα, ότι το μεγαλύτερο περιβαλλοντικό πρόβλημα είναι η Διοίκηση. Σήμερα βιώνουμε το αποτέλεσμα αυτής της πολιτικής. Θεωρούν δε ότι το βασικό όπλο, αντιμετώπισης της κρίσης, είναι η παιδεία και ότι οι προηγούμενες δράσεις του ΠΑΝΔΟΙΚΟ και οι επόμενες, ποιούν και αυτές παιδεία.   
Τέλος το Συνέδριο εκτιμά ότι πρέπει η Πολιτεία, όχι μόνο να μην επιβάλει αδικαιολόγητες φορολογίες και πρόστιμα, αλλά οφείλει να στηρίζει το έργο των υγιών εθελοντικών οργανώσεων, ανάμεσα στις ΜΚΟ, οργανώσεων που είναι χρήσιμες, προσφέροντας σημαντικό έργο, είτε στην προστασία του περιβάλλοντος, είτε στη παροχή βοήθειας σε δοκιμαζόμενες ομάδες του πληθυσμού και τους πρόσφυγες, αλλά και γενικότερα στο κοινωνικό σύνολο.<br/><br/>

Για το 25ο Συνέδριο του ΠΑΝΔΟΙΚΟ<br/>
Ο Πρόεδρος της Οργανωτικής Επιτροπής  Σάββας Χιονίδης, Δήμαρχος Κατερίνης.
</Typography>

</Box>

<ScrollToTopButton />






    </>
  );
}

export default F11_oikologika_11;
