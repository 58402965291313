import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

function D106_ContactWithUs() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>

      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Επικοινωνήστε μαζί μας" />
      </Box>

      <Box sx={{ ...commonBoxStyles, marginBottom:'3em' }}>
        <Typography variant="h3" sx={theme.typography.typography2}>
          To email του συλλόγου μας είναι:{" "}
          <Typography component="span" sx={{ fontWeight: "bold", fontSize: "1.0em"  }}>
            delfini33@gmail.com
          </Typography>
        </Typography>
      </Box>
    </>
  );
}

export default D106_ContactWithUs;
