import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/22sinedriopandoiko_01.jpg";

function F8_oikologika_08() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="22o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>


      
      <Box sx={{ ...commonBoxStyles }}> 
		
      <Typography variant="h1" sx={theme.typography.typography1}>Πνευνατικό κέντρο δήμου Χανίων 3-4 Σεπτεμβρίου 2010.</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>ΘΕΜΑ: «Οι Δημόσιοι Ελεύθεροι Χώροι ως Κοινωνικό Αγαθό και η Βιοκλιματική Αρχιτεκτονική στον δομημένο χώρο»</Typography>
		
		<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
				
		<Typography variant="h1" sx={theme.typography.typography1}>Απόφαση συνεδρίου</Typography>
				
		<Typography variant="h3" sx={theme.typography.typography2}>Οι σύνεδροι του 22ου Συνεδρίου του ΠΑΝΔΟΙΚΟ  (που διοργανώθηκε από την Οικολογική Πρωτοβουλία Χανίων με την υποστήριξη της ΚΕΔΚΕ), μέλη 
        των οργανώσεών του, στελέχη της αυτοδιοίκησης, επιστήμονες, πολίτες των Χανίων, μετά την πραγμάτευση των κρίσιμων θεμάτων των δημόσιων
        ελεύθερων χώρων και της βιοκλιματικής αρχιτεκτονικής, συνεκτιμούμε και συναποφασίζουμε τα ακόλουθα:

		
		
		<ol>
			<li>Άνετοι, λειτουργικοί και προσβάσιμοι στο κοινό δημόσιοι κοινόχρηστοι χώροι σε συνδυασμό 
                με την εφαρμογή των αρχών της βιοκλιματικής                αρχιτεκτονικής και πολεοδομίας 
                αποτελούν θεμελιώδη προϋπόθεση για  για την αξιοβίωτη ανάπτυξη των πόλεων και την ποιότητα ζωής. 
                Η                βιοκλιματική αρχιτεκτονική και πολεοδομία είναι είναι ο δρόμος που οδηγεί στην 
                συμφιλίωση της πόλης με τη φύση και την δραστική μείωση                της συμβολής της στην κλιματική αλλαγή.</li>

			<li>Οι δημόσιοι ελεύθεροι χώροι των ελληνικών πόλεων αντιμετωπίζουν σοβαρές απειλές : 
                Πιέσεις οργανωμένων συμφερόντων για να δομηθούν.                Απώλειες λόγω αδυναμίας της 
                Αυτοδιοίκησης να καταβάλλει το κόστος των απαλλοτριώσεων. Καταπατήσεις αλλά και υποβάθμιση λόγω                κατακερματισμού, εγκατάλειψης και έλλειψης σχεδιασμένης και λειτουργικής τους ένταξης στις λειτουργίες των πόλεων. 
                Ανοικτή διευκόλυνση                της ιδιωτικοποίησής τους μέσω της διαχείρισής τους από Α.Ε. του Δημοσίου (Ολυμπιακά Ακίνητα, ΓΑΙΑ, ΟΣΕ, Κτημ. Εταιρία Δημοσίου, Ετ.                Τουριστικών Ακινήτων). </li>

			<li>Κύριες ελπίδες για την διάσωση των κοινόχρηστων χώρων από την οικοπεδοποίηση και την υποβάθμιση 
                αλλά και την προώθηση της βιοκλιματικής                πολεοδομίας είναι : Τα κινήματα πολιτών και 
                οι οργανώσεις για την υπεράσπισή τους. Οι πρωτοπόροι μηχανικοί και επιστήμονες. Στελέχη της                αυτοδιοίκησης που τολμούν να αντιτεθούν στα συμφέροντα και την αδράνεια.<br/><br/>
                <b>ΘΕΩΡΟΥΜΕ ΑΝΑΓΚΑΙΟ</b> να παλέψουμε για: Να μη δομηθεί κανένας αδόμητος ανοικτός χώρος στις 
                πόλεις μας. Να νομοθετηθούν οικονομικοί                πόροι και πολεοδομικά εργαλεία 
                (αξιοποιώντας και τις σχετικές προτάσεις της ΚΕΔΚΕ) για την διάσωση των κοινοχρήστων χώρων που 
                χάνονται                λόγω λόγω αδυναμίας καταβολής των αποζημιώσεων. Να ξαναγίνουν ανοικτοί
                 κοινόχρηστοι χώροι όλα τα Ολυμπιακά ακίνητα που παράνομα                οικοπεδοποιήθηκαν.
                  Να ανοίξει το θαλάσσιο μέτωπο των πόλεων στη δημόσια χρήση αναψυχής. 
                  Να εφαρμοσθούν αποτελεσματικές πολιτικές για                την ανάδειξη, 
                  την προσβασιμότητα και προπαντός την ενοποίηση των κοινόχρηστων χώρων.
                   Να ενσωματωθούν οργανικά στην πολεοδομική                νομοθεσία οι
                    αρχές όχι απλώς της βιοκλιματικής αλλά  της ολιστικής αρχιτεκτονικής και
                     πολεοδομίας (εξοικονόμηση ενέργειας, οικολογικά                υλικά, γεωβιολογία). <br/>
                Οι κοινόχρηστοι χώροι είναι η ανάσα μας και όχι οικόπεδα!<br/>
                Ναι στην αρχιτεκτονική που δεν υποτάσσει αλλά υποτάσσεται στην φύση!<br/>
               
                </li>
		</ol>
        
        </Typography>
        
		
		
		<Typography variant="h3" sx={theme.typography.typography2}>Με την λήξη του Συνεδρίου εκλέχτηκε νέα επταμελής Γραμματεία η οποία όμως δεν έχει αναλάβει τα καθήκοντά της λόγω δικαστικής                αμφισβήτησης μετά τα θλιβερά γεγονότα αποκλεισμού πολλών οργανώσεων μεταξύ των οποίων και του Συλλόγου μας επειδή οι εξουσιοδοτήσεις                είχαν σταλεί με φαξ, μια πρακτική η οποία δεν είχε αμφισβητηθεί ποτέ στο παρελθόν. Ήδη γίνονται επαφές και συνεννοήσεις για απεμπλοκή                από την δικαστική οδό αμφισβήτησης της νέας επταμελούς Γραμματείας με την πραγματοποίηση ενός έκτακτου συνεδρίου στην Αθήνα με                αποκλειστικό σκοπό τις αρχαιρεσίες και την εκλογή επταμελούς Γραμματείας χωρίς τους αποκλεισμούς των οργανώσεων που έγιναν στα Χανιά.
        </Typography>
        
		
	</Box>

    <ScrollToTopButton />


    </>
  );
}

export default F8_oikologika_08;
