import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/Hmerologia_p4/Imbros_01.jpg";
import Image02 from "../assets/images/Hmerologia_p4/Imbros_02.jpg";
import Image03 from "../assets/images/Hmerologia_p4/Imbros_03.jpg";
import Image04 from "../assets/images/Hmerologia_p4/Imbros_04.jpg";
import Image05 from "../assets/images/Hmerologia_p4/Imbros_05.jpg";
import Image06 from "../assets/images/Hmerologia_p4/Imbros_06.jpg";
import Image07 from "../assets/images/Hmerologia_p4/Imbros_07.jpg";
import Image08 from "../assets/images/Hmerologia_p4/Imbros_08.jpg";
import Image09 from "../assets/images/Hmerologia_p4/Imbros_09.jpg";
import Image10 from "../assets/images/Hmerologia_p4/Imbros_10.jpg";
import Image11 from "../assets/images/Hmerologia_p4/Imbros_11.jpg";
import Image12 from "../assets/images/Hmerologia_p4/Imbros_12.jpg";
import Image13 from "../assets/images/Hmerologia_p4/Imbros_13.jpg";
import Image14 from "../assets/images/Hmerologia_p4/Imbros_14.jpg";

import Image15 from "../assets/images/Hmerologia_p4/Tenedos_03.jpg";
import Image16 from "../assets/images/Hmerologia_p4/Tenedos_04.jpg";


function P4_Hmerologia() {

    const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">        
        <HomeIcon />
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ημερολόγιο 2007 : Ίμβρος και Τενέδος" />
      </Box>


      
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ιστορική διαδρομή</Typography>
	<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Το σύμπλεγμα των Θρακικών Σποράδων εκτός από τα νησιά Θάσο, Λήμνο, Σαμοθράκη, Άι-Στράτη που ανήκουν στην ελληνική επικράτεια, περιλαμβάνουν και τα νησιά Ίμβρο, 
	Τένεδο που η γεωγραφική τους θέση και  η εγγύτητά τους στα μικρασιατικά παράλια και στα στενά των Δαρδανελλίων, καθόρισε τη μοίρα τους.<br/>	
	Επί 2.500 χρόνια η ιστορία των δύο νησιών είναι άρρηκτα συνδεδεμένη με αυτή του Ελληνισμού.<br/>	
	‘‘ Έστί δε τι σπέος ευρύ, βαθείης βένθεσι λίμνης, μεσσηγύς Τενέδοιο και Ίμβρου παιπαλοέσσης ενθ’ ίππους έστησε Ποσειδάων ενοσίχθων…’’ (Ιλιάδα, Ν32 -34). 
	Απτή  μαρτυρία για την ελληνικότητα των νησιών από τα ομηρικά ακόμη χρόνια.  Στον τρωικό πόλεμο χρησιμοποιήθηκαν σαν βάσεις ανεφοδιασμού των Ελλήνων. Το 494 π.Χ. 
	ο Μιλτιάδης κατέλαβε την Ίμβρο και την αποίκησε με Αθηναίους κληρούχους, ενώ έγινε μέλος της Α΄ και Β΄ Αθηναϊκής  Συμμαχίας. Έκτοτε η παρουσία του ελληνικού στοιχείου 
	στα δύο νησιά υπήρξε συνεχής, παρά τις εκάστοτε μεταβολές και την υπαγωγή τους σε διάφορους κατακτητές.<br/>	
	Η επικράτηση του χριστιανισμού στο νησί έλαβε χώρα  την εποχή του Μ. Κωνσταντίνου. Οι επίσκοποι δε της Τενέδου, συμμετείχαν στις ιστορικές για την οργάνωση της 
	Εκκλησίας, Γ΄ και Δ΄ Οικουμενικές Συνόδους. Λόγω της γειτνίασής τους με την Κωνσταντινούπολη τα δύο νησιά γνώρισαν σημαντική ανάπτυξη κατά τους βυζαντινούς 
	χρόνους. <br/>	
	Μετά την κατάρρευση της Βυζαντινής Αυτοκρατορίας, ένας Ίμβριος πρόκριτος, ο Mιχαήλ  Κριτόβουλος,, διαπραγματεύθηκε με ευνοϊκούς όρους την υπαγωγή της 
	Ίμβρου, της Λήμνου και της Θάσου στην Οθωμανική Αυτοκρατορία, εξασφαλίζοντας έτσι την ακώλυτη επιβίωση του Ελληνισμού από το 1456 έως την απελευθέρωσή τους το 1912 
	από το ελληνικό, πολεμικό ναυτικό. <br/>	
	Το Νοέμβριο του 1912, οι ελληνικές ναυτικές δυνάμεις υπό το ναύαρχο Παύλο Κουντουριώτη απελευθέρωσαν τα δύο νησιά, προσαρτώντας τα στην Ελλάδα. Προσάρτηση που 
	επικυρώθηκε με το άρθρο 84 της Συνθήκης των Σεβρών το 1920. Η ενσωμάτωση αυτή στον εθνικό κορμό, σηματοδοτεί μια χρυσή δεκαετία για τον Ελληνισμό των νησιών, 
	ανακόπτοντας παράλληλα το μεταναστευτικό ρεύμα προς την Αίγυπτο που είχε παρατηρηθεί κατά τα τέλη του 19ου αιώνα.<br/>	
	Μετά τη Μικρασιατική καταστροφή, ετέθη εκ νέου  το μελλοντικό καθεστώς των νησιών του βορειοανατολικού Αιγαίου και στη συνθήκη της Λοζάνης  που υπογράφηκε 
	στις 24-Ιουλίου1923 στην Ελβετία, με το άρθρο 14 αποφασίσθηκε η επιστροφή των νησιών στην Τουρκία, παρά τη συντριπτική και αδιαμφισβήτητη υπεροχή του 
	ελληνικού στοιχείου. Η απογραφή του 1912 είχε καταγράψει στη μεν αμιγώς ελληνική Ίμβρο 9.207 Έλληνες, στη δε Τένεδο 5.420 Έλληνες έναντι 1.200 Τούρκων.<br/>	
	Στις 15 Σεπτεμβρίου 1923, τα ελληνικά στρατεύματα εκκένωσαν τα δύο νησιά. Με την παραλαβή τους  από την Άγκυρα στις αρχές Οκτωβρίου 1923, αμέσως 
	διορίστηκε Τούρκος διοικητής και οι τοπικές αρχές στελεχώθηκαν με Τούρκους αξιωματικούς. Με τον τρόπο αυτό, καταστρατηγήθηκε εξυπαρχής το άρθρο 
	14 της Λοζάνης που προέβλεπε Έλληνα, Ίμβριο διοικητή και ελληνική τοπική αυτοδιοίκηση.</Typography>	
	<img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Το 1927 με τον ειδικό νόμο (αρ.1151) που ψηφίστηκε για την τοπική αυτοδιοίκηση, ουσιαστικά καταργείται η ελληνική εκπαίδευση των νησιών και 
	επιβάλλεται ένα εκπαιδευτικό σύστημα όμοιο με αυτό που ακολουθούσαν τα τουρκικά σχολεία στην ηπειρωτική Τουρκία. Κατά την περίοδο 1951-1963, 
	ενώ μαίνεται ο ψυχρός πόλεμος και η Ελλάδα και Τουρκία γίνονται μέλη του ΝΑΤΟ, οι ελληνορθόδοξοι κατάφεραν να απολαύσουν τα βασικά ανθρώπινα 
	και μειονοτικά δικαιώματά τους ενώ το ελληνικό μειονοτικό εκπαιδευτικό σύστημα της Κωνσταντινούπολης  επεκτάθηκε  στα δύο νησιά.<br/>	
	Οι συνθήκες που επικρατούσαν στην Ίμβρο και Τένεδο επηρεαζόταν άμεσα από τις εκάστοτε ελληνοτουρκικές σχέσεις, που συχνά δοκιμάζονταν. Έτσι η αναζωπύρωση του 
	Κυπριακού το Δεκέμβριο του 1963, ανέτρεψε το προηγούμενο θετικό κλίμα. Η αντίστροφη μέτρηση και ο αφελληνισμός των νησιών, ειδικά της Ίμβρου που είχε 
	διατηρήσει τον ελληνικό αιγαιοπελαγίτικο χαρακτήρα της, άρχισε. Η κατάργηση των ελληνικών σχολείων, η αυθαίρετη δήμευση των εκπαιδευτηρίων, η αφαίρεση 
	των μέσων βιοπορισμού του τοπικού πληθυσμού με τη συστηματική  εφαρμογή ενός προγράμματος απαλλοτριώσεων που επέτρεψε την απώλεια  από τους Έλληνες του 
	95% της καλλιεργήσιμης γης, η απαγόρευση της αλιείας στους γηγενείς, αλλά και η δημιουργία ανοιχτής, αγροτικής φυλακής για Τούρκους βαρυποινίτες από την 
	ηπειρωτική Τουρκία στην Ίμβρο, ήταν μερικά από τα μέτρα που σηματοδότησαν την απαρχή μιας νέας εποχής και που οδήγησε τον Ελληνισμό των δύο νησιών 
	σε αφανισμό. Τέλος στα μέτρα αυτά πρέπει να προστεθεί και ο χαρακτηρισμός των νησιών από τις τουρκικές αρχές ως επιτηρούμενη ζώνη, που απαιτούσε ειδική 
	άδεια από το νομάρχη Δαρδανελλίων για να την επισκεφτεί κανείς. Τα δύο νησιά απομονώθηκαν και τα μέτρα πέτυχαν απόλυτα τους στόχους τους αφού οι
	 Έλληνες, ειδικά της Ίμβρου, εγκατέλειψαν τις εστίες τους και διασκορπίστηκαν στα πλάτη και τα μήκη του κόσμου. Παράλληλα άρχισε η εγκατάσταση 
	 Τούρκων εποίκων στο νησί, πρόσφυγες από τη Βουλγαρία, Κούρδοι, αραβόφωνοι κ.ά. Έτσι η Ίμβρος  που το 1893 απαριθμούσε 10.000 περίπου Έλληνες, 
	 σήμερα έχει γύρω στους 300, η δε Τένεδος με 5.320 Έλληνες έχει πλέον μόνο 45. Στην πρωτεύουσα της Ίμβρου την Παναγιά,  χτίστηκε τζαμί με το
	 συμβολικό όνομα ΄΄ Φατίχ΄΄, δηλαδή τζαμί του κατακτητή και το 1973 το νησί άλλαξε το όνομά του από ΄΄ Ίμβρος΄΄ σε ΄΄Γκοκτσέαντα΄΄  (Gökçeada), 
	 η δε Τένεδος έλαβε το όνομα  Μποζτζάαντα (Bozcaada)</Typography>
	
	<Typography variant="h1" sx={theme.typography.typography1}>27-Μαρτίου 1923  Λονδίνο</Typography>
	<Typography variant="h1" sx={theme.typography.typography1}>Τηλεγράφημα Ελευθερίου Βενιζέλου</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Προς Επιτροπή Λαού Τενέδου. Μετά βαθυτάτης λύπης μου σας πληροφορώ ότι απόφασις περί Τενέδου αμετάκλητος</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>24-Ιουλίου1923  Ελβετία</Typography>
	<Typography variant="h1" sx={theme.typography.typography1}>Συνθήκη της Λωζάννης</Typography>
	<Typography variant="h1" sx={theme.typography.typography1}>Άρθρο 14</Typography>
	<img src={Image15}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Αι νήσοι Ίμβρος και Τένεδος, παραμένουσαι υπό την τουρκικήν κυριαρχίαν, θα απολαύουν ειδικής διοικητικής οργανώσεως,  αποτελουμένης υπό τοπικών 
	στοιχείων και παρεχούσης πάσαν εγγύησιν εις τον αυτόχθονα μη μουσουλμανικόν πληθυσμόν όσον αφορά την τοπικήν αυτοδιοίκησιν και την προστασίαν των ατόμων και 
	των αγαθών. Η τήρησις της τάξεως θα διασφαλίζεται υπό αστυνομίας στρατολογουμένης εκ του αυτόχθονος πληθυσμού, μερίμνη της ως άνω προβλεπομένης τοπικής 
	διοικήσεως και υπό τας διαταγάς αυτής τιθεμένης".</Typography>
	
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>4- Οκτωβρίου-1923  Ίμβρος</Typography>
	<Typography variant="h1" sx={theme.typography.typography1}>Πρωτόκολλον παραδόσεως της νήσου Ίμβρου</Typography>
	<img src={Image16}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Σήμερον, την 4ην Οκτωβρίου 1923 συμφώνως προς το άρθρον 14 της συνθήκης ειρήνης και εις εκτέλεσιν Νο 4 του Πρωτοκόλλου Νο 15 σχετικού προς την ειρημένη 
	συνθήκην, τα οποία υπεγράφησαν εις την Λωζάννη την 24 Ιουλίου 1923, μεταξύ των συμβαλλομένων Υψηλών Μερών και εις βεβαίωσιν της ειρημένης συνθήκης και 
	Πρωτοκόλλου υπό των κυβερνήσεων της Μεγάλης Εθνοσυνελεύσεως της Τουρκίας και του Βασιλείου της Ελλάδος παρεχωρήθη η νήσος Ίμβρος (Ίμπρος) από τον Διοικητήν της 
	νήσου κύριον Ιωάννην Παπουτσιδάκιν εις την Αυτού Εξοχότητα Καντρή Μπέη Διοικητικόν Επιθεωρητή του υπουργείου των Εσωτερικών της κυβερνήσεως της Μεγάλης 
	Εθνοσυνελεύσεως της Τουρκίας, εξουσιοδοτημένου προς τούτο. <br/>
	Εις πίστωσιν τούτου συνετάχθη το παρόν Πρωτόκολλον εις δύο αντίτυπα και υπεγράφη εις Ίμβρον την 4ην Οκτωβρίου 1923. <br/>
	Υπεγράφησαν υπό:<br/>
	Εν ονόματι της κυβερνήσεως του Βασιλείου της Ελλάδος (υπ. ελληνιστί)<br/>
	Ι.Παπουτσιδάκις<br/>
	Εν ονόματι της κυβερνήσεως της Μεγ. Εθνοσυνελεύσεως της Τουρκίας (υπ. τουρκιστί)<br/>
	Καντρή Μπέης.<br/>
	</Typography>
	</Box>
	
	<ScrollToTopButton />	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Φωτογραφικό Υλικό</Typography>	
    <img src={Image03}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image04}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image05}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image06}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image07}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image08}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image09}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image10}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image11}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image12}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image13}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image14}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image15}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
    <img src={Image16}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	</Box>

    <ScrollToTopButton />

    </>
  );
}

export default P4_Hmerologia;
