import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/drastiriotites2023/image001.jpg";
import Image03 from "../assets/images/drastiriotites2023/image003.jpg";
import Image05 from "../assets/images/drastiriotites2023/image005.jpg";
import Image07 from "../assets/images/drastiriotites2023/image007.jpg";
import Image09 from "../assets/images/drastiriotites2023/image009.jpg";
import Image11 from "../assets/images/drastiriotites2023/image011.jpg";
import Image13 from "../assets/images/drastiriotites2023/image013.jpg";
import Image15 from "../assets/images/drastiriotites2023/image015.jpg";
import Image17 from "../assets/images/drastiriotites2023/image017.jpg";
import Image19 from "../assets/images/drastiriotites2023/image019.jpg";
import Image21 from "../assets/images/drastiriotites2023/image021.jpg";
import Image23 from "../assets/images/drastiriotites2023/image023.jpg";
import Image25 from "../assets/images/drastiriotites2023/image025.jpg";
import Image27 from "../assets/images/drastiriotites2023/image027.jpg";
import Image29 from "../assets/images/drastiriotites2023/image029.jpg";
import Image31 from "../assets/images/drastiriotites2023/image031.jpg";
import Image33 from "../assets/images/drastiriotites2023/image033.jpg";
import Image35 from "../assets/images/drastiriotites2023/image035.jpg";
import Image37 from "../assets/images/drastiriotites2023/image037.jpg";
import Image39 from "../assets/images/drastiriotites2023/image039.jpg";
import Image41 from "../assets/images/drastiriotites2023/image041.jpg";
import Image43 from "../assets/images/drastiriotites2023/image043.jpg";
import Image45 from "../assets/images/drastiriotites2023/image045.jpg";
import Image47 from "../assets/images/drastiriotites2023/image047.jpg";
import Image49 from "../assets/images/drastiriotites2023/image049.jpg";
import Image51 from "../assets/images/drastiriotites2023/image051.jpg";
import Image53 from "../assets/images/drastiriotites2023/image053.jpg";

function D20_drastiriotites_2023() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        <HomeIcon />
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Οι δραστηριότητές μας το 2023" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Κοπή της Βασιλόπιτας του Συλλόγου μας
        </Typography>

        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <br />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στην βιβλιοθήκη του Συλλόγου με πολύ καλό καιρό, την Κυριακή 15
          Ιανουαρίου 2023 στις 6 το απόγευμα το Δ.Σ. του Συλλόγου Μικρασιατών
          Σκάλας Λουτρών <b>«Το Δελφίνι»</b> έκοψε την βασιλόπιτά του.
          <br />
          Παρόντες ο ιερέας της ενορίας μας πάτερ Παναγιώτης Χατζηβασιλείου ο
          οποίος την ευλόγησε συνοδευόμενος από τον ψάλτη και μέλος του Συλλόγου
          κ. Σωτήρη Μαμουνή, η αντιπεριφερειάρχης πολιτισμού και οικονομικών της
          Π.Β. Αιγαίου κ. Αναστασία Αντωνέλλη, ο πρόεδρος του τοπικού Συμβουλίου
          κ. Βασίλης Μαμώλης και το μέλος κ. Μιχάλης Τουρλής, ο πρόεδρος του
          Συλλόγου γονέων του Δημοτικού Σχολείου Λουτρών κ. Δημήτρης
          Καντεμνίδης, η δημοσιογράφος κ. Βαρβάρα Γκιγκιλίνη, ο πρόεδρος του
          Συλλόγου της παλιάς αγοράς κ. Ιωάννης Μολυβιάτης, και πολλά μέλη του
          Συλλόγου.
          <br />
          Το φλουρί, μετά από κλήρωση που διενεργήθηκε το πήρε ο ιερέας της
          ενορίας μας με τον αριθμό <b>23</b>.<br />
          Ένα δεύτερο δώρο που ήταν προσφορά από το κατάστημα «Ρολογάδικο» του
          κ. Ι. Μολυβιάτη το κέρδισε ο κ. Γιώργος Κρητικός με τον αριθμό{" "}
          <b>8</b>. <br />
          Στους παρόντες προσφέρθηκαν καφές, αναψυκτικά, και ποτά με διάφορα
          εδέσματα συνοδευόμενα από κρασόμελο που ήταν προσφορά του μέλους του
          Συλλόγου της κ. Ευθαλίας Τουρλή.
          <br />
          Στο τέλος της εκδήλωσης έγινε και η κλήρωση των δώρων της λαχειοφόρου
          αγοράς του Συλλόγου. <br />
          <br />
          Και του χρόνου με υγεία.
        </Typography>
      </Box>

      

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ο Αγιασμός των Υδάτων στη Σκάλα Λουτρών και στην Χαραμίδα
        </Typography>

        <img
          src={Image03}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image05}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Μετά από δύο χρόνια καραντίνας και με καλό καιρό εορτάστηκαν τα
          Θεοφάνια, στη Σκάλα Λουτρών και τη Χαραμίδα. <br />
          Παρόντες, ο πρόεδρος της Κοινότητας μας κ. Β. Μαμώλης, ο περιφερειακός
          σύμβουλος κ. Π. Χριστόφας, το μέλος του συλλόγου αιμοδοτών Μυτιλήνης
          κ. Δ. Μπουρνούς, ο πρόεδρος του συλλόγου μας κ. Δ. Παπαχρυσός, ο
          προϊστάμενος της τεχνικής υπηρεσίας του Δήμου κ Α. Παπαχριστοφόρου, ο
          πρόεδρος του Συλλόγου γονέων και κηδεμόνων κ. Δ. Καντεμνίδης, ο
          πρόεδρος του "Αετού" κ Ν. Σπανός, ο πρόεδρος του πολιτιστικού συλλόγου
          "Λιοστάσι" κ. Δ. Μπουλέτος κ.α. <br />
          <br />
          Μετά το πέρας της Θείας Λειτουργίας στον Ιερό Ναό του Αγίου Γεωργίου
          Λουτρών, όλο το εκκλησίασμα ξεκίνησε για τον αγιασμό των υδάτων στην
          προβλήτα της Σκάλας Λουτρών όπου διαβάστηκε το ευαγγέλιο πάνω στην
          εξέδρα που γι αυτό το σκοπό είχε στηθεί και μόλις ο ιερέας είπε το «εν
          Ιορδάνη βαπτιζομένου σου Κύριε…» οκτώ νεαροί, αλλά και θαρραλέοι
          κολυμβητές βούτηξαν στα κρύα νερά του κόλπου Γέρας. <br /> <br />
          Αυτός που έπιασε πρώτος τον Σταυρό και ανέσυρε στην επιφάνεια, ήταν ο{" "}
          <b>Παναγιώτης Γκαγκάνης</b> από τα Λουτρά, ο οποίος έλαβε και το
          αναμνηστικό δώρο από τον πρόεδρο της Κοινότητας μας. Αμέσως μετά το
          Τοπικό Συμβούλιο Λουτρών έκοψε την καθιερωμένη βασιλόπιτα προσφέροντας
          συγχρόνως και από ένα ποτό στους παρευρισκόμενους. Το φλουρί εδώ έπεσε
          στην μικρή <b>Αναστασία Μολυβιάτου</b>. <br />
          Μετά τον Αγιασμό των υδάτων που έγινε στη Σκάλα Λουτρών ο ιερέας και
          οι περισσότεροι από τους παρευρισκόμενους, ξεκίνησαν για να
          παρευρεθούν στον Αγιασμό των υδάτων στην Χαραμίδα όπου περίμεναν
          πολλοί κάτοικοι της περιοχής, καθώς και αρκετοί Μυτιληνιοί.
          <br />
          <br />Η καθιερωμένη τελετή πραγματοποιήθηκε και εδώ χωρίς προβλήματα
          και τον Σταυρό ανέσυρε από τη θάλασσα ο κ. <b>Γιάννης Λούπος</b>.
          Αμέσως μετά ακολούθησε το κόψιμο της βασιλόπιτας των κατοίκων της
          περιοχής και το σχετικό κέρασμα σε όλους τους παρευρισκόμενους.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Βοήθεια προς τους σεισμόπληκτους στην Τουρκία
        </Typography>

        <img
          src={Image07}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Κυριακή 12-02-2023, συγκεντρώθηκε στον χώρο του{" "}
          <b>«Μουσείου Προσφυγικής Μνήμης 1922»</b> η βοήθεια, που απλόχερα
          προσφέρθηκε από τους κατοίκους του προσφυγικού Συνοικισμού της Σκάλας
          Λουτρών, για να μεταφερθεί μέσω της Π.Β. Αιγαίου στους σεισμόπληκτους
          κατοίκους της Τουρκίας και της Συρίας. <br />
          <br />
          Εκ μέρους του Δ.Σ. του Συλλόγου Μικρασιατών Σκάλας Λουτρών «Το
          Δελφίνι», ευχαριστούμε όλους όσοι πρόσφεραν έστω και από το υστέρημα
          τους αυτή την ουσιαστική ανθρωπιστική βοήθεια η οποία με μέριμνα του
          Συλλόγου πακεταρίστηκε και μεταφέρθηκε στον χώρο υποδοχής στο κτίριο
          της πρώην Νομαρχίας Λέσβου.
          <br />
          <br />
          Επίσης να εκφράσουμε και τα συγχαρητήρια μας στην Ζωή και στην Μαρία,
          τις ακούραστες εθελόντριες που εργάζονταν νυχθημερόν για την παραλαβή,
          την τακτοποίηση και την προώθηση αυτής της βοήθειας στις πληγείσες
          περιοχές. <br />
          <br />
          Ο Σύλλογος μας επιπλέον αυτής της βοήθειας αγόρασε καθ υπόδειξη των
          υπευθύνων, αναγκαίο φαρμακευτικό υλικό αξίας πλέον των 500 ευρώ.{" "}
          <br />
          <br />
          <i>
            «Ἐάν ταῖς γλώσσαις τῶν ἀνθρώπων λαλῶ καί τῶν ἀγγέλων, ἀγάπην δέ μή
            ἔχω, γέγονα χαλκός ἠχῶν ἤ κύμβαλον ἀλαλάζον.. Καί ἐάν ἔχω προφητείαν
            καί εἰδῶ τά μυστήρια πάντα καί πάσαν τήν γνῶσιν, καί ἐάν ἔχω πάσαν
            τήν πίστιν, ὥστε ὅρη μεθιστάνειν, ἀγάπην δέ μή ἔχω, οὐδέν εἰμί. Καί
            ἐάν ψωμίσω πάντα τά ὑπάρχοντά μου, καί ἐάν παραδῶ τό σῶμα μου ἴνα
            καυθήσομαι, ἀγάπην δέ μή ἔχω, οὐδέν ὠφελοῦμαι. ἡ ἀγάπη μακροθυμεῖ,
            χρηστεύεται, ἡ ἀγάπη οὐ ζηλοί, ἡ ἀγάπη οὐ περπερεύεται, οὐ
            φυσιοῦται, οὐκ ἀσχημονεῖ, οὐ ζητεῖ τά ἑαυτῆς, οὐ παροξύνεται, οὐ
            λογίζεται τό κακόν, οὐ χαίρει ἐπί τή ἀδικία, συγχαίρει δέ τή
            ἀληθεία, πάντα στέγει, πάντα πιστεύει, πάντα ἐλπίζει, πάντα
            ὑπομένει. ἡ ἀγάπη οὐδέποτε ἐκπίπτει…
          </i>
        </Typography>
      </Box>

     

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Απάντηση που ελήφθη από το Chat GPT (Τεχνητή νοημοσύνη) στην ερώτηση,
          τι γνωρίζεται για το «Μουσείο Προσφυγικής Μνήμης 1922» της Σκάλας
          Λουτρών!
        </Typography>

        <img
          src={Image09}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          «Το "Μουσείο Προσφυγικής Μνήμης 1922" στη Σκάλα Λουτρών της Λέσβου
          είναι ένα μουσείο που αναδεικνύει την ιστορία της προσφυγιάς του 1922
          στη Λέσβο και παρουσιάζει τις συνέπειες της στον τόπο και τους
          ανθρώπους. Το μουσείο ιδρύθηκε το 2004 από τον Σύλλογο Μικρασιατών
          Σκάλας Λουτρών "Το Δελφίνι" και βρίσκεται στην παλιά αγορά του χωριού.
          <br />
          <br />
          Το μουσείο περιλαμβάνει έκθεση με αντικείμενα, φωτογραφίες, έγγραφα
          και βίντεο που αναφέρονται στην προσφυγιά του 1922 στη Λέσβο και στις
          επιπτώσεις της στο νησί. Η έκθεση παρουσιάζει επίσης τη ζωή των
          προσφύγων και τον πολιτισμό τους, καθώς και την προσφορά τους στην
          τοπική κοινωνία και στην Ελλάδα συνολικά.
          <br />
          <br />
          Επιπλέον, το μουσείο φιλοξενεί εκθέσεις τέχνης, θεατρικές παραστάσεις
          και διάφορες εκδηλώσεις πολιτιστικού ενδιαφέροντος. Επιπλέον, στην
          περιοχή γίνονται συχνά συναυλίες και παραστάσεις στο ανοιχτό θέατρο
          που βρίσκεται δίπλα στο μουσείο.
          <br />
          <br />
          Το μουσείο διαθέτει επίσης αντικείμενα και φωτογραφίες από την εποχή
          της προσφυγιάς και της ανταλλαγής πληθυσμών, καθώς επίσης και από την
          ιστορία της περιοχής πριν και μετά την προσφυγιά. Με αυτόν τον τρόπο,
          παρέχει μια πλήρη εικόνα της ιστορίας και της κουλτούρας της περιοχής
          κατά τη διάρκεια της προσφυγιάς και της μεταγενέστερης ιστορίας της
          Σκάλας Λουτρών.
          <br />
          <br />
          Συνολικά, το Μουσείο Προσφυγικής Μνήμης 1922 στη Σκάλα Λουτρών Λέσβου
          αποτελεί ένα σημαντικό ιστορικό κέντρο και έναν σημαντικό πολιτιστικό
          πόρο για την περιοχή και την ευρύτερη περιοχή του νησιού της Λέσβου».
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Πάσχα στη Σκάλα Λουτρών
        </Typography>

        <img
          src={Image11}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image13}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με μεγάλη συμμετοχή και με κατάνυξη πραγματοποιήθηκαν για 18η
          συνεχόμενη χρονιά, οι ιερές ακολουθίες της Μεγάλης εβδομάδας στο
          εκκλησάκι του Αγίου Παντελεήμονα της Σκάλας Λουτρών οι οποίες ήταν οι
          εξής:
          <br />
          <br />
          *Την Κυριακή των Βαΐων το πρωί, ετελέσθη η Θεία Λειτουργία από τον
          πρωτοσύγκελο Μυτιλήνης π. Ιάκωβο και το απόγευμα της ίδιας ημέρας η
          «ακολουθία του Νυμφίου» από τον ιερέα της ενορίας μας.
          <br />
          *Την Μ. Τετάρτη το απόγευμα ετελέσθη το «Μυστήριο του Ευχελαίου».
          <br />
          *Την Μ. Πέμπτη το απόγευμα η «ακολουθία των Παθών».
          <br />
          *Την Μ. Παρασκευή το απόγευμα η «αποκαθήλωση» και εν συνεχεία η
          «ακολουθία του Επιταφίου» και τέλος <br />
          *το Μ. Σάββατο στις 11 το βράδυ η «ακολουθία της Ανάστασης». <br />
          Ο Σύλλογός μας, σε συνεργασία πάντοτε με τον ιερέα της ενορίας μας και
          με το γραφείο της Ιεράς Μητροπόλεως Μυτιλήνης μερίμνησαν και φέτος,
          έτσι ώστε να καλυφθούν οι ανάγκες των κατοίκων του οικισμού μας, με
          την παρουσία ιερέως ειδικά για αυτές τις Άγιες ημέρες του Πάσχα, οι
          οποίες ενισχύουν την πίστη μας και μας βοηθούν να κατανοήσουμε
          καλύτερα το πραγματικό νόημα του θανάτου και της Ανάστασης του Κυρίου
          μας!
          <br />
          <br />
          Το βράδυ της Μ. Πέμπτης είχε προηγηθεί ο στολισμός του επιταφίου από
          τις γυναίκες και τα νέα κορίτσια του οικισμού μας, που αφιέρωσαν με
          μεγάλη χαρά πολλές ώρες, για να είναι ο στολισμός του επιταφίου ο
          καλύτερος. Βάσει του εθίμου, κατά την διάρκεια του στολισμού, εψάλη το
          τραγούδι της Παναγίας και άλλα εγκώμια από όλες τις κυρίες. Κατά την
          διάρκεια του στολισμού του επιταφίου προσφέρθηκε πρόσφορο, βουτηγμένο
          σε κόκκινο κρασί και ο στολισμός του επιταφίου στον Άγιο Παντελεήμονα,
          έληξε γύρω στις 2 το πρωί. Η περιφορά του επιταφίου σε όλο τον οικισμό
          της Σκάλας Λουτρών την Μ. Παρασκευή το απόγευμα έδωσε την ευκαιρία σε
          όλους τους υπερήλικες να τον ασπαστούν ενώ η χορωδία από την ομάδα
          γυναικών μελών του Συλλόγου μας έψελνε συνεχώς τα εγκώμια.
          <br />
          <br />
          Τέλος, ο Σύλλογός μας τηρώντας το έθιμο, το βράδυ του Μεγάλου Σαββάτου
          στο τέλος της Θείας λειτουργίας μοίρασε σε όλους τους παρευρισκόμενους
          κόκκινα αυγά και τσουρέκια. Εμείς από την πλευρά μας ευχαριστούμε
          θερμά τον εφημέριο της ενορίας μας π. Παναγιώτη, τον πρωτοσύγκελο της
          Ιεράς Μητροπόλεως Μυτιλήνης π. Ιάκωβο, καθώς και τον Σεβασμιότατο
          Μητροπολίτη Μυτιλήνης, που μερίμνησαν και ικανοποίησαν το αίτημά μας
          για αποστολή ιερέα στον οικισμό μας τις ημέρες του Πάσχα. <br />
          <br />
          Είχε προηγηθεί για πρώτη χρονιά φέτος, το Σάββατο του Λαζάρου, μια
          αναβίωση εθίμου από τα Εγγλεζονήσια της Μικράς Ασίας, που σύμφωνα με
          την αφήγηση της κ. Μαρίας Κρητικού (την οποία κατέγραψε η κ. Ευθαλία
          Τουρλή), τραγουδούσαν τα παιδιά την παραμονή του Λαζάρου, στα σπίτια,
          κρατώντας καλαθάκια με λουλούδια, που άφηναν στα σπίτια, ενώ οι
          νοικοκυρές, χάριζαν αυγά και κουλούρια.
          <br />
          <br />
          «Σήμερα έρχεται ο Χριστός, επουράνιος Θεός και στην πόλη Βηθανία,
          κλαίει η Μάρθα κι η Μαρία. Λάζαρο τον αδερφό της, τον γλυκό τον
          καρδιακό της, τον μοιρολογούν και λέγουν, τον μοιρολογούν και
          κλαίγουν. Την ημέρα την τετάρτη, κίνησε ο Χριστός για νάρθει και
          εβγήκε η Μαρία, έξω από τη Βηθανία. Και εμπρός του γονατίζει, και τους
          πόδας του φιλεί! Πες μας Λάζαρε τι είδες, εις τον Άδη που επήγες? Είδα
          φόβους είδα τρόμους, είδα βάσανα και πόνους. Δώστε μου λίγο νεράκι, να
          ξεπλύνω το φαρμάκι της καρδιάς μου των χειλέων, μη με ερωτάτε πλέον.
          Και του χρόνου και να ζείτε, την Ανάσταση να δείτε!»
          <br />
          <br />
          Και του χρόνου να είμαστε γεροί να ξαναγιορτάσουμε όλοι μαζί αυτές τις
          Άγιες ημέρες.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Περιβαλλοντικές δράσεις στη Σκάλα Λουτρών
        </Typography>

        <img
          src={Image15}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image17}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          – Το Σάββατο 3 Ιουνίου 2023 στη Σκάλα Λουτρών, ο Σύλλογος Μικρασιατών
          Σκάλας Λουτρών <b>«Το Δελφίνι»</b>, ο Σύλλογος{" "}
          <b>«Λέσβος χωρίς πλαστικά»</b> και η κοινότητα μας, ενώσαμε τις
          δυνάμεις μας για να ασχοληθούμε με το πρόβλημα της ρύπανσης από τα
          πλαστικά, καθώς και με θέματα ανακύκλωσης και επαναχρησιμοποίησης
          υλικών για την ευαισθητοποίηση των μαθητών, της νεολαίας και των
          κατοίκων της δημοτικής μας κοινότητας.
          <br />
          <br />
          Οι δράσεις περιελάμβαναν:
          <br />
          - Ομιλίες σχετικές με την προστασία του περιβάλλοντος.
          <br />
          - Χειροτεχνία και παιχνίδια σχετικά με την ανακύκλωση και την
          επαναχρησιμοποίηση των υλικών. <br />
          - Το παιχνίδι της ανακύκλωσης και άλλα εκπαιδευτικά παιχνίδια. <br />
          - Προβολές σχετικές με την προστασία και την μόλυνση που προκαλείται
          στο περιβάλλον από τα πλαστικά αντικείμενα. <br />
          <br />
          Η προσπάθεια αλλαγής σε τοπικό, εθνικό και παγκόσμιο επίπεδο προς την
          «οικολογική σκέψη και πρακτική» είναι ένας τεράστιος αγώνας, μια
          τεράστια αλλαγή «νοοτροπίας και στάσης» που υπαγορεύεται πλέον από το
          ένστικτο της αυτοσυντήρησης του ανθρώπου. <br />
          <br />
          Ειδικά εμείς οι νησιώτες που αντιλαμβανόμαστε τα τελευταία χρόνια την
          σαρωτική επέλαση στην υποβάθμιση του περιβάλλοντος, οραματιζόμαστε μια
          ήπια και βιώσιμη ανάπτυξη για τα νησιά μας και σ αυτή την κατεύθυνση
          ενώνουμε όλοι τις δυνάμεις μας.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αρχαιρεσίες στον Σύλλογο μας
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Κατά την διάρκεια της Γ.Σ. της 26 Μαρτίου 2023 στον χώρο του Μουσείου
          της Σκάλας Λουτρών πραγματοποιήθηκαν οι αρχαιρεσίες του Συλλόγου με τα
          παρακάτω αποτελέσματα: <br />
          <br />
          <b>
            <u>Για το Δ.Σ. εκλέχτηκαν:</u>
          </b>{" "}
          <br />
          Παπαχρυσός Δημήτριος, Βέντου Θεοδώρα, Σταματέλλη Αγγελική, Σταματέλλη
          Ρένα, Παπαμακαρίου Ντόρα, Τουρλής Μιχάλης και Γρηγορέλλη Κατερίνα.
          <br />
          <br />
          <b>
            <u>Για την Ε. Επιτροπή εκλέχτηκαν:</u>
          </b>
          Βαλαχής Παναγιώτης, Δασκάλου Μαρία και Σπανός Νικόλαος.
          <br />
          <br />
          <b>
            <u>Η εφορευτική Επιτροπή: </u>
          </b>
          Αποστόλου Ανθίππη, Παλκάνικου Τζένη, Κρητικού Δήμητρα. <br />
          <br />
          Στην συνέχεια συνεδρίασε στις 2 Απριλίου 2023 το νεοεκλεγέν Δ.Σ. του
          Συλλόγου που προέκυψε από τις αρχαιρεσίες της 26-3-2023 και καθόρισε
          τις αρμοδιότητές του ως κάτωθι:
          <br />
          <br />
          <b>Πρόεδρος:</b> Παπαχρυσός Δημήτριος του Νικολάου.
          <br />
          <b>Αντιπρόεδρος:</b> Τουρλής Μιχάλης του Γεωργίου.
          <br />
          <b>Γ. Γραμματέας:</b> Βέντου Θεοδώρα του Γεωργίου. <br />
          <b>Ταμίας:</b> Σταματέλλη Ειρήνη του Ιωάννη.
          <br />
          <b>Μέλη:</b> Σταματέλλη Αγγελική, Παπαμακαρίου Ντόρα και Γρηγορέλλη
          Κατερίνα.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διεθνής ημέρα Μουσείων
        </Typography>

        <br />
        <br />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Πέμπτη <b>18</b> Μαΐου <b>2023</b>, που εορτάζεται η Διεθνής ημέρα
          Μουσείων, το <b>«Μουσείο Προσφυγικής Μνήμης 1922»</b> στη Σκάλα
          Λουτρών, ήταν ανοικτό για τους επισκέπτες από τις <b>10.00</b> έως τις{" "}
          <b>12.00</b> και από τις <b>18.00</b> έως τις <b>20.00</b>.<br />
          <br />
          Το Διεθνές Συμβούλιο Μουσείων (ICOM), επιθυμώντας να αναδείξει το ρόλο
          των Μουσείων στη σύγχρονη κοινωνία, έχει ορίσει από το <b>
            1977
          </b> την <b>18η Μαΐου</b> ως <b>Διεθνή Ημέρα Μουσείων</b>.<br />
          <br />
          Το μήνυμα αυτής της επετείου είναι «να γίνουν τα Μουσεία φορείς
          πολιτισμικών ανταλλαγών, με σκοπό την ανάπτυξη της μόρφωσης, της
          αμοιβαίας κατανόησης, της συνεργασίας και της ειρήνης ανάμεσα στους
          λαούς».
          <br />
          <br />
          *Υπενθυμίζουμε ότι, το <b>«Μουσείο Προσφυγικής Μνήμης 1922»</b> τις
          υπόλοιπες ημέρες και ώρες, δέχεται επισκέψεις, μόνον κατόπιν ραντεβού
          στο τηλέφωνο: 69746.40419.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διαγωνισμός ζωγραφικής και έκθεσης
        </Typography>

        <img
          src={Image19}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image21}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image23}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image25}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε από τον Σύλλογο Μικρασιατών Σκάλας Λουτρών «ΤΟ
          ΔΕΛΦΙΝΙ» με επιτυχία, ο ετήσιος διαγωνισμός ζωγραφικής και έκθεσης με
          τους μαθητές και τις μαθήτριες του δημοτικού σχολείου Λουτρών.
          <br />
          <br />
          Οι τέσσερις πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα: <br />
          «Ζωγραφίστε ένα τοπίο του χωριού μας που σας αρέσει!»
          <br />
          <br />
          και οι δύο μεγαλύτερες τάξεις στην έκθεση με θέμα:
          <b>
            «Το bullying (εκφοβισμός) έχει σχέση με τον πολιτισμό που
            επιδιώκουμε?»{" "}
          </b>
          <br />
          <br />
          Διακρίθηκαν:
          <br />
          Από την <b>Α΄ Τάξη</b> η Μαμουνή Γεωργία και η Ζαμτράκη Στέλλα.
          <br />
          Από την <b>Β΄ Τάξη</b> η Κρεμύδα Δήμητρα και η Μαστροσάββα Σοφία.
          <br />
          Από την <b>Γ΄ Τάξη</b> η Μανιάτη Σταματίνα και η Σταματέλλη Ανθή.
          <br />
          Από την <b>Δ΄ Τάξη</b> η Μπολύλα Βασιλική και η Γκέντση Ελεονώρα.{" "}
          <br />
          Από την <b>Ε1΄ Τάξη</b> η Βουτσαρδέλλη Μαρία και η Ντάκα Αντζελική.
          <br />
          Από την <b>ΣΤ1΄ Τάξη</b> ο Δασκάλου Γιάννης και ο Παπουτσής Ταξιάρχης.
          <br />
          Από την <b>ΣΤ2΄ Τάξη</b> ο Παπουτσής Χρυσοβαλάντης και η Τατάκη
          Αλεξάνδρα.
          <br /> <br />
          Το Δ.Σ. του Συλλόγου μας ευχαριστεί την διευθύντρια και τους δασκάλους
          του Δημοτικού Σχολείου Λουτρών για την ουσιαστική βοήθεια και την
          συνεργασία που είχαμε κατά την διενέργεια του διαγωνισμού.
          <br />
          <br />
          Παρακάτω δημοσιεύουμε 2 εκθέσεις από αυτές που διακρίθηκαν:
          <br />
          <br />
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Έκθεση Παπουτσή Ταξιάρχη Ε! Τάξη
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το φαινόμενο του σχολικού εκφοβισμού η bullying όπως είναι γνωστός ο
          όρος, έχει αρχίσει να παίρνει διαστάσεις στη σχολική κοινότητα και
          θεωρείται πλέον κοινωνικό πρόβλημα.
          <br />
          <br />
          Η νέα μάστιγα της εποχής μας, είναι μία μέθοδος τρομοκρατίας που
          εφαρμόζεται στα σχολεία ή στην καθημερινή ζωή των παιδιών. Παιδιά
          επιτίθενται λεκτικά, σωματικά ή συναισθηματικά με σκοπό να
          τρομοκρατήσουν και να μειώσουν. Πειράγματα, διάδοση φημών, κλωτσιές,
          κοινωνικός εκφοβισμός και όχι μόνο της αυλής του σχολείου, τρέχοντας
          με ιλιγγιώδεις ταχύτητες στους δρόμους του διαδικτύου. Στην εκδήλωση
          του συμβάλει η αλληλεπίδραση ατομικών και περιβαλλοντικών παραγόντων.
          <br />
          <br />
          Το bullying ή αλλιώς ο σχολικός εκφοβισμός είναι εντελώς αντίθετος με
          την διαχρονική αξία του Ελληνικού πολιτισμού. Οι αρχές και οι αξίες
          του πολιτισμού μας είναι η αλληλεγγύη, η προστασία των ανθρωπίνων
          δικαιωμάτων, η δύναμη της γνώσης, ο διάλογος και η αντιμετώπιση της
          βίας. Ο Ελληνικός πολιτισμός προάγει την ειρήνη, την αγάπη προς τον
          συνάνθρωπο και την οικογένεια. Ο Ελληνικός πολιτισμός και η παιδεία
          ξεπερνά τα εθνικά μας σύνορα με την κατανόηση και την εκτίμηση των
          ανθρώπων ανεξάρτητα από το χρώμα, την ηλικία, το φύλο την καταγωγή,
          την θρησκεία και την κοινωνική θέση. Η 6η μέρα του Μάρτη είναι η
          παγκόσμια ημέρα κατά του σχολικού εκφοβισμού. Είναι υποχρέωση μας να
          συζητάμε, να ενημερωνόμαστε και να καταγγέλλουμε αυτό το φαινόμενο.
          <br />
          <br />
          Πιστεύω ότι εμείς τα παιδιά είμαστε το αύριο, το μέλλον αυτού του
          κόσμου και η ελπίδα του. Ας βάλουμε λοιπόν τα δυνατά μας για να
          διαλύσουμε το μαύρο σύννεφο του σχολικού εκφοβισμού.
          <br />
          <br />
          Ας γίνουν οι γονείς και οι εκπαιδευτικοί μας, μέσα από την ενημέρωση
          που μας παρέχουν, η αρχή που θα τα ξεπλύνει κι εμείς τα παιδιά ας
          ενώσουμε τα χέρια, ας τεντωθούμε από την μια άκρη της γης ως την άλλη
          φτιάχνοντας το πιο όμορφο ουράνιο τόξο. Το τόξο της φιλίας και της
          ενσυναίσθησης!
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Έκθεση του Δασκάλου Ιωάννη ΣΤ΄τάξη.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τα τελευταία χρόνια το bullying ή αλλιώς εκφοβισμός, το συναντάμε
          πολλές φορές. Γιατί όμως γίνεται αυτό? Ο εκφοβισμός υπήρχε και στα πιο
          παλιά χρόνια, αλλά όχι τόσο έντονα όπως τώρα. Ο εκφοβισμός είναι, όταν
          κάποιος προσπαθεί να κάνει κάποιον να νιώθει άσχημα και να το
          συνεχίζει. Πως πρέπει όμως τα παιδιά να αντιμετωπίζουν αυτό? Πολλοί το
          αντιμετωπίζουν λάθος, δηλαδή δεν το λένε σε κανέναν και φοβούνται να
          το πουν. Τα παιδιά πρέπει να το συζητάνε με τους γονείς τους ή με τους
          δασκάλους.
          <br />
          <br />
          Έχει όμως σχέση το bullying με τον πολιτισμό? Στην κοινωνία πρέπει να
          σεβόμαστε τους γύρω ανθρώπους μας, να τους φερόμαστε με ευγένεια ώστε
          να μην νιώθουν στεναχώρια και να μας σέβονται και εκείνοι. Το bullying
          δεν ταιριάζει μ αυτό και πρέπει οπωσδήποτε να σταματήσει με αποτέλεσμα
          να έχουμε έναν πολιτισμένο κόσμο.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          «Η Μουσική του Βασιλιά»
        </Typography>

        <img
          src={Image27}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image29}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Μουσικοχορευτική εκδήλωση πραγματοποιήθηκε με επιτυχία, στον αύλειο
          χώρο του Μουσείου «Teriade» με την συμμετοχή και του χορευτικού
          τμήματος του Συλλόγου μας, την Τετάρτη 21 Ιουνίου 2023 στις 9 το
          βράδυ.
          <br />
          <br />
          Στην εκδήλωση συμμετείχαν επίσης το χορευτικό τμήμα του Συλλόγου
          γυναικών Θερμής, η χορευτική ομάδα «Πάραλος» και το φωνητικό σύνολο
          «GITAMO», ενώ την διδασκαλία των χωρών είχε επιμεληθεί η κ. Φλώρα
          Αντωνοπούλου. <br />
          <br />Η «Μουσική του Βασιλιά» ήταν ένα μουσικοχορευτικό παραμύθι που
          απόλαυσαν όλοι οι παρευρισκόμενοι και κυρίως τα παιδιά, από το οποίο
          διδαχθήκαμε τον παγκόσμιο ρόλο της Μουσικής που κατορθώνει να ενώνει
          όλους τους λαούς της γης.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τα Κάψαλα και ο κλήδονας στη Σκάλα Λουτρών
        </Typography>

        <img
          src={Image31}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ανάβουνε φωτιές στις γειτονιές του Άη Γιάννη αχ πόσα ξέρεις και μου
          λες αχ πόσα τέτοια ξέρεις και μου λες που ’χουν πεθάνει…
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το Σάββατο 24 Ιουνίου, ημέρα της εορτής του Αγίου Ιωάννου Προδρόμου,
          αναβιώσαμε μετά από πολλά χρόνια τα Κάψαλα και ανοίξαμε τον Κλήδονα,
          στο θερινό θεατράκι του Συλλόγου μας στη Σκάλα Λουτρών. Μεγάλη η
          συμμετοχή μικρών και μεγάλων, τόσο στα Κάψαλα όσο και στον Κλήδονα,
          όπου ακούστηκαν όλων των ειδών τα ποιήματα, σατυρικά, ερωτικά,
          σεξουαλικά κ.α.
          <br />
          <br />
          Τα Κάψαλα ή αλλιώς πυροβασίες, είναι πράξη κατά την οποία ανάβονται
          φωτιές στις πλατείες ή στους δρόμους κάθε χωριού και οι άνθρωποι
          πηδούν από πάνω τους τρεις φορές, προσέχοντας για να μην καούν, με
          απώτερο σκοπό να εξαγνισθούν ψυχή τε και σώματι, και να ξορκιστεί το
          κακό και οι αρρώστιες! Και αυτό γιατί η φωτιά είχε τα παλιά χρόνια
          καθαρκτική ιδιότητα και οι άνθρωποι με την ομοιοπαθητική μαγεία την
          μεταχειρίζονταν και στη ζωή τους, νομίζοντας ότι θα απαλλαγούν από ότι
          κακό τους απειλούσε, χωρίς να γνωρίζουν ότι πίσω από όλα αυτά υπήρχαν
          δαιμονικές παρουσίες και θλιβερές επιρροές για την ζωή τους.
          <br />
          <br />Ο κλήδονας είναι τρόπον τινά η συνέχιση τελετών που γινόντουσαν
          στα αρχαία χρόνια στα μαντεία, όπου εκεί «προφητεύονταν» δήθεν τα
          μελλούμενα και «αποκτιόταν» η δύναμη του ήλιου με μαντικές πρακτικές,
          ενώ σήμερα κατά την συνήθεια του κλήδονα λέγονται διάφορες λέξεις,
          στιχάκια, λόγια και φράσεις, ακόμα και αδιάντροπου ερωτικού και
          σατιρικού περιεχομένου, χάριν ψυχαγωγίας! Επίσης, οι κοπέλες πίνουν το
          αμίλητο νερό, δηλ. κρατούν το νερό μέσα στο στόμα τους, μέχρι να
          μάθουν το όνομα του άνδρα που θα παντρευτούν.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          11ος Κολυμβητικός διάπλους Μικρασιατικής Μνήμης
        </Typography>

        <img
          src={Image33}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image35}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε με επιτυχία και ικανοποιητική συμμετοχή την Κυριακή{" "}
          <b>30 Ιουλίου 2023</b>, ο <b>12</b>ος κολυμβητικός διάπλους του Κόλπου
          Γέρας από την Κουντουρουδιά έως το Πέραμα. Οι <b>53</b> κολυμβητές και
          κολυμβήτριες που συμμετείχαν διήνυσαν την απόσταση των <b>1.000</b>{" "}
          περίπου μέτρων που χωρίζει την Κουντουρουδιά από το Πέραμα χωρίς
          ιδιαίτερα προβλήματα αφού ο καιρός ήταν καλός και η θάλασσα με ανεκτό
          κυματισμό.
          <br />
          <br />
          Οι ηλικίες των κολυμβητών που συμμετείχαν ήταν από <b>14</b> έως{" "}
          <b>76</b> ετών και ο χρόνος που έκαναν κυμάνθηκε από <b>16</b> περίπου
          λεπτά ο πρώτος μέχρι <b>53</b> λεπτά ο τελευταίος. Στην ασφαλή
          διεξαγωγή του κολυμβητικού διάπλου συνέβαλαν αποφασιστικά, το πλωτό
          σκάφος του Λιμενικού Σώματος που επέβλεπε συνεχώς τους κολυμβητές,
          καθώς και η παρουσία των ιατρών Μάκη Αξιώτη και Μιχαλάκα Ευστρατίας,
          των Χρόνη Παναγιώτη και Πανσεληνά Στέλιου από την Ελληνική ομάδα
          διάσωσης και του Φλασκή Κωνσταντίνου από το ΕΚΑΒ που παρευρίσκονταν
          για να προσφέρουν τις πρώτες βοήθειες αν χρειαζόταν.
          <br />
          <br />
          Τις ευχαριστίες μας οφείλουμε σε όλους τους προαναφερθέντες, καθώς
          επίσης και στον καπετάνιο κ. Στέλιο Χιώτη, ο οποίος διέθεσε την βάρκα
          του για τη δωρεάν μεταφορά των αθλητών και των συνοδών τους από την
          Κουντουρουδιά στο Πέραμα και αντιστρόφως.
          <br />
          <br />
          Τέλος, σε όλους τους συμμετέχοντες δόθηκαν αναμνηστικά διπλώματα και
          μετάλλια για τη συμμετοχή τους, τα οποία ήταν προσφορά των διοργανωτών
          Συλλόγων.
          <br />
          <br />
          Και του χρόνου με υγεία.
          <br />
          <br />
          * Ο φετινός Διάπλους ήταν αφιερωμένος στον αείμνηστο Στρατή Καραντώνη,
          τον εμβληματικό βαρκάρη του κόλπου, που αφιέρωνε την ψυχή και την
          καρδιά του σε όλους τους προηγούμενους διάπλους, που μας συντρόφευε με
          γέλιο και προθυμία. <br />
          Στρατή, για πάντα μαζί μας στα ταξίδια μας στην αγαπημένη θάλασσα της
          Γέρας...
          <br />
          <br />
          <u>Διοργανωτές της εκδήλωσης ήταν:</u>
          <br />
          <br />
          Ο «Παγγεραγωτικός» Πολιτιστικός Σύλλογος Γέρας <br />
          Ο Σύλλογος Μικρασιατών Σκάλας Λουτρών «ΤΟ ΔΕΛΦΙΝΙ»
          <br />
          Ο «Παγγεραγωτικός» Σύλλογος Μυτιλήνης και
          <br />Ο ΑΠΣ «Αναγέννηση Ποσειδώνα» Περάματος
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Βράβευση αριστούχων μαθητών Δημοτικής Κοινότητας Λουτρών
        </Typography>

        <img
          src={Image37}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image39}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στον αύλιο χώρο του «Μουσείου Προσφυγικής Μνήμης 1922», της Σκάλας
          Λουτρών, πραγματοποιήθηκε, την Κυριακή <b>6-8-2023</b> το απόγευμα, με
          επιτυχία και αθρόα συμμετοχή, η εκδήλωση για την βράβευση των
          αριστούχων μαθητών της Δημοτικής Κοινότητας Λουτρών από τον Σύλλογο
          Μικρασιατών <b>«Το Δελφίνι»</b>, παρουσία της διευθύντριας και των
          δασκάλων του Δημοτικού Σχολείου Λουτρών, του προέδρου της κοινότητας
          Λουτρών και του προέδρου του Αετού Λουτρών. <br />
          <br />
          Στην εκδήλωση μας τίμησαν με την παρουσία τους ο Περιφερειάρχης Β.
          Αιγαίου κ. Κωνσταντίνος Μουτζούρης ενώ μήνυμα που διαβάστηκε μας
          έστειλε ο Γ.Γ. Αιγαίου και Νησιωτικής Πολιτικής κ. Μανώλης
          Κουτουλάκης. <br />
          <br />
          Στην αρχή της εκδήλωσης έγινε μια σύντομη ενημέρωση από τον πρόεδρο
          του Συλλόγου κ. Δημήτρη Παπαχρυσό, σχετική με τις τρέχουσες
          δραστηριότητες του Συλλόγου και εν συνεχεία οι βραβεύσεις άρχισαν με
          τα παιδιά του Δημοτικού σχολείου που διακρίθηκαν στην ζωγραφική και
          στην έκθεση, μια δραστηριότητα που επαναλαμβάνεται κάθε χρόνο με την
          συνεργασία του Συλλόγου και των δασκάλων του Δημοτικού Σχολείου
          Λουτρών. <br />
          <br />
          Οι <b>4</b> πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα: <br />
          <b>«Ζωγραφίστε ένα τοπίο του χωριού μας που σας αρέσει!»</b>
          <br />
          <br />
          και οι 2 μεγαλύτερες τάξεις διαγωνίσθηκαν στην έκθεση με θέμα:
          <br />
          <b>
            «Το bullying (εκφοβισμός) έχει σχέση με τον πολιτισμό που
            επιδιώκουμε?»{" "}
          </b>
          <br />
          <br />
          <u>Βραβεύτηκαν:</u>
          <br />
          <br />
          Από την <b>Α΄ Τάξη</b> η Μαμουνή Γεωργία και η Ζαμτράκη Στέλλα.
          <br />
          Από την <b>Β΄ Τάξη</b> η Κρεμύδα Δήμητρα και η Μαστροσάββα Σοφία.
          <br />
          Από την <b>Γ΄ Τάξη</b> η Μανιάτη Σταματίνα και η Σταματέλλη Ανθή.
          <br />
          Από την <b>Δ΄ Τάξη</b> η Μπολύλα Βασιλική και η Γκέντση Ελεονώρα.{" "}
          <br />
          Από την <b>Ε1΄ Τάξη</b> η Βουτσαρδέλλη Μαρία και η Ντάκα Αντζελική.
          <br />
          Από την <b>ΣΤ1΄ Τάξη</b> ο Δασκάλου Γιάννης και ο Παπουτσής Ταξιάρχης.
          <br />
          Από την <b>ΣΤ2΄ Τάξη</b> ο Παπουτσής Χρυσοβαλάντης και η Τατάκη
          Αλεξάνδρα.
          <br /> <br />
          <br />
          Ακολούθησε η βράβευση των αριστούχων παιδιών του Γυμνασίου και του
          Λυκείου.
          <br />
          <u>Βραβεύτηκαν:</u>
          Από την <b>Α!</b> γυμνασίου <br /> η <b>Κλάβα Σοφία</b> <br />ο{" "}
          <b>Ραλής Αναστάσιος</b>
          <br />η <b>Γεωργούση Βασιλική</b> και
          <br />ο <b>Καλιοντζής Άγγελος</b>
          <br />
          <br />
          <br />
          Από την <b>Β!</b> γυμνασίου <br /> ο <b>Δασκάλου Φίλιππος</b> <br />η{" "}
          <b>Βαϊοπουλου Ελένη</b> και
          <br />ο <b>Τατάκης Γεώργιος</b>
          <br />
          <br />
          <br />
          Από την <b>Γ!</b> γυμνασίου <br /> ο <b>Στρίγκος Χρήστος</b> <br />η{" "}
          <b>Ζούρου Ευαγγελία</b> και
          <br />η <b>Λιαδέλλη Δωροθέα</b>
          <br />
          <br />
          <br />
          Από την <b>Α!</b> λυκείου <br /> η <b>Μύνικνα Μαρία</b> και
          <br />η <b>Κάσδαγλη Μυρσίνη</b>
          <br />
          <br />
          Από την <b>Β!</b> λυκείου <br /> η <b>Τρουλινού Αικατερίνη</b>
          <br />η <b>Γκίζα Ευστρατία</b>
          <br />η <b>Κέντση Μαρία</b>
          <br />η <b>Μύνικνα Κωνσταντίνα</b> <br />η <b>Μολυβιάτου Νίκη</b> και
          <br />η <b>Γκέρσα Σπαχόλλι</b> <br />
          <br />
          <br />
          Από την <b>Γ!</b> λυκείου <br /> ο <b>Λέλεκας Φίλιππος</b> <br />ο{" "}
          <b>Στρίγκος Μάριος</b> <br />ο <b>Αρμενάκας Νίκος</b> <br />η{" "}
          <b>Γεωργιτζίκη Παρασκευή</b> και
          <br />ο <b>Αλεξίου Γεώργιος</b> <br />
          <br />
          <br />
          Τα δώρα που πήραν τα παιδιά του Δημοτικού σχολείου εκ μέρους του
          Συλλόγου που κάλυψε την σχετική δαπάνη ήταν, από ένα tablet writer για
          σημειώσεις, σχέδια και ζωγραφική, ενώ στα παιδιά του Γυμνασίου και του
          Λυκείου προσφέρθηκε από ένα ρολόι (smart watch) για παρακολούθηση των
          αθλητικών δραστηριοτήτων τους. <br /> <br />
          Η βράβευση των διακριθέντων παιδιών του Δημοτικού Σχολείου Λουτρών
          έγινε από την διευθύντρια και τους δασκάλους του σχολείου ενώ τα
          αριστούχα παιδιά του Γυμνασίου και του Λυκείου, βραβεύτηκαν από τον
          πρόεδρο της κοινότητας μας και από μέλη του Δ.Σ. του Συλλόγου. <br />{" "}
          <br />
          Συγκινητική ήταν η παρουσία της κ. Όλγας Γονιδέλλη, κόρης του
          εκλιπόντος κ. Απόστολου Γονιδέλλη, η οποία κατόπιν επιθυμίας του,
          θέλησε να συνεχίσει και να βραβεύσει για 3η συνεχόμενη χρονιά, τα
          αριστούχα παιδιά της Γ΄ Λυκείου με το ποσό των 500 ευρώ (100 ευρώ σε
          κάθε έναν από τους 5 αριστούχους). <br /> <br />s Η εκδήλωση
          συνεχίστηκε με τον δάσκαλο μουσικής κ. Γρηγόρη Μολυβιάτη και την κ.
          Νάσια Δαφιώτη που μας παρουσίασαν ένα σύντομο μουσικό καλλιτεχνικό
          πρόγραμμα, αφιερωμένο στα παιδιά που βραβεύσαμε.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Κοινός λόγος της Έλλης Παπαδημητρίου
        </Typography>

        <img
          src={Image41}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image43}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          <i>
            {" "}
            «Ολοένα λιγοστεύει η γενιά που έζησε την καταστροφή του 1922, που
            πέρασε από τότε στη Μυτιλήνη τη ζωή της προσφυγιάς, που βλέπει τα
            μέρη τ’ αντικρινά και μπορεί να θυμάται. Οι πιο πολλοί αν όχι όλοι,
            ξεκουράζονται πια, αφού απόκαμαν, στα μικρά νεκροταφεία των
            λεσβιακών χωριών».
          </i>
          <br />
          <br />
          Την Κυριακή 20 Αυγούστου φιλοξενήσαμε στον αύλειο χώρο του «Μουσείου
          Προσφυγικής Μνήμης 1922», τον Φ.Ο. Μυτιλήνης για να παρακολουθήσουμε
          την θεατρική παράσταση με τίτλο <b>«Κοινός λόγος»</b> της Έλλης
          Παπαδημητρίου, που ερμήνευσε η κ. Γεωργία Σαλεβουράκη.
          <br />
          <br />Η Έλλη Παπαδημητρίου γεννήθηκε το 1906 στη Σμύρνη και μεγάλωσε
          στην Αθήνα όπου σπούδασε γεωπονία. Εξέδωσε την ποιητική συλλογή{" "}
          <b>"Απόκριση"</b> το 1946 με ποιήματα από τη Μέση Ανατολή κατά τη
          διάρκεια του πολέμου, και το θεατρικό έργο <b>"Ανατολή"</b> το 1952,
          με θέμα τη Μικρασιατική Καταστροφή. <br />
          <br />
          Ακολούθησαν τα βιβλία:<b>"Ποιητική γνώση"</b> το 1952,{" "}
          <b>"Οκτώ τετράδια ποιήματα"</b> το 1961, <b>"Το βουνό"</b> το 1963 και
          το <b>"Ελληνική βοήθεια προς Αμερική"</b> το 1964. Την ίδια χρονιά
          εξέδωσε μια σειρά από δεκαεπτά προφορικές μαρτυρίες ανθρώπων που
          επέζησαν από τη Μικρασιατική Καταστροφή, την Κατοχή, την Αντίσταση και
          τον Εμφύλιο, με τίτλο <b>"Ακούμε τη φωνή σου, πατρίδα"</b>. <br />
          <br />
          Ανάμεσα στο 1972 και το 1979, το υλικό αυτό, φτάνοντας τις 100 περίπου
          αφηγήσεις, επανεκδόθηκε από τις εκδόσεις Κέδρος σε τέσσερις τόμους,
          παίρνοντας τον τίτλο <b>"Κοινός λόγος"</b>. Θεατρικές διασκευές του
          έργου πραγματοποιήθηκαν στην Αθήνα από τον Βαγγέλη Θεοδωρόπουλο, το
          1988, το 1997, το 2004 και το 2013 - οι τρεις τελευταίες μετά το
          θάνατο της συγγραφέως, το 1993. Πριν ένα χρόνο την σκυτάλη πήρε ο Φ.Ο.
          Μυτιλήνης ο οποίος παρουσίασε στο θεατράκι του στη Μυτιλήνη το έργο{" "}
          <b>"Κοινός λόγος"</b> και τους ευχαριστούμε ιδιαιτέρως που αποδέχτηκαν
          την πρόταση μας να έρθουν στη Σκάλα Λουτρών για να μας τo
          παρουσιάσουν. <br />
          <br />
          Ευχαριστούμε ιδιαιτέρως τον κ. Σεφτελή Ανδρέα που σκηνοθέτησε την
          παράσταση, την κ. Γεωργία Σαλεβουράκη που ερμήνευσε τον «Κοινό λόγο»
          και την καλλιτεχνική διευθύντρια του Φ.Ο.Μ. κ. Ειρήνη Δημητρίου που
          είχε τον συντονισμό για την κάλυψη του φωτισμού και της μουσικής.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Η προσφορά των Μικρασιατών προσφύγων στην κοινωνία του χωριού μας
        </Typography>

        <img
          src={Image45}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image47}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Τρίτη 22 Αυγούστου 2023 και ώρα 19:30, μετά την τέλεση του
          πανηγυρικού Εσπερινού στο εκκλησάκι της Παναγίας, στον προσφυγικό
          Συνοικισμό της Σκάλας Λουτρών, πραγματοποιήθηκε η περιφορά της εικόνας
          της Παναγίας στους δρόμους του χωριού με κατάληξη τον αύλειο χώρο του{" "}
          <b>"Μουσείου Προσφυγικής Μνήμης 1922"</b>. <br />
          <br />
          Στην συνέχεια πραγματοποιήθηκε εκδήλωση, με θέμα την προσφορά των
          Μικρασιατών Προσφύγων της Σκάλας Λουτρών στην κοινωνία του χωριού μας
          με τραγούδια, χορούς και ιστορίες από την ζωή των προσφύγων της Σκάλας
          Λουτρών, κατά την περίοδο της κατοχής του νησιού, τα δίσεκτα χρόνια
          του 1940-44.
          <br />
          <br />
          Εκ μέρους του Δ.Σ. εκφράζουμε τις ευχαριστίες μας στο μέλος του
          Συλλόγου μας την κ. Ευθαλία Τουρλή που είχε το κουράγιο, την υπομονή
          και την επιμονή, να συγκεντρώσει και να καταγράψει τις ιστοριούλες που
          ακούστηκαν, την κ. Φλώρα Αντωνοπούλου που είχε την επιμέλεια των
          χορευτικών τμημάτων, καθώς και σε όλους όσους εργάστηκαν για να
          μπορέσει να υλοποιηθεί σε σύντομο χρονικό διάστημα αυτή η εκδήλωση.{" "}
          <br />
          <br />
          Η βραδιά συνεχίστηκε με την ορχήστρα που μας κράτησε συντροφιά μέχρι
          τα μεσάνυχτα και με την προσφορά στους επισκέπτες μας, από ένα ποτήρι
          κρασί μαζί με τον παραδοσιακό καβουρμά που είχαν ετοιμάσει οι
          νοικοκυρές της Σκάλας Λουτρών. <br />
          <br />
          Στην εκδήλωση μας τίμησαν με την παρουσία τους η αντιπεριφερειάρχης
          Πολιτισμού και Οικονομικών κ. Αναστασία Αντωνέλλη, ο πρόεδρος της
          κοινότητας Λουτρών κ. Βασίλης Μαμώλης, ο πρόεδρος του Αετού Λουτρών κ.
          Νίκος Σπανός, ο πρόεδρος του Συλλόγου Γονέων του Δημοτικού σχολείου
          Λουτρών κ. Δημήτρης Καντεμνίδης, ο κ. Απόστολος Μάτας από τον Σύλλογο
          Λέσβος χωρίς πλαστικά, η δημοσιογράφος κ. Βαρβάρα Γκιγκιλίνη, ο
          πρόεδρος του Μικρασιατικού Συλλόγου Κιόστε κ. Γεώργιος Κατσούπης και
          το μέλος κ. Δημήτρης Μπουρνούς, ο κ. Αργύρης Χατζημαλής από τους
          Αιολείς κ.α. Η έκπληξη της βραδιάς ήταν η βράβευση του προέδρου και
          του Δ.Σ. του Συλλόγου μας από την Αντιπεριφερειάρχη Πολιτισμού και
          Οικονομικών της Π.Β. Αιγαίου της κ. Αναστασίας Αντωνέλλη που λέει:{" "}
          <br />
          <br />
          <b>Βραβείο</b>: Απονέμεται στον πρόεδρο του Δ.Σ. Δημήτρη Παπαχρυσό και
          στα μέλη του Διοικητικού Συμβουλίου του Συλλόγου Μικρασιατών Σκάλας
          Λουτρών «Το Δελφίνι» για την προσφορά τους στην διατήρηση της
          Ιστορικής Μνήμης και της Ανάδειξης του Μικρασιατικού Πολιτισμού.
          Μυτιλήνη 22 Αυγούστου 2023. Ο Περιφερειάρχης Κωνσταντίνος Μουτζούρης
          και η Αντιπεριφερειάρχης Πολιτισμού Βορείου Αιγαίου Αναστασία
          Αντωνέλλη.
          <br />
          <br />
          Οι εκδηλώσεις μνήμης του Συλλόγου ολοκληρώθηκαν με την τέλεση Θείας
          Λειτουργίας την Τετάρτη 23 Αυγούστου 2023 το πρωί στην Παναγιά την
          Αψηλή.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αφιέρωμα στην Άννα Κινδύνη
        </Typography>

        <img
          src={Image49}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image51}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Τετάρτη 13 Σεπτεμβρίου στις 8 το βράδυ, πραγματοποιήθηκε η
          εκδήλωση του αφιερώματος για την Φωκιανή Άννα Κινδύνη Μαυρουδή στον
          αύλειο χώρο του «Μουσείου Προσφυγικής Μνήμης 1922» στη Σκάλα Λουτρών.{" "}
          <br />
          <br />
          <i>
            « Η Άννα Κινδύνη-Μαυρουδή γεννήθηκε το 1914 στη Φώκαια της Μικράς
            Ασίας. Με την έναρξη του Πρώτου Παγκοσμίου Πολέμου η οικογένεια της
            κατέφυγε στη Μυτιλήνη. Επέστρεψαν στη Μ. Ασία το 1919 με την τότε
            παρουσία του Ελληνικού στρατού στη Σμύρνη, αλλά η ολοκληρωτική
            καταστροφή του 1922 ανάγκασε την οικογένεια της να προσφύγει για
            δεύτερη φορά στην Μυτιλήνη, πριν εγκατασταθεί οριστικά στην Αθήνα,
            το 1930. <br />
            <br />
            Εκεί εργάστηκε αρχικά ως σχεδιάστρια στην Εθνική Τράπεζα και το 1940
            παντρεύτηκε τον αρχιτέκτονα Μανόλη Κινδύνη. <br />
            <br />
            Κατά τη διάρκεια της Κατοχής μπήκε στην Αντίσταση, στον τομέα της
            Εθνικής Αλληλεγγύης και δούλεψε στην στατιστική υπηρεσία του Δήμου
            Αθηναίων, επιφορτισμένη με την καταμέτρηση των καθημερινών θανάτων
            στους δρόμους της πρωτεύουσας, καθήκον που σε μεγάλο βαθμό καθόρισε
            την μετέπειτα καλλιτεχνική της πορεία. Αμέσως μετά την απελευθέρωση,
            με τον άνδρα της υπότροφο της Γαλλικής Κυβέρνησης, φεύγει για το
            Παρίσι, μέσω Ιταλίας. <br />
            <br />
            Το 1947 εγγράφεται στο εργαστήριο χαρακτικής της Σχολής Καλών Τεχνών
            του Παρισιού, ενώ ταυτόχρονα παρακολουθεί μαθήματα σχεδίου σε δύο
            Ακαδημίες, με καθηγητές τους Δημήτρη Γαλάνη και Albert Cami. Από
            τότε, και έως το 1965 περίπου, δουλεύει αποκλειστικά με κάρβουνο. Το
            1960 κάνει την πρώτη της ατομική έκθεση σε γκαλερί του Παρισιού και
            δύο χρόνια αργότερα σε γκαλερί του Λονδίνου. Το 1963 της απονέμεται
            το βραβείο <b>«Eugene Carrierre»</b>..
            <br />
            <br />
            Τα θέματα και η τεχνοτροπία της σιγά-σιγά διαφοροποιούνται. Η
            Κινδύνη πειραματίζεται με μία δική της τεχνική, χαράσσοντας πάνω σε
            πλαστική ύλη. Αποτέλεσμα της διαδικασίας αυτής είναι η μεγάλη σειρά{" "}
            <b>«Έρωτες»</b>, με έργα που ξεκινούν από το 1975. <br />
            <br />
            Από το 1984 μέχρι και την αποδημία της το 2003, οργανώνει διάφορες
            εκθέσεις σε διάφορα πνευματικά κέντρα και στην πινακοθήκη του Δήμου
            Αθηναίων. <br />
            <br />
            Το μεγαλύτερο μέρος του έργου της δωρίθηκε στο Μουσείο Μπενάκη από
            την ανιψιά της Μαργαρίτα Παπαδημητρίου, το 2006. Έργα της βρίσκονται
            επίσης στην Δημοτική Πινακοθήκη της Αθήνας, στην Τράπεζα της Ελλάδος
            και σε πολλές δημόσιες και ιδιωτικές συλλογές της Ελλάδος και του
            εξωτερικού. <br />
            <br />
            Τον Ιανουάριο του 2009, έξι χρόνια μετά το θάνατό της, κυκλοφόρησε
            το βιβλίο "Άννα Κινδύνη 1914-2003".»
          </i>
          <br />
          <br />
          Διοργανωτές της εκδήλωσης ήταν, ο Σύλλογος Μικρασιατών της Σκάλας
          Λουτρών «Το Δελφίνι» και ο Πολιτιστικός Σύλλογος «Φωκαέων Πολιτεία»
          από την Παλαιά Φώκαια Αττικής. Ο πρόεδρος του Συλλόγου κ. Παπαχρυσός
          Δημήτρης καλωσόρισε τους φιλοξενούμενους εκ μέρους του Δ.Σ. και η κ.
          Ευθαλία Τουρλή τους ξενάγησε στον χώρο του Μουσείου όπου τους
          προσφέρθηκαν διάφορα παραδοσιακά γλυκά του κουταλιού και αμυγδαλωτά
          από τον «Συνεταιρισμό Γυναικών Μεσοτόπου». <br />
          <br />
          Η παρουσίαση της ζωής και του έργου της Φωκιανής Άννας Κινδύνη από
          τους προαναφερθέντες Συλλόγους ήταν εξαιρετική και μας έκρυβε μία
          έκπληξη στο τέλος, αφού με πρωτοβουλία του συμπατριώτη μας (σκηνοθέτη)
          κ. Μάνου Ευστρατιάδη και με αίτημα του Συλλόγου μας προς την ΕΡΤ μας
          εστάλη και παρουσιάσαμε στο τέλος της εκδήλωσης, ένα οπτικό απόσπασμα
          13 λεπτών από μία συνέντευξη της Άννας Κινδύνη το 1986 που είχε
          συμπεριληφθεί σε ένα ωριαίο ντοκιμαντέρ για το έργο της. <br />
          <br />
          Τις ευχαριστίες μας για την εκδήλωση οφείλουμε στην «Φωκαέων Πολιτεία»
          και στην πρόεδρο της κ. Ζαχαρώ Φραντζέσκου για την άριστη συνεργασία
          που είχαμε, στην κ. Δέσποινα Ζερβού και το λοιπό προσωπικό της ΕΡΤ για
          την άμεση ανταπόκρισή τους, στον σκηνοθέτη κ. Μάνο Ευστρατιάδη που μας
          έκανε την σχετική ενημέρωση και μεσολάβησε στην ΕΡΤ για την
          διεκπεραίωση του θέματος, στον δάσκαλο μας Γρηγόρη Μολυβιάτη που
          πλαισίωσε με την κλασική κιθάρα του την εκδήλωση, την κ. Ζαχαρώ
          Φρατζέσκου και την κ. Αθανασία Δαφιώτη που ήταν στην αφήγηση του έργου
          της και σε όσους άλλους βοήθησαν αφανώς για την επιτυχία της
          εκδήλωσης.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Σεμινάριο πρώτων βοηθειών από το «Χαμόγελου του παιδιού»
        </Typography>

        <img
          src={Image53}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με έντονο ενδιαφέρον παρακολουθήσαμε την Παρασκευή 6 Οκτωβρίου 2023
          στον αύλειο χώρο του <b>"Μουσείου Προσφυγικής Μνήμης 1922"</b>, από
          τους εκπροσώπους του <b>«Χαμόγελου του παιδιού»,</b> το σεμινάριο
          πρώτων βοηθειών και καρδιοαναπνευστικής αναζωογόνησης, κατά την
          διάρκεια του οποίου αποκτήσαμε χρήσιμες γνώσεις για την παροχή εκ
          μέρους μας πρώτων βοηθειών σε συνανθρώπους μας που θα το έχουν άμεση
          ανάγκη.
          <br />
          <br />
          Αγαπητοί μας φίλοι του <b>«Χαμόγελου του παιδιού»</b>, εκ μέρους του
          Δ.Σ. του Συλλόγου Μικρασιατών Σκάλας Λουτρών <b>"Το Δελφίνι"</b> και
          των κατοίκων του οικισμού μας, σας ευχαριστούμε πάρα πολύ για όσα
          χρήσιμα μας διδάξατε και για τις απαντήσεις που δώσατε στους
          συμμετέχοντες, λύνοντας όσες απορίες είχαν. <br />
          <br />
        </Typography>

        <h4>Λοιπές δραστηριότητες</h4>

        <Typography variant="h3" sx={theme.typography.typography2}>
          &nbsp;
          <br />
          <b>Μουσικό τμήμα.</b>
          <br />
          Μετά από διακοπή σχεδόν τριών ετών λόγω COVID-19, θα επαναλειτουργήσει
          το μουσικό μας τμήμα από το νέο έτος. Τα μαθήματα θα γίνονται στην
          Βιβλιοθήκη του Συλλόγου κάθε Σάββατο, από τις 12 το μεσημέρι μέχρι τις
          5 το απόγευμα. Για περισσότερες πληροφορίες μπορείτε να έρθετε σε
          επικοινωνία με τον πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό στο τηλ.
          69746.40419. Δάσκαλος θα είναι ο κ. Μολυβιάτης Γρηγόρης ο οποίος επί
          πολλά χρόνια έχει προσφέρει εξαιρετικό έργο. <br />
          <br />
          <b>Νέα τμήματα.</b>
          <br />
          Ο Σύλλογός μας μπορεί να ιδρύσει νέα τμήματα παραδοσιακών μουσικών
          οργάνων όπως π.χ. βιολί, λαούτο κτλ εφ όσον εκδηλωθεί σχετικό
          ενδιαφέρον από τα μέλη μας. <br />
          Διευκρινίζουμε ότι για να λειτουργήσει ένα νέο τμήμα θα πρέπει να
          ενδιαφερθούν κατ ελάχιστο τρία άτομα.
          <br />
          <br />
          <b>Τμήματα παραδοσιακών τραγουδιών και χορών.</b>
          <br />
          Έχουν ξεκινήσει και λειτουργούν τμήματα παραδοσιακών χορών για παιδιά
          και ενήλικες με δασκάλα την κ. Φλώρα Αντωνοπούλου και όσοι
          ενδιαφέρονται μπορούν να έλθουν σε συνεννόηση με την κ. Ρένα
          Σταματέλλη στο τηλ. 69777.07860. <br />
          Τα μαθήματα κατά την διάρκεια του χειμώνα θα γίνονται στο ξενοδοχείο
          ΖΑΪΡΑ κάθε Σάββατο απόγευμα από τις 4 μέχρι τις 6 και εν συνεχεία τους
          θερινούς μήνες στον αύλιο χώρο του «Μουσείου Προσφυγικής Μνήμης 1922»
          στη Σκάλα Λουτρών.
          <br />
          <br />
          <b>Τμήμα βιβλιοθήκης</b>
          <br />
          Με πλέον των 8.500 περίπου τίτλους βιβλίων είναι εμπλουτισμένη η
          βιβλιοθήκη του Συλλόγου μας τα οποία είναι διαθέσιμα για να δανειστούν
          στα μέλη μας, αλλά και σε όσους άλλους ενδιαφέρονται. Όλα τα βιβλία
          είναι καταχωρημένα σε ηλεκτρονικό υπολογιστή για την εύκολη διαχείριση
          τους.
          <br />
          Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει
          τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση
          είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα
          προβλήματα που αποτελούν τη ζωή του σύγχρονου ανθρώπου και τοποθετεί
          τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη
          διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και
          φανατισμούς.
          <br />
          <br />
          <b>Δωρεά βιβλίων στο Σύλλογό μας.</b> <br />
          Ευχαριστούμε θερμά τους κ.κ. Χατζηδήμο Αλέξανδρο, Πελεκάνο Θεόδωρο,
          Δαφιώτη Νάσια, Λιούμπα Σκούρτη-Τυραδέλλη, Ματζουράνη Ευστράτιο και
          Ματθαίου Μιχάλη, για τα βιβλία που πρόσφεραν στην βιβλιοθήκη του
          Συλλόγου μας. <br />
          <br />
          <b>Ευχαριστήρια</b>
          <br />
          Ευχαριστούμε θερμά:
          <br />
          *Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο
          μας.
          <br />
          *Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες
          συντήρησης των κτιρίων του Συλλόγου, καθώς και όσους προσφέρουν
          εθελοντικώς την εργασία τους.
          <br />
          <h4>Οικονομικές ενισχύσεις</h4>
          <b>
            Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.
          </b>
          <br />
          <br />
          500 ευρώ, Σταύρος και Βίκυ Κρητικού, για υποστήριξη των Μικρασιατικών
          εκδηλώσεων του Συλλόγου, σαν φόρο τιμής στην οικογένεια των παππούδων
          τους Νικολάκη και Ευφροσύνης Κρητικού από τη Φώκαια Μ. Ασίας.
          <br />
          250 ευρώ, Καλιοντζής Μιχάλης στη μνήμη της μητέρας του Κατίνας από
          προσφορές στεφανιών στην κηδεία της. <br />
          150 ευρώ, Δεσπότου Έλλη στη μνήμη των γονέων της Γεωργίου και
          Ευαγγελίας. <br />
          50 ευρώ, Καλαθάς Γρηγ. & Αιβαλιώτου Χρ. στη μνήμη των γονέων τους.
          <br />
          100 ευρώ, Κλήμη Μελίνα και Πελαγία, στη μνήμη Αριστείδη και Μερόπης
          Κλήμη.
          <br />
          50 ευρώ, Τζανουδάκη Ιωάννα.
          <br />
          50 ευρώ, Κοντούλης Γεώργιος υπέρ σεισμόπληκτων Τουρκίας <br />
          15 ευρώ, Γονιδέλλη Βενέτα υπέρ σεισμόπληκτων Τουρκίας <br />
          20 ευρώ, Γρηγορέλλη Κατερίνα υπέρ σεισμόπληκτων Τουρκίας <br />
          30 ευρώ, Τζανουδάκη Ιωάννα υπέρ σεισμόπληκτων Τουρκίας
          <br />
          50 ευρώ, Αναγνωστοπούλου Χρυσούλα <br />
          50 ευρώ, Χατζηδήμος Αλέξανδρος <br />
          50 ευρώ, Μαυραντώνη-Σαλτερή Βικτωρία στη μνήμη Πελαγίας Μαυραντώνη
          <br />
          30 ευρώ, Γιαννίκος Στρατής.
          <br />
          20 ευρώ, Κεφαλίδης Δημήτρης. <br />
          50 ευρώ, Τζαβίδη Πέπη και Μανδαμά Ελπίδα στη μνήμη της αδελφής της
          Φρόσως
          <br />
          25 ευρώ, Λευκαδίτης Παναγιώτης στη μνήμη της μητέρας του Μερακλήνας.
          <br />
          50 ευρώ, Τσερδάνη Χρύσα. <br />
          40 ευρώ, Χιώτης Βασίλης <br />
          30 ευρώ, Peters man - Gerharis Ruth
          <br />
          20 ευρώ, Θεοδώρου Μαρία, Χαλάνδρι.
          <br />
          20 ευρώ, Castelli Frederico
          <br />
          10 ευρώ, Ανώνυμος
          <br />
          05 ευρώ, Ανώνυμος <br />
          <br />
          Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του
          να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός για την
          συντήρηση του κτιρίου του «Μουσείου Προσφυγικής Μνήμης 1922», του
          κτιρίου της Βιβλιοθήκης, του οικίσκου του Ιατρείου της Σκάλας Λουτρών
          του Θερινού αμφιθεάτρου, του γηπέδου μπάσκετ και του χώρου αθλοπαιδιών
          του Συλλόγου.
          <br />
          <br />
          Ευχαριστούμε επίσης τον κ. Εμ. Κουτουλάκη, Γ.Γ. Αιγαίου και Νησιωτικής
          Πολιτικής για την έγκριση αγοράς βιβλίων εκδόσεων του Συλλόγου, καθώς
          και για την επιχορήγηση της δαπάνης εκτύπωσης του 30ου τεύχους του
          περιοδικού «Τα Νέα μας». <br />
          <br />
          Για καταθέσεις συνδρομών και ενισχύσεων στο Σύλλογο <br />
          Τράπεζα Πειραιώς Αριθμός Λογαριασμού: <b>6355-010482-750</b> <br />
          <br />
          <b>IBAN GR94 0171 3550 0063 5501 0482750 </b>
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default D20_drastiriotites_2023;
