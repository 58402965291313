import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g11/fokaia_01.jpg";
import Image02 from "../assets/images/mikrasiatika_g11/fokaia_02.jpg";

function G11_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Η εκδίωξη των Ελλήνων από τη Φώκαια της Μικράς Ασίας" />
      </Box>      
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ένα φωτογραφικό τεκμήριο της ιστορίας</Typography>
	
    <img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<Typography variant="h3" sx={theme.typography.typography2}>Η Φωτογραφική Εταιρεία Μυτιλήνης σε συνεργασία με τον Σύλλογο Μικρασιατών Σκάλας Λουτρών το «Δελφίνι», του οποίου 
	το 70% αποτελείται από Μικρασιάτες της Φώκαιας, πραγματοποίησαν εκδήλωση στο «Μουσείο Προσφυγικής Μνήμης 1922» στη Σκάλα Λουτρών, κατά τη διάρκεια της οποίας μίλησε
	ο κ. Χάρης Γιακουμής, με θέμα «η εκδίωξη των Ελλήνων από τη Φώκαια της Μικράς Ασίας». <br/>	
	Ο κ.Χάρης Γιακουμής είναι ερευνητής ιστορικών φωτογραφιών και συγγραφέας πολλών βιβλίων με αντίστοιχα θέματα. Με βάση το πρωτότυπο φωτογραφικό αρχείο του Γάλλου 
	αρχαιολόγου Φελίξ Σαρτιό, που ο κ. Χάρης Γιακουμής αγόρασε σε δημοπρασία στο Λονδίνο και το οποίο αναφέρεται στο διωγμό των Ελλήνων από τις Φώκαιες, ερευνά ακόμη 
	και σήμερα στοιχεία που αναφέρονται σ αυτό το τραγικό ιστορικό γεγονός. <br/>	
	Ο κ.Χάρης Γιακουμής ήρθε ειδικά για αυτή την εκδήλωση στη Μυτιλήνη, αλλά και για να συνεχίσει την έρευνά του στις Φώκαιας της Μ. Ασίας πάνω στο θέμα.
	</Typography>
    <br/><br/><br/>



	<Typography variant="h1" sx={theme.typography.typography1}>ΟΙ ΦΩΤΟΓΡΑΦΙΚΕΣ ΜΑΡΤΥΡΙΕΣ ΤΟΥ FELIX SARTIAUX </Typography>
	<img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
    <Typography variant="h3" sx={theme.typography.typography2}>Ο Γάλλος αρχαιολόγος Felix Sartiaux βρίσκεται για ανασκαφές το 1914 στην Παλαιά Φώκαια. Σ' αυτή την πόλη της Μικράς Ασίας αυτή τη περίοδο σε σύνολο 12.000 
	κατοίκων οι 9.000 ήτανε Έλληνες και οι 3.000 Τούρκοι. Η συνύπαρξη του ελληνικού και τουρκικού πληθυσμού ήτανε αρκετά ειρηνική, έως ότου παρθεί η ιδέα από το 
	τουρκικό κράτος να εκδιώξει το ελληνικό στοιχείο από τα παράλια της Μικράς Ασίας. Η μέθοδος που χρησιμοποιήθηκε ήτανε, αρπαγή όλων των υπαρχόντων των πλούσιων 
	σχετικά Ελλήνων, πυρπόληση και λεηλασία των σπιτιών τους και δολοφονία αυτών που θα αντισταθούν. Μένοντας χωρίς τίποτα οι Έλληνες αναγκάστηκαν να φύγουν σαν 
	πρόσφυγες στην κοντινή Μυτιλήνη καταρχήν και μετά σε όλη την Ελλάδα και ιδιαίτερα στην παραλιακή περιοχή πριν το Σούνιο για να ιδρύσουν αργότερα την Παλαιά Φώκαια. 
	Η επιχείρηση λοιπόν του εκδιωγμού και η καταστροφή της Παλαιάς Φώκαιας διήρκεσε μόνο ένα εικοσιτετράωρο. Αυτή η άγνωστη (και αυτό σίγουρα λόγω έλλειψης φωτογραφικών 
	μαρτυριών) σελίδα της ιστορίας του ελληνικού έθνους αποτελεί 	την αρχή για το μεγάλο διωγμό που θα κορυφωθεί το 1922 με την καταστροφή της Σμύρνης.<br/>	
	Ο Felix Sartiaux έχοντας μαζί του τον Ιούνιο του 1914 μία φωτογραφική μηχανή για τις ανάγκες των ανασκαφών του, μόλις αρχίζουν τα γεγονότα εκδιωγμού, σταματά 
	κάθε δραστηριότητα και αφιερώνεται αποκλειστικά μαζί με τους Γάλλους συνεργάτες του, στη διάσωση των προσφύγων αλλά και τη φωτογράφηση αυτών των γεγονότων. 
	Επιστρέφοντας λίγο μετά στη Γαλλία δημοσιεύει τις γραπτές του μαρτυρίες, αλλά καμία από τις φωτογραφίες. Οι στερεοσκοπικές πλάκες του Felix Sartiaux με τα 
	γεγονότα αυτά, αλλά και πολλές άλλες από τις φωτογραφίσεις του σε όλη την Ελλάδα και τη Μικρά Ασία έρχονται στο φως μόλις το Μάιο του 2006 μετά από πώλησή τους 
	σε πλειστηριασμό στο Παρίσι, αλλά με καμία ένδειξη του ονόματός του.<br/>	
	Η αγορά τους από Γάλλο και μετά από Τούρκο έμπορα και ακολούθως από τον κ. Χ. Γιακουμή, ο οποίος ανακαλύπτει μετά από έρευνες την ταυτότητα του φωτογράφου και 
	έτσι όλη τη σημασία τους, έρχονται να ξαναζωντανέψουν τη μνήμη ενός λαού, διδάσκοντάς μας έτσι ότι η κάθε φωτογραφική λήψη είναι πάνω απ' όλα και ένα φωτογραφικό 
	τεκμήριο της ιστορίας.
    </Typography>
	</Box>
	
    </>
  );
}

export default G11_mikrasiatika;
