import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mouseio/mouseio1.jpg";
import Image02 from "../assets/images/mouseio/mouseio.jpg";
import Image03 from "../assets/images/mouseio/mouseio3.jpg";
import Image04 from "../assets/images/mouseio/egkainia_pap.jpg";
import Image05 from "../assets/images/mouseio/episkepsi_nomarxi.jpg";
import Image06 from "../assets/images/mouseio/mouseio14.jpg";
import Image07 from "../assets/images/mouseio/mouseio15.jpg";
import Image08 from "../assets/images/mouseio/mouseio16.jpg";
import Image09 from "../assets/images/mouseio/mouseio17.jpg";
import Image10 from "../assets/images/mouseio/mouseio18.jpg";
import Image11 from "../assets/images/mouseio/mouseio19.jpg";
import Image12 from "../assets/images/mouseio/mouseio20.jpg";
import Image13 from "../assets/images/mouseio/mouseio21.jpg";
import Image14 from "../assets/images/mouseio/mouseio22.jpg";
import Image15 from "../assets/images/mouseio/mouseio23.jpg";
import Image16 from "../assets/images/mouseio/mouseio24.jpg";
import Image17 from "../assets/images/mouseio/mouseio25.jpg";
import Image18 from "../assets/images/mouseio/mouseio26.jpg";
import Image19 from "../assets/images/mouseio/mouseio27.jpg";
import Image20 from "../assets/images/mouseio/mouseio28.jpg";
import Image21 from "../assets/images/mouseio/mouseio29.jpg";
import Image22 from "../assets/images/mouseio/mouseio30.jpg";
import Image23 from "../assets/images/mouseio/mouseio31.jpg";
import Image24 from "../assets/images/mouseio/mouseio32.jpg";
import Image25 from "../assets/images/mouseio/mouseio33.jpg";
import Image26 from "../assets/images/mouseio/mouseio34.jpg";
import Image27 from "../assets/images/mouseio/mouseio35.jpg";
import Image28 from "../assets/images/mouseio/mouseio36.jpg";
import Image29 from "../assets/images/mouseio/mouseio37.jpg";
import Image30 from "../assets/images/mouseio/mouseio38.jpg";
import Image31 from "../assets/images/mouseio/mouseio31.jpg";


function D102_ToMouseioMas() {

    const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Μουσείο Προσφυγικής Μνήμης" />
      </Box>



      
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Δε ζει χωρίς πατρίδες η ανθρώπινη ψυχή</Typography>
	<img src={Image01}    style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image02}    style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο παλαμικός τούτος στίχος, ασίγαστος συνοδός της ατελεύτητης όδεψης κάθε ανήσυχης ψυχής. Βασανιστικός στο άκουσμα του για όσους χάνουν τον πολυτιμότερό
	τους προσδιορισμό, την Πατρίδα τους και εντέλει την ουσία της ταυτότητάς τους. "Χαρά και μύρο της ζωής τους" σε όσους  έκλεισαν τα όσια και ιερά των προγόνων
	τους στην ψυχή τους και από ΄΄χαμένα΄΄ τα μετέβαλαν σε ΄΄αλησμόνητα΄΄. Γιατί πραγματικά κάτι χάνεται μόνο αν  λησμονηθεί. Και οι δικές μας Πατρίδες στα σεπτά
	χώματα της Μικρασίας, αλησμόνητες μείναν μέσα μας.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Σε τούτο το μικρό προσφυγικό χωριό, τη Σκάλα Λουτρών, με τον πιο βαθύ σεβασμό σκύψαμε πάνω στις τραγικές θύμησες των προσφύγων της πρώτης γενιάς και ό,τι
	είχαν μπορέσει να φέρουν απ’ τα καρσινά παράλια μέσα απ’ την αντάρα της αποτρόπαιης βίας, το συγκεντρώσαμε σε ένα καλαίσθητο χώρο για να θυμόμαστε και να μη
	ξεχάσουμε ποτέ. Μια ιδέα ενός μικρού ιστορικού, λαογραφικού μουσείου, σεμνή υπογραφή των προσφύγων και των απογόνων τους, σε έναν κόσμο που οι καινούριες θεωρίες
	του "συνωστίζονται" στις προκυμαίες της λήθης. Μα ταυτόχρονα ο παντοτινός προσδιορισμός της μικρασιατικής ταυτότητας του οικισμού μας, οι παλιοί περήφανα για
	να θυμούνται, οι τωρινοί να κατανοούν και οι καινούριοι να ρωτάνε και να μαθαίνουν.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}><b>"Μουσείο Προσφυγικής Μνήμης 1922",</b> το ονομάσαμε. 12 Αυγούστου 2006 το εγκαινιάσαμε. Η μεγαλύτερη τιμή που  έπρεπε, μας αποδόθηκε στα εγκαίνια του από
	την παρουσία της Α.Π.Θ. Οικουμενικού Πατριάρχη Κωνσταντινουπόλεως κ.κ. Βαρθολομαίο. Μέσα στην καταλαμπή της μέρας εκείνης, ξεχωριστή θέση τα δάκρυα των ελάχιστων 
	πλέον προσφύγων πρώτης γενιάς, που στην τελευταία περίοδο της ζωής τους ένιωσαν τη  δικαίωση τούτη. Να τους ξαναδωθεί δηλαδή ένα μέρος της χαμένης  ταυτότητά τους.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Ένα μεγάλο ευχαριστώ σε όσους προσέφεραν και προσφέρουν τα ανεκτίμητα κειμήλιά τους, τους τελευταίους "θησαυρούς" της Μικράς Ασίας, προορισμένους πλέον να
	θυμίζουν στους παλιότερους και να διδάσκουν τους νεότερους. Ένα επιπλέον ευχαριστώ σε όσους προσφέρουν βιβλία σχετικά με τη Μικρά Ασία στην εξειδικευμένη
	βιβλιοθήκη του μουσείου και ιδιαίτερα στο Κέντρο Μικρασιατικών Σπουδών για τις πολυτιμότατες προσφορές του. Τους υποσχόμαστε πως όλα αυτά θα τα διαφυλάξουμε 
	και θα τα συντηρήσουμε με τον καλύτερο τρόπο.</Typography>
	</Box>
	
	
	<ScrollToTopButton />

	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Εγκαίνια Μουσείου Προσφυγικής Μνήμης 1922, υπό της Α.Θ.Π. του Οικουμενικού Πατριάρχη κ.κ. Βαρθολομαίου.</Typography>
	<img src={Image03} alt="Μουσείο Προσφυγικής Μνήμης"/>
	<Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκαν με μεγάλη επισημότητα από την Α.Θ.Π. ΤΟΝ Οικουμενικό Πατριάρχη Κωνσταντινουπόλεως τον κ. Βαρθολομαίο τα εγκαίνια του Μουσείου Προσφυγικής
	Μνήμης 1922 στις 12 Αυγούστου 2006 στη Σκάλα Λουτρών.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Το αεροσκάφος που έφερε τον Παναγιότατο από την Κωνσταντινούπολη με την συνοδεία του, προσγειώθηκε στο αεροδρόμιο "Οδυσσέας Ελύτης" στις 11 π.μ. του Σαββάτου
	12 Αυγούστου 2006. η υποδοχή υπήρξε λαμπρή. Άγημα του στρατού παρουσίασε όπλα και ο Παναγιότατος επιθεώρησε αυτό και ευλόγησε. Επίσημοι, κλήρος και λαός είχαν
	παραταχθεί και υποδέχτηκαν τον Πατριάρχη. Στην αίθουσα των επιβατών εξωτερικού έγινε η πρώτη υποδοχή. Πρώτος προσφώνησε τον Παναγιότατο ο Δήμαρχος Μυτιλήνης
	κ. Άρης Χατζηκομνηνός και μετά ο Νομάρχης Λέσβου κ. Παύλος Βογιατζής. Στην αντιφώνησή του ο Πατριάρχης αναφέρθηκε στον πόλεμο του Λιβάνου και ευχήθηκε ειρήνευση
	και καταλαγή, ενώ για το νησί μας ευχήθηκε ειρήνη, προκοπή και ευλογία Θεού. Μετά το αεροδρόμιο ο Πατριάρχης, επισκέφθηκε το Δημαρχείο και τη Νομαρχία από όπου
	μετά τις προσφωνήσεις που του έγιναν αναχώρησε για την Σκάλα Λουτρών στο έτοιμο για να εγκαινιασθεί "Μουσείο Προσφυγικής Μνήμης 1922".</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Οι κοπέλες του Συλλόγου μας με μικρασιατικές στολές και με μικρασιατικά παραδοσιακά τραγούδια υποδέχθηκαν με το "καλώς ανταμωθήκαμε" τον Πατριάρχη 
	ο οποίος αφού χαιρέτησε έναν προς έναν του; Πρόσφυγες πρώτης γενιάς που τον περίμεναν, τέλεσε στην συνέχεια τον Αγιασμό των εγκαινίων και μετά το τέλος του Αγιασμού 
	επαίνεσε τις κοπέλες και ζήτησε να πουν άλλο ένα παραδοσιακό μικρασιάτικο τραγούδι. Στην συνέχεια ο πρόεδρος του Συλλόγου μας κ. Δημήτρης Παπαχρυσός εκφώνησε 
	τον παρακάτω λόγο.</Typography>
	</Box>
	
	
	<ScrollToTopButton />	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Ομιλία προέδρου Συλλόγου Μικρασιατών Σκάλας Λουτρών</Typography>
	<img src={Image04} alt="Μουσείο Προσφυγικής Μνήμης"/>
	<Typography variant="h3" sx={theme.typography.typography2}>Παναγιότατε, αισθάνομαι πολύ υπερήφανος που ως πρόεδρος του  Δ.Σ. του Συλλόγου Μικρασιατών της Σκάλας Λουτρών Λέσβου, βρίσκομαι στην ευχάριστη θέση να σας
	καλωσορίσω στον οικισμό μας και να σας ευχαριστήσω για την ιδιαίτερη τιμή που μας  κάνατε να παρευρεθείτε  εδώ για να εγκαινιάσετε το <b>Μουσείο Προσφυγικής Μνήμης
	1922</b>. Είναι γνωστό πως μετά τα γεγονότα της μικρασιατικής καταστροφής, η Λέσβος δέχτηκε ένα μεγάλο αριθμό προσφύγων που δημιούργησαν διάφορους οικισμούς και χωριά.
	Ένα από τα χωριά αυτά είναι και το δικό μας, η <b>Σκάλα Λουτρών</b>, που ανήκει στο δήμο Μυτιλήνης, δημιουργήθηκε το 1930 από 25 προσφυγικές οικογένειες και απαριθμεί
	σήμερα 300 περίπου μόνιμους κατοίκους. Σήμερα στη Λέσβο, ελάχιστα πράγματα θυμίζουν πως  το νησί ήταν κάποτε τόπος υποδοχής ενός τόσο μεγάλου αριθμού προσφύγων.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Εκτός από ένα άγαλμα <b>"Μικρασιάτισας μάνας"</b> στην Επάνω Σκάλα και την επωνυμία ενός χωριού τις <b>"Νέες Κυδωνίες"</b>, τίποτ’ άλλο δε θυμίζει τα γεγονότα της 
	Μικρασιατικής Καταστροφής. Μέσω του Συλλόγου που έχουμε δημιουργήσει εδώ και 16 χρόνια με πολυσχιδή δράση, προσπαθούμε να  διασώσουμε ό,τι έχει απομείνει απ’ αυτές 
	τις μνήμες. Έτσι έχουμε αποδυθεί σε μια προσπάθεια προβολής του ελληνικού πολιτισμού της Μικράς Ασίας, πραγματοποιώντας διάφορες εκδηλώσεις και εκδίδοντας μέσω της
	Νομαρχιακής Αυτοδιοίκησης Λέσβου ημερολόγια-λευκώματα για τις αλησμόνητες Πατρίδες μας, <b>ξεκινώντας το 2004 με τη Σμύρνη, συνεχίζοντας το 2005 με το Αϊβαλί, το 2006 
	με την Πέργαμο και το 2007 με την Ίμβρο και Τένεδο.</b></Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Τα ημερολόγια αυτά  αποτελούν και μια από τις κύριες πηγές εσόδων μας, γνωρίζοντας πως οι κρατικές επιχορηγήσεις είναι ασήμαντες και τυπικές πολλές φορές. 
	Τα τελευταία τρία  χρόνια  διαμορφώνοντας  κατάλληλα το κτήριο του παροπλισμένου Δημοτικού Σχολείου του χωριού μας, δημιουργήσαμε τις προϋποθέσεις για τη στέγαση 
	της συλλογής των μικρασιατικών κειμηλίων μας και βρισκόμαστε σήμερα στην ευχάριστη θέση να το εγκαινιάσουμε, δημιουργώντας την απαρχή ενός ευρύτερου μουσείου 
	προσφυγικής μνήμης στο νησί μας. </Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Οφείλω να σας πω, πως χωρίς να έχει διαδοθεί ευρέως η ιδέα ακόμα, η ανταπόκριση του κόσμου είναι απρόσμενη θα λέγαμε, αφού προσέφερε πολλά κειμήλια και έγγραφα 
	ιστορικής σημασίας. Ευελπιστούμε σε σύντομο χρονικό διάστημα η συλλογή μας να αυξηθεί τόσο ώστε να επιδιώξουμε την ανέγερση σε παρακείμενο οικόπεδο ενός πολύ 
	μεγαλύτερου κτηρίου. Θεωρούμε πως οι τελευταίοι ΄΄θησαυροί΄΄ του μικρασιατικού πολιτισμού, τουλάχιστον στο νησί μας, δεν πρέπει να χαθούν  και γι’ αυτό δημιουργήσαμε 
	αυτή τη στέγη, ως βασική προϋπόθεση που μέχρι στιγμής δεν υπήρχε. Τελειώνοντας θέλω να ευχαριστήσω όλους όσοι συνέβαλαν στην υλοποίηση αυτής της προσπάθειας 
	και είναι πάρα πολλοί για να αναφερθώ στον καθένα προσωπικά  όμως θέλω να ξεχωρίσω δύο ανθρώπους και να τους ευχαριστήσω ιδιαιτέρως, τον Νομάρχη Λέσβου κ. 
	Παύλο Βογιατζή για την αμέριστη ηθική και υλική υποστήριξη που είχαμε όλο αυτό το διάστημα, μάλιστα σ αυτόν οφείλουμε και την πρόταση να εγκαινιασθεί αυτό το 
	Μουσείο υπό της Παναγιότητάς σας, καθώς και το μέλος του Συλλόγου μας τον  ακούραστο δάσκαλο τον κ. Σταύρο Βαλαχή χωρίς την βοήθεια του οποίου δεν θα είχε 
	επιτευχθεί αυτό το εγχείρημα. Πάνω απ όλους όμως θέλω να ευχαριστήσω τον Θεό που μας αξίωσε και τα έφερε όλα βολικά ώστε σήμερα να βρισκόμαστε σ αυτή την 
	ευχάριστη θέση,  «ουκ εξ ημών λοιπόν Θεού το δώρον  ίνα μην καυχηθεί τις…» Ακόμη θέλω να ευχαριστήσω και όλους εσάς που παρευρίσκεστε εδώ και μας τιμάτε 
	ιδιαιτέρως με την παρουσία σας όπως τον έξαρχο του Παναγίου τάφου στην Αθήνα και συγχωριανό μας τον Σεβασβιότατο κ Θεοφάνη τους λοιπούς Σεβασμιοτάτους 
	τον κ. Υπουργό, τον κ. Περιφερειάρχη , τους  Βουλευτές του νομού μας, τον  Νομάρχη Λέσβου , τον  Δήμαρχο Μυτιλήνης και τους λοιπούς εκπροσώπους της 
	τοπικής αυτοδιοίκησης ,τους εκπροσώπους των πολιτικών και στρατιωτικών αρχών του τόπου μας καθώς και τους εκπροσώπους των αδελφών Μικρασιατικών Σωματείων. 
	Παρακαλώ όλους σας μετά τα εγκαίνια και αφού περιηγηθείτε στον εσωτερικό χώρο του Μουσείου να γράψετε τις εντυπώσεις σας πριν φύγετε στο βιβλίο επισκεπτών. 
	Επίσης σας ενημερώνουμε πως το Μουσείο αμέσως μετά τα εγκαίνια θα παραμείνει ανοικτό μέχρι αργά το βράδυ έτσι ώστε να μπορέσετε με άνεση χρόνου να το επισκεφθείτε.
	Παναγιότατε και λοιποί προσκεκλημένοι καλώς μας ήρθατε.</Typography><br/>
	<Typography variant="h3" sx={theme.typography.typography2}>Ο Oικουμενικός Πατριάρχης μίλησε και συνεχάρη τους εμπνευστές και ιδρυτές αυτού του Μουσείου και είπε ότι είναι τιμή για όλους εκείνους τους πρόσφυγες που 
	ήρθαν τότε στην Ελλάδα και έφεραν μαζί τους ένα λαμπρό πολιτισμό και Ελληνικά ήθη. Ο Οικουμενικός Πατριάρχης έφερε μαζί του και μας δώρισε φωτοτυπία ενός 
	επίσημου εγγράφου από την Μ Ασία των αρχών του αιώνα και χάρισε στη συνέχεια σε όλα τα παιδιά από ένα σταυρουδάκι. Από την πλευρά μας ο Σύλλογός μας 
	δώρισε στον Πατριάρχη ένα κεντημένο στο χέρι ριχτάρι σαν αναμνηστικό από την επίσκεψή του αυτή στον οικισμό μας. Αμέσως μετά κόπηκε η καθιερωμένη κορδέλα τ
	ης εισόδου από τον Πατριάρχη και τόσο ο ίδιος όσο και όλοι οι παριστάμενοι  περιηγήθηκαν το Μουσείο και θαύμασαν τα εκτεθειμένα είδη, [εικόνες, κεντήματα, 
	σερβίτσια, φωτογραφίες, έγγραφα, ρούχα κ.τ.λ.]. Βγαίνοντας από το Μουσείο ο Οικουμενικός Πατριάρχης και αφού είχε γράψει τις εντυπώσεις του στο βιβλίο 
	επισκεπτών έκανε τα αποκαλυπτήρια στην μαρμάρινη πλάκα [με την επιγραφή των εγκαινίων] και στην συνέχεια φύτεψε στην αυλή του Μουσείου μια ελιά σαν 
	αναμνηστικό του περάσματός του από τον τόπο μας.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Την συνοδεία του Οικουμενικού Πατριάρχη αποτελούσαν ιεράρχες του θρόνου, όπως ο αρχιεπίσκοπος Αμερικής κ. Δημήτριος, αλλά και μητροπολίτες της 
	Ελλαδικής Εκκλησίας μεταξύ των οποίων: ο Μυτιλήνης Ιάκωβος, ο Θηβών Ιερώνυμος, ο Ιωαννίνων Θεόκλητος, ο Ζακύνθου Χρυσόστομος και άλλοι. Παρόντες 
	επίσης ήταν Μητροπολίτες εκπρόσωποι των Πατριαρχείων Αλεξανδρείας και Ιεροσολύμων, καθώς και ο έξαρχος του Παναγίου Τάφου στην Αθήνα ο χωριανός μας 
	Σεβασμιότατος κ. Θεοφάνης  ο οποίος μας χάρισε μιαν εξαίρετη εικόνα του Αγίου Νικολάου. Ανάμεσα στους επισήμους ο Υπουργός Αιγαίου και Νησιωτικής 
	πολιτικής κ. Αριστ. Παυλίδης, ο νομάρχης Λέσβου κ. Παύλος Βογιατζής, ο δήμαρχος Μυτιλήνης κ. Άρης Χατζηκομνηνός, οι βουλευτές του Νομού μας κ. Ιωάννης 
	Γιαννέλης και κ. Χριστιάνα Καλογήρου, ο ευρωβουλευτής κ. Νίκος Σηφουνάκης, εκπρόσωποι των πολιτικών και στρατιωτικών αρχών του τόπου μας καθώς και 
	εκπρόσωποι μικρασιατικών συλλόγων. Εκτός από τα μέλη του Συλλόγου μας τα εγκαίνια παρακολούθησαν οι κάτοικοι του οικισμού μας και πλήθος κόσμου</Typography>
	</Box>
	
	
	<ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Εντυπώσεις του Πατριάρχη στο βιβλίο επισκεπτών του Μουσείου</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Εν βαθυτάτη συγκινήσει εγκαινιάζομεν το Μουσείον τούτο της Μικρασιατικής Μνήμης. Ευχόμεθα υπέρ αναπαύσεως των ψυχών των εις την απέναντι όχθην 
	αναπαυομένων πατέρων και αδελφών ημών, των τε ειρηνικώς κοιμηθέντων και των μαρτυρικώς τελειωθέντων, και δεόμεθα του Άρχοντος της Ειρήνης όπως 
	χαρίζηται εις τους δύο λαούς, μέλλον ειρηνικόν, δημιουργικόν και ελπιδοφόρον. Διάπυρος προς Θεόν ευχέτης.<br/><br/>
	Ο Κωνσταντινουπόλεως Βαρθολομαίος.<br/>
	12 Αυγούστου 2006 Μυτιλήνη.</Typography>
	</Box>
	
	<ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Το μουσείο προσφυγικής μνήμης Σκάλας Λουτρών Λέσβου</Typography>
	
	<img src={Image05} alt="Μουσείο Προσφυγικής Μνήμης"/>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Σας παραθέτουμε το 2ο κεφάλαιο από την <b>μεταπτυχιακή εργασία</b> της φοιτήτριας στο πανεπιστήμιο Αιγαίου κ. Δεληγιάννη Ειρήνη Χρυσοβαλάντη, με θέμα το
	   «Μουσείο Προσφυγικής Μνήμης 1922» στη Σκάλα Λουτρών.</Typography>
	   
    <Typography variant="h1" sx={theme.typography.typography1}>Ιστορία του μουσείου</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Είδα στο λιμάνι ένα πλοίο με πρόσφυγες που αγκυροβολούσε. Δεν μπορούσα ποτέ να φανταστώ τέτοιο τραγικό θέαμα. Εφτά χιλιάδες άνθρωποι ήταν στοιβαγμένοι σε ένα 
		πλοίο, που κανονικά είχε χωρητικότητα δύο χιλιάδων. Ήταν στη θάλασσα τέσσερις ημέρες. Δεν υπήρχε χώρος να ξαπλώσουν, δεν είχαν τρόφιμα. Δεν υπήρχαν τουαλέτες. 
		Επί τέσσερις μέρες και νύχτες στέκονταν στο κατάστρωμα, κάτω από τη φθινοπωρινή βροχή, μέσα στο κρύο της νύχτας και κάτω από τον ήλιο του μεσημεριού. Έφτασαν στο 
		λιμάνι κουρελιασμένοι, πεινασμένοι, άρρωστοι, ψειριασμένοι αποπνέοντας ανθρώπινη μιζέρια και απελπισία…[….]...Οι ξυπόλητοι πρόσφυγες φόρεσαν παπούτσια από λάστιχα 
		αυτοκινήτων και ντύθηκαν με σάκους από αλεύρι. Κονσερβοκούτια χρησίμευαν για κατσαρόλες. Γυναίκες που είχαν γνωρίσει την άνετη ζωή φύλαγαν ουρά επί ώρες για ένα 
		κομμάτι ψωμί. Άνθρωποι άλλοτε ευκατάστατοι γεύονταν την πικρή δημοκρατία της δυστυχίας.<br/>
		<b>Henry Morgenthau, Πρώτος πρόεδρος της Επιτροπής Αποκαταστάσεως Προσφύγων</b></Typography>


        <Typography variant="h3" sx={theme.typography.typography2}>Το παραπάνω απόσπασμα από το ημερολόγιο του Μουσείου της Σκάλας Λουτρών καταδεικνύει το πρόβλημα της προσφυγιάς και τα σκληρά βιώματα των κατατρεγμένων αυτών ανθρώπων. 
	Για τον αριθμό των προσφύγων που κατέφτασαν στον Ελλαδικό χώρο δεν υπάρχουν ακριβή στοιχεία εκτός από εκείνα της απογραφής του 1928. Η Λέσβος έτυχε ένας από τους τόπους 
	με τη μεγαλύτερη υποδοχή προσφύγων. Η εγκατάσταση αυτή μάλιστα, προσέγγισε το 45% του συνολικού πληθυσμού. Μια μικρή ομάδα από αυτούς, εγκαταστάθηκε στην περιοχή των 
	Λουτρών. Η εγκατάσταση αυτή έγινε μέσα στο χωριό Λουτρά, αλλά και σε παλιές εγκαταλειμμένες εμπορικές αποθήκες και αγροικίες (ντάμια) στη Σκάλα Λουτρών, μια ακατοίκητη 
	περιοχή με υποτυπώδες λιμάνι και δύο ελαιοτριβεία. Η υφιστάμενη εγκατάσταση διατηρήθηκε έτσι μέχρι το 1930. Στο διάστημα αυτό, οικοδομήθηκαν από το κράτος σε μια ανοιχτή
	έκταση με την επωνυμία ΄΄Βατσίνα΄΄, 25 σπίτια των δύο δωματίων και παραχωρήθηκαν σε 25 μικρασιατικές οικογένειες το 1931. Με τον τρόπο αυτό στην περιοχή δημιουργείται ο 
	οικισμός της Σκάλας Λουτρών, ένας καθαρά προσφυγικός οικισμός που στο λόγο των κατοίκων του φέρει ακόμα την ονομασία ΄΄ο συνοικισμός΄΄</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Συνυπολογίζοντας και τα άτομα που δεν είχαν μικρασιατική καταγωγή μαζί με τα γεννημένα παιδιά δεύτερης γενιάς , το 1931 ο πρώτος οικισμός της Σκάλας Λουτρών θα πρέπει 
	να κατοικήθηκε από 160 περίπου κατοίκους. Σήμερα απαριθμεί γύρω στους 300 μόνιμους, διαθέτει κοινό τοπικό συμβούλιο με το χωριό Λουτρά και ανήκει διοικητικά στο Δήμο 
	Μυτιλήνης. Το 80% του σημερινού πληθυσμού της Σκάλας είναι μικρασιατικής καταγωγής και από το 2006 έχει έρθει στη ζωή πλέον η πέμπτη γενιά προσφύγων. Το 1993 λόγω του 
	ότι η πρώτη γενιά είχε "φύγει" και είχε αρχίσει να εξασθενεί η συνείδηση των νεότερων, έλαβαν χώρα μια σειρά εκδηλώσεων, όπως μουσικοχορευτικές και λαογραφικές 
	παραστάσεις, καθώς και θεατρικοποιημένα δρώμενα με σκοπό η υπόθεση της καταγωγής να ενσωματωθεί στη σκέψη όλων και οι παλιοί να ξαναμιλήσουν για τις χαμένες πατρίδες 
	(5-6 άτομα ζουν ακόμη από τους πρώτους πρόσφυγες). Ο απώτερος στόχος ήταν η αναζήτηση ριζών και η ενίσχυση της μικρασιάτικης μνήμης και συνείδησης. Δημιουργήθηκε έτσι 
	σιγά σιγά, η επιθυμία να συγκεντρωθούν τα αντικείμενα των Μικρασιατών και να βρεθεί ο κατάλληλος χώρος. Συγκυρία ήταν το κλείσιμο του σχολείου.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το <b>Μουσείο Προσφυγικής Μνήμης 1922</b> εδρεύει στη Σκάλα Λουτρών και βρίσκεται στη θέση του παλιού Δημοτικού  Σχολείου του χωριού. Αμέσως μετά το κλείσιμο του 
	σχολείου λόγω έλλειψης μαθητών, το κτίριο το οποίο βρισκόταν σε κακή κατάσταση παραδόθηκε από τη Νομαρχία στο Σύλλογο Μικρασιατών της Σκάλας Λουτρών "το Δελφίνι" και 
	ο οποίος αποφάσισε να το μετασκευάσει  σε μουσείο. Με χρηματοδότηση του συλλόγου, μια μικρή ομάδα από τη Σκάλα επί τρία χρόνια με προσωπική εργασία προχώρησε στην 
	ανάπλαση του χώρου 130 τ.μ και τη μετατροπή του σε αίθουσα για στέγαση της πρώτης συλλογής προσφυγικών κειμηλίων (κειμήλια που είχαν φέρει οι πρόσφυγες από τη Μικρά 
	Ασία). Η Νομαρχία Λέσβου συνέβαλε στη χρηματοδότηση της διαμόρφωσης του αύλιου χώρου. Τα εγκαίνια πραγματοποιήθηκαν στις 12 Αυγούστου 2006 από την Α.Θ.Π. Πατριάρχη 
	Κωνσταντινουπόλεως κ.κ. Βαρθολομαίο. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Λόγω του ιδιαίτερου ιστορικού και λαογραφικού χαρακτήρα του Μουσείου, το ενδιαφέρον των επισκεπτών είναι μεγάλο και οι εκπαιδευτικές επισκέψεις αρκετές. Ο επισκέπτης
	και
	ιδιαίτερα ο μαθητής έχει τη δυνατότητα να ενημερωθεί για τη Μικρά Ασία από τον πρώτο ελληνικό αποικισμό και την εξέλιξη των περιοχών όπου εγκαταστάθηκαν Έλληνες μέχρι 
	και το 1922,  καθώς και για την εγκατάσταση των προσφύγων στην Ελλάδα. Ακόμη, μέσα από τα αντικείμενα μπορεί κανείς να γνωρίσει μικρές προσωπικές ιστορίες και να 
	διακρίνει την τέχνη και τις συνήθειες των Ελλήνων της Μικράς Ασίας. Επίσης, λειτουργεί εξειδικευμένη βιβλιοθήκη που συνεχώς εμπλουτίζεται με καινούρια βιβλία είτε από 
	δωρεές είτε από αγορές του Συλλόγου. Το <b>Μουσείο Προσφυγικής Μνήμης 1922</b> αποτελεί μια μικρή προσφορά των κατοίκων της Σκάλας Λουτρών στην προσπάθεια διατήρησης της 
	ιστορικής μνήμης και διάσωσης των τελευταίων ΄΄θησαυρών της Μ. Ασίας΄΄ πάνω στη Λέσβο.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η συλλογή του μουσείου, η οποία διαρκώς μεγαλώνει από προσφορές, περιλαμβάνει 500 περίπου εκθέματα διαφόρων κατηγοριών. Τα περισσότερα αντικείμενα είναι δωρισμένα, 
	ενώ κάποια βρίσκονται με μόνιμο ή προσωρινό δανεισμό. Σε κάθε αντικείμενο μνημονεύεται ο τόπος προέλευσης, το όνομα του κατόχου πρόσφυγα και το όνομα του δωρητή ή 
	δανειστή.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το αντικείμενο ακόμη και αν δωρίστηκε δεν παύει να παραμένει στην κατοχή του δωρητή ως προσωπικό κειμήλιο. Υπάρχει μελλοντικός στόχος για μετεγκατάσταση του μουσείου 
	μέσω χρηματοδότησης του 4ου Κοινοτικού Πλαισίου, έτσι ώστε να αποκτήσει και οπτικοακουστικά μέσα. Υφίσταται 
	προοπτική να αναδομηθεί και να αναπαλαιωθεί με σκοπό να ενσωματωθεί με το Μουσείο το μόνο προσφυγικό σπίτι που διασώζεται και το οποίο έχει χαρακτηριστεί ως διατηρητέο 
	από το Υπουργείο Πολιτισμού, μια προσφυγική κατοικία από το 1930, (για να γίνει το ιστορικό αρχείο του χωριού). Γίνονται προσπάθειες για απαλλοτρίωση ώστε η ανωτέρω 
	κατοικία να περιέλθει στην κυριότητα του μουσείου.</Typography>
	</Box>
	
	
	<ScrollToTopButton />
		
	
	<Box sx={{ ...commonBoxStyles }}>
    <Typography variant="h1" sx={theme.typography.typography1}>Φωτογραφικό Υλικό</Typography>
				
		<img src={Image06}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		
		<img src={Image07}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image08}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
				

		<img src={Image09}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

		<img src={Image10}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image11}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image12}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image13}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image14}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image15}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image16}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image17}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image18}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image19}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image20}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image21}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image22}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />		
		<img src={Image23}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		
		<img src={Image24}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image25}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image26}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image27}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image28}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image29}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image30}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />		
		
		<img src={Image31}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		
		
		
	</Box>

    <ScrollToTopButton />

    </>
  );
}

export default D102_ToMouseioMas;
