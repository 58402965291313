import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g10/prosfiges.jpg";

function G10_mikrasiatika() {

  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Η προσφορά του προσφυγικού Ελληνισμού στην ανάπτυξη της σύγχρονης Ελλάδας" />
      </Box>

      
      <Box sx={{ ...commonBoxStyles }}>
      <Typography variant="h1" sx={theme.typography.typography1}>απόσπασμα από την ομιλία του ιστορικού, συγγραφέα και καθηγητή Σαράντου Καργάκου, στη Μυτιλήνη.</Typography>
	
	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

<Typography variant="h3" sx={theme.typography.typography2}>Ο καταγόμενος από τη Σπάρτη ομιλητής, ήταν γλαφυρός κατατοπιστικός, και παραστατικός, ανέφερε γνωστά σε όλους μας γεγονότα, αλλά παρουσίασε και 
	καινούργια στοιχεία από την έρευνα που είχε κάνει. Θεωρεί δε την ομιλία του ως μια ταπεινή αντιπροσφορά στη μεγάλη προσφορά των προσφύγων. Η ομιλία του αρχίζει 
	έτσι. "Η πορεία των Μικρασιατών από την καταστροφή προς τη μητροπολιτική Ελλάδα, ήταν μια πορεία που από τη θυσία, μας έφερε στην υψηλή δημιουργία".</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Τον όρο	<b>πρόσφυγας</b> τον θεωρούσα προσβλητικό διότι πρόσφυξ σημαίνει αυτός που έχει δικαίωμα κάπου να προσφύγει για να διεκδικήσει τα δικαιώματα του κι 
	όχι να καταφύγει.
	Ο προσφυγικός κόσμος υπήρξε η ατμομηχανή της σύγχρονης Ελλάδας και πριν την άφιξή του, η Ελλάδα ήταν μια Βαλκανική επαρχία  που μετά την άφιξή τους έγινε Ευρώπη,
	χωρίς όμως να χαθούν οι συναισθηματικοί δεσμοί με την Ανατολή. Πρέπει να τονιστεί εδώ ότι όλοι οι πρόσφυγες δεν ήρθαν μόνο στην Ελλάδα γιατί πολλοί κατέφυγαν στην 
	Αμερική και Αίγυπτο, όπως και στις περιοχές Λυών, Σαιντ Ετιέν και Μασ	σαλίας, όπου είχαν εγκατασταθεί 2.000 οικογένειες με ειδική παρέμβαση του Βενιζέλου προς τον
	Γάλλο πρωθυπουργό. Σήμερα δεν μιλάμε για χαμένες πατρίδες αλλά για αλησμόνητες πατρίδες που πεθαίνουν μόνο όταν τις ξεχνάμε. Ξεριζωμένοι οι πρόσφυγες επούλωσαν
	πληθυσμιακά κενά και θωράκισαν αμυντικά τις περιοχές της Θράκης και Μακεδονίας κι αφαίρεσαν κάθε σοβαρή απόπειρα ανακαινίσεως του Μακεδονικού και Θρακικού ζητήματος.
	Οι πρόσφυγες αν και δεν ήταν όλοι αγροτικής προελεύσεως, αξιοποίησαν τις εύφορες περιοχές με ιδιαίτερη επίδοση στην καπνοκαλλιέργεια και το καπνεμπόριο. Έφεραν μαζί
	τους ένα προοδευτικό κοινωνικοπολιτικό πνεύμα που τους έκανε πρωτοπόρους σε συνδικαλιστικούς αγώνες. Έφεραν ακόμη το πνεύμα του κοινοτισμού και της συνεργατικής δράσης 
	κι έτσι αναπτύχθηκε η Συνεταιριστική οργάνωση. Ομοίως και η τοπική αυτοδιοίκηση, αποδίδει περισσότερα όταν βρίσκεται στα χέρια των προσφύγων. Οι πρόσφυγες διαθέτουν
	τους περισσότερους πολιτιστικούς συλλόγους, όπως και τις περισσότερες μελέτες. Η προσφυγιά έδωσε άφθονα εργατικά χέρια στη βιομηχανία και βιοτεχνία έτσι που οι 
	προσφυγικοί συνοικισμοί είχαν μετατραπεί σε αξιόλογες βιομηχανικές και βιοτεχνικές περιοχές. Οι πρόσφυγες έφεραν ένα σημαντικό πνευματικό κεφάλαιο. Εκατοντάδες 
	ηθοποιοί, συγγραφείς, δημοσιογράφοι, επιστήμονες αιματοδότησαν την αναιμική πνευματική και καλλιτεχνική ζωή της Ελλάδος. Αλλά και το λαϊκό προσφυγικό στοιχείο, 
	έφερε μαζί του το λαϊκό πολιτισμό που ενσωματώθηκε με τα εντόπια πολιτιστικά στοιχεία και έδωσε ανυπέρβλητα δείγματα λαϊκού πολιτισμού, ο δε χασάπικος χορός, 
	είναι ο μακελάρικος χορός των Βυζαντινών και το ζεϊμπέκικο, είναι ο αρχαίος Θρακικός χορός συνδεόμενος με τη λατρεία του Διός Μπέκου (Ζευς Μπέκος) που ήταν
	ευρύτατα διαδεδομένος στην περιοχή του Αιδινίου.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Οι πρόσφυγες πρωτοπόρησαν και στον αθλητισμό όπως και στον γυναικείο αθλητικό τομέα. Ο εθνομάρτης Χρυσόστομος Σμύρνης έκανε υποχρεωτική σ όλα τα σχολεία τη
	γυμναστική κι από τότε αρχίζει η ανάπτυξη του γυναικείου αθλητισμού. Η καλαθοσφαίριση και η πετόσφαιρα (βόλεϊ) ήταν άγνωστα στ	ην Ελλάδα πριν την άφιξη των
	προσφύγων. Η γυναίκα αρχίζει να χειραφετείται από τη στιγμή που οι προσφυγοπούλες για να ζήσουν την οικογένειά τους μπαίνουν στις φάμπρικες, μετέχουν σε 
	εργατικούς αγώνες, διευθύνουν επιχειρήσεις και ανοίγουν τις πύλες των ανωτάτων πνευματικών ιδρυμάτων. Ακόμη οι γυναίκες αυτές μας έφεραν την ποικιλία των
	εδεσμάτων, εξαφανίζοντας την Σπαρτιατική μονοτονία της Ελλαδικής κουζίνας και εισβάλει ορμητικά η πολίτικη και Σμυρναϊκή κουζίνα. Όσο κι .αν θίγεται ο
	παλαιοελλαδίτικος εγωισμός μας, είναι αλήθεια ότι οι πρόσφυγες μας έμαθαν την υγιεινή του σώματος. Μέχρι την άφιξή τους η λεγόμενη τουαλέτα, η το μπάνιο,
	θεωρούνταν πολυτέλεια και περιττός χώρος. Εκείνοι έκαναν το μπάνιο αναγκαίο μέρος του σπιτιού. Σήμερα μένουμε έκθαμβοι από τους προσφυγικούς ναούς και
	βλέπουμε στις εκκλησίες η στα μουσεία τα πολύτιμα βιβλία και τις γιγαντιαίες εικόνες που μετέφεραν οι πρόσφυγες στην Ελλάδα που για να μεταφερθούν, 
	έπρεπε να τις κρατούν δύο άνθρωποι κι έτσι οι πρόσφυγες προτίμησαν να σώσουν τα ι	ερά παρά τα οικιακά τους σκεύη. Μαζί τους ξαναζωντάνεψε η ρωμαϊκή 
	παράδοση στην αγιογραφία χάρις στον Κόντογλου και κυρίως στην ψαλτική. Οι φραγκοπαναγίες, όπως τις έλεγε ο Κόντογλου εκτοπίστηκαν από τις εκκλησίες μας. </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Μετά τον ξεριζωμό ο προσφυγικός κόσμος περιορίζεται στη Δ. όχθη του Αιγαίου κι αυτό είναι το καινούργιο δεδομένο ποιο συγκλονιστικό κι απ την 
	άλωση της
	Πόλης, γιατί άρχιζε να σβήνει το όραμα της "μεγάλης ιδέας" που θέρμαινε επί αιώνες την Ελληνική ψυχή. Το διάσπαρτο σ όλη την Οθωμανική αυτοκρατορία 
	Ελληνικό στοιχείο συνενώθηκε βίαια με το μητροπολιτικό και η συγκέντρωσή του σ ένα ενιαίο γεωγραφικό χώρο, άνοιξε μια νέα προοπτική.  Για πρώτη φορά
	Ελληνισμός δρα μαζί όλος. Αυτή τη μια και μοναδική ψυχή των Ελλήνων πρέπει να τη δώσουμε και στη ψυχή των παιδιών μας. Έτσι θα διασωθεί η ιστορική μας
	μνήμη, διασώζοντας την πατριωτική μας μνήμη. Όταν πρωτόγραφα αυτές τις γραμμές [24-1-1993] ο Τούρκος πρωθυπουργός Σουλεϊμάν Ντεμιρέλ εξαπέλυε εναντίον
	μας νέους προκλητικούς κεραυνούς. Θα καταριόσαστε 	–είπε- τη στιγμή που γεννηθήκατε. Κι όμως η Ευρώπη κι η Αμερική εκσυγχρονίζουν τη στρατιωτική
	τεχνολογία στην Τουρκία και ξεχνά αυτό που είπε ο Σατωβριάνδος ότι δίδοντας ατμόπλοια και σιδηροδρόμους, εκπαιδεύοντας το στρατό και το στόλο της 
	δεν σημαίνει  ότι επεκτείνει τον πολιτισμό στην Ανατολή αλλά ότι εισάγει τη βαρβαρότητα στη Δύση. Η Τουρκία ξέρει πολλά τερτίπια αλλά αγνοεί τη σοφία
	μιας αρχαίας παροιμίας πολλά γνωρίζει η αλεπού, αλλ ο σκαντζόχοιρος ένα και τρανό, να συσπειρώνεται και ν απλώνει τα αγκάθια του. Μια είναι η 
	συμβουλή προς τη γείτονα που συνεχώς προκαλεί και δεν θέλει να ζητήσει συγγνώμη για τα εγκλήματα που έχει διαπράξει εις βάρος των χριστιανών και 
	σκάβει συνεχώς το λάκκο μας κι όποιος σκάβει λάκκο, θα πέσει σ αυτόν. Ξέρω και το έχω γράψει άπειρες φορές ότι η ιστορία μας χωρίζει, η γεωγραφία
	μας ενώνει. Αυτό δεν σημαίνει και συνενώνει. Μπορούμε να ζήσουμε ειρηνικά αν η γείτων αποβάλει τα επιθετικά της ένστικτα. Μια υποχωρητική όμως 
	πολιτική θα είναι ασέβεια προς τους νεκρούς που αφ	ήσαμε στη Μ. Ασία. Θα είναι κατάρα που θα μας κυνηγά για πάντα"
	<b><br/><br/><br/>Νίκη Τατά Χούσου.</b></Typography>

	</Box>
	
	<ScrollToTopButton />

    </>
  );
}

export default G10_mikrasiatika;
