import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g4/photo1.jpg";
import Image02 from "../assets/images/mikrasiatika_g4/photo2.jpg";
import Image03 from "../assets/images/mikrasiatika_g4/photo3.jpg";
import Image04 from "../assets/images/mikrasiatika_g4/photo4.jpg";
import Image05 from "../assets/images/mikrasiatika_g4/photo5.jpg";
import Image06 from "../assets/images/mikrasiatika_g4/photo6.jpg";

function G4_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ο Χριστιανισμός και οι επτά εκκλησίες της Μικράς Ασίας" />
      </Box>

        
      <Box sx={{ ...commonBoxStyles }}>
	
	
	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Καθοριστική για την εξάπλωση του χριστιανισμού ήταν η ιεραποστολική δράση του <b>απόστολου Παύλου</b>, γύρω στα μέσα του 1ου αι. μ.Χ. Καταγόμενος από την Ταρσό της Κιλικίας,
	ο Παύλος ήταν γνώστης της ελληνικής γλώσσας και Ρωμαίος πολίτης, γεγονός που του έδινε ευχέρεια κινήσεων. Ταξίδεψε αρκετά στη Μικρά Ασία, διδάσκοντας  και ιδρύοντας
	εκκλησίες, τόσο στις ελληνικές πόλεις των παραλίων, ιδιαίτερα στην Έφεσο και τη Μίλητο όσο και στις αυτόχθονες περιοχές της ενδοχώρας. 
	Οι πρώτες χριστιανικές κοινότητες περιλάμβαναν πιστούς απ’ όλα τα κοινωνικά στρώματα. Στην ηγεσία ωστόσο βρίσκονταν κυρίως άνθρωποι μορφωμένοι και γνώστες της 
	ελληνικής γλώσσας. Σε περιόδους διωγμών, οι εκκλησίες της Μικράς Ασίας πλήρωσαν και αυτές  φόρο αίματος. Ωστόσο, οι κοινότητες των χριστιανών δεν κάμφθηκαν, 
	αντίθετα ισχυροποιήθηκαν. Έτσι, όταν ο αυτοκράτορας Κωνσταντίνος αναγνώρισε το χριστιανισμό, τα πρώτα εκκλησιαστικά οικοδομήματα δεν άργησαν να πάρουν τη θέση 
	τους στον οικοδομικό ιστό των πόλεων, συχνά αντικαθιστώντας δημόσια κτήρια ή και προγενέστερους ναούς.</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Στα τέλη του 1ου αι. μ.Χ. ο ευαγγελιστής Ιωάννης, στην εισαγωγή της Αποκάλυψης, απευθύνθηκε με επιστολές προς τις <b>Επτά Εκκλησίες της Ασίας</b>, οι οποίες φαίνεται 
	ότι ήταν οι πιο επιφανείς χριστιανικές κοινότητες. Ο αριθμός 7 είχε ιδιαίτερη συμβολική σημασία και ενδέχεται οι επτά πόλεις να συμβολίζουν ολόκληρο το σώμα της 
	Εκκλησίας. Ίσως πάλι να ήταν οι εκκλησίες εκείνες με τις οποίες ο Ιωάννης είχε έρθει σε κάποια αντιπαράθεση και τώρα έγγραφε για να τις νουθετήσει. 
	Οι εκκλησίες αυτές ήταν των <b>Σάρδεων</b>, των <b>Θυάτειρων</b>, της <b>Σμύρνης</b>, της <b>Εφέσου</b>, της <b>Περγάμου</b>, της <b>Φιλαδέλφειας</b>
	και της <b>Λαοδίκειας</b>.</Typography>
	
	<img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Οι <b>Σάρδεις</b> ήταν η πρωτεύουσα της Λυδίας, περιοχής περιώνυμης για τον πλούτο της, που ενδέχεται να αποτέλεσε την αιτία που οι προσήλυτοι χριστιανοί δεν 
	τηρούσαν πιστά τις οδηγίες του ενάρετου βίου, όπως τους προσάπτει ο Ιωάννης ο Θεολόγος. Ωστόσο, οι συστηματικές ανασκαφές έφεραν στο φως πολλές  εκκλησίες, όπως
	η Βασιλική Μ, χτισμένη στο νοτιοδυτικό άκρο του Ναού της Άρτεμης, πριν το 400 μ. Χ., που λειτουργούσε αρχικά ως κοιμητηριακός ναός.</Typography>	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Τα <b>Θυάτειρα</b> αποτελούσαν συνοριακό φυλάκιο του βασιλείου της Περγάμου και ακολούθησαν τη μοίρα του  όταν, το 133 π.Χ., ο Άτταλος ο Γ΄ κληροδότησε το κράτος 
	του στη Ρώμη. Η χριστιανική κοινότητα, αν και μικρή, ήταν από τις πιο ενάρετες, σύμφωνα με τον Ιωάννη. Τα χριστιανικά μνημεία  της πόλης ωστόσο είναι 
	περιορισμένα στο Ulu Cami.</Typography>		
	
	<img src={Image03}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η χριστιανική κοινότητα της <b>Περγάμου</b> ήταν, σύμφωνα με τη σχετική επιστολή του Ιωάννη, από τις πιο ευάλωτες και προβληματικές. Η Πέργαμος αποτελούσε μια  
	από τις πιο αφοσιωμένες πόλεις στη ρωμαϊκή εξουσία και οι παγανιστικές λατρείες, συμπεριλαμβανομένης και της αυτοκρατορικής, ήταν ιδιαίτερα διαδεδομένες. 
	Εντούτοις, οι χριστιανοί της Περγάμου άντεξαν τα μαρτύρια που τους επιβλήθηκαν και καταπολέμησαν τον Νικολαϊιτισμό και την αίρεση του Βαλαάμ. Σημαντικά 
	χριστιανικά κτίσματα εμφανίζονται από τον 4ο αι., με  κυριότερη τη Βασιλική του Αγίου Ιωάννη του Θεολόγου, που χτίστηκε στην εντυπωσιακή Κόκκινη Αυλή, 
	ρωμαϊκό κτήριο δημόσιου χαρακτήρα. Εκκλησίες επίσης χτίστηκαν μέσα στο Ναό της Αθηνάς, κοντά στο Ασκληπιείο και στο νότιο τμήμα της Αγοράς.</Typography>		
	
	<Typography variant="h3" sx={theme.typography.typography2}>Οι χριστιανοί της <b>Φιλαδέλφειας</b> (σημερινό <b>Alasehir</b>) την εποχή του Ιωάννη ήταν λίγοι και μάλλον φτωχοί και για το λόγο αυτό, ενάρετοι. Η πόλη δεν έχει 
	δώσει σημαντικά χριστιανικά μνημεία, εκτός από διάσπαρτα μέλη, όπως αυτό το μαρμάρινο ανάγλυφο.</Typography>
	
	
	<img src={Image04}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Ο Ιωάννης κατηγορεί τους χριστιανούς της <b>Λαοδίκειας</b> (σημερινό <b>Εskihisar</b>), ως "χλιαρούς" στην πίστη τους. Παρ’ όλα αυτά, η πόλη φαίνεται ότι διέθετε 
	αρκετές εκκλησίες, ήδη πριν τον 5ο αι., όπως το Νυμφαίο στο κέντρο της πόλης, που θεωρείται σήμερα ότι είχε μετατραπεί σε χριστιανικό ναό, καθώς και 
	αρκετά παλαιότερα κτήρια στα οποία χαράχτηκε το σημείο του σταυρού.</Typography>
	<br/><br/><br/><br/><br/><br/>
	
	<img src={Image05}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η πρώιμη χριστιανική ιστορία της <b>Σμύρνης</b> σφραγίστηκε από τον επίσκοπό της και μάρτυρα Πολύκαρπο (95-156 μ.Χ.), ο οποίος ίσως να χειροτονήθηκε από τον 
	ίδιο τον Ιωάννη. Ο Πολύκαρπος θανατώθηκε στην πυρά και στην περιοχή αναπτύχθηκε ξεχωριστή λατρεία προς το μάρτυρα. Θα πρέπει μάλιστα να είχε χτιστεί και 
	μαρτύριο προς τιμή του. Εδώ, η δυτική στοά της Αγοράς στη Σμύρνη, 2ος αι. π.Χ.</Typography>
	
	<img src={Image06}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Από τα πρώϊμα χριστιανικά κατάλοιπα της <b>Εφέσου</b>, θεωρείται ότι είναι μια σήραγγα σκαμμένη στο όρος Κορησσός, επάνω από το Σεράπειο, μέσα στην οποία βρέθηκαν 
	λυχνάρια και χαράγματα θρησκευτικού περιεχομένου. Σε τέτοιου είδους κτίσματα και σε ιδιωτικές οικίες συγκεντρώνονταν οι χριστιανοί την εποχή που γράφτηκε η 
	Αποκάλυψη. Μόνο μετά την αναγνώριση του χριστιανισμού από τον Μεγάλο Κωνσταντίνο χτίστηκαν οι πρώτες μεγάλες εκκλησίες, η μια πάνω στο ναό του Σέραπη, η 
	άλλη στη θέση ρωμαϊκού ηρώου στην Άνω Αγορά. Ο δυτικός πύργος του τείχους του Λυσίμαχου έχει χαρακτηριστεί ως "φυλακή του Αγίου Παύλου", αν και ο απόστολος 
	δεν φυλακίστηκε, αλλά εκδιώχθηκε από την Έφεσο. Ο ίδιος ο Ιωάννης, μετά την Πάτμο, εγκαταστάθηκε εκεί ως θρησκευτικός ηγέτης. Τον 6ο αι. χτίστηκε προς τιμήν 
	του η μεγάλη βασιλική στο σημερινό λόφο του Selçuk. Τέλος, στα χριστιανικά αξιοθέατα της Εφέσου  περιλαμβάνονται ο λεγόμενος Οίκος της Παρθένου Μαρίας 
	και το Σπήλαιο των Επτά Παίδων, όπου σύμφωνα με την παράδοση έπεσαν σε αιώνιο ύπνο επτά νέοι την εποχή του Δέκιου και ξύπνησαν στα μέσα του 5ου αι. Μετά τον 
	οριστικό τους θάνατο, ενταφιάστηκαν στο ίδιο σπήλαιο και με αυτοκρατορική χορηγία του Θεοδόσιου Β΄, χτίστηκε εκεί εκκλησία, η οποία αποτέλεσε τόπο προσκυνήματος 
	τον 15ο αι. μ. Χ.</Typography>	
		
	</Box>


    <ScrollToTopButton />


    </>
  );
}

export default G4_mikrasiatika;
