import React from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import ScrollToTopButton from '../components/ScrollToTopButton';
import Header1 from '../components/Header1';
import HomeIcon from '@mui/icons-material/Home'
import { commonBoxStyles, headerBoxStyles } from '../components/CommonStyles'

import Image02 from '../assets/images/drastiriotites2013/image002.jpg'
import Image04 from '../assets/images/drastiriotites2013/image004.jpg'
import Image06 from '../assets/images/drastiriotites2013/image006.jpg'
import Image08 from '../assets/images/drastiriotites2013/image008.jpg'
import Image10 from '../assets/images/drastiriotites2013/image010.jpg'
import Image12 from '../assets/images/drastiriotites2013/image012.jpg'
import Image14 from '../assets/images/drastiriotites2013/image014.jpg'
import Image16 from '../assets/images/drastiriotites2013/image016.jpg'
import Image18 from '../assets/images/drastiriotites2013/image018.jpg'
import Image20 from '../assets/images/drastiriotites2013/image020.jpg'
import Image22 from '../assets/images/drastiriotites2013/image022.jpg'
import Image24 from '../assets/images/drastiriotites2013/image024.jpg'
import Image26 from '../assets/images/drastiriotites2013/image026.jpg'
import Image28 from '../assets/images/drastiriotites2013/image028.jpg'
import Image30 from '../assets/images/drastiriotites2013/image030.jpg'
import Image32 from '../assets/images/drastiriotites2013/image032.jpg'
import Image34 from '../assets/images/drastiriotites2013/image034.jpg'
import Image36 from '../assets/images/drastiriotites2013/image036.jpg'
import Image38 from '../assets/images/drastiriotites2013/image038.jpg'
import Image40 from '../assets/images/drastiriotites2013/image040.jpg'
import Image42 from '../assets/images/drastiriotites2013/image042.jpg'
import Image44 from '../assets/images/drastiriotites2013/image044.jpg'
import Image46 from '../assets/images/drastiriotites2013/image046.jpg'
import Image48 from '../assets/images/drastiriotites2013/image048.jpg'
import Image50 from '../assets/images/drastiriotites2013/image050.jpg'
import Image52 from '../assets/images/drastiriotites2013/image052.jpg'
import Image54 from '../assets/images/drastiriotites2013/image054.jpg'
import Image56 from '../assets/images/drastiriotites2013/image056.jpg'
import Image58 from '../assets/images/drastiriotites2013/image058.jpg'
import Image60 from '../assets/images/drastiriotites2013/image060.jpg'
import Image62 from '../assets/images/drastiriotites2013/image062.jpg'
import Image64 from '../assets/images/drastiriotites2013/image064.jpg'
import Image66 from '../assets/images/drastiriotites2013/image066.jpg'
import Image68 from '../assets/images/drastiriotites2013/image068.jpg'
import Image70 from '../assets/images/drastiriotites2013/image070.jpg'

function D10_drastiriotites_2013() {

    const theme = useTheme();


    return (
        <>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ ...headerBoxStyles }}>
                <Header1 props="Οι δραστηριότητές μας το 2013" />
            </Box>


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Κοπή της Βασιλόπιτας του Συλλόγου μας</Typography>
                <img src={Image02} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image04} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε με μεγάλη επιτυχία η χοροεσπερίδα του Συλλόγου μας την παραμονή της Πρωτοχρονιάς, στις 10 το βράδυ στο ξενοδοχείο ΚΟΥΝΤΟΥΡΟΥΔΙΑ.
                    Στο ξενοδοχείο ΚΟΥΝΤΟΥΡΟΥΔΙΑ πραγματοποιήθηκε με μεγάλη επιτυχία η χοροεσπερίδα του Συλλόγου μας την παραμονή της Πρωτοχρονιάς, στις 10 το βράδυ.
                    Η αίθουσα του ξενοδοχείου ήταν κατάμεστη από τα μέλη και τους φίλους του Συλλόγου που για μια ακόμη χρονιά έδειξαν ότι, παρά την οικονομική κρίση, στηρίζουν τις εκδηλώσεις μας και μας δίνουν κουράγιο για να συνεχίσουμε το έργο που επιτελούμε ανελλιπώς εδώ στη Σκάλα Λουτρών από το 1990.<br />
                    Το κέφι ήταν παντού διάχυτο και ο χορός, κυρίως από την νεολαία, ασταμάτητος αφού η εξαίρετη ορχήστρα του ξενοδοχείου μας κράτησε συντροφιά μέχρι τα ξημερώματα της Πρωτοχρονιάς. <br />
                    Κατά την διάρκεια της εκδήλωσης διενεργήθηκε λαχειοφόρος αγορά με πολλά και πλούσια δώρα , όλα προσφορές των καταστημάτων και των επιχειρήσεων της Μυτιλήνης και του χωριού μας, τους οποίους ευχαριστούμε ιδιαιτέρως. <br />
                    Με την αλλαγή του χρόνου αμέσως μετά τις 12.00 κόπηκε η βασιλόπιτα του Συλλόγου με τις ευλογίες του ιερέα του χωριού μας του Παπά Παναγιώτη, η οποία μοιράστηκε σε όλα τα τραπέζια. Τυχερή της βραδιάς με κλήρωση ήταν η δεσποινίδα <b>Κατερίνα Ταβατζή</b> που με τον αριθμό <b>93</b> κέρδισε το φλουρί της πίτας, ένα ασημένιο χειροποίητο Κωσταντινάτο, προσφορά του χρυσοχοείου <b>Βέτσικα</b>, καθώς και ένα δαχτυλίδι, προσφορά από το χρυσοχοείο της κ. <b>Αλέκας Κοντηβέη</b>.
                    Τέλος επισημαίνουμε ότι, η εξυπηρέτηση του ξενοδοχείου ήταν πολύ καλή, το φαγητό εξαιρετικό  και όλοι όσοι παραβρέθηκαν έφυγαν με τις καλλίτερες εντυπώσεις. Και του χρόνου με υγεία.

                </Typography>                
            </Box>


            <ScrollToTopButton />



            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Ο Αγιασμός των υδάτων στη Σκάλα Λουτρών και στην Χαραμίδα</Typography>
                <img src={Image06} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image08} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Με χιονόνερο και τσουχτερό κρύο πραγματοποιήθηκε ο Αγιασμός των υδάτων τόσο στη Σκάλα Λουτρών όσο και στη Χαραμίδα.
                    Μετά το πέρας της Θείας Λειτουργίας στον Ιερό Ναό του Αγίου Γεωργίου Λουτρών, ο ιερέας μαζί με το εκκλησίασμα ξεκίνησαν για τον αγιασμό των υδάτων στην προβλήτα της Σκάλας Λουτρών, ένα έθιμο το οποίο επαναλαμβάνετε αδιαλείπτως κάθε χρόνο την ίδια ημέρα.<br />
                    Μετά την καθιερωμένη τελετουργία και με το «εν Ιορδάνη βαπτιζομένου σου Κύριε…»  δύο θαρραλέοι κολυμβητές ο Παναγιώτης Τσεσμελής και ο Γεώργιος Κρητικός έπεσαν στα παγωμένα νερά όπου ο  <b>Παναγιώτης Τσεσμελής</b> ήταν αυτός που έπιασε πρώτος τον Σταυρό τον οποίο και ανέσυρε στην επιφάνεια. Αμέσως μετά το πολιτιστικό κέντρο Λουτρών έκοψε την καθιερωμένη βασιλόπιτα προσφέροντας συγχρόνως και από ένα ποτό στους παρευρισκόμενους.<br />
                    Μετά τον Αγιασμό των υδάτων που έγινε στη Σκάλα Λουτρών ο ιερέας της ενορίας του χωριού μας και οι περισσότεροι από τους παρευρισκόμενους, ξεκίνησαν για να παρευρεθούν στον Αγιασμό των υδάτων στην περιοχή της Χαραμίδας όπου περίμεναν πολλοί κάτοικοι της περιοχής, καθώς και αρκετοί Μυτιληνιοί οι οποίοι το έχουν καθιερώσει κάθε χρόνο τέτοια μέρα να έρχονται στη Χαραμίδα.<br />
                    Η καθιερωμένη τελετή και εδώ πραγματοποιήθηκε κάτω από δυσμενείς καιρικές συνθήκες με χιονόνερο και τον Σταυρό ανέσυρε από τη θάλασσα ο <b>Μιχάλης Μακρής</b>.<br />
                    Αμέσως μετά ακολούθησε και εδώ το κόψιμο της βασιλόπιτας των κατοίκων της περιοχής και το σχετικό κέρασμα σε όλους τους παρευρισκόμενους με  πρωτεργάτες την κ. Άσπα Αβαγιανού, την κ. Αμπατζή κ.α. <br />
                    Παρόντες και στις δύο τελετές τα μέλη του Τοπικού Συμβουλίου Λουτρών καθώς και το Δ.Σ. με τα μέλη του Συλλόγου Μικρασιατών της Σκάλας Λουτρών.

                </Typography>
                <Typography variant="h3" sx={theme.typography.typography2}><br /></Typography>
            </Box>


            <ScrollToTopButton />



            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Αποκριάτικη παιδική εορτή</Typography>
                <img src={Image10} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image12} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Την <b>Κυριακή 10 Μαρτίου 2013</b> πραγματοποιήθηκε για πρώτη φορά αποκριάτικη παιδική εορτή, στο ξενοδοχείο Κουντουρουδιά με τη συμμετοχή όλων των μικρών μας φίλων μαζί με τους γονείς τους.<br /><br />
                    Οι μικροί μας φίλοι, αλλά και αρκετοί από τους γονείς, μεταμφιεσμένοι ανάλογα με τα γούστα τους ξεφάντωσαν για αρκετές ώρες στον κατάλληλα διακοσμημένο χώρο του ξενοδοχείου. Οι μητέρες είχαν φέρει μαζί τους διάφορα γλυκίσματα όπως κέικ, τούρτες, και όλων των ειδών τις λιχουδιές. Όλοι περάσαμε πραγματικά υπέροχα και πρόθεσή μας είναι αυτή την γιορτούλα να την καθιερώσουμε να επαναλαμβάνετε κάθε χρόνο αυτή την εποχή. Η συμμετοχή κλόουν στην εκδήλωση, ενθουσίασε τα παιδιά και η γιορτούλα έκλεισε πλέκοντας το αποκριάτικο Γαϊτανάκι.<br /><br />
                    Ο Σύλλογός μας ευχαρίστησε όλες τις νοικοκυρές για την συμμετοχή τους και ειδικότερα τις μητέρες των μικρών παιδιών της παιδικής ομάδας Play group για την εξαιρετική βραδιά που μας πρόσφεραν.<br />
                </Typography>
                <Typography variant="h3" sx={theme.typography.typography2}></Typography>
            </Box>


            <ScrollToTopButton />



            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Αρχαιρεσίες στον Σύλλογό μας.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Την <b>Κυριακή  17 Μαρτίου 2013</b> και ώρα 18.00  πραγματοποιήθηκε η ετήσια τακτική Γενική Συνέλευση των μελών  του Συλλόγου μας, στην αίθουσα της βιβλιοθήκης, στη Σκάλα Λουτρών, με τα εξής θέματα.<br />
                    1.	Έκθεση και έλεγχος της δράσης του Δ.Σ.<br />
                    2.	Οικονομικός απολογισμός - έκθεση εξελεγκτικής επιτροπής.<br />
                    3.	Ενημέρωση επί τρεχόντων θεμάτων.<br />
                    4.	Προτάσεις μελών. <br />
                    5.	Εκλογή εφορευτικής επιτροπής.<br /><br />
                    Οι αρχαιρεσίες καθορίστηκαν από την Γ.Σ. για την Κυριακή 24-3-2013 στην αίθουσα του «Μουσείου Προσφυγικής Μνήμης 1922» και το νέο Δ.Σ. που προέκυψε από τις αρχαιρεσίες της 24-3-2013 καθόρισε τις αρμοδιότητές του ως κάτωθι:<br />
                    <b>Πρόεδρος</b>:           Παπαχρυσός Δημήτρης.<br />
                    <b>Αντιπρόεδρος</b>:    Δαφιώτη Νάσια.<br />
                    <b>Γ. Γραμματέας</b>:  Δασκάλου Μαρία. <br />
                    <b>Ταμίας</b>:               Παλκανίκου Τζένη.<br />
                    <b>Μέλη</b>:                 Τουρλής Μιχάλης, Βέντου Δώρα, Λημναίου Βαγγελίτσα.<br />
                    <b>Αν. Μέλη</b>:        Κρητικού Φραντζέσκα, Λιόφλου Βικτωρίτσα, Σταματέλλη Αγγελική.<br /><br /><br />
                    Επίσης καθορίστηκαν οι υπεύθυνοι στα διάφορα τμήματα του Συλλόγου:<br />
                    Στον <b>Πολιτιστικό τομέα</b> : Δαφιώτη Νάσια, Λέλεκας Έλπις, Τουρλής Μιχάλης, Λιόφλου Βικτωρίτσα και Λημναίου Βαγγελίτσα.<br />
                    Στα θέματα <b>Περιβάλλοντος</b> : Παπαχρυσός Δημήτρης, Παλκανίκου Τζένη, Σταματέλλη Αγγελική, Βέντου Δώρα και Κρητικού Φραντζέσκα.<br />
                    Στην <b>Bιβλιοθήκη</b> : Παπαχρυσός Δημήτρης, Βέντου Δώρα και Κρητικού Φραντζέσκα.<br />
                    Στο <b>Μουσείο Προσφυγικής Μνήμης 1922</b> : Βαλαχής Σταύρος, Τουρλή Ευθαλία, Καραμάνης Στράτης και Κρητικού Φραντζέσκα.<br />
                    Στα <b>Μουσικά τμήματα</b>: Παπαχρυσός Δημήτρης και Παλκανίκου Τζένη.<br />
                    Στο <b>παιδικό τμήμα</b>: Παλκανίκου Τζένη, Κρητικού Φραντζέσκα και Σταματέλλη Αγγελική.<br /><br />
                    <b>Τέλος</b> για τις εισπράξεις συνδρομών κτλ καθορίστηκαν ως βοηθοί του  ταμία οι Τουρλής Μιχάλης, Παπαχρυσός Δημήτρης και Σταματέλλη Αγγελική.<br />

                </Typography>

            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Πάσχα  στη Σκάλα Λουτρών</Typography>
                <img src={Image14} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκαν και φέτος για όγδοη συνεχή χρονιά οι θρησκευτικές εκδηλώσεις των αγίων ημερών του Πάσχα στο παρεκκλήσιο του Αγίου Παντελεήμονος στη Σκάλα Λουτρών. Αυτές ξεκίνησαν με την τέλεση της θείας λειτουργίας το πρωί της Κυριακής των Βαΐων και της ακολουθίας του Νυμφίου το απόγευμα της ίδιας μέρας. Στην συνέχεια το απόγευμα της Μ. Τετάρτης ετελέσθη το Μυστήριο του Ευχελαίου. Την Μ. Πέμπτη ετελέσθη η ακολουθία των παθών και εψάλησαν τα 12 Ευαγγέλια.  <br /><br />
                    Την Μ. Παρασκευή έγινε η περιφορά του επιτάφιου και η αποκαθήλωση του εσταυρωμένου με την συμμετοχή όλων των κατοίκων και της νεολαίας του οικισμού μας. Τέλος το βράδυ του Μεγάλου Σαββάτου, μέσα σε μια κατανυκτική ατμόσφαιρα υπό το φως των κεριών  ετελέσθη η αναστάσιμη Θεία Λειτουργία. Ο σύλλογός μας στο τέλος της Θείας λειτουργίας μοίρασε σε όλους τους παρευρισκόμενους κόκκινα αυγά  και τσουρέκια. Εμείς από την πλευρά μας ευχαριστούμε θερμά τον εφημέριο της ενορίας μας, τον Σεβασμιότατο  Μητροπολίτη Μυτιλήνης, καθώς και το αρμόδιο γραφείο της Μητροπόλεως  που μερίμνησαν και ικανοποίησαν το αίτημά μας  για αποστολή ιερέα. Η συμμετοχή των κατοίκων ήταν καθολική και ιδίως των ηλικιωμένων οι οποίοι προσήλθαν με μεγάλη χαρά. Και του χρόνου να είμαστε γεροί να ξαναγιορτάσουμε όλοι μαζί αυτές τις Άγιες ημέρες.


                </Typography>
                <Typography variant="h3" sx={theme.typography.typography2}></Typography>
            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Ο στολισμός του επιταφίου</Typography>

                <img src={Image16} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Ο στολισμός του επιταφίου των Μικρασιατών της Σκάλας Λουτρών Μυτιλήνης, έληξε γύρω στις 2 το ξημέρωμα. Ένα μπουκέτο απο υπέροχες γυναίκες, η Ευθαλίτσα, η Δεσποινούλα, η Ανθούλα, η Άννα, η Ελένη, η Ειρήνη, η Βασιλεία, η Βικτωρίτσα με το μυρωδάτο τσάι της και η Κατερίνα πλαισίωσαν με έναν υπέροχο τρόπο αυτόν τον στολισμό, με τη Γραμματούλα στο προβάδισμα της ψαλμωδίας, βάσει του εθίμου, ψάλλοντας το τραγούδι της Παναγίας και άλλα εγκώμια.<br />
                    Ο Δημήτρης μοίρασε πρόσφορο, βαφτισμένο σε κόκκινο κρασί. Τα λουλούδια, που στόλισαν τον επιτάφιο, ήταν γκρενά γαρύφαλλα μπλεγμένα με αγγελική, πλαισιωμένα από γυψόφυλλο, που μοσχομύρισε όλο το ξωκλήσι του Αγίου Παντελεήμονα. Γύρω γύρω, φρέσκα τριαντάφυλλα κομμένα απ' τις αυλές των σπιτιών και όλο αυτό μου θύμιζε τη γλύκα του να δημιουργούν όλοι μαζί! Η περιφορά του επιταφίου αρχίζει στις έξι το απόγευμα, σε μια διαδρομή που δένει αρμονικά το πράσινο των αγρών με το μπλε της θάλασσας, υπό το άπλετο αιολικό φως, για να μας θυμίζει την απέριττη ομορφιά της τέχνης που βρίσκεται πάντα κοντά στο απλό...<br /><br />
                    Αμαλία Αποστόλου.

                </Typography>

            </Box>


            <ScrollToTopButton />



            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εξόρμηση καθαριότητας</Typography>
                <img src={Image18} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image20} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Με την ευκαιρία της <b>5ης Ιουνίου</b>, ημέρας αφιερωμένης στην <b>προστασία του περιβάλλοντος</b>, ο Σύλλογός μας «ΤΟ ΔΕΛΦΙΝΙ» πραγματοποίησε τρεις εξορμήσεις καθαριότητας. Καθαρίστηκαν από σκουπίδια και αγριόχορτα οι κοινόχρηστοι χώροι της Σκάλας Λουτρών, η παιδική χαρά, ο χώρος αθλοπαιδιών, το γήπεδο μπάσκετ, το θερινό αμφιθέατρο, το λιμανάκι και ο αύλιος χώρος του «Μουσείου Προσφυγικής Μνήμης 1922». Επίσης επισκευάσαμε τον νυκτερινό φωτισμό του γηπέδου μπάσκετ και κλαδέψαμε  όλα τα δένδρα στους παραπάνω χώρους.<br />
                    Επίσης έγιναν παρεμβάσεις αξιοποίησης και πλακόστρωση του αύλιου χώρου της οικίας στην οποία στεγάζεται το μουσικό τμήμα του Συλλόγου μας, καθώς και οι μικροί μας φίλοι του « Play group». Στον ίδιο χώρο επίσης έγιναν εργασίες ανακαίνισης του WC. <br />
                    Το μήνυμα που δίδουμε κάθε χρόνο με αυτές τις ενέργειες μας είναι ότι είμαστε παρόντες στην προσπάθεια που πρέπει να γίνεται από όλους για την προστασία του περιβάλλοντος, των ακτών και των δημόσιων χώρων εν γένει που αποτελούν σημαντικό χώρο αναψυχής των πολιτών, πόλο έλξης επισκεπτών και πηγή οικονομικής ανάπτυξης του τόπου μας. <br />
                    Ζητάμε από το Δήμο Μυτιλήνης και από το Τοπικό Συμβούλιο Λουτρών, την αυτονόητη φροντίδα  από την υποχρέωση που έχουν σε θέματα καθαριότητας του οικισμού μας.<br />

                </Typography>

            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Κλάδεμα δένδρων και περιποίηση αύλιου χώρου Μουσείου Προσφυγικής μνήμης 1922</Typography>

                <img src={Image22} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image24} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Με την βοήθεια των μελών του Συλλόγου μας και με την αγορά των κατάλληλων υλικών, συντηρήθηκε τόσο εσωτερικά όσο και εξωτερικά το κτίριο και ο αύλιος χώρος του «Μουσείου Προσφυγικής Μνήμης» Στον ίδιο χώρο, κλαδεύτηκαν όλα τα δένδρα, περιποιήθηκαν όλα τα λουλούδια στα παρτέρια και γενικότερα ευπρεπίστηκε όλος ο εξωτερικός χώρος του κτιρίου. Δυστυχώς η εγκατάλειψη που βιώνει από τον Δήμο Μυτιλήνης ο οικισμός μας εδώ και αρκετά χρόνια μας αναγκάζει να διαθέτουμε τις λίγες οικονομίες που με κόπο αποκτάμε σε έργα που θα έπρεπε να τα φροντίζει εκείνος η τουλάχιστον να έχουμε την συνδρομή του.<br /><br /><br /><br />

                    Προς:   ΟΛΣΑ<br /><br />

                    Με το παρακάτω έγγραφό προς την Ομοσπονδία Λεσβιακών Συλλόγων Αττικής, ΟΛΣΑ τους ενημερώνουμε ότι:<br /><br />
                    <i>«Ο Σύλλογος Μικρασιατών της Σκάλας Λουτρών Λέσβου «ΤΟ ΔΕΛΦΙΝΙ» στηρίζει την πρόταση της Ομοσπονδίας Λεσβιακών Συλλόγων Αττικής για την διάσπαση του ενός μόνο Δήμου Λέσβου και την δημιουργία πέντε νέων Δήμων, δεδομένου ότι από την εφαρμογή του ενός μόνο Δήμου έχουν προκύψει τεράστια και δισεπίλυτα προβλήματα τα οποία ταλαιπωρούν σε αφάνταστο βαθμό όλους ανεξαιρέτως τους κατοίκους του νησιού μας».</i>


                </Typography>

            </Box>


            <ScrollToTopButton />



            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>«Μουσειακές διαδρομές: περιήγηση στην ιστορία και τον πολιτισμό της Λέσβου»</Typography>

                <img src={Image26} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας συμμετέχει με το <b>«Μουσείο Προσφυγικής Μνήμης 1922»</b> στο δίκτυο <b>«Μουσειακές διαδρομές: περιήγηση στην ιστορία και τον πολιτισμό της Λέσβου»</b> το οποίο υλοποιείτε από τον Δήμο Λέσβου, στέλνοντας την  παρακάτω επιστολή.<br /><br />
                    «Σε απάντηση του με αρ. πρωτοκόλλου  <b>49749</b>/25-06-2013  εγγράφου σας, σας ενημερώνουμε ότι ο Σύλλογός μας συμφωνεί και επιθυμεί την ένταξη του  «<b>Μουσείου Προσφυγικής Μνήμης 1922» στο «Ολοκληρωμένο Σχέδιο Ανάπτυξης Περιοχών Υπαίθρου (Ο.Σ.Α.Π.Υ.) Δήμου Λέσβου – Ύπαιθρος Χώρα και Πολιτισμός Τουρισμός</b>» και συγκεκριμένα στο υποέργο «<b>Μουσειακές διαδρομές: περιήγηση στην ιστορία και τον πολιτισμό της Λέσβου</b>».<br /><br />
                    Επιπροσθέτως σας ενημερώνουμε ότι το «<b>Μουσείο Προσφυγικής Μνήμης 1922</b>» το οποίο ίδρυσε και συντηρεί ο Σύλλογός μας από τον Αύγουστο του 2006 στη Σκάλα Λουτρών προς το παρόν και έως την εύρεση αντίστοιχων οικονομικών πόρων, είναι επισκέψιμο μόνο με ραντεβού  κατόπιν τηλεφωνικής επικοινωνίας στα τηλέφωνα:
                    22510.91277 κ. Ευθαλία Τουρλή και 69746.40419 κ. Δημήτριο Παπαχρυσό.<br />
                    Θα είμαστε στην διάθεσή σας για την περαιτέρω συνεργασία επί του προαναφερθέντος θέματος καθώς και για οποιεσδήποτε διευκρινήσεις».
                </Typography>

            </Box>


            <ScrollToTopButton />





            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Σάββατο 18 Μαΐου Διεθνής ημέρα Μουσείων</Typography>
                <img src={Image28} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Με Δελτίο Τύπου ο Σύλλογός μας ενημέρωσε το κοινό ότι το <b>Σάββατο 18  Μαΐου 2013</b>, εορτάζετε η <u>Διεθνής ημέρα Μουσείων</u> και για τον λόγο αυτό, το «<b>Μουσείο Προσφυγικής Μνήμης 1922</b>» στη Σκάλα Λουτρών, θα είναι ανοικτό για τους επισκέπτες
                    από τις <b>09.30</b> έως τις <b>13.00</b> και από τις <b>18.00</b> έως τις <b>20.30</b>.<br />
                    Το Διεθνές Συμβούλιο Μουσείων (ICOM), επιθυμώντας να αναδείξει το ρόλο των Μουσείων στη σύγχρονη κοινωνία, έχει ορίσει από το <b>1977</b> την <b>18η Μαΐου</b> ως <b>Διεθνή Ημέρα Μουσείων</b>.<br />
                    Το μήνυμα αυτής της επετείου είναι «να γίνουν τα Μουσεία φορείς πολιτισμικών ανταλλαγών, με σκοπό την ανάπτυξη της μόρφωσης, της αμοιβαίας κατανόησης, της συνεργασίας και της ειρήνης ανάμεσα στους λαούς».<br />
                    Υπενθυμίζουμε ότι το «<b>Μουσείο Προσφυγικής Μνήμης 1922</b>» τις υπόλοιπες ημέρες δέχεται επισκέψεις, μόνον κατόπιν τηλεφωνικής συνεννοήσεως στα τηλέφωνα:
                    22510.91277 κ. Ευθαλία Τουρλή και 69746.40419 κ. Δημήτρη Παπαχρυσό.
                </Typography>

            </Box>


            <ScrollToTopButton />





            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Νεκρή χελώνα Caretta caretta</Typography>
                <img src={Image30} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Νεκρή χελώνα Caretta caretta εξέβρασε η θάλασσα μέσα στον κόλπο Γέρας λίγο μετά το ξενοδοχείο Κουντουρουδιά. Ο Σύλλογός μας ενημερώθηκε από μέλος του που κολυμπούσε στην περιοχή και αμέσως ενημερώθηκε το λιμεναρχείο Μυτιλήνης για τα περαιτέρω. Η χελώνα που είχε μήκος 120 εκατοστά και πλάτος 60 εκατοστά ήταν σε κατάσταση ημιαποσύνθεσης δεν έφερε εμφανή τραύματα  και ο θάνατός της πρέπει να συνέβη κατ εκτίμηση περίπου πριν 3 εικοσιτετράωρα.  Από όσο γνωρίζουμε είναι μάλλον η πρώτη φορά που εντοπίζεται αυτό το είδος χελώνας στον κόλπο της Γέρας.</Typography>

            </Box>


            <ScrollToTopButton />





            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Διαγωνισμός ζωγραφικής και έκθεσης</Typography>
                <img src={Image32} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image34} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Στις <b>5 Ιουνίου 2013</b>, ημέρας αφιερωμένης ως γνωστόν στην προστασία του Περιβάλλοντος και με πρωτοβουλία του Συλλόγου μας, πραγματοποιήθηκε με επιτυχία, ο καθιερωμένος ετήσιος διαγωνισμός ζωγραφικής και έκθεσης με τους μαθητές και τις μαθήτριες του δημοτικού σχολείου Λουτρών.
                    Οι τέσσερις πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα:<br /><br />
                    «<b>Ζωγραφίζω πεταλούδες και λουλούδια του Μαΐου</b>» και οι δύο μεγαλύτερες τάξεις διαγωνίσθηκαν στην έκθεση με θέμα: <br />
                    «<b>Πως φαντάζεστε το περιβάλλον του χωριού μας σε 20 χρόνια;</b>» Η επιλογή της καλλίτερης ζωγραφικής και της καλλίτερης έκθεσης από κάθε τάξη έγινε από τους δασκάλους του σχολείου και τα παιδιά δούλεψαν με ενθουσιασμό και φαντασία τόσο στην ζωγραφική όσο και στην έκθεση. <br />
                    Τα παιδιά που διακρίθηκαν βραβεύθηκαν σε ειδική εκδήλωση που πραγματοποιήθηκε στο ξενοδοχείο «ΚΟΥΝΤΟΥΡΟΥΔΙΑ» το Σάββατο 3 Αυγούστου 2013 στις 9.30 το βράδυ.<br /><br />
                    Οι μαθητές που διακρίθηκαν είναι οι παρακάτω.<br />
                    <b>Κέντζη Μαρία</b>   από την   Α!   δημοτικού στη ζωγραφική.<br />
                    <b>Λέλεκας Φίλιππος</b>   από την   Β!   δημοτικού στη ζωγραφική.<br />
                    <b>Κοζπή Σταυρούλα</b>    από την   Γ!   δημοτικού στη ζωγραφική.<br />
                    <b>Λέλεκας Κωνσταντίνος</b> από την   Δ!   δημοτικού στη ζωγραφική.<br />
                    <b>Κοζπή Ταξιαρχούλα</b>  από την   Ε!    δημοτικού  στην έκθεση.<br />
                    <b>Αραμπατζή Παναγιώτα</b> από την   ΣΤ! δημοτικού στην έκθεση.<br />
                </Typography>


            </Box>


            <ScrollToTopButton />





            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Κολυμβητικός διάπλους Μικρασιατικής Μνήμης «2ος Κολυμβητικός διάπλους Κόλπου Γέρας»</Typography>
                <img src={Image36} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image38} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Με μεγάλη συμμετοχή κολυμβητών πραγματοποιήθηκε το <b>Σάββατο 27-7-2013</b> για δεύτερη χρονιά ο κολυμβητικός διάπλους του Κόλπου Γέρας με την ονομασία «Διάπλους Μικρασιατικής Μνήμης» με αφετηρία την Κουντουρουδιά και τερματισμό το Πέραμα της Γέρας.<br />
                    Οι προσδοκίες των διοργανωτών ξεπέρασαν κάθε πρόβλεψη αφού, η συμμετοχή των κολυμβητών και των συνοδών τους ήταν αθρόα και ενθουσιώδης.<br />
                    Συμμετείχαν <b>63</b>, συνολικά, κολυμβητές, <b>45</b> άνδρες και <b>18</b>  γυναίκες, ηλικίας από <b>10</b> έως <b>74</b> ετών. Όλοι τους τερμάτισαν, χωρίς προβλήματα, στο Πέραμα, που ήταν και ο τελικός προορισμός. Η απόσταση που διανύθηκε ήταν περίπου 1100 μέτρα (σε ευθεία) και ο χρόνος που έκαναν οι κολυμβητές παρόλο που ο διάπλους ήταν συμβολικός και όχι διαγωνιστικός κυμάνθηκε από 15 περίπου λεπτά ο πρώτος, μέχρι 70 λεπτά ο τελευταίος.<br />
                    Ανάμεσα στους συμμετέχοντες υπήρχαν και νεαροί κολυμβητές από τα οι τμήματα κολύμβησης του «Ναυτικού Ομίλου Μυτιλήνης» και των «Αργοναυτών».
                    Με την άφιξη των κολυμβητών στο Πέραμα ξεκίνησε η τέλεση του Μικρασιατικού εθίμου «<b>Το Κοντάρι</b>», το οποίο επαναλαμβάνετε κάθε χρόνο στις 27 Ιουλίου, ημέρα εορτής του Αγίου Παντελεήμονος, πολιούχου Περάματος. <br />
                    Στην ασφαλή διεξαγωγή του κολυμβητικού διάπλου συνέβαλαν, αποφασιστικά, o <b>Λιμενικός Σταθμός Περάματος Γέρας</b> και <b>πλωτό του Λιμενικού Σώματος</b> τους οποίους ευχαριστούμε θερμά. Τις ευχαριστίες μας, επίσης, οφείλουμε στους ιατρούς <b>Ευστράτιο Χατζηγιαννάκη</b> και <b>Νέλη Χατζηπαρασκευά</b> που ήταν σε επιφυλακή δια παν ενδεχόμενο, στον πρόεδρο του Τ. Σ. Περάματος κ. Χρυσίδη Κίμωνα για τις αδειοδοτήσεις που διεκπεραίωσε και στον κ. Ευστράτιο Καραντώνη, ο οποίος διέθεσε τη βάρκα του για τη δωρεάν μεταφορά των αθλητών και των συνοδών τους από την Κουντουρουδιά στο Πέραμα και αντιστρόφως.<br />
                    Τέλος, σε όλους τους συμμετέχοντες δόθηκαν αναμνηστικά διπλώματα για τη συμμετοχή τους και μοιράστηκαν αναμνηστικά καπελάκια.<br />
                    Διοργανωτές της εκδήλωσης ήταν: <br />
                    Ο «<b>Παγγεραγωτικός</b>» Πολιτιστικός Σύλλογος Γέρας,<br />
                    Ο Σύλλογος Μικρασιατών Σκάλας Λουτρών «<b>Το Δελφίνι</b>»,<br />
                    Ο «<b>Παγγεραγωτικός</b>» Σύλλογος Μυτιλήνης,<br /><br />

                    Το Δ. Δ. Περάματος και <br />
                    Η  ΟΛΣΑ ( Ομοσπονδία Λεσβιακών Συλλόγων Αττικής).<br />

                </Typography>

            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Αναφορά του Συλλόγου μας από τις 15-1-2013 στον επιθεωρητή Δημόσιας Διοίκησης<br />
                    για τα έργα ύδρευσης – αποχέτευσης της Σκάλας Λουτρών</Typography>
                <img src={Image40} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image42} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>     Αξιότιμοι κύριοι οι <b>350</b> μόνιμοι κάτοικοι της Σκάλας Λουτρών Λέσβου υφίστανται μια άνευ προηγουμένου ταλαιπωρία από τα έργα ύδρευσης αποχέτευσης του οικισμού μας τα οποία ξεκίνησαν τον Ιούλιο του <b>2009</b> με διάρκεια ολοκλήρωσης των εργασιών τους 10 μήνες και δυστυχώς μέχρι σήμερα 15-1-2013, όχι μόνο δεν έχουν ολοκληρωθεί αλλά οι δρόμοι και οι κοινόχρηστοι χώροι του οικισμού είναι εγκαταλελειμμένοι σε άθλια κατάσταση, τέτοια που ακόμη και το αστικό λεωφορείο του <b>ΚΤΕΛ</b> δεν εκτελεί τα δρομολόγιά του προς την Σκάλα Λουτρών από τον Ιούνιο του <b>2011</b>, δηλαδή εδώ και 20 μήνες περίπου. Λόγω αυτής της απαραδέκτου κατάστασης έχουν προκληθεί πολλές ζημιές σε περιουσίες ιδιωτών αλλά και σε δημόσιους χώρους όπως στην παιδική χαρά, στο γήπεδο μπάσκετ, στις πινακίδες σήμανσης κτλ κυρίως από την πλημμελή εκτέλεση και επίβλεψη των εργασιών. Επίσης έχουν γίνει και ατυχήματα όπως πχ το σπάσιμο του ποδιού κατοίκου της Σκάλας Λουτρών όταν έπεσε σε λάκκο από τα έργα ο οποίος ήταν αφύλακτος χωρίς σήμανση κ.α.<br />

                    Για τα σοβαρά αυτά προβλήματα ο Σύλλογος Μικρασιατών της Σκάλας Λουτρών Λέσβου έχει κάνει επανειλημμένως έγγραφα που έχουν κατατεθεί στο πρωτόκολλο του Δήμου Μυτιλήνης και της ΔΕΥΑΛ στα οποία επισημαίνει τα σοβαρά προβλήματα που έχουν δημιουργηθεί, χωρίς όμως κανένα αποτέλεσμα μέχρι σήμερα. Συγκεκριμένα έχουμε καταθέσει έγγραφα στο Δήμο Μυτιλήνης, στην ΔΕΥΑΛ κ.α. στις παρακάτω ημερομηνίες:<br />
                    Στις <b>11-10-2010</b> προς την τότε ΔΕΥΑΜ και σημερινή ΔΕΥΑΛ.<br />
                    Στις <b>17-03-2011 προς</b>  τον Δήμαρχο Λέσβου, το Δημοτ. Συμβούλιο και την ΔΕΥΑΛ.<br />
                    Στις <b>06-06-2011</b> κοινοποιούμε δελτίο τύπου στα ΜΜΕ της Λέσβου για το πρόβλημα.<br />
                    Στις <b>20-09-2011</b> κάνουμε αναφορά για τα προβλήματα στην Εισαγγελία Μυτιλήνης.<br />
                    Στις <b>26-10-2011</b> κοινοποιούμε στην Εισαγγελία πλήθος δημοσιευμάτων του Τοπικού τύπου με φωτογραφίες για τα σοβαρά προβλήματα που έχουν δημιουργηθεί.<br />
                    Στις <b>03-09-2012</b> αναγκαζόμαστε και στέλνουμε εξώδικη διαμαρτυρία στον Δήμαρχο Λέσβου κ. Δημήτριο Βουνάτσο, στον αντιδήμαρχο περιβάλλοντος και ποιότητας ζωής κ. Βατό Ιωάννη, στον πρόεδρο της ΔΕΥΑΛ  κ. Σωτήρη Μαρινάτο, στην προϊσταμένη τεχνικών υπηρεσιών της ΔΕΥΑΛ κ. Δέσποινα Μπώκου και στον επιβλέποντα των κατασκευαστικών έργων της ΔΕΥΑΛ  κ. Παρασκευά Φινδανή.<br /><br />

                    Την εξώδικη αυτή διαμαρτυρία (η οποία συνοδευόταν και από ένα DVD διάρκειας <b>15</b> λεπτών στο οποίο παρουσιαζόταν η υφιστάμενη κατάσταση του έργου και οι ζημίες που έχουν γίνει) κοινοποιήσαμε στον Υπουργό Εσωτερικών, στον Περιφερειάρχη Βορείου Αιγαίου, καθώς και στην Εισαγγελία Εφετών Βορείου Αιγαίου.<br />
                    Εκτός των ανωτέρω ενεργειών μας επιδιώξαμε κατά το παρελθόν και είχαμε επανειλημμένες συναντήσεις με όλους τους αρμοδίους αναφέροντας κάθε φορά  και προφορικώς τα σοβαρότατα προβλήματα που έχουν δημιουργηθεί.<br />
                    Σήμερα, μη γνωρίζοντας τι επιπλέον μπορούμε να κάνουμε για να αντιμετωπιστούν τα σοβαρά προβλήματα του οικισμού μας, που πολύ περιληπτικά σας προαναφέραμε, αναγκαζόμαστε να απευθυνθούμε και σε εσάς μήπως από την διερεύνηση του θέματος προκύψουν και ατασθαλίες οικονομικές καθώς φημολογείται ότι οι καθυστερήσεις του έργου είναι και σκόπιμες προκειμένου να επιτευχθεί ανακοστολόγηση των εργασιών.<br />
                    Όλα τα παραπάνω έγγραφα και το εξώδικο μπορούμε να σας τα στείλουμε είτε ταχυδρομικώς είτε σε ηλεκτρονική μορφή εάν μας δώσετε κάποιο email.
                    Θα είμαστε στην διάθεσή σας για οποιαδήποτε τυχόν διευκρίνιση.<br />
                    <br/>Στις 10-6-2013 ο Σύλλογός μας επανέρχεται με νέα αναφορά στον επιθεωρητή Δημόσιας Διοίκησης  θέτοντας εκ νέου το πρόβλημα.<br /><br />
                        Αξιότιμοι κύριοι, στις <b>15-1-2013</b> με έγγραφό μας σας είχαμε ενημερώσει για τα σοβαρά προβλήματα που υπάρχουν στην Σκάλα Λουτρών Λέσβου σχετικά με τα έργα ύδρευσης αποχέτευσης και ο κωδικός της σχετικής καταγγελίας μας είχε πάρει αριθμό 3403.<br />
                        Σήμερα 10-6-2013 είμαστε αναγκασμένοι να σας ενημερώσουμε εκ νέου ότι αν και παρήλθε αρκετό διάστημα δεν έχει υπάρξει μέχρι στιγμής καμιά εξέλιξη όσον αφορά την  συνέχιση των εργασιών και αυτό έχει σαν αποτέλεσμα εκτός των άλλων, να υφίστανται οι κάτοικοι την ίδια ταλαιπωρία με την άθλια κατάσταση του δρόμου, καθώς το αστικό λεωφορείο συνεχίζει να μην εκτελεί τα δρομολόγιά του προς τον οικισμό μας από τον Ιούνιο του <b>2011</b>, <u>δηλαδή εδώ και δύο χρόνια</u>, γι αυτό σας παρακαλούμε να ενδιαφερθείτε εκ νέου, προκειμένου να αποκατασταθεί άμεσα τουλάχιστον το οδόστρωμα, έτσι ώστε να μην ταλαιπωρηθούν οι κάτοικοι για <b>5ο</b> συνεχόμενο καλοκαίρι από την σκόνη και τις λακκούβες που υπάρχουν και προξενούν ανυπολόγιστες ζημίες τόσο στους κατοίκους όσο και στα τουριστικά καταλύματα και τις ταβέρνες της περιοχής μας.<br />
                        Ευελπιστώντας στην αποτελεσματικότητα των ενεργειών σας, σας ευχαριστούμε εκ των προτέρων.<br /><br />

                        <b>Επιτέλους έκλεισαν οι «πληγές» στη Σκάλα Λουτρών.</b><br />
                        Μετά τις παραπάνω αναφορές και τα εξώδικα που προηγήθηκαν και μετά από 4 χρόνια απίστευτης ταλαιπωρίας για τους κατοίκους του χωριού μας ολοκληρώθηκαν επί τέλους οι εργασίες ασφαλτόστρωσης του κεντρικού δρόμου στο τέλος του περασμένου Ιουλίου. Έτσι αποκαταστάθηκε η βατότητα του δρόμου και επανεκίνησαν τα δρομολόγια του αστικού ΚΤΕΛ τα οποία είχαν διακοπή λόγω της κατάστασης του δρόμου από τον Ιούνιο του 2011.<br />
                        Ωστόσο εκκρεμούν ακόμη αρκετές εργασίες για την αποκατάσταση διαφόρων κακοτεχνιών του εργολάβου, καθώς και η κατασκευή νέας δεξαμενής ύδρευσης, η οποία ενώ περιλαμβανόταν στο όλο έργο, και είχε χρηματοδοτηθεί από το αντίστοιχο πρόγραμμα, δεν έχει ακόμη κατασκευαστεί.
                    Περιμένουμε λοιπόν από την Δημοτική αρχή να επαναδραστηριοποιηθεί για την συνέχιση των εργασιών που περιλαμβάνουν την κατασκευή της νέας υδατοδεξαμενής και την επίλυση και των υπολοίπων προβλημάτων που εκκρεμούν.<br />

                </Typography>

            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Λειτουργία στο εκκλησάκι του Αγίου Παντελεήμονα</Typography>
                <img src={Image44} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>
                    Το Σάββατο 27 Ιουλίου 2013 εορτάσθηκε με λαμπρότητα και πλήθος πιστών από τη Σκάλα Λουτρών, τα Λουτρά και τη Μυτιλήνη η εορτή του Αγίου Παντελεήμονα στο ομώνυμο εκκλησάκι του οικισμού μας στη Σκάλα Λουτρών. Κάθε χρόνο πολλοί πιστοί προσέρχονται για να προσευχηθούν και να θαυμάσουν το γραφικό αυτό εκκλησάκι του οικισμού μας το οποίο ως γνωστό έχει ανακαινισθεί πλήρως με την βοήθεια των κατοίκων και του Συλλόγου μας ο οποίος εξακολουθεί να το φροντίζει και να το συντηρεί κάθε χρόνο όπου είναι απαραίτητο.<br /><br /><br /><br /><br /></Typography>
                <Typography variant="h3" sx={theme.typography.typography2}><br /></Typography>
            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Θερινή χοροεσπερίδα και βράβευση των αριστούχων μαθητών</Typography>

                <img src={Image46} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image48} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>    Πραγματοποιήθηκε με επιτυχία η χοροεσπερίδα του Συλλόγου μας το Σάββατο <b>3 Αυγούστου 2013</b> στον ανακαινισμένο θερινό χώρο του ξενοδοχείου “ΚΟΥΝΤΟΥΡΟΥΔΙΑ”.<br />
                    Με την συμμετοχή των μελών και των φίλων του Συλλόγου περάσαμε μια υπέροχη βραδιά δίπλα στη θάλασσα, με θέα το Πέραμα και τα απέναντι χωριά της Γέρας. <br />
                    Κατά την διάρκεια της εκδήλωσης πραγματοποιήθηκε η καθιερωμένη βράβευση των αριστούχων μαθητών του τοπικού διαμερίσματος Λουτρών.<br />
                    Βραβεύτηκαν οι παρακάτω: <br />
                    <b>Αχλάδας Παναγιώτης</b>  του Ευστρατίου    από την    <b>Α!</b>    γυμνασίου<br />
                    <b>Σαράκης Ακίνδυνος</b>     του Δημητρίου      από την    <b>Α!</b>    γυμνασίου<br />
                    <b>Λίγκος Χαράλαμπος</b>     του Αργύρη          από την    <b>Β!</b>    γυμνασίου<br />
                    <b>Ταλιαντζής Ιωάννης</b>     του Σταύρου         από την    <b>Β!</b>   γυμνασίου<br />
                    <b>Δράκου Χαρίκλεια</b>        του Δημητρίου     από την    <b>Γ!</b>    γυμνασίου<br />
                    <b>Τασιοπούλου Παναγιώτα</b>  του Γεωργίου  από την    <b>Β!</b>   λυκείου<br />
                    <b>Παπαχρυσός Απόστολος</b>   του Ιωάννη       από την    <b>Β!</b>   λυκείου<br />
                    <b>Βατζάκα Χριστίνα</b>          του Γεωργίου      από την    <b>Γ!</b>    λυκείου<br />
                    <b>Ταλιαντζή Κατερίνα</b>      του Σταύρου        από την    <b>Γ!</b>   λυκείου<br /><br />

                    Επίσης δόθηκαν έπαινοι σε παιδιά του Δημοτικού Σχολείου Λουτρών γιατί πρώτευσαν σε διαγωνισμό ζωγραφικής και έκθεσης με θέμα το περιβάλλον ο οποίος είχε πραγματοποιηθεί στις 5 του περασμένου Ιουνίου.<br />
                    Οι μαθητές που διακρίθηκαν είναι οι παρακάτω.<br />
                    <b>Κέντζη Μαρία</b>                                                   από την   <b>Α!</b>   δημοτικού<br />
                    <b>Λέλεκας Φίλιππος</b>                                             από την   <b>Β!</b>   δημοτικού<br />
                    <b>Κοζπή Σταυρούλα</b>                                             από την   <b>Γ!</b>   δημοτικού<br />
                    <b>Λέλεκας Κωνσταντίνος</b>                                     από την   <b>Δ!</b>   δημοτικού<br />
                    <b>Κοζπή Ταξιαρχούλα </b>                                         από την   <b>Ε!</b>    δημοτικού <br />
                    <b>Αραμπατζή Παναγιώτα</b>                                     από την   <b>ΣΤ!</b> δημοτικού<br /><br />

                    Κατά την διάρκεια της εκδήλωσης πραγματοποιήθηκε η κλήρωση των δώρων της λαχειοφόρου αγοράς του Συλλόγου  και κέρδισαν οι παρακάτω αριθμοί.<br />
                    <b>1ος</b> λαχνός το νούμερο 846 κερδίζει το TABLET P/C<br />
                    <b>2ος</b> λαχνός το νούμερο 677 κερδίζει το ποδήλατο.<br />
                    <b>3ος</b> λαχνός το νούμερο 505 κερδίζει το DVD.<br />
                    <b>4ος</b> λαχνός το νούμερο 484  κερδίζει τον ανεμιστήρα.<br />

                </Typography>

            </Box>


            <ScrollToTopButton />





            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Διήμερες εκδηλώσεις του Συλλόγου, 22 και 23 Αυγούστου 2013</Typography>
                <img src={Image50} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image52} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />


                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκαν με μεγάλη επιτυχία οι διήμερες εκδηλώσεις (22 και 23 Αυγούστου) του Συλλόγου μας με τίτλο «<b>Όσο ζω … θα θυμάμαι</b>».<br />
                    Η εκδήλωση της Πέμπτης <b>22</b> Αυγούστου ξεκίνησε στις <b>19.30</b> με την τέλεση του Εσπερινού στο εκκλησάκι της Παναγίας και την περιφορά της εικόνας έως την αυλή του «<b>Μουσείου Προσφυγικής Μνήμης 1922</b>» <br />
                    Η εκδήλωση ήταν εστιασμένη σε μαρτυρίες προσφύγων πρώτης κυρίως γενιάς από κατοίκους της Σκάλας Λουτρών οι οποίοι είχαν έλθει μικρά παιδιά τις τραγικές για τον Ελληνισμό ημέρες του θέρους του <b>1922</b>.<br />
                    Η προβολή του σχετικού ντοκιμαντέρ διάρκειας <b>50</b> περίπου λεπτών που δημιουργήθηκε με πρωτοβουλία του μέλους του Συλλόγου μας κ. Ευθαλίας Τουρλή, ειδικά για αυτήν την εκδήλωση συγκίνησε ιδιαίτερα όχι μόνο τους κατοίκους της Σκάλας Λουτρών αλλά και όλους τους παρευρισκόμενους.<br />
                    Ιδιαίτερη συγκίνηση δημιουργήθηκε όταν ο κ. Στράτης Καραμάνης διάβασε αδημοσίευτα κείμενα σχετικά με την καταστροφή, γραμμένα από τον πατέρα του  Φίλιππο Καραμάνη.<br />
                    Στο τέλος της εκδήλωσης μοιράστηκε ο καβουρμάς (παραδοσιακό φαγητό με ρεβίθια και κρέας) σε όλους τους επισκέπτες συνοδευόμενο με ένα ποτήρι κρασί.<br />
                    Η βραδιά έκλεισε στις 12 τα μεσάνυχτα συντροφιά με παραδοσιακά μικρασιάτικα τραγούδια από την ορχήστρα της Παράλου.<br />
                    Την επομένη το πρωί 23-8-2012 τελέσθηκε η καθιερωμένη Θεία Λειτουργία με αρτοκλασία στην Παναγιά την Αψηλή με την παρουσία πολλών κατοίκων από την Σκάλα Λουτρών, τα Λουτρά και το Πέραμα της Γέρας.<br />
                </Typography>

            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Παναγιά η Αψιλή</Typography>
                <img src={Image54} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image56} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Πάνω από το Συνοικισμό βρίσκετε το εκκλησάκι της Παναγιάς της Αψιλής, έτσι την ονόμαζε η γιαγιά μου η Αναστασία Κατρακίλη. <br />
                    Η Αναστασία γεννήθηκε στις Φώκιες Μικράς Ασίας και είχε μιαν αδελφή και έναν αδελφό. Η Αναστασία, αγάπησε κάποιο παλικάρι το οποίο πήγε στον πατέρα της και την ζήτησε αλλά εκείνος δεν την έδωσε και έτσι το παλικάρι έφυγε στην Αμερική.<br />
                    Η Αναστασία δεν παντρεύτηκε ποτέ  και όταν πέθαναν οι γονείς της έμενε με την αδελφή της Ευαγγελία και το γαμπρό της Αντώνη και βοηθούσε στο μεγάλωμα των τεσσάρων παιδιών τους. Όταν έγινε ο διωγμός το 1922 έχασε τον αδελφό της και την ανεψιά της Βαρβάρα, δεν μπόρεσε να τους βρει και όπως μου έλεγε θα τους σκότωσαν οι Τσέτιδες.<br />
                    Η ανεψιά της ήταν πολύ ωραία και υπέθετε ότι ίσως να την πήραν οι Τούρκοι, το είχε μεγάλο καημό. <br />
                    Ο γαμπρός της ο Αντώνης όταν έγινε ο διωγμός τους έβαλε όλους στη βάρκα και έφτασαν στο Πέραμα της Γέρας όπου και μένανε. Εκεί όμως αρρώστησε ο μικρός Νικολάκης και πέθανε. Τότε φοβήθηκαν για τα άλλα παιδιά μπήκαν στη βάρκα και πέρασαν απέναντι στο Καμάρι όπου βρήκαν τον πύργο του Σιμωνίδη και κάθισαν εκεί μέχρι που χτίστηκε ο Συνοικισμός.<br />
                    Η Αναστασία άρχισε να ανεβαίνει στο βουνό και μέσα στα βράχια είδε το εικόνισμα της Παναγιάς με ένα καντηλάκι. Πήγαινε κάθε μέρα εκεί και το ίδιο έκαναν και άλλες γυναίκες που έμεναν στο Καμάρι.<br />
                    Όταν έγινε ο Συνοικισμός τους δώσανε ένα σπίτι το οποίο σήμερα κατοικείτε από την οικογένεια Σκριβάνου. Η Αναστασία έμενε στη Σκάλα μαζί με τον Χρήστο, τη Βασιλική και τα τέσσερα παιδιά τους. Εγώ πήγαινα στον παππού και ανέβαινα στην εκκλησιά τακτικά και όταν την ρώτησα γιατί γράφει η ταμπέλα που υπήρχε εκεί το όνομά σου, σε ένα άσπρο σανίδι έγραφε «Αναστασία Κατρακίλη 1932», εκείνη μου είπε ότι τα ποιο βαριά υλικά τα ανέβασα εγώ και για να με τιμήσουν το έβαλαν εκείνοι που την έχτισαν. Βοήθησαν τότε όλοι οι πρόσφυγες και αυτό είναι το εκκλησάκι της προσφυγιάς. Ίσως από τα βάρη που σήκωνε είχε πετάξει στην πλάτη της ένα εξόγκωμα  και είχε γεράσει πριν την ώρα της.<br />
                    Όταν ήρθε από την Αμερική εκείνος που την αγαπούσε ζήτησε να την δει, εκείνη όμως δεν πήγε. <br />
                    Εκεί που μέναμε, στο σπίτι μας στη Σκάλα Λουτρών φαινόταν από μακριά το εκκλησάκι και εκείνη έβλεπε τακτικά την Παναγιά να περπατά στην αυλή του … Να τώρα μπήκε μέσα έλεγε.<br />
                    Αυτά τα έγραψα στη μνήμη της Αναστασίας που μας βοήθησε στην ανατροφή μας και μου κρατούσε το χέρι την νύχτα όταν αρρώσταινα.<br /><br />
                    Γεωργία Μοσχονησιώτη, κόρη του Χρήστου.
                </Typography>

            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εκδηλώσεις μνήμης</Typography>
                <img src={Image58} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>
                    Την Κυριακή <b>15 Σεπτεμβρίου 2013</b> πραγματοποιήθηκαν στην πόλη της Μυτιλήνης οι εκδηλώσεις μνήμης για την γενοκτονία των Ελλήνων της Μικράς Ασίας στο πλαίσιο της 91ης επετείου από τη Μικρασιατική καταστροφή. <br />
                    Η Μικρασιατική καταστροφή καταγράφεται στις ποιο θλιβερές σελίδες του Ελληνικού Έθνους, στιγματίστηκε από την απώλεια ανθρώπων και πατρίδων, δημιούργησε πρόσφυγες και σκόρπισε δυστυχία. Αυτοί οι πρόσφυγες πληγωμένοι από τη βαρβαρότητα και τη φρίκη ήρθαν στην Ελλάδα αγωνίστηκαν για την επιβίωσή τους και συνέβαλαν με τους αγώνες τους στην ανανέωση και στην πρόοδο της Ελληνικής κοινωνίας. Κατά την διάρκεια των εκδηλώσεων ετελέσθη δοξολογία και αρτοκλασία στον ιερό ναό του Αγίου Νικολάου στην Επάνω Σκάλα και τον πανηγυρικό της ημέρας εκφώνησε ο  Διδάκτωρ Θεολογίας Α.Π.Θ. – Θεολόγος Καθηγητής στο Πρότυπο Πειραματικό Λύκειο Μυτιλήνης του Πανεπιστημίου Αιγαίου Αθανάσιος Καλαμάτας.<br />
                    Μετά την δοξολογία επακολούθησε επιμνημόσυνη δέηση και κατάθεση στεφάνων στον χώρο του αγάλματος της Μικρασιάτισσας Μάνας με την συμμετοχή της Στρατιωτικής Μουσικής της 98 ΑΔΤΕ. Το Δ.Σ. του Συλλόγου μας παραβρέθηκε σύσσωμο τόσο στην τέλεση της δοξολογίας στον Ιερό Ναό του Αγίου Νικολάου όσο και στην συνέχεια με κατάθεση στεφάνου στο άγαλμα της Μικρασιάτισσας μάνας μαζί με τους υπόλοιπους Συλλόγους και τις αρχές του τόπου.



                </Typography>

            </Box>


            <ScrollToTopButton />





            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εκδήλωση μουσικού τμήματος.</Typography>

                <img src={Image60} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image62} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />


                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε με επιτυχία την <b>Κυριακή 15-9-2013</b> το απόγευμα στον αύλιο χώρο του Μουσείου Προσφυγικής Μνήμης,  η ετήσια εκδήλωση του μουσικού τμήματος του Συλλόγου μας.<br />
                    Την εκδήλωση παρακολούθησαν με μεγάλο ενδιαφέρον οι γονείς των παιδιών και πολλά μέλη του Συλλόγου οι οποίοι είχαν τη δυνατότητα να παρατηρήσουν από κοντά την πρόοδο των παιδιών τους στη μουσική και να τα καμαρώσουν γι αυτό.<br />
                    Στο μουσικό τμήμα του Συλλόγου συμμετέχουν αυτή τη στιγμή 32 παιδιά ηλικίας από 7 έως 17 ετών και τα μαθήματα για όσους ενδεχομένως ενδιαφέρονται γίνονται στην αίθουσα του Συλλόγου στη Σκάλα Λουτρών κάθε Σάββατο και Τρίτη το απόγευμα.<br />
                    Στην εκδήλωση έπαιξαν κιθάρα και τραγούδησαν οι παρακάτω μαθητές και μαθήτριες.<br /><br />
                    Βατζάκα Μαριλένα,, Κουκώνης Ιωάννης, Κουκώνης Ραφαήλ, Μεταξάς Γεώργιος,  Λέλεκας Κωνσταντίνος, Λέλεκας Φίλιππος, Σπανός Χαράλαμπος, Καλιοντζής Θεόδωρος, Καλιοντζής Παναγιώτης, Κέντζης Μαρίνος, Γκύζα Κωνσταντίνα,  Γκύζα Γεωργία, Ντηνιακός Μαρίνος, Σαράκης Ακίνδυνος, Αλεξίου Γεώργιος, Αλεξίου Μαρία, Δράκου Δήμητρα, Αραμπατζής Μίλτος, Αραμπατζή Παναγιώτα, Μωυσιάδου Αλεξάνδρα, Κρητικός Γαβριήλ, Μύνηκνα Μαρία, Κανέλος Άγγελος, Γκαγκάνης Βασίλης, Λυρή Αναστασία και Λύρας Δημήτρης.

                </Typography>

            </Box>


            <ScrollToTopButton />



            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Βιβλιοπαρουσίαση</Typography>
                <img src={Image64} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Το Σάββατο <b>23 Νοεμβρίου 2013</b> έγινε η παρουσίαση του βιβλίου του κ. Παναγιώτη Παρασκευαϊδη με τίτλο «<b>Η Προσφυγιά του Β΄ διωγμού στη Λέσβο</b>».<br />
                    Η παρουσίαση έγινε στον χώρο του «Μουσείου Προσφυγικής Μνήμης 1922», μέσα σε κλίμα εγκαρδιότητας και συγκινησιακής φόρτισης παρουσία πολλών μελών μας,
                    Μικρασιατών του προσφυγικού οικισμού μας της Σκάλας Λουτρών.<br />
                    Το βιβλίο προλόγισαν και ανέλυσαν ο διδάκτωρ φιλολογίας κ. Δημήτριος Πατήλας καθώς και ο ίδιος ο συγγραφέας κ. Παναγιώτης Παρασκευαϊδης.<br />
                    Στο τέλος της παρουσίασης έγινε συζήτηση και απαντήθηκαν ερωτήσεις του κοινού από τον συγγραφέα.<br />
                    Εκδότης του βιβλίου είναι ο κ. Γεώργιος Ιορδάνου ο οποίος επίσης ήταν παρών στην εκδήλωση.<br />
                    Το βιβλίο περιέχει πλούσιο φωτογραφικό υλικό και σπάνια ντοκουμέντα από την διαβίωση των προσφύγων του 1922.<br /><br />

                </Typography>

            </Box>


            <ScrollToTopButton />





            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εορτή Αγίου Νικολάου</Typography>

                <img src={Image66} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <img src={Image68} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Με λαμπρότητα και με τη συμμετοχή των μελών του Συλλόγου και όλων των κατοίκων του χωριού μας, πραγματοποιήθηκαν στη Σκάλα Λουτρών, οι εκδηλώσεις για την εορτή του Αγίου Νικολάου, προστάτη των ναυτικών και των αλιέων.<br />
                    Συγκεκριμένα, με την παρουσία του εφημέριου Αγίου Γεωργίου Λουτρών και στρατιωτικού αγήματος από την μονάδα καταδρομέων του Περάματος, ετελέσθη στο παρεκκλήσιο του Αγίου Νικολάου της Σκάλας Λουτρών, η Θεία Λειτουργία και στη συνέχεια έγινε η λιτάνευση της εικόνας (του Αγίου Νικολάου, φερμένη το 1922 από τον Τσεσμέ της Μ. Ασίας), με κατεύθυνση την νέα προβλήτα του λιμανιού της Σκάλας Λουτρών. Μετά την ευλογία των άρτων ο ιερέας και το στρατιωτικό άγημα επιβιβάσθηκαν στη βάρκα του κ. Ευστράτιου Καραντώνη από την Κουντουρουδιά  και κρατώντας την εικόνα του Αγίου την περιέφεραν, ρίχνοντας το στεφάνι που την πλαισίωνε στην θάλασσα και ευχόμενοι ήρεμα ταξίδια για τους ναυτικούς και τους αλιείς μας.<br />
                    Μετά το πέρας των θρησκευτικών εκδηλώσεων οι παριστάμενοι κατέληξαν στην Βιβλιοθήκη του Συλλόγου όπου προσφέρθηκαν διάφορα εδέσματα και καφές, από τις νοικοκυρές του οικισμού μας. Και του χρόνου με υγεία.<br />
                </Typography>

            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα βιβλιοθήκης</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Με γοργό ρυθμό εμπλουτίζετε η βιβλιοθήκη του Συλλόγου μας και υπάρχει αρκετό ενδιαφέρον από τα μέλη μας που την επισκέπτονται για να δανειστούν διάφορα βιβλία. Κατά το τρέχον έτος προστέθηκαν πολλά νέα βιβλία, όλα δωρεές των μελών και των φίλων του Συλλόγου μας. Όλα τα βιβλία είναι καταχωρημένα σε ηλεκτρονικό υπολογιστή για την εύκολη διαχείριση τους.<br />
                    Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα προβλήματα που αποτελούν  τη ζωή του σύγχρονου ανθρώπου και τοποθετεί τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και φανατισμούς.<br /><br />

                    <u>Δωρεά βιβλίων στο Σύλλογό μας</u><br />
                    Ευχαριστούμε θερμά  τους  κ.κ. Παυλάκη Μάριο, Πελεκάνο Θεόδωρο, Παπαχρυσό Γεώργιο, Αντωνόπουλο Κωνσταντίνο, Μαντό Δημήτριο, Δεληγιάννη Νικόλαο, Χλίμπο Παναγιώτη, Χατζηδήμο Αλέξανδρο, Πατανό Ιωάννη και Όλγα Ιωάννου – Βαλειάδη για τα βιβλία που προσέφεραν στη βιβλιοθήκη του Συλλόγου μας.<br /><br />
                    Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα χρειάζονται να μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας η οποία ήδη στεγάζεται στο καινούργιο κτίριο που κατασκευάσαμε στη Σκάλα Λουτρών και που αυτή τη στιγμή τα βιβλία που διαθέτει ξεπερνούν τις 6.500.

                </Typography>

            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Μουσικό τμήμα</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Λειτουργεί κάθε <b>Σάββατο</b> και <b>Τρίτη</b> από τις <b>2 το μεσημέρι μέχρι τις 7 το απόγευμα</b> και αυτή την περίοδο φοιτούν 32 παιδιά σχολικής ηλικίας τα οποία μαθαίνουν κιθάρα. Τα μαθήματα εκτός από πρακτικά είναι και θεωρητικά έτσι ώστε τα παιδιά να αποκτούν γερές βάσεις και να συνεχίσουν μελλοντικά τις σπουδές τους στην μουσική εφ όσον το επιθυμήσουν. Η μηνιαία συνδρομή είναι 20 ευρώ και όσοι ενδιαφέρονται για τα παιδιά τους μπορούν να έρθουν σε επικοινωνία με τον πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό στο τηλ. 69746.40419 για περισσότερες πληροφορίες. Δάσκαλος είναι ο κ. Μολυβιάτης Γρηγόρης ο οποίος επί πολλά χρόνια έχει προσφέρει εξαιρετικό έργο.
                </Typography>

            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Ευχαριστήρια</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Ευχαριστούμε θερμά τους παρακάτω.<br />

                    * Τους Ευάγγελο Γρηγορέλλη, Μιχάλη Μεταξά και Παντελή Ντίνια, για τις εργασίες που πρόσφεραν στην ανακαίνιση του WC του Συλλόγου χωρίς αμοιβή, καθώς και τον κ. Γεώργιο Λημναίο.<br />
                    *Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες του Συλλόγου καθώς και όσους προσφέρουν εθελοντικώς την εργασία τους.<br />
                    *Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο μας.<br />
                    *Ιδιαιτέρως ευχαριστούμε το συγχωριανό μας Δημήτρη Γρηγορέλλη που από ιδρύσεως του Συλλόγου, μας έχει παραχωρήσει αφιλοκερδώς το πατρικό του σπίτι στη Σκάλα Λουτρών για την στέγαση  δραστηριοτήτων του Συλλόγου μας.<br />

                </Typography>

            </Box>


            <ScrollToTopButton />





            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Οικονομικές ενισχύσεις</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.<br /><br />

                    Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.<br /><br />

                    Δεσπότου Έλλη                      200 ευρώ<br />
                    Σεβαστός Δημήτριος              100 ευρώ<br />
                    Καραμάνης Ευστράτιος           100 ευρώ<br />
                    Κατανάκης Μιχάλης               50 ευρώ<br />
                    Θωμαϊδης Ελευθέριος            50 ευρώ<br />
                    Παπαχρυσός Ιωάννης             50 ευρώ<br />
                    Λεωνιδοπούλου Μυρσίνη       50 ευρώ<br />
                    Γεωργιάδου Ιφιγένεια              40 ευρώ<br />
                    Αγιακάτσικας Παναγιώτης       35 ευρώ<br />
                    Πετράκη Μαρίνα                       30 ευρώ<br />
                    Λευκαδίτης Παναγιώτης           25 ευρώ<br />
                    Fisher Aec/pa                             10 ευρώ<br />
                    Guesnay Geweviene                     5 ευρώ<br /><br />

                    Κινικλή Τούλα   50 ευρώ στη μνήμη του πατέρα της Γεωργίου.<br />
                    Κλήμη Μερόπη  50 ευρώ στη μνήμη του συζύγου της Αριστείδη.<br />
                    Μακρή Βασιλεία 40 ευρώ στη μνήμη του αδελφού της Ευάγγελου Νταή.<br />
                    Περγαμηνού Γαρουφαλιά  20 ευρώ στη μνήμη του συζύγου της Ευστρατίου.<br /><br />

                    Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός  για αναβάθμιση του οικισμού μας , έτσι ώστε να μπορέσουμε να ανταποκριθούμε στο βαρύ φορτίο των υποχρεώσεων που έχουμε αναλάβει και να ολοκληρώσουμε τους στόχους μας σε σύντομο χρονικό διάστημα.
                </Typography>

            </Box>


            <ScrollToTopButton />




            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τα ημερολόγιά μας 2004 – 2013</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}><b>10</b> χρόνια, <b>10</b> εξαιρετικά συλλεκτικά ημερολόγια του Συλλόγου μας, που έγιναν ανάρπαστα και κοσμούν τις βιβλιοθήκες πολλών μελών αλλά και πολλών φίλων μας.<br />
                    Δυστυχώς όμως για φέτος η οικονομική κρίση χτύπησε και τη δική μας πόρτα με
                    αποτέλεσμα να μην είναι εφικτό λόγω κόστους να συνεχιστεί αυτή η προσπάθεια.<br />
                    Ελπίζουμε να είμαστε σε θέση να επανέλθουμε σύντομα σε αυτή τη δραστηριότητά
                    μας.<br /><br />
                    Ευχαριστούμε θερμά όλους όσους είχαν βοηθήσει στο παρελθόν... θα τους χρειαστούμε και στο μέλλον.<br />
                    Χρόνια Πολλά και Ευτυχισμένο το Νέο Έτος σε όλα τα μέλη και τους φίλους του Συλλόγου μας.<br />
                </Typography>

            </Box>


            <ScrollToTopButton />


        </>
    )
}

export default D10_drastiriotites_2013