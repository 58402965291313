import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, Grid } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import { commonBoxStyles } from "../components/CommonStyles";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Footer() {
  return (
    <>
      <Box sx={{ ...commonBoxStyles }}>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center" // Ensure vertical alignment
          sx={{ marginTop: "0em", marginLeft: "0em", marginRight: "0em" }}
        >
          {/* // 1. */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid
                item
                sx={{
                  textDecoration: "none",
                  color: "blue",
                  textAlign: "center",
                }}
              >
                2006-2024 Σχεδίαση, Ανάπτυξη:{" "}
                <Box
                  component="a"
                  href="https://www.linkedin.com/in/konstantinos-katsavaros-72b18619b/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    color: "blue",
                    textDecoration: "none",
                  }}
                >
                  Κατσάβαρος Κωνσταντίνος <LinkedInIcon sx={{ ml: 0.5 }} />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* // 2. */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid
                item
                component="a"
                href="https://www.facebook.com/groups/70049541214"
                rel="noopener noreferrer"
                target="_blank"
              >
                <IconButton>
                  <FacebookIcon sx={{ color: "blue" }} />
                </IconButton>
              </Grid>

              <Grid
                item
                component="a"
                href="http://www.twitter.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <IconButton>
                  {/* <TwitterIcon /> */}
                </IconButton>
              </Grid>

              <Grid
                item
                component="a"
                href="http://www.instagram.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <IconButton>
                  {/* <InstagramIcon /> */}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Footer;
