import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g9/skala_loutron_1937.jpg";

function G9_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Σκάλα Λουτρών - Ένα προσφυγικό χωριό" />
      </Box>

      
      <Box sx={{ ...commonBoxStyles }}>
	
	<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Ο ξεριζωμός του ’22 από τη μικρασιατική Πατρίδα, έφερε ένα μεγάλο αριθμό προσφύγων στη λεσβιακή γη.
	Μια μικρή ομάδα από αυτούς, εγκαταστάθηκε στην περιοχή των Λουτρών. Η εγκατάσταση αυτή έγινε μέσα στο χωριό Λουτρά, αλλά και σε παλιές εγκαταλειμμένες 
	εμπορικές αποθήκες και αγροικίες (ντάμια) στη Σκάλα Λουτρών η οποία ήταν ακατοίκητη περιοχή με υποτυπώδες λιμάνι και δύο ελαιοτριβεία. Η υφιστάμενη εγκατάσταση 
	διατηρήθηκε μέχρι το 1930. Στο διάστημα αυτό, οικοδομήθηκαν από το κράτος σε μια ανοιχτή έκταση με την επωνυμία "Βατσίνα", 25 σπίτια των δύο δωματίων και 
	παραχωρήθηκαν σε 25 μικρασιατικές οικογένειες  το 1931. Έτσι στην περιοχή δημιουργείται ο οικισμός της Σκάλας Λουτρών, ένας καθαρά προσφυγικός οικισμός που στο 
	λόγο των κατοίκων του φέρει ακόμα την ονομασία "ο συνοικισμός".</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Τόποι καταγωγής των προσφύγων της ευρύτερης περιοχής των Λουτρών ήταν ο Μαρμαράς Αϊδινίου, ο Τσεσμές (Κρήνη), η Αγία Παρασκευή, τα Αλάτσατα και το Εγγλεζονήσι 
	της Ερυθραίας, το Σεβδίκιοϊ της Σμύρνης, οι Φώκαιες, το Τσανταρλί, το Δικελί, το Ατζανός, οι Σααντζήδες της περιοχής Περγάμου και το Αϊβαλί.
	</Typography>	

	<Typography variant="h3" sx={theme.typography.typography2}>Οι οικογένειες  και τα άτομα που εγκαταστάθηκαν στη Σκάλα Λουτρών και δημιούργησαν το <b>"συνοικισμό"</b> ήταν  οι:
    <br/><br/>

	<List>
	<ListItem>Αδαλής Παντελής και Βαγγελιώ και τα παιδιά τους Τριανταφυλλιώ και Γιωργίτσα (Φώκαιες)</ListItem>
	<ListItem>Βαλαχής  Σταυρής και Σταυρούλα (Αγία Παρασκευή Εγγλεζονησίου) </ListItem>
	<ListItem>Βατζάκας Νικολής και Μαρία και τα παιδιά τους Σοφία, Γαρυφαλιώ (Τσεσμές)</ListItem>
	<ListItem>Βατζάκας Ηρακλής και Παρασκευή και το παιδί τους Μαρία (Τσεσμές)</ListItem>
	<ListItem>Βεύγα Άννα και το παιδί της Παρίσος (Μαρμαράς-Αϊδινίου)</ListItem>
	<ListItem>Γιούσου Αθανάσης και Μαντώ (Φώκαιες)</ListItem>
	<ListItem>Καπετανέλη Καλιόπη και τα παιδιά της Μαρία, Νικόλας, Γεωργία, Χριστίνα (Φώκαιες)</ListItem>
	<ListItem>Καμπέρης Αντώνης και Βασιλεία  και τα παιδιά τους Μαρία, Ξενοφώντας, Παναγιώτης, Γιωργίτσα(Φώκαιες)</ListItem>
	<ListItem>Καράμπελας Δημήτρης και Τασώ και τα παιδιά τους Μάρκος και Βασίλης (Φώκαιες)</ListItem>
	<ListItem>Κερεντζής Νικόλας και Κυριακούλα και τα παιδιά τους Γιάννης και Στεφάνα (Φώκαιες)</ListItem>
	<ListItem>Κοκκίνου Ζαμπετούλα και το παιδί της Τάσουλης (Φώκαιες)</ListItem>
	<ListItem>Κουτζαγιώτης Μανόλης και Καλιόπη και το παιδί τους  Κομνηνός (Δικελί)</ListItem>
	<ListItem>Κατρακύλη Στασώ (Φώκαιες)</ListItem>
	<ListItem>Κουγιουμτζή Πολυξένη με τη μητέρα της Ελένη και το παιδί της Μαρία (Φώκαιες)</ListItem>
	<ListItem>Κρητικός Βασίλης και Ειρήνη και το παιδί τους Παναγιώτης (Φώκαιες)</ListItem>
	<ListItem>Κρητικός Νικολάκης (Φώκαιες)</ListItem>
	<ListItem>Μακρόγλου Αναστάσιος (Φώκαιες)</ListItem>
	<ListItem>Ματές Διαμαντής και Αγγέλα και τα παιδιά τους Χρήστος, Στέλια, Μαρίτσα, Γιώργος, Ιουλία (Τσεσμές και Εγγλεζονήσι)</ListItem>
	<ListItem>Μοσχονησιώτης Αντώνης και Βαγγελιώ και τα παιδιά τους Χρήστος και Γιώργος (Φώκαιες)</ListItem>
	<ListItem>Μοσχονησιώτης  Κωστής και Αννίτσα και το παιδί τους Νίκος (Φώκαιες)</ListItem>
	<ListItem>Μοσχονησιώτης Μιχάλης και Καλιόπη και τα παιδιά της Αρχοντία, Ζαφειρώ, Γιώργος (Φώκαιες)</ListItem>
	<ListItem>Μοσχονησιώτη Πελαγία και τα παιδιά της Βαρβάρα, Θάνος, Στέλιος (Φώκαιες)</ListItem>
	<ListItem>Μοσχονησιώτης Τάσος και Τριανταφυλλιά (Φώκαιες)</ListItem>
	<ListItem>Μπατζίνας Παλάσης και Ομορφιά και το παιδί τους Δέσποινα (Φώκαιες)</ListItem>
	<ListItem>Μπουγάς Μανόλης και Παρασκευώ και το παιδί τους Μαρία (Τσεσμές)</ListItem>
	<ListItem>Μουντής Αντώνης και Δέσποινα και τα παιδιά Βενέτα, Κατίνα, Τριαντάφυλλος (Μαρμαράς-Αϊδινίου)</ListItem>
	<ListItem>Μακρής Στρατής (Τσανταρλί)</ListItem>
	<ListItem>Ντήνιας Γιώργος και Μαριγί και τα παιδιά τους Ντουάρης, Κυριάκος, Μιχάλης, Αρχοντούλα (Εγγλεζονήσι)</ListItem>
	<ListItem>Ντινιακός  Στέλιος και Σεβασμία και τα παιδιά τους Κώστας, Βασιλεία, Αργυρώ (Φώκαιες)</ListItem>
	<ListItem>Παλάσης Γιάννης και Ελένη και τα παιδιά τους Καραφούλης, Παναγιώτα, Μαρία (Φώκαιες)</ListItem>
	<ListItem>Παπαμακαρίου Δυσσέας και Μαριγί (Φώκαιες)</ListItem>
	<ListItem>Πορτοκάλης Στέλιος και Βαγγελιώ  και το παιδί τους Νίκος (Φώκαιες)</ListItem>
	<ListItem>Σαρημπακάλη Γεωργία και Κλεάνθη (Φώκαιες)</ListItem>
	<ListItem>Ταχταλής Θοδωρής και τα παιδιά του Μυρσίνη, Χαρίκλεια, Σταύρος, Φώτης (Δικελί)</ListItem>
	<ListItem>Τσικμής Κώστας (Ατζανός)</ListItem>
	<ListItem>Φραγκουλάκης Μανόλης και Βαγγελιώ και το παιδί τους Κική (Τσεσμές)</ListItem>
	<ListItem>Φραγκουλάκης  Νικολής και Γραμματία και τα παιδιά τους Παρασκευή, Μιχάλης (Τσεσμές)</ListItem>
	<ListItem>Φραγκουλάκης Φραγκούλης και το παιδί του Μιχάλης (Τσεσμές)</ListItem>
	<ListItem>Φραντζέσκος Αναστάσιος και Βενετία και το παιδί τους Γιώργος (Φώκαιες)</ListItem>
	<ListItem>Χατζηαργυρίου  Δημήτρης και Ρηνιώ και τα παιδιά τους Μαρία και Κωνσταντίνος (Τσεσμές)</ListItem>
	</List>
    </Typography>	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Συνυπολογίζοντας και τα άτομα που δεν είχαν μικρασιατική καταγωγή μαζί με τα παιδιά δεύτερης γενιάς που είχαν γεννηθεί, το 1931 ο πρώτος 
	οικισμός της Σκάλας Λουτρών θα πρέπει να κατοικήθηκε από 160 περίπου κατοίκους. Σήμερα απαριθμεί γύρω στους 300, διαθέτει κοινό τοπικό συμβούλιο με το 
	χωριό Λουτρά και ανήκει διοικητικά στο Δήμο Μυτιλήνης.</Typography>	
	
	</Box>
	
	 <ScrollToTopButton />


    </>
  );
}

export default G9_mikrasiatika;
