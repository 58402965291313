import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/Hmerologia_p7/image001.jpg";
import Image02 from "../assets/images/Hmerologia_p7/image002.jpg";
import Image03 from "../assets/images/Hmerologia_p7/image003.jpg";
import Image04 from "../assets/images/Hmerologia_p7/image004.jpg";
import Image05 from "../assets/images/Hmerologia_p7/image005.jpg";
import Image06 from "../assets/images/Hmerologia_p7/image006.jpg";
import Image07 from "../assets/images/Hmerologia_p7/image007.jpg";
import Image08 from "../assets/images/Hmerologia_p7/image008.jpg";
import Image09 from "../assets/images/Hmerologia_p7/image009.jpg";
import Image10 from "../assets/images/Hmerologia_p7/image010.jpg";
import Image11 from "../assets/images/Hmerologia_p7/image011.jpg";
import Image12 from "../assets/images/Hmerologia_p7/image012.jpg";
import Image13 from "../assets/images/Hmerologia_p7/image013.jpg";
import Image14 from "../assets/images/Hmerologia_p7/image014.jpg";
import Image15 from "../assets/images/Hmerologia_p7/image015.jpg";
import Image16 from "../assets/images/Hmerologia_p7/image016.jpg";
import Image17 from "../assets/images/Hmerologia_p7/image017.jpg";
import Image18 from "../assets/images/Hmerologia_p7/image033.jpg";


function P7_Hmerologia() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        <HomeIcon />
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ημερολόγιο 2010: Λογοτεχνικό ταξίδι στη Λέσβο του χθες." />
      </Box>


      
	
	
	<Box sx={{ ...commonBoxStyles }}>

    <img src={Image18}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>«Πουθενά, σε κανένα άλλο μέρος του κόσμου, ο Ήλιος και η Σελήνη δεν συμβασιλεύουν τόσο αρμονικά, δε μοιράζονται τόσο ακριβοδίκαια την ισχύ τους όσο επάνω σ’ αυτό 
	το κομμάτι γης που κάποτε, ποιος ξέρει σε τι καιρούς απίθανους, ποιος Θεός, για να κάνει το κέφι του, έκοψε και φύσηξε μακριά, ίδιο πλατανόφυλλο καταμεσής του πελάγους. 
	Μιλώ για το νησί που αργότερα, όταν κατοικήθηκε ονομάστηκε «Λέσβος» και που η θέση του όπως βλέπουμε σημαδεμένη στους γεωγραφικούς χάρτες, δε μοιάζει ν’ ανταποκρίνεται 
	και πολύ στην πραγματικότητα».</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Μ’ αυτά τα λόγια ο Οδυσσέας Ελύτης ξεκινά ένα απ’ τα πιο όμορφα δοκίμιά του που έχει τον τίτλο «Η Άλλη Λέσβος». Αυτός ο ευλογημένος τόπος με τις ξεχωριστές 
	επιδόσεις στην τέχνη, στην ποίηση, στο λόγο από την αρχαιότητα μέχρι σήμερα διακρίνεται για την παρουσία του στο πολιτιστικό γίγνεσθαι της χώρας. Στα χώματα αυτά 
	γεννήθηκαν και δημιούργησαν λογοτέχνες και καλλιτέχνες ξακουστοί σ’ όλο τον κόσμο. Μερικοί απ’ αυτούς όπως η Σαπφώ και ο Αλκαίος σημάδεψαν με το έργο τους τον τόπο 
	και το χρόνο αφήνοντας ποιητικό στίγμα εσαεί δροσερό και επίκαιρο.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Η ανεκτίμητη αυτή πνευματική παρακαταθήκη (Πιττακός, Αλκαίος, Σαπφώ, Τέρπανδρος, Αρίων, Κριναγόρας, Αλφειός κ.ά. κατά την αρχαιότητα, Χριστόφορος Μυτιληναίος, 
	Ιγνάτιος Αγαλλιανός κ.ά. την περίοδο του Βυζαντίου και της Τουρκοκρατίας), γνωρίζει άνθιση και δίνει μοναδικούς καρπούς τον 19ο αιώνα και τον 20ό αιώνα. Είναι η 
	περίοδος που δημιουργείται ο «Αιολικός κύκλος» και αργότερα η περίφημη «Λεσβιακή Άνοιξη». Ο σπουδαίος φιλόλογος και γραμματολόγος Γιώργος Βαλέτας στην «Επίτομη 
	Ιστορία της Νεοελληνικής Λογοτεχνίας» γράφει σχετικά:</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>«Ο Αιολικός κύκλος με κέντρο τη Μυτιλήνη και τα αιολικά παράλια (Κυδωνίες, Μοσχονήσια, Πέργαμος, όλη η σφύζουσα από αστικό πνεύμα περιοχή του Αδραμυτινού κόλπου) 
	έδωσε ποσοτικά και ποιοτικά στον αγώνα και στη λογοτεχνία του δημοτικισμού σημαντικώτατες δυνάμεις. Ήδη απ’ τα χρόνια της Νέας Σχολής, με το Βερναρδάκη, τον Καζάζη, 
	τον Καραμήτσα, τον Καμπά κ.ά. η Μυτιλήνη δίνει πρωτοπόρους στο πνευματικό κίνημα, από τα χρόνια όμως του Εφταλιώτη με ολοένα αυξανόμενη παραγωγικότητα σε ταλέντα και 
	έργα, ο Αιολικός κύκλος διευρύνεται, βγάζει μοναδικά για την αξία τους σατιρικά περιοδικά (Μυτιληνιός, Σκορπιός, Σκριπ, Βούρδουλας, Τρίβολος κλπ.), εφημερίδες μεγάλες 
	(Σάλπιγξ, Λέσβος), και τη μεγάλη φιλολογική επιθεώρηση «Χαραυγή» με διευθυντές δυο πρωτοπόρους Μυτιληνιούς, το Μανόλη Βάλλη και το Δημήτρη Αλβανό. <br/>
	Η «Χαραυγή» βάσταξε 
	πέντε χρόνια (1910 – 1914) και έγινε πανελλήνιο όργανο του δημοτικισμού, επιβλητικό σε όγκο και σε περιεχόμενο. Αργότερα συνέχισαν το έργο της άλλα πολλά περιοδικά, 
	ιδίως τα «Νιάτα» και τα «Λεσβιακά Γράμματα» του κριτικού Στράτη Παρασκευαΐδη. Σ’ όλη αυτή την κίνηση άσκησε τεράστια επίδραση η προσωπικότητα του Στράτη Μυριβήλη. 
	Η Αιολική Σχολή, συνεχίζοντας το έργο της και στις επόμενες δεκαετίες, έδωσε και στην ποίηση και στην πεζογραφία σημαντικώτατο έργο, που επιβλήθηκε πανελλήνια και 
	άνοιξε νέους δρόμους στην πεζογραφία του μεσοπολέμου. Κυριώτεροι εκπρόσωποί της στην ποίηση είναι ο Κώστας Κόντος με τα «Τραγούδια του Χωριάτη» και διάφορα 
	πεζογραφήματα, ο Θρασύβουλος Σταύρου με τη συλλογή «Δρόμοι και μονοπάτια» και τις μοναδικές μεταφράσεις του, ο Θείελπις Λεφκίας (Βρανάς Μπεγιάζης) ποιητής της 
	«Βαγγελιώς» και άλλων μικρών αριστουργημάτων, ο Αντώνης Πρωτοπάτσης, μεταφραστής του Baudelaire, αριστοτέχνης σ’ όλα του, φύση λαϊκώτατα καλλιτεχνική, ο Δημ. 
	Βερναρδάκης, ποιητής και μεταφραστής της «Ψάπφας», ο Κώστας Φριλίγγος, μεταφραστής του «Ιώβ» και άλλων έργων της Εβραϊκής φιλολογίας, ο λαογράφος Π. Ψάλτης, ο 
	Μ. Καλλοναίος, οι κριτικοί Χρύσανθος Μολίνος και Σ. Γ. Παρασκευαΐδης, ο Φ. Ανατολέας, ο Ασ. Πανσέληνος, ο Φίλων Οφερέτης (Μ. Στεφανίδης), Θ. Θεοδωρίδης, οι 
	πρωτοπόροι Δελήδες, Αριστείδης και Γιάννης, ο Β. Ελεγάς, και από τους νεώτερους ο Καρίπης, Λιάκος κι ο Οδυσσέας Ελύτης, ένας από τους κορυφαίους σύγχρονους Έλληνες 
	ποιητές, που έδωσε στη μοντέρνα ποίηση την ισορροπία και τη μορφή αληθινής τέχνης. Στη σάτιρα διακρίθηκε ο Στράτης Παπανικόλας με τον περίφημο «Τρίβολο». Ο ίδιος 
	έγραψε δυο περίφημα θεατρικά. Αλλά η δόξα  της Σχολής βρίσκεται στην πεζογραφία της, που πλούτισε με νέες μορφές και ανανέωσε τη νεοελληνική τέχνη με σημαντικώτατες 
	επιδράσεις στην παραπέρα εξέλιξή της. Οι κυριώτεροι πεζογράφοι της Αιολικής Σχολής είναι ο Στράτης Μυριβήλης, ο Ηλίας Βενέζης, ο Φώτης Κόντογλου, ο Στράτης Δούκας, 
	ο Κοσμάς Πολίτης, ο Κ. Κόντος, ο Κ. Μάκιστος, ο Δ. Αλβανός, ο Μ. Βάλλης, η Μαρία Κλωνάρη, η Σίτσα Καραϊσκάκη, ο Στράτης Αναστασέλλης, ο Ευστρ. Ευστρατιάδης, ο 
	Ν. Αθανασιάδης, ο Τάκης Χατζηαναγνώστου, ο Κώστας Γ. Βαλέτας κ.ά».</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Αλλά και ο Αντώνης Πρωτοπάτσης στην περίφημη ομιλία του το 1930 στη Φιλολογική Στέγη του Πειραιά με θέμα τη «Λεσβιακή Άνοιξη» ανάμεσα στα άλλα είπε: «Μ’ αν ήτανε 
	να μιλήσω για την Άνοιξη στη Λέσβο, θα το έκαμνα με ζωγραφιές. Και έχω χρόνια να περάσω την άνοιξη στον τόπο μου … «Λεσβιακή Άνοιξη» … έτσι μας αρέσει να ονομάζουμε 
	εκεί πέρα ένα άνθισμα από νέα ελπιδοφόρα ταλέντα στην τέχνη του λόγου. Και είναι τόσο φυσικό ένα τέτοιο φαινόμενο για όποιον γνωρίζει το νησί! Στη Λέσβο όποιος έχει 
	μάτια σπουδάζει την ομορφιά. Όποιος έχει ψυχή ζητά να την εκφράσει. Το τραγούδι στη Λέσβο είναι πάντα έτοιμο ν’ αντιλαλήσει, φτάνει να το επιτρέπουν οι καιροί. 
	Έτσι και τώρα».</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Αποφασίσαμε να αφιερώσουμε το φετινό ημερολόγιο σ’ αυτή την μεγάλη πολιτιστική μας περιουσία. Επιλέξαμε 12 Λέσβιους λογοτέχνες με έντονη προσφορά στην πνευματική 
	και κοινωνική ζωή της Λέσβου και της Ελλάδας τιμώντας όμως στο πρόσωπό τους όλους τους Λέσβιους δημιουργούς του παρελθόντος αλλά και του παρόντος. (Πρόθεσή μας είναι 
	το αφιέρωμα αυτό να συνεχιστεί και τα επόμενα χρόνια). Όλοι τους ανήκουν στον «Αιολικό κύκλο», όπως εύστοχα τον όρισε ο Γιώργος Βαλέτας και όλοι τους ανάμεσα στα 
	άλλα διακρίθηκαν και για την μεγάλη τους αγάπη για τον τόπο. Δύο από αυτούς, ο Τάκης Χατζηαναγνώστου και η Μαρία Αναγνωστοπούλου με παρουσία πάνω από μισό αιώνα 
	στα Ελληνικά Γράμματα και μ’ ένα έργο πολυσήμαντο αποτελούν και τον σύνδεσμο με τον 21ο αιώνα, με τη νέα γενιά, με τη συνέχεια της πνευματικής ζωής και δημιουργίας.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Από το πλούσιο και ποικίλο έργο τους επιλέξαμε λογοτεχνικά κείμενα που έχουν ως σημείο αναφοράς και κέντρο το νησί μας. Μια λογοτεχνική περιήγηση στην πόλη και 
	στα χωριά του χθες, στο τοπίο και στους ανθρώπους, σε πράγματα και καταστάσεις που άλλα έχουν αλλάξει τελείως και άλλα παραμένουν τα ίδια, αναλλοίωτα, ακίνητα. Σε 
	στιγμές δύσκολες αλλά και σε χαρούμενες, σε πάθη,  σε εσωτερικές γωνιές, σε φωτοσκιάσεις, σε ρεαλιστικές περιγραφές αλλά και σε παιχνιδίσματα της φαντασίας.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Τα κείμενα πλαισιώνονται και με αρχειακό φωτογραφικό υλικό, στιγμιότυπα ιστορικών στιγμών αλλά και απλών συμβάντων της καθημερινότητας, καθώς και τοπία, κτίσματα, 
	άνθρωποι όψεις και τεκμήρια μιας άλλης εποχής και αφορμή για συγκρίσεις, αναπολήσεις, στοχασμούς.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Στις τελευταίες σελίδες του ημερολογίου επιλέξαμε από το μνημειώδες βιβλίο του Παναγιώτη Νικήτα «Λεσβιακό Μηνολόγιο» μερικές λαογραφικές καταγραφές, ήθη και 
	έθιμα του τόπου μας, συνδεδεμένα με τον κύκλο του χρόνου, που νομίζουμε ότι χαρακτηρίζουν το νησί μας.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Με τις σκέψεις αυτές καλωσορίζουμε τον καινούργιο χρόνο και ευχόμαστε σε όλους υγεία, δύναμη και καλή ανάγνωση.</Typography>
	
    </Box>
	
	
	<ScrollToTopButton />
	
	
	
	<Box sx={{ ...commonBoxStyles }}>

	<Typography variant="h1" sx={theme.typography.typography1}>Λεσβιακό Μηνολόγιο</Typography>	
	<img src={Image18}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το λαογραφικό υλικό που ακολουθεί έχει ανθολογηθεί από το «Λεσβιακό Μηνολόγιο» του Παναγιώτη Νικήτα (Μυτιλήνη 1889 – 1965). Η εξαιρετική αυτή λεσβιακή λαογραφική 
	μελέτη κυκλοφόρησε για πρώτη φορά το 1953 από την Εταιρεία Λεσβιακών Μελετών και εξαντλήθηκε πολύ γρήγορα, αφού δέχθηκε πλήθος εγκωμιαστικών κριτικών από  έγκριτους 
	κριτικούς και διακεκριμένους επιστήμονες όπως ο Δημήτρης Λουκάτος, ο Γεώργιος Μέγας, ο Ιωάννης Κακριδής, ο Φαίδωνας Κουκουλές, ο Ευάγγελος Παπανούτσος κ.ά.</Typography>	
	<Typography variant="h3" sx={theme.typography.typography2}>Ο τόμος επανεκδόθηκε το 2001 με τη μέθοδο της φωτομηχανικής ανατύπωσης, συμπληρωμένος με εκτενές βιογραφικό σημείωμα του συγγραφέα επιμελημένο από τον Κώστα 
	Μίσσιο καθώς και με αναλυτικό ευρετήριο τοπωνυμίων και ανθρωπονυμίων  το οποίο συνέταξε ο Παναγιώτης Μιχαηλάρης.</Typography>	
	<Typography variant="h3" sx={theme.typography.typography2}>Η μελέτη του Παναγιώτη Νικήτα υποβλήθηκε στο σχετικό διαγωνισμό που είχε προκηρύξει ο Δήμος Μυτιλήνης και βραβεύτηκε από την επιτροπή που συγκροτούσαν οι 
	Δημ. Γρηγ. Βερναρδάκης, Χρύσανθος Μολίνος και Βασίλειος Αρχοντίδης. </Typography>	
	<Typography variant="h3" sx={theme.typography.typography2}>Στην εισηγητική τους έκθεση γράφουν ανάμεσα στα άλλα: «Ο διαγωνισμός του Δήμου Μυτιλήνης μας δίνει σήμερα μια αξιόλογη λαογραφική συλλογή, που μας 
	φέρνει στο φως τόσους και τόσους κρυμμένους θησαυρούς απ’ τον απέραντο πλούτο της λαϊκής ψυχής, όπου γλώσσα, παραδόσεις, τραγούδια, ήθη και έθιμα, φανερώματα του 
	ψυχικού και κοινωνικού βίου του λεσβιακού λαού, συγκεντρώνονται μέσα στο “Λεσβιακό Μηνολόγιο” του Νικήτα, με μεθοδική τάξη και πληρότητα…» <br/><br/>
	Παναγιώτης Σκορδάς</Typography>
	
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ιανουάριος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Κατά τα χαράματα της παραμονής των Φώτων, οι γυναίκες τραβούσαν στις εκκλησιές: «στου μ’κρό τουν αγιασμό». Κι’ όταν τέλειωνε ο αγιασμός, γυρίζανε στα σπίτια τους 
	με μαστραπάδες και μποκάλια γεμάτα αγιασμό. Όλη η φαμίλια έπρεπε να πιη πρωί πρωί αγιασμό, κι’ όσος πέρσευε, τον ραντίζανε στο σπίτι, στ’ αμπέλια και στα χωράφια τους, 
	ψέλνοντας το τροπάρι «Εν Ιορδάνη…». Όλη την ημέρα δεν τρώγανε ούτε λάδι και καθότανε νηστικοί, ίσαμε να περάση ο παπάς από τα σπίτια και να φιλήσουν το Σταυρό.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Την παραμονή των Φώτων οι νοικοκυρές ζυμώνουν και ανοίγουν φύλλα, για να κάνουν τη μπλατζέτα, που την τηγανίζουν ύστερα και τη βουτούν στο μέλι, και τη ραντίζουν 
	με ζάχαρη ψιλή, καρύδια ή αμύγδαλα κοπανισμένα και κανέλλα. Είναι το γλύκισμα, που έρχεται δεύτερο απ’ τα φοινίκια της Πρωτοχρονιάς. </Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Το βράδυ θα βγούνε τα παιδιά στα κάλαντα, όπως και την παραμονή της Πρωτοχρονιάς. Από μέρες μαθαίνουν τα πρόσωπα του κάθε σπιτιού, πόσα θηλυκά, πόσα αρσενικά και 
	ταιριάζουν απάνω στο σκοπό της Πρωτοχρονιάς τα καλλίτερα δίστιχα.<br/>
	[…] Ανήμερα τα Φώτα, ύστερα από την τελετή του Αγιασμού, θα ρίξουν το Σταυρό στη θάλασσα.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Ο κόσμος ξεχύνεται στο μουράγιο. Βάρκες και καΐκια, στολισμένα, είναι κατάφορτα από κόσμο. Παπάδες και ψαλτάδες τραβούνε προς τη θάλασσα ψέλνοντας το «Εν Ιορδάνη». 
	Ο Δεσπότης ή ο παπάς αφήνει να πετάξη ένα περιστέρι (είναι «το πνεύμα εν είδει περιστεράς…») και κατόπι ρίχνει το Σταυρό στη θάλασσα. Πολλοί βουτούνε στη θάλασσα 
	κι’ όποιος πιάσει το Σταυρό, θάναι ο τυχερός της χρονιάς. Ο Δεσπότης θα του δώση το μπαξίζ (δώρο) κ’ ύστερα θα γυρίση δίσκο στα κέντρα να μαζέψη κι’ άλλα λεφτά.
	Από αυτή τη μέρα αγιάζουν τα ύδατα, τελειώνουν τα δρύματα, φεύγουν οι «Καλ’κατζάρ’» και χάνονται τα φαντάσματα.</Typography>
	
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Φεβρουάριος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Η «Κριγιατ’νή» και η «Τυρινή». Αυτές οι τις δυο Κυριακές γινότανε μεγάλη κίνηση. Όλοι μας γινόμαστε «μουτσούνες». Απ’ την παραμονή ρωτούσε ο ένας τον άλλο:
	«Ε, θα γίνουμι φέτου;» κι’ απαντούσε ο άλλος: «Εμ’ είμιστι πούμιστι μουτσούνις, τι α γίνουμι;».</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Το απομεσήμερο βγαίναμε μασκαρεμένοι παρέες παρέες από δέκα, είκοσι, με το οργανάκι μπροστά. Γυρίζανε στους μαχαλάδες και στους κεντρικούς δρόμους και 
	στήνανε χορό. Χορευότανε οι ρεμπέτικοι χοροί και οι ευρωπαϊκοί, με το απαραίτητο κορδονάκι. Ντυνόταν φουστανελάδες, Μακεδόνες με περικεφαλαίες και σπαθιά, 
	βλαχοπούλες, γύφτισσες, που βλέπανε τις μοίρες κ.λ.π.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Σε ανοιχτό μέρος ή σε τρίστρατα, οι μουτσούνες (κουδουνάτοι) κάνανε προχείρους θιάσους και δίνανε παραστάσεις από λαϊκά θεατρικά έργα, ένα μέρος από τον 
	Ερωτόκριτο, ή από τη θυσία του Αβραάμ κ.ά.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Άλλοι ντυνότανε ψαράδες, μ’ ένα καλαμίδι στο χέρι και μ’ ένα σύκο κρεμασμένο στην άκρη του σπάγγου, και ψαρεύανε ανθρώπους. Τα παιδιά ανοίγανε το στόμα τους 
	κι’ απάνω που πετυχαίνανε να χάψουν το σύκο, ο ψαράς έπαιρνε μια χούφτα πίτυρα απ’ το σακίδιό του και τα πετούσε στα μούτρα τους. Τα παιδιά τότε φωνάζαν: 
	«Δότι τουν τσ’ είνι ψαράς», και του ρίχνανε λεμονόκουπες. Όσοι τολμούσανε να εμφανίσουνε τύπους ηλιθίων, τρελλών, καμπούρηδων, αγκαστρωμένων γυναικών κ.λ.π. 
	τους επερίμενε βροχή από σάπια λεμόνια και πατάτες. Τα παιδιά φωνάζαν: «Αλέστα λιμουνιά τσι μια bουρτακαλιά». Ρύζι, νταρί, φασόλες και κάθε άλλο όσπριο, ήτανε 
	το ράντισμα, που αντιπροσώπευε το σημερινό κομφετί.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Τις αποκριές ερχόντανε αρκουδιάρηδες και στήνανε χορούς με τις αρκούδες και τις μαϊμούδες «τ’ς κουτσ’νόκουλ’» (κοκκινόκωλοι). Όταν περνούσαν οι αρκούδες, 
	οι γυναίκες βγαίνανε με τα ψαλίδια και κόβανε μαλλί από την προβιά της αρκούδας και τόχανε φυλαχτό για τις μεγάλες αρρώστιες, την πανούκλα, την χολέρα κ.λ.π.<br/>
	Στα κεντρικώτερα σημεία της πόλης, λαϊκοί σατιρικοί ποιητάδες, απαγγέλνανε αυτοσχέδια σατιρικά δίστιχα, που είχαν σχέση με πρόσωπα και πράγματα του τόπου και με 
	περιστατικά της καθημερινής ζωής, μέσα στο χρόνο. Πολλά απ’ αυτά τα δίστιχα απομένουν στο στόμα του λαού και γίνονται κτήμα του.</Typography>
	
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Μάρτιος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Του Ευαγγελισμού. «Τ’ Βγατζιλ’σμού», ο λαός τρώγει ψάρια και παστά. Στα ψαράδικα γίνεται καυγάς, ποιος θα πρωτοπάρη ψάρια, για να γιορτάση καλλίτερα.<br/>
	Άμα πλαγιάσουν κείνη τη βραδιά γυναίκα και άντρας και πιάσουν παιδί, το παιδί τους θα βγη σημαδεμένο: «σημειουμένου».<br/>
	Στο χωριό τον Μανταμάδο λένε: «Απισπιρού, έϊτσειν’ τ’ βραδιά, τα δέdρα θα στσύψιν να προυστσ’νήσιν, να φ’λήσιν τ’ γη. Τσι τσείν’ τ’ν ώρα π’ θα του δης αυτό, ό,τ’ 
	γυρέψ’ς θα σ’ του δώσ’ η Παναγιά».<br/>
	Του Ευαγγελισμού μας έρχονται τα χελιδόνια. Τα φέρνουν οι γερανοί «Οι γι ουρανοί», «οι βαρβαρόχ’νις».<br/><br/>
	
	<div class="dimotiki">
	Άμα δουν τους γερανούς λένε:<br/>
	Πάρτι τ’ν ουκνιά μας<br/>
	τσι φέρτι τ’ν αγληγουριά μας,<br/>
	πάρτι τ’ν αρρουστιά μας<br/>
	τσι φέρτι την υγειά μας.<br/><br/>
	
	
	Τα χελιδόνια, όταν έρχονται, λένε:<br/>
	Πάηκα τσ’ αφήκα<br/>
	σύκου τσι σταφύλ’<br/>
	σταυρό τσι λυχνιστήρ’<br/>
	τσ’ ήρτα τσι βρήκα φύτρ…<br/><br/>
	</div></Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Με το τραγούδι αυτό το χελιδόνι θέλει να πη, πως φεύγοντας από τον τόπο μας, άφησε σταφύλια και λυχνιστήρια και τώρα γυρίζοντας βρίσκει άνοιξη, φύτρες κ.λ.π.
	«Όποιους σκουτώσ’ χιλ’δόν’, πέφτιν τα μαλλιά τ’».</Typography>
	
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Απρίλιος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Ο Απρίλης είναι ο μήνας της πρασινάδας και των λουλουδιών. Γι’ αυτό ο λαός λέει: «Ήρτι η μήνας η γι Απρίλ’ς μι τ’ς πρασινάδις τυ iβ’νού τσι μι τ’ς λιακάδις», και: 
	<br/><br/>
	
	<div class="dimotiki">
	«Μι του έμπα τ’ Απριλιού <br/>
	να ξυπνάς πριν τουν ηλιού».<br/><br/>
	</div></Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Τα πρωταπριλιάτικα ψέματα δεν έχουν πέραση στον τόπο μας. Τα ψέματα εδώ τα λένε το μήνα Μάη.<br/>
	Μέσα στον Απρίλη γίνονται πολλές ζέστες και ο λαός τον βρίζει και τον καταριέται.<br/>
	«Η γι Απρίλ’ς να πριστή τσι να σκάσ’, που μας σκα απού τ’ ζέστ’». (Σίγρι)<br/><br/>
	
	<div class="dimotiki">
	Απρίλη Απρίλη κάματι<br/>
	τσι Μάη κατακαημένι<br/><br/>
	</div></Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Στον Απρίλη μέσα, άμα βλέπουμε πολλές κωλοφωτιές και μας έρτουν πολλές σουσουράδες, θα πη πως θάχουμε καλή χρονιά.<br/>
	[…] Από τη μέρα τ’ Αγιού Γιωργιού, ο λαός πετά από πάνου του τα χοντρά και μάλλινα ρουχικά του, γιατί έχει έρτη πια το καλοκαίρι. Το λέγει και το δοξαστικό 
	του Αγίου: «Ανέτειλε το έαρ, δεύτε ευωχηθώμεν».<br/>
	Στο τέλος του Απρίλη γίνεται το κλάδεμα, για να δυναμώνουν τα δέντρα. Οι γεωργοί βοτανίζουν, σκαλίζουν τα φυντάνια, τειαφίζουν τ’ αμπέλια τους και σπέρνουν 
	τα ρουβίθια τους, τα φασόλια, το καλαμπόκι κ.τ.λ. Την απριλιάτικη βροχή τη λένε διαμάντι.<br/></Typography>
	
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Μάης</Typography>	
	<Typography variant="h3" sx={theme.typography.typography2}>Την Πρωτομαγιά οι γυναίκες σηκώνονται πολύ πρωί, προτού βγη ο ήλιος, και ξυπνούνε όλη τη φαμίλια. Πρώτη τους δουλειά είναι να βάνουν γρήγορα κάτι στο 
	στόμα τους και κατά προτίμηση γλυκά ή γλυκίσματα, για να μην τους κουμπώση ο γάιδαρος, δηλ. να μην τους γελάση ο γάιδαρος. Γιατί κείνη τη μέρα ο γάιδαρος 
	ξυπνά πολύ πρωί, για να γιορτάση το Μάη, κι αρχίζει το αγκάρισμα. Όποιος ακούση το αγκάρισμα και δεν έχει βάνη τίποτε στο στόμα του, είναι γελασμένος, τον 
	έχει κουμπώση ο γάιδαρος.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Στα χωριά μελώνουνε κείνο το πρωί τα παιδιά με λογιώ λογιώ γλυκά, για να περάση ο Μάης γλυκός σαν το μέλι.<br/>
	Σ’ άλλα χωριά τρώνε το πρωί σκόρδα, για να μην τους γελάση ο γάιδαρος, ή βουτούνε στο κρασί παξιμάδι και τρώνε, για να μην χαλάση η φωνή τους και γίνη γαϊδουρίσια.<br/>
	Στα παλιά τα χρόνια, την Πρωτομαγιά την γιορτάζανε με μεγάλα γλέντια και μεταμφιέσεις. Θυμάμαι ολόκληρες παρέες να γυρίζουν μες στις γειτονιές, καβάλα πάνου σε 
	σαράντα γαϊδάρους και γαϊδάρες και με τα τραγούδια τους και με τα αγκαρίσματα των γαϊδάρων ξεσήκωναν ολόκληρους μαχαλάδες στο πόδι.<br/>
	Φορτωμένοι και στολισμένοι με αγριολούλουδα, αλυγαριές, αγριοκρομμύδες και αγκαθούρες, φωνάζανε: «Ποιος θέλ’ να πιάσ’ του μαγιόξ’λου;».<br/>
	[…] Το Μάη γίνονται τα μάγια. Βγαίνουν και νεράιδες τα βράδυα και παίζουν και γλεντούν στις βρύσες και στις χαβούζες. Βγαίνουν και του αγέρα τα δαιμόνια και σε 
	μαγεύουν και σε παλαβώνουν. Μέσα στις κατάρες που λένε οι γυναίκες είναι και τούτη: «Α, π’να σ’ έρτ’ τ’ Μαγιού τ’ αγέρ’» ή «Α, που να σ’ εύρ’ τ’ Μαγιού τ’ ανέμ’».<br/>
	Στα χωριά πιστεύουν πως το κακό τ’ αγέρι κάνει ακόμα και τα λάδια και τα κρασιά να παίρνουν βόλτες μέσα στα βαρέλια.</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ιούνιος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Το βράδυ της παραμονής τ’ αγιού Γιαννιού, παρέες-παρέες, από άντρες και γυναίκες μαζεύουν ξηρά χορτάρια και αγκάθια-αστοιβιές και τα βάζουν φωτιά στους δρόμους, 
	έξω από τα σπίτια τους. Σε κάθε γειτονιά όλοι, μικροί και μεγάλοι, κορίτσια και αγώρια, τόχουν σε καλό να πηδήξουν τα κάψαλα, όταν ανάψουν και βγάλουν μεγάλες 
	λόχες (φλόγες), γιατί έτσι φεύγουν όλες οι κακομοιριές και οι αρρώστιες. Πηδώντας, ρίχτουν στη φωτιά και μια βαρειά πέτρα και λένε: «Όσο βαρεί η πέτρα, να βαρή 
	τσ’ η κισέ μ’». Και πηδούν τρεις τέσσερες φορές προς όλες τις διευθύνσεις, προς την Ανατολή, Δύση, Νοτιά και Βοριά.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Άλλοι τα κάψαλα τ’ ανάβουν στις αυλές τους, κάτω από τα δέντρα τους, για να φύγουν τα κακά και οι αρρώστιες από τα δέντρα. Άλλοι πάλι, πηδώντας, ζητούνε και 
	κάποια χάρη από τον άγιο Γιάννη.
	[…] Άμα τελειώσουν τα κάψαλα, ετοιμάζεται ο κλήδονας, που θ’ ανοιχτή το πρωί. Έτσι δυο παιδιά είτε αγώρια, είτε κορίτσια, παίρνουνε τη νύχτα από τρεις βρύσες 
	«τ’ αμίληχτο νερό». Τα παιδιά αυτά πρέπει να μην είναι ορφανά και να προσέχουν την ώρα που κουβαλούν το νερό, να μη μιλήσουν κανένα που θα συναντήσουν στο δρόμο 
	τους. Το νερό αυτό το ρίχνουν μέσα σε μια γραγούδα (πήλινο δοχείο) και όσοι ή όσες θα πάνε το πρωί στον Κλήδονα, ρίχτουν από βραδύς μέσα στη γραγούδα τα σημάδια 
	τους, δαχτυλίδια, σκουλαρίκια, νομίσματα, χάντρα, κ.ά. Η γραγούδα θα σκεπαστή με κόκκινο πανί και θα μείνη όλη τη νύχτα έξω να την βλέπουν τ’ άστρα. Σ’ άλλα χωριά 
	τη βάζουν στο φούρνο, και σ’ άλλα κάτω από τα δέντρα ή ανάμεσα στα λουλούδια.<br/>
	Ο πρωί ή το απομεσήμερο, σε κάθε γειτονιά, μαζεύονται οι κοπέλλες στην αυλή ενός σπιτιού και ανοίγουνε τον Κλήδονα. Στη γιορτή αυτή του λαϊκού μαντείου παίρνουν μέρος
 	και άντρες. Όλοι παίρνουν θέση γύρω στη γραγούδα. Δυο μικρά παιδιά βγάζουν μέσα απ’ το δοχείο ένα-ένα κόσμημα. Πρώτα όμως θα ειπωθή το τραγούδι και ύστερα θα 
	βγη απ’ τη γραγούδα το αντικείμενο.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Έτσι ο καθένας προσπαθεί να πη καλλίτερο τραγούδι από τον άλλον. Τα τραγούδια αυτά είναι λαϊκά δίστιχα, και πολλά απ’ αυτά είναι πειραχτικά. Απ’ τη σημασία 
	του δίστιχου κρίνεται και η τύχη του κάθε κοριτσιού.</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Ιούλιος</Typography>
	
	<div class="dimotiki">
	
	Αμ’ του άγιου Λια του βράδ’,<br/>
	Βάζει η γ ιλιά του λάδ’.<br/><br/>
	</div>
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Θέλει να πη, πως απ’ τη μ’ερα του προφήτη Ηλία αρχίζει να λαδίζη η ελιά.<br/>
	«Η γι άγι Λιας άμα π’λαλή στουν ουρανό μι του άρμα τ’, γινόdιν οι βρουdές τσ οι γι αστραπές». Κείνη τη μέρα οι παλιοί κατέβαιναν στη θάλασσα και βλέπαν τα μερομήνια. 
	Απ’ τη φουσκοθαλασσιά ή από τη ξέρα (τραβούν τα νερά) προμάντευαν τον καιρό.
	Όταν βλέπαν σύννεφα στον ουρανό, θάχαμε πρώιμο χεμώνα. Άμα πάλι φανούν πολλοί τζιτζίροι ή πολλές μυρμηγιές, θάχουμε άσκημη χρονιά.<br/>
	Τ’ αγιού Λια το μεσημέρι, ό,τι καιρός πάρη, τέτιος θάναι και ο καιρός το χειμώνα. Αν έχη συννεφιά, θάχουμε βαροχειμωνιά.<br/>
	Το πρωί τ’ αγιού Λια, οι τζομπάνηδες βλέπουνε τους σκύλους τους με τι τρόπο κάθονται. Άμα ο σκύλος είναι ξαπλωμένος κατά τη Νοτιά, θάχουμε καλοκαιριές, άμα πάλι 
	είναι κουλουριασμένος και βλέπει κατά το Βοριά, θάχουμε βαροχειμωνιά.<br/>
	«Οι Τούρκοι έχουν εχθρό τον άγι Λια, γιατί αυτός ξεσκέπασε τα ψευτοθάματα του Μωάμεθ. Όταν γίνονται αστραπές, οι Τούρκοι λένε, πως ο Μωάμεθ κυνηγά τον άγι Λια και 
	παλέβουν με τα σπαθιά τους. Τότες οι σπίθες των σπαθιών κάνουν τις αστραπές. Πιστεύουν ακόμα οι Τούρκοι, ότι ο άγι Λιας φράζει το φεγγάρι (όταν το φεγγάρι πιάνεται) 
	και τότες οι Τούρκοι ρίχτουν τουφεκιές στα σύννεφα, για να φανή το φεγγάρι».<br/></Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Αύγουστος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>«Τ’ς αγιάς Σουτήρας» πάνε στις εκκλησιές τα πρώτα σταφύλια να τα διαβάση και να τα βλογήση ο παπάς και να μοιραστούν ύστερα στο εκκλησίασμα, που θα ευχηθή «καλό 
	μπιρικέτ».<br/>
	«Τ’ς αγιάς Σουτήρας, ήdαν η Παναγιά άρρουστ’. Λαχτάρ’ξι κουλιό τσι σταφύλ’, τσι τ’ bήγαν τσ’ έφαγι. Απί τσειν’ τ’ μέρα λέν: Του Βαγιώ τσι τ’ς αγιάς Σουτήρας, 
	ψάρ’ άμα δε βρης να φας, λέπ’ να βρης, α του γλύψ’ς».<br/></Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Η κοίμηση της Θεοτόκου. Όλο το δεκαπενταύγουστο νηστεύει ο λαός. Γυναίκες, άντρες και παιδιά, που έχουνε τάμα στην Παναγιά, μαυροφορούν. Σε πολλά χωριά του Νησιού 
	γίνονται πανηγύρια, μα το μεγαλύτερο προσκύνημα γίνεται στην Παναγιά της Αγιάσου. (Μεγάλο χωριό, που απέχει απ’ τη Μυτιλήνη 25 χιλιόμετρα).<br/>
	Απ’ την παραμονή, όσοι τόχουν τάμα, παίρνουν όλον αυτό το δρόμο με τα πόδια τους και πάνε στη χάρη της να ξεπληρώσουν το τάμα τους. Άλλος τραβά ξυπόλυτος, 
	σηκώνοντας στον ώμο του το λάδι, που θ’ αφιερώση. Άλλες γυναίκες με τα χέρια πίσω δεμένα, πιστάγκωνα, τραβούνε ξυπόλυτες στη Μεγαλόχαρη και πολλά παιδιά, που 
	σηκώνουν μεγάλες λαμπάδες ίσαμε το μπόγι τους, πηγαίνουν με πίστη να κάνουν το τάμα τους. Το βράδυ όλοι αυτοί οι προσκυνητές, ξενυχτούν μέσα στην εκκλησία με 
	αγρυπνιές και προσευχές.<br/>
	Και η Παναγιά η Αγιασώτισσα κάθε χρόνο στο πανηγύρι της θα κάνη το θάμα της. Θα γιατρευτούν τρελλοί, τυφλοί και σακάτηδες.<br/></Typography>



	<Typography variant="h1" sx={theme.typography.typography1}>Σεπτέμβριος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Σε μερικά χωριά τον λένε και Τρύγο, γιατί τα σταφύλια τα τρυγούνε και μέσα στο Σεπτέβρη.<br/>
	Οι γεωργοί, όπως και η Εκκλησία μας, την αρχή του χρόνου τη λογαριάζουν απ’ τον Σεπτέβρη. Έτσι η πρώτη του Σταυρού είναι γι’ αυτούς πρωτοχρονιά. Γι’ αυτό και 
	στην πρώτη του μηνός, δε βάζουν στο σπίτι τους άνθρωπο, σα δεν κάνουν πρώτα αγιασμό.<br/>
	[…] Του Σταυρού. Οι γυναίκες παίρνουν αγιασμό απ’ την εκκλησιά και πιάνουν καινούργιο προζύμι. Το ζυμάρι το θυμιάζουν με το λουλούδι του Σταυρού, για να φουσκώση. 
	Όταν δώσης δανεικό προζύμι, δεν πρέπει να το δουν τάστρα, ούτε την ώρα που το δίνεις, ούτε την ώρα που σου το επιστρέφουν.<br/>
	«Μήδι του κόστσ’νου π’ θα κουστσ’νίγ’ς τ’ αλεύρ’ σ’, ε gαν’ α του δουν τάστρα, μόνι του στσιπάζιν μι του πανί».<br/>
	Στο φούρνισμα άμα σκάση το ψωμί, τότες γιά μουσαφίρη θάχης, γιά δρόμο θα πάρης. Άμα θα πρωτανάψης καινούργιο φούρνο, πρέπει να κάνης και πίττες να τις 
	μοιράσης στη γειτονιά.<br/></Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Οκτώβριος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Με τον Οχτώβρη αρχίζουν οι βροχές και δροσολογιέται η γης. Γι’ αυτό οι γεωργοί που περιμένουν τα πρωτοβρόχια, λένε:<br/><br/></Typography>
	
	<div class="dimotiki">
	«Η γι άγι Δημήτρ’ς θα φέρ’ νιρό».<br/><br/>
	</div>
	
	
	<Typography variant="h3" sx={theme.typography.typography2}>Τότες ανοίγουνε και λάκκους για καταβολάδες. Αυτό γίνεται και το Μάρτη.<br/>	
	Τον Οχτώβρη δείχνει πια ο καρπός της ελιάς, φαίνονται τα μαξούλια κι’ αρχίζουν τα παζάρια με τους κισιμτζήδες.<br/>
	Με τις πρώτες βροχές σπέρνουν τα σιτάρια και τα κριθάρια.<br/>
	[…] Προμηνύματα βροχής. Όταν βλέπουμε κοπάδια κάργες να πετούνε πάνω από τα σπίτια, θα έχουμε βροχή. Γι’ αυτό λένε:<br/><br/></Typography>
	
	<div class="dimotiki">
	Κόρακας στουν ουρανό,<br/>
	γιά αγέρας, γιά νιρό.<br/><br/>
	</div>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Όταν δούμε σύννεφα στης Αμαλής το βουνό, που πέφτει στο νότιο μέρος της Μυτιλήνης, είναι σημάδι σίγουρης βροχής. Και λέμε: «Τ’ς Αμαλής τα β’να φουρτουθήκαν. 
	Η κιρός τα μ’τζούρουσι».<br/>
	Όταν βλέπουμε τους γλάρους να πετούνε απ’ τη θάλασσα προς τη στεριά, θάχουμε κακοκαιριά και φουρτούνα.<br/>
	Όταν πολλοί γλάροι μαζεύονται πάνω σε ήρεμη θάλασσα, σημάδι πως έρχεται τρικυμία.<br/>
	Όταν απ’ τη θάλασσα μας έρχεται μυρωδιά καρπουζιού, θάχουμε βροχή.<br/>
	</Typography>
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Νοέμβριος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Σ’ αυτό το μήνα αρχίζουν τα ζευγαρίσματα για τις όψιμες σπορές και για τα κουκιά και τα άλλα όσπρια. Ξελακκιάζουν και φυτεύουν καινούργια δέντρα και 
	καθαρίζουν τ’ αμπέλια. Αρχίζει το ράβδισμα και το λιομάζεμα.<br/>
	[…] Τα Εισόδια της Θεοτόκου. Τ’ς Παναγιάς τ’ς Μισουσπουρίτιργιας. Ως εκείνη τη μέρα οι ζευγάδες έχουν σπείρη το μισό σπόρο. Είναι συνήθεια παλιά, 
	να γίνεται η μισή σπορά ως τη μέρα των Εισοδίων. Γι’ αυτό, αυτή τη γιορτή τη λένε: «Τ’ς Παναγιάς τ’ς   Μισουσπουρίτιργιας». Και ο αγροτικός κόσμος λέγει: <br/><br/></Typography>
	
	<div class="dimotiki">
	Του πρώιμου του βλόγ’σι η Χριστός<br/>
	του όψ’μου του κάλισι η κιρός.<br/><br/>
	</div>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Τ’ αγιού Αντρέα. Από σήμερα αντρειεύει ο χειμώνας. Οι κοπέλλες ανάφτουν κεριά και προσκυνούν, για να βοηθήση η χάρη του να πάρουν καλόν άντρα. Τα κορίτσια το 
	βράδυ της παραμονής τ’ αγιού Αντρέα, θα δουν την τύχη τους. Παίρνουν μια χούφτα κριθάρι και σιιτάρι και τα ρίχτουν στα τερσέκια, στα τρίστρατα και λένε:<br/><br/></Typography>
	
	<div class="dimotiki">
	Αγιά μ’ dριγιά τσ’ αdρειγιουμένι,<br/>
	δείξι μι τη dύχη μου,<br/>
	σπέρνου στάρι κι κριθάρι<br/>
	κι όποιους είνι τυχηρό μου<br/>
	νάρτ’ απόψι να του θιρίσουμι.<br/><br/>
	</div>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το βράδυ που θα πλαγιάσουν, μελετούν και βλέπουν στον ύπνο τους το πρόσωπο που θα πάρουν.</Typography>
	
	
	
	<Typography variant="h1" sx={theme.typography.typography1}>Δεκέμβριος</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Χριστούγεννα. Την παραμονή το πρωί σφάζανε τα γουρούνια. Στα παλιά τα χρόνια, κάθε σπίτι φρόντιζε να έχη κ’ ένα γουρούνι και τόθρεφτε όλο το χρόνο, για να το 
	σφάξη τα Χριστούγεννα. Οι χασάπηδες είχανε πολλές δουλειές. Γυρίζανε από σπίτι σε σπίτι, για να σφάζουν γουρούνια και να τα ξετριχίζουν με ζεστό νερό, ζεματιστό. 
	Όποιος είχε ξεπαγιασμένα πόδια, χέρια, τα βουτούσε στο ζεστό αίμα του γουρουνιού για να γιάνουν. Στο τσαρσί, λογιώ-λογιώ κρέατα. Βόδινα, χοίρινα, όρνιθες, 
	γαλοπούλες, κατσίκια, αρνιά, σουζούκια, παστουρμάδες, κ.λ.π.<br/>
	Τη νύχτα χτυπούσαν οι καμπάνες, κι’ ο κόσμος τραβούσε στις εκκλησιές, για ν’ ακούση τη γέννα του Χριστού.<br/>
	Απολείτουργα, κατά τα χαράματα, γυρίζαν στα σπίτια τους, στρώνανε το Χριστουγεννιάτικο τραπέζι και τρώγανε σούπες. Ύστερα πέφταν και κοιμώτανε. Το μεσημέρι 
	πάλι στρωνότανε άλλο τραπέζι. Έτσι το φαγοπότι αυτό και οι γιορτές κρατούσαν τρεις μέρες. Κανείς δεν έπιανε δουλειά. Γι’ αυτό ο λαός έλεγε:<br/><br/></Typography>
	
	<div class="dimotiki">
	Τρεις τα Γέννα, τρεις τα Φώτα τσ’ έξ τ’ Λαμπρή.<br/><br/>
	</div>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Δηλ. γιορτάζαν τρεις μέρες για τα Χριστούγεννα, τρεις για τα Φώτα και εξ για τη Λαμπρή.<br/>
	[…] Ανήμερα τα Χριστούγεννα, μοιράζανε στους φτωχούς Χριστόψωμα και κουλούρια, για τις ψυχές των πεθαμένων. Μαζί μ’ αυτά μοιράζανε και μπουρέκια, με κρέατα βωδινά, 
	γιατί δε γίνεται το συχώριο με κρέας χοιρινό.<br/>
	Ύστερα πια ερχόντανε οι παραμονές της Πρωτοχρονιάς. Έτσι τελειώνοντας ο χρόνος, ο λαός τραγουδούσε:<br/><br/></Typography>
	
	<div class="dimotiki">
	Καλώς ανταμωθήκαμε τα δυο τ’ αγαπημένα,<br/>
	από καιρού χαρούμενα και καλοκαρδισμένα.<br/>
	</div>
	
	<Typography variant="h3" sx={theme.typography.typography2}> </Typography>

	</Box>
	
	<ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
		<Typography variant="h1" sx={theme.typography.typography1}>Φωτογραφικό Υλικό</Typography>
		
		<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image03}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image04}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image05}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image06}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image07}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image08}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image09}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image10}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image11}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image12}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image13}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image14}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image15}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image16}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<img src={Image17}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
		
	</Box>

    <ScrollToTopButton />



    </>
  );
}

export default P7_Hmerologia;
