import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import InboxIcon from '@mui/icons-material/Inbox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grow from '@mui/material/Grow';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { GiDolphin } from "react-icons/gi";

import { IoHomeSharp } from "react-icons/io5";
import { VscTypeHierarchy } from "react-icons/vsc";
import { PiMapPinFill } from "react-icons/pi";
import { TbActivity } from "react-icons/tb";
import { GrUserWorker } from "react-icons/gr";
import { GiEcology } from "react-icons/gi";
import { MdMuseum } from "react-icons/md";
import { HiBuildingLibrary } from "react-icons/hi2";
import { BiWorld } from "react-icons/bi";
import { IoCalendarOutline } from "react-icons/io5";
import { SlEnvolopeLetter } from "react-icons/sl";
import { FaMapPin } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";

import { IoMdArrowDroprightCircle } from "react-icons/io"; // arrow right.



import Home from '../screens/Home';
import D2_o_sillogos from '../screens/D2_o_sillogos';
import D3_pou_vriskomaste from '../screens/D3_pou_vriskomaste';


import D4_drastiriotites_2007 from '../screens/D4_drastiriotites_2007';
import D5_drastiriotites_2008 from '../screens/D5_drastiriotites_2008';
import D6_drastiriotites_2009 from '../screens/D6_drastiriotites_2009';
import D7_drastiriotites_2010 from '../screens/D7_drastiriotites_2010';
import D8_drastiriotites_2011 from '../screens/D8_drastiriotites_2011';
import D9_drastiriotites_2012 from '../screens/D9_drastiriotites_2012';
import D10_drastiriotites_2013 from '../screens/D10_drastiriotites_2013';
import D11_drastiriotites_2014 from '../screens/D11_drastiriotites_2014';
import D12_drastiriotites_2015 from '../screens/D12_drastiriotites_2015';
import D13_drastiriotites_2016 from '../screens/D13_drastiriotites_2016';
import D14_drastiriotites_2017 from '../screens/D14_drastiriotites_2017';
import D15_drastiriotites_2018 from '../screens/D15_drastiriotites_2018';
import D16_drastiriotites_2019 from '../screens/D16_drastiriotites_2019';
import D17_drastiriotites_2020 from '../screens/D17_drastiriotites_2020';
import D18_drastiriotites_2021 from '../screens/D18_drastiriotites_2021';
import D19_drastiriotites_2022 from '../screens/D19_drastiriotites_2022';
import D20_drastiriotites_2023 from '../screens/D20_drastiriotites_2023';

import D101_spoudaioteraErgamas from '../screens/D101_spoudaioteraErgamas';

import F1_oikologika_01 from '../screens/F1_oikologika_01';
import F2_oikologika_02 from '../screens/F2_oikologika_02';
import F3_oikologika_03 from '../screens/F3_oikologika_03';
import F4_oikologika_04 from '../screens/F4_oikologika_04';
import F5_oikologika_05 from '../screens/F5_oikologika_05';
import F6_oikologika_06 from '../screens/F6_oikologika_06';
import F7_oikologika_07 from '../screens/F7_oikologika_07';
import F8_oikologika_08 from '../screens/F8_oikologika_08';
import F9_oikologika_09 from '../screens/F9_oikologika_09';
import F10_oikologika_10 from '../screens/F10_oikologika_10';
import F11_oikologika_11 from '../screens/F11_oikologika_11';
import F12_oikologika_12 from '../screens/F12_oikologika_12';
import F13_oikologika_13 from '../screens/F13_oikologika_13';
import F14_oikologika_14 from '../screens/F14_oikologika_14';
import F15_oikologika_15 from '../screens/F15_oikologika_15';
import F16_oikologika_16 from '../screens/F16_oikologika_16';
import F17_oikologika_17 from '../screens/F17_oikologika_17';
import F18_oikologika_18 from '../screens/F18_oikologika_18';

import D102_ToMouseioMas from '../screens/D102_ToMouseioMas';
import D103_HBibliothikiMas from '../screens/D103_HBibliothikiMas';

import G1_mikrasiatika from '../screens/G1_mikrasiatika';
import G2_mikrasiatika from '../screens/G2_mikrasiatika';
import G3_mikrasiatika from '../screens/G3_mikrasiatika';
import G4_mikrasiatika from '../screens/G4_mikrasiatika';
import G5_mikrasiatika from '../screens/G5_mikrasiatika';
import G6_mikrasiatika from '../screens/G6_mikrasiatika';
import G7_mikrasiatika from '../screens/G7_mikrasiatika';
import G8_mikrasiatika from '../screens/G8_mikrasiatika';
import G9_mikrasiatika from '../screens/G9_mikrasiatika';
import G10_mikrasiatika from '../screens/G10_mikrasiatika';
import G11_mikrasiatika from '../screens/G11_mikrasiatika';
import G12_mikrasiatika from '../screens/G12_mikrasiatika';
import G13_mikrasiatika from '../screens/G13_mikrasiatika';
import G14_mikrasiatika from '../screens/G14_mikrasiatika';
import G15_mikrasiatika from '../screens/G15_mikrasiatika';
import G16_mikrasiatika from '../screens/G16_mikrasiatika';
import G17_mikrasiatika from '../screens/G17_mikrasiatika';
import G18_mikrasiatika from '../screens/G18_mikrasiatika';
import G19_mikrasiatika from '../screens/G19_mikrasiatika';
import G20_mikrasiatika from '../screens/G20_mikrasiatika';

import P1_Hmerologia from '../screens/P1_Hmerologia';
import P2_Hmerologia from '../screens/P2_Hmerologia';
import P3_Hmerologia from '../screens/P3_Hmerologia';
import P4_Hmerologia from '../screens/P4_Hmerologia';
import P5_Hmerologia from '../screens/P5_Hmerologia';
import P6_Hmerologia from '../screens/P6_Hmerologia';
import P7_Hmerologia from '../screens/P7_Hmerologia';
import P8_Hmerologia from '../screens/P8_Hmerologia';
import P9_Hmerologia from '../screens/P9_Hmerologia';
import P10_Hmerologia from '../screens/P10_Hmerologia';



import D104_Epistoles from '../screens/D104_Epistoles';

import D105_XartisSmirnis from '../screens/D105_XartisSmirnis';

import D106_ContactWithUs from '../screens/D106_ContactWithUs';

import D107_Karsi from '../screens/D107_Karsi';


import Footer from './Footer';






const drawerWidth = 260;

function ResponsiveDrawer() {

  const navigate = useNavigate();

  const location = useLocation();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Changed to 'md' for medium-sized screens

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // ********************************************************* */
  // Οι δραστηριότητές μας.
  // ********************************************************* */
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // ********************************************************* */

  // ********************************************************* */
  // Οικολογικά.
  // ********************************************************* */
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleMenu2Open = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenu2Close = () => {
    setAnchorEl2(null);
  };
  // ********************************************************* */

  // ********************************************************* */
  // Μικρασιάτικα.
  // ********************************************************* */
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleMenu3Open = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleMenu3Close = () => {
    setAnchorEl3(null);
  };
  // ********************************************************* */


  // ********************************************************* */
  // Τα ημερολόγιά μας.
  // ********************************************************* */
  const [anchorEl4, setAnchorEl4] = useState(null);

  const handleMenu4Open = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleMenu4Close = () => {
    setAnchorEl4(null);
  };
  // ********************************************************* */




  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItemButton component={Link} to="/home">
          <ListItemIcon>
            <IoHomeSharp />
          </ListItemIcon>
          <ListItemText primary="Αρχική" />
        </ListItemButton>

        <ListItemButton component={Link} to="/D2_o_sillogos">
          <ListItemIcon>
            <VscTypeHierarchy />
          </ListItemIcon>
          <ListItemText primary="Ο σύλλογος" />
        </ListItemButton>

        <ListItemButton component={Link} to="/D3_pou_vriskomaste">
          <ListItemIcon>
            <PiMapPinFill />
          </ListItemIcon>
          <ListItemText primary="Που βρισκόμαστε" />
        </ListItemButton>
      </List>

      <Divider />

      <List>
        <ListItemButton onClick={handleMenuOpen}>
          <ListItemIcon>
            <TbActivity />
          </ListItemIcon>
          <ListItemText primary="Οι δραστηριότητές μας" />
          <IoMdArrowDroprightCircle />
        </ListItemButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D20_drastiriotites_2023'); }}>Δραστηριότητες 2023</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D19_drastiriotites_2022'); }}>Δραστηριότητες 2022</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D18_drastiriotites_2021'); }}>Δραστηριότητες 2021</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D17_drastiriotites_2020'); }}>Δραστηριότητες 2020</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D16_drastiriotites_2019'); }}>Δραστηριότητες 2019</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D15_drastiriotites_2018'); }}>Δραστηριότητες 2018</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D14_drastiriotites_2017'); }}>Δραστηριότητες 2017</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D13_drastiriotites_2016'); }}>Δραστηριότητες 2016</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D12_drastiriotites_2015'); }}>Δραστηριότητες 2015</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D11_drastiriotites_2014'); }}>Δραστηριότητες 2014</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D10_drastiriotites_2013'); }}>Δραστηριότητες 2013</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D9_drastiriotites_2012'); }}>Δραστηριότητες 2012</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D8_drastiriotites_2011'); }}>Δραστηριότητες 2011</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D7_drastiriotites_2010'); }}>Δραστηριότητες 2010</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D6_drastiriotites_2009'); }}>Δραστηριότητες 2009</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D5_drastiriotites_2008'); }}>Δραστηριότητες 2008</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl)} timeout={1000}>
            <MenuItem onClick={() => { handleMenuClose(); navigate('/D4_drastiriotites_2007'); }}>Δραστηριότητες 2007</MenuItem>
          </Grow>

        </Menu>
      </List>

      <List>
        <ListItemButton component={Link} to="/D101_spoudaioteraErgamas">
          <ListItemIcon>
            <GrUserWorker />
          </ListItemIcon>
          <ListItemText primary="Σπουδαιότερα έργα μας" />
        </ListItemButton>
      </List>

      <List>
        <ListItemButton onClick={handleMenu2Open}>
          <ListItemIcon>
            <GiEcology />
          </ListItemIcon>
          <ListItemText primary="Οικολογικά" />
          <IoMdArrowDroprightCircle />
        </ListItemButton>
        <Menu
          anchorEl={anchorEl2}
          open={Boolean(anchorEl2)}
          onClose={handleMenu2Close}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F18_oikologika_18'); }}>Τα κυριότερα περιβαλλοντολογικά προβλήματα από το ΠΑΝΔΟΙΚΟ</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F17_oikologika_17'); }}>30 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F16_oikologika_16'); }}>29 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F15_oikologika_15'); }}>28 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F14_oikologika_14'); }}>Το μήνυμα του Οικουμενικού Πατριάρχη Βαρθολομαίου για το περιβάλλον</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F13_oikologika_13'); }}>27 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F12_oikologika_12'); }}>26 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F11_oikologika_11'); }}>25 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F10_oikologika_10'); }}>24 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F9_oikologika_09'); }}>23 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F8_oikologika_08'); }}>22 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F7_oikologika_07'); }}>21 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F6_oikologika_06'); }}>20 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F5_oikologika_05'); }}>19 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F4_oikologika_04'); }}>18 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F3_oikologika_03'); }}>17 {' '}<sup>{'\u00B0'}</sup> συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F2_oikologika_02'); }}>Περίληψη του προβλήματος των εγκαταστάσεων</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl2)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu2Close(); navigate('/F1_oikologika_01'); }}>Ημερίδα Προστασίας Φυσικού και Πολιτιστικού περιβάλλοντος</MenuItem>
          </Grow>

        </Menu>
      </List>

      <List>

        <ListItemButton component={Link} to="/D102_ToMouseioMas">
          <ListItemIcon>
            <MdMuseum />
          </ListItemIcon>
          <ListItemText primary="Το μουσείο μας" />
        </ListItemButton>

        <ListItemButton component={Link} to="/D103_HBibliothikiMas">
          <ListItemIcon>
            <HiBuildingLibrary />
          </ListItemIcon>
          <ListItemText primary="Η βιβλιοθήκη  μας" />
        </ListItemButton>

      </List>




      <List>
        <ListItemButton onClick={handleMenu3Open}>
          <ListItemIcon>
            <BiWorld />
          </ListItemIcon>
          <ListItemText primary="Μικρασιάτικα" />
          <IoMdArrowDroprightCircle />
        </ListItemButton>
        <Menu
          anchorEl={anchorEl3}
          open={Boolean(anchorEl3)}
          onClose={handleMenu3Close}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G20_mikrasiatika'); }}>Η αρχαιολογική υπηρεσία στη Μικρά Ασία</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G19_mikrasiatika'); }}>Οι ηθοποιοί στον πόλεμο (του 1922)</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G18_mikrasiatika'); }}>Η εγκατάσταση των προσφύγων του 22 στην Λέσβο</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G17_mikrasiatika'); }}>Η συνθήκη της Λωζάνης (το πλήρες κείμενο)</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G16_mikrasiatika'); }}>Η ιστορία της Αγγελικής Ματθαίου</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G15_mikrasiatika'); }}>Άσα Τζένινγκς , ο άνθρωπος που έσωσε πάνω από το 15% των Ελλήνων της Μικρασίας το 1922.</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G14_mikrasiatika'); }}>Η μεγάλη έξοδος</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G13_mikrasiatika'); }}>Προσφυγική κατοικία στη Σκάλα Λουτρών</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G12_mikrasiatika'); }}>Έθιμα για τη λεχώνα και το βρέφος</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G11_mikrasiatika'); }}>Η εκδίωξη των Ελλήνων από τη Φώκαια της Μικράς Ασίας</MenuItem>
          </Grow>
          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G10_mikrasiatika'); }}>Η προσφορά του προσφυγικού Ελληνισμού στην ανάπτυξη της σύγχρονης Ελλάδας</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G9_mikrasiatika'); }}>Σκάλα Λουτρών - Ένα προσφυγικό χωριό</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G8_mikrasiatika'); }}>1922 - 1924: Η μεγαλύτερη μετακίνηση πληθυσμών στην ιστορία</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G7_mikrasiatika'); }}>Η Μικρασιατική εκστρατεία </MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G6_mikrasiatika'); }}>Τα σχολεία της Σμύρνης </MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G5_mikrasiatika'); }}>Η διαδρομή ενός Μικρασιάτη</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G4_mikrasiatika'); }}>Ο Χριστιανισμός και οι επτά εκκλησίες της Μικράς Ασίας</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G3_mikrasiatika'); }}>Ο Γεωγραφικός χώρος της Μικράς Ασίας</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G2_mikrasiatika'); }}>Φώκαια του 1914: Άλμπουμ φωτογραφιών του Φελίξ Σαρτιώ. </MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl3)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu3Close(); navigate('/G1_mikrasiatika'); }}>Ο εκ Καισαρίας Παλαντζής</MenuItem>
          </Grow>

        </Menu>
      </List>


      <List>
        <ListItemButton onClick={handleMenu4Open}>
          <ListItemIcon>
            <IoCalendarOutline />
          </ListItemIcon>
          <ListItemText primary="Τα ημερολόγιά μας." />
          <IoMdArrowDroprightCircle />
        </ListItemButton>
        <Menu
          anchorEl={anchorEl4}
          open={Boolean(anchorEl4)}
          onClose={handleMenu4Close}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P10_Hmerologia'); }}>Ημερολόγιο 2013 : Μικρασιατική εκστρατεία - Πορεία Μεραρχίας Αρχιπελάγους</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P9_Hmerologia'); }}>Ημερολόγιο 2012 : Πατρίδα μου Μικρά Ασία.</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P8_Hmerologia'); }}>Ημερολόγιο 2011 : Φώκαια. Ο πρώτος ξεριζωμός.</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P7_Hmerologia'); }}>Ημερολόγιο 2010 : Λογοτεχνικό ταξίδι στη Λέσβο του χθες</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P6_Hmerologia'); }}>Ημερολόγιο 2009 : Οικουμενικό Πατριαρχείο</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P5_Hmerologia'); }}>Ημερολόγιο 2008 : Μουσείο Προσφυγικής μνήμης</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P4_Hmerologia'); }}>Ημερολόγιο 2007 : Ίμβρος και Τενέδος</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P3_Hmerologia'); }}>Ημερολόγιο 2006 : Πέργαμος</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P2_Hmerologia'); }}>Ημερολόγιο 2005 : Αϊβαλί</MenuItem>
          </Grow>

          <Grow in={Boolean(anchorEl4)} timeout={1000}>
            <MenuItem onClick={() => { handleMenu4Close(); navigate('/P1_Hmerologia'); }}>Ημερολόγιο 2004 : Σμύρνη</MenuItem>
          </Grow>

        </Menu>
      </List>


      <List>

        <ListItemButton component={Link} to="/D104_Epistoles">
          <ListItemIcon>
            <SlEnvolopeLetter />
          </ListItemIcon>
          <ListItemText primary="Επιστολές" />
        </ListItemButton>

        {/* <ListItemButton component={Link} to="/D105_XartisSmirnis">
          <ListItemIcon>
            <FaMapPin />
          </ListItemIcon>
          <ListItemText primary="Χάρτης Σμύρνης" />
        </ListItemButton> */}

        <List>
          <ListItemButton component="a" href="/Map_of_Smirni/interaction_new.html" target="_blank" rel="noopener noreferrer">
            <ListItemIcon>
              <FaMapPin />
            </ListItemIcon>
            <ListItemText primary="Χάρτης Σμύρνης" />
          </ListItemButton>
        </List>


        <ListItemButton component={Link} to="/D106_ContactWithUs">
          <ListItemIcon>
            <MdAttachEmail />
          </ListItemIcon>
          <ListItemText primary="Επικοινωνία" />
        </ListItemButton>

        {/* <ListItemButton component={Link} to="/D107_Karsi">
          <ListItemIcon>
            <MdAttachEmail />
          </ListItemIcon>
          <ListItemText primary="Καρσί" />
        </ListItemButton> */}

      </List>




    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">

        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 3 }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            noWrap
            sx={{
              marginLeft: { xs: 0, lg: '10em', xl: '10em' }, // Adjust margin for lg and xl screens
            }}
          >

            {/* <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>              
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <GiDolphin style={{ fontSize: 40, color: 'blue', marginTop: '0.2em', color: 'white' }} />
                <span style={{ marginTop: '0.3em', marginLeft: '3em' }}>"Το δελφίνι"</span> 
              </div>
            </Link> */}

            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              {/* Link to the theme screen */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <GiDolphin style={{ fontSize: 40, color: 'blue', marginTop: '0.2em', color: 'white' }} />
                {isMobile ? ( // Check if screen size is below md
                  <span style={{ marginTop: '0.3em', marginLeft: '3em' }}>"Το Δελφίνι"</span>
                ) : (
                  <span style={{ marginTop: '0.3em', marginLeft: '3em' }}>Σύλλογος Μικρασιατών Σκάλας Λουτρών Λέσβου - "Το Δελφίνι"</span>
                )}
              </div>
            </Link>

          </Typography>

          <div style={{ flexGrow: 1 }} />

        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {location.pathname === '/' && <Home />}
        {location.pathname === '/home' && <Home />}
        {location.pathname === '/D2_o_sillogos' && <D2_o_sillogos />}
        {location.pathname === '/D3_pou_vriskomaste' && <D3_pou_vriskomaste />}
        {location.pathname === '/D20_drastiriotites_2023' && <D20_drastiriotites_2023 />}
        {location.pathname === '/D19_drastiriotites_2022' && <D19_drastiriotites_2022 />}
        {location.pathname === '/D18_drastiriotites_2021' && <D18_drastiriotites_2021 />}
        {location.pathname === '/D17_drastiriotites_2020' && <D17_drastiriotites_2020 />}
        {location.pathname === '/D16_drastiriotites_2019' && <D16_drastiriotites_2019 />}
        {location.pathname === '/D15_drastiriotites_2018' && <D15_drastiriotites_2018 />}
        {location.pathname === '/D14_drastiriotites_2017' && <D14_drastiriotites_2017 />}
        {location.pathname === '/D13_drastiriotites_2016' && <D13_drastiriotites_2016 />}
        {location.pathname === '/D12_drastiriotites_2015' && <D12_drastiriotites_2015 />}
        {location.pathname === '/D11_drastiriotites_2014' && <D11_drastiriotites_2014 />}
        {location.pathname === '/D10_drastiriotites_2013' && <D10_drastiriotites_2013 />}
        {location.pathname === '/D9_drastiriotites_2012' && <D9_drastiriotites_2012 />}
        {location.pathname === '/D8_drastiriotites_2011' && <D8_drastiriotites_2011 />}
        {location.pathname === '/D7_drastiriotites_2010' && <D7_drastiriotites_2010 />}
        {location.pathname === '/D6_drastiriotites_2009' && <D6_drastiriotites_2009 />}
        {location.pathname === '/D5_drastiriotites_2008' && <D5_drastiriotites_2008 />}
        {location.pathname === '/D4_drastiriotites_2007' && <D4_drastiriotites_2007 />}

        {location.pathname === '/D101_spoudaioteraErgamas' && <D101_spoudaioteraErgamas />}

        {location.pathname === '/F1_oikologika_01' && <F1_oikologika_01 />}
        {location.pathname === '/F2_oikologika_02' && <F2_oikologika_02 />}
        {location.pathname === '/F3_oikologika_03' && <F3_oikologika_03 />}
        {location.pathname === '/F4_oikologika_04' && <F4_oikologika_04 />}
        {location.pathname === '/F5_oikologika_05' && <F5_oikologika_05 />}
        {location.pathname === '/F6_oikologika_06' && <F6_oikologika_06 />}
        {location.pathname === '/F7_oikologika_07' && <F7_oikologika_07 />}
        {location.pathname === '/F8_oikologika_08' && <F8_oikologika_08 />}
        {location.pathname === '/F9_oikologika_09' && <F9_oikologika_09 />}
        {location.pathname === '/F10_oikologika_10' && <F10_oikologika_10 />}
        {location.pathname === '/F11_oikologika_11' && <F11_oikologika_11 />}
        {location.pathname === '/F12_oikologika_12' && <F12_oikologika_12 />}
        {location.pathname === '/F13_oikologika_13' && <F13_oikologika_13 />}
        {location.pathname === '/F14_oikologika_14' && <F14_oikologika_14 />}
        {location.pathname === '/F15_oikologika_15' && <F15_oikologika_15 />}
        {location.pathname === '/F16_oikologika_16' && <F16_oikologika_16 />}
        {location.pathname === '/F17_oikologika_17' && <F17_oikologika_17 />}
        {location.pathname === '/F18_oikologika_18' && <F18_oikologika_18 />}

        {location.pathname === '/D102_ToMouseioMas' && <D102_ToMouseioMas />}
        {location.pathname === '/D103_HBibliothikiMas' && <D103_HBibliothikiMas />}

        {location.pathname === '/G1_mikrasiatika' && <G1_mikrasiatika />}
        {location.pathname === '/G2_mikrasiatika' && <G2_mikrasiatika />}
        {location.pathname === '/G3_mikrasiatika' && <G3_mikrasiatika />}
        {location.pathname === '/G4_mikrasiatika' && <G4_mikrasiatika />}
        {location.pathname === '/G5_mikrasiatika' && <G5_mikrasiatika />}
        {location.pathname === '/G6_mikrasiatika' && <G6_mikrasiatika />}
        {location.pathname === '/G7_mikrasiatika' && <G7_mikrasiatika />}
        {location.pathname === '/G8_mikrasiatika' && <G8_mikrasiatika />}
        {location.pathname === '/G9_mikrasiatika' && <G9_mikrasiatika />}
        {location.pathname === '/G10_mikrasiatika' && <G10_mikrasiatika />}
        {location.pathname === '/G11_mikrasiatika' && <G11_mikrasiatika />}
        {location.pathname === '/G12_mikrasiatika' && <G12_mikrasiatika />}
        {location.pathname === '/G13_mikrasiatika' && <G13_mikrasiatika />}
        {location.pathname === '/G14_mikrasiatika' && <G14_mikrasiatika />}
        {location.pathname === '/G15_mikrasiatika' && <G15_mikrasiatika />}
        {location.pathname === '/G16_mikrasiatika' && <G16_mikrasiatika />}
        {location.pathname === '/G17_mikrasiatika' && <G17_mikrasiatika />}
        {location.pathname === '/G18_mikrasiatika' && <G18_mikrasiatika />}
        {location.pathname === '/G19_mikrasiatika' && <G19_mikrasiatika />}
        {location.pathname === '/G20_mikrasiatika' && <G20_mikrasiatika />}

        {location.pathname === '/P1_Hmerologia' && <P1_Hmerologia />}
        {location.pathname === '/P2_Hmerologia' && <P2_Hmerologia />}
        {location.pathname === '/P3_Hmerologia' && <P3_Hmerologia />}
        {location.pathname === '/P4_Hmerologia' && <P4_Hmerologia />}
        {location.pathname === '/P5_Hmerologia' && <P5_Hmerologia />}
        {location.pathname === '/P6_Hmerologia' && <P6_Hmerologia />}
        {location.pathname === '/P7_Hmerologia' && <P7_Hmerologia />}
        {location.pathname === '/P8_Hmerologia' && <P8_Hmerologia />}
        {location.pathname === '/P9_Hmerologia' && <P9_Hmerologia />}
        {location.pathname === '/P10_Hmerologia' && <P10_Hmerologia />}


        {location.pathname === '/D104_Epistoles' && <D104_Epistoles />}

        {location.pathname === '/D105_XartisSmirnis' && <D105_XartisSmirnis />}

        {location.pathname === '/D106_ContactWithUs' && <D106_ContactWithUs />}

        {location.pathname === '/D107_Karsi' && <D107_Karsi />}

        <Footer />
      </Box>




    </Box>
  );
}

export default ResponsiveDrawer;
