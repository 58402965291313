import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/Hmerologia_p1/Smirni_01.jpg";
import Image02 from "../assets/images/Hmerologia_p1/Smirni_02.jpg";
import Image03 from "../assets/images/Hmerologia_p1/Smirni_03.jpg";
import Image04 from "../assets/images/Hmerologia_p1/Smirni_04.jpg";
import Image05 from "../assets/images/Hmerologia_p1/Smirni_05.jpg";
import Image06 from "../assets/images/Hmerologia_p1/Smirni_06.jpg";
import Image07 from "../assets/images/Hmerologia_p1/Smirni_07.jpg";
import Image08 from "../assets/images/Hmerologia_p1/Smirni_08.jpg";
import Image09 from "../assets/images/Hmerologia_p1/Smirni_09.jpg";
import Image10 from "../assets/images/Hmerologia_p1/Smirni_10.jpg";
import Image11 from "../assets/images/Hmerologia_p1/Smirni_11.jpg";
import Image12 from "../assets/images/Hmerologia_p1/Smirni_12.jpg";
import Image13 from "../assets/images/Hmerologia_p1/Smirni_13.jpg";
import Image14 from "../assets/images/Hmerologia_p1/Smirni_14.jpg";
import Image15 from "../assets/images/Hmerologia_p1/Smirni_15.jpg";
import Image16 from "../assets/images/Hmerologia_p1/Smirni_16.jpg";
import Image17 from "../assets/images/Hmerologia_p1/Smirni_17.jpg";
import Image18 from "../assets/images/Hmerologia_p1/Smirni_18.jpg";
import Image19 from "../assets/images/Hmerologia_p1/Smirni_19.jpg";
import Image20 from "../assets/images/Hmerologia_p1/Smirni_20.jpg";
import Image21 from "../assets/images/Hmerologia_p1/Smirni_21.jpg";
import Image22 from "../assets/images/Hmerologia_p1/Smirni_22.jpg";
import Image23 from "../assets/images/Hmerologia_p1/Smirni_23.jpg";
import Image24 from "../assets/images/Hmerologia_p1/Smirni_24.jpg";
import Image25 from "../assets/images/Hmerologia_p1/Smirni_25.jpg";
import Image26 from "../assets/images/Hmerologia_p1/Smirni_26.jpg";
import Image27 from "../assets/images/Hmerologia_p1/Smirni_27.jpg";
import Image28 from "../assets/images/Hmerologia_p1/Smirni_28.jpg";
import Image29 from "../assets/images/Hmerologia_p1/Smirni_29.jpg";


function P1_Hmerologia() {

  const theme = useTheme();

  return (
    <>     


      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ημερολόγιο 2004 : Σμύρνη" />
      </Box>

      
	<Box sx={{ ...commonBoxStyles }}>
	
    <Typography variant="h1" sx={theme.typography.typography1}>Η καταστροφή της Σμύρνης</Typography>

	<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<Typography variant="h3" sx={theme.typography.typography2}>Πολλοί ξένοι και Έλληνες αυτόπτες μάρτυρες περιγράφουν σκηνές φρίκης
	 κατά τις μέρες της πυρκαϊάς και δίνουν στοιχεία για τους δράστες του εμπρησμού.  Δεν υπάρχει αμφιβολία ότι ο εμπρησμός ήταν προμελετημένος και οργανωμένος.
	 Τούρκοι στρατιώτες μετέφεραν δοχεία με βενζίνη και πετρέλαιο ή έριχναν εμπρηστικές βόμβες.</Typography>
	
     <Typography variant="h3" sx={theme.typography.typography2}>Από τη φωτιά, σύμφωνα με τις μαρτυρίες του Χρ. Σολομωνίδη  κάηκαν περίπου 4.000.000 τμ «από τη  Μπελλαβίστα μέχρι Χατζηφράγκου, 
	κατ΄ ευθείαν γραμμή, δια του Γαλλικού νοσοκομείου και του Αγίου Ιωάννου μήκος 2.000 περίπου μέτρα. Προς το Τεπεντζήκι και το Σταυρό δια της εφαπτομένης των
	συνοικιών Κιουπετζόγλου και Μορτάκια, πλέον των 2.000μ.
	Από το Τεπεντζήκι μέχρι του Τελωνείου πλέον των 3.000μ. και από το Τελωνείο μέχρι της Μπελλαβίστας 1.000 περίπου μέτρα. Στον χώρον αυτόν εκάησαν περί τις 
	55.000 σπίτια, από τα οποία 43.000 ελληνικά, 10.000 αρμενικά και 2.000 ξένων υπηκόων. Επίσης απετεφρώθησαν 5.000 καταστήματα, τα περισσότερα των οποίων ήσαν 
	ελληνικά. Στην αποτεφρωθείσα  έκταση υπολογίζεται ότι  κατοικούσαν πλέον των 180.000 ατόμων, κατά μέγιστον ποσοστόν  Έλληνες». Και προσθέτει: «Συγκεκριμένα εκάησαν:<br/>
    </Typography>

	<img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<Typography variant="h3" sx={theme.typography.typography2}>
	Η αρμενική συνοικία με την εκκλησία του Αγίου Στεφάνου.<br/>
	Η ελληνική αγορά, η γνωστή με το όνομα «Μεγάλες Ταβέρνες»,<br/>
	τα  Γυαλάδικα,<br/>
	το Γερανιό,<br/>
	ο Φραγκομαχαλάς<br/>
	η οδός Νοσοκομείων με τα τρία νοσοκομεία της, το Γραικικό, το Ολλανδικό,<br/>
	και το Καθολικό του Αγίου Αντωνίου<br/>
	επίσης οι συνοικίες<br/>
	του Αγίου Γεωργίου<br/>
	της Αγίας Φωτεινής<br/>
	του Αγίου Δημητρίου<br/>
	της Ευαγγελιστρίας<br/>
	<img src={Image04}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <br/>
	του Αγίου Τρύφωνος,<br/>	
	του Αγίου Ιωάννου της Λυγαριάς,<br/>
	του Αγίου Νικολάου.<br/>
	Και ακόμη<br/>
	του Τσαρμάδου,<br/>
	οι Πορτάρες,<br/>
	ο Φασουλάς,<br/>
	τα Μπογιατζίδικα,<br/>
	τα Τράσσα,<br/>
	του Μεϊμάρογλου,<br/>
	τα Σερβετάδικα,<br/>	
	<img src={Image03}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        /><br/>	
	τα Ταμπάχανα,<br/>	
	τα Κουγιουμτζίδικα,<br/>
	τα Μαλτέζικα,<br/>
	της Τσικουδιάς,<br/>
	του Κιουπετζόγλου,<br/>
	του Χαλεπλή,<br/>
	ο Νέος Κόσμος,<br/>
	η Παράλληλος,<br/>
	και μεγάλο τμήμα της Προκυμαίας.<br/> </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Απετεφρώθησαν όλοι σχεδόν οι ορθόδοξοι ναοί, 117 σχολεία κοινοτικά και ιδιωτικά Ελλήνων και Αρμενίων, 
	το αρχαιολογικό και νομισματολογικό μουσείο της Ευαγγελικής Σχολής, και πλείστα όσα αγαθοεργά καθιδρύματα». 
	Ο G. Horton γράφει ότι ο συστηματικός εμπρησμός της πόλεως έγινε από τους στρατιώτες του Μουσταφά Κεμάλ με το σκοπό να εξολοθρευθούν οι  
	Χριστιανοί της Μικράς Ασίας και να μην ξαναγυρίσουν ποτέ πια στην πατρίδα τους...<br/>
	(ΠΗΓΗ Ι.Ε.Ε. ΕΚΔΟΤΙΚΗ ΑΘΗΝΩΝ ΤΟΜ. ΙΕ΄ ΣΕΛ 236-237 )</Typography>
	</Box>
	
	
    <ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Φωτογραφικό Υλικό</Typography>
			
	<img src={Image05}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	<img src={Image07}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image08}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image06}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	<img src={Image10}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image09}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	<img src={Image12}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<img src={Image14}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image15}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image16}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image17}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image18}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image19}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image20}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image21}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image22}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image23}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image24}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image25}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image26}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image27}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image28}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />


	<img src={Image11}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<img src={Image13}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	
	</Box>

    <ScrollToTopButton />

    </>
  );
}

export default P1_Hmerologia;
