import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";

import ImageCarousel from "../components/ImageCarousel";

import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/images001_HomePage/1- Άγιος Ισίδωρος κόλπου Γέρας.jpg";
import Image02 from "../assets/images/images001_HomePage/2- Λιμανάκι Σκάλας Λουτρών.jpg";
import Image03 from "../assets/images/images001_HomePage/3- Παναγία Αψηλή, η Προσφυγοπαναγιά.jpg";
import Image04 from "../assets/images/images001_HomePage/4- Μουσείο Προσφυγικής Μνήμης 1922.jpg";
import Image05 from "../assets/images/images001_HomePage/5- Μουσείο Προσφυγικής Μνήμης 1922.jpg";
import Image06 from "../assets/images/images001_HomePage/6- Χορευτικό τμήμα Συλλόγου.jpg";
import Image07 from "../assets/images/images001_HomePage/7- Κολυμβητικός διάπλους Κουντουρουδιά-Πέραμα.jpg";
import Image08 from "../assets/images/images001_HomePage/8-Σκάλα Λουτρών.jpg";
import Image09 from "../assets/images/images001_HomePage/8-Σκάλα Λουτρών.jpg";

import Image10 from "../assets/images/images001_HomePage/Transparent_Pandoiko.png";
import Image11 from "../assets/images/images001_HomePage/Transparent_Greenpeace.png";
import Image12 from "../assets/images/images001_HomePage/Transparent_wwf.png";
import Image13 from "../assets/images/images001_HomePage/Transparent_opse.png";

import pdfFile1 from "./Epeteiaki_Delfini_1922.pdf";
import pdfFile2 from "./100_xronia_Mikrasiatikou_Ellinismou.pdf";

function Home() {

  const images = [
    { url: Image01, caption: "Άγιος Ισίδωρος κόλπου Γέρας" },
    { url: Image02, caption: "Λιμανάκι Σκάλας Λουτρών" },
    { url: Image03, caption: "Παναγία Αψηλή, η Προσφυγοπαναγιά" },
    { url: Image04, caption: "Μουσείο Προσφυγικής Μνήμης 1922" },
    { url: Image05, caption: "Μουσείο Προσφυγικής Μνήμης 1922" },
    { url: Image06, caption: "Χορευτικό τμήμα Συλλόγου" },
    { url: Image07, caption: "Κολυμβητικός διάπλους Κουντουρουδιά-Πέραμα" },
    { url: Image08, caption: "Σκάλα Λουτρών" },
    { url: Image09, caption: "Σκάλα Λουτρών" },
  ];

  return (
    <>
      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h4" align="center" sx={{ color: "blue" }}>
          Σύλλογος Μικρασιατών Σκάλας Λουτρών Λέσβου
        </Typography>

        <Typography variant="h4" align="center" sx={{ color: "blue" }}>
          "Το Δελφίνι"
        </Typography>

        <ImageCarousel images={images} interval={3000} />

        <Typography
          variant="h6"
          sx={{ textAlign: "center", color: "blue", marginTop: "3em" }}
        >
          Εξωραϊστικός – Πολιτιστικός – Οικολογικός
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center", color: "blue" }}>
          ΜΕΛΟΣ ΤΟΥ ΠΑΝΕΛΛΗΝΙΟΥ ΔΙΚΤΥΟΥ ΟΙΚΟΛΟΓΙΚΩΝ ΟΡΓΑΝΩΣΕΩΝ
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center", color: "blue" }}>
          ΜΕΛΟΣ ΤΟΥ ΕΠΙΜΕΛΗΤΗΡΙΟΥ ΠΕΡΙΒΑΛΛΟΝΤΟΣ ΚΑΙ ΒΙΩΣΙΜOΤΗΤΟΣ
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center", color: "blue" }}>
          ΜΕΛΟΣ ΤΗΣ ΟΜΟΣΠΟΝΔΙΑΣ ΠΡΟΣΦΥΓΙΚΩΝ ΣΩΜΑΤΕΙΩΝ ΕΛΛΑΔΟΣ
        </Typography>

      <Grid
          container
          spacing={4}
          style={{ marginTop: "3em" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Tooltip title="Πανελλήνιο Δίκτυο Οικολογικών Οργανώσεων">
              <a href="https://pandoiko.org/" target="_blank" rel="noopener noreferrer">
                <img
                  src={Image10}
                  alt="ΠΑΝΕΛΛΗΝΙΟ ΔΙΚΤΥΟ ΟΙΚΟΛΟΓΙΚΩΝ ΟΡΓΑΝΩΣΕΩΝ"
                  style={{
                    width: "80px",
                    height: "80px",
                    filter: "brightness(0.6) saturate(200%) hue-rotate(180deg)",
                  }}
                />
              </a>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Greenpeace">
              <a href="https://www.greenpeace.org/greece/" target="_blank" rel="noopener noreferrer">
                <img
                  src={Image11}
                  alt="Greenpeace"
                  style={{
                    width: "250px",
                    height: "80px",
                    filter: "brightness(0.6) saturate(200%) hue-rotate(180deg)",
                  }}
                />
              </a>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="wwf">
              <a href="https://www.wwf.gr/" target="_blank" rel="noopener noreferrer">
                <img
                  src={Image12}
                  alt="wwf"
                  style={{
                    width: "80px",
                    height: "80px",
                    filter: "brightness(0.6) saturate(200%) hue-rotate(180deg)",
                  }}
                />
              </a>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Oμοσπονδία Προσφυγικών Σωματείων Ελλάδος">
              <a href="https://opsehellas.gr/" target="_blank" rel="noopener noreferrer">
                <img
                  src={Image13}
                  alt="Oμοσπονδία Προσφυγικών Σωματείων Ελλάδος"
                  style={{
                    width: "80px",
                    height: "80px",
                    filter: "brightness(0.6) saturate(200%) hue-rotate(180deg)",
                  }}
                />
              </a>
            </Tooltip>
          </Grid>
        </Grid>


        <Grid
          container
          spacing={4}
          style={{ marginTop: "1em" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Link to="/D107_Karsi" style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "blue", marginTop: "3em" }}
              >
                Καλώς ήρθατε στην ιστοσελίδα του συλλόγου μας "To Δελφίνι".
                Περιηγηθείτε για να ενημερωθείτε για τον σύλλογό μας, τις
                εκδηλώσεις μας και τους αγώνες μας. Μπορείτε επίσης να δείτε
                φωτογραφίες από την Μικρά Ασία, στα ημερολόγιά μας.
              </Typography>
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          style={{ marginTop: "1em" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h6">
              <a
                href={pdfFile1}
                download="Epeteiaki_Delfini_1922.pdf"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "blue" }} // Set default color to blue
                onMouseOver={(e) => (e.target.style.color = "#f0677d")} // Change color to pink on hover
                onMouseOut={(e) => (e.target.style.color = "blue")} // Revert color to blue when not hovered
              >
                Κατεβάστε στον υπολογιστή σας το επετειακό μας αρχείο pdf :
                "1914-2014 : 100 χρόνια από τον πρώτο ξεριζωμό."
              </a>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          style={{ marginTop: "1em" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h6">
              <a
                href={pdfFile2}
                download="100_xronia_Mikrasiatikou_Ellinismou.pdf"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "blue" }} // Set default color to blue
                onMouseOver={(e) => (e.target.style.color = "#f0677d")} // Change color to pink on hover
                onMouseOut={(e) => (e.target.style.color = "blue")} // Revert color to blue when not hovered
              >
                Κατεβάστε στον υπολογιστή σας το επετειακό μας αρχείο pdf :
                "100 χρόνια Εθνικής Μνήμης Μικρασιατικού Εληνισμού"
              </a>
            </Typography>
          </Grid>
        </Grid>

     
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default Home;
