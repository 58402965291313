import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/Hmerologia_p3/Pergamos_01.jpg";
import Image02 from "../assets/images/Hmerologia_p3/Pergamos_02.jpg";
import Image03 from "../assets/images/Hmerologia_p3/Pergamos_03.jpg";
import Image04 from "../assets/images/Hmerologia_p3/Pergamos_04.jpg";
import Image05 from "../assets/images/Hmerologia_p3/Pergamos_05.jpg";
import Image06 from "../assets/images/Hmerologia_p3/Pergamos_06.jpg";
import Image07 from "../assets/images/Hmerologia_p3/Pergamos_07.jpg";
import Image08 from "../assets/images/Hmerologia_p3/Pergamos_08.jpg";
import Image09 from "../assets/images/Hmerologia_p3/Pergamos_09.jpg";
import Image10 from "../assets/images/Hmerologia_p3/Pergamos_10.jpg";
import Image11 from "../assets/images/Hmerologia_p3/Pergamos_11.jpg";
import Image12 from "../assets/images/Hmerologia_p3/Pergamos_12.jpg";
import Image13 from "../assets/images/Hmerologia_p3/Pergamos_13.jpg";
import Image14 from "../assets/images/Hmerologia_p3/Pergamos_14.jpg";
import Image15 from "../assets/images/Hmerologia_p3/Pergamos_15.jpg";
import Image16 from "../assets/images/Hmerologia_p3/Pergamos_16.jpg";
import Image17 from "../assets/images/Hmerologia_p3/Pergamos_17.jpg";
import Image18 from "../assets/images/Hmerologia_p3/Pergamos_18.jpg";
import Image19 from "../assets/images/Hmerologia_p3/Pergamos_19.jpg";
import Image20 from "../assets/images/Hmerologia_p3/Pergamos_20.jpg";
import Image21 from "../assets/images/Hmerologia_p3/Pergamos_21.jpg";
import Image22 from "../assets/images/Hmerologia_p3/Pergamos_22.jpg";
import Image23 from "../assets/images/Hmerologia_p3/Pergamos_23.jpg";
import Image24 from "../assets/images/Hmerologia_p3/Pergamos_24.jpg";
import Image25 from "../assets/images/Hmerologia_p3/Pergamos_25.jpg";
import Image26 from "../assets/images/Hmerologia_p3/Pergamos_26.jpg";

function P3_Hmerologia() {

    const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ημερολόγιο 2006 : Πέργαμος" />
      </Box>

      
	<Box sx={{ ...commonBoxStyles }}>

	 <Typography variant="h1" sx={theme.typography.typography1}>Πέργαμος</Typography>

	<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<Typography variant="h3" sx={theme.typography.typography2}>Ιστορική πόλη της Μικράς Ασίας, πρωτεύουσα του βασιλείου της Περγάμου. Είχε κτισθεί στην εύφορη κοιλάδα της Τευθρανίας ανάμεσα στους ποταμούς Σελινούντα και 
	Κήτειο,  παραποτάμους του Κάικου ποταμού.<br/>
	Το όνομά της κατά μία παράδοση, το οφείλει στον Πέργαμο, γιο του Νεοπτόλεμου και της Ανδρομάχης. Όνομα που σημαίνει ισχυρό φρούριο. Κατ’ άλλη παράδοση, η πόλη 
	δημιουργήθηκε από τον Ασκληπιό και Επιδαύριους αποίκους.<br/>
	Η ιστορία της πόλης ουσιαστικά αρχίζει το 301 π.χ όταν ο Λυσίμαχος, ένας από τους επιγόνους του Μ. Αλεξάνδρου,  εμπιστεύθηκε στο διοικητή της Περγάμου Φιλέταιρο Τιανό, προς φύλαξη 9.000 τάλαντα. Το 282 π.χ όμως ο Φιλέταιρος αποστάτησε  και σφετερίστηκε τα χρήματα ιδρύοντας ανεξάρτητο κράτος, το οποίο κυβέρνησαν από τότε οι Ατταλίδες.
	Τον διαδέχτηκε ο ανιψιός του  Ευμένης Α΄ (263-241 π.χ) και αυτόν ο Άτταλος Α΄ ( 241-197 π.χ). <br/>
	Και οι δύο αντιμετώπισαν με επιτυχία τους εξωτερικούς κινδύνους της επικράτειάς τους, κυρίως δε τις επιδρομές των Γαλατών που λυμαίνονταν την εποχή εκείνη τον 
	ελλαδικό χώρο. <br/>
	Επί του διαδόχου του Αττάλου, Ευμένους Β΄ (197-159 π.χ) το βασίλειο της Περγάμου φτάνει στη μέγιστη ακμή του και εκτείνεται από τη θάλασσα του Μαρμαρά ως τα όρη 
	του Ταύρου. Στους χρόνους του, η πόλη εξελίσσεται σε λαμπρό πνευματικό και καλλιτεχνικό κέντρο και εξωραΐζεται με επιβλητικά οικοδομήματα. Δημιουργείται η περίφημη 
	βιβλιοθήκη της Περγάμου με 200.000 τόμους, ενώ τελειοποιείται η περγαμηνή ως νέα γραφική ύλη. Τα χρόνια τούτα οικοδομείται  και ο περιώνυμος βωμός του Διός-Σωτήρως.<br/> 
	Ο Άτταλος Β΄ διαδέχθηκε τον Ευμένη και επί των ημερών του η πόλη καταλήφθηκε από τον βασιλιά της Βιθυνίας Προυσία Β΄, χωρίς όμως να υπάρξει  ατιμωτική συνθήκη για τους 
	Περγαμηνούς ύστερα από επέμβαση της Ρώμης.<br/>
	Η παρακμή της Περγάμου αρχίζει επί εποχής του γιου του Αττάλου Β΄, Αττάλου Γ΄ ο οποίος μετά το θάνατό του όρισε ως κληρονόμους του κράτους του, τους Ρωμαίους. Από 
	τότε και παρά την επανάσταση του Αριστόνικου, φυσικού γιου του Ευμένους, το βασίλειο της  Περγάμου κατέστη οριστικά ρωμαϊκή επαρχία συνεχίζοντας την ανάπτυξή του. 
	Μάλιστα, τον 2ο μ.Χ. αιώνα ο πληθυσμός της πόλης απαριθμούσε  120.000 κατοίκους.<br/>
	Την οικονομική ακμή της Περγάμου, μαρτυρούν οι περίφημοι κιστοφόροι της (περγαμινά  νομίσματα) ενώ την καλλιτεχνική της άνθιση βεβαιώνει η μεγάλη Περγαμηνή Σχολή 
	της ελληνιστικής πλαστικής, με κύριους εκπροσώπους τον Επίγονο, τον Ισίγονο και τον Αντίγονο.<br/></Typography>

	<img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<Typography variant="h3" sx={theme.typography.typography2}>Το πλέον επιβλητικό δημιούργημα της σχολής αυτής, αποτελεί ασφαλώς ο μεγάλος βωμός του Δία με τις γεμάτο πάθος και πληθωρική δράση μορφές του γλυπτού διακόσμου 
	του ( μουσείο Περγάμου, Βερολίνο).<br/>
	Κατά τα πρώιμα βυζαντινά χρόνια η πόλη ανέκτησε πολλά από τα στοιχεία της παλαιότερης οικονομικής και πνευματικής ακτινοβολίας της. Η Εκκλησία της Περγάμου 
	(μια εκ των επτά εκκλησιών της Αποκαλύψεως), έδρα επισκόπου και οι υψηλού επιπέδου ανώτερες σχολές, ιδιαίτερα της ιατρικής, ανέδειξαν την πνευματική συμβολή της
	πόλης στη ζωή της αυτοκρατορίας.<br/>
	Το 716 μ.χ καταστρέφεται από τους Άραβες ενώ μετά την άλωση της Κωνσταντινούπολης από τους Φράγκους (1204), εντάσσεται στην αυτοκρατορία της Νίκαιας. Τέλος το 
	έτος 1310 μ.χ η πόλη καταλαμβάνεται από τους Οθωμανούς Τούρκους. <br/>

	Η ανάπτυξή της όμως δεν σταματά κι έτσι η Πέργαμος κατά τον 14ο και 15ο αιώνα εξελίσσεται σε ένα από τα κέντρα της μουσουλμανικής θεολογικής σοφίας.<br/></Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Η καταστροφή της το 1402 από τους μογγόλους του Ταμερλάνου, ανέκοψε αυτή την ανάπτυξη και μόνο από τα μέσα του 16ου αιώνα άρχισε πάλι η εγκατάσταση νέων κατοίκων. Το 1675 απαριθμούσε 3.000 κατοίκους εκ των οποίων μόνο 100 ήταν Έλληνες. Ο αριθμός τους όμως αυξήθηκε στις αρχές του 19ου αιώνα από Χιώτες, Μυτιληνιούς, Πελοποννήσιους, Μακεδόνες και Ηπειρώτες. Έτσι το 1914 ο πληθυσμός της πόλης ανερχόταν σε 19.000 εκ των οποίων 10.000 Τούρκοι, 8.000 Έλληνες, 300 Αρμένιοι και 700 Εβραίοι. 
	Μετά την παλιννόστηση του 1919, ο ελληνικός πληθυσμός καταμετρήθηκε σε 6.000.<br/>
	Πριν το 1922 η Πέργαμος ήταν έδρα καζά (διοικητική περιφέρεια) και αποτελούσε την σπουδαιότερη πόλη της κοιλάδας του Μπακίρ τσάγ (Κάικος ποταμός), που οι δύο 
	παραπόταμοί του  Κέστελ-τσάγ ( Κήτιος) και Πόκλουτζα-τσάγ ( Σελινούντας ) την περιέρρεαν. Ο ρους του τελευταίου που διήρχετο μέσα από την πόλη, τη χώριζε σε δύο 
	μεγάλα τμήματα. Το τουρκικό με το εβραϊκό και το ελληνικό με το αρμένικο, τα οποία συνδέονταν μεταξύ τους  με τρεις λίθινες γέφυρες. Η αρχαιότερη ήταν "το 
	Κιουγιούν-κιοπρού", που χτίστηκε από τον σουλτάνο Μουράτ Χαν το 1383.<br/>
	Στο κέντρο ακριβώς της πόλης, βρισκόταν η αγορά της, με χαρακτηριστικό γνώρισμά ότι η κάθε συντεχνία ήταν συγκεντρωμένη σε έναν τόπο. Έτσι σε ένα μέρος υπήρχαν 
	τα "μπεζεστένια" (υπόστεγες αγορές), "τα μπεζιριάνικα" (υφασματοπωλεία), "τα μπακιρτζίδικα" (χαλκωματάδικα), "η Λόντζα" (σιταγορά) κ.τ.λ. Η εμπορική της όμως 
	σπουδαιότητα παρουσίαζε μόνο τοπικό ενδιαφέρον.	Τα επονομαζόμενα "Γκιορνέλλια"ή "Γιουρνέλλια",  αποτελούσαν τη μεγάλη πλατεία της πόλης.<br/>
	Κατά τη μικρασιατική εκστρατεία, η πόλη της Περγάμου αποτέλεσε την έδρα της "Μεραρχίας του Αρχιπελάγους". Στις 22 Αυγούστου του 1922, η Πέργαμος περιήλθε οριστικά 
	τους Τούρκους και όσοι από τους κατοίκους της σώθηκαν, κατέφυγαν οριστικά πρόσφυγες στην Ελλάδα.</Typography>

	</Box>
	
	<ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	 <Typography variant="h1" sx={theme.typography.typography1}>Ο Ελληνισμός της επαρχίας και της πόλης της Περγάμου-η ελληνική συνοικία</Typography>	
	<img src={Image03}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Το 1914 ο πληθυσμός της επαρχίας της Περγάμου απαριθμούσε  70.000 περίπου κατοίκους, εκ των οποίων 24.000 Έλληνες, 1.500 Αρμένιοι, 800 Εβραίοι και οι υπόλοιποι 
	Τούρκοι. Μετά την παλιννόστηση του 1919 ο ελληνικός πληθυσμός της Επαρχίας ανερχόταν σε 18.000 μειωμένος κατά το ένα τέταρτο.<br/>
	Τα ελληνικά χωριά γύρω από την Πέργαμο ήταν τα: Κινίκι, Χριστιανοχώρι (Γκιαβούρ-κιόι), Κιοσπεϊλί, Κασικτσή, Σαντζίδες, Κάλαργα, Κλησέ-κιόι, Τσανταρλί (Αλισάρνη), 
	Ατζανόζ (Κάνη), Δικελί (Μαλέας) το επίνειο της Περγάμου, Καπακούμ, Μακαρονία και Αγιασμάτι.<br/>
	Οι Έλληνες κάτοικοι ιδίως των χωριών Κινικίου, Χριστιανοχωρίου, Κιοσπεϊλί, Κασικτσή, Τσανταρλί, Ατζανόζ και Δικελί προέρχονταν κυρίως από τη Λέσβο. Ασχολούνταν 
	με τη γεωργία, κτηνοτροφία, το εμπόριο, τη βιομηχανία και τη βιοτεχνία.<br/>
	Το 1836-37 μια επιδημία πανούκλας με το όνομα "το μεγάλο θανατικό", συντάραξε την κοινωνική δομή της πόλης. Οι περισσότεροι κάτοικοι αναγκάστηκαν να εγκαταλείψουν 
	την Πολιτεία και να  καταφύγουν στην πεδιάδα. Από τότε δημιουργήθηκαν εξοχικές κατοικίες με αμπέλια, ‘‘τ’ Αμπέλια’’ όπως ονομαζόταν η όλη τοποθεσία,  όπου τους 
	θερινούς μήνες περνούσε η πλειονότητα των κατοίκων.<br/>
	Σαν πέρασε  "το μεγάλο θανατικό" αποκαταστάθηκε ο κανονικός ρυθμός της ζωής και ο ελληνικός πληθυσμός στάθηκε η κινητήρια δύναμη της πόλης  της Περγάμου.
	Η Πέργαμος όπως διαχωριζόταν από το Σελινούντα ποταμό, με τον ελληνικό πληθυσμό προς τα κράσπεδα του βουνού και τον τουρκικό  προς την αντικρινή  πλευρά, ο 
	ελληνικός τομέας, το ΄΄ Ρουμ μαχαλεσί’’ πρόβαλλε σαν μια ξεχωριστή πολιτεία, πράγμα που  επέτρεψε να διατηρηθούν τα ελληνοχριστιανικά χαρακτηριστικά του. <br/>
	Ο διαχωρισμός αυτός παρεμπόδιζε την άμεση επαφή και επικοινωνία με τους αλλόφυλους πληθυσμούς, με αποτέλεσμα ο επηρεασμός της γλώσσας, των ηθών και των εθίμων να είναι 
	πολύ μικρός.<br/></Typography>
	
	<img src={Image04}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η ελληνική συνοικία αποτελείτο από μεγάλες γειτονιές, τους ΄΄μαχαλάδες΄΄ και αυτοί ήταν:  των Αγίων Θεοδώρων, της Αγίας Παρασκευής, του Αγίου Γεωργίου, της 
	Αγια Σοφιάς, το Αλανέλ, το Μιχανέ Μπογάζ ή Αγίου Αντύπα και τα Χαμτζαλέλια.<br/>
	Η πλατεία του Αγίου Γεωργίου- το περιβόητο ΄΄ Ντουμούζ Αλάν΄΄ , συγκέντρωνε το κοινωνικό ενδιαφέρον των Ελλήνων της συνοικίας. Δενδροφυτευμένη, εξωραϊσμένη, 
	φιλοξενούσε  κάθε 15-17 Αυγούστου το μεγάλο πανηγύρι της ΄΄ Εύρεσης της εικόνας του Αγίου Γεωργίου’’. Είχε έκταση περί τα πέντε στρέμματα και εκεί υπήρχαν τα δύο
	μεγάλα καφενεία του Σαμψών και του Κανέλλη. <br/>
	Δεν υπήρχαν επαύλεις και επιβλητικά αρχοντικά ούτε και ρυμοτομίες με φαρδείς και ίσιους δρόμους. ΄΄Καλντιρίμια΄΄ και ΄΄ντουσεμέδες΄΄ (λιθόστρωτα) ήταν οι
	δρόμοι των μαχαλάδων, στενοσόκακα τα περισσότερα που τα ΄΄τουρσέκια΄΄ τους (γωνιές με τρίστρατα) φωτίζονταν από παλιά πετρελαιοφάναρα. Συχνά δε, κατέληγαν σε 
	‘‘τσικμά σοκάκια’’ δηλαδή αδιέξοδα. Τα σπίτια  κυρίως δίπατες μονοκατοικίες, λιθόκτιστα με κεραμίδια σκεπασμένα πιο φανταχτερά των πλουσίων, ταπεινότερα των 
	πιο φτωχών.<br/>
	Η ελληνική κοινότητα της Περγάμου διέθετε από το 1859 νοσοκομείο, τα ΄΄Σπιτάλια΄΄ όπως ονομαζόταν, με δώδεκα κλίνες και εξαιρετική υγειονομική 
	περίθαλψη. Επίσης παρθεναγωγείο που φοιτούσαν 250-280 μαθήτριες και αρρεναγωγείο, την ΄΄Αστική Σχολή΄΄, επτατάξιο ή οκτατάξιο που διέθετε και οργανωμένο νηπιαγωγείο. 
	Ξενοδοχεία, λέσχες, καφενεία, μεγάλα και μικρά εμπορικά καταστήματα, οργανωμένα δικηγορικά γραφεία τα καλύτερα της Περγάμου ανήκαν στην ελληνική κοινότητα. Ακόμη 
	πλήθος σωματίων συμπλήρωναν την κοινωνική δραστηριότητα, με κυριότερα : το σωματείο ΄΄ των Παντοπωλών΄΄, ΄΄ των Μανιφατουριέρων΄΄ (υφασματοπωλών), ΄΄του Υδραγωγείου΄΄ 
	που συντηρούσε γύρω στις 70 βρύσες της ελληνικής συνοικίας, ΄΄του Αττάλου΄΄, ΄΄ της Αγίας Καστριανής΄΄, ΄΄του Αγίου Αντύπα΄΄κ.α. <br/>
	Προς το τέλος του 19ου αιώνα, η ελληνική κοινότητα είχε ξεχωρίσει και στον οικονομικό τομέα παραμερίζοντας τις υπόλοιπες των Τούρκων, Εβραίων και Αρμένιων απ’ όλα 
	γενικώς τα επίκεντρα σημεία της κεντρικής αγοράς (το τσαρσί), οι οποίες περιορίστηκαν στις απόκεντρες  μεριές της και ασκούσαν ευτελή συνήθως επαγγέλματα. Έτσι η 
	φυσιογνωμία της Περγάμου πρόβαλε ως καθαρά ελληνική, ‘‘ αρχόντισσα της Ανατολής’’.<br/>
	Στο ύφος αυτό συνέβαλε και ο μεγάλος αριθμός χριστιανικών εκκλησιών. Οι κυριότερες εκκλησίες , μερικές από τις οποίες είχαν μετατραπεί σε τζαμιά, ήταν: η 
	μεγάλη Βασιλική που όπως αναφέρει ο περιηγητής Spon το 1675, ήταν η Μητρόπολη αφιερωμένη στη μνήμη του Αγίου Ιωάννη του Θεολόγου  (Κιζίλ-αυλή-Σεράπειο). Κατ’ άλλους 
	ήταν αφιερωμένη στη μνήμη του ‘‘μάρτυρα’’ Επισκόπου Περγάμου, Αγίου Αντύπα. Αργότερα μετά την καταστροφή της από τον Σουλτάνο Βαγιαζίτ Α΄ το 1398-99, ο ένας από 
	τους δύο θολωτούς κυλινδρικούς πύργους της,  μετασχηματίσθηκε σε παρεκκλήσιο τιμώμενο στο όνομα των αγίων Ιωάννη του Θεολόγου και Αντύπα. Της  Αγίας  Σοφίας  
	(Ουλιού-Τζαμί). Των Αγίων Αποστόλων ( Παρμακλή-Τζαμί ). Των Αγίων Θεοδώρων. Της Ζωοδόχου Πηγής (Μητροπολιτικός). Του  Αγίου Αντύπα, ο δεξιός πύργος της Βασιλικής. 
	Του Αγίου Γεωργίου. Της Αγίας Παρασκευής. Του Αγίου Ευστρατίου και της  Αγίας Καστριανής εκτός της πόλεως. Επίσης υπήρχαν αρκετά  αγιάσματα και τόποι που έφεραν 
	ονόματα αγίων. </Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Όλα αυτά, γνωρίσματα μιας εξελιγμένης κοινότητας, της κοινότητας των Ελλήνων της Περγάμου, με αυστηρή όμως τήρηση των θεμελιωδών αρχών που τη χαρακτήριζαν, με 
	πάνω απ’ όλα την ελληνοχριστιανική μόρφωση. <br/>
	Η κοινωνική αυτή εξέλιξη ανεκόπη από το Νοέμβριο του 1913 και ύστερα, όταν και επιβλήθηκε απόλυτα το νεοτουρκικό κομιτάτο. Η ελληνική κοινότητα ζούσε μέσα σε μια
	απορυθμισμένη και αβέβαιη κατάσταση, με έκδηλη την αγωνία και ανησυχία των χριστιανών, ως τις 27 Μαΐου του 1914 που υποχρεώθηκαν μέσα σε δύο ώρες, 8 με 10χιλιάδες 
	άνδρες, γυναίκες , παιδιά να εγκαταλείψουν τα πατρογονικά εδάφη τους και να οδέψουν προς το άγνωστο, τα νησιά του Αιγαίου.<br/>
	Μετά την κατάληψη της Περγάμου, στις 9 Ιουνίου του 1919 από τον ελληνικό στρατό, άρχισε η παλιννόστηση των Ελλήνων σε μια πόλη που είχε χάσει πια την παλιά 
	φυσιογνωμία και αίγλη της. <br/>
	Η ζωή γρήγορα αποκαταστάθηκε και μια ανθηρή ξανά πολιτεία αναδύθηκε. Με τα μεγάλα πάλι καταστήματά της, τα υφασματοπωλεία, τα εργαστήρια, τα φιλανθρωπικά 
	της σωματεία, τις συντεχνίες, τις προοδευτικές γυναικείες οργανώσεις, τις γιορτές, τα πανηγύρια.<br/>
	Στις 24 Αυγούστου του 1922, ο ελληνισμός της Περγάμου, υποχρεώνεται οριστικά να εγκαταλείψει τη Μικρά Ασία, αλλά  ‘‘όχι όμοια με το 1914, συν γυναιξί και 
	τέκνοις, μα λειψοί, κατάλειψοι όλοι’’. <br/>
	Γύρω στις  4000 ανέρχονται τα θύματα της ευρύτερης περιοχής της Περγάμου.<br/></Typography>
	</Box>	
	
	<ScrollToTopButton />
	
	
	
	<Box sx={{ ...commonBoxStyles }}>
	 <Typography variant="h1" sx={theme.typography.typography1}>Λαογραφικά στοιχεία</Typography>
	
	<img src={Image05}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Ο κοινωνικός βίος των Περγαμηνών Ελλήνων ήταν οργανωμένος απλοϊκά με πλήθος παραδόσεων, ηθών και εθίμων πολλά από τα οποία απαντώνταν και στη Λέσβο.  <br/>
	Η ενδυμασία τόσο των ανδρών όσο και των γυναικών, διέφερε ανάλογα την εποχή. Η επικρατούσα ενδυμασία των ανδρών ήταν τα ΄΄σαλβάρια΄΄ ή τα ΄΄πορπούτια’’, με ζωνάρι στη 
	μέση και σαρίκι στο κεφάλι. Τις επίσημες μέρες, οι άντρες φορούσαν τα πορπούτια (βράκες από τσόχα με περισσότερες πτυχές), το ΄΄ταράπουλους΄΄ (πολύχρωμο ζωνάρι), 
	φαρδομάνικα πουκάμισα και τα ΄΄τουζλούκια΄΄ (υποδήματα κεντημένα στις κνήμες). Το χειμώνα ήταν απαραίτητη η γούνα από μαύρο δέρμα αρνιού, η οποία ρίχνονταν  πάντοτε στη ράχη. Από το 1880 και ύστερα η ενδυμασία αυτή άρχισε να αντικαθίσταται με ΄΄φράγκικα΄΄, το δε σαρίκι εγκαταλείφθηκε.
	Ως τα τέλη του 19ου αιώνα η γυναικεία φορεσιά αποτελείτο  από την ΄΄τάκα΄΄ (φουστάνι από χρυσοφασμένο μεταξωτό μουχανέ), το πουκάμισο, το γιλέκο με τις γλώσσες ανοιχτό
	ως τη μέση του στήθους, το στηθόπανο (μεταξωτή λωρίδα που δενόταν γύρω στο στήθος και σκέπαζε τους μαστούς), το μεϊτανάκι από χρυσοκέντητο βελιό και τέλος στο κεφάλι, 
	μικρό γυναικείο φέσι με μακριά μπρισιμένια μαύρη φούντα, που έπεφτε με χάρη στον ώμο. Διατηρούσαν μακριά μαλλιά και χρησιμοποιούσαν για το λούσιμό τους καρυδόφυλλα, 
	μυρτιόφυλλα, βαγιόφυλλα, βαγιοκούκουτσα  και άλλα που συνέτειναν στη στιλπνότητα  και τόνωση του δέρματος. Τις καθημερινές τα έπλεκαν  σε μία ή δύο ΄΄βουρλίδες΄΄ (κοτσίδες),
	ενώ κατά τις γιορτές τα έδεναν κότσο, που τα περιέβαλλαν με βαρύτιμη ΄΄μουστούκα΄΄ (φακιόλι), διπλωμένη διαγωνίως με την  μια γωνιά προς τα πίσω και τις άλλες στο 
	δεξιό μάγουλο, με τρόπο που να κυματίζει κατά το βάδισμά τους. Φορούσαν πάντοτε σκουλαρίκια, βαρύτιμα συνήθως και όταν ντύνονταν τα γιορτινά τους, στους ώμους 
	έριχναν το ακριβό  μεταξομάλλινο ΄΄σάλι΄΄ και στόλιζαν το λαιμό με ΄΄γιορτάνι΄΄, από ΄΄ορμαθόν φλωρίων΄΄.  </Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Το νοικοκυριό του σπιτιού αποτελούσε την κύρια απασχόληση των γυναικών. Η αγαπημένη τους όμως δραστηριότητα  ήταν η ΄΄κρεβατή΄΄ (αργαλειός) όπου ύφαιναν τα ΄΄φαντά΄΄
	όπως έλεγαν, με διάφορες ΄΄φασιές΄΄ (τεχνικές)-φασιά ριζάκι, χήνας, μάτι, σταυρό, κεντητή κ.α. Σε παλαιότερα χρόνια έκαναν φασιές από μετάξι και χρυσάφι. Τα πιο 
	ωραία υφαντά προέρχονταν από το χωριό Σαντζήδες και οι Σαντζηλιώτισσες εφημίζονταν για την περίτεχνη ύφανσή τους.  <br/>
	Η ελληνική γλώσσα προφέρονταν με ιδιωματισμούς όπως στη Μυτιλήνη. Συνήθως για να ονοματίσουν κάποιον χρησιμοποιούσαν ΄΄παρατσούκλια΄΄ (παρωνύμια), που συνήθως
	προέρχονταν από κανένα ΄΄κουσούρι΄΄ (ελάττωμα) ή κανένα ΄΄χούγι΄΄ (συνήθεια) ή ακόμα ακόμα από καμιά αδυναμία και πράξη του, ενώ το αρσενικό άρθρο ΄΄όμικρον΄΄ 
	μετατρέπονταν στην ονομαστική σε θηλυκό ΄΄ήτα΄΄.  Η Μαλαγάνας, η Παππούς, η Βασιλές, η Ρούσους, η Χαβαλές, του Ζαμπόν, του Τουμπελέκ, του Αυγουλέμουνου κ.α, ήταν 
	μερικά από αυτά που κυριαρχούσαν ακόμα και των μικρών ονομάτων. Έτσι απ’ τον ΄΄Γιαρίμ-Λάμπια’’ θα κέρδιζαν ΄΄στου καφενέ του Σαμψών του ψημένου κεφαλάκ’΄΄ με τα 
	΄΄τσιφ-τεκ΄΄ (μονά – ζυγά) της λοταρίας, για μεζέ του ΄΄καραφάκ’ τσίπουρο, ποτηράκια τρία΄΄, που θα παράγγελνε η Μαλαγάνας, η Σαμψών΄ς΄΄ και για να ντουζντίσουν 
	κεφ’ γκίζντιζαν (τσάτιζαν)  και τον Ταμ-Κούκου, για ν’ ακούσουν ύστερα τα λιανά τους ΄΄κι όσα σέρν’ η σκούπα’’. <br/>
	Τα μικρά παιδια τα φώναζαν με το μικρό όνομα της μητέρας τους, προτάσσοντας το ΄΄τσι΄΄ αντί το ΄΄της΄΄. Έτσι ΄΄ η γιος τσ’ Αμιρσούδας έσπασι του τζαμ’΄΄ έλεγαν 
	ή ΄΄ τσι Πιρμαθούλας η κορ’ τουν είδε κι τουν παρούγδισι΄΄. Όταν τα παιδιά μεγάλωναν και έβγαιναν στη βιοπάλη, τότε μόνο αποκτούσαν ουσιαστικά το πραγματικό τους 
	όνομα:  ΄΄η Νικουλής΄΄, ΄΄η Χρον’ς΄΄, ΄΄η Ιγνάτ’ς΄΄ αλλά και αρχαία ονόματα όπως ΄΄η Άταλλους΄΄, ΄΄η Ευμέν’ς’’, ΄΄η Γαληνός΄΄ κ.α . </Typography>
	</Box>	
	
	<ScrollToTopButton />	 
	 
	 
	
	<Box sx={{ ...commonBoxStyles }}>
	 <Typography variant="h1" sx={theme.typography.typography1}>Ο Περγαμηνός γάμος</Typography>
	 
     <img src={Image06}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Η δημιουργία οικογένειας και η γέννηση παιδιών ανήκαν στις προτεραιότητες  κάθε Περγαμηνού και Περγαμηνής.<br/>
	Οι γονείς ήταν αυτοί που με προξενιό συμφωνούσαν ΄΄τσ’ αρραβώνες΄΄ των παιδιών. Αυτό συνέβαινε όταν το κορίτσι ζύγωνε την  ηλικία των είκοσι και είχε ετοιμάσει 
	τα ΄΄προυκιά΄΄ (προίκα). Ο γαμπρός έπρεπε να είναι ΄΄πατριώτης΄΄ όπως έλεγαν και όχι ξένος. Τα ΄΄καλουρίζ’κα΄΄ ξεκινούσαν με την επαύριο, με αποστολή από τη 
	μεριά της νύφης στο σπίτι των πεθερικών, ασημένιου δίσκου γεμάτο από ΄΄σικέρ λουκούμια΄΄ (κουραμπιέδες).  Ακολουθούσαν οι αρραβώνες στο σπίτι της νύφης 
	με ΄΄κουφέτες΄΄ και ΄΄βιργέτες΄΄ (βέρες).<br/>
	Τις επόμενες μέρες γινόταν ανταλλαγή δώρων μεταξύ των δύο οικογενειών. Η νύφη έπρεπε να διαθέτει προίκα, που περιλάμβανε και το σπίτι, αλλιώς δεν θα
	χαιρότανε ΄΄σώβρακο΄΄ στο πλευρό της όπως έλεγαν. Στο μέσο της τελευταίας βδομάδας του γάμου, στο κοινοτικό γραφείο και σε ειδικό βιβλίο της εκκλησίας 
	συντασσόταν η ΄΄εγκλαβή΄΄, το προικοσύμφωνο δηλαδή.<br/>
	Ο γάμος γινόταν Κυριακή, όμως οι προετοιμασίες άρχιζαν μία βδομάδα πριν. Στην αρχή αυτής της εβδομάδας γινόταν το ΄΄κόψιμο του νυφικού΄΄ όπου φιλενάδες της 
	νύφες και ανύπαντρα κορίτσια παρευρίσκοντο και τραγουδούσαν εύθυμα δίστιχα και έραιναν το νυφικό με τα ΄΄ριζίδια΄΄ ( αργυρά νομίσματα ).<br/></Typography>
    <br/><br/>
	<b>‘‘ Βασιλικός είν’ ο γαμπρός κι η νύφη ματζουράνα κι άγγελοι τους υμνολογούν, ψηλά απ’ τα ‘πουράνια…’’</b>
    <br/><br/>
	<Typography variant="h3" sx={theme.typography.typography2}>Τις υπόλοιπες μέρες ακολουθούσαν πλήθος δρώμενων όπως ΄΄το πάστρεμα του σταριού΄΄ για το φαγητό του γάμου ΄΄κισκέκ’΄΄ και η μεταφορά του στο ΄΄ντουπέκ’΄΄ 
	(πέτρινο γουδί) με ΄΄παιχνίδια΄΄ (μουσικά όργανα) και τραγούδια, ευτράπελου συνήθως χαρακτήρα. Ακόμη η μεταφορά των ΄΄προυκιών΄΄ του γαμπρού, το ΄΄χαμάμ΄΄ για να 
	λουστούν και να ΄΄κναδιαστούν΄΄, το ΄΄διάρμισμα΄΄ (συγύρισμα) του σπιτιού και τέλος το προσκάλεσμα που γίνονταν δίνοντας κερί για προσκλητήριο και τους καλούσαν
	΄΄να κοπιάσουν στη βλόγα΄΄.<br/>
	Απόγευμα Σαββάτου, παραμονή του γάμου, παρευρίσκονταν ΄΄στο κόψιμο του ψωμιού της νύφης΄΄, μέσα σε ΄΄σινί΄΄ (μεγάλο αβαθές ταψί), πάνω στο κεφάλι της νύφης που 
	το κρατούσαν τρία κορίτσια που ζούσαν οι γονείς τους. </Typography>
    <br/><br/>
	<b>΄΄Γλυκό να σουναι το ψωμί σε όλη τη ζωή σου να χαίρονται ν’ αγάλλονται , οι συγγενείς μαζί σου…’’  </b>
    <br/><br/>
	
	<img src={Image07}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Την  Κυριακή το πρωί, την ημέρα του γάμου, ο γαμπρός ντύνονταν τα ΄΄γαμπρίκια΄΄ του, μέσα σε μεγάλο ταψί για να είναι ΄΄σιδηρένιους΄΄ και του έβαζαν
	ζώνη ασημένια, ΄΄χατζήδικια΄΄. <br/>
	Της νύφης έβαζαν εσωτερικά κόκκινη κορδέλα κι ένα μικό ψαλίδι στο παπούτσι ΄΄για να μη τσι πιάσουν τα μάγια΄΄.  Στην εξώπορτα, η μητέρα της νύφης τοποθετούσε 
	ασημένιο ποτήρι με νερό, αργυρά νομίσματα και ρύζι. Έπειτα από όλα αυτά έπαιρναν το δρόμο για την εκκλησία, όπου γινόταν η ΄΄βλόγα΄΄.<br/>
	Μετά το πέρας της τελετής, ύστερα από παραίνεση των φίλων με τη φράση ΄΄τάξε΄΄, ο γαμπρός έταζε  τραπέζι με γλέντι στα  ΄΄πλατανέλια΄΄ της Αγίας Καστριανής. <br/>
	Τέλος όλοι οι καλεσμένοι παρακάθονταν σε τραπέζι, πλούσιο στρωμένο, με τραγούδια και χορούς μέχρι το πρωί. <br/><br/>
	"Νύφη μου στρωσ’ το σπίτι σου, με κατιφέ κνικάτο Γιατί ο γαμπρός που κάναμε, δεν είναι παρακάτω…"</Typography>
	</Box>
	
	<ScrollToTopButton />
		
	
	<Box sx={{ ...commonBoxStyles }}>
	 <Typography variant="h1" sx={theme.typography.typography1}>Τα παιδιά</Typography>
	
     <img src={Image08}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Μεγάλη επιθυμία των κοριτσιών μετά το γάμο, ήταν να γίνουν μητέρες. Όταν  ΄΄βάρυναν΄΄ (έμεναν έγκυες), όλοι και όλες προσπαθούσαν στις ΄΄βαρυμένες΄΄ να 
	ικανοποιήσουν όλα τα ΄΄κακοψύχια΄΄ τους (τις στομαχικές ιδιοτροπίες τους). Για να μην αποβάλουν καλούσαν την ΄΄Τσουπτσινίδα΄΄ (μαμή) για να τις ΄΄δεσ’΄΄ τη  μέση 
	με ΄΄μπλαθρί΄΄ (έμπλαστρο), που το παρασκεύαζε με σαπούνι, ασπράδι αυγού και από άπλυτο μαλλί προβάτου. <br/>
	Πλήθος προλήψεων και δεισιδαιμονιών συνόδευε τη διάρκεια της κύησης, όπως για παράδειγμα του Αγίου Σιμιού απέφευγαν κάθε εργασία ΄΄για να μη σημειουθεί 
	–σημαδευτεί- του πιδί΄΄. <br/>
	Για να δουν τι παιδί θα γεννηθεί, καλούσαν ξαφνικά την έγκυο και αν αυτή ξεκινούσε με το δεξί θα έκανε κορίτσι ειδάλλως ΄΄κοπελάρι΄΄ (αγόρι). Κορίτσι 
	θα γεννούσαν και αν έβρισκαν στο δρόμο βελόνα, ενώ αγόρι αν έβρισκαν καρφίτσα. <br/>
	΄΄Τσ’ ήρθαν τα σναίματα τ’ς΄΄ έλεγαν όταν άρχιζαν οι ωδίνες του τοκετού και έτρεχαν να φωνάξουν τη μαμή, τοποθετώντας το εικόνισμα του Αγίου Ελευθερίου 
	πάνω από το κρεβάτι. Και δίπλα ένα ποτήρι νερό, όπου έριχναν μέσα ξερό χόρτο με το όνομα ΄΄παναγιόχορτο΄΄ ή ΄΄τσ’ Παναγιάς του χέρ’΄΄, που καθώς μούσκευε 
	και άνοιγε θύμιζε το χέρι της Παναγίας που κρατούσε το Χριστό. Αυτό για να καταπραϋνθούν οι πόνοι. <br/>
	Ως φυσικό, τη γέννα ακολουθούσε μια σειρά από ήθη και έθιμα που πολλά από αυτά παρέπεμπαν στην αρχαιότητα. <br/>
	Στο μεγάλωμα και στη διαπαιδαγώγηση των παιδιών, εκτός από τη μητέρα, μεγάλο ρόλο έπαιζε και η ΄΄νενέ΄΄ (γιαγιά) που με τα παραμύθια της και τα λεκτικά 
	παιχνίδια της , κέντριζε πάντα το ενδιαφέρον τους. <br/>
	Συνηθέστερη απασχόληση των παιδιών  φυσικά, το παιχνίδι. Τα πιο συνηθισμένα παιχνίδια τους ήταν : τα ΄΄στεφανωτά΄΄, τα ΄΄άστρα΄΄, τα ΄΄τσιρκένια΄΄, 
	οι ΄΄καϊμάκες΄΄, του ΄΄κουλκουλντέ –ερ-μπιρ΄΄, το ΄΄πιτλανγκίτς΄΄, το ΄΄αρτίρματζα΄΄ κ.α.  <br/></Typography>
	</Box>	
	
	<ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>Τα φαγητά</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Τα φαγητά τους τα έφτιαχναν, όπως άλλωστε συνηθίζεται στην Ανατολή, λιπαρά με πολλές σάλτσες και μπόλικα καρυκεύματα. Από τα ιδιόμορφα φαγητά τους 
	ξεχώριζαν ΄΄ το μπουρέκ’΄΄, ΄΄ το κισκέκ’΄΄, ΄΄το τουρλού-τουρλού΄΄,  ΄΄ η ψωμαγειριά΄΄,  ΄΄η μπουκουβάλα΄΄. </Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Το <b>μπουρέκ’:</b> Μάτσιζαν κι έφτιαχναν κιμά την καρδιά, το συκώτι, τη σπλήνα και τα πάχητα που υπήρχαν πάνω σ’ αυτά, όλα από αρνί. Ψιλόκοβαν 
	ύστερα ένα κρεμμύδι, λίγο μαϊντανό και άνηθο, τα αλατοπιπερώνανε προσθέτοντας και λίγο κύμινο ή μπαχάρι , ένα ποτήρι ρύζι και τα  ΄΄τσιγάριζαν΄΄ 
	(σώταραν) για να λιώσουν τα λίπη και να μαραθούν τα μυριστικά χορταρικά, οπότε και όλο αυτό το μείγμα το τοποθετούσαν   σε ανοιγμένη ΄΄σκέπη΄΄ (μπόλια). 
	Το περιτύλιγαν μέσα σ’ αυτή, για να το βάλουν έπειτα με προσοχή στο πυρίμαχο σκεύος (γιουβέτσι) και να το στείλουν στο φούρνο για να ψηθεί. Συνοδευόταν δε 
	κατά την ώρα του γεύματος με ΄΄γιγούρτ΄΄ (γιαούρτι) για να κόβει τη λιπαρότητα.</Typography>
	</Box>
	<br/><br/><br/>
	
	<Box sx={{ ...commonBoxStyles }}>
	 <Typography variant="h1" sx={theme.typography.typography1}>Γιορτές και πανηγύρια</Typography>
	
     <img src={Image09}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Πολλές γιορτές και πανηγύρια συνόδευαν τον κοινωνικό βίο των Περγαμηνών, δίνοντας την απαραίτητη νότα χαλάρωσης και ευθυμίας, συνέχοντας ταυτόχρονα
	την ελληνική κοινότητα γύρω από τα θέσμια της φυλής. <br/>
	Το κεντρικό και παλλαϊκό πανηγύρι της Περγάμου ήταν αυτό ΄΄της Εύρεσης της εικόνας του Αγίου Γεωργίου΄΄.  Χρονολογείτο από το 1889, οπότε και αποκαλύφθηκε 
	το εικόνισμα του Αγίου στις 17 Αυγούστου. Διαρκούσε τρεις μέρες, από 15 ως και 17 Αυγούστου και προσέλκυε προσκυνητές από τις γύρω περιοχές, από τα νησιά του 
	Αιγαίου καθώς επίσης και αλλόφυλους, ιδίως Τούρκους που εσέβοντο το θαυματουργό ΄΄Άγιο Χεντρελέζ΄΄ ( άγιο Γεώργιο). <br/>
	Το πανηγύρι όμως του ΄΄Αγίου Γεωργίου του όξου΄΄, ένα ερημικό ξωκλήσι , ήταν το πιο εντυπωσιακό. Γινόταν στις 22-23 Απριλίου και περιελάμβανε επιβλητικό 
	τελετουργικό  ΄΄αλογατόρηδων΄΄ (καβαλάρηδων), με περίτεχνες επιδείξεις των ικανοτήτων αναβατών και αλόγων. <br/>
	Άλλες σημαντικές γιορτές και πανηγύρια ήταν της ΄΄Αγίας Παρασκευής της όξου΄΄ την Λαμπροπαρασκευή ή της Ζωοδόχου Πηγής και τα ΄΄ρουβίθια των 
	Αγίων Θεοδώρων΄΄.  Το Σάββατο της πρώτης εβδομάδας της Μεγάλης Σαρακοστής, γιόρταζε η ΄΄Απάνου ακκλησιά΄΄, οι Άγιοι  Θεόδωροι και τότε σε όλη την ελληνική 
	συνοικία μοίραζαν μέσα σε ΄΄μπακράτσα΄΄ (χάλκινα σκεύη) ρεβίθια που έβραζαν μέσα σε καζάνια στο προαύλιο της εκκλησίας. Τα ΄΄ρουβίθια΄΄ πίστευαν τα μοίραζε ο 
	Άγιος Θεόδωρος γι’ αυτό και θεωρούσαν μεγάλη αμαρτία να χυθεί έστω και μια σταγόνα λάδι. Σε καλό τους είχαν να δοκιμάσουν και οι αλλόθρησκοι αλλά και οι 
	φυλακισμένοι. </Typography>
	</Box>
	
	<ScrollToTopButton />
	
	
	<Box sx={{ ...commonBoxStyles }}>
	 <Typography variant="h1" sx={theme.typography.typography1}>Αντί επιλόγου</Typography>
     <img src={Image10}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
	<Typography variant="h3" sx={theme.typography.typography2}>Καλέ Αφρουδίτ’, θεια  Πιρσιφόν ’, θεια-τσατσαπιρμαθούλα, βρε Ατταλέλ’ αντήστε (ετοιμαστείτε) να φύγουμι, αν η ώρα (εδώ και πολλή ώρα) που χτύπ’σι τούτου 
	του γιανγκίν (πυρκαγιά), η νους μ’ πλαλά (τρέχει) κι η καρδιά μ’ δε νταγιαντά (βαστά) να βλέπ’ άλλου. Αντήστε να φύγουμι. <br/>
	Ούλου κι συ ξιχαίνω (λησμονώ) κι ούλου κι συ κουπιάζω. Κι συ σένα γείτονα μ’ μέχρι χτες…..Ίλαμ (σώνει και καλά) του δικό σ’ να γέν’’’.<br/>
	‘‘ Μην κλαις νενέ. Γκιουλέ γκιουλέ (να πας στο καλό). Έχουμε κι οι δυο τον ίδιο πόνο. Ο τόπος που γεννηθήκαμε δε θα είναι ποτέ πιο ο ίδιος, γιατί οι άνθρωποι 
	δε σκέφτονται σωστά. Γκιουλέ γκιουλέ’’.</Typography>
	</Box>
	<br/><br/>
	
	
	<Box sx={{ ...commonBoxStyles }}>
	 <Typography variant="h1" sx={theme.typography.typography1}>Από το περγαμηνό λεξιλόγιο</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}><br/>
	αρσίζικο = λαίμαργο<br/>
	αχλιά = στάχτη<br/>
	γιγούρτ = γιαούρτι <br/>
	διάρμισμα = συγύρισμα<br/>
	δρακιάρικο = κλαψιάρικο<br/>
	ζεμπίλι = τσάντα μεγάλη από ψάθα<br/>
	ιρκέτς = περυσινό κατσίκι<br/>
	καυκουμάνα = ποτήρι τσαγιού<br/>
	κιντί = δειλινό <br/>
	κουμούτσια = κομμάτια ψωμιού<br/>
	κουφνίδα = κοφίνι<br/>
	κριγιάς = κρέας <br/>
	μάγ’να = κέρματα<br/>
	μπερεκέτι= αφθονία <br/>
	μπιτίσω = αποτελειώνω<br/>
	μπουκουνιά = δαγκωματιάv<br/>
	μουζντελίκια = ευφρόσυνη είδηση<br/>
	ναμούσι = υπόληψη, τιμή<br/>
	νένε = γιαγιά<br/>
	ντουπέκ = γουδί<br/>
	πιασμός = πυρετός<br/>
	πισκίρια = πεσέτες<br/>
	πιτταστή = λαγάνα<br/>
	ρούχο = έμμηνος ροή<br/>
	τέστο = πήλινο τσουκάλι<br/>
	τζαναμπέτικο = στριφνό<br/>
	τζανερίκια = κορόμηλα<br/>
	τουρσέκι = τρίστρατο<br/>
	φλουμάρια = χυλοπίτες<br/>
	φρουκάλισμα = σκούπισμα<br/>
	χουσμέτι = θέλημα<br/></Typography>
	</Box>
	
	<ScrollToTopButton />
	
	<Box sx={{ ...commonBoxStyles }}>
	 <Typography variant="h1" sx={theme.typography.typography1}>Φωτογραφικό Υλικό</Typography>	
     <img src={Image11}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image12}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image13}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image14}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image15}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image16}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image17}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image18}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image19}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image20}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image21}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image22}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image23}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image24}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image25}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
     <img src={Image26}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		
		
	</Box>	
	
	<ScrollToTopButton />



    </>
  );
}

export default P3_Hmerologia;
