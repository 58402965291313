import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g16/01_Aggeliki_Matheou.jpg";
import Image02 from "../assets/images/mikrasiatika_g16/02_Aggeliki_Matheou.jpg";

function G16_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Η ιστορία της Αγγελικής Ματθαίου, το γένος Καπετάνιου Νικολάου Πολίτη από τις Φώκιες της Σμύρνης" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Είμαι η Αγγελική του Καπετάν Νικολή Πολίτη η κόρη. Γράφω την ιστορία
          της Μικράς Ασίας τώρα, το 1995. Το 22 ήμουν ένα κοριτσάκι 6 έως 7
          χρονών. Ότι θυμάμαι θα γράψω. Είμαι του Καπετάν Νικολή Πολίτη από τις
          Νέες Φώκιες της Σμύρνης. Ο πατέρας μου ήταν Καπετάνιος. Είχαμε ένα
          μεγάλο καΐκι. Το είχε ονομάσει ο πατέρας μου “ Πανούσα ”, επειδής
          έλεγαν την μητέρα μου Παναγιώτα και επειδής την αγαπούσε πολύ ονόμασε
          το καΐκι. Ήμασταν 6 άτομα παιδιά και ο πατέρας και η μητέρα. Γράφω ότι
          θυμάμαι.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο πατέρας μου ήταν από την Κωνσταντινούπολη και ήλθε στις Φώκιες και
          παντρεύτηκε λέγανε την ωραία Παναγιώτα. Ζούσαμε πολύ καλά διότι είχαμε
          πολύ περιουσία. Ο πατέρας μου ήταν μοναχοπαίδι. Είχαμε δύο σπίτια. Το
          ένα ήτανε στο Φαρδύ Σοκάκι και το άλλο έξω από την πόλη. Ήτανε
          μονοκατοικία. Η πρόσοψη του ήταν με 4 καμάρες. Στη μεσαία καμάρα ήτανε
          η πόρτα. Μόλις έμπαινες ήτανε ένα μεγάλο χαγιάτι και στο καθιστικό
          είχαμε ένα μεγάλο τζάκι και ένα παράθυρο που έβλεπες τη θάλασσα. Δίπλα
          ήτανε μία μεγάλη κουζίνα και ένα μεγάλο ντουλάπι. Δίπλα στην κουζίνα
          ήτανε η σκάλα και έβγαινες σε ένα μεγάλο χαγιάτι. Από αριστερά ήτανε
          του αδελφού μου που τον λέγαμε Θανάση. Μετά ήτανε το μεγάλο δωμάτιο
          που κοιμότανε η μητέρα και ο πατέρας ή οι μουσαφίρηδες όταν είχαμε
          μουσαφίρηδες. Ήταν στολισμένο με ένα μεγάλο καθρέφτη, μία μεγάλη
          ντουλάπα που βάζανε τα στρώματα και τα παπλώματα και αυτά τα σκεπάζανε
          με μεγάλη κουρτίνα. Το λέγανε Γιουκλούκι και δίπλα ήτανε ένα δωμάτιο
          που ήτανε αποθήκη. Βάζαμε τα σύκα, τα καρύδια, τις σταφίδες, τη ξερή
          μουσταλευριά, το λάδι, το πετιμέζι, τα σουτζούκια.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο πατέρας μου είχε 400 δένδρα ελιές, είχε ένα αμπέλι στα 3 πηγάδια και
          το άλλο στα Μερσυνάκια. Εκείνο ήταν πιο κοντά στην πόλη και πηγαίναμε
          το καλοκαίρι, εγώ και η αδελφή μου. Κοιμόμαστε στο κάτω το καθιστικό.
          Η αυλή μας ήτανε χοχλάδια της θάλασσας. Σχημάτιζε μαργαρίτες. Γύρω
          γύρω είχε παρτέρια που φύτευε η μητέρα μου κατιφέδες και βασιλικά. Από
          έξω ήτανε τσιτσιφιές. Όταν ανθούσαν μοσχομύριζε ο τόπος η δε θάλασσα
          ήτανε σαν 100 μέτρα από το σπίτι μας και όλη η ακρογιαλιά ήτανε
          ανεμόμυλοι που αλέθανε το σιτάρι. Αυτά για το σπίτι μας.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Τώρα θα γράψω για την Καταστροφή. Την νύκτα βγαίνανε στα παράθυρα και
          βλέπανε στα βουνά φωτιές. Άρχισε ο κόσμος να φοβάται. Η μητέρα μου δεν
          ήξερε τι να κάνει γιατί ο πατέρας μου έλειπε ταξίδι. Το δεύτερο βράδυ
          μαζεύτηκε όλη η γειτονιά στο δικό μας σπίτι. Θυμάμαι που κάνανε μία
          τούρκικη σημαία με το μισοφέγγαρο και τη βάλανε στο παράθυρο για να
          δούνε οι Τούρκοι. Το ίδιο βράδυ ήλθε ο πατέρας μου. Μας είπε να μην
          φοβόμαστε. Κλειστήκαμε στο σπίτι.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ξαφνικά πλάκωσε το τούρκικο στρατό. Ακούγαμε ντούπανα, ντουφεκιές,
          τραγούδια. Τα άλογα χηλιμητρούσανε. Δεν μπορώ να σας πω το τι γινόταν.
          Τότε σηκώθηκε ο πατέρας μου να πάει να δη το καΐκι για να φύγουμε. Η
          μητέρα μου δεν τον άφηνε και της είπε θα πάω από άλλον δρόμο. Πήγε
          αλλά το καΐκι το είχανε καταστρέψει. Δεν είχε ούτε κατάρτια ούτε πανιά
          ούτε μηχανή. Μας λέει θα πάμε στον Ασματερέ, εκεί ήταν ένα λιμανάκι
          και ίσως βρούμε καΐκι να φύγουμε για τη Μυτιλήνη. Φύγαμε από τα βουνά.
          Εμένα με σήκωνε ο αδελφός μου στην ράχη του. Νύκτα ήτανε. Τα βουνά
          ήταν τόσο άγρια. Φωνάζανε τα σκυλιά, τα τσακάλια. Τα σκυλιά από τα
          εξοχικά σπίτια τρέχανε. Τα αγριογούρουνα μουκρήζανε τα ζητλάνια. Λες
          και θα χαλούσε ο κόσμος. Αμίλητοι φθάσαμε στο λιμανάκι αλλά δεν υπήρχε
          τίποτε. Μόνο τα ζώα του κόσμου φωνάζανε λες και είχανε καταλάβει τον
          χωρισμό από τους δικούς τους. Την άλλη μέρα οι Τούρκοι κατασκήνωσαν σε
          ένα χωράφι πολύ μεγάλο που το λέγανε Σητσακτερέ και άρχισαν και
          κατεβαίνανε στην χώρα. Μαζεύανε τον κόσμο. Άλλον σκοτώνανε, άλλον
          κοπανούσανε με τα ντουφέκια. Τους άλλους τους ρίχνανε κάτω και τους
          πατούσανε στην κοιλιά και οι ανθρώποι κάνανε εμετό. Εμείς είχαμε φύγει
          κρυφά και μπήκαμε σε ένα μεγάλο σπίτι αλλά μας βρήκανε. Κατεβάσανε τον
          Πατέρα μου, τον βγάλανε το σαλβάρι του, το σακάκι του, τα παπούτσια
          του. Του πήρανε τη μεγάλη σακούλα με τα λεφτά και τα χρυσαφικά και μας
          βάλανε μαζί με άλλους που μαζεύανε από τα σπίτια τους. Μας πήγαν σε
          ένα χωριό που το λέγανε Τσακμακλή. Μας αφήσανε σε ένα χωράφι και
          αρχίσανε να μαζεύουν τους άνδρες. Πήρανε τον πατέρα μου τον αδελφό μου
          και όλους. Μετά ακούσαμε τα πολυβόλα. Μας λέγανε όλοι ότι τους
          σκοτώσανε. Από τότε δεν τους είδαμε. <br />
          Το ίδιο βράδυ πήρανε την αδελφή μου στα βουνά. Παίρνανε τα κορίτσια.
          Την αδελφή μου την πήρανε τέσσερις. Είχε μακριές πλεξούδες. Οι δυο την
          πιάσανε από τις πλεξούδες και οι άλλοι δύο από τα πόδια. Το τι
          γινότανε εκείνο το βράδυ δεν λέγεται. Χαλούσε ο κόσμος. Φωνάζανε τα
          κορίτσια, κλαίγανε οι μάνες τους, φωνάζανε, βουίζανε τα βουνά. Εγώ με
          είχε η μητέρα μου κάτω από το φουστάνι της και έτρεμα σαν το φύλλο. Η
          μητέρα μου σηκώθηκε και φώναζε παιδί μου Κατερίνα που σε πάνε και ένας
          τούρκος της δίνει μια με το ντουφέκι και πέφτει και σπάει το κεφάλι
          της. Τρέχανε τα αίματα. Εγώ σήκωνα το φουστανάκι μου και τη σκούπιζα.
          Την άλλη μέρα μας πήρανε από κει και αρχίσαμε να βαδίζουμε από βουνά
          όχι από δρόμους. Μας είχανε πάρει τα παπούτσια μας και τα βουνά ήτανε
          στρωμένα από αγκάθια. Ήταν αδύνατο να βαδίσομε. Εκεί ήτανε το πολύ
          ξύλο οι κλωτσιές. Βαδίζαμε το βράδυ. Φθάσαμε σε ένα χωριό που το
          λέγανε Τσακμακλή. Ήτανε ελληνικό χωριό. Εκεί μας χώσανε τον έναν απάνω
          στον άλλον. Για να μας βάλουνε μέσα σκοτώσανε 2 παιδιά και μια γυναίκα
          και ξύλο με τα καμιτσιά αλύπητα. Ο κλαυθμός και ο φόβος ήτανε
          αβάστακτα. Εκείνο το βράδυ πήρανε και τη μανούλα μου. Ξέχασα να γράψω.
          Είχαμε και ένα μωράκι και όταν την πήρανε κρατούσε και το μωρό. Της το
          πήρανε και το πετάξανε σε έναν καλαμιώνα που ήτανε λίμνη και πάει και
          αυτό τη δε μανούλα μου την έφεραν. Να σκεφτείτε τον πόνο της και τα
          δάκρυα της.
          <br /> Έμεινα μόνο εγώ. Με σκέπαζε με το φουστάνι της σαν τη κλώσα που
          βάζει τα πουλάκια της κάτω από τα φτερά της. Κάνω πολλά λάθη γιατί δεν
          μπορώ να γράψω από τα δάκρυα και τον πόνο που έχω. Μου έχουν μείνει
          αποθέματα. Είναι μεγάλος πόνος να μείνεις ορφανό τόσο μικρό και στα
          τούρκικα χέρια. Παρακαλώ τον Θεό όλα τα παιδάκια να έχουν τη μανούλα
          τους. Να μην πονέσουνε σαν εμένα. Άκουγα μανούλα που φωνάζανε και
          μαραινόμουν σαν το φύλλο που πέφτει από το δένδρο. Το πιο γλυκό πράγμα
          του κόσμου είναι η μάνα. Όλα αυτά τα χρόνια που ζω δεν τη ξέχασα. Το
          δε πρωί μας πηγαίνανε σε ένα χωριό που το λέγανε Μελεμένη. Το χωριό
          ήτανε κάρβουνο. Το είχανε κάψει οι δικοί μας στρατιώτες όταν
          οπισθοχωρήσανε. Ήταν ελληνικό χωριό. Μας περάσανε από μέσα για να
          δούμε που ήτανε καμένα. Εκεί είπαμε ότι εδώ θα μας κάψουνε και μας
          χτυπούσανε τόσο άγρια που μας φωνάζανε οι δικοί μας να σκύβουμε σαν τα
          πρόβατα. Εκεί κόψανε μιας γυναίκας τη μύτη. Με τα πολλά φύγαμε από το
          χωριό. Βαδίζοντας βγήκαμε σε ένα χωράφι που είχε άγριες αχλαδιές και
          ήταν φορτωμένες αχλάδια. Όσα μπορούσανε κόψανε να φάνε αλλά πιο πολύ
          ήτανε το ξύλο. Συνέχεια βαδίζαμε. Πάμε πιο πέρα που ήτανε ένα αλώνι
          και αλωνίζανε κουκιά, φασόλια. Μόλις τα είδε ο κόσμος τρέξανε να
          μαδήσουνε. Η Τούρκοι δεν μας έβλεπαν γιατί τα δεμάτια ήτανε πολύ ψηλά.
          Οι Τούρκοι από μέσα δεν μας βλέπανε. Οι τσανταρμάδες είχανε μπει μέσα
          στο αλώνι και μιλούσανε με τους χωριάτες. Μάδησα και γω λίγα και όταν
          καμιά φορά ανάβανε φωτιά τα ψήναμε με την Μανούλα μου και ξεχνούσαμε
          λίγο την πείνα μας. Συνέχεια βαδίζαμε δεν ξέρω πόσες μέρες. Πηγαίναμε
          για τη Μαγνησία. Βγαίνανε και το φωνάζανε ότι πάμε στην Μαγνησία. Είχε
          άνδρες που ξέρανε τούρκικα και μας φωνάζανε ότι πάμε στην Μαγνησία να
          μας βγάλει λόγο ο Κεμάλ.
        </Typography>

        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Και που νομίζεται που μας πήγανε. Σε ένα παλιό εργοστάσιο και μαζέψανε
          τους άνδρες, τους βάλανε μέσα τους γδύσανε και αρχίσανε και τους
          χτυπούσανε με τα καμιτσιά. Οι άνδρες πηδούσανε σαν ποντίκια. Τους
          είχανε μαύρους. Οι άνθρωποι πέσανε όλοι κάτω σαν πεθαμένοι με βογκητά
          με κλάματα από τους πόνους.
          <br />
          Το χειρότερο ήτανε ότι μαζέψανε τα γυναικόπαιδα και μας πήγανε σε ένα
          χωράφι γεμάτο μνήματα από Έλληνες στρατιώτες και σε κάθε μνήμα είχαν
          βάλει τα δίκοκα τους. Εκεί φανταστείτε τι έγινε. Πέσαν οι γυναίκες στα
          μνήματα φωνάζανε κλαίγανε και από πάνω κλωτσιές, πέτρες. Σπούσανε
          δένδρα κλαριά και τις κοπανούσανε. Πολλές μάνες είχαν χάσει τα παιδιά
          τους σε αυτόν το πόλεμο. Μα δεν υπήρχε Θεός ; Δεν υπήρχε κανένας νόμος
          για μας ; Μετά μας πήγανε σε αμπέλι και κόβανε τα φύλα και τα χώνανε
          στο στόμα να τα φάνε και όποιος δεν τα μάσαγε τον τρίβανε τα μούτρα
          του στο χώμα. Μια γυναίκα δεν το δεχότανε και της βάλανε φωτιά στο
          φόρεμα της και κάηκε σαν κερί και δεν αφήνανε κανένανε να πάει κοντά
          της. Αυτά που κάνανε αυτοί οι βάρβαροι δεν τα έκανε κανένα κράτος.
          Μετά φύγαμε από την Μαγνησία και μας πηγαίνανε για μια μέρα όλο από
          βουνά και αγκάθια. Φθάσαμε σε μια μικρή πόλη. Εκεί ήτανε σταθμός
          χωροφυλακής. Μας υποδεχτήκανε πολύ άγρια ως συνήθως. Από κάτω από το
          κτήριο είχε μια χαβούζα με νερό. Το νερό αυτό είχε μέσα ότι βρωμιά
          ήθελες. Και δεν ήτανε μόνο η βρωμιά αλλά κατουρούσανε και από πάνω.
          Και χονδρά και ψιλά. Αλλά ο κόσμος ήτανε τόσο διψασμένος που σπρώχνανε
          της βρωμιές και πίνανε και πλύνανε και τα μούτρα τους και αυτοί κάνανε
          γέλια και λέγανε πως γκιαούρη αυτό θα πει. Βρώμικοι Έλληνες.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Η μανούλα μου με πήρε και πήγαμε στο χάνι που βάζανε τα άλογα με ένα
          μέτρο η κοπριά. Κάτσαμε. Χωθήκαμε στην κοπριά για να ζεσταθούμε. Σε
          λίγο ήλθανε και οι άλλοι. Λέγανε ότι έπεσε και πνίγηκε μια γυναίκα.
          Αυτό δεν το είδα. Το ψωμί και το φαΐ το είχαμε ξεχάσει. Αν βρίσκανε
          στα βουνά καμήλες ψόφιες τρέχανε και ξεσχίζανε με τα χέρια και άμα
          σταματούσαμε σε κανένα χωριό γυρεύανε φωτιά και τα ψήνανε και τα
          τρώγανε. Η μανούλα μου δεν έτρωγε ούτε και γώ. Μας πήρανε πάλι από
          κει, άλλαξαν οι τσανταρμάδες. Σε κάθε χωριό μας παίρνανε άλλοι. Μας
          περάσανε από ένα ποτάμι που είχε λιγοστό νερό αλλά είχε πολλά
          αγριοσέληνα. Όταν τα είδε ο κόσμος πέσανε με τα μούτρα σαν τα πρόβατα.
          Δεν έμεινε ούτε φύλο. Εκεί σε κείνο το λίγο νερό πλυθήκαμε λουστήκαμε
          χωρίς σαπούνι βέβαια. Πάλι αρχίσαμε την διαδρομή μας. Στο χωριό που
          πηγαίναμε μας είπανε ότι εκεί που θα πάμε είναι Ελληνικό χωριό και
          είχε Έλληνες. Τα λέγανε σε έναν που ήξερε τούρκικα και αυτός μας τα
          έλεγε. Τυχαίνανε καμιά φορά και καλοί τσανταρμάδες. Πήγαμε στο χωριό.
          Ήτανε νύχτα και κείνο το βράδυ έριχνε χιόνι. Ήλθαν οι Έλληνες μας
          φιλούσαν, χαϊδεύανε τα παιδάκια και έτσι που ήμαστε κουβάρες κουβάρες
          μας βάλανε όλους κοντά και φέρανε ένα πολύ μεγάλο κιλίμι και μας
          σκεπάσανε. Μετά ήλθαν την νύκτα πάλι και φέρανε και άλλο κιλίμι και
          δεν μας πείραξε το χιόνι. Το πρωί φέρανε γάλα ολόκληρα καζάνια ζεστό
          και ψωμί. Φάγαμε όσο θέλαμε. Μας ρωτούσαν αν είναι καλό το φαΐ. Δεν
          ξέραμε τούρκικα. Μας τα εξηγούσε ένας που ήξερε. Μας ρωτούσανε που θα
          μας πάνε. Μήπως ξέραμε και εμείς λέγανε. Και μας θα μας διώξουνε.
          Αυτοί λέγανε ότι περιμένανε του Βενιζέλου την ανταλλαγή.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Αρχίσαμε πάλι τα βουνά. Το χωριό ήτανε κοντά. Μόλις μας είδανε πλάκωσε
          η τουρκιά. Αρχίσανε όλοι νύκτα να παίρνουνε τα κορίτσια. Ψάχνανε με
          τους φακούς οι δε τσανταρμάδες φεύγανε και οι χωριάτες βρίσκανε
          ευκαιρία. Φωνάζανε τούρκικα άλλην άλλην θα πει στα ελληνικά πάρτε
          πάρτε και κείνο το βράδυ τραβήξανε την μανούλα μου και έτσι που πήγε
          να σηκωθεί είδαν εμένα που με είχε από κάτω από το φουστάνι της.
          Αρπάξανε εμένα. Από τον πολύ σπαραγμό πού έκανα θυμάμαι που έβγαλε το
          φέσι του και μου έκλεισε το στόμα με πήγε πιο πέρα και άρχισε να με
          ψάχνει αφού με έριξε κάτω. Σκεφτείτε εκείνη την ώρα τον σπαραγμό μου
          και τις φωνές μου και αφού είδε που ήμουν παιδάκι μου δίνει μια
          κλωτσιά και με αφήνει.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Εγώ μες την νύκτα δεν ήξερα που πάω. Έτρεχα με κομμένα τα πόδια. Από
          την μια φώναζα εγώ και από την άλλη ερχότανε η μανούλα μου
          ξεμαλλιασμένη να φωνάζει Αγγελική αίμα μου παιδί μου που είσαι. Εγώ με
          τα κλάματα και αυτή με τις φωνές βρεθήκαμε. Δεν ξέρω να την περιγράψω
          αυτήν την περιπέτεια. Εσείς που θα την διαβάσετε μπορείτε να
          καταλάβετε. Φύγαμε πάλι από κει. Μας πήγανε σε ένα άλλο χωριό. Εκεί
          μείναμε κάτω από μια γέφυρα. Εκεί βρήκαμε σαλιγκάρια και τα ψήναμε και
          τρώγαμε. Σε λίγο φέρανε με ένα κάρο δύο σακιά κριθάρι. Μας είπανε να
          πάρομε. Πήρε ο κόσμος. Πώς τρωγότανε ; Οι δε τσανταρμάδες φύγανε και
          τότε βγήκανε οι άνδρες και βρήκανε παλιοτενεκέδες και ανάψανε φωτιές
          και καβουρντίζαμε το κριθάρι και τρώγαμε.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την άλλη μέρα χιόνιζε. Μας είπανε θα πάμε στο Ουσάκ. Βαδίζοντας μέσα
          στο χιόνι αρχίσανε τα πόδια μας και σουβλίζανε. Τότε η Μανούλα μου
          βγάζει έναν μπούστο πού φορούσε τον κομμάτιασε και δέσαμε τα πόδια
          μας. Μα πόσο να βαστάξει το πανί; Βαδίζαμε μια νύκτα. Φθάσαμε μέρα.
          Μας βάλανε σε κάτι μεγάλους στάβλους που βάζανε οι στρατιώτες τα
          άλογα. Η κοπριά ήτανε ένα μέτρο. Εμείς χωθήκαμε να ζεσταθούμε. Σε λίγο
          μας βγάλανε έξω και μας δώσανε γαλέτα σκουληκιασμένη. Εμείς τη φάγαμε
          και μας έπιασε μια διάρροια που δεν προλαβαίναμε. Εκεί πέθαναν πολλά
          παιδιά. Εκείνο το βράδυ ήλθανε πολλοί τούρκοι και παίρνανε γυναίκες να
          τις βιάσουνε και μια γυναίκα έριξε τον τούρκο κάτω και τα έκανε στα
          μούτρα του. Το πρωί ήρθε ο τούρκος και τη γύρευε. Κανένας βέβαια δεν
          μαρτύρησε. όλοι λέγανε καλά τον έκανες. Από κει πάλι πήραμε τον δρόμο.
          Μας είπανε πάμε για τη Κόννια. Βαδίζαμε πολλές μέρες χωρίς νερό χωρίς
          ψωμί. Κάποια μέρα φθάσαμε. Μας βάλανε σε ένα χάνι ως συνήθως. Ξέχασα
          να γράψω. Δεν μας πήγανε στην Κόννια. Σε χωριό του Ικονίου. Εκεί
          ήρθανε οι τούρκοι και βιάζανε τις γυναίκες μπροστά στους άνδρες τους
          και τα παιδιά τους. Σηκωθήκανε δύο άνδρες να τους σπρώξουνε αλλά τους
          πήρανε και δεν ξέρουμε τη έγιναν. Εκεί σε αυτό το χωριό καθίσαμε πάλι
          σε έναν στάβλο με πάπλωμα την κοπριά. Η μανούλα μου με είχε στην
          αγκαλιά της και κοιμόμουνα. Ήλθε κοντά ένας τούρκος και με τραβά από
          το χέρι και μου έλεγε να σηκωθώ αλλά με καλό τρόπο. Εγώ δεν
          σηκωνόμουν. Από τα τόσα που έβλεπα έσφιγγα πιο πολύ την μανούλα μου
          και έκλαιγα. Στο τέλος ήλθε κοντά μας ο άνθρωπος που ήξερε τούρκικα
          και του είπε ο τούρκος ότι θέλει να με κάνει παιδί του.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Εκείνη την ώρα καταλαβαίνετε τι έγινε. Με έσφιξε στην αγκαλιά της και
          θυμάμαι το λόγο που μου είπε. Πήγαινε παιδάκι μου. Εγώ θα πεθάνω και
          που θα μείνεις. Δεν έχουμε πια κανένανε και όταν τελειώσει ο πόλεμος
          να έλθεις στην Σμύρνη. Ο τούρκος έφυγε και σε λίγο ήλθε με ένα άλογο
          και έδωσε στην Μανούλα μου ψωμί και σουτζούκια. Αυτόν τον χωρισμό δεν
          θα τον ξεχάσω. Με καθίζει εμένα στα καπούλια του αλόγου. Καβάλησε και
          αυτός. Με έκανε νόημα να τον κρατώ. Το χωριό ήτανε μακριά. Στον δρόμο
          κατέβηκε να κάνει το νερό του. Εγώ έκλαιγα και έλεγα τώρα θα με
          σκοτώσει. Αυτός έβλεπε ότι φοβόμουνα και με χάιδευε. Αλλά στα καπούλια
          του αλόγου είχε βάλει ένα χαλάκι και αυτό με πλήγωνε και γώ άρχισα να
          κλαίγω. Αυτός το κατάλαβε που πονούσα και κατέβηκε και κατέβασε και
          μένα και είδε τα αίματα. Βγάζει ένα μαντίλι και τα σκούπισε και έβαλε
          το μαντήλι στο χαλάκι και φύγαμε. Φθάσαμε στο χωριό βράδυ. Έξω από το
          χωριό ήτανε το σπίτι. Κατέβηκε αυτός κατέβασε και μένα. Έδεσε το άλογο
          στης πόρτας τον χαλκά κτυπάει την πόρτα. Της μίλησε τούρκικα. Ανοίγει
          λίγο την πόρτα και βγαίνει μια γριά κουκουλωμένη με μαύρο μαντήλι. Της
          είπε κάτι λόγια και με φίλησε και έφυγε.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Όταν μπήκα μέσα βγήκε από ένα δωμάτιο μία κοπέλα και μόλις με είδε
          έπιασε την μύτη της ότι βρωμάω. Αμέσως άναψε η γριά φωτιά και ζέστανε
          νερό να με πλύνουνε. Πολεμούσε η γριά να λύση τα μαλλιά μου. Είχανε
          τόσα αγκάθια και ψείρες που η γριά όλο μιλούσε. Εγώ δεν ήξερα τι έλεγε
          για να σας το γράψω. Ο τούρκος ο μπαμπάς ερχότανε κάθε εβδομάδα και
          μου έφερνε ζαχαρωτά. Με κάθιζε στα γόνατα του αλλά αυτή δεν
          παρουσιαζότανε. Μόνο με την γριά μιλούσε. Με έλουζε κάθε μέρα η γριά.
          Όταν τελείωνα πήγαινα σε ένα κηπαράκι που είχε ήλιο για να ζεσταθώ.
          Εκεί έβλεπα τα πουλάκια και έκλαιγα και τους έλεγα πουλάκια μου που
          είναι η μανούλα μου. Γιατί με έφεραν εδώ; Ήμουν πολύ άρρωστη και με
          βάζανε με το ζόρι να τρώω. Το φαΐ τους ήτανε όλο πράσα. Μόλις έτρωγα
          δύο μπουκιές αμέσως έτρεχα να κάνω εμετό. Έτρεχε η κόρη και με
          κτυπούσε με ένα σχοινί. Η γριά τη μάλωνε. Έφευγα ή πλάγιαζα σε μια
          γούνα. Εκείνη η γούνα ήτανε το στρώμα μου και το πάπλωμα μου. Μια μέρα
          μου έδωσε ένα λαήνι και με έδειξε το πηγάδι να φέρω νερά. Πήγα στο
          πηγάδι και βλέπω έναν κουβά. Πώς να ρίξω τον κουβά να πάρω νερό; Εγώ
          δεν είχα πνοή επάνω μου. Έβλεπα γύρω γύρω ίσως έλθει κανείς να μου
          γεμώση το λαηνάκη. Το παίρνω το λαηνάκη και πάω στο σπίτι. Έρχεται
          αυτή βλέπει το λαηνάκη άδειο το αρπάει με πολύ θυμό και το κοπανάει
          μπροστά στα πόδια μου και μετά παίρνει το σχοινί και με κοπανούσε σαν
          αφηνιασμένη και με έβριζε γκιαβούρ. Αυτή με έστειλε στο πηγάδι για να
          πνιγώ. Δεν με ήθελε. Αυτή ήτανε μεγάλη γυναίκα, η χήρα ή γεροντοκόρη
          και ζούσε με την μάνα της.
          <br /> Η γριά με έφερνε σύκα ξερά. Τα είχανε μέσα στο λάδι και τα
          τρώγανε μα εγώ δεν μπορούσα να τα φάγω. Τα άφηνα εκεί. Εγώ ήθελα λίγη
          σούπα ζεστή λίγο γάλα. Αυτά είχα στο μυαλό μου. Τώρα θα πάω σε άλλη
          περιπέτεια. Μια μέρα εκεί που ήμουνα στη γούνα βλέπω την χανούμ και
          έβαφε το πρόσωπο της με μπογιές κόκκινες. Έβαζε ελιές μαύρες και μετά
          έστρωσε δύο προβιές από καμήλα. Την μία από την μια και την άλλη από
          την άλλη μεριά της πόρτας και στάθηκε στην μια προβιά κουκουλωμένη με
          ένα μεγάλο τούλι κόκκινο και περίμενε . Μόλις νύχτωσε άκουσα να ψάλουν
          οι χοτζάδες. Μπαίνει μέσα ο γαμπρός και κάνανε ναμάζ προσευχή. Μετά
          πάει κοντά της και σηκώνει το τούλι και την παίρνει αγκαλιά και πάνε
          στον οντά (δωμάτιο). Μετά από λίγες μέρες μου λέει ο τούρκος θα πάμε
          μαμά. Όταν άκουσα εγώ μαμά το κατάλαβα και έπεσα στην αγκαλιά του και
          έκλαιγα. Αυτή θύμωσε και με αρπάζει και με πετά πέρα. Ο τούρκος την
          μάλωσε. Μετά με γδύνει και παίρνει το φουστανάκι από τον τοίχο που το
          είχε πετάξει. Μου το βάζει. Ήτανε μες την βρόμα και ψείρα και μου το
          φόρεσε. Με παίρνει ο τούρκος να φύγουμε. Όταν έβγαινα μου δίνει μια
          κλωτσιά. Απ έξω ήτανε ένα κάρο με βόδια. <br />
          Με βάζει ο τούρκος και πέφτω στο κάρο. Είχε πολύ κρύο. Βγάζει το
          μανδύα του και με σκεπάζει. Μου είπε να κοιμηθώ μα εγώ είχα την χαρά
          που θα που θα πάω στην μανούλα μου. Πηγαίναμε ώρες πολλές. Φθάσαμε στο
          Ικόνιο. Ανεβήκαμε τις σκάλες. Μου κάνει νόημα να περιμένω. Εκεί ήτανε
          αστυνομία. Πήγε μίλησε για μένα. Φεύγει ο τούρκος. Εγώ στεκόμουνα και
          περνούσανε οι τούρκοι και μου λέγανε τεσλήμ; Εγώ δεν ήξερα και
          έκλαιγα. Νόμιζα ότι έλεγαν θα σε σκοτώσουνε; Σε λίγο έρχεται ο μπαμπάς
          έτσι τον έλεγα και μου φέρνει σε μία μανδήλα σαν αυτή που φοράει ο
          Αραφάτ. Είχε ένα μεγάλο ψωμί ένα κομμάτι τυρί και μου βάζει στην τσέπη
          μου επτά μπανκανότες. Με φίλησε στο κούτελο και έφυγε. Μετά ήτανε το
          μαρτύριο. Έρχεται ένας τσανταρμάς και μου λέει … σήκω. Εγώ δεν είχα
          δύναμη και καθόμουνα χάμου. Απέναντι στο κονάκι ήτανε ένας μεγάλος
          στάβλος. Βγάζει μια μεγάλη κλειδαριά και με πετάει μέσα. Σκοτάδι.
          Σταμάτησα λίγο να δω που να ακουμπήσω. Από το κλάμα δεν έβλεπα. Είχε
          ένα παραθυράκι στενόμακρο. Από κει έμπαινε λίγο φως. Εγώ πασπατώντας
          βρήκα ένα παχνί με άχυρα. Ανέβηκα και λούφαξα στα άχυρα. Αλλά είχα το
          τυρί και τα ποντίκια πηδούσαν πότε επάνω στο κεφάλι μου πότε στην
          πλάτη μου. Εκεί ήτανε ο μεγαλύτερος φόβος. Σκέφτηκα και άδειασα την
          μαντίλα στο παχνί και τυλίχτηκα γιατί είχε πολύ κρύο. Το τρομερό δράμα
          που πέρασα ήταν αυτό. Δεν θυμάμαι πόσες μέρες έμεινα εκεί μέσα. Μια
          μέρα ανοίξανε την πόρτα και με φώναζε ένας τσανταρμάς. <br />
          Πλησίασα κοντά του. Κηζ κηζ δηλαδή κορίτσι. Μα εγώ από το σκοτάδι δεν
          έβλεπα. Εκεί μπροστά στην πόρτα ήτανε ένα σιντριβάνι. Πέφτω μέσα στα
          νερά και στις λάσπες. Δίπλα ήτανε οι γυναικείες φυλακές. Με είδε μία
          γυναίκα που έπεσα. Από το σιδερένιο παράθυρο φώναξε τον τσανταρμά. Δεν
          ξέρω τι του είπε. Με μια κλειδάρα άνοιξε και με πήρε η γυναίκα. Αμέσως
          έβγαλε τα ρούχα μου και με έκανε μπάνιο. Έπλυνε τα ρούχα μου και με
          τύλιξε με ένα σεντόνι. Μετά ετοίμασε να φάμε. Το φαΐ ήτανε κουκιά αλλά
          εγώ δεν μπορούσα να φάω και μου έκαμε τσάι. Το ήπια με λίγο ψωμί.
          <br /> Σε λίγες μέρες φέρανε πρόσφυγες από τα Κούλα. Σε λίγο ανοίγει
          την πόρτα της φυλακής και φωνάζει στην γυναίκα να κατεβώ. Εγώ τους
          είδα από το παράθυρο και χάρηκα πολύ. Με παίρνει ο τσανταρμάς . Με
          ρίχνει σε αυτούς. Δυστυχώς δεν ήτανε η μανούλα μου αυτή. Μιλούσανε
          τούρκικα. Πήγαινα στον έναν με διώχνανε. Πήγαινα στον άλλον το ίδιο.
          Τότε ζάρωσα σε μια γωνιά. Όταν βράδιασε άρχισαν οι τούρκοι με τους
          φακούς και γυρεύανε κορίτσια. Τότε ένας από τους πρόσφυγες αρπάει
          εμένα και με έδωσε στον τούρκο. Σκεφτείτε τι έπαθα εκείνη την ώρα.
          Έτρεμα και φώναζα μανούλα μου που είσαι να με πάρεις. Τότε ο τούρκος
          με στήνει όρθια με έψαξε παντού και με παρατάει και φεύγει. Εγώ από
          την τρεμούλα που είχα δεν ήξερα πού πήγαινα. Από τις φωνές μου με
          άκουσε αυτός που τους φύλαγε και ήλθε και με πήρε και με έριξε πάλι
          μαζί τους. Κανείς δεν γύριζε να με δει. Ήτανε πολύ σκληροί άνθρωποι.
          Το πρωί ήλθε ο παπάς του Ικονίου. Από το Ικόνιο δεν είχανε φύγει οι
          Ρωμιοί. Περιμένανε την ανταλλαγή του Βενιζέλου. Ο δε παπάς ρώτησε τον
          φύλακα αν μπορεί να με πάρει σπίτι του και ο φύλακας είπε να την
          πάρεις. Την άλλη μέρα θα φύγουνε. Με πήρε ο παπάς. Πήγαμε σπίτι του.
          Μου μιλούσανε ελληνικά. Με περιποιηθήκανε πολύ. Με λούσανε με φορέσανε
          καθαρά ρούχα το δε πρωί ήθελε ο παπάς να τού πω πώς έχασα τους γονιούς
          μου. Το είπα μερικά όπως έχω γράψει στην αρχή της ιστορίας μου. Μου
          είπε ο παπάς. Τώρα θα πάτε στο Εσκησεχήρ μετά Αφιονκαραχισάρ γιατί
          είναι διαταγή του Κεμάλ.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πήγαμε στο Αφιονκαραχισάρ. Εκεί ήτανε πιο ήρεμοι οι τούρκοι αλλά εμένα
          με είχανε οι δικοί μας. Επειδής δεν είχα κανένανε με στέλνανε να
          πηγαίνω σαν δουλάκι τους στα τούρκικα σπίτια να γυρεύω ψωμί αλάτι αλλά
          εγώ δεν πήγαινα και με διώχνανε από κοντά τους. Τώρα ακούω για τα
          παιδιά της Βοσνίας της Γιουγκοσλαβίας της Κροατίας πόσο τα
          φροντίζουνε. Εμάς κανείς δεν μας θυμήθηκε. Κατόπιν εορτής όταν
          χαθήκαμε πια στείλανε τον Ερυθρό Σταυρό να μας μαζέψει.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ας αφήσουμε αυτά. Το δε πρωί είπανε θα πάτε στην Μερσίνα με το τρένο.
          Μας βάλανε στο τρένο και στα μισά του δρόμου μας βγάλανε έξω. Που να
          πηγαίναμε δεν ξέρανε και αυτοί. Βαδίζαμε στα κουτουρού. Στον δρόμο
          βρήκαμε τούρκους με καμήλες. Ρωτήσανε τους τούρκους και μας είπανε από
          δω θα πάτε στην Νύγδη. Βαδίζαμε κάπου 2 μέρες. Την ημέρα ήταν κάπως
          καλά γιατί σταματούσαμε λίγο αλλά την νύκτα πού είχε πολύ κρύο; Αυτή
          είχανε τσουβάλια ρούχα. Εγώ δεν είχα. Μόνο την μαντίλα που μου είχε
          βάλει ο τούρκος το ψωμί. Πόσες φορές έμεινα πίσω να με σκοτώσουνε αλλά
          τελευταία δεν σκοτώνανε. Με σπρώχνανε και σηκωνόμουν. Δεν άντεχα πια
          πρώτον με αυτούς τους άπονους ανθρώπους που έτυχα και δεύτερον η
          ορφάνια μου. Δεν είχα διάθεση να ζήσω. Είναι τόσα πολλά πού δεν
          γράφονται.
          <br />
          Φθάσαμε στην Νύγδη. Μάς βάλανε ως συνήθως σε έναν στάβλο με κοπριές.
          <br />
          Ήτανε πρωί. Στους δρόμους δεν υπήρχαν τούρκοι. Σε λίγο μπήκε ένας
          κύριος μέσα καλοντυμένος. Ήτανε ο δήμαρχος στους χριστιανούς στην
          Νύγδη. Ακόμα δεν είχανε φύγει οι χριστιανοί. Ρωτάει αυτούς αν υπάρχει
          κανένα ορφανό και αυτή δείξανε εμένα. Με ρωτάει ο κύριος από πού
          είμαι. Του είπα εγώ δεν ξέρω τούρκικα. Με ρώτησε ελληνικά από πού
          είμαι. Του είπα από την Σμύρνη. Με ρωτούσε πώς χάθηκα του τα είπα.
          Όπως τα έχω παραπάνω γραμμένα. Ρωτάει τον φύλακα να το πάρω; Ο φύλακας
          του είπε πάρτο γρήγορα και φύγε. Φεύγουμε και πάμε στο σπίτι του. Εκεί
          ήτανε η γυναίκα του. Είδα μεγάλη στοργή και αγάπη. Με ρωτούσανε τη
          θέλω να φάω. Μου φτιάχνανε τραχανά. Μόνο αυτό έτρωγα. Σιγά σιγά άρχισα
          και έτρωγα από όλα. Είχανε μπαστουρμά, σουτζούκια καπνιστό κρέας.
          Είχανε ξερή μουσταλευριά σουτζούκι με καρύδι σταφίδες. Είχανε πολύ
          ωραίο γιαούρτι και τυρί. Ζυμώνανε για ένα μήνα για να έχουνε ψωμί
          γιατί κάθε μέρα χιόνιζε και δεν βγαίνανε έξω. Η σόμπα με ξύλα άναβε το
          σπίτι στρωμένο με χαλιά πού τα φτιάχνανε οι ίδιες.
          <br />
          Αλλά δεν έμεινα πολύ εκεί. Είχε δύο αγόρια και τα είχε στείλει στην
          Ρουμανία γιατί οι τούρκοι τα σκοτώνανε. Και μια μέρα λάβανε ένα γράμμα
          να πάνε και οι γονείς. Πριν φύγουνε ήλθε μία κοπέλα πού έπαιζε ούτι
          και τραγούδησε τούρκικα. Θα το γράψω και θα το εξηγήσω. Ησταμπόλταν
          ορτασίντα μπεσή τάς νέ ανάμ βάρ νέ παμπερβούρ νέ καρτάς καρά τομπράκ
          ολσούν μπενήμ αρκατάς. Θα το εξηγήσω τώρα. Στην πόλη μέσα έχει μια
          κούνια πέτρινη και πήγα και δεν βρήκα ούτε μάνα ούτε πατέρα ούτε
          αδέλφια. Το μαύρο χώμα ας γίνει πατέρας μάνα και αδελφή. Στα ελληνικά
          δεν ταιριάζει. Δεν μπορώ να γράψω το τι έπαθα εκείνη την ώρα. Με
          συνεφέρανε με νερά και με χτυπήματα. Ήξερα πια τα τούρκικα και ήτανε
          για μένα αυτό το τραγούδι μαχαιριά. Έμαθα πολλά τραγούδια. Τραγουδούσα
          και πολύ όμορφα και με βάζανε να τραγουδώ για να ξεχνώ. Μια μέρα μου
          είπε ο αρής εφέντης δηλαδή ο κύριος Αριστείδης. Αγγελικούλα τώρα που
          θα φύγουμε θα σε πάω σε ένα δικό μας σπίτι. Είναι πολύ καλοί είναι
          μόνοι τους και θέλουνε ένα παιδάκι.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Εγώ στεναχωρέθηκα πολύ. Είχα μάθει μαζί τους. Άρχισαν και ετοίμαζαν τα
          πράγματα τους. Πριν φύγουν με είπε χαιρέτισε την Ολυμπία την Ελισάβετ.
          Η Ολυμπία ήτανε η κοπέλα πού έπαιζε το ούτι και ή Ελισάβετ ήτανε η
          γυναίκα του. Εγώ έκλαιγα πρώτον πού μιλούσανε ελληνικά και πού είχα
          στηριχθεί με έλληνες.
          <br />
          Με παίρνει ο κύριος Αριστείδης και πάμε σε αυτό το σπίτι. Τούς μίλησε
          τούρκικα για μένα και έμεινα εκεί. Περνούσα καλά αλλά εγώ είχα τον
          πόνο μέσα μου. Με κάνανε ρούχα παπούτσια. Δεν χαιρόμουνα με τίποτε.
          Αφού έμεινα αρκετό καιρό κοντά τους μια μέρα η μητέρα μου έδωσε ένα
          ταψί και με τα νοήματα με είπε να πάω στον μπαμπά να μου κόψη
          σταφύλια. Ήτανε το μαγαζί του μπαμπά λίγο πιο κάτω. Ήτανε μαραγκός.
          Πάω εγώ του δείχνω τα σταφύλια για να καταλάβει. Τού δίνω το ταψί. Τα
          σταφύλια τα είχανε κρεμασμένα στο δοκάρι του μαγαζιού μαζί με τής
          κληματαριές και τον χειμώνα κόβανε για να φάνε. Αυτός δεν έκοψε
          σταφύλια. Με παίρνει κοντά του και με χάιδευε και με φυλούσε. Εγώ σαν
          παϊδάκι δεν κατάλαβα. Όταν έβαλε το χέρι του στο στήθος μου και πήγε
          να ξεκουμπώσει το φόρεμα μου τότε του δίνω μια σπρωξιά και βγαίνω έξω.
          Τρέχω προς τα κάτω. Εγώ δεν πήγα σπίτι του. Πιο κάτω χτύπησα μια
          πόρτα. Ανοίγει μία ηλικιωμένη γυναίκα αλά δεν μπορούσα να πω από το
          τρεχιό και την τρεμούλα πού είχα. Συνήλθα λίγο και είπα η σταρυράς
          έτσι τον λέγανε αυτόν. Τότε η γυναίκα κατάλαβε. Της έκανα εγώ με
          νοήματα ότι με ξεκούμπωνε. Η γυναίκα έκαμε τον σταυρό της και έλεγε
          ήμαρτον Αλαχήμ Θεέ μου.
          <br />
          Κάθισα με την Ελέν Καπλά. Θυμάμαι πού πηγαίναμε στο αμπέλι και κόβαμε
          τα βερίκοκα και τα σταφύλια και τα απλώναμε στο δώμα να ξεραθούνε.
          Θυμάμαι και τον χειμώνα πού χιόνιζε και δεν μπορούσαμε να ανοίξουμε
          την πόρτα. Η τουαλέτα ήτανε κάτω κάτω στην εξώπορτα. Εκεί είχανε ένα
          μεγάλο πανί και σκουπιζότανε.
          <br />
          Εγώ το έβλεπα κάθε μέρα αλλά δεν μπορούσα να το πιάσω. Σκέφτηκα όσο
          μικρή και να ήμουν. Βρίσκω ένα ξύλο και το παίρνω και πάω πέρα από το
          σπίτι. Κυλούσε ένας καταρράχτης. Βάζω το πανί και το πλακώνω με μια
          πέτρα να μαλακώσει.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στεκόμουνα εκεί. Βλέπω από μακριά να έρχονται τρεις άνδρες. Οι δύο
          φορούσανε καπέλα. Ό ένας φορούσε φέσι. Όταν είδα τα καπέλα κάπως
          χάρηκα. Μου μίλησε ο τούρκος. Νεραλήσιν από πού είσαι; Εγώ είπα δεν
          ξέρω τούρκικα και αμέσως μου μίλησε ο άλλος με το καπέλο ελληνικά. Από
          πού είσαι μικρούλα μου. Είπα από τής Νέες Φώκιες της Σμύρνης. Και πού
          μένεις. Εδώ έδειξα το σπίτι. Μου λέει πάμε. Εξήγησε και στους άλλους.
          Πάμε σπίτι. Ή μητέρα δεν ήτανε εκεί. Με ρωτούσε όλα όσα έχω γραμμένα
          και μου είπε αν μπορώ να ανοίξω την πόρτα. Εγώ ήξερα. Το κλειδί το
          βάζαμε σε μια πλάκα από κάτω. Άνοιξα και μπήκαμε μέσα. Μόλις άνοιξα
          ήλθε και η μητέρα. Τής είπανε ότι είναι από τον Ερυθρό Σταυρό και το
          παιδί θα το πάρομε και αν βρεθούν οι δικοί της θα το έχεις έτοιμο και
          θα το δώσομε. Όταν μαζέψουμε και άλλα παιδιά θα πάρομε και την
          Αγγελική.
          <br />
          Εγώ πια δεν κοιμόμουνα από τη χαρά μου. Εντωμεταξύ μεγάλωσα και
          σχεδίαζα τα πράγματα αλλιώς. Ότι θα βρω τούς δικούς μου. Έκανα σχέδια
          αλλά δυστυχώς ήτανε όνειρα όλα. Με ετοίμασε ή μητέρα και γώ πια ήμουν
          όλο χαρά. Ό κύριος Ηκιάδης με τον Μουσίου Πρών και τον τούρκο φύγανε.
          Θα πηγαίνανε στα βάθη της Μικράς Ασίας να βρούνε και άλλα παιδιά. Ό
          Κύριος Ηκιάδης μου είπε θα πάμε μέχρι την Καισάρεια και θα γυρίσουμε
          να πάμε στην Ελλάδα.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πέρασε αρκετός καιρός και γώ άρχισα να ανησυχώ ότι εμένα με αφήσανε.
          Στο μεταξύ εγώ αρρώστησα. Πέταξα στο σώμα σπυράκια κόκκινα και στα
          μούτρα μου. Η μητέρα με κάθισε στον ήλιο και με άλειβε θειάφι με λάδι.
          Σε λίγη ώρα με έπλυνε με ζεστό νερό. Μου το έκανε δύο τρεις φορές και
          πέρασε.. Πέρασε πολύς αρκετός καιρός και ήλθανε. Εγώ ήμουνα πάντα
          έτοιμη. Με πήρανε και πήγαμε στο Ουλούκησταν. Στο τρένο τα παιδιά πού
          μαζέψανε δεν τα είδα. Εμένα με πήρανε μαζί τους. Ρώτησα τον κύριο
          Ηκιάδη που είναι τα παιδιά και μου είπε αυτά πάνε με φορτηγό στην
          Μερσίνα. Εσύ θα είσαι μαζί μας. Εγώ πάλι στεναχωρέθηκα γιατί νόμιζα
          ότι θα με πάνε στην μανούλα μου. Τέλος φθάσαμε στην Μερσίνα. Εκεί πού
          μένανε ο κύριος Ηκιάδης και ο Μουσίου Πρών. Ό δε τούρκος έφυγε.
          Ανεβήκαμε σε ένα πολύ ωραίο σπίτι. Μας καλοδέχτηκε μία γαλλίδα. Την
          λέγανε Μαργκαρήτ Ρόση. Με αγάπησε πάρα πολύ. Έφερε τον κουρέα και μου
          έκοψε τα μαλλιά μου. Με κάνανε μπάνιο. Οι υπηρέτες έφεραν μοδίστρα και
          μου έραψε πολλά εσώρουχα και φουστανάκια και με μπαναμά (καπελάκι). Με
          έπαιρνε μαζί της όπου πήγαινε. Πήγαμε στα Άδανα και από κει με ψώνισε.
          Με πήρε κούκλα που έκλινε τα μάτια της. Μου πήρε βραχιόλια γυάλινα
          διάφορα χρώματα και άλλα πολλά. Εγώ πια σαν παϊδάκι ξέχασα λίγο τα
          περασμένα μου. Ήτανε πολύ καλή κυρία. Άνδρα δεν είχε. Δεν ξέρω. Είχε
          μία αδελφή στην Γαλλία. Την λέγανε μαντάμ Πλάνσ.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την νύκτα όταν μπουμπούνιζε ερχότανε στο δωμάτιο μου και μου έλεγε να
          μη φοβάμαι. Μου είχε μία άσπρη ποδίτσα και την έβαζα όταν τρώγανε.
          Έπαιρνα τα πιάτα και τα πήγαινα στην κουζίνα. Είχε δύο υπηρέτες. Μία
          μαύρη και έναν μαύρο. Εμένα δεν με άφηνε να τρώγω στην κουζίνα. Όταν
          φεύγανε στα δωμάτια τoυς έτρωγα στην τραπεζαρία. Με τούς μαύρους δεν
          με άφηνε να έχω σχέσεις. Κάθισα στην Μερσίνα δύο χρόνια. Με πήγαινε
          στις καλόγριες και μάθαινα Γαλλικά. Οι καλόγριες με φωνάζανε Ζοσεφήνα.
          Στο σπίτι με λέγανε Αντζέλικα. Ερχότανε σπίτι πολλοί Γάλοι και με
          χαϊδεύανε και λέγανε στην Μαμαζέλ τρπιέ λαπτήτ. Ωραία είναι ή μικρή.
          Εγώ με τόσα καλά πού είχα κάπως είχα ξεχάσει λίγο. Αλλά η μάνα δεν
          ξεχνιέται. Μια μέρα έστρωσα το τραπέζι έβαλα την άσπρη ποδίτσα μου και
          τούς περιμέναμε για φαΐ. Αφού ήλθανε καθίσανε για φαΐ. Με βοήθησε ή
          αραπίνα και φέραμε το φαγητό. Βγάζει ο κύριος Ηκιάδης μία εφημερίδα.
          Τότε ο Μουσίου Πρών μου είπε. Αντζέλικα ούνβερ τε πούρ. Φέρε μου ένα
          πούρο. Μιλούσανε Γαλλικά. Εγώ κατάλαβα πού είπε λαπτήτ. Της μικρής.
          Την εφημερίδα την πήρε στο δωμάτιο του ο κύριος Ηκιάδης. Σε λίγο με
          φώναξε. Πήγα εγώ και μου είπε θα σου πω κάτι. Δεν θα κλάψεις όμως. Και
          μου είπε. Η αδελφούλα σου βρέθηκε και είναι τώρα στον Πειραιά. Σε λίγο
          καιρό θα πάς και συ. Ανοίγει την εφημερίδα και βλέπω την φωτογραφία
          της. Το τι αισθάνθηκα δεν ξέρω να το περιγράψω. Αλλά η μεγάλη μου
          ελπίδα ήτανε να βρω την μανούλα μου. Πέρασε ένας χρόνος και κανείς δεν
          μου έλεγε τίποτα. Εγώ ζούσα με την αγωνία. Ούτε ήθελα να παίξω ούτε
          κοιμόμουνα. Είχα όλα μου τα πράγματα έτοιμα και περίμενα να μου πούνε
          θα φύγεις τώρα. Μια μέρα μου είπε ο κύριος Ηκιάδης θα έλθει ένα μεγάλο
          βαπόρι και θα σε στείλω στην αδελφή σου. Άλλη αγωνία είχα.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Έρχονται πολλά βαπόρια αλλά θέλω με το Λέσβος να φύγεις γιατί γνωρίζω
          τον κυβερνήτη τού βαποριού. Μόλις τελειώσει η ανταλλαγή θα φύγεις. Η
          δε μαμαζέλ δεν ήθελε να φύγω. Κάθε μέρα λέγανε για μένα. Μου έλεγε η
          μαμαζέλ αν κάτσω κοντά της θα μου έδινε όλη την περιουσία της. Μα εγώ
          ήμουν μικρή. Δε καταλάβαινα. Ήθελα την Αδελφή μου. Μετά την ανταλλαγή
          ήλθε το βαπόρι. Άκουσα πού το λέγανε μια μέρα. Η δε Μαμαζέλ μου τα
          είχε όλα έτοιμα γιατί δεν ήταν δυνατόν να μείνω. Ένα μεσημέρι είδα τον
          κύριο Ηκιάδη να έρχεται με έναν κύριο. Με φώναξε εμένα και είπε στον
          κύριο. Αυτό είναι το κοριτσάκι πού θα πάρετε. Ο κύριος με χάιδεψε και
          είπε θα ταξιδέψουμε μαζί. Σε λίγες μέρες φόρτωσε το καράβι και τον
          φέρανε στο σπίτι και τού κάνανε τραπέζι το δε βράδυ φύγαμε όλοι μαζί.
          Με πήγανε στο καράβι. Με χαιρετήσανε όλοι. Η μαμαζέλ δεν με άφηνε από
          την αγκαλιά της και κλαίγαμε.
          <br />
          Το βράδυ ξεκίνησε το καράβι. Εγώ έμενα μαζί με τον κύριο Κοσμά στην
          καμπίνα του στο πάνω κρεβάτι. Ταξιδεύαμε πέντε μερόνυκτα για να
          φθάσομε στον Πειραιά. Φθάσαμε στον Πειραιά αλλά δεν βγήκαμε. Μάς είχαν
          τρεις μέρες καραντίνα.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Κύριος Κοσμάς Μπούμπουλης μπορεί να έχετε ακούσει την Μπουμπουλίνα
          πού πολέμησε με τούς άνδρες ήτανε γιαγιά του. Όταν τελείωσε η
          καραντίνα φώναξε ο κύριος Μπούμπουλης έναν ναύτη και τού είπε πάρε την
          μικρή να την πάς στον Πόρο στο σπίτι μου. Με πήρε ο ναύτης μπήκαμε σε
          μια βενζινάκατο και πήγαμε στον Πόρο στο σπίτι τού κυρίου Κοσμά. Εκεί
          ήτανε η γυναίκα του. Ένα αγοράκι πού είχε το λέγανε Παντελάκη.
          Γενήκαμε φίλοι. Με αγαπούσανε όλοι στο σπίτι και γώ τούς αγάπησα.
          Έβγαζε ο Παντελάκης τα παιχνίδια του και γώ τα δικά μου και παίζαμε.
          Είχα ξεχάσει πια να κλαίω. Την αδελφή μου όμως την ήθελα. Έλεγα πότε
          θα με πάνε. Μία μέρα ήλθε ο κύριος Κοσμάς. Είπε στην κυρία Βιργινία
          στην γυναίκα του να με πάει σχολείο. Εγώ ήξερα μερικά γράμματα. Στην
          Νύγδη πού ήμουν με πήγαινε η Ελένκο απλά σε ένα σπίτι πού έκαμε στα
          παιδιά μάθημα κρυφά. Εκεί έμαθα την αλφαβήτα. Μου δώσανε ένα μικρό
          βιβλίο πού έγραφε την αλφαβήτα. Εγώ έγραφα και συλλαβές. Το γάλα το
          νερό το ψωμί και άλλες λέξεις. Πήγα και στον Πόρο σχολείο μέχρι
          δευτέρα τάξη. Άρχισα να αλληλογραφώ με την αδελφή μου. Στραβά κουτσά
          τής έγραφα ότι θέλω να πάω κοντά της. Και έτσι έγραψε η αδελφή μου
          στον κύριο Κοσμά να με πάνε.
          <br />
          <br />
          Last Updated on Sunday, 16 September 2012 08:57 <br />
          Copyright © 2015 Hellenic Electronic Center. All Rights Reserved.
          <br />
          www.greece.org
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default G16_mikrasiatika;
