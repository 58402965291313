import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g7/me_01.jpg";
import Image02 from "../assets/images/mikrasiatika_g7/me_02.jpg";
import Image03 from "../assets/images/mikrasiatika_g7/me_03.jpg";
import Image04 from "../assets/images/mikrasiatika_g7/me_04.jpg";
import Image05 from "../assets/images/mikrasiatika_g7/me_05.jpg";
import Image06 from "../assets/images/mikrasiatika_g7/me_06.jpg";
import Image07 from "../assets/images/mikrasiatika_g7/me_07.jpg";
import Image08 from "../assets/images/mikrasiatika_g7/me_08.jpg";
import Image09 from "../assets/images/mikrasiatika_g7/me_09.jpg";
import Image10 from "../assets/images/mikrasiatika_g7/me_10.jpg";
import Image11 from "../assets/images/mikrasiatika_g7/me_11.jpg";
import Image12 from "../assets/images/mikrasiatika_g7/me_12.jpg";
import Image13 from "../assets/images/mikrasiatika_g7/me_13.jpg";
import Image14 from "../assets/images/mikrasiatika_g7/me_14.jpg";
import Image15 from "../assets/images/mikrasiatika_g7/me_15.jpg";


function G7_mikrasiatika() {

    const theme = useTheme();


  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Η Μικρασιατική εκστρατεία" />
      </Box>


      
	<Box sx={{ ...commonBoxStyles }}>
	<Typography variant="h1" sx={theme.typography.typography1}>ΤΟ ΙΣΤΟΡΙΚΟ ΠΟΛΙΤΙΚΟ ΠΛΑΙΣΙΟ</Typography>	
	<Typography variant="h3" sx={theme.typography.typography2}>Το τέλος του Α΄ Παγκόσμιου Πολέμου βρήκε την Ελλάδα στο στρατόπεδο των νικητών ενώ τη Βουλγαρία και την Τουρκία στο στρατόπεδο των  ηττημένων. Γρήγορα όμως διαφάνηκαν οι πρώτες διαφορές ανάμεσα στους νικητές. Ιδιαίτερα στα ζητήματα που αφορούσαν την Οθωμανική Αυτοκρατορία, η Βρετανία είχε εντελώς διαφορετικές επιδιώξεις από τη Γαλλία αλλά και την Ιταλία.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Ο Βενιζέλος, ο οποίος ήδη από το τέλος του 1918 είχε φροντίσει με υπόμνημα του στη Συνδιάσκεψη Ειρήνης των Παρισίων να παρουσιάσει το σύνολο των ελληνικών διεκδικήσεων, πίεζε συνεχώς τους συνέδρους να προστατέψουν τους χριστιανούς της Οθωμανικής Αυτοκρατορίας. Προς την κατεύθυνση αυτή, παρουσίασε τον ελληνικό στρατό ως τη μόνη συγκροτημένη δύναμη στην περιοχή, ικανή να επιβάλει την ειρήνη.</Typography>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Ο ΕΛΛΗΝΙΚΟΣ ΣΤΡΑΤΟΣ ΣΤΗ ΣΜΥΡΝΗ</Typography>
	
	<img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Τελικά η Συνδιάσκεψη των Παρισίων έδωσε εντολή στον Ελευθέριο Βενιζέλο να αποστείλει στρατό στη Σμύρνη (6 Μαΐου 1919, νέο ημερολόγιο). Η εντολή δόθηκε, γιατί υπήρχαν πληροφορίες για επικείμενες σφαγές των χριστιανών, αλλά στην πραγματικότητα επρόκειτο περισσότερο για απόρροια των ενδοσυμμαχικών συγκρούσεων παρά για απόφαση στήριξης των ελληνικών διεκδικήσεων. Κύριος στόχος ήταν να περιοριστούν οι επεκτατικές βλέψεις των Ιταλών, οι οποίοι είχαν ήδη καταλάβει την Αττάλεια μέσω της ελληνικής  στρατιωτικής παρουσίας στη περιοχή της Σμύρνης. </Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>παρουσίας στη περιοχή της Σμύρνης. Τα πρώτα τμήματα του ελληνικού στρατού (1η Ελληνική Μεραρχία υπό το συνταγματάρχη Ζαφειρίου) αποβιβάστηκαν στην προκυμαία της Σμύρνης το πρωί της 15ης Μαΐου 1919 (2 Μαΐου, παλαιό ημερολόγιο). Ήταν η απαρχή μιας τρίχρονης περιπέτειας που έμελλε να σφραγίσει με τον πιο τραγικό τρόπο τη νεότερη ελληνική ιστορία. 
	</Typography>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΕΠΕΚΤΑΣΗ ΤΗΣ ΖΩΝΗΣ ΕΛΛΗΝΙΚΗΣ ΚΑΤΟΧΗΣ</Typography>
	
	<img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Παρά την προσπάθεια των ελληνικών αρχών κατοχής, σε διάφορες συνοικίες της Σμύρνης σημειώθηκαν συγκρούσεις ανάμεσα σε Τούρκους και Έλληνες, στις οποίες σκοτώθηκαν 73 άνθρωποι και δεκάδες  τραυματίστηκαν -οι  περισ-
	σότεροι   Τούρκοι. Το γεγονός αυτό συντέλεσε στη μείωση του κύρους της Ελλάδας ως εντολοδόχου δύναμης και στην ενίσχυση της ανθελληνικής προπαγάνδας των Τούρκων εθνικιστών. Λίγες μέρες μετά την αποβίβαση των Ελλήνων στη Σμύρνη το ελληνικό Γενικό Επιτελείο  διέταξε  εκκαθαριστικές επιχειρήσεις στην περιοχή. Έτσι, στο τέλος του 1919 η προς ανατολάς προέλαση έφτασε σε βάθος 120 περίπου   χιλιομέτρων.  Στην  κοιλά-
	δα  του   Μαιάνδρου καταλήφθηκε το Ναζλί και, με σημαντικές απώλειες αμάχων, και το Αϊδίνι.
	</Typography><br/>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΤΟΥΡΚΙΚΗ ΑΝΤΙΔΡΑΣΗ ΚΑΙ Η ΣΥΝΘΗΚΗ ΤΩΝ ΣΕΒΡΩΝ</Typography>
	
	<img src={Image03}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Το Μάιο του 1919 ο Τούρκος στρατηγός Μουσταφά Κεμάλ πασάς έφτασε στη Σαμψούντα με την ιδιότητα του γενικού επιθεωρητή των στρατιωτικών δυνάμεων των ανατολικών επαρχιών και άρχισε να οργανώνει τις κινήσεις των Τούρκων ενάντια στις ξένες δυνάμεις και κυρίως ενάντια στους Έλληνες. Τον Απρίλιο του 1920 σχηματίστηκε στην Άγκυρα μια επαναστατική κυβέρνηση που δεν αναγνώριζε ουσιαστικά το σουλτάνο. </Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Η παρουσία και η συνεχής ενίσχυση του κεμαλικού κινήματος έπεισαν τους Συμμάχους να επιτρέψουν την ακόμη μεγαλύτερη διεύρυνση της ελληνικής ζώνης κατοχής στη Μικρά Ασία (Μάρτιος 1920). Έτσι, τον Ιούλιο του 1920 ο ελληνικός στρατός προήλασε σε βάθος 150 χιλιομέτρων και σταθεροποίησε το μέτωπο στη γραμμή Πάνορμος-Προύσα-Ουσάκ. Την ίδια περίοδο ελληνικές στρατιωτικές δυνάμεις προωθήθηκαν στην Ανατολική Θράκη φτάνοντας μέχρι τα περίχωρα της Κωνσταντινούπολης. Η υπογραφή της συνθήκης ειρήνης με την Τουρκία έγινε στις Σέβρες της Γαλλίας (28 Ιουλίου/10 Αυγούστου 1920). Οι όροι της αποτελούν θεωρητικά μεγάλο επίτευγμα για την Ελλάδα, ο οποία αναλάμβανε τη διοίκηση της περιοχής της Σμύρνης και της ενδοχώρας για χρονικό διάστημα πέντε ετών. Μετά την παρέλευση αυτού του χρόνου οι κάτοικοι με ελεύθερο δημοψήφισμα θα αποφάσιζαν για το μέλλον της περιοχής.   Επίσης,   παραχωρού-
	νταν στην Ελλάδα η Ανατολική Θράκη και τα νησιά Ίμβρος και Τένεδος. Στην πραγματικότητα  τη συνθήκη υπέγραψε  η  σχεδόν χω-
	ρίς  εξουσία  σουλτανική κυβέρνη-
	ση, ενώ αμέσως μετά την  υπογρα-
	φή της οι Γάλλοι και οι Ιταλοί έσπευσαν  να  βρουν τρόπους επι-
	κοινωνίας με τον Κεμάλ για να διασφαλίσουν τα συμφέροντά τους έναντι των Βρετανών. 
	</Typography>
	
	
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΠΤΩΣΗ ΤΟΥ ΒΕΝΙΖΕΛΟΥ</Typography>
	
	<img src={Image04}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
    <Typography variant="h3" sx={theme.typography.typography2}>
	Τα αποτελέσματα των εκλογών της 1ης Νοεμβρίου του 1920 ήταν καταδικαστικά για την πολιτική του Ελευθέριου Βενιζέλου και των Φιλελευθέρων. Αν και συνολικά το κόμμα 
	του έλαβε μερικές χιλιάδες ψήφους περισσότερες από αυτές της Ηνωμένης Αντιπολίτευσης, λόγω του ισχύοντος εκλογικού συστήματος οι αντίπαλοί του πέτυχαν καθαρή νίκη. 
	Η πολιτική του Κρητικού πολιτικού, όσο και αν υπήρξε μεγαλόπνοη και επιτυχής, κούρασε τον ελληνικό λαό, ο οποίος βρισκόταν σε συνεχή επιστράτευση από το 1912, ενώ οι
	αυθαιρεσίες μικρομεσαίων στελεχών του κόμματός του την περίοδο 1917-1920 προκάλεσαν έντονες αντιδράσεις στο μεγαλύτερο τμήμα του ελληνικού λαού. Επίσης, έγινε φανερό
	ότι η εφαρμογή της Συνθήκης των Σεβρών εξαρτιόταν από την αποτελεσματικότητα των ελληνικών όπλων, απαιτούσε δηλαδή νέες θυσίες, ενώ τα κόμματα της Αντιπολίτευσης
	έκαναν λόγο για αποστράτευση.
	Μετά την εκλογική του ήττα ο Βενιζέλος αναχώρησε για το εξωτερικό, ενώ άνοιξε ο δρόμος για την επιστροφή του εξόριστου Κωνσταντίνου παρά την αντίθετη άποψη που 
	είχαν εκφράσει ο Σύμμαχοι.
	</Typography>
	
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΕΠΙΣΤΡΟΦΗ ΤΟΥ ΚΩΝΣΤΑΝΤΙΝΟΥ ΚΑΙ ΟΙ ΚΥΒΕΡΝΗΣΕΙΣ ΔΗΜΗΤΡΙΟΥ ΡΑΛΛΗ ΚΑΙ ΝΙΚΟΛΑΟΥ ΚΑΛΟΓΕΡΟΠΟΥΛΟΥ</Typography>
	
	<img src={Image05}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Το Δεκέμβριο του 1920 η κυβέρνηση Δημητρίου Ράλλη οργάνωσε δημοψήφισμα για την επάνοδο του Κωνσταντίνου. Λίγο πριν τη διεξαγωγή του  οι σύμμαχες χώρες διεμήνυσαν ότι ενδεχόμενη επιστροφή του γερμανόφιλου βασιλιά θα τις αποδέσμευε από τις υποχρεώσεις τους προς την Ελλάδα. Δήλωσαν επίσης, ότι ήταν αποφασισμένες να διακόψουν κάθε οικονομική υποστήριξη προς την κυβέρνηση της Αθήνας. Τελικά, η επιστροφή του Κωνσταντίνου πρόσφερε τα αναγκαία προσχήματα στους Γάλλους και τους Ιταλούς για να αποδεσμευτούν από τη Συνθήκη των Σεβρών και να επιδιώξουν την προσέγγιση της κεμαλικής Τουρκίας. 
	Την επάνοδο των φιλοβασιλικών κομμάτων στην εξουσία ακολούθησαν μεγάλες εκκαθαρίσεις στη Δημόσια Διοίκηση και στο Στρατό. Απομακρύνθηκαν από το στράτευμα πολλοί ανώτεροι κυρίως αξιωματικοί, ενώ επανήλθαν στις τάξεις του στρατού  πολλοί απότακτοι της περιόδου 1917-1920. 
	Νέος αρχιστράτηγος στη θέση του Λεωνίδα Παρασκευόπουλου διορίστηκε ο στρατηγός Αναστάσιος Παπούλας, ο οποίος ανέλαβε και τη διεύθυνση των επιχειρήσεων στο μέτωπο της Μικράς Ασίας. 
	</Typography>
    <br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΔΙΠΛΩΜΑΤΙΚΗ ΑΝΤΕΠΙΘΕΣΗ ΤΟΥ ΚΕΜΑΛ ΚΑΙ Η ΕΛΛΗΝΙΚΗ ΑΠΑΝΤΗΣΗ</Typography>
	
	<img src={Image06}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Το Φεβρουάριο του 1921 συνήλθε στο Λονδίνο η διασυμμαχική διάσκεψη για να εξεταστεί η κατάσταση που επικρατούσε στην Ανατολή. Σε αυτή προσκλήθηκαν και εκπρόσωποι των Τούρκων εθνικιστών, κίνηση που αποτέλεσε επίσημη αναγνώριση του κεμαλικού καθεστώτος. Η διάσκεψη γρήγορα κατέληξε σε αδιέξοδο και οι κεμαλικοί εκμεταλλεύτηκαν το γεγονός και προχώρησαν σε μυστικές συναντήσεις με τους Γάλλους και τους Ιταλούς, οι οποίες λίγο αργότερα  οδήγησαν στην υπογραφή συμφωνιών. Με βάση αυτές, οι δύο χώρες δέχονταν να αποσύρουν τα στρατεύματά τους με αντάλλαγμα την κατοχύρωση των συμφερόντων τους στην περιοχή. Την ίδια περίοδο πύκνωσαν οι επαφές ανάμεσα στους εθνικιστές και τη Σοβιετική Ένωση, η οποία άρχισε να ενισχύει ποικιλοτρόπως τον πολεμικό αγώνα των Τούρκων.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Το διπλωματικό αδιέξοδο της διάσκεψης του Λονδίνου και η διπλωματική αναγνώριση, έστω de facto, του κεμαλικού κινήματος έκαναν επιτακτική την ανάγκη για ανάληψη επιθετικής πρωτοβουλίας από την πλευρά του ελληνικού στρατού. Στην κατεύθυνση αυτή κινήθηκαν και οι σχετικές παραινέσεις των Βρετανών. Έτσι, το Μάρτιο του 1921 το ελληνικό στρατηγείο διέταξε ολομέτωπη επίθεση με αντικειμενικό σκοπό την κατάληψη δύο μεγάλων συγκοινωνιακών κόμβων, του Εσκί Σεχίρ στο βορρά και του Αφιόν Καραχισάρ στο νότο. Στο νότιο τομέα του μετώπου το Α΄Σώμα Στρατού  κατέλαβε εύκολα  το  Αφιόν  Καραχισάρ.   Στο  βόρειο 
	τομέα  όμως  το  Γ΄  Σώμα  Στρατού  συνάντησε  μεγάλη   αντίσταση   στην   περιοχή   Ινονού  και αναγκάστηκε να υποχωρήσει προς την Προύσα. Η ανάγκη ευθυγράμμισης του μετώπου συμπαρέσυρε σε οπισθοχώρηση προς Ουσάκ και το Γ΄Σώμα Στρατού.<br/>
	Από την αποτυχημένη έκβαση των επιχειρήσεων προέκυψε κυβερνητική κρίση. Ο πρωθυπουργός Ν. Καλογερόπουλος παραιτήθηκε και ανέλαβε ο ισχυρός άνδρας της κυβερνητικής πλειοψηφίας Δημήτριος Γούναρης.
	</Typography>
	
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>ΠΡΟΣ ΤΗΝ ΑΓΚΥΡΑ</Typography>
	
	<img src={Image07}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Οι στρατιωτικές αποτυχίες του Μαρτίου του 1921 επιδείνωσαν τη διεθνή θέση της Ελλάδας προς όφελος των Τούρκων. Ήταν πλέον φανερό πως ο διεθνής παράγοντας προετοίμαζε νέα ειρηνευτική διάσκεψη με τελικό στόχο την εκπόνηση σχεδίου που, μεταξύ άλλων, θα προέβλεπε εκκένωση από τον ελληνικό στρατό της Μικράς Ασίας. Η ελληνική ηγεσία, που σε καμία περίπτωση δεν ήταν διατεθειμένη να εγκαταλείψει τη Μικρά Ασία, αποφάσισε την ανάληψη ευρείας κλίμακας πολεμικής δράσης για να επιτύχει αποφασιστική νίκη επί των Τούρκων, που θα αποτελούσε διαπραγματευτικό πλεονέκτημα στη συνδιάσκεψη που προετοιμαζόταν. <br/>
	Για την προετοιμασία της επίθεσης έφτασε στη Σμύρνη ο βασιλιάς Κωνσταντίνος, συνοδευόμενος από τον πρωθυπουργό Δημήτριο Γούναρη. 
	</Typography>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΕΛΛΗΝΙΚΗ ΕΠΙΘΕΣΗ ΚΑΙ ΤΟ ΜΕΓΑΛΟ ΔΙΛΗΜΜΑ</Typography>
	
	<img src={Image08}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Η επίθεση εκδηλώθηκε σε όλο σχεδόν το μέτωπο στις 25 Ιουνίου 1921(παλαιό ημερολόγιο), αλλά επικεντρώθηκε κυρίως στον κεντρικό τομέα, στην περιοχή της Κιουτάχειας, την οποία κατέλαβε ο ελληνικός στρατός στις 4 Ιουλίου του 1921. λίγες μέρες νωρίτερα στο νότιο τομέα είχε κυριευτεί και το Αφιόν Καραχισάρ. Μετά την κατάληψη του συγκοινωνιακού κόμβου της Κιουτάχειας από τους Έλληνες, οι Τούρκοι υποχώρησαν προς το Εσκί Σεχίρ. Στις 8 Ιουλίου ο Ισμέτ πασάς (Ινονού) εξαπέλυσε ευρείας κλίμακας αντεπίθεση στην περιοχή του Εσκί Σεχίρ, η οποία αποκρούστηκε από τις ελληνικές δυνάμεις. Η τουρκική υποχώρηση έγινε μέσα σε κλίμα απόλυτης αταξίας, με βαριές απώλειες. Οι τουρκικές δυνάμεις πρόλαβαν να υποχωρήσουν πέρα από το Σαγγάριο ποταμό και να ανασυνταχθούν.</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Οι τελευταίες επιτυχίες του ελληνικού στρατού, έθεσαν το δίλημμα στην ελληνική ηγεσία. Ο έλεγχος της στρατηγικής σημασίας σιδηροδρομικής γραμμής Εσκί Σεχίρ-Αφιόν Καραχισάρ ήταν μια ιδιαίτερα σημαντική επιτυχία, όμως η ανασύνταξη του κεμαλικού στρατού πέρα από το Σαγγάριο συνιστούσε μια διαρκή απειλή για τους Έλληνες. Έτσι, στο πολεμικό συμβούλιο που συγκλήθηκε στην Κιουτάχεια (15 Ιουλίου 1921) αποφασίστηκε η προέλαση στην Άγκυρα, με αντικειμενικό σκοπό την κατάληψη της πρωτεύουσας των εθνικιστών και την καταστροφή των βάσεων εφοδιασμού τους. Στη συνέχεια ο ελληνικός στρατός θα ανασυντασσόταν στην κύρια γραμμή άμυνας Εσκί Σεχίρ-Αφιόν Καραχισάρ.
	</Typography>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΜΑΧΗ ΤΟΥ ΣΑΓΓΑΡΙΟΥ</Typography>
	
	<img src={Image09}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Την 1η Αυγούστου του 1921 ο ελληνικός στρατός ξεκίνησε από τις θέσεις εξόρμησης για το Σαγγάριο. Η δύναμη του αριθμούσε τρία σώματα στρατού και μία ανεξάρτητη ταξιαρχία ιππικού. Στη βόρεια πλευρά υπήρχε το Γ΄ Σώμα Στρατού, ενώ νοτιότερα κινήθηκαν το Α΄ και το Β΄ Σώμα Στρατού.  Περισσότερο  επίπονη  ήταν  η  πορεία  του  Β΄ Σώματος Στρατού μέσα από την Αλμυρά
	Έρημο. Η εξαντλητική πορεία κάτω από τον καυτό ήλιο και οι δυσκολίες κατά το ανεφοδιασμό εξουθένωσαν τις ελληνικές δυνάμεις.<br/>
	Στις 10 Αυγούστου οι ελληνικές εμπροσθοφυλακές έφτασαν στο Σαγγάριο και στις 23 του ίδιου μήνα άρχισε η γενική επίθεση των Ελλήνων. Σημαντικό σημείο του ελληνικού επιτελικού σχεδίου αποτελούσε η υπερκέραση της τουρκικής παράταξης στην περιοχή του Καλέ Γρότο, η οποία στη συνέχεια θα οδηγούσε σε κύκλωμα όλων των τουρκικών δυνάμεων. Όμως, η συγκεκριμένη ενέργεια, εξαιτίας της καθυστέρησης και των περιορισμένων αριθμητικά δυνάμεων, απέτυχε (18 Αυγούστου 1921). Η πύρρεια νίκη ανάγκασε την ελληνική ηγεσία να αλλάξει τους αρχικούς στόχους της. Έτσι, αποφασίστηκε η υποχώρηση του ελληνικού στρατού στη γραμμή Εσκί Σεχίρ-Αφιόν Καραχισάρ. Στις 31 Αυγούστου του 1921 η ελληνική στρατιά πέρασε στη δυτική όχθη του Σαγγάριου με υποδειγματική τάξη. Η πιο επική φάση της εκστρατείας στη Μικρά Ασία, η πορεία προς την Άγκυρα, είχε τερματιστεί. <br/>
	</Typography>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>H ΤΕΛΕΥΤΑΙΑ ΝΙΚΗΦΟΡΑ ΜΑΧΗ ΤΟΥ ΕΛΛΗΝΙΚΟΥ ΣΤΡΑΤΟΥ ΤΟ 1921</Typography>	
	<Typography variant="h3" sx={theme.typography.typography2}>Στο τέλος του Σεπτεμβρίου του 1921 εκδηλώθηκε τουρκική επίθεση στο νότιο τομέα, στην περιοχή του Αφιόν Καραχισάρ, που αποτελούσε σημαντικό συγκοινωνιακό κόμβο. Αντικειμενικός σκοπός των Τούρκων ήταν να αποκόψουν τις γραμμές ανεφοδιασμού του νότιου συγκροτήματος των μεραρχιών. Η μάχη του Αφιόν Καραχισάρ διήρκεσε με διαλείμματα μία εβδομάδα και τελικά οι Τούρκοι υποχώρησαν και πάλι πίσω από τον Σαγγάριο. Η ελληνική γραμμή του μετώπου σταθεροποιήθηκε ανάμεσα στην Κίο, το Εσκί Σεχίρ, το Αφιόν Καραχισάρ και τον ποταμό Μαίανδρο και είχε συνολικό μήκος 700 περίπου χιλιόμετρα.</Typography>
	
	
	
	
	
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΔΙΠΛΩΜΑΤΙΚΗ ΑΝΤΕΠΙΘΕΣΗ ΤΟΥ ΜΟΥΣΤΑΦΑ ΚΕΜΑΛ</Typography>
	
	<img src={Image10}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Από τη Μάρτιο του 1921 η κεμαλική κυβέρνηση ξεκίνησε μια έντονη διπλωματική δραστηριότητα, που περιλάμβανε μυστικές επαφές με τους Γάλλους και τους Ιταλούς και απευθείας επαφές με τους Σοβιετικούς. Τον Οκτώβριο του 1921 οι Τούρκοι υπέγραψαν ένα σύμφωνο με τη Γαλλία, πιο γνωστό ως Σύμφωνο Φρακλέν Μπουγιόν, από το όνομα του Γάλλου διαπραγματευτή. Με αυτό τέθηκε τέλος στη γαλλική κατοχή της Κιλικίας. Ως αντάλλαγμα οι Γάλλοι εξασφάλισαν σημαντικές οικονομικές παραχωρήσεις σε ολόκληρη τη Μικρά Ασία. Τέλος, μεγάλη ποσότητα γαλλικού πολεμικού υλικού παραχωρήθηκε στους Τούρκους. Η ανάπτυξη καλών σχέσεων με την ΕΣΣΔ αποτελούσε το δεύτερο σκέλος της κεμαλικής διπλωματίας. Με την υπογραφή μια σειράς συμφωνιών η Τουρκία καθόρισε με επωφελή γι’ αυτήν τρόπο τα σύνορά της με τη Σοβιετική Ένωση, ενώ συγχρόνως εξασφάλισε τον απρόσκοπτο ανεφοδιασμό της με πολεμοφόδια από τα λιμάνια στον Εύξεινο Πόντο</Typography>
	
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>ΛΙΓΟ ΠΡΙΝ ΤΗΝ ΚΑΤΑΙΓΙΔΑ</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Η αποτελμάτωση των επιχειρήσεων στο μικρασιατικό μέτωπο καταρράκωσε το ηθικό των ελληνικών δυνάμεων. Τα τεράστια οικονομικά προβλήματα που συνεπαγόταν η συντήρηση της στρατιάς ανάγκασαν την κυβέρνηση να προχωρήσει, με εισήγηση του αρμόδιου υπουργού Πρωτοπαπαδάκη, σε  διχοτόμηση  του  νομίσματος, ενέργεια που προκάλεσε γενική δυσφορία. Οι 
	συνεχείς κυβερνητικές αλλαγές στην Αθήνα, η αντικατάσταση του αρχιστράτηγου Α. Παπούλα από Γ. Χατζηανέστη και οι αγωνιώδεις προσπάθειες της ελληνικής κυβέρνησης να πείσει τους πρώην Συμμάχους να προτείνουν κάποιο σχέδιο για την επίλυση του προβλήματος σε συνδυασμό με την απόλυτη αδιαλλαξία του Κεμάλ, φανέρωναν τη σκληρή πραγματικότητα, ότι η τελευταία σκηνή του δράματος θα παιζόταν στο πεδίο της μάχης. 
	</Typography>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>ΤΟΥΡΚΙΚΗ ΕΠΙΘΕΣΗ ΚΑΙ ΕΛΛΗΝΙΚΗ ΥΠΟΧΩΡΗΣΗ</Typography>
	
	<img src={Image11} alt="δελφίνι" />
	<Typography variant="h3" sx={theme.typography.typography2}>Τα ξημερώματα της 13ης Αυγούστου του 1922 (παλαιό ημερολόγιο) άρχισε η από πολύ καιρό αναμενόμενη τουρκική επίθεση. Εκδηλώθηκε στο νότιο τομέα, στην περιοχή του Αφιόν Καραχισάρ. Ο ελληνικός στρατός, που το ηθικό του είχε καταρρακωθεί λόγω της μακράς παραμονής του στο μέτωπο, αλλά και της πολύχρωμης προπαγάνδας που είχε αναπτυχθεί στις τάξεις του, γρήγορα έπαψε να αντιστέκεται αποτελεσματικά και άρχισε να υποχωρεί προς το Αιγαίο σε απόλυτη αταξία, ενώ τον ακολουθούσαν πλήθη προσφύγων που προσπαθούσαν να βρουν καταφύγιο στη Σμύρνη και στη συνέχεια στην Ελλάδα. Στο βόρειο τομέα το Γ΄Σώμα Στρατού υποχώρησε με σχετική τάξη και κατευθύνθηκε προς τα λιμάνια της Πανόρμου και της Κυζίκου, όπου επιβιβάστηκε σε πλοία που το μετέφεραν στην Ελλάδα. Μέχρι τις 4 Σεπτεμβρίου 1922 και ο τελευταίος Έλληνας στρατιώτης είχε εγκαταλείψει το μικρασιατικό έδαφος. </Typography>
	<br/>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΚΑΤΑΛΗΨΗ ΚΑΙ Ο ΕΜΠΡΗΣΜΟΣ ΤΗΣ ΣΜΥΡΝΗΣ</Typography>
	
	<img src={Image12}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Η διάσπαση του μετώπου γέμισε ανησυχία τους κατοίκους της Σμύρνης. Η κατάσταση διαρκώς χειροτέρευε με την αυξανόμενη άφιξη χιλιάδων προσφύγων στην πόλη. Στις 19 Αυγούστου το 1922 ο ύπατος αρμοστής Στεργιάδης διέταξε τους υπαλλήλους της αρμοστείας που ήταν διασκορπισμένοι σε ολόκληρη την ελληνική ζώνη κατοχής να συγκεντρωθούν στη Σμύρνη. Παράλληλα άρχισαν να συγκεντρώνονται στο λιμάνι της πολεμικά πλοία των Μεγάλων Δυνάμεων για να προστατεύσουν τα μέλη των ξένων παροικιών. Στις 26 Αυγούστου η ανησυχία του πλήθους μετατράπηκε σε πανικό καθώς τα υπολείμματα του ελληνικού στρατού συγκεντρώθηκαν στη χερσόνησο της Ερυθραίας, έτοιμα να εγκαταλείψουν τη Μικρά Ασία. Την ίδια ημέρα έσπευσε να εγκαταλείψει τη Σμύρνη και ο Στεργιάδης.<br/>	
	Στις 27 Αυγούστου (παλαιό ημερολόγιο) μπήκαν στην πόλη τουρκικά στρατεύματα και εγκαταστάθηκε τουρκική διοίκηση υπό τον Νουρεντίν πασά. Το απόγευμα της ίδιας ημέρας ο Νουρεντίν πασάς παρέδωσε στον τουρκικό όχλο το Μητροπολίτη Σμύρνης Χρυσόστομο και μαζί του αρκετά μέλη της δημογεροντίας της πόλης, οι οποίοι, αφού υπέστησαν φριχτά βασανιστήρια θανατώθηκαν. Ακολούθησαν βιαιοπραγίες που κράτησαν μερικές μέρες.<br/>
	<br/>
	<img src={Image13}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	Το απόγευμα της 31ης Αυγούστου εκδηλώθηκε πυρκαγιά στην αρμενική συνοικία της πόλης. Η φωτιά ήταν προφανώς προμελετημένη και την ευθύνη πιθανότατα έφεραν οι 
	τούρκικες Αρχές, καθώς ανεξάρτητοι παρατηρητές-κυρίως Αμερικανοί αυτόπτες μάρτυρες- είδαν Τούρκους στρατιώτες να μεταφέρουν δοχεία με πετρέλαιο. Η πυρκαγιά, η 
	οποία εκδηλώθηκε σε μια στιγμή που φυσούσε νότιος άνεμος, γρήγορα επεκτάθηκε στην ελληνική συνοικία. Πολλοί κάτοικοι, στην προσπάθειά τους να απομακρυνθούν,
	εμποδίστηκαν δυναμικά από τους Τούρκους στρατιώτες. Οι Αρχές δεν πήραν κανένα μέτρο για την κατάσβεση της. Ήταν φανερή η επιδίωξη τους να καταστραφεί οτιδήποτε 
	μαρτυρούσε τη μακραίωνη ελληνική παρουσία στην περιοχή. Καθώς η φωτιά μαινόταν, χιλιάδες κάτοικοι συγκεντρώθηκαν πανικόβλητοι στην προκυμαία, προσπαθώντας να 
	εγκαταλείψουν τη Σμύρνη με κάθε μέσο. Συνέπεια αυτής της προσπάθειας ήταν να βρουν τραγικό θάνατο εκατοντάδες άνθρωποι, ενώ τα πληρώματα των ξένων πολεμικών πλοίων 
	παρακολουθούσαν τις δραματικές σκηνές που εκτυλίσσονταν. Αποτεφρώθ	ηκαν 55.000 σπίτια, 5.000 καταστήματα, 21 εκκλησίες, 32 σχολεία, 5 προξενεία, 7 λέσχες, 5 τράπεζες 
	και 5 νοσοκομεία.<br/>
	Μέχρι τη 17η Σεπτεμβρίου 1922 όλοι οι χριστιανοί κάτοικοι της Σμύρνης (Έλληνες και Αρμένιοι) είχαν εγκαταλείψει τη πόλη, ενώ χιλιάδες Έλληνες είχαν μεταφερθεί 
	στην Ανατολία ως όμηροι. Τα πουλιά, που το 800 π.Χ. περίπου είχαν πετάξει προς την Ανατολή και είχαν δημιουργήσει το θαύμα της Ιωνίας, γύρισαν πληγωμένα στη φωλιά τους.  <br/>
	</Typography>
	
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>Η ΕΠΑΝΑΣΤΑΣΗ ΠΛΑΣΤΗΡΑ – ΓΟΝΑΤΑ, Η ΔΙΚΗ ΚΑΙ Η ΕΚΤΕΛΕΣΗ ΤΩΝ ΕΞΙ</Typography>
	
	<img src={Image14}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Στις 11 Σεπτεμβρίου του 1922 (π.ημ.) εκδηλώθηκε κίνημα στις τάξεις του στρατού, ο κύριος όγκος του οποίου ήταν συγκεντρωμένος στη Χίο και τη Μυτιλήνη. Την ίδια στιγμή είχε σχηματιστεί στην Αθήνα Επαναστατική Επιτροπή, που βρισκόταν σε στενή επαφή με τους κινηματίες , αρχηγοί των οποίων ήταν οι συνταγματάρχες Νικόλας Πλαστήρας και Στυλιανός Γονατάς. Σε προκήρυξή τους οι επαναστάτες ζητούσαν παραίτηση της κυβέρνησης Τριανταφυλλάκου, απομάκρυνση από το θρόνο του βασιλιά Κωνσταντίνου και παραίτηση του υπέρ του διαδόχου Γεωργίου Β΄, διάλυση της Βουλής και ενίσχυση του  στρατού στη  Θράκη. Στις 14  Σεπτεμβρίου ο Κωνσταντί-
	νος παραιτήθηκε υπέρ του διαδόχου και αναχώρησε για το εξωτερικό. Συγχρόνως σχηματίστηκε πολιτική κυβέρνηση με πρωθυπουργό το Σωτήριο Κροκιδά.
	Με διάταγμα οριζόταν ανακριτική επιτροπή, με πρόεδρο το Θεόδωρο Πάγκαλο, που θα διερευνούσε τις ευθύνες των πολιτικών που κυβέρνησαν τη χώρα μετά το Νοέμβριο του 1920 και έκτακτο στρατοδικείο για να δικάσει τους πρωταίτιους της ήττας. Σε δίκη παραπέμφθηκαν οι πολιτικοί Δημήτριος Γούναρης, Πέτρος Πρωτοπαδάκης, Νικόλαος Θεοτόκης, Γεώργιος Μπαλτατζής, Νικόλαος Στράτος και οι στρατιωτικοί Γεώργιος Χατζηανέστης, Μιχαήλ Γούδας και Ξενοφώντας Στρατηγός.  Με συνοπτικές διαδικασίες το δικαστήριο καταδίκασε σε θάνατο για εσχάτη προδοσία όλους τους πολιτικούς και το στρατηγό Χατζηανέστη, οι οποίοι εκτελέστηκαν σχεδόν αμέσως μετά την έκδοση της απόφασης.
	</Typography>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>ΟΙ ΔΙΑΠΡΑΓΜΑΤΕΥΣΕΙΣ ΤΗΣ ΛΟΖΑΝΗΣ ΚΑΙ Η ΑΝΤΑΛΛΑΓΗ ΠΛΗΘΥΣΜΩΝ</Typography>
	
	<img src={Image15}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />	
	<Typography variant="h3" sx={theme.typography.typography2}>Το Νοέμβριο του 1922 άρχισαν στη Λοζάνη της Ελβετίας οι διαπραγματεύσεις για τη σύναψη ειρήνης με την Τουρκία.  Την ηγεσία της ελληνικής αντιπροσωπείας ανέλαβε ο Ελευθέριος Βενιζέλος, αποδεχόμενος σχετική πρόταση της Επαναστατικής Επιτροπής, ενώ επικεφαλής της τουρκικής αντιπροσωπείας ήταν ο Ισμέτ Ινονού πασάς. Η συνδιάσκεψη διήρκεσε οκτώ περίπου μήνες. Σε αυτό το διάστημα πολλές φορές κινδύνεψαν να διακοπούν οι εργασίες. Συνολικά υπογράφτηκαν πέντε συνθήκες και συμβάσεις: η συνθήκη ειρήνης, η σύμβαση για το καθεστώς των Στενών, η σύμβαση για την ανταλλαγή των πληθυσμών, η σύμβαση για τα σύνορα της Θράκης και το σύμφωνο για την απελευθέρωση των πολιτών ομήρων και για την ανταλλαγή των αιχμαλώτων. <br/>
	Η ανταλλαγή των πληθυσμών αποτέλεσε το πιο λεπτό ζήτημα από όσα συζητήθηκαν στη Λοζάνη. Τελικά αποφασίστηκε η υποχρεωτική ανταλλαγή των πληθυσμών ανάμεσα στις δύο χώρες. Η ανταλλαγή έγινε με βάση το θρήσκευμα και όχι την εθνικότητα. Η σύμβαση υπογράφτηκε στις 30 Ιανουαρίου 1923 (ν. ημ.) και από το διακανονισμό εξαιρέθηκαν οι Έλληνες  της Κωνσταντινούπολης, της Ίμβρου και της Τενέδου και οι μουσουλμάνοι της Δυτικής Θράκης. Σε γενικές γραμμές εξαιρέθηκαν περίπου 140.000 Έλληνες και 100.000 μουσουλμάνοι. <br/>
	Η μαζική άφιξη των προσφύγων (κάτω από διαφορετικές συνθήκες) δημιούργησαν ανυπέρβλητες δυσκολίες και στις δύο πλευρές. Περίπου 1.100.000 Έλληνες της Μικράς Ασίας-κυρίως από τα παράλια- ήρθαν στην Ελλάδα λίγο μετά την καταστροφή και ακολούθησαν άλλες 200.000 χιλιάδες μέχρι το 1924-κυρίως Πόντιοι και Καππαδόκες-στο πλαίσιο εφαρμογής της σύμβασης που αφορούσε την ανταλλαγή των πληθυσμών. Από το πρώτο προσφυγικό κύμα, οι 900.000 ήρθαν σχεδόν μόνο με τα ρούχα τους. Από την Ελλάδα αποχώρησαν 550. 000 μουσουλμάνοι. <br/>
	</Typography>
	<br/><br/><br/>
	<Typography variant="h1" sx={theme.typography.typography1}>ΟΙ ΒΑΣΙΚΟΙ ΟΡΟΙ ΤΗΣ  ΣΥΝΘΗΚΗΣ ΤΗΣ ΛΟΖΑΝΗΣ</Typography>
	
	<Typography variant="h3" sx={theme.typography.typography2}>Η σύμβαση υπογράφτηκε στις 30 Ιανουαρίου 1923, υλοποιήθηκε υπό την αιγίδα της Κοινωνίας των Εθνών και οι βασικοί της όροι προέβλεπαν:
	Από 1ης Μαΐου 1923, αποφασίζεται η υποχρεωτική ανταλλαγή των Τούρκων υπηκόων ελληνικού ορθόδοξου δόγματος και των Ελλήνων υπηκόων μουσουλμανικής θρησκείας. Στην ανταλλαγή αυτή, με αναδρομική ισχύ, θα συμπεριληφθούν και όσοι Έλληνες ή μωαμεθανοί έχουν ήδη μεταναστεύσει, οποτεδήποτε, από τον Οκτώβριο του 1912 και εξής.
	</Typography>
	<Typography variant="h3" sx={theme.typography.typography2}>Η κινητή περιουσία που υπάρχει στα σπίτια, στα τεμένη, στις εκκλησίες, στα σχολεία, στα μοναστήρια ή στα νοσοκομεία τους θα μπορεί να εξέλθει αδασμολόγητα από τη χώρα, μαζί τους. Η ακίνητη περιουσία καθώς θα εγκαταλείπεται, θα απογράφεται, θα εκτιμάται και θα ρευστοποιείται από τη Μεικτή Επιτροπή. <br/>
	Η διαδικασία ρευστοποίησης θα έχει αναδρομική ισχύ όσον αφορά τις περιουσίες Ελλήνων και μωαμεθανών που έχουν ήδη μεταναστεύσει, οποτεδήποτε, από τον Οκτώβριο του 1912 και εξής.<br/>
	Η Μεικτή Επιτροπή θα αποτελείται από δύο Τούρκους, δύο Έλληνες και τρεις υπηκόους χωρών που έμειναν ουδέτερες, στη διάρκεια του Παγκόσμιου Πολέμου. Οι τρεις τελευταίοι θα επιλέγονται από την Κοινωνία των Εθνών. <br/>
	Κατ’ αρχήν, κάθε πρόσφυγας θα λαμβάνει περιουσία ίσης αξίας  με την περιουσία που εγκατέλειψε. Η αποτίμηση της συνολικής αξίας των εγκαταλελειμμένων περιουσιών, σε αμφότερες τις χώρες, θα συνιστά κρατικό χρέος και μόνο, μεταξύ της Τουρκίας και της Ελλάδος. Αφού ολοκληρωθεί ο ισολογισμός, η πιστώτρια χώρα θα πάρει από τη χρεώστρια διακανονισμό εξόφλησης, σύμφωνα με τον οποίο ένα μέρους του χρέους θα πληρωθεί σε μετρητά και ένα άλλο σε κρατικά ομόλογα. <br/>
	Δεν επιτρέπεται να τεθούν εμπόδια, να ασκηθούν πιέσεις ούτε να επιβληθούν φόροι στους μετανάστες. Όσοι εξαιρούνται της υποχρεωτικής ανταλλαγής είναι ελεύθεροι, αν το επιθυμούν να μεταναστεύσουν. <br/>
	Εξαιρούμενοι θεωρούνται:<br/>
	α) οι Έλληνες, οι εγκατεστημένοι στην Κωνσταντινούπολη πριν τις 30 Οκτωβρίου 1918 και <br/>
	β) οι μωαμεθανοί κάτοικοι της Δυτικής Θράκης.</Typography>
	</Box>

<ScrollToTopButton />





    </>
  );
}

export default G7_mikrasiatika;
