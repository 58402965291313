import React from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import ScrollToTopButton from '../components/ScrollToTopButton';
import Header1 from '../components/Header1';
import HomeIcon from '@mui/icons-material/Home'
import { commonBoxStyles, headerBoxStyles } from '../components/CommonStyles'

import Image01 from '../assets/images/oikologika/21sinedriopandoiko_01.jpg'
import Image02 from '../assets/images/oikologika/kolpos_geras_02.jpg'
import Image03 from '../assets/images/oikologika/prokimaia_mtl.jpg'

function F7_oikologika_07() {

    const theme = useTheme();


  return (
    <>

<IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="21o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>


      
	
      <Box sx={{ ...commonBoxStyles }}> 
		
      <Typography variant="h1" sx={theme.typography.typography1}>Λαμία 13-14-15 Νοεμβρίου 2009.</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>ΘΕΜΑ: «Το νερό ως κοινωνικό αγαθό»</Typography>
		
		<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<Typography variant="h3" sx={theme.typography.typography2}>Με μεγάλη επιτυχία και συμμετοχή τόσο συνέδρων απ’ όλη την Ελλάδα όσο και κοινού πραγματοποιήθηκε στις 13 και 14 Νοεμβρίου το 21ο Συνέδριο του Πανελληνίου 
		Δικτύου Οικολογικών Οργανώσεων. Το συνέδριο έγινε στη Λαμία στο Πολιτιστικό Κέντρο του Δήμου Λαμιέων  σε συνεργασία με την ΚΕΔΚΕ  </Typography>
		
		<Typography variant="h3" sx={theme.typography.typography2}>Παρέστησαν η Υπουργός Αγρ. Ανάπτυξης κ. Κ. Μπατζελή, η Ειδ. Γραμματέας του Υπ. Περιβάλλοντος κ. Καραβασίλη, ο ευρωβουλευτής Μ. Τρεμόπουλος οι βουλευτές του 
		νομού Φθιώτιδας κ. Αντωνίου, Τσώνης,  Σταικούρας, ο Νομάρχης κ. Χειμάρας και οι δήμαρχοι Λαμιέων κ. Κοτρωνιάς, Γοργοποτάμου Σπαθούλας, Υπάτης Δ. Καραβάνας, 
		Αγ. Κωνσταντίνου Ε. Ψαθάς και Στυλίδας Α. Σχορετσιανίτη, Μώλου κ. Ε. Τετριμίδας, Αμφίκλειας κ. Τσιτσιμπής., ο κ. Αποστόλου ως εκπρόσωπος του ΣΥΡΙΖΑ, ο κ. Ν. 
		Χρυσόγελος ως εκπρόσωπος των Οικολόγων Πράσινων και ο κ. Λιβανός ως εκπρόσωπος του ΠΑΣΟΚ, και ο Σ. Καρκάνης, πρόεδρος του ΤΕΙ Λαμίας.<br/>
		Μήνυμα έστειλε ο οικουμενικός Πατριάρχης Βαρθολομαίος.<br/>
		Μετά την ολοκλήρωση του θεματικού μέρους με θέμα «ΤΟ ΝΕΡΟ ΩΣ ΚΟΙΝΩΝΙΚΟ ΑΓΑΘΟ» οι σύνεδροι ψήφισαν ομόφωνα απόφαση η οποία (συνοπτικά) τονίζει τα ακόλουθα :</Typography>
		
		
		<Typography variant="h3" sx={theme.typography.typography2}>Το νερό είναι και οφείλει να αντιμετωπίζεται ως <b>κοινωνικό αγαθό και όχι ως εμπόρευμα</b>.<br/><br/>
		Η διαχείρισή του οφείλει να υπακούει στις <b>αρχές της αειφορίας, της διαγενεακής δικαιοσύνης και της διαφύλαξης της βιοποικιλότητας</b> και να γίνεται από 
		<b>δημόσιους ή αυτοδιοικητικούς φορείς</b>. Η διεθνής εμπειρία ιδιωτικοποιήσεων του πόσιμου νερού είναι σαφώς αρνητική.<br/>
		Η χώρα μας, αν και ευνοημένη, από πλευράς ποσότητας  ποιότητας υδάτινων πόρων, δεν έχει επιτύχει την διαχείρισή του με βάση τις παραπάνω αρχές.<br/>
		Παρά την πρόοδο που έχει σημειωθεί στον τομέα της επεξεργασίας λυμάτων, με αυξανόμενο ρυθμό παρατηρούνται προβλήματα ρύπανσης, υπεράντλησης και ταπείνωσης
		υπόγειων υδροφορέων, υφαλμύρωσης παράκτιων υδροφορέων, επιβάρυνσης από βαρέα μέταλλα και νιτρικά,   συρρίκνωσης λιμνών, αλόγιστης χρήσης στο γεωργικό τομέα, 
		αδικαιολόγητων απωλειών στα υδρευτικά δίκτυα. Βάσιμες είναι οι ανησυχίες για κίνδυνο <b>ερημοποίησης</b> εκτεταμένων περιοχών της χώρας, εν όψει και των 
		επιπτώσεων που θα έχει το φαινόμενο της εν εξελίξει κλιματικής αλλαγής.
		Η ευρωπαϊκή Οδηγία-Πλαίσιο για το Νερό (2000/60), η οποία αποτελεί ένα πλήρες και συνεκτικό σχέδιο για την ολοκληρωμένη  διαχείριση του νερού, παραμένει 
		ουσιαστικά ανεφάρμοστη.  <br/>
		Διαπιστώνεται πολυδιάσπαση των φορέων που εμπλέκονται στην διαχείριση των υδατικών πόρων, και ανεπαρκής συγκρότηση, στελέχωση, και εξοπλισμός των Περιφερειακών 
		Υπηρεσιών για τα νερά.<br/><br/>
		
		Είναι αναγκαία μια στροφή πολιτικής που να περιλαμβάνει:
		<ul>
			<li>ουσιαστική εφαρμογή της ευρωπαϊκής οδηγίας για τα νερά.</li>
			<li>Παύση σχεδίων εκτροπής ποταμών σε άλλη λεκάνη απορροής.</li>
			<li>Καμιά ιδιωτικοποίηση φορέα πόσιμου νερού.</li>
			<li>Ανάσχεση της ρύπανσης, της μόλυνσης με βαρέα μέταλλα και νιτρικά, των πρακτικών αλόγιστης υπεράντλησης.</li>
			<li>Aναδιάρθρωση καλλιεργειών ώστε να μειωθούν οι υδροβόρες</li>
			<li>Συστηματική ευαισθητοποίηση του κοινού σε πρακτικές εξοικονόμησης</li>
			<li>Αποφασιστικός ρόλος της αυτοδιοίκησης χωρίς ιδιωτικοποιήσεις,</li>		
		</ul></Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η γενιά μας, καταλήγει η απόφαση, έχει την ευθύνη να παραδώσει τους υδάτινους πόρους της χώρας στις επόμενες ακέραιους, αμόλυντους και δημόσιους 
		«κτήμα ες αεί» όλων των Ελλήνων και θεμέλιο του ιδιαίτερα σημαντικού πλούτου βιοποικιλότητας που διαθέτουμε.</Typography>
		
		
		
		<Typography variant="h1" sx={theme.typography.typography1}>Απόφαση συνεδρίου</Typography>
				
		<Typography variant="h3" sx={theme.typography.typography2}>Οι εκπρόσωποι των οργανώσεων του Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων, τα στελέχη της Αυτοδιοίκησης και οι λοιποί σύνεδροι που άκουσαν τις εισηγήσεις 
		και έλαβαν μέρος στο διάλογο που αναπτύχθηκε κατά τη διάρκεια των εργασιών του 21ου συνεδρίου του Δικτύου, που έγινε στη Λαμία, το Νοέμβριο του 2009, συνεκτιμούν 
		και συναποφασίζουν τα ακόλουθα:
		Το νερό είναι και οφείλει να αντιμετωπίζεται ως κοινωνικό αγαθό και όχι ως εμπόρευμα.<br/>
		Είναι ο κυριότερος πόρος της ανθρώπινης και κάθε άλλης μορφής ζωής, κρίσιμος παράγων της οικονομίας, θεμέλιο της υγιεινής και της ποιότητας ζωής.<br/>
		Η διαχείρισή του οφείλει να υπακούει στις αρχές της αειφορίας, της διαγενεακής δικαιοσύνης και της διαφύλαξης της βιοποικιλότητας και να γίνεται από δημόσιους ή 
		αυτοδιοικητικούς φορείς. Η διεθνής εμπειρία ιδιωτικοποιήσεων του πόσιμου είναι σαφώς αρνητική διότι κατά κανόνα οδήγησε σε αύξηση τιμών, στέρηση του νερού από 
		τα φτωχότερα κοινωνικά στρώματα, ληστρική εκμετάλλευση υδάτινων πόρων.<br/>

		Η χώρα μας, αν και ευνοημένη, αναμφίβολα, τόσο από πλευράς ποσότητας όσο και από πλευράς ποιότητας υδάτινων πόρων, δεν έχει επιτύχει την διαχείρισή του με βάση 
		τις παραπάνω αρχές.<br/>
		Παρά την πρόοδο που έχει σημειωθεί στον τομέα της επεξεργασίας λυμάτων, με αυξανόμενο ρυθμό παρατηρούνται προβλήματα ρύπανσης, υπεράντλησης και ταπείνωσης 
		υπόγειων υδροφορέων, υφαλμύρωσης παράκτιων υδροφορέων, επιβάρυνσης από βαρέα μέταλλα και νιτρικά, συρρίκνωσης λιμνών, αλόγιστης χρήσης στο γεωργικό τομέα, 
		αδικαιολόγητων απωλειών στα υδρευτικά δίκτυα. Βάσιμες είναι οι ανησυχίες για κίνδυνο ερημοποίησης εκτεταμένων περιοχών της χώρας, εν όψει και των επιπτώσεων 
		που θα έχει το φαινόμενο της εν εξελίξει κλιματικής αλλαγής.<br/>
		Γενικότερα συντηρείται μια λογική οικονομικής ανάπτυξης που δεν σέβεται την φέρουσα ικανότητα των υδάτινων πόρων μας.<br/><br/><br/>

		<b>Αναλυτικότερα:</b><br/><br/>
		
		Η ευρωπαϊκή <b>Οδηγία-Πλαίσιο για το Νερό (2000/60)</b>, η οποία αποτελεί ένα πλήρες και συνεκτικό σχέδιο για την ολοκληρωμένη  διαχείριση του νερού, ενσωματώθηκε 
		στο εσωτερικό μας δίκαιο με τον ν. 3199/03, κατά τρόπο ανεπαρκή και με ουσιώδεις ελλείψεις, όπως η ρύθμιση της διαχείρισης όχι κατά λεκάνη απορροής αλλά κατά 
		διοικητική περιφέρεια.<br/>
		Και αυτού όμως του μη πλήρους νόμου η εφαρμογή συναντά μεγάλα εμπόδια και καθυστερήσεις, κυρίως όσον αφορά τα άρθρα 5 (αξιολόγηση ανά λεκάνη απορροής) και 8 
		παρακολούθηση ποσοτικών και ποιοτικών χαρακτηριστικών των υδάτινων σωμάτων).<br/>
		Δεν υπάρχει ένα επαρκές Δίκτυο μέτρησης της ποσότητας και ποιότητας των διαθέσιμων υδατικών πόρων. Χιλιάδες παράνομες ή μη ενταγμένες σε διαχειριστικό σχέδιο 
		γεωτρήσεις οδηγούν σε υπεράντληση των υπόγειων υδάτων.<br/>
		Διαπιστώνεται <b>πολυδιάσπαση των φορέων</b> που εμπλέκονται στην διαχείριση των υδατικών πόρων, <b>ανεπαρκής συγκρότηση</b>, στελέχωση, και εξοπλισμός των 
		Περιφερειακών Υπηρεσιών και επιτροπών παρακολούθησης Υδάτινων πόρων, περιορισμένη δημόσια διαβούλευση με τις ΜΚΟ και την κοινωνία των πολιτών, έλλειψη μακροχρόνιου 
		προγραμματισμού εκ μέρους των φορέων της Αυτοδιοίκησης.<br/>
		Παρατηρείται αδυναμία των φορέων Αυτοδιοίκησης να συνεργάζονται με τους ΤΟΕΒ /ΟΕΒ να συγκροτούν και να λειτουργούν ή εποπτεύουν βιώσιμους φορείς διαχείρισης 
		των υδρευτικών και αρδευτικών έργων, με σύγχρονους κανονισμούς άρδευσης και ύδρευσης, με αποτελεσματική και προοδευτική τιμολόγηση ανάκτησης του λειτουργικού 
		και περιβαλλοντικού κόστους των έργων και υδάτινων πόρων. Επίσης απουσία επαρκών δημόσιων ελεγκτικών μηχανισμών ελέγχου. Λείπουν ακόμη, δημόσιοι θεσμοί ενίσχυσης 
		και προβολής των καλών πρακτικών στον τομέα διαχείρισης των υδατικών πόρων. Παρατηρείται σοβαρή αφαίρεση πόρων του ΕΣΠΑ από έργα βιολογικών καθαρισμών.<br/>
		Επιπλέον με ανησυχία παρακολουθούμε τις κινήσεις για την <b>ιδιωτικοποίηση</b> της Εταιρίας υδάτων της Θεσσαλονίκης.<br/><br/>
		
		Επομένως, κρίνουμε απαραίτητη μια ουσιαστική στροφή της πολιτικής υδάτων τόσο σε κεντρικό όσο και σε περιφερειακό επίπεδο, και με δεσμευτικά χρονοδιαγράμματα,  
		που να περιλαμβάνει:</Typography>
		
		<ul>
			<li>Αναθεώρηση του νόμου 3199/03, ώστε να εναρμονιστεί πλήρως με την ευρωπαϊκή οδηγία για τα νερά.</li>
			<li>Στο πλαίσιο αυτό <b>παύση κάθε σχεδίου εκτροπής υδάτων ποταμών</b> σε διαφορετική από τη δική τους υδατική λεκάνη</li>
			<li>Σαφή πολιτική δέσμευση <b>ενάντια σε σχέδια ιδιωτικοποίησης</b> του πόσιμου νερού. Επιβάλλεται η άμεση παύση κάθε κίνησης για ιδιωτικοποίηση του πόσιμου 
			νερού της Θεσσαλονίκης.</li>
			<li>Πολιτικές <b>ανάσχεσης της υποβάθμισης</b> των επιφανειακών, υπόγειων και θαλάσσιων υδατικών συστημάτων με προτεραιότητα στην απαλλαγή ποταμών και λιμνών από 
			την επιβάρυνση με βαρέα μέταλλα, χημικά και νιτρικά. Ιδιαίτερη μνεία κάνουμε στην αποκατάσταση της ποιότητας των νερών του βοιωτικού Ασωπού και της ίδιας 
			της ύπαρξης της λίμνης Κορώνειας.</li>
			<li>Διασφάλιση επαρκών ποσοτήτων και <b>καλής ποιότητας</b> πόσιμου νερού ειδικότερα στα αστικά κέντρα και στις τουριστικές περιοχές και διατήρηση καλής 
			ποιότητας των νερών κολύμβησης</li>
			<li>Συμμετοχή όλων των δήμων της χώρας στην προγραμματική σύμβαση Υπ. Εσωτερικών – ΙΓΜΕ – ΚΕΔΚΕ, με στόχο την <b>αναβάθμιση των δυνατοτήτων</b> τους για 
			ορθολογική διαχείριση των θεμάτων πόσιμου νερού.</li>
			<li>Στρατηγικό σχέδιο <b>αναδιάρθρωσης των καλλιεργειών</b> της χώρας, με προτεραιότητα στις περιοχές με ήδη ορατά προβλήματα υφαλμύρωσης υδάτων και 
			ημιερημοποίησης εδαφών και με έμφαση στην εναρμόνιση γεωργίας - κτηνοτροφίας και περιβαλλοντικής προστασίας.</li>
			<li>Διαρκής <b>ευαισθητοποίηση του κοινού</b> σε καλές πρακτικές εξοικονόμησης και υπεύθυνης χρήσης του νερού ώστε να στοχεύουμε προοπτικά σε «μηδενικό 
			υδατικό αποτύπωμα».</li>
		</ul>
		<Typography variant="h3" sx={theme.typography.typography2}>
		Οι οργανώσεις του ΠΑΝΔΟΙΚΟ θα συνεχίσουμε τον αγώνα για την υπεράσπιση των υδάτινων σωμάτων της χώρας, θεμελιώδους κεφαλαίου για την βιοποικιλότητα της 
		χώρας μας και για την εξασφάλιση του κυριότερου πόρου για την ποιότητα ζωής της κοινωνίας μας. Θα συνεχίσουμε διατυπώνουμε τεκμηριωμένες προτάσεις πολιτικής 
		για την αειφορική τους διαχείριση.<br/>
		Θεωρούμε ότι ο ρόλος της Αυτοδιοίκησης είναι και πρέπει να παραμείνει αποφασιστικός στη διαχείριση του πόσιμου νερού και ότι, σε συνεργασία και με την κοινωνία 
		των πολιτών, οι φορείς της επιβάλλεται να αναβαθμίζουν σταθερά την ικανότητα τους να διαχειρίζονται αποτελεσματικά και βιώσιμα τους υδάτινους πόρους κάθε 
		περιοχής, για όλους τους πολίτες, χωρίς υποταγή σε ιδιωτικά συμφέροντα.<br/><br/></Typography>
		
		
		<Typography variant="h3" sx={theme.typography.typography2}>
		Η γενιά μας έχει την ευθύνη να παραδώσει τους υδάτινους πόρους της χώρας στις επόμενες ακέραιους, αμόλυντους και δημόσιους «κτήμα ες αεί» όλων των Ελλήνων και 
		θεμέλιο του ιδιαίτερα σημαντικού πλούτου βιοποικιλότητας που διαθέτουμε.<br/><br/>
		
		<b>ΠΑΓΙΑ ΚΑΙ ΣΤΡΑΤΗΓΙΚΗ ΜΑΣ ΕΠΙΔΙΩΞΗ ΕΙΝΑΙ:</b><br/><br/>
		
		<b>ΔΗΜΟΣΙΟΙ, ΑΚΕΡΑΙΟΙ ΚΑΙ ΥΨΗΛΗΣ ΠΟΙΟΤΗΤΑΣ ΥΔΑΤΙΝΟΙ ΠΟΡΟΙ!</b><br/><br/>

		ΟΙ ΣΥΝΕΔΡΟΙ ΤΟΥ 21ου ΣΥΝΕΔΡΙΟΥ ΤΟΥ ΠΑΝΕΛΛΗΝΙΟΥ ΔΙΚΤΥΟΥ ΟΙΚΟΛΟΓΙΚΩΝ ΟΡΓΑΝΩΣΕΩΝ<br/>

		Λαμία 14 Νοεμβρίου 2009</Typography>	
		
	</Box>

    <ScrollToTopButton />
    
    
    </>
  )
}

export default F7_oikologika_07