import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g17/image001.jpg";
import Image02 from "../assets/images/mikrasiatika_g17/image002.jpg";
import Image03 from "../assets/images/mikrasiatika_g17/image003.jpg";
import Image04 from "../assets/images/mikrasiatika_g17/image004.jpg";
import Image05 from "../assets/images/mikrasiatika_g17/image005.jpg";

function G17_mikrasiatika() {
  const theme = useTheme();
  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Η συνθήκη της Λωζάνης (το πλήρες κείμενο)" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <br />
        <br />
        ΣΥΝΘΗΚΗ ΤΗΣ ΛΩΖΑΝΗΣ
        <br />
        Ι. ΣΥΝΘΗΚΗ ΕΙΡΗΝΗΣ <br />
        ΥΠΟΓΡΑΦΕΙΣΑ ΤΗΝ 24 ΙΟΥΛΙΟΥ 1923
        <br />
        Η ΒΡΕΤΤΑΝΙΚΗ ΑΥΤΟΚΡΑΤΟΡΙΑ, Η ΓΑΛΛΙΑ, Η ΙΤΑΛΙΑ, Η ΙΑΠΩΝΙΑ, Η ΕΛΛΑΣ, Η
        ΡΟΥΜΑΝΙΑ, ΤΟ ΣΕΡΒΟ-ΚΡΟΑΤΟ-ΣΛΟΒΕΝΙΚΟΝ ΚΡΑΤΟΣ
        <br />
        <br />
        αφ’ ενός <br />
        ΚΑΙ Η ΤΟYΡΚΙΑ
        <br />
        <br />
        αφ’ ετέρου <br />
        Διαπνεόμεναι υπό του αυτού πόθου όπως τερματίσωσιν οριστικώς την
        εμπόλεμον κατάστασιν, ήτις από του 1914 συνετάραξε την Ανατολήν,
        Μεριμνώσαι όπως αποκαταστήσωσι τας μεταξύ αυτών σχέσεις φιλίας και
        εμπορίου, αναγκαίας δια την χοινήν ευημερίαν των οικείων αυτών Εθνών,
        Λαμβάνουσαι υπ’ όψιν ότι αι σχέσεις αύται δέον να βασίζονται επί του
        σεβασμού της ανεξαρτησίας και της κυριαρχίας των Κρατών, Απεφάσισαν να
        συνομολογήσωσιν επί τούτω Συνθήκην και διώρισαν ως πληρεξουσίους αυτών:{" "}
        <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΟΥ ΗΝΩΜΕΝΟΥ ΒΑΣΙΛΕΙΟΥ ΤΗΣ ΜΕΓΑΛΗΣ
        ΒΡΕΤΤΑΝΙΑΣ ΚΑΙ ΤΗΣ ΙΡΛΑΝΔΙΑΣ ΚΑΙ ΤΩΝ ΥΠΕΡΘΑΛΛΑΣΙΩΝ ΒΡΕΤΤΑΝΙΚΩΝ ΧΩΡΩΝ
        ΑΥΤΟΚΡΑΤΩΡ ΤΩΝ ΙΝΔΙΩΝ:
        <br />
        <br />
        Τον Εντιμότατον Sir Horace George Montagu RUMBOLD, Baronet, G.C.M.G.,
        Ύπατον Αρμοστήν εν Κωνσταντινουπόλει·:
        <br />
        Ο ΠΡΟΕΔΡΟΣ ΤΗΣ ΓΑΛΛΙΚΗΣ ΔΗΜΟΚΡΑΤΙΑΣ::
        <br />
        Τον Εντιμότατον Sir Horace George Montagu RUMBOLD, Baronet, G.C.M.G.,
        Ύπατον Αρμοστήν εν Κωνσταντινουπόλει·:
        <br />
        Ο ΠΡΟΕΔΡΟΣ ΤΗΣ ΓΑΛΛΙΚΗΣ ΔΗΜΟΚΡΑΤΙΑΣ:
        <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟTHΣ Ο ΒΑΣΙΛΕΥΣ ΤΗΣ ΙΤΑΛΙΑΣ:
        <br />
        Τον έντιμον Μαρκήσιον Canulle GARRONI, Γερουσιαστήν του Βασιλείου,
        Πρεσβευτήν της Ιταλίας, Ύπατον Αρμοστήν εν Κωνσταντινουπόλει,:
        <br />
        Μεγαλόσταυρον των Ταγμάτων των Αγίων Μαυρικίου και Λαζάρου και του
        Στέμματος της Ιταλίας· :<br />
        Τον Κύριον Jules Cesar MONTAGNA, Έκτακτον Απεσταλμένον και Πληρεξούσιον
        Υπουργόν εν Αθήναις, Ταξιάρχην του Τάγματος των Αγ. Μαυρικίου και
        Λαζάρου, Ανώτερον Ταξιάρχην του Στέμματος της Ιταλίας· <br />
        <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟTHΣ Ο ΑΥΤΟΚΡΑΤΩΡ ΤΗΣ ΙΑΠΩΝΙΑΣ:
        <br />
        Τον Κύριον Kentaro OTCHIAI, Jusammi, Μέλος πρώτης τάξεως του Τάγματος
        του Ανατέλλοντος Ηλίου, Έκτακτον και Πληρεξούσιον Πρεσβευτήν εν Ρώμη·{" "}
        <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΕΛΛΗΝΩΝ:
        <br />
        <br />
        Τον Κύριον Ελευθέριον Κ. ΒΕΝΙΖΕΛΟΝ, Πρώην Πρόεδρον του Υπουργικού
        Συμβουλίου, Μεγαλόσταυρον του Τάγματος του Σωτήρος· <br />
        Τον Κύριον Δημήτριον ΚΑΚΛΑΜΑΝΟΝ, Πληρεξούοιον Υπουργόν εν Λονδίνω,
        Ταξιάρχην του Τάγματος του Σωτήρος· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΗΣ ΡΟΥΜΑΝΙΑΣ:
        <br />
        <br />
        Τον Κύριον Constanun I. DIAMANTY, Πληρεξούσιον Υπουργόν· <br />
        Τον Κύριον Constanun CONTZESCO Πληρεξούσιον Υπουργόν· <br />
        <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΣΕΡΒΩΝ, ΤΩΝ ΚΡΟΑΤΩΝ ΚΑΙ ΤΩΝ ΣΛΟΒΕΝΩΝ:
        <br />
        Τον Δρα Miloutine YOVANOVITCH, Έκτακτον Απεσταλμένον και Πληρεξούσιον
        Υπουργόν εν Βέρνη· <br />
        <br />
        Η ΚΥΒΕΡΝΗΣΙΣ ΤΗΣ ΜΕΓΑΛΗΣ ΕΘΝΟΣΥΝΕΛΕΥΣΕΩΣ ΤΗΣ ΤΟΥΡΚΙΑΣ:
        <br />
        Τον ISMET Pacha, Υπουργόν επί των Εξωτερικών, Βουλευτήν Αδριανουπόλεως·
        Τον Δρα RIZA NOUR Bey Υπουργόν της Υγιεινής και Κοινωνικής Περιθάλψεως,
        Βουλευτήν Σινώπης
        <br />
        Τον HASSAN Bey, πρώην Υπουργόν Βουλευτήν Τραπεζούντος· <br />
        ΟΙΤΙΝΕΣ, επιδείξαντες αλλήλοις τα πληρεξούσια αυτών έγγραφα, ευρεθέντα
        εν πλήρει τάξει και κατά τους απαιτούμενους τύπους, συνεφώνησαν επί των
        ακολούθων όρων: <br />
        <br />
        ΠΟΛΙΤΙΚΟΙ ΟΡΟΙ <br />
        <br />
        Άρθρον 1. Από της ενάρξεως της ισχύος της παρούσης Συνθήκης, η
        κατάστασις Ειρήνης θα αποκατασταθή οριστικώς μεταξύ της Βρεττανικής
        Αυτοκρατορίας, της Γαλλίας, της Ιταλίας, της Ιαπωνίας, της Ελλάδος, της
        Ρουμανίας, του Σερβο-Κροατο-Σλοβενικού Κράτους, αφ’ ενός, και της
        Τουρκίας, αφ’ ετέρου, ως και μεταξύ των οικείων υπηκόων. <br />
        Εκατέρωθεν θα υφίστανται επίσημοι σχέσεις εν ταις οικείαις δε χώραις, οι
        διπλωματικοί και προξενικοί πράκτορες θα απολαύωσιν υπό την επιφύλαξιν
        συναφθησομένων ιδιαιτέρων συμφωνιών, των καθιερωμένων προνομιών υπό των
        γενικών αρχών του Διεθνούς Δικαίου. <br />
        <br />
        <br />
        ΤΜΗΜΑ Α’ <br />
        <br />
        Ι. ΕΔΑΦΙΚΟΙ ΟΡΟΙ <br />
        <br />
        Άρθρον 2.
        <br />
        Από του Ευξείνου Πόντου προς το Αιγαίον τα σύνορα της Τουρκίας ορίζονται
        ως έπεται: <br />
        (όρα χάρτην υπ’ αριθ. 1). <br />
        1όν μετά της Βουλγαρίας: <br />
        Από της εκβολής του Ρέζβαγια μέχρι του Έβρου, το σημείο ενώσεως των
        συνόρων Τουρκίας, Βουλγαρίας και Ελλάδος: τα νότια σύνορα της
        βουλγαρίας, ως είναι νυν καθωρισμένα. <br />
        <br />
        2ον Μετά της Ελλάδος: <br />
        Εκείθεν μέχρι της συμβολής του Άρδα και του Έβρου: <br />
        ο ρους του Έβρου· <br />
        Εκείθεν προς τα άνω του Άρδα μέχρι σημείο επί του ποταμού τούτου
        ορισθησομένου επί του εδάφους εγγύτατα προς το χωρίον Τσουρέκ-κιοϊ: ο
        ρους του Άρδα· <br />
        Εκείθεν Νοτιοανατολικούς μέχρι σημείου επί του Έβρου εις απόστασιν ενός
        χιλιομέτρου προς τα κάτω του Βόσνα κιοϊ: <br />
        γραμμή αισθητώς ευθεία αφίνουσα εν Τουρκία το χωρίον Βόσνα-κιοϊ. Το
        χωρίον Τσουρέκ-κιοϊ θα παραχωρηθή εις την Ελλάδα ή εις την Τουρκίαν,
        καθ’ όσον η πλειονότης του πληθυσμού ήθελεν αναγνωρισθή ελληνική ή
        τουρκική υπό της εν τω άρθρω 5 προβλεπομένης επιτροπής, μη
        συνυπολογιζομένων των μεταναστευσάντων εις το χωρίον τούτο μετά την 11
        ην Οκτωβρίου 1922. <br />
        Εκείθεν μέχρι του Αιγαίου: ο ρους του Έβρου. <br />
        <br />
        Άρθρον 3.
        <br />
        Από της Μεσογείου μέχρι της μεθορίου της Περσίας, τα σύνορα της Τουρκίας
        καθορίζονται ως εξής: <br />
        1όν Μετά της Συρίας; <br />
        Τα εν τω άρθρω 8 της Γαλλοτουρκικής Συμφωνίας της 20ης Οκτωβρίου 1921
        καθοριζόμενα σύνορα. <br />
        2ον Μετά του Ιράκ: <br />
        Η μεταξύ Τουρκίας και Ιράκ μεθόριος θέλει καθορισθή συμβιβαστικώς μεταξύ
        Τουρκίας και Μεγάλης Βρεττανίας εντός προθεσμίας εννέα μηνών. Μη
        επερχόμενης συμφωνίας μεταξύ των δύο Κυβερνήσεων εντός της προβλεπομένης
        προθεσμίας, η διαφορά θέλει άχθη ενώπιον του Συμβουλίου της Κοινωνίας
        των Εθνών. <br />
        Αι Κυβερνήσεις Τουρκίας και Μεγάλης Βρεττανίας υποχρεούνται αμοιβαίως
        όπως, μέχρις ου ληφθή η επί του ζητήματος της μεθορίου απόφασις εις
        ουδεμίαν προβώσι στρατιωτικήν ή άλλην κίνησιν, δυναμένην να επιφέρη
        οιανδήποτε μεταβολήν εις την παρούσαν κατάστασιν των εδαφών, ων η
        οριστική τύχη θέλει εξαρτηθή εκ της αποφάσεως ταύτης. <br />
        <br />
        Άρθρον 4.
        <br />
        Τα υπό της παρούσης Συνθήκης διαγραφόμενα σύνορα είναι κεχαραγμένα επί
        των συνημμένων τη παρούση Συνθήκη χαρτών υπό κλίμακα 1/1.000.000. <br />
        Εν περιπτώσει διαφοράς μεταξύ του κειμένου και του χάρτου, θέλει ισχύει
        το κείμενον. <br />
        <br />
        Άρθρον 5.
        <br />
        Επιτροπή οροθετήσεως θέλει επιφορτισθή να χάραξη επί του εδάφους τα εν
        τω άρθρω 2 εδ. 2 περιγραφόμενα σύνορα. Η Επιτροπή αυτή θα αποτελεσθή εξ
        ενός αντιπροσώπου της Ελλάδος, ενός αντιπροσώπου της Τουρκίας και ενός
        Προέδρου εκλεγομένου υπ’ αυτών μεταξύ των υπηκόων τρίτης Δυνάμεως.{" "}
        <br />
        Η Επιτροπή θέλει, κατά πάσας τας περιπτώσεις, καταβάλει προσπάθειαν όπως
        ακόλουθη, όσον ένεστι ακριβέστερον, τους εν τη παρούση Συνθήκη ορισμούς,
        λαμβάνουσα υπ’ όψιν κατά το δυνατόν τα διοικητικά όρια και τα τοπικά
        οικονομικά συμφέροντα. <br />
        Αι αποφάσεις της Επιτροπής θα λαμβάνωνται κατά πλειοψηφίαν και θα ώσιν
        υποχρεωτικοί δια τα ενδιαφερόμενα Μέρη. <br />
        Αι δαπάναι της Επιτροπής θα βαρύνωσιν εξ ίσου τα ενδιαφερόμενα Μέρη.{" "}
        <br />
        <br />
        Άρθρον 6.
        <br />
        Όσον αφορά εις τα καθοριζόμενα υπό του ρου ποταμού και ουχί υπό των
        οχθών αυτού σύνορα, οι όροι “ρους” ή “αύλαξ” ων γίνεται χρήσις εν τη
        παρούση Συνθήκη, σημαίνουσι δια μεν τους μη πλωτούς ποταμούς την μέσην
        γραμμήν αυτών ή του κυρίου αυτών βραχίονος, δια δε τους πλωτούς ποταμούς
        την μέσην γραμμήν της κυρίας αύλακος ναυσιπλοίας. Εν τούτοις,
        εναπόκειται εις την Επιτροπήν να καθορίση ειδικότερον, εάν η συνοριακή
        γραμμή θα ακολουθήση, εις τας ενδεχομένας αυτής μετατοπίσεις, τον ούτω
        προσδιορισθέντα ρουν ή την “αύλακα, ή εάν θα καθορισθή οριστικώς εκ της
        θέσεως του ρου ή της αύλακος κατά την έναρξιν της ισχύος της παρούσης
        Συνθήκης. <br />
        Εκτός αντιθέτων διατάξεων της παρούσης Συνθήκης, τα θαλάσσια όρια
        περιλαμβάνουσι τας νήσους και νησίδια τας κειμένας εις απόστασιν
        μικροτέραν των τριών μιλλίων από της ακτής. <br />
        <br />
        Άρθρον 7.
        <br />
        Τα ενδιαφερόμενα Κράτη υποχρειούνται να παρέχωσιν εις την οροθετικήν
        Επιτροπήν πάντα τα αναγκαία δια το έργον αυτής έγγραφα, ιδία δε επίσημα
        αντίγραφα των πρακτικών καθορισμού των σημερινών ή παλαιών συνόρων,
        πάντας τους υφισταμένους χάρτας μεγάλης κλίμακος, τα γεωδαιτικά
        διδόμενα, τα εκτελεσθέντα και μη δημοσιευθέντα σχεδιαγραφήματα, τας
        πληροφορίας περί των πλημμύρων των μεθοριακών ποταμών.
        <br /> Οι χάρται, τα γεωδαιτικά διδόμενα, και τα σχεδιαγραφήματα, έστω
        και μη δημοσιευθέντα, τα ευρισκόμενα εις την κατοχήν των τουρκικών
        Αρχών, δέον να παραδοθώσιν εν Κωνσταντινουπόλει εις τον Πρόεδρον της
        Επιτροπής εντός της βραχυτέρας προθεσμίας από της ενάρξεως της ισχύος
        της παρούσης Συνθήκης. <br />
        Τα ενδιαφερόμενα Κράτη υποχρεούνται, επί πλέον, να δώσωσι διαταγάς εις
        τας τοπικάς Αρχάς, όπως ανακοινώσιν εις την Επιτροπήν παν έγγραφον, ιδία
        σχέδια, κτηματολόγια και κτηματικά βιβλία, παρέχωσι δε, επί τη αιτήσει
        ταύτης, πάσαν πληροφορίαν περί της ιδιοκτησίας, των οικονομικών συνθηκών
        και λοιπάς χρησίμους πληροφορίας. <br />
        <br />
        Άρθρον 8.
        <br />
        Τα ενδιαφερόμενα Κράτη υποχρεούνται να συντρέχωσι την οροθετικήν
        επιτροπήν είτε απ’ αυθείας, είτε δια των τοπικών Αρχών, εν παντί τω
        αφορώντι εις την μετακίνησιν, την κατοικίαν, τους εργάτας, τα αναγκαία
        προς εκπλήρωσιν της αποστολής της υλικά (πασσάλους, ορόσημα). <br />
        Ειδικώς η Οθωμανική Κυβέρνησις αναλαμβάνει την υπαχρέωσιν να παράσχη,
        εάν δεήση, το τεχνικό προσωπικόν, ικανόν να βοηθήση την οροθετικήν
        Επιτροπήν εις την εκπλήρωσιν του έργου της. <br />
        <br />
        Άρθρον 9.
        <br />
        Τα ενδιαφερόμενα Κράτη αναλαμβάνουσι την υποχρέωσιν να καθιστώσι σεβαστά
        τα τιθέμενα υπό της Επιτροπής τριγωνομετρικά σημεία, σήματα, πασσάλους ή
        ορόσημα. <br />
        <br />
        Άρθρον 10.
        <br />
        Τα ορόσημα θέλουσι τεθή εις ορατήν απ’ αλλήλων απόστασιν, θα ώσιν
        ηριθμημένα, η δε τοποθεσία και ο αριθμός αυτών θα σημειώνται εν
        χαρτογραφικώ πίνακι. <br />
        <br />
        Άρθρον 11.
        <br />
        Τα οριστικά πρωτόκολλα οροθετήσεως, οι επισυναπτόμενοι χάρται χαι
        έγγραφα θα γίνωσιν εις τρία προτότυπα, ων δύο θα διαβιβασθώσιν εις τας
        Κυβερνήσεις των ομόρων Κρατών, το δε τρίτον εις την Κυβέρνησιν της
        Γαλλικής Δημοκρατίας, ήτις θέλει επιδόσει επίσημα τούτων αντίγραφα εις
        τας υπογραψάσας την παρούσαν Συνθήκην Δυνάμεις. <br />
        <br />
        Άρθρον 12.
        <br />
        Η ληφθείσα απόφασις τη 13η Φεβρουαρίου 1914 υπό της Συνδιασκέψεως του
        Λονδίνου εις εκτέλεσιν των άρθρων 5 της Συνθήκης του Λονδίνου της 17/30
        Μαΐου 1913, και 15 της Συνθήκης των Αθηνών της 1/14 Νοεμβρίου 1913, η
        κοινοποιηθείσα εις την Ελληνικήν Κυβέρνησιν τη 13 Φεβρουαρίου 1914 και
        αφορώσα εις την κυριαρχίαν της Ελλάδος επί των νήσων της Ανατολικής
        Μεσογείου, εκτός της Ίμβρου, Τενέδου και των Λαγουσών νήσων (Μαυρυών),
        ιδία των νήσων Λήμνου, Σαμοθράκης, Μυτιλήνης, Χίου, Σάμου και Ικαρίας,
        επικυρούνται, υπό την επιφύλαξιν των διατάξεων της παρούσης Συνθήκης των
        συναφών προς τας υπό την κυριαρχίαν της Ιταλίας διατελούσας νήσους, περί
        ων διαλαμβάνει το άρθρον 15. Εκτός αντιθέτου διατάξεως της παρούσης
        Συνθήκης, αι νήσοι, αι κείμεναι εις μικροτέραν απόστασιν των τριών
        μιλλίων της ασιατικής ακτής, παραμένουσιν υπό την τουρκικήν κυριαρχίαν.{" "}
        <br />
        <br />
        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <br />
        <br />
        <br />
        Άρθρον 13.
        <br />
        Προς εξασφάλισιν της ειρήνης, η Ελληνική Κυβέρνησις υποχρεούται να τηρή
        εν ταις νήσοις Μυτιλήνη, Χίω, Σάμω και Ικαρία τα ακόλουθα μέτρα: <br />
        <br />
        1. Αι ειρημέναι νήσοι δεν θα χρησιμοποιηθώσιν εις εγκατάστασιν ναυτικής
        βάσεως ή εις ανέγερσιν οχυρωματικού τίνος έργου. <br />
        2. θα απαγορευθή εις την Ελληνικήν στρατιωτικήν αεροπλοΐαν να
        υπερίπταται του εδάφους της ακτής της Ανατολίας. <br />
        Αντιστοίχως, η Οθωμανική Κυβέρνησις θα απαγορεύση εις την στρατιωτικήν
        αεροπλοΐαν αυτής να υπερίπταται των ρηθεισών νήσων. <br />
        3. Αι ελληνικαι στρατιωτικαί δυνάμεις εν ταις ειρημέναις νήσοις θα
        περιορισθώσι εις τον συνήθη αριθμόν των δια την στρατιωτικήν υπηρεσίαν
        καλουμένων, οίτινες δύνανται να εκγυμνάζωνται επί τόπου, ως και εις
        δύναμιν χωροφυλακής και αστυνομίας ανάλογον προς την εφ’ ολοκλήρου του
        ελληνικού εδάφους υπάρχουσαν τοιαύτην. <br />
        <br />
        Άρθρον 14.
        <br />
        Αι νήσοι Ίμβρος και Τένεδος, παραμένουσαι υπό την τουρκικήν κυριαρχίαν,
        θα απολαύωσιν ειδικής διοικητικής οργανώσεως, αποτελούμενης εκ τοπικών
        στοιχείων και παρεχούσης πάσαν εγγύησιν εις τον μη μουσουλμανικόν
        ιθαγενή πληθυσμόν δι’ ό,τι αφορά εις την τοπικήν διοίκησιν και την
        προστασίαν των προσώπων και των περιουσιών. <br />
        Η διατήρησις της τάξεως θα εξασφαλίζηται εν αυταίς δι’ αστυνομίας
        στρατολογουμένης μεταξύ του ιθαγενούς πληθυσμού, τη φροντίδι της ως άνω
        προβλεπομένης τοπικής διοικήσεως υπό τα διαταγάς της οποίας θα διατελή.{" "}
        <br />
        Αι συνομολογηθείσαι ή συνομολογηθησόμεναι μεταξύ Ελλάδος και Τουρκίας
        συμφωνίαι, αι αφορώσαι την ανταλλαγήν των Ελληνικών και τουρκικών
        πληθυσμών, δεν θα εφαρμοσθώσιν εις τους κατοίκους των νήσων Ίμβρου και
        Τενέδου. <br />
        <br />
        Άρθρον 15.
        <br />
        Η Τουρκία παραιτείται υπέρ της Ιταλίας παντός δικαιώματος και τίτλου επί
        των κάτωθι απαριθμουμένων νήσων, τουτέστι της Αστυπάλαιας, Ρόδου,
        Χάλκης, Καρπάθου, Κάσσου, Τήλου, Νισύρου, Καλύμνου, Λέρου, Πάτμου,
        Λειψούς, Σύμης και Κω, των κατεχομένων νυν υπό της Ιταλίας και των
        νησίδων των εξ αυτών εξαρτωμένων, ως και της νήσου Καστελλορίζου (όρα
        χάρτην υπ’ αρ. 2). <br />
        <br />
        Άρθρον 16.
        <br />
        Η Τουρκία δήλοι ότι παραιτείται παντός τίτλου και δικαιώματος πάσης
        φύσεως επί των εδαφών ή εν σχέσει προς τα εδάφη άτινα κείνται πέραν των
        προβλεπομένων υπό της παρούσης Συνθήκης ορίων, ως και επί των νήσων,
        εκτός εκείνων ων η κυριαρχία έχει αναγνωρισθή αυτή δια της παρούσης
        Συνθήκης, της τύχης των εδαφών και των νήσων τούτων κανονισθείσης ή
        κανονισθησομένης μεταξύ των ενδιαφερομένων.
        <br />
        Αι διατάξεις του παρόντος άρθρου δεν θίγουσι τας συνομολογηθείσας ή
        συνομολογηθησομένας ιδιαιτέρας συμφωνίας μεταξύ της Τουρκίας και των
        ομόρων χωρών λόγω της γειτνιάσεως αυτών. <br />
        <br />
        Άρθρον 17.
        <br />
        Η παραίτησις της Τουρκίας από παντός δικαιώματος και τίτλου αυτής επί
        της Αιγύπτου και επί του Σουδάν θεωρείται γενομένη από της 5ης Νοεμβρίου
        1914. <br />
        <br />
        Άρθρον 18.
        <br />
        Η Τουρκία απαλάσσεται πάσης υποχρεώσεως αφορώσης εις τα οθωμανικά δάνεια
        τα ηγγυημένα δια του αιγυπτιακού φόρου, ήτοι τα δάνεια του 1855, 1891
        και 1894. <br />
        Των δια την υπηρεσίαν των τριών τούτων δανείων γενομένων υπό της
        Αιγύπτου ετησίων καταβολών, αποτελουσών σήμερον μέρος της υπηρεσίας του
        Δημοσίου Αιγυπτιακού Χρέους, η Αίγυπτος απαλλάσσεται πάσης άλλης
        υποχρεώσεως ως προς το Δημόσιον Οθωμανικόν Χρέος. <br />
        <br />
        Άρθρον 19.
        <br />
        Μεταγενέστεραι συμφωνίαι, συναφθησόμεναι υπό όρους καθοριστέους μεταξύ
        των ενδιαφερομένων Δυνάμεων, θα διακανονίσωσι τα προκύπτοντα ζητήματα εκ
        της αναγνωρίσεως του Αιγυπτιακού Κράτους, εφ’ ου δεν εφαρμόζονται αϊ
        διατάξεις της παρούσης Συνθήκης, αϊ αφορώσαι τα αποσπασθέντα από της
        Τουρκίας εδάφη δυνάμει της Συνθήκης ταύτης. <br />
        <br />
        Άρθρον 20.
        <br />
        Η Τουρκία δηλοί ότι αναγνωρίζει την προσάρτησιν της Κύπρου
        ανακηρυχθείσαν υπό της Βρεττανικής Κυβερνήσεως την 5ην Νοεμβρίου 1914.
        Άρθρον 21.
        <br />
        Οι τούρκοι, οι εγκατεστημένοι εν τη νήσω Κύπρω κατά την 5ην Νοεμβρίου
        1914, θα αποκτήσωσιν, εφ’ οις όροις προβλέπει ο εγχώριος νόμος, την
        βρεττανικήν ιθαγένειαν, απο-βάλλοντες ως εκ τούτου την τουρκικήν. <br />
        θα δύνανται, εν τούτοις, επί δύο έτη από της ενάρξεως της ισχύος της
        παρούσης Συνθήκης, να ασκήσωσι δικαίωμα επιλογής υπέρ της τουρκικής
        ιθαγενείας· εν τοιαύτη περιπτώσει, δέον να εγκαταλείψωσι την Κύπρον
        εντός δώδεκα μηνών, αφ’ ης ημέρας ασκήσωσι το δικαίωμα της επιλογής.
        Ωσαύτως αποβάλλουσι την τουρκικήν ιθαγένειαν, οι τούρκοι υπήκοοι, οι
        εγκατεστημένοι εv τη νήσω Κύπρω κατά την έναρξιν της ισχύος της παρούσης
        Συνθήκης, οίτινες κατά την εποχήν αυτήν έχουσιν αποκτήσει ή πρόκειται να
        αποκτήσωσι την βρεττανικήν ιθαγένειαν, συνεπεία αιτήσεως υποβληθέσης
        κατά τας διατάξεις της εγχωρίου νομοθεσίας. <br />
        Εννοείται ότι η Κυβέρνησις της Κύπρου θα δύναται να αρνηθή την
        βρεττανικήν ιθαγένειαν εις τους αποκτήσαντος, άνευ της αδείας της
        Τουρκικής Κυβερνήσεως, πάσαν άλλην ιθαγένειαν εκτός της τουρκικής.{" "}
        <br />
        <br />
        Άρθρον 22.
        <br />
        Υπό την επιφύλαξιν των γενικών διατάξεων του άρθρου 27, η Τουρκία δηλοί
        ότι αναγνωρίζει την οριστικήν κατάργησιν παντός δικαιώματος ή προνομίου
        πάσης φύσεως, ων απήλαυεν εν Λυβύη, δυνάμει της Συνθήκης της Λωζάννης
        της 18ης Οκτωβρίου 1912 και των συναφών Πράξεων. <br />
        <br />
        <br />
        <br />
        2. ΕΙΔΙΚΑΙ ΔΙΑΤΑΞΕΙΣ <br />
        <br />
        Άρθρον 23.
        <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη αναγνωρίζουσι και διακηρύττουσι την αρχήν της
        ελευθερίας διελεύσεως και πλου, δια θαλάσσης ή δια του αέρος, εv καιρώ
        ειρήνης ως και εν καιρώ πολέμου, εντός των στενών του Ελλησπόντου, της
        Προποντίδος και του Βοσπόρου, ως προβλέπεται εν τη ειδική Συμβάσει του
        καθεστώτος των Στενών τη συνομολογηθείση υπό σημερινήν ημερομηνίαν.
        <br />
        Η Σύμβασις αύτη θα έχη το αυτό κύρος και την αυτήν ισχύν έναντι των ώδε
        συμβαλλομένων Υψηλών Μερών, ως εάν περιείχετο εν τη παρούση Συνθήκη.{" "}
        <br />
        <br />
        Άρθρον 24.
        <br />
        Η συνομολογηθείσα σήμερον ειδική Σύμβασις περί του καθεστώτος της εν τω
        άρθρω 2 της παρούσης Συνθήκης, περιγραφόμενης μεθορίου, θα έχη το αυτό
        κύρος και την αυτήν ισχύν έναντι των ώδε συμβαλλομένων Υψηλών Μερών, ως
        εάν περιείχετο εν τη παρούση Συνθήκη.
        <br />
        <br />
        Άρθρον 25.
        <br />
        Η Τουρκία υποχρεούται να αναγνωρίση την πλήρη ισχύν των Συνθηκών ειρήνης
        και των προσθέτων Συμβάσεων, αίτινες συνωμολογήθησαν υπό των
        συμβαλλομένων Δυνάμεων μετά των Δυνάμεων αίτινες επολέμησαν παρά το
        πλευρόν της Τουρκίας, να αποδεχθή τας αποφάσεις αίτινες ελήφθησαν ή θα
        ληφθώσιν αναφορικώς προς τα εδάφη της τέως Γερμανικής Αυτοκρατορίας, της
        Αυστρίας, της Ουγγαρίας και της Βουλγαρίας και να αναγνωρίση τα νέα
        Κράτη εντός των ούτω καθορισθέντων συνόρων. <br />
        <br />
        <img
          src={Image03}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <br />
        <br />
        <br />
        Άρθρον 26.
        <br />
        Η Τουρκία δηλοί από τούδε ότι αναγνωρίζει και αποδέχεται τα σύνορα της
        Γερμανίας, της Αυστρίας, της Βουλγαρίας, της Ελλάδος, της Ουγγαρίας, της
        Πολωνίας, της Ρουμανίας, του Σερβο-Κροατο-Σλοβενικού και του
        Τσεχοσλοβακικού Κράτους, ως ταύτα καθορίζονται ή θέλουσι καθορισθή υπό
        των προβλεπομένων εν άρθρω 25 Συνθηκών ή υπό πάσης συμπληρωματικής
        συμβάσεως.
        <br />
        <br />
        Άρθρον 27.
        <br />
        Ουδεμία πολιτική, νομοθετική ή διοικητική εξουσία ή δικαιοδοσία θέλει
        ασκηθή, δι’ οιονδήποτε λόγον, υπό της Κυβερνήσεως ή των Αρχών της
        Τουρκίας εκτός του τουρκικού εδάφους επί των υπηκόων εδάφους
        διατελούντος υπό την κυριαρχίαν ή το προτεκτοράτον των λοιπών Δυνάμεων
        των υπογραψασών την παρούσαν Συνθήκην και επί των υπηκόων εδάφους
        αποσπασθέντος της Τουρκίας. <br />
        Εννοείται ότι η πνευματική δικαιδοσία των θρησκευτικών μουσουλμανικών
        Αρχών δεν θέλει ποσώς θιγή. <br />
        <br />
        Άρθρον 28.
        <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη δηλούσιν ότι αποδέχονται, έκαστον το καθ*
        εαυτό, την πλήρη και υπό πάσαν έποψιν κατάργησιν των Διομολογήσεων εν
        Τουρκία. <br />
        <br />
        Άρθρον 29.
        <br />
        Οι Μαροκηνοί υπήκοοι Γάλλοι και οι Τυνήσιοι θα υπόκεινται κατά πάντα εν
        Τουρκία εις το αυτό καθεστώς εις ό και οι λοιποί Γάλλοι. <br />
        Οι υπήκοοι της Λιβύης θα υπόκεινται κατά πάντα εν Τουρκία εις το αυτό
        καθεστώς εις ο και οι λοιποί υπήκοοι Ιταλοί. <br />
        Αι διατάξεις του παρόντος άρθρου δεν προδικάζουσι την ιθαγένειαν των εν
        Τουρκία εγκατεστημένων προσώπων των καταγόμενων εκ της Τύνιδας, της
        Λιβύης και του Μαρόκου. <br />
        Αντιστοίχως, οι τούρκοι υπήκοοι θα απολαύωσιν εν ταις χώραις, ων οι
        κάτοικοι επωφελούνται των διατάξεων των εδαφίων 1 και 2, του αυτού
        καθεστώτος του οποίου απολαύουσιν εv Γαλλία και εν Ιταλία. <br />
        Το καθεστώς εις ο θα υπάγωνται εν Τουρκία τα εκ των χωρών, ων οι
        κάτοικοι απολαύουσι των διατάξεων του εδαφίου 1, προερχόμενα εμπορεύματα
        ή τα εις τα χώρας ταύτας κατευθυνόμενα και, αντιστοίχως, το καθεστώς εις
        ο θα υπάγωνται εν ταις ρηθείσαις χώραις τα εκ Τουρκίας, προερχόμενα ή
        δι’ αυτήν προοριζόμενα εμπορεύματα, θέλει καθορισθή εκ συμφώνου μεταξύ
        των Κυβερνήσεων Γαλλίας και Τουρκίας. <br />
        <br />
        ΤΜΗΜΑ Β’. ΙΘΑΓΕΝΕΙΑ <br />
        <br />
        Άρθρον 30.
        <br />
        Οι τούρκοι υπήκοοι, οι εγκατεστημένοι εις τα εδάφη άτινα, δυνάμει των
        διατάξεων της παρούσης Συνθήκης, αποσπώνται της Τουρκίας, καθίστανται
        αυτοδικαίως και κατά τους όρους της εγχωρίου νομοθεσίας υπήκοοι του
        Κράτους εις ο το έδαφος μεταβιβάζεται. <br />
        <br />
        Άρθρον 31.
        <br />
        Οι άγοντες ηλικίαν άνω των 18 ετών, οίτινες αποβάλλουσι την τουρκικήν
        ιθαγένειαν και αποκτώσιν αυτοδικαίως νέον ιθαγένειαν δυνάμει του άρθρου
        30, έχουσι δικαίωμα επιλογής υπέρ της τουρκικής ιθαγενείας επί δύο έτη
        από της ενάρξεως της ισχύος της παρούσης Συνθήκης.
        <br />
        <br />
        Άρθρον 32.
        <br />
        Οι άγοντες ηλικίαν άνω των 18 ετών, οίτινες είναι εγκατεστημένοι εις
        έδαφος αποσπώμενον της Τουρκίας συμφώνως προς την παρούσαν Συνθήκην και
        οίτινες διαφέρουσι φυλετικώς της πλειονότητας του πληθυσμού του ρηθέντος
        εδάφους, έχουσι δικαίωμα επί δύο έτη από της ενάρξεως της ισχύος της
        παρούσης Συνθήκης να ασκήσωσιν επιλογήν υπέρ της ιθαγενείας ενός των
        Κρατών, ένθα η πλειονότης του πληθυσμού είναι της αυτής φυλής μετά του
        ατόμου του ασκούντος το δικαίωμα της επιλογής και υπό τον όρον της
        συγκαταθέσεως του Κράτους τούτου. <br />
        <br />
        Άρθρον 33.
        <br />
        Οι ασκήσαντες το δικαίωμα της επιλογής, συμφώνως προς τας διατάξεις των
        άρθρων 31 και 32, οφείλουσιν, εντός των επομένων δώδεκα μηνών, να
        μεταφέρωσι την κατοικίαν των εις το Κράτος υπέρ ου ήσκησαν την επιλογήν.{" "}
        <br />
        Είναι ελεύθεροι να διατηρήσωσι την ην κέκτηνται ακίνητον περιουσίαν εν
        τω εδάφει του Κράτους, ένθα είχον την κατοικίαν των προ της παρ’ αυτών
        γενομένης επιλογής. <br />
        Δύνανται δε να αποκομίσωσι τα κινητά αυτών πάσης φύσεως, χωρίς να
        υποβληθώσιν ένεκα τούτου εις ουδέν εισαγωγικόν ή εξαγωγικόν τέλος ή
        δασμόν.
        <br />
        Άρθρον 34.
        <br />
        Υπό την επιφύλαξιν των συμφωνιών, αίτινες θα απέβαινον αναγκαίοι μεταξύ
        των Κυβερνήσεων των ασκουσών την εν τοις αποσπωμέναις της Τουρκίας
        χώραις εξουσίαν και των Κυβερνήσεων των χωρών ένθα εισίν εγκατεστημένοι,
        οι άγοντες ηλικίαν άνω των 18 (των τούρκοι υπήκοοι, οι καταγόμενοι εξ
        εδάφους αποσπώμενου της Τουρκίας δυνάμει της παρούσης Συνθήκης και
        εγκατεστημένοι εις το εξωτερικόν κατά την έναρξιν της ισχύος αυτής,
        δύνανται να ασκήσωσιν επιλογήν υπέρ της επικρατούσης εν τη χώρα εξ ης
        κατάγονται ιθαγενείας, εάν συνδέωνται φυλετικώς μετά της πλειονότητας
        του πληθυσμού της χώρας ταύτης και εάν η ασκούσα την εξουσίαν Κυβέρνησις
        συναινέση εις τούτο. Το δικαίωμα τούτο της επιλογής δέον να ασκηθή εντός
        δύο ετών από της ενάρξεως της ισχύος της παρούσης Συνθήκης. <br />
        <br />
        Άρθρον 35.
        <br />
        Αι συμβαλλόμεναι Δυνάμεις αναλαμβάνουσι την υποχρέωσιν όπως ουδέν
        παρεμβάλλωσι πρόσκομμα εις την άσκησιν του δικαιώματος επιλογής του
        προβλεπομένου υπό της παρούσης Συνθήκης ή των συνομολογηθεισών Συνθηκών
        Ειρήνης μετά της Γερμανίας, Αυστρίας, Βουλγαρίας ή Ουγγαρίας, ή υπό
        Συνθήκης συνομολογηθείσης μεταξύ των συμβαλλομένων τούτων Δυνάμεων πλην
        της Τουρκίας, ή μιας εξ αυτών και της Ρωσσίας, ή μεταξύ αυτών τούτων των
        συμβαλλομένων Δυνάμεων και επιτρεπούσης εις τους ενδιαφερομένους την
        απόκτησιν πάσης άλλης προσιτής αυτοίς ιθαγενείας. <br />
        <br />
        Άρθρον 36.
        <br />
        Αι σύζυγοι ακολουθούσι την κατάστασιν των συζύγων αυτών και τα ηλικίας
        κάτω των 18 ετών τέκνα την των γονέων των δια παν ό,τι αφορά εις την
        εφαρμογήν των διατάξεων του παρόντος Τμήματος. <br />
        <br />
        <img
          src={Image04}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <br />
        <br />
        <br />
        ΤΜΗΜΑ Ε. ΠΡΟΣΤΑΣΙΑ ΤΩΝ ΜΕΙΟΝΟΤΉΤΩΝ <br />
        <br />
        Άρθρον 37.
        <br />
        Η Τουρκία αναλαμβάνει την υποχρέωσιν όπως αι εν τοις άρθροις 38 – 44
        περιεχόμεναι διατάξεις αναγνωρισθώσιν ως θεμελιώδεις νόμοι, όπως ουδείς
        νόμος ή κανονισμός ή επίσημος τις πράξις διατελώσιν εν αντιφάσει ή εν
        αντιθέσει προς τας διατάξεις ταύτας και όπως ουδείς νόμος ή κανονισμός ή
        επίσημος τις πράξις κατισχύωσιν αυτών. <br />
        <br />
        Άρθρον 38.
        <br />
        Η Τουρκική Κυβέρνησις αναλαμβάνει την υποχρέωσιν να παρέχη εις πάντας
        τους κατοίκους της Τουρκίας πλήρη και απόλυτον προστασίαν της ζωής και
        της ελευθερίας αυτών, αδιακρίτως γεννήσεως, εθνικότητος, γλώσσης, φυλής
        ή θρησκείας. <br />
        Πάντες οι κάτοικοι της Τουρκίας δικαιούνται να πρεσβεύωσιν ελευθέρως,
        δημοσία τε και κατ’ ιδίαν, πάσαν πίστιν, θρησκείαν ή δοξασίαν ων η
        άσκησις δεν ήθελεν είναι ασυμβίβαστος προς την δημόσιαν τάξιν και τα
        χρηστά ήθη. <br />
        Αι μη μουσουλμανικαί μειονότητες θα απολαύωσιν πλήρως της ελευθερίας
        κυκλοφορίας και μεταναστεύσεως, υπό την επιφύλαξιν των εφαρμοζομένων,
        εφ’ όλου ή μέρους του εδάφους, εις άπαντας τους τούρκους υπηκόους μέτρων
        άτινα, ήθελον τυχόν ληφθή υπό της Τουρκικής Κυβερνήσεως χάριν της
        εθνικής αμύνης και της τηρήσεως της δημοσίας τάξεως. <br />
        <br />
        Άρθρον 39.
        <br />
        Οι ανήκοντες εις μη μουσουλμανικάς μειονότητας υπήκοοι τούρκοι θα
        απολαύωσι των αυτών αστικών και πολιτικών δικαιωμάτων ων και οι
        μουσουλμάνοι. Πάντες οι κάτοικοι της Τουρκίας, άνευ διακρίσεως
        θρησκεύματος, θα ώσιν ίσοι απέναντι του νόμου. <br />
        Η διαφορά θρησκείας, δοξασίας ή πίστεως δεν οφείλει να αποτελέση κώλυμα
        δι* ουδένα τούρκον υπήκοον ως προς την απόλαυσιν των αστικών και
        πολιτικών δικαιωμάτων και ιδία την παραδοχήν εις τας δημοσίας θέσεις,
        αξιώματα και τιμάς ή την εξάσκησιν των διαφόρων επαγγελμάτων και
        βιομηχανιών. Ουδείς περιορισμός θέλει επιβληθή κατά της ελευθέρας
        χρήσεως παρά παντός τούρκου υπηκόου οιασδήποτε γλώσσης, είτε εν ταις
        ιδιωτικαίς ή εμπορικαίς σχέσεσιν, είτε ως προς την θρησκείαν τον τύπον
        και πάσης φύσεως δημοσιεύματα, είτε εν ταις δημοσίαις συναθροίσεσιν.{" "}
        <br />
        Παρά την ύπαρξιν της επισήμου γλώσσης, θα παρέχωνται αι προσήκουσαι
        ευκολίαι εις τους τούρκους υπηκόους, τους λαλούντες γλώσσαν άλλην ή την
        τουρκικήν, δια την προφορικήν χρήσιν της γλώσσης, αυτών ενώπιων των
        δικαστηρίων. <br />
        <br />
        Άρθρον 40.
        <br />
        Οι τούρκοι υπήκοοι, οι ανήκοντες εις μη μουσουλμανικάς μειονότητας, θα
        απολαύωσι “νομικώς” και πραγματικώς της αυτής προστασίας και των αυτών
        εγγυήσεων ων απολαύουσι και οι λοιποί τούρκοι υπήκοοι, θα έχωσιν ιδίως
        ίσον δικαίωμα να συνιστώσι, διευθύνωσι και εποπτεύωσιν, ιδίαις δαπάναις,
        παντός είδους, φιλανθρωπικά, θρησκευτικά ή κοινωφελή ιδρύματα, σχολεία
        και λοιπά εκπαιδευτήρια μετά του δικαιώματος να ποιώνται ελευθέρως εν
        αυτοίς χρήσιν της γλώσσης των και να τελώσιν ελευθέρως τα της θρησκείας
        των. <br />
        <br />
        Άρθρον 41.
        <br />
        Εν ταις πόλεσι και περιφερείαις, ένθα διαμένει σημαντική αναλογία
        υπηκόων, μη μουσουλμάνων η Τουρκική Κυβέρνησις θα παρέχη ως προς την
        δημοσίαν εκπαίδευσιν, τας προσήκουσας ευκολίας προς εξασφάλισιν της εν
        τοις δημοτικοίς σχολείοις παροχής εν τη ιδία αυτών γλώσση, της
        διδασκαλίας εις τα τέκνα των εν λόγω τούρκων υπηκόων. Η διάταξις αύτη
        δεν κωλύει την Τουρκικήν Κυβέρνησιν να καταστήση υποχρεωτικήν την
        διδασκαλίαν της τουρκικής γλώσσης εν τοις ειρημένοις σχολείοις. <br />
        Εν ταις πόλεσι και περιφερείαις, ένθα υπάρχει σημαντική αναλογία τούρκων
        υπηκόων ανηκόντων εις μη μουσουλμανικάς μειονότητας, θέλει εξασφαλισθή
        εις τας μειονότητας ταύτας δικαία συμμετοχή εις την διάθεσιν των
        χρηματικών ποσών, άτινα τυχόν θα εχορηγούντο εκ του δημοσίου χρήματος
        υπό του προϋπολογισμού του Κράτους ή των δημοτικών και λοιπών
        προϋπολογισμών επί εκπαιδευτικώ θρησκευτικώ ή φιλανθρωπικώ σκοπώ. <br />
        Τα ποσά ταύτα θα καταβάλλωνται εις τους αρμοδίους αντιπροσώπους των
        ενδιαφερομένων καθιδρυμάτων και οργανισμών. <br />
        <br />
        Άρθρον 42.
        <br />
        Η Τουρκική Κυβέρνησις δέχεται να λάβη απέναντι των μη μουσουλμανικών
        μειονοτήτων, όσον αφορά την οικογενειακήν ή προσωπικήν αυτών κατάστασιν,
        πάντα τα κατάλληλα μέτρα, όπως τα ζητήματα ταύτα κανονίζωνται συμφώνως
        προς τα έθιμα των μειονοτήτων τούτων. <br />
        Τα μέτρα ταύτα θέλουσιν επεξεργασθή ειδικαί επίτροπαι, αποτελούμεναι εξ
        ίσου αριθμού αντιπροσώπων της Τουρκικής Κυβερνήσεως και μιας εκάστης των
        ενδιαφερομένων μειονοτήτων. Εν περιπτώσει διαφωνίας, η Τουρκική
        Κυβέρνησις και το Συμβούλιον της Κοινωνίας των Εθνών θέλουσι διορίσει,
        από κοινού, επιδιαιτητήν εκλεγόμενον μεταξύ των ευρωπαίων νομομαθών.{" "}
        <br />
        Η Τουρκική Κυβέρνησις υποχρεούται να παρέχη πάσαν προστασίαν εις τας
        εκκλησίας, συναγωγάς, νεκροταφεία και λοιπά θρησκευτικά καθιδρύματα των
        ειρημένων μειονοτήτων. Εις τα ευαγή καθιδρύματα ως και τα θρησκευτικά
        και φιλανθρωπικά καταστήματα των αυτών μειονοτήτων, των ήδη ευρισκομένων
        εν Τουρκία, θα παρέχηται πάσα ευκολία και άδεια, η δε Τουρκική
        Κυβέρνησις, προκειμένου περί ιδρύσεως νέων θρησκευτικών και
        φιλανθρωπικών καθιδρυμάτων, ουδεμίαν θέλει αρνηθή εκ των αναγκαίων
        ευκολιών, αίτινες έχουσιν εξασφαλισθή εις τα λοιπά ιδιωτικά καθιδρύματα
        ομοίας φύσεως.
        <br />
        <br />
        Άρθρον 43.
        <br />
        Οι εις τας μη μουσουλμανικάς μειονότητας ανήκοντες τούρκοι υπήκοοι δεν
        θα ώσιν υποχρεωμένοι να εκτελώσι πράξεις αποτελούσας παράβασιν της
        πίστεως ή των θρησκευτικών των εθίμων, ούτε θα περιπίπτωσιν εις
        ανικανότητα τίνα αρνούμενοι να παραστώσιν ενώπιον των δικαστηρίων ή να
        εκτελέσωσι νόμιμόν τίνα πράξιν κατά την ημέραν της εβδομαδιαίας των
        αναπαύσεως. <br />
        Ουχ ήττον, η διάταξις αυτή δεν απαλάσσει τους τούρκους τούτους υπηκόους
        των υποχρεώσεων, αίτινες επιβάλλονται εις πάντας τους λοιπούς τούρκους
        υπηκόους προς τήρησιν της δημοσίας τάξεως. <br />
        <br />
        Άρθρον 44.
        <br />
        Η Τουρκία παραδέχεται όπως αι διατάξεις των προηγουμένων άρθρων του
        παρόντος Τμήματος, εφ’ όσον αφορώσιν εις τους μη μουσουλμάνους υπηκόους
        της Τουρκίας, αποτελέσωσιν υποχρεώσεις διεθνούς συμφέροντος και τεθώσιν
        υπό την εγγύησιν της Κοινωνίας των Εθνών. Αι διατάξεις αύται δεν
        δύνανται να τροποποιηθώσιν άνευ της συγκαταθέσεως της πλειοψηφίας του
        Συμβουλίου της Κοινωνίας των Εθνών. <br />
        <br />
        Η Βρεττανική Αυτοκρατορία, η Γαλλία, η Ιταλία και η Ιαπωνία υποχρεούνται
        δια της παρούσης Συνθήκης να μη αρνηθώσι την συγκατάθεσιν αυτών εις
        πάσαν τροποποίησιν των ειρημένων άρθρων, ην ήθελε κατά τους νομίμους
        τύπους αποφασίσει η πλειοψηφία του Συμβουλίου της Κοινωνίας των Εθνών. Η
        Τουρκία δέχεται όπως παν Μέλος του Συμβουλίου της Κοινωνίας των Εθνών
        έχη το δικαίωμα να επισύρη την προσοχήν του Συμβουλίου επί πάσης
        παραβάσεως ή κινδύνου παραβάσεως οιασδήποτε των υποχρεώσεων τούτων και
        όπως το Συμβούλιον δύναται να ενεργή καθ’ οιονδήποτε τρόπον και παρέχη
        οιασδήποτε οδηγίας κρινόμενος καταλλήλους και αποτελεσματικός εν τη
        περιστάσει. <br />
        Η Τουρκία δέχεται προς τούτοις όπως, εν περιπτώσει διχογνωμίας επί
        νομικών ή πραγματικών ζητημάτων αφορώντων τα άρθρα ταύτα, μεταξύ της
        Τουρκικής Κυβερνήσεως και μιας οιασδήποτε των λοιπών υπογραψασών την
        παρούσαν Συνθήκην Δυνάμεων ή πάσης άλλης Δυνάμεως Μέλους του Συμβουλίου
        της Κοινωνίας των Εθνών, η τοιαύτη διχογνωμία θεωρηθή ως διεθνούς
        χαρακτήρας διαφορά κατά το γράμμα του άρθρου 14 του Συμφώνου της
        Κοινωνίας των Εθνών. <br />
        Η Τουρκική Κυβέρνησις δέχεται όπως πάσα τοιούτου είδους διαφορά, επί τη
        αιτήσει του ετέρου των Μερών, παραπέμπηται εις το Διαρκές Δικαστήριον
        Διεθνούς Δικαιοσύνης. <br />
        Η απόφασις του Διαρκούς Δικαστηρίου θα η ανέκκλητος, θα έχη δε την ισχύν
        και το κύρος αποφάσεως εκδοθείσης δυνάμει του άρθρου 13 του Συμφώνου.
        <br />
        <br />
        Άρθρον 45.
        <br />
        Τα αναγνωρισθέντα δια των διατάξεων του παρόντος Τμήματος δικαιώματα εις
        τας εν Τουρκία μη μουσουλμανικάς μειονότητας, αναγνωρίζονται επίσης υπό
        της Ελλάδος εις τας εν τω εδάφει αυτής ευρισκομένας μουσουλμανικάς
        μειονότητας. <br />
        <br />
        <img
          src={Image05}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <br />
        <br />
        <br />
        ΔΗΜΟΣΙΟΝΟΜΙΚΟΙ ΟΡΟΙ <br />
        <br />
        ΔΗΜΟΣΙΟΝ ΟΘΩΜΑΝΙΚΟΝ ΧΡΕΟΣ <br />
        <br />
        Άρθρον 46. <br />
        Το Δημόσιον Οθωμανικόν Χρέος, ως καθορίζεται εv τω συνημμένω τω παρόντι
        Τμήματι Πίνακι, θα κατανεμηθή, συμφώνως προς τους εν τω παρόντι Τμήματι
        όρους, μεταξύ της Τουρκίας, των Κρατών υπέρ των οποίων απεσπάσθησαν
        εδάφη εκ της Οθωμανικής Αυτοκρατορίας συνεπεία των Βαλκανικών πολέμων
        του 1912-1913, των Κρατών εις τα οποία παρεχωρήθησαν αι εν τοις άρθροις
        12 και 15 της παρούσης Συνθήκης αναφερόμεναι νήσοι και το εv τω
        τελευταίω εδαφίω του παρόντος άρθρου έδαφος και τέλος των νεωστί
        δημιουργηθέντων Κρατών εν τοις ασιατικοίς εδάφεσι τοις αποσπωμένοις εκ
        της Οθωμανικής Αυτοκρατορίας δυνάμει της παρούσης Συνθήκης. Άπαντα τα ως
        είρηται Κράτη θα συμμετάσχωσι προς τούτοις, υπό τους εν τω παρόντι
        Τμήματι διαγραφόμενους όρους, των ετησίων υποχρεώσεων των αφορωσών εις
        την υπηρεσίαν του Δημοσίου Οθωμανικού Χρέους από των χρονολογιών των
        προβλεπομένων εν άρθρω 53. <br />
        Από των εν τω άρθρω 53 χρονολογιών, η Τουρκία επ’ ουδενί λόγω δύναται να
        θεωρηθή υπεύθυνος δια τας μερίδας συμμετοχής δι’ ων επεβαρύνθησαν τα
        λοιπά Κράτη. <br />
        Το έδαφος της Θράκης, όπερ κατά την 1ην Αυγούστου 1914 διετέλει υπό την
        οθωμανικήν κυριαρχίαν, ευρίσκεται δ’ εκτός των εν τω άρθρω 2 της
        παρούσης Συνθήκης διαγραφομένων ορίων της Τουρκίας, θα θεωρηθή, δι’ ό,τι
        αφορά την κατανομήν του δημοσίου Οθωμανικού Χρέους, ως αποσπασθέν εκ της
        Οθωμανικής Αυτοκρατορίας δυνάμει της παρούσης Συνθήκης. <br />
        <br />
        Άρθρον 47. <br />
        Εντός προθεσμίας τριών μηνών από της ενάρξεως της ισχύος της παρούσης
        Συνθήκης, το Συμβούλιον του Δημοσίου Οθωμανικού χρέους οφείλει να
        καθορίση, επί τη οριζομένη εv τοις άρθροις 50 και 51 βάσει, το ποσόν των
        ετησίων χρεωλυτικών δόσεων των αναλογουσών εις τα εν τω Μέρει Α’ του
        συνημμένου τω παρόντι Τμήματι Πίνακος αναφερόμενα δάνεια και
        επιβαρυνουσών έκαστον των ενδιαφερομένων Κρατών, να φέρη δε εις γνώσιν
        των Κρατών τούτων το εν λόγω ποσόν. <br />
        Τα Κράτη ταύτα δικαιούνται ν’ αποστείλωσιν εις Κωνσταντινούπολιν
        αντιπροσώπους, ίνα παρακολουθήσωσι τας επί τούτου εργασίας του
        Συμβουλίου του Δημοσίου Οθωμανικού Χρέους. <br />
        Το Συμβούλιον του Χρέους θα εκπλήρωση τα καθήκοντα άτινα προβλέπονται
        υπό του άρθρου 134 της μετά της Βουλγαρίας Συνθήκης Ειρήνης της 27ης
        Νοεμβρίου 1919. <br />
        Πάσαι αι τυχόν αναφυησόμεναι διαφοραί μεταξύ των ενδιαφερομένων Μερών,
        ως προς την εφαρμογήν των εν τω παρόντι άρθρω διατυπουμένων αρχών, θα
        παραπέμπωνται, εντός μηνός το βραδύτερον από της εν τω πρώτω εδαφίω
        προβλεπομένης κοινοποιήσεως, εις διαιτητήν, τον οποίον το Συμβούλιον της
        Κοινωνίας των Εθνών θέλει παρακληθή να διορίση και όστις οφείλει να
        αποφασίση εντός προθεσμίας τριών μηνών κατ’ ανώτατον όριον. Αι αποδοχαί
        του διαιτητού θέλουσι καθορισθή υπό του Συμβουλίου της Κοινωνίας των
        Εθνών και επιβαρύνει μετά των λοιπών εξόδων της διαιτησίας τα
        ενδιαφερόμενα Μέρη. <br />
        Αι αποφάσεις του διαιτητού θα ώσιν αμετάκλητοι. Η προσφυγή εις τον
        ειρημένον διαιτητήν δεν θα αναστέλλη την πληρωμήν των ετησίων
        χρεωλυτικών δόσεων. <br />
        <br />
        Άρθρον 48. <br />
        Τα Κράτη, εκτός της Τουρκίας, μεταξύ των οποίων θέλει κατανεμηθή το
        Δημόσιον Οθωμανικόν Χρέος, ως τούτο καθορίζεται εν τω Μέρει Α’ του
        συνημμένου τω παρόντι Τμήματι Πίνακος, οφείλουσιν, εντός προθεσμίας
        τριών μηνών από της γενησομένης κατά τας διατάξεις του άρθρου 47
        κονοποιήσεως του αναλογούντος ενι εκάστω τούτων μέρους εκ των εν τω
        ρηθέντι άρθρω προβλεπομένων ετησίων βαρών, να παράσχωσιν εις το
        Συμβούλιον του Χρέους ικανάς εγγυήσεις προς εξασφάλισιν της πληρωμής του
        μεριδίου αυτών. <br />
        Εν η περιπτώσει αι εγγυήσεις αύται δεν ήθελον δοθή εντός της ως άνω
        προθεσμίας, ή εν περιπτώσει διαφωνίας ως προς το κατάλληλον των δοθεισών
        εγγυήσεων, πάσα Κυβέρνησις υπογράψασα την παρούσαν Συνθήκην δύναται να
        αναφέρηται εις το Συμβούλιον της Κοινωνίας των Εθνών. <br />
        Το Συμβούλιον της Κοινωνίας των Εθνών θα δύναται να αναθέτη εις τους
        διεθνείς οικονομικούς οργανισμούς, τους υφισταμένους εν ταις χώραις,
        εκτός της Τουρκίας, μεταξύ των οποίων κατανέμεται το χρέος, την
        είσπραξιν των προσόδων αίτινες εδόθησαν ως εγγύησις. Αι αποφάσεις του
        Συμβουλίου της Κοινωνίας των Εθνών θα ώσιν αμετάκλητοι. <br />
        <br />
        Άρθρον 49. <br />
        Εντός ενός μηνός, αφ’ ης ημέρας ήθελε καθορισθή οριστικώς, συμφώνως προς
        τας διατάξεις του άρθρου 47, το ποσόν των ετησίων χρεωλυτικών δόσεων το
        αναλογούν εκάστω των ενδιαφερομένων Κρατών, θέλει συνέλθει εν Παρισίοις
        Επιτροπή όπως ορίση τον τρόπον της κατανομής του ονομαστικού κεφαλαίου
        του Δημοσίου Οθωμανικού Χρέους, ως τούτο καθορίζεται εν τω Μέρει Α’ του
        συνημμένου τω παρόντι Τμήματι Πίνακος. Η κατανομή αύτη θέλει γίνει κατά
        την αυτήν αναλογία την εφαρμοσθείσαν επί της διανομής των ετησίων
        χρεωλυτικών δόσεων, λαμβανομένων υπ’ όψει των όρων των συμβάσεων δανείων
        ως και των διατάξεων του παρόντος Τμήματος. <br />
        Η εν τω 1ω εδαφίω προβλεπομένη Επιτροπή θα απαρτίζηται εξ ενός
        αντιπροσώπου της Τουρκικής Κυβερνήσεως, ενός αντιπροσώπου του Συμβουλίου
        του Δημοσίου Οθωμανικού Χρέους, ενός αντιπροσώπου των Δανείων, εκτός του
        Ηνοποιημένου και των Τουρκικών Λαχειοφόρων Ομολογιών, ως και του
        αντιπροσώπου, ον εν έκαστον των ενδιαφερομένων Κρατών έχει το δικαίωμα
        να διορίση. Παν ζήτημα, εφ’ ου η Επιτροπή δεν ήθελε καταλήξει εις
        συμφωνίαν, θα παραπέμπηται εις τον υπό του άρθρου 47, εδάφιον 4,
        προβλεπόμενον διαιτητήν. <br />
        Εν η περιπτώσει τη Τουρκία ήθελεν αποφασίσει να εκδώση νέους τίτλους
        εκπροσωπούντας το αναλογούν αυτή μέρος, ο καταμερισμός του κεφαλαίου του
        Χρέους θα γίνη εν πρώτοις, όσον αφορά την Τουρκίαν, υπό συμβουλίου
        αποτελουμένου εκ του αντιπροσώπου της Τουρκικής Κυβερνήσεως, του
        αντιπροσώπου του Συμβουλίου του Δημοσίου Οθωμανικού Χρέους και του
        αντιπροσώπου των Δανείων, εκτός του Ηνοποιημένου και των Λαχειοφόρων
        Τουρκικών Ομολογιών. Οι νεωστί εκδοθέντες τίτλοι θα παραδίδωνται εις την
        Επιτροπήν, ήτις θα μεριμνήση περί της παραδόσεως αυτών εις τους
        κατόχους, υπό όρους πιστοποιούντας την απαλλαγήν της Τουρκίας ως και το
        δικαίωμα των κατόχων έναντι των λοιπών Κρατών άτινα επιβαρύνονται δια
        μέρους του Δημοσίου Οθωμανικού Χρέους. Οι εκδοθέντες τίτλοι, οι
        εκπροσωπούντες το μερίδιον εκάστου Κράτους επί του Δημοσίου Οθωμανικού
        Χρέους, θα ώσιν απηλλαγμένοι εν τοις χώραις των Υψηλών Συμβαλλομένων
        Μερών παντός δικαιώματος χαρτοσήμου ή λοιπών τελών, άτινα θα συνεπήγετο
        η ρηθείσα έκδοσις. <br />
        Η πληρωμή των επιβαρυνουσών έκαστον ενδιαφερόμενον Κράτος ετησίων
        χρεωλυτικών δόσεων δεν δύναται να αναβληθή συνεπεία των διατάξεων του
        παρόντος άρθρου των αφορωσών εις την κατανομήν του ονομαστικού
        κεφαλαίου. <br />
        <br />
        Άρθρον 50. <br />
        Η κατανομή των εν τω άρθρω 47 προβλεπομένων ετησίων βαρών ως και η του
        ονομαστικού κεφαλαίου του Δημοσίου Οθωμανικού Χρέους, περί ου το άρθρον
        49, θέλουσι γίνει ως εξής: <br />
        1όν Τα προ της 17ης Οκτωβρίου 1912 δάνεια και τα συναφή προς αυτά βάρη
        θέλουσι κατανεμηθή μεταξύ της Οθωμανικής Αυτοκρατορίας, ως αύτη υφίστατο
        κατόπιν των Βαλκανικών πολέμων του 1912-1913, των Βαλκανικών Κρατών υπέρ
        ων απεσπάσθη έδαφος εκ της Οθωμανικής Αυτοκρατορίας συνεπεία των
        ρηθέντων πολέμων, και των Κρατών εις ά παρεχωρήθησαν οι εν τοις άρθροις
        12 και 15 της παρούσης Συνθήκης αναφερόμεναι νήσοι. Αι εδαφικαί
        μεταβολαί, οι επελθούσαι αφ’ ης ετέθησαν εν ισχύί οι τερματίσασαι τους
        πολέμους τούτους Συνθήκαι ή αϊ μεταγενέστεραι τοιαύται, θέλουσι ληφθή
        υπ’ όψιν. <br />
        2ον Το παραμένον εις βάρος της Οθωμανικής Αυτοκρατορίας μετά την πρώτην
        ταύτην διανομήν υπόλοιπον των δανείων και το υπόλοιπον των συναφών προς
        αυτά ετησίων χρεωλυτικών δόσεων, ηυξημένα δια των δανείων των
        συνομολογηθέντων υπό της ειρημένης Αυτοκρατορίας μεταξύ της 17ης
        Οκτωβρίου 1912 και της 1ης Νοεμβρίου 1914, ως και των συναφών προς ταύτα
        ετησίων χρεωλυτικών δόσεων, θα κατανεμηθώσι μεταξύ της Τουρκίας, των
        νεωστί εν Ασία δημιουργηθέντων Κρατών, υπέρ ων απεσπάσθη έδαφος εκ της
        Οθωμανικής Αυτοκρατορίας δυνάμει της παρούσης Συνθήκης, και του Κράτους
        εις ο παρεχωρήθη το εν τω τελευταίω εδαφίω του άρθρου 46 της Συνθήκης
        ταύτης αναφερόμενον έδαφος. <br />
        Η κατανομή του κεφαλαίου θα γίνη δι’ έκαστον δάνειον επί του ποσού του
        υφισταμένου κατά την έναρξιν της ισχύος της παρούσης Συνθήκης κεφαλαίου.
        <br />
        <br />
        Άρθρον 51. <br />
        Το ποσόν του αναλογούντος εκάστω ενδιαφερόμενω Κράτει μέρους εκ των
        ετησίων υποχρεώσεων του Δημοσίου Οθωμανικού Χρέους συνεπεία της εν τω
        άρθρω 50 προβλεπομένης κατανομής, θέλει καθορισθή ως εξής: <br />
        1όν Όσον αφορά εις την προβλεπομένην εν τη 1η παραγράφω του άρθρου 50
        κατανομήν, θέλει εν πρώτοις καθορισθή το μέρος το βαρύνον το σύνολο των
        εν τοις άρθροις 12 και 15 αναφερομένων νήσων και των εκ της Οθωμανικής
        Αυτοκρατορίας αποσπασθέντων εδαφών συνεπεία των Βαλκανικών πολέμων. Το
        μέρος τούτο δέον να έχη, εν σχέσει προς το ολικόν ποσόν των διανεμητέων
        ετησίων χρεωλυτικών δόσεων συμφώνως προς τας διατάξεις του άρθρου 50,
        την αυτήν αναλογίαν ην και ο μέσος όρος των ολικών προσόδων των
        προαναφερομένων νήσων και εδαφών, ομού λαμβανομένων, προς τον μέσον όρον
        των ολικών προσόδων της Οθωμανικής Αυτοκρατορίας κατά τα οικονομικά έτη
        1910-1911 και 1911 -1912, συμπεριλαμβανομένου του προϊόντος των
        επιβληθέντων τω 1907 προσθέτων τελωνειακών δασμών. <br />
        Το ούτω καθοριζόμενον ποσόν θέλει μετά ταύτα κατανεμηθή μεταξύ των
        Κρατών εις ά παρεχωρήθησαν τα εν τω προηγουμένω εδαφίω αναφερόμενα
        εδάφη, το δε μέρος, όπερ συνεπώς θα επιβαρύνη εν έκαστον των Κρατών
        τούτων, δέον να έχη, ως προς το κατανεμηθέν μεταξύ αυτών ολικόν ποσόν,
        την αυτήν αναλογίαν, οίαν έχει ο μέσος όρος των προσόδων του εις έκαστον
        Κράτος παραχωρηθέντος εδάφους προς τον μέσον όρον των ολικών προσόδων
        κατά τα οικονομικά έτη 1910-1911 και 1911-1912 του συνόλου των εδαφών,
        άτινα απε-σπάσθησαν εκ της Οθωμανικής Αυτοκρατορίας συνεπεία των
        Βαλκανικών πολέμων, και των εν τοις άρθροις 12 και 15 αναφερομένων
        νήσων. Εν τω υπολογισμώ των εν τω παρόντι εδαφίω προβλεπομένων προσόδων
        δεν θα ληφθώσιν υπ’ όψιν αι εισπράξεις των τελωνείων. <br />
        2ον Όσον αφορά τα αποσπώμενα εκ της Οθωμανικής Αυτοκρατορίας εδάφη
        δυνάμει της παρούσης Συνθήκης, συμπεριλαμβανομένου και του εδάφους εις ο
        αφορά το τελευταίον εδάφιον του άρθρου 46, το ποσόν του αναλογούντος
        εκάστω ενδιαφερομένω Κράτει μεριδίου, δέον να έχη, εν σχέσει προς το
        ολικόν ποσόν των ετησίων χρεωλυτικών δόσεων, των κατανιμητέων συμφώνως
        ταις διατάξεσι της 2ας παραγράφου του άρθρου 50, την αυτήν αναλογίαν, ην
        παρουσιάζει ο μέσος όρος των προσόδων του αποσπώμενου εδάφους προς τον
        μέσον όρον των ολικών προσόδων της Οθωμανικής Αυτοκρατορίας κατά τα
        οικονομικά έτη 1910-1911 και 1911-1912 (συμπεριλαμβανομένου του
        προϊόντος των επιβληθέντων τω 1907 προσθέτων τελωνειακών δασμών),
        μειωμένων κατά το αναλογούν μέρος εις τα εν τη 1η παραγράφω αναφερόμενα
        εδάφη και τας νήσους. <br />
        <br />
        Άρθρον 52. <br />
        Αι προβλεπόμεναι προκαταβολαί εν τω Μέρει Β’ του συνημμένου τω παρόντι
        Τμήματι Πίνακος, θα κατανεμηθώσι μεταξύ της Τουρκίας και των εν τω άρθρω
        46 αναφερομένων Κρατών, υπό τους εξής όρους: <br />
        1ον ‘Όσον αφορά εις τας προβλεπομένας εν τω πινάκι προκαταβολάς, αίτινες
        υφίσταντο κατά την 17ην Οκτωβρίου 1912, το ποσόν του μη καταβληθέντος
        κεφαλαίου, αν υπάρχη τοιούτον, κατά την έναρξιν της ισχύος της παρούσης
        Συνθήκης, ως και οι λήξαντες τόκοι από των εν τω πρώτω εδαφίων του
        άρθρου 53 αναφερομένων προθεσμιών και αι γενόμεναι πληρωμαί από των
        προθεσμιών τούτων, θα κατανεμηθώσι συμφώνως προς τας διατάξεις της 1ης
        παραγράφου του άρθρου 50 και της 1ης παραγράφου του άρθρου 51. <br />
        2ον Όσον αφορά το αναλογούντα εις την Οθωμανικήν Αυτοκροτορίαν ποσά,
        συνεπεία της πρώτης ταύτης κατανομής, και τας προβλεπομένας εν τω πίνακι
        προκαταβολάς, αίτινες συνήφθησαν υπό της ειρημένης Αυτοκρατορίας μεταξύ
        της 17ης Οκτωβρίου 1912 και της 1ης Νοεμβρίου 1914, το ποσόν του μη
        καταβληθέντος κεφαλαίου, εάν τυχόν υπάρχη κατά την έναρξιν της ισχύος
        της παρούσης Συνθήκης, ως και οι λήξαντες από της 1ης Μαρτίου 1920 τόκοι
        και αι γενόμεναι από της προθεσμίας ταύτης πληρωμαί, θα κατανεμηθώσι
        συμφώνως προς τας διατάξεις της 2ας παραγράφου του άρθρου 50 και της 2ας
        παραγράφου του άρθρου 51. <br />
        Το Συμβούλιον του Δημοσίου Οθωμανικού Χρέους οφείλει, εντός τριών μηνών
        από της ενάρξεως της ισχύος της παρούσης Συνθήκης, να καθορίση το εκάστω
        των ενδιαφερομένων Κρατών αναλογούν μέρος εκ των ως είρηται προκαταβολών
        και να φέρη εις γνώσιν αυτών το ποσόν τούτο. <br />
        Τα ποσά, δι’ ων επεβαρύνθησαν τα λοιπά εκτός της Τουρκίας κράτη, θα
        καταβάλλονται υπ’ αυτών εις το Συμβούλιον του Χρέους και θα πληρώνωνται
        υπό του τελευταίου τούτου εις τους πιστωτάς ή θα φέρωνται υπ’ αυτού εις
        πίστωσιν της Τουρκικής Κυβερνήσεως μέχρι του ποσού των γενομένων
        πληρωμών υπό της Τουρκίας είτε λόγω τόκων, είτε λόγω καταβολών δια
        λογαριασμόν των ειρημένων Κρατών. <br />
        Αι εν τω προηγουμένω εδαφίω προβλεπόμεναι καταβολαί θα γίνωνται δια
        πέντε ίσων ετησίων δόσεων από της ενάρξεως της ισχύος της παρούσης
        Συνθήκης. Το μέρος των πληρωμών τούτων, όπερ δέον να καταβληθή εις τους
        πιστωτάς της Οθωμανικής Αυτοκρατορίας, θα φέρη τους εν τοις συμβολαίοις
        των προκαταβολών συνομολογηθέντας τόκους. Το αναλογούν εις το Τουρκικόν
        Κράτος μέρος θα καταβληθή ατόκως. <br />
        <br />
        Άρθρον 53. <br />
        Αι ετήσιαι χρεωλυτικαί δόσεις των δανείων του Δημοσίου Οθωμανικού
        Χρέους, ως τούτο καθορίζεται εν τω Μέρει Α’ του συνημμένου τω παρόντι
        Τμήματι Πίνακος, αι οφειλόμεναι υπό των κρατών, υπέρ ων απεσπάσθη έδαφος
        εκ της Οθωμανικής Αυτοκρατορίας συνεπεία των Βαλκανικών πολέμων, θα ώσιν
        απαιτηταί από της ενάρξεως της ισχύος των Συνθηκών δυνάμει των οποίων
        μετεβιβάσθησαν τα εδάφη ταύτα εις τα ειρημένα Κράτη. ‘Όσον αφορά εις τας
        εν τω άρθρω 12 αναφερομένας νήσους, το χρεωλύσιον θα η απαιτητόν από της
        1/14ης Νοεμβρίου 1913, όσον δ’ αφορά τας εν τω άρθρω 15 νήσους, από της
        1 Της Οκτωβρίου 1912. <br />
        Αι οφειλόμεναι χρεωλυτικαί δόσεις υπό των νεωστί δημιουργηθέντων Κρατών
        εν τοις αποσπώμενου. της Οθωμανικής Αυτοκρατορίας ασιατικοίς εδάφεσι
        δυνάμει της παρούσης Συνθήκης και υπό του Κράτους εις ο παρεχωρήθη το εν
        τω τελευταίω εδαφίω του άρθρου 46 έδαφος, θα ώσιν απαιτηταί από της 1ης
        Μαρτίου 1920. <br />
        <br />
        Άρθρον 54. <br />
        Τα εν τω Μέρει Α’ του συνημμένου τω παρόντι Τμήματι Πίνακος
        απαριθμούμενα έντοκα γραμμάτια του 1911, 1912 και 1913, θέλουσι πληρωθή
        μετά των συμφωνηθέντων τόκων, εντός δέκα ετών εν τοις συμβολαίοις
        οριζομένων προς πληρωμήν προθεσμιών. <br />
        <br />
        Άρθρον 55. <br />
        Τα εν τω άρθρω 46 αναφερόμενα Κράτη, συμπεριλαμβανομένης και της
        Τουρκίας, θα καταβάλωσιν εις το Συμβουλιον του Δημοσίου Οθωμανικού
        Χρέους τας ετησίας χρεωλυτικάς δόσεις αίτινες αφορώσιν εις το Δημόσιον
        Οθωμανικόν Χρέος, ως τούτο καθορίζεται εν τω συνημμένω των παρόντι
        Τμήματι Πίνακι, και αι οποίαι, επιβαρύνουσαι αυτά και καταστάσαι
        απαιτηταί από των εν τω άρθρω 53 προβλεπομένων χρονολογιών,
        καθυστερούνται. Η πληρωμή αυτή θέλει γίνει ατόκως δι’ είκοσιν ίσων
        ετησίων δόσεων από της ενάρξεως της ισχύος της παρούσης Συνθήκης. <br />
        Το ποσόν των ετησίων χρεωλυτικών δόσεων των καταβαλλομένων υπό των
        λοιπών, πλην της Τουρκίας, Κρατών εις το Συμβούλιον του Χρέους, θα
        φέρηται υπό του Συμβουλίου τούτου, μέχρι συμπληρώσεως των υπό της
        Τουρκίας καταβληθέντων ποσών δια λογαριασμόν των εφημένων Κρατών, εις
        μείωσιν των καθυστερουμένων ποσών άτινα ήθελεν ακόμη οφείλει η Τουρκία.{" "}
        <br />
        <br />
        Άρθρον 56. <br />
        Το Διοικητικόν Συμβουλιον του Δημοσίου Οθωμανικού Χρέους δεν θα περιλάβη
        πλέον αντιπροσώπους των γερμανών, αυστριακών και ούγγρων κατόχων. <br />
        <br />
        Άρθρον 57. <br />
        Επί του εδάφους των Υψηλών Συμβαλλομένων Μερών, αι προθεσμίαι προσαγωγής
        των τοκομεριδίων των δανείων και των προκαταβολών του Δημοσίου
        Οθωμανικού Χρέους και των οθωμανικών δανείων του 1855, 1891 και 1894,
        των ηγγυημένων διά του φόρου της Αιγύπτου, και αι προθεσμίαι προσαγωγής
        των κληρωθέντων τίτλων των ειρημένων δανείων προς εξόφλησιν, θα
        θεωρηθώσιν ως ανασταλείσαι από της 29ης Οκτωβρίου 1914 μέχρι της λήξεως
        τριών μηνών από της ενάρξεως της ισχύος της παρούσης Συνθήκης. <br />
        <br />
        ΤΜΗΜΑ Β’ <br />
        <br />
        ΔΙΑΤΑΞΕΙΣ ΔΙΑΦΟΡΟΙ <br />
        <br />
        Άρθρον 58. <br />
        Η Τουρκία αφ’ ενός, και αι λοιπαί συμβαλλόμεναι Δυνάμεις (εξαιρέσει της
        Ελλάδος) αφ’ εταίρου, παραιτούνται αμοιβαίως πάσης χρηματικής απαιτήσεως
        δια τας απωλείας ή ζημίας ας η Τουρκία και αι ειρημέναι Δυνάμεις ως και
        οι υπήκοοι αυτών (συμπεριλαμβανομένων των νομικών προσώπων) υπέστησαν
        κατά την από 1ης Αυγούστου 1914 μέχρι της ενάρξεως της ισχύος της
        παρούσης Συνθήκης περίοδον, είτε συνεπεία πολεμικών πράξεων, είτε εκ
        μέτρων επιτάξεως, μεσεγγυήσεως, διαθέσεως ή δημεύσεως. <br />
        Ουχ ήττον, η προηγουμένη διάταξις δεν θίγει τας διατάξεις του Μέρους Γ
        (Οικονομικοί όροι) της παρούσης Συνθήκης. <br />
        Η Τουρκία παραιτείται υπέρ των λοιπών συμβαλλομένων Δυνάμεων (εξαιρέσει
        της Ελλάδος) παντός δικαιώματος επί των εις χρυσόν ποσών, άτινα
        μετεβιβάσθησαν υπό της Γερμανίας και της Αυστρίας δυνάμει του άρθρου 259
        εδ. 1. της Συνθήκης Ειρήνης της 2ης Ιουνίου 1919 μετά της Γερμανίας και
        του άρθρου 210 εδ. 1. της Συνθήκης Ειρήνης της 10ης Σεπτεμβρίου 1919
        μετά της Αυστρίας. <br />
        Ακυρούνται πάσαι αι υποχρεώσεις πληρωμής, δι’ ων έχει επιβαρυνθή το
        Διοικητικό Συμβούλιον του Δημοσίου Οθωμανικού Χρέους είτε δυνάμει της
        Συμβάσεως της 20ης Ιουνίου 1331 (3 Ιουλίου 1915) της αφορώσης εις το
        τουρκικόν χαρτονόμισμα της πρώτης εκδόσεως, είτε δυνάμει του επί των
        χαρτονομισμάτων τούτων οπισθογράφου κειμένου. <br />
        Η Τουρκία αποδέχεται επίσης να μη ζήτηση παρά της Βρεττανικής
        Κυβερνήσεως ή παρά των υπηκόων αυτής την απόδοσιν των πληρωθέντων ποσών
        δια τα πολεμικά σκάφη, άτινα παρηγγέλθησαν εν Αγγλία υπό της Οθωμανικής
        Κυβερνήσεως και επετάχθησαν υπό της Βρεττανικής Κυβερνήσεως κατά το
        1914, παραιτουμένη πάσης εκ του λόγου τούτου απαιτήσεως. <br />
        <br />
        Άρθρον 59. <br />
        Η Ελλάς αναγνωρίζει την υποχρέωσιν αυτής όπως επανορθώση τας
        προξενηθείσας εν Ανατολία ζημίας εκ πράξεων του ελληνικού στρατού ή της
        ελληνικής διοικήσεως αντιθέτων προς τους νόμους του πολέμου. <br />
        Εξ άλλου η Τουρκία, λαμβάνουσα υπ’ όψιν την οικονομικήν κατάστασιν της
        Ελλάδος, ως αύτη προκύπτει εκ της παρατάσεως του πολέμου και των
        συνεπειών αυτού, παραιτείται οριστικώς πάσης απαιτήσεως κατά της
        Ελληνικής Κυβερνήσεως περί επανορθώσεων. <br />
        <br />
        Άρθρον 60. <br />
        Τα Κράτη, υπέρ ων απεσπάσθη ή αποσπάται έδαφος εκ της Οθωμανικής
        Αυτοκρατορίας, είτε συνεπεία των Βαλκανικών πολέμων, είτε δυνάμει της
        παρούσης Συνθήκης, θα αποκτήσωσιν άνευ ανταλλάγματος πάσαν ιδιοκτησίαν
        της Οθωμανικής Αυτοκρατορίας κειμένην εν τω εδάφει τούτω. Εννοείται ότι
        οι δυνάμει των Αυτοκρατορικών διαταγμάτων της 26ης Αυγούστου 1324 (8
        Σεπτεμβρίου 1908) και 20ης Απριλίου 1325 (2 Μαΐου 1909) μεταβιβασθείσαι
        εκ του Αυτοκρατορικού Ταμείου εις το Κράτος ιδιοκτησίαι, ως και αι κατά
        την 30ην Οκτωβρίου 1918 διατελούσαι υπό την διαχείρισιν του
        Αυτοκρατορικού Ταμείου επ’ ωφελεία δημοσίας υπηρεσίας, περιλαμβάνονται
        μεταξύ των εν τω προηγουμένω εδαφίω αναφερομένων ιδιοκτησιών των
        ειρημένων Κρατών υποκαθισταμένων εις την Οθωμανικήν Κυβέρνησιν όσον
        αφορά τας ιδιοκτησίας ταύτας και υποχρεουμένων να σεβασθώσι τα επ’ αυτών
        συνεστημένα Βακούφια. <br />
        Η αναφυείσα διαφορά μεταξύ της Ελληνικής Κυβερνήσεως και της Τουρκικής
        ως προς τας ιδιοκτησίας τας μεταβιβασθείσας εκ του Αυτοκρατορικού
        Ταμείου εις το Κράτος και κειμένας εντός των περιελθόντων εις την Ελλάδα
        εδαφών της πρώην Οθωμανικής Αυτοκρατορίας, είτε συνεπεία των Βαλκανικών
        πολέμων, είτε μεταγεστέρως, θα υποβληθή, επί τη βάσει
        συνομολογηθησομένου συνυποσχετικού, εις Διαιτητικόν Δικαστήρισν εν Χάγη,
        συμφώνως προς το συνημμένον τη Συνθήκη των Αθηνών της 1/14ης Νοεμβρίου
        1913, υπ’ αριθ. 2, ειδικόν Πρωτόκολλον. <br />
        Αι διατάξεις του παρόντος άρθρου δεν θέλουσι μεταβάλει την νομικήν φύσιν
        των επ’ ονόματι του Αυτοκρατορικού Ταμείου εγγεγραμμένων και υπό την
        διαχείρισιν αυτού διατελεσασών ιδιοκτησιών, των μη αναφερομένων εν τοις
        εδαφίοις 2 και 3 του παρόντος άρθρου. <br />
        <br />
        Άρθρον 61. <br />
        Οι διακαιούχοι πολιτικών και στρατιωτικών συντάξεων, οίτινες εγένοντο
        δυνάμει της παρούσης Συνθήκης υπήκοοι άλλου τινός Κράτους, εκτός της
        Τουρκίας, ουδεμίαν θα δύνανται να ασκήσωσι, λόγω των συντάξεων τούτων,
        απαίτησιν κατά της Τουρκικής Κυβερνήσεως. <br />
        <br />
        Άρθρον 62. <br />
        Η Τουρκία αναγνωρίζει την μεταβίβασιν πασών των πιστώσεων, ας έχουσιν
        εναντίον αυτής η Γερμανία, η Αυστρία, η Βουλγαρία και η Ουγγαρία
        συμφώνως προς το άρθρον 261 της εν Βερσαλλίαις συνομολογηθείσης τη 28η
        Ιουνίου 1919 Συνθήκης Ειρήνης μετά της Γερμανίας και προς τα αντίστοιχα
        άρθρα των Συνθηκών Ειρήνης της 10ης Σεπτεμβρίου 1919 μετά της Αυστρίας,
        της 27ης Νοεμβρίου 1919 μετά την Βουλγαρίας και της 4ης Ιουνίου 1920
        μετά της Ουγγαρίας. <br />
        Αι λοιπαί συμβαλλόμεναι Δυνάμεις συμφωνούσιν όπως απαλλάξωσι την
        Τουρκίαν των επιβαρυνόντων ταύτην, εκ του λόγου τούτου, χρεών. Αι
        πιστώσεις ας η Τουρκία έχει εναντίον της Γερμανίας, της Αυστρίας, της
        Βουλγαρίας και της Ουγγαρίας μεταβιβάζονται ωσαύτως εις τας ειρημένας
        συμβαλλομένας Δυνάμεις. <br />
        <br />
        Άρθρον 63. <br />
        Η Τουρκική Κυβέρνησις, εκ συμφώνου μετά των λοιπών συμβαλλομένων
        Δυνάμεων, δηλοί ότι απαλλάσσει την Γερμανικήν Κυβέρνησιν της
        αναληφθείσης υπ’ αυτής διαρκούντος του πολέμου υποχρεώσεως, όπως
        αποδέχηται γραμμάτια εκδεδομένα υπό της Τουρκικής Κυβερνήσεως, εφ’
        ωρισμένη τιμή συναλλάγματος, προς πληρωμήν εξαχθησομένων εκ Γερμανίας
        εις Τουρκίαν εμπορευμάτων μετά τον πόλεμον .<br />
        <br />
        ΟΙΚΟΝΟΜΙΚΟΙ ΟΡΟΙ <br />
        <br />
        Άρθρον 64. <br />
        Εν τω παρόντι Μέρει, η έκφρασις “Σύμμαχοι Δυνάμεις” δηλοί τας
        συμβαλλομένας Δυνάμεις, εκτός της Τουρκίας ο όρος “Σύμμαχοι υπήκοοι”
        περιλαμβάνει τα φυσικά πρόσωπα, τας εταιρείας, τους συνεταιρισμούς και
        τα ιδρύματα τα υπαγόμενα εις τας συμβαλλομένας δυνάμεις, εκτός της
        Τουρκίας, ή εις Κράτος ή έδαφος διατελούν υπό το προτεκτοράτον μιας των
        ειρημένων Δυνάμεων. <br />
        Των διατάξεων του παρόντος Μέρους, των αφορωσών εις τους “Συμμάχους
        υπηκόους”, θα επωφελώνται τα πρόσωπα, άτινα, μη όντα υπήκοοι των
        Συμμάχων Δυνάμεων, υπήχθησαν υπό των οθωμανικών Αρχών, λόγω της
        προστασίας ης πράγματι απήλαυον εκ μέρους των ειρημένων Δυνάμεων, εις το
        αυτό καθεστώς, εις ο και οι Σύμμαχοι υπήκοοι, και υπέστησαν ως εκ τούτου
        ζημίας. <br />
        <br />
        ΤΜΗΜΑ Α’ <br />
        <br />
        ΠΕΡΙΟΥΣΙΑΙ, ΔΙΚΑΙΩΜΑΤΑ ΚΑΙ ΣΥΜΦΕΡΟΝΤΑ <br />
        <br />
        Άρθρον 65. <br />
        Αι περιουσίαι, τα δικαιώματα και συμφέροντα, τα υφιστάμενα εισέτι και
        δυνάμενα να εξακριβωθώσιν επί των εδαφών άτινα παραμένουσι τουρκικά κατά
        την έναρξιν της ισχύος της παρούσης Συνθήκης, ανήκοντα δε εις πρόσωπα
        άτινα ήσαν Σύμμαχοι υπήκοοι κατά την 29ην Οκτωβρίου 1914, θέλουσιν
        αμέσως αποδοθή εις τους δικαιούχους, εν η ευρίσκονται καταστάσει. <br />
        Αντιστοίχως αι εις τούρκους υπηκόους ανήκουσαι περιουσίαι, δικαιώματα
        και συμφέροντα, αι εισέτι υφιστάμεναι και δυνάμεναι να εξακριβωθώσιν επί
        των εδαφών των διατελούντων υπό την κυριαρχίαν ή το προτεκτοράτον των
        Συμμάχων Δυνάμεων κατά την 29ην Οκτωβρίου 1914, ή επί των αποσπασθέντων
        της Οθωμανικής Αυτοκρατορίας εδαφών συνεπεία των Βαλκανικών πολέμων και
        υπαγομένων σήμερον υπό την κυριαρχίαν των ειρημένων Δυνάμεων, θέλουσι
        αμέσως αποδοθή εις τους δικαιούχους εις ην κατάστασιν ευρίσκονται. Το
        αυτό θέλει εφαρμοσθή ως προς τας περιουσίας, δικαιώματα και συμφέροντα
        τας ανηκούσας εις τούρκους υπηκόους εκ των αποσπασθέντων εκ της
        Οθωμανικής Αυτοκρατορίας εδαφών δυνάμει της παρούσης Συνθήκης, και
        αίτινες τυχόν απετέλεσαν αντικείμενον εκκαθαρίσεως ή οιουδήποτε άλλου
        εξαιρετικού μέτρου εκ μέρους των Αρχών των Συμμάχων Δυνάμεων. <br />
        Αι επί εδάφους αποσπωμένου της Οθωμανικής Αυτοκρατορίας δυνάμει της
        παρούσης Συνθήκης κείμεναι περιουσίαι, δικαιώματα και συμφέροντα
        αίτινες, γενόμενοι αντικείμενον εξαιρετικού τίνος πολεμικού μέτρου εκ
        μέρους της Οθωμανικής Κυβερνήσεως, ευρίσκονται ήδη εις χείρας της
        συμβαλλόμενης Δυνάμεως της ασκούσης εξουσίαν επί του εδάφους τούτου και
        δύνανται να εξακριβωθώσι, θέλουσιν αποδοθή εις τον νόμιμον αυτών κύριον,
        εις ην κατάστασιν ευρίσκονται. Το αυτό θέλει εφαρμοσθή ως προς τα
        ακίνητα, άτινα έχουσι τυχόν εκκα-θαρισθή υπό της συμβαλλομένης Δυνάμεως
        της ασκούσης εξουσίαν επί του εδάφους τούτου. Πάσα άλλη διεκδίκησις
        μεταξύ ιδιωτών θα υποβάλληται εις τα αρμόδια τοπικά δικαστήρια.
        <br />
        Πάσα διαφορά σχετική προς την ταυτότητα ή την απόδοσιν των αιτουμένων
        περιουσιών θα υποβάλληται εις το προβλεπόμενον υπό του Τμήματος Ε’ του
        παρόντος Μέρους Μικτόν Διαιτητικόν Δικαστήριον. <br />
        <br />
        Άρθρον 66. <br />
        Προς εκτέλεσιν των διατάξεων του άρθρου 65, εδάφια 1 και 2, τα Υψηλά
        Συμβαλλόμενα Μέρη θα αποκαταστήσωσι, δια της ταχυτέρας διαδικασίας, τους
        δικαιούχους εις τας περιουσίας, δικαιώματα και συμφέροντα αυτών ,
        ελευθέρας των βαρών ή δουλειών, αίτινες τυχόν επεβλήθησαν επί τούτων
        άνευ της συγκαταθέσεως των ειρημένων δικαιούχων.
        <br /> Η Κυβέρνησις της ενεργούσης την απόδοσιν Δυνάμεως θα μεριμνήση
        περί της αποζημιώσεως των τρίτων, οίτινες τυχόν απέκτησαν αμέσως ή
        εμμέσως δικαιώματα ένταντι της ειρημένης Κυβερνήσεως και ους θα εζημίου
        ή απόδοσις αύτη.
        <br />
        Αι δυνάμεναι να αναφυώσι διαφοραί ως προς την εν λόγω αποζημίωσιν θα
        υπάγονται εις την αρμοδιότητα δικαστηρίων του κοινού δικαίου. <br />
        Εν πάση άλλη περιπτώσει, απόκειται εις τους ζημιουμένους τρίτους να
        στραφώσι κατά του υπαιτίου, όπως αποζημιωθώσιν. <br />
        Επί τω τέλει τούτω, πάσα πράξις διαθέσεως ή άλλα εξαιρετικά μέτρα
        πολέμου, εις ά τα Υψηλά Συμβαλλόμενα Μέρη έχουσι τυχόν προβή ως προς τας
        εχθρικάς περιουσίας, δικαιώματα και συμφέροντα, θα αρθώσι και
        ανασταλώσιν αμέσως, εφ’ όσον πρόκειται περί μήπω περατωθείσης εισέτι
        εκκαθαρίσεως. Οι αιτούντες ιδιοκτήται θέλουσιν ικανοποιηθή δια της
        αμέσου αποδόσεως των περιουσιών, δικαιωμάτων και συμφερόντων αυτών,
        ευθύς ως ταύτα εξακριβωθώσιν. <br />
        Εν η περιπτώσει, κατά την υπογραφήν της παρούσης Συνθήκης, αι
        περιουσίαι, τα δικαιώματα και συμφέροντα, ων η απόδοσις προβλέπεται υπό
        του άρθρου 65, έχουσι τυχόν εκαθαρισθή υπό των Αρχών μιας των Υψηλών
        Συμβαλλομένων Δυνάμεων, η Δύναμις αύτη θα απαλλαγή της προς απόδοσιν των
        ειρημένων περιουσιών, δικαιωμάτων και συμφερόντων υποχρεώσεως, δια της
        πληρωμής εις τον ιδιοκτήτην του προϊόντος της εκκαθαρίσεως. Εν ή
        περιπτώσει το εν τω Τμήματι Ε’ προβλεπόμενον Μικτόν Διαιτητικόν
        Δικαστήριον επί τη αιτήσει του ιδιοκτήτου, ήθελε κρίνει ότι η
        εκκαθάρισις δεν εγένετο υπό συνθήκας εξασφαλιζούσας την πραγματοποίησιν
        δικαίου τιμήματος, δύναται το δικαστήριον, ελλείψει συμφωνίας μεταξύ των
        μερών, να αύξηση το προϊόν της εκκαθαρίσεως καθ’ ό ποσόν ήθελε κρίνει
        δίκαιον, αι ειρημέναι περιουσίαι, δικαιώματα και συμφέροντα θα
        αποδοθώσιν, εάν η πληρωμή δεν συνετελέσθη εντός προθεσμίας δύο μηνών από
        της μετά του ιδιοκτήτου συμφωνίας ή της αποφάσεως του ως άνω Μικτού
        Διαιτητικού Δικαστηρίου. <br />
        <br />
        Άρθρον 67. <br />
        Η Ελλάς, η Ρουμανία, το Σερβο-Κροατο-Σλοβενικόν Κράτος αφ’ ενός, και η
        Τουρκία αφ’ ετέρου, αναλαμβάνουσι την υποχρέωσιν να διευκολύνωσιν
        αμοιβαίως, είτε δια καταλλήλων διοικητικών μέτρων, είτε δια της
        παραδόσεως παντός σχετικού εγγράφου, την επί του εδάφους αυτών ανεύρεσιν
        και απόδοσιν των πάσης φύσεως κινητών, άτινα, αφαιρεθέντα, κατασχθέντα ή
        μεσεγγυηθέντα υπό των στρατευμάτων ή των διοικητικών αυτών αρχών επί του
        εδάφους της Τουρκίας ή αντιστοίχως επί του εδάφους της Ελλάδος, της
        Ρουμανίας και του Σερβο-Κροατο-Σλοβενικού Κράτους, ευρίσκονται ήδη επί
        του εδάφους τούτου. <br />
        Η ανεύρεσις και η απόδοσις θα λαβή ωσταύτως χωράν ως προς τα υπό των
        γερμανικών, αυστρο-ουγγρικών ή βουλγαρικών στρατευμάτων ή διοικητικών
        αρχών κατασχεθέντα ή μεσεγγυηθέντα ως άνω αντικείμενα επί του εδάφους
        της Ελλάδος, της Ρουμανίας ή του Σερβο-Κροατο-Σλοβένικου Κράτους, άτινα
        έχουσι τυχόν παραχωρηθή εις την Τουρκίαν ή εις τους υπηκόους αυτής, ως
        και δια τα κατασχεθέντα ή μεσεγγυηθέντα αντικείμενα υπό των ελληνικών,
        ρουμανικών ή σερβικών στρατευμάτων επί του εδάφους της Τουρκίας άτινα
        τυχόν παρεχωρήθησαν εις την Ελλάδα, την Ρουμανίαν ή το Σερβο-Κροατο-
        Σλοβενικό Κράτος ή εις τους υπηκόους αυτών. <br />
        Αι αφορώσαι την ανεύρεσιν και απόδοσιν ταύτην αιτήσεις θα υποβληθώσιν
        εντός έξι μηνών από της ενάρξεως της ισχύος της παρούσης Συνθήκης.{" "}
        <br />
        <br />
        Άρθρον 68. <br />
        Τα χρέη, τα προκύπτοντα εκ συμβολαίων συναφθέντων εν ταις χώραις,
        αίτινες κατείχοντο εν Τουρκία υπό των Ελληνικών στρατευμάτων, μεταξύ των
        Ελληνικών Αρχών αφ’ ενός, και τούρκων υπηκόων αφ’ ετέρου, θα πληρωθώσιν
        υπό της Ελληνικής Κυβερνήσεως κατά τους εν τοις ειρημένοις συμβολαίοις
        προβλεπόμενους όρους. <br />
        <br />
        Άρθρον 69. <br />
        Ουδείς φόρος, τέλος, ή πρόσθετον δικαίωμα ων απηλλάσσοντο οι Σύμμαχοι
        υπήκοοι και αι περιουσίαι αυτών, δυνάμει του καθεστώτος υφ’ ό διτέλουν
        κατά την 1ην Αυγούστου 1914, θέλει εισπραχθή παρά των υπηκόων τούτων ή
        επί των περιουσιών αυτών, έναντι χρήσεων προγενεστέρων του 1922- 1923.{" "}
        <br />
        Εν ή περιπτώσει εισεπράχθησαν ποσά μετά την 15ην Μαΐου 1923, έναντι των
        προ του 1922-1923 χρήσεων, θέλουσι ταύτα αποδοθή εις τους δικαιούχους
        άμα τη ενάρξει της ισχύος της παρούσης Συνθήκης. <br />
        Ουδεμία απαίτησις δύναται να εγερθή ως προς τα προ της 15ης Μαΐου 1923
        εισπραχθέντα ποσά. <br />
        <br />
        Άρθρον 70. <br />
        Αι επί των άρθρων 65, 66 και 69 βασιζόμεναι αιτήσεις δέον να υποβάλλωντα{" "}
        <br />
        <br />
        ΣΥΓΚΟΙΝΩΝΙΑΙ <br />
        <br />
        Άρθρον 101. <br />
        Η Τουρκία δηλοί ότι προσχωρεί εις την Σύμβασιν και τον Κανονισμόν περί
        ελευθερίας της διαμετακομίσεως τους εγκριθέντας υπό της Συνδιασκέψεως
        της Βαρκελώνης την 14ην Απριλίου 1921, ως και εις την Σύμβασιν και τον
        Κανονισμό των πλωτών οδών διεθνούς συμφέροντος ους απεδέχθη η ειρημένη
        Συνδιάσκεψις την 19η Απριλίου 1921, και εις το πρόσθετον Πρωτόκολλον.{" "}
        <br />
        Κατά συνέπειαν, η Τουρκία υποχρεούται να εφαρμόση τας διατάξεις των εν
        λόγω Συμβάσεων, Κανονισμών και Πρωτοκόλλου άμα τη ενάρξει της ισχύος της
        παρούσης Συνθήκης. <br />
        <br />
        Άρθρον 102. <br />
        Η Τουρκία δηλοί ότι προσχωρεί εις την από 20ης Απριλίου 1921 Δήλωσιν της
        Βαρκελώνης “την αναγνωρίζουσαν το δικαίωμα σημαίας των Κρατών των
        εστερημένων θαλασσίας εξόδου”. <br />
        <br />
        Άρθρον 103. <br />
        Η Τουρκία δηλοί ότι προσχωρεί εις τας από 20ης Απριλίου 1921 Συστάσεις
        της Συνδιασκέψεως της Βαρκελώνης περί των υπό διεθνές καθεστώς
        διατελούντων λιμένων. Η Τουρκία θα γνωστοποίηση βραδύτερον τους εις το
        καθεστώς τούτο υπαχθησομένους λιμένας. <br />
        <br />
        Άρθρον 104. <br />
        Η Τουρκία δηλοί ότι προσχωρεί εις τας Συστάσεις της Συνδιασκέψεως της
        Βαρκελώνης της 20ης Απριλίου 1921 περί διεθνών σιδηροδρομικών γραμμών.{" "}
        <br />
        Αι συστάσεις αύται θέλουσιν εφαρμοσθή υπό της Τουρκικής Κυβερνήσεως άμα
        τη ενάρξει της ισχύος της παρούσης Συνθήκης υπό τον όρον της
        αμοιβαιότητας. <br />
        Άρθρον 105. <br />
        Άμα τη ενάρξει της ισχύος της παρούσης Συνθήκης, η Τουρκία υποχρεούται
        να προσχώρηση εις τας εν Βέρνη υπογραφείσας Συμβάσεις και Συμφωνίας της
        14ης Οκτωβρίου 1890, 20ης Σεπτεμρίου 1893, 16ης Ιουλίου 1895, 16ης
        Ιουνίου 1898 και 19ης Σεπτεμβρίου 1906 περί της δια σιδηροδρόμων
        μεταφοράς εμπορευμάτων. <br />
        <br />
        Άρθρον 106. <br />
        Οσάκις, συνεπεία της χαράξεως των νέων συνόρων, γραμμή τις συνδέουσα δύο
        μέρη της αυτής χώρας, διασχίζει ετέραν χώραν, ή οσάκις διακλάδωσις τις
        αρχομένη εκ μιας χώρας καταλήγει εις ετέραν, οι όροι της εκμεταλλεύσεως,
        όσον αφορά εις το μεταφορικόν εμπόριον μεταξύ των δύο χωρών, θα
        κανονισθώσιν εκτός ειδικών διατάξεων, δια συμφωνίας συνομολογηθησομένης
        μεταξύ των ενδιαφερομένων διευθύνσεων των σιδηροδρόμων.
        <br /> Εν η περιπτώσει αι διευθύνσεις αύται δεν κατώρθουν να
        ομοφωνήσωσιν επί των όρων της συμφωνίας ταύτης, οι όροι ούτοι θέλουσι
        καθορισθή δια διαιτησίας. Η εγκατάστασις πάντων των νέων μεθοριακών
        σταθμών μεταξύ της Τουρκίας και των ομόρων Κρατών, ως και η
        εκμετάλλευσις των μεταξύ των σταθμών τούτων γραμμών, θα κανονισθώσι δια
        συμφωνιών συνομολογουμένων κατά τους αυτούς όρους. <br />
        Άρθρον 107. <br />
        Οι εκ της Τουρκίας ή της Ελλάδος προερχόμενοι ή εις αυτάς κατευθυνόμενοι
        ταξειδιώται και τα εμπορεύματα, κατά την διέλευσιν ή διαμετακόμισιν δια
        των τριών τμημάτων των ανατολικών Σιδηροδρόμων των περιλαμβανομένων
        μεταξύ της ελληνοβουλγαρικής και της ελληνοτουρκικής μεθορίου παρά το
        Κούλελη – Μπουργάζ, δεν θα υποβάλλωνται λόγω της τοιαύτης διελεύσεως ή
        διαμετακομίσεως εις ουδέν τέλος ή δασμόν, ουδ’ εις διατύπωσίν τίνα
        εξελέγξεως διαβατηρίων ή τελωνειακού ελέγχου. <br />
        Η εκτέλεσις των διατάξεων του παρόντος άρθρου θέλει εξασφαλισθή υπό ενός
        επιτρόπου εκλεγησομένου υπό του Συμβουλίου της Κοινωνίας των Εθνών.{" "}
        <br />
        Εκάστη των Κυβερνήσεων Ελλάδος και Τουρκίας θα έχη το δικαίωμα να
        διορίση παρά τω επιτρόπω τούτω ένα αντιπρόσωπον, όστις θα έχη ως έργον
        να εφιστά την προσοχήν του Επιτρόπου επί παντός ζητήματος αφορώντος εις
        την εκτέλεσιν των ειρημένων διατάξεων και θα απολαύη όλων των αναγκαίων
        δια την εκπλήρωσιν του έργου του ευκολιών. Οι αντιπρόσωποι ούτοι θα
        ορίσωσιν εκ συμφώνου μετά του επιτρόπου τον αριθμόν και το είδος του
        κατωτέρου προσωπικού ούτινος θα έχωσιν ανάγκην. <br />
        Ο Επίτροπος θα υποβάλλη υπό την κρίσιν του Συμβουλίου της Κοινωνίας των
        Εθνών παν ζήτημα, αφορών εις την εκτέλεσιν των ειρημένων διατάξεων, όπερ
        δεν ήθελε δυνηθή να λήση. Αι Κυβερνήσεις της Ελλάδος και της Τουρκίας
        υποχρεούνται να συμμορφώνται προς πάσαν απόφασιν εκδιδομένη κατά
        πλειοψηφίαν υπό του ειρημένου Συμβουλίου. <br />
        Ο μισθός ως και τα έξοδα δια την λειτουργίαν της υπηρεσίας του ειρημένου
        Επιτρόπου θέλουσι βαρύνη εξ ημισείας την Ελληνικήν και Τουρκικήν
        Κυβέρνησιν. <br />
        Εις περίπτωσιν καθ’ ην η Τουρκία ήθελε κατασκευάσει μεταγενετέρως
        σιδηροδρομικήν γραμμήν συνδέουσαν την Αδριανούπολιν μετά της γραμμής
        Κούλελη Μπουργάζ – Κωνσταντινουπόλεως, αι διατάξεις του παρόντος άρθρου
        θέλουσιν ατονίσει ως προς την διαμετακόσισιν μεταξύ των σημείων της
        ελληνοτουρκικής μεθορίου των κειμένων πλησίον του Κούλελη -Μπουργάζ και
        του Βόσνα – κιοϊ ή αντιθέτως. Εκάστη των δύο ενδιαφερομένων Κυβερνήσεων
        θα έχη το δικαίωμα, μετά πέντε έτη από της ενάρξεως της ισχύος της
        παρούσης Συνθήκης, να απευθυνθή εις το Συμβούλιον της Κοινωνίας των
        Εθνών όπως τούτο αποφασίση, εάν υπάρχη λόγος διατηρήσεως του
        προβλεπομένου εν τοις εδαφίοις 2-5 του παρόντος άρθρου ελέγχου.
        Εννοείται όμως ότι αι διατάξεις του πρώτου εδαφίου θα παραμείνωσιν εν
        ισχύι καθ’ όσον αφορά την διαμετακόμισιν επί των δύο τμημάτων των
        ανατολικών Σιδηροδρόμων μεταξύ της ελληνοβουλγαρικής μεθορίου και του
        Βόσνα – κιοϊ. <br />
        <br />
        Άρθρον 108. <br />
        Εκτός ιδιαιτέρων συμφωνιών ως προς τας μεταβιβάσεις των λιμένων και των
        σιδηροδρόμων των ανηκόντων είτε εις την Τουρκικήν Κυβέρνησιν, είτε εις
        ιδιωτικάς εταιρείας και περιλαμβανομένων εντός των αποσπωμένων εκ της
        Τουρκίας εδαφών δυνάμει της παρούσης Συνθήκης, υπό την επιφύλαξιν δε των
        μεταξύ των συμβαλλομένων Δυνάμεων συνομολογηθεισών ή συνομολογηθησομένων
        διατάξεων αναφορικώς προς τους αναδόχους και την υπηρεσίαν των συντάξεων
        του προσωπικού, ή μεταβίβασις των σιδηροδρομικών γραμμών γενήοεται υπό
        τους ακολούθους όρους: <br />
        1) Τα έργα και αι εγκαταστάσεις πασών των σιδηροδρομικών γραμμών θα
        παραδοθώσιν εις ακέραιον και εν όσω το δυνατόν καλλίτερα καταστάσει. 2)
        Εάν δίκτυόν τι έχον ίδιον τροχαίον υλικόν, περιλαμβάνηται ολόκληρον
        εντός μεταβιβαζομένου εδάφους, το υλικόν τούτο θα παραδοθή εις ακέραιον,
        συμφώνως προς την τελευταίαν απογραφή κατά την 30η Οκτωβρίου 1918. *{" "}
        <br />
        3) Ως προς τας γραμμάς των οποίων η διεύθυνσις, δυνάμει της παρούσης
        Συνθήκης, θα ευρεθή κατατετμημένη, η κατανομή του τροχαίου υλικού θα
        ορισθή δια συμφωνίας μεταξύ των διευθύνσεων υφ’ ας θα υπάγωνται τα
        διάφορα τμήματα. <br />
        Κατά την συμφωνίαν ταύτην δέον να ληφθώσιν υπ’ όψιν η ποσότης του επί
        των γραμμών τούτων καταγεγραμμένου υλικού, συμφώνως προς την τελευταίαν
        απογραφή κατά την 30ην Οκτωβρίου 1918, το μήκος των γραμμών,
        συμπεριλαμβανομένων των υπηρεσιακών γραμμών, η φύσις και η σπουδαιότης
        της εμπορικής κινήσεως. Εν περιπτώσει διαφωνίας, η διαφορά θα λυθή δια
        διαιτησίας. Η διαιτητική απόφασις θα ορίση ωσαύτως, εν ενδεχομένη
        περιπτώσει, τας αφεθησομένας εις έκαστον τμήμα ατμομηχανάς,
        σιδηροδρομικά οχήματα και αμάξας, θα προσδιορίση τους όρους της
        παραλαβής αυτών και θα προβή εις οίας ήθελε κρίνει αναγκαίας
        διευθετήσεις προς εξασφάλισιν επί ωρισμένην χρονικήν περίοδον της
        συντηρήσεως του μεταβιβαζομένου υλικού εν τοις υφισταμένοις συνεργείοις.{" "}
        <br />
        4) Αι προμήθειαι, τα έπιπλα και τα εργαλεία θα παραδοθώσιν υφ’ ους όρους
        και το τροχαίον υλικόν. <br />
        <br />
        Άρθρον 109. <br />
        Μη υφισταμένων αντιθέτων διατάξεων, εάν, συνεπεία της χαράξεως νέας
        τινός μεθορίου, το σύστημα των υδάτων (διοχετεύσεις, πλημμύραι,
        αρδεύσεις, αποξηράνσεις ή άλλα παρόμοια ζητήματα) εν τινι Κράτει,
        εξαρτάται εξ έργων εκτελεσθέντων εν τω εδάφει ετέρου τινός Κράτους, ή
        εάν, δυνάμει προγενεστέρων του πολέμου συνηθειών, γίνηται χρήσις επί του
        εδάφους ενός Κράτους υδάτων ή υδραυλικής δυνάμεως παραγομένων επί του
        εδάφους ετέρου τινός Κράτους τα ενδιαφερόμενα Κράτη δέον να προέλθωσιν
        εις συνεννόησιν προς προστασίαν των αμοιβαίων συμφερόντων και κεκτημένων
        δικαιωμάτων. <br />
        Ελλείψει συμφωνίας, το θέμα θέλει κανονισθή δια διαιτησίας. <br />
        <br />
        Άρθρον 110. <br />
        Η Ρουμανία και η Τουρκία θα συνεννοηθώσιν όπως καθορίσωσι ακριβοδικαίως
        τους όρους εκμεταλλεύσεως του καλωδίου Κωστάντζης- Κωνσταντινουπόλεως.
        Ελλείψει συνεννοήσεως, το ζήτημα θα κανονισθή δια διαιτησίας. <br />
        <br />
        Άρθρον 111. <br />
        Η Τουρκία παραιτείται, δι’ εαυτήν και εν ονόματι των υπηκόων της, πάντων
        των δικαιωμάτων, τίτλων ή προνομίων πάσης φύσεως, επί του όλου ή επί
        μέρους των καλωδίων άτινα δεν διέρχονται πλέον δια του εδάφους της.{" "}
        <br />
        Εάν τα συμφώνως προς το προηγούμενον εδάφιον μεταβιβαζόμενα καλώδια ή
        τμήματα καλωδίων αποτελώσιν ιδιοκτησίαν ιδιωτικήν, αι Κυβερνήσεις, εις
        ας μεταβιβάζεται η κυριότης, θέλουσιν αποζημιώσει τους ιδιοκτήτας.{" "}
        <br />
        Εν περιπτώσει διαφωνίας περί του ποσού της αποζημιώσεως, αύτη θέλει
        καθορισθή δια διαιτησίας. <br />
        <br />
        Άρθρον 112. <br />
        Η Τουρκία θα διατήρηση τα τυχόν ανήκοντα ήδη αυτή δικαιώματα κυριότητος
        επί των καλωδίων άτινα διέρχονται δι’ ενός τουλάχιστον σημείου του
        τουρκικού εδάφους. <br />
        Η ενάσκησις των δικαιωμάτων προσγειώσεως των ειρημένων καλωδίων επί
        εδάφους μη τουρκικού και οι όροι της εκμεταλλεύσεως τούτων θα
        κανονισθώσιν φιλικώς μεταξύ των ενδιαφερομένων Κρατών. Εν διαφωνία, η
        διαφορά θέλει λυθή δια διαιτησίας. <br />
        <br />
        Άρθρον 113. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη δηλουσιν ότι αποδέχονται, έκαστον δι’ εαυτό
        την κατάργησιν των ξένων ταχυδρομείων εν Τουρκία. <br />
        <br />
        ΤΜΗΜΑ Β’ <br />
        <br />
        ΥΓΕΙΟΝΟΜΙΚΑ ΖΗΤΗΜΑΤΑ <br />
        <br />
        Άρθρον 114. <br />
        Το Ανώτερον Υγειονομικόν Συμβούλιον της Κωνσταντινοπόλεως καταργείται. Η
        Τουρκική Διοίκησις αναλαμβάνει την υγειονομικήν οργάνωσιν των παραλίων
        και μεθορίων της Τουρκίας. <br />
        <br />
        Άρθρον 115. <br />
        Μία και η αυτή υγειονομική διατίμησις, ης τα τέλη και οι όροι θα ώσιν
        δίκαιοι, θέλει εφαρμοσθή εις πάντα τα πλοία, άνευ διακρίσεως μεταξύ της
        τουρκικής σημαίας και των ξένων, και εις τους υπηκόους των ξένων
        Δυνάμεων υπό τους αυτούς προς τους υπηκόους της Τουρκίας όρους. <br />
        <br />
        Άρθρον 116. <br />
        Η Τουρκία υποχρεούται να σεβασθή εξ ολοκλήρου το δικαίωμα των
        απολυθέντων υγειονομικών υπαλλήλων όπως αποζημιωθώσιν εκ των κεφαλαίων
        του πρώην Ανωτέρου Υγειονομικού Συμβουλίου της Κωνσταντινουπόλεως, ως
        και πάντα τα άλλα κεκτημένα δικαιώματα των υπαλλήλων και πρώην υπαλλήλων
        του Συμβουλίου τούτου και των δικαιοδόχων αυτών. Πάντα τα ζητήματα τα
        σχετιζόμενα προς τα δικαιώματα ταύτα, προς την διάθεσιν των αποθεματικών
        κεφαλαίων του πρώην Ανωτέρου Υγειονομικού Συμβουλίου της
        Κωνσταντινοπόλεως, την οριστικήν εκκαθάρισιν της παλαιάς υγειονομικής
        διευθύνσεως, ως και παν άλλο παρόμοιον ή συναφές ζήτημα, θα κανονισθώσιν
        υπό Επιτροπής ad hoc, αποτελούμενης εξ ενός αντιπροσώπου εκάστης των
        μετασχουσών του Ανωτέρου Υγειονομικού Συμβουλίου της Κωνσταντινουπόλως
        Δυνάμεων, εξαιρέσει της Γερμανίας, Αυστρίας και Ουγγαρίας. Εν περιπτώσει
        διαφωνίας μεταξύ των μελών της Επιτροπής ταύτης επί ζητήματος αφορώντος
        είτε εις την αναφερομένην ανωτέρω εκκαθάρισιν, είτε εις την διάθεσιν του
        υπολοίπου κεφαλαίου μετά την εκκαθάρισιν ταύτην, πάσα Δύναμις
        εκπροσωπούμενη εν τη Επιτροπή θα έχη το δικαίωμα να φέρη το ζήτημα τούτο
        εις το Συμβούλισν της Κοινωνίας των Εθνών, όπερ θα αποφασίζη επ’ αυτού
        ανεκκλήτως. <br />
        <br />
        Άρθρον 117. <br />
        Η Τουρκία και αι Δυνάμεις αι ενδιαφερόμεναι δια την επιτήρησιν των
        προσκυνημάτων της Ιερουσαλήμ και της Χετζάζης και του Σιδηροδρόμου της
        Χετζάζης θα λάβωσι τα προς τούτο κατάλληλα μέτρα, συμφώνως προς τας
        διατάξεις των διεθνών υγειονομικών Συμβάσεων. Όπως δε επιτευχθή πλήρης
        ενότης της εκτελέσεως αυτών, αι Δυνάμεις αύται και η Τουρκία θα
        συστήσωσιν Επιτροπήν υγειονομικού εναρμονισμού των προσκυνημάτων, εν τη
        οποία θα αντιπροσωπεύονται αι υγειονομικαί υπηρεσίαι της Τουρκίας και το
        θαλάσσιον υγειονομικόν και επί των καθάρσεων Συμβούλιον της Αιγύπτου. Η
        Επιτροπή αύτη δέον να τύχη της προηγουμένης συγκαταθέσεως του Κράτους
        επί του εδάφους του οποίου θέλει συνέλθει. <br />
        <br />
        Άρθρον 118. <br />
        Εκθέσεις περί των έργων της Επιτροπής εναρμονισμού των προσκυνημάτων θα
        διαβιβάζωνται εις την Επιτροπήν Υγιεινής της Κοινωνίας των Εθνών και εις
        το Διεθνές Γραφείον δημοσίας υγιεινής, ως και εις την Κυβέρνησιν πάσης
        χώρας ενδιαφερομένης δια τα προσκυνήματα, επί τη αιτήσει ταύτης. Η
        Επιτροπή θα γνωμοδοτή επί παντός ζητήματος τεθησομένου αυτή υπό της
        Κοινωνίας των Εθνών, δια του Διεθνούς Γραφείου δημοσίας υγιεινής ή των
        ενδιαφερομένων Κυβερνήσεων. <br />
        <br />
        ΑΙΧΜΑΛΩΤΟΙ ΠΟΛΕΜΟΥ <br />
        <br />
        Άρθρον 119. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη υποχρεούνται να επαναφέρωσιν αμέσως εις τας
        πατρίδας των τους τυχόν παραμένοντας εν τη χώρα αυτών αιχμαλώτους
        πολέμου και πολιτικούς κρατουμένους. <br />
        Η ανταλλαγή των κατακρατουμένων υπό της Ελλάδος και Τουρκίας αιχμαλώτων
        πολέμου και πολιτών αποτελεί αντικείμενον της ιδιατέρας Συμφωνίας μεταξύ
        των Δυνάμεων τούτων της υπογραφείσης εν Λωζάνη την 30ην Ιανουαρίου 1923.{" "}
        <br />
        <br />
        Άρθρον 120. <br />
        Οι αιχμάλωτοι πολέμου και πολιτικοί κρατούμενοι, οι υποκείμενοι εις
        τιμωρίαν ή τιμωρηθέντες δια παραπτώματα αντιπειθαρχικά, θα παλινοστήσωσι
        χωρίς να ληφθή υπ’ όψιν η λήξις της ποινής αυτών ή της αρξαμένης κατ’
        αυτών διαδικασίας. <br />
        Οι υποκείμενοι ή καταδικασθέντες εις ποινάς δι’ άλλος, πλην των
        πειθαρχικών παραβάσεων, πράξεις δύνανται να εξακολουθήσωσι κρατούμενοι.{" "}
        <br />
        Άρθρον 121. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη υποχρεούνται να παρέχωσιν εν ταις οικείαις
        αυτών χώραις πάσαν ευκολίαν δια την αναζήτησιν των εξαφανισθέντων ή την
        εξακρίβωσιν της ταυτότητος των αιχμαλώτων πολέμου και πολιτικών
        κρατουμένων, οίτινες εξεδήλωσαν την επιθυμίαν όπως μη παλινοστήσωσιν.{" "}
        <br />
        <br />
        Άρθρον 122. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη υποχρεούνται να αποδώσωσιν, άμα τη ενάρξει
        της ισχύος της παρούσης Συνθήκης, πάντα τα τυχόν παρακρατηθέντα
        αντικείμενα, νομίσματα, αξίας, έγγραφα ή προσωπικά πράγματα πάσης φύσεως
        άτινα ανήκουσιν ή ανήκον εις τους αιχμαλώτους πολέμου και πολιτικούς
        κρατουμένους. <br />
        <br />
        Άρθρον 123. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη δηλούσιν ότι παραιτούνται της αμοιβαίας
        πληρωμής των οφειλομένων ποσών δια την συντήρησιν των αιχμαλώτων
        πολέμου, οίτινες συνελήφθησαν υπό των στρατευμάτων αυτών. <br />
        <br />
        2.ΤΑΦΟΙ <br />
        <br />
        Άρθρον 124. <br />
        Μη θιγομένων των ιδιαιτέρων διατάξεων των διατυπουμένων εν τω κατωτέρω
        126ω άρθρω, τα Υψηλά Συμβαλλόμενα Μέρη θα μεριμνώσι περί της προφυλάξεως
        και συντηρήσεως των εν ταις χώραις αυτών νεκροταφείων, τάφων,
        κοιμητηρίων και αναμνηστικών μνημείων των στρατιωτών και ναυτών εκάστου
        εξ αυτών, των πεσόντων επί του πεδίου της μάχης ή αποβιωσάντων συνεπεία
        των τραυμάτων των, δυστυχημάτων ή ασθενειών μετά την 29ην Οκτωβρίου
        1914, ως και των θανόντων εν αιχμαλωσία αιχμαλώτων πολέμου και πολιτικών
        κρατουμένων μετά την αυτήν χρονολογίαν. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη θα προέλθωσιν εις συνεννόησιν όπως παράσχωσι
        πάσαν ευκολίαν προς εκπλήρωσιν της αποστολής των, εν τοις οικείοις αυτών
        εδάφεσιν, εις τας επιτροπάς, εις ας έκαστον εξ αυτών δύναται να ανάθεση
        την εξακρίβωσιν, καταχώρισιν, συντήρησιν των ειρημένων νεκροταφείων,
        κοιμητηρίων και τάφων και την ανέγερσιν επί των τόπων αυτών καταλλήλων
        μνημείων. Αι επιτροπαί αύται δέον να μη έχωσιν ουδένα στρατιωτικόν
        χαρακτήρα. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη συμφωνούσιν όπως παρέχωσιν αμοιβαίως, υπό την
        επιφύλαξιν των διατάξεων της ιδιαιτέρας αυτών νομοθεσίας και των
        απαιτήσεων της δημοσίας υγιεινής, πάσαν ευκολίαν προς εκτέλεσιν των
        αιτήσεων περί μεταφοράς εις τας πατρίδας των των λειψάνων των εν τω
        ανωτέρω άρθρω αναφερομένων στρατιωτών και ναυτών αυτών. <br />
        <br />
        Άρθρον 125. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη υποχρεούνται να χορηγήσωσιν αλλήλοις: <br />
        1) Πλήρη κατάλογον των αιχμαλώτων πολέμου κρατουμένων, οίτινες απεβίωσαν
        εν αιχμαλωσία, μεθ’ όλων των χρησίμων προς εξακρίβωσιν της ταυτότητός
        των πληροφοριών. <br />
        2) Πάσαν πληροφορίαν περί του αριθμού και του τόπου των τάφων των
        ενταφιασθέντων άνω προηγουμένης εξακριβώσεως της ταυτότητός των. Άρθρον
        126. <br />
        Η συντήρησις των τάφων, νεκροταφείων, κοιμητηρίων και αναμνηστικών
        μνημείων των τούρκων στρατιωτών, ναυτών και αιχμαλώτων πολέμου, οίτινες
        απεβίωσαν εν τω Ρουμανικώ εδάφει μετά την 27ην Αυγούστου 1916, ως και
        πάσα άλλη υποχρέωσις προκύπτουσα εκ των άρθρων 124 και 125 ως προς τους
        πολιτικούς κρατουμένους, θα αποτελέσωσιν αντικείμενον ιδιαιτέρας
        συμφωνίας μεταξύ της Ρουμανικής και της Τουρκικής Κυβερνήσεως. <br />
        <br />
        Άρθρον 127. <br />
        Προς συμπλήρωσιν των γενικής φύσεως διατάξεων των άρθρων 124 και 125, αι
        Κυβερνήσεις της Βρεττανικής Αυτοκρατορίας, της Γαλλίας και της Ιταλίας,
        αφ’ ενός, και η Τουρκική και Ελληνική Κυβέρνησις, αφ’ ετέρου,
        συνομολογούσι τας ειδικάς διατάξεις τας περιεχόμενας εν τοις άρθροις
        128-136. <br />
        <br />
        Άρθρον 128. <br />
        Η Τουρκική Κυβέρνησις υποχρεούται, έναντι των Κυβερνήσεων της
        Βρεττανικής Αυτοκρατορίας, της Γαλλίας και της Ιταλίας, να παραχωρήση
        αυταίς κεχωρισμένως και διηνικώς, εν τω εδάφει αυτής, τους χώρους ένθα
        ευρίσκονται τάφοι, νεκροταφεία, κοιμητήρια και αναμνηστικά μνημεία των
        οικείων αυτών στρατιωτών και ναυτών των πεσόντων επί του πεδίου της
        μάχης ή αποβιωσάντων συνεπεία των τραυμάτων των, δυστυχημάτων ή
        ασθενειών, ως και των εν αιχμαλωσία αποβιωσάντων αιχμαλώτων πολέμου και
        πολιτικών κρατουμένων αυτών, θα παραχώρηση αυταίς ωσαύτως τους χώρους,
        οίτινες κριθήσονται αναγκαίοι εις το μέλλον προς ίδρυσιν συγκεντρωμένων
        νεκροταφείων, κοιμητηρίων ή αναμνηστικών μνημείων υπό των εν τω άρθρω
        130 προβλεπομένων επιτροπών. <br />
        Εκτός τούτου, η Τουρκική Κυβέρνησις υποχρεούται να εξασφάλιση την
        ελεύθερον είσοδον εις τους τάφους τούτους, τα νεκροταφεία, κοιμητήρια
        και μνημεία και να επιτρέψη, εν ενδεχομένη περιστάσει, την κατασκευήν
        των αναγκαίων οδών. <br />
        Η Ελληνική Κυβέρνησις αναλαμβάνει τας αυτός υποχρεώσεις ως προς το
        έδαφος της. Αι ανωτέρω διατάξεις δεν θίγουσι την τουρκικήν ή, κατά τας
        περισπάσεις, την Ελληνικήν κυριαρχίαν επί των παραχωρηθέντων εδαφών.{" "}
        <br />
        <br />
        Άρθρον 129. <br />
        Μεταξύ των παραχωρηθησομένων υπό της Τουρκικής Κυβερνήσεως γηπέδων, θα
        συμπεριληφθώσιν, ιδία δια την Βρετανικήν Αυτοκρατορίαν, τα της
        περιφερείας της επονομαζόμενης Anzac (An Biimu), τα σημειούμενα εν τω
        υπ’ αριθ. 3 χάρτη. <br />
        Η υπό της Βρεττανικής Αυτοκρατορίας χρησιμοποίησις του προαναφερομένου
        χώρου θα υπόκειται εις τους ακολούθους όρους: <br />
        1)0 χώρος ούτος δεν δύναται να διατεθή δι’ άλλον ή τον εν τη παρούση
        Συνθήκη οριζόμενον σκοπόν. Κατά συνέπειαν, δεν οφείλει να χρησιμοποιηθή
        προς στρατιωτικόν ή εμπορικόν τίνα σκοπόν ή προς οιονδήποτε άλλον
        διάφορον του ως άνω αναφερομένου προορισμού. <br />
        2) Η Τουρκική Κυβέρνησις θα έχη, εν παντί καιρώ, το δικαίωμα να επιθεωρή
        τον χώρον τούτον, περιλαμβανομένων και των νεκροταφείων. <br />
        3)0 αριθμός των διατιθεμένων δια την φύλαξιν των νεκροταφείων πολιτικών
        φυλάκων δεν δύναται να υπερβή τον ένα, κατά νεκροταφείον, φύλακα. Δεν θα
        υπάρχωσιν ειδικοί φύλακες δια τον εκτός των νεκροταφείων κείμενον χώρον.{" "}
        <br />
        4) Δεν επιτρέπεται η ανέγερσις εν τω ειρημένω χώρω, είτε εντός, είτε
        έξωθεν των νεκροταφείων, ή μόνον των απολύτως αναγκαίων δια τους φύλακας
        οικημάτων. <br />
        5) Δεν επιτρέπεται η κατασκευή επί της ακτής του ειρημένου χώρου
        προκυμαίας, προβλήτος ή κινητής αποβάθρας δυναμένης να διευκολύνη την
        αποβίβασιν ή επιβίβασιν προσώπων ή εμπορευμάτων.
        <br />
        6) Πάσαι αι αναγκαίαι διατυπώσεις οφείλουσι να εκπληρώνται εν τη
        εσωτερική πλευρά των Στενών, η δε δια της ακτής του Αιγαίου είσοδος εις
        τον χώρον δεν θα επιτρέπηται ή μετά την εκπλήρωσιν των ειρημένων
        διατυπώσεων. Η Τουρκική Κυβέρνησις δέχεται όπως οι διατυπώσεις αύται,
        αίτινες δέον να είναι όσον ένεστιν απλοί, μη ώσιν επαχθέστεροι, υπό την
        επιφύλαξιν εν τούτοις των λοιπών διατάξεων του παρόντος άρθρου, των
        επιβαλλομένων εις τους εν Τουρκία μεταβαίνοντας ξένους και εκπληρώνται
        υπό συνθήκας τοιαύτας, ώστε να αποφεύγηται πάσα άσκοπος επιβράδυνσις. 7)
        Τα επιθυμούντα όπως επισκεφθώσι τον χώρον πρόσωπα δέον να μη ώσιν
        ωπλισμένα, η δε Τουρκική Κυβέρνησις έχει το δικαίωμα να επιτηρή την
        εφαρμογήν της απολύτου ταύτης απαγορεύσεως. <br />
        8) Η Τουρκική Κυβέρνησις δέον να ειδοποιήται, προ μιας τουλάχιστον
        εβδομάδος, περί της αφίξεως πάσης ομάδος επισκεπτών υπερβαινούσης τα 150
        πρόσωπα. <br />
        <br />
        Άρθρον 130. <br />
        Εκάστη των Κυβερνήσεων της Μεγάλης Βρεττανίας, της Γαλλίας και της
        Ιταλίας θα κατάρτιση Επιτροπήν, παρά τη οποία αι Κυβερνήσεις της
        Τουρκίας και της Ελλάδος θα διορίσωσιν αντιπρόσωπον και ήτις θέλει
        αναλάβει να κανονίση επί τόπου τα αφορώντα τους τάφους, τα νεκροταφεία,
        κοιμητήρια και αναμνηστικά μνημεία ζητήματα. Αι Επιτροπαί αύται θ’
        αναλάβωσιν ιδία:
        <br />
        1) να αναγνωρίσωσι τα μέρη ένθα εγένοντο ή είναι πιθανόν να εγένοντο
        ενταφιασμοί και εξακριβώσωσι τους υπάρχοντας τάφους, νεκροταφεία
        κοιμητήρια και μνημεία· <br />
        2) να ορίσωσι τους όρους υφ’ ους θα γίνωσιν, εάν συντρέχη λόγος, αι
        συγκεντρώσεις των τάφων, να υποδείξωσιν εν μεν τω τουρκικώ εδάφει, εκ
        συμφώνου μετά του τούρκου αντιπροσώπου, εν δε τω ελληνικώ, μετά του
        έλληνος αντιπροσώπου, τους τόπους των ανεγερθησομένων συγκεντρωμένων
        νεκροταφείων, κοιμητηρίων και αναμνηστικών μνημείων και να καθορίσωσι τα
        όρια των τόπων τούτων, περιορίζουσαι την καταλαμβανόμενην επιφάνειαν εις
        το απαραιτήτως ελάχιστον όριον· <br />
        3) ν’ ανακοινώσωσιν εις την Τουρκικήν και Ελληνικήν Κυβέρνησιν, εν
        ονόματι των οικείων αυτών Κυβερνήσεων, το οριστικόν σχέδιον των
        ανεγερθέντων ή ανεγερθησομένων δια τους ομοεθνείς αυτών τάφων,
        νεκροταφείων, κοιμητηρίων και μνημείων. <br />
        <br />
        Άρθρον 131. <br />
        Αι υπέρ ων η παραχώρησις Κυβερνήσεις αναλαμβάνουσι την υποχρέωσιν να μη
        χρησιμοποιήσωσι τους παραχωρουμένους χώρους ουδέ να επιτρέψωσι την
        χρησιμοποίησίν των προς σκοπόν διάφορον του ανωτέρω προβλεπομένου.{" "}
        <br />
        Εάν οι χώροι ούτοι κείνται παρά την θάλασσαν, η ακτή δεν δύναται να
        χρησιμοποιηθή παρά των ειρημένων Κυβερνήσεων προς στρατιωτικόν, ναυτικόν
        ή εμπορικόν σκοπόν οιονδήποτε. Τα γήπεδα των τάφων και νεκροταφείων,
        άτινα ήθελον απωλέσει το αρχικόν των προορισμόν και δεν ήθελον
        χρησιμοποιηθή προς ενέγερσιν αναμνηστικών μνημείων, θα επιστραφώσιν εις
        την Τουρκικήν ή εις την Ελληνικήν Κυβέρνησιν, αναλόγως της περιπτώσεως.{" "}
        <br />
        Άρθρον 132. <br />
        Τα αναγκαία νομοθετικά ή διοικητικά μέτρα προς παραχώρησιν εις την
        Βρεττανικήν, την Γαλλικήν και την Ιταλικήν Κυβέρνησιν της εις το
        διηνεκές πλήρους νομής των εν τοις άρθροις 128-130 χώρων, δέον να
        ληφθώσι παρά της Τουρκικής ή Ελληνικής Κυβερνήσεως εντός εξ μηνών από
        της προβλεπομένης εν τω άρθρω 130, παράγραφος 3, κοινοποιήσεως. Εάν
        παραστή ανάγκη απαλλοτριώσεων, αύται θα γίνωσι μερίμνη και δαπάναις της
        Τουρκικής ή Ελληνικής Κυβερνήσεως επί των οικείων αυτών εδαφών. <br />
        <br />
        Άρθρον 133. <br />
        Η Βρεττανική, Γαλλική και Ιταλική Κυβέρνησις είναι ελεύθεραι να
        αναθέσωσιν, εις οία κρίνουσι κατάλληλα εκτελεστικά όργανα, την
        εγκατάστασιν, διαρρύθμισιν και συντήρησιν των τάφων, νεκροταφείων,
        κοιμητηρίων και μνημείων των υπηκόων αυτών. Τα όργανα ταύτα δέον να μη
        έχωσι χαρακτήρα στρατιωτικόν. Θα έχωσι μόνα το δικαίωμα να προβαίνωσιν
        εις πάσαν εκταφήν ή μετακομιδήν οστών, κρινομένην αναγκαίαν προς
        συγκέντρωσιν των τάφων και ίδρυσιν των νεκροταφείων και κοιμητηρίων, ως
        και εις εκταφήν ή μετακομιδήν των λειψάνων, ων την μεταφοράν εις την
        πατρίδα των ήθελον κρίνει αναγκαίαν αι υπέρ ων η παραχώρησις
        Κυβερνήσεις. <br />
        <br />
        Άρθρον 134. <br />
        Η Βρεττανική, Γαλλική και Ιταλική Κυβέρνησις έχουσι το δικαίωμα να
        εςασφαλίσωσι την φύλαξιν των εν Τουρκία κειμένων τάφων αυτών,
        νεκτροταφείων, κοιμητηρίων και αναμνηστικών μνημείων δια φρουρών
        διοριζομένων μεταξύ των υπηκόων των. Οι φρουροί ούτοι δέον να
        αναγνωρίζωνται παρά των τουρκικών Αρχών και τυγχάνωσι της συνδρομής
        αυτών προς εξασφάλισιν της προστασίας των τάφων, νεκροταφείων,
        κοιμητηρίων και μνημείων. Δεν θα έχωσι στρατιωτικόν χαρακτήρα, θα
        δύνανται όμως να ώσιν ωπλισμένοι δι’ ενός περιστρόφου ή πιστολιού
        αυτομάτου προς ατομικήν αυτών υπεράσπιση”. <br />
        <br />
        Άρθρον 135. <br />
        Οι αναφερόμενοι εν τοις άρθροις 128-131 χώροι δεν θέλουσιν υποβληθή υπό
        της Τουρκίας ή των τουρκικών Αρχών, ή κατά την περίπτωσιν, υπό της
        Ελλάδος ή των ελληνικών Αρχών, εις ουδέν μίσθωμα, τέλος ή φόρον. <br />
        Η εις αυτούς είσοδος θα είναι εν παντί χρόνω ελευθέρα εις τους
        αντιπροσώπους της Βρεττανικής, Γαλλικής και Ιταλικής Κυβερνήσεως, ως και
        εις τους επιθυμούντας να επισκεφθώσι τους τάφους, τα νεκροταφεία,
        κοιμητήρια και αναμνηστικά μνημεία. Η Τουρκική και η Ελληνική Κυβέρνησις
        αναλαμβάνουσιν εκατέρα εις το διηνεκές την συντήρησιν των αγουσών προς
        τους ειρημένους χώρους οδών. <br />
        Η Τουρκική και η Ελληνική Κυβέρνησις υποχρεούνται εκατέρα να παρέχωσι
        προς την Βρεττανικήν, Γαλλικήν και Ιταλικήν Κυβέρνησιν πάσαν ευκολίαν
        προς προμήθειαν της απαραιτήτου ποσότητος ύδατος δια τας ανάγκας του
        προσωπικού του χρησιμοποιουμένου δια την συντήρησιν ή την φύλαξιν των εν
        λόγω νεκροταφείων, τάφων, κοιμητηρίων, μνημείων και δια την άρδευσιν του
        εδάφους. <br />
        <br />
        Άρθρον 136. <br />
        Η Βρεττανική, Γαλλική και Ιταλική Κυβέρνησις υποχρεούνται να παρέχωσιν
        εις την Τουρκικήν Κυβέρνησιν τα εκ των διατάξεων των άρθρων 128 και 130-
        135 απορρέοντα ωφελήματα ως προς την ανέγερσιν των τάφων, νεκροταφείων,
        κοιμητηρίων και αναμνηστικών μνημείων των τούρκων στρατιωτών και ναυτών
        των ενταφιασμένων εις τα υπό την εξουσίαν αυτών τελούντα εδάφη, εν οις
        και τα εκ της Τουρκίας αποσπώμενα. <br />
        <br />
        3. ΓΕΝΙΚΑΙ ΔΙΑΤΑΞΕΙΣ <br />
        <br />
        Άρθρον 137. <br />
        Εκτός αντιθέτων συμφωνιών μεταξύ των Υψηλών Συμβαλλομένων Μερών, αι υπό
        των Αρχών των Δυνάμεων, αίτινες κατέλαβαν την Κωνσταντινούπολιν, ή εκ
        συμφώνου μετά των Αρχών τούτων ληφθείσαι αποφάσεις ή εκδοθείσαι
        διαταγαί, από της 30ης Οκτωβρίου 1918 μέχρι της ενάρξεως της ισχύος της
        παρούσης Συνθήκης, αι αφορώσαι εις τας περιουσίας, δικαιώματα και
        συμφέροντα των υπηκόων αυτών, των αλλοδαπών ή των τούρκων υπηκόων και
        εις τας μετά των Αρχών της Τουρκίας σχέσεις των μεν ή των δε, θέλουσι
        θεωρηθή οριστικαί και δεν δύνανται να δώσωσιν αφορμήν εις απαίτησίν τίνα
        κατά των εν λόγω Δυνάμεων ή των Αρχών αυτών. <br />
        Πάσα άλλη απαίτησις δια ζημίαν προσγενομένην συνεπεία των ανωτέρω
        αναφερομένων αποφάσεων ή διαταγών θέλει υποβληθεί εις το Μικτόν
        Διαιτητικόν Δικαστήριον. <br />
        <br />
        Άρθρον 138. <br />
        Μη θιγομένων των διατάξεων των παραγράφων 4 και 6 της υπό σημερινήν
        ημερομηνίαν Δηλώσεως περί αμνηστείας, θεωρηθήσονται ως οριστικαί αι επί
        δικαστικών υποθέσεων εκδοθείσαι εν Τουρκία από της 30ης Οκτωβρίου 1918
        μέχρι της ενάρξεως της ισχύος της παρούσης Συνθήκης, αποφάσεις και
        διαταγαί των δικαστών, δικαστηρίων ή Αρχών των καταλαβουσών την
        Κωνσταντιούπολιν Δυνάμεων και της συσταθείσης την 8ην Δεκεμβρίου 1921
        προσωρινής Μικτής Δικαστικής Επιτροπής, ως και τα μετρά εκτελέσεως
        αυτών. <br />
        Εν ή, εν τούτοις, περιπτώσει ήθελεν υποβληθή υπό ιδιώτου απαίτησις περί
        επανορθώσεως ζημίας προσγενομένης αυτώ επ’ ωφελεία ετέρου ιδιώτου,
        συνεπεία δικαστικής αποφάσεως εκδοθείσης εν πολιτική υποθέσει υπό
        στρατιωτικού ή αστυνομικού δικαστηρίου η απαίτησις αύτη θέλει υποβληθή
        εις την κρίσιν του Μικτού Διαιτητικού Δικαστηρίου, το οποίον θα δύναται,
        εάν συντρέχη λόγος, να επιβάλη την πληρωμήν αποζημιώσεως ή και να
        διατάξη αποδόσεις. <br />
        <br />
        Άρθρον 139. <br />
        Τα εν Τουρκία ευρισκόμενα αρχεία, βιβλία, σχέδια, τίτλοι και άλλα
        έγγραφα πάσης φύσεως, τα αφορώντα εις τας πολιτικάς, δικαστικάς ή
        οικονομικάς Αρχάς ή εις την διεύθυνσιν των βακουφίων και ενδιαφέροντα
        αποκλειστικώς την Κυβέρνησιν εδάφους αποσπασθέντος εκ της Οθωμανικής
        Αυτοκρατορίας και αντιστοίχως τα εν εδάφει αποσπασθέντι εκ της
        Οθωμανικής Αυτοκρατορίας ευρισκόμενα τοιαύτα, άτινα ενδιαφέρουσιν
        αποκλειστικώς την Τουρκικήν Κυβέρνησιν θέλουσιν αμοιβαίως παραδοθή υπό
        της μιας Κυβερνήσεως προς την ετέραν.
        <br />
        Τα ανωτέρω αναφερόμενα αρχεία, βιβλία, σχέδια, τίτλοι και λοιπά έγγραφα
        δια τα οποία η κάτοχος Κυβέρνησις θεωρεί εαυτήν εξ ίσου ενδιαφερομένην,
        δύνανται να κρατηθώσιν υπ’ αυτής, υπό τον όρον της παροχής εις την
        ενδιαφερομένην Κυβέρνησιν, επί τη αιτήσει ταύτης, φωτογραφιών ή
        κεκυρωμένων ακριβών αντιγράφων. <br />
        Τα αρχεία, βιβλία, σχέδια, τίτλοι και λοιπά έγγραφα, άτινα τυχόν
        αφηρέθησαν είτε εκ της Τουρκίας, είτε εκ των αποσπασθέντων εδαφών,
        θέλουσιν αμοιβαίως αποδοθή εν πρωτοτύπω, εφ’ όσον αφορώσιν αποκλειτικώς
        εις τα εδάφη ες ων παρελήφθησαν.
        <br />
        Τα εκ των ενεργειών τούτων έξοδα θέλουσι βαρύνει την αιτούσαν
        Κυβέρνησιν. <br />
        Αι προηγούμεναι διατάξεις εφαρμόζονται, υπό τους αυτούς όρους, εις τα
        βιβλία, άτινα αφορώσι την γαιοκτησίαν ή τα βακούφια εν τοις
        μεταβιβασθείσιν εις την Ελλάδα μετά το 1912 διαμερίσμασι της παλαιάς
        Οθωμανικής Αυτοκρατορίας. <br />
        <br />
        Άρθρον 140. <br />
        Αι κατά το διάστημα του μεταξύ της Τουρκίας και των λοιπών συμβαλλομένων
        Δυνάμεων πολέμου, και προ της 30ης Οκτωβρίου 1918, γενόμενοι εκατέρωθεν
        θαλάσσιαι λείαι δεν θέλουσι δώσει αφορμήν εις απαίτησίν τίνα του ενός ή
        του άλλου μέρους. Ωσαύτως ουδεμία απαίτησις δύναται να εγερθή δια τας
        μετά την ειρημένην χρονολογίαν γενόμενας τυχόν, κατά παράβασιν της
        ανακωχής, συλλήψεις πλοίων υπό των καταλαβουσών την Κωνσταντινούπολιν
        Δυνάμεων. <br />
        Εννοείται ότι ουδεμία απαίτησις θέλει διατύπωση, τόσον εκ μέρους των
        Κυβερνήσεων των καταλαβουσών την Κωνσταντινούπολιν Δυνάμεων ή των
        υπηκόων αυτών, όσον και εκ μέρους της Τουρκικής Κυβερνήσεως ή των
        υπηκόων αυτής σχετικώς προς τας παντός είδους λέμβους, πλοία μικρός
        χωρητικότητας, θαλαμηγούς και φορτηγίδας, αίτινες διετέθησαν υπό των μεν
        ή των δε εκ των Κυβερνήσεων τούτων από της 29ης Οκτωβρίου 1914 μέχρι της
        1ης Ιανουαρίου 1923 εν τοις οικείοις αυτών λιμέσιν ή τοις παρ’ αυτών
        καταληφθείσι. Η διάταξις αυτή, ουχ ήττον, δεν θέλει θίξει τας διατάξεις
        της παραγράφου 6 της υπό σημερινήν χρονολογίαν Δηλώσεως περί αμνηστείας,
        ουδέ τας διεκδικήσεις, ας θα ηδύναντο να εγείρωσιν ιδιώται κατ’ άλλων
        ιδιωτών δυνάμει προγενεστέρων της 29ης Οκτωβρίου 1914 δικαιωμάτων.{" "}
        <br />
        Τα υπό τουρκικήν σημαίαν πλοία, άτινα συνελήφθησαν υπό των ελληνικών
        δυνάμεων μετά την 30ην Οκτωβρίου 1918, θέλουσιν αποδοθή εις την
        Τουρκίαν. <br />
        <br />
        Άρθρον 141. <br />
        Κατ’ εφαρμογήν του άρθρου 25 της παρούσης Συνθήκης και των άρθρων 155,
        250 και 440 ως και του Παραρτήματος Γ, Μέρος Η’ (Επανορθώσεις) της
        Συνθήκης Ειρήνης των Βερσαλλιών της 28ης Ιουνίου 1919, η Τουρκική
        Κυβέρνησις και οι υπήκοοι αυτής απαλλάσονται πάσης τυχόν επιβαρυνούσης
        τούτους απέναντι της Γερμανικής Κυβερνήσεως ή των υπηκόων αυτής
        υποχρεώσεως σχετικώς προς παν γερμανικό πλοίον, όπερ, μεταβιβασθέν κατά
        την διάρκειαν του πολέμου υπό της Γερμανικής Κυβερνήσεως ή των υπηκόων
        αυτής εις την Οθωμανικήν Κυβέρνησιν ή τους υπηκόους της, άνευ της
        συγκαταθέσεως των Συμμάχων Κυβερνήσεων, διατελεί νυν υπό την κατοχήν των
        τελευταίων τούτων. <br />
        Η διάταξις αύτη θέλει εφαρμοσθή, ενδεχομένως, και εις τας σχέσεις μεταξύ
        Τουρκίας και των λοιπών Δυνάμεων αίτινες επολέμησαν παρά το πλευρόν
        αυτής. <br />
        <br />
        Άρθρον 142. <br />
        Η συνομολογηθείσα την 30ην Ιανουαρίου 1923 μεταξύ της Ελλάδος και της
        Τουρκίας ειδική Σύμβασις περί ανταλλαγής των ελληνικών και τουρκικών
        πληθυσμών θα έχη μεταξύ των δύο τούτων Υψηλών Συμβαλλομένων Μερών την
        αυτήν ισχύν και κύρος, ως εάν περιελαμβάνετο εν τη παρούση Συνθήκη.{" "}
        <br />
        <br />
        Άρθρον 143. <br />
        Η ΠΑΡΟΥΣΑ ΣΥΝΘΗΚΗ επικυρωθήσεται, ως οιόν τε τάχιον. <br />
        Αι επικυρώσεις κατατεθήσονται εν Παρισίοις. <br />
        Η Ιαπωνική Κυβέρνησις δύναται και απλώς να γνωστοποίηση προς την
        Κυβέρνησιν της Γαλλικής Δημοκρατίας, δια του εν Παρισίοις διπλωματικού
        αυτής αντιπροσώπου, ότι η επικύρωσις αυτής εγένετο, εν τοιαύτη δε
        περιπτώσει οφείλει να διαβιβάση όσον το δυνατόν ταχύτερον, την πράξιν
        της επικυρώσεως. Εκάστη των υπογραψασών Δυνάμεων θα επικυρώση δια μιας
        και της αυτής πράξεως την παρούσαν Συνθήκην, ομού μετά των παρ’ αυτής
        υπογραφεισών και εν τη τελική Πράξει της Συνδιασκέψεως της Λωζάνης
        αναφερόμενος πράξεων, εφ’ όσον αύται χρήζουσιν επικυρώσεως. <br />
        Το πρώτον Πρακτικόν καταθέσεως θέλει συνταχθή ευθύς ως η Τουρκία, αφ’
        ενός, και η Βρεττανική Αυτοκρατορία, η Γαλλία, η Ιταλία, η Ιαπωνία ή
        τρείς εξ αυτών, αφ’ ετέρου, καταθέσωσιν την πράξιν της επικυρώσεως
        αυτών. <br />
        Από της χρονολογίας του πρώτου τούτου πρακτικού, η Συνθήκη άρξεται
        ισχύουσα μεταξύ των ούτως επικυρωσάντων αυτήν Υψηλών Συμβαλλομένων
        Μερών. Ως προς τας λοιπάς Δυνάμεις άρξεται ακολούθως αύτη ισχύουσα από
        της ημέρας της καταθέσεως της επικυρώσεως αυτών. <br />
        Ουχ’ ήττον, όσον αφορά εις την Ελλάδα και την Τουρκίαν, αι διατάξεις των
        άρθρων 1,2 εδ.2 και 5-11 συμπεριλαμβανομένου, άρξονται ισχύουσαι ευθύς
        ως η Ελληνική και η Τουρκική Κυβέρνησις καταθέσωσι την πράξιν της
        επικυρώσεως αυτών, έστω και αν, κατά την ημέραν ταύτην, δεν έχει εισέτι
        συνταχθή το ανωτέρω αναφερόμενον πρακτικόν. <br />
        Η Γαλλική Κυβέρνησις θέλει χορηγήση εις πάσας τας υπογραψάσας Δυνάμεις
        επίσημον αντίγραφον των πρακτικών καταθέσεως των επικυρώσεων. ΠΡΟΣ
        ΠΙΣΤΩΣΙΝ ΤΟΥΤΩΝ, οι ανωτέρω μνημονευόμενοι Πληρεξούσιοι υπέγραψαν την
        παρούσαν Συνθήκην. <br />
        ΕΓΈΝΕΤΟ εν Λωζάννη, τη εικοστή τετάρτη Ιουλίου του χιλιοστού
        εννεακοσιοστού εικοστού τρίτου έτους, εις εν και μόνον αντίτυπον, όπερ
        κατατεθήσεται εν τοις αρχείοις της Κυβερνήσεως της Γαλλικής Δημοκρατίας,
        ήτις θέλει επιδώσει επίσημον αυτού αντίγραφον προς εκάστην των
        συμβαλλομένων Δυνάμεων. <br />
        <br />
        Horace RUMBOLD <br />
        PELLE <br />
        GARRONI <br />
        G. C. MONTAGNA <br />
        K. OTCHIAI <br />
        E.K. ΒΕΝΙΖΕΛΟΣ <br />
        Δ.ΚΑΚΛΑΜΑΝΟΣ <br />
        Const. DIAMANDY <br />
        Const. CONTZESCO <br />
        M. ISMET <br />
        Dr. RIZA NOUR <br />
        ΠΕΡΙ TOY ΚΑΘΕΣΤΩΤΟΣ ΤΩΝ ΣΤΕΝΩΝ <br />
        ΠΑΡΑΡΤΗΜΑ
        <br />
        ΚΑΝΟΝΕΣ ΔΙΑ ΤΗΝ ΔΙΟΔΟΝ ΤΩΝ ΕΜΠΟΡΙΚΩΝ ΠΛΟΙΩΝ ΚΑΙ ΑΕΡΟΠΛΟΙΩΝ ΚΑΙ ΤΩΝ
        ΠΟΛΕΜΙΚΩΝ ΣΚΑΦΩΝ ΚΑΙ ΑΕΡΟΠΛΟΙΩΝ ΕΝΤΟΣ ΤΩΝ ΣΤΕΝΩΝ <br />
        ΥΠΟΓΡΑΦΕΙΣΑ ΤΗ 24 ΙΟΥΛΙΟΥ 1923 <br />
        Η ΒΡΕΤΤΑΝΙΚΗ ΑΥΤΟΚΡΑΤΟΡΙΑ, Η ΓΑΛΛΙΑ, Η ΙΤΑΛΙΑ. Η ΙΑΠΩΝΙΑ, Η ΒΟΥΛΓΑΡΙΑ, Η
        ΕΛΛΑΣ, Η ΡΟΥΜΑΝΙΑ, Η ΡΩΣΣΙΑ, ΤΟ ΣΕΡΒΟ-ΚΡΟΑΤΟ- ΣΛΟΒΕΝΙΚΟΝ ΚΡΑΤΟΣ ΚΑΙ Η
        ΤΟΥΡΚΙΑ, <br />
        Μεριμνώσαι όπως εξασφαλίσωσιν εις πάντα τα Έθνη την εντός των Στενών
        ελευθερίαν διόδου και ναυσιπλοΐας, μεταξύ της Μεσογείου θαλάσσης και του
        Ευξείνου Πόντου, συμφώνως προς την καθιερωθείσαν αρχήν υπό του άρθρου 23
        της υπό σημερινήν χρονολογίαν Συνθήκης Ειρήνης, Και έχουσαι υπ’ όψιν ότι
        η διατήρησις της ελευθερίας ταύτης είναι αναγκαία δια την γενικήν
        ειρήνην και το παγκόσμιον εμπόριον, Απεφάσισαν να συνομολογήσωσιν επί
        τούτω Σύμβασιν και διώρισαν ως οικείους αυτών πληρεξουσίους: <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΟΥ ΗΝΩΜΕΝΟΥ ΒΑΣΙΛΕΙΟΥ ΤΗΣ ΜΕΓΑΛΗΣ
        ΒΡΕΤΤΑΝΙΑΣ ΚΑΙ ΤΗΣ ΙΡΛΑΝΔΙΑΣ ΚΑΙ ΤΩΝ ΥΠΕΡΘΑΛΛΑΣΙΩΝ ΒΡΕΤΤΑΝΙ-ΚΩΝ ΧΩΡΩΝ
        ΑΥΤΟΚΡΑΤΩΡ ΤΩΝ ΙΝΔΙΩΝ:
        <br />
        Τον Εντιμότατον Sir Horace George Montagu RUMBOLD, Baronet, G.C.M.G.,
        Ύπατον Αρμοστήν εν Κωνσταντινουπόλει· <br />
        Ο ΠΡΟΕΔΡΟΣ ΤΗΣ ΓΑΛΛΙΚΗΣ ΔΗΜΟΚΡΑΤΙΑΣ:
        <br />
        Τον Μέραρχον Στρατηγόν κ. Maurice PELLE, Πρεσβευτήν της Γαλλίας, Ύπατον
        Αρμοστήν της Δημοκρατίας εν Ανατολή, Ανώτερον Ταξιάρχην Τάγματος της
        Λεγεώνος της Τιμής <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΗΣ ΙΤΑΛΙΑΣ:
        <br />
        Τον έντιμον Μαρκήσιον CamiIIe CARRONI, Γερουσιαστήν του Βασιλείου,
        Πρεσβευτήν της Ιταλίας, Ύπατον Αρμοστήν εν Κωνσταντινουπόλει,
        Μεγαλόσταυρον των Ταγμάτων των Αγίων Μαυρικίου και Λαζάρου και του
        Στέμματος της Ιταλίας· <br />
        Τον Κύριον Jules Cesar MONTAGNA, Έκτακτον Αποσταλμένον και Πληρεξούσιον
        Υπουργόν εν Αθήναις, Ταξιάρχην του Τάγματος των Αγ. Μαυρικίου και
        Λαζάρου, Ανώτερον Ταξιάρχην του Στέμματος της Ιταλίας· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΑΥΤΟΚΡΑΤΩΡ ΤΗΣ ΙΑΠΩΝΙΑΣ:
        <br />
        Τον Κύριον Kentaro OTCHIAI, Jusammi, Μέλος πρώτης τάξεως του Τάγματος
        του Ανατέλλοντος Ηλίου, Έκτακτον και Πληρεξούσιον Πρεσβευτήν εν Ρώμη·{" "}
        <br />
        Η ΑΥΤΌΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΒΟΥΛΓΑΡΩΝ:
        <br />
        Τον Κύριον Bogdan MORPHOFF, πρώην Υπουργόν των Σιδηροδρόμων,
        Ταχυδρομείων και Τηλεγράφων <br />
        Το Κύριον Dimitri STANCIOFF, Διδάκτορα του Δικαίου, Έκτακτον
        Απεσταλμενον και Πληρεξουσιον Υπουργόν εν Λονδίνω, Μεγαλόσταυρον του
        Τάγματος του Αγίου Αλεξάνδρου· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΕΛΛΗΝΩΝ:
        <br />
        Τον Κύριον Ελευθέριον Κ. ΒΕΝΙΖΕΛΟΝ, Πρώην Πρόεδρον του Υπουργικού
        Συμβουλίου, Μεγαλόσταυρον του Τάγματος του Σωτήρος· <br />
        Τον Κύριον Δημήτριον ΚΑΚΛΑΜΑΝΟΝ, Πληρεξούσιον Υπουργόν εν Λονδίνω,
        Ταξιάρχην του Τάγματος του Σωτήρος· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΗΣ ΡΟΥΜΑΝΙΑΣ:
        <br />
        Τον Κύριον Constantin I. DIAMANTY, Πληρεξούσιον Υπουργόν Τον Κύριον
        Constantin CONTZESCO Πληρεξούσιον Υπουργόν· <br />
        Η ΡΩΣΣΙΑ:
        <br />
        Τον Κύριον Nicolas Ivanovitch IORDANSKI <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ. Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΣΕΡΒΩΝ, ΚΡΟΑΤΩΝ ΚΑΙ ΣΛΟΒΕΝΩΝ:
        <br />
        Τον Δρα Miloutine YOVANOVITCH, ‘Έκτακτον Απεσταλμένον και Πληρεξούσιον
        Υπουργόν εν Βέρνη·
        <br />
        Η ΚΥΒΕΡΝΗΣΙΣ ΤΗΣ ΜΕΓΑΛΗΣ ΕΘΝΟΣΥΝΕΛΕΥΣΕΩΣ ΤΗΣ ΤΟΥΡΚΙΑΣ:
        <br />
        Τον ISMET Pacha, Υπουργόν επί των Εξωτερικών, Βουλευτήν Αδριανουπόλεως{" "}
        <br />
        Τον Δρα RIZA NOUR Bey Υπουργόν της Υγιεινής και Κοινωνικής Περιθάλψεως,
        Βουλευτήν Σινώπης· <br />
        Τον HASSAN Bey, πρώην Υπουργόν Βουλευτήν Τραπεζούντας· <br />
        ΟΙΤΙΝΕΣ, αφ’ ου επεδείξαντο αλλήλοις τα πληρεξούσια αυτών έγγραφα
        ευρεθέντα, εν πληροί τάξει και κατά τους απαιτουμένους τύπους,
        συνεφώνησαν επί των ακολούθων διατάξεων: <br />
        <br />
        Άρθρον 1. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη συμφωνούσιν όπως αναγνωρίσωσι και διακηρύξωσι
        την αρχήν της ελευθερίας της ναυσιπλοΐας εντός των στενών του
        Ελλησπόντου, της Προποντίδος και του Βοσπόρου, των περιλαμβανομένων
        κατωτέρω εν τη γενική ονομασία “Στενά”. <br />
        <br />
        Άρθρον 2. <br />
        Η δίοδος και η ναυσιπλοΐα των εμπορικών πλοίων και αεροπλοίων, και των
        πολεμικών σκαφών και αεροπλοίων εντός των Στενών, εν καιρώ ειρήνης ως
        και εν καιρώ πολέμου, θα κανονίζωνται από τούδε υπό των διατάξεων του
        ώδε συνημμένου Παραρτήματος. <br />
        ΠΑΡΑΡΤΗΜΑ <br />
        <br />
        ΚΑΝΟΝΕΣ ΔΙΑ ΤΗΝ ΔΙΟΔΟΝ ΤΩΝ ΕΜΠΟΡΙΚΩΝ ΠΛΟΙΩΝ ΚΑΙ ΑΕΡΟΠΛΟΙΩΝ ΚΑΙ ΤΩΝ
        ΠΟΛΕΜΙΚΩΝ ΣΚΑΦΩΝ ΚΑΙ ΑΕΡΟΠΛΟΙΩΝ ΕΝΤΟΣ ΤΩΝ ΣΤΕΝΩΝ <br />
        <br />
        §1. <br />
        Εμπορικά πλοία, συμπεριλαμβανομένων των πλωτών νοσοκομείων, θαλαμηγών
        και αλιευτικών πλοίων ως και των μη πολεμικών αεροπλοίων: <br />
        α) Εν καίρω ειρήνης: <br />
        Πλήρης ελευθερία ναυσιπλοΐας και διόδου, ημέρας τε και νύχτας, οιαδήποτε
        και αν ή η σημαία και το φορτίον, άνευ ουδεμιάς διατυπώσεως, τέλους ή
        υποχρεώσεως οιασδήποτε, υπό την επιφύλαξιν των διεθνών υγειονομικών
        διατάξεων, εφ’ όσον δεν πρόκειται περί αμέσως παρεχομένων υπηρεσιών, ως
        τελών πλοηγίας, φάρων, ρυμουλκίσεως ή άλλων ομοίας φύσεως, και χωρίς να
        θίγωνται τα επί του προκειμένου εξασκούμενα δικαιώματα υπό των ήδη
        παραχωρηθεισών υπό της Τουρκικής Κυβερνήσεως υπηρεσιών και επιχειρήσεων.{" "}
        <br />
        Προς διευκόλυνσιν της εισπράξεως των δικαιωμάτων τούτων τα διερχόμενα
        των Στενών εμπορικά πλοία οφείλουσι να δηλώσιν εις τους υπό της
        Τουρκικής Κυβερνήσεως υποδεικνυόμενος σταθμούς το όνομα, την εθνικότητα,
        την χωρητικότητα και τον προορισμόν αυτών. <br />
        Η πλοιηγία παραμένει προαιρετική. <br />
        β) Εν καιρώ πολέμου, της Τουρκίας παραμένουσας ουδετέρας: <br />
        Πλήρης ελευθερία ναυσιπλοΐας και διόδου, ημέρας τε και νυχτός, υπό τους
        αυτούς ως άνωθι όρους. Τα δικαιώματα και αι υποχρεώσεις της Τουρκίας, ως
        ουδετέρας Δυνάμεως, δεν επιτρέπουσα αυτή όπως λαμβάνη μέτρα δυνάμενα να
        παρακωλύσωσιν την ναυσιπλοΐάν εντός των Στενών, των οποίων τα ύδατα και
        η ατμόσφαιρα δέον να παραμένωσιν απολύτως ελεύθερα, εν καιρώ πολέμου,
        της Τουρκίας ούσης ουδετέρας, ως και εν καιρώ ειρήνης. <br />
        Η πλοηγία παραμένει προαιρετική. <br />
        γ) Εν καιρώ πολέμου, της Τουρκίας ούσης εμπολέμου: <br />
        Ελευθερία ναυσιπλοΐας δια τα ουδέτερα πλοία και τα μη πολεμικά ουδέτερα
        αερόπλοια, εάν το πλοίον ή το αερόπλοιον δεν βοηθή τον εχθρόν, μεταφέρον
        ιδίως λαθρεμπόρων πολέμου, στρατόν ή υπηκόους εχθρούς. Η Τουρκία θα έχη
        το δικαίωμα να εκτελή νηοψίας επί των ειρημένων πλοίων και αεροπλοίων,
        και, προς τον σκοπόν τούτον, τα αερόπλοια δέον να προσγειώνται ή
        προσθαλασσώνται εις τας επί τούτω καθορισθησομένας και
        παρασκευασθησομένας υπό της Τουρκίας ζώνας. Το δικαίωμα της Τουρκίας
        όπως εφαρμόζη εις τα εχθρικά πλοία τα υπό του Διεθνούς Δικαίου
        καθιερωμένα μέτρα δεν θίγεται ποσώς. <br />
        Η Τουρκία θα έχη πλήρες δικαίωμα να λαμβάνη οία μέτρα ήθελε κρίνει
        αναγκαία προς παρεμπόδισιν των εχθρικών πλοίων από του να χρησιμοποιώσι
        τα Στενά. Ουχ’ ήττον, τα μέτρα ταύτα δεν θα είναι τοιαύτης φύσεως, ώστε
        να παρακωλύηται η ελευθέρα δίοδος των ουδετέρων πλοίων, και, προς τούτο,
        η Τουρκία υποχρεούται να παρέχη αυτοίς τας αναγκαιούσας οδηγίας ή τους
        αναγκαίους πλοηγούς. <br />
        <br />
        §2. <br />
        Πολεμικά σκάφη, συμπεριλαμβανομένων των βοηθητικών πλοίων, των
        μεταγωγικών, των αεροπλανοφόρων σκαφών και των πολεμικών αεροπλοίων.{" "}
        <br />
        α) Εν καιρώ ειρήνης: <br />
        Πλήρης ελευθερία διόδου, ημέρας τε και νυκτός, δι’ αοιανδήποτε σημαίαν,
        άνευ ουδεμιάς διατυπώσεως, τέλους ή υποχρεώσεως τίνος, αλλ’ υπό τοις
        κάτωθι επιφυλάξεις ως προς το σύνολον των δυνάμεων. <br />
        Η κατά μέγιστον όριον δύναμις Κράτους, ήτις δύναται να διέλθη των Στενών
        κατευθυνόμενη προς τον Εύξεινον Πόντον, δεν θέλει υπερβή την δύναμιν του
        ισχυροτέρου στόλου των Παρευξεινείων Δυνάμεων, του ευρισκομένου εν τη
        θαλάσση ταύτη κατά την στιγμήν της διόδου. Ουχ’ ήττον, οι Δυνάμεις
        επιφυλάσσουσιν εαυταίς το δικαίωμα όπως αποστείλωσιν εις τον Εύξεινον
        Πόντον, εν παντί καιρώ και πάση περιστάσει, δύναμιν μη υπερβαίνουσαν
        τρία σκάφη, ουδέν των οποίων θα ή μεγαλείτερον των 10.000 τόννων. <br />
        Ουδεμία ευθύνη θέλει βαρύνει την Τουρκίαν όσον αφορά τον αριθμόν των δια
        των Στενών διερχομένων σκαφών. <br />
        Όπως καθίσταται δυνατή η τήρησις του παρόντος κανόνος, η εν τω άρθρω 10
        προβλεπομένη Επιτροπή των Στενών θα αιτήται παρ’ εκάστης Παρευξεινείου
        Δυνάμεως, την 1ην Ιανουαρίου και την 1ην Ιουλίου εκάστου έτους, τον
        αριθμόν των θωρηκτών, ευδρόμων μάχης, αεροπλανοφόρων σκαφών, ευδρόμων,
        αντιτορπιλλικών, υποβρυχίων ή παντός άλλου τύπου σκάφους, ως και των
        ναυτικών αεροπλοίων, άτινα διατηρεί εν τω Ευξείνω Πόντω, μετά καθορισμού
        των εξωπλισμένων σκαφών, των σκαφών μετά ηλαττωμένου προσωπικού, εν
        παρο-πλισμώ, εν επισκευή ή μετασκευή.
        <br />
        Η Επιτροπή των Στενών θα ειδοποίηση είτα τας ενδιαφερόμενος Δυνάμεις
        περί του αριθμού των θωρηκτών, ευδρόμων μάχης, αεροπλανοφόρων σκαφών,
        ευδρόμων, αντιτορπιλλικών, υποβρυχίων, αεροπλοίων και ενδεχομένως,
        μονάδων άλλου τύπου, εξ ων συνίσταται η ισχυροτέρα εν τω Ευξείνω Πόντω
        Δύναμις. Προς τούτοις, πάσα μεταβολή προκύπτουσα είτε εκ της εισόδου εν
        τω Ευξείνω, είτε της εξόδου εξ αυτού παντός σκάφους ανήκοντος εις την
        ειρημένην Δύναμιν, θέλει αμέσως γνωστοποιηθή εις τας ενδιαφερομένας
        Δυνάμεις. <br />
        Ο αριθμός και ο τύπος των εξωπλισμένων σκαφών θέλουσιν μόνοι ληφθή υπ’
        όψιν δια την εκτίμησιν της ναυτικής Δυνάμεως, ήτις θέλει διέλθη των
        Στενών κατευθυνόμενη εις τον Εύξεινον Πόντον. <br />
        β) Εν καιρώ πολέμου, της Τουρκίας ούσης ουδετέρας: <br />
        Πλήρης ελευθερία διόδου, ημέρας τε και νυκτός, δι’ οιανδήποτε σημαίαν,
        άνευ ουδεμιάς διατυπώσεως, τέλους ή υποχρεώσεως τίνος, αλλ’ υπό τους
        αυτούς περιορισμούς οίτινες προβλέπονται εν τη παραγράφω 2 εδ. α.){" "}
        <br />
        Ουχ’ ήττον, οι περιορισμοί ούτοι δεν εφαρμόζονται επί των εμπολέμων
        Δυνάμεων επί ζημία των δικαιωμάτων αυτών ως εμπολέμων εν τω Ευξείνω
        Πόντω. <br />
        Τα δικαιώματα και αι υποχρεώσεις της Τουρκίας, ως ουδετέρας Δυνάμεως,
        δεν επιτρέπουσιν αυτή όπως λαμβάνη μέτρα δυνάμενα να παρακωλύσωσι την
        ναυσιπλοιαν εντός των Στενών, ων τα ύδατα και η ατμόσφαιρα δέον να
        παραμένωσιν εντελώς ελεύθερα, εν καιρώ πολέμου, της Τουρκίας ούσης
        ουδετέρας, ως και εν καιρώ ειρήνης. <br />
        Απαγορεύεται εις τα πολεμικά σκάφη και εις τα πολεμικά αερόπλοΐα των
        εμπολέμων να ενεργώσιν συλλήψεις, εκτελώσι νηοψίας ή προβαίνωσιν εις
        πάσαν άλλην εχθρικήν πράξιν εντός των Στενών.
        <br />
        Όσον αφορά τον ανεφοδιασμόν και τας επισκευάς, τα πολεμικά σκάφη θα
        διέπωνται υπό των διατάξεων της XIII Συμβάσεως της Χάγης του 1907, περί
        της θαλασσιάς ουδετερότητος. <br />
        Μέχρι της συνομολογήσεως διεθνούς Συμβάσεως καθοριζούσης τους κανόνας
        της ουδετερότητας δια τα αερόπλοια, τα πολεμικά αερόπλοια θα υπάγωνται
        εντός των Στενών εις ανάλογον προς το προβλεπόμενον δια τα πολεμικά
        σκάφη υπό της XIII Συμβάσεως της Χάγης του 1907. γ) Εν καιρώ πολέμου,
        της Τουρκίας ούσης εμπολέμου: <br />
        Πλήρης ελευθερία διόδου δια τα ουδέτερα πολεμικά σκάφη, άνευ ουδεμίας
        διατυπώσεως, τέλους ή υποχρεώσεως τίνος, αλλ’ υπό τους αυτούς
        περιορισμούς οίτινες προβλέπονται εν τη παραγράφω 2 εδ. α.) <br />
        Τα ληφθησόμενα υπό της Τουρκίας μέτρα, προς παρεμπόδισιν των εχθρικών
        σκαφών και αεροπλοίων από του να χρησιμοποιώσι τα Στενά, δεν θα είναι
        φύσεως τοιαύτης, ώστε να παρακωλύηται η ελευθέρα δίοδος των ουδετέρων
        σκαφών και αεροπλοίων, και προς τούτο η Τουρκία υποχρεούται να παρέχη
        εις τα ειρημένα σκάφη και αερόπλοια τας αναγκαίουσας οδηγίας ή τους
        αναγκαίους πλοηγούς. <br />
        Τα ουδέτερα πολεμικά αερόπλοια θα διαπλέωσι τα Στενά υπ’ ευθύνη αυτών
        και θα υπόκεινται εις έλεγχον ως προς τον χαρακτηρισμόν των. Προς τον
        σκοπόν τούτον, τα αερόπλοια δένον να προσγειώνται ή προσθαλασσώνται εις
        τας ορισθησομένας και πρασκευασθησομένας επί τούτω υπό της Τουρκίας
        ζώνας. <br />
        <br />
        §3. <br />
        α) Τα υποβρύχια των διατελουσών εν ειρήνη μετά της Τουρκίας Δυνάμεων
        δέον να διέρχωνται των Στενών επί της επιφανείας. β) Ο αρχηγός ξένης
        ναυτικής δυνάμεως προερχομένης εκ της Μεσογείου ή του Ευξείνου Πόντου θα
        δηλώνη εις σηματοφορικόν τίνα σταθμόν κατά την είσοδον του Ελλησπόντου ή
        του Βοσπόρου, χωρίς να ανακόπτη τον πλουν του, τον αριθμόν και το όνομα
        των υπό τας διαταγάς αυτού σκαφών, άτινα πρόκειται να εισέλθωσιν εντός
        των Στενών. <br />
        Η Τουρκία θα γνωστοποίηση τους σηματοφορικούς τούτους σταθμούς, μέχρι δε
        της ανακοινώσεως ταύτης, η ελευθερία διόδου των ξένων πολεμικών σκαφών
        δια των Στενών δεν θέλει παύσει υφισταμένη, της εισόδου εντός αυτών μη
        δυναμένης ως εκ τούτου να επιβραδυνθή.
        <br />
        γ) Η άδεια δια τα πολεμικά και μη πολεμικά αερόπλοια όπως υπερίπτανται
        των Στενών, υπό τους προβλεπόμενους υπό των παρόντων κανόνων όρους,
        συνεπάγεται δια τα ειρημένα αερόπλοια: <br />
        1) την ελευθερίαν πτήσεως υπεράνω λωρίδας εδάφους πέντε χιλιομέτρων επί
        εκάστης πλευράς των στενότερων μερών των Στενών. <br />
        2) το δικαίωμα, εν περιπτώσει βλάβης, όπως προσγειώνται επί της παραλίας
        ή προσθαλασσώνται εντός των χωρικών υδάτων της Τουρκίας. <br />
        <br />
        §4. <br />
        Περιορισμός της διόδου των πολεμικών σκαφών <br />
        Τα διερχόμενα δια των Στενών πολεμικά σκάφη δεν δύνανται εν ουδεμία
        περιπτώσει, εκτός της αβαρίας ή θαλασσίου κινδύνου, να παραμείνωσιν
        εντός αυτών πέραν του αναγκαιούντος αυτοίς προς διέλευσιν χρόνου ,
        συμπεριλαμβανομένου και του χρόνου της αγκυροβολιάς κατά την νύκτα, εάν
        η ασφάλεια της ναυσιπλοΐας επιβάλλη τούτο. <br />
        <br />
        §5. <br />
        Παραμονή εν τοις λιμέσι των Στενών και του Ευξείνου Πόντου. <br />
        α) Αι παράγραφοι 1, 2 και 3 του παρόντος Παραρτήματος, εφαρμοζόμεναι επί
        της διόδου των πλοίων, πολεμικών σκαφών και αεροπλοίων δια των Στενών
        και υπεράνω αυτών, δεν θίγουσι το δικαίωμα της Τουρκίας όπως εκδίδη τους
        κανονισμούς, ους ήθελε κρίνει αναγκαίους ως προς τον αριθμόν των
        πολεμικών σκαφών και των πολεμικών αεροπλοίων της αυτής δυνάμεως, άτινα
        θα δύνανται να επισκέπτωνται συγχρόνως τους τουρκικούς λιμένας και τα
        αεροδρόμια, ως και την διάρκειαν της παραμονής αυτών. <br />
        β) Αι Παρευξείνειοι Δυνάμεις θα έχωσι το αυτό δικαίωμα, όσον αφορά τους
        λιμένας και τα αεροδρόμια αυτών.
        <br />
        γ) Τα ελαφρά σκάφη, τα οποία αι ήδη εν τη Ευρωπαϊκή Επιτροπή του
        Δουνάβεως εκπροσωπούμενοι Δυνάμεις διατηρούσιν ως φυλακίδας εις τας
        εκβολάς του ποταμού τούτου και μέχρι του Γαλαζίου, θέλουσι προστεθή εις
        τα υπό της 2 παραγράφου προβλεπόμενα, δυνάμενα να αντικαθίστανται, εάν
        παραστή ανάγκη. <br />
        <br />
        §6. <br />
        Ειδικαί διατάξεις περί της υγεινομικής προφυλάξεως <br />
        Τα πολεμικά σκάφη, εφ’ ων υπάρχουσιν κρούσματα πανώλους, χολέρας ή
        τυφοειδούς πυρετού, ή εφ’ ων εσημειώθησαν τοιαύτα από επτά ημερών, ως
        και τα σκάφη άτινα ανεχώρησαν από χρόνου βραχυτέρου των πέντε
        εισοσιτετραώρων εκ λιμένας μεμολυσμένου, οφείλουσι να διέρχωνται των
        Στενών εν καθάρσει και εφαρμόζωσι δια των μέσων, άτινα διαθέτουσι επί
        του πλοίου, τα αναγκαία προφυλακτικά μέτρα προς πρόληψιν πάσης δυνατής
        μολύνσεως των Στενών. <br />
        Η διάταξις αυτή εφαρμοσθήσεται και επί των εμπορικών πλοίων, εφ’ ων
        υπάρχει ιατρός, των διερχομένων κατ’ ευθείαν δια των Στενών, χωρίς να
        σταθμεύσωσιν ή εκφορτώσωσιν εμπορεύματα. <br />
        Τα εμπορικά πλοία, εφ’ ων δεν υπάρχει ιατρός, δέον, πριν εισέλθωσιν
        εντός των Στενών, και αν ακόμη δεν πρόκειται να σταθμεύσωσιν, όπως
        συμμορφώνται προς τας διεθνείς υγειονομικάς διατάξεις. <br />
        Τα πολεμικά σκάφη και τα εμπορικά πλοία, προσεγγείζοντα εις τίνα των
        λιμένων των Στενών, θα υπάγωνται εντός του λιμένος τούτου εις τας
        ισχύουσας εν αυτώ διεθνείς υγειονομικάς διατάξεις. <br />
        <br />
        Άρθρον 3. <br />
        ‘Όπως διατηρήται ελευθέρα παντός κωλύματος ή δίοδος και η ναυσιπλοΐα
        εντός των Στενών, τα εν τοις άρθροις 4-9 καθοριζόμενα μέτρα θα
        εφαρμόζωνται εις τα ύδατα και τας ακτάς αυτών, ως και εις τας εντός των
        Στενών ή παρ’ αυτά κείμενας νήσους. <br />
        <br />
        Άρθρον 4. <br />
        Ουδετεροποιούνται αι κατωτέρω οριζόμεναι ζώναι και νήσοι: <br />
        1) Αι δύο ακταί του στενού του Ελλησπόντου και του Βοσπόρου κατά την
        έκτασιν των κατωτέρω καθοριζομένων ζωνών (Όρα συνημμένον χάρτην):
        Ελλήσποντος: Βορειοδυτικώς, χερσόνησος της Καλλιπόλεως και περιοχή
        νοτιοανατολικώς γραμμής, αρχομένης από σημείου του κόλπου του Ξηρού
        κείμενου 4 χιλιόμετρα βορειοανατολικώς του Μπακλά – Μπουρνού,
        καταληγούσης επί της Προποντίδος εις Κούμβαον και διερχόμενης νοτίως του
        Καβάκ (του χωρίου τούτου μη συμπεριλαμβανομένου)· <br />
        Νοτιοανατολικώς, περιοχή περιλαμβανομένη μεταξύ της ακτής και γραμμής
        χαρασσομένης 20 χιλιόμετρα από της ακτής, αρχομένης από του ακρωτηρίου
        Εσκή – Ισταμπόλ έναντι της Τενέδου και καταληγούσης εις την Προποντίδα
        επί σημείου της ακτής κειμένου αμέσως βορείως του Καραμπιγά. <br />
        Βόσπορος (υπό την επιφύλαξιν του εδικού καθεστώτος της
        Κωνσταντινουπόλως, ‘Αρθ. 8): <br />
        Ανατολικώς, ζώνη εκτεινομένη μέχρι γραμμής χαρασσομένης 15 χιλιόμετρα
        από της ανατολικής ακτής του Βοσπόρου- Δυτικώς, ζώνη εκτεινομένη μέχρι
        γραμμής χαρασσομένης 15 χιλιόμετρα από της δυτικής ακτής του Βοσπόρου.{" "}
        <br />
        2) Άπασαι αι νήσοι της Προποντίδος, εκτός της νήσου Καλολίμνου. <br />
        3) Εν τω Αιγαίω, αι νήσοι Σαμοθράκη, Λήμνος, Ίμβρος, Τένεδος και αι
        Λαγούσαι νήσοι (Μαυρυαί). <br />
        <br />
        Άρθρον 5. <br />
        Εντός δέκα πέντε ημερών από της ενάρξεως της ισχύος της παρούσης
        Συμβάσεως, θέλει συνέλθει Επιτροπή, αποτελουμένη εκ τεσσάρων μελών
        διοριζομένων υφ’ εκάστης των Κυβερνήσεων της Γαλλίας, της Μεγάλης
        Βρεττανίας, της Ιταλίας και της Τουρκίας, όπως καθορίση επί τόπου τα
        όρια των εν τω άρθρω 4 εδ. 1 προβλεπομένων ζωνών. <br />
        Αι εν τη Επιτροπή ταύτη αντιπροσωπευόμεναι Κυβερνήσεις θέλουσι
        μεριμνήσει περί των αποζημιώσεων, ων θα δικαιούνται οι οικείοι αυτών
        αντιπρόσωποι. <br />
        Πάντα τα εκ των λειτουργίας της Επιτροπής προκύπτοντα γενικά έξοδα
        θέλουσιν επιβαρύνει εξ ίσου τας αντιπροσωπευομένας Δυνάμεις. <br />
        <br />
        Άρθρον 6. <br />
        Υπό την επιφύλαξιν των περί Κωνσταντινουπόλεως διατάξεων του άρθρου 8,
        ουδέν οχυρωματικόν έργον, ουδεμία μόνιμος εγκατάστασις πυροβολικού,
        ουδέν υποβρύχιον πολεμικόν μηχάνημα, εκτός των υποβρυχίων σκαφών, ως και
        ουδεμία εγκατάστασις στρατιωτικής αεροναυτικής, ή ναυτική βάσις δύνανται
        να υφίστανται εν ταις ουδετεροποιουμέναις ζώναις και νήσοις.
        <br />
        Ουδεμία ένοπλος δύναμις δύναται να σταθμεύση εν αυταίς, εκτός των προς
        διατήρησιν της τάξεως αναγκαιουσών δυνάμεων αστυνομίας και χωροφυλακής,
        των οποίων ο οπλισμός δέον να αποτελήται μόνον εκ του περιστρόφου, της
        σπάθης, του όπλου και τεσσάρων οπλοπολυβόλων ανά εκατόν άνδρας,
        αποκλειομένου παντός πυροβολικού. <br />
        Εντός των χωρικών υδάτων των ουδετεροποιουμένων ζωνών και νήσων, ουδέν
        υποβρύχιον πολεμικόν μηχάνημα δέον να υφίσταται, εκτός των υποβρυχίων
        σκαφών. <br />
        Παρά τα προηγούμενα εδάφια, η Τουρκία θα διατήρηση το δικαίωμα όπως
        διαμετακομίζη τας στρατιωτικός αυτής δυνάμεις δια των ουδετεροποιουμένων
        ζωνών και νήσων του τουρκικού εδάφους, ως και δια των χωρικών αυτών
        υδάτων, ένθα ο τουρκικός στόλος δύναται να αγκυροβολή. <br />
        Προς τούτοις, όσον αφορά τα Στενά, η Τουρκική Κυβέρνησις δικαιούται να
        επισκοπή, δι’ αεροπλάνων ή αεροστάτων, την επιφάνειαν και τον πυθμένα
        της θαλάσσης. Τα τουρκικά αερόπλοια δύνανται πάντοτε να υπερίπτανται των
        υδάτων των Στενών και των ουδετεροποιουμένων ζωνών του τουρκικού εδάφους
        και να προσγειώνται ή προσθαλασσώνται πανταχού εν αυτοίς εν πλήρει
        ελευθερία. <br />
        Η Τουρκία και η Ελλάς δύνανται ωσαύτως, εν ταις ουδετεροποιουμένας
        ζώναις και νήσοις και εν τοις χωρικοίς αυτών ύδασι, να ενεργώσι τας
        μετακινήσεις του προσωπικού τας αναγκαίας δια την εκπαίδευσιν, εκτός των
        ζωνών και νήσων τούτων, των εν αυταίς στρατολογουμένων ανδρών. <br />
        Η Τουρκία και η Ελλάς θα ώσιν ελεύθεραι να διοργανώσωσιν, εντός των
        ειρημίνων ζωνών και νήσων των οικείων αυτών εδαφών, παρατηρητήρια και
        τηλεγραφικάς, τηλεφωνικάς και οπτικάς συγκοινωνίας παντός συστήματος. Η
        Ελλάς δύναται να μεταφέρη τον στόλον της εντός των χωρικών υδάτων των
        ουδετεροποιουμένων ελληνικών νήσων, ουχί όμως και να χρησιμοποιή τα
        ύδατα ταύτα ως βάσιν επιχειρήσεων κατά της Τουρκίας ή δια ναυτικήν ή
        στρατιωτικήν συγκέντρωσιν προς τον σκοπόν τούτον. <br />
        <br />
        Άρθρον 7. <br />
        Ουδέν υποβρύχιον πολεμικόν μηχάνημα, εκτός των υποβρυχίων σκαφών δύναται
        να τοποθετηθή εντός των υδάτων της Προποντίδος. Η Τουρκική Κυβέρνησις
        δεν θέλει εγκαταστήση ουδέν μόνιμον πυροβολείον, ή τορπιλλοβλητικούς
        σωλήνας, δυναμένους να παρακωλύσωσιν την διάβασιν των Στενών, επί της
        παραλιακής ευρωπαϊκής περιοχής της Προποντίδος ή επί της παραλιακής
        περιοχής της Ανατολίας της κειμένης ανατολικώς της ουδετεροποιουμένης
        ζώνης του Βοσπόρου μέχρι της Αρετσού (Ρύσιον). <br />
        <br />
        Άρθρον 8. <br />
        Εν Κωνσταντινουπόλει, συμπεριλαμβανομένων της Σταμπούλ, του Πέρα, Γαλατά
        και της Χρυσουπόλεως ως και των Πριγκηπονήσων, και εις τα εγγύτατα αυτής
        περίχωρα, θα δύναται να σταθμεύη φρουρά εκ 12.000 ανδρών κατά μέγιστον
        όριον, δια τας ανάγκας της πρωτευούσης, θα επιτρέπηται ωσαύτως να
        διατηρηθώσιν εν Κωνσταντινουπόλει εις ναύσταθμος και μία ναυτική βάσις.{" "}
        <br />
        <br />
        Άρθρον 9. <br />
        Εάν, εν περιπτώσει πολέμου, η Τουρκία ή η Ελλάς, ποιούσαι χρήσιν του
        δικαιώματος αυτών ως εμπολέμων Δυνάμεων, προήρχοντο εις την ανάγκην όπως
        επιφέρωσι τροποποιήσεις εις την ως άνω προβλεπομένη ουδετεροποίησιν, θα
        είχον την υποχρέωσιν, άμα τη συνομολογήσει της ειρήνης, να
        αποκαταστήσωσι το υπό της παρούσης Συμβάσεως προβλεπόμενον καθεστώς.{" "}
        <br />
        <br />
        Άρθρον 10. <br />
        Εν Κωνσταντινουπόλει θέλει συσταθή Διεθνής Επιτροπή, υπό την επονομασίαν
        “Επιτροπή των Στενών”, συγκροτούμενη κατά τα εν τω άρθρω 12 οριζόμενα.{" "}
        <br />
        <br />
        Άρθρον 11. <br />
        Η Επιτροπή θα ασκή την δικαιοδοσίαν της επί των υδάτων των Στενών.{" "}
        <br />
        <br />
        Άρθρον 12. <br />
        Η Επιτροπή, διατελούσα υπό την προεδρείαν ενός αντιπροσώπου της
        Τουρκίας, θα αποτελήται εξ αντιπροσώπων της Γαλλίας, της Μεγάλης
        Βρεττανίας, της Ιταλίας, της Ιαπωνίας, της Βουλγαρίας, της Ελλάδος, της
        Ρουμανίας, της Ρωσσίας και του Σεβρο-ΚροατοΣλοβενικού Κράτους, ως
        Δυνάμεων υπογραψασών την παρούσαν Σύμβασιν και καθ’ όσον αύτη
        επικυρούται υπό των Δυνάμεων τούτων. <br />
        Η προσχώρησις των Ηνωμένων Πολιτειών εις την παρούοαν Σύμβασιν θα
        συνεπάγεται δι’ αυτάς το δικαίωμα όπως έχωσιν ωσαύτως αντιπρόσωπον εν τη
        Επιτροπή. <br />
        Το αυτό δικαίωμα, υπό τους αυτούς όρους, επιφυλάσσεται εις τα ανεξάρτητα
        Παρευξείνια Κράτη τα μη αναφερόμενα εν τω πρώτω εδαφίω του παρόντος
        άρθρου. <br />
        <br />
        Άρθρον 13. <br />
        Αι εν τη Επιτροπή αντιπροσωπευόμεναι Κυβερνήσεις θα μεριμνήσωσι περί της
        χορηγηθησομένης τυχόν αποζημιώσεως εις τους αντιπροσώπους αυτών. Πάσα
        συμπληρωματική δαπάνη της Επιτροπής θα επιβαρύνη τας ειρημένας
        Κυβερνήσεις καθ’ ην αναλογίαν κατανέμονται αι δαπάναι της Κοινωνίας των
        Εθνών. <br />
        <br />
        Άρθρον 14. <br />
        Η Επιτροπή θα εξακριβώνη ότι δεόντως τηρούνται αι αφορώσαι την δίοδον
        των πολεμικών σκαφών και πολεμικών αεροπλοίων διατάξεις, αίτινες
        αναφέρονται εν τοις παραγράφοις 2, 3 και 4 του συνημμένου τω άρθρω 2
        Παραρτήματος. <br />
        <br />
        Άρθρον 15. <br />
        Η Επιτροπή των Στενών θα εκπληρή την αποστολήν αυτής υπό την αιγίδα της
        Κοινωνίας των Εθνών, προς την οποίαν θα απευθύνη ετησίως έκθεσιν περί
        της εκπληρώσεως της αποστολής της, μετά πάσης, επί πλέον, χρησίμου
        πληροφορίας όσον αφορά το εμπόριον και την ναυτιλίαν. Προς τούτο, η
        Επιτροπή θα συνεννοήται μετά των υπηρεσιών της Τουρκικής Κυβερνήσεως των
        επιφορτισμένων τα της ναυσιπλοΐας εντός των Στενών.
        <br />
        <br />
        Άρθρον 16. <br />
        Η Επιτροπή θέλει εκπονήση τους αναγκαίους κανονισμούς προς εκπλήρωσιν
        της αποστολής της. <br />
        <br />
        Άρθρον 17. <br />
        Αι διατάξεις της παρούσης Συμβάσεως δεν θίγουσι το δικαίωμα της Τουρκίας
        όπως μετακινή ελευθέρως τον στόλον αυτής εντός των τουρκικών υδάτων.{" "}
        <br />
        <br />
        Άρθρον 18. <br />
        Επιθυμούσαι όπως η ουδετεροποίησις των Στενών και των γειτνιαζουσών
        ζωνών μη καθίσταται υπό στρατιωτικήν έποψιν αιτία αδικαιολογήτου
        κινδύνου δια την Τουρκίαν και όπως μη τίθενται εν κινδύνω η ελευθερία
        των Στενών ή η ασφάλεια των ουδετεροποιουμένων ζωνών εκ πράξεων
        πολεμικών, τα Υψηλά Συμβαλλόμενα Μέρη συμφωνούσιν επί των επομένων
        διατάξεων: <br />
        Εάν η ελευθερία της ναυσιπλοΐας των Στενών ή η ασφάλεια των
        ουδετεροποιουμένων ζωνών ετίθεντο τυχόν εν κινδύνω εκ παραβάσεως των
        διατάξεων περί της ελευθερίας διόδου, αιφνίδιας τινός επιθέσεως, ή
        πολεμικής τίνος πράξεως ή απειλής πολέμου, τα Υψηλά Συμβαλλόμενα Μέρη,
        και, εν πάση περιπτώσει, η Γαλλία, η Μεγάλη Βρεττανία, η Ιταλία και η
        Ιαπωνία θέλουσιν εμποδίσει αυτάς, από κοινού, δι’ όλων των μέσων, τα
        οποία ήθελε προκρίνει προς τον σκοπόν τούτον το Συμβούλιον της Κοινωνίας
        των Εθνών. <br />
        Ευθύς ως αι πράξεις, αίτινες προκάλεσαν την υπό του προηγουμένου εδαφίου
        προβλεπομένην ενέργειαν, ήθελον καταπαύσει, το καθεστώς των Στενών, ως
        κανονίζεται υπό των διατάξεων της παρούσης Συμβάσεως, θα εφαρμοσθή εκ
        νέου επακριβώς. <br />
        Η παρούσα διάταξις, αποτελούσα αναπόσπαστον μέρος των διατάξεων των
        συναφών προς την ουδετεροποίησιν και την ελευθερίαν των Στενών, δεν
        θίγει ποσώς τα δικαιώματα και τας υποχρεώσεις, ας έχουσι τα Υψηλά
        Συμβαλλόμενα Μέρη δυνάμει του Συμφώνου της Κοινωνίας των Εθνών. <br />
        <br />
        Άρθρον 19. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη θα καταβάλωσι πάσαν προσπάθειαν όπως, αι μη
        υπογράψασαι την παρούσαν Σύμβασιν Δυνάμεις, προσχωρήσωσιν εις αυτήν.{" "}
        <br />
        Η προσχώρησις αύτη κοινοποιηθήσεται, δια της διπλωματικής οδού, εις την
        Κυβέρνησιν της Γαλικής Δημοκρατίας και δι’ αυτής εις πάντα τα
        υπογράψαντα ή προσχωρούντα Κράτη, θα ισχύη δε αύτη από της ημέρας της
        κοινοποιήσεως εις την Γαλλικήν Κυβέρνησιν. <br />
        <br />
        Άρθρον 20. <br />
        Η ΠΑΡΟΥΣΑ ΣΥΜΒΑΣΙΣ επικυρωθήσεται. Αι επικυρώσεις κατατεθήσονται εν
        Παρισίοις, ως οίον τε τάχιον. ‘Αρξεται ισχύουσα υφ’ ους όρους και η υπό
        σημερινήν χρονολογίαν Συνθήκη Ειρήνης. Ως προς τας μη υπογραψάσας την
        Συνθήκην ταύτην Δυνάμεις, αίτινες, κατά την στιγμήν ταύτην, δεν έχουσιν
        ακόμη επικυρώσει την παρούσαν Σύμβασιν, άρξεται αύτη ισχύουσα από της
        καταθέσεως των επικυρώσεων αυτών, ήτις θέλει κοινοποιηθή προς τας λοιπάς
        συμβαλλομένας Δυνάμεις υπό της Κυβερνήσεως της Γαλλικής Δημοκρατίας.{" "}
        <br />
        ΠΡΟΣ ΠΙΣΤΩΣΙΝ ΤΟΥΤΩΝ, οι ανωτέρω μνημονευόμενοι Πληρεξούσιοι υπέγραψαν
        την παρούσαν Σύμβασιν. <br />
        ΕΓΕΝΕΤΟ εν Λωζάννη, τη 24η Ιουλίου 1923, εις εν και μόνον αντίτυπον,
        όπερ κατατεθήσεται εν τοις αρχείοις της Κυβερνήσεως της Γαλλικής
        Δημοκρατίας, υπό της οποίας θέλει επιδοθή επίσημον αυτού αντίγραφον προς
        εκάστην των συμβαλλομένων Δυνάμεων. <br />
        Horace RUMBOLD <br />
        PELLE <br />
        GARRONI <br />
        G.C. MONTAGNA <br />
        Κ. OTCHIAI <br />
        Β. MORPHOFF <br />
        STANCIOFF <br />
        Ε. Κ. ΒΕΝΙΖΕΛΟΣ <br />
        Δ. ΚΑΚΛΑΜΑΝΟΣ <br />
        Const. DIAMANDY <br />
        Const. CONTZESCO <br />
        Ν. IORDANSKI <br />
        Μ. ISMET <br />
        Dr. RIZA NOUR <br />
        HASSAN <br />
        ΠΕΡΙ ΤΗΣ ΜΕΘΟΡΙΟΥ ΤΗΣ ΘΡΑΚΗΣ <br />
        ΥΠΟΓΡΑΦΕΙΣΑ ΤΗ 24 ΙΟΥΛΙΟΥ 1923
        <br />
        Η ΒΡΕΤΤΑΝΙΚΗ ΑΥΤΟΚΡΑΤΟΡΙΑ, Η ΓΑΛΛΙΑ, Η ΙΤΑΛΙΑ, Η ΙΑΠΩΝΙΑ. Η ΒΟΥΛΓΑΡΙΑ, Η
        ΕΛΛΑΣ, Η ΡΟΥΜΑΝΙΑ, ΤΟ ΣΕΡΒΟ-ΚΡΟΑΤΟ-ΣΛΟΒΕΝΙΚΟΝ ΚΡΑΤΟΣ ΚΑΙ Η ΤΟΥΡΚΙΑ, εν
        τη μερίμνη αυτών όπως εξασφαλίσωσι την διατήρησιν της ειρήνης εις τα
        σύνορα της Θράκης, Και θεωρούσαι προς τούτο αναγκαίον όπως ληφθώσιν
        αμοιβαίως ειδικά τίνα μέτρα εκατέρωθεν των συνόρων τούτων, καθ’ ά
        προβλέπεται εν τω άρθρω 24 της υπό σημερινήν ημερομηνίαν Συνθήκης
        ειρήνης, Απεφάσισαν να συνομολογήσωσιν επί τούτω Σύμβασιν και διώρισαν
        ως Πληρεξουσίους αυτών: <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΟΥ ΗΝΩΜΕΝΟΥ ΒΑΣΙΛΕΙΟΥ ΤΗΣ ΜΕΓΑΛΗΣ
        ΒΡΕΤΤΑΝΙΑΣ ΚΑΙ ΤΗΣ ΙΡΛΑΝΔΙΑΣ ΚΑΙ ΤΩΝ ΥΠΕΡΘΑΛΛΑΣΙΩΝ ΒΡΕΤΤΑΝΙΚΩΝ ΧΩΡΩΝ
        ΑΥΤΟΚΡΑΤΩΡ ΤΩΝ ΙΝΔΙΩΝ:
        <br />
        Τον Εντιμότατον Sir Horace George Montagu RUMBOLD, Baronet, G.C.M.G.,
        Ύπατον Αρμοστήν εν Κωνσταντινούπολη· <br />
        Ο ΠΡΟΕΔΡΟΣ ΤΗΣ ΓΑΛΛΙΚΗΣ ΔΗΜΟΚΡΑΤΙΑΣ:
        <br />
        Τον Μέραρχον Στρατηγόν κ. Maurice PELLE, Πρεσβευτήν της Γαλλίας, Ύπατον
        Αρμοστήν της Δημοκρατίας εν Ανατολή, Ανώτερον Ταξιάρχην Τάγματος της
        Λεγεώνος της Τιμής <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΗΣ ΙΤΑΛΙΑΣ:
        <br />
        Τον έντιμον Μαρκήσιον Camille GARRONI, Γερουσιαστήν του Βασιλείου,
        Πρεσβευτήν της Ιταλίας, Ύπατον Αρμοστήν εν Κωνσταντινουπόλει,
        μεγαλόσταυρον των Ταγμάτων των Αγίων Μαυρικίου και Λαζάρου και του
        Στέμματος της Ιταλίας· <br />
        Τον Κύριον Jules Cesar MONTAGNA, ‘Εκτακτον Αποσταλμένον και Πληρεξούσιον
        Υπουργόν εν Αθήναις, Ταξιάρχην του Τάγματος των Αγ. Μαυρικίου και
        Λαζάρου, Ανώτερον Ταξιάρχην του Στέμματος της Ιταλίας· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΑΥΤΟΚΡΑΤΩΡ ΤΗΣ ΙΑΠΩΝΙΑΣ:
        <br />
        Τον Κύριον Kentaro OTCHIAI, Jusammi, Μέλος πρώτης τάξεως του Τάγματος
        του Ανατέλλοντος Ηλίου, ‘Εκτακτον και Πληρεξούσιον Πρεσβευτήν εν Ρώμη·{" "}
        <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΒΟΥΛΓΑΡΩΝ:
        <br />
        Τον Κύριον Bogdan MORPHOFF, πρώην Υπουργόν των Σιδηροδρόμων,
        Ταχυδρομείων χαι Τηλεγράφων <br />
        Το Κύριον Dimitri STANCIOFF, Διδάκτορα του Δικαίου, ‘Εκτακτον
        Απεσταλμένον και Πληρεξούσιον Υπουργόν εν Λονδίνω, Μεγαλόσταυρόν του
        Τάγματος του Αγίου Αλεξάνδρου· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΕΛΛΗΝΩΝ:
        <br />
        Τον Κύριον Ελευθέριον Κ. ΒΕΝΙΖΕΛΟΝ, Πρώην Πρόεδρον του Υπουργικού
        Συμβουλίου, Μεγαλόσταυρόν του Τάγματος του Σωτήρος· <br />
        Τον Κύριον Δημήτριον ΚΑΚΛΑΜΑΝΟΝ, Πληρεξούσιον Υπουργόν εν Λονδίνω,
        Ταξιάρχην του Τάγματος του Σωτήρος· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΗΣ ΡΟΥΜΑΝΙΑΣ:
        <br />
        Τον Κύριον Constantin I. DIAMANTY, Πληρεξούσιον Υπουργόν <br />
        Τον Κύριον Constantin CONTZESCO Πληρεξούσιον Υπουργόν· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΣΕΡΒΩΝ, ΚΡΟΑΤΩΝ ΚΑΙ ΣΛΟΒΕΝΩΝ:
        <br />
        Τον Δρα Miloutine YOVANOVITCH. ‘Εκτακτον Απεσταλμένον και Πληρεξούσιον
        Υπουργόν εν Βέρνη· <br />
        Η ΚΥΒΕΡΝΗΣΙΣ ΤΗΣ ΜΕΓΑΛΗΣ ΕΘΝΟΣΥΝΕΛΕΥΣΕΩΣ ΤΗΣ ΤΟΥΡΚΙΑΣ:
        <br />
        Τον ISMET Pacha, Υπουργόν επί των Εξωτερικών, Βουλευτήν Αδριανουπόλεως·{" "}
        <br />
        Τον Δρα RIZA NOUR Bey Υπουργόν της Υγιεινής και Κοινωνικής Περιθάλψεως,
        Βουλευτήν Σινώπης· <br />
        Toν HASSAN Bey, πρώην Υπουργόν Βουλευτήν Τραπεζούντας- ΟΙΤΙΝΕΣ, αφού
        επεδείξαντο αλλήλοις τα πληρεξούσια αυτών έγγραφα, ευρεθέντα εν πλήρει
        τάξει και κατά τους απαιτουμένους τύπους, συνεφώνησαν επί των ακολούθων
        διατάξεων: <br />
        <br />
        Άρθρον 1. <br />
        Τα από του Αιγαίου μέχρι του Ευξείνου Πόντου εδάφη, τα διήκοντα
        εκατέρωθεν των συνόρων, άτινα χωρίζουσι την Τουρκίαν από της Βουλγαρίας
        και της Ελλάδος, θα ουδετεροποιηθώσιν εις βάθος τριάκοντα περίπου
        χιλιομέτρων, περιλαμβανόμενον εντός των κάτωθι ορίων (Όρα συνημμένον
        χάρτην). 1όν) Επί Τουρκικού εδάφους, από του Αιγαίου μέχρι του Ευξείνου
        Πόντου: <br />
        γραμμή αισθητώς παράλληλος προς την μεθόριον της Τουρκίας μετά της
        Ελλάδος και της Βουλγαρίας, ως καθωρίσθη εν τω άρθρω 2 παράγρ. 1 και 2
        της υπό σημερινήν χρονονολογίαν Συνθήκης Ειρήνης. Η γραμμή αύτη θέλει
        χαραχθή εις απόστασιν τριάκοντα τουλάχιστον χιλιομέτρων από της εν λόγω
        μεθορίου, πλην της περιοχής Σαράντα Εκκλησιών, ένθα δέον να αφήση έξω
        της ουδετεροποιουμένης ζώνης αυτήν ταύτην την πόλιν μετά περιμέτρου
        πέντε τουλάχιστον χιλιομέτρων, υπολογιζόμενης από του κέντρου της πόλεως
        ταύτης. Η γραμμή αύτη αρχομένη από του ακρωτηρίου Ιμπριτζέ – Μπουρνού,
        επί του Αιγαίου, θέλει καταλήξει εις το ακρωτήριον Σερμπές – Μπουρνού,
        επί του Ευξείνου Πόντου. <br />
        2ον) Επί Ελληνικού εδάφους, από του Αιγαίου μέχρι της Ελληνο-βουλγαρικής
        μεθορίου: <br />
        γραμμή αναχωρούσα από του άκρου του ακρωτηρίου Μάκρης (του χωρίου Μάκρη
        εξαιρουμένου), ακολουθούσα προς βορράν διεύθυνσιν αισθητώς παράλληλον
        προς τον ρουν του Έβρου μέχρι του Ταχταλή, και εξικνουμένη είτα προς
        ανατολάς του Μεχέρ – κόζ εις σημείον καθοριστέον επί της
        ελληνοβουλγαρικής μεθορίου, δέκα πέντε περίπου χιλιόμετρα δυτικώς του
        Κιουτσούκ – Δερμπέντ. <br />
        3ον) Επί Βουλγαρικού εδάφους, από της Ελληνοβουλγαρικής μεθορίου μέχρι
        του Ευξείνου Πόντου: <br />
        γραμμή αναχωρούσα εκ του ανωτέρου ορισθέντος σημείου, τέμνουσα την οδόν
        Αδριανουπόλεως εις Κοσή – Καβάκ, πέντε χιλιόμετρα δυτικώς του Παπάς –
        κοϊ, είτα προχωρούσα εις απόστασιν τριάκοντα τουλάχιστον χιλιομέτρων από
        της ελληνοβουλγαρικής και της τουρκοβουλγαρικής μεθορίου, πλην της
        περιοχής του Χαρμανλή, ένθα δέον να αφήση έξω της ουδετεροποιουμένης
        ζώνης αυτήν ταύτην την πόλιν μετά περιμέτρου πέντε τουλάχιστον
        χιλιομέτρων, υπολογιζόμενης από του κέντρου της ειρημένης πόλεως, και
        καταλήγουσα επί του Ευξείνου Πόντου εις τον μυχόν του όρμου του κείμενου
        βορειοδυτικώς του Αμπερλέρ. <br />
        <br />
        Άρθρον 2. <br />
        Επιτροπή οροθετήσεως, συγκροτηθησομένη εντός δέκα πέντε ημερών από της
        ενάρξεως της ισχύος της παρούσης Συμβάσεως, θα αναλάβη όπως καθορίση και
        χάραξη επί τόπου τα εν τω άρθρω 1 καθοριζόμενα όρια. Η Επιτροπή αύτη θα
        αποτελήται εξ αντιπροσώπων της Γαλλίας, της Μεγάλης Βρεττανίας, της
        Ιταλίας, της Βουλγαρίας, της Ελλάδος και της Τουρκίας, εκάστης των
        Δυνάμεων τούτων διοριζούσης ανά ένα αντιπρόσωπον. Οι αντιπρόσωποι της
        Βουλγαρίας, της Ελλάδος και της Τουρκίας θα μετέχωσι μόνον των εργασιών,
        αίτινες αφορώσι τα εδάφη των οικείων αυτών Κρατών το συνολικόν όμως
        πόρισμα των εργασιών τούτων θέλει καθορισθή και καταχωρισθή εν ολομέλεια
        της Επιτροπής. <br />
        <br />
        Άρθρον 3. <br />
        Η ουδετεροποίησις των εν τω άρθρω 1 οριζομένων ζωνών, θέλει συντελεσθή
        και διατηρηθή συμφώνως προς τας κάτωθι διατάξεις: <br />
        1ον) Πάντα τα ήδη υπάρχοντα οχυρωματικά έργα, μόνιμα ή εκστρατείας, δέον
        να αφοπλισθώσι και κατεδαφισθώσι μερίμνη της Δυνάμεως επί του εδάφους
        της οποίας ευρίσκονται. Ουδέν νέον έργον τοιούτου είδους θέλει
        κατασκευασθή, ουδεμία δε αποθήκη όπλων ή υλικού πολέμου, ως και ουδεμία
        ετέρα επιθετική ή αμυντική εγκατάστασις στρατιωτικής, ναυτικής ή
        αεροπορικής φύσεως θέλει οργανωθή. <br />
        2ον) Ουδεμία ένοπλος δύναμις δύναται να σταθμεύη ή να μετακινήται πλην
        των ειδικών στοιχείων, ως χωροφυλακής, αστυνομικών δυνάμεων,
        τελωνοφυλάκων, οροφυλάκων, των αναγκαίων προς εξασφάλισιν της εσωτερικής
        τάξεως και επιτήρησιν των συνόρων. <br />
        Ο αριθμός των ειδικών τούτων στοιχείων, εν οις δεν θα περιλαμβάνονται
        αεροπορικαί δυνάμεις, δεν θα υπερβαίνη:
        <br />
        α) εντός της ουδετεροποιουμένης ζώνης του τουρκικού εδάφους, 5000 άνδρας
        εν συνόλω· <br />
        β) εντός της ουδετεροποιουμένης ζώνης του ελληνικού εδάφους 2500 άνδρας
        εν συνόλω· <br />
        γ) εντός της ουδετεροποιουμένης ζώνης του βουλγαρικού εδάφους, 2500
        άνδρας εν συνόλω. <br />
        Ο οπλισμός τούτων θα αποτελήται μόνον εκ του περιστρόφου, της σπάθης,
        του όπλου και τεσσάρων οπλοπολυβόλων ανά 100 άνδρας, αποκλειομένου
        παντός πυροβολικού. <br />
        Αι διατάξεις αύται δεν θίγουσι τας επιβληθείσας τη Βουλγαρία υποχρεώσεις
        δυνάμει της από 27 Νοεμβρίου 1919 Συνθήκης του Νεϊγύ. 3ον) Απαγορεύεται
        η υπεράνω της ουδετεροποιουμένης ζώνης πτήσις στρατιωτικών ή ναυτικών
        αεροπλοίων, οιανδήποτε σημαίαν και αν φέρωσι ταύτα.
        <br />
        <br />
        Άρθρον 4. <br />
        Εν η περιπτώσει μία των ομόρων Δυνάμεων, ης το έδαφος αναφέρεται εν τη
        παρούση Συμβάσει, ήθελε τυχόν διατύπωση παράπονα ως προς την τήρησιν των
        προηγουμένων διατάξεων, τα παράπονα ταύτα θέλουσιν υποβληθή υπ’ αυτής
        εις το Συμβούλιον της Κοινωνίας των Εθνών. <br />
        <br />
        Άρθρον 5. <br />
        Η ΠΑΡΟΥΣΑ ΣΥΜΒΑΣΙΣ επικυρωθήσεται. <br />
        Αι επικυρώσεις αυτής θέλουσι κατατεθή εν Παρισίοις, ως οίον τε τάχιον.{" "}
        <br />
        Άρξεται ισχύουσα ευθύς ως επικυρωθή υπό της Βουλγαρίας, της Ελλάδος και
        της Τουρκίας. Ειδικόν πρακτικόν θα πιστοποίηση τας επικυρώσεις ταύτας.
        Ως προς τας λοιπάς Δυνάμεις, αίτινες κατά την στιγμήν αυτήν δεν έχουσι
        τυχόν επικύρωση την Σύμβασιν, άρξεται αύτη ισχύουσα από της καταθέσεως
        των επικυρώσεων αυτών, κοινοποιηθησομένης προς τας λοιπάς συμβαλλομένας
        Δυνάμεις υπό της Κυβερνήσεως της Γαλλικής Δημοκρατίας. <br />
        Η Ιαπωνική Κυβέρνησις δύναται και απλώς να γνωστοποίηση εις την
        Κυβέρνησιν της Γαλλικής Δημοκρατίας, δια του εν Παρισίοις διπλωματικού
        αυτής αντιπροσώπου, ότι η επικύρωσις αυτής εγένετο, εν τοιαύτη δε
        περιπτώσει οφείλει να διαβιβάση αυτή, όσον το δυνατόν ταχύτερον, την
        πράξιν της επυκυρώσεως. <br />
        ΠΡΟΣ ΠΙΣΤΩΣΙΝ ΤΟΥΤΩΝ, οι ανωτέρω μνημονευόμενοι Πληρεξούσιοι υπέγραψαν
        την παρούσαν Σύμβασιν. <br />
        ΕΓΕΝΕΤΟ εν Λωζάννη, τη 24η Ιουλίου 1923, εις εν και μόνον αντίτυπον,
        όπερ κατατεθήσεται εν τοις αρχείοις της Κυβερνήσεως της Γαλλικής
        Δημοκρατίας, ήτις θέλει επιδώσει επίσημον αυτού αντίγραφαν προς εκάστην
        των υπογραψασών Δυνάμεων. <br />
        Horace RUMBOLD <br />
        PELLE <br />
        GARRONI <br />
        G. C. MONTAGNA <br />
        Κ. OTCHIAI <br />
        Β. MORPHOFF <br />
        STANCIOFF <br />
        Ε. Κ. ΒΕΝΙΖΕΛΟΣ Δ. ΚΑΚΛΑΜΑΝΟΣ <br />
        Const. DIAMANDY <br />
        Const. CONTZESCO <br />
        Μ. ISMET <br />
        Dr. RIZA NOUR <br />
        HASSAN <br />
        ΠΕΡΙ ΕΓΚΑΤΑΣΤΑΣΕΩΣ ΚΑΙ ΔΙΚΑΣΤΙΚΗΣ ΔΙΚΑΙΟΔΟΣΙΑΣ <br />
        ΚΕΦΑΛΑΙΟΝ Α’ <br />
        ΟΡΟΙ ΕΓΚΑΤΑΣΤΑΣΕΩΣ <br />
        ΤΜΗΜΑ Α’: ΕΙΣΟΔΟΣ ΚΑΙ ΔΙΑΜΟΝΗ (Άρθρον 2. – Άρθρον 7.)
        <br />
        ΤΜΗΜΑ Β’: ΦΟΡΟΛΟΓΙΚΑΙ ΔΙΑΤΑΞΕΙΣ (Άρθρον 8. – Άρθρον 13.) <br />
        ΚΕΦΑΛΑΙΟΝ Β’ <br />
        ΔΙΚΑΣΤΙΚΗ ΔΙΚΑΙΟΔΟΣΙΑ (Άρθρον 14. – Άρθρον 18.) <br />
        ΚΕΦΑΛΑΙΟΝ Γ’ <br />
        ΤΕΛΙΚΑΙ ΔΙΑΤΑΞΕΙΣ (Άρθρον 19. – Άρθρον 21.) <br />
        ΥΠΟΓΡΑΦΕΙΣΑ ΤΗ 24 ΙΟΥΛΙΟΥ 1923 <br />
        Η ΒΡΕΤΤΑΝΙΚΗ ΑΥΤΟΚΡΑΤΟΡΙΑ, Η ΓΑΛΛΙΑ, Η ΙΤΑΛΙΑ, Η ΙΑΠΩΝΙΑ, Η ΕΛΛΑΣ, Η
        ΡΟΥΜΑΝΙΑ, ΤΟ ΣΕΡΒΟ-ΚΡΟΑΤΟ-ΣΛΟΒΕΝΙΚΟΝ ΚΡΑΤΟΣ, αφ’ ενός <br />
        ΚΑΙ Η ΤΟΥΡΚΙΑ <br />
        αφ’ ετέρου <br />
        Επιθυμούσαι όπως κανονίσωσι συμφώνως προς το σύγχρονον Διεθνές Δίκαιον
        τους όρους εγκαταστάσεως εν Τουρκία των υπηκόων των λοιπών συμβαλλομένων
        Δυνάμεων και τους όρους εγκαταστάσεως των Τούρκων υπηκόων εν ταις χώραις
        των Δυνάμεων τούτων, ως και τίνα ζητήματα αφορώντα την δικαστικήν
        δικαιοδοσίαν, <br />
        Απεφάσισαν να συνομολογήσωσιν επί τούτω Σύμβασιν και διώρισαν ως
        οικείους αυτών Πληρεξουσίους:
        <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΟΥ ΗΝΩΜΕΝΟΥ ΒΑΣΙΛΕΙΟΥ ΤΗΣ ΜΕΓΑΛΗΣ
        ΒΡΕΤΤΑΝΙΑΣ ΚΑΙ ΤΗΣ ΙΡΛΑΝΔΙΑΣ ΚΑΙ ΤΩΝ ΥΠΕΡΘΑΛΑΣΣΙΩΝ ΒΡΕΤΤΑΝΙΚΩΝ ΧΩΡΩΝ
        ΑΥΤΟΚΡΑΤΩΡ ΤΩΝ ΙΝΔΙΩΝ:
        <br />
        Τον Εντιμότατσν Sir Horace George Montagu RUMBOLD, Baronet, G.C.M.G.,
        Ύπατον Αρμοστήν εν Κωνσταντινουπόλει· Ο ΠΡΟΕΔΡΟΣ ΤΗΣ ΓΑΛΛΙΚΗΣ
        ΔΗΜΟΚΡΑΤΙΑΣ:
        <br />
        Τον Μέραρχον Στρατηγόν κ. Maurice PELLE, Πρεσβευτήν της Γαλλίας, Ύπατον
        Αρμοστήν της Δημοκρατίας εν Ανατολή, Ανώτερον Ταξιάρχην Τάγματος της
        Λεγεώνας της Τιμής <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΗΣ ΙΤΑΛΙΑΣ:
        <br />
        Τον έντιμον Μαρκήσιον Camille GARRONI, Γερουσιαστήν του Βασιλείου,
        Πρεσβευτήν της Ιταλίας, Ύπατον Αρμοστήν εν Κωνσταντινουπόλει,
        Μεγαλόσταυρον των Ταγμάτων των Αγίων Μαυρικίου και Λαζάρου και του
        Στέμματος της Ιταλίας· <br />
        Τον Κύριον Jules Cesar MONTAGNA, ‘Εκτακτον Αποσταλμένον και Πληρεξούσιον
        Υπουργόν εν Αθήναις, Ταξιάρχην του Τάγματος των Αγ. Μαυρικίου και
        Λαζάρου, Ανώτερον Ταξιάρχην του Στέμματος της Ιταλίας. <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΑΥΤΟΚΡΑΤΩΡ ΤΗΣ ΙΑΠΩΝΙΑΣ:
        <br />
        Τον Κύριον Kentaro OTCHIAI, Jusammi, Μέλος πρώτης τάξεως του Τάγματος
        του Ανατέλλοντος Ηλίου, Έκτακτον και Πληρεξούσιον Πρεσβευτήν εν Ρώμη·{" "}
        <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΕΛΛΗΝΩΝ:
        <br />
        Τον Κύριον Ελευθέριον Κ. ΒΕΝΙΖΕΛΟΝ, Πρώην Πρόεδρον του Υπουργικού
        Συμβουλίου, Μεγαλόσταυρον του Τάγματος του Σωτήρος· <br />
        Τον Κύριον Δημήτριον ΚΑΚΛΑΜΑΝΟΝ, Πληρεξούσιον Υπουργόν εν Λονδίνω,
        Ταξιάρχην του Τάγματος του Σωτήρος· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΎΣ ΤΗΣ ΡΟΥΜΑΝΙΑΣ:
        <br />
        Τον Κύριον Constantin I. DIAMANTY, Πληρεξούσιον Υπουργόν· <br />
        Τον Κύριον Constantin CONTZESCO Πληρεξούσιον Υπουργόν· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΣΕΡΒΩΝ, ΤΩΝ ΚΡΟΑΤΩΝ ΚΑΙ ΤΩΝ ΣΛΟΒΕΝΩΝ:
        <br />
        Τον Δρα Miloutine YOVANOVITCH, ‘Εκτακτον Απεσταλμένον και Πληρεξούσιον
        Υπουργόν εν Βέρνη· <br />
        Η ΚΥΒΕΡΝΗΣΙΣ ΤΗΣ ΜΕΓΑΛΗΣ ΕΘΝΟΣΥΝΕΛΕΥΣΕΩΣ ΤΗΣ ΤΟΥΡΚΙΑΣ:
        <br />
        Τον ISMET Pacha, Υπουργόν επί των Εξωτερικών, Βουλευτήν Αδριανουπόλεως·{" "}
        <br />
        Τον Δρα RIZA NOUR Bey Υπουργόν της Υγιεινής και Κοινωνικής Περιθάλψεως,
        Βουλευτήν Σινώπης <br />
        Τον HASSAN Bey, πρώην Υπουργόν Βουλευτήν Τραπεζούντας. <br />
        ΟΙΤΙΝΕΣ, επιδείξαντες αλλήλοις τα πληρεξούσια αυτών έγγραφα, ευρεθέντα
        εν πλήρει τάξει και κατά τους απαιτούμενους τύπους, συνεφώνησαν επί των
        ακολούθων διατάξεων: <br />
        ΚΕΦΑΛΑΙΟΝ Α’ <br />
        ΟΡΟΙ ΕΓΚΑΤΑΣΤΑΣΕΩΣ <br />
        <br />
        Άρθρον 1. <br />
        Η εν Τουρκία εφαρμογή εκάστης των διατάξεων του παρόντος Κεφαλαίου επί
        των υπηκόων και εταιρειών των λοιπών συμβαλλομένων Δυνάμεων εξαρτάται εκ
        του ρητού όρου πλήρους αμοιβαιότητας παρεχομένης εις του υπηκόους και
        εταιρείας της Τουρκίας εν ταις Χώραις των ειρημένων Δυνάμεων. Εν η
        περιπτώσει μία των Δυνάμεων τούτων ήθελεν αρνηθή, δυνάμει των νόμων
        αυτής ή άλλου τινός λόγου, όπως παράσχη την αμοιβαιότητα ως προς
        οιανδήποτε των εν λόγω διατάξεων, οι υπήκοοι και αι εταιρείαι αυτής δεν
        θα δύνανται να επωφεληθώσιν εν Τουρκία της διατάξεως ταύτης. Εν τη
        εφαρμογή του παρόντος άρθρου, αι υπό την προστασίαν ή εξουσίαν των
        λοιπών συμβαλλομένων Δυνάμεων υπαγόμενοι Κτήσεις (Dominions), αποικίαι
        και χώραι θα θεωρώνται εκάστη ιδιαιτέρως ως ιδία συμβαλλομένη Χώρα.{" "}
        <br />
        <br />
        ΤΜΗΜΑ Α’ <br />
        ΕΙΣΟΔΟΣ ΚΑΙ ΔΙΑΜΟΝΗ <br />
        Άρθρον 2. <br />
        <br />
        Εν Τουρκία οι υπήκοοι των λοιπών συμβαλλομένων Δυνάμεων θα γίνωνται
        δεκτοί συμφώνως προς το κοινόν Διεθνές Δίκαιον και θα διέπωνται υπ’
        αυτού αυτοί τε και αι περιουσίαι των. Θα απολαύωσιν ως προς εαυτούς, τας
        περιουσίας, δικαιώματα και συμφέροντα αυτών της πληρεστέρας και
        διαρκεστέρας προστασίας των εγχωρίων νόμων και Αρχών. Μη θιγομένων των
        περί μεταναστεύσεως διατάξεων, θα έχωσι πλήρη ελευθερίαν εισόδου και
        εγκαταστάσεως εν Τουρκία και θα δύνανται, κατά συνέπειαν, να μεταβαίνωσι
        και διαμένωσιν εν αυτή, συμμορφούμενοι προς τους ισχύοντας νόμους και
        κανονισμούς. <br />
        <br />
        Άρθρον 3. <br />
        Οι υπήκοοι των λοιπών συμβαλλομένων Δυνάμεων θα έχωσι το δικαίωμα εν
        Τουρκία να αποκτώσι, κατέχωσι και απαλλοτριώσι παν είδος κινητής και
        ακινήτου περιουσίας, συμμορφούμενοι προς τους νόμους και κανονισμούς της
        χώρας· θα δύνανται ιδία να διαθέτωσι ταύτην δια πωλήσεως, ανταλλαγής,
        δωρεάς, διαθηκικής διατάξεως ή δια παντός άλλου τρόπου, ως και να
        διακατέχωσι ταύτην συνεπεία διαδοχής εκ του νόμου ή συνεπεία διατάξεως
        μεταξύ ζώντων ή εκ διαθήκης. <br />
        <br />
        Άρθρον 4 <br />
        Η εν Τουρκία παραδοχή των υπηκόων των λοιπών συμβαλλομένων Δυνάμεων εις
        τα διάφορα είδη εμπορίου, επαγγέλματος ή βιομηχανίας και αντιστοίχως η
        εν τω εδάφει των ειρημένων Δυνάμεων παραδοχή των τούρκων υπηκόων εις τα
        διάφορα είδη εμπορίου, επαγγέλματος ή βιομηχανίας, θα αποτελέσωσιν
        αντικείμενον ιδιαιτέρων συμβάσεων συνομολογηθησομένων μεταξύ της
        Τουρκίας και των Δυνάμεων τούτων εντός δώδεκα μηνών από της ενάρξεως της
        ισχύος της παρούσης Συμβάσεως. <br />
        Εννοείται ότι μέχρι της συνομολογήσεως των εν λόγω συμβάσεων θα
        διατηρηθή το καθεστώς της 1ης Ιανουαρίου 1923, μη συνομολογουμένης δε
        τοιαύτης συμβάσεως μέχρι της λήξεως της ειρημένης δωδεκαμήνου
        προθεσμίας, εκάστη των συμβαλλομένων Δυνάμεων θέλει αναλάβει την
        ελευθερίαν ενεργείας αυτής, υπό την επιφύλαξιν του σεβασμού των υπό των
        ιδιωτών κεκτημένων δικαιωμάτων κατά την 1ην Ιανουαρίου 1923. <br />
        <br />
        Άρθρον 5. <br />
        Αι κανονικώς συστηθείσαι εν τη χώρα μιας οιασδήποτε των λοιπών
        συμβαλλομένων Δυνάμεων εμπορικαί, βιομηχανικαί ή δημοσιονομικαί
        εταιρείαι, ως και αι εταιρείαι μεταφορών ή ασφαλειών αναγνωρίζονται εν
        Τουρκία. <br />
        Δια παν ό,τι αφορά εις την σύστασιν αυτών, την ικανότητα και το δικαίωμα
        του ενάγειν ενώπιον των δικαστηρίων θα διέπωνται υπό του νόμου της χώρας
        αυτών. <br />
        Αι εταιρείαι αύται θα δύνανται να εγκαθίστανται εν Τουρκία και να
        διενεργώσιν εν αυτή παν είδος εμπορίου και βιομηχανίας, όπερ δύνανται να
        μετέρχωνται οι υπήκοοι της χώρας εν η συνεστήθησαν, εφ’ όσον δεν
        απαγορεύεται εν Τουρκία εις τας τουρκικάς εταιρείας. Θα δύνανται να
        διεξαγάγωσιν ελευθέρως τας εργασίας αυτών, υπό την επιφύλαξιν της
        τηρήσεως των διατάξεων δημοσίας τάξεως, υπό την έποψιν δε ταύτην θα
        απολαύωσι των αυτών δικαιωμάτων, ων απολαύει πάσα ομοίας φύσεως τουρκική
        εταιρεία. <br />
        θα έχωσι το δικαίωμα να αποχτώσι, διακατέχωσι και απαλλοτριώσι παν είδος
        κινητής περιουσίας, συμμορφούμενοι προς τους νόμους και κανονισμούς της
        χώρας· το δικαίωμα τούτο θα έχωσιν ωσαύτως ως προς την ακίνητον
        περιουσίαν την αναγκαιούσαν δια την λειτουργίαν της εταιρείας, υπό την
        προϋπόθεσιν όμως ότι, εν τοιαύτη περιπτώσει, η απόκτησις της ακινήτου
        περιουσίας δεν αποτελεί το αντικείμενον της εταιρείας. <br />
        <br />
        Άρθρον 6. <br />
        Οι υπήκοοι των λοιπών συμβαλλομένων Δυνάμεων δεν θα υπάγωνται εν Τουρκία
        εις τους αφορώντας την στρατιωτικήν υπηρεσίαν νόμους, θα απαλλάσσωνται
        πάσης υπηρεσίας και υποχρεώσεως ή αντισηκώματος αντικαθιστώντας την
        στρατιωτικήν υπηρεσίαν. <br />
        Δεν θα δύνανται να αποξενωθώσι της περιουσίας αυτών ή να αποστερηθώσιν,
        έστω και προσωρινώς, της χρήσεως αυτής, ειμή μόνον δια δημοσίαν ωφέλειαν
        νομίμως αναγνωριζομένην και έναντι προηγουμένης δικαίας αποζημιώσεως.
        Ουδεμία απαλλοτρίωση δύναται να λαβή χωράν άνευ προηγουμένης
        δημοσιότητος. <br />
        <br />
        Άρθρον 7. <br />
        Η Τουρκία επιφυλάσσει εαυτή το δικαίωμα να απελαύνη δι’ ατομικών μέτρων,
        λαμβανομένων είτε κατόπιν νομίμου αποφάσεως, είτε συμφώνως προς τους
        νόμους ή κανονισμούς επί των ηθών, της υγείας ή επαιτείας, είτε δια
        λόγους εσωτερικής ή εξωτερικής ασφαλείας του Κράτους, τους υπηκόους των
        λοιπών συμβαλλομένων Δυνάμεων, ους υποχρεούνται αύται να δέχωνται, εν
        παντί καιρώ, μετά των οικογενειών των. Η απέλασις θα λαμβάνη χωράν υπό
        συνθήκας σύμφωνους προς την υγιεινήν και τον ανθρωπισμόν. <br />
        <br />
        ΤΜΗΜΑ Β’ <br />
        <br />
        ΦΟΡΟΛΟΓΙΚΑΙ ΔΙΑΤΑΞΕΙΣ <br />
        <br />
        Άρθρον 8. <br />
        Οι υπήκοοι των λοιπών συμβαλλομένων Δυνάμεων δεν θα υποβάλλωνται εις
        ουδένα φόρον, τέλος ή δασμόν, οιασδήποτε φύσεως ή ονομασίας, διάφορον ή
        επαχθέστερον εκείνου εις ον υποβάλλονται οι τούρκοι υπήκοοι, λόγω της εν
        Τουρκία διαμονής ή εγκαταστάσεως αυτών, ως και της εν αυτή εξασκήσεως
        παντός είδους εμπορίας, επαγγέλματος, βιομηχανίας, εκμεταλλεύσεως ή
        πάσης φύσεως εργασίας, επιτρεπομένων αυτοίς κατά τους εν άρθρω 4
        προβλεπομένους όρους. <br />
        Οι υπήκοοι των ειρημένων Δυνάμεων, οι εγκατεστημένοι εν τω εξωτερικώ
        οίτινες, διερχόμενοι δια του τουρκικού εδάφους, επιχειρούσιν εν αυτώ
        οιανδήποτε εργασίαν, δεν θα υποβάλλωνται εις ουδένα φόρον, τέλος ή
        δασμόν, οιασδήποτε φύσεως ή ονομασίας, διάφορον ή επαχθέστερον εκείνου
        εις όν υποβάλλονται οι τούρκοι υπήκοοι ή οι εγκατεστημένοι εν Τουρκία,
        ξένοι δι’ ομοίας φύσεως και σημασίας εργασίαν κατά τας ισχύουσας εν
        Τουρκία φορολογικάς διατάξεις. <br />
        Αι εν Τουρκία περιουσίαι, δικαιώματα και συμφέροντα των υπηκόων των
        ειρημένων Δυνάμεων δεν θα υποβάλλωνται εις ουδένα φόρον, άμεσον ή
        έμμεσον, τέλος ή δασμόν διάφορον ή ανώτερον του επιβαλλομένου επί των
        περιουσιών, δικαιωμάτων και συμφερόντων των τούρκων υπηκόων, είτε κατά
        την απόκτησιν, διακατοχήν και χρήσιν των ειρημένων περιουσιών, είτε κατά
        την μετάθεσιν αυτών δι’ εκχωρήσεως, μεταβιβάσεως ή κληρονομιάς. <br />
        <br />
        Άρθρον 9. <br />
        Αι εμπορικαί, βιομηχανικαί ή δημοσιονομικαί εταιρείαι, ως και αι
        εταιρείαι μεταφορών ή ασφαλειών, αίτινες, συστηθείσαι κατά τον νόμον
        μιας των λοιπών συμβαλλομένων Χωρών, εγκαθίστανται εν Τουρκία ή
        διενεργούσιν εν αυτή τας εργασίας των κατά τους εν τω άρθρω 5
        προβλεπομένους όρους, δεν θα υποβάλλωνται εις ουδένα φόρον, τέλος ή
        δασμόν, οιασδήποτε φύσεως ή ονομασίας, εφ’ όσον δεν υποβάλλονται αι
        ομοίας φύσεως εταιρείαι, αι συστηθείσαι κατά τον τουρκικόν νόμον. <br />
        Αι αυταί διατάξεις εφαρμόζονται εις τα αυτοτελή υποκαταστήματα
        (filiales), υποκαταστήματα, πρακτορεία και λοιπάς αντιπροσωπείας οίκων ή
        εταιρειών των ειρημένων χωρών, άτινα εγκαθίστανται ή διενεργούσιν εν
        Τουρκία τας εργασίας των κατά τους εν άρθρω 5 προβλεπομένους όρους,
        εξυπακουομένου ότι, εάν η διεύθυνσις των οίκων ή εταιρειών τούτων
        ευρίσκεται εκτός της Τουρκίας, τα ειρημένα αυτοτελή υποκαταστήματα,
        υποκαταστήματα, πρακτορεία και αντιπροσωπείαι θα φορολογώνται μόνον δια
        το πραγματικώς εν Τουρκία χρησιμοποιούμενον κεφάλαιον ή δια τα εν αυτή
        πράγματι κτηθέντα κέρδη και προσόδους· τα κέρδη ταύτα και αι πρόσοδοι θα
        χρησιμεύωσι προς καθορισμόν του φορολογητέου κεφαλαίου, εάν δεν δύναται
        τούτο να εξακριβωθή. <br />
        <br />
        Άρθρον 10. <br />
        Εάν η Τουρκική Κυβέρνησις θεσπίση απαλλαγάς από των φορολογικών
        υποχρεώσεων, οιασδήποτε φύσεως ή ονομασίας, αι απαλλαγαί αύται θέλουσι
        παρασχεθή εις τους εγκατεστημένους εν Τουρκία υπηκόους ή εταιρείας των
        λοιπών συμβαλλομένων Χωρών, εφ’ όσον παρέχονται και εις τους τούρκους
        υπηκόους ή τας εταιρείας τας συστηθείσας κατά τον τουρκικόν νόμον.{" "}
        <br />
        Την διάταξιν ταύτην ουδείς δύναται να επικαλεσθή όπως αιτήσηται το
        ευεργέτημα της εκ των φόρων απαλλαγής, της παρεχομένης εις τα υπό του
        Κράτους ιδρυθέντα καθιδρύματα ή εις εκδοχείς παραχωρήσεως δημοσίας
        υπηρεσίας. <br />
        <br />
        Άρθρον 11. <br />
        Επί των εν τοις άρθροις 8-10 αναφερομένων θεμάτων, οι επιβαλλόμενοι εν
        Τουρκία εις τους υπηκόους των λοιπών συμβαλλομένων Χωρών επαρχιακοί ή
        τοπικοί φόροι, δασμοί, τέλη δεν θα είναι διάφοροι ή ανώτεροι των
        επιβαλλομένων εις τους τούρκους υπηκόους. <br />
        <br />
        Άρθρον 12. <br />
        Ουδέν αναγκαστικόν δάνειον ή άλλη εξαιρετική φορολογία επί της
        περιουσίας θέλει επιβληθή εν Τουρκία, και εν περιπτώσει έτι πολέμου, εις
        τους εγκατεστημένους ή εργαζομένους εν αυτή υπηκόους των λοιπών
        συμβαλλομένων Χωρών, επί των περιουσιών, δικαιωμάτων και συμφερόντων,
        των ευρισκομένων εν τω τουρκικώ εδάφει, ως και επί των εταιρειών,
        αυτοτελών υποκαταστημάτων, υποκαταστημάτων ή πρακτορείων, άτινα,
        συστηθέντα κατά τον νόμον μιας των ειρημένων χωρών, εγκαθίστανται ή
        διενεργούσιν εν Τουρκία τας εργασίας αυτών. <br />
        <br />
        Άρθρον 13. <br />
        Καταργηθεισών των Διομολογήσεων, η Τουρκία δεν θα παρέχη εις τους
        υπηκόους των ξένων Δυνάμεων μείζονα προστασίαν εκείνης, ης απολαύουσιν
        οι υπήκοοι αυτής, όσον αφορά δε τα εν τω παρόντι Τμήματι αναφερόμενα
        θέματα, θα εφαρμόζη εις τους υπηκόους αυτής ως και εις τους υπηκόους των
        λοιπών συμβαλλομένων Δυνάμεων την αρχήν της ισότητος. <br />
        ΚΕΦΑΛΑΙΟΝ Β’ <br />
        <br />
        ΔΙΚΑΣΤΙΚΗ ΔΙΚΑΙΟΔΟΣΙΑ <br />
        <br />
        Άρθρον 14. <br />
        Οι εν Τουρκία υπήκοοι των λοιπών συμβαλλομένων Δυνάμεων και αντιστοίχως
        οι εν ταις χώραις των εν λόγω Δυνάμεων τούρκοι υπήκοοι θα παρίστανται
        ελευθέρως ενώπιον των ιθαγενών δικαστηρίων και θα δύνανται να ενάγωσι
        και ενάγωνται υπό τους αυτούς κατά πάντα όρους υφ’ ους και οι ημεδαποί,
        υπό την επιφύλαξιν των διατάξεων του άρθρου 18. <br />
        <br />
        Άρθρον 15. <br />
        Υπό την επιφύλαξιν του άρθρου 16, τα ζητήματα της δικαστικής
        δικαιοδοσίας, εν ταις σχέσεσι μεταξύ της Τουρκίας και των λοιπών
        συμβαλλομένων Δυνάμεων, θα κανονίζωνται εφ’ όλων των θεμάτων, συμφώνως
        προς τας αρχάς του Διεθνούς Δικαίου. <br />
        <br />
        Άρθρον 16. <br />
        Μεταξύ της Τουρκίας και των λοιπών συμβαλλομένων Δυνάμεων καθορίζεται,
        ότι, επί των θεμάτων της προσωπικής καταστάσεως: τουτέστιν εφ’ όλων των
        ζητημάτων των αφορώντων τον γάμον και την συζυγικήν κοινοκτημοσύνην, το
        διαζύγιον, τον χωρισμόν από τραπέζης και κοίτης, την προίκα, πατρότητα,
        υιότητα, υιοθεσίαν, ικανότητα των προσώπων, ενηλικιότητα, επιτροπείαν,
        κηδεμονίαν, απαγόρευσιν επί κινητών: το δικαίωμα της εκ διαθήκης ή εξ
        αδιαθέτου διαδοχής, αι διανομαί και εκκαθαρίσεις, και εν γένει επί
        θεμάτων οικογενειακού δικαίου, μόνα αρμόδια, έναντι των εγκατεστημένων ή
        ευρισκομένων εν Τουρκία μη μουσουλμάνων υπηκόων των ειρημένων Δυνάμεων,
        θα είναι τα ιθαγενή δικαστήρια ή άλλοι ιθαγενείς Αρχαί εδρεύουσαι εν τη
        χώρα του διαδίκου, ούτινος η προσωπική κατάστασις είνε επίδικος. <br />
        Η παρούσα διάταξις δεν θίγει την ειδικήν δικαιοδοσίαν των προξένων επί
        των ληξιαρχικών πράξεων, συμφώνως προς το διεθνές δίκαιον ή τας
        συναφθησομένας τυχόν ιδιαιτέρας συμφωνίας, ουδ’ έτι το δικαίωμα των
        τουρκικών δικαστηρίων όπως αιτώνται και λαμβάνωσι τας αποδείξεις
        σχετικώς προς τα ζητήματα, τα ανωτέρω αναγνωριζόμενα ως υπαγόμενα εις
        την δικαιοδοσίαν των ιθαγενών δικαστηρίων ή άλλων ιθαγενών Αρχών των
        διαδίκων. Κατ’ εξαίρεσιν του πρώτου εδαφίου, τα τουρκικά δικαστήρια
        δύνανται ωσαύτως να κηρυχθώσιν αρμόδια ως προς τα εν τη ειρημένη
        παραγράφω αναφερόμενα θέματα, εάν πάντες οι διάδικοι υπαχθώσιν εγγράφως
        εις την δικαιοδοσίαν των δικαστηρίων τούτων, άτινα θα δικάζωσι κατά τον
        νόμον της χώρας των διαδίκων. <br />
        <br />
        Άρθρον 17. <br />
        Η Τουρκική Κυβέρνησις δηλοί ότι θέλει εξασφαλίσει εις τους εν Τουρκία
        ξένους, όσον αφορά εαυτούς και τας περιουσίας των, ενώπιον των τουρκικών
        δικαστηρίων, προστασίαν σύμφωνον προς το Διεθνές Δίκαιον ως και προς τας
        αρχάς και μεθόδους τας γενικώς παραδεδεγμένας εν ταις άλλαις χώραις.{" "}
        <br />
        <br />
        Άρθρον 18. <br />
        Παν ζήτημα σχετικόν προς την εγγυοδοσίαν αλλοδαπού (judicatum solvi),
        την εκτέλεσιν των αποφάσεων, την κοινοποίησιν των δικαστικών και
        εξωδίκων πράξεων, τας παραγγελίας, την καταδίκην εις τα έξοδα και τέλη,
        την ατελή δικαστικήν αρωγήν και την προσωπικήν κράτησιν θέλει
        διακανονιστή, εν ταις μεταξύ της Τουρκίας και των λοιπών συμβαλλομένων
        Δυνάμεων σχέσεσιν, δι’ ειδικών συμβάσεων μεταξύ των ενδιαφερομένων
        Κρατών.
        <br />
        <br />
        ΚΕΦΑΛΑΙΟΝ Γ’ <br />
        <br />
        ΤΕΛΙΚΑΙ ΔΙΑΤΑΞΕΙΣ <br />
        <br />
        Άρθρον 19. <br />
        Αι συμβαλλόμεναι Δυνάμεις επιφυλάσσονται το δικαίωμα όπως δηλώσωσι κατά
        την έναρξιν της ισχύος της παρούσης Συμβάσεως, ότι αι διατάξεις της
        Συμβάσεως ταύτης δεν θέλουσιν εφαρμοσθή επί του όλου ή μέρους των
        Κτήσεων αυτών (Dominions), των διατελουσών υπό αυτόνομον κυβέρνησιν, των
        αποικιών αυτών, προτεκτοράτων, κτήσεων ή υπερθαλασσίων εδαφών, των
        υπαγομένων υπό την κυριαρχίαν ή εξουσίαν αυτών, εν τοιαύτη δε
        περιπτώσει, η Τουρκία θέλει απαλλαγή των εκ της παρούσης Συμβάσεως
        απορρεουσών δι’ αυτήν υποχρεώσεων έναντι των ειρημένων Κτήσεων
        (Dominions), αποικιών, προτεκτοράτων, κτήσεων και εδαφών. <br />
        Ουχ’ ήττον, αι ειρημέναι Δυνάμεις θα δύνανται να προσχωρήσωσι
        μεταγενεστέρως, εν ονόματι πάσης Κτήσεως διατελούσης υπό αυτόνομον
        κυβέρνησιν, αποικίας, προτεκτοράτου, κτήσεως ή εδάφους, δι’ ά είχον
        τυχόν προβή εις δήλωσιν αποκλείουσαν ταύτα κατά τους όρους της παρούσης
        Συμβάσεως. <br />
        <br />
        Άρθρον 20. <br />
        Η παρούσα Σύμβασις συνομολογείται δια χρονικήν περίοδον επτά ετών από
        της ενάρξεως της ισχύος αυτής. <br />
        <br />
        Εάν η Σύμβασις δεν καταγγελθή υφ’ ενός οιουδήποτε των Υψηλών
        Συμβαλλομένων Μερών εν έτος τουλάχιστον προ της λήξεως της περιόδου
        ταύτης, θα παραμείνη εν ισχύι μέχρις ου καταγγελθή η καταγγελία αύτη θα
        παραγάγη τα αποτελέσματα αυτής μετά την πάροδον προθεσμίας ενός έτους.{" "}
        <br />
        Εάν η Σύμβασις καταγγελθή υπό μιας οιασδήποτε των συμβαλλομένων Δυνάμεων
        εκτός της Τουρκίας, η καταγγελία αύτη θα ισχύση μόνον μεταξύ της
        Δυνάμεως ταύτης και της Τουρκίας. <br />
        Η Τουρκία θα δικαιούται να καταγγείλη την Σύμβασιν είτε ως προς πάσας
        τας συμβαλλομένας Δυνάμεις, είτε μόνον ως προς μίαν εξ αυτών, εν τη
        τελευταία δε ταύτη περιπτώσει, η Σύμβασις θα παραμείνη εν ισχύι έναντι
        των λοιπών Δυνάμεων. <br />
        <br />
        Άρθρον 21. <br />
        Η ΠΑΡΟΥΣΑ ΣΥΜΒΑΣΙΣ επικυρωθήσεται. <br />
        Αι επικυρώσεις αυτής θέλουσι κατατεθή, ως οίον τε τάχιον, εν Παρισίοις.{" "}
        <br />
        Άρξεται ισχύουσα υφ’ ους όρους και η υπό σημερινήν χρονολογίαν Συνθήκη
        Ειρήνης. <br />
        ΠΡΟΣ ΠΙΣΤΩΣΙΝ ΤΟΥΤΩΝ, οι ανωτέρω μνημονευόμενοι Πληρεξούσιοι υπέγραφαν
        την παρούσαν Σύμβασιν.
        <br />
        ΕΓΕΝΕΤΟ εν Λωζάννη, τη 24η Ιουλίου 1923, εις εν και μόνον αντίτυπον,
        όπερ κατατιθήσεται εv τοις αρχείοις της Κυβερνήσεως της Γαλλικής
        Δημοκρατίας, ήτις θέλει επιδώσει επίσημον αυτού αντίγραφον προς εκάστην
        των υπογραψασών Δυνάμεων. <br />
        Horace RUMBOLD <br />
        PELLE <br />
        GARRONI <br />
        G.C. MONTAGNA <br />
        Κ. OTCHIAI <br />
        Ε.Κ. ΒΕΝΙΖΕΛΟΣ <br />
        Δ.ΚΑΚΛΑΜΑΝΟΣ <br />
        Const. DIAMANDY <br />
        Const. CONTZESCO <br />
        Μ. ISMET <br />
        Dr. RIZA NOUR <br />
        HASSAN <br />
        ΠΕΡΙ ΑΝΤΑΛΛΑΓΗΣ ΤΩΝ ΕΛΛΗΝΙΚΩΝ ΚΑΙ ΤΟΥΡΚΙΚΩΝ ΠΛΗΘΥΣΜΩΝ <br />
        ΚΑΙ ΠΡΩΤΌΚΟΛΛΟΝ ΥΠΟΓΡΑΦΕΝΤΑ ΤΗ 30 ΙΑΝΟΥΑΡΙΟΥ 1923 <br />
        Η ΚΥΒΕΡΝΗΣΙΣ ΤΗΣ ΜΕΓΑΛΗΣ ΕΘΝΟΣΥΝΕΛΕΥΣΕΩΣ ΤΗΣ ΤΟΥΡΚΙΑΣ ΚΑΙ <br />
        Η ΕΛΛΗΝΙΚΗ ΚΥΒΕΡΝΗΣΙΣ συνεφώνησαν επί των ακολούθων όρων. <br />
        <br />
        Άρθρον 1. <br />
        Από της 1ης Μαΐου 1923, θέλει διενεργηθή η υποχρεωτική ανταλλαγή των
        Τούρκων υπηκόων, ελληνικού ορθοδόξου θρησκεύματος, των εγκατεστημένων
        επί των τουρκικών εδαφών, και των Ελλήνων υπηκόων, μουσουλμανικού
        θρησκεύματος, των εγκατεστημένων επί των ελληνικών εδαφών. <br />
        Τα πρόσωπα ταύτα δεν θα δύνανται να έλθωσιν ίνα εγκατασταθώσιν εκ νέου
        εν Τουρκία ή αντιστοίχως εν Ελλάδι, άνευ της αδείας της Τουρκικής
        Κυβερνήσεως ή αντιστοίχως της Ελληνικής Κυβερνήσεως. <br />
        Άρθρον 2. <br />
        <br />
        Δεν θα περιληφθώσιν εις την εν τω πρώτω άρθρω προβλεπομένην ανταλλαγήν:{" "}
        <br />
        α) οι Έλληνες κάτοικοι της Κωνσταντινουπόλεως· <br />
        β) οι Μουσουλμάνοι κάτοικοι της Δυτικής Θράκης. <br />
        Θέλουσι θεωρηθή ως Έλληνες κάτοικοι της Κωνσταντινουπόλεως, πάντες οι
        Έλληνες οι εγκατεστημένοι ήδη προς της 30ης Οκτωβρίου 1918, εν τη
        περιφέρεια της Νομαρχίας Κωνσταντινουπόλεως, ως αύτη καθορίζεται δια του
        νόμου του 1912. <br />
        Θέλουσι θεωρηθή ως μουσουλμάνοι κάτοικοι της Δυτικής Θράκης, πάντες οι
        Μουσουλμάνοι οι εγκατεστημένοι εν τη περιοχή ανατολικώς της μεθορίου
        γραμμής της καθορισθείσης τω 1913 δια της Συνθήκης του Βουκουρεστίου.{" "}
        <br />
        <br />
        Άρθρον 3. <br />
        Οι Έλληνες και οι Μουσουλμάνοι, οι εγκαταλείψαντες ήδη από της 18ης
        Οκτωβρίου 1912 τα εδάφη, ων οι ‘Έλληνες και τούρκοι κάτοικοι θέλουσιν
        αμοιβαίως ανταλλαγή, θα θεωρηθώσι περιλαμβανόμενοι εν τη ανταλλαγή τη
        προβλεπομένη εν τω 1 άρθρω. <br />
        Η έκφρασις “μετανάστης” εν τη παρούση Συμβάσει, περιλαμβάνει πάντα τα
        φυσικά και νομικά πρόσωπα τα μέλλοντα να μεταναστεύσωσιν ή έχοντα
        μεταναστεύσει από της 18ης Οκτωβρίου 1912. <br />
        <br />
        Άρθρον 4. <br />
        Πάντες οι ικανοί άρρενες (hommes valides), οι ανήκοντες εις τον
        ελληνικόν πληθυσμόν, ων αι οικογένειαι εγκατέλειψαν ήδη το τουρκικόν
        έδαφος, οι κρατούμενοι νυν εν Τουρκία, θα αποτελέσωσι το πρώτον τμήμα εξ
        Ελλήνων οίτινες θα σταλώσιν εις Ελλάδα συμφώνως τη παρούση Συμβάσει.{" "}
        <br />
        <br />
        Άρθρον 5. <br />
        Υπό την επιφύλαξιν των διατάξεων των άρθρων 9 και 10 της παρούσης
        Συμβάσεως, τα δικαιώματα ιδιοκτησίας και αι απαιτήσεις των εν Τουρκία
        Ελλήνων ή των εν Ελλάδι Μουσουλμάνων, ουδόλως θέλουσι θιγή συνεπεία της
        γενησομένης δυνάμει της παρούσης Συμβάσεως ανταλλαγής. <br />
        <br />
        Άρθρον 6. <br />
        Ουδέν κώλυμα θέλει παρεμβληθή, δι’ οιανδήποτε αίτιαν, ως προς την
        αναχώρησιν προσώπων ανηκόντων εις τους ανταλλακτέους πληθυσμούς. Εν
        περιπτώσει τελεσιδίκου καταδίκης εις επανορθωτικήν ποινήν και εν
        περιπτώσει μη οριστικής καταδίκης ή ποινικής διώξεως κατά μετανάστου, ο
        τελευταίος ούτος θέλει παραδοθή υπό των Αρχών της διωκούσης χώρας, εις
        τας Αρχάς της εις ην μεταβαίνει χώρας, ίνα εκτίση την ποινήν του ή
        δικασθή. <br />
        <br />
        Άρθρον 7. <br />
        Οι μετανάσται θέλουσιν αποβάλει την ιθαγένειαν της εγκαταλειπομένης παρ’
        αυτών χώρας και αποκτήσει την της χώρας προς ην κατευθύνονται, άμα τη
        αφίξει των εις το έδαφος ταύτης. <br />
        Οι μετανάσται, οι εγκαταλείψαντες ήδη την μεν ή την ετέραν των δύο χωρών
        και μη αποκτήσαντες εισέτι την νέον αυτών υπηκοότητα, θέλουσιν αποκτήσει
        την υπηκοότητα ταύτην κατά την χρονολογίαν της υπογραφής της παρούσης
        Συμβάσεως. <br />
        <br />
        Άρθρον 8. <br />
        Οι μετανάσται θα ώσιν ελεύθεροι να συναποκομίσωσιν ή να μεταφέρωσι την
        πάσης φύσεως κινητήν αυτών περιουσίαν χωρίς δια τον λόγον τούτον να τοις
        επιβληθή τέλος τι εξαγωγικόν ή εισαγωγικόν ούτε άλλος τις φόρος. <br />
        Ομοίως τα μέλη πάσης κοινότητος (περιλαμβανομένου του προσωπικού των
        τεμενών (τζαμιών), μοναστηριών (τεκκέ), ιερατικών σχολών (μεδρεσέ),
        εκκλησιών, μοναστηρίων, σχολείων, νοσοκομείων, εταιρειών, συνεταιρισμών
        και νομικών προσώπων ή άλλων ιδρυμάτων οιασδήποτε φύσεως), ήτις θέλει
        εγκαταλείψει το έδαφος ενός των συμβαλλομένων Κρατών, δυνάμει της
        παρούσης Συμβάσεως, θα έχωσι το δικαίωμα να συναποκομίσωσιν ελευθέρως ή
        να μεταφέρωσι την εις την εαυτών κοινότητα ανήκουσαν κινητήν αυτών
        περιουσίαν. <br />
        Υπό των Αρχών των δύο χωρών θέλει παρασχεθή πάσα ευκολία μεταφοράς, τη
        συστάσει της Μικτής Επιτροπής, της προβλεπομένης υπό του άρθρου 11. Οι
        μετανάσται οίτινες δεν ήθελον δυνηθή να συναποκομίσωσι πάσαν ή μέρος της
        κινητής αυτών περιουσίας δύνανται ν’ αφήσωσι ταύτην επί τόπου. Εν τη
        περιπτώσει ταύτη, αι τοπικαί αρχαί υποχρεούνται να καθορίσωσι,
        παρισταμένου και του μετανάστου, την άξιον της παρ’ αυτών
        εγκαταλειπομένης κινητής περιουσίας. Τα πρωτόκολλα, περιέχοντα τον
        κατάλογον και την άξιον της υπό του μετανάστου εγκαταλειπομένης κινητής
        περιουσίας, θα συντάσσωνται εις 4 αντίτυπα, ων το εν θα φυλάσσηται παρά
        των τοπικών Αρχών, το δεύτερον θα παραδίδηται εις την εν τω άρθρον 11
        προβλεπομένην Μικτήν επιτροπήν ίνα χρησιμεύση ως βάσις δια την εν τω
        άρθρω 9 προβλεπομένην εκκαθάρισιν, το τρίτον αντίτυπον θα παραδίδηται
        εις την Κυβέρνησιν της εις ην μεταναστεύει χώρας και το τέταρτον εις τον
        μετανάστην. <br />
        <br />
        Άρθρον 9. <br />
        Η ακίνητος αγροτική ή αστική περιουσία, η ανήκουσα εις τους μετανάστας,
        εις τας εν τω άρθρω 9 αναφερομένας κοινότητας, ως και η παρά των
        μεταναστών ή των κοινοτήτων εγκαταλειπομένη κινητή περιουσία, θέλουσιν
        εκκαθαρισθή συμφώνως προς τας κατωτέρω διατάξεις υπό των μικτών
        επιτροπών των προβλεπομένων εν άρθρω 11. <br />
        Αι περιουσίαι, αι κείμεναι εις τα εδάφη τα υπαγόμενα εις την
        υποχρεωτικήν ανταλλαγήν, ανήκουσαι δε εις εκκλησιαστικά ή εις ευαγή
        καθιδρύματα κοινοτήτων εγκατεστημένων εις έδαφος μη υπαγόμενον εις την
        ανταλλαγήν, ανήκουσαι δε εις εκκλησιαστικά ή εις ευαγή καθιδρύματα
        κοινοτήτων εγατεστημένων εις έδαφος μη υπαγόμενον εις την ανταλλαγήν,
        θέλουσιν ομοίως εκκαθαρισθή υπό τους αυτούς όρους. <br />
        <br />
        Άρθρον 10. <br />
        Η εκκαθάρισις της κινητής και ακινήτου περιουσίας της ανηκούσης εις
        πρόσωπα εγκαταλείψαντα ήδη τα εδάφη των Υψηλών Συμβαλλομένων Μερών,
        θεωρούμενα δε δυνάμει του άρθρου 3 της παρούσης Συμβάσεως ως υπαγόμενα
        εις την ανταλλαγήν των πληθυσμών, θέλει συντελεσθή συμφώνως προς το
        άρθρον 9 και ανεξαρτήτως παντός μέτρου οιουδήποτε χαρακτήρας, όπερ,
        συμφώνως προς τους νόμους ή άλλα διοικητικά μέτρα πάσης φύσεως,
        θεσπισθέντα από της 18ης Οκτωβρίου 1912 εν Ελλάδι ή εν Τουρκία ή καθ’
        οιονδήποτε άλλον τρόπον, έσχεν ως αποτέλεσμα οιονδήποτε περιορισμόν του
        δικαιώματος της ιδιοκτησίας επί της περιουσίας ταύτης, οίον κατάσχεσις,
        αναγκαστική πώλησις και άλλα. Εν περιπτώσει καθ’ ην εις τας εν τω
        παρόντι ή εν τω άρθρω αναφερομένας περιουσίας είχεν επιβληθή μέτρον
        τοιαύτης φύσεως, η αξία τούτων θέλει καθορισθή υπό της εν τω άρθρω 9
        προβλεπομένης Επιτροπής, ως εάν δεν είχον εφαρμοσθή τα εν λόγω μέτρα.{" "}
        <br />
        Όσον αφορά τας απαλλοτριωθείσας περιουσίας, η Μικτή Επιτροπή θέλει προβή
        εις νέαν εκτίμησιν των από της 18 Οκτωβρίου 1912 απαλλοτριωθεισών τούτων
        περιουσιών, αίτινες ανήκον εις πρόσωπα υποκείμενα εις την ανταλλαγήν των
        δύο χωρών και αίτινες κείνται εις τα υποκείμενα εις ανταλλαγήν εδάφη. Η
        Επιτροπή θα καθορίση υπέρ των ιδιοκτητών αντιστάθμισμα προς επανόρθωσιν
        της ζημίας, ην θέλει βεβαιώσει. Το ποσόν του αντισταθμίσματος τούτου
        θέλει αχθή εις πίστωσιν των ιδιοκτητών τούτων και εις χρέωσιν της
        Κυβερνήσεως επί του εδάφους της οποίας ευρίσκονται τα απαλλοτριωθέντα
        ακίνητα. Εις ην περίπτωσιν τα εν τοις άρθροις 8 και 9 αναφερόμενα
        πρόσωπα δεν ήθελον εισπράξει το εισόδημα των περιουσιών της καρπώσεως,
        των οποίων είχον κατά τον ένα ή τον άλλον τρόπον στερηθή, η ανταπόδοσις
        της αξίας των εισοδημάτων θέλει τοις εξασφαλισθή επί τη βάσει της μέσης
        προπολεμικής αποδόσεως, καθ’ όν τρόπον θέλει ορίσει η Μικτή Επιτροπή.
        <br />
        Η εν τω άρθρω 11 προβλεπομένη Μικτή Επιτροπή κατά την εκκαθάρισιν των εν
        Ελλάδι περιουσιών των Βακουφιών και των εκείθεν απορρεόντων δικαιωμάτων
        και συμφερόντων ως και των αναλόγων ιδρυμάτων των ανηκόντων εις Έλληνας
        εν Τουρκία θέλει εμπνευσθή εκ των καθιερωθεισών αρχών εις τας
        προηγούμενας Συνθήκας, όπως εξασφάλιση πλήρως τα δικαιώματα και
        συμφέροντα των ιδρυμάτων τούτων και των ιδιωτών των εχόντων σχετικά
        συμφέροντα. <br />
        Η εν τω άρθρω 11 προβλεπομένη Μικτή Επιτροπή θέλει εφαρμόσει τας
        διατάξεις ταύτας.
        <br />
        <br />
        Άρθρον 11. <br />
        Εντός προθεσμίας ενός μηνός από της ενάρξεως της ισχύος της παρούσης
        Συμβάσεως, θέλει ιδρυθή Μικτή Επιτροπή εδρεύουσα εν Τουρκία ή εν Ελλάδι
        και συνισταμένη εκ τεσσάρων μελών δι’ έκαστον των Υψηλών Συμβαλλομένων
        Μερών, και εκ τριών μελών εκλεγομένων υπό του Συμβουλίου της Κοινωνίας
        των Εθνών μεταξύ των υπηκόων των Δυνάμεων των μη μετασχουσών εις τον
        πόλεμον του 1914-1918. Η προεδρεία της Επιτροπής θέλει ασκηθή διαδοχικώς
        υφ’ ενός εκ των ουδετέρων τριών τούτων μελών. <br />
        Η Μικτή Επιτροπή θα έχη το δικαίωμα να συνιστά, εις ους ήθελεν εγκρίνει
        τόπους, Υποεπιτροπάς εργαζομένας υπό τας διαταγάς της. Εκάστη τούτων θ’
        αποτελήται εξ ενός Τούρκου, ενός ‘Ελληνος ως μελών και ενός Προέδρου
        ουδετέρου, υποδειχθησομένου υπό της Μικτής Επιτροπής. Η Μικτή Επιτροπή
        θα καθορίση την εις τας Υποεπιτροπάς ανατεθησομένην εξουσίαν. <br />
        <br />
        Άρθρον 12. <br />
        Η Μικτή Επιτροπή θέλει ασκεί την επιτήρησιν και διευκολύνει την υπό της
        παρούσης Συμβάσεως προβλεπομένην μετανάστευσιν και προβαίνει εις την υπό
        των άρθρων 9 και 10 εκκαθάρισιν της κινητής και ακινήτου περιουσίας.{" "}
        <br />
        θέλει ορίζει τον τρόπον της μεταναστεύσεως και της ως άνω οριζόμενης
        εκκαθαρίσεως. <br />
        Εν γένει η Μικτή Επιτροπή θα δύναται να λαμβάνη πάντα τα κατάλληλα μέτρα
        προς εκτέλεσιν της παρούσης Συμβάσεως και θα λύη παν εκ της Συμβάσεως
        ταύτης αναφυόμενον ζήτημα. <br />
        Αι αποφάσεις της Μικτής Επιτροπής θα λαμβάνωνται κατά πλειονοψηφίαν.
        Πάσα διαφορά αφορώσα τας υπό εκκαθάρισιν περιουσίας, δικαιώματα και
        συμφέροντα θέλει κανονίζηται οριστικώς υπ’ αυτής. <br />
        <br />
        Άρθρον 13. <br />
        Η Μικτή Επιτροπή θα έχη παν δικαίωμα όπως διατάξη την ενέργειαν
        εκτιμήσεως της κινητής και ακινήτου περιουσίας της εκκαθαρισθησομένης
        δυνάμει της παρούσης Συμβάσεως, αφού εξετάση τους ενδιαφερομένους ή αφού
        αρμοδίως καλέση αυτούς προς εξέτασιν. <br />
        Ως βάσις εκτιμήσεως των υπό εκκαθάρισιν περιουσιών θέλει ληφθή η εις
        χρυσόν αξία των. <br />
        <br />
        Άρθρον 14. <br />
        Η Επιτροπή θέλει παραδώσει εις τον ενδιαφερόμενον ιδιοκτήτην δήλωσιν
        αναγράφουσαν το εις αυτόν οφειλόμενον ποσόν εκ της στερήσεως της
        περιουσίας του, ήτις περιουσία θέλει παραμείνη εις την διάθεσιν της
        Κυβερνήσεως επί του εδάφους της οποίας αύτη κείται. <br />
        Τα επί τη βάσει των ειρημένων δηλώσεων οφειλόμενα ποσά θ’ αποτελέσωσιν
        οφειλήν της Κυβερνήσεως της χώρας, εις ην θα ενεργηθή η εκκαθάρισις,
        έναντι της Κυβερνήσεως της χώρας, εις ην ανήκει ο μετανάστης. Ούτος
        δικαιούται κατ’ αρχήν να λάβη εν τη χώρα, ένθα μεταναστεύει και δια τα
        ποσά άτινα τω οφείλονται, περιουσίαν ίσης αξίας και της αυτής φύσεως οία
        η παρ’ αυτού εγκαταλειφθείσα. <br />
        Κατά εξ μήνας θέλει καταρτίζηται λογαριασμός των παρά των οικείων
        Κυβερνήσεων οφειλομένων ποσών, επί τη βάσει των ως άνω εκδιδομένων
        δηλώσεων. <br />
        Κατά την γενικήν εκκαθάρισιν, εάν υφίσταται ισοτιμία μεταξύ των
        αμοιβαίως οφειλομένων ποσών, οι σχετικοί λογαριασμοί θέλουσι συμψηφισθή.
        Εάν η μία των Κυβερνήσεων είναι οφειλέτις έναντι της ετέρας μετά τον
        γενόμενον συμψηφισμόν, το χρεωστικόν υπόλοιπον θέλει καταβληθή τοις
        μετρητοίς. Εάν η οφειλέτις Κυβέρνησις ζήτηση προθεσμίας δια την
        καταβολήν ταύτην, η Επιτροπή δύναται να τη παράσχη ταύτας, υπό τον όρον
        όπως το οφειλόμενον ποσόν πληρωθή το πολύ εις τρεις ετησίας δόσεις. Η
        Επιτροπή θέλει ορίζει τους πληρωτέους τόκους κατά τας προθεσμίας ταύτας.{" "}
        <br />
        Εάν το πληρωτέον ποσόν είναι αρκούντως σημαντικόν και απαιτεί μακροτέρας
        προθεσμίας, η οφειλέτις Κυβέρνησις θα πληρώσει τοις μετρητοίς ποσόν
        καθοριστέον υπό της Μικτής Επιτροπής μέχρι 20% επί του συνόλου του
        οφειλομένου ποσού και θα εκδώση δια το υπόλοιπον ομολογίας δανείου
        αποφέρουσας τόκον καθορισθησόμενον υπό της Μικτής Επιτροπής και
        εξοφλητέας εις διάστημα είκοσι ετών κατά μέγιστον όριον. Δια την
        υπηρεσίαν του δανείου τούτου η οφειλέτις Κυβέρνησις θα διάθεση εγγυήσεις
        εγκρινομένας υπό της Επιτροπής. Η διαχείρισις των εγγυήσεων τούτων ως
        και η είσπραξις των προσόδων αυτών θέλει ενεργείται εν Ελλάδι υπό της
        Διεθνούς Επιτροπής και εν Κων/πόλει υπό του Συμβουλίου του Δημοσίου
        Χρέους.
        <br />
        Εν περιπτώσει ασυμφωνίας όσον αφορά τας εγγυήσεις ταύτας, ο καθορισμός
        τούτων ανατίθεται εις το Συμβούλιον της Κοινωνίας των Εθνών. <br />
        <br />
        Άρθρον 15. <br />
        Προς διευκόλυνσιν της μεταναστεύσεως τα ενδιαφερόμενα Κράτη θέλουσι
        προκαταβάλει χρηματικά ποσά εις την Μικτήν Επιτροπή, κατά τους παρ’
        αυτής ορισθησομένους όρους.
        <br />
        <br />
        Άρθρον 16. <br />
        Αι Κυβερνήσεις της Τουρκίας και της Ελλάδος θέλουσι συμφωνήσει μετά της
        εν τω 11 άρθρω προβλεπομένης Μικτής Επιτροπής επί παντός ζητήματος
        αφορώντος τας γενησομένας γνωστοποιήσεις εις τα πρόσωπα, άτινα δέον να
        εγκαταλείψωσι τα εδάφη των δυνάμει της παρούσης Συμβάσεως και τους
        λιμένας εις ους τα εν λόγω πρόσωπα θέλουσι κατευθυνθή, όπως μεταφερθώσιν
        εις τας χώρας του προορισμού των. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη αναλαμβάνουσιν αμοιβαίως την υποχρέωσιν, όπως
        ουδεμία άμεσος ή έμμεσος πίεσις ασκηθή επί των μελλόντων να ανταλλαγώσι
        πληθυσμών ίνα εξαναγκασθώσι να εγκαταλείψωσι τας εστίας των ή
        αποξενωθώσι της περιουσίας των προ της οριζομένης προθεσμίας δια την
        αναχώρησίν των. Αναλαμβάνουσιν ομοίως την υποχρέωσιν, όπως μη υποβάλωσι
        τους εγκαταλείψαντας ή μέλλοντας να εγκαταλείψωσι την χωράν μετανάστας
        εις ουδένα φόρον ή τέλος έκτακτον. Ουδέν εμπόδιον θέλει παρεμβληθή δια
        την παρά των κατοίκων, των δυνάμει του άρθρου 2 εξαιρουμένων της
        ανταλλαγής περιοχών, ελεύθεραν άσκησιν του δικαιώματος αυτών όπως
        παραμείνωσιν έχει ή επιστρέψωσιν και απολαμβάνωσιν απωλύτως της
        ελευθερίας των και των δικαιωμάτων αυτών ιδιοκτησίας εν Τουρκία και εν
        Ελλάδι. Η διάταξις αύτη δεν θέλει προβληθή ως λόγος παρεμποδίσεως της
        ελευθέρας αποξενώσεως της περιουσίας της ανηκούσης εις τους κατοίκους
        των ειρημένων περιοχών των εξαιρουμένων της ανταλλαγής ως και της
        εκουσίας αναχωρήσεως εκείνων εκ των κατοίκων τούτων, οίτινες επιθυμούσι
        να εγκαταλείψωσι την Τουρκίαν ή την Ελλάδα. <br />
        <br />
        Άρθρον 17. <br />
        Αι δαπάναι της συντηρήσεως και λειτουργίας της Μιτής Επιτροπής και των
        οργάνων αυτής θέλουσιν επιβαρύνει κατά την ορισθησομένην υπό της
        Επιτροπής αναλογίαν τας ενδιαφερομένας Κυβερνήσεις. <br />
        <br />
        Άρθρον 18. <br />
        Τα Υψηλά Συμβαλλόμενα Μέρη υποχρεούνται να επιφέρωσιν εις την οικείαν
        αυτών νομοθεσίαν τας τυχόν αναγκαίας τροποποιήσεις προς εκτέλεσιν της
        παρούσης Συμβάσεως. <br />
        <br />
        Άρθρον 19. <br />
        Η παρούσα Σύμβασις θα έχη το αυτό κύρος και την αυτήν ισχύν έναντι των
        ώδε Υψηλών Συμβαλλομένων Μερών, ωσεί περιελαμβάνετο αύτη εν τη Συνθήκη
        Ειρήνης, ήτις θέλει συναφθή μετά της Τουρκίας. Η ισχύς ταύτης άρξεται
        αμέσως μετά την επιχύρωσιν της ειρημένης Συνθήκης παρά των δυο υψηλών
        Συμβαλλομένων Μερών. <br />
        ΠΡΟΣ ΠΙΣΤΩΣΙΝ ΤΟΥΤΩΝ, οι υπογεγραμμένοι Πληρεξούσιοι, ων τα πληρεξούσια
        έγγραφα ευρέθησαν εν πλήρει τάξει και κατά τους απαιτουμένους τύπους,
        υπέγραψαν την παρούσαν Σύμβασιν. <br />
        ΕΓΕΝΕΤΟ εν Λωζάννη, τη 30ή Ιανουαρίου 1923. εις τρία αντίτυπα, ων το εν
        θέλει επιδοθή εις την Ελληνικήν Κυβέρνησιν, το έτερον εις την Κυβέρνησιν
        της Μεγάλης Εθνοσυνελεύσεως της Τουρκίας και το τρίτον θέλει κατατεθή
        εις τα αρχεία της Κυβερνήσεως της Γαλλικής Δημοκρατίας, ήτις θέλει
        επιδώσει επίσημα αυτού αντίγραφα εις τας λοιπάς Δυνάμεις αίτινες θα
        υπογράψωσι την Συνθήκην Ειρήνης μετά της Τουρκίας. <br />
        Ε.Κ. ΒΕΝΙΖΕΛΟΣ <br />
        M. ISΜΕΤ <br />
        Δ. ΚΑΚΛΑΜΑΝΟΣ <br />
        Dr. RIZA NOUR <br />
        HASSAN <br />
        ΠΡΩΤΟΚΟΛΛΟΝ <br />
        <br />
        ΟΙ ΥΠΟΓΕΓΡΑΜΜΕΝΟΙ ΤΟΥΡΚΟΙ ΠΛΗΡΕΞΟΥΣΙΟΙ, δεόντως εξουσιοδοτημένοι,
        δηλούσιν ότι η Τουρκική Κυβέρνησις, χωρίς ν’ αναμείνη την έναρξιν της
        ισχύος της υπό σημερινήν χρονολογίαν μετά της Ελλάδος συναφθείσης
        Συμβάσεως περί ανταλλαγής των Ελληνικών και Τουρκικών πληθυσμών, και
        κατ’ εξαίρεσιν του άρθρου 1 της Συμβάσεως ταύτης, θέλει απελευθερώσει,
        άμα τη υπογραφή της Συνθήκης Ειρήνης, τους ικανούς άρρενας (homines
        valides) περί ων προνοεί το άρθρον 4 της ρηθείσης Συμβάσεως και
        εξασφαλίσει την αναχώρησίν των. <br />
        ΕΓΕΝΕΤΟ εις τριπλούν εν Λωζάννη, την τριακοστήν Ιανουαρίου του χιλιοστού
        εννιακοσιοστού εικοστού τρίτου έτους. <br />
        Μ. ΙSMET <br />
        Dr. RIZA NOUR <br />
        HASSAN <br />
        ΕΜΠΟΡΙΚΗ <br />
        ΥΠΟΓΡΑΦΕΙΣΑ ΤΗ 24 ΙΟΥΛΙΟΥ 1923 <br />
        Η ΒΡΕΤΤΑΝΙΚΗ ΑΥΤΟΚΡΑΤΟΡΙΑ, Η ΓΑΛΛΙΑ, Η ΙΤΑΛΙΑ, Η ΙΑΠΩΝΙΑ, Η ΕΛΛΑΣ, Η
        ΡΟΥΜΑΝΙΑ, ΤΟ ΣΕΡΒΟ-ΚΡΟΑΤΟ-ΣΛΟΒΕΝΙΚΟΝ ΚΡΑΤΟΣ <br />
        <br />
        αφ’ ενός <br />
        ΚΑΙ Η ΤΟΥΡΚΙΑ <br />
        αφ’ ετέρου <br />
        <br />
        Διαπνεόμενοι υπό της επιθυμίας όπως καθορίσωσι τας οικονομικάς αυτών
        σχέσεις επί τη βάσει του Διεθνούς Δικαίου και υπό τους προσφορότερους
        όρους προς ενθάρρυσιν του εμπορίου και διευκόλυνσην των συναλλαγών,{" "}
        <br />
        Απεφάσισαν να συνομολογήσωσιν επί τούτω Σύμβασιν και διώρισαν ως
        Πληρεξουσίους αυτών: <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΟΥ ΗΝΩΜΕΝΟΥ ΒΑΣΙΛΕΙΟΥ ΤΗΣ ΜΕΓΑΛΗΣ
        ΒΡΕΤΤΑΝΙΑΣ ΚΑΙ ΤΗΣ ΙΡΛΑΝΔΙΑΣ ΚΑΙ ΤΩΝ ΥΠΕΡΘΑΛΑΣΣΙΩΝ ΒΡΕΤΤΑΝΙΚΩΝ ΧΩΡΩΝ
        ΑΥΤΟΚΡΑΤΩΡ ΤΩΝ ΙΝΔΙΩΝ:
        <br />
        Τον Εντιμότατον Sir Horace George Montagu RUMBOLD, Baronet, G.C.M.G.,
        Ύπατον Αρμοστήν εν Κωνσταντινουπόλει· <br />
        Ο ΠΡΟΕΔΡΟΣ ΤΗΣ ΓΑΛΛΙΚΗΣ ΔΗΜΟΚΡΑΤΙΑΣ:
        <br />
        Τον Μέραρχον Στρατηγόν x. Maurice PELLE, Πρεσβευτήν της Γαλλίας, Ύπατον
        Αρμοστήν της Δημοκρατίας εν Ανατολή, Ανώτερον Ταξιάρχην Τάγματος της
        Λεγεώνος της Τιμής <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΗΣ ΙΤΑΛΙΑΣ:
        <br />
        Τον έντιμον Μαρκήσιον Camille GARRONI, Γερουσιαστήν του Βασίλειου,
        Πρεσβευτήν της Ιταλίας, Ύπατον Αρμοστήν εν Κωνσταντινουπόλει,
        Μεγαλόσταυρον των Ταγμάτων των Αγίων Μαυρικίου και Λαζάρου και του
        Στέμματος της Ιταλίας· <br />
        Τον Κύριον Jules Cesar MONTAGNA, ‘Εκτακτον Αποσταλμένον και Πληρεξούσιον
        Υπουργόν εν Αθήναις, Ταξιάρχην του Τάγματος των Αγ. Μαυρικίου και
        Λαζάρου, Ανώτερον Ταξιάρχην του Στέμματος της Ιταλίας· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΑΥΤΟΚΡΑΤΩΡ ΤΗΣ ΙΑΠΩΝΙΑΣ:
        <br />
        Τον Κύριον Kentaro OTCHIAI, Jusammi, Μέλος πρώτης τάξεως του Τάγματος
        του Ανατέλλοντος Ηλίου, ‘Εκτακτον και Πληρεξούσιον Πρεσβευτήν εν Ρώμη·{" "}
        <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΕΛΛΗΝΩΝ:
        <br />
        Τον Κύριον Ελευθέριον Κ. ΒΕΝΙΖΕΛΟΝ, Πρώην Πρόεδρον του Υπουργικού
        Συμβουλίου, Μεγαλόσταυρον του Τάγματος του Σωτήρος· <br />
        Τον Κύριον Δημήτριον ΚΑΚΛΑΜΑΝΟΝ, Πληρεξούσιον Υπουργόν εν Λονδίνω,
        Ταξιάρχην του Τάγματος του Σωτήρος· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΗΣ ΡΟΥΜΑΝΙΑΣ:
        <br />
        Τον Κύριον Constantin I. DIAMANDY, Πληρεξούσιον Υπουργόν· <br />
        Τον Κύριον Constanlin CONTZESCO Πληρεξούσιον Υπουργόν· <br />
        Η ΑΥΤΟΥ ΜΕΓΑΛΕΙΟΤΗΣ Ο ΒΑΣΙΛΕΥΣ ΤΩΝ ΣΕΡΒΩΝ, ΤΩΝ ΚΡΟΑΤΩΝ ΚΑΙ ΤΩΝ ΣΛΟΒΕΝΩΝ:
        <br />
        Τον Δρα Miloutine YOVANOVITCH, Έκτακτον Απεσταλμένον και Πληρεξούσισν
        Υπουργόν εν Βέρνη· <br />
        Η ΚΥΒΕΡΝΗΣΙΣ ΤΗΣ ΜΕΓΑΛΗΣ ΕΘΝΟΣΥΝΕΛΕΥΣΕΩΣ ΤΗΣ ΤΟΥΡΚΙΑΣ:
        <br />
        Τον ISMET Pacha, Υπουργόν επί των Εξωτερικών, Βουλευτήν Αδριανουπόλεως·
        Τον Δρα RIZA NOUR Bey Υπουργόν της Υγιεινής και Κοινωνικής Περιθάλψεως,
        Βουλευτήν Σινώπης· <br />
        Τον HASSAN Bey, πρώην Υπουργόν, Βουλευτήν Τραπεζούντας- ΟΙΤΙΝΕΣ,
        επιδείξαντες αλλήλοις τα πληρεξούσια αυτών έγγραφα, ευρεθέντα εν πλήρει
        τάξει και κατά τους απαιτούμενους τύπους, συνεφώνησαν επί των ακολούθων
        όρων: <br />
        <br />
        ΤΜΗΜΑ Α’ <br />
        <br />
        ‘Αρθρον 1. <br />
        Άμα τη ενάρξει της ισχύος της παρούσης Συμβάσεως, το κατά την 1ην
        Σεπτεμβρίου 1916 τεθέν εν ισχύει ειδικόν οθωμανικόν δασμολόγιον θέλει
        εφαρμοσθή επί των παραγομένων εις τα εδάφη των λοιπών συμβαλλομένων
        Χωρών ή εξ αυτών προερχομένων φυσικών ή βιομηχανικών προϊόντων κατά την
        εισαγωγήν αυτών εν Τουρκία. <br />
        Άρθρον 2. <br />
        Τα εις τουρκικόν χαρτονόμισμα εισπραττόμενα τέλη του οθωμανικού
        δασμολογίου της 1ης Σεπτεμβρίου 1916 θα υποβάλλωνται εις συντελεστάς
        ανατιμήσεως κατά περιόδους ρυθμιζόμενους συμφώνως προς την τιμήν του
        συναλλάγματος, κατά τους κάτωθι καθοριζομένους όρους: Ως τοιούτοι
        συντελεσταί θέλουσι ληφθή οι ισχύοντες κατά την 1ην Μαρτίου 1923. Ουχ’
        ήττον, τα εν τω πίνακι του Παραρτήματος Ι απαριθμούμενα είδη, θα
        υποβάλλωνται εις τον συντελεστήν 9. <br />
        Οι ανωτέρω αναφερόμενοι συντελεσταί θα προσαρμόζωνται προς την τιμήν του
        συναλλάγματος κατά τους επομένους κανόνας: <br />
        Δεδομένου ότι οι εν λόγω συντελεσταί έχουσιν ορισθή καθ’ ον χρόνον η
        αγγλική λίρα ετιμάτο 745 χάρτινα γρόσια, εάν, κατά τον προ της ενάρξεως
        της ισχύος της παρούσης Συμβάσεως μήνα, η τουρκική λίρα ήθελεν
        ανατιμηθή, κατά μέσον όρον, πλέον των 30% εν σχέσει προς την ανωτέρω
        τιμήν, οι συντελεσταί 12 και 9 θέλουσιν ελαττωθή κατ’ αναλογίαν προς την
        μέσην τιμήν του αυτού μηνός· ο ούτω κανονιζόμενος συντελεστής θα
        παραμείνη εν ισχύι κατά την επομένην τριμηνίαν, άμα τη λήξει της οποίας,
        θέλει ούτος εκ νέου προσαρμοσθή, εάν συντρέχη περίπτωσις, προς την μέσην
        τιμήν συναλλάγματος του τελευταίου μηνός. <br />
        Ωσαύτως, εάν, κατά τον προ της ενάρξεως της ισχύος της παρούσης
        Συμβάσεως μήνα, η τουρκική λίρα υποτιμηθή, κατά μέσον όρον, πλέον των
        30% εν σχέσει προς την αρχικήν τιμήν των 745 γροσιών κατά αγγλικήν
        λίραν, οι συντελεσταί 12 και 9 δύνονται να αυξηθώσι κατ’ αναλογίαν προς
        την μέσην τιμήν του αυτού μηνός· ο ούτω κανονιζόμενος συντελεστής θα
        παραμείνη εν ισχύι κατά την επομένην τριμηνίαν, άμα τη λήξει της οποίας,
        θέλει ούτος εκ νέου προσαρμοσθή, εάν συντρέχη λόγος, προς την μέσην
        τιμήν συναλλάγματος του τελευταίου μηνός. <br />
        Ο συντελεστής 5 δύναται να αυξηθή εν περιπτώσει υποτιμήσεως της
        τουρκικής λίρας, υφ’ ους όρους και οι συντελεσταί 12 και 9, αλλ’ εν
        περιπτώσει υποτιμήσεως της τουρκικής λίρας, θέλει ελαττωθή αφ’ ης
        στιγμής η αγγλική λίρα ετιμάτο ολιγώτερον των 5 χαρτίνων τουρκικών
        λιρών. Εν περιπτώσει νομισματικής μεταρρυθμίσεως, οι διάφοροι ως άνω
        καθορισθέντες συντελεσταί θέλουσι τροποποιηθή, αναλόγως της διαφοράς
        μεταξύ του νέου και του παλαιού νομίσματος, και κατά τρόπον ώστε να μη
        μεταβληθή η επίπτωσις των τελωνειακών δασμών. <br />
        <br />
        Άρθρον 3. <br />
        Η Τουρκία αναλαμβάνει την υποχρέωσιν όπως, άμα τη ενάρξει της ισχύος της
        παρούσης Συμβάσεως, καταργήση και μη επαναφέρη κατά την διάρκειαν αυτής
        πάσαν απαγόρευσιν εισαγωγής ή εξαγωγής, πλην εκείνων αίτινες θα
        απέβαινον αναγκαίαι προς: <br />
        1ον) εξοικονόμησιν των απαραιτήτων πόρων προς διατροφήν του λαού και
        προστασίαν της οικονομικής δράσεως του Έθνους· <br />
        2ον) περιφρούρησιν της ασφαλείας του Κράτους· <br />
        3ον) προφύλαξιν των ανθρώπων, ζώων και φυτών από των λοιμωδών νόσων,
        επιζωοτιών και επιφυτειών <br />
        4ον) παρακώλυσιν της χρήσεως του οπίου και λοιπών δηλητηριωδών <br />
        5ον) απαγόρευσιν της εισαγωγής αλκοολικών προϊόντων, ων η χρήσις
        απαγορεύεται εν Τουρκία·
        <br />
        6ον) παρεμπόδισιν της εξαγωγής χρυσών νομισμάτων ή μεταλλικού χρυσού·{" "}
        <br />
        7ον) ίδρυσιν ή διατήρησιν μονοπωλίων του Κράτους. <br />
        Υπό τον όρον ακριβοδικαίας αμοιβαιότητος παρεχομένης παρ’ εκάστης των
        λοιπών συμβαλλομένων Δυνάμεων, συμφώνως προς την νομοθεσίαν αυτής, η
        Τουρκία αναλαμβάνει την υποχρέωσιν όπως εφαρμόζη, άνευ ουδεμιάς
        διακρίσεως, τας απαγορεύσεις ταύτας, εν η δε περιπτώσει ήθελεν επιτρέψει
        εξαιρέσεις ή χορηγήσει αδείας ως προς τα απηγορευμένα προϊόντα, όπως μη
        ευνοή το εμπόριον όμοιας συμβαλλομένης Δυνάμεως ή οιασδήποτε Δυνάμεως
        επί βλάβη του εμπορίου ετέρας συμβαλλομένης Δυνάμεως. <br />
        <br />
        Άρθρον 4. <br />
        Υπό τον όρον αμοιβαιότητος, ουδείς φόρος καταναλώσεως (droit de
        consommation ou d’accise) θα επιβάλληται εν Τουρκία επί των εμπορευμάτων
        των παραγομένων εν ταις λοιπαίς συμβαλλομέναις Χώραις ή προερχομένων εξ
        αυτών πέραν του εισπραττομένου επί των εν Τουρκία παραγομένων ομοίων ή
        παρεμφερών ειδών. <br />
        Εκτός τούτου, η Τουρκία δύναται να εξακολουθή εισπράττουσα, δια τα εν τω
        πίνακι του Παραρτήματος II απαριθμούμενα προϊόντα, τα εν αυτώ οριζόμενα
        δικαιώματα καταναλώσεως, υπό τους αυτούς όρους ισότητας μεταξύ των
        υπηκόων αυτής και των υπηκόων των λοιπών συμβαλλομένων Χωρών. Υπό τον
        όρον αμοιβαιότητας, τα διαπύλια τέλη ή παν άλλο τέλος εισπραττόμενον υπό
        των τοπικών Αρχών, εφ’ όσον μεν πρόκειται περί ειδών παραγομένων εν
        Τουρκία, θα επιβάλλωνται άνευ διακρίσεως μεταξύ των τουρκικών προϊόντων
        και των παραγομένων εν ταις λοιπαίς συμβαλλομέναις Χώραις ή εξ αυτών
        προερχομένων, εφ’ όσον δε πρόκειται περί ειδών μη παραγομένων εν
        Τουρκία, θα επιβάλλονται ωσαύτως, άνευ ουδεμιάς διακρίσεως, εφ’ όλων των
        ομοίων ή παρεμφερών ξένων προϊόντων, οιοσδήποτε και αν ή ο τόπος της
        παραγωγής ή προελεύσεως αυτών. <br />
        <br />
        Άρθρον 5. <br />
        Υπό τον όρον ακριβοδικαίας αμοιβαιότητας παρεχομένης εις την Τουρκίαν
        παρ’ εκάστης των λοιπών συμβαλλομένων Δυνάμεων, συμφώνως προς την
        νομοθεσίαν αυτής, παν τέλος εξαγωγής, όπερ η Τουρκία έχει τυχόν
        επιβάλλει ή ήθελεν επιβάλλει εφ’ οιουδήποτε φυσικού ή βιομηχανικού
        προϊόντος, θα εφαρμόζηται εξ ίσου ως προς πάσαν χωράν δι’ ην προορίζεται
        το εν λόγω προϊόν, μη επιτρεπομένης, οπωσδήποτε, διακρίσεως τίνος επί
        ζημία του εμπορίου μιας οιασδήποτε των λοιπών συμβαλλομένων Δυνάμεων.{" "}
        <br />
        <br />
        Άρθρον 6. <br />
        Η Τουρκία θέλει χορηγήσει εις τας λοιπάς συμβαλλομένας Δυνάμεις το
        ευεργέτημα παντός ευνοϊκωτέρου όρου, ον ήθελε παραχωρήσει, ως προς τα εν
        τοις άρθροις 1-5 αναφερόμενα θέματα, εις πάσαν άλλην χωράν, εξαιρέσει
        όμως των ειδικών πλεονεκτημάτων, άτινα ήθελε χορηγήσει, ως προς τα
        δασμολόγια ή παν άλλο εν γένει εμπορικόν είδος, εις οιονδήποτε εκ των
        αποσπωμένων εξ αυτής εδαφών δυνάμει της υπό σημερινήν χρονολογίαν
        Συνθήκης Ειρήνης, ή εις όμορόν τι Κράτος χάριν του συνοριακού εμπορίου.{" "}
        <br />
        <br />
        Άρθρον 7. <br />
        Η Τουρκία και αι1 λοιπαί συμβαλλόμεναι Δυνάμεις δύνανται να απαιτήσωσιν
        αμοιβαίως παρά του εισαγωγέως, προς απόδειξιν της χώρας προελεύσεως των
        εισαγομένων προϊόντων, την προσαγωγή επισήμου πιστοποιητικού
        βεβαιούντος, ότι το εισαγόμενον είδος είναι εγχώριον προϊόν ή
        κατασκεύασμα της ειρημένης χώρας, ή ότι δέον να θεωρήται ως τοιούτον, ως
        εκ της μεταβολής ην υπέστη εν τη χώρα ταύτη. <br />
        Τα πιστοποιητικά προελεύσεως, συντασσόμενα κατά το συνημμένον τω παρόντι
        Τμήματι υπ’ αριθ. III υπόδειγμα, θα εκδίδωνται είτε υπό του Υπουργείου
        του Εμπορίου ή της Γεωργίας, είτε υπό του Εμπορικού Επιμελητηρίου, εις
        το οποίον υπάγεται ο αποστολεύς, είτε υφ’ οιουδήποτε άλλου οργάνου ή
        σωματείου όπερ η χώρα του προορισμού ήθελεν αποδεχθή. Τα εν λόγω
        πιστοποιητικά θα επικυρώνται υπό διπλωματικού ή προξενικού αντιπροσώπου
        της χώρας του προορισμού. <br />
        Τα ταχυδρομικά δέματα θα απαλλάσσωνται του πιστοποιητικού προελεύσεως,
        όταν η χώρα του προορισμού αναγνωρίση ότι δεν πρόκειται περί αποστολής
        εμπορικής φύσεως. <br />
        <br />
        Άρθρον 8. <br />
        Εν πάση περιπτώσει, ουδεμία των συμβαλλομένων Δυνάμεων δύναται να
        επικαλεσθή το ευεργέτημα των διατάξεων του παρόντος Τμήματος, εφ’ όσον
        δεν παρέχει προς την Τουρκίαν, καθ’ όλην τη διάρκειαν της Συμβάσεως,
        όρους εξ ίσου ευνοϊκούς προς τους υπ’ αυτής παρεχομένους εις πάσαν άλλην
        ξένην χωράν. <br />
        <br />
        <br />
        ΠΑΡΑΡΤΗΜΑ Ι <br />
        ΠΙΝΑΞ ΤΩΝ ΕΙΣ ΤΟΝ ΣΥΝΤΕΛΕΣΤΗΝ 9 ΥΠΑΓΟΜΕΝΩΝ ΕΙΔΩΝ <br />
        Αριθμοί Δασμολογίου <br />
        65 ……………. Γεώμηλα. <br />
        69 ……………. Πορτοκάλια. <br />
        121 ………….. Σακχαρώδεις σκευασίαι. <br />
        130 ………….. Μεταλλικά ύδατα. <br />
        178 ………….. Δέρματα βερνικωτά (λουστρίνια). <br />
        180 ………….. Δέρματα χοίρου. <br />
        185-187-188 …. Υποδήματα. <br />
        192 ……………. Χειρόκτια. <br />
        200-201 ……… Μηλωταί (γουναρικά) ακατέργαστοι ή κατειργασμέναι. <br />
        217-218 ………. Έπιπλα. <br />
        273-274—275 ….. Κεντήματα, τρίχαπτα και ταινίαι βαμβακεροί. <br />
        302 ……………. Γνάφαλα μετάξης. <br />
        305 ……………. Γάζα κ.λπ. <br />
        306 ……………. Τούλλιον εκ μετάξης κλπ. <br />
        308 ……………. Μετάξινα υφάσματα. <br />
        311-312 ……… Οθόναι εκ μετάξης. <br />
        314 ……………. Ταινιοπλέγματα εκ μετάξης. <br />
        324 ……………. Σάλια και μάλλιναι ζώναι. <br />
        339 ……………. Ενδύματα. <br />
        348 ……………. Αλεξήλια, Αλεξίβροχα κλπ. <br />
        <br />
        ΠΑΡΑΡΤΗΜΑ II. <br />
        ΦΟΡΟΙ ΚΑΤΑΝΑΛΩΣΕΩΣ <br />
        Τέϊον ………………………………… 40 γρόσια κατά χιλιόγραμμο” <br />
        Καφές ……………………………….. 20 ” ” <br />
        Πετρέλαιον ……………………..….. 6 ” ” <br />
        Όρυζα ……………………………….. 10 ” ” <br />
        Μαργαρίνη, ελαιομαργαρίνη και λοιπά ζωικά λιπάσματα 80 ” ” <br />
        Κηρία εκ στεαρίνης ………………………. 30 ” ” <br />
        Σάπων κοινός …………………………… 5 ” ” <br />
        Σάκκοι καινουργείς και μεταχειρισμένοι …………. 5 ” ” <br />
        Καρυκεύματα …………………………… 30 ” ” <br />
        Πυρεία ……………………………….. 1/2 γρόσιον το κυτίον 60 πυρείων <br />
        Πυρεία κήρινα ………………………….. 1 γρόσιον το κυτίον 60 πυρείων <br />
        Σιγαρόχαρτον …………………………… 1 γρόσιον τα 50 φύλλα. <br />
        Αναπτήρες …………………………….. 25 γρόσια έκαστος. <br />
        Σάκχαρις ……………………………… 15 γρόσια κατά χιλιόγραμμον. <br />
        Διπυρίται ……………………………… <br />
        Σοκολάτα ……………………………… υποκείμενα εις φόρον καταναλώσεως <br />
        Συμπεπυκνωμένον γάλα ……………………. αναλόγως της ποσότητος της <br />
        Είδη ζαχαροπλαστικής και αμυλοσάκχαρον ………. (περιεχόμενης ζακχάρεως.{" "}
        <br />
        Ποτά μη αλκοολικά, αεριούχα και λεμονάδες ……… <br />
        Πάντα τα λοιπά ζακχαρώδη προϊόντα ………….. <br />
        Τουμβεκίον ……………………………. 40 γρόσια κατά χιλιόγραμμο ” ” <br />
        <br />
        ΠΑΡΑΡΤΗΜΑ III. <br />
        ΥΠΟΔΕΙΓΜΑ ΠΙΣΤΟΠΟΙΗΤΙΚΟΥ ΠΡΟΕΛΕΥΣΕΩΣ <br />
        Ημείς (αρχή εκδίδουσα το πιστοποιητικόν) (1) πιστοποιυούμεν ότι: <br />
        Παραγωγός ή βιομήχανος, <br />
        Ο κ. ………………….. Παραγωγός ή βιομήχανος, Πληρεξούσιος του κ…………..
        κατοίκου………………………. <br />
        Έμπορος επιτηδευματίας, <br />
        κάτοικος …………………………………. εδήλωσιν ενώπιον ημών, <br />
        υπευθύνως, ότι τα κάτωθι σημειούμενα εμπορεύματα είναι προελεύσεως ή
        κατασκευής (τουρκικής ή ………………………………………………), <br />
        συμφώνως προς τα αξιόπιστα έγγραφα τα προσαχθέντα ημίν παρά του
        αποστολέως (3). Τα εμπορεύματα ταύτα αποστέλλονται εις ……. επί
        παρακαταθήκη προς πώλησιν <br />
        παρά τω κ. ……………… εμπόρω ή βιομηχάνω εις ……………… <br />
        δια (ξηράς ή δια πλοίου ……………………………………). <br />
        Βεβαιώ τ’ ανωτέρω υπευθύνως, τη ………………… <br />
        (Υπογραφή του δηλούντος <br />
        Βεβαίουται παρ’ ημών (αρχή εκδίδουσα το πιστοποιητικόν), πιστοποιούντων
        προσέτι ότι η πώλησις των ως άνω σημειουμένων εμπορευμάτων συνωμολογήθη
        πράγματι εν τη χώρα ταύτη. <br />
        (Ημερομηνία και υπογραφή της εκδιδούσης το πιστοποιητικό αρχής) <br />
        Εθεωρήθη εν τω Προξενείω δι’ επικύρωσιν του γνησίου της παρούσης
        υπογραφής. <br />
        (Ημερομηνία, υπογραφή και σφραγίς του Προξενείου). <br />
        (1) Τα πιστοποιητικά θα εκδίδονται είτε παρά του Υπουργείου του Εμπορίου
        ή της Γεωργίας, είτε παρά του Εμπορικού Επιμελητηρίου εις το οποίον
        υπάγεται ο αποστολεύς, είτε παρά παντός άλλου οργάνου ή σωματείου
        εγκρινομένου υπό της χώρας του προορισμού· <br />
        (2) Διαγράφονται αι περιτταί μνείαι. <br />
        (3) Οσάκις ο λαμβάνων το πιστοποιητικόν είναι ο παραγωγός ή ο βιομήχανος
        ή ο πληρεξούσιος αυτών, η φράσις «Συμφώνως προς τα αξιόπιστα έγγραφα τα
        προσαχθέντα ημίν παρά του αποστολέως» θα διαγράφηται. <br />
        <br />
        ΤΜΗΜΑ Β’ <br />
        <br />
        Άρθρον 9. <br />
        Υπό τον όρον της αμοιβαιότητος, η Τουρκία υποχρεούται να εξασφάλιση εις
        τα πλοία των λοιπών συμβαλλομένων Δυνάμεων το αυτό καθεστώς, εις ό
        υπάγονται τα ίδια αυτής πλοία ή καθεστώς ευνοϊκώτερον εκείνου, εις ό
        υπάγονται ή ήθελον τυχόν υπαχθή τα πλοία πάσης άλλης Δυνάμεως. Η Τουρκία
        διατηρεί έναντι εκάστης των λοιπών συμβαλλομένων Δυνάμεων και,
        αντιστοίχως, εκάστη τούτων έναντι της Τουρκίας, το δικαίωμα όπως
        επιφυλάσση εις την σημαίαν αυτής την αλιείαν, την θαλασσίαν ακτοπλοΐαν
        ήτοι την δια θαλάσσης μεταφοράν των επιβιβαζομένων εν τινί λιμένι του
        εδάφους αυτής εμπορευμάτων και επιβατών, των κατευθυνομένων προς άλλον
        λιμένα του αυτού εδάφους, και τας λιμενικός υπηρεσίας, ήτοι την
        ρυμούλκησιν, πλοηγίαν και πάσης φύσεως εσωτερικήν υπηρεσίαν. <br />
        Άρθρον 10. <br />
        Υπό την επιφύλαξιν των εν τω προηγουμένω άρθρω προβλεπομένων εξαιρέσεων
        ως προς την αλιείαν, την θαλασσίαν ακτοπλοΐαν και τας λιμενικάς
        υπηρεσίας, η Τουρκία αφ’ ενός, και εκάστη των λοιπών συμβαλλομένων
        Δυνάμεων αφ’ ετέρου, θέλουσιν αμοιβαίως εξασφαλίσει εις τα πλοία αυτών
        το αυτό καθεστώς προς το των ιθαγενών πλοίων, καθ’ όσον αφορά το
        δικαίωμα εισαγωγής παντός είδους εμπορευμάτων ή μεταφοράς των εκ της
        χώρας αυτών προερχομένων ή προς αυτήν κατευθυνόμενων επιβατών, και την
        παροχήν πάσης ευκολίας κατά την στάθμευσιν, φόρτωσιν και εκφόρτωσιν των
        πλοίων εις τους λιμένας, δεξαμενάς, προκυμαίας και όρμους. <br />
        Υπό τον αυτόν όρον αμοιβαιότητας, θα υφίσταται ωσαύτως απόλυτος ισότης
        ως προς τα επί των πλοίων εισπραττόμενα παντός είδους δικαιώματα και
        τέλη, οία τα υγειονομικά, τα λιμενικά, τα τέλη προκυμαίας, αγκυροβολίας,
        πλοηγίας, καθάρσεως, τα φαρικά και λοιπά παρόμοια τέλη, τα εισπραττόμενα
        εν ονόματι ή υπέρ της Κυβερνήσεως, των δημοσίων υπαλλήλων, ιδιωτών,
        συνεταιρισμών ή παντός είδους ιδρυμάτων. <br />
        Η Τουρκία υποχρεούται ωσαύτως, επ’ αμοιβαιότητι, όπως μη επιβαρύνη τα
        εισαγόμενα ή εξαγόμενα εμπορεύματα δι’ ουδενός διαφορικού ή προσθέτου
        τέλους ή αυξήσεως τινός, οιασδήποτε φύσεως ή ονομασίας, λόγω της σημαίας
        του εισάγοντος ή εξάγοντος το εμπόρευμα πλοίου, του λιμένος της αφίξεως
        ή αναχωρήσεως, του πλου του πλοίου ή των σταθμεύσεων αυτού, των
        δικαιωμάτων και τελών των επιβαλλομένων επί των εισαγομένων ή εξαγομένων
        εμπορευμάτων καθοριζομένων αποκλειστικώς εκ του τόπου της παραγωγής και
        προελεύσεως ή προορισμού αυτών και εφαρμοζομένων εξ ίσου ως προς πάσας
        τας λοιπάς συμβαλλομένας Δυνάμεις, συμφώνως προς τας διατάξεις του
        Τμήματος Α’. <br />
        <br />
        Άρθρον 11. <br />
        Πάντα τα σχετικά προς τα πλοία και ατμόπλοια, τα φορτία και τους
        επιβάτας αυτών πιστοποιητικά ή έγγραφα, τα αναγνωρισθέντα προ του
        πολέμου υπό της Τουρκίας ως έγκυρα ή δυνάμενα μεταγενεστέρως να
        αναγνωρισθώσιν ως τοιαύτα υπό των κυριωτέρων ναυτικών Κρατών, θέλουσιν
        αναγνωρισθή υπό της Τουρκίας, όσον αφορά τα πλοία των λοιπών
        συμβαλλομένων Δυνάμεων, ως έγκυρα και ισοδύναμα προς τα αντίστοιχα
        πιστοποιητικά, τα χορηγούμενα εις τουρκικά πλοία και ατμόπλοια. <br />
        Αι διατάξεις αύται θα ώσιν έγκυροι, εφ’ όσον τα παρά της Τουρκίας εις τα
        τουρκικά πλοία και ατμόπλοια χορηγούμενα πιστοποιητικά και έγγραφα, υπό
        συνθήκας ανάλογους προς τας ισχύουσας εν ταις κυριωτέραις ναυτικαίς
        χώραις, θεωρούνται υπό των λοιπών συμβαλλομένων Μερών ως ισοδύναμα προς
        τα παρ’ αυτών εκδιδόμενα τοιαύτα. <br />
        <br />
        ΤΜΗΜΑ Γ’ <br />
        Άρθρον 12.
        <br />
        Υπό τον όρον αμοιβαιότητος, η Τουρκία υποχρεούται να λαμβάνη πάντα τα
        νομοθετικά ή διοικητικά μέτρα και επιτρέπη πάσαν προς την δικαιοσύνην
        προσφυγήν προς προστασίαν των φυσικών ή βιομηχανικών προϊόντων
        οιασδήποτε των λοιπών συμβαλλομένων Δυνάμεων από πάσης μορφής αθεμίτου
        ανταγωνισμού εν ταις εμπορικαίς συναλλαγαίς. <br />
        Η Τουρκία υποχρεούται ωσαύτως, υπό τον όρον αμοιβαιότητος, να καταστέλλη
        και απαγορεύη, δια προσηκόντων μέτρων, την εισαγωγήν, ως και την εν τω
        εσωτερικώ κατασκευήν, κυκλοφορίαν, πώλησιν και προσφοράν προς πώλησιν
        παντός προϊόντος ή εμπορεύματος φέροντος επ’ αυτού τούτου ή επί της
        συνήθους αυτού συσκευασίας ή του εξωτερικού περικαλύμματος αυτού σήματα,
        ονόματα, επιγραφάς ή σημεία οιαδήποτε συνεπαγόμενα αμέσως ή εμμέσως,
        ψευδείς ενδείξεις περί της προελεύσεως, του είδους, της φύσεως ή περί
        των ιδιαιτέρων ιδιοτήτων του εν λόγω προϊόντος ή εμπορεύματος. <br />
        <br />
        Άρθρον 13.
        <br />
        Υπό τον όρον της αμοιβαιότητος, η Τουρκία υποχρεούται να συμμορφούται
        προς τους ισχύοντας εν ετέρα συμβαλλομένη Χώρα και αρμοδίως
        ανακοινουμένους αυτή νόμους, ως και προς τας συμφώνως τοις νόμοις
        τούτοις λαμβανομένας διοικητικάς ή δικαστικάς αποφάσεις, δι’ ων
        καθορίζεται η διακανονίζεται το δικαίωμα της περιφερειακής ονομασίας των
        προϊόντων, άτινα έλκουσι τας ιδιαιτέρας αυτών ιδιότητας εκ του εδάφους ή
        του κλίματος, ή οι όροι υφ’ ους δύναται να επιτραπή η χρήσις
        περιφερειακής τίνος ονομασίας. Η παρά τους προειρημένους νόμους ή
        αποφάσεις εισαγωγή, εξαγωγή, ως και η κατασκευή, κυκλοφορία, πώλησις η
        προσφορά προς πώλησιν προϊόντων ή εμπορευμάτων φερόντων περιφερειακάς
        ονομασίας, θέλουσιν απαγορευθή υπό της Τουρκίας και κατασταλή δια των εν
        τω άρθρω 12 οριζομένων μέτρων. <br />
        <br />
        Άρθρον 14.
        <br />
        Η Τουρκία, υποχρεούται, προς της παρελεύσεως δώδεκα μηνών από της
        ενάρξεως της ισχύος της παρούσης Συμβάσεως: <br />
        1ον) Να προσχώρηση, κατά τους κεκανονισμένους τύπους, εις την από 20
        Μαρτίου 1883 Διεθνή Σύμβασιν των Παρισίων περί προστασίας της
        βιομηχανικής ιδιοκτησίας, την αναθεωρηθείσαν εν Βασιγκτώνι την 2αν
        Ιουνίου 1911. <br />
        2ον) Να προσχώρηση ωσαύτως εις την από 9 Σεπτεμβρίου 1886 Διεθνή
        Σύμβασιν της Βέρνης περί προστασίας των πνευματικών και καλλιτεχνικών
        έργων, την αναθεωρηθείσαν εν Βερολίνω την 13ην Νοεμβρίου 1908, ως και
        εις το από 20 Μαρτίου 1914 πρόσθετον Πρωτόκολλον της Βέρνης περί
        προστασίας των πνευματικών και καλλιτεχνικών έργων. <br />
        Αι υπογράψασαι την παρούσαν Σύμβασιν λοιπαί Δυνάμεις δεν θέλουσιν
        αντιταχθή, διαρκούσης της παρούσης Συμβάσεως, κατά της επιφυλάξεως ην η
        Τουρκία δηλοί ότι διατυπώνει ως προς τας διατάξεις των προαναφερομένων
        Συμβάσεων και Πρωτοκόλλου των αφορωσών το δικαίωμα μεταφράσεως εις την
        τουρκικήν γλώσσαν, εάν εντός ενός έτους από της ενάρξεως της ισχύος της
        παρούσης Συμβάσεως, και αι λοιπαί συνυπογράψασαι τας Συμβάσεις ταύτας
        και Πρωτόκολλον Δυνάμεις ουδεμίαν εγείρωσιν αντίρρησιν κατά της εν λόγω
        επιφυλάξεως. <br />
        Εν η περιπτώσει αι υπογράψασαι την παρούσαν Σύμβασιν Δυνάμεις δεν θα
        ηδύναντο να διατηρήσωσι την συναίνεσίν των επί της τουρκικής επιφυλάξεως
        περί του δικαιώματος μεταφράσεως, η Τουρκία θέλει απαλλαγή της
        υποχρεώσεως όπως διατηρήση την προσχώρησιν αυτής εις τας προειρημένας
        Συμβάσεις και Πρωτόκολλον. <br />
        3ον) Ν’ αναγνώριση και προστατεύση προ της λήξεως της αυτής προθεσμίας,
        διά πραγματικής νομοθεσίας, συμφώνως προς τας αρχάς των ειρημένων
        Συμβάσεων, την βιομηχανικήν, πνευματικήν και καλλιτεχνικήν ιδιοκτησίαν
        των υπηκόων των λοιπών συμβαλλομένων Δυνάμεων. <br />
        <br />
        Άρθρον 15.
        <br />
        Ιδιαίτεραι συμβάσεις μεταξύ των ενδιαφερομένων χωρών θέλουσι κανονίση
        παν ζήτημα αφορών τα αρχεία, βιβλία και σχέδια τα αναφερόμενα εις την
        υπηρεσίαν της βιομηχανικής και καλλιτεχνικής ιδιοκτησίας, ως και την
        ενδεχομένην διαβίβασιν ή ανακοίνωσιν αυτών δια των υπηρεσιών της
        Τουρκίας προς τας υπηρεσίας των Κρατών υπέρ ων αποσπώνται εδάφη εκ της
        Τουρκίας. <br />
        <br />
        ΓΕΝΙΚΑΙ ΔΙΑΤΑΞΕΙΣ
        <br />
        <br />
        Άρθρον 16. <br />
        Αι συμβαλλόμεναι Δυνάμεις επιφυλάσσονται το δικαίωμα όπως δηλώσωσι κατά
        την έναρξιν της ισχύος της παρούσης Συμβάσεως, ότι αι διατάξεις αυτής
        δεν θέλουσιν εφαρμοστή επί του όλου ή μέρους των Κτήσεων αυτών
        (Dominions), των εχουσών αυτόνομον κυβέρνησιν, των αποικιών αυτών,
        προτεκτοράτων, κτήσεων ή υπερθαλασσίων εδαφών, των υπαγομένων υπό την
        κυριαρχίαν ή εξουσίαν αυτών, αλλ’ εν τοιαύτη περιπτώσει, η Τουρκία θα
        απαλλαγή των εκ της παρούσης Συμβάσεως απορρεουσών δι’ αυτήν υποχρεώσεων
        έναντι των ειρημένων Κτήσεων (Dominions), αποικιών, προτεκτοράτων,
        κτήσεων και εδαφών. <br />
        Ουχ’ ήττον, αι ειρημέναι Δυνάμεις δύνανται να προσχωρήσωσι βραδύτερον,
        εν ονόματι πάσης Κτήσεως (Dominion), εχούσης αυτόνομον κυβέρνησιν,
        αποικίας, προτεκτοράτου, κτήσεως ή εδάφους, άτινα είχον τυχόν αποκλείση
        δια προηγουμένης δηλώσεως κατά τας διατάξεις της παρούσης Συμβάσεως.
        <br />
        <br />
        Άρθρον 17.
        <br />
        Τα εκ της Λιβύης προερχόμενα ή δι’ αυτήν προοριζόμενα εμπορεύματα και
        προϊόντα θα υποβάλλωνται εν Τουρκία εις το αυτό καθεστώς εις ό και τα
        ιταλικά εμπορεύματα και προϊόντα. <br />
        Τα εκ της Τουρκίας προερχόμενα ή δι’ αυτήν προοριζόμενα εμπορεύματα και
        προϊόντα θα απολαύωσιν εν Λιβύη του καθεστώτος της μάλλον ευνοούμενης
        ξένης χώρας. <br />
        <br />
        Άρθρον 18.
        <br />
        Η παρούσα Σύμβασις συνομολογείται δια πέντε έτη. <br />
        Όσον αφορά το Τμήμα Α’, η Τουρκία αφ’ ενός, η Ελλάς, η Ρουμανία και το
        Σερβο-Κροατο-Σλοβενικόν Κράτος αφ’ ετέρου, αναγνωρίζουσαι την ανάγκην
        καθορισμού εντός βραχυτέρας προθεσμίας νέου καθεστώτος δια τας εμπορικάς
        αυτών συναλαγάς, συμφωνούσιν όπως αναγνωρίσωσιν αμοιβαίως το δικαίωμα
        καταγγελίας της παρούσης Συμβάσεως εν πάση στιγμή μετά την λήξιν της
        πρώτης τριακονταμήνου χρονικής περιόδου, της ειρημένης Συμβάσεως
        λήγουσης έξι μήνας μετά την καταγγελίαν. <br />
        Η Τουρκία αφ’ ενός, και έκαστον των λοιπών συμβαλλομένων Μερών αφ’
        ετέρου, υποχρεούνται όπως, εν πάση στιγμή κατά την διάρκειαν των ως άνω
        οριζομένων δια την εφαρμογήν της Συμβάσεως χρονικών περιόδων και ευθύς
        ως διατυπωθή προς αυτά σχετική αίτησις, διαξαγάγωσι συντόνους
        διαπραγματεύσεις προς συνομολόγησιν νέων εμπορικών συνθηκών, αίτινες
        δέον να περατωθώσι προ της παρελεύσεως των ειρημένων χρονικών περιόδων.{" "}
        <br />
        Εάν αι εν λόγω διαπραγματεύσεις δεν καταλήξωσιν εις αποτέλεσμα προ της
        παρελεύσεως των ως άνω προβλεπομένων προθεσμιών, έκαστος των Υψηλών
        Συμβαλλομένων Μερών θα δύναται να αναλάβη την ελευθερίαν ενεργείας
        αυτού. <br />
        <br />
        Άρθρον 19. <br />
        Η ΠΑΡΟΥΣΑ ΣΥΜΒΑΣΙΣ επικυρωθήσεται.
        <br />
        Αι επικυρώσεις κατατεθήσονται εν Παρισίοις, ως οιόντε τάχιον. <br />
        Άρξεται ισχύουσα υφ’ ους όρους και η υπό σημερινήν χρονολογίαν Συνθήκη
        Ειρήνης. <br />
        ΠΡΟΣ ΠΙΣΤΩΣΙΝ ΤΟΥΤΩΝ, οι ανωτέρω μνημονευόμενοι Πληρεξούσιοι υπέγραψαν
        την παρούσαν Σύμβασιν. <br />
        ΕΓΕΝΕΤΟ εν Λωζάνη, τη 24η Ιουλίου 1923, εις εν και μόνον αντίτυπον, όπερ
        κατατεθήσεται εν τοις αρχείοις της Κυβερνήσεως της Γαλλικής Δημοκρατίας,
        ήτις θέλει επιδώσει επίσημον αυτού αντίγραφαν προς εκάστην των
        υπογραψασών Δυνάμεων. <br />
        Horace RUMBOLD <br />
        PELLE <br />
        GARRONI <br />
        G.C. MONTAGNA <br />
        Κ. OTCHIAI <br />
        Ε.Κ. ΒΕΝΙΖΕΛΟΣ <br />
        Δ.ΚΑΚΛΑΜΑΝΟΣ <br />
        Const. DIAMANDY <br />
        Const. CONTZESCO <br />
        Μ. ISMET <br />
        Dr. RIZA NOUR <br />
        HASSAN
        <br />
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default G17_mikrasiatika;
