import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

// import Image01 from "../assets/images/oikologika/29SinedrioPandoiko.jpg";

function F18_oikologika_18() {

    const theme = useTheme();


  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Τα κυριότερα περιβαλλοντολογικά προβλήματα από το Πανελλήνιο Δίκτυο Οικολογικών Οργανώσεων" />
      </Box>


      
      <Box sx={{ ...commonBoxStyles }}>
		 
		
		
		<Typography variant="h3" sx={theme.typography.typography2}>Οι πιέσεις που ασκούνται στα στοιχεία του φυσικού περιβάλλοντος (βιοτικού και αβιοτικού) στην Ελλάδα είναι ανάλογες με αυτές που παρατηρούνται 
		στις περισσότερες βιομηχανικές χώρες.  Το παραγωγικό και καταναλωτικό πρότυπο είναι μη βιώσιμα.  Παρά ταύτα υπάρχουν κάποιες διαφοροποιήσεις.
		Μία ολοκληρωμένη πολιτική βιωσιμότητας παραμένει το ζητούμενο.<br/><br/></Typography>
		
		<Typography variant="h1" sx={theme.typography.typography1}><u>Σημειώνουμε παρακάτω τα κυριότερα προβλήματα του Ελληνικού χώρου:</u></Typography>
		<br/><br/>
		<Typography variant="h1" sx={theme.typography.typography1}>Α. ΔΙΑΧΕΙΡΙΣΗ ΥΔΑΤΙΚΟΥ ΔΥΝΑΜΙΚΟΥ</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Υποβαθμίσεις λιμναίων εκτάσεων, με χαρακτηριστικότερη τη λίμνη Κορώνεια, οι ρυπάνσεις ποταμών, η υποβάθμιση και αλάτωση των υπογείων υδροφορέων
		(π.χ. Αργολικό πεδίο, κάμπος Μολάων, Θεσσαλία) αποτελούν ένα μέρος των προβλημάτων που υπάρχουν.  Η εμμονή στο φαραωνικό σχέδιο της εκτροπής του 
		Αχελώου, αντί της ανά υδατικό διαμέρισμα αειφορικής διαχείρισης των επιφανειακών υδάτων, απειλεί σημαντικά οικοσυστήματα.<br/>
		Αναγκαία είναι η εφαρμογή της οδηγίας της Ε.Ε. του 2000/60/ΕΚ για τα νερά αλλά και του ισχύοντος, αλλά πλημμελώς εφαρμοζόμενου νόμου.
		</Typography>
		
		<Typography variant="h1" sx={theme.typography.typography1}>Β.  ΑΤΜΟΣΦΑΙΡΑ ΠΟΛΕΩΝ – ΒΙΩΣΙΜΟΤΗΤΑ</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Τέσσερις ελληνικές πόλεις είναι ανάμεσα στην δεκάδα των πλέον επιβαρημένων από αιωρούμενα σωματίδια ευρωπαϊκών πόλεων.  Οι πολιτικές του παρελθόντος (
		π.χ. καταλύτες στα οχήματα) έχουν εξαντλήσει τις δυνατότητές τους.  Μια ολοκληρωμένη πολιτική βελτίωσης της ποιότητας του αέρα, που να περιλαμβάνει σύστημα 
		απογραφής των εκπομπών, κατάργηση ιδιαίτερα επιβαρυντικών καυσίμων, όπως το πετ – κώκ, αποθάρρυνση της ιδιωτικής αυτοκίνησης, δημιουργία ποδηλατοδρόμων κ.λ.π., 
		είναι επείγουσα ανάγκη.  Το κυκλοφοριακό είναι πλέον για τους πολίτες ένα από τα πιο σημαντικά προβλήματα στην ατζέντα τους, κατασπαταλά πολύτιμο χρόνο και 
		υποβαθμίζει την ποιότητα ζωής των πολιτών.  Μία από τις προτάσεις για την Αθήνα και τις μεγάλες επαρχιακές  πόλεις είναι η ενίσχυση των μαζικών μέσων μεταφοράς 
		λεωφορεία-λεωφορειόδρομοι,τραμ κλπ).<br/>
		Με μεγάλη ανησυχία βλέπουμε να έρχονται συνεχώς στη δημοσιότητα δηλώσεις και πληροφορίες για χρήση μεγάλων ενιαίων χώρων(πρώην αεροδρόμιο ελληνικού, 
		στρατόπεδα στο Γουδί, στο Χαϊδάρι, στη Θεσσαλονίκη και αλλού) για οικιστική, κατά μεγάλο μέρος, ανάπτυξη αντί της οφθαλμοφανώς αναγκαίας κατοχύρωσής τους 
		ως <b>ενιαίων και αδιαιρέτων κοινόχρηστων χώρων</b> υπερτοπικής σημασίας (σε αντίθεση και με σχετικές προεκλογικές τοποθετήσεις).  Εκφράζουμε την αγωνία μας και 
		ζητούμε:  το Ελληνικό, τελευταία ευκαιρία για ένα μεγάλο πάρκο στην Αττική να μην τεμαχιστεί και οικοπεδοποιηθεί.  Η πολιτική απόδοσης στρατοπέδων σε κοινή 
		χρήση είναι επίσης μια αναγκαία πολιτική που καθυστερεί.</Typography>

		
		<Typography variant="h1" sx={theme.typography.typography1}>Γ.  ΔΙΑΧΕΙΡΙΣΗ ΑΠΟΒΛΗΤΩΝ – ΑΠΟΡΡΙΜΜΑΤΩΝ</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Μεγάλη καθυστέρηση παρατηρείται σε ότι αφορά τη διαχείριση των αποβλήτων, αστικών και βιομηχανικών.  Παρά την μείωση των ανεξέλεγκτων αποθέσεων 
		(στις οποίες συχνά οι ίδιοι οι δήμαρχοι βάζουν φωτιά το καλοκαίρι προς μείωση του όγκου) αυτές δέχονται πάνω από το ένα τρίτο των οικιακών απορριμμάτων.
		Η  πρακτική αυτή είναι η κυριότερη αιτία δασικών πυρκαγιών.<br/>
		Συγχρόνως οι ανεξέλεγκτες αποθέσεις προκαλούν και άλλα προβλήματα όπως ρύπανση υδροφορέων ή αισθητική προσβολή περιοχών ιδιαίτερου φυσικού κάλλους 
		(π.χ. απόθεση στην έξοδο του φαραγγιού του Βουραϊκού στην Αχαϊα, ή στην κοίτη του Αχελώου στην Αιτωλοακαρνανία)<br/>
		Παράλληλα η <b>ανακύκλωση</b> έχει περιορισμένη εφαρμογή και σε ελάχιστο αριθμό δήμων ενώ η μέθοδος της διαλογής στην πηγή δεν υφίσταται.<br/>
		Δυστυχώς δεν έχουν ακόμα ενεργοποιηθεί επαρκώς όλα τα «συστήματα εναλλακτικής διαχείρισης», παρά τις προθεσμίες που μας έχουν τεθεί από την Ε.Ε.<br/>
		Σε ότι αφορά τα <b>οικοδομικά απορρίμματα, </b> παρότι εν δυνάμει μπορούν να τύχουν 100% ορθολογικής διαχείρισης, με σημαντικό ποσοστό ανακύκλωσης, 
		πετιούνται χωρίς ανακύκλωση, ως μπάζα.<br/>
		Σε ότι αφορά τα <b>αστικά λύματα</b> μεγάλη πρόοδος έχει σημειωθεί μια και τα μεγαλύτερα πολεοδομικά συγκροτήματα διαθέτουν ήδη, λιγότερο ή περισσότερο καλά, 
		λειτουργούντες βιολογικούς καθαρισμούς.	Όμως σημαντικός αριθμός μικρότερων πόλεων στερείται ακόμη και αποχετευτικών αγωγών ή συστημάτων απλής πρωτοβάθμιας 
		καθίζησης.</Typography>
       
		
		<Typography variant="h1" sx={theme.typography.typography1}>Δ. ΠΑΝΙΔΑ</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Το <b>κυνήγι</b> και η <b>λαθροθηρία</b> αποτελούν ένα πολύ μεγάλο πρόβλημα για τη διατήρηση της πανίδας στον τόπο μας.  Δυστυχώς κάθε χρόνο το Υπουργείο 
		Γεωργίας εκδίδει 
		σχεδόν πανομοιότυπες ρυθμιστικές για το κυνήγι, χωρίς να υπάρχουν αξιόπιστες μελέτες για την κατάσταση της πανίδας και παρά τις αντίθετες θέσεις του Συμβουλίου 
		της Επικρατείας.  Αποτέλεσμα είναι η μείωση της βιοποικιλότητας, γεγονός που έχει επίπτωση σε πολλές άλλες δραστηριότητες όπως π.χ. η γεωργία.  Από την άλλη 
		μεριά η υπεραλίευση και τα σύγχρονα μέσα, ιδίως οι μηχανότρατες, που χρησιμοποιούνται σήμερα έχουν δημιουργήσει σοβαρά προβλήματα στην επάρκεια αλιευμάτων, με 
		πρώτα θύματα τους ίδιους τους ψαράδες.  Την άσχημη αυτή εικόνα έρχονται να συμπληρώσουν οι δυναμιτιστές που καταστρέφουν το γόνο και το αλίευμα και που πολλές 
		φορές δρουν με την ανοχή υπηρεσιών και την κάλυψη πολιτικών.  Οι ιχθυοκαλλιέργειες παρά τη συμβολή τους στην αύξηση της προσφοράς αλιευμάτων, οφείλουν να 
		χωροθετούνται με αυστηρούς όρους, αποφεύγοντας ευαίσθητα οικοσυστήματα (κλειστοί όρμοι).  Εκτός των άλλων  αποτελούν και τουριστική υποβάθμιση.</Typography>

		
		<Typography variant="h1" sx={theme.typography.typography1}>Ε.  ΕΡΗΜΟΠΟΙΗΣΗ – ΔΙΑΒΡΩΣΗ</Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>Αυξανόμενες επεμβάσεις στις ορεινές λεκάνες απορροής (αποδάσωση, οδοποιία στα βουνά, υπερβόσκηση, αυθαίρετη δόμηση)  επιδεινώνουν τα χειμαρρικά φαινόμενα με 
		αποτέλεσμα την έκπλυση του επιφανειακού γόνιμου εδάφους και την είσοδο στο φαύλο κύκλο της ερημοποίησης.  Αν λάβουμε υπόψη  και την επίπτωση του φαινομένου του 
		θερμοκηπίου, το θέμα οφείλει να αποτελέσει αντικείμενο μακροχρόνιας εθνικής πολιτικής, πρώτης προτεραιότητας που να περιλαμβάνει συστηματική προστασία της 
		βλάστησης και των αναβαθμίδων, σε επικλινή ιδίως εδάφη, και άλλα συναφή μέτρα.  Υποδειγματική παρέμβαση κατά της απώλειας εδάφους και νερού ήταν το πρόγραμμα 
		που εφάρμοσε ο Μ. Γλέζος στη Νάξο.  Θυμίζουμε ότι από την ερημοποίηση απειλείται το 35% της χώρας.</Typography>

		
		
		<Typography variant="h1" sx={theme.typography.typography1}>ΣΤ.  ΥΓΡΟΤΟΠΟΙ, ΔΑΣΗ</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Ένα πλήθος αυθαιρεσιών υποβαθμίζει τους υγρότοπους:  παράνομο κυνήγι, υπερβόσκηση, οδοποιία, καταπατήσεις.  Φορείς διαχείρισης (Φ.Δ.)  ιδρύθηκαν για ορισμένες 
		περιοχές, όχι για όλες, αλλά ούτε προεδρικά διατάγματα προστασίας και διαχείρισης υπάρχουν, ούτε οι Φ.Δ. χρηματοδοτούνται.  Μελέτες και φορείς (χωρίς εξουσίες 
		και χρήματα) δεν αρκούν, απαιτείται ανατροπή τετελεσμένων (καταπατήσεις κ.λ.π.) και εφαρμογή της νομοθεσίας.<br/>
        Σε ότι αφορά τα δάση οι πυρκαγιές, οι διεκδικήσεις και οι πιέσεις για οικοδόμηση διευκολύνονται από την έλλειψη δασολογίου.  Επαναλαμβανόμενες απόπειρες 
		παρατηρούνται για μείωση της προστασίας των δασών τόσο στο νομοθετικό όσο και στο συνταγματικό επίπεδο.  Ειδικότερα με προτεινόμενη τροποποίηση του συντάγματος 
		επιδιώκεται η διάκριση δασικών εκτάσεων και δασών, με στόχο την ικανοποίηση των πιέσεων των οικοδομικών συνεταιρισμών και τη συνολική νομιμοποίηση των προ του 
		1975 καταπατήσεων, με προφανή κίνδυνο απώλειας πλέον του ενός τρίτου των σημερινών δασών.<br/>
        Η πράξη παραχώρησης δασικής γης στο Καζίνο της Πάρνηθας και τα 2650 στρέμματα δασικής Νατούρα και παραλίας στην Ηλεία, αποδεικνύουν για μια ακόμα φορά το πόσο 
		επικίνδυνες για την Ελλάδα και τους Έλληνες είναι οι πολιτικές που ακολουθούν οι εκάστοτε πολιτικές ηγεσίες.</Typography>
				
		
		<Typography variant="h1" sx={theme.typography.typography1}>Ζ.  ΧΩΡΟΤΑΞΙΑ – ΤΟΥΡΙΣΜΟΣ</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η παντελής έλλειψη ολοκληρωμένου χωροταξικού σχεδιασμού, εντείνεται από την ανυπαρξία, μέχρι τώρα, του εθνικού κτηματολογίου και ανοίγει την όρεξη ολοένα 
		και περισσότερων αυθαιρετούντων, που ελπίζουν σε νομιμοποίηση των πράξεών τους, όπως πολλές φορές έγινε μέχρι σήμερα.  Από την άλλη ο μαζικός τουρισμός 
		υποβαθμίζει τους πολιτιστικούς και φυσικούς πόρους, τα οικοσυστήματα και καταναλώνει το φυσικό μας πλούτο.  Το νέο ειδικό χωροταξικό πλαίσιο για τον τουρισμό 
		εκρίθη απαράδεκτο από το Επιμελητήριο Περιβάλλοντος και Βιωσιμότητας.</Typography>
		
		
		<Typography variant="h1" sx={theme.typography.typography1}>Η.  ΑΚΤΕΣ</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Οι περιαστικές ακτές έχουν δεχτεί σοβαρά πλήγματα από την <b>οδοποιία, τις περιφράξεις από ιδιώτες και τον περιορισμό της πρόσβασης του κοινού και από τη διάβρωση.  
		</b> Ανησυχούμε σοβαρά για την διαδικασία ιδιωτικοποίησης δημοσίων ακταίων κτημάτων του Ε.Ο.Τ.  Το υπό διαβούλευση νομοσχέδιο για τον αιγιαλό, περιλαμβάνει διατάξεις 
		που περιορίζουν τον κοινόχρηστο χαρακτήρα των ακτών υπέρ της οικονομικής τους αξιοποίησης από ιδιωτικά συμφέροντα, με σοβαρές 
		επιπτώσεις τόσο στην δημόσια κτήση, όσο και στην ευαίσθητη βιοποικιλότητα των ακτών.</Typography>

		
		<Typography variant="h1" sx={theme.typography.typography1}>Θ.  ΕΝΕΡΓΕΙΑ</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Το ενεργειακό μοντέλο της χώρας παραμένει ακόμα εξαρτημένο από το λιγνίτη.  Οι εκπομπές αερίων του θερμοκηπίου από την παραγωγή ενέργειας και από τις μεταφορές 
		αντί να σταθεροποιούνται, αυξάνονται.  Ενώ η χώρα μας ήδη έχει ξεπεράσει τον εθνικό στόχο του πρωτοκόλλου Kyoto για τα αέρια απόβλητα, η προώθηση των ανανεώσιμων 
		πηγών είναι απαράδεκτα χαμηλά, ενώ εθνική στρατηγική εξοικονόμησης ενέργειας δεν υφίσταται.  Ο νέος νόμος για τις επενδύσεις σε ανανεώσιμες πηγές παρά τα θετικά 
		του, διατηρεί διατάξεις που εκτιμούμε ότι θα αποτελέσουν σημαντικό παράγοντα καθυστέρησης στην επίτευξη του στόχου του 20% συμμετοχής των ΑΠΕ στη χώρα μας στην 
		ηλεκτροπαραγωγή.  Κυριότερος από αυτούς είναι η μη επαρκής ενίσχυση της αιολικής ενέργειας.  Επιπλέον το εθνικό χωροταξικό σχέδιο για τη χωροθέτηση των σχετικών 
		εγκαταστάσεων δεν έχει ακόμα ολοκληρωθεί.</Typography>

		
		<Typography variant="h1" sx={theme.typography.typography1}>Ι.  ΓΕΩΡΓΙΑ</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η ελληνική γεωργία παρουσιάζει όλο και περισσότερο μη αειφορικά χαρακτηριστικά.  Μονοκαλλιέργειες βασισμένες στην υπερχρήση χημικών εισροών, υποβάθμιση 
		υδροφορέων, απώλεια εντόπιων ποικιλιών είναι ενδεικτικές της αύξουσας βλάβης που προκαλείται στα οικοσυστήματα και το καλλιεργήσιμο έδαφος.</Typography>

		
		<Typography variant="h1" sx={theme.typography.typography1}>ΙΑ.  ΑΛΛΑ ΠΡΟΒΛΗΜΑΤΑ</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Τα <b>διατροφικά προβλήματα</b> ενδιαφέρουν ιδιαίτερα τους Έλληνες πολίτες.  Τα διατροφικά σκάνδαλα διαδέχονται το ένα το άλλο.  Οι ελεγκτικοί μηχανισμοί 
		φαίνονται αδύναμοι να προστατεύσουν τους καταναλωτές.  Τα μεταλλαγμένα βρίσκονται ήδη στο πιάτο μας.  Η βιολογική γεωργία, παρά τα συγκριτικά πλεονεκτήματα της 
		χώρας μας, είναι πίσω σε σχέση με άλλες χώρες, ακόμα και της Βόρειας Ευρώπης.  Οι Έλληνες, κυρίως οι νέοι, απομακρύνονται από τη Μεσογειακή διατροφή, που αποτελεί 
		άμυνα και ασπίδα για την υγεία τους.
		
		Η <b>χημική ρύπανση</b> είναι μέσα στα σπίτια μας, και όχι μόνο, με διάφορες μορφές.  Το κενό στην Ελληνική νομοθεσία έρχεται ευτυχώς να συμπληρώσει η οδηγία 
		REACH για τα χημικά, που πρόσφατα επικυρώθηκε από το Ευρωπαϊκό Κοινοβούλιο.
		
		Οι <b>κεραίες κινητής τηλεφωνίας</b>, οι οποίες τοποθετούνται ασύδοτα και ανεξέλεγκτα από τις εταιρείες κινητής τηλεφωνίας, θυμίζουν τη Λερναία Ύδρα.  Μία 
		κατεβαίνει και δύο στήνονται.  Η πρόσφατη νομοθετική ρύθμιση, όχι μόνο δεν έλυσε το πρόβλημα, αλλά προφανέστατα το επιδείνωσε.  Αποτέλεσμα είναι η διαρκής «κόντρα» 
		θιγομένων πολιτών και Δήμων με της εταιρείες κινητής τηλεφωνίας.
		Τέλος τα <b>χρυσωρυχεία</b>, η πλέον χαρακτηριστική μορφή μη βιώσιμης δραστηριότητας, που ιδρύονται ενάντια στη βούληση των τοπικών κοινωνιών (Χαλκιδική, Θράκη – 
		Μήλος) αποτελούν σοβαρές αιτίες δηλητηρίασης εδαφών και νερών και γενικά υποβάθμισης του περιβάλλοντος και της ποιότητας ζωής των πολιτών.<br/><br/></Typography>
		
		<Typography variant="h1" sx={theme.typography.typography1}>Τα παραπάνω είναι μια σύνοψη των πιέσεων που αντιμετωπίζει το ελληνικό φυσικό αλλά και αστικό περιβάλλον.<br/>
		Το κυριότερο αίτημα των οργανώσεων του δικτύου στο πολιτικό επίπεδο, πέραν της ενσωμάτωσης των αρχών της βιωσιμότητας σε κάθε πολιτική, παραμένει η <b>εφαρμογή</b>
		των κειμένων διατάξεων και ο <b>έλεγχος των αποτελεσμάτων</b> της ασκούμενης πολιτικής.</Typography>
		
		</Box>

        <ScrollToTopButton />
    </>
  );
}

export default F18_oikologika_18;
