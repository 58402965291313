import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/karsi/epitelous.jpg";
import Image02 from "../assets/images/karsi/ola.jpg";

function D107_Karsi() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        <HomeIcon />
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Καρσί ήταν ίσαμε χθες η πατρίδα μας..." />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={{color:'blue'}}>
          '' ...τόσα φαρμάκια, τόση συμφορά κι εμένα ο νους θέλει να γυρίσει
          πίσω, πίσω στα παλιά. Αχ, νάταν λέει ψέμα όσα περάσαμε και να γυρίζαμε
          τώρα δα, στη γη μας, με τους μπαξέδες μας, στα δάση μας με τις
          καρδερίνες, τις κάργες και τα πετροκότσυφα, στα περιβολάκιαμας με τις
          ματζουράνες και τις ανθισμένες κερασιές, στα πανηγύρια μας με τις
          όμορφες...''
        </Typography>

        <Typography variant="h3" sx={{color:'blue'}}></Typography>

        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default D107_Karsi;
