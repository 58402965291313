import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g5/a1.jpg";
import Image02 from "../assets/images/mikrasiatika_g5/a2.jpg";
import Image03 from "../assets/images/mikrasiatika_g5/a3.jpg";

function G5_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Η διαδρομή ενός Μικρασιάτη" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στις παρακάτω σειρές θα προσπαθήσω να καταγράψω, όπως μου έχει
          αφηγηθεί η γιαγιά μου, τη ζωή της οικογένειας του προπάππου μου,
          μικρασιάτη πρόσφυγα, τόσο στη γενέτειρά του όσο και αργότερα στην
          Ελλάδα. Η ακαταμάχητη ψυχική δύναμη όλων αυτών των ανθρώπων είναι κάτι
          που συγκλονίζει όλους εμάς τους νεώτερους και μας κάνει υπερήφανους
          για τις ρίζες μας.Ο προπάππους μου Δημοσθένης Δίγκας γεννημένος στο
          Αδά Τεπέ της Μικράς Ασίας ήταν ένα από τα έξι παιδιά ενός
          μεγαλοκτηματία που ζούσαν άνετα χωρίς να τους λείπει τίποτα στην
          πατρίδα τους. Από μικρός ήταν άνθρωπος των γραμμάτων γι’ αυτό
          ακολούθησε τον μεγαλύτερο αδελφό του στη Σμύρνη, όπου εγγράφεται στην
          Ευαγγελική Σχολή από όπου παίρνει το απολυτήριό του. Ο αδελφός του
          Αλέξανδρος, άτομο με επιχειρηματικό πνεύμα ιδρύει εργοστάσιο
          αρωματοποιίας στη Σμύρνη.
        </Typography>

        <img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η επιχείρηση αυτή γνώρισε μεγάλη άνθιση και ξεπέρασε τα όρια της
          Μικράς Ασίας με εξαγωγές στο εξωτερικό. Όταν τελειώνει ο προπάππους
          μου την Ευαγγελική Σχολή αναλαμβάνει τα οικονομικά της επιχείρησης. Η
          όλη τους ευημερία δεν ήταν γραφτό να κρατήσει πολύ. Όταν ο αέρας στη
          Σμύρνη άρχισε να βαραίνει και σ’ ολόκληρη τη Μικρά Ασία άρχισε να
          βαραίνει, ο μεγαλύτερός του αδελφός ναυλώνει καράβι και μαζί με την
          οικογένειά του αποφασίζουν να φύγουν για τη Μυτιλήνη. Μάταια
          προσπάθησαν να πείσουν τον προπάππου μου και τον μικρότερό τους αδελφό
          να φύγουν μαζί τους. Ο προπάππους μου ήθελε να δει τους γονείς του και
          τις αδελφές του στο χωριό τους. Αποφασίζει να γυρίσει πίσω. Με δάκρυα
          στα μάτια αποχαιρετά τον αδελφό του και την οικογένειά του στην
          προκυμαία και παίρνει το δρόμο με χίλιες δυο προφυλάξεις για το χωριό
          του.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το θέαμα που αντίκρισε, όπως μου περιγράφει η γιαγιά μου ήταν
          συγκλονιστικό. Δεν έβλεπες τίποτε άλλο από σύννεφο σκόνης και φωτιάς
          που κατέκαιε ότι είχε απομείνει. Η ψυχή του κουρελιάζεται, οι δυνάμεις
          του τον εγκαταλείπουν. Συνειδητοποιεί ότι είχε χάσει τα πολυτιμότερα
          πλάσματα στον κόσμο, τους γονείς του και τις αδελφές του με τις
          οικογένειές τους. Μαζεύοντας τα όποια απομεινάρια της ψυχικής δύναμης
          του γυρνάει στη Σμύρνη όπου τον περιμένει ο μικρότερός αδελφός του.
          Πρέπει να φύγουν το γρηγορότερο. Στην προκυμαία της Σμύρνης δεν
          ακούγεται τίποτε άλλο από κλάμα και σπαραγμό. Οι στιγμές τραγικές, ο
          πόνος μεγάλος. Σε κάποια στιγμή βρίσκεται μόνος του μέσα σ’ ένα καΐκι
          χωρίς τον μικρό του αδελφό. Δεν μπορεί να καταλάβει πότε έφυγε από
          κοντά του αλλά ελπίζει ότι θα τον συναντήσει στη Μυτιλήνη. Στην
          Μυτιλήνη συναντιέται με τον μεγαλύτερό του αδελφό που τον περιμένει.
          Διαπιστώνουν ότι ο άλλος τους αδελφός δεν έφτασε στο νησί. Η
          στεναχώρια τους μεγάλη, όμως η ελπίδα που φωλιάζει πάντα στην ψυχή
          μας, τους δίνει το κουράγιο να σταθούν και πάλι στα πόδια τους.
          Ανοίγουν πάλι βιοτεχνία αρωματοποιίας στην Επάνω Σκάλα, που όμως μετά
          από πυρκαγιά αποτεφρώνεται. Δεύτερο μεγάλο πλήγμα στην οικογένεια. Ο
          μεγαλύτερος αδελφός τους φεύγει για την Αθήνα μαζί με την οικογένειά
          του, αν και κατεστραμμένος γεμάτος από αισιοδοξία και φιλόδοξα σχέδια
          που δεν μπόρεσε όμως να πραγματοποιήσει.
        </Typography>

        <img src={Image03}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο προπάππους μου δημιουργεί οικογένεια και αποκτά μια κόρη, τη γιαγιά
          μου. Στα χρόνια του πολέμου φεύγει στην Αθήνα και προσλαμβάνεται ως
          αρχιλογιστής στα εργοστάσια ρουχισμού, που προοριζόταν για τον στρατό,
          των Κοσμίδη – Πουλακάκη από όπου πήρε τη σύνταξή του. Μετά από περίπου
          30 χρόνια από την Μικρασιατική Καταστροφή μέσω του Ερυθρού Σταυρού
          συναντιόνται με τον μικρότερό αδελφό του Ευστράτιο, που βρέθηκε στην
          Αμερική όπου και παντρεύτηκε. Οι σκηνές που διαδραματίστηκαν πάνω στην
          προκυμαία της Μυτιλήνης ήταν απερίγραπτες, όπως μου διηγείται η γιαγιά
          μου. Τα δυο αδέλφια να κλαιν αγκαλιασμένα στην προκυμαία, τούτη όμως
          τη φορά από χαρά. Αυτή ήταν η διαδρομή του προπάππου μου, που θα
          μοιάζει σε πολλές περιπτώσεις με τη ζωή πολλών άλλων συμπατριωτών του.
          Σ’ όλες αυτές τις ιστορίες όμως αυτό που καταγράφεται έντονα είναι ο
          αλτρουισμός και η ψυχική δύναμη αυτών των ανθρώπων, που μέσα στις
          τόσες συμφορές τους, μπόρεσαν να σταθούν όρθιοι, να δώσουν τον αγώνα
          της ζωής και να διακριθούν με τέτοιο τρόπο που μας κάνει όλους εμάς
          τόσο υπερήφανου.
        </Typography>
      </Box>

      <ScrollToTopButton />
      
    </>
  );
}

export default G5_mikrasiatika;
