import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/17sinedriopandoiko_01.jpg";
import Image02 from "../assets/images/oikologika/17sinedriopandoiko_02.jpg";
import Image03 from "../assets/images/oikologika/mamidakis3.jpg";

function G1_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ο εκ Καισαρίας Παλαντζής και η δωρεά αυτού προς ανέγερσιν Πανεπιστημίου εν Σμύρνη" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Καθ’ όν χρόνον αντεπροσώπευα την πατρίδα μου εν Χάγη και ακριβώς κατά
          το 1919, μου συνέβη να δεχθώ την επίσκεψιν Έλληνος, προερχομένου εκ
          των ενδοτέρων τής Ασίας μερών, τής Καισαρείας «Άγιο Βασίλη τόπο»
          Έλληνος, λέγω, ίσως ουδέ¬ποτε επισκεφθέντος την Ελλάδα, αι σκέψεις και
          οι εθνικοί σκοποί του όποιου τόσον διέθρυψαν τα αι¬σθήματα
          υπερηφανείας τής φυλής μας, ώστε να θεωρώ σήμερον ιδιαιτέραν
          ευχαρίστησιν και καθή¬κον, να κάμω κοινωνούς αυτών και τούς φιλογενεϊς
          αναγνώστας μου.
          <br />
          Μετά πάροδον ολίγου χρόνου από τής κατά Ιού¬νιον του 1919 υπογραφής
          τής Συνθήκης των Σεβρών, ο κλητήρ τής Πρεσβείας Χάγης, μοι ανήγγειλε
          την εις τον προθάλαμον άναμονήν ενός κυρίου, όστις επεθύμει να με ίδη
          κατ’ ιδίαν. Ό κύριος εισέρχεται εις το γραφείον μου, δηλώνει το όνομά
          του, «Παλαντζής εκ Καισαρείας» και με μίαν ελληνοτουρκικήν διάλεκτον
          και τουρκικήν προφοράν, με ερωτά, αν προτιμώ αντί τής ελληνικής, την
          οποίαν δυσκόλως ομιλεί, να μεταχειρισθεί την Γαλλικήν. Εις την
          απάντησίν μου, ότι προτιμώ την Ελληνικήν, έστω και δυσκατάληπτον, μου
          λέγει τα έξής :<br /><br />
          —Εγκώ τέλει βλέπει Βενίζέλο δώση περιουσία, μου...
          <br />
          --Πολύ καλά, του απαντώ, θα σας δώσω μίαν συστατικήν επιστολήν και θα
          μεταβήτε εις Παρισίους να τον ιδήτε.
          <br />
          —Εγκώ ντε μπορεί περάση Βελγικά σύνορα… <br />
          —Και γιατί; <br />
          —Έρχομαι, μου λέγει με τουρκικήν πάντοτε προφοράν, ην παραλείπω να
          απομιμηθώ, και μισοενοουμένας λέξεις, από τάς Βρυξέλλας, όπου
          μετηρχόμην τον καπνέμπορον. Μετά την λήξιν του πολέ¬μου και την
          υποχώρησιν των γερμανικών στρατευ¬μάτων, κατηγορήθην από άνθρωπον του
          αυτού επαγγέλματος, εις τάς Βελγικάς Αρχάς ότι κατά την υπό των
          Γερμανών κατοχήν του Βελγίου, επρομήθευα κα¬πνά εις αυτούς και δια την
          πράξιν μου αυτήν οι Βέλ¬γοι με απήλασαν, ώστε αν μεταβώ εις Παρισίους,
          θα περάσω κατ' ανάγκην τα Βελγικά σύνορα και εκεί και πάλιν θα με
          συλλάβουν.
          <br />
          —θα σάς δώσω διπλωματικόν διαβατήριον.
          <br />
          —Έχω και την γυναίκα μου, ή οποία, καίτοι Ολλανδίς, θέλει και αύτή να
          δώση την περιουσίαν της εις την Ελλάδα.
          <br />
          —Το διπλωματικόν διαβατήριον θα αναφέρη και την γυναίκα σας. Επειδή
          όμως με κυτούσε χωρίς να ομιλή, αντελήφθην ότι και με το διπλωματικόν
          διαβατήριον, έδίσταζε να επιχειρήση μόνος του το εις Παρισίους
          ταξίδιον, πάντοτε εκ φόβου, μήπως και πάλιν συλληφθή υπό των Βέλγων
          και απεφάσισα να τούς εκδόσω το διπλωματικόν διαβατήριον, άλλά και να
          τούς συνοδεύσω ο ίδιος μέχρι Παρισίων.
          <br />
          Και πράγματι, την επομένην, ο Παλαντζής, ή γυναίκα του και εγώ,
          επεβιβάσθημεν τής πρωινής αμαξοστοιχίας και την πέμπτην
          μεταμεσημβρινήν ώραν ευρισκόμεθα εις το παρά το Arc de Triomphe de L
          Etoile ξενοδοχείον Mercedes, ένθα ή ελληνική αποστολή είχε καταλύση.
          Τό βράδυ κατεβήκαμεν εις την τραπεζαρίαν, ένθα διερχόμενος ο τότε
          Πρωθυπουργός προ ημών, μας επλησίασε, μάς εχαιρέτησε και μετά τάς
          τυπικάς παρουσιάσεις, μας ώρισε την επομένην δια την τηλεφωνικώς εκ
          Χάγης ζητηθείσαν συνέντευξιν. Και πράγματι, την επομένην περί την
          ενδεκάτην πρωϊνήν ώραν, εισήλθομεν εις το γραφείον του, ένθα εύρομεν
          τον Πρωθυπουργόν συνεργαζόμενον μετά του κ. Ν. Πολίτου. Ο κ. Παλαντζής
          προ του Πρωθυπουργού, ποιών το σημείον του Σταυρού και απαγγέλλων το
          «Άγκιο ο Τεό, Άγκιο ισκυρό, Άγκιο άτάνατο κλπ.», πέφτει και του φιλεί
          τα πόδια. Η σκηνή δραματικωτάτη, εγώ να κλαίω σαν μωρό παιδί, ιδίως
          όταν εγειρόμενος και αποτεινόμενος προς τον Πρωθυπουργόν του λέγει:
          <br />
          —Εγκώ έχει τριάντα εκατομμύρια, τέλει ντόσει εσένα!
          <br />
          —Τα έχεις μαζί σου ; τον έρωτα ο Πρωθυ¬πουργός.
          <br />
          —Όχι, έχω μετοχάς κατατεθειμένας εις τάς Τραπέζας, αι οποίαι δεν
          πρέπει να εκποιηθούν τώρα, διότι είναι εις εκπεσμόν.
          <br />
          —Πολύ καλά, και πόσα μπορείς να δώσης τώρα εις μετρητά ;<br />
          —Δύο εκατομμύρια φράγκα.
          <br />
          —Έχει καλώς... φθάνουν... δι’ αυτών θα κτίσωμεν ένα Πανεπιστήμιον
          ανωτέρων εμπορικών και βιομηχανικών σπουδών εν Σμύρνη. Και
          αποτεινόμενος εκ νέου προς τον Παλαντζήν τον έρωτά, πόσα ημπορεί να
          διαθέση τώρα προς συντήρησίν του.
          <br />
          —250 έως 300 χιλιάδας το έτος.
          <br />
          Εν τω μεταξύ όμως επεμβαίνει ο κ. Πολίτης και υποβάλλει εις τον
          Πρωθυπουργόν την ιδέαν να εκλεγή ή Θεσσαλονίκη, ως τόπος ιδρύσεως
          αυτού του Πανεπιστημίου.
          <br />
          —Όχι, απαντά με τόνον ο Πρωθυπουργός,- πρέπει να γίνη στη Σμύρνη,
          πρέπει να δείξουμε, στον κόσμο, ότι ο Ελληνισμός τής Ασίας είνε
          ενθουσιασμένος πού απέκτησε την ελευθερία του και εξακολουθεί να
          διατηρή αμείωτον την αφοσίωσίν του προς την μητέρα πατρίδα. Και
          στρεφόμενος προς εμέ, μου παραγγέλλει να προβώμεν αμέσως εις την
          σύνταξιν συμβολαίου. ΕΙς τούτο, επεμβαίνων ό κ. Πολίτης, συνιστά να
          επιληφθώμεν από κοινού την επεξεργασίαν τούτου την επομένην.
          <br />
          Το βράδυ επήγαμεν να κατακλιθώμεν εις τα διαμερίσματά μας, τα οποία
          απετελούντο από δύο δω¬μάτια ύπνου, λουτήρα και κοινόν γραφείον. Την
          επομένην, λίαν πρωί, ανοίγων τα μάτια μου, τον βλέπω εις το γραφείον
          εργαζόμενον.
          <br />
          —Τι κάνεις, τον ερωτώ, εκεί ;<br />
          —Κάνω την διαθήκην μου, μου απαντά και μου προσθέτει ότι αφήνει
          εκτελεστάς τής διαθήκης τον εν Λονδίνω δικηγόρον κ. Τζών ΣταυρΙδην και
          εμέ δια την εκτέλεσιν των τελευταίων βουλήσεων του και ότι δια την
          εργασίαν αυτήν χορηγεί εις έκαστον εξ ημών των δύο το ποσόν των
          διακοσίων χιλιάδων Φράγκων. —Εμένα να με σβύσης, του λέγω. Το είπα
          όμως φαίνεται με τέτοιο τόνο, ώστε να προκαλέσω την δικαίαν
          διαμαρτυρίαν του.
          <br />
          —Τι κάνει εσύ έτσι, μου λέγει, εγκώ κλέφτη ντεν ίμε, αν εσύ ντέ τέλει,
          εγκώ αφήση Τράπεζα πεντιά σου.
          <br />
          —Δεν θέλω, του λέγω, επιμένων, απολύτως τί¬ποτε, ούτε για μένα, ούτε
          για τα παιδιά μου, ο Τζών Σταυρίδης υπεραρκεί. Ούτω και εγένετο. Εις
          το κεί¬μενον των τελευταίων βουλήσεών του, δεν ανεφέρετο παρά
          αποκλειστικώς το όνομα του φίλου δικη¬γόρου κ. Σταυρίδη.
          <br />
          Την επομένην και μετά την υπό του κ. Πολίτου σύνταξιν του δωρητηρίου
          συμβολαίου, ανεχώρησα δια Ρουμανίαν και μετά ένα μήνα αύτόθι διαμονής,
          επέστρεψα εις την εν Χάγη θέσιν μου. Μετά πάροδον ολίγου χρόνου,
          εδεχόμην την επίσκεψιν ενός εκ των πλέον διακεκριμένων Ελλήνων εν
          Γερμανία, των εξαιρετικά τιμώντων το ελληνικόν όνομα εν τη ξένη, του
          κ. Καραθεοδωρή, ο όποιος μοί εζήτησε συστατικήν επιστολήν δια τον
          Πρωθυπουργόν, ην και προθυμότατα εχορήγησα. Κατόπιν επληροφορήθην, ότι
          ο κ. Καραθεοδωρής, παρά την εξέχουσαν θέσιν ην είχεν εις ένα των εν
          Γερμανία Πανεπιστημίων, προθύμως συνήνεσε παρακληθείς να την
          εγκαταλείψη και να δεχθή την προταθείοαν αυτώ θέσιν του Πρυτάνεως του
          νέου εν Σμύρνη Πανεπιστημίου, του οποίου η ανοικοδόμησις και η
          επίπλωσις είχον τότε επιοπευσθή προς την ταχυτέραν λειτουργίαν, άνευ
          όμως αποτελέσματος, λόγω των μετά ταύτα γεγονότων. <br />
          <br />
          <i>Από το βιβλίο «Αναμνήσεις διπλωμάτου» του κ. Εμμ. Γ. Καψάμπελη</i>
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default G1_mikrasiatika;
