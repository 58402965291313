import React from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import ScrollToTopButton from '../components/ScrollToTopButton';
import Header1 from '../components/Header1';
import HomeIcon from '@mui/icons-material/Home'
import { commonBoxStyles, headerBoxStyles } from '../components/CommonStyles'

import Image01 from '../assets/images/drastiriotites2008/xoroesperida.jpg'
import Image02 from '../assets/images/drastiriotites2008/theofania2008.jpg'
import Image03 from '../assets/images/drastiriotites2008/dentrofiteusi.jpg'
import Image04 from '../assets/images/drastiriotites2008/limanaki.jpg'
import Image05 from '../assets/images/drastiriotites2008/apoxilosi.jpg'
import Image06 from '../assets/images/drastiriotites2008/theofania_sl.jpg'
import Image07 from '../assets/images/drastiriotites2008/ekthema_mouseio.jpg'
import Image08 from '../assets/images/drastiriotites2008/katharismos_paralias.jpg'
import Image09 from '../assets/images/drastiriotites2008/pasxa.jpg'
import Image10 from '../assets/images/drastiriotites2008/protomagia.jpg'
import Image11 from '../assets/images/drastiriotites2008/basileuousa.jpg'
import Image12 from '../assets/images/drastiriotites2008/egkainia_bibliothiki.jpg'
import Image13 from '../assets/images/drastiriotites2008/egkainia_bibliothiki_2.jpg'
import Image14 from '../assets/images/drastiriotites2008/therini_xoroesperida.jpg'
import Image15 from '../assets/images/drastiriotites2008/ekdiloseis_mnimis.jpg'
import Image16 from '../assets/images/drastiriotites2008/panagia_apsili.jpg'
import Image17 from '../assets/images/drastiriotites2008/triantafillo.jpg'
import Image18 from '../assets/images/drastiriotites2008/episkepsies_mouseio.jpg'
import Image19 from '../assets/images/drastiriotites2008/mousiko_tmima.jpg'
import Image20 from '../assets/images/drastiriotites2008/proistoriki_thesi.jpg'
import Image21 from '../assets/images/drastiriotites2008/photos01.jpg'
import Image22 from '../assets/images/drastiriotites2008/photos02.jpg'
import Image23 from '../assets/images/drastiriotites2008/imerologio_2009.jpg'
import Image24 from '../assets/images/drastiriotites2008/thima_kitharas.jpg'
import Image25 from '../assets/images/drastiriotites2008/tmina_paradosiakon_xoron.jpg'
import Image26 from '../assets/images/drastiriotites2008/eutixes.jpg'

function D5_drastiriotites_2008() {

    const theme = useTheme();

    return (
        <>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ ...headerBoxStyles }}>
                <Header1 props="Οι δραστηριότητές μας το 2008" />
            </Box>

            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Η Χοροεσπερίδα μας την παραμονή της Πρωτοχρονιάς στο ξενοδοχείο ΖΑΙΡΑ</Typography>
                <img src={Image01} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Την παραμονή της Πρωτοχρονιάς, στις 10 το βράδυ πραγματοποιήθηκε, όπως κάθε χρόνο με μεγάλη επιτυχία, η χοροεσπερίδα του Συλλόγου μας στο ξενοδοχείο ΖΑΙΡΑ.
                    Με την αλλαγή του χρόνου και αμέσως  μετά τις 12.00 κόπηκε  η βασιλόπιτα του Συλλόγου μας με τις ευλογίες του ιερέα του χωριού μας. Τυχερή της βραδιάς ήταν ο κ.
                    Κοντάρας Ιγνάτης ο οποίος με τον τυχερό  κέρδισε το φλουρί της πίτας , ένα χρυσό Κωσταντινάτο. Στην συνέχεια έγινε δεύτερη κλήρωση ένα  κόσμημα προσφορά από το
                    κατάστημα του κ. Γιώργου Λαουτάρη το οποίο κέρδισε η κ. Μαρικούλα Χατζηλάμπρου   και τέλος κληρώθηκε ένα κιβώτιο κρασιά Λήμνου προσφορά του ξενοδοχείου ΖΑΙΡΑ.
                    Στην εκδήλωση παραβρέθηκαν και μας τίμησαν με την παρουσία τους, ο αντινομάρχης Λέσβου κ. Σπύρος Γαληνός, ο διευθυντής της εφημερίδας Δημοκράτης Μυτιλήνης κ. Κίνδερλης
                    ,ο πρόεδρος του Συλλόγου Πελοπονησίων Λέσβου κ. Αντωνόπουλος, η πρόεδρος του Πολιτιστικού κέντρου Λουτρών κ. Κομνηνού - Τατάκη, ο προϊστάμενος του ΟΤΕ κ. Λεοντής,
                    πρόεδροι τοπικών Συλλόγων καθώς και  φίλοι μας από τη Μυτιλήνη και τα χωριά της Γέρας.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Τέλος μηνύματα μας έστειλαν και διαβάσαμε ο Νομάρχης Λέσβου κ. Παύλος Βογιατζής
                    και ο βουλευτής Λέσβου κ. Ιωάννης Γιανέλλης.. Κατά την διάρκεια της εκδήλωσης διενεργήθηκε λαχειοφόρος αγορά με πολλά και πλούσια δώρα , όλα προσφορές των
                    καταστημάτων και των επιχειρήσεων της Μυτιλήνης και του χωριού μας. Δώρα στα παιδιά μοίρασε ο Αι Βασίλης ο γνωστός μας Γιάννης Μολυβιάτης τον οποίο και ευχαριστούμε
                    θερμά για την διαχρονική προσφορά του στις εκδηλώσεις του Συλλόγου μας. Δώρα στους παρευρισκόμενους πρόσφερε και ο Σύλλογός μας  από ένα εξαίσιο μπρελόκ ως αναμνηστικό.
                    Η εξυπηρέτηση του ξενοδοχείου ήταν πολύ καλή και η ορχήστρα του κ. Ευαγγέλου Χάρη, με την συμμετοχή  του Λευτέρη Παπαμακαρίου και των λοιπών μελών της ορχήστρας
                    κράτησε αμείωτο το κέφι και το γλέντι μέχρι τις πρωινές ώρες. Και του χρόνου με υγεία.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εορτή των Θεοφανίων στην Χαραμίδα</Typography>
                <img src={Image02} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Μετά τον Αγιασμό των υδάτων που έγινε στη Σκάλα Λουτρών ο ιερέας του χωριού μας και όσοι άλλοι παρευρίσκονταν, ξεκίνησαν για να αγιάσουν τα ύδατα και
                    στην περιοχή της Χαραμίδας. Αυτό είναι ένα νέο σχετικά έθιμο που ξεκίνησε πριν από έξη χρόνια με πρωτοβουλία του Εξωραϊστικού Συλλόγου Χαραμίδας, και χρόνο με το
                    χρόνο καθιερώνεται όλο και περισσότερο αφού φέτος ο κόσμος που συμμετείχε ξεπέρασε κάθε προηγούμενο. Εκεί λοιπόν κάτω από την γνωστή ταβέρνα του κ. Παναγιώτη
                    Βουτσαρδέλλη  περίμεναν τα μέλη του τοπικού Συλλόγου  και οι κάτοικοι της περιοχής για να γίνει  ο καθιερωμένος πλέον Αγιασμός των υδάτων και στην συνέχεια το
                    κόψιμο της Βασιλόπιτας. Τον σταυρό ανέσυρε από την Θάλασσα ο κ Σωτήρης Μαμουνής γνωστό μέλος τόσο του Συλλόγου μας όσο και του Συλλόγου της Χαραμίδας.
                    Επακολούθησε το κόψιμο της πίτας και το σχετικό κέρασμα σε όλους τους παρευρισκόμενους με  πρωτεργάτες τα μέλη του Συλλόγου και την κ. Άσπα Αβαγιανού.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Δενδροφύτευση στη Χαραμίδα  </Typography>
                <img src={Image03} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας "ΤΟ ΔΕΛΦΙΝΙ", από κοινού με τον  Φυσιολατρικό Όμιλο Μυτιλήνης ΥΔΑΤΙΝΟΣ, τον Φιλοζωικό Σύλλογο Μυτιλήνης "Ο ΠΑΝ" και το
                    Πολιτιστικό Κέντρο του Δ.Δ. Λουτρών, προχώρησαν την Κυριακή 27/1/2008 και ώρα 09:30 σε δενδροφύτευση - θαμνοφύτευση στη καμένη περιοχή του δάσους στην Χαραμίδα
                    της Αμαλής.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Επιστολή για το λιμανάκι</Typography>
                
                <img src={Image04} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>
                <b>Προς:</b>   Το Λιμενικό Ταμείο Λέσβου<br />
                Υπ όψιν προέδρου κ. Πελαγίας Ανδριώτου<br />
                <b>Κοιν.</b>   Νομάρχη Λέσβου κ. Παύλο Βογιατζή<br /><br/>

                Κυρία πρόεδρε του Λιμενικού Ταμείου Λέσβου, με την παρούσα επιστολή θέλουμε να σας ενημερώσουμε για ένα πρόβλημα σημαντικό κατά την άποψή μας
                    που θα προκύψει εάν δεν παρέμβετε εγκαίρως  να το τακτοποιήσετε.<br />
                    Συγκεκριμένα παραπλεύρως από το ξενοδοχείο ΖΑΙΡΑ στη Σκάλα Λουτρών έχουν τοποθετηθεί εδώ και μια δεκαετία περίπου τα μπλόκια που θα χρησιμοποιηθούν στην προβλήτα
                    του λιμανιού του οικισμού μας. Όμως ο ιδιοκτήτης του ξενοδοχείου ΖΑΙΡΑ κ. Εμ. Τζανέτος που έχει ενοικιάσει από το Ίδρυμα Πέτρου Μάνδρα αυτό το χώρο όπου σήμερα
                    ευρίσκονται  τα μπλόκια έχει ήδη ξεκινήσει έργα διαμόρφωσης για να τον μετατρέψει σε αύλιο χώρο του ξενοδοχείου του με αποτέλεσμα τα μπλόκια αυτά να εγκλωβιστούν.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Σε επαφή μας με τον Νομάρχη Λέσβου κ. Παύλο Βογιατζή μας ενημέρωσε ότι τα έργα στο λιμανάκι θα ξεκινήσουν το νωρίτερο το προσεχές φθινόπωρο με αποτέλεσμα τότε,
                    να μην υπάρχει η δυνατότητα να μεταφερθούν αυτά στον προορισμό τους η να χρειασθεί να προκληθούν προστριβές και μεγάλες ζημιές  στο χώρο που ήδη διαμορφώνετε.<br />
                    Κυρία πρόεδρε επειδή τα έργα στο λιμανάκι είναι πολύ σημαντικά για τους κατοίκους και τους ψαράδες  του οικισμού μας, σας παρακαλούμε να φροντίσετε άμεσα για την
                    επίλυση του προβλήματος αυτού έτσι ώστε να μεταφερθούν εγκαίρως αυτά τα μπλόκια από το μέρος όπου ευρίσκονται και να μην προκύψουν μελλοντικά προβλήματα στην
                    εκτέλεση των έργων στο λιμανάκι της Σκάλας Λουτρών.<br /><br />
                    Ευελπιστώντας στην θετική σας ανταπόκριση σας ευχαριστούμε θερμώς εκ των προτέρων.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Επιστολή για την αποψίλωση των δεξαμενών καυσίμων</Typography>
                <img src={Image05} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>
                <b>Προς:</b> τον  Νομάρχη Λέσβου κ. Παύλο Βογιατζή<br /><br />
                Κύριε Νομάρχα,<br/><br/>
                Επικοινωνούμε μαζί σας για να σας παρακαλέσουμε να μεσολαβήσετε στην επίλυση ενός χρονίζοντος προβλήματος της περιοχής μας.
                    Συγκεκριμένα όπως ασφαλώς γνωρίζετε με τελεσίδικη απόφαση του Σ.τ.Ε. καθώς και με παρέμβαση της Ευρωπαϊκής Κοινότητας σφραγίστηκαν από τον Δεκέμβριο του 2002 οι
                    εγκαταστάσεις καυσίμων  της εταιρείας ΕΚΟ - ΕΛΔΑ  (πρώην ΜΑΜΙΔΑΚΗ)  οι οποίες ευρίσκονται μέσα στον κόλπο της Γέρας (περιοχή NATURA2000) και εφάπτονται των ορίων του
                    οικισμού μας της Σκάλας Λουτρών.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Όμως ενώ θα έπρεπε η εταιρεία ΕΚΟ να είχε προβεί στην αποξήλωσή τους όλο αυτό το διάστημα των 6 περίπου ετών που έχουν μεσολαβήσει, η προαναφερθείσα εταιρεία δεν
                    έχει πράξει μέχρι σήμερα τίποτα απολύτως με αποτέλεσμα αφ ενός να ρυπαίνετε το έδαφος από τις σκουριές των δεξαμενών και των σωληνώσεων που υπάρχουν εδώ και αφ
                    εταίρου να δημιουργούν σοβαρό πρόβλημα οπτικής ρύπανσης μέσα σε έναν οικότοπο και ακριβώς δίπλα στον οικισμό μας.
                    Συγκεκριμένα έχουν διατυπωθεί προς το Σύλλογό μας πολλά παράπονα από τους κατοίκους και κυρίως από τους τουρίστες που επισκέπτονται την περιοχή μας και το
                    παραπλεύρως ξενοδοχείο ΖΑΙΡΑ οι οποίοι (τουρίστες) προβληματίζονται και κυρίως φοβούνται για ένα πιθανό ατύχημα επειδή δεν γνωρίζουν ότι αυτές οι δεξαμενές είναι
                    άδειες και ότι οι εγκαταστάσεις δεν λειτουργούν πλέον.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ύστερα από τα προαναφερθέντα σας παρακαλούμε να μεσολαβήσετε  ώστε να ενημερωθεί η εταιρεία ΕΚΟ-ΕΛΔΑ από την Νομαρχία για τα προβλήματα που δημιουργούνται εδώ από
                    τις εγκαταστάσεις της και να μας γνωστοποιήσει τις προθέσεις της, για το εάν και πότε θα προχωρήσει στο ξήλωμα αυτών των εγκαταστάσεων, έτσι ώστε και εμείς να
                    γνωρίζουμε πως θα κινηθούμε μελλοντικά για την αντιμετώπιση αυτών των σοβαρών προβλημάτων που υποβαθμίζουν το περιβάλλον και ζημιώνουν τους επιχειρηματίες της
                    περιοχής μας.<br /><br />
                    Ευελπιστώντας στην θετική σας ανταπόκριση σας ευχαριστούμε θερμώς εκ των προτέρων.</Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Από τον ΟΑΕΔ νέο πρόγραμμα  STAGE  στο πλαίσιο των ΠΕΠ</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Εγκρίθηκε από τον ΟΑΕΔ η απασχόληση δύο ατόμων με το πρόγραμμα STAGE στο Σύλλογό μας τα οποία θα εργασθούν το ένα στην βιβλιοθήκη μας και το άλλο
                    στο Μουσείο
                    Προσφυγικής Μνήμης 1922 για απόκτηση εργασιακής εμπειρίας. Η εργασία που ήδη τους έχει ανατεθεί είναι η καταγραφή των βιβλίων τόσο της βιβλιοθήκης όσο και του
                    Μουσείου σε ειδικό πρόγραμμα ηλεκτρονικού υπολογιστή καθώς και η καταγραφή των εκθεμάτων του Μουσείου μας. Επίσης μέσα στο ωράριό τους θα είναι και η υποχρέωση
                    εξυπηρέτησης των επισκεπτών μας στο Μουσείο και του κοινού στην Βιβλιοθήκη μας.
                    Η διάρκεια του προγράμματος είναι 9 μήνες και αφορά σε 1 μήνα θεωρητική και πρακτική εξοικείωση με το εργασιακό περιβάλλον και 8 μήνες για απόκτηση εργασιακής
                    εμπειρίας. Το πρόγραμμα ξεκίνησε στις 28 Φεβρουαρίου και θα λήξει στις 27 Νοεμβρίου του 2008.</Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εορτή των Θεοφανίων στη Σκάλα Λουτρών</Typography>
                <img src={Image06} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Όπως κάθε χρόνο έτσι και φέτος, την ημέρα των Φώτων με την συμμετοχή των μελών του Συλλόγου μας , του Τοπικού Συμβουλίου Λουτρών, και
                    πολλών κατοίκων του χωριού μας έγινε στην προβλήτα του Αγίου Γεωργίου στη Σκάλα Λουτρών ο Αγιασμός των υδάτων και η ρίψη του Τιμίου Σταυρού στη θάλασσα με
                    την συμμετοχή αρκετών κολυμβητών  οι οποίοι αψήφησαν το κρύο και βούτηξαν κατά πως επιτάσσει το έθιμο. Φέτος, τυχερός νεαρός που ανέσυρε το σταυρό από τη θάλασσα
                    ήταν ο Άγγελος Μήνυκνας που παρέλαβε και το δώρο του, ένα χρυσό σταυρό με καδένα προσφορά του Πολιτιστικού κέντρου Λουτρών. Του ευχόμαστε ολόψυχα να είναι γερός
                    και πάντα να συμμετέχει σ αυτό το έθιμο.<br /><br /><br /></Typography>
            </Box>
            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Νέα εκθέματα στο Μουσείο Προσφυγικής Μνήμης 1922</Typography>
                <img src={Image07} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Συνεχίστηκε και εφέτος η προσφορά αξιόλογων αντικειμένων από Μικρασιάτες πρόσφυγες στο «Μουσείο Προσφυγικής Μνήμης 1922». Ήδη έχουμε ξεκινήσει
                    την επαναξιολόγηση και την ανακατανομή των αντικειμένων που έχουμε στις βιτρίνες του Μουσείου με την προοπτική να μπορέσουν να στηθούν και τα νέα εκθέματα τα
                    οποία ομολογουμένως θα εντυπωσιάσουν τους επισκέπτες μας.</Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Ετήσια τακτική Γενική Συνέλευση.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε με επιτυχία η ετήσια τακτική γενική συνέλευση των μελών του Συλλόγου μας την Κυριακή 20 Απριλίου 2008 κατά την διάρκεια της
                    οποίας έγινε ο διοικητικός και ο οικονομικός απολογισμός. Συζητήθηκαν τα τρέχοντα θέματα, έγιναν διάφορες προτάσεις και στο τέλος έγινε η παρουσίαση της
                    Ιστοσελίδας του Συλλόγου μας καθώς και προβολή ενός ντοκιμαντέρ σχετικού με την Μικρά Ασία.</Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Το ΔΕΛΦΙΝΙ στην Ευρώπη</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας συμμετέχει στο Ευρωπαϊκό Σχέδιο  «Η Ευρώπη χρειάζεται ενεργούς πολίτες» στο πλαίσιο του προγράμματος Grundtvig – Εκπαίδευση Ενηλίκων,
                    μαζί με τις χώρες Πολωνία, Ιταλία, Πορτογαλία, Ισπανία, Τουρκία και Εσθονία.  Σκοπός του Σχεδίου είναι η παρουσίαση στοιχείων του ελληνικού πολιτισμού στις
                    άλλες χώρες που συμμετέχουν καθώς και η ενεργός συμμετοχή ατόμων άνω των 50 ετών, η επαφή τους με τους νέους ώστε να τους μεταφέρουν εμπειρίες ζωής και η
                    διαπολιτισμική επικοινωνία μεταξύ ντόπιων και οικονομικών μεταναστών. Στο πλαίσιο του Σχεδίου Δράσης ο Σύλλογός μας καλεί όποιον θέλει να συνεισφέρει στο
                    σχεδιασμό και στην υλοποίηση ορισμένων δράσεων (παρουσίαση στοιχείων  του πολιτισμού μας, υποδοχή ξένων επισκεπτών, προβολή στα ΜΜΕ, δημιουργία CD, ηλεκτρονική
                    επικοινωνία με τους εταίρους μας από τις άλλες χώρες κ.α.) να επικοινωνήσει με τον πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό τηλ. 69746.40419.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Είναι το πρώτο άνοιγμα του Συλλόγου μας προς κατοίκους άλλων ευρωπαϊκών χωρών, μετά την επιτυχημένη επαφή και συνεργασία με τους ξένους φιλέλληνες που κάθε
                    χρόνο παίρνουν μέρος στα μαθήματα ελληνικής γλώσσας και  πολιτισμού  της πολιτιστικής εταιρείας ΑΙΟΛΙΣ στη Σκάλα Λουτρών. Πρόκειται για μία ευκαιρία να ταξιδέψουν
                    στο εξωτερικό οι νέοι μαζί με μεγαλύτερους στην ηλικία κατοίκους του χωριού μας, με στόχο να μάθουν για άλλες κουλτούρες, να συζητήσουν και να διασκεδάσουν μαζί με
                    άλλους ευρωπαίους πολίτες και τελικά να ανοίξουν μία δίοδο σταθερής επικοινωνίας με ανθρώπους άλλων πολιτισμών. Η γνωριμία και η ανοχή στον διαφορετικό
                    (είτε άλλης εθνικότητας και φυλής είτε κοινωνικής ή ηλικιακής ομάδας) θα ανοίξει στους κατοίκους της Σκάλας Λουτρών ένα νέο παράθυρο στον κόσμο. </Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Η πρόσκληση είναι ανοικτή έως και το Σεπτέμβριο του 2010, οπότε και θα ολοκληρωθεί το πρόγραμμα. Η βασική γλώσσα επικοινωνίας είναι η αγγλική,
                    αλλά θα βοηθηθούν να επικοινωνήσουν και άτομα που δεν την γνωρίζουν καλά. Ελπίζουμε πως θα ακολουθήσουν και άλλα παρόμοια προγράμματα με την ενεργητική συμμετοχή
                    όλο και περισσότερο νέων μελών του Συλλόγου μας. </Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εθελοντικός  καθαρισμός παραλιών</Typography>
                <img src={Image08} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Την Κυριακή 8 Ιουνίου 2008 πραγματοποιήθηκε όπως κάθε χρόνο με την βοήθεια των μελών και κυρίως της νεολαίας του οικισμού μας η
                    καθιερωμένη εξόρμηση εθελοντικού καθαρισμού της παραλίας του όρμου  της Σκάλας Λουτρών. Το μήνυμα που δίδουμε κάθε χρόνο με αυτή την ενέργειά μας είναι ότι
                    είμαστε παρόντες στην ανάγκη προστασίας των ακτών μας διότι αυτές αποτελούν σημαντικό χώρο αναψυχής των πολιτών, πόλο έλξης επισκεπτών και πηγή οικονομικής
                    ανάπτυξης του τόπου μας. Ο καθαρισμός και η προστασία των παραλιών σε συνδυασμό με το ότι αυτές βρίσκονται μέσα στην περιοχή προστασίας του Κόλπου της Γέρας
                    (NATURA 2000) συμβάλουν στην οικολογική ισορροπία της περιοχής και στην προστασία των απειλούμενων ζώων και φυτών που υπάρχουν και διαβιούν εδώ.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Τα νέα είναι ευχάριστα και αισιόδοξα αφού το σπουδαίο έργο του βιολογικού καθαρισμού των λυμάτων του τοπικού διαμερίσματος Λουτρών προβλέπετε να έχει
                    ολοκληρωθεί μέχρι τις 31-12-2008. Μάλιστα ήδη έχουν ενταχθεί στο πρόγραμμα Θησέας τα δίκτυα ύδρευσης και αποχέτευσης της Σκάλας Λουτρών και πολύ σύντομα
                    θα εγκατασταθεί ο εργολάβος. Όπως κάθε χρόνο  λοιπόν  έτσι και φέτος δώσαμε το μήνυμα της ανάγκης προστασίας των ακτών μας, για μας, για τα παιδιά μας,
                    για τις επόμενες γενιές, για τα ζώα, τα φυτά και την οικολογική ισορροπία. Ζητάμε παραλίες ελεύθερες για όλους, καθαρές με τη φροντίδα της Πολιτείας, των
                    Δήμων και των πολιτών</Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Πάσχα στη Σκάλα Λουτρών</Typography>
                <img src={Image09} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Για τρίτη συνεχή χρονιά έγιναν οι θρησκευτικές τελετουργίες για τον εορτασμό των αγίων ημερών του Πάσχα στη Σκάλα Λουτρών. Αυτές ξεκίνησαν με την τέλεση της
                    θείας λειτουργίας το πρωί της Κυριακής των Βαΐων και του Νυμφίου το απόγευμα της ίδιας ημέρας. Στην συνέχεια το απόγευμα της Μ. Τετάρτης έγινε προηγιασμένη θεία
                    λειτουργία και ευχέλαιο. Την Μ. Πέμπτη εψάλησαν τα 12 Ευαγγέλια και την Μ. Παρασκευή έγινε η περιφορά του επιτάφιου και η αποκαθήλωσις στην συνέχεια του εσταυρωμένου
                    με την συμμετοχή όλων των κατοίκων.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Τέλος το βράδυ του Μεγάλου Σαββάτου, μέσα σε μια πολύ κατανυκτική ατμόσφαιρα υπό το φως των κεριών  ετελέσθη η αναστάσιμη Θεία Λειτουργία στο
                    γραφικό εκκλησάκι του Αγίου Παντελεήμονα αφού με πρωτοβουλία του Σεβασμιωτάτου Μητροπολίτου Μυτιλήνης και ύστερα από δικό μας αίτημα εστάλη ιερέας γι αυτό το σκοπό.
                    Η συμμετοχή των κατοίκων ήταν καθολική και ιδίως των ηλικιωμένων οι οποίοι προσήλθαν με μεγάλη χαρά. Στην Ανάσταση συμμετείχαν και αρκετοί συμπολίτες μας από την
                    Μυτιλήνη, καθώς και ορισμένοι τουρίστες από το παραπλεύρως ξενοδοχείο ΖΑΙΡΑ. Ο Σύλλογός μας στο τέλος πρόσφερε κόκκινα αυγά , μαγειρίτσα και τσουρέκια σε όλους
                    τους παρευρισκόμενους τα οποία ήταν προσφορά του ξενοδοχείου ΖΑΙΡΑ γι αυτό και ευχαριστούμε πολύ τον ιδιοκτήτη του ξενοδοχείου και μέλος του Δ.Σ. του Συλλόγου μας
                    κ. Εμ. Τζανέτο. Και του χρόνου να είμαστε γεροί να αναστήσουμε ξανά με ιερέα στον οικισμό μας, όσο για το ξενοδοχείο του ευχόμαστε ολόψυχα καλές δουλειές με υγεία
                    και νοικοκυροσύνη..</Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Πρωτομαγιά και Νομαρχία Λέσβου</Typography>
                <img src={Image10} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Καθιερώθηκε πλέον κάθε πρωτομαγιά ο Σύλλογός μας να προσφέρει ένα πανέμορφο στεφάνι από τριαντάφυλλα και αγριολούλουδα στην Νομαρχία Λέσβου
                    το οποίο φτιάχνουν οι κυρίες του Δ.Σ. του Συλλόγου μας. Όπως κάθε χρόνο λοιπόν έτσι και φέτος τα μέλη του  Δ.Σ.  επισκεφθήκαμε την Νομαρχία Λέσβου και παραδώσαμε
                    το μαγιάτικο στεφάνι στο γραφείο του Νομάρχη κ. Παύλου Βογιατζή. Και του χρόνου με αγάπη και υγεία.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Οι τελευταίες 52 ημέρες της Βασιλεύουσας. Οι καταθέσεις των αυτόπτων μαρτύρων</Typography>
                <img src={Image11} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Ο νους μου και ο λογισμός συγχύζεται να γράψει,<br />
                    Να στιχοπλέξει αστοχεί την Άλωσιν της Πόλης.<br />
                    Εσείς βουνά θρηνήσετε και πέτρες ραγισθείτε<br />
                    Και ποταμοί φυράνετε και βρύσες ξεραθείτε.<br /><br />
                    Την Κυριακή 15 Ιουνίου 2008 στις 8 το βράδυ πραγματοποιήθηκε με μεγάλη επιτυχία η εκδήλωση με τίτλο «Οι τελευταίες 52 ημέρες της Βασιλεύουσας. Οι καταθέσεις
                    των αυτόπτων μαρτύρων», με την ευκαιρία της συμπλήρωσης 555 χρόνων από την άλωση της Κωνσταντινούπολης. Ομιλήτρια η έγκριτη δημοσιογράφος κ. Ντόρα Πολίτη, η
                    οποία και καταχειροκροτήθηκε από το πολυπληθές ακροατήριο το οποίο έμεινε στην κυριολεξία καθηλωμένο επί μιάμιση ώρα. Η εκδήλωση έγινε στον αύλιο χώρο του
                    «Μουσείου Προσφυγικής Μνήμης 1922» στη Σκάλα Λουτρών και μας τίμησαν με την παρουσία τους ο ιερέας του χωριού μας, ο πρόεδρος του Τοπικού Συμβουλίου Λουτρών
                    κ. Μαμώλης Βασίλης, η πρόεδρος του Πολιτιστικού κέντρου Λουτρών κ. Τατάκη-Κομνηνού Τούλα, ο πρόεδρος του Φ.Ο.Μ κ. Περικλής Μαυρογιάννης., η πρόεδρος της
                    πολιτιστικής εταιρίας ΑΙΟΛΙΣ κ. Ιφιγένεια Γεωργιάδου, ο πρόεδρος του Παγγεραγωτικού κ. Ζορμπάς Γιώργος, ο πρόεδρος του Συλλόγου Πελλοπονησίων Λέσβου κ.
                    Αντωνόπουλος Κώστας, από τον Σύλλογο Περάματος ο κ. Αντώνης Γκίκας, ο κ. Δημοσθένης Ψαρός, 	ο προϊστάμενος του Ο.Τ.Ε. κ. Λεοντής Γιώργοςκαι πολλοί φίλοι
                    μας από τη Μυτιλήνη και τα χωριά της Γέρας.<br />
                    Μηνύματα μας έστειλαν και διαβάσαμε ο Νομάρχης Λέσβου κ. Παύλος Βογιατζής και ο βουλευτής Λέσβου κ. Γιαννέλης Ιωάννης.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εγκαίνια στην βιβλιοθήκη «ΠΕΤΡΟΣ ΜΑΝΔΡΑΣ» του Συλλόγου μας.</Typography>
                <img src={Image12} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκαν την Κυριακή 6 Ιουλίου 2008 τα εγκαίνια του νέου κτιρίου της Βιβλιοθήκης του Συλλόγου μας. Για όσους δεν γνωρίζουν αυτή βρίσκεται
                    μέσα στο χώρο αθλοπαιδιών δίπλα στο γήπεδο μπάσκετ, ένα χώρο που μας έχει παραχωρήσει με συμβολικό ενοίκιο το Ίδρυμα Πέτρου Μάνδρα από το 1991. Οι εργασίες για την
                    κατασκευή της άρχισαν το 2001 με την έκδοση της σχετικής οικοδομικής άδειας και την κατασκευή του σκελετού της οικοδομής, επιβραδύνθηκαν όμως μετά επειδή δώσαμε
                    τότε προτεραιότητα στην ανακατασκευή και στην διαμόρφωση του χώρου του πρώην Δημοτικού σχολείου της Σκάλας Λουτρών το οποίο στέγασε το «Μουσείο Προσφυγικής Μνήμης
                    1922» με τα κειμήλια των προσφύγων κατοίκων του οικισμού μας. Μετά το πέρας των εργασιών και αφού αγοράσθηκε και τοποθετήθηκε ο σχετικός εξοπλισμός τοποθετήθηκαν
                    και καταγράφηκαν σε ηλεκτρονικό υπολογιστή τα πλέον των 4000 βιβλία του Συλλόγου μας. Ο ηλεκτρονικός υπολογιστής δίνει την δυνατότητα της γρήγορης ανεύρεσης
                    των βιβλίων που επιθυμεί ο κάθε επισκέπτης, αφού αυτά μπορούν να αναζητηθούν ποικιλοτρόπως. Εμείς εκείνο που έχουμε να προτείνουμε στα παιδιά είναι να
                    διαβάζουν ανελλιπώς διότι το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, προσανατολίζει την σκέψη σε νέους δρόμους, προσφέρει γνώσεις και πληροφορίες και
                    η γνώση σήμερα γι αυτόν που την κατέχει είναι δύναμη. Επίσης η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα προβλήματα που αποτελούν τη ζωή του σύγχρονου
                    ανθρώπου και τοποθετεί τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε στη διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και
                    φανατισμούς. Το Δ.Σ. του Συλλόγου μας εκφράζει ένα μεγάλο ευχαριστώ σε όσους χάρισαν βιβλία για τον εμπλουτισμό της βιβλιοθήκης μας και επίσης ένα μεγάλο
                    ευχαριστώ σε όσους βοήθησαν οικονομικά στην στήριξη αυτής της πολύχρονης προσπάθειας. </Typography>

                <img src={Image13} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Το κόψιμο της κορδέλας των εγκαινίων έγινε από τον πρόεδρο του Ιδρύματος «Πέτρος Μάνδρας» κ. Γεώργιο Πρίμο και για όσους δεν γνωρίζουν ο αείμνηστος Πέτρος
                    Μάνδρας ήταν γιός του Βασιλείου Μάνδρα εγγονού του Χατζηγεωργάκη Μάνδρα. Γεννήθηκε το 1887, είχε δύο αδελφούς, τον Πάνο και τον Γιώργο και μια αδελφή την Πελαγία.
                    Από τα αδέλφια του ο Πάνος ήταν ανύπανδρος, ο Γιώργος σπούδασε γιατρός στο Παρίσι, παντρεύτηκε αλλά σκοτώθηκε σε αυτοκινητιστικό ατύχημα και η αδελφή του η Πελαγία
                    παντρεύτηκε τον κ. Γιώργο Κούμπα και έκανε τρία παιδιά, δύο κόρες και ένα γιό. Ο Πέτρος Μάνδρας αφού σπούδασε στο Παρίσι επέστρεψε στη Μυτιλήνη και διαχειριζόταν τη
                    μεγάλη κτηματική του περιουσία χωρίς όμως να δημιουργήσει οικογένεια. ‘Ίδρυσε μαζί με τους συγγενείς του την εταιρεία Γρημάνης- Μάνδρας-Καμπούρης που έκανε ένα
                    ελαιοτριβείο στα Λουτρά, (αυτό που έχει σήμερα ο Συνεταιρισμός Λουτρών), χρημάτισε Δήμαρχος Μυτιλήνης και πέθανε στο εξοχικό του στη Βαρειά το 1944 αφήνοντας
                    μεγάλο μέρος της περιουσίας του για τους φτωχούς της τότε κοινότητας και σήμερα τοπικού διαμερίσματος Λουτρών, για χορήγηση υποτροφιών σε σπουδαστές των Α.Ε.Ι.
                    καταγόμενους από τα Λουτρά και για ίδρυση παιδικού σταθμού. Τέλος δώρισε μια μεγάλη έκταση στην περιοχή Νερούτσικα σε φτωχές οικογένειες των Λουτρών. </Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Στην τελετή των εγκαινίων παραβρέθηκαν και μας τίμησαν με την παρουσία τους ο Δήμαρχος Μυτιλήνης κ. Να σος Γιακαλής, ο πρόεδρος του τοπικού συμβουλίου
                    Λουτρών κ. Μαμώλης Βασίλειος, η πρόεδρος του πολιτιστικού κέντρου Λουτρών κ. Τούλα Τατάκη-Κομνηνού, ο πρόεδρος του Συλλόγου Πελοπονησίων Λέσβου κ. Αντωνόπουλος
                    Κώστας, ο πρόεδρος του Φ.Ο.Μ. κ. Περικλής Μαυρογιάννης, ο πρόεδρος του Παγγεραγωτικού κ. Ζορμπάς Γεώργιος, εκπρόσωπος του Λιμεναρχείου ο κ. Γρηγορέλλης Νίκος, ο
                    παριστάμενος του Ο.Τ.Ε. κ. Λεοντής Γιώργος, η πρόεδρος της Πολιτιστικής εταιρίας Αιολίς κ. Ιφιγένεια Γεωργιάδου και πολλοί άλλοι. Μηνύματα μας έστειλαν και
                    διαβάσαμε ο Νομάρχης Λέσβου κ. Παύλος Βογιατζής και ο βουλευτής Λέσβου κ. Γιαννέλης Ιωάννης.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Αφίσες για το Μουσείο.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Εκτυπώθηκαν και διανεμήθηκαν 50 αφίσες σχετικές με τα ωράρια λειτουργίας του «Μουσείου Προσφυγικής Μνήμης 1922». Στην τήρηση του σχετικού
                    ωραρίου λειτουργίας του Μουσείου βοήθησε πάρα πολύ και η πρόσληψη ενός ατόμου με το πρόγραμμα STAGE.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>5η Ιουνίου Παγκόσμια ημέρα για το περιβάλλον</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Η 5η Ιουνίου ως γνωστόν έχει καθιερωθεί από τον Ο.Η.Ε.  ως Παγκόσμια Ημέρα για το Περιβάλλον. Με την ευκαιρία λοιπόν αυτή ο Σύλλογός μας
                    διοργάνωσε διαγωνισμό ανάμεσα στους μαθητές του Δημοτικού Σχολείου Λουτρών ξεχωριστά για κάθε τάξη με την συνεργασία των δασκάλων. Οι  Α! , Β! , Γ! και Δ!
                    τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα "Ζωγράφισε ένα δάσος με τα ζωάκια του" οι δε  Ε! και ΣΤ! τάξεις στην έκθεση με θέμα "Τι γνωρίζετε για τις
                    ανανεώσιμες πηγές ενέργειας?". </Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Λειτουργία στο εκκλησάκι του Αγίου Παντελεήμονα</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Γιορτάσθηκε και φέτος με λαμπρότητα και πλήθος πιστών από τα Λουτρά τη Σκάλα Λουτρών και τη Μυτιλήνη η εορτή του Αγίου Παντελεήμονα στο
                    ομώνυμο εκκλησάκι του οικισμού μας στη Σκάλα Λουτρών στις 27 Ιουλίου 2007. Είναι πλέον γνωστό πως κάθε χρόνο όλο και περισσότεροι πιστοί προσέρχονται για
                    α προσευχηθούν και να θαυμάσουν το γραφικό αυτό εκκλησάκι του οικισμού μας το οποίο ως γνωστό έχει ανακαινισθεί πλήρως με την βοήθεια των κατοίκων και του
                    Συλλόγου μας ο οποίος εξακολουθεί να το φροντίζει και να το συντηρεί.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Θερινή χοροεσπερίδα και βράβευση αριστούχων  μαθητών</Typography>
                <img src={Image14} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε με επιτυχία η θερινή χοροεσπερίδα του Συλλόγου μας το Σάββατο 19 Ιουλίου 2008 στον νέο χώρο «Ελιά και θάλασσα» του ξενοδοχείου
                    ΖΑΙΡΑ στη Σκάλα Λουτρών. Όπως κάθε χρόνο έτσι και φέτος κατά την διάρκεια της χοροεσπερίδας βραβεύτηκαν οι αριστούχοι μαθητές Γυμνασίου-Λυκείου του Τοπικού
                    Διαμερίσματος Λουτρών.
                    <strong>Βραβεύτηκαν οι παρακάτω μαθητές και μαθήτριες:</strong>
                    Ταλιαντζή Κατερίνα του Σταύρου Α!  Γυμνασίου.<br />
                    Σφακιανός Κωσταντίνος του Γεωργίου Β! Γυμνασίου.<br />
                    Ευαγγέλλου Βασιλική του Χαριλάου Β! Γυμνασίου.<br />
                    Καρακατσάνης Γεώργιος του Μιχαήλ Γ! Γυμνασίου.<br />
                    Κρητικού Αφροδίτη του Γεωργίου Α! Λυκείου.<br />
                    Μαμουλής Κωνσταντίνος του Γεωργίου  Γ! Λυκείου.<br /><br /></Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Επίσης εδόθησαν έπαινοι σε έξη παιδιά του Δημοτικού Σχολείου Λουτρών [ένα από κάθε τάξη] γιατί πρώτευσαν σε διαγωνισμό ο οποίος είχε πραγματοποιηθεί σε συνεργασία
                    με τον Σύλλογό μας την 5 Ιουνίου παγκόσμια ημέρα για το περιβάλλον. Οι μαθητές που βραβεύτηκαν είναι οι παρακάτω.
                    Από την  Α!    τάξη η Αχλάδα Μαρία.<br />
                    Από την  Β!    τάξη ο Ντινιακός Μαρίνος.<br />
                    Από την  Γ!    τάξη ο Βερβέρης Αντώνης.<br />
                    Από την  Δ!    τάξη η Πιτσιλαδή Δήμητρα.<br />
                    Από την  Ε!    τάξη η Τζανέλλη Μελπομένη.<br />
                    Από την  ΣΤ!  τάξη η. Βατζάκα Μαριλένα<br /><br />

                    Σε όλα τα παιδιά εκτός από τον έπαινο εδόθησαν και διάφορα δώρα προσφορά του Συλλόγου μας.
                    Στην παραπάνω εκδήλωση παραβρέθηκαν και μας τίμησαν με την παρουσία τους, ο αντινομάρχης Λέσβου κ. Σπύρος Γαληνός, ,ο πρόεδρος Τοπικού Συμβουλίου Λουτρών
                    κ. Μαμώλης Βασίλης, εκπρόσωπος του Παγγεραγωτικού Συλλόγου ο κ. Χατζηχρήστος Στρατής, η δημοσιογράφος κ. Ειρήνη Κανλή, η δικαστικός από τα Λουτρά κ.
                    Φρόσω Κρητικού, η δασκάλα από το Δημοτικό Σχολείο Λουτρών κ. Μαρία Περγαμηνού καθώς και  αρκετά μέλη και φίλοι μας φίλοι μας από τη Μυτιλήνη.
                    Τέλος μήνυμα μας έστειλε και διαβάσαμε ο Νομάρχης Λέσβου κ. Παύλος Βογιατζής και ο βουλευτής Λέσβου κ. Ιωάννης Γιανέλλης.<br /><br />
                    Kατά την διάρκεια της παραπάνω εκδήλωσης έγινε και η κλήρωση των δώρων της λαχειοφόρου αγοράς του Συλλόγου μας και κέρδισαν οι παρακάτω αριθμοί.<br />

                    1ος  λαχνός  το νούμερο  977  κέρδισε ένα ποδήλατο <br />
                    2ος  λαχνός  το νούμερο  495  κέρδισε ένα  DVD<br />
                    3ος  λαχνός  το νούμερο   678  κέρδισε ένα ραδιόφωνο<br />
                    4ος  λαχνός  το νούμερο  115  κέρδισε ένα ανεμιστήρα<br /></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Αγορά κλιματιστικού.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας με απόφαση του Δ.Σ. αγόρασε ένα κλιματιστικό το οποίο τοποθετήθηκε στην αίθουσα διδασκαλίας των μουσικών μας τμημάτων.
                    Ήδη έχουμε πάρει απόφαση για περαιτέρω βελτίωση του χώρου που κάνουν το μάθημά τους τα παιδιά. </Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εκδηλώσεις μνήμης</Typography>
                <img src={Image15} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Την Κυριακή 14 Σεπτεμβρίου 2008 πραγματοποιήθηκαν στη Μυτιλήνη οι εκδηλώσεις μνήμης για την γενοκτονία των Ελλήνων της Μικράς Ασίας στο
                    πλαίσιο της 86ης επετείου από τη Μικρασιατική καταστροφή. Όπως είναι γνωστό η 14η Σεπτεμβρίου καθιερώθηκε με το νόμο  2645/1998, ως ημέρα μνήμης της γενοκτονίας
                    των Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος. Η Μικρασιατική καταστροφή δεν είναι ένα απλό ιστορικό γεγονός. Αντιπροσωπεύει μια πραγματική τομή για την
                    Ελληνική Ιστορία. Γιατί πέρα από τα πραγματικά γεγονότα, επέφερε παράλληλα και μια ανανέωση στις δομές της Ελληνικής Κοινωνίας και στον πολιτισμό της. Έτσι την
                    Κυριακή 14 Σεπτεμβρίου το Δ.Σ. του Συλλόγου μας παραβρέθηκε στην τέλεση της δοξολογίας στον Ιερό Ναό του Αγίου Νικολάου στην επάνω Σκάλα και στην συνέχεια κατέθεσε
                    στεφάνι στο άγαλμα της Μικρασιάτισας μάνας μαζί με τους υπόλοιπους Συλλόγους και τις αρχές του τόπου.
                    Για τα γεγονότα της ημέρας μας μίλησε ο κ. Γεράσιμος Βενέτας πρόεδρος του Συλλόγου «Η Επάνω Σκάλα»</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Η Μικρασιατική καταστροφή είναι γεγονός ότι καταγράφεται στις ποιο θλιβερές σελίδες του Ελληνικού Έθνους, στιγματίστηκε από την απώλεια ανθρώπων και
                    πατρίδων, δημιούργησε πρόσφυγες και σκόρπισε δυστυχία. Αυτοί οι πρόσφυγες πληγωμένοι από τη βαρβαρότητα και τη φρίκη ήρθαν στην Ελλάδα αγωνίστηκαν για την επιβίωσή
                    τους και συνέβαλαν με τους αγώνες τους στην ανανέωση και στην πρόοδο της Ελληνικής κοινωνίας</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Ίδρυση ιστοσελίδας  www.delfini1922.gr</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Από τις αρχές του 2008 έχει ξεκινήσει και λειτουργεί η ιστοσελίδα του Συλλόγου μας την οποία μπορείτε να επισκεφθείτε πληκτρολογώντας στον
                    Ηλεκτρονικό σας υπολογιστή το www.delfini1922.gr  Στην αρχική σελίδα έρχεστε σε επαφή με το μενού από το οποίο μπορείτε να επιλέξετε τις διάφορες σελίδες
                    και να περιηγηθείτε σ αυτές. Αρκετό ενδιαφέρον υπάρχει στη στήλη Μικρασιάτικα, στην στήλη με τα ημερολόγιά μας και σ αυτή που αφορά το «Μουσείο Προσφυγικής
                    Μνήμης 1922». Υπάρχει άφθονο φωτογραφικό υλικό από τα παράλια της Μικράς Ασίας καθώς και σημαντικά ιστορικά στοιχεία που σίγουρα ενδιαφέρουν όσους έλκουν την
                    καταγωγή τους από τα απέναντι παράλια. Επίσης θα μπορέσετε να περιηγηθείτε στις διάφορες άλλες δραστηριότητές μας και στις ομορφιές του χωριού μας.
                    Με την υλοποίηση αυτής της προσπάθειας ο Σύλλογός μας θα προβάλλετε  πλέον δυναμικά μέσω του διαδικτύου σε όλο τον κόσμο.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Επιστολή στο Μητροπολίτη Μυτιλήνης.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός έστειλε την παρακάτω επιστολή στον Σεβασμιότατο Μητροπολίτη Μυτιλήνης κ. Ιάκωβο για την επίλυση του προβλήματος της έλλειψης ιερέα
                    στον Οικισμό μας.<br /><br />
                    Σεβασμιώτατε, <br />
                    Σε συνέχεια της πρόσφατης επίσκεψής μας στο γραφείο σας και διερμηνεύοντας την σφοδρή επιθυμία των κατοίκων  επανερχόμεθα στο θέμα του ορισμού ιερέως ως μονίμου
                    εφημέριου στον οικισμό μας τη Σκάλα Λουτρών.
                    Μας είχατε εκφράσει την επιθυμία να σας αποστείλουμε τον αριθμό των οικογενειών με τις υπογραφές τους και την θερμή τους παράκληση όπως ικανοποιήσετε την ψυχική
                    τους αυτή ανάγκη.<br />
                    Ευελπιστώντας στην θετική σας ανταπόκριση σας ευχαριστούμε θερμώς εκ των προτέρων.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Λειτουργία στην Παναγιά την Αψηλή</Typography>
                <img src={Image16} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Με την συμμετοχή των μελών του Συλλόγου μας και πλήθος κόσμου που έρχεται κάθε χρόνο από τα Λουτρά τη Μυτιλήνη και τα χωριά της Γέρας
                    (όπου ως γνωστό υπάρχουν αρκετοί πρόσφυγες ιδίως στο Πέραμα), ετελέσθη η καθιερωμένη λειτουργία με αρτοκλασία και επιμνημόσυνη δέηση υπέρ αναπαύσεως των
                    ψυχών των προσφύγων κατοίκων του οικισμού μας που έχει καθιερωθεί να εορτάζετε στις  23 Αυγούστου μαζί με τα εννιάμερα της Παναγίας στην προσφυγοπαναγιά όπως
                    την λένε εδώ στη Σκάλα Λουτρών την Αψηλή.
                    Ο καιρός ήταν θαυμάσιος και αποζημίωσε με το παραπάνω όσους έκαναν τον κόπο και ανέβηκαν μέχρι την κορυφή του λόφου. Στο τέλος της Θείας Λειτουργίας και αφού
                    ετελέσθη η επιμνημόσυνη δέηση τα μέλη του Συλλόγου μας πρόσφεραν καφέ , γλυκό και διάφορα εδέσματα στους προσκυνητές.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ας διαβάσουμε παρακάτω πως περιγράφει μία επισκέπτης η κ. Άσπα Κοπανέλλη – Αβαγιανού, την εμπειρία της από αυτή την εκδήλωση.
                    Μεγάλη η αγάπη και η πίστη μας στην Παναγιά τη Μεγαλόχαρη, την Άγια Μητέρα. Τη λατρευτή της Μορφή, προσκυνούμε ολογυρίς το χρόνο αλλά ο Αύγουστος είναι ο
                    αφιερωμένος στη Χάρη της. Η Κοίμησις της Θεοτόκου είναι η μεγάλη γιορτή της Χριστιανοσύνης. Είναι το Πάσχα του καλοκαιριού. Μόλις γυρίσεις ψηλά το βλέμμα στη
                    Σκάλα Λουτρών καμαρώνει σκαρφαλωμένο στην κορυφή του λόφου, το εκκλησάκι της Παναγιάς της Αψηλής. Οι πιστοί, όσοι ξέρουν πως γίνεται λειτουργία 9 ημέρες μετά
                    την κοίμηση της Θεοτόκου, ανεβαίνουν, παρ όλη την ανηφόρα αλλά και την Αυγουστιάτικη ζέστη για να ανάψουν το κεράκι τους, στην Άγια Εικόνα της. Στην αρχή 35
                    καλοφτιαγμένοι πέτρινοι διάδρομοι που διασχίζονται με πέντε έως δεκαπέντε βήματα και μετά 55 σκαλοπάτια που θέλουν τέσσερα έως δέκα βήματα για να περπατηθούν,
                    πέτρινα κι αυτά και να τα τελευταία 20 ασβεστωμένα σκαλοπάτια, που οδηγούν τα βήματα του προσκυνητή στον Ιερό χώρο που βρίσκεται το εκκλησάκι της Παναγίας.
                    Ένα απαλό, δροσερό αεράκι σε υποδέχεται, οι πιστοί πολλοί και οι ψαλμωδίες βγαλμένες απ την καρδιά! Η θέα γύρω, κόβει την αναπνοή! Απ τη μια το λιμανάκι της
                    Κουντουρουδιάς, απ την άλλη τα Λουτρά, η Σκάλα Λουτρών με τα πλεούμενά της κι απ την άλλη ο κόλπος της Γέρας.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Απέναντι, ολόγυρα λιόδεντρα και τα Γεραγώτικα χωριά σκαρφαλωμένα στις καταπράσινες λιόσπαρτες εκτάσεις. Κι ένα γύρω γαλαζοπράσινη, ήρεμη, ονειρική
                    η θάλασσα! Με τους ήσυχους κολπίσκους τους μικροσκοπικούς, με τις άπειρες αποχρώσεις που αντανακλά το φως του ήλιου στα γαλανά νερά της! Ηρεμία, γαλήνη, απόλαυση που δεν
                    θέλει παρά 100 «σκαλοπάτια»  να ανεβεί για να τα απολαύσει, να τα ζήσει και να τα χαρεί κάποιος!!! (Αρκεί βέβαια να αντέχουν τα πόδια του και από θέα άλλο τίποτα!!!</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τριαντάφυλλο</Typography>
                <img src={Image17} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Μια υπέροχη ζωγραφιά φιλοξενεί από το περασμένο καλοκαίρι ο μπροστινός τοίχος της βιβλιοθήκης του Συλλόγου μας. Η ζωγράφος κ. Sven Berg από τη
                    Σουηδία η οποία είναι τακτικός επισκέπτης και λάτρης του οικισμού μας την ζωγράφισε αφιλοκερδώς και την ευχαριστούμε πολύ για την προσφορά της αυτή.
                    <br /><br /><br /><br /><br /><br /><br /><br /></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Πολλές επισκέψεις στο Μουσείο μας</Typography>
                <img src={Image18} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Οι επισκέπτες που ήρθαν στο «Μουσείο Προσφυγικής Μνήμης 1922» την χρονιά που μας πέρασε είναι αλήθεια πως ξεπέρασαν κάθε προσδοκία μας.
                    Το Μουσείο μας είχε αρκετούς επισκέπτες επί καθημερινής βάσεως και ευτυχώς που μας δόθηκε η δυνατότητα μέσω του προγράμματος STAGE να έχουμε τις πρωινές ώρες
                    υπάλληλο που το κρατούσε ανοικτό. Τις απογευματινές ώρες το άνοιγμα του Μουσείου αναλάμβανε η νεολαία της Σκάλας Λουτρών και έτσι εκτός ελαχίστων περιπτώσεων
                    αυτό ήταν ανοικτό τόσο το πρωί όσο και τις απογευματινές ώρες. Είναι γεγονός πως χρειάσθηκε αρκετές φορές το καλοκαίρι να το ανοίξουμε εκτάκτως και βραδινές
                    ώρες για να εξυπηρετήσουμε ανθρώπους οι οποίοι είχαν σφοδρή επιθυμία για να το δουν και που την επομένη ταξίδευαν.</Typography>

                <Typography variant="h3" sx={theme.typography.typography2}>Πολλές ήταν και οι μορφωτικές επισκέψεις διαφόρων σχολείων και φορέων που μας επισκέφθηκαν.
                    Ενδεικτικά αναφέρουμε το Δημοτικό σχολείο και το νηπιαγωγείο Λουτρών, το νηπιαγωγείο Μανταμάδου, το 3ο, το 7ο, το 12ο, το14ο, και το 15ο δημοτικά σχολεία Μυτιλήνης,
                    τα εκπαιδευτήρια Πλάτωνος, το Πανεπιστήμιο Αιγαίου, ο Πολιτιστικός σύλλογος Βαφειού, η στρατιωτική μονάδα Περάματος, οι ξένοι τουρίστες από το ξενοδοχείο ΖΑΙΡΑ καθ όλη
                    την περίοδο του καλοκαιριού κ.α.
                    Αξιομνημόνευτη  η επίσκεψη των συνέδρων του 11ου Ευρωπαϊκού Συνεδρίου περί Δικαίου Ασύλου που έγινε από 17.09 μέχρι 24.09.2008 στη Λέσβο.
                    Και όλα αυτά χωρίς να έχει υπάρξει σχετική διαφημιστική προβολή.
                    Οι κολακευτικές αναφορές στο βιβλίο των επισκεπτών είναι πολλές και μας δίνουν το κουράγιο να συνεχίσουμε την προσπάθειά μας αυτή.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Εκδήλωση του μουσικού τμήματος του Συλλόγου μας</Typography>
                <img src={Image19} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Στον αύλειο χώρο του «Μουσείου Προσφυγικής Μνήμης 1922» της Σκάλας Λουτρών, το βράδυ του Σαββάτου 23 Αυγούστου 2008, παρουσίασαν τη δουλειά
                    της χρονιάς που πέρασε τα μουσικά τμήματα του Συλλόγου μας, στο αρμόνιο , το πιάνο και την κιθάρα. Στην εκδήλωση έπαιξαν και τραγούδησαν διάφορα Ελληνικά και
                    ξένα τραγούδια οι μαθητές και μαθήτριες,  Αφροδίτη Κρητικού,  Παναγιώτα Παπαχρυσού, Παναγιώτα Κουτσαντώνη, οι αδελφές Χριστίνα και Γιώτα Κατσακούλη και ο Νίκος
                    Μανώλης με τον δάσκαλο του τμήματος κιθάρας τον κ. Γρηγόρη Μολυβιάτη.<br /><br /><br /><br /><br /></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Στη Σκάλα Λουτρών το πρώτο Διεθνές Μουσικό Φεστιβάλ</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Από τις 29 Αυγούστου μέχρι τις 3 Σεπτεμβρίου διοργανώθηκε στον όμορφο χώρο δίπλα από το ξενοδοχείο ΖΑΙΡΑ, με το όνομα «Ελιά και Θάλασσα» το πρώτο Διεθνές Μουσικό
                    Φεστιβάλ.
                    Η διεύθυνση του Μουσικού Σχολείου Μυτιλήνης και ο Σύλλογος γονέων του ήταν αρωγοί στην προσπάθεια διοργάνωσης και πραγματοποίησης του μοναδικού αυτού γεγονότος στην
                    πόλη μας. Πάνω από 50 διακεκριμένοι μουσικοί, καθηγητές και φοιτητές από τη Ρωσία, τη Γαλλία, τη Γερμανία, το Ισραήλ, την Ιταλία, την Κορέα και την Αμερική πήραν μέρος
                    στο διεθνές αυτό φεστιβάλ. Η διοργάνωση τελούσε υπό την αιγίδα της Νομαρχιακής Αυτοδιοίκησης Λέσβου, του Δήμου Μυτιλήνης, της κυβέρνησης της Μόσχας και της Ρωσικής
                    πρεσβείας. Δυστυχώς, δεν είχαν την ευκαιρία όσοι συμπολίτες μας θα έπρεπε, να απολαύσουν αυτό το εξαίρετο φεστιβάλ που διοργανώθηκε εδώ στη Σκάλα Λουτρών κι αυτό ίσως
                    γιατί δεν προβλήθηκε εγκαίρως όπως θα έπρεπε. Στην έναρξη του φεστιβάλ είχαμε την τύχη να παρακολουθήσουμε το πρόγραμμα της ορχήστρας της κρατικής σχολής πνευστών της
                    Μόσχας. Το πρόγραμμα που παρουσιάστηκε ήταν το παρακάτω.
                    Παρασκευή 29-8-2008 Συναυλία της ορχήστρας του Διεθνούς Μουσικού Φεστιβάλ με μαέστρο τον Robert Canetti. Δευτέρα 1-9-2008 Συναυλία της ορχήστρας του Διεθνούς Μουσικού
                    Φεστιβάλ με μαέστρο τον Αλέξανδρο Χάλαρη. Τρίτη 2-9-2008 Συναυλία των νέων σολίστ. Τετάρτη 3-9-2008 Συναυλία Gala Ορχήστρας του Διεθνούς Μουσικού Φεστιβάλ</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας σαν ένα μικρό δείγμα φιλοξενίας, προσέφερε από ένα λουλούδι σε κάθε νέο σολίστ την Τρίτη 2-9-2008 και στην συνέχεια παρέθεσε δείπνο
                    σε όλους τους φιλοξενούμενους με την ευχή αυτό το φεστιβάλ να καθιερωθεί και να επαναλαμβάνετε κάθε χρόνο στη Σκάλα Λουτρών.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Προϊστορική θέση στη Σκάλα Λουτρών</Typography>
                <img src={Image20} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Στην κορυφή του λόφου όπου βρίσκετε και το εκκλησάκι της Παναγιάς της Αψηλής υπάρχει όπως αναφέρει και ο Σ. Χαριτωνίδης στο βιβλίο του «αρχαίον οχυρόν με τείχος
                    εξ ακανονίστων και άνευ συνδέσμων λίθων βατόν κατά την δυτικήν και εν μέρει την νότια πλευράν, ενώ προς τας λοιπάς δεν υπάρχουν ίχνη οχυρώσεως, είτε διότι
                    αυτή ηφανίσθη, είτε διότι ουδέποτε κατεσκευάσθη…»  Το οχυρό αυτό ήταν γνωστό στα μέλη του Συλλόγου μας γι αυτό είχαμε ενδιαφερθεί από το 1994 με έγγραφό μας προς
                    την αρχαιολογική υπηρεσία το οποίο έλεγε τα παρακάτω.<br />
                    Σκάλα Λουτρών     06-01-1994<br /><br />
                    Προς : Την Αρχαιολογική Υπηρεσία Νομού Λέσβου <br />
                    Σαν πρόεδρος του Εξωραϊστικού και Εκπολιτιστικού Συλλόγου Σκ . Λουτρών «Το Δελφίνι» σας ενημερώνω για το παρακάτω θέμα το οποίο άπτεται των αρμοδιοτήτων σας .
                    Σήμερα το μεσημέρι (06-01-94) ο κ. Σταύρος Βαλαχής από την Σκ. Λουτρών διδάσκαλος το επάγγελμα με κάλεσε και πήγαμε στην κορυφή ενός λόφου που ευρίσκεται στην Σκάλα
                    Λουτρών στους πρόποδες του οποίου είναι κτισμένος ο οικισμός μας και μου έδειξε κάτι πλάκες λαξευμένες στις οποίες είχε αφαιρεθεί ελαφρώς το χώμα από πάνω διότι
                    διαφορετικά δεν θα ήταν πλήρως ορατές και μου παρέδωσε 3 μικρά κομμάτια προφανώς από πήλινα αγγεία και 2 κομμάτια χυτό μολύβι που βρέθηκαν εκεί τα οποία και σας
                    παραδίδω με την παράκληση να επισκεφθείτε  το συντομότερο δυνατόν την περιοχή για να κάνετε την αξιολόγηση και την πρέπουσα προστασία του χώρου εάν επιβάλλετε.
                    Ο Πρόεδρος του Συλλόγου Δ . Παπαχρυσός<br />
                    …Τώρα βέβαια μη ρωτήσετε καλλίτερα για την συνέχεια γιατί δεν είχαμε ποτέ καμία ενημέρωση.<br /></Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Η Εορτή του Αγίου Νικολάου στη Σκάλα Λουτρών</Typography>
                <img src={Image21} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />

                <Typography variant="h3" sx={theme.typography.typography2}>Με μεγάλη λαμπρότητα και με την συμμετοχή όλων των κατοίκων του χωριού μας, πραγματοποιήθηκαν οι εκδηλώσεις για την εορτή του Αγίου Νικολάου, προστάτη των
                    ναυτικών και των αλιέων, που διοργάνωσε για πρώτη φορά ο Σύλλογός μας αναβιώνοντας ένα παλιό τοπικό έθιμο.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Με την παρουσία δύο ιερέων, του πατέρα Παναγιώτη εφημέριου Αγίου Γεωργίου Λουτρών και του πατέρα Κομνηνού εφημέριου Αγίων Θεοδώρων Μεραρχίας, ετελέσθη η Θεία
                    Λειτουργία και στη συνέχεια έγινε η λιτάνευση της εικόνας του Αγίου με κατεύθυνση το λιμανάκι της Σκάλας Λουτρών. Μετά την ευλογία των άρτων ο ιερέας επιβιβάσθηκε
                    στη βάρκα του κ. Ευάγγελου Κρητικού και κρατώντας την εικόνα του Αγίου την περιέφερε, ρίχνοντας το στεφάνι που την πλαισίωνε στην θάλασσα και ευχόμενος ήρεμα
                    ταξίδια για τους ναυτικούς και τους αλιείς μας</Typography>
                <img src={Image22} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Μετά το πέρας των θρησκευτικών εκδηλώσεων τα μέλη του Συλλόγου μαζί με τους ιερείς κατέληξαν στην ταβέρνα του Οδυσσέα Παπαμακαρίου όπου γευμάτισαν και
                    γλέντησαν συνοδεία παραδοσιακής ορχήστρας με νησιώτικα τραγούδια μέχρι αργά το βράδυ.
                    <br /> Έτσι …όπως τον παλιό καλό καιρό.
                  </Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Το ημερολόγιό μας για το 2009</Typography>
                <img src={Image23} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Με τίτλο <strong>« Οικουμενικό  Πατριαρχείο. Οι Πατριάρχες του Γένους από το 1901 έως σήμερα» </strong> κυκλοφόρησε το ημερολόγιο του Συλλόγου μας για το έτος 2009.
                    Πρόκειται για επιτραπέζιο ημερολόγιο, βιβλίο το οποίο είναι συλλεκτικό κομμάτι και περιλαμβάνει ιστορικά στοιχεία και πάρα πολλές φωτογραφίες από την
                    Κωνσταντινούπολη και από το Οικουμενικό Πατριαρχείο. Το ημερολόγιο είναι δίγλωσσο (Ελληνικά – Αγγλικά) και τυπώθηκε στην Αθήνα .
                    Θεωρούμε υποχρέωσή μας να ευχαριστήσουμε την Νομαρχιακή Αυτοδιοίκηση Λέσβου και ιδιαιτέρως τον Νομάρχη Λέσβου κ. Παύλο Βογιατζή για την βοήθειά τους στην
                    έκδοση του ημερολογίου αυτού καθώς και όλους τους λοιπούς χορηγούς  που κάθε χρόνο συμβάλουν οικονομικά στην έκδοση των ημερολογίων μας.<br /><br /></Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Εισαγωγή:<br />
                    Ήταν κάποτε εκεί πέρα<br />
                    Ήταν κάποτε εκεί πέρα , κάπου σε μια γη , σε πηγή λαών και χρόνων, στην άκρη της Θράκης, στο άγγιγμα της Μ. Ασίας. Εκεί όπου η ελληνική φυλή, αποτραβηγμένη από
                    το προσκήνιο της ιστορίας για αιώνες, κατέθετε τους πόθους της, σε ένα καινούριο ξεκίνημα, για μια ανάταση όμοια του  παρελθόντος, σε πέλαα ουρανομήκη.
                    Εκεί στον « απεναντίον των τυφλών » τόπο, στη χώρα του μυθικού Μεγαρέα οικιστή Βύζαντα, στο Βυζάντιο.
                    Εκεί « εις την Πόλιν » τη χτισμένη πάνω σε επτά λόφους. « Εις την Πόλιν » την επτάλοφο. « Εις την Πόλιν » την Πρωτεύουσα της πολυεθνούς βυζαντινής Αυτοκρατορίας,
                    που στους κόλπους της χωνεύτηκαν, ισορρόπησαν, μετουσιώθηκαν δυνάμεις αντίρροπες και ακραίες. Το αρχαιελληνικό Πνεύμα και ο Χριστιανισμός. « Εις την Πόλιν »…
                    « Ισταμπούλ », αναφωνούν και οι σημερινοί αλλόθρησκοι ένοικοί της.<br />
                    Για σένα μιλάω Κωνσταντινούπολη, που ούτε το όνομά σου πια δεν αναγνωρίζεις. Για σένα που γεννήθηκες 330 χρόνια μετά τη γέννηση του Χριστού, πλημμυρισμένη από
                    μοσχομυριστά μπάλσαμα του μέλλοντος, που προοιώνιζαν  δόξες ασίγαστες για χίλια και βάλε, χρόνια.
                    Ήταν θέλημα Θεού όμως, όπως είπαν, να ακουσθεί η μητέρα όλων των κραυγών: «Εάλω η Πόλις». <br />
                    « Η Πόλη έπεσε ». Κραυγή φρίτουσας ψυχής. Ανάρθρωτη κραυγή, πληγής χαίνουσας της ψυχής μας, των αχράντων μας απάντων, στον αιώνα των αιώνων. Από τότε τις 29
                    Μαϊου του άραχλου 1453.<br />
                    Βλέπεις τη νύχτα, βλέπεις τη φρίκη, βλέπεις το ποτάμι από Τούρκους, ορδές λιμασμένες, συνωθούμενες, σπληγωμένο να σκαρφαλώνει στα ιερά τείχη, να χύνεται…
                    αλαλάζοντας, πλημμυρίδα σε διαρκή άνοδο…και κύματα κύματα να ορμούν στα τέσσερα σημεία, στην Πόλη της Παρθένου Παναγιάς, την Πόλη της Σοφίας του Θεού, την
                    Πόλη των Πόλεων « πασών κεφαλή », την Κωνσταντινούπολη.<br />
                    Ευχαριστούμε για πολλοστή φορά τη Νομαρχιακή Αυτοδιοίκηση Λέσβου και το νομάρχη Π. Βογιατζή προσωπικά για την αμέριστη ηθική και υλική υποστήριξη στις ετήσιες
                    προσπάθειές μας, φωτογραφικής ‘‘ανασύστασης’’ των αλησμόνητων πατρίδων μας.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα Αρμονίου</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Λειτούργησε μέχρι τον Ιούνιο του 2007 οπότε και σταμάτησε λόγω έλλειψης μαθητών. Το τμήμα θα επαναλειτουργήσει μόλις εκδηλωθεί σχετικό ενδιαφέρον.
                    Όσοι λοιπόν ενδιαφέρονται για τα παιδιά τους μπορούν να το δηλώσουν στο τηλ. 22510 91231 κ. Παπαχρυσό Δημ. η στο 22510 91302 κ. Γρηγορέλλη Κατερίνα.
                    Η μηνιαία συνδρομή είναι 15 ευρώ.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα Κιθάρας</Typography>
                <img src={Image24} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Λειτουργούν κάθε Σάββατο από τις 2 το μεσημέρι μέχρι τις 5 το απόγευμα και όσοι ενδιαφέρονται για τα παιδιά τους μπορούν να το δηλώσουν στα ανωτέρω
                    προαναφερθέντα τηλέφωνα . Η μηνιαία συνδρομή είναι και εδώ 15 ευρώ και δάσκαλος είναι ο κ. Μολυβιάτης Γρηγόρης ο οποίος επί πολλά χρόνια έχει προσφέρει
                    εξαιρετικό έργο στα παιδιά του Συλλόγου μας.</Typography>
            </Box>


            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Τμήμα παραδοσιακών χορών και τραγουδιών</Typography>
                <img src={Image25} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>O Σύλλογός μας προτίθεται να επαναλειτουργήσει τμήματα παραδοσιακών χορών και τραγουδιών από τη Μ. Ασία γι αυτό όσοι ενδιαφέρονται μικροί και μεγάλοι
                    μπορούν να έλθουν σε συνεννόηση με την κ. Ευθαλία Τουρλή στο τηλ. 22510 91170 για να το δηλώσουν.
                   </Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Νέα Τμήματα</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας προτίθεται να ιδρύσει νέα τμήματα παραδοσιακών μουσικών οργάνων εφ όσον εκδηλωθεί σχετικό ενδιαφέρον από τα μέλη μας. Διευκρινίζουμε ότι
                    για να λειτουργήσει ένα νέο τμήμα θα πρέπει να ενδιαφερθούν κατ ελάχιστο τρία άτομα.</Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Δωρεά βιβλίων στο Σύλλογό μας</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ευχαριστούμε ιδιαιτέρως το βιβλιοπωλείο του κ. Νικολάου Σαράτση για τα πολλά βιβλία που χάρισε στην βιβλιοθήκη του Συλλόγου μας. Τα βιβλία αυτά είναι
                    αχρησιμοποίητα και κυρίως παιδικής λογοτεχνίας.  Επίσης ευχαριστούμε θερμά  τους  κ.κ. Αριστείδη Κλήμη, Θεόδωρο και Παναγιώτη Πελεκάνο, Γεώργιο Γονιδέλλη Ακίνδυνου,
                    Σταυράκη Ευστρατία, Κινικλή Μαρικούλα, Χούσου – Τατά Νίκη, Βασιλειάδη Βασίλειο, Πρωτόπαπα Αγγέλα, Μολυβιάτη Ρούλα, Αγραφιώτου-Πρόκα Μυρσίνη, Χαρίλαο Μωραΐτη, το
                    Κέντρο Μικρασιατικών Σπουδών, τη Νομαρχία Λέσβου, το Υπουργείο Αιγαίου, τα τυπογραφεία Δημητρίου Δούκα και Γεωργίου Παπαχρυσού για τα βιβλία που προσέφεραν στη
                    βιβλιοθήκη του Συλλόγου μας.</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα χρειάζονται να μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας η οποία ήδη στεγάζεται στο καινούργιο
                    κτίριο που κατασκευάσαμε στη Σκάλα Λουτρών και που αυτή τη στιγμή τα βιβλία που διαθέτει ξεπερνούν τις 4000.
                    Το βιβλίο είναι ένα παράθυρο ανοιχτό, γιατί προσανατολίζει τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση είναι δύναμη. Η μόρφωση
                    ευαισθητοποιεί
                    το άτομο απέναντι στα προβλήματα που αποτελούν  τη ζωή του σύγχρονου ανθρώπου και τοποθετεί τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη
                    διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και φανατισμούς.</Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Ευχαριστήρια</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Ευχαριστούμε θερμά τους παρακάτω.
                    *Το Υπουργείο Εμπορικής Ναυτιλίας Αιγαίου και Νησιωτικής Πολιτικής για την οικονομική ενίσχυση 3.000 ευρώ υπέρ των δραστηριοτήτων του Συλλόγου μας.<br />
                    *Το Νομάρχη Λέσβου κ. Παύλο Βογιατζή και το Νομαρχιακό Συμβούλιο Λέσβου για τη χορηγία τους στην έκδοση του ημερολογίου μας 2009.<br />
                    Την COSMOTE και τον ΟΤΕ για τις χορηγίες τους υπέρ των δραστηριοτήτων του «Μουσείου Προσφυγικής Μνήμης 1922» με 4000 και 2000 Ευρώ αντίστοιχα.<br />
                    *Τον ΟΤΕ Μυτιλήνης, την ΕΠΟΜ, το ξενοδοχείο ΖΑΙΡΑ,τον κ. Δεμερτζή Δημήτρη, το κατάστημα forma-biz-art και τους λοιπούς χορηγούς του ημερολογίου μας.<br />
                    *Τα καταστήματα της Μυτιλήνης και όσους προσφέρουν δώρα στις εκδηλώσεις μας.<br />
                    * Τον Μιχάλη Τουρλή για την δωρεάν εγκατάσταση του air condition στη βιβλιοθήκη μας.<br />
                    * Τον κ. Σαράτση Νίκο που επιδιορθώνει χωρίς αμοιβή τα βιβλία της βιβλιοθήκης μας.<br />
                    * Τον κ. Μαρίνο Κουκώνη για τον δωρεά ελαιοχρωματισμό στη βιβλιοθήκη και στο Μουσείο.<br />
                    *Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες του Συλλόγου καθώς και όσους προσφέρουν εθελοντικώς την εργασία τους.<br />
                    *Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο μας.<br />
                    *Ιδιαιτέρως ευχαριστούμε το συγχωριανό μας Δημήτρη Γρηγορέλλη που από ιδρύσεως του Συλλόγου μας , μας έχει παραχωρήσει αφιλοκερδώς το πατρικό του σπίτι
                    στη Σκάλα Λουτρών για την στέγαση  δραστηριοτήτων του Συλλόγου μας.<br /></Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Οικονομικές ενισχύσεις</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.<br />
                    Πελεκάνος Θεόδωρος          50 ευρώ.<br />
                    Παπαχρυσός Ιωάννης          50 ευρώ.<br />
                    Παπαχρυσός Γεώργιος         100 ευρώ.<br />
                    Σταθέλλης Παναγιώτης        20 ευρώ.<br />
                    Κλήμης Αριστείδης           100 ευρώ. <br />
                    Κατσαλή Γεωργία             50 ευρώ.<br />
                    Παπαδάκης Θεόδωρος          50 ευρώ.<br />
                    Παπαδάκης Ιωάννης           100 ευρώ.<br />
                    Ρετζέπης Ιωάννης            60 ευρώ.<br />
                    Κινικλή Τούλα               50 ευρώ.<br />
                    Κινικλή Μαρικούλα           50 ευρώ.<br />
                    Χατζηδήμος Αλέξανδρος       50 ευρώ.<br />
                    Βαλαχής Νικόλαος            50 ευρώ.<br />
                    Γρημάνη Μυρσίνη             100 ευρώ.<br />
                    Protelis George             50 ευρώ.<br />
                    Σταυρινού Έλλη              50 ευρώ.<br />
                    Μηναίδης Βασίλειος          100 ευρώ.<br /><br />

                    Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός  για αναβάθμιση του
                    οικισμού μας , έτσι ώστε να μπορέσουμε να ανταποκριθούμε στις υποχρεώσεις που έχουμε αναλάβει και να ολοκληρώσουμε τους στόχους μας σε σύντομο χρονικό διάστημα.
                </Typography>
            </Box>

            <ScrollToTopButton />


            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Νέα μέλη του Συλλόγου μας</Typography>
                <Typography variant="h3" sx={theme.typography.typography2}>Με μεγάλη χαρά καλωσορίζουμε στην οικογένεια του Συλλόγου μας τα νέα μέλη που είναι τα παρακάτω:<br />
                    Σκοπελίτης Σπύρος.<br />
                    Κοκονός Δημήτριος.<br />
                    Τριανταφύλλου Γεωργία.<br />
                    Παπά Ελένη.<br />
                    Χατζηδήμος Αλέξανδρος.<br />
                    Ραλής  Θεόδωρος<br /></Typography>
            </Box>

            <ScrollToTopButton />

            <Box sx={{ ...commonBoxStyles }}>
                <Typography variant="h1" sx={theme.typography.typography1}>Ποίημα.</Typography>
                <img src={Image26} style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', maxWidth: '100%', height: 'auto', margin: '10px' }} alt="δελφίνι" />
                <Typography variant="h3" sx={theme.typography.typography2}>Τι είναι η πατρίδα μας? Μην είν οι κάμποι?<br />
                    Μην είναι τα άσπαρτα ψηλά βουνά?<br />
                    Μην είν ο ήλιος της που χρυσολάμπει?<br />
                    Μην είναι τ άστρα της τα φωτεινά?<br />
                    Μην είναι κάθε της ρηχό ακρογιάλι<br />
                    και κάθε χώρα της με τα χωριά?<br />
                    κάθε νησάκι της που αχνά προβάλλει?<br />
                    κάθε της θάλασσα, κάθε στεριά?<br />
                    Μην είναι τάχατε τα ερειπωμένα<br />
                    αρχαία μνημεία της χρυσή στολή<br />
                    που η τέχνη εφόρεσε και το καθένα<br />
                    μια δόξα αθάνατη αντιλαλεί?<br />
                    Όλα πατρίδα μας! Κι αυτά κι εκείνα,<br />
                    και κάτι πού χουμε μες την καρδιά<br />
                    και λάμπει αθώρητο σαν ήλιου αχτίνα<br />
                    και κράζει μέσα μας: Εμπρός παιδιά!<br /><br />

                    Ιωάννης Πολέμης (1862-1924)
                    <br /></Typography>
            </Box>

            <ScrollToTopButton />


        </>
    )
}

export default D5_drastiriotites_2008