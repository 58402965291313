import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g2/image001.jpg";
import Image02 from "../assets/images/mikrasiatika_g2/image002.jpg";
import Image03 from "../assets/images/mikrasiatika_g2/image003.jpg";
import Image04 from "../assets/images/mikrasiatika_g2/image004.jpg";
import Image05 from "../assets/images/mikrasiatika_g2/image005.jpg";
import Image06 from "../assets/images/mikrasiatika_g2/image006.jpg";
import Image07 from "../assets/images/mikrasiatika_g2/image007.jpg";

function G2_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Φώκαια του 1914: Άλμπουμ φωτογραφιών του Φελίξ Σαρτιώ." />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <img src={Image01}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο <b>Φελίξ Σαρτιώ</b> (Felix Sartiax, 1876-1944) Γάλος μηχανικός και
          αρχαιολόγος ήταν ο πρώτος που οργάνωσε αρχαιολογικές ανασκαφές στην
          αρχαία Φώκαια της Μικράς Ασίας, το 1913, 1914 και 1920. Όταν ξέσπασε ο
          διωγμός της Φώκαιας στις 12 Ιουνίου 1914 (30 Μαΐου με το παλαιό
          ημερολόγιο) βρέθηκε εκεί για δεύτερη φορά και οι διωκόμενοι Φωκιανοί
          βρήκαν προστασία κοντά του, αφού ο επιφανής φιλέλληνας κατόρθωσε με
          την βοήθεια των συνεργατών του, να υψώσει τη σημαία της Γαλλικής
          Δημοκρατίας σε τέσσερα σπίτια και έτσι να σώσει μεγάλο μέρος του
          πληθυσμού από τη μανία των Τσετών. Το 1919 – 1920 επέστρεψε στη Φώκαια
          μαζί με τους Φωκιανούς πρόσφυγες, οι οποίοι επανεγκαταστάθηκαν στις
          πατρογονικές τους εστίες. Φωτογράφησε όλες τις στιγμές της χαράς και
          της θλίψης του τόπου, δημοσίευσε κείμενα και πολύ λίγες φωτογραφίες
          από όσα είχε δεί και ζήσει ως μάρτυρας του διωγμού. Όλο του το έργο
          είναι αφιερωμένο στην τέχνη της Μικράς Ασίας, με επίκεντρο τη Φώκαια.
        </Typography>

        <img src={Image02}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Αυτή η άγνωστη πτυχή της ιστορίας του Ελληνικού Έθνους και ειδικότερα
          των Φωκιανών, που η μοίρα τους έλαχε να έχουν υποστεί όχι έναν, αλλά
          δύο διωγμούς, το 1914 και το 1922, έρχεται να φωτιστεί με την πρόσφατη
          ανακάλυψη, από τον ιστορικό φωτογραφίας Χάρη Γιακουμή, πρώτα όλου του
          φωτογραφικού αρχείου του Σαρτιώ και ακολούθως του υπόλοιπου γραπτού
          του αρχείου. Μέσα στην προσωπική δερμάτινη βαλίτσα του, εκτός από το
          συμβολικό και ιδιαίτερα συγκινητικό πορτρέτο του εθνομάρτυρα
          Χρυσοστόμου Σμύρνης, βρέθηκαν όλα τα άρθρα και οι σημειώσεις του
          Σαρτιώ και των συνεργατών του για τα θλιβερά γεγονότα του 1914. Μας
          φανερώνουν τον πλέον σημαντικό μάρτυρα και πιστό φίλο του Ελληνισμού
          της Μικράς Ασίας, που τόσο τον αγάπησε και υποστήριξε σε όλες τις
          δύσκολες στιγμές της ύπαρξής του.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το σχετικό βιβλίο-άλμπουμ που έχει εκδοθεί και κυκλοφορεί συνιστούν
          φόρο τιμής στον λαό της Φώκαιας, με την τόσο ένδοξη ιστορία και
          παρελθόν. Μια ιστορία που ανάγεται στα κλασικά χρόνια, όταν ήδη από
          τον 6ο αιώνα π.χ. οι Φωκαείς ίδρυσαν διάφορες αποικίες – σταθμούς στη
          Μεσόγειο (τη Μασσαλία στη Γαλλία, το Εμπόριον στην Ισπανία, την Ελέα
          στην Ιταλία κ.α.) και έτσι διέδωσαν τον Ελληνικό πολιτισμό στην
          υπόλοιπη Ευρώπη.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <img src={Image03}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο ιερέας Κούκουλης φωτογραφίζεται δίπλα σε άγαλμα που πιθανόν είναι
          της θεάς Κυβέλης, 1913.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <img src={Image04}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μια ομάδα Τσέτες φορτωμένοι λάφυρα. Πίσω του,Έλληνες της Π. Φώκαιας
          έχουν συγκεντρωθεί μπροστά στο σπίτι του Σαρτιώ με τη Γαλλική σημαία
          να τους προστατεύει, 13-6-1911.{" "}
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <img src={Image05}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Οι Έλληνες κάτοικοι της Φώκαιας και των γύρω περιοχών επιβιβάζονται σε
          πλοιάρια και αναχωρούν για τη Θεσσαλονίκη, τον Πειραιά η τη Μυτιλήνη,
          Σάββατο 13 Ιουνίου 1914.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <img src={Image06}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στον Μικρό Γιαλό το σώμα νεαρής γυναίκας που πνίγηκε. Στο βάθος
          διακρίνονται οι έφιπποι Τσέτες να μπαίνουν στην πόλη από τη βόρεια
          πλευρά, Σάββατο 13 Ιουνίου 1914.{" "}
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <img src={Image07}   style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Μυτιλήνη. Ο καταυλισμός των Φωκιανών την επομένη της άφιξής τους, 19
          Ιουνίου 1914.{" "}
        </Typography>

      
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default G2_mikrasiatika;
