import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/19sinedriopandoiko_01.jpg";


function F5_oikologika_05() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="19ο συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>


      
      <Box sx={{ ...commonBoxStyles }}>
      <Typography variant="h1" sx={theme.typography.typography1}>&nbsp;</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>ΚΕΡΚΥΡΑ 5, 6 και 7 Οκτωβρίου 2007. </Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>ΘΕΜΑ:  «Κλιματικές Αλλαγές. Τοπικές Προτάσεις και Λύσεις»</Typography>
	
		
		<Typography variant="h3" sx={theme.typography.typography2}>Με μεγάλη επιτυχία πραγματοποιήθηκε στην πόλη της Κέρκυρας το τριήμερο 5-7 Οκτωβρίου,  το 19ο Συνέδριο του <a href="http://www.pandoiko.gr">Πανελληνίου Δικτύου 
		Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ)</a>, στο οποίο συνδιοργανωτής ήταν η ΚΕΔΚΕ.<br/>
		Το θέμα του Συνεδρίου ήταν:<br/>
		<b>«Κλιματικές Αλλαγές. Τοπικές Προτάσεις και Λύσεις»</b><br/>
		
		Οι εισηγητές ανέλυσαν, με πολύ γλαφυρό και απλό τρόπο, τις επιπτώσεις που έχει η ανθρώπινη συμπεριφορά στο παγκόσμιο κλίμα, τεκμηριώνοντας με συγκεκριμένα 
		παραδείγματα τις αλλαγές που συντελούνται και τις αιτίες που δημιουργούν τα νέα δεδομένα.<br/>		
		Το θεματικό Συνέδριο κατέληξε εκδίδοντας απόφαση, με βάση τις εισηγήσεις που αναπτύχθηκαν. Σε παράρτημα της απόφασης αναλύονται απλές συμβουλές προς τους 
		πολίτες και τους φορείς, με τις οποίες εξοικονομείται ενέργεια  για να αποφευχθούν περαιτέρω δυσάρεστες εξελίξεις για τον πλανήτη Γη.<br/>		
		Το δεύτερο μέρος του 19ου Συνεδρίου του <a href="http://www.pandoiko.gr">ΠΑΝΔΟΙΚΟ</a> αφορούσε σε εσωτερικές διαδικασίες και εκλογή νέας Επταμελούς Γραμματείας, 
		η οποία είναι η εξής:<br/> Βολιώτης Κώστας (Βόλος), Βασιλακόπουλος Γιώργος (Πάτρα), Ασλάνογλου Νίκος (Βέροια), Παπαχρυσός Δημήτρης (Μυτιλήνη), Γεροκώστα Γιάννα 
		(Λάρισα), Καλλιαμπέτσος Γιώργος	(Παλιό Φάληρο) και Αλευρά Άννα (Σέρρες).		
		Αποφασίστηκε το επόμενο Συνέδριο να πραγματοποιηθεί στις Σέρρες με θέμα: «Φυσικό Περιβάλλον, Φορείς Διαχείρισης και Δημόσια Διοίκηση»</Typography>
		
		<h4>Απόφαση συνεδρίου</h4>
		
		<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
		<Typography variant="h3" sx={theme.typography.typography2}>Οι εκπρόσωποι των οργανώσεων μελών του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων, οι εκπρόσωποι της ΚΕΔΚΕ και οι άλλοι σύνεδροι του 19ου Συνεδρίου
		του <a href="http://www.pandoiko.gr">ΠΑΝΔΟΙΚΟ</a>, έχοντας εμπλουτίσει σημαντικά τη γνώση μας για το κυριότερο, σήμερα, πρόβλημα,  του πλανήτη μας συνεκτιμούμε 
		και αποφασίζουμε τα ακόλουθα:</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η <b>ανθρωπογενής ανατροπή της παγκόσμιας κλιματικής ισορροπίας</b> είναι, πέραν πάσης αμφιβολίας. Η τελευταία  (φετινή) έκθεση της Διακυβερνητικής Επιτροπής
		για τις Κλιματικές Αλλαγές (IPCC),  καταλήγει στο συμπέρασμα ότι αν δε μειωθούν δραματικά οι εκπομπές αερίων του θερμοκηπίου, η μέση πλανητική θερμοκρασία
		μπορεί να αυξηθεί κατά 1,1°C – 6,4°C έως το 2095 σε σχέση με τα επίπεδα της περιόδου 1980-1999. Η αύξηση αυτή της θερμοκρασίας αναμένεται να προκαλέσει
		περισσότερους καύσωνες, ξηρασίες και πλημμύρες, όπως επίσης ισχυρότερους τυφώνες, λιώσιμο των πάγων και αύξηση της στάθμης της θάλασσας. Η προοπτική μιας
		καλπάζουσας ερημοποίησης της εύκρατης, ιδίως, ζώνης ως το τέλος του αιώνα που διανύουμε είναι μια ζοφερή αλλά ρεαλιστική προοπτική, αν δεν πραγματοποιηθεί
		τόσο τοπικά όσο και παγκόσμια μια στροφή 180 μοιρών στην παραγωγή και χρήση της ενέργειας, με δραστική μείωση της προερχόμενης από ορυκτά καύσιμα.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Ο <b>χρόνος</b> είναι η σημαντικότερη παράμετρος κάθε αποτελεσματικής απάντησης στο πρόβλημα. Η ταχύτητα της μεταβολής της ενεργειακής συμπεριφοράς ατόμων,
		κρατών και διεθνούς κοινότητας οφείλει να είναι μεγαλύτερη από την ταχύτητα με την οποία ήδη εξελίσσεται το φαινόμενο της υπερθέρμανσης του πλανήτη.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Είναι παραπάνω από προφανές ότι η άφεση της λύσης του προβλήματος στις δυνάμεις της αγοράς είναι όχι μόνο ανεπαρκής αλλά και παραπλανητική. Πολιτικές σε
		διεθνές, κρατικό και τοπικό επίπεδο οφείλουν να έχουν <b>σαφή δεσμευτικό χαρακτήρα</b> σύμφωνα με τις διακηρύξεις του Ρίο (1992) και του <b>Γιοχάνεσμπουργκ (2002),
		</b>που αποτελούν ένα διεθνές εργαλείο της βιώσιμης προοπτικής του πλανήτη.Η ενσωμάτωση της διάστασης της <b>ενεργειακής βιωσιμότητας</b> σε κάθε πολιτική, ιδιαίτερα
		την ηλεκτροπαραγωγή, τη γεωργία, τις μεταφορές, την πολεοδομία και την δόμηση,  είναι κοινός τόπος πλέον για την επιστημονική κοινότητα. Πρέπει να ενθαρρυνθεί
		η Ε.Ε. στην προσπάθειά της για αντιμετώπιση των κλιματικών αλλαγών σε πλανητικό επίπεδο.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η παρατηρούμενη προσπάθεια επιστροφής της πυρηνικής ενέργειας με πρόσχημα  την αντιμετώπιση του φαινομένου του θερμοκηπίου δεν είναι λύση και παραμένει μια
		τεράστια απειλή  για το περιβάλλον, τη ζωή και την ειρήνη.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η <b>πολιτική της χώρας μας</b> στα ζητήματα αυτά και ειδικότερα  όσον αφορά την αναδιάρθρωση εις βάρος των ορυκτών καυσίμων  και υπέρ των ΑΠΕ της παραγωγής
		ενέργειας,την ορθολογική χρήση και εξοικονόμηση ενέργειας, τα μέτρα περιορισμού των λοιπών αερίων του θερμοκηπίου, απέχει σημαντικά από τις απαιτήσεις <b>που επιβάλει
		η κρισιμότητα των περιστάσεων.</b> Η αντίφαση λόγων και έργων φτάνει στο σημείο να προγραμματίζονται νέες μονάδες λιγνίτη και λιθάνθρακα δυναμικότητας 2400 MW, τη 
		στιγμή που ήδη έχουμε υπερβεί τις δεσμεύσεις που έχουμε αναλάβει δυνάμει του πρωτοκόλλου του Κιότο.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Είναι επείγουσα ανάγκη <b>ριζικής μεταβολής ενεργειακού προτύπου ΤΩΡΑ!</b></Typography>
		<b>Απαιτούνται:</b>
		<ul>
			<li>Ταχεία ψήφιση ενός αποτελεσματικού χωροταξικού σχεδίου για τις ΑΠΕ.</li>
			<li>Εγκατάλειψη κάθε σχεδίου για νέες ανθρακικές μονάδες.</li>
			<li>Εθνικό σχέδιο εξοικονόμησης ενέργειας εξειδικευμένο ανά τομέα (δόμηση, βιομηχανία, κατοικίες, μεταφορές).</li>
			<li>Βιώσιμη διαχείριση των στερεών αποβλήτων  στην κατεύθυνση εξοικονόμησης ενέργειας  μέσω επανάχρησης, ανακύκλωσης και κομποστοποίησης.</li>
			<li>Αλλαγή του καταναλωτικού και διατροφικού μοντέλου με περιορισμό της υπερκατανάλωσης κρέατος, δεδομένου ότι η κτηνοτροφία συμβάλλει
			   σημαντικά με την εκπομπή μεθανίου στο φαινόμενο του θερμοκηπίου</li>
			<li>Πλατειά ενημέρωση του πληθυσμού για την εξοικονόμηση, τις ΑΠΕ, το παγκόσμιο πρόβλημα ώστε να στηριχθεί και σε ατομικό επίπεδο η αλλαγή ενεργειακής
			    συμπεριφοράς.</li>		
		</ul>
		<Typography variant="h3" sx={theme.typography.typography2}>Πολύ σημαντική είναι η διεθνής  (και ιδιαίτερα βαλκανική) συνεργασία  ενάντια στην πυρηνική ενέργεια και η απεξάρτηση από τα ορυκτά καύσιμα.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Οι οικολογικές οργανώσεις της χώρας μαζί με την Τοπική Αυτοδιοίκηση ως τμήμα του παγκόσμιου οικολογικού κινήματος,  επιδιώκοντας να λειτουργήσουμε ως μέρος
		της συνείδησης της ανθρωπότητας, διακηρύσσουμε ότι η αλλαγή ενεργειακής συμπεριφοράς και ενεργειακού προτύπου, τοπικά και παγκόσμια, είναι ο μόνος τεκμηριωμένος
		ρεαλισμός, η μόνη ελπιδοφόρα προοπτική για την ανθρωπότητα αλλά και για την κινδυνεύουσα από ερημοποίηση χώρα μας.</Typography>
		</Box>
    </>
  );
}

export default F5_oikologika_05;
