import React, { useState, useEffect } from "react";
import { Card, CardMedia, Box, Typography } from "@mui/material";

const ImageCarousel = ({ images, interval = 3000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [images, interval]);

  return (
    <>
     

      <Card
        style={{
          maxWidth: 600,
          margin: "auto",
          overflow: "hidden",
          position: "relative",
          marginTop:'2em'
        }}
      >
        <Box style={{ height: 400, width: "100%", position: "relative" }}>
          <CardMedia component="img" image={images[index].url} />

          <Typography
            variant="subtitle1"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              padding: "8px",
              width: "100%",
              textAlign: "center",
            }}
          >
            {images[index].caption}
          </Typography>
        </Box>
      </Card>

    
    </>
  );
};

export default ImageCarousel;
