import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/Hmerologia_p8/image001.jpg";
import Image02 from "../assets/images/Hmerologia_p8/image002.jpg";
import Image03 from "../assets/images/Hmerologia_p8/image003.jpg";
import Image04 from "../assets/images/Hmerologia_p8/image004.jpg";
import Image05 from "../assets/images/Hmerologia_p8/image005.jpg";
import Image06 from "../assets/images/Hmerologia_p8/image006.jpg";
import Image07 from "../assets/images/Hmerologia_p8/image007.jpg";
import Image08 from "../assets/images/Hmerologia_p8/image008.jpg";
import Image09 from "../assets/images/Hmerologia_p8/image009.jpg";
import Image10 from "../assets/images/Hmerologia_p8/image010.jpg";
import Image11 from "../assets/images/Hmerologia_p8/image011.jpg";
import Image12 from "../assets/images/Hmerologia_p8/image012.jpg";
import Image13 from "../assets/images/Hmerologia_p8/image013.jpg";
import Image14 from "../assets/images/Hmerologia_p8/image014.jpg";
import Image15 from "../assets/images/Hmerologia_p8/image015.jpg";
import Image16 from "../assets/images/Hmerologia_p8/image016.jpg";
import Image17 from "../assets/images/Hmerologia_p8/image017.jpg";
import Image18 from "../assets/images/Hmerologia_p8/image018.jpg";
import Image19 from "../assets/images/Hmerologia_p8/image019.jpg";
import Image20 from "../assets/images/Hmerologia_p8/image020.jpg";
import Image21 from "../assets/images/Hmerologia_p8/image021.jpg";

function P8_Hmerologia() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        <HomeIcon />
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ημερολόγιο 2011: Φώκαια, Ο πρώτος ξεριζωμός." />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ιστορική αναδρομή
        </Typography>
        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Η Φώκαια, παράλια πόλη και λιμάνι της Μ. Ασίας ιδρύθηκε περίπου τον 8ο
          π.Χ. αιώνα από Έλληνες αποίκους, ιωνικής προέλευσης. Γεωγραφικά δε,
          αποτελεί το πέρας της Αιολίδας και την αρχή της Ιωνίας.
          <br />
          Για την ονομασία της, συνυπάρχουν δύο εκδοχές. Η μια υποστηρίζει πως
          το όνομά της το πήρε από το ότι πολλοί άποικοι κατάγονταν από τη
          Φωκίδα, ενώ η άλλη, επειδή η περιοχή αποτελούσε πέρασμα για τις φώκιες
          της Μεσογείου. Η δεύτερη αυτή εκδοχή ενισχύεται κι από νόμισμα της
          πόλης πάνω στο οποίο ήταν χαραγμένο κεφάλι φώκιας. Κατά μία παράδοση,
          η κύρια χώρα (όπου κατοίκησαν οι πρώτοι άποικοι) ήταν ένα πετρώδες
          νησί, το οποίο οι Φωκαείς ένωσαν κατόπιν με τη στεριά. Κύρια ασχολία
          των Φωκαέων υπήρξε η ναυτιλία και το εμπόριο. Η περίοδος 605-560 π.Χ.
          είναι η πιο ένδοξη εποχή της εμπορικής θαλασσοκρατίας για τους
          Φωκαείς, που διατηρήθηκε μέχρι τον καιρό της βασιλείας του Κροίσου, 54
          ολόκληρα χρόνια.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Η Φώκαια ήταν απ’ τις πιο μικρές πόλεις της Ιωνίας αλλά η ποιο
          δραστήρια στο εμπόριο και τον αποικισμό. Όταν στο τέλος του 7ου αιώνα
          παρουσιάστηκε ανάγκη να δημιουργήσει νέες αποικίες, βρήκε πιασμένες
          από άλλους τις κοντινότερες περιοχές και έτσι αφού ίδρυσε τη Λάμψακο
          στον Ελλήσποντο, στράφηκε μόνη αυτή απ όλες τις ιωνικές πόλεις προς τη
          Δυτική Μεσόγειο, ιδρύοντας ονομαστές αποικίες όπως τη Μασσαλία στη
          Γαλλία, το Εμπόριον στην Ισπανία, την Ελέα στην Ιταλία κ.α. Για τη
          Μασσαλία μια παράδοση λέει, ότι τη μέρα που έφθασαν οι Φωκαείς, ο
          ντόπιος βασιλιάς πάντρευε την κόρη του και κάλεσε στο γάμο και τους
          ξένους. Επειδή ήταν συνήθεια του τόπου να διαλέγει η νύφη το γαμπρό
          στο τέλος του συμποσίου, η πριγκιποπούλα προσέφερε το γαμήλιο κύπελλο
          της στον αρχηγό των Φωκαέων, σημάδι ότι προτιμούσε αυτόν για σύζυγό
          της. Έτσι δημιουργήθηκε σύμφωνα με την παράδοση, η Μασσαλία κι αυτό
          διδασκόταν στα σχολεία της Φώκαιας για την ιστορία της, μέχρι και
          τελευταία.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Σήμερα στο Δημαρχείο της Μασσαλίας βρίσκεται μαρμάρινο αντίγραφο με
          ανάγλυφο τον «Πετεινό της Νίκης», δώρο των Φωκαέων προς τους
          Μασσαλιώτες. Ακόμη εκεί υπάρχει εντοιχισμένη πλάκα αφιερωμένη στην
          πανάρχαια «μητέρα» πατρίδα και στους τολμηρούς Φωκαείς θαλασσοπόρους,
          που την ίδρυσαν. Η περίοδος ακμής της Φώκαιας κράτησε πάνω από
          τετρακόσια χρόνια, κόβοντας μάλιστα δικό της χρυσό νόμισμα το «Φωκαϊκό
          Στατήρα», δείγμα της οικονομικής και πολιτικής ισχύος της. <br />
          Το οικονομικό, πνευματικό, επιστημονικό και πολιτιστικό θαύμα της
          ιωνικής δωδεκάπολης (συμπεριλαμβανομένης της Φώκαιας ),
          μεταλαμπαδεύτηκε βαθμηδόν στην ηπειρωτική Ελλάδα και μετέπειτα σε όλον
          το δυτικό κόσμο, χαρακτηρίζοντας τη συνέχεια της πορείας του σ’ αυτό
          που είναι γνωστότερο σήμερα ως «δυτικός πολιτισμός». <br />
          Το 1250 μ.χ. ιδρύεται η Νέα Φώκαια από κατοίκους της Παλαιάς Φώκαιας,
          για την καλύτερη εκμετάλλευση των ορυχείων στύψης, ουσίας απαραίτητης
          την εποχή εκείνη στη βαφή των υφασμάτων. Η Νέα Φώκαια τοποθετείται
          λίγο βορειότερα της Παλαιάς, είναι χτισμένη σε μια μικρή πεδιάδα, με
          κήπους, αμπέλια, ελαιώνες και με έναν μικρό υπήνεμο όρμο. <br />
          Μετά την κατάληψή της Φώκαιας από τους Τούρκους το 1455 και τις
          καταστροφές που υπέστη, δεν μπόρεσε πάλι να ανακτήσει την παλιά
          εμπορική της ισχύ. Στην ελληνική επανάσταση του 1821 οι κόλποι της
          Παλαιάς και της Νέας Φώκαιας χρησίμευσαν ως ορμητήρια στα ψαριανά
          καράβια, στον αγώνα τους για την απελευθέρωση της Ελλάδας
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Σε απογραφή του 1904 οι κάτοικοι της Π. Φώκαιας υπολογίζονται σε 7.704
          από τους οποίους οι 6.300 είναι Έλληνες. Το 1914, τέλη Μαΐου ξέσπασε ο
          «πρώτος διωγμός» εναντίον των Ελλήνων, σε εφαρμογή ενός ευρύτερου
          σχεδίου απομάκρυνσης των από τα δυτικά παράλια της Μικράς Ασίας.{" "}
          <br />
          Όσοι κατόρθωσαν να γλιτώσουν κατέφυγαν στη Λέσβο, όπου εγκαταστάθηκαν
          πρόχειρα στις πόλεις και τα χωριά, σε εκκλησίες, εργοστάσια και
          σχολεία, περνώντας κάτω από αντίξοες συνθήκες πέντε χρόνια προσφυγιάς.
          Απ αυτούς οι περισσότεροι το 1919, όταν ο Ελληνικός στρατός
          αποβιβάστηκε στη Σμύρνη, επέστρεψαν πίσω στις πατρίδες τους. Τους
          πρόσφυγες που επέστρεψαν τους περίμενε σκληρή δουλειά, αφού έπρεπε να
          τακτοποιήσουν τα εγκαταλειμμένα κτήματα, να επιδιορθώσουν τα
          κατεστραμμένα από τις λεηλασίες σπίτια και καταστήματά τους. Μετά όμως
          την κατάρρευση του μικρασιατικού μετώπου τον Αύγουστο του 1922 και την
          καταστροφή που επακολούθησε, όσοι σώθηκαν μεταφέρθηκαν εκ νέου στα
          απέναντι ελληνικά νησιά και πολλοί από αυτούς στην ηπειρωτική Ελλάδα.
          Έτσι το ελληνικό στοιχείο που ουσιαστικά δημιούργησε τις πόλεις της
          Παλαιάς αλλά και της Νέας Φώκαιας, εγκαταλείπει οριστικά τη
          μικρασιατική γη των προγόνων του, δημιουργώντας σε πολλά μέρη της
          Ελλάδας νέες πόλεις και νέα χωριά. Η ονομασία Παλαιά Φώκαια και Νέα
          Φώκαια αναβιώνει σε δύο από αυτές τις εγκαταστάσεις, στην Αττική
          (Ανάβυσσος) και στη Χαλκιδική αντίστοιχα. Ένα μικρό μεν αλλά συμπαγές
          κομμάτι από Φωκιανούς πρόσφυγες εγκαταστάθηκε και στη Μυτιλήνη, στην
          περιοχή της Σκάλας Λουτρών όπου το 1931 δημιουργείται ο προσφυγικός
          Συνοικισμός της, που το 60% περίπου του μικρασιατικού πληθυσμού της
          έλκει την καταγωγή από τις Παλαιές και Νέες Φώκαιες.{" "}
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΙΑΝΟΥΑΡΙΟΣ – Φώκαια.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Αν κοιτάξει κανείς από κοντά τις φωτογραφίες που παρουσιάζονται στο
          ημερολόγιο τούτο από τη Φώκαια της Μικράς Ασίας, μαγνητίζεται από το
          σπάνιο υλικό. «Ποιος είναι ο φωτογράφος;», η εύλογη ερώτηση. «Ο Felix
          Sartiaux, ο Γάλλος αρχαιολόγος που έκανε ανασκαφές στην περιοχή».
          <br />
          Η Φώκαια της Μικράς Ασίας, η Παλιά και η Νέα, κοντά η μία στην άλλη.
          Ελληνικός τόπος ως το 1922. Τα σπίτια θυμίζουν Αιγαίο. Άσπροι σοβάδες,
          κόκκινα βυζαντινά κεραμίδια, κωδωνοστάσια. Ευημερία πολίχνης με
          πλούσια ενδοχώρα και ανοιχτή θάλασσα.
          <br />
          Το ερώτημα…«Πού βρέθηκαν;». «Δημοπρατήθηκαν στο Παρίσι από τον οίκο
          Drouot, βρέθηκαν στα χέρια Τούρκου εμπόρου, στο Λονδίνο» και από αυτόν
          τις αγόρασε ο κ. Χάρης Γιακουμής, ιστορικός φωτογραφίας και Κύπριος
          στην καταγωγή. Ένας ιχνηλάτης της πρώιμης φωτογραφίας με έδρα το
          Παρίσι.
          <br />
          Πρωτοείδαμε τη Φώκαια του Sartiaux σε διαφάνειες στο «Μουσείο
          Προσφυγικής Μνήμης 1922» το Φεβρουάριο του 2007, σε μία αίθουσα με
          χαμηλωμένα φώτα και τους νεκρούς της Φώκαιας να ζωντανεύουν στην
          οθόνη. Μέσα σε υποβλητική σιωπή. Ήταν το πρώτο ξεμύτισμα των
          φωτογραφιών στο σύγχρονο κόσμο. <br />Η φωτογραφική καταγραφή των
          γεγονότων στην Παλαιά Φώκαια της Μικράς Ασίας ανήκει πλέον και αυτή
          στην ιστορία της φωτογραφίας, ελληνικού ενδιαφέροντος. Στην Παλαιά
          Φώκαια έμεναν εκείνη την εποχή λίγο περισσότεροι από 6.000 Έλληνες,
          που ζούσαν από το εμπόριο, τη θάλασσα και τη γεωργία. Ήταν μία ανθηρή
          κοινότητα, όπως οι περισσότερες στη Μικρά Ασία αλλά στη διάρκεια του
          Α΄ Παγκόσμιου Πολέμου οι διεθνείς ισορροπίες άλλαξαν οριστικά. Οι
          Έλληνες της Φώκαιας εκδιώχθηκαν το “1914”, ξαναγύρισαν το “1919” με
          την εγκατάσταση της ελληνικής διοίκησης για να εγκαταλείψουν οριστικά
          τα πάτρια εδάφη το “1922 – 23”.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΦΕΒΡΟΥΑΡΙΟΣ - Φελίξ Σαρτιώ.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο <b>Φελίξ Σαρτιώ</b> (Felix Sartiaux, 1876-1944) Γάλλος μηχανικός και
          αρχαιολόγος, ήταν ο πρώτος που οργάνωσε αρχαιολογικές ανασκαφές στην
          αρχαία Φώκαια της Μικράς Ασίας το “1913”, “1914” και “1920”. Όταν
          ξέσπασε ο διωγμός της Φώκαιας στις 12 Ιουνίου “1914” (30 Μαΐου με το
          παλαιό ημερολόγιο) βρέθηκε εκεί για δεύτερη φορά και οι διωκόμενοι
          Φωκιανοί βρήκαν προστασία κοντά του, αφού ο επιφανής φιλέλληνας
          κατόρθωσε με την βοήθεια των συνεργατών του, να υψώσει τη σημαία της
          Γαλλικής Δημοκρατίας σε τέσσερα σπίτια και έτσι να σώσει μεγάλο μέρος
          του πληθυσμού από τη μανία των Τσετών. Το “1919” – “1920” επέστρεψε
          στη Φώκαια μαζί με τους Φωκιανούς πρόσφυγες, οι οποίοι εγκαταστάθηκαν
          ξανά στις πατρογονικές τους εστίες. Φωτογράφισε όλες τις στιγμές της
          χαράς και της θλίψης του τόπου, δημοσίευσε κείμενα και πολύ λίγες
          φωτογραφίες από όσα είχε δει και ζήσει ως μάρτυρας του διωγμού. Όλο
          του το έργο είναι αφιερωμένο στην τέχνη της Μικράς Ασίας, με επίκεντρο
          τη Φώκαια.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Αυτή η άγνωστη πτυχή της ιστορίας του Ελληνικού Έθνους και ειδικότερα
          των Φωκιανών που η μοίρα τους έλαχε να έχουν υποστεί όχι έναν αλλά δύο
          διωγμούς, το “1914” και το “1922”, έρχεται να φωτιστεί με την πρόσφατη
          ανακάλυψη από τον ιστορικό φωτογραφίας κ. Χάρη Γιακουμή, πρώτα όλου
          του φωτογραφικού αρχείου του Σαρτιώ και ακολούθως του υπόλοιπου
          γραπτού του αρχείου. Μέσα στην προσωπική δερμάτινη βαλίτσα του, εκτός
          από το συμβολικό και ιδιαίτερα συγκινητικό πορτραίτο του εθνομάρτυρα
          Χρυσοστόμου Σμύρνης, βρέθηκαν όλα τα άρθρα και οι σημειώσεις του
          Σαρτιώ και των συνεργατών του για τα θλιβερά γεγονότα του “1914”. Μας
          φανερώνουν δε, τον πλέον σημαντικό μάρτυρα και πιστό φίλο του
          Ελληνισμού της Μικράς Ασίας που τόσο αγάπησε και υποστήριξε σε όλες
          τις δύσκολες στιγμές της ύπαρξής του.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΜΑΡΤΙΟΣ - Οι ανασκαφές.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στα 35 του χρόνια ο Φελίξ Σαρτιώ, απόφοιτος της Πολυτεχνικής σχολής
          είχε δείξει ότι ενδιαφερόταν για την αρχαιολογία δημοσιεύοντας τις
          ανακοινώσεις του για διάφορες θέσεις της Μ. Ασίας. Λίγο αργότερα, το
          Υπουργείο Δημόσιας εκπαίδευσης της Γαλλίας, του εμπιστεύθηκε δύο
          αποστολές. Η πρώτη ήταν να μελετήσει την αρχιτεκτονική και το γλυπτό
          διάκοσμο του αρχαϊκού ναού στην Άσσο και η δεύτερη να διερευνήσει τη
          θέση της Φώκαιας. Η Παλαιά Φώκαια τράβηξε το ενδιαφέρον της αποστολής,
          λόγω των δεσμών της με την λαμπρή της αποικία (τη Μασσαλία). Καμιά
          διερεύνηση δεν είχε επιχειρηθεί σε αυτή την τόσο σημαντική περιοχή. Η
          βασική αποστολή πραγματοποιήθηκε τον Οκτώβριο του 1913, ενώ η
          προσπάθεια του Ιουνίου του 1914 σταμάτησε απότομα με την απρόκλητη
          επίθεση εναντίον του ελληνικού πληθυσμού (πρώτος διωγμός). Τις έρευνές
          του στη Φώκαια τις γνωρίζουμε κατά βάση από δύο άρθρα δημοσιευμένα
          στον απολογισμό πεπραγμένων της Ακαδημίας Επιγραφών και Γραμμάτων
          (1914 και 1921) και από φυλλάδιο όπου αναδημοσιεύεται το κείμενο
          διάλεξής του.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ένα αυθεντικό φυλλάδιο του Φελίξ Σαρτιώ με τα πεπραγμένα του έτους
          1921 εκτίθεται στο «Μουσείο Προσφυγικής Μνήμης 1922» της Σκάλας
          Λουτρών, χαρισμένο από την υπερήλικα σήμερα ανιψιά του, την κ. Ciepka
          μετά από μεσολάβηση και προτροπή του ιστορικού φωτογραφίας και φίλου
          του Συλλόγου Μικρασιατών της Σκάλας Λουτρών κ. Χάρη Γιακουμή.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΑΠΡΙΛΙΟΣ - Τα κατάλοιπα του παρελθόντος.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          «Τα κατάλοιπα του παρελθόντος που βλέπεις πάνω στο έδαφος είναι τριών
          ειδών: μνημεία σκαλισμένα στο βράχο απροσδιόριστης εποχής, τμήματα
          μνημείων και θραύσματα ελληνικών και ρωμαϊκών αντικειμένων, κτίσματα
          και απομεινάρια του Μεσαίωνα. Σας μίλησα για ένα μεγάλο μεγαλιθικό
          μνημείο στον δρόμο από τη Σμύρνη στη Φώκαια που η κατασκευή του μπορεί
          να αποδοθεί στους Φρύγες. Στην ίδια την αρχαιολογική θέση της Φώκαιας
          υπάρχουν πολλές ομάδες μνημείων λαξευμένων στον βράχο. Στους πρόποδες
          του Προφήτη Ηλία έχει ανοιχτεί μεγάλος τάφος γνωστός στην περιοχή με
          το όνομα Λουτρός (στα τουρκικά Σευτάν Χαμάμ, λουτρό του διαβόλου),
          όπου μπαίνεις από θολωτή πύλη με καμάρα. Αποτελείται από δυο
          ορθογώνιους θαλάμους που τους χωρίζει άλλη θολωτή πόρτα ανοιγμένη στα
          τοιχώματα με αέτωμα από πάνω. <br />
          Στους βράχους των λόφων Χρυσοσπηλιώτισσα και Μύλοι του Μπακαθανάση
          βρίσκουμε σειρές λαξεύσεων, άλλες κλιμακωτές, άλλες σαν ορθογώνιες
          κόγχες όπου τοποθετούσαν μάλλον αναθηματικές εικόνες ή επικλήσεις για
          τις θεότητες του τόπου. Τέλος στο χείλος ενός φαραγγιού στα βόρεια της
          πόλης, υπάρχει ολόκληρη νεκρόπολη, που οι σκαφοειδείς της τάφοι σε
          διάφορες διαστάσεις, είναι σκαλισμένοι οριζόντια στο βράχο και έχουν
          αυλάκι όπου εφάρμοζε το σκέπασμα. Τα λείψανα αυτά μπορεί να ανήκουν
          στην Ελληνική εποχή ή να χρησιμοποιήθηκαν ξανά τότε. Κλίνω μάλλον να
          πιστεύω ότι ανάγονται σε πολύ παλιότερη εποχή και ότι ίσως τα έφτιαξε
          ο φρυγικός πληθυσμός που τόσα και τόσα λαξευμένα στον βράχο μνημεία
          του σώζονται ακόμη στη Μικρά Ασία».
          <br />
          <br />
          (Φελίξ Σαρτιώ Απρίλιος 1914).
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΜΑΙΟΣ - Ο πρώτος διωγμός.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Οι δύο πληθυσμοί, ο ελληνικός και ο τουρκικός, έχοντας κατακτήσει την
          ισορροπία της συμβίωσης και αποκαταστήσει σχέσεις που ελαχιστοποιούσαν
          προβλήματα και διαφορές, έφτασαν σε φάση εξαιρετικά κρίσιμη το “1914”
          όπου δυστυχώς δεν μπόρεσαν, όπως ήταν φυσικό, να διαφυλάξουν την
          παραδοσιακή τους αμοιβαία εμπιστοσύνη και θέληση να παραμείνουν
          σύνοικοι όπως παλιά. Έτσι, μέσα στο κλίμα της εποχής που καθοριζόταν
          από την εθνικιστική πολιτική των Νεότουρκων και του Α΄ παγκοσμίου
          πολέμου, οι δεσμοί έσπασαν και η ρήξη οδήγησε για πρώτη φορά τον
          ελληνικό πληθυσμό στη φυγή και την εγκατάλειψη των περιουσιών του. Η
          έκταση αλλά και η ένταση των διωγμών, που παρατηρήθηκαν αυτή την
          περίοδο στα παράλια της Μικράς Ασίας και στην ευρύτερη περιφέρεια της
          Παλαιάς Φώκαιας, ήταν πρωτοφανείς. Μέχρι το τέλος του Ιουνίου του
          “1914”, ολόκληρος σχεδόν ο ελληνικός πληθυσμός είχε εγκαταλείψει την
          περιοχή. Από τα αρχεία του ελληνικού υπουργείου περιθάλψεως της εποχής
          εκείνης διαπιστώνεται ότι ο αριθμός των Ελλήνων από την περιφέρεια της
          Φώκαιας που έφτασε στην Ελλάδα, υπολογίζεται περί τις 18.000 και ως
          τόποι διαμονής τους αναφέρονται οι περιοχές της Μυτιλήνης με 6.000
          Φωκιανούς, της Θεσσαλονίκης με 3.000, της Αθήνας και του Πειραιά με
          1.600, της Εύβοιας με 150, του Ηρακλείου Κρήτης με 150, της Καβάλας,
          της Χίου, του Βόλου κ.α. Ειδικότερα στην περιοχή της Μυτιλήνης, για
          την καλύτερη περίθαλψη των προσφύγων, η πόλη χωρίστηκε σε δέκα τμήματα
          και ένα ήταν αποκλειστικά για τους Έλληνες από την περιφέρεια της
          Φώκαιας. Η λεηλασία της Φώκαιας και η εκδίωξη των Ελλήνων της Μικράς
          Ασίας τον Ιούνιο του “1914” είχε ήδη συντελεστεί. Ο Felix Sartiaux
          ήταν ο μοναδικός ξένος μάρτυρας των γεγονότων, αλλά οι φωτογραφίες του
          αυτές δεν είχαν ποτέ δημοσιευθεί μέχρι την πρόσφατη ανακάλυψή τους.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΙΟΥΝΙΟΣ - Η καταστροφή.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η καταστροφή από αυτόν το διωγμό είναι σχεδόν ολοκληρωτική. Ειδικά
          στην Νέα Φώκαια, των 7.500 περίπου κατοίκων εκ των οποίων οι 6.500
          ήταν Έλληνες, λεηλατούνται και ερημώνονται τα πάντα τη νύχτα της 11ης
          προς την 12η Ιουνίου του “1914”. Μέσα στα σπίτια βασιλεύει πλέον
          απερίγραπτη αταξία. Έπιπλα, συρτάρια, μπαούλα κείτονται καταγής,
          σπασμένα και ξεκοιλιασμένα. Οι Τσέτες, (άτακτος τούρκικος στρατός)
          αρπάζουν βιαστικά ότι πολύτιμο βρίσκουν και μπορούν να το μεταφέρουν
          εύκολα. Οι πόρτες και τα παραθυρόφυλλα, που είχαν φροντίσει να τα
          κλείσουν οι ιδιοκτήτες τους, έχουν πλέον παραβιαστεί και καπνός
          βγαίνει από πολλές συνοικίες από τις φωτιές που έχουν μπει.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Όπως μας αφηγείται ο Φελίξ Σαρτιώ «Ουρλιαχτά ακούγονται, αλαλιασμένοι
          άνθρωποι φεύγουν τρέχοντας με τα ρούχα σκισμένα και το πρόσωπο
          ματωμένο, ενώ οι πληγωμένοι σέρνονται στο κατώφλι. Όσοι επιτιθέμενοι
          είχαν μπει με τη βία στα σπίτια, βγαίνουν με τις αγκαλιές φορτωμένες
          μπόγους, που τους στοιβάζουν βιαστικά μέσα σε μεγάλα κοφίνια πάνω στα
          ζώα τους. Το πλήθος ορμάει προς τις προκυμαίες, αναζητώντας με το
          βλέμμα πλεούμενα για να φύγει. Όμως όλα σχεδόν τα πλεούμενα έχουν
          εξαφανιστεί από την προηγούμενη. Φωνές φρίκης απαντούν στους
          πυροβολισμούς. Λεηλατούν, πυρπολούν, σκοτώνουν ψυχρά χωρίς μίσος, κατά
          μία έννοια μεθοδικά. Εφαρμόζουν πρόγραμμα, που τους το έχουν σχεδιάσει
          στο όνομα των ανώτερων συμφερόντων της αυτοκρατορίας και της
          θρησκείας. Η λεηλασία, οι προσωπικές εκδικήσεις, ο βιασμός είναι ο
          μισθός τους..,.»
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΙΟΥΛΙΟΣ - Φθάνοντας στη Μυτιλήνη.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          «Φορτώνουμε στην πλάτη μας τα ανθρώπινα ναυάγια και τα μεταφέρουμε στο
          καράβι μας που βάζει αμέσως πλώρη για τη Μυτιλήνη. Στη μέση της
          διαδρομής, σε 15 πάνω κάτω μίλα από την ακτή, περισυλλέγουμε ένα καΐκι
          έτοιμο να ναυαγήσει, φορτωμένο κατσίκια και τρεις άμοιρους φυγάδες,
          που έρχεται από την ακτή. Είμαστε περίπου εβδομήντα άνθρωποι πάνω στο
          πλοίο. Άλλοι περιπλανήθηκαν για πολλές μέρες αλλόφρονες στο βουνό για
          να αποφύγουν το θάνατο. Άλλους πάλι τους είχαν βρει σε σοκάκια, σε
          αυλές, σε κήπους. Μια γριά 99 ετών την τυλίξαμε σε κουβέρτα. Τις
          έριξαν δύο σφαίρες στο μπράτσο, οι πληγές της είναι βαθιές και
          πυορροούν. Ένας άντρας έμεινε στο αμπέλι του γιατί τον νόμισαν νεκρό.
          Έχει σφαίρα στο δεξί πλευρό, το χέρι του το έσπασαν και, μένοντας
          χωρίς φροντίδα, η γάγγραινα έφτασε στο βραχίονα. Η κατάστασή του είναι
          απελπιστική. Ένας άλλος μου διηγείται ότι κατέφυγε στο κάστρο της
          Παλαιάς Φώκαιας, όπου έμεινε τρεις μέρες κρυμμένος. Οι συνεργάτες μου
          τον βρήκαν και ζήτησαν από τον καϊμακάμη να του δώσει για συνοδεία δύο
          χωροφύλακες ώστε να πάει στο σπίτι του και να πάρει ρούχα και
          παπούτσια. Φτάνοντας στο σπίτι, οι φρουροί του ρίχτηκαν, του
          ακούμπησαν το ρεβόλβερ στο στήθος και απαίτησαν να τους δώσει ότι
          διέθετε σε ρευστό…»
          <br />
          <br />
          (Φελίξ Σαρτιώ Ιούνιος 1914).
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΑΥΓΟΥΣΤΟΣ - Κατ εντολή της Γαλλικής κυβέρνησης.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          «Έχοντας αναλάβει από τη Γαλλική κυβέρνηση αρχαιολογική αποστολή στη
          Φώκαια, στη θέση της αρχαίας και λαμπρής Ιωνικής πόλης που διέδωσε στη
          δική μας Προβηγκία τα καλά του Ελληνισμού και του πολιτισμού,
          ανακατεύθηκα και μοιραία συμμετείχα ο ίδιος, ενεργά στα τραγικά
          γεγονότα που εκτυλίχθηκαν τον περασμένο Ιούνιο στις ακτές της Μικράς
          Ασίας και τα σκέπασε μέχρι τώρα η σιωπή. Είδα πολλά, συνέλεξα
          προσωπικά και άμεσα πλήθος μαρτυρίες. Είχα θεωρήσει όμως ότι κατά την
          εκτέλεση της αποστολής μου η επίσημη ιδιότητά μου με υποχρέωνε να
          απευθύνω τις αναφορές, τις διαπιστώσεις, τις σκέψεις μου αποκλειστικά
          στην κυβέρνηση και σε εκείνους που μου είχαν αναθέσει τα καθήκοντά
          μου. Η αποστολή διακόπηκε απότομα στις πρώτες μέρες του Ιουλίου, λίγες
          εβδομάδες προτού πέσει ο τρομακτικός κεραυνός που αναστάτωσε την
          Ευρώπη και που ο απόηχός του μόλις απλώθηκε μέχρις εδώ, στις ακτές της
          Ασίας. Εκτιμώ ότι παραθέτοντας τώρα τα γεγονότα των οποίων υπήρξα
          μάρτυρας, δίνοντας στην κοινή γνώμη τις πληροφορίες που συγκέντρωσα,
          ευθύνομαι πλέον μόνον προσωπικά. Η σιωπή κράτησε δυστυχώς πολύ καιρό.
          Η ιστορία, η δικαιοσύνη, η ανθρωπότητα, η φήμη περί ευθύτητας και
          τιμής που απολαμβάνει η Γαλλία ανά τον κόσμο έχουν να κερδίσουν αν
          ακουστεί η μαρτυρία μου».
          <br />
          <br />
          (Φελίξ Σαρτιώ).
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΣΕΠΤΕΜΒΡΙΟΣ - Η ταυτότητα των προσφύγων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          «Οι πληθυσμοί που μόλις εκδιώχθηκαν από την Ανατολία δεν είναι οι
          οποιοιδήποτε πληθυσμοί που η τόσο ταραγμένη ανατολική πολιτική, τους
          μεταφέρει από τη μια περιοχή σε άλλη. Είναι οι άμεσοι κληρονόμοι των
          λαμπρών και ανδρείων Ελλήνων, οι οποίοι μας κληροδότησαν τον πολιτισμό
          μας, έθεσαν τις βάσεις του δικαίου και της ηθικής, ανακάλυψαν τις
          πρώτες αρχές της επιστήμης και έκαναν τη γοητεία και την τελειότητα
          του κάλους να θεριέψει στον κόσμο. Εδώ και τρεις χιλιάδες χρόνια, οι
          ακτές της Μικράς Ασίας είναι ελληνικές. Οι Έλληνες δημιούργησαν τη
          ζωή, τη συντήρησαν και την ανέπτυξαν σε αυτή τη χαρούμενη χώρα.
          Κράτησαν εδώ συνεχώς ζωντανή τη γλώσσα, τις παραδόσεις, τη λατρεία των
          προγόνων τους. Τομή και απότομη ρήξη υπέστη τώρα η μακρά αυτή
          αλυσιδωτή διαδοχή, που οι Πέρσες, οι Ρωμαίοι, οι Βυζαντινοί και οι
          Λατίνοι, οι Οθωμανοί του 15ου αιώνα είχαν σεβαστεί τη συνέχειά της.
          Στη Φώκαια συγκεκριμένα, τη λαμπρή αυτή πόλη της Ιωνίας, που με τέτοια
          τόλμη μετέφερε στις ακτές της δικής μας Προβηγκίας τα καλά του
          Ελληνισμού, όσοι επιζούσαν μετά τις τόσες ανατροπές και καταστροφές
          κατάφεραν για πάντα να υπερασπίζουν την αρχαία κληρονομιά, ενάντια
          στην πίεση που άσκησαν τόσες και τόσες φορές κατακτητικές και βάρβαρες
          ορδές. Κατάφεραν να μεταδώσουν από γενιά σε γενιά τις παραδόσεις και
          τις μνήμες τους. Καμιά επίθεση από όσες υπέστησαν κατά τη διάρκεια της
          λαμπερής και θυελλώδους ιστορίας τους δεν διέρρηξε τον δεσμό που τους
          συνέδεε με τις απαρχές τους και καμιά δεν είχε, όπως η τωρινή, το
          χαρακτήρα άνανδρης επίθεσης, τον οποίο μόλις προσέδωσε η Νέα Τουρκία
          στον διωγμό τους.» <br />
          <br />
          (Φελίξ Σαρτιώ Ιούνιος 1914).
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΟΚΤΩΒΡΙΟΣ - Η επιστροφή το "1919" και η οριστική εγκατάλειψη το
          "1922".
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Από τα αρχεία του ελληνικού υπουργείου περιθάλψεως της εποχής εκείνης,
          διαπιστώνεται ότι ο αριθμός των Ελλήνων από την περιφέρεια της Φώκαιας
          που έφτασε στην Ελλάδα ανερχόταν στις 18.000 περίπου. O ακριβής
          αριθμός των Φωκιανών που δέχτηκε περίθαλψη στη Μυτιλήνη υπολογίστηκε,
          την 31-12-1918, στις 5.182. Η περίοδος της πρώτης αυτής προσφυγιάς
          διήρκεσε μέχρι το 1919, στιγμή κατά την οποία οι περισσότεροι Φωκιανοί
          επανέκαμψαν στη Φώκαια της Μικράς Ασίας, αμέσως μετά την αποβίβαση του
          Ελληνικού στρατού στη Σμύρνη. Εκτός από 5.000 Φωκιανούς, που
          προτίμησαν να παραμείνουν στην Ελλάδα και δεν επέστρεψαν ποτέ στη
          Φώκαια, οι υπόλοιποι κάτοικοι περί τους 11.000, επανήλθαν έως την 1η
          Ιανουαρίου του “1921”. Πολύ γρήγορα ξεπέρασαν τα προβλήματα,
          αποκαθιστώντας τη συνέχεια της παρουσίας τους στην περιοχή, όχι όμως
          για πολύ αφού λίγο αργότερα το 1922 αναγκάστηκαν να εγκαταλείψουν
          οριστικά τα πατρογονικά τους εδάφη. Η μοίρα των Ελλήνων της Φώκαιας
          πορεύτηκε παράλληλα με τη μοίρα ολόκληρου του Ελληνισμού της Μικράς
          Ασίας, η οποία κρίθηκε οριστικά και δραματικά με την τελευταία φάση
          των πολεμικών επιχειρήσεων τον Αύγουστο του 1922. Οι Φωκιανοί, που
          κατάφεραν τότε να φτάσουν στην Ελλάδα, με κυριότερο ενδιάμεσο σταθμό
          τη Μυτιλήνη, σκορπίστηκαν σε όλη τη χώρα. Η εμπειρία του 1914 και το
          ένστικτο αυτοσυντήρησης τους οδήγησαν σε επιλογές προσωρινής
          εγκατάστασης, που σε αρκετές περιπτώσεις έμελλε να είναι οριστική.
          Όσοι από τους Φωκιανούς έμειναν πιστοί στις πρώτες αυτές επιλογές και
          απέφυγαν μελλοντικές περιπλανήσεις εντάχθηκαν, όπως ήταν φυσικό, σε
          γενικότερους σχεδιασμούς περίθαλψης και αποκατάστασης.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΝΟΕΜΒΡΙΟΣ - Σε ποιες αιτίες να αποδώσεις τα γεγονότα του "1914".
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          «Σε τι απέβλεπε η Τουρκική κυβέρνηση ξεριζώνοντας το Ελληνικό στοιχείο
          που στο παρελθόν ήταν η δόξα της ωραίας αυτής χώρας και στο παρόν
          αποτελεί σχεδόν όλο της τον πλούτο; Γιατί το πρόγραμμα εφαρμόστηκε με
          τόση βιαιότητα, περιφρονώντας τον στοιχειωδέστερο σεβασμό για τις
          ανθρώπινες ζωές και τις ιδιοκτησίες; Ο ουσιαστικός παράγοντας αυτής
          της κίνησης πρέπει να αναζητηθεί στο βαθιά εθνικιστικό πνεύμα που
          διακατέχει την τουρκική κυβέρνηση από την εποχή της επανάστασης του
          1908. Ήδη εξαρχής, το πρόγραμμά της ήταν να κυβερνήσει τουρκιστί, για
          τους Τούρκους μόνο, εις βάρος των υπολοίπων οθωμανικών πληθυσμών της
          αυτοκρατορίας. Ο κ. Βικτόρ Μπεράρ, στα τελευταία κεφάλαια του έργου
          του «ο θάνατος της Ισταμπούλ», παρουσίασε μια από τις μορφές που
          προσέλαβε η πανισλαμική αυτή πολιτική και είναι η συγκέντρωση, μέσω
          της μετανάστευσης, του μουσουλμανικού στοιχείου στις περιοχές όπου δε
          διέθετε την πρωτοκαθεδρία. Οι ακρωτηριασμοί που υπέστη η Τουρκία με το
          βαλκανικό πόλεμο διόγκωσαν απλώς αυτό το πνεύμα και ενίσχυσαν αυτό το
          κομμάτι του προγράμματος. Το εμπόδιο όμως που προβάλουν στην ανάπτυξη
          της τουρκικής φυλής οι εγκατεστημένοι από αιώνες στις ακτές της
          Ανατολίας πληθυσμοί, όπου κατάφεραν να συγκεντρώσουν στα χέρια τους το
          εμπόριο, τη βιομηχανία, τη γεωργία και τις επιχειρήσεις, δεν μπορούσε
          να ξεπεραστεί επιβάλλοντας απλώς στη ζωή του τόπου στοιχεία φερμένα
          από έξω. Όπως το 1895 και το 1909 η Τουρκία προσπάθησε να λύσει το
          αρμενικό ζήτημα εξολοθρεύοντας τους Αρμένιους, το πρόβλημα της
          συγκέντρωσης και της ηγεμονίας της τουρκικής φυλής στις ακτές της
          Μικράς Ασίας θεωρήθηκε ότι μπορούσε να λυθεί μόνο με το ξερίζωμα των
          Ελλήνων».
          <br />
          <br />
          (Φελίξ Σαρτιώ - Ιούνιος 1914).
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          ΔΕΚΕΜΒΡΙΟΣ - Το γλυκό φως της Ιωνίας
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          «Το γλυκό φως της Ιωνίας φωτίζει τώρα μονάχα ερημωμένες εκτάσεις. Η
          μακρόχρονη και υπομονετική προσπάθεια αναγέννησης, που τη διέκοπταν
          αιματηρές φάσεις, βοήθησε για πολλούς αιώνες να ξανανθίσει ο
          Ελληνισμός στις όχθες του Έρμου και του Μαιάνδρου, αλλά τελικά
          ναυάγησε κι αυτή. Δεν υπάρχει χώρα στον κόσμο που η ιστορία της να
          παρουσιάζει τέτοια σκαμπανεβάσματα μεγαλείου και αθλιότητας. Δεν
          υπάρχει λαός που να μπορεί να επικαλείται στη γη όπου ζει τίτλους τόσο
          παλιούς αλλά και μόνιμους. Τους τίτλους αυτούς τους κατέχουν χάρη στη
          γη τους, που είναι γη του Αιγαίου εδώ και εκατομμύρια χρόνια, προτού η
          θάλασσα της Τριτογενούς περιόδου πνίξει την Αιγαιίδα. Τους κατέχουν
          χάρη στη γη αυτή που το σχήμα της, η χάρη, το φως και τα τοπία της
          είναι του Αιγαίου, από τότε που υπάρχουν άνθρωποι πάνω στη γη. Τους
          κατέχουν χάρη στην ιστορία τους, το δημιουργικό τους δαιμόνιο, τις
          επιστήμες τους, τη λογοτεχνία, την ηθική, τη δημοκρατία που έκαναν την
          πατρίδα τους πατρίδα της Δύσης. Τους κατέχουν, επειδή επιμένουν και
          φροντίζουν πάντα, να διατηρήσουν τη μεγάλη αυτή ανθρώπινη κληρονομιά,
          της οποίας υπήρξαν για δέκα αιώνες οι φρουροί και το προτείχισμα στην
          Ανατολή. Τους κατέχουν χάρη στις πρόσφατες προσπάθειές τους, χάρη στη
          μακρά κυοφορία και τη γέννηση της νεότερης Ελλάδας, που ο μέγας Κοραής
          τη συνέδεσε με την κυοφορία και τη γέννηση της Γαλλίας το 1789. Τους
          κατέχουν επειδή αποκατέστησαν το εμπόριο, τη βιομηχανία, τα σχολεία,
          τα γράμματα και τις επιστήμες στις ακτές του Αιγαίου, όπου θέλουν να
          δώσουν στις πόλεις τους τη λάμψη της αρχαίας Εφέσου, της αρχαίας
          Περγάμου και της αρχαίας Μιλήτου. Τους κατέχουν χάρη στα αναρίθμητα
          πλήθη των ακρωτηριασμένων νεκρών τους, χάρη στο αίμα και τα δάκρυά
          τους, που η πηγή τους δεν στερεύει ποτέ και που οι καρποί τους
          φαίνεται να αντλούν καινούρια δύναμη από το κάθε βάσανο και την κάθε
          προσβολή που υφίστανται. Τους κατέχουν, τέλος, χάρη στα αδέρφια τους,
          τους γιους τους που έπεσαν για την αιώνια πατρίδα, που τους είδα
          γυμνούς και ξεπεσμένους, θλιβερά ναυάγια, στις ακτές της Λέσβου και
          της Αττικής, και υπήρξαν το πρώτο και ένα από τα βασικά στηρίγματα των
          συμμάχων στα παιδία της Μακεδονίας. Δεν υπάρχει λαός στη γη που να
          μπορεί να προσκομίσει τόσους τίτλους και τόσα δικαιώματα και να τα
          αποθέσει στα πόδια του κόσμου όλου και της Κοινωνίας των Εθνών.»
          <br />
          <br />
          (Φελίξ Σαρτιώ).{" "}
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Φωτογραφικό Υλικό
        </Typography>

        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image02}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image03}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image04}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image05}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image06}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image07}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image08}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image09}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image10}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image11}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image12}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image13}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image14}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image15}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image16}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image17}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image18}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image19}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image20}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <img
          src={Image21}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default P8_Hmerologia;
