import React from 'react';
import {Typography} from "@mui/material";

function Header1({props}) {
    return (
      <div>
          <Typography variant="h4" color="#ffa500" sx={{textAlign:"center", marginBottom:"0em", fontWeight:'bold'}}>
            {props}
          </Typography>
      </div>
    )
}

export default Header1

// import React from 'react';
// import { Typography, useTheme, responsiveFontSizes } from "@mui/material";

// function Header1({props}) {

//     const theme = useTheme();
  
//     const responsiveTypography = responsiveFontSizes({

//         fontSize: theme.typography.pxToRem(24), 

//         [theme.breakpoints.down('sm')]: {
//             fontSize: theme.typography.pxToRem(20),
//         },

//     });

//     return (
//       <div>
//           <Typography variant="h4" color="#ffa500" sx={{ ...responsiveTypography, textAlign:"center", marginBottom:"0em", fontWeight:'bold'}}>
//             {props}
//           </Typography>
//       </div>
//     )
// }

// export default Header1;
