import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Hellas_lesvos from '../assets/images/images002_PouVriskomaste/hellas_lesvos.jpg'
import Lesvos2 from '../assets/images/images002_PouVriskomaste/lesvos2.jpg'


function D3_pou_vriskomaste() {

    const theme = useTheme();

    return (
        <>
            <IconButton component={Link} to="/">
                <HomeIcon />
            </IconButton>
            <Box sx={{ ...headerBoxStyles }}>
                <Header1 props="Που βρισκόμαστε" />
            </Box>

            <Box sx={{ ...commonBoxStyles }}>

                <Typography variant="h6" sx={{

                    borderRadius: 8,
                    marginLeft: '8px',
                    flex: 1,
                    textAlign: 'center',
                    color: 'blue',
                    marginTop: '0em'
                }}>
                    Η Λέσβος βρίσκεται στο Βορειοανατολικό Αιγαίο.<br />
                    Η Σκάλα Λουτρών είναι στον κόλπο της Γέρας και απέχει περίπου 15 λεπτά από την Μυτιλήνη.
                </Typography>

                <Grid container spacing={2} style={{ justifyContent: 'center', marginTop: '0.5em' }}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <img src={Hellas_lesvos} alt="Agios Ermogenis" style={{ maxWidth: '100%', height: 'auto', borderRadius: 8, boxShadow: '0 8px 4px rgba(0, 0, 0, 0.1)' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <img src={Lesvos2} alt="Agios Ermogenis" style={{ maxWidth: '100%', height: 'auto', borderRadius: 8, boxShadow: '0 8px 4px rgba(0, 0, 0, 0.1)' }} />
                    </Grid>
                </Grid>

            </Box>

            <ScrollToTopButton />

        </>
    )
}

export default D3_pou_vriskomaste