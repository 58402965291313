import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/23_image1.jpg";
import Image02 from "../assets/images/oikologika/23_image2.jpg";

function F9_oikologika_09() {
  const theme = useTheme();

  return (
    <>
      
      
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="23o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>



      
      <Box sx={{ ...commonBoxStyles }}> 
      <Typography variant="h1" sx={theme.typography.typography1}>Συνδιοργάνωση: «Κεντρική Ένωση Δήμων  Ελλάδας»</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>Κουκουναριές Σκιάθου, 20-22 Σεπτεμβρίου 2013</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>ΘΕΜΑ: «ΟΤΑ – ΑΠΟΡΡΙΜΜΑΤΑ – ΑΝΑΚΥΚΛΩΣΗ»</Typography>
		
		<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
				
		<Typography variant="h3" sx={theme.typography.typography2}>Το Πανελλήνιο Δίκτυο Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ), σε συνδιοργάνωση με την Κεντρική Ένωση Δήμων Ελλάδος (ΚΕΔΕ), πραγματοποίησε το 23ο Πανελλήνιο Συνέδριό του στις Κουκουναριές της Σκιάθου από 20-22 Σεπτεμβρίου 2013 με θέμα:  «ΟΤΑ-ΑΠΟΡΡΙΜΜΑΤΑ - ΑΝΑΚΥΚΛΩΣΗ»
Σκοπός του συνεδρίου ήταν η γόνιμη ανταλλαγή απόψεων και προτάσεων συνεργασίας μεταξύ των Συστημάτων Εναλλακτικής Διαχείρισης των ΟΤΑ και των οικολογικών οργανώσεων, για την οργάνωση και διαχείριση των αστικών και μη αποβλήτων. Το Συνέδριο απευθυνόταν κυρίως σε Δημάρχους, Δημοτικούς Συμβούλους και στελέχη της τοπικής αυτοδιοίκησης που ασχολούνται με το περιβάλλον, Συμβούλους Ανάπτυξης των ΠΕΔ, στελέχη των ΦΟΔΣΑ, και φορείς που σχετίζονται με το περιβάλλον, καθώς και πολίτες. </Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Τα συστήματα εναλλακτικής Διαχείρισης, ως η μοναδική και πλέον ανεπτυγμένη μορφή διαχείρισης αποβλήτων, παράλληλα με την διαχείριση των αποβλήτων από τους ΟΤΑ, αποτελούν το συμπληρωματικό μέρος, της προσπάθειας που καταβάλλουν οι ΟΤΑ και απαιτεί η κοινωνία, για την προστασία του περιβάλλοντος και της δημόσια υγείας.</Typography>
<br/><br/>
<Typography variant="h1" sx={theme.typography.typography1}> Περιληπτική εισήγηση του κ. Γιώργου Κοτρωνιά, προέδρου της Επιτροπής Αειφόρου Ανάπτυξης & Περιβάλλοντος ΚΕΔΕ</Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Αγαπητοί σύνεδροι, <br/><br/>

Το θέμα της διαχείρισης των στερεών αποβλήτων και η ανακύκλωση αποτελούν ίσως το κρισιμότερο ζήτημα περιβαλλοντικής ευθύνης για το κάθε πολίτη, για τις οργανώσεις της κοινωνίας των πολιτών, για μας ως αυτοδιοίκηση στα πλαίσια της θεσμικής μας ευθύνης και λειτουργίας και κυρίως του ίδιου του κράτους που διαμορφώνει το θεσμικό χρηματοδοτικό και οργανωτικό πλαίσιο διαχείρισης. </Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Οι Δήμοι της χώρας και οι φορείς τους έχουν την ευθύνη για (2) κύριες και βασικές λειτουργίες στο όλο σύστημα  της διαχείρισης των στερεών αποβλήτων. 
Η πρώτη βασική λειτουργία αφορά την οργάνωση της διαχείρισης των αστικών στερεών αποβλήτων στο επίπεδο της χωρικής ενότητας κάθε Δήμου. 
Στο επίπεδο της κατοικίας, στο επίπεδο των επιχειρηματικών δραστηριοτήτων σε κάθε άλλη δραστηριότητα στη πόλη και στο χωριό, στο κέντρο και στη γειτονιά. <br/>
Στο επίπεδο αυτό οι Δήμοι της χώρας οργανώνουν τη διαχείριση, συνεργάζονται με τα συστήματα ανακύκλωσης συμβάλλουν με την ενημέρωση και την ευαισθητοποίηση των πολιτών στο να έχουμε καθαρές πόλεις και χωριά. <br/>
Μέσα σ’ ένα εξαιρετικά δυσμενές οικονομικό περιβάλλον είναι διαρκής η προσπάθεια των Δήμων με πενιχρά μέσα πολλές φορές να μεγιστοποιήσουν το αποτέλεσμα χωρίς να επιβαρύνουν, μετακυλήσουν το κόστος αυτό στους Δημότες και τις οικονομικές δραστηριότητες της περιοχής. <br/>
Η δεύτερη βασική λειτουργία αφορά την οργάνωση της τελικής διαχείρισης των αστικών στερεών αποβλήτων μέσω των ΦΟΔΣΑ με τη δημιουργία εγκαταστάσεων συγκέντρωσης (ΣΜΑ) επεξεργασίας (ΚΔΑΥ) και τελικής διάθεσης σε ΧΥΤΥ. Με βάση το άρθρο 104 του Ν. 3852/2010 του νόμου δηλαδή του Καλλικράτη προβλέπεται η συγχώνευση όλων των ΦΟΔΣΑ σ’ ένα ενιαίο φορέα ανά Περιφέρεια. <br/>
Η διαδικασία αυτή είναι σε εξέλιξη και με βάση τη παράταση που έχει δοθεί θα πρέπει να έχει ολοκληρωθεί στις 31/12/2013.</Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Σταθερή θέση της ΚΕΔΕ αποτελεί η εφαρμογή του άρθρου αυτού και των κανονιστικών διατάξεων που το εξειδικεύουν ώστε να έχουμε ισχυρούς και βιώσιμους φορείς διαχείρισης σε κάθε περιφέρεια.<br/>
Κρίσιμο ζήτημα για την αυτοδιοίκηση και τους φορείς διαχείρισης αποτελεί η αναμενόμενη εφαρμογή της οδηγίας 2008/98 και 2008/99 που κυρώθηκαν με το νόμο 4042/12 για τη ποινική προστασία του περιβάλλοντος. <br/>
Με το νόμο αυτό προβλέπεται η επιβολή τέλους ταφής από 1/1/2014 κάτι το οποίο αν εφαρμοστεί σύμφωνα με τις προβλέψεις θα δημιουργήσει ένα εκρηκτικό πρόβλημα για τους φορείς διαχείρισης, για τους Δήμους και για τους δημότες καθώς θα τους επιβαρύνει με ένα δυσβάσταχτο κόστος σε μία εξαιρετικά δύσκολη οικονομική συγκυρία. </Typography>


<Typography variant="h1" sx={theme.typography.typography1}>ΑΠΟΦΑΣΗ 23oυ ΠΑΝΕΛΛΗΝΙΟΥ ΣΥΝΕΔΡΙΟΥ ΠΑΝΔΟΙΚΟ</Typography>


<img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
				
<Typography variant="h3" sx={theme.typography.typography2}>Στις  20-22 Σεπτεμβρίου 2013, στη Σκιάθο, πραγματοποιήθηκε το 23ο Πανελλήνιο Συνέδριο του ΠΑΝΔΟΙΚΟ, σε συνδιοργάνωση με την ΚΕΔΕ, με θέμα «ΟΤΑ – ΑΠΟΡΡΙΜΜΑΤΑ – ΑΝΑΚΥΚΛΩΣΗ» και την παρουσία του συνόλου των Συστημάτων Εναλλακτικής Διαχείρισης.<br/>
Στο Συνέδριο αναδείχθηκαν τα θέματα της διαχείρισης και ανακύκλωσης των  αστικών και βιομηχανικών  στερεών αποβλήτων, τα οποία αποτελούν ένα από τα  κρισιμότερα ζητήματα περιβαλλοντικής ευθύνης για κάθε πολίτη, για τις οικολογικές οργανώσεις  της κοινωνίας των πολιτών, την τοπική αυτοδιοίκηση, τα Συστήματα Εναλλακτικής Διαχείρισης και βεβαίως την Πολιτεία.<br/>
Η προστασία του περιβάλλοντος και της δημόσιας υγείας, αποτελεί  μέγιστη ευθύνη  των παραπάνω φορέων και κυρίως των ελεγκτικών μηχανισμών της πολιτείας, στην επιβολή ποινών αποτρεπτικών για  τους  ρυπαίνοντες  και εφαρμογή της αρχής ο  ρυπαίνων πληρώνει.
Φυσικά τον πρώτο ρόλο έχει η ευαισθητοποίηση των πολιτών, στην οποία σημαντικά μπορούν να συμβάλουν οι οικολογικές οργανώσεις, με αντίστοιχους πόρους από την Πολιτεία, η περιβαλλοντική εκπαίδευση, οι ΟΤΑ, τα ΜΜΕ και φορείς της Πολιτείας.<br/>
Η διαχείριση των στερεών αποβλήτων, μέσω της διαδικασίας της ανακύκλωσης, προσφέρει εξοικονόμηση πόρων, ενέργειας και κυρίως διασφαλίζει την αειφόρο ανάπτυξη, την υγεία και την  προστασία του περιβάλλοντος.</Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Είναι όμως σημαντικό να περιοριστεί η κατανάλωση, επομένως και ο όγκος των απορριμμάτων και να εξασφαλιστεί η προώθηση της διαλογής στην πηγή. Επίσης να εξασφαλιστεί η ασφαλής διάθεση των υπολειμμάτων, κυρίως των επικίνδυνων, με αυστηρό έλεγχο από την Πολιτεία.<br/>
Το συνέδριο εξέτασε το σύνολο των παραμέτρων που αφορούν τη λειτουργία όλων των συντελεστών της εναλλακτικής διαχείρισης προτείνοντας  την επέκταση της εναλλακτικής διαχείρισης στη νησιωτική χώρα, τη διαχείριση και άλλων αποβλήτων,  π.χ. νοσοκομειακά, την μετατροπή των ΧΥΤΑ σε ΧΥΤΥ,  με παράλληλη διαλογή στην πηγή των αστικών αποβλήτων, τη δημιουργία συστημάτων για τις συσκευασίες φυτοφαρμάκων και λιπασμάτων, τη δημιουργία συστήματος διαχείρισης υπολειμμάτων  επισκευής και συντήρησης οχημάτων και εξοπλισμού.<br/>
Ο σχεδιασμός και η επιτυχία προγραμμάτων, όπως η Διαλογή Στην Πηγή (ΔΣΠ) προϋποθέτουν κανόνες, κίνητρα, στέρεους στόχους και αλλαγή του ισχύοντος νομικού πλαισίου, αναφορικά με τα κριτήρια χρέωσης και επιβάρυνσης των καταναλωτών  για τα παραγόμενα  απορρίμματα. Απαιτείται η εκπόνηση ειδικού επιχειρησιακού προγράμματος ανακύκλωσης  (ΔΣΠ) και η χρηματοδότησή του από την Πολιτεία.<br/>
Στο Συνέδριο αναδείχτηκε η σταθερή απόφαση των συμμετασχόντων για την υποστήριξη των  συστημάτων εναλλακτικής διαχείρισης και την άρση των  εμποδίων και των στρεβλώσεων στη λειτουργία τους.<br/>
Ο οικονομικός απολογισμός σε όλα τα επίπεδα διαχείρισης του περιβάλλοντος  και η καθιέρωση και επίτευξη  στόχων είναι αναγκαία για την αξιολόγηση  των αποτελεσμάτων δράσεων.</Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Η διασφάλιση ανοιχτού και ουσιαστικού  διαλόγου μεταξύ της ΚΕΔΕ, των οικολογικών οργανώσεων, των Συστημάτων, των υπόχρεων παραγωγών, των Φορέων Διαχείρισης Στερεών αποβλήτων (ΦοΔΣΑ) και της πολιτείας θα λειτουργήσει αποτελεσματικά και όχι από καθέδρας, στην επίλυση προβλημάτων και προώθηση της εναλλακτικής διαχείρισης.<br/>
Η επιλογή της πολιτείας, για χωρίς προϋποθέσεις  δημιουργία «ανταγωνιστικών συστημάτων», στον ευαίσθητο χώρο της προστασίας του περιβάλλοντος και κυρίως η απουσία σοβαρών ελέγχων και αποτρεπτικών ποινών, αποτελεί παράμετρο  που  μόνο αρνητικές επιπτώσεις θα έχει στην προστασία και τη  διαχείριση του περιβάλλοντος.<br/>
Η λειτουργία ευέλικτων και αποτελεσματικών   ΦοΔΣΑ  για τη διαχείριση των στερεών αστικών αποβλήτων με τη δημιουργία εγκαταστάσεων μεταφόρτωσης απορριμμάτων ΣΜΑ και επεξεργασίας ΚΔΑΥ, η τελική διάθεση των υπολειμμάτων σε ΧΥΤΥ, η ορθή διαχείριση και ασφαλής διάθεση των επικινδύνων υπολειμμάτων, αποτελούν μια βεβαιότητα για την εξάλειψη του φαινομένου των χωματερών,  που συνεχίζουν  μέχρι σήμερα να προσβάλλουν την υγεία μας και το περιβάλλον.
Η χρηματοδότηση τοπικών  φορέων από την πολιτεία για ανάληψη πρωτοβουλιών για τη διαχείριση των απορριμμάτων,  είναι αναγκαία για την στήριξη και  την ανάπτυξη τους.<br/>
Η κοστολόγηση των υπηρεσιών, ο έλεγχος των αποτελεσμάτων και ο εξαντλητικός επιστημονικός και κοινωνικός διάλογος, μπορούν  να προσφέρουν τις απαντήσεις  στην διαχείριση των αστικών, βιομηχανικών κλπ. αποβλήτων.<br/>
Όλα αυτά προϋποθέτουν την αναθεώρηση και  την σύνταξη του νέου εθνικού σχεδιασμού διαχείρισης στερεών αποβλήτων  και των ΠΕΣΔΑ.<br/></Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Τέλος, διαπιστώθηκε η ανάγκη συνεργασίας του ΠΑΝΔΟΙΚΟ, των Συστημάτων και της ΚΕΔΕ, με πρωτοβουλία της τελευταίας, για την εξέταση  προτάσεων και λύσεων προς την πολιτεία, που θα συμβάλλουν στη  βελτίωση και προώθηση της ανακύκλωσης  στο σύνολο της χώρας.<br/><br/>

<b>Ο Πρόεδρος της Οργανωτικής Επιτροπής Γ, Κοτρωνιάς.</b></Typography>

    </Box>

    <ScrollToTopButton />



    </>
  );
}

export default F9_oikologika_09;
