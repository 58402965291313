import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/Hmerologia_p6/oikoumeniko1.jpg";
import Image02 from "../assets/images/Hmerologia_p6/oikoumeniko2.jpg";
import Image03 from "../assets/images/Hmerologia_p6/oikoumeniko3.jpg";
import Image04 from "../assets/images/Hmerologia_p6/oikoumeniko4.jpg";
import Image05 from "../assets/images/Hmerologia_p6/oikoumeniko5.jpg";

import Image06 from "../assets/images/Hmerologia_p6/photo1.jpg";
import Image07 from "../assets/images/Hmerologia_p6/photo2.jpg";
import Image08 from "../assets/images/Hmerologia_p6/photo3.jpg";
import Image09 from "../assets/images/Hmerologia_p6/photo4.jpg";
import Image10 from "../assets/images/Hmerologia_p6/photo5.jpg";
import Image11 from "../assets/images/Hmerologia_p6/photo6.jpg";
import Image12 from "../assets/images/Hmerologia_p6/photo7.jpg";
import Image13 from "../assets/images/Hmerologia_p6/photo8.jpg";
import Image14 from "../assets/images/Hmerologia_p6/photo9.jpg";
import Image15 from "../assets/images/Hmerologia_p6/photo10.jpg";
import Image16 from "../assets/images/Hmerologia_p6/photo11.jpg";
import Image17 from "../assets/images/Hmerologia_p6/photo12.jpg";
import Image18 from "../assets/images/Hmerologia_p6/photo13.jpg";





function P6_Hmerologia() {

  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        <HomeIcon />
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Ημερολόγιο 2009 : Οικουμενικό Πατριαρχείο" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Όπως γνωρίζουμε οι Απόστολοι εστάλησαν από το Χριστό για να κηρύξουν
          το Ευαγγέλιο της Σωτηρίας σε όλα τα έθνη. Κατά θαυμαστό όντως τρόπο
          πέτυχαν, παρά τους διωγμούς και τις δυσμενείς συνθήκες που
          αντιμετώπισαν, με τη δύναμη του Αγίου Πνεύματος, να εξαπλώσουν το
          Χριστιανισμό, εντός ελαχίστου χρονικού διαστήματος, στα πέρατα της
          οικουμένης.{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Χριστιανισμός διαδόθηκε στην αρχή σε περιοχές, όπου κυριαρχούσε ο
          ελληνικός πολιτισμός και ομιλείτο η ελληνική γλώσσα και έτσι οι
          Απόστολοι έδρασαν για τη διάδοση του Χριστιανισμού σε ελληνικές ή
          ελληνόφωνες περιοχές. Ο ελληνικός κόσμος των παραλίων της Μεσογείου
          και του Εύξεινου Πόντου απετέλεσε το έδαφος της ιεραποστολικής δράσεως
          πολλών αποστόλων. Δια τούτο η οργάνωση της πρώτης Χριστιανικής
          Εκκλησίας στηρίχθηκε στις μεγάλες ελληνικές πόλεις, όπου υπήρχαν
          ακμαίες χριστιανικές κοινότητες.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Το ιεραποστολικό χρέος του Αποστόλου Παύλου για τη διάδοση του
          Χριστιανισμού σε ΄Έλληνες και βαρβάρους (Ρωμ. 1,14) ξεπληρώθηκε από
          τον ίδιο κατά το πρώτο σκέλος, δια της διαδόσεως του Χριστιανισμού
          στον τότε Ελληνικό κόσμο. Την ιεραποστολική οφειλή του Αποστόλου, κατά
          το δεύτερο σκέλος, ξεπλήρωσε παραδειγματικά η Εκκλησία της
          Κωνσταντινουπόλεως, προικισμένη δι όλων των ευνοϊκών προϋποθέσεων, τις
          οποίες δημιούργησε η μεταφορά της πρωτεύουσας εκ της Παλαιάς Ρώμης,
          στη Νέα Ρώμη, την Κωνσταντινούπολη.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Οι ιστορικοί έχουν αποδεχθεί ανεπιφύλακτα την διαπίστωση, κατά την
          οποία στο τέλος του 2ου μ.Χ. αιώνος, ενώ εις το δυτικό τμήμα του
          ρωμαϊκού κράτους, το οποίο συμπίπτει με τη σημερινή Ευρώπη, ο
          Χριστιανισμός σποραδικά μόνο είχε διαδοθεί σε μεμονωμένες κοινότητες,
          στην Ανατολή αντιθέτως είχε εμπεδωθεί οριστικά σε πυκνό δίκτυο
          κοινοτήτων, ιδιαιτέρως στις επαρχίες της Μικράς Ασίας και του Πόντου,
          οι οποίες μετά της Θράκης θα αποτελέσουν από τον τέταρτο αιώνα το
          έδαφος της εκκλησιαστικής δικαιοδοσίας της Εκκλησίας
          Κωνσταντινουπόλεως. Η διαμόρφωση της υψηλής εκκλησιαστικής θέσεως της
          Κωνσταντινουπόλεως ως πνευματικού κέντρου της χριστιανικής οικουμένης
          συντελέσθηκε πλέον ταχύτατα. Η προνομιακή θέση του Οικουμενικού
          Πατριαρχείου, στηριζόμενη σε ρητές κανονικές διατάξεις (Οικουμενικών
          Συνόδων κτλ), κατέληξε στην ιστορία της Ορθοδόξου Εκκλησίας, πέραν της
          νομικής θεμελιώσεως και σε φυσικό αυτής γνώρισμα.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο ορθόδοξος πολιτισμός δεν είναι δυνατό να κατανοηθεί, αν αφαιρέσει
          κανείς την Κωνσταντινούπολη, η οποία απέβη το μέγα Κέντρο της
          Ορθοδοξίας καθ΄ όλη τη δυναμική ιστορική της πορεία. Κατά την περίοδο
          αυτή της ισχύος και της ακμής, το Οικουμενικό Πατριαρχείο πρωτοστάτησε
          στη διατύπωση και διαμόρφωση των δογμάτων, στη σύγκληση των
          Οικουμενικών Συνόδων, στην ανάπτυξη του Μοναχισμού, στον εμποτισμό του
          συνόλου της ζωής στην αυτοκρατορία υπό του χριστιανικού πνεύματος, σε
          αρμονική συνεργασία μετά της πολιτικής εξουσίας, ώστε πράγματι το
          Βυζάντιο να αποτελεί μοναδικό στην παγκόσμια ιστορία παράδειγμα
          γνήσιας βιώσεως του Ευαγγελίου του Χριστού. Μετά την απώλεια της
          εξωτερικής αίγλης και δυνάμεως, λόγω συγκυριακών επιδράσεων, το
          Οικουμενικό Πατριαρχείο ανέλαβε ως στοργική μήτηρ το έργο της
          προστασίας και περιθάλψεως των υπόδουλων ορθοδόξων λαών και με πολλή
          σύνεση και σοφία κινήθηκε μέσα στις νέες συνθήκες των καιρών, για να
          συντηρήσει την ορθόδοξο πίστη και την αυτοσυνειδησία των Ορθοδόξων.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Από τις αρχές του παρόντος αιώνος το Οικουμενικό Πατριαρχείο
          παρακολουθώντας με οδύνη την έλλειψη ενότητας μεταξύ των Ορθοδόξων
          εκκλησιών κατέβαλε μεγάλη προσπάθεια, συσφίξεως των σχέσεων και
          σφυρηλατήσεως της μεταξύ αυτών ενότητας, η οποία με τη χάρη του Θεού
          εστέφθη υπό πλήρους επιτυχίας. Σήμερα ενωμένη η Ορθόδοξος Εκκλησία
          παρέχει τη μαρτυρία της ορθοδόξου πίστεως, διαλεγομένη μετά των
          ετεροδόξων εν αγάπη και αληθεία. Εις ουδεμία άλλη εποχή διεξήχθησαν
          τόσοι θεολογικοί διάλογοι, όσοι διεξάγονται σήμερα. Το αποτέλεσμα
          αυτών των διαλόγων και γενικότερα της συμμετοχής των Ορθοδόξων στη
          λεγομένη Οικουμενική Κίνηση, εφόσον αυτή η συμμετοχή στηρίζεται στις
          αρχές της ορθοδόξου πίστεως και ζωής, επαφίεται στη χάρη του Θεού,{" "}
          <strong>«του αληθεύοντος εν αγάπη και αγαπώντος εν αληθεία».</strong>
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Περιβάλλον και Ορθοδοξία
        </Typography>
        <img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο βιασμός της φύσης από τον άνθρωπο είναι η αιτία για να εκδηλωθούν οι
          πρώτες ανησυχίες του Οικουμενικού Πατριαρχείου για το Περιβάλλον στην
          3η Πανορθόδοξη Διάσκεψη στη Γενεύη το 1986 όπου για πρώτη φορά
          εκφράστηκαν ενδοιασμοί για τις κλιματικές αλλαγές. Ακολουθούν τα
          Διορθόδοξα Οικολογικά Συνέδρια στη Σόφια το 1987, στην Πάτμο το 1988,
          ενώ προάγγελος του πρωταγωνιστικού ρόλου του Οικουμενικού Πατριαρχείου
          στο ζήτημα αυτό είναι η Χριστουγεννιάτικη εγκύκλιος του Πατριάρχη
          Δημητρίου του 1988. Τον Σεπτέμβριο του 1989 ο αείμνηστος Πατριάρχης
          Δημήτριος εκδίδει ένα εγκύκλιο μήνυμα σε όλο τον κόσμο και με αυτό
          κατόπιν αποφάσεως της Ιεράς Συνόδου, καθιερώνεται η 1η Σεπτεμβρίου ως
          ημέρα προσευχής και ικεσίας της προστασίας του φυσικού περιβάλλοντος.
          Στο ιστορικό αυτό κείμενο το Πατριαρχείο διαπιστώνει με μεγάλη αγωνία
          ότι στις μέρες μας συντελείται από τον άνθρωπο μια ανηλεής καταστροφή
          του φυσικού περιβάλλοντος με πολύ επικίνδυνες συνέπειες για την ίδια
          την επιβίωση του φυσικού κόσμου που ο Θεός έπλασε.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Τον Μάρτιο του 1992 παίρνει τη σκυτάλη ο νεοεκλεγείς τότε Πατριάρχης
          κ.κ. Βαρθολομαίος και καλεί στην Κωνσταντινούπολη όλους τους αρχηγούς
          των ορθόδοξων εκκλησιών. Στο μήνυμα που απευθύνουν οι προκαθήμενοι
          επικροτούν την πρωτοβουλία να καθιερωθεί η 1η Σεπτεμβρίου ως ημέρα
          περιβάλλοντος με πανορθόδοξο πλέον κύρος και έτσι το Πατριαρχείο
          απευθύνει σχεδόν κάθε χρόνο εγκύκλιο μήνυμα με περιεχόμενο
          περιβαλλοντικού ενδιαφέροντος. Πέραν των μηνυμάτων η oοικολογική
          δραστηριότητα του Πατριαρχείου επεκτείνεται και σε άλλες πρωτοβουλίες.
          Το 1994 εγκαινιάζεται σειρά ετήσιων Οικολογικών θερινών σεμιναρίων στη
          Θεολογική Σχολή της Χάλκης υπό την αιγίδα του Πατριάρχου κ.κ.
          Βαρθολομαίου. Επίσης πραγματοποιούνται έκτακτες πρωτοβουλίες όπως π.χ.
          τα εν πλω Οικολογικά συνέδρια το 1995 στην Πάτμο με θέμα «Αποκάλυψη
          και Περιβάλλον», το 1997 στα λιμάνια του Εύξεινου Πόντου με θέμα την
          «προστασία της μαύρης θάλασσας» κ.α. Σε ομιλία του το 1997 ο
          Οικουμενικός Πατριάρχης κ.κ. Βαρθολομαίος στην ολομέλεια του
          Ευρωπαϊκού Κοινοβουλίου υπογραμμίζει ότι «το Οικολογικό πρόβλημα της
          εποχής μας απαιτεί ριζική αναθεώρηση της κοσμολογίας μας» και θέτει το
          Οικουμενικό Πατριαρχείο στη διάθεση της Ευρωβουλής για οποιαδήποτε
          συνεργασία και συμβολή στο θέμα.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Είναι αλήθεια ότι ο Οικουμενικός μας Πατριάρχης κ.κ. Βαρθολομαίος έχει
          ταξιδέψει μέχρι σήμερα στα πέρατα της Οικουμένης για να δώσει το παρόν
          στις Οικολογικές ανησυχίες των απανταχού της γης ευαισθητοποιημένων
          στο θέμα αυτό κοινωνικών ομάδων και οργανώσεων, υποστηρίζοντας μάλιστα
          ότι η Εκκλησία Κωνσταντινουπόλεως αναλαμβάνοντας αυτές τις
          πρωτοβουλίες κινείται εξ ολοκλήρου και αξιωματικά εντός του κυρίου
          έργου της.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Βαρθολομαίος 1991…
        </Typography>
        <img src={Image03}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Η Α.Θ.Π. ο Οικουμενικός Πατριάρχης κ.κ. Βαρθολομαίος, γεννήθηκε στο
          χωριό Άγιοι Θεόδωροι της Ίμβρου στις 29 Φεβρουαρίου 1940 από τον
          Χρήστο και τη Μερόπη Αρχοντώνη και το κατά κόσμον όνομά του ήταν
          Δημήτριος. Μετά τα πρώτα μαθήματα στη γενέτειρα του και στο Ζωγράφειο
          Λύκειο της Πόλεως, εισήλθε στην περιώνυμη Θεολογική Σχολή της Χάλκης,
          από την οποία απεφοίτησε αριστούχος το 1961 και αμέσως χειροτονήθηκε
          διάκονος, μετονομασθείς σε Βαρθολομαίο.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Αμέσως μετά την ενθρόνιση του στο Οικουμενικό Πατριαρχείο και αφού εν
          τω μεταξύ είχε ανεβεί όλα τα σκαλιά της εκκλησιαστικής Ιεραρχίας
          άρχισε σεμνά να πραγματοποιεί το σπουδαίο έργο του. Αντιπροσώπευσε το
          Οικουμενικό Πατριαρχείο σε πλείστα Συνέδρια Διορθόδοξα και
          Διαχριστιανικά, σε επίσημες αποστολές προς την τουρκική κυβέρνηση,
          προς ορθοδόξους και μη Εκκλησίες, προς επαρχίες του Θρόνου και προς το
          Άγιο Όρος. Το 1990 προήδρευσε στη Γενεύη της Διορθοδόξου
          Προπαρασκευαστικής της Αγίας και Μεγάλης Συνόδου Επιτροπής, η οποία
          εξέτασε το θέμα της Ορθοδόξου Διασποράς. Ομιλεί πλην της ελληνικής
          γλώσσας, την τουρκική, τη λατινική, την ιταλική, την αγγλική, τη
          γαλλική και τη γερμανική. Δημοσίευσε αρκετά άρθρα, μελέτες και λόγους.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Είναι επίτιμος Διδάκτωρ της Θεολογικής Σχολής του Πανεπιστημίου
          Αθηνών, τής Θεολογικής Ακαδημίας Μόσχας, της Φιλοσοφικής Σχολής του
          Πανεπιστημίου Κρήτης, του City University του Λονδίνου, του τμήματος
          Περιβάλλοντος του Πανεπιστημίου Αιγαίου, της Σχολής Κανονικού Δικαίου
          του Πανεπιστημίου Aix-en-Provence (Γαλλία), του Πανεπιστημίου του
          Εδιμβούργου, της Θεολογικής Σχολής Τιμίου Σταυρού Βοστώνης, πέντε
          τμημάτων του Πανεπιστημίου Θεσσαλονίκης, των εν Αμερική Πανεπιστημίων
          Georgetown, Tuft, Southern Methodist, του Δημοκρίτειου Πανεπιστημίου
          Ξάνθης, του Πανεπιστημίου Yale, κ.α.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Δημήτριος 1972-1991
        </Typography>
        <img src={Image04}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Γεννήθηκε τo1914 στην Κωνσταντινούπολη και γονείς του ήταν ο
          Παναγιώτης και η Ειρήνη Παπαδοπούλου. Το Ι931 εισήχθη στη Θεολογική
          Σχολή της Χάλκης και απεφοίτησε το 1937. Το ίδιο έτος ο Επίσκοπος
          Ναζιανζού Φιλόθεος τον χειροτόνησε διάκονο και έτσι αρχίζει η λαμπρή
          εκκλησιαστική του διαδρομή. Ο Δημήτριος εργάσθηκε με σύνεση, ταπείνωση
          και αγάπη προς όλους, για τη συνέχιση της υπερχιλιετούς ιστορίας και
          παραδόσεως της Αγίας του Χριστού Μεγάλης Εκκλησίας. Έδωσε ιδιαίτερη
          σημασία στην ορθόδοξη ενότητα και συνεργασία και δέχθηκε τις
          επισκέψεις των Προκαθημένων όλων των κατά τόπους ορθοδόξων
          Πατριαρχείων και Αυτοκέφαλων και Αυτόνομων Εκκλησιών με τους οποίους
          συνομίλησε για τα θέματα που αφορούν το σύνολο της Ορθοδοξίας. Για την
          προώθηση του έργου της προετοιμασίας της Αγίας και Μεγάλης Συνόδου της
          Ορθοδόξου Εκκλησίας, συγκάλεσε τις Α΄, Β΄ και Γ΄ Προσυνοδικές
          Πανορθόδοξες Διασκέψεις στο Ορθόδοξο Κέντρο του Οικουμενικού
          Πατριαρχείου στο Chambesy της Γενεύης. Ακόμη δέχθηκε πολλές
          αντιπροσωπείες ορθοδόξων και μη ορθοδόξων Εκκλησιών, οι οποίες του
          εξέθεσαν τα ειδικά θέματα που απασχολούν τις Εκκλησίες τους, για τα
          οποία και πήρε τις κατάλληλες αποφάσεις και συμπαραστάθηκε στις
          ανάγκες των Εκκλησιών.{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Πατριάρχης Δημήτριος συνέχισε σε όλα την παράδοση των προκατόχων
          του, πέτυχε τη σύμπνοια της Ορθοδοξίας και την κοινή αντιμετώπιση κάθε
          θέματος που την αφορά, συντέλεσε στην προώθηση της μεγάλης υπόθεσης
          της χριστιανικής ενότητας και καταλλαγής με θεολογικούς διαλόγους
          ανάμεσα στην Ορθοδοξία και άλλες χριστιανικές Εκκλησίες και Ομολογίες,
          και συνεχώς διακόνησε εκτός από το άμεσο ποίμνιο του, ολόκληρη την
          Ορθοδοξία ως πρώτος στην τιμή και τη διακονία επίσκοπός της. Κοιμήθηκε
          στις 2 Οκτωβρίου του 1991.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Αθηναγόρας 1948-1972
        </Typography>
        <img src={Image05}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο πατριάρχης Αθηναγόρας, κατά κόσμον Αριστοκλής Σπύρου, γεννήθηκε στις
          25 Μαρτίου 1886 στο χωριό Βασιλικό του Πωγωνίου Ηπείρου. Αφού φοίτησε
          στα σχολεία της Κόνιτσας και των Ιωαννίνων εισήλθε το 1903 στη
          Θεολογική Σχολή της Χάλκης. Η Ιερά σύνοδος της Εκκλησίας της Ελλάδος
          σε ηλικία 36 ετών, τον εξέλεξε ως μητροπολίτη Κερκύρας και Παξών, όπου
          και επέδειξε ιδιαίτερο ενδιαφέρον για το ποίμνιό του και για τους εκ
          Μικράς Ασίας ελθόντες πρόσφυγες. Η σύνοδος του Οικουμενικού
          Πατριαρχείου τον εξέλεξε στις 12 Αυγούστου 1930 ως αρχιεπίσκοπο
          Βορείου και Νοτίου Αμερικής. Η ενθρόνιση του ως Οικουμενικού Πατριάρχη
          έγινε στις 27 Ιανουαρίου 1949. Υπήρξε ο πρώτος Πατριάρχης, που
          επισκέφθηκε την Άγκυρα, και τους πολιτικούς ηγέτες της Τουρκίας (1949
          και 1952) και δέχθηκε την επίσκεψη στην έδρα του Πατριαρχείου του
          πρωθυπουργού Αδνάν Μενδερές (1952). Παρακολούθησε τα τραγικά γεγονότα
          της 6ης – 7ης Σεπτεμβρίου 1955, στη συνέχεια τη μείωση του ορθοδόξου
          ποιμνίου της Κωνσταντινούπολης και την απαγόρευση της λειτουργίας της
          Θεολογικής Σχολής της Χάλκης.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Ιστορική θα παραμείνει η εις τους Αγίους Τόπους συνάντησή του με τον
          πάπα Παύλο τον Στ΄ το 1964. Ο Αθηναγόρας ήταν σπουδαίος χριστιανός
          ηγέτης, ειρηνοποιός, οραματιστής, αλλά και άνθρωπος των πράξεων και
          των έργων. Κατά τα τελευταία έτη της ζωής του, ιδίως από το 1970 και
          μετά, ήταν ασθενής και πέθανε στις 7 Ιουλίου του 1972. Ο Αθηναγόρας
          υπήρξε ο μακροβιότερος από όλους τους μετά την άλωση Οικουμενικούς
          Πατριάρχες, διατηρώντας το Θρόνο για 24 συναπτά έτη (1948-1972).
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
      <Typography variant="h1" sx={theme.typography.typography1}>Φωτογραφικό Υλικό</Typography>

        <img src={Image06}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image07}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image08}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image09}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image10}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image11}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image12}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image13}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image14}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image15}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image16}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image17}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img src={Image18}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default P6_Hmerologia;
