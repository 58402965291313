import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/20sinedriopandoiko_01.jpg";

function F6_oikologika_06() {

    const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="20o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>


      
      <Box sx={{ ...commonBoxStyles }}>      
      <Typography variant="h1" sx={theme.typography.typography1}>Σέρρες 24,25 και26 Οκτωβρίου 2008.</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>ΘΕΜΑ: «Φυσικό Περιβάλλον - Φορείς Διαχείρισης - Δημόσια Διοίκηση»</Typography>
		
		<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

		<Typography variant="h3" sx={theme.typography.typography2}> Ολοκληρώθηκαν με επιτυχία οι εργασίες του 20ου συνεδρίου του Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων από την Οικολογική Κίνηση Σερρών με τη 
		συνεργασία της Κεντρικής Ένωσης Δήμων και Κοινοτήτων Ελλάδος (ΚΕΔΚΕ), της Νομαρχιακής Αυτοδιοίκησης Σερρών, του Δήμου Σερρών, της ΤΕΔΚ Σερρών, του 
		Εμποροβιομηχανικού Επιμελητηρίου Σερρών (ΕΒΕΣ) και του ΤΕΙ Σερρών.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η κήρυξη έναρξης εργασιών του Συνεδρίου έγινε την Παρασκευή 24 Οκτωβρίου 2008. Μετά το καλωσόρισμα από τους εκπρόσωπους  της Οικολογικής Κίνησης Σερρών 
		και του ΠΑΝΔΟΙΚΟ ακολούθησαν χαιρετισμοί από τον Νομάρχη  Σερρών κ. Φωτιάδη, από εκπρόσωπο του Οικουμενικού Πατριαρχείου (ο οποίος διάβασε μήνυμα του 
		Οικουμενικού Πατριάρχη κ. Βαρθολομαίου) από τον δήμαρχο Σερρών κ. Γιάννη Βλάχο από τον πρόεδρο της Επιτροπής Περιβάλλοντος της ΚΕΔΚΕ και δήμαρχο Λαμιαίων 
		κ. Γεώργιο Κοτρονιά από την ευρωβουλευτή κ. Ευαγγελία Τζαμπάζη από την εκπρόσωπο  των «Ευρωπαίων Πρασίνων» κ. Μαρία Βασιλάκου, από τον Πρόεδρο του 
		Εμποροβιομηχανικού Επιμελητηρίου Σερρών κ. Άρη Δρογαλά και από τον πρόεδρο του ΤΕΙ Σερρών κ. Δημήτρη Πασχαλούδη</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Μετά το θεματικό Συνέδριο πραγματοποιήθηκε το εσωτερικό Συνέδριο του ΠΑΝΔΟΙΚΟ στο οποίο εκτός από τους απολογισμούς συζητήθηκαν προτάσεις για την καλλίτερη 
		λειτουργία του Δικτύου καθώς και οι προτάσεις για την αλλαγή του καταστατικού. Με την λήξη του Συνεδρίου του ΠΑΝΔΟΙΚΟ εκλέχτηκε νέα επταμελής Γραμματεία η οποία 
		αποτελείται από τους εξής:
		Γράψας Κώστας (ΛΕΥΚΑΔΑ) , Μπελέσης Αλέξανδρος (ΜΑΚΡΑΚΩΜΗ ΦΘΙΩΤΙΔΑΣ) , Τσέκος Γιώργος (ΧΑΝΙΑ) , Σπίθας Ευάγγελος (ΑΛΙΒΕΡΙ) , Πασχαλίδης Γιώργος (ΚΑΒΑΛΑ) , 
		Γεροκώστα Γιάννα (ΛΑΡΙΣΑ) , Φούντας Πάρις (ΛΑΜΙΑ).</Typography>
		
		
		<Typography variant="h1" sx={theme.typography.typography1}>Απόφαση συνεδρίου</Typography>
		
		<Typography variant="h3" sx={theme.typography.typography2}>Οι σύνεδροι αφού άκουσαν τις εμπεριστατωμένες εισηγήσεις και συμμετείχαν στον διάλογο που αναπτύχθηκε στο πλαίσιο του συνεδρίου,
		εξέδωσαν την παρακάτω απόφαση.</Typography>
		
		<Typography variant="h3" sx={theme.typography.typography2}><strong>Η χώρα μας, ιδιαίτερα ευνοημένη από πλευράς φυσικών οικοσυστημάτων και τοπίων, σημειώνει σοβαρές αποτυχίες σ’ ότι αφορά την προστασία και διατήρησή τους 
		λόγω μεγάλων νομοθετικών ελλειμμάτων και ακόμη μεγαλύτερων αδυναμιών εφαρμογής της υπάρχουσας περιβαλλοντικής νομοθεσίας.</strong></Typography>
		
		<Typography variant="h3" sx={theme.typography.typography2}>Ειδικότερα:<br/><br/>
		H ιδιαιτέρως πλούσια <strong>χλωρίδα και πανίδα</strong> της χώρας μας καθώς και η ασυνήθιστα πλατιά γκάμα των τοπίων και οικοσυστημάτων της, αποτελούν σοβαρότατο πλεονέκτημά
		μας αλλά και συνεπάγονται αυξημένες ευθύνης κράτους και πολιτών για την διατήρησή τους.   Συνοπτικά αναφέρουμε ότι, στη χλωρίδα μας περιλαμβάνονται πάνω 
		από 5.500 είδη, ανάμεσα στα οποία πολλά υπάρχουν μόνο στη χώρα μας (ενδημικά) και άλλα είναι σπάνια και απειλούμενα. Εντυπωσιακός είναι και ο αριθμός των 
		ειδών της ελληνικής πανίδας (422 πουλιά, 116 θηλαστικά 20.000 ασπόνδυλα).</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η επιτυχία στον στόχο της διατήρησης, που ορίζεται και από το άρθρο 24 του Συντάγματός μας αλλά και από την Ευρωπαϊκή νομοθεσία για τους οικοτόπους και 
		τα άγρια πτηνά, τη Διεθνή Σύμβαση για την προστασία της βιοποικιλότητας και τις παγκόσμιες διασκέψεις του Ρίο και του Γιοχάνεσμπουργκ, είναι πρώτιστο καθήκον 
		της δημόσιας διοίκησης και της αυτοδιοίκησης μας. Τόσο οι εισηγήσεις όσο και η εμπειρία των οργανώσεων μελών του Δικτύου οδηγούν
		στη γενική διαπίστωση ότι, παρά την ίδρυση 27 Φορέων Διαχείρισης προστατευομένων περιοχών, η κατάσταση των φυσικών οικοτόπων της χώρας επιδεινώνεται 
		από αιτίες όπως οι πυρκαγιές, η υπερβόσκηση, το κυνήγι, η οικιστική επέκταση, η απόρριψη στερεών και υγρών αποβλήτων, περιλαμβανομένων και τοξικών,  η 
		παράνομη αποψίλωση, η υλοποίηση αλόγιστων επεμβάσεων, όπως η εκτροπή μεγάλων ποταμών, η επιβάρυνση από  την χρήση αγροχημικών.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Υπάρχουν σοβαρότατα <strong>νομοθετικά ελλείμματα</strong> : η πλειοψηφία των προστατευμένων περιοχών <strong>δεν διαθέτει ειδικό νομοθετικό καθεστώς</strong>,
		παρ’ ότι τούτο είναι υποχρέωση της Πολιτείας, ενώ καμιά νομοθετική πρωτοβουλία δεν έχει αναληφθεί για να γίνουν <strong>θαλάσσια καταφύγια</strong>.
		Προπαντός όμως παρατηρείται <strong>τεράστια αποτυχία εφαρμογής</strong> της κείμενης γενικής, και σε όποιες περιπτώσεις υπάρχει, ειδικής περιβαλλοντικής νομοθεσίας: η 
		ρύπανση ποταμών –ενδεικτικά αναφέρουμε τον Αλιάκμονα και τον Ασωπό- συνεχίζεται, η υποβάθμιση λιμνών, με κορυφαίο παράδειγμα την συνεχιζόμενη κατάργηση της 
		Λίμνης Κορώνειας ως τέτοιας, παραμένει πραγματικό στίγμα στον πολιτισμό μας, οι καταπατήσεις δημόσιας γης έχουν λάβει διαστάσεις.
		Ο θεσμός των <strong>Φορέων Διαχείρισης</strong>, θετικός κατ’ αρχήν, πάσχει όχι μόνο από την έλλειψη ειδικής νομοθεσίας ανά περιοχή, αλλά και από την 
		υποχρηματοδότηση, την έλλειψη αρμοδιοτήτων, μέσων και προσωπικού (ιδίως φύλαξης) για την εφαρμογή των αποφάσεών του.
		Άλλοι ελεγκτικοί θεσμοί όπως οι <strong>επιθεωρητές περιβάλλοντος</strong> αδυνατούν να φέρουν επαρκώς σε πέρας το έργο τους, λόγω επίσης σοβαρών ελλείψεων προσωπικού και μέσων.
		Οι <strong>Νομαρχιακές Υπηρεσίες</strong> περιβάλλοντος είναι ισχνές και αδύναμες.</Typography>
		<Typography variant="h3" sx={theme.typography.typography2}>Η <strong>Δασική Υπηρεσία</strong>, ακρογωνιαίος λίθος κάθε πολιτικής προστασίας των φυσικών οικοσυστημάτων, υφίσταται τα τελευταία χρόνια συστηματική 
		απογύμνωση από  μέσα, αρμοδιότητες και προσωπικό. Οι σύνεδροι του 20ου Συνεδρίου του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων εκτιμούμε ότι <strong>απόλυτη 
		προτεραιότητα</strong> στο πλαίσιο σαφών χρονοδιαγραμμάτων πρέπει να δοθεί:</Typography>
		<ul>
			<li>Στην εντός έτους <strong>ολοκλήρωση των ειδικών νομοθεσιών</strong> για τις προστατευόμενες περιοχές με μορφή προεδρικών διαταγμάτων</li>
			<li>Στην λήψη ουσιαστικών μέτρων  για την <strong>ορατή βελτίωση του επιπέδου εφαρμογής της νομοθεσίας</strong> ιδίως στους τομείς της μόλυνσης 
			υδατίνων σωμάτων, της καταπάτησης δημοσίων εκτάσεων, της ανάταξης καμένων περιοχών και υποβαθμισμένων λιμναίων οικοσυστημάτων</li>
			<li>Ειδικότερα για τους <strong>Φορείς Διαχείρισης</strong> στην άμεση έστω και μερική χρηματοδότησή τους από τον κρατικό προϋπολογισμό, παροχή σ’ αυτούς 
			δυνατότητας υλοποίησης των έργων με αυτεπιστασία και  μόνιμο προσωπικό,  απλοποίηση γραφειοκρατικών διαδικασιών, συντονισμός των συναρμοδίων τους 
			υπηρεσιών, μεταφορά της υλικοτεχνικής υποδομής που χρησιμοποιούν στη δικαιοδοσία των ιδίων.</li>
			<li>Στην  αναδιοργάνωση και επαρκή στελέχωση της <strong>Δασικής Υπηρεσίας</strong></li>
			<li>Στην λήψη μέτρων για την <strong>πάταξη της λαθραίας θήρας</strong> και την μείωση της κυνηγετικής περιόδου.</li>
		</ul>
		
		<Typography variant="h3" sx={theme.typography.typography2}>Πιστεύουμε ότι ο <strong>διάλογος διοίκησης και οργανώσεων των πολιτών</strong> είναι αναγκαίος όρος για την προαγωγή της προστασίας της φύσης, 
		τόσο σ’ ότι αφορά την ευαισθητοποίηση του κοινού  όσο και την μαχητική συμβολή στην επίτευξη των στόχων εφαρμογής της νομοθεσίας. Η ελληνική Φύση αποτελεί 
		κεφάλαιο αναντικατάστατων βιολογικών, αισθητικών αλλά και οικονομικών, στο πλαίσιο της βιωσιμότητας, αξιών.<br/><br/>
		<strong>Η  προστασία της στην πράξη και όχι μόνο στα λόγια αποτελεί ύψιστο δημόσιο συμφέρον της παρούσης και των επιμένων γενεών.</strong><br/><br/>
		<strong>ΟΙ ΣΥΝΕΔΡΟΙ ΤΟΥ 20ΟΥ ΣΥΝΕΔΡΙΟΥ ΤΟΥ ΠΑΝΕΛΛΗΝΙΟΥ ΔΙΚΤΥΟΥ ΟΙΚΟΛΟΓΙΚΩΝ ΟΡΓΑΝΩΣΕΩΝ  ΣΕΡΡΕΣ   26 ΟΚΤΩΒΡΙΟΥ 2008</strong></Typography>	
		
	</Box>
    <ScrollToTopButton />
	
    </>
  );
}

export default F6_oikologika_06;
