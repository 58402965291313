import React from 'react';
import Button from '@mui/material/Button';

const ScrollToTopButton = () => {

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Button variant="outlined" color="primary" onClick={handleScrollToTop} size="small" sx={{marginTop:'1em', marginBottom:'1em'}}>
            Top
        </Button>
    );
};

export default ScrollToTopButton;

