import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/mikrasiatika_g12/baftistiko.jpg";


function G12_mikrasiatika() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Έθιμα για τη λεχώνα και το βρέφος" />
      </Box>


      <Box sx={{ ...commonBoxStyles }}>
	
	
	<img src={Image01}    style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	<Typography variant="h3" sx={theme.typography.typography2}>Την ώρα του τοκετού οι γύρω από την ετοιμόγεννη επικαλούνταν τον Άγιο Ελευθέριο για να την ελευθερώσει. Όταν προβλεπόταν δύσκολος τοκετός, κοντά στο εικόνισμα 
	του Αγίου τοποθετούσαν και ποτήρι γεμάτο νερό μέσα στο οποίο έβαζαν ένα φυτό φερμένο από το ΄΄Χατζηλίκι΄΄ δηλαδή τους Άγιους Τόπους. Αυτό στην αρχή ήταν κλειστό, 
	μόλις όμως άρχιζαν να πυκνώνουν οι πόνοι, έλεγαν ότι σιγά σιγά μέσα  στο νερό  άπλωνε και τότε έμοιαζε σαν χέρι και το ονόμαζαν ΄΄χέρι της Παναγίας΄΄. Πίστευαν έτσι
	πως έβαζε η Παναγία το χέρι της για  να καταπραϋνθούν οι πόνοι. Εάν συνέβαινε κάποτε να μην υπήρχε το φυτό τούτο, περιέζωναν την ετοιμόγεννη με ΄΄ζώνη της Παναγίας΄΄
	προερχομένη επίσης από τους Αγίους Τόπους. </Typography>


	<Typography variant="h3" sx={theme.typography.typography2}>Μόλις γεννιόταν το παιδί, το έπαιρνε η μαμή και το έτριβε σε όλο το σώμα με ζάχαρη και αλάτι ΄΄για να μεστώσει΄΄ έλεγαν και έπειτα το φάσκιωνε. Στη συνέχεια κρατώντας 
	το στα χέρια σταύρωνε τρεις φορές τη μητέρα και το έβαζε  στα πόδια της. Αυτή το κλωτσούσε ελαφρά τρεις φορές. Η  μαμή το παρέδιδε στη μητέρα και τη ρωτούσε: ΄΄αρνί
	ή κατσίκι;΄΄. Η μητέρα απαντούσε όταν ήταν κορίτσι ΄΄αρνί΄΄, όταν δε ήταν αγόρι ΄΄κατσίκι΄΄. <br/>	
	Στη συνέχεια ερχόταν ο παπάς και διάβαζε διάφορες ευχές πάνω από τη λεχώνα. Άφηνε το πετραχείλι του και το κρεμούσαν στην πόρτα του δωματίου που έμενε η λεχώνα ως 
	φυλαχτό για σαράντα μέρες.
	Με το νύχτωμα άναβαν θυμιατό με λιβάνι μαζί με σταυρολούλουδα που τα τοποθετούσαν στην πόρτα του δωματίου. Όταν ερχόταν κάποια εκείνη την ώρα για να δει τη λεχώνα,
	έπρεπε να περάσει πρώτα από το θυμιατό. Αυτό γινόταν τις τρεις πρώτες μέρες. Επίσης δεν επιτρεπόταν να βγάλουν έξω από το δωμάτιο τη φροκαλιά (σκούπα)  με την οποία 
	το σκούπισαν.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Την τρίτη μέρα ερχόταν η μαμή, προσκαλούνταν όλοι οι συγγενείς της λεχώνας και γινόταν το πρώτο ΄΄κολύμβημα΄΄  ή λούσιμο του παιδιού σε χλιαρό νερό στο οποίο 
	έριχναν λίγο κρασί και ζάχαρη. Μόλις η μαμή το βουτούσε στο νερό οι συγγενείς έριχναν χρήματα μέσα στη σκάφη ως δώρα στη μαμή. Εντωμεταξύ άλλαζαν όλα τα στρωσίδια 
	της λεχώνας με νέα και μεταξωτά. Ύστερα  από το λούσιμο, άλλαζαν το παιδί με καινούρια ρούχα και το στόλιζαν με κορδέλες και με διάφορα δώρα των συγγενών όπως φλουριά,
	σταυρούς κ. ά. Έβαζαν ακόμη κοσμήματα κάτω από το προσκεφάλι γιατί το βράδυ θα έρχονταν οι Μοίρες για να ΄΄μοιράνουν΄΄ το νεογέννητο, να το κάνουν δηλαδή καλόμοιρο ή 
	κακόμοιρο. Τα κοσμήματα, πίστευαν πως ευχαριστούσαν τις Μοίρες και έτσι το παιδί θα γινόταν καλόμοιρο. Για να αποφύγουν το κακό μάτι, τη ΄΄βασκανία΄΄, κρεμούσαν στον
	ώμο του παιδιού γαλάζιες χάντρες, σκόρδο και σφραγιστεράκι η δε μητέρα έδενε στην άκρη της μουστούκας ( μαντίλι του κεφαλιού) σκόρδο, ψωμί και δαχτυλίδι. Στη
	συνέχεια  η μαμή έπλυνε το στήθος της λεχώ	νας με ζάχαρη και λίγο κρεμμύδι και κατόπιν η μητέρα βύζαινε για πρώτη φορά το παιδί από τη γέννησή του.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Άμα περνούσαν εννιά μέρες από τη μέρα της γέννας η μαμή ξαναερχόταν και έβαζε μπροστά από το κρεβάτι της λεχώνας ένα κομμάτι σίδηρο, κατά προτίμηση πέταλο, επάνω 
	στο οποίο θα πατούσε η λεχώνα για να κατέβει  πρώτη φορά από το κρεβάτι από τότε πού γέννησε και να είναι όπως έλεγαν ΄΄σιδερένια΄΄. Και ενώ η μαμή την κρατούσε 
	από το χέρι, την έκανε τρεις βόλτες μέσα στην κάμαρά της και της ευχόταν το ΄΄καλορίζικο΄΄ και το ΄΄να ζήσει΄΄. <br/>	
	Εωσότου σαραντίσει, η λεχώνα δεν επιτρεπόταν να δει λείψανο και δεν έβγαινε έξω από το σπίτι ούτε και πήγαινε σε καμιά γειτόνισσα γιατί πίστευαν θα γινόταν κακό 
	στα σπίτια  που θα τύχαινε να επισκεφτεί. Όταν σαράντιζε η μητέρα μαζί με την γιαγιά ή κάποια γειτόνισσα πήγαιναν το παιδί στην εκκλησία για να πάρει ΄΄σαράντιση΄΄. 
	Μετά τη σχετική ευχή, έπαιρνε ο ιερέας το παιδί μέσα στο ιερό και το έκαμε τρεις βόλτες γύρω από την Αγία Τράπεζα και έπειτα όταν ήταν κορίτσι το τοποθετούσε μπροστά 
	στην Παναγία, όταν ήταν αγόρι μπροστά στο Χριστό. Η μητέρα στη συνέχεια το πήγαινε σε σπίτια συγγενών και γειτόνων όπου οι γυναίκες έβαζαν μέσα στη φασκιά του παιδιού
	ζάχαρη για να γίνει γλυκό και βαμβάκι για να γίνει γέρος με άσπρα μαλλιά. Τέλος πριν μπει στο σπίτι της,  έπιανε το σιδερένιο χαλκά της εξώπορτας για να γίνει
	΄΄σιδερένια΄΄.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Προτού σαραντίσει το μωρό, για να βγει το κακό αίμα, ΄΄το χάραζαν΄΄ δηλαδή χάραζαν όλη τη ράχη και τις φτέρνες με ξυράφι.  Εωσότου σαραντίσει το μωρό, δεν έτρωγε 
	τίποτα άλλο από το γάλα της μητέρας εκτός και αν αρρώσταινε αυτή οπότε το τάιζε γάλα, χαλβά μαύρο και αλεύρι καβουρδισμένο με βούτυρο. Μετά τη σαράντιση η μητέρα τάιζε
	το παιδί με πυκνή τροφή, που πρώτα τη μασούσε η ίδια και έπειτα την έβαζε στο στόμα του. <br/>	
	Για τον ύπνο έκαναν κρεμαστές κούνιες τις οποίες κουνούσαν με μακριές λουρίδες και έλεγαν διάφορα νανουρίσματα. Σε πολλά σπίτια έκαμαν ξύλινες κούνιες τις ΄΄πισκές΄΄
	όπως τις έλεγαν. </Typography>

    <Typography variant="h3" sx={theme.typography.typography2}>Όταν έκλεγε το παιδί δυνατά, θεωρούσαν πως το κρατούσε πόνος. Φώναζαν τη μαμή να το εξετάσει, κατόπιν έβαζε στο στόμα της ρακί και το ΄΄φρόμαζε΄΄ μέσα στο στόμα 
	του παιδιού για να ησυχάσει. Έπειτα ανακάτευαν κύμινο με ρακί και άλειφαν τον αφαλό του για να του περάσει ο πόνος.  Όταν τα παιδί δεν ήταν κλαψιάρικο, έλεγαν πως 
	ήταν ΄΄δρακονιάρικο΄΄, δηλαδή πίστευαν πως το έπιασε δράκαινα και φρόντιζαν με αγιωτικά και γυναικεία φάρμακα να το γιατρέψουν. <br/>	
	Την περίοδο που έβγαιναν τα πρώτα δόντια και ειδικά όταν αυτά έβγαιναν δύσκολα, φώναζαν τη μαμή η οποία έβαζε στην κορυφή του κεφαλιού ρακί για να είναι ζεστό το 
	κεφάλι και χάραζε τα ούλα με ΄΄φλωρί βενέτικο΄΄.</Typography>

	<Typography variant="h3" sx={theme.typography.typography2}>Συνήθως η βάπτιση γινόταν μετά τη σαράντιση, εκτός και αν αρρώσταινε το μωρό οπότε βαπτιζόταν αμέσως για να μη τυχόν και πεθάνει αβάπτιστο και χωρίς άγιο μύρο.  
	Το βάπτισμα γινόταν στην εκκλησία και στο τέλος όλοι οι προσκεκλημένοι, προπορευομένου του νουνού με το ΄΄βαφτισίμι΄΄ του στην αγκαλιά και με μια μεγάλη λαμπάδα 
	στα χέρια, πήγαιναν στο σπίτι του παιδιού, όπου η μητέρα του φιλούσε το χέρι και το έπαιρνε από την αγκαλιά του. Κατόπιν κερνούσαν γλυκό, στραγάλια και χαλβά και 
	δίνονταν διάφορες ευχές. Ο νονός στη συνέχεια μοίραζε τις ΄΄μαρτυρίες΄΄ στους προσκεκλημένους, στα δε παιδιά που ήταν μαζεμένα στην αυλή πετούσε κέρματα ΄΄τα
	μεταλλίκια΄΄. Το όνομα που θα έδιναν στο παιδί θα έπρεπε να είναι του πεθαμένου πλησιέστερου συγγενούς των γονιών του παιδιού ή και του νονού.</Typography>
	</Box>

    <ScrollToTopButton />

    </>
  );
}

export default G12_mikrasiatika;
