import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/drastiriotites2015/image001.jpg";
import Image03 from "../assets/images/drastiriotites2015/image003.jpg";
import Image05 from "../assets/images/drastiriotites2015/image005.jpg";
import Image07 from "../assets/images/drastiriotites2015/image007.jpg";
import Image09 from "../assets/images/drastiriotites2015/image009.jpg";
import Image11 from "../assets/images/drastiriotites2015/image011.jpg";
import Image13 from "../assets/images/drastiriotites2015/image013.jpg";
import Image15 from "../assets/images/drastiriotites2015/image015.jpg";
import Image17 from "../assets/images/drastiriotites2015/image017.jpg";
import Image19 from "../assets/images/drastiriotites2015/image019.jpg";
import Image21 from "../assets/images/drastiriotites2015/image021.jpg";
import Image23 from "../assets/images/drastiriotites2015/image023.jpg";
import Image25 from "../assets/images/drastiriotites2015/image025.jpg";
import Image27 from "../assets/images/drastiriotites2015/image027.jpg";
import Image29 from "../assets/images/drastiriotites2015/image029.jpg";
import Image31 from "../assets/images/drastiriotites2015/image031.jpg";
import Image33 from "../assets/images/drastiriotites2015/image033.jpg";
import Image35 from "../assets/images/drastiriotites2015/image035.jpg";
import Image37 from "../assets/images/drastiriotites2015/image037.jpg";
import Image39 from "../assets/images/drastiriotites2015/image039.jpg";
import Image41 from "../assets/images/drastiriotites2015/image041.jpg";
import Image43 from "../assets/images/drastiriotites2015/image043.jpg";
import Image45 from "../assets/images/drastiriotites2015/image045.jpg";
import Image47 from "../assets/images/drastiriotites2015/image047.jpg";
import Image49 from "../assets/images/drastiriotites2015/image049.jpg";
import Image51 from "../assets/images/drastiriotites2015/image051.jpg";
import Image53 from "../assets/images/drastiriotites2015/image053.jpg";
import Image55 from "../assets/images/drastiriotites2015/image055.jpg";
import Image57 from "../assets/images/drastiriotites2015/image057.jpg";
import Image59 from "../assets/images/drastiriotites2015/image059.jpg";
import Image61 from "../assets/images/drastiriotites2015/image061.jpg";
import Image63 from "../assets/images/drastiriotites2015/image063.jpg";
import Image65 from "../assets/images/drastiriotites2015/image065.jpg";
import Image67 from "../assets/images/drastiriotites2015/image067.gif";
import Image68 from "../assets/images/drastiriotites2015/image068.jpg";
import Image70 from "../assets/images/drastiriotites2015/image070.jpg";
import Image72 from "../assets/images/drastiriotites2015/image072.jpg";

function D12_drastiriotites_2015() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Οι δραστηριότητές μας το 2015" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Κοπή της Βασιλόπιτας του Συλλόγου μας
        </Typography>
        <img
          src={Image01}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image03}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στην αίθουσα της βιβλιοθήκη μας, με βροχερό καιρό και πολύ κρύο, την
          Κυριακή 4 Ιανουαρίου 2015 στις 5 το απόγευμα το Δ.Σ. του Συλλόγου μας,
          παρουσία πολλών μελών και κατοίκων του χωριού, έκοψε την βασιλόπιτά
          του.
          <br />
          Παρόντες ο ιερέας της ενορίας μας ο οποίος και την ευλόγησε, οι ψάλτες{" "}
          <b>Ευστράτιος Πασπατής</b> και <b>Σωτήρης Μαμουνής</b>, ο πρόεδρος του
          Τοπικού μας Συμβουλίου <b>Βασίλης Μαμώλης</b>, το μέλος του Τοπικού
          Συμβουλίου <b>Νίκος Σπανός</b>, ο Πρόεδρος του Συλλόγου της παλιάς
          αγοράς <b>Ιωάννης Μολυβιάτης</b>, εκπρόσωπος της Οικολογικής κίνησης
          Πάτρας <b>Διονύσης Μαμάσης</b> και πολλοί άλλοι.
          <br />
          Το χρυσό φλουρί, ήταν προσφορά από το κατάστημα «Ρολογάδικο» του κ.
          Ιωάννη Μολυβιάτη και μετά από κλήρωση το κέρδισε ο κ. Μιχάλης Τουρλής.{" "}
          <br />
          Κατά την διάρκεια της εκδήλωσης έγινε και η κλήρωση των δώρων της
          λαχειοφόρου αγοράς του Συλλόγου και κέρδισαν οι παρακάτω αριθμοί:
          <br />
          1. Ο αριθμός 307 κέρδισε το TABLET.
          <br />
          2. Ο αριθμός 152 κέρδισε το ποδήλατο.
          <br />
          3. Ο αριθμός 206 κέρδισε το ραδιόφωνο.
          <br />
          <br />
          Και του χρόνου με υγεία.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ο Αγιασμός των υδάτων στη Σκάλα Λουτρών και στη Χαραμίδα, τον
          περασμένο Ιανουάριο.
        </Typography>
        <img
          src={Image05}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image07}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με την παρουσία του Δημάρχου Λέσβου, κ. <b>Γαληνού Σπύρου</b>, του
          αντιδημάρχου οικονομικών κ. Τσουπή Παναγιώτη, του προέδρου του
          Δημοτικού Συμβουλίου κ. Ψάνη Γεωργίου, του εντεταλμένου Δημοτικού
          συμβούλου κ. Στεργίου Θεοδώρου, του Δημοτικού Συμβούλου κ.
          Αλβανόπουλου Παναγιώτη, του προέδρου του Τοπικού Συμβουλίου Λουτρών κ.
          Μαμώλη Βασίλη, του εκπροσώπου της Σκάλας Λουτρών στο Τοπικό Συμβούλιο
          κ. Σπανού Νικολάου και του Δ.Σ. του Συλλόγου Μικρασιατών της Σκάλας
          Λουτρών «ΤΟ ΔΕΛΦΙΝΙ», πραγματοποιήθηκε τον περασμένο Ιανουάριο ο
          Αγιασμός των υδάτων τόσο στη Σκάλα Λουτρών όσο και στη Χαραμίδα.
          <br />
          Παρά το τσουχτερό κρύο και την κατά περιόδους χιονόπτωση, η συμμετοχή
          των κατοίκων ήταν μεγάλη αφού μετά το πέρας της Θείας Λειτουργίας στον
          Ιερό Ναό του Αγίου Γεωργίου Λουτρών, ο ιερέας μαζί με το εκκλησίασμα
          ξεκίνησαν για τον αγιασμό των υδάτων στην προβλήτα της Σκάλας Λουτρών,
          ένα έθιμο το οποίο επαναλαμβάνετε αδιαλείπτως κάθε χρόνο. Αφού διάβασε
          ο ιερέας της ενορίας μας το ευαγγέλιο πάνω στην εξέδρα που γι αυτό το
          σκοπό είχε στηθεί, μόλις είπε το «εν Ιορδάνη βαπτιζομένου σου Κύριε…»
          δύο θαρραλέοι κολυμβητές βούτηξαν στα παγωμένα νερά του κόλπου Γέρας
          και αυτός που έπιασε πρώτος τον Σταυρό τον οποίο και ανέσυρε στην
          επιφάνεια, ήταν ο <b>Άγγελος Μίνικνας</b> από τα Λουτρά. Αμέσως μετά
          το πολιτιστικό κέντρο Λουτρών έκοψε την καθιερωμένη βασιλόπιτα
          προσφέροντας συγχρόνως από ένα ποτό στους παρευρισκόμενους.
          <br />
          <br />
          Μετά τον Αγιασμό των υδάτων που έγινε στη Σκάλα Λουτρών ο ιερέας και
          οι περισσότεροι από τους παρευρισκόμενους, ξεκίνησαν για να
          παρευρεθούν στον Αγιασμό των υδάτων στην περιοχή της Χαραμίδας όπου
          περίμεναν πολλοί κάτοικοι της περιοχής, καθώς και αρκετοί Μυτιληνιοί.
          <br />Η καθιερωμένη τελετή και εδώ πραγματοποιήθηκε χωρίς ιδιαίτερα
          προβλήματα και τον Σταυρό ανέσυρε από τη θάλασσα ο{" "}
          <b>Μιχάλης Μακρής</b>.<br />
          Αμέσως μετά ακολούθησε και εδώ το κόψιμο της βασιλόπιτας των κατοίκων
          της περιοχής και το σχετικό κέρασμα σε όλους τους παρευρισκόμενους.
          <br />
          Παρόντες και εδώ τα μέλη τόσο του Τοπικού Συμβουλίου Λουτρών όσο και
          του Συλλόγου Μικρασιατών της Σκάλας Λουτρών. <br />
          <br />
          Και του χρόνου με υγεία.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Προβολή του ιστορικού ντοκιμαντέρ «Γεγονότα στη Φώκαια 1914"
        </Typography>
        <img
          src={Image09}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το Σάββατο 14 Φεβρουαρίου 2015 στο Θέατρο του Φ.Ο.Μ. στις 19.00 ο
          Σύλλογός μας σε συνεργασία με το Πολιτιστικό Κέντρο Φ.Ο.Μ. ο
          «ΘΕΟΦΙΛΟΣ»,, προβάλανε το Ιστορικό ντοκιμαντέρ των Ανιές Σκλάβου και
          Στέλιου Τατάκη, διάρκειας περίπου 60 λεπτών, με τίτλο «
          <b>Γεγονότα στη Φώκαια 1914</b>".
          <br />
          <br />
          <i>
            Ο <b>Φελίξ Σαρτιώ</b> (Felix Sartiaux, 1876-1944) Γάλλος μηχανικός
            και αρχαιολόγος, ήταν ο πρώτος που οργάνωσε αρχαιολογικές ανασκαφές
            στην αρχαία Φώκαια της Μικράς Ασίας το “1913”, “1914” και “1920”.
            Όταν ξέσπασε ο διωγμός της Φώκαιας στις 12 Ιουνίου “1914” (30 Μαΐου
            με το παλαιό ημερολόγιο) βρέθηκε εκεί για δεύτερη φορά και οι
            διωκόμενοι Φωκιανοί βρήκαν προστασία κοντά του, αφού ο επιφανής
            φιλέλληνας κατόρθωσε με την βοήθεια των συνεργατών του, να υψώσει τη
            σημαία της Γαλλικής Δημοκρατίας σε τέσσερα σπίτια και έτσι να σώσει
            μεγάλο μέρος του πληθυσμού από τη μανία των Τσετών. <br />
            Φωτογράφισε όλες τις στιγμές της χαράς και της θλίψης του τόπου,
            δημοσίευσε κείμενα και πολύ λίγες φωτογραφίες από όσα είχε δει και
            ζήσει ως μάρτυρας του διωγμού. Όλο του το έργο είναι αφιερωμένο στην
            τέχνη της Μικράς Ασίας, με επίκεντρο τη Φώκαια.
            <br />
            Αυτή η άγνωστη πτυχή της ιστορίας του Ελληνικού Έθνους και
            ειδικότερα των Φωκιανών που η μοίρα τους έλαχε να έχουν υποστεί όχι
            έναν αλλά δύο διωγμούς, το “1914” και το “1922”, έρχεται να φωτιστεί
            με την πρόσφατη ανακάλυψη από τον ιστορικό φωτογραφίας κ. Χάρη
            Γιακουμή, πρώτα όλου του φωτογραφικού αρχείου του Σαρτιώ και
            ακολούθως του υπόλοιπου γραπτού του αρχείου. Μέσα στην προσωπική
            δερμάτινη βαλίτσα του, εκτός από το συμβολικό και ιδιαίτερα
            συγκινητικό πορτραίτο του εθνομάρτυρα Χρυσοστόμου Σμύρνης, βρέθηκαν
            όλα τα άρθρα και οι σημειώσεις του Σαρτιώ και των συνεργατών του για
            τα θλιβερά γεγονότα του “1914”. Μας φανερώνουν δε, τον πλέον
            σημαντικό μάρτυρα και πιστό φίλο του Ελληνισμού της Μικράς Ασίας που
            τόσο αγάπησε και υποστήριξε σε όλες τις δύσκολες στιγμές της ύπαρξής
            του.
            <br />
            <br />
            Στο ιστορικό ντοκιμαντέρ των Ανιές Σκλάβου και Στέλιου Τατάκη
            διάρκειας 60 περίπου λεπτών που προβλήθηκε με τίτλο «
            <b>Γεγονότα στη Φώκαια 1914</b>" παρουσιάζονται τα συμβάντα μέσα από
            συνεντεύξεις με ιστορικούς, εμπειρογνώμονες, απογόνους από την
            Φώκαια και του φωτογραφικού αρχείου, με στόχο να τιμηθεί η μνήμη του
            Σαρτιώ και συγχρόνως να προσφερθεί η ιστορική τεκμηρίωση των
            γεγονότων που διαδραματίστηκαν 100 χρόνια πριν.
          </i>
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αποκριάτικη παιδική εορτή
        </Typography>
        <img
          src={Image11}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image13}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Κυριακή 22 Φεβρουαρίου, για τρίτη συνεχόμενη χρονιά,
          πραγματοποιήθηκε στον αύλιο χώρο του «Μουσείου Προσφυγικής Μνήμης
          1922», αποκριάτικη παιδική εορτή, με τη συμμετοχή όλων των μικρών μας
          φίλων.
          <br />
          Τα παιδιά, αλλά και αρκετοί από τους γονείς, μεταμφιεσμένοι ανάλογα με
          τα γούστα τους ξεφάντωσαν για αρκετές ώρες στον κατάλληλα διακοσμημένο
          αύλιο χώρο του Μουσείου. Οι μητέρες είχαν φέρει μαζί τους διάφορα
          γλυκίσματα όπως κέικ, τούρτες, και όλων των ειδών τις λιχουδιές. Όλοι
          περάσαμε πραγματικά υπέροχα και αυτή η γιορτούλα έχει πλέον καθιερωθεί
          αφού την επαναλαμβάνουμε κάθε χρόνο αυτή την εποχή. Η συμμετοχή
          κλόουν, ενθουσίασε τα παιδιά και το ξεφάντωμα με το αποκριάτικο
          γαϊτανάκι ήταν αναπόφευκτο.
          <br />
          <br />
          Ο Σύλλογός μας ευχαρίστησε όλους τους γονείς για την συμμετοχή τους
          και ειδικότερα τις μητέρες των μικρών παιδιών, για την εξαιρετική
          βραδιά που μας πρόσφεραν. <br />
          Και του χρόνου με υγεία.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αρχαιρεσίες στον Σύλλογό μας.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε την Κυριακή 13 Απριλίου 2015 και ώρα 6 το απόγευμα η
          συνεδρίαση του νέου Δ.Σ. του Συλλόγου Μικρασιατών της Σκάλας Λουτρών
          «ΤΟ ΔΕΛΦΙΝΙ» που προέκυψε από τις αρχαιρεσίες της 5-4-2015 και
          καθόρισε τις αρμοδιότητές του ως κάτωθι:
          <b>Πρόεδρος:</b> Παπαχρυσός Δημήτρης.
          <br />
          <b>Αντιπρόεδρος:</b> Βέντου Δώρα.
          <br />
          <b>Γ. Γραμματέας:</b> Δασκάλου Μαρία. <br />
          <b>Ταμίας:</b> Σταματέλλη Ρένα.
          <br />
          <b>Μέλη:</b> Παλκανίκου Τζένη, Τουρλής Μιχάλης, Μεταξά Φιλίτσα.
          <br />
          <b>Αν. Μέλη:</b> Λιόφλου Βικτωρίτσα, Λημναίου Βαγγελίτσα, Δαφιώτη
          Νάσια.
          <br />
          <br />
          <b>Υπεύθυνοι τμημάτων του Συλλόγου:</b>
          <br />
          <br />
          <b>Στο Μουσείο Προσφυγικής Μνήμης 1922 :</b> Τουρλή Ευθαλία, Καραμάνης
          Στράτης και Μεταξά Φιλίτσα.
          <br />
          <b>Στο τμήμα Πολιτιστικών εκδηλώσεων:</b> Δαφιώτη Νάσια, Λέλεκας
          Έλπις, Τουρλής Μιχάλης, Λιόφλου Βικτωρίτσα, Λημναίου Βαγγελίτσα και
          Τουρλή Ευθαλία.
          <br />
          <b>Στα θέματα Περιβάλλοντος :</b> Παπαχρυσός Δημήτρης, Σταματέλλη
          Ρένα, Βέντου Δώρα, Μεταξά Φιλίτσα, Δασκάλου Μαρία και Τουρλής Μιχάλης.
          <br />
          <b>Στην βιβλιοθήκη :</b> Παπαχρυσός Δημήτρης, Σταματέλλη Ρένα, Βέντου
          Δώρα και Μεταξά Φιλίτσα.
          <br />
          <b>Στα Μουσικά τμήματα:</b> Παπαχρυσός Δημήτρης, Σταματέλλη Ρένα, και
          Μεταξά Φιλίτσα.
          <br />
          <b>Υπεύθυνοι δημοσίων σχέσεων:</b> Δαφιώτη Νάσια και Παπαχρυσός
          Δημήτρης.
          <br />
          <br />
          <b>Τέλος</b> για τις εισπράξεις συνδρομών κτλ καθορίστηκαν ως βοηθοί
          του ταμία οι Παπαχρυσός Δημήτρης, Μεταξά Φιλίτσα και Βέντου Δώρα.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ο στολισμός του επιταφίου
        </Typography>
        <img
          src={Image17}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Φέτος όπως κάθε χρόνο ο στολισμός του επιταφίου έγινε από τις γυναίκες
          και τα νέα κορίτσια του οικισμού μας, που αφιέρωσαν με μεγάλη χαρά
          πολλές ώρες, για να είναι ο στολισμός του επιταφίου ότι καλλίτερο.{" "}
          <br />
          Τα ονόματά τους γνωστά σε όλους μας όπως η Ευθαλίτσα, η Γραμματούλα, η
          Δέσποινα, η Ανθούλα, η Άννα, η Ελένη, η Ειρήνη, η Βασιλεία, η
          Βικτωρίτσα, η Κατερίνα, η Κλεονίκη,η Βούλα, και τόσες άλλες… Τα
          λουλούδια που χρησιμοποιήθηκαν ήταν άγριες ορχιδέες και πασχαλιές από
          τους αγρούς καθώς και λουλούδια από τις αυλές όλων των σπιτιών,
          δημιουργώντας έναν υπέροχο συνδυασμό που μοσχομύρισε το μικρό
          εκκλησάκι του Αγίου Παντελεήμονα. <br />
          Βάσει του εθίμου, κατά την διάρκεια του στολισμού, εψάλη το τραγούδι
          της Παναγίας και άλλα εγκώμια από όλες τις κυρίες. Ενδιάμεσα του
          στολίσματος του επιταφίου ο Δημήτρης μοίρασε πρόσφορο, βουτηγμένο σε
          κόκκινο κρασί και έτσι ο στολισμός του επιταφίου στον Άγιο
          Παντελεήμονα, έληξε γύρω στις 3 τα ξημερώματα. Και του χρόνου με
          υγεία.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Πάσχα στη Σκάλα Λουτρών.
        </Typography>
        <img
          src={Image15}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στο εκκλησάκι του Αγίου Παντελεήμονα του οικισμού μας της Σκάλας
          Λουτρών αναβιώνονται κάθε χρόνο τα έθιμα των ημερών του Πάσχα από τον
          Σύλλογο μας, που αρχίζουν την Κυριακή των Βαΐων και τελειώνουν με την
          Αναστάσιμη Θεία λειτουργία του Μεγάλου Σαββάτου! Η ομορφιά είναι ότι
          όλα συνδημιουργούνται από όλους τους κατοίκους, από το χτύπημα της
          καμπάνας, το στολισμό του επιταφίου, το καθάρισμα του χώρου, την
          ψαλμωδία της περιφοράς από γυναίκες ντυμένες στα μαύρα και όλα αυτά σ'
          ένα τοπίο γεμάτο από φύση και αγκαλιασμένο από τις ακτίνες του
          Αιολικού Φώτος! <br />
          Φέτος ήταν η δέκατη συνεχής χρονιά που πραγματοποιήθηκαν οι
          θρησκευτικές εκδηλώσεις των αγίων ημερών του Πάσχα στο χωριό μας. Το
          πρωί της Κυριακής των Βαΐων, ετελέσθη η θεία λειτουργία με μεγάλη
          συμμετοχή πιστών και το απόγευμα της ίδιας ημέρας η ακολουθία του
          Νυμφίου. Εν συνεχεία την Μ. Τετάρτη το απόγευμα ετελέσθη το Μυστήριο
          του Ευχελαίου και την Μ. Πέμπτη η ακολουθία των παθών με τα 12
          Ευαγγέλια. <br />
          Την Μ. Παρασκευή έγινε η αποκαθήλωση του εσταυρωμένου και η περιφορά
          του επιταφίου σε όλο τον οικισμό, σε μια διαδρομή που δένει αρμονικά
          το πράσινο των αγρών με το μπλε της θάλασσας. Την τελετή της περιφοράς
          συμπλήρωνε η χορωδία από ομάδα γυναικών μελών του Συλλόγου μας. Η
          συμμετοχή όλων των κατοίκων και της νεολαίας του οικισμού μας ήταν
          πλήρης.
          <br />
          Τέλος το βράδυ του Μεγάλου Σαββάτου, μέσα σε μια κατανυκτική
          ατμόσφαιρα υπό το φως των κεριών ετελέσθη η αναστάσιμη Θεία Λειτουργία
          από τον πρωτοσύγκελο της Ιεράς Μητροπόλεως Μυτιλήνης. Ο σύλλογός μας
          τηρώντας το έθιμο, στο τέλος της Θείας λειτουργίας μοίρασε σε όλους
          τους παρευρισκόμενους κόκκινα αυγά και τσουρέκια. Εμείς από την πλευρά
          μας ευχαριστούμε θερμά τον εφημέριο της ενορίας μας, τον πρωτοσύγκελο
          της Ιεράς Μητροπόλεως και τον Σεβασμιότατο Μητροπολίτη Μυτιλήνης, που
          μερίμνησαν και ικανοποίησαν το αίτημά μας για αποστολή ιερέα. Η
          συμμετοχή των κατοίκων ήταν καθολική και ιδίως των ηλικιωμένων οι
          οποίοι προσήλθαν με μεγάλη χαρά. Και του χρόνου να είμαστε γεροί να
          ξαναγιορτάσουμε όλοι μαζί αυτές τις Άγιες ημέρες.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Περιβαλλοντική δράση στη Σκάλα Λουτρών.
        </Typography>
        <img
          src={Image19}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image21}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με την συμμετοχή και την συνεργασία του Συνδέσμου Εφέδρων Καταδρομέων
          Νομού Λέσβου και της Δημοτικής Κοινότητας Λουτρών ο Σύλλογός μας
          πραγματοποίησε το Σάββατο 18 Απριλίου 2015 περιβαλλοντική δράση, με
          σκοπό τον καθαρισμό του βυθού και της ακτής της Σκάλας Λουτρών.
          <br />
          Η συγκομιδή των απορριμμάτων από τον βυθό ήταν δύσκολη δεδομένου ότι
          είχαν ποντισθεί κατά το παρελθόν ογκώδη αντικείμενα, δυστυχώς από
          ασυνείδητους συμπολίτες μας, τα οποία ρύπαιναν σε σοβαρό βαθμό την
          θαλάσσια περιοχή. <br />
          Το φορτηγό που διέθεσε ο Δήμος Μυτιλήνης έφυγε υπέρβαρο από την
          «πλούσια» συλλογή των άχρηστων σκουπιδιών που περισυλλέχτηκαν και
          απεφασίσθη η προσπάθεια καθαρισμού του βυθού να επαναληφθεί σε σύντομο
          χρονικό διάστημα, για να απομακρυνθούν και από άλλα σημεία του βυθού
          τα διάφορα ποντισμένα αντικείμενα όπου αυτά υπάρχουν.
          <br />
          Παράλληλα με την ανωτέρω δραστηριότητα πραγματοποιήθηκε και εξόρμηση
          καθαριότητας και εξωραϊσμού της ευρύτερης περιοχής της Σκάλας Λουτρών,
          με κούρεμα των άγριων χόρτων και απομάκρυνση των άχρηστων
          αντικειμένων, από τους κατοίκους της περιοχής μας, οι οποίοι
          συμμετείχαν ενεργά στις προαναφερθείσες δράσεις.
          <br />
          Ένα μεγάλο ευχαριστώ από τους κατοίκους και τον Σύλλογό μας, σε όλους
          τους συμμετέχοντες, στην Δημοτική Κοινότητα Λουτρών και κυρίως στην
          ομάδα δυτών του Συνδέσμου Εφέδρων Καταδρομέων Νομού Λέσβου.
          <br />
          <br />
          <br />
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Αντικατάσταση προβολέων γηπέδου μπάσκετ
        </Typography>
        <img
          src={Image23}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στις 22 Μαΐου 2015 ο Σύλλογός μας με πρωτοβουλία του Δ.Σ. προέβη στη
          αντικατάσταση των 4 προβολέων του γηπέδου μπάσκετ, αντικαθιστώντας
          τους παλαιούς προβολείς (οι οποίοι είχαν τοποθετηθεί από το 1994) με
          νέους, έτσι ώστε να μπορούν να φωτίζουν τις νυκτερινές ώρες το γήπεδο
          χωρίς προβλήματα.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Καλλωπισμός του αύλιου χώρου Μουσείου Προσφυγικής Μνήμης 1922
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Την Κυριακή 17 Μαΐου 2015 πραγματοποιήθηκε περιβαλλοντική δράση
          καθαρισμού και καλλωπισμού του αύλιου χώρου του «Μουσείου Προσφυγικής
          Μνήμης 1922» με θέμα «Καλλωπίζω τον αύλιο χώρο του Μουσείου,
          φυτεύοντας λουλούδια». Για τον σκοπό αυτό αγοράσθηκαν και φυτεύτηκαν
          45 γλάστρες με λουλούδια στα υφιστάμενα παρτέρια με την συμμετοχή των
          μελών του Συλλόγου μας και όλων των μικρών μας φίλων, οι οποίοι έμαθαν
          με μεγάλη ευκολία τον τρόπο που φυτεύουμε τόσο τα λουλούδια όσο και τα
          δένδρα. <br />
          Το μήνυμα που δίδουμε στα παιδιά κάθε χρόνο με αυτές τις εξορμήσεις
          καθαριότητας και καλλωπισμού, είναι ότι είμαστε παρόντες στην
          προσπάθεια που πρέπει να γίνεται από όλους για την προστασία του
          περιβάλλοντος, των ακτών και των δημόσιων χώρων που εν γένει που
          αποτελούν σημαντικό χώρο αναψυχής των πολιτών, πόλο έλξης επισκεπτών
          και πηγή οικονομικής ανάπτυξης του τόπου μας.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διεθνής ημέρα Μουσείων
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το Διεθνές Συμβούλιο Μουσείων (ICOM), επιθυμώντας να αναδείξει το ρόλο
          των Μουσείων στη σύγχρονη κοινωνία, έχει ορίσει από το <b>1977</b> την{" "}
          <b>18η Μαΐου</b> ως <b>Διεθνή Ημέρα Μουσείων</b>.<br />
          Έτσι και φέτος τιμώντας αυτή την επέτειο, το «
          <b>Μουσείο Προσφυγικής Μνήμης 1922</b>» στη Σκάλα Λουτρών, παρέμεινε
          ανοικτό για τους επισκέπτες από τις <b>09.3</b>0 έως τις <b>13.00</b>{" "}
          και από τις <b>18.00</b> έως τις <b>20.30</b>.<br />
          Το μήνυμα αυτής της επετείου είναι «να γίνουν τα Μουσεία φορείς
          πολιτισμικών ανταλλαγών, με σκοπό την ανάπτυξη της μόρφωσης, της
          αμοιβαίας κατανόησης, της συνεργασίας και της ειρήνης ανάμεσα στους
          λαούς».
          <br />
          Υπενθυμίζουμε ότι το «<b>Μουσείο Προσφυγικής Μνήμης 1922</b>» τις
          υπόλοιπες ημέρες δέχεται επισκέψεις, μόνον κατόπιν τηλεφωνικής
          συνεννοήσεως στα τηλέφωνα:
          <br />
          22510.91277 κ. Ευθαλία Τουρλή και
          <br />
          69746.40419 κ. Δημήτρη Παπαχρυσό.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Βράβευση του Συλλόγου μας
        </Typography>
        <img
          src={Image25}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στην Μυτιλήνη διεξήχθη φέτος το 40ο Πανελλήνιο συνέδριο της
          Πολιτιστικής Ένωσης Εργαζομένων ΟΤΕ. 160 σύνεδροι, παρατηρητές και
          διευθυντικά στελέχη, ήρθαν στην πόλη μας απ' όλη την Ελλάδα για το
          τετραήμερο από τις 27 έως την 31η Μαΐου 2015.
          <br />
          Το θέμα του συνεδρίου ήταν «Μουσικές διαδρομές του πολιτισμού μας.
          Μουσικές του Αιγαίου», με πλούσιο πολιτιστικό και καλλιτεχνικό
          πρόγραμμα, που περιελάμβανε μεταξύ άλλων και κοινωνικές δράσεις, όπως
          προσφορά χρηματικού ποσού στο κοινωνικό παντοπωλείο του Δήμου Λέσβου.
          <br />
          Την πρώτη μέρα έναρξης του συνεδρίου, μετά τους χαιρετισμούς, το λόγο
          πήρε ο συγγραφέας ερευνητής από το χωριό μας Απόστολος Γονιδέλλης, με
          θέμα «Λυρική Ποίηση και Μουσική στην Αρχαία Λέσβο».
          <br />
          Κατά την διάρκεια των εκδηλώσεων βραβεύτηκε ο Σύλλογός μας «ΤΟ
          ΔΕΛΦΙΝΙ», για την προσφορά του στην ανάδειξη του πολιτισμού της Μικράς
          Ασίας με ειδική πλακέτα, την οποία παρέλαβε ο πρόεδρος του Δημήτρης
          Παπαχρυσός.
          <br />
          Οι εργασίες του Συνεδρίου συνεχίστηκαν τις επόμενες ημέρες στην
          αίθουσα του Επιμελητηρίου Λέσβου, όπου επίσης ο Σύλλογός μας
          συμμετείχε στις δράσεις του Συνεδρίου με την έκθεση φωτογραφιών του
          Φελίξ Σαρτιώ από τον Α! διωγμό του 1914 στην Φώκαια της Μικράς Ασίας.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Παιδική εορτή
        </Typography>
        <img
          src={Image27}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με την ευκαιρία της λήξης της σχολικής χρονιάς ο Σύλλογός μας
          διοργάνωσε παιδική εορτή στον χώρο του θερινού αμφιθεάτρου της Σκάλας
          Λουτρών το Σάββατο 13 Ιουνίου στις 6 το απόγευμα. Οι μητέρες των
          παιδιών είχαν φροντίσει να υπάρχουν όλων των ειδών οι λιχουδιές, και η
          συμμετοχή κλόουν έδωσε ιδιαίτερη χαρά στα παιδιά τα οποία ξεφάντωσαν
          χορεύοντας μέχρι αργά το βράδυ.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Διαγωνισμός ζωγραφικής και έκθεσης
        </Typography>
        <img
          src={Image29}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image31}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκε και φέτος με επιτυχία, ο ετήσιος διαγωνισμός
          ζωγραφικής και έκθεσης με τους μαθητές και τις μαθήτριες του δημοτικού
          σχολείου Λουτρών.
          <br />
          Η εκδήλωση πραγματοποιήθηκε με πρωτοβουλία του Συλλόγου μας και με την
          ευκαιρία της 5ης Ιουνίου 2015, ημέρας αφιερωμένης ως γνωστόν στην
          προστασία του Περιβάλλοντος.
          <br />
          Οι τέσσερις πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα: «Πως
          φαντάζομαι το πάρκο των ονείρων μου» και οι δύο μεγαλύτερες τάξεις
          διαγωνίσθηκαν στην έκθεση με θέμα: «Το μεγάλο πρόβλημα των σκουπιδιών
          και ο ρόλος της ανακύκλωσης».
          <br />
          Η επιλογή της καλλίτερης ζωγραφικής και της καλλίτερης έκθεσης από
          κάθε τάξη έγινε από τους δασκάλους του σχολείου και τα παιδιά δούλεψαν
          με ενθουσιασμό και φαντασία τόσο στην ζωγραφική όσο και στην έκθεση.{" "}
          <br />
          Τα παιδιά που διακρίθηκαν βραβεύθηκαν σε ειδική εκδήλωση που
          πραγματοποιήθηκε στον αύλιο χώρο του «Μουσείου Προσφυγικής Μνήμης
          1922» την Κυριακή 2 Αυγούστου 2015 στις 7 το βράδυ.
          <br />
          <br />
          Οι μαθητές και οι μαθήτριες που διακρίθηκαν είναι οι παρακάτω.
          <br />
          Από την <b>Α! Τάξη</b> οι Βασιλέλλη Μαρία και Κρανά Στέλλα. <br />
          Από την <b>Β! Τάξη</b> οι Κέντσης Στράτος και Μερούκο Φεσιάνα.
          <br />
          Από την <b>Γ! Τάξη</b> οι Τρουλινού Κατερίνα και Γκίζα Γεωργία. <br />
          Από την <b>Δ! Τάξη</b> οι Λέλεκας Φίλιππος και Πασβούρη Ελπίδα.
          <br />
          Από την <b>Ε! Τάξη</b> οι Κοζπή Σταυρούλα και Ευαγγέλου Χριστίνα.
          <br />
          Από την <b>ΣΤ! Τάξη</b> οι Λέλεκας Κωνσταντίνος και Δράκου Δήμητρα.
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Μόλυνση θαλάσσιας περιοχής.
        </Typography>
        <img
          src={Image33}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image35}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          Δύο επιστολές προς το Κεντρικό Λιμεναρχείο Μυτιλήνης και την
          Εισαγγελία έστειλε ο Σύλλογός μας από κοινού με το τοπικό διαμέρισμα
          Λουτρών για τα προβλήματα που δημιουργούνται στην θαλάσσια περιοχή μας
          σχετικά με την εγκατάλειψη και βύθιση αλιευτικών και άλλων σκαφών.
          <br />
          <br />
          Η πρώτη με ημερομηνία 20-7-2015 αναφέρει:
          <br />
          Κύριε Λιμενάρχη, σας αναφέρουμε ότι στην θαλάσσια περιοχή της Σκ
          Λουτρών και συγκεκριμένα στην προβλήτα του Αγίου Γεωργίου, υπάρχει εδώ
          και πολλά χρόνια εγκαταλελειμμένο ένα παλαιό ξύλινο πλοίο με στοιχεία
          ΔΥΟ ΑΔΕΛΦΙΑ ΝΜ334 το οποίο έχει βουλιάξει, ρυπαίνει την θαλάσσια
          περιοχή και εγκυμονεί σοβαρούς κινδύνους ατυχήματος, αφού είναι
          αφύλακτο και εύκολα προσβάσιμο σε μικρά παιδιά που επισκέπτονται την
          προβλήτα. Εκτός των προαναφερθέντων ο διαλυμένος σκελετός του πλοίου
          αυτού δημιουργεί σοβαρό πρόβλημα αισθητικής εν μέσω τουριστικής
          περιόδου καταλαμβάνει μόνιμα την θέση της προβλήτας και εμποδίζει την
          προσέγγιση αλιευτικών και τουριστικών σκαφών που επισκέπτονται την
          περιοχή μας για ανεφοδιασμό και τουρισμό. <br />
          Η κατάσταση που επικρατεί αυτή τη στιγμή, είναι απαράδεκτη και από
          υγειονομικής πλευράς για πολιτισμένο κράτος, γι αυτό σας παρακαλούμε
          να μεταβείτε άμεσα προς επιβεβαίωση των προαναφερθέντων λαμβάνοντας
          συγχρόνως, όλα τα ενδεικνυόμενα μέτρα για εντοπισμό και τιμωρία των
          υπεύθυνων της απαράδεκτης αυτής κατάστασης.
          <br />
          Επιπλέον σας ενημερώνουμε ότι, κατά το παρελθόν έχουμε επισκεφθεί
          αρκετές φορές το Λιμεναρχείο και έχουμε αναφέρει το πρόβλημα χωρίς να
          υπάρξει όμως η σχετική και επιβεβλημένη παρέμβαση στην αντιμετώπιση
          του προβλήματος.
          <br />
          <br />
          Η δεύτερη επιστολή εστάλη στις 16-11-2015 και αναφέρει:
          <br />
          Κύριε Λιμενάρχη,
          <br />
          πρόσφατα έχουν μεταφερθεί και εγκαταλειφθεί στη Σκάλα Λουτρών μπροστά
          στο καρνάγιο, σκάφη (τούρκων διακινητών?) τα οποία βυθίζονται και
          αποτελούν εστίες κινδύνων για την ναυσιπλοΐα και ρύπανσης της
          περιοχής, αφού ήδη έχει γίνει διαρροή πετρελαιοειδών στη θάλασσα.
          <br />
          Κατόπιν των ανωτέρω και επειδή ήδη έχουμε διαμαρτυρηθεί κατ επανάληψη
          για το θέμα της εγκατάλειψης σκαφών στην θαλάσσια περιοχή μας η οποία
          ως γνωστόν είναι μέσα στον κόλπο της Γέρας ο οποίος υπάγεται στο
          δίκτυο NATURA 2000 κωδικός GR4110005 και προστατεύετε από διεθνείς
          συνθήκες, ζητάμε λόγω αρμοδιότητας: 1ον Να ελεγχθεί άμεσα η κατάσταση
          που επικρατεί στην περιοχή και να ενημερωθούμε από ποιους και για
          ποιόν λόγο αυτές οι ενέργειες της εγκατάλειψης σκαφών τα οποία
          βυθίζονται επαναλαμβάνονται συνεχώς και
          <br />
          2ον Την άμεση απομάκρυνσή τους.
          <br />
          Κατόπιν των ανωτέρω ενημερώσεων και εφ όσον δεν ληφθούν εγκαίρως τα
          κατάλληλα μέτρα για την αντιμετώπιση των προβλημάτων και της ρύπανσης
          που αυτά δημιουργούν, ο Σύλλογός μας θα λάβει όλα τα κατάλληλα μέτρα
          που απαιτούνται για την αντιμετώπιση του προβλήματος προβαίνοντας
          ακόμη και σε μηνύσεις για παράβαση καθήκοντος όσων δημοσίων προσώπων
          εμπλέκονται και αδρανούν συστηματικά στην προαναφερθείσα υπόθεση.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          1ος Ποδηλατικός γύρος Κόλπου Γέρας
        </Typography>
        <img
          src={Image37}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image39}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
       
          Με την συμμετοχή <b>65</b> ποδηλατών διεξήχθη ο{" "}
          <b>1ος Ποδηλατικός γύρος του Κόλπου Γέρας</b> το Σάββατο 25 Ιουλίου
          2015 με αφετηρία την Κουντουρουδιά και τερματισμό το Πέραμα Γέρας.
          <br />
          Οι 65 ποδηλάτες που συμμετείχαν διήνυσαν την απόσταση των <b>
            28
          </b>{" "}
          χιλιομέτρων που χωρίζει την Κουντουρουδιά από το Πέραμα με αρκετή
          άνεση παρά την ζέστη με 35 βαθμούς Κελσίου και όλη η διαδρομή
          ολοκληρώθηκε σε <b>1</b> ώρα και <b>50</b> λεπτά περίπου.
          <br />
          Η τροχαία η οποία είχε ενημερωθεί σχετικά, βοήθησε πάρα πολύ καθ όλη
          την διάρκεια της διαδρομής, ρυθμίζοντας την κυκλοφορία όπου απαιτείτο
          και προστατεύοντας από κάθε απρόοπτο συμβάν τους συμμετέχοντες.
          <br />
          Η ιατρός κ. Ασημίνα Πανουσάκη ήταν παρούσα για να βοηθήσει για κάθε
          απρόοπτο ενδεχόμενο και το όχημα τύπου VΑΝ της LESVOS RIDE ακολουθούσε
          στο τέλος της ποδηλατοπορείας για να βοηθήσει και να μεταφέρει κάποια
          ποδήλατα που ενδεχομένως θα παρουσίαζαν μηχανικά προβλήματα.
          <br />
          Σε πολλά σημεία της όλης διαδρομής υπήρχαν εθελοντές που καθοδηγούσαν
          για την πορεία της διαδρομής και πρόσφεραν δροσερό νερό στους
          ποδηλάτες.
          <br />
          Στους συμμετέχοντες ποδηλάτες περιλαμβάνονταν και δύο ακόμη ιατροί, ο
          καρδιολόγος κ. Παναγιώτης Ανδρόνικος και η παθολόγος κ. Ελένη Λακκά.{" "}
          <br />
          Τις ευχαριστίες μας οφείλουμε εκτός από την τροχαία και την συνοδό
          ιατρό, σε όλους τους εθελοντές που συμμετείχαν, στους χορηγούς της
          εκδήλωσης, κ. Καρδαμήλα-Φωτεινό Super Market «ΓΕΡΑ», Ράδιο Αίολος,
          Προωθείν και στον κ. Ευστράτιο Καραντώνη, ο οποίος διέθεσε τη βάρκα
          του για τη δωρεάν μεταφορά των αθλητών και των ποδηλάτων τους από το
          Πέραμα στην Κουντουρουδιά και αντιστρόφως.
          <br />
          <br />
          Διοργανωτές της εκδήλωσης ήταν: <br />Ο «<b>Παγγεραγωτικός</b>»
          Πολιτιστικός Σύλλογος Γέρας,
          <br />ο Σύλλογος Μικρασιατών Σκάλας Λουτρών «<b>Το Δελφίνι</b>»<br />
          και οι Ποδηλάτες “<b>LesvosRide</b>”.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          4ος Κολυμβητικός διάπλους Μικρασιατικής Μνήμης
        </Typography>
        <img
          src={Image41}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image43}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
       
          Με εξαιρετικό καιρό και αθρόα συμμετοχή κολυμβητών και κολυμβητριών
          διεξήχθη ο <b>4ος Διάπλους Μικρασιατικής Μνήμης</b> την Κυριακή{" "}
          <b>26-7-2015</b>.<br />
          Οι <b>69</b> κολυμβητές και κολυμβήτριες που συμμετείχαν διήνυσαν την
          απόσταση των 1.100 μέτρων που χωρίζει την Κουντουρουδιά από το Πέραμα
          χωρίς προβλήματα και ο χρόνος που έκαναν κυμάνθηκε από 11 περίπου
          λεπτά ο πρώτος μέχρι 45 λεπτά ο τελευταίος.
          <br />
          Στην ασφαλή διεξαγωγή του κολυμβητικού διάπλου συνέβαλε αποφασιστικά,
          το πλωτό σκάφος του Λιμενικού Σώματος, το οποίο και επέβλεπε συνεχώς
          τους κολυμβητές. Τις ευχαριστίες μας οφείλουμε εκτός από το Λιμενικό
          Σώμα, στην ιατρό κ. Ασημίνα Πανουσάκη η οποία παρευρίσκετο καθ όλη την
          διάρκεια του Διάπλου για παν ενδεχόμενο, καθώς επίσης και στον κ.{" "}
          <b>Ευστράτιο Καραντώνη</b>, ο οποίος διέθεσε τη βάρκα του για τη
          δωρεάν μεταφορά των αθλητών και των συνοδών τους από την Κουντουρουδιά
          στο Πέραμα και αντιστρόφως. Τέλος, σε όλους τους συμμετέχοντες δόθηκαν
          αναμνηστικά διπλώματα και καπελάκια για τη συμμετοχή τους.
          <br />
          <br />
          Διοργανωτές της εκδήλωσης ήταν: <br />Ο «<b>Παγγεραγωτικός</b>»
          Πολιτιστικός Σύλλογος Γέρας,
          <br />ο Σύλλογος Μικρασιατών Σκάλας Λουτρών «<b>Το Δελφίνι</b>»<br />
          και οι Ποδηλάτες “<b>LesvosRide</b>”.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Μικρασιάτικη εκδήλωση στις 9 Αυγούστου 2015
        </Typography>
        <img
          src={Image45}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image47}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
      
          Την Κυριακή 9 Αυγούστου 2015 στις 21.00 στο θερινό αμφιθέατρο της
          Σκάλας Λουτρών, πραγματοποιήθηκε η κεντρική εκδήλωση του Συλλόγου μας
          θέμα «
          <b>
            Το στόλισμα της νύφης μέσα από γαμήλια τραγούδια του δυτικού Πόντου,
            της Καππαδοκίας, των Παραλίων της Μικράς Ασίας και των προσφύγων της
            Σκάλας Λουτρών
          </b>
          ».
          <br />
          Τα ο θερινό μας θεατράκι ξεχείλισε από τα μέλη και τους φίλους του
          Συλλόγου μας οι οποίοι στο τέλος καταχειροκρότησαν τους συμμετέχοντες
          στα δρώμενα.
          <br />
          Η ομάδα γυναικών που συμμετείχε είχε προετοιμαστεί πολύ καλά αφού οι
          δοκιμές και οι πρόβες είχαν ξεκινήσει από τις αρχές του έτους.
          Σημαντική η συμβολή στις εκδηλώσεις του δασκάλου Σταύρου Βαλαχή ο
          οποίος είχε γράψει και επιμεληθεί τα κείμενα και τα βιντεάκια που
          προβλήθηκαν, της κ. Ευθαλίας Τουρλή η οποία όλο το διάστημα ήταν
          επικεφαλής στις πρόβες που γινόντουσαν στον αύλιο χώρο του Μουσείου,
          της Φλώρας Αντωνοπούλου που επιμελήθηκε το χορευτικό κομμάτι και του
          Δημήτρη Καρπούζη ο οποίος είχε την επιμέλεια της κάλυψης του μουσικού
          τμήματος της εκδήλωσης. Τέλος ένα μεγάλο ευχαριστώ για την παραχώρηση
          των παραδοσιακών στολών χωρίς καμιά απολύτως αμοιβή στην Φλώρα
          Αντωνοπούλου και στον Αργύρη Χατζημαλή, καθώς και σε όλους τους
          συμμετέχοντες καθ οιονδήποτε τρόπο στην εκδήλωση.
          <br />
          Κατά την διάρκεια της εκδήλωσης ακούστηκαν από την χορωδία γυναικών
          της Σκάλας Λουτρών τα παρακάτω γαμήλια τραγούδια:
          <br />
          <br />
          <img
            src={Image72}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          /><br/><br/>
          ΄΄Σήμερα γάμος γίνεται΄΄ από τα Παράλια της Μικράς Ασίας
          <br />
          ΄΄Κοιμάτ’ αστρί΄΄ από Ινέπολις Πόντου <br />
          ΄΄Αυγερινός θε να γενώ΄΄από Κερασούντα Πόντου
          <br />
          ΄΄Επάρ σινιά ’΄΄ από Σύλατα- Καππαδοκίας
          <br />
          ΄΄Φόρια- φοριά ’΄΄ από Φάρασα- Καππαδοκίας
          <br />
          ΄΄Όμορφη που ‘σαι νύφη μας΄΄ από Αλάτσατα-Ερυθραίας
          <br />
          ΄΄Νύφη μου το φουστάνι σου΄΄ από Κελεμπέσι Σωκίων
          <br />
          ΄΄Άκουγε του γαμπρού μάνα΄΄ από Κερασούντα Πόντου
          <br />
          ΄΄Καλώς ήρτεν η νύφη μας΄΄ από Οινόη Πόντου
          <br />
          ΄΄Έλα, ας Πολυχρονίσομε΄΄ από Οινόη Πόντου
          <br />
          ΄΄Φαρλάτισαν τα γόνατά μ’΄΄ από Μαλακοπή Καππαδοκίας
          <br />
          ΄΄ Μότα γλέχε΄΄ από Φάρασα – Καππαδοκίας
          <br />
          ΄΄Σήμερις είν’ η μέρα μας΄΄ από Σύλατα Καππαδοκίας
          <br />
          ΄΄Έρχομου κι ‘σύ κοιμάσαι΄΄ από Λιβίσι-Μάκρη
          <br />
          ΄΄Νύφη μου καλορίζισσα΄΄ από Φώκαιες
          <br />
          ΄΄Ποιος ήταν ο προξενητής΄΄ από Τσεσμές Ερυθραίας
          <br />
          ΄΄Άγιε μου Παντελέημονα΄΄ από Ρεΐς Ντερέ-Ερυθραίας
          <br />
          ΄΄Ώρα καλή να δώσει ο Θιος΄΄ από Σκάλα Λουτρών
          <br />
          ΄΄Γαμπρέ μου να την χαίρεσαι΄΄ από Αγία Παρασκευή Τσεσμέ Ερυθραίας
          <br />
          ΄΄Νύφη μου σου το φέραμε΄΄ από Ρεΐς Ντερέ-Ερυθραίας
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Βράβευση αριστούχων μαθητών Δημοτικής Κοινότητας Λουτρών.
        </Typography>
        <img
          src={Image51}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image53}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
         
          Στον αύλιο χώρο του Μουσείου Προσφυγικής Μνήμης πραγματοποιήθηκε, την
          Κυριακή <b>2-8-2015</b> το απόγευμα, με επιτυχία και αθρόα συμμετοχή,
          η εκδήλωση για την βράβευση των αριστούχων μαθητών της Δημοτικής
          Κοινότητας Λουτρών από τον Σύλλογο Μικρασιατών της Σκάλας Λουτρών «ΤΟ
          ΔΕΛΦΙΝΙ».
          <br />
          Στην αρχή της εκδήλωσης έγινε μια σύντομη ενημέρωση από τον πρόεδρο κ.
          Δημήτρη Παπαχρυσό, σχετική με τις τρέχουσες δραστηριότητες του
          Συλλόγου και εν συνεχεία ο καθηγητής κ. Στράτης Καραμάνης μας ανέπτυξε
          σε ομιλία του τον «
          <b>
            Ρόλο της οικογένειας και του σχολείου στη διαμόρφωση της
            προσωπικότητας του παιδιού
          </b>
          ». <br />
          Οι βραβεύσεις άρχισαν με τα παιδιά του Δημοτικού σχολείου που
          διακρίθηκαν στην ζωγραφική και στην έκθεση με θέμα το περιβάλλον, μια
          δραστηριότητα που επαναλαμβάνεται κάθε χρόνο με την συνεργασία του
          Συλλόγου και των δασκάλων του Δημοτικού Σχολείου Λουτρών στις 5
          Ιουνίου, που είναι η παγκόσμια ημέρα για το περιβάλλον.
          <br />
          Οι 4 πρώτες τάξεις διαγωνίσθηκαν στην ζωγραφική με θέμα «Πως
          φαντάζομαι το πάρκο των ονείρων μου» και οι 2 μεγαλύτερες τάξεις
          διαγωνίσθηκαν στην έκθεση με θέμα «Το μεγάλο πρόβλημα των σκουπιδιών
          και ο ρόλος της ανακύκλωσης».
          <br />
          <br />
          Βραβεύτηκαν:
          <br />
          Από την <b>Α! Τάξη</b> η Βασιλέλλη Μαρία και η Κρανά Στέλλα.
          <br />
          Από την <b>Β! Τάξη</b> ο Κέντσης Στράτος και η Μερούκο Φεσιόνα.
          <br />
          Από την <b>Γ! Τάξη</b> η Τρουλινού Κατερίνα και η Γκίζα Γεωργία.{" "}
          <br />
          Από την <b>Δ! Τάξη</b> ο Λέλεκας Φίλιππος και η Πασβούρη Ελπίδα.
          <br />
          Από την <b>Ε! Τάξ</b>η η Κοζπή Σταυρούλα και η Ευαγγέλου Χριστίνα.
          <br />
          Από την <b> ΣΤ! Τάξη</b> ο Λέλεκας Κωνσταντίνος και η Δράκου Δήμητρα.
          <br />
          Στο σημείο αυτό, η θεατρολόγος και διδάκτωρ του Πανεπιστημίου Αιγαίου
          κ. <b>Νάσια Δαφιώτη</b>, μας διάβασε τις εκθέσεις των παιδιών που
          διακρίθηκαν και στη συνέχεια διασκέδασε τους μικρούς μας φίλους με ένα
          σύντομο θεατρικό παιχνίδι.
          <br />
          Η εκδήλωση συνεχίστηκε με την βράβευση των αριστούχων από το Γυμνάσιο
          και το Λύκειο.
          <br />
          <br />
          Βραβεύτηκαν:
          <br />
          Σφακιανού Γαρυφαλιά του Γεωργίου από την <b>Α!</b> γυμνασίου
          <br />
          Κοζπή Ταξιαρχούλα του Νικολάου από την <b> Α!</b> γυμνασίου
          <br />
          Κέντσης Μαρίνος του Νικολάου από την <b> Α! </b> γυμνασίου
          <br />
          Ζούρου Χριστίνα του Κομνηνού από την <b>Α!</b> γυμνασίου <br />
          Γκίζα Κωνσταντίνα του Βασιλείου από την <b> Α!</b> γυμνασίου
          <br />
          Καλιοντζής Θεόδωρος του Μιχαήλ από την <b> Α!</b> γυμνασίου
          <br />
          Ντίνια Χριστιάνα του Κυριάκου από την <b> Β!</b> γυμνασίου <br />
          Αχλάδα Μαρία του Ευστρατίου από την <b> Β!</b> γυμνασίου
          <br />
          Καραντώνης Μιχαήλ του Ευστρατίου από την <b> Β!</b> γυμνασίου <br />
          Νικολάρη Έρικα του Γεωργίου από την <b> Γ! </b> γυμνασίου
          <br />
          Λίγκος Χαράλαμπος του Αργύρη από την <b> Α!</b> λυκείου
          <br />
          Ταλιαντζής Ιωάννης του Σταύρου από την <b> Α!</b> λυκείου
          <br />
          Δράκου Χαρίκλεια του Δημητρίου από την <b> Β!</b> λυκείου
          <br />
          Ζαραδούκα Δωροθέα του Νικολάου από την Γ! λυκείου
          <br />
          <br />Η εκδήλωση έκλεισε με την δασκάλα <b>Αθηνά Πολυχρόνη</b>, η
          οποία μας παρουσίασε την δραστηριότητα των μαθητών της σχετική με τα
          δικαιώματα των παιδιών και τον σεβασμό στο διαφορετικό, όπου κι αν
          αυτό εντοπίζεται.
          <br />
          Οι μαθητές της, με αφορμή τη συμμετοχή τους στο πρόγραμμα της{" "}
          <b>Unicef</b> με τίτλο «<b>Σχολεία υπερασπιστές των παιδιών</b>» αλλά
          και στο ευρωπαϊκό πρόγραμμα «Teachers4Europe», υιοθέτησαν το λογότυπο
          «<b>Από την Ανατολή στη Δύση, όλοι Διαφορετικοί, όλοι Ίσοι</b>» και
          προσπάθησαν μέσα από τη δημιουργία βίντεο και αφισών να διαδώσουν το
          μήνυμα του σεβασμού στο «Άλλο» και της αποφυγής κρουσμάτων βίας
          ανάμεσα στους ανθρώπους και ιδιαίτερα στους νέους. Η εργασία αυτή και
          ειδικότερα το βίντεο των παιδιών με αφορμή την παγκόσμια ημέρα κατά
          της ενδοσχολικής βίας, επιλέχθηκε και φιλοξενήθηκε στη σελίδα της
          Unicef στο facebook, σημείωσε πάνω από 100.000 προβολές και για τον
          ίδιο λόγο η εργασία αυτή βραβεύτηκε τον παρελθόντα Μάρτιο από το
          Δημοτικό συμβούλιο Λέσβου.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Λειτουργία στην Παναγιά την Αψηλή
        </Typography>
        <img
          src={Image55}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Την Κυριακή 23 Αυγούστου 2015 στις 08.00 το πρωί που εορτάζονται τα
          εννιάμερα της Παναγίας ετελέσθη θεία λειτουργία με αρτοκλασία και
          επιμνημόσυνη δέηση υπέρ αναπαύσεως των ψυχών των Μικρασιατών προσφύγων
          στην Παναγιά την Αψηλή, με την παρουσία πολλών κατοίκων από την Σκάλα
          Λουτρών, τα Λουτρά και το Πέραμα της Γέρας. Αυτό είναι ένα έθιμο που
          επαναλαμβάνετε κάθε χρόνο την ίδια ημέρα και δίνει την δυνατότητα
          στους συμμετέχοντες εκτός από τα θρησκευτικά τους καθήκοντα να
          απολαύσουν και την υπέροχη θέα του κόλπου με τα χωριά της Γέρας. Εδώ
          υπενθυμίζουμε ότι ο Σύλλογός μας, συμμετέχοντας στο βαρύ πένθος της
          οικογενείας του αδικοχαμένου πριν λίγες ημέρες νεαρού συγχωριανού μας
          ψαροτουφεκά δεν πραγματοποίησε τις σχετικές εκδηλώσεις μνήμης που
          είχαν προγραμματισθεί για την παραμονή 22 Αυγούστου στην αυλή του
          «Μουσείου Προσφυγικής Μνήμης 1922.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Θερινή Λαχειοφόρος αγορά του Συλλόγου
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Στις 22 Αυγούστου στις 8 το βράδυ στον χώρο του Μουσείου Προσφυγικής
          Μνήμης 1922 πραγματοποιήθηκε η κλήρωση της λαχειοφόρου αγοράς του
          Συλλόγου μας και κέρδισαν οι παρακάτω αριθμοί:
          <br />
          <br />
          1oς Λαχνός ο αριθμός <b>970</b> κερδίζει το ποδήλατο.
          <br />
          2ος Λαχνός ο αριθμός <b>867</b> κερδίζει το tablet p/c. <br />
          3ος Λαχνός ο αριθμός <b>1241</b> κερδίζει το ατμοσίδερο.
          <br />
          4ος Λαχνός ο αριθμός <b>367</b> κερδίζει το ρολόι unisex.
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εκδήλωση μουσικού τμήματος
        </Typography>
        <img
          src={Image57}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image59}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
      
          Με επιτυχία πραγματοποιήθηκε την Κυριακή <b>27-9-2015</b> το απόγευμα
          στην αυλή του Μουσείου Προσφυγικής Μνήμης, η ετήσια εκδήλωση του
          μουσικού τμήματος του Συλλόγου Μικρασιατών της Σκάλας Λουτρών «
          <b>ΤΟ ΔΕΛΦΙΝΙ</b>».
          <br />
          Την εκδήλωση, που ήταν και η τελευταία του καλοκαιριού, παρακολούθησαν
          με μεγάλο ενδιαφέρον οι γονείς των παιδιών και πολλά μέλη του Συλλόγου
          οι οποίοι είχαν τη δυνατότητα να παρατηρήσουν από κοντά την πρόοδο των
          παιδιών τους στη μουσική και να τα καμαρώσουν γι αυτό.
          <br />
          Στο μουσικό τμήμα του Συλλόγου συμμετέχουν αυτή τη στιγμή 20 παιδιά
          ηλικίας από 6 έως 17 ετών και τα μαθήματα για όσους ενδεχομένως
          ενδιαφέρονται γίνονται στην αίθουσα του Συλλόγου στη Σκάλα Λουτρών
          κάθε Σάββατο.
          <br />
          Στην εκδήλωση έπαιξαν κιθάρα και τραγούδησαν οι παρακάτω μαθητές και
          μαθήτριες.
          <br />
          <br />
          Αλεξίου Γιώργος, Αλεξίου Μαρία, Αραμπατζής Μίλτος, Αραμπατζή
          Παναγιώτα, Βουκαλέλλης Νίκος, Γκίζα Γεωργία, Γκίζα Κωνσταντίνα, Δράκου
          Δήμητρα, Ευαγγέλου Χριστίνα, Κανέλος Άγγελος, Κέντζης Μαρίνος,
          Κουκώνης Ιωάννης, Κουκώνης Ραφαήλ, Λέλεκας Κωνσταντίνος, Λιαδέλλη
          Ντορέτα, Ντηνιακός Μαρίνος, Παλαμουτσή Αναστασία, Σπανός Χαράλαμπος
          και Τζωρτζάκος Βασίλης.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ημέρα μνήμης της γενοκτονίας των Ελλήνων της Μικράς Ασίας
        </Typography>
        <img
          src={Image61}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Σύμφωνα με τις διατάξεις του Προεδρικού Διατάγματος 304/2001, η{" "}
          <b>14η Σεπτεμβρίου</b> ορίζεται ως ημέρα Εθνικής μνήμης της
          γενοκτονίας των Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος. Η
          επέτειος μνήμης έχει σκοπό να τονίσει ιδιαίτερα και να υπενθυμίσει τα
          γεγονότα της δραματικής περιόδου της γενοκτονίας και του ξεριζωμού των
          Ελλήνων της Μικράς Ασίας από το Τουρκικό κράτος, που είχαν ως
          αποτέλεσμα να μετατρέψουν τους Έλληνες της Μικράς Ασίας σε έναν λαό
          προσφύγων της διασποράς. <br />
          Το πρόγραμμα εκδηλώσεων αυτής της επετείου μνήμης ειδικά για αυτή την
          χρονιά πραγματοποιήθηκε την Κυριακή 4 Οκτωβρίου και περιελάμβανε:
          <br />
          Τέλεση δοξολογίας και αρτοκλασίας χοροστατούντος του Μητροπολίτου
          Λέσβου κ.κ. Ιακώβου στον Ιερό Ναό Αγίου Νικολάου στην Επάνω Σκάλα,
          σχετική ομιλία από τον κ. Μάκη Βενέτα, επιμνημόσυνη δέηση και κατάθεση
          στεφάνων στο άγαλμα της Μικρασιάτισσας μάνας με την συμμετοχή της
          στρατιωτικής μουσικής της 98 ΑΔΤΕ από, τον εκπρόσωπο της κυβέρνησης,
          την Περιφερειάρχη Βορείου Αιγαίου, τον Στρατιωτικό Διοικητή, το
          Δήμαρχο Λέσβου, τους εκπροσώπους πολιτικών κομμάτων, αντιπροσωπίες
          απόστρατων αξιωματικών, εκπροσώπους εφεδροπολεμικών οργανώσεων, καθώς
          και εκπροσώπους νομικών προσώπων και Συλλόγων.
          <br />
          Το Δ.Σ. του Συλλόγου μας παρευρέθη σύσσωμο στις προαναφερθείσες
          εκδηλώσεις μνήμης, με κατάθεση στεφάνου από τον πρόεδρό του κ. Δημήτρη
          Παπαχρυσό.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Α΄ Πανελλήνια συνάντηση εκπροσώπων Συλλόγων με καταγωγή από τις
          Φώκαιες (Παλαιά & Νέα) της Μ. Ασίας
        </Typography>
        <img
          src={Image63}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <img
          src={Image65}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
       
          Στις <b>17/10/15</b> πραγματοποιήθηκε με πολύ μεγάλη επιτυχία στην Νέα
          Φώκαια Κασσάνδρας Χαλκιδικής η Α΄ Πανελλήνια συνάντηση εκπροσώπων των
          συλλόγων από διάφορες περιοχές της πατρίδας μας που ζουν Έλληνες
          Μικρασιάτες και έχουν καταγωγή από τις Φώκαιες (Παλαιά & Νέα) της Μ.
          Ασίας. Στην ιστορική αυτή συνάντηση η συγκίνηση και ο ενθουσιασμός
          πλημμύρισαν την αίθουσα του Πολιτιστικού Συλλόγου.
          <br />
          <br />
          Η συμμετοχή ξεπέρασε κάθε προσδοκία. Το πανέμορφο παραθαλάσσιο χωριό
          των 2000 περίπου Φωκιανών της Νέας Φώκαιας με το καλοδιατηρημένο
          βυζαντινό πύργο του Αγίου Παύλου, το γραφικό λιμανάκι, με τις καθαρές
          παραλίες & την καθαρή θάλασσα άνοιξε την αγκαλιά του & δέχτηκε τα
          αδέλφια του από τις 4 γωνιές της Ελλάδας, σπάζοντας 93 χρόνων σιωπή &
          αποκαθιστώντας την επικοινωνία μεταξύ τους. Στην εκδήλωση παραβρέθηκαν
          Φωκιανοί & από Θεσσαλονίκη, Βόλο, Αθήνα κλπ μιας και είχε δοθεί μεγάλη
          δημοσιότητα από τους συντελεστές. <br />
          <br />
          Η Φώκαια ήταν απ’ τις πιο μικρές πόλεις της Ιωνίας αλλά η ποιο
          δραστήρια στο εμπόριο και τον αποικισμό. Αφού ίδρυσε τη Λάμψακο στον
          Ελλήσποντο, στο τέλος του 7ου αιώνα στράφηκε μόνη αυτή απ’ όλες τις
          ιωνικές πόλεις προς τη Δυτική Μεσόγειο, ιδρύοντας ονομαστές αποικίες
          όπως τη Μασσαλία στη Γαλλία, το Εμπόριον στην Ισπανία, την Ελέα στην
          Ιταλία. Συνολικά 24 πόλεις. Επίσης για τα γεγονότα των σφαγών το 1914
          που σημειώθηκαν από τους Τούρκους σε όλη την Ιωνία , τα περισσότερα σε
          αριθμό ντοκουμέντα (γραπτά, αλλά και φωτογραφικά, πράγμα πολύ σπάνιο)
          υπάρχουν για την περιοχή στις Φώκαιες. Τα ντοκουμέντα αυτά αποτελούν
          την βάση για να διεκδικήσουμε εμείς οι Φωκιανοί & όχι μόνον το
          δικαίωμα μας στην Μνήμη. Σαν αποτέλεσμα της ιστορικής πρώτης
          συνάντησης αποφασίσθηκε κατ΄ αρχήν η δημιουργία ενός Πανελλήνιου
          Συντονιστικού Οργάνου των Φωκαϊκών Συλλόγων για περαιτέρω δράσεις /
          εκδηλώσεις. Στην συνάντηση απηύθυναν χαιρετισμό οι : <br />
          <br />
          ΝΙΚΟΛΑΟΣ ΧΟΡΜΠΟΣ (Α΄ Αντιπρόεδρος Ιωνικής Εστίας Θεσσαλονίκης,
          ιστορικός, ερευνητής της ιστορίας των Φωκαέων, τον χαιρετισμό διάβασε
          η κόρη του Εύη), ΠΑΝΤΖΙΚΗΣ ΠΑΥΛΟΣ (Πρόεδρος Πολιτιστικής & Λαογραφικής
          Ομάδας Ν. Φώκαιας Χαλκιδικής), ΒΑΣΙΛΕΙΟΣ ΚΥΡΙΤΣΗΣ (Δήμαρχος Ν.
          Κασσάνδρας, τον χαιρετισμό διάβασε η κ. Μαρία Παπαδημητρίου, Δημ.
          Σύμβουλος Ν. Φώκαιας στον Δήμο Ν. Κασσάνδρας), ΦΙΛΙΑ ΣΚΟΥΛΟΥΔΗ
          (Πρόεδρος Δημοτικού Διαμερίσματος Ν. Φώκαιας Χαλκιδικής), Πατήρ
          ΙΩΑΝΝΗΣ ΚΟΣΚΙΝΑΣ (Ενορία Ν. Φώκαιας Χαλκιδικής), ΜΑΡΙΑ ΠΑΠΑΔΗΜΗΤΡΙΟΥ
          (Δημοτική Σύμβουλος Ν. Φώκαιας στον Δήμο Ν. Κασσάνδρας), ΜΑΡΟΥΛΑ
          ΧΑΡΑΡΑ (Εκπαιδευτικός), ΙΩΑΝΝΗΣ ΒΕΛΕΝΤΖΑΣ (Πρόεδρος του Συνδέσμου των
          εν Ελλάδι Νεοφωκαέων Προσφύγων Χαϊδαρίου, τον χαιρετισμό διάβασε ο κ.
          Γιάννης Κιουλέκας, μέλος του συλλόγου)
          <br />
          <br />H αντιπροσωπεία των Φωκιανών από την υπόλοιπη Ελλάδα αποτελείτο
          από τους: ΠΑΡΑΣΚΕΥΑΣ ΣΥΡΙΑΝΟΓΛΟΥ (Πρ. Πρόεδρος συλλόγου Μικρασιατών
          Ρεθύμνου, Συγγραφέας, Ιστορικός), ΑΘΑΝΑΣΙΟΣ ΠΑΠΟΥΤΣΗΣ (Πρόεδρος
          Κοινότητας Παλαιάς Φώκαιας Αναβύσσου, Αττική), ΔΗΜΗΤΡΗΣ ΠΑΠΑΧΡΥΣΟΣ
          (Πρόεδρος Συλλόγου Μικρασιατών ‘’Το Δελφίνι΄΄ από την Σκάλα Λουτρών
          Μυτιλήνης), ΙΩΑΝΝΗΣ ΚΙΟΥΛΕΚΑΣ (Εκπρόσωπος του Συνδέσμου Νεοφωκαέων
          Προσφύγων, Χαιδαρίου).
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Συνέδριο κέντρου Μικρασιατικού Πολιτισμού Δήμου Νέας Ιωνίας
        </Typography>
        <img
          src={Image67}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το <b>Κέντρο Σπουδής και Ανάδειξης Μικρασιατικού Πολιτισμού</b> (
          ΚΕ.ΜΙ.ΠΟ. ), είναι Νομικό Πρόσωπο Δημοσίου Δικαίου ( Ν.Π.Δ.Δ. ) του
          Δήμου Νέας Ιωνίας Αττικής και ιδρύθηκε το 2003.
          <br />
          Σκοπός του ΚΕ.ΜΙ.ΠΟ. είναι, η περισυλλογή, διάσωση, διατήρηση και
          μετάδοση των στοιχείων της Ιστορίας και του Πολιτισμού των Ελλήνων της
          καθ’ ημάς Ανατολής και ειδικότερα της Μικράς Ασίας, του Πόντου, της
          Ανατολικής Θράκης, της Κωνσταντινουπόλεως και η συλλογή ταξινόμηση,
          μελέτη και αξιοποίηση του αρχειακού υλικού που αφορά τη Νέα Ιωνία. Για
          την εξυπηρέτηση των σκοπών αυτών οργανώνει ειδικές επιτροπές, για τη
          συλλογή στοιχείων και ντοκουμέντων, τη λειτουργία Βιβλιοθήκης και
          Μουσείων, την οργάνωση συνεδρίων και εκδηλώσεων, την πραγματοποίηση
          εκπαιδευτικών προγραμμάτων που απευθύνονται σε μαθητές και νέους, τις
          εκδόσεις συναφών έργων, την επιστημονική έρευνα σε θέματα θρησκείας,
          γλώσσας, ηθών και εθίμων, παραδόσεων, γραπτών μνημείων, έργων τέχνης,
          ναών και οικιών και γενικώς κάθε στοιχείου που αφορά στον πολιτισμό
          του Ελληνισμού που έζησε ως το 1922 στα μέρη εκείνα. <br />
          Το ΚΕ.ΜΙ.ΠΟ. στεγάζεται σε αναπαλαιωμένα κτίρια παλαιών εργοστασίων,
          όπου και έχει αναπτύξει τα Μουσεία Ιστορίας, Λαογραφίας και
          Υφαντουργίας – Ταπητουργίας, τα οποία είναι ανοικτά καθημερινά από
          9:00 π.μ. έως 13:00 πλην Κυριακών και αργιών.
          <br />
          Στους χώρους αυτούς υποδέχεται τους επισκέπτες για ξενάγηση και
          ανταλλαγή απόψεων, για την αποδοχή δωρεών αντικειμένων, εγγράφων,
          φωτογραφιών και γενικώς ενθυμημάτων μουσειακού χαρακτήρα και την
          καταγραφή, μαγνητοφώνηση και μαγνητοσκόπηση μαρτυριών. <br />
          Το ΚΕ.ΜΙ.ΠΟ. έχει οργανώσει στους χώρους του Συνεδριακού Κέντρου του
          Δήμου Ν. Ιωνίας έως τώρα επτά (7) Συμπόσια ( 2004, 2005, 2007, 2009,
          2011, 2012 και 2015).
          <br />
          • Το 1ο Συμπόσιο είχε ως θέμα : « Ο Ελληνισμός της Μικράς Ασίας από
          την Αρχαιότητα έως τη Μεγάλη Έξοδο »<br />
          • Το 2ο Συμπόσιο είχε ως θέμα : « Παιδεία και Εκπαίδευση στις
          Αλησμόνητες Πατρίδες της Ανατολής »<br />
          • Το 3ο Συμπόσιο είχε ως θέμα : « Τρεις χιλιετίες Μικρασιατικού
          Πολιτισμού : Επιστήμες – Γράμματα – Τέχνες »<br />
          • Το 4ο Συμπόσιο είχε ως θέμα : «Πολιτικοί, κοινωνικοί, οικονομικοί
          θεσμοί των Ελλήνων της Μ. Ασίας, κατά τους νεότερους χρόνους».
          <br />
          • Το 5ο Συμπόσιο είχε ως θέμα: « Η Συμβολή των προσφύγων στην
          Πολιτική, Πολιτιστική & Οικονομική ανάπτυξη της Ελλάδας»
          <br />
          • Το 6ο Συμπόσιο είχε ως θέμα: «Μικρασιατικός Ελληνισμός: Παρελθόν,
          Παρόν & Μέλλον»
          <br />
          • Το τελευταίο, 7ο Συμπόσιο είχε ως θέμα: «Η θέση και ο ρόλος της
          Μικρασιάτισσας <br />
          κατά τους νεότερους χρόνους, έως το 1922», πραγματοποιήθηκε στις 27,
          28 και 29 Νοεμβρίου 2015 και ο Σύλλογός μας εκπροσωπήθηκε σ αυτό από
          τον πρόεδρό του κ. Δημήτρη Παπαχρυσό.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Μουσειακές διαδρομές: περιήγηση στην ιστορία και τον πολιτισμό της
          Λέσβου
        </Typography>
        <img
          src={Image68}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ολοκληρώθηκε το πρόγραμμα στο οποίο συμμετέχει ο Σύλλογός μας με το
          «Μουσείου Προσφυγικής Μνήμης 1922» στο δίκτυο «Μουσειακές διαδρομές:
          περιήγηση στην ιστορία και τον πολιτισμό της Λέσβου» το οποίο
          υλοποιείτε από τον Δήμο Λέσβου και είναι ενταγμένο στο «Ολοκληρωμένο
          Σχέδιο Ανάπτυξης Περιοχών Υπαίθρου (Ο.Σ.Α.Π.Υ.) Δήμου Λέσβου –
          Ύπαιθρος Χώρα και Πολιτισμός Τουρισμός» και συγκεκριμένα στο υποέργο
          «Μουσειακές διαδρομές: περιήγηση στην ιστορία και τον πολιτισμό της
          Λέσβου».
          <br />
          <br />
          Τα Μουσεία που συμμετέχουν στη δράση είναι:
          <br />
          1. Αναγνωστήριο Αγιάσου <br />
          2. Μουσείο Προσφυγικής Μνήμης 1922 Σκάλας Λουτρών
          <br />
          3. Λαογραφικό Μουσείο Βατούσας
          <br />
          4. Λαογραφικό Μουσείο Πλωμαρίου
          <br />
          5. Λαογραφικό Μουσείο Ρητίνης Αμπελικού
          <br />
          6. Μουσείο Φυσικής Ιστορίας Απολιθωμένου Δάσους Λέσβου
          <br />
          <br />
          <br />
          <br />
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Εορτή Αγίου Νικολάου στη Σκάλα Λουτρών
        </Typography>
        <img
          src={Image70}
          style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Στο παρεκκλήσιο του Αγίου Νικολάου της Σκάλας Λουτρών,
          πραγματοποιήθηκαν με μεγάλη λαμπρότητα και με την συμμετοχή των μελών
          του Συλλόγου, πολλών πιστών από την Σκάλα Λουτρών, τα Λουτρά, την
          Κουντουρουδιά και τη Μυτιλήνη, οι καθιερωμένες θρησκευτικές εκδηλώσεις
          για την εορτή του Αγίου Νικολάου, προστάτη των ναυτικών και των
          αλιέων. Συγκεκριμένα, ετελέσθη η Θεία Λειτουργία και στη συνέχεια
          έγινε η λιτάνευση της εικόνας του Αγίου Νικολάου, φερμένη το 1922 από
          τον Τσεσμέ της Μ. Ασίας, παρουσία στρατιωτικού αγήματος από την μονάδα
          καταδρομέων του Περάματος, με κατεύθυνση την νέα προβλήτα του λιμανιού
          της Σκάλας Λουτρών.
          <br />
          <br />
          <br />
          <img
            src={Image72}
            style={{
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              maxWidth: "100%",
              height: "auto",
              margin: "10px",
            }}
            alt="δελφίνι"
          />
          Εκεί, μετά την ευλογία των άρτων, ο ιερέας και το στρατιωτικό άγημα
          επιβιβάσθηκαν στη βάρκα του κ. Ευστρατίου Καραντώνη από την
          Κουντουρουδιά και κρατώντας την εικόνα του Αγίου την περιέφεραν στον
          όρμο της Σκάλας Λουτρών, ρίχνοντας το στεφάνι που την πλαισίωνε στην
          θάλασσα και ευχόμενοι ήρεμα ταξίδια στους ναυτικούς και τους αλιείς
          μας. Μετά το πέρας των θρησκευτικών εκδηλώσεων όλοι οι παριστάμενοι
          προσκλήθηκαν στην Βιβλιοθήκη του Συλλόγου όπου τους προσφέρθηκαν
          διάφορα εδέσματα, αναψυκτικά και καφές, όλα φτιαγμένα από τις
          νοικοκυρές του οικισμού μας. <br />
          Και του χρόνου με υγεία.
          <br />
          <br />
          <br />
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Δωρεά βιβλίων στο Σύλλογό μας.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε για τα βιβλία που μας πρόσφεραν οι Κατερίνα Ράντη,
          Θωμαίδης Ελευθέριος, Τούλα και Μαρικούλα Κινικλή, Ανδρονίκη Παπαχρυσού
          και πολλοί άλλοι.
          <br /> Τους ευχαριστούμε θερμά όλους και ανακοινώνουμε ότι όλα αυτά
          είναι ήδη διαθέσιμα για δανεισμό από το κτίριο της βιβλιοθήκης του
          Συλλόγου μας στη Σκάλα Λουτρών, η οποία αυτή την στιγμή διαθέτει πλέον
          των 7.000 τίτλων βιβλίων διαφόρων κατηγοριών τα οποία είναι
          καταχωρημένα σε ειδικό πρόγραμμα ηλεκτρονικού υπολογιστή για την
          εύκολη αναζήτησή τους.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Μουσικό τμήμα.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Λειτουργεί κάθε Σάββατο από τις 1 το μεσημέρι μέχρι τις 8 το βράδυ και
          αυτή την περίοδο φοιτούν 22 παιδιά σχολικής ηλικίας τα οποία μαθαίνουν
          κιθάρα. <br />
          Τα μαθήματα εκτός από πρακτικά είναι και θεωρητικά έτσι ώστε τα παιδιά
          να αποκτούν γερές βάσεις και να συνεχίσουν μελλοντικά τις σπουδές τους
          στην μουσική εφ όσον το επιθυμήσουν. Η μηνιαία συνδρομή είναι 20 ευρώ
          και όσοι ενδιαφέρονται για τα παιδιά τους μπορούν να έρθουν σε
          επικοινωνία με τον πρόεδρο του Συλλόγου κ. Δημήτρη Παπαχρυσό στο τηλ.
          69746.40419 για περισσότερες πληροφορίες. <br />
          Δάσκαλος είναι ο κ. Μολυβιάτης Γρηγόρης ο οποίος επί πολλά χρόνια έχει
          προσφέρει εξαιρετικό έργο.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Νέα τμήματα
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Σύλλογός μας μπορεί να ιδρύσει νέα τμήματα παραδοσιακών μουσικών
          οργάνων όπως π.χ. βιολί, λαούτο κτλ εφ όσον εκδηλωθεί σχετικό
          ενδιαφέρον από τα μέλη μας. Διευκρινίζουμε ότι για να λειτουργήσει ένα
          νέο τμήμα θα πρέπει να ενδιαφερθούν κατ ελάχιστο τρία άτομα.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τμήματα παραδοσιακών τραγουδιών και χορών.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Αυτά τα τμήματα λειτουργούν περιστασιακά και πλαισιώνουν διάφορες
          εκδηλώσεις του Συλλόγου μας που έχουν σχέση με τις αλησμόνητες
          πατρίδες της Μ. Ασίας. Τα μαθήματα είναι δωρεάν και όσοι ενδιαφέρονται
          μικροί και μεγάλοι μπορούν να έλθουν σε συνεννόηση με την κ. Ευθαλία
          Τουρλή στο τηλ. 22510 91277.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Τμήμα βιβλιοθήκης
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Με γοργό ρυθμό εμπλουτίζετε η βιβλιοθήκη του Συλλόγου μας και υπάρχει
          αρκετό ενδιαφέρον από τα μέλη μας που την επισκέπτονται για να
          δανειστούν διάφορα βιβλία. Κατά το τρέχον έτος προστέθηκαν πολλά νέα
          βιβλία, όλα δωρεές των μελών και των φίλων του Συλλόγου μας. Όλα τα
          βιβλία είναι καταχωρημένα σε ηλεκτρονικό υπολογιστή για την εύκολη
          διαχείριση τους.
          <br />
          Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει
          τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση
          είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα
          προβλήματα που αποτελούν τη ζωή του σύγχρονου ανθρώπου και τοποθετεί
          τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη
          διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και
          φανατισμούς.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Ευχαριστήρια.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους παρακάτω.
          <br />
          *Τα διάφορα συνεργεία που κάνουν ειδικές τιμές για τις εργασίες
          συντήρησης των κτιρίων του Συλλόγου, τον Άρη Κερεντζή ο οποίος
          φροντίζει τον κήπο του Μουσείου, καθώς και όσους προσφέρουν
          εθελοντικώς την εργασία τους.
          <br />
          *Όλους όσοι χάρισαν τα πολύτιμα κειμήλια που στεγάστηκαν στο Μουσείο
          μας.
          <br />
          *Ιδιαιτέρως ευχαριστούμε το συγχωριανό μας Δημήτρη Γρηγορέλλη που από
          ιδρύσεως του Συλλόγου, μας έχει παραχωρήσει αφιλοκερδώς το πατρικό του
          σπίτι στη Σκάλα Λουτρών για την στέγαση δραστηριοτήτων του Συλλόγου
          μας.
        </Typography>
      </Box>

      <ScrollToTopButton />

      <Box sx={{ ...commonBoxStyles }}>
        <Typography variant="h1" sx={theme.typography.typography1}>
          Οικονομικές ενισχύσεις.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Για τους σκοπούς του Συλλόγου μας προσφέρθηκαν τα παρακάτω ποσά.
          <br />
          <br />
          Δεσπότου Έλλη 250 ευρώ
          <br />Image072
          Κρητικός Σταύρος 100 ευρώ
          <br />
          Μηναίδου Μαρία 100 ευρώ
          <br />
          Κρητικός Σταύρος και Βίκυ 100 ευρώ
          <br />
          Λεωνιδοπούλου Μυρσίνη 100 ευρώ
          <br />
          Χατζησόγλου Δέσποινα 50 ευρώ
          <br />
          Παπαχρυσός Ιωάννης 50 ευρώ
          <br />
          Σύλλογος κυριών Μανταμάδου 20 ευρώ
          <br />
          Αψόκαρδος Ιγνάτιος 5 ευρώ
          <br />
          Σοφιανόπουλος Ανδρέας 5 ευρώ
          <br />
          Philip Stimaridis 10 ευρώ
          <br />
          Christian Kirchen 10ευρώ
          <br />
          Richard Youve 5 ευρώ
          <br />
          Sam Coulson 5 ευρώ
          <br />
          <br />
          Καλιοντζής Μιχάλης 100 ευρώ στη μνήμη του πατέρα του Ελευθερίου.
          <br />
          Καδής Μιχάλης 50 ευρώ στη μνήμη Ευαγγελίας Καδή και Γεωργίου Βατζάκα.
          <br />
          Τζαβίδη Ελπίδα 30 ευρώ στη μνήμη της αδελφής της Φρόσως.
          <br />
          Περγαμηνού Γαρουφαλιά 30 ευρώ στη μνήμη του συζύγου της Ευστρατίου.
          <br />
          <br />
          Το Δ.Σ. του Συλλόγου μας τους ευχαριστεί θερμά και καλεί τα μέλη του
          να ενισχύσουν οικονομικά την προσπάθεια που κάνει ο Σύλλογός για
          αναβάθμιση του οικισμού μας , έτσι ώστε να μπορέσουμε να
          ανταποκριθούμε στο βαρύ φορτίο των υποχρεώσεων που έχουμε αναλάβει.
          <br />
          Για καταθέσεις συνδρομών και ενισχύσεων στο Σύλλογο{" "}
          <b>
            Τράπεζα Πειραιώς Αριθμός Λογαριασμού <i>6355-010482-750</i> IBAN{" "}
            <i>GR94 0171 3550 0063 5501 0482 750</i>{" "}
          </b>
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default D12_drastiriotites_2015;
