import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/bibliothiki/bibliothiki.jpg";
import Image02 from "../assets/images/bibliothiki/library3.jpg";
import Image03 from "../assets/images/bibliothiki/library4.jpg";
import Image04 from "../assets/images/bibliothiki/bibliothiki_2015_02.jpg";
import Image05 from "../assets/images/bibliothiki/bibliothiki_2015_01.jpg";
import Image06 from "../assets/images/bibliothiki/library.jpg";

function D103_HBibliothikiMas() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="Η βιβλιοθήκη μας" />
      </Box>

      <Box sx={{ ...commonBoxStyles }}>
        <img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα χρειάζονται να
          μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας η οποία ήδη
          στεγάζεται στο καινούργιο κτίριο που κατασκευάσαμε στη Σκάλα Λουτρών.
        </Typography>

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το βιβλίο είναι ένα παράθυρο ανοιχτό, γιατί προσανατολίζει τη σκέψη σε
          νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση είναι
          δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα προβλήματα που
          αποτελούν τη ζωή του σύγχρονου ανθρώπου και τοποθετεί τις βάσεις για
          τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη διαμόρφωση
          ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και φανατισμούς
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Εγκαίνια στην βιβλιοθήκη «ΠΕΤΡΟΣ ΜΑΝΔΡΑΣ» του Συλλόγου μας.
        </Typography>
        <img src={Image02}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Πραγματοποιήθηκαν την Κυριακή 6 Ιουλίου 2008 τα εγκαίνια του νέου
          κτιρίου της Βιβλιοθήκης του Συλλόγου μας. Για όσους δεν γνωρίζουν αυτή
          βρίσκεται μέσα στο χώρο αθλοπαιδιών δίπλα στο γήπεδο μπάσκετ, ένα χώρο
          που μας έχει παραχωρήσει με συμβολικό ενοίκιο το Ίδρυμα Πέτρου Μάνδρα
          από το 1991. Οι εργασίες για την κατασκευή της άρχισαν το 2001 με την
          έκδοση της σχετικής οικοδομικής άδειας και την κατασκευή του σκελετού
          της οικοδομής, επιβραδύνθηκαν όμως μετά επειδή δώσαμε τότε
          προτεραιότητα στην ανακατασκευή και στην διαμόρφωση του χώρου του
          πρώην Δημοτικού σχολείου της Σκάλας Λουτρών το οποίο στέγασε το
          «Μουσείο Προσφυγικής Μνήμης 1922» με τα κειμήλια των προσφύγων
          κατοίκων του οικισμού μας. Μετά το πέρας των εργασιών και αφού
          αγοράσθηκε και τοποθετήθηκε ο σχετικός εξοπλισμός τοποθετήθηκαν και
          καταγράφηκαν σε ηλεκτρονικό υπολογιστή τα πλέον των 4000 βιβλία του
          Συλλόγου μας. Ο ηλεκτρονικός υπολογιστής δίνει την δυνατότητα της
          γρήγορης ανεύρεσης των βιβλίων που επιθυμεί ο κάθε επισκέπτης, αφού
          αυτά μπορούν να αναζητηθούν ποικιλοτρόπως. Εμείς εκείνο που έχουμε να
          προτείνουμε στα παιδιά είναι να διαβάζουν ανελλιπώς διότι το βιβλίο
          είναι ένα παράθυρο ανοιχτό στον κόσμο, προσανατολίζει την σκέψη σε
          νέους δρόμους, προσφέρει γνώσεις και πληροφορίες και η γνώση σήμερα γι
          αυτόν που την κατέχει είναι δύναμη. Επίσης η μόρφωση ευαισθητοποιεί το
          άτομο απέναντι στα προβλήματα που αποτελούν τη ζωή του σύγχρονου
          ανθρώπου και τοποθετεί τις βάσεις για τη συνολική πρόοδο του
          πολιτισμού, συντελεί δε στη διαμόρφωση ψύχραιμης και νηφάλιας σκέψης
          μακριά από ακρότητες και φανατισμούς. Το Δ.Σ. του Συλλόγου μας
          εκφράζει ένα μεγάλο ευχαριστώ σε όσους χάρισαν βιβλία για τον
          εμπλουτισμό της βιβλιοθήκης μας και επίσης ένα μεγάλο ευχαριστώ σε
          όσους βοήθησαν οικονομικά στην στήριξη αυτής της πολύχρονης
          προσπάθειας.{" "}
        </Typography>
        <img src={Image03}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

        <Typography variant="h3" sx={theme.typography.typography2}>
          Το κόψιμο της κορδέλας των εγκαινίων έγινε από τον πρόεδρο του
          Ιδρύματος «Πέτρος Μάνδρας» κ. Γεώργιο Πρίμο και για όσους δεν
          γνωρίζουν ο αείμνηστος Πέτρος Μάνδρας ήταν γιός του Βασιλείου Μάνδρα
          εγγονού του Χατζηγεωργάκη Μάνδρα. Γεννήθηκε το 1887, είχε δύο
          αδελφούς, τον Πάνο και τον Γιώργο και μια αδελφή την Πελαγία. Από τα
          αδέλφια του ο Πάνος ήταν ανύπανδρος, ο Γιώργος σπούδασε γιατρός στο
          Παρίσι, παντρεύτηκε αλλά σκοτώθηκε σε αυτοκινητιστικό ατύχημα και η
          αδελφή του η Πελαγία παντρεύτηκε τον κ. Γιώργο Κούμπα και έκανε τρία
          παιδιά, δύο κόρες και ένα γιό. Ο Πέτρος Μάνδρας αφού σπούδασε στο
          Παρίσι επέστρεψε στη Μυτιλήνη και διαχειριζόταν τη μεγάλη κτηματική
          του περιουσία χωρίς όμως να δημιουργήσει οικογένεια. ‘Ίδρυσε μαζί με
          τους συγγενείς του την εταιρεία Γρημάνης- Μάνδρας-Καμπούρης που έκανε
          ένα ελαιοτριβείο στα Λουτρά, (αυτό που έχει σήμερα ο Συνεταιρισμός
          Λουτρών), χρημάτισε Δήμαρχος Μυτιλήνης και πέθανε στο εξοχικό του στη
          Βαρειά το 1944 αφήνοντας μεγάλο μέρος της περιουσίας του για τους
          φτωχούς της τότε κοινότητας και σήμερα τοπικού διαμερίσματος Λουτρών,
          για χορήγηση υποτροφιών σε σπουδαστές των Α.Ε.Ι. καταγόμενους από τα
          Λουτρά και για ίδρυση παιδικού σταθμού. Τέλος δώρισε μια μεγάλη έκταση
          στην περιοχή Νερούτσικα σε φτωχές οικογένειες των Λουτρών.{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Στην τελετή των εγκαινίων παραβρέθηκαν και μας τίμησαν με την παρουσία
          τους ο Δήμαρχος Μυτιλήνης κ. Να σος Γιακαλής, ο πρόεδρος του τοπικού
          συμβουλίου Λουτρών κ. Μαμώλης Βασίλειος, η πρόεδρος του πολιτιστικού
          κέντρου Λουτρών κ. Τούλα Τατάκη-Κομνηνού, ο πρόεδρος του Συλλόγου
          Πελοπονησίων Λέσβου κ. Αντωνόπουλος Κώστας, ο πρόεδρος του Φ.Ο.Μ. κ.
          Περικλής Μαυρογιάννης, ο πρόεδρος του Παγγεραγωτικού κ. Ζορμπάς
          Γεώργιος, εκπρόσωπος του Λιμεναρχείου ο κ. Γρηγορέλλης Νίκος, ο
          παριστάμενος του Ο.Τ.Ε. κ. Λεοντής Γιώργος, η πρόεδρος της
          Πολιτιστικής εταιρίας Αιολίς κ. Ιφιγένεια Γεωργιάδου και πολλοί άλλοι.
          Μηνύματα μας έστειλαν και διαβάσαμε ο Νομάρχης Λέσβου κ. Παύλος
          Βογιατζής και ο βουλευτής Λέσβου κ. Γιαννέλης Ιωάννης.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          {" "}
          Δωρεά βιβλίων στο Σύλλογό μας το 2018
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους κ.κ. Αλεξέλλη Παναγιώτη, Κρητικό Γεώργιο,
          Βαλαχή Σταυρίτσα, Σάλτα Μαρία, Χατζηδήμο Αλέξανδρο, Σταματέλλη
          Αγγελική, Κεφαλίδη Δημήτρη, Πελεκάνο Θεόδωρο, Παπαχρυσό Γεώργιο και το
          ΚΕ.ΜΙ.ΠΟ. για τα βιβλία που πρόσφεραν στην βιβλιοθήκη του Συλλόγου μας
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          {" "}
          Δωρεά βιβλίων στο Σύλλογό μας το 2017
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          {" "}
          Ευχαριστούμε θερμά την Γ. Γραμματεία του Υπουργείου Αιγαίου και
          νησιωτικής πολιτικής, για τα βιβλία που πρόσφερε μετά από αίτημα μας
          στην βιβλιοθήκη του Συλλόγου μας. <br />
          Βιβλία για την βιβλιοθήκη μας πρόσφεραν επίσης ο κ. Εγγλέζος Θεοφάνης
          και ο κ. Χατζηδήμος Αλέξανδρος τους οποίους επίσης ευχαριστούμε.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          {" "}
          Δωρεά βιβλίων στο Σύλλογό μας το 2016
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά το ίδρυμα Παπαδημητρίου και ιδιαιτέρως τον πρόεδρό
          του κ. Νικόλαο Παπαδημητρίου για τα πολλά και εν- διαφέροντα βιβλία
          που πρόσφεραν στην βιβλιοθήκη του Συλλόγου μας.
          <br />
          Επίσης βιβλία μας πρόσφεραν η Ένωση Σμυρναίων, το Αναγνω- στήριο
          Αγιάσου, οι κ.κ. Μαντός Δημήτριος, Παυλάκης Μάριος, Θωμαίδης
          Ελευθέριος, Βαλαχής Σταύρος και πολλοί άλλοι. Τους ευχαριστούμε θερμά
          όλους και ανακοινώνουμε ότι όλα αυτά είναι ήδη διαθέσιμα για δανεισμό
          από το κτίριο της βιβλιοθήκης του Συλλόγου μας στη Σκάλα Λουτρών, η
          οποία αυτή την στιγμή διαθέτει πλέον των 6.500 τίτλων βιβλίων διαφόρων
          κατηγοριών τα οποία είναι καταχωρημένα σε ειδικό πρόγραμμα
          ηλεκτρονικού υπο- λογιστή για την εύκολη αναζήτησή τους.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          {" "}
          Δωρεά βιβλίων στο Σύλλογό μας το 2015
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε για τα βιβλία που μας πρόσφεραν οι Κατερίνα Ράντη,
          Θωμαίδης Ελευθέριος, Τούλα και Μαρικούλα Κινικλή, Ανδρονίκη Παπαχρυσού
          και πολλοί άλλοι. Τους ευχαριστούμε θερμά όλους και ανακοινώνουμε ότι
          όλα αυτά είναι ήδη διαθέσιμα για δανεισμό από το κτίριο της
          βιβλιοθήκης του Συλλόγου μας στη Σκάλα Λουτρών, η οποία αυτή την
          στιγμή διαθέτει πλέον των 7.000 τίτλων βιβλίων διαφόρων κατηγοριών τα
          οποία είναι καταχωρημένα σε ειδικό πρόγραμμα ηλεκτρονικού υπολογιστή
          για την εύκολη αναζήτησή τους.{" "}
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          {" "}
          Δωρεά βιβλίων στο Σύλλογό μας το 2014{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά το ίδρυμα Παπαδημητρίου και ιδιαιτέρως τον πρόεδρό
          του κ. Νικόλαο Παπαδημητρίου για τα πολλά (34 κιβώτια) και
          ενδιαφέροντα βιβλία που πρόσφεραν στην βιβλιοθήκη του Συλλόγου μας.
          Επίσης βιβλία μας πρόσφεραν η Ένωση Σμυρναίων, το Αναγνωστήριο
          Αγιάσσου, οι κ.κ. Μαντός Δημήτριος, Παυλάκης Μάριος, Θωμαίδης
          Ελευθέριος, Βαλαχής Σταύρος και πολλοί άλλοι.
          <br />
          Τους ευχαριστούμε θερμά όλους και ανακοινώνουμε ότι όλα αυτά είναι ήδη
          διαθέσιμα για δανεισμό από το κτίριο της βιβλιοθήκης του Συλλόγου μας
          στη Σκάλα Λουτρών, η οποία αυτή την στιγμή διαθέτει πλέον των 6.800
          τίτλων βιβλίων διαφόρων κατηγοριών τα οποία είναι καταχωρημένα σε
          ειδικό πρόγραμμα ηλεκτρονικού υπολογιστή για την εύκολη αναζήτησή
          τους.{" "}
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          {" "}
          Δωρεά βιβλίων στο Σύλλογό μας το 2013
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους κ.κ. Παυλάκη Μάριο, Πελεκάνο Θεόδωρο,
          Παπαχρυσό Γεώργιο, Αντωνόπουλο Κωνσταντίνο, Μαντό Δημήτριο, Δεληγιάννη
          Νικόλαο, Χλίμπο Παναγιώτη, Χατζηδήμο Αλέξανδρο, Πατανό Ιωάννη και Όλγα
          Ιωάννου – Βαλειάδη για τα βιβλία που προσέφεραν στη βιβλιοθήκη του
          Συλλόγου μας.
          <br />Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα
          χρειάζονται να μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας
          η οποία ήδη στεγάζεται στο καινούργιο κτίριο που κατασκευάσαμε στη
          Σκάλα Λουτρών και που αυτή τη στιγμή τα βιβλία που διαθέτει ξεπερνούν
          τις 6200.{" "}
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          {" "}
          Δωρεά βιβλίων στο Σύλλογό μας το 2012{" "}
        </Typography>
        <img src={Image04}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους κ.κ. Αλεξέλλη Παναγιώτη, Παυλάκη Μάριο,
          Κινικλή Μαρικούλα, Τασιοπούλου Παναγιώτα, Πελεκάνο Θεόδωρο, Λεκάκη
          Γιώργο, Παπάλα Προκόπη, Χατζηαναγνώστου Τάκη, Δαλαμάγκα Γιώργο, πάτερ
          Γιουσμά Αθανάσιο, Αναγνωστοπούλου Μαρία, Παπαχρυσό Γιώργο, Buonomo
          Joshef, Δούκα Μάκη , Σαράτση Νίκο , Ιωάννου - Βαλειάδου Όλγα, ΚΕΜΙΒΟ,
          Λεωνιδοπούλου Μυρσίνη, Ψωμαδέλλη Γιώργο, Αντωνόπουλο Κωνσταντίνο και
          Δρ. κ. Τσολακίδη Στέφανο από την εταιρεία ΚΛΕΟΣ ΑΕ για τα βιβλία που
          προσέφεραν στη βιβλιοθήκη του Συλλόγου μας.
          <br />Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα
          χρειάζονται να μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας
          η οποία ήδη στεγάζεται στο καινούργιο κτίριο που κατασκευάσαμε στη
          Σκάλα Λουτρών και που αυτή τη στιγμή τα βιβλία που διαθέτει ξεπερνούν
          τις 6000.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          {" "}
          Δωρεά βιβλίων στο Σύλλογό μας το 2011{" "}
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε ιδιαιτέρως το ίδρυμα Ν. Γ. Παπαδημητρίου για τα πολλά και
          εξαιρετικού ενδιαφέροντος βιβλία που μας χάρισε. Για την τακτοποίησή
          τους ο Σύλλογός μας έχει παραγγείλει έξτρα έπιπλο βιβλιοθήκης για να
          τοποθετηθούν. Επίσης ευχαριστούμε θερμά τους κ.κ. Μίσσιο Κώστα,
          Αλεξέλλη Παναγιώτη, Ντόρα Πολίτη-Λιόλιο, Μαρικούλα Κινικλή, Πελεκάνο
          Θεόδωρο, Μάκη Δούκα, Νίκο Σαράτση, Γιώργο Ζορμπά, Κρητικού Βούλα,
          Ντίνια Γεώργιο, Κούμπα Γεώργιο, Χιώτου Μυρσίνη και το WWF, για τα
          βιβλία που προσέφεραν στη βιβλιοθήκη του Συλλόγου μας.
          <br />Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα
          χρειάζονται να μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας
          η οποία ήδη στεγάζεται στο καινούργιο κτίριο που κατασκευάσαμε στη
          Σκάλα Λουτρών και που αυτή τη στιγμή τα βιβλία που διαθέτει ξεπερνούν
          τις 5600.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Δωρεά βιβλίων στο Σύλλογό μας το 2010
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε ιδιαιτέρως τον κ. Δημήτρη Νικορέτζο για τα πολλά και
          εξαιρετικού ενδιαφέροντος βιβλία που μας χάρισε.. Για την τακτοποίησή
          τους ο Σύλλογός μας έχει παραγγείλει έξτρα έπιπλο βιβλιοθήκης για να
          τοποθετηθούν. Επίσης ευχαριστούμε θερμά τους κ.κ. Πελεκάνο Θεόδωρο,
          Δελή - Αριστοτέλους Θεοδοσία, Τσίκνα Ιωάννη, Καλάργαλη Αριστείδη,
          Μπαιρακτάρη Στυλιανό, Θωμαίδη Ελευθέριο, Παπαχρυσό Γεώργιο, Χόρμπο
          Νικόλαο, Πρίμου Σούγιουλτζη Ευανθία, Πρίμου Ανίτα, Παλάζη Στάθη,
          Σταματέλλη Αγγελική, Σκορδά Παναγιώτη, Κάκο Βασίλη, Αραμπατζόγλου
          Βούλα, το WWF, τη Νομαρχία Λέσβου και την ΜΚΟ Σόλων, για τα βιβλία που
          προσέφεραν στη βιβλιοθήκη του Συλλόγου μας. Ο Σύλλογός μας παρακαλεί
          όσους έχουν βιβλία που δεν τα χρειάζονται να μας τα χαρίσουν για τον
          εμπλουτισμό της βιβλιοθήκης μας η οποία ήδη στεγάζεται στο καινούργιο
          κτίριο που κατασκευάσαμε στη Σκάλα Λουτρών και που αυτή τη στιγμή τα
          βιβλία που διαθέτει ξεπερνούν τις 5300.
          <br />
          Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει
          τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση
          είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα
          προβλήματα που αποτελούν τη ζωή του σύγχρονου ανθρώπου και τοποθετεί
          τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη
          διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και
          φανατισμούς.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Δωρεά βιβλίων στο Σύλλογό μας το 2009
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε ιδιαιτέρως τον κ. Βασίλη Βόμβα με την σύζυγό του Άντα για
          τα πολλά και εξαιρετικού ενδιαφέροντος βιβλία που μας χάρισαν και που
          υπερβαίνουν τα 500. Για την τακτοποίησή τους ο Σύλλογός μας έχει
          παραγγείλει έξτρα έπιπλο βιβλιοθήκης για να τοποθετηθούν. Επίσης
          ευχαριστούμε θερμά τους κ.κ. Κλήμη Αριστείδη , Αγιακάτσικα Παναγιώτη,
          Χατζηδήμο Αλέξανδρο, Πρίμο Γεώργιο, Μεσηνέζη Καίτη, Θωμαίδη Πλαστήρα,
          Σταματέλλη Αγγελική, Ταμβάκη Ανδρέα, Πολυχρονιάδη Θανάση, Σαμαρίδη
          Μαρία, Γονιδέλλη Γεώργιο, Γκαγκάνη Δέσποινα, Παπαχρυσού Αγγελική,
          Περγαμηνού Μαρία, Σκορδά Παναγιώτη, Λενέτα Στράνη, Ψαλτόπουλο Μιχάλη,
          τη Νομαρχία Λέσβου, το Σύλλογο Μεσοτοπιτών, το WWF, και τα τυπογραφεία
          Δημητρίου Δούκα και Γεωργίου Παπαχρυσού, για τα βιβλία που προσέφεραν
          στη βιβλιοθήκη του Συλλόγου μας.<br/><br/>
        
          <img src={Image05}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        /><br/><br/>
          Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα χρειάζονται να
          μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας η οποία ήδη
          στεγάζεται στο καινούργιο κτίριο που κατασκευάσαμε στη Σκάλα Λουτρών
          και που αυτή τη στιγμή τα βιβλία που διαθέτει ξεπερνούν τις 5000.
          <br />
          Το βιβλίο είναι ένα παράθυρο ανοιχτό στον κόσμο, γιατί προσανατολίζει
          τη σκέψη σε νέους δρόμους, προσφέρει γνώσεις, πληροφορίες και η γνώση
          είναι δύναμη. Η μόρφωση ευαισθητοποιεί το άτομο απέναντι στα
          προβλήματα που αποτελούν τη ζωή του σύγχρονου ανθρώπου και τοποθετεί
          τις βάσεις για τη συνολική πρόοδο του πολιτισμού, συντελεί δε, στη
          διαμόρφωση ψύχραιμης και νηφάλιας σκέψης μακριά από ακρότητες και
          φανατισμούς.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Δωρεά βιβλίων στο Σύλλογό μας το 2008.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε ιδιαιτέρως το βιβλιοπωλείο του κ. Νικολάου Σαράτση για τα
          πολλά βιβλία που χάρισε στην βιβλιοθήκη του Συλλόγου μας. Τα βιβλία
          αυτά είναι αχρησιμοποίητα και κυρίως παιδικής λογοτεχνίας. Επίσης
          ευχαριστούμε θερμά τους κ.κ. Αριστείδη Κλήμη, Θεόδωρο και Παναγιώτη
          Πελεκάνο, Γεώργιο Γονιδέλλη Ακίνδυνου, Σταυράκη Ευστρατία, Κινικλή
          Μαρικούλα, Χούσου – Τατά Νίκη, Βασιλειάδη Βασίλειο, Πρωτόπαπα Αγγέλα,
          Μολυβιάτη Ρούλα, Αγραφιώτου-Πρόκα Μυρσίνη, Χαρίλαο Μωραΐτη, το Κέντρο
          Μικρασιατικών Σπουδών, τη Νομαρχία Λέσβου, το Υπουργείο Αιγαίου, τα
          τυπογραφεία Δημητρίου Δούκα και Γεωργίου Παπαχρυσού για τα βιβλία που
          προσέφεραν στη βιβλιοθήκη του Συλλόγου μας.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Δωρεά βιβλίων στο Σύλλογό μας το 2007
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους κ.κ. Δέσποινα Λημναίου-Καρέκου, Μέλπω Αλμπάνη,
          Γεώργιο Πρίμο, Αριστείδη Κλήμη, Ιφιγένεια Γεωργιάδου, Βικτωρίτσα
          Ευαγγέλου, Αριστείδη Κουτζαμάνη, Θεόδωρο Πελεκάνο, Παντελή Τζανέτο,
          Γεώργιο Ντίνια, Νικόλαο Κατσαλή, Μαρία Καπαρού, Γονιδέλλη Ακίνδυνου,
          Αλέξανδρο Χατζηδήμο, τη Νομαρχία Λέσβου, το Υπουργείο Αιγαίου, και το
          τυπογραφείο Δημητρίου Δούκα για τα βιβλία που προσέφεραν στη
          βιβλιοθήκη του Συλλόγου μας.
        </Typography>

        <Typography variant="h1" sx={theme.typography.typography1}>
          Δωρεά βιβλίων στο Σύλλογό μας το 2006.
        </Typography>
        <img src={Image06}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ευχαριστούμε θερμά τους κ. Παν. Λευκαδίτη και Γεώργιο Λευκαδίτη
          (Susaeta Ελλάς), τον κ. Ιωάννη Παπαδάκη ,τον κ. Νικόλαο Μυρωδέλλη ,
          την κ. Νίκη Τατά –Χούσου, την Μέλπω Αλμπάνη, την κ. Ειρήνη Δουκάκη ,
          το ΠΑΝΔΟΙΚΟ και τον Νομάρχη Λέσβου κ. Παύλο Βογιατζή για τα βιβλία που
          προσέφεραν στη βιβλιοθήκη του Συλλόγου μας.
        </Typography>
        <Typography variant="h3" sx={theme.typography.typography2}>
          Ο Σύλλογός μας παρακαλεί όσους έχουν βιβλία που δεν τα χρειάζονται να
          μας τα χαρίσουν για τον εμπλουτισμό της βιβλιοθήκης μας η οποία ήδη
          στεγάζεται στο καινούργιο κτίριο που κατασκευάσαμε στη Σκάλα Λουτρών.
          <br />
          <br />
          <br />
          <br />
        </Typography>
      </Box>

      <ScrollToTopButton />
    </>
  );
}

export default D103_HBibliothikiMas;
