import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Header1 from "../components/Header1";
import HomeIcon from "@mui/icons-material/Home";
import { commonBoxStyles, headerBoxStyles } from "../components/CommonStyles";

import Image01 from "../assets/images/oikologika/26_image_01.jpg";


function F12_oikologika_12() {
  const theme = useTheme();

  return (
    <>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box sx={{ ...headerBoxStyles }}>
        <Header1 props="26o συνέδριο Πανελλήνιου Δικτύου Οικολογικών Οργανώσεων" />
      </Box>


      
      <Box sx={{ ...commonBoxStyles }}>
	
      <Typography variant="h1" sx={theme.typography.typography1}>11-13 Νοεμβρίου 2016, Κόρινθος.</Typography>
      <Typography variant="h1" sx={theme.typography.typography1}>Θέμα: «Περιβάλλον και πλαστικό. Η πλαστική σακούλα»</Typography>
		
	<img src={Image01}  style={{
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            maxWidth: "100%",
            height: "auto",
            margin: "10px",
          }}
          alt="δελφίνι"
        />

	
    <Typography variant="h3" sx={theme.typography.typography2}>Πραγματοποιήθηκε με μεγάλη επιτυχία στην Κόρινθο (11-13 Νοεμβρίου 2016) το <b>26ο Συνέδριo</b> του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ) 
	με θέμα: «<b>Περιβάλλον και πλαστικό. Η πλαστική σακούλα</b>»</Typography>
    
	<Typography variant="h3" sx={theme.typography.typography2}>Συνδιοργανωτές του Συνεδρίου ήταν: <b>η ΚΕΔΕ, ο Σύνδεσμος Προστασίας και Ορθολογικής Ανάπτυξης  Κορινθιακού (ΣΠΟΑΚ) «ΑΡΙΩΝ», το Δίκτυο ΦοΔΣΑ</b> και ο <b>Δήμος Κορινθίων</b></Typography>
	<br/><br/>

	
    <Typography variant="h1" sx={theme.typography.typography1}>Απόφαση 26ου Πανελλήνιου Συνεδρίου ΠΑΝΔΟΙΚΟ</Typography>
   
   <Typography variant="h3" sx={theme.typography.typography2}>   Οι σύνεδροι του 26ου Συνεδρίου του Πανελληνίου Δικτύου Οικολογικών Οργανώσεων (ΠΑΝΔΟΙΚΟ), που πραγματοποιήθηκε στην Κόρινθο 11-13 Νοεμβρίου 2016 με συνδιοργανωτές την ΚΕΔΕ, τον ΣΠΟΑΚ «ΑΡΙΩΝΑ», το Δίκτυο ΦοΔΣΑ και το Δήμο Κορινθίων, λαμβάνοντας υπόψη τις εισηγήσεις των προσκεκλημένων ομιλητών, από όλους τους επιστημονικούς φορείς, την τοπική αυτοδιοίκηση, τις οικολογικές οργανώσεις και τον επιχειρηματικό κόσμο, διαπιστώνουν ότι: Η πλαστική ρύπανση εκτός από τις εμφανείς επιπτώσεις στο χερσαίο περιβάλλον, εμφανίζει εξαιρετικά ανεξέλεγκτες διαστάσεις στο θαλάσσιο περιβάλλον, κυρίως από τα μικροπλαστικά, με σοβαρές επιπτώσεις και στη δημόσια υγεία. Κύρια αιτία αποτελούν τα πλαστικά μιας χρήσης (πλαστικές σακούλες, κυπελλάκια κλπ.), τα οποία έχουν τη μεγαλύτερη ευθύνη για την κακοποίηση του περιβάλλοντος και την καταστροφή της θαλάσσιας πανίδας.</Typography>
   <Typography variant="h3" sx={theme.typography.typography2}>Διαπίστωσαν από τις εισηγήσεις ότι το πρόβλημα είναι πολύ μεγαλύτερο από ότι πιστεύεται ακόμα και σε ευαισθητοποιημένα τμήματα της κοινωνίας, ακόμα και από τις ίδιες τις οικολογικές οργανώσεις.</Typography>
   <Typography variant="h3" sx={theme.typography.typography2}>Τονίζεται ότι το πρόβλημα της θαλάσσιας ρύπανσης είναι διασυνοριακό και απαιτεί εθνικές, διακρατικές, αλλά και δραστικές στρατηγικές αντιμετώπισης. <br/>
Οι προτάσεις που αποτυπώθηκαν στο συνέδριο θα αποτελέσουν σημαντική συμβολή στη διεξαγόμενη ήδη δημόσια διαβούλευση για την εναρμόνιση με την Ευρωπαϊκή Οδηγία για τον περιορισμό της πλαστικής σακούλας. 
</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Αναλυτικότερα αποφασίζεται να προταθούν: <br/> 
Καμπάνιες ενημέρωσης και εκπαιδευτικά προγράμματα για την ευαισθητοποίηση της κοινωνίας και την αλλαγή της καταναλωτικής συμπεριφοράς.<br/>
Βελτίωση των πολιτικών του Εθνικού Σχεδιασμού Ανακύκλωσης και εφαρμογή επιτέλους του Εθνικού Σχεδίου Διαχείρισης Απορριμμάτων (ΕΣΔΑ).<br/>
Ανάπτυξη Περιφερειακών και Εθνικών Σχεδίων παρακολούθησης του προβλήματος των θαλασσίων απορριμμάτων για τον έλεγχο της αποτελεσματικότητας των μέτρων.  Απαιτείται όμως, τα όποια σχετικά προγράμματα και πολιτικές αναπτύσσονται, να τυχαίνουν αυστηρής αξιολόγησης από αξιόπιστους φορείς. <br/>
Παρακολούθηση της δημόσιας υγείας μέσω  επιδημιολογικών ερευνών με στόχο την αξιοποίησή τους στις ετήσιες αναφορές παρακολούθησης.<br/>
Οι σύνεδροι εκφράζουν έντονα την αναγκαιότητα να αποφασιστεί από τώρα νομοθετικά η κατάργηση της πλαστικής σακούλας κλπ., αφού όμως δοθεί μια μεταβατική περίοδος, π.χ. 2-3 χρόνων. Στο διάστημα αυτό προτείνεται η δραστική της μείωση με ανταποδοτικό περιβαλλοντικό τέλος, για τις σακούλες με πάχος 15 έως 50 μικρά, καθώς και για τις σακούλες με 51 έως 100 μικρά, τις κομποστοποιήσιμες, καθώς και τις χάρτινες σακούλες.<br/><br/> 
</Typography>

<Typography variant="h3" sx={theme.typography.typography2}>Τα ανταποδοτικά οφέλη θα πρέπει να οδηγούνται:<br/> 
Α. Το μεγαλύτερο μέρος στον πολίτη με ένα μικρότερο να δίνεται: <br/>
Στις αρχές της τοπικής αυτοδιοίκησης προκειμένου να στηριχθεί η διαδικασία ανακύκλωσης.<br/>
Σε καμπάνιες ευαισθητοποίησης και πληροφόρησης. <br/>
Στην έρευνα για εναλλακτικά υλικά και νέες μεθόδους για την αντιμετώπιση της ρύπανσης. <br/>
Ή<br/>
Β. Ολόκληρο το ποσό θα πρέπει να επιστρέφεται απευθείας στον πολίτη.<br/>
</Typography>

   <Typography variant="h3" sx={theme.typography.typography2}>Καμία όμως πολιτική δεν θα μπορέσει να έχει επιτυχία αν δεν βασιστεί στην ενημέρωση του πληθυσμού, με βασικό μέσο την περιβαλλοντική εκπαίδευση και ενημέρωση, κυρίως στις μικρές ηλικίες.
   <br/><br/>
   
Για το 26ο Συνέδριο του ΠΑΝΔΟΙΚΟ<br/><br/>
 Ο Συντονιστής                  Ο Πρόεδρος<br/><br/>

Σάββας Χιονίδης                Κώστας Βολιώτης

</Typography>

</Box>

<ScrollToTopButton />

    </>
  );
}

export default F12_oikologika_12;
